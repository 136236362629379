import React, { useCallback, useState } from 'react'
import { Modal } from '@grafana/ui'

import { ScheduledTestPayload } from 'types/scheduledTests'
import { Button } from 'components/Button'
import {
  useCreateSchedule,
  useDisableSchedule,
  useUpdateSchedule,
} from './ScheduledTests.hooks'
import { ScheduledTestsSelect } from './ScheduledTestsSelect'
import {
  ScheduledTestsForm,
  ScheduledTestModalProps,
  ScheduledTestSelectValues,
  UpdateScheduledTestModalProps,
} from 'components/ScheduledTests'

export const CreateScheduledTestModal = ({
  isOpen,
  onDismiss,
  onSuccess,
  selectedTest,
}: ScheduledTestModalProps) => {
  const hasSelectStep = !selectedTest
  const [isSelectStep, setIsSelectStep] = useState(hasSelectStep)
  const [selectValues, setSelectValues] = useState<
    ScheduledTestSelectValues | undefined
  >(selectedTest)

  const handleDismiss = useCallback(() => {
    hasSelectStep && setIsSelectStep(true)
    setSelectValues(selectedTest)
    onDismiss()
  }, [onDismiss, hasSelectStep, selectedTest])

  const { mutate: createSchedule, isLoading } = useCreateSchedule({
    onSuccess: async () => {
      await onSuccess?.()
      handleDismiss()
    },
  })

  return (
    <Modal title="Schedule test run" isOpen={isOpen} onDismiss={handleDismiss}>
      {isSelectStep ? (
        <ScheduledTestsSelect
          formActions={
            <Modal.ButtonRow>
              <Button
                variant="secondary"
                onClick={handleDismiss}
                fill="outline"
              >
                Cancel
              </Button>
              <Button type="submit">Next</Button>
            </Modal.ButtonRow>
          }
          onSubmit={(values) => {
            setSelectValues(values)
            setIsSelectStep(false)
          }}
          selectValues={selectValues}
        />
      ) : selectValues ? (
        <ScheduledTestsForm
          formActions={
            <Modal.ButtonRow>
              <Button
                variant="secondary"
                onClick={handleDismiss}
                fill="outline"
              >
                Cancel
              </Button>
              {hasSelectStep && (
                <Button onClick={() => setIsSelectStep(true)} fill="outline">
                  Go back
                </Button>
              )}
              <Button
                disabled={isLoading}
                icon={isLoading ? 'fa fa-spinner' : undefined}
                type="submit"
              >
                Add schedule
              </Button>
            </Modal.ButtonRow>
          }
          isLoading={isLoading}
          onSubmit={createSchedule}
          projectName={selectValues?.project_name}
          testId={selectValues?.test_id}
          testName={selectValues?.test_name}
        />
      ) : null}
    </Modal>
  )
}

export const EditScheduledTestModal = ({
  isOpen,
  onDismiss,
  onSuccess,
  scheduledTest,
}: UpdateScheduledTestModalProps) => {
  const handleOnSuccess = async () => {
    await onSuccess?.()
    onDismiss()
  }

  const { mutate: updateSchedule, isLoading: isUpdatingLoading } =
    useUpdateSchedule({
      onSuccess: handleOnSuccess,
    })

  const { mutate: disableSchedule, isLoading: isDisablingLoading } =
    useDisableSchedule({
      onSuccess: handleOnSuccess,
      schedule: scheduledTest,
    })

  const isLoading = isUpdatingLoading || isDisablingLoading

  const handleUpdate = (payload: ScheduledTestPayload) => {
    updateSchedule({
      ...payload,
      id: scheduledTest.id,
      test_id: scheduledTest.test_id,
    })
  }

  return (
    <Modal title="Schedule test run" isOpen={isOpen} onDismiss={onDismiss}>
      <ScheduledTestsForm
        formActions={
          <Modal.ButtonRow>
            <Button variant="secondary" onClick={onDismiss} fill="outline">
              Cancel
            </Button>
            {scheduledTest.active && (
              <Button
                disabled={isLoading}
                icon={isDisablingLoading ? 'fa fa-spinner' : undefined}
                onClick={() => disableSchedule()}
                variant="destructive"
              >
                Disable Schedule
              </Button>
            )}
            <Button
              disabled={isLoading}
              icon={isUpdatingLoading ? 'fa fa-spinner' : undefined}
              type="submit"
            >
              {`${scheduledTest.active ? 'Update' : 'Enable'} schedule`}
            </Button>
          </Modal.ButtonRow>
        }
        isLoading={isLoading}
        onSubmit={handleUpdate}
        projectName={scheduledTest.load_test.project_name}
        scheduledTest={scheduledTest}
        testName={scheduledTest.load_test.name}
      />
    </Modal>
  )
}
