import type { Scenario } from '../schema'
import type { Options } from '../schema/options'
import { ErrorSource } from './errors'

type ScenarioMatcher<T> = {
  [P in Scenario['executor']]?: (
    scenario: Extract<Scenario, { executor: P }>,
    source: ErrorSource
  ) => T[]
}

function match<T>(
  scenario: Scenario,
  source: ErrorSource,
  matcher: ScenarioMatcher<T>
): T[] {
  switch (scenario.executor) {
    case 'shared-iterations':
      return matcher['shared-iterations']?.(scenario, source) ?? []

    case 'per-vu-iterations':
      return matcher['per-vu-iterations']?.(scenario, source) ?? []

    case 'constant-vus':
      return matcher['constant-vus']?.(scenario, source) ?? []

    case 'ramping-vus':
      return matcher['ramping-vus']?.(scenario, source) ?? []

    case 'constant-arrival-rate':
      return matcher['constant-arrival-rate']?.(scenario, source) ?? []

    case 'ramping-arrival-rate':
      return matcher['ramping-arrival-rate']?.(scenario, source) ?? []

    case 'externally-controlled':
      return matcher['externally-controlled']?.(scenario, source) ?? []
  }
}

function resolveScenarioSources(options: Options): ErrorSource[] {
  if (options.duration !== undefined) {
    return [
      {
        type: 'duration',
        scenario: {
          executor: 'constant-vus',
          duration: options.duration,
          vus: options.vus,
          exec: 'default',
        },
      },
    ]
  }

  if (options.stages !== undefined) {
    return [
      {
        type: 'stages',
        scenario: {
          executor: 'ramping-vus',
          stages: options.stages,
          startVUs: options.vus,
          exec: 'default',
        },
      },
    ]
  }

  // By default, k6 will use the shared-iterations executor if neither
  // scenarios or the shorthand properties are provided, so we check if
  // scenarios is undefined here as well.
  if (options.iterations !== undefined || options.scenarios === undefined) {
    return [
      {
        type: 'iterations',
        scenario: {
          executor: 'shared-iterations',
          iterations: options.iterations,
          vus: options.vus,
          exec: 'default',
        },
      },
    ]
  }

  return Object.entries(options.scenarios).map(([name, scenario]) => {
    return {
      type: 'scenario',
      name,
      scenario,
    }
  })
}

/**
 * This function removes having to handle shorthand definitions
 * of scenarios (e.g. using the `iterations` property). It converts
 * the shorthands into a full scenarios and passes an object describing
 * how the scenario was defined to the given matcher functions.
 */
export function matchScenarios<T>(
  options: Options,
  matcher: ScenarioMatcher<T>,
  filter: (scenario: Scenario) => boolean = () => true
): T[] {
  const sources = resolveScenarioSources(options)

  return sources.flatMap((source) => {
    if (!filter(source.scenario)) {
      return []
    }

    return match(source.scenario, source, matcher)
  })
}

export function validateScenarios<Error>(
  options: Options,
  fn: (scenario: Scenario, source: ErrorSource) => Error[]
) {
  return matchScenarios(options, {
    'constant-vus': fn,
    'shared-iterations': fn,
    'per-vu-iterations': fn,
    'ramping-vus': fn,
    'constant-arrival-rate': fn,
    'ramping-arrival-rate': fn,
  })
}

export function calculateTotalVUs(
  options: Options,
  filter: (scenario: Scenario) => boolean = () => true
): number {
  const matcher: ScenarioMatcher<number> = {
    'constant-vus': ({ vus = 1 }) => {
      return [vus]
    },

    'shared-iterations': ({ vus = 1 }) => {
      return [vus]
    },

    'per-vu-iterations': ({ vus = 1 }) => {
      return [vus]
    },

    'ramping-vus': ({ startVUs = 1, stages }) => {
      return [Math.max(startVUs, ...stages.map((stage) => stage.target))]
    },

    'constant-arrival-rate': ({ preAllocatedVUs, maxVUs }) => {
      return [maxVUs ?? preAllocatedVUs]
    },
    'ramping-arrival-rate': ({ preAllocatedVUs, maxVUs }) => {
      return [maxVUs ?? preAllocatedVUs]
    },
  }

  return matchScenarios(options, matcher, filter).reduce(
    (acc, vus) => acc + vus,
    0
  )
}
