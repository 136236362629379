import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
  type ReactNode,
} from 'react'

interface ProviderProps {
  children: ReactNode
}

export type AuditFilterValue = 'good' | 'needs-improvement' | 'poor' | 'skipped'

type SetFilter = Dispatch<SetStateAction<AuditFilterValue[]>>
type ContextProps = [AuditFilterValue[], SetFilter]

export const Context = createContext<Partial<ContextProps>>([])

const CloudInsightsAuditFilterProvider = ({ children }: ProviderProps) => {
  const state = useState<AuditFilterValue[]>([
    'good',
    'needs-improvement',
    'poor',
  ])
  const context = state as ContextProps

  return <Context.Provider value={context}>{children}</Context.Provider>
}

const useCloudInsightsAuditFilter = () => {
  const state = useContext(Context)

  if (typeof state === 'undefined') {
    throw new Error(
      'useCloudInsightsAuditFilter must be used within a CloudInsightsAuditFilterProvider'
    )
  }

  return state as ContextProps
}

export { CloudInsightsAuditFilterProvider, useCloudInsightsAuditFilter }
