import React, { useState } from 'react'
import { Button, Modal } from '@grafana/ui'

import { Account } from 'types'
import { useOnboarding } from 'hooks/useOnboarding'
import { ApplicationWindow } from 'components/ApplicationWindow'
import { CLIRunGuide } from 'components/CLIRunGuide'

import LocalTestExecutionImgSrc from './images/local-execution.png'
import { useAccount } from 'data/useAccount'
import { useProjectsByOrgId } from 'data/useProjects'

export function OnboardingModal() {
  const [hasXP, setHasXP] = useState<boolean | null>(null)
  const [stage, setStage] = useOnboarding()

  function handleDismiss() {
    setStage('experienced')
    setHasXP(null)
  }

  return (
    <Modal
      title="Have you used k6 before?"
      isOpen={stage === 'welcomed'}
      onDismiss={handleDismiss}
    >
      <OnboardingModalContent hasXP={hasXP} />

      <Modal.ButtonRow>
        {hasXP === null
          ? [
              <Button key="has-xp-yes" onClick={() => setHasXP(true)}>
                Yes
              </Button>,
              <Button key="has-xp-no" onClick={() => setHasXP(false)}>
                No
              </Button>,
            ]
          : [
              <Button key="back" onClick={() => setHasXP(null)}>
                Back
              </Button>,
              <Button key="close" variant="secondary" onClick={handleDismiss}>
                Close
              </Button>,
            ]}
      </Modal.ButtonRow>
    </Modal>
  )
}

interface OnboardingModalContentProps {
  hasXP: boolean | null
}

function OnboardingModalContent({ hasXP }: OnboardingModalContentProps) {
  const { data: account } = useAccount()
  const { data: projects = [] } = useProjectsByOrgId(getOrgId(account), {
    enabled: !!account,
  })

  const project = projects[0]
  const projectId = project?.id ?? 'YOUR PROJECT ID'

  if (hasXP === false) {
    return (
      <CLIRunGuide
        projectId={projectId}
        testName="YOUR TEST NAME"
        script=""
        scriptName="test.js"
      />
    )
  }

  if (hasXP === true) {
    return (
      <CLIRunGuide
        projectId={projectId}
        testName="YOUR TEST NAME"
        script=""
        scriptName="test.js"
        steps={[CLIRunGuide.Login, CLIRunGuide.Run]}
      />
    )
  }

  return (
    <ApplicationWindow>
      <img src={LocalTestExecutionImgSrc} />
    </ApplicationWindow>
  )
}

function getOrgId(account: Account | undefined) {
  return account?.organizations?.[0]?.id ?? 1
}
