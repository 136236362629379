import React, { FormEvent, MouseEvent, useState } from 'react'
import { Button, Icon, Input, Tooltip } from '@grafana/ui'

import {
  CreateNewProjectBtnContainer,
  NoResultsMessage,
  Overlay,
  ProjectLink,
  ProjectLinkLabel,
  ProjectList,
  SearchFieldWrapper,
} from './ProjectSelectorOverlay.styles'

import { useCanUserCreateProjects } from 'data/usePermissions'
import { useIsProjectReadOnlyById } from 'hooks/useIsProjectReadOnlyById'
import { routeMap } from 'routeMap'
import { Project } from 'types'

interface ProjectSelectorOverlayProps {
  projects: Project[]
  onCreateNewProjectBtnClick: () => void
}

export const ProjectSelectorOverlay = ({
  projects,
  onCreateNewProjectBtnClick,
}: ProjectSelectorOverlayProps) => {
  const [searchValue, setSearchValue] = useState('')
  const canUserCreateProjects = useCanUserCreateProjects()

  // Prevent Dropdown from closing when the user clicks on the search field
  const handleSearchFieldClick = (e: MouseEvent) => {
    e.stopPropagation()
  }

  const handleSearchValueChange = (e: FormEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value)
  }

  const filteredProjects = projects.filter(({ name }) =>
    name.toLowerCase().includes(searchValue.toLowerCase())
  )

  return (
    <Overlay>
      <SearchFieldWrapper onClick={handleSearchFieldClick}>
        <Input
          prefix={<Icon name="search" aria-hidden />}
          placeholder={'Search by project name'}
          onChange={handleSearchValueChange}
        />
      </SearchFieldWrapper>

      {filteredProjects.length > 0 ? (
        <ProjectList>
          {filteredProjects.map(({ id, name }) => (
            <ProjectListItem key={id} projectId={id} projectName={name} />
          ))}
        </ProjectList>
      ) : (
        <NoResultsMessage>No projects found</NoResultsMessage>
      )}

      {canUserCreateProjects && (
        <CreateNewProjectBtnContainer>
          <Button fullWidth onClick={onCreateNewProjectBtnClick}>
            Create new project
          </Button>
        </CreateNewProjectBtnContainer>
      )}
    </Overlay>
  )
}

const ProjectListItem = ({
  projectId,
  projectName,
}: {
  projectId: number
  projectName: string
}) => {
  const { getIsProjectReadOnly } = useIsProjectReadOnlyById()

  return (
    <ProjectLink to={routeMap.project(projectId)}>
      <ProjectLinkLabel>{projectName}</ProjectLinkLabel>
      {getIsProjectReadOnly(projectId) && (
        <Tooltip
          content={
            <>
              Invited as a <strong>read-only</strong> member
            </>
          }
        >
          <Icon name="eye" />
        </Tooltip>
      )}
    </ProjectLink>
  )
}
