import React from 'react'

import { Flex } from 'components/Flex'
import { docs } from 'components/DocLink'

import { useResponsiveRow } from '../components/hooks/useResponsiveRow'
import { Section } from '../components/Section'
import { LearnMoreLink } from '../components/LearnMoreLink'
import { PromoCodeSnippet } from '../components/PromoCodeSnippet'

export function ScriptExampleSection() {
  const style = useResponsiveRow('lg')

  return (
    <Section>
      <Flex gap={4} justify="space-between" width="100%" className={style}>
        <Flex direction="column" basis="600px" grow={0} shrink={1}>
          <div>
            <Section.Heading>
              Use the same script for local, distributed, and cloud tests
            </Section.Heading>
            <Section.Preamble>
              Migrate from local tests to the cloud using the same script for
              both environments.
            </Section.Preamble>
            <div>
              <LearnMoreLink href={docs.gettingStarted.runCloudTestsFromCLI} />
            </div>
          </div>
        </Flex>

        <Flex basis="1000px" grow={0} shrink={1} className="hidden-md">
          <PromoCodeSnippet />
        </Flex>
      </Flex>
    </Section>
  )
}
