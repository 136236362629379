import { useMemo } from 'react'
import { useQueryParameter } from 'hooks/useQueryParameter'
import { BreakdownTab } from '../Breakdown'
import { PropertyPath } from 'utils/typescript'
import {
  Check,
  Http,
  GrpcUrl,
  SortOptions,
  TestRunFilter,
  Threshold,
  WSValue,
  TestRunFilterWithOptions,
} from 'types'

import {
  BROWSER_HTTP_FILTER_OPTIONS,
  CHECKS_FILTER_OPTIONS,
  FilterOption,
  GRPC_FILTER_OPTIONS,
  HTTP_FILTER_OPTIONS,
  THRESHOLDS_FILTER_OPTIONS,
  WEB_SOCKETS_FILTER_OPTIONS,
} from './Filters.constants'

export function useURLFilters<T>(
  tab: BreakdownTab,
  initOptions: FilterOption[],
  defaultSort?: SortOptions<T>
) {
  const [filters, setURLFilters] = useQueryParameter<TestRunFilter[]>({
    name: tab,
    decoder: parseFilters,
    encoder: (decoded) => {
      return decoded.length ? encodeFilters(decoded) : null
    },
  })

  const [sortBy, setSortBy] = useQueryParameter<SortOptions<T> | undefined>({
    name: `${tab}Order`,
    decoder: (encoded) => (encoded ? parseSort(encoded) : defaultSort),
    encoder: (decoded) => (decoded ? encodeSort(decoded) : null),
  })

  // Don't show already selected filters as viable options
  const options = initOptions.filter(
    (option) => !filters.find((filter) => filter.by === option.value)
  )

  const filtersWithOptions: TestRunFilterWithOptions[] = useMemo(
    () =>
      filters.map((filter) => {
        const option = initOptions.find((opt) => opt.value === filter.by)

        return {
          ...filter,
          required: option?.required,
          multiSelect: option?.multiSelect,
          allowCustomValue: option?.allowCustomValue,
          label: option?.label,
        }
      }),
    [filters, initOptions]
  )

  return {
    filters: filtersWithOptions,
    options,
    sortBy,
    addFilterValue,
    addNewFilter,
    removeAllFilters,
    removeFilter,
    replaceFilters,
    updateSortBy,
    tab,
  }

  function addNewFilter(filter: Omit<TestRunFilter, 'values'>) {
    setURLFilters('replace', [
      ...filters,
      {
        ...filter,
        values: [],
      },
    ])
  }

  function removeFilter({ by }: { by: TestRunFilter['by'] }) {
    setURLFilters(
      'replace',
      filters.filter((filter) => filter.by !== by)
    )
  }

  function removeAllFilters() {
    setURLFilters('replace', [])
  }

  function addFilterValue({
    by,
    value,
  }: {
    by: TestRunFilter['by']
    value: string[]
  }) {
    setURLFilters(
      'replace',
      filters.map((filter) => {
        if (filter.by === by) {
          return {
            ...filter,
            values: value,
          }
        }

        return filter
      })
    )
  }

  function replaceFilters(newFilters: TestRunFilter[]) {
    setURLFilters('replace', newFilters)
  }

  /**
   *
   * @param value the new order by value
   * @param forceRemount force the data table to remount because we can't update the order from outside the table
   */
  function updateSortBy(value?: SortOptions<T>) {
    setSortBy('replace', value)
  }
}

export function encodeFilters(decoded: TestRunFilter[]) {
  return encodeURIComponent(btoa(JSON.stringify(decoded)))
}

function parseFilters(encodedFilters: string | null) {
  try {
    return encodedFilters
      ? JSON.parse(atob(decodeURIComponent(encodedFilters)))
      : []
  } catch {
    return []
  }
}

function encodeSort<T>(sortOptions?: SortOptions<T>) {
  if (!sortOptions) {
    return ''
  }

  return `${sortOptions.sortBy} ${sortOptions.direction}`
}

function parseSort<T>(value?: string) {
  const [sortBy, direction] = value?.split(' ') as [
    PropertyPath<T>,
    'asc' | 'desc'
  ]

  return {
    sortBy,
    direction,
  }
}

export const useRunDetailsHttpFilters = () =>
  useURLFilters<Http>(BreakdownTab.HTTP, HTTP_FILTER_OPTIONS)

export const useRunDetailsThresholdsFilters = () =>
  useURLFilters<Threshold>(BreakdownTab.THRESHOLDS, THRESHOLDS_FILTER_OPTIONS)

export const useRunDetailsChecksFilters = () =>
  useURLFilters<Check>(BreakdownTab.CHECKS, CHECKS_FILTER_OPTIONS)

export const useRunDetailsGRPCFilters = () =>
  useURLFilters<GrpcUrl>(BreakdownTab.GRPC, GRPC_FILTER_OPTIONS)

export const useRunDetailsWebSocketsFilters = () =>
  useURLFilters<WSValue>(BreakdownTab.WS, WEB_SOCKETS_FILTER_OPTIONS)

export const useRunDetailsBrowserFilters = () =>
  useURLFilters<any>(BreakdownTab.BROWSER, BROWSER_HTTP_FILTER_OPTIONS)
