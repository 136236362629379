import styled from 'styled-components'

export const StyledWebVitalValue = styled.div`
  display: inline;
  &.rating--good {
    // we only want to highlight bad values
  }

  &.rating--needs_improvement {
    color: ${({ theme }) => theme.visualization.getColorByName('orange')};
  }

  &.rating--poor {
    color: ${({ theme }) => theme.visualization.getColorByName('red')};
  }
`
