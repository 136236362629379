import { isFetchError } from '@grafana/runtime'
import * as Sentry from '@sentry/react'
import { CaptureContext, ScopeContext } from '@sentry/types'
import { isLockedDown } from 'data/queryClient'

const isEnabled = process.env.SENTRY_ENABLED === 'true'

export const sentryClient = {
  configure: ({
    dsn,
    version,
    environment,
  }: {
    dsn: string
    version: string
    environment: string
  }) => {
    if (!isEnabled) {
      return
    }

    if (Sentry.getCurrentHub().getClient()) {
      sentryClient.enable()
      return
    }

    Sentry.init({
      dsn,
      environment,
      release: `k6-app@${version}`,
      normalizeDepth: 5,
      beforeSend(event) {
        if (!event?.tags?.isK6PluginError) {
          return null
        }

        return event
      },
    })
  },
  captureException: (exception: any, captureContext?: CaptureContext) => {
    if (!isEnabled) {
      return
    }

    const tags = (captureContext as ScopeContext)?.tags

    Sentry.captureException(exception, {
      ...captureContext,
      tags: {
        ...tags,
        isK6PluginError: 'true',
      },
    })
  },
  captureEvent: (...args: Parameters<typeof Sentry.captureEvent>) => {
    if (!isEnabled) {
      return
    }

    Sentry.captureEvent(...args)
  },
  setContext: (...args: Parameters<typeof Sentry.setContext>) => {
    if (!isEnabled) {
      return
    }

    Sentry.setContext(...args)
  },
  setUserContext: (user: any) => {
    if (!isEnabled) {
      return
    }

    Sentry.configureScope(function (scope) {
      scope.setUser(user)
    })
  },
  clearUserContext: () => {
    if (!isEnabled) {
      return
    }

    Sentry.configureScope(function (scope) {
      scope.setUser(null)
    })
  },
  disable: () => {
    const client = Sentry.getCurrentHub().getClient()

    if (client) {
      client.getOptions().enabled = false
    }
  },
  enable: () => {
    const client = Sentry.getCurrentHub().getClient()

    if (client) {
      client.getOptions().enabled = true
    }
  },
}

export function captureBackendSrvException(error: unknown) {
  // Capture all non-fetch errors backend throws
  if (!isFetchError(error)) {
    return sentryClient.captureException(error)
  }

  // Skip not found and form validation error
  const shouldCaptureException =
    ![400, 404].includes(error.status) && !isLockedDown(error)

  if (shouldCaptureException) {
    // Give a nice name to exception because BackendSrv throws object without name
    sentryClient.captureException(new Error('FetchError'), {
      extra: { error },
      tags: {
        endpoint: error.config.url.replace(/\b\d+\b/g, ':id'),
        method: error.config.method,
        errorCode: error.status,
      },
      // Override sentries issue grouping to avoid splitting into separate issues
      fingerprint: ['backend-srv-fetch-error'],
    })
  }
}
