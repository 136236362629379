import { TestRun, TrendDataPoint } from 'types'
import { TREND_VALUE_STATUS_MAP } from 'constants/trend'
import {
  isTestPreparingToStart,
  isTestProcessing,
  isTestRunning,
} from 'utils/testRun'
import { getRunStatusColor } from 'pages/utils'
import { toTrendValueStatus } from 'utils/trends'

import { isDataPointCalculating } from '../../utils'

const isTestActive = (testRun: TestRun) =>
  isTestPreparingToStart(testRun) ||
  isTestRunning(testRun) ||
  isTestProcessing(testRun)

function getRunStatus(dataPoint: TrendDataPoint) {
  const status = toTrendValueStatus(dataPoint.run)

  return TREND_VALUE_STATUS_MAP[status]
}

export const getBackgroundColor = (dataPoint: TrendDataPoint) => {
  const isStarting = isDataPointCalculating(dataPoint)
  const isRunning = isTestActive(dataPoint.run)

  if (!isRunning && isStarting) {
    return 'transparent'
  }

  const runStatus = getRunStatus(dataPoint)

  if (!runStatus) {
    return 'transparent'
  }

  return getRunStatusColor(dataPoint.run)
}

export const getProgressState = (dataPoint: TrendDataPoint) => {
  const isStarting = isDataPointCalculating(dataPoint)
  const isRunning = isTestActive(dataPoint.run)

  if (isRunning) {
    return 'running'
  }

  if (isStarting) {
    return 'starting'
  }

  return 'finished'
}
