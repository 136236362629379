import styled from 'styled-components'
import { Button, Icon } from '@grafana/ui'

import { TestRunWithMetrics } from 'types'
import { spacing, type PropsWithTheme } from 'utils/styled'
import { getScoreColor } from 'utils/insights'
import { DataTable } from 'components/DataTable'
import { InsightScore } from 'types/cloudInsights'

export const NoteCellBody = styled.div`
  padding: ${spacing(0.5)}; // so outline is visible on focus
  padding-right: 0;
`

export const CreateNoteButton = styled(Button)`
  padding: 0;
  border: none;
  background-color: transparent !important;
  opacity: 0;

  &:focus-visible {
    opacity: 1;
  }
`

export const NoteIcon = styled(Icon)`
  opacity: 0;

  &:focus-visible {
    opacity: 1;
  }
`

export const NoteText = styled.div`
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.text.primary};
`

export const EditNoteButton = styled(Button)`
  width: 100%;
  line-height: unset;
  padding: 0;
  text-align: unset;

  > span {
    width: 100%;
  }

  &:hover {
    background-color: transparent;
  }

  [tabIndex]:focus-visible + div ${NoteIcon} {
    opacity: 1;
  }
`

export const NoteWrapper = styled.div`
  display: flex;
  gap: ${spacing(1)};
  width: 100%;
`

export const RunsDataTable = styled(DataTable<TestRunWithMetrics>)`
  .rdt_TableRow:hover {
    ${CreateNoteButton},
    ${NoteIcon} {
      opacity: 1;
    }
  }

  .fa-spinner {
    width: auto;
  }
`

export const RunsDataTablePlaceholder = styled.div`
  min-height: 500px;
`

interface ScoreProps extends PropsWithTheme {
  score?: InsightScore
}

export const Score = styled.span<ScoreProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${getScoreColor};
  font-size: ${({ theme }) => theme.typography.h6.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`
