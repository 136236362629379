import React from 'react'

import { TableColumn } from 'components/DataTable'
import { StaticIP } from 'types'
import { LoadZone } from 'types/loadZone'

import { StaticIPsLoadZoneLabel } from '../StaticIPsLoadZoneLabel'
import { StaticIPsStatusLabel } from '../StaticIPsStatusLabel'

export const staticIPsColumns: Array<
  TableColumn<StaticIP & { load_zone?: LoadZone; load_zone_city?: string }>
> = [
  {
    name: 'Load Zone',
    sortable: true,
    sortField: 'load_zone_city',
    cell: (row) => {
      return row.load_zone ? (
        <StaticIPsLoadZoneLabel loadZone={row.load_zone} />
      ) : (
        <span>{row.load_zone_identifier}</span>
      )
    },
  },
  {
    name: 'IP Address',
    sortable: true,
    sortField: 'ip',
    cell: (row) => {
      return <span>{row.ip ?? '-'}</span>
    },
  },
  {
    name: 'Status',
    sortable: true,
    sortField: 'provisioning_status',
    cell: (row) => {
      return <StaticIPsStatusLabel status={row.provisioning_status} />
    },
  },
]
