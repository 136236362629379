import React from 'react'
import { BrowserMetricSummary, WebVitalName } from 'types'
import { WebVitalGauge } from 'components/WebVitalGauge'
import { getSummaryP75Value } from 'utils/webVitals'

export interface WebVitalOverviewProps {
  name: WebVitalName
  left: BrowserMetricSummary
  right: BrowserMetricSummary | null | undefined
  disabled?: boolean
}

export function WebVitalOverview({
  name,
  left,
  right,
  disabled,
}: WebVitalOverviewProps) {
  const value = getSummaryP75Value(name, left)
  const compareValue =
    right !== undefined ? getSummaryP75Value(name, right ?? undefined) : null

  return (
    <WebVitalGauge
      name={name}
      value={value}
      compareValue={compareValue}
      disabled={disabled}
    />
  )
}
