import React, { useMemo } from 'react'
import { Chart } from 'components/Chart'
import { useTimeRange } from '../../../TimeRangeContext'
import { ChartFilters } from '../../ChartFilters/ChartFilters'
import { TagQueryBuilder } from 'utils/metrics'
import { TestRunAnalysis } from 'types'
import { RunsPageTestIds } from 'types/dataTestIds'

interface BrowserChartProps {
  analysis: TestRunAnalysis
  url: string
  scenario: string
}

export function BrowserChart({ analysis, url, scenario }: BrowserChartProps) {
  const { timeRange, setTimeRange } = useTimeRange()
  const tags = useMemo(() => {
    return new TagQueryBuilder()
      .equal('url', url)
      .equal('scenario', scenario)
      .build()
  }, [url, scenario])

  if (!analysis?.main?.nodes) {
    return null
  }

  return (
    <ChartFilters tags={tags} nodes={analysis.main.nodes} type="browser">
      {(metrics) => (
        <Chart
          title="Web Vitals"
          testRun={analysis.main}
          compareWith={analysis.compareWith}
          metrics={metrics}
          timeRange={timeRange}
          onChangeTimeRange={setTimeRange}
          dataTestId={RunsPageTestIds.BrowserChart}
        />
      )}
    </ChartFilters>
  )
}
