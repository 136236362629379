import { useCallback } from 'react'
import { useAppContext } from 'appContext'
import { useHasOnlyTrialTypeSubscriptions } from 'data/useSubscriptions'

import { TrackingEvent } from './tracking.types'

export const useTrackAction = () => {
  const { orgId } = useAppContext()
  const isTrial = useHasOnlyTrialTypeSubscriptions()

  const trackAction = useCallback(
    ({ eventName, ...rest }: TrackingEvent) => {
      if (process.env.NODE_ENV !== 'production') {
        return
      }

      window.rudderanalytics?.track(eventName, {
        k6_org_id: orgId,
        subscription_type: isTrial ? 'trial' : 'paid',
        ...rest,
      })
    },
    [orgId, isTrial]
  )

  return trackAction
}

// Used in instances where an org id does not exist yet (uninitialized state)
export const useTrackActionWithoutOrg = () => {
  const trackAction = useCallback(({ eventName, ...rest }: TrackingEvent) => {
    if (process.env.NODE_ENV !== 'production') {
      return
    }

    window.rudderanalytics?.track(eventName, {
      ...rest,
    })
  }, [])

  return trackAction
}
