import styled from 'styled-components'
import { color, fontWeight, spacing } from 'utils/styled'

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${spacing(1)};
  padding-bottom: ${spacing(1)};

  h2 {
    margin: 0;
    font-weight: ${fontWeight.bold};
    font-size: ${({ theme }) => theme.typography.size.base};
    text-transform: uppercase;
  }
`

export const Line = styled.div`
  height: 2px;
  background: ${color('border', 'weak')};
  flex: 1;
`
