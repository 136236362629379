import { Select } from '@grafana/ui'
import { useLoadZones } from 'data/useLoadZones'
import React from 'react'
import { TestRunNode } from 'types'
import { LoadZone } from 'types/loadZone'
import { InlineElement, InlineLabel } from './ChartFilters.styles'

interface SelectLoadZoneProps {
  nodes: TestRunNode[]
  onChange: (loadZone?: string) => void
  value?: string
}

export const SelectLoadZone = ({
  nodes,
  onChange,
  value,
}: SelectLoadZoneProps) => {
  const { data: loadZones } = useLoadZones()
  if (!loadZones || nodes.length <= 1) {
    return null
  }

  const options = [
    { value: '', label: 'All load zones' },
    ...getLoadZoneOptions(nodes, loadZones),
  ]

  return (
    <InlineElement>
      <InlineLabel>Load zone</InlineLabel>
      <Select
        options={options}
        onChange={({ value }) => onChange(value)}
        value={value || ''}
      />
    </InlineElement>
  )
}

export const getLoadZoneOptions = (
  nodes: TestRunNode[],
  loadZones: LoadZone[]
) => {
  return nodes.map((node) => {
    const loadZone = loadZones.find(
      (lz) => lz.k6_load_zone_id === node.load_zone_id
    )
    const label = !loadZone
      ? 'N/A'
      : loadZone.public
      ? `${loadZone.city}, ${loadZone.country}`
      : loadZone.name

    return { value: node.load_zone_id, label }
  })
}
