import React from 'react'
import { Converter, type ShowdownExtension, type Flavor } from 'showdown'

const DEFAULT_OPTIONS = [
  { key: 'simplifiedAutoLink', value: true },
  { key: 'openLinksInNewWindow', value: true },
]

interface MarkdownProps {
  extensions?: ShowdownExtension
  flavor?: Flavor
  markdown: string
  options?: Array<{ key: string; value: any }>
  onSanitize?: (html: string) => string
}

export const MarkdownRenderer = ({
  flavor,
  extensions,
  markdown,
  options = [],
  onSanitize,
}: MarkdownProps) => {
  const opts = [...DEFAULT_OPTIONS, ...options]
  const converter = new Converter()

  if (opts.length) {
    opts.forEach((option) => {
      converter.setOption(option.key, option.value)
    })
  }

  if (flavor) {
    converter.setFlavor(flavor)
  }

  if (extensions) {
    converter.addExtension(extensions)
  }

  let html = converter.makeHtml(markdown)

  if (onSanitize) {
    html = onSanitize(html)
  }

  return <div dangerouslySetInnerHTML={{ __html: html }} />
}
