import React from 'react'
import { HttpMetricSummary } from 'types'
import { quantity, requestRate, timing } from 'utils/formatters'

interface HttpTestSummaryProps {
  summary?: HttpMetricSummary
}

const getFailureCopy = (count: number) => {
  if (count === 0) {
    return null
  }

  // Believe it or not, I actually have a test that made
  // a bunch of requests but got only 1 failure. :P
  if (count === 1) {
    return (
      <>
        with <b>1</b> failure
      </>
    )
  }

  return (
    <>
      with <b>{quantity(count)}</b> failures
    </>
  )
}

export const HttpTestSummary = ({ summary }: HttpTestSummaryProps) => {
  return (
    <>
      The 95th percentile response time of the system being tested was{' '}
      <b>{timing(summary?.duration.p95 ?? 0)}</b>, and{' '}
      <b>{quantity(summary?.requests_count ?? 0)}</b> requests were made{' '}
      {getFailureCopy(summary?.failures_count ?? 0)} at an average rate of{' '}
      <b>{requestRate(summary?.rps_mean ?? 0, { unit: 'none' })}</b>{' '}
      requests/second.
    </>
  )
}
