import {
  GroupTree,
  GroupWithScenarioName,
  useGroupClient,
} from 'data/clients/entities/groups'
import { toGroupTreeQueryKey } from 'data/queryKeys'
import { useDatasource } from 'datasourceContext'
import {
  useStreamingQuery,
  useInfiniteStreamingQuery,
} from 'hooks/useStreamingQuery'
import { PagedItems, SortOptions, TestRun, TestRunAnalysis } from 'types'
import { calculateTotalPages } from 'utils/pagination'
import { TreeTable } from '../types'
import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { useTableStateContext } from '../TableStateProvider'
import { groupBy, keyBy } from 'lodash'
import { isFetching, isLoading } from 'utils/reactQuery'
import { useComparedValues } from '../hooks'

export function useGroupTree(analysis: TestRunAnalysis) {
  const client = useGroupClient()

  return useStreamingQuery(analysis.main, {
    queryKey: toGroupTreeQueryKey(analysis.main.id, analysis.compareWith?.id),
    queryFn: () => client.fetchTree(analysis.main.id, analysis.compareWith?.id),
  })
}

interface UseGroupValuesOptions<T, P> {
  analysis: TestRunAnalysis
  group: GroupWithScenarioName
  params: P
  table: TreeTable<T, P>
  sortBy: SortOptions<T> | undefined
}

export function useGroupValues<T, P>({
  analysis,
  params,
  group,
  sortBy,
  table,
}: UseGroupValuesOptions<T, P>) {
  const { pageSize } = table

  const {
    queryKey,
    queryFn,
    enabled = true,
  } = table.fetchByGroup({
    ...params,
    testRun: analysis.main,
    group,
    sortBy,
    pageSize,
  })

  const values = useInfiniteStreamingQuery(analysis.main, {
    queryKey: queryKey,

    queryFn({ pageParam = 1 }) {
      return queryFn(pageParam as number)
    },

    getNextPageParam(lastPage, allPages) {
      if (
        allPages.length >=
        calculateTotalPages({ pageSize, totalSize: lastPage.count })
      ) {
        return undefined
      }

      return allPages.length + 1
    },

    keepPreviousData: true,
    enabled,
  })

  const comparisons = useComparedValues({
    left: analysis.main,
    right: analysis.compareWith,
    pages: values.data?.pages ?? [],
    params,
    table,
  })

  return {
    isLoading:
      values.isLoading || values.isPreviousData || comparisons.some(isLoading),
    hasNextPage: values.hasNextPage ?? false,
    isFetching: values.isFetching || comparisons.some(isFetching),
    values: values.data?.pages,
    pages: comparisons,
    fetchNextPage: values.fetchNextPage,
  }
}

function* getGroups(tree: GroupTree): Generator<GroupWithScenarioName> {
  if (tree.type === 'root') {
    for (const scenario of tree.scenarios) {
      yield* getGroups(scenario)
    }

    return
  }

  if (tree.type === 'scenario') {
    yield* getGroups(tree.rootGroup)

    return
  }

  yield tree.left

  for (const child of tree.children) {
    yield* getGroups(child)
  }
}

interface UseExpandOnInitOptions<T, P> {
  enabled: boolean
  testRun: TestRun
  tree: GroupTree | undefined
  params: P
  table: TreeTable<T, P>
  sortBy: SortOptions<T> | undefined
}

export function useExpandOnInit<T, P>({
  enabled,
  testRun,
  tree,
  params,
  table,
  sortBy,
}: UseExpandOnInitOptions<T, P>) {
  const { state, setInitialized, expandGroups } = useTableStateContext()

  const groups = useMemo(() => {
    return tree && [...getGroups(tree)]
  }, [tree])

  const prefetchedGroups = usePrefetchedGroups({
    enabled,
    testRun,
    groups,
    params,
    sortBy,
    table,
  })

  useEffect(() => {
    if (
      groups === undefined ||
      prefetchedGroups === undefined ||
      state.initialized
    ) {
      return
    }

    if (!enabled) {
      setInitialized()

      return
    }

    expandGroups(groups, prefetchedGroups, table.groupId)

    setInitialized()
  }, [
    state.initialized,
    enabled,
    groups,
    prefetchedGroups,
    setInitialized,
    expandGroups,
    table.groupId,
  ])

  return state.initialized
}

interface UsePrefetchValuesOptions<T, P> {
  enabled: boolean
  testRun: TestRun
  groups: GroupWithScenarioName[] | undefined
  params: P
  sortBy: SortOptions<T> | undefined
  table: TreeTable<T, P>
}

function usePrefetchedGroups<T, P>({
  enabled,
  testRun,
  groups,
  params,
  sortBy,
  table,
}: UsePrefetchValuesOptions<T, P>) {
  const { ds } = useDatasource()
  const queryClient = useQueryClient()

  const [prefetched, setPrefetched] = useState<string[] | undefined>(
    enabled ? undefined : []
  )

  useEffect(() => {
    if (groups === undefined || prefetched !== undefined) {
      return
    }

    const { queryKey, queryFn } = table.fetchByGroup({
      ...params,
      testRun,
      group: 'any',
      pageSize: 1000,
      sortBy,
    })

    queryClient
      .fetchQuery({ queryKey: queryKey, queryFn: () => queryFn(1) })
      .then((result) => {
        const groupsById = keyBy(groups, (node) =>
          table.groupId ? table.groupId(node) : node.id
        )
        const valuesByGroup = groupBy(result.items, table.groupBy)

        for (const [groupId, items] of Object.entries(valuesByGroup)) {
          const group = groupsById[groupId]

          if (group === undefined) {
            continue
          }

          const { queryKey } = table.fetchByGroup({
            ...params,
            testRun,
            group,
            pageSize: table.pageSize,
            sortBy,
          })

          queryClient.setQueryData<InfiniteData<PagedItems<T>>>(queryKey, {
            pageParams: [1],
            pages: [
              {
                items,
                count: items.length,
              },
            ],
          })
        }

        setPrefetched(Object.keys(valuesByGroup))
      })
  }, [ds, queryClient, groups, prefetched, testRun, params, sortBy, table])

  return prefetched
}
