import React from 'react'

import { WebVitalCLS } from 'types/insights/webVitals'

import { InsightListItem } from '../InsightItem'
import { SkippedDescription } from '../SkippedDescription'
import { InsightBody } from '../InsightBody'
import { docs, ReadMore } from 'components/DocLink'

interface WebVitalCLSItemProps {
  insight: WebVitalCLS
}

const WebVitalCLSDescription = ({ insight }: WebVitalCLSItemProps) => {
  switch (insight.status) {
    case 'PASSED':
      return (
        <>
          We did not detect any considerable shift in layout.{' '}
          <ReadMore article={docs.insights.webVital.cls} />
        </>
      )

    case 'WARNING':
      return <>We detected a slight shift in layout.</>

    case 'FAILED':
      return <>We detected a considerable shift in layout.</>

    default:
      return <SkippedDescription insight={insight} />
  }
}

export const WebVitalCLSItem = ({ insight }: WebVitalCLSItemProps) => {
  return (
    <InsightListItem header="Cumulative Layout Shift" insight={insight}>
      <WebVitalCLSDescription insight={insight} />
    </InsightListItem>
  )
}

export const WebVitalCLSContent = () => {
  return (
    <InsightBody>
      <p>
        Cumulative Layout Shift (CLS) is a measure of visual stability on a
        webpage. It quantifies the amount of unexpected layout shift of visible
        page content. Improving CLS is essential for providing a more
        predictable and pleasant user experience.
      </p>
      <h4>Here are some strategies to enhance Cumulative Layout Shift:</h4>
      <ul>
        <li>
          <strong>Set Dimensions for Images and Videos:</strong> Reserve space
          for images and videos by specifying their dimensions in the HTML. This
          prevents the page content from shifting when these elements load.
        </li>
        <li>
          <strong>Use CSS Transitions for Animations:</strong> If you have
          animations on your page, use CSS transitions instead of JavaScript
          animations. CSS transitions are less likely to cause layout shifts.
        </li>
        <li>
          <strong>Preload and Preconnect:</strong> Use the `preload` and
          `preconnect` hints to give the browser a heads-up about resources that
          will be needed soon. This can help to load resources earlier and
          reduce the chance of layout shifts.
        </li>
        <li>
          <strong>Lazy Load Elements Below the Fold:</strong> Defer the loading
          of images and other non-critical resources until they are about to be
          scrolled into the viewport.
        </li>
        <li>
          <strong>Avoid Dynamically Injected Content:</strong> If you{"'"}re
          injecting content dynamically, make sure to reserve space for it in
          the layout or use placeholders.
        </li>
        <li>
          <strong>Optimize Font Loading:</strong> Use the `font-display: swap;`
          property in your CSS to ensure text is rendered with a fallback font
          until the custom font is loaded.
        </li>
        <li>
          <strong>Optimize Third-Party Embeds:</strong> If using third-party
          embeds, like social media widgets or iframes, ensure they reserve the
          required space and don{"'"}t cause unexpected shifts. Some embed codes
          provide specific attributes or customization options to control layout
          behavior.
        </li>
        <li>
          <strong>Testing on Different Devices and Resolutions:</strong> Test
          your website on various devices and screen resolutions to ensure that
          layout shifts are minimized across different contexts.
        </li>
        <li>
          <strong>Implementing a Loading Skeleton:</strong> Use loading
          skeletons or placeholders for content that is about to load. This
          gives users a visual indication of where the content will appear and
          reduces the perceived impact of shifts.
        </li>
      </ul>

      <p>
        By implementing these strategies, you can work towards minimizing
        Cumulative Layout Shift and providing a more stable and user-friendly
        experience on your website. Remember to continuously monitor and
        optimize, as website content and structure may change over time.
      </p>
    </InsightBody>
  )
}
