import styled from 'styled-components'
import { spacing } from 'utils/styled'
import { FullWidthRow, FullWidthTableData } from './Table'
import { QueryErrorBoundary } from 'components/ErrorBoundary'

export const ValueRowBody = styled(FullWidthRow)`
  border-top: none;
  padding-top: 0;
  padding-bottom: ${spacing(3)};

  ${FullWidthTableData} {
    padding: ${spacing(0, 2, 0, 2)};

    &::before {
      display: block;
      position: absolute;
      content: '';
    }
  }
`

export const StyledBoundary = styled(QueryErrorBoundary)`
  padding-top: ${spacing(2)};
`
