import React, { FormEvent, useEffect, useState } from 'react'
import { Button, ModalProps, TextArea } from '@grafana/ui'
import styled from 'styled-components'

import { useUpdateTestRunNote } from 'data/useUpdateTestRunNote'
import { useHasUserProjectWriteAccess } from 'data/usePermissions'
import { TestRun } from 'types'
import { getEditNoteDisabledText } from 'pages/TestRunPage/components/NoteButton'
import { getNoteMenuLabel } from 'utils/note'
import { isTestRunExpired } from 'utils/testRun'
import { spacing } from 'utils/styled'

import { NoteModal as Modal } from './NoteModal.styles'

export interface NoteModalProps extends Omit<ModalProps, 'title'> {
  run: TestRun
}

export const NoteModal = ({ onDismiss, run, isOpen }: NoteModalProps) => {
  const hasNote = run.note.length > 0
  const [note, setNote] = useState('')
  const [hasChanged, setHasChanged] = useState(false)

  const { isLoading, mutateAsync: updateNote } = useUpdateTestRunNote(
    run,
    onDismiss
  )
  const hasUserProjectWriteAccess = useHasUserProjectWriteAccess()

  // The modal is controlled
  useEffect(() => {
    setNote(run.note)
  }, [run])

  useEffect(() => {
    const isUpdatingNote = note !== run.note
    const isDeletingNote = isUpdatingNote && note.length === 0
    const isCreatingNote = run.note.length === 0 && note.length > 0
    setHasChanged(isDeletingNote || isCreatingNote || isUpdatingNote)
  }, [note, run.note])

  const onNoteChanged = (event: FormEvent<HTMLTextAreaElement>) => {
    setNote(event.currentTarget.value.trim())
  }

  const canEditNote =
    !isTestRunExpired(run) && hasUserProjectWriteAccess && !isLoading

  return (
    <Modal
      title={getNoteMenuLabel(run.note, hasUserProjectWriteAccess)}
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      <TextArea onChange={onNoteChanged} readOnly={!canEditNote}>
        {run.note}
      </TextArea>
      {!hasUserProjectWriteAccess && (
        <Description>
          You do not have permission to create or edit test run notes
        </Description>
      )}
      {!canEditNote && (
        <Description>{getEditNoteDisabledText('edit')}</Description>
      )}
      {hasUserProjectWriteAccess && (
        <Modal.ButtonRow>
          <Button disabled={isLoading} onClick={onDismiss} variant="secondary">
            Cancel
          </Button>
          <Button
            disabled={!canEditNote || !hasChanged || isLoading}
            icon={isLoading ? 'fa fa-spinner' : undefined}
            onClick={() => updateNote({ note })}
          >
            {hasNote ? 'Save' : 'Create'}
          </Button>
        </Modal.ButtonRow>
      )}
    </Modal>
  )
}

export const Description = styled.p`
  padding-top: ${spacing(1)};
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
  margin: 0;
`
