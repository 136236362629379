import React from 'react'
import styled from 'styled-components'
import { Icon } from '@grafana/ui'

import { Flex } from 'components/Flex'
import { Tooltip, TooltipProps } from 'components/Tooltip'

interface ValueWrapperProps {
  children: React.ReactNode
  tooltip?: TooltipProps['content']
  tooltipPlacement?: TooltipProps['placement']
  showWarning?: boolean
}

export function Value({
  children,
  tooltip,
  tooltipPlacement = 'top',
  showWarning = false,
}: ValueWrapperProps) {
  return (
    <Tooltip
      show={tooltip !== undefined}
      content={tooltip ?? ''}
      placement={tooltipPlacement}
    >
      <StyledValue gap={0.5} align="center" warning={showWarning}>
        <Flex gap={0.5} align="center">
          {children}
          {showWarning && <Icon name="exclamation-circle" aria-hidden />}
        </Flex>
      </StyledValue>
    </Tooltip>
  )
}

const StyledValue = styled(Flex)<{ warning?: boolean }>`
  // !important prevents the value from being overridden by the table styles
  width: min-content !important;
  max-width: 100%;
  color: ${({ warning, theme }) => warning && theme.colors.warning.main};
`
