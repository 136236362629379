import styled from 'styled-components'
import { spacing } from 'utils/styled'
import { PlainSection } from './Section.styled'

export const SectionGrid = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  grid-auto-flow: column;
  gap: ${spacing(3)};

  && {
    overflow: visible;
  }

  > ${PlainSection} {
  }
`
