import { LoadingContainer } from 'components/LoadingContainer'
import styled from 'styled-components'
import { MetaBarStyled } from 'components/MetaBar/MetaBar.styled'

export const TestPageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  ${MetaBarStyled} {
    flex: 1 1 100%;
    margin: 0;
  }
`

export const TestPageLoadingContainer = styled(LoadingContainer)`
  position: unset;
  flex: 1 1 0;
`

export const TestPageBodyPlaceholder = styled.div`
  height: 300px;
`
