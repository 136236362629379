import { useRef, useCallback } from 'react'
import { SortOptions } from 'types'
import { useQueryParameter } from './useQueryParameter'
import { PropertyPath } from 'utils/typescript'

export function useSortBy<T>(key: string, options: Array<PropertyPath<T>>) {
  const optionsRef = useRef(options)

  const encoder = useCallback((sortBy: SortOptions<T> | undefined | null) => {
    return sortBy !== undefined
      ? `${sortBy?.sortBy} ${sortBy?.direction ?? 'asc'}`
      : null
  }, [])

  const decoder = useCallback(
    (value: string | null): SortOptions<T> | undefined => {
      if (value === null) {
        return undefined
      }

      const [field, direction, ...rest] = value.split(' ')

      // Make sure that the format matches "field asc" or "field desc" and nothing else.
      if (
        field === undefined ||
        (direction !== 'asc' && direction !== 'desc') ||
        rest.length > 0
      ) {
        return undefined
      }

      const sortBy = optionsRef.current.find((option) => option === field)

      if (sortBy === undefined) {
        return undefined
      }

      return {
        sortBy,
        direction,
      }
    },
    []
  )

  return useQueryParameter({
    name: `sort-${key}`,
    encoder,
    decoder,
  })
}
