import styled, { css } from 'styled-components'
import { colorManipulator } from '@grafana/data'
import { styleMixins } from '@grafana/ui'

import { color } from 'utils/styled'

import { DATA_TABLE_INITIAL_HEIGHT } from './DataTable.constants'

export const DataTableWrapper = styled.div<{
  $isEmpty?: boolean
  $isFetching?: boolean
  $isRowClickable?: boolean
  $expandableRows?: boolean
  $fixedHeader?: boolean
}>`
  ${({ $fixedHeader }) =>
    $fixedHeader &&
    css`
      flex: 1;
    `}

  position: relative;
  width: 100%;

  .rdt_TableCell {
    &,
    > * {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .rdt_TableCol_Sortable.active {
    color: ${({ theme }) => theme.colors.primary.main};
  }

  .rdt_TableCol_Sortable:hover,
  .rdt_TableCol_Sortable:focus {
    span,
    span * {
      opacity: 1 !important;
    }
  }

  .rdt_TableHeadRow {
    height: ${DATA_TABLE_INITIAL_HEIGHT}px;
    padding: 4px 0;

    [data-sort-id]:focus-visible {
      ${({ theme }) => styleMixins.focusCss(theme)};
    }
  }

  *[data-tag='allowRowEvents'] {
    cursor: ${({ $isRowClickable }) => $isRowClickable && 'pointer'};
  }

  .rdt_TableRow {
    color: ${({ theme }) => theme.colors.text.primary};

    &:hover {
      background-color: ${({ theme }) =>
        theme.isLight
          ? colorManipulator.darken(theme.colors.background.secondary, 0.05)
          : colorManipulator.lighten(theme.colors.background.secondary, 0.025)};
    }
  }

  .rdt_TableCol {
    text-transform: uppercase;
  }

  ${({ $isFetching, $isEmpty, theme }) =>
    $isFetching &&
    css`
      .rdt_Table::after {
        content: '';
        display: block;
        position: absolute;
        top: ${$isEmpty ? 0 : `${DATA_TABLE_INITIAL_HEIGHT}px`};
        height: ${$isEmpty
          ? '100%'
          : `calc(100% - ${DATA_TABLE_INITIAL_HEIGHT}px);`};
        width: 100%;
        background-color: ${theme.isDark
          ? 'rgba(0, 0, 0, 0.2)'
          : 'rgba(255, 255, 255, 0.4)'};
      }
    `}

  ${({ $expandableRows }) =>
    $expandableRows &&
    css`
      .rdt_TableRow {
        position: relative;
      }

      .rdt_TableHeadRow > *:first-child,
      .rdt_TableRow > *:first-child {
        bottom: 0;
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1000;
      }
    `}

  .rdt_TableRow.row-disabled {
    background-color: transparent;
    color: ${color('text', 'primary')};
    border-bottom-color: ${color('border', 'weak')};
  }

  .rdt_TableHead {
    z-index: 1;
    color: ${({ theme }) => theme.colors.text.primary};
    background-color: ${({ theme }) => theme.colors.background.secondary};
  }

  .rdt_TableBody {
    z-index: 0;
  }
`

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: ${DATA_TABLE_INITIAL_HEIGHT}px;
  height: calc(100% - ${DATA_TABLE_INITIAL_HEIGHT}px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PaginationWrapper = styled.div`
  margin: 20px;

  &::after {
    content: ' ';
    display: block;
    clear: both;
  }
`

export const SortIconWrapper = styled.div<{ $isSorted?: boolean }>`
  & svg {
    transform: none !important;
    opacity: 1 !important;

    fill: ${({ $isSorted, theme }) =>
      $isSorted
        ? theme.colors.primary.main
        : theme.isDark
        ? 'rgb(204, 204, 220)'
        : 'rgba(36,41,46,1)'} !important;
  }
`
