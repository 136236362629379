const downloadBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')

  a.href = url
  a.download = filename || 'download'

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      a.removeEventListener('click', clickHandler)
    }, 150)
  }

  a.addEventListener('click', clickHandler, false)
  a.click()

  return a
}

export const downloadFile = (
  data: BlobPart,
  name: string,
  contentType: string
) => {
  const blob = new Blob([data], { type: contentType })
  return downloadBlob(blob, name) // Create a download link for the blob content
}

export const downloadScript = (scriptData: BlobPart, name = 'script.js') =>
  downloadFile(scriptData, name, 'application/javascript')
