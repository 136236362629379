import React, { useMemo } from 'react'

import { TestRun, Check } from 'types'

import { RunDetailsFilters } from '../../Filters/Filters'
import { useURLFilters } from '../../Filters/Filters.hooks'

interface ChecksFiltersProps extends ReturnType<typeof useURLFilters<Check>> {
  run: TestRun
}

export const ChecksFilters = ({
  addFilterValue,
  addNewFilter,
  filters,
  options,
  removeAllFilters,
  removeFilter,
  run,
  updateSortBy,
}: ChecksFiltersProps) => {
  const presets = useMemo(
    () => [
      {
        label: 'Order all by fail count',
        value: 'fail-count',
        onChange: () => {
          removeAllFilters()
          updateSortBy({
            sortBy: 'metric_summary/fail_count',
            direction: 'desc',
          })
        },
      },
      {
        label: 'Order all by success rate',
        value: 'success-rate',
        onChange: () => {
          removeAllFilters()
          updateSortBy({
            sortBy: 'metric_summary/success_rate',
            direction: 'desc',
          })
        },
      },
    ],
    [removeAllFilters, updateSortBy]
  )

  return (
    <RunDetailsFilters
      filters={filters}
      options={options}
      run={run}
      onClearFiltersClick={removeAllFilters}
      onAddNewFilterClick={addNewFilter}
      onRemoveFilterClick={removeFilter}
      onAddFilterValue={addFilterValue}
      presets={presets}
    />
  )
}
