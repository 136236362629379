import React, { useMemo } from 'react'

import { TestRun, WSValue } from 'types'

import { RunDetailsFilters } from '../../Filters/Filters'
import { useURLFilters } from '../../Filters/Filters.hooks'

interface WebSocketFiltersProps
  extends ReturnType<typeof useURLFilters<WSValue>> {
  run: TestRun
}

export const WSFilters = ({
  addFilterValue,
  addNewFilter,
  filters,
  options,
  removeAllFilters,
  removeFilter,
  updateSortBy,
  run,
}: WebSocketFiltersProps) => {
  const presets = useMemo(
    () => [
      {
        label: 'Order all by slowest connecting',
        value: 'slowest-connecting',
        onChange: () => {
          removeAllFilters()
          updateSortBy({
            sortBy: 'ws_metric_summary/connecting/p95',
            direction: 'desc',
          })
        },
      },
      {
        label: 'Order all by session count',
        value: 'session-count',
        onChange: () => {
          removeAllFilters()
          updateSortBy({
            sortBy: 'ws_metric_summary/sessions',
            direction: 'desc',
          })
        },
      },
    ],
    [removeAllFilters, updateSortBy]
  )

  return (
    <RunDetailsFilters
      filters={filters}
      onAddFilterValue={addFilterValue}
      onAddNewFilterClick={addNewFilter}
      onClearFiltersClick={removeAllFilters}
      onRemoveFilterClick={removeFilter}
      options={options}
      presets={presets}
      run={run}
    />
  )
}
