import React from 'react'

import type { TrendDataPoint } from 'types'

import { getDateLabel, getTimeLabel } from './XAxisLabel.utils'
import { Container, DateLabel, TimeLabel } from './XAxisLabel.styles'

interface XAxisLabelProps {
  dataPoint: TrendDataPoint
}

export const XAxisLabel = ({ dataPoint }: XAxisLabelProps) => {
  const { run } = dataPoint
  const date = new Date(run.started || run.created)

  return (
    <Container direction="column" align="center">
      <DateLabel>{getDateLabel(date)}</DateLabel>
      <TimeLabel>{getTimeLabel(date)}</TimeLabel>
    </Container>
  )
}
