import { TimeRange } from '@grafana/data'
import { endOfDay, formatISO, startOfDay } from 'date-fns'

import { AuditTrail, PaginatedAuditTrailResponse } from 'types/auditTrail'

export const getAuditTrails = (pages: PaginatedAuditTrailResponse[] = []) => {
  return pages.reduce(
    (allAuditTrails = [], props) => [
      ...allAuditTrails,
      ...(props?.audit_trails ?? []),
    ],
    [] as AuditTrail[]
  )
}

export const getOrderByParam = (value?: string) => {
  if (!value) {
    return
  }

  const [field, order] = value.split(' ')

  return `${order === 'desc' ? '-' : ''}${field}`
}

export const getPageCount = (pages: PaginatedAuditTrailResponse[] = []) => {
  return pages?.[0]?.audit_trail?.meta?.count ?? 0
}

export const getStartDate = (timeRange: TimeRange) => {
  if (timeRange.from?.isValid()) {
    return formatISO(startOfDay(timeRange.from.toDate()))
  }

  return
}

export const getEndDate = (timeRange: TimeRange) => {
  if (timeRange.to?.isValid()) {
    return formatISO(endOfDay(timeRange.to.toDate()))
  }

  return
}

export const hasMoreData = (pages: PaginatedAuditTrailResponse[] = []) => {
  return getPageCount(pages) !== pages.length
}

/**
 * TimeRangeInput accepts null for these properties as it's cleared state
 */
export const initialTimeRange = {
  from: null,
  to: null,
  raw: {
    from: null,
    to: null,
  },
} as unknown as TimeRange
