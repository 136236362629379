import { useTheme2 } from '@grafana/ui'

export const useStepCount = (width: number) => {
  const theme = useTheme2()

  if (width <= theme.breakpoints.values.lg) {
    return 10
  }

  return 5
}

export const useOffsetPosition = (spacing: number, offset: number) => {
  const theme = useTheme2()

  if (!offset) {
    return 0
  }

  return offset + Number(theme.spacing(spacing).replace('px', ''))
}
