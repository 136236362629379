import {
  ColorGroups,
  MetricConfig,
  PlotType,
  TestRunConfigType,
} from 'datasource/models'
import { VUS_METRIC } from 'constants/metrics'

import { NamedColors, Threshold } from 'types'
import { parseThresholdName, parseThresholdStat } from 'utils/threshold'

import { toUnit } from 'utils/units'
import { toMetricOption } from 'utils/options/metricOptions'

export const getThresholdMetrics = (threshold: Threshold) => {
  const parsedExpression = parseThresholdName(threshold.name)
  const parsedMethod = parseThresholdStat(
    parsedExpression.metric,
    threshold.stat
  )
  const metricProperties = toMetricOption({ name: parsedExpression.metric })

  const metric: MetricConfig = {
    type: TestRunConfigType.Metric,
    name: parsedExpression.metric,
    label: metricProperties.label,
    unit: toUnit({ metric: parsedExpression.metric, method: parsedMethod }),
    plot: { type: PlotType.Line, style: 'solid' },
    preferredColor: { group: ColorGroups.Blue },
    thresholds: [
      {
        color: NamedColors.Red,
        value: threshold.value,
      },
    ],
    query: {
      type: 'series',
      metric: parsedExpression.metric,
      method: parsedMethod,
      tags: parsedExpression.tags,
    },
  }

  return [VUS_METRIC, metric]
}
