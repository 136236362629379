import { useMemo } from 'react'
import { PagedItems, Scenario, ScenarioId, SortOptions, TestRunId } from 'types'
import { K6DataSource, ScenariosPayload } from 'datasource/datasource'
import { includes, QueryParams, toSelectQuery } from 'datasource/serialization'
import { useDatasource } from 'datasourceContext'
import { timeRangeToQueryParams } from 'utils/date'
import { type AbsoluteTimeRange } from '@grafana/data'

const select: Array<keyof Scenario> = [
  'name',
  'id',
  'test_run_id',
  'http_metric_summary',
  'ws_metric_summary',
  'grpc_metric_summary',
  'checks_metric_summary',
  'browser_metric_summary',
]

function toPagedItems({ value }: ScenariosPayload) {
  return {
    count: value.length,
    items: value,
  }
}

export class ScenarioClient {
  datasource

  constructor(datasource: K6DataSource) {
    this.datasource = datasource
  }

  fetchAll(
    testRunId: TestRunId,
    sortBy: SortOptions<Scenario> | undefined,
    timeRange?: AbsoluteTimeRange
  ) {
    const selectQuery = toSelectQuery<Scenario>({
      select,
      orderBy: [sortBy ? [sortBy.sortBy, sortBy.direction] : ['name', 'asc']],
    })

    const query = { ...timeRangeToQueryParams(timeRange) }

    const params = {
      ...query,
      ...selectQuery,
    }

    return this.fetch(testRunId, params)
  }

  fetchByIds(
    testRunId: TestRunId,
    ids: ScenarioId[]
  ): Promise<PagedItems<Scenario>> {
    const params = toSelectQuery<Scenario>({
      select,
      filter: includes('id', ids),
    })

    return this.fetch(testRunId, params)
  }

  async fetch(testRunId: number, params: QueryParams) {
    const result = await this.datasource
      .get<ScenariosPayload>(`loadtests/v4/test_runs(${testRunId})/scenarios`, {
        params,
      })
      .then(toPagedItems)

    return result
  }
}

export function useScenarioClient() {
  const { ds } = useDatasource()

  return useMemo(() => new ScenarioClient(ds), [ds])
}
