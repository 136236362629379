import React from 'react'
import styled from 'styled-components'
import { Icon } from '@grafana/ui'

import type { Trend } from 'types'
import { spacing } from 'utils/styled'
import { MinimalPageSection } from 'components/PageSection'
import { TrendMetaName, TrendMetaTags } from 'components/TrendChart/TrendMeta'
import { TrendBarChart } from 'components/TrendBarChart'

import { TestPageTrendChartWrapper } from './TrendsHttp.styles'
import { Flex } from 'components/Flex'

export const BAR_COUNT = 40

interface TrendHttpProps {
  trend?: Trend
  calculating?: boolean
}

export const TrendsHttp = ({ trend, calculating }: TrendHttpProps) => {
  if (trend === undefined) {
    return null
  }

  return (
    <MinimalPageSection key={trend.id}>
      <TrendContainer>
        <Flex gap={1} align="center" justify="space-between">
          {calculating ? (
            <Flex gap={1} align="center">
              <Icon name="fa fa-spinner" />
              <span>Calculating trend...</span>
            </Flex>
          ) : (
            <TrendMetaName trend={trend} />
          )}
          <TrendMetaTags trend={trend} />
        </Flex>
      </TrendContainer>
      <TestPageTrendChartWrapper>
        <TrendBarChart
          className="padded-trend-bar-chart"
          barCount={BAR_COUNT}
          trend={trend}
          calculating={calculating}
          placeholder="The trend will appear once there are some test runs available."
        />
      </TestPageTrendChartWrapper>
    </MinimalPageSection>
  )
}

const TrendContainer = styled.div`
  margin-bottom: ${spacing(2)};
`
