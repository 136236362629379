import { useMemo } from 'react'

import type { Trend, TrendDataPoint } from 'types'

import { DEFAULT_TREND_BAR_COUNT } from './constants'
import { getMinMaxValue, getPaddedArray } from './TrendBarChart.utils'

export function usePaddedTrendValues(
  trend?: Trend,
  valueCount: number = DEFAULT_TREND_BAR_COUNT
) {
  return useMemo(() => {
    return getPaddedArray(trend?.values ?? [], valueCount)
  }, [trend, valueCount])
}

export function useMinMaxValue(values?: Array<TrendDataPoint | undefined>) {
  return useMemo(() => {
    return getMinMaxValue(values ?? [])
  }, [values])
}
