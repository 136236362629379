import React from 'react'

import { type Trend } from 'types'
import { LoadingContainer } from 'components/LoadingContainer'
import { MinimalPageSection } from 'components/PageSection'

import { useCategoryResultHistory } from './TrendsInsights.hooks'
import { Chart } from './Chart'
import { Container } from './TrendsInsights.styles'

interface TrendsInsightsProps {
  trend?: Trend
  calculating?: boolean
}

export const TrendsInsights = ({ trend }: TrendsInsightsProps) => {
  const { data, isLoading } = useCategoryResultHistory(trend, {
    useErrorBoundary: false,
  })

  return (
    <MinimalPageSection>
      <LoadingContainer loading={isLoading}>
        <p>
          This chart illustrates the trend of category scores across all test
          runs in which insights were executed successfully.
        </p>
        <Container>{!isLoading && <Chart history={data} />}</Container>
      </LoadingContainer>
    </MinimalPageSection>
  )
}
