import React, { ReactNode, useMemo } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { PanelRenderer } from '@grafana/runtime'
import { PanelChrome } from '@grafana/ui'
import {
  createDefaultFieldConfig,
  createPanelData,
  createPanelOptions,
} from './TrendChart.utils'
import { Trend } from 'types'
import {
  ChartPlaceholderBackground,
  ChartPlaceholderContent,
  ChartPlaceholderWrapper,
  TrendChartContainer,
  TrendChartLegend,
  TrendChartWrapper,
} from './TrendChart.styles'
import { TrendMeta } from './TrendMeta'
import { Spinner } from '../LoadZones/LoadZones.styles'
import { Tooltip } from '../Tooltip'

const BAR_COUNT = 40

interface TrendChartProps {
  className?: string
  trend: Trend
  placeholder?: ReactNode
  legend?: boolean
  xAxis?: boolean
  barCount?: number
  calculating?: boolean
}

export const TrendChart = ({
  className,
  trend,
  placeholder,
  legend = true,
  xAxis = true,
  barCount = BAR_COUNT,
  calculating,
}: TrendChartProps) => {
  const data = useMemo(
    () => createPanelData(trend, barCount, xAxis),
    [trend, barCount, xAxis]
  )
  const panelOptions = createPanelOptions()
  const fieldConfig = createDefaultFieldConfig()

  return (
    <TrendChartContainer className={className}>
      <TrendChartWrapper>
        <AutoSizer>
          {(size) => (
            <PanelChrome width={size.width || 0} height={size.height || 0}>
              {(innerWidth, innerHeight) =>
                trend.values.length > 0 ? (
                  <PanelRenderer
                    pluginId="barchart"
                    title=""
                    width={innerWidth}
                    height={innerHeight}
                    data={data}
                    options={panelOptions}
                    fieldConfig={fieldConfig}
                  />
                ) : (
                  <ChartPlaceholderWrapper $height={innerHeight}>
                    <ChartPlaceholderContent>
                      {placeholder}
                    </ChartPlaceholderContent>
                    <ChartPlaceholderBackground
                      $hasPlaceholder={!!placeholder}
                    />
                  </ChartPlaceholderWrapper>
                )
              }
            </PanelChrome>
          )}
        </AutoSizer>
      </TrendChartWrapper>
      {legend && (
        <TrendChartLegend>
          <TrendMeta trend={trend} />
          <Tooltip
            show={calculating}
            content="Calculating trend"
            placement="top"
          >
            <span> {calculating && <Spinner size={14} />}</span>
          </Tooltip>
        </TrendChartLegend>
      )}
    </TrendChartContainer>
  )
}
