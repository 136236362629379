import {
  object,
  literal,
  array,
  type infer as Infer,
  number,
  string,
} from 'zod'
import { NodePathSchema } from '../primitives'

export const ScriptTooLargeSchema = object({
  type: literal('script-too-large'),
  actual: number(),
  max: number(),
})

export type ScriptTooLarge = Infer<typeof ScriptTooLargeSchema>

export const NoOptionsExportedSchema = object({
  type: literal('no-options-exported'),
})

export type NoOptionsExported = Infer<typeof NoOptionsExportedSchema>

export const ScriptSyntaxErrorSchema = object({
  type: literal('script-syntax-error'),
})

export type ScriptSyntaxError = Infer<typeof ScriptSyntaxErrorSchema>

export const NonConstantOptionsSchema = object({
  type: literal('non-constant-options'),
  errors: array(
    object({
      path: NodePathSchema,
      message: string(),
    })
  ),
})

export type NonConstantOptions = Infer<typeof NonConstantOptionsSchema>
