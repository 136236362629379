import { IconButtonStyled } from './DownloadBlob.styled'
import React, { useState } from 'react'
import { useTimeout } from 'usehooks-ts'
import { IconName } from '@grafana/ui'

interface DownloadBlobProps {
  icon?: IconName
  activeIcon?: IconName
  filename: string
  content: string
}

export function DownloadBlob({
  icon = 'download-alt',
  activeIcon = 'check',
  filename,
  content,
}: DownloadBlobProps) {
  const [active, setActive] = useState(false)
  useTimeout(() => setActive(false), active ? 3000 : null)
  const currentIcon = active ? activeIcon : icon

  const handleDownload = () => {
    setActive(true)
    const blob = new Blob([content], { type: 'text/plain' })
    const element = document.createElement('a')

    // Create anchor and emulate click
    const url = URL.createObjectURL(blob)
    element.setAttribute('href', url)
    element.setAttribute('download', filename)
    element.click()

    // Clean up
    URL.revokeObjectURL(url)
  }

  const tooltipKey = active ? 'active' : 'inactive'
  const tooltipText = active ? 'Downloading' : 'Download script'

  return (
    <IconButtonStyled
      onClick={handleDownload}
      aria-label="Download"
      name={currentIcon}
      $active={active}
      tooltip={<span key={tooltipKey}>{tooltipText}</span>}
    />
  )
}
