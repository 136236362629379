import styled from 'styled-components'

import { color, spacing } from 'utils/styled'
import { PageSection } from 'components/PageSection'

import { INSIGHTS_MIN_HEIGHT } from './constants'
import { getBackgroundColorMedium } from './utils'

export const Container = styled(PageSection)`
  margin-bottom: ${spacing(3)};
  background-color: ${color('background', 'secondary')};
  border: 1px solid ${getBackgroundColorMedium};
`

export const Content = styled.div<{ loading?: boolean }>`
  min-height: ${({ loading = false }) =>
    loading ? `${INSIGHTS_MIN_HEIGHT}px` : 'auto'};
`
