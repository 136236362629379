import styled from 'styled-components'
import { color, colorByName, spacing } from 'utils/styled'

export const StyledTimelineItem = styled.div`
  position: absolute;
  height: 8px;
  min-width: 1px;
  border-right: 1px solid black;

  &:not(.compact) {
    background-color: ${colorByName('gray')};
  }

  &.compact {
    background-color: ${colorByName('gray')};

    &.web-vital-rating--good {
      background-color: ${colorByName('green')};
    }

    &.web-vital-rating--needs_improvement {
      background-color: ${colorByName('orange')};
    }

    &.web-vital-rating--poor {
      background-color: ${colorByName('red')};
    }
  }
`

export const InteractionIcons = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
`

export const ItemRow = styled.div`
  position: relative;
  flex-direction: column;
  min-height: 8px;
  width: 100%;
  margin-bottom: 4px;

  & ${StyledTimelineItem} {
    height: 10px;
    top: 4px; // same as top border height
    position: absolute;
    border-right: unset;
    z-index: 1;
  }

  &.web-vital-rating-border-top,
  .web-vital-rating-border-top {
    border-top: 6px ${colorByName('gray', 0.2)} solid;
  }

  .web-vital-rating-color {
    background-color: ${colorByName('grey')};
  }

  &.web-vital-rating--good {
    &.web-vital-rating-border-top,
    .web-vital-rating-border-top {
      border-top: 6px ${colorByName('green', 0.2)} solid;
    }

    .web-vital-rating-color {
      background-color: ${colorByName('green')};
    }
  }

  &.web-vital-rating--needs_improvement {
    &.web-vital-rating-border-top,
    .web-vital-rating-border-top {
      border-top: 6px ${colorByName('orange', 0.2)} solid;
    }

    .web-vital-rating-color {
      background-color: ${colorByName('orange')};
    }
  }

  &.web-vital-rating--poor {
    &.web-vital-rating-border-top,
    .web-vital-rating-border-top {
      border-top: 6px ${colorByName('red', 0.2)} solid;
    }

    .web-vital-rating-color {
      background-color: ${colorByName('red')};
    }
  }

  code {
    display: block;
  }
`

export const Item = styled.div`
  margin-top: ${spacing(1)};
  position: relative;
  margin-bottom: 2px;
  background: ${color('background', 'primary', 0.8)};
  display: inline-block;
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
`
