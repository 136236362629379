import { RefObject, useEffect, useState } from 'react'

export function useContentRect<T extends Element>(
  ref: RefObject<T>
): DOMRectReadOnly | undefined {
  const entry = useResizeObserver<T>(ref)

  if (!entry) {
    return undefined
  }

  return entry.contentRect
}

function useResizeObserver<T extends Element>(
  ref: RefObject<T>
): ResizeObserverEntry | undefined {
  const [entry, setEntry] = useState<ResizeObserverEntry>()

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const observer = new ResizeObserver((entries) => {
      setEntry(entries[0])
    })

    observer.observe(ref.current, { box: `border-box` })

    return () => {
      observer.disconnect()
    }
  }, [ref])

  return entry
}
