import React from 'react'
import { MultiSelect, MultiSelectCommonProps } from '@grafana/ui'
import { Selectable } from 'datasource/Selects/types'
import { OrganizationRole } from 'types'
import { useOrgRoles } from 'data/useOrgRoles'

export const getRecipientOptions = (
  projectUsers: OrganizationRole[]
): Array<Selectable<string>> => {
  return projectUsers.map(({ user_email }) => ({
    label: user_email,
    value: user_email,
  }))
}

interface NotificationRecipientsSelectProps
  extends Omit<MultiSelectCommonProps<string>, 'onChange' | 'value'> {
  value: string
  onChange(value: string[]): void
}

export const NotificationRecipientsSelect = ({
  value,
  onChange,
  ...rest
}: NotificationRecipientsSelectProps) => {
  const { data: orgRoles, isLoading } = useOrgRoles()
  const values = value ? value.split(',') : []

  return (
    <MultiSelect
      {...rest}
      value={values}
      className="multiple-select"
      placeholder="Select recipients"
      isClearable
      allowCustomValue
      isLoading={isLoading}
      options={getRecipientOptions(orgRoles ?? [])}
      onChange={(selectedOptions) => {
        const values = selectedOptions.map(({ value }) => value!)
        onChange(values)
      }}
    />
  )
}
