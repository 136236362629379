import React from 'react'
import styled, { css, keyframes } from 'styled-components'

export const ActivityCount = ({
  count,
  interactive,
}: {
  count: number
  interactive?: boolean
}) => {
  return (
    <Count $active={count !== 0} $interactive={interactive}>
      {count}
    </Count>
  )
}

const activeAnimation = keyframes`
  0% {
    border-top-color: var(--effectColor);
  }
  25% {
    border-right-color: var(--effectColor);
  }
  50% {
    border-bottom-color: var(--effectColor);
  }
  75% {
    border-left-color: var(--effectColor);
    background-color: var(--effectColor);
  }
`
export const Count = styled.div<{ $active?: boolean; $interactive?: boolean }>`
  --activityCountSize: ${({ theme }) => theme.spacing(3)};
  --effectColor: ${({ theme }) => {
    const variation = theme.isLight ? 'shade' : 'text'
    return theme.colors.primary[variation]
  }};
  --backgroundColor: ${({ theme }) => theme.colors.primary.main};
  --boxShadow: ${({ theme }) => theme.colors.primary.main};
  align-items: center;
  color: ${({ theme }) => theme.colors.primary.contrastText};
  display: flex;
  height: var(--activityCountSize);
  justify-content: center;
  max-width: var(--activityCountSize);
  min-width: var(--activityCountSize);
  position: relative;
  text-align: center;
  text-shadow: 0px 0 2px #000;
  z-index: 1;

  ${({ $interactive }) =>
    $interactive &&
    css`
      &:hover:before {
        background-color: var(--effectColor);
        border: transparent;
      }
    `}

  &:before {
    content: '';
    align-items: center;
    align-self: center;
    background-color: ${({ $interactive }) =>
      $interactive ? 'var(--backgroundColor)' : 'var(--effectColor)'};
    border-radius: 2px;
    border: 2px solid transparent;
    box-shadow: 0 0 var(--activityCountSize) -4px var(--boxShadow);
    color: #fff;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(45deg);
    width: 100%;
    z-index: -1;

    ${({ $active }) =>
      $active &&
      css`
        background-color: var(--backgroundColor);
        animation: ${activeAnimation} 2s linear infinite;
      `}
  }
`
