import { useMemo } from 'react'

import { TestRun, TestRunWithMetrics } from 'types'
import { createGroupedOptions } from './TestRunsSelect.utils'

export const useTestRunGroups = (
  selected: TestRun[],
  testRuns: TestRunWithMetrics[] = []
) => {
  return useMemo(() => {
    return createGroupedOptions(selected, testRuns)
  }, [selected, testRuns])
}
