import { useMemo } from 'react'
import { useHasUserProjectWriteAccess } from 'data/usePermissions'

const REQUIRES_WRITE_ROLE_MESSAGE =
  'This action requires a project editor role.'

export function useViewerButtonTooltipProps() {
  const hasUserProjectWriteAccess = useHasUserProjectWriteAccess()

  return useMemo(
    () =>
      hasUserProjectWriteAccess
        ? {}
        : { disabled: true, tooltip: REQUIRES_WRITE_ROLE_MESSAGE },
    [hasUserProjectWriteAccess]
  )
}
