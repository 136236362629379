type ParseResult = { success: true; seconds: number } | { success: false }

function toSeconds(
  duration: string,
  { time, unit }: RegExpExecArray['groups'] = { time: '0', unit: 's' }
) {
  const value = Number(time)

  if (Number.isNaN(value)) {
    throw new Error(
      `Unable to parse the time "${time}" of the duration "${duration}".`
    )
  }

  switch (unit) {
    case 'ns':
      return value / 1e9
    case 'us':
      return value / 1e6
    case 'ms':
      return value / 1e3
    case 's':
      return value
    case 'm':
      return value * 60
    case 'h':
      return value * 60 * 60

    default:
      throw new Error(
        `Unable to parse the unit "${unit}" of the duration "${duration}".`
      )
  }
}

function parseSign(duration: string): { sign: number; duration: string } {
  if (duration.startsWith('+')) {
    return { sign: 1, duration: duration.slice(1) }
  }

  if (duration.startsWith('-')) {
    return { sign: -1, duration: duration.slice(1) }
  }

  return { sign: 1, duration }
}

export function parseDuration(input: string): ParseResult {
  if (input.length === 0) {
    return { success: false }
  }

  const { sign, duration } = parseSign(input)

  const regexp = /((?<time>\d+)(?<unit>ns|us|ms|s|m|h))/y
  const matches: RegExpExecArray[] = []

  while (regexp.lastIndex < duration.length) {
    const match = regexp.exec(duration)

    if (match === null) {
      return { success: false }
    }

    matches.push(match)
  }

  if (matches.length === 0) {
    return { success: false }
  }

  return {
    success: true,
    seconds:
      sign *
      matches.reduce(
        (total, { groups }) => total + toSeconds(input, groups),
        0
      ),
  }
}

export function unsafeParseDuration(duration: string) {
  const result = parseDuration(duration)

  if (!result.success) {
    throw new Error(`Failed to parse duration "${duration}".`)
  }

  return result.seconds
}
