import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Icon, IconName } from '@grafana/ui'
import { color, spacing } from '../../utils/styled'

const Row = styled.div`
  display: flex;
  padding-right: ${spacing(0.5)};
  gap: ${spacing(1)};
  min-height: ${spacing(1)};
`

const Column = styled.div<{ $hasIcon?: boolean }>`
  display: flex;
  align-items: center;
  color: ${color('text', 'primary')};

  &:first-child {
    justify-content: center;
    background-color: ${color('background', 'canvas')};
    color: ${color('text', 'secondary')};
    width: 16px;
    min-height: ${({ $hasIcon }) => ($hasIcon ? 20 : 4)}px;
  }
`

interface DataRowProps {
  icon?: IconName | 'link-h'
  children?: ReactNode
}

export function DataRow({ icon, children = null }: DataRowProps) {
  const hasIcon = !!icon
  return (
    <Row>
      <Column $hasIcon={hasIcon}>
        {/* @ts-expect-error link-h is missing in IconName type */}
        {hasIcon && <Icon name={icon} size="xs" />}
      </Column>
      <Column>{children}</Column>
    </Row>
  )
}
