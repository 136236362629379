import React from 'react'
import { Badge } from '@grafana/ui'
import { spacing } from 'utils/styled'
import styled from 'styled-components'

const StyledBadge = styled(Badge)`
  margin-left: ${spacing(1)};
  line-height: 1.45;
`

export const TabSuffixNew = () => <StyledBadge text="NEW" color="orange" />
