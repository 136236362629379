import React, { useState } from 'react'

import { type TestRunAnalysis } from 'types'
import { type MetricConfig } from 'datasource/models'
import { Chart } from 'components/Chart'
import { Flex } from 'components/Flex'
import { useTimeRange } from 'pages/TestRunPage/TimeRangeContext'
import { MetricBuilder } from 'utils/metrics'
import { SelectAggregation } from 'pages/TestRunPage/components/ChartFilters/SelectAggregation'
import { ColorPool } from 'utils/colorPool'
import { RunsPageTestIds } from 'types/dataTestIds'

type AnalysisChartProps = {
  analysis: TestRunAnalysis
  colorPool: ColorPool
  loadZone?: string
  metric: MetricConfig
  showAggregationSelect?: boolean
}

export const AnalysisChart = ({
  analysis,
  colorPool,
  loadZone,
  metric,
  showAggregationSelect = true,
}: AnalysisChartProps) => {
  const [aggregationMethod, setAggregationMethod] = useState(
    metric.query.method
  )
  const { main, compareWith } = analysis
  const { timeRange, setTimeRange } = useTimeRange()
  const taggedMetric = new MetricBuilder({
    ...metric,
    query: {
      ...metric.query,
      method: aggregationMethod,
    },
  })

  if (loadZone) {
    taggedMetric.withTags({
      loadZone: { operator: 'equal', name: 'load_zone', values: [loadZone] },
    })
  }

  return (
    <Flex direction="column" gap={1}>
      <Flex>
        {showAggregationSelect && (
          <SelectAggregation
            metrics={[metric]}
            value={aggregationMethod}
            onChange={setAggregationMethod}
          />
        )}
      </Flex>
      <Chart
        colorPool={colorPool}
        compareWith={compareWith}
        metrics={[taggedMetric.build()]}
        onChangeTimeRange={setTimeRange}
        testRun={main}
        timeRange={timeRange}
        title={metric.label}
        dataTestId={RunsPageTestIds.AnalysisChart}
      />
    </Flex>
  )
}
