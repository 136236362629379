import { identity } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSearchParams } from './useSearchParams'

interface UseQueryParameterOptions<T> {
  /**
   * The name of the query parameter that the state is stored in.
   */
  name: string

  /**
   * A function that parses the query parameter value. If the URL does not
   * contain the query parameter, the value will be null and the parser
   * should return a default value.
   *
   * If value cannot be parsed, the parser should just ignore the input and
   * return some default value.
   */
  decoder: (value: string | null) => T

  /**
   * A function that takes a value and encodes as a string. If the encoder returns
   * null, the parameter will be removed from the query.
   */
  encoder: (value: T) => string | null
}

type QueryParameterFilter =
  | string[]
  | ((name: string, value: string) => boolean)

function resolveQueryParameterFilter(fn: QueryParameterFilter | undefined) {
  if (fn === undefined) {
    return () => true
  }

  if (Array.isArray(fn)) {
    return (name: string) => fn.includes(name)
  }

  return fn
}

export interface UseQueryParameterSetterOptions {
  filter?: QueryParameterFilter
}

/**
 * Creates a new URL where the query parameter has been updated with the new value and
 * optionally navigates to it using the specified operation. If the new value is null,
 * the parameter will be removed from the URL.
 */
type UseQueryParameterSetter<T> = (
  op: 'push' | 'replace' | 'href',
  newValue: T,
  options?: UseQueryParameterSetterOptions
) => string

export const useQueryParameter = <T>({
  name,
  decoder,
  encoder,
}: UseQueryParameterOptions<T>): [T, UseQueryParameterSetter<T>] => {
  const location = useLocation()

  const history = useHistory()
  const searchParams = useSearchParams()

  const rawValue = searchParams.get(name)

  const value = useMemo(() => {
    return decoder(rawValue)
  }, [decoder, rawValue])

  const setParam: UseQueryParameterSetter<T> = useCallback(
    (op, newValue, options) => {
      const filter = resolveQueryParameterFilter(options?.filter)

      const currentParams = new URLSearchParams(history.location.search)
      const newParams = new URLSearchParams()

      for (const [name, value] of currentParams) {
        if (!filter(name, value)) {
          continue
        }

        newParams.append(name, value)
      }

      const encodedValue = encoder(newValue)

      if (encodedValue === null) {
        newParams.delete(name)
      } else {
        newParams.set(name, encodedValue)
      }

      const search = newParams.toString()
      const href =
        search.length > 0
          ? `${location.pathname}?${newParams.toString()}`
          : location.pathname

      switch (op) {
        case 'push':
          history.push(href)
          break

        case 'replace':
          history.replace(href)
          break
      }

      return href
    },
    [name, history, location, encoder]
  )

  return [value, setParam]
}

export const useNumericParameter = (name: string, defaultValue = 0) => {
  return useQueryParameter({
    name,
    decoder: (param) => {
      const value = Number(param ?? undefined)

      if (isNaN(value)) {
        return defaultValue
      }

      return value
    },
    encoder: (value) => value.toString(),
  })
}

export function useEnumParameter<T extends string>(
  name: string,
  options: readonly [T, ...T[]]
) {
  return useQueryParameter({
    name,
    decoder: (value) => {
      return options.find((option) => option === value) ?? options[0]
    },
    encoder: identity,
  })
}

export function useUpdateQueryParameters() {
  const history = useHistory()

  return useCallback(
    (params: Record<string, string | null>) => {
      const newParams = new URLSearchParams(history.location.search)

      Object.entries(params).forEach(([name, value]) => {
        if (value === null) {
          newParams.delete(name)
          return
        }

        newParams.set(name, value)
      })

      const search = newParams.toString()
      const href =
        search.length > 0
          ? `${history.location.pathname}?${newParams.toString()}`
          : history.location.pathname

      history.replace(href)
    },
    [history]
  )
}
