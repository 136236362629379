import React from 'react'
import { NoDataSectionOverlay } from './NoDataOverlay.styles'

export const NoDataOverlay = ({
  children,
}: React.HTMLProps<HTMLDivElement>) => {
  return (
    <NoDataSectionOverlay>
      {children || <span>No data recorded</span>}
    </NoDataSectionOverlay>
  )
}
