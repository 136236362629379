import React, { ReactNode } from 'react'
import { Button, IconButton } from '@grafana/ui'

export const RefetchIcon = ({
  entity,
  onClick,
  children,
}: {
  entity: string
  onClick: () => void
  children?: ReactNode
}) => {
  const tooltip = `Could not load ${entity}. Click to retry.`
  const icon = 'exclamation-triangle'
  const variant = 'destructive'

  if (children) {
    return (
      <Button
        tooltip={tooltip}
        icon={icon}
        variant={variant}
        onClick={onClick}
        fill="text"
      >
        {children}
      </Button>
    )
  }

  return (
    <IconButton
      tooltip={tooltip}
      name={icon}
      variant={variant}
      onClick={onClick}
    />
  )
}
