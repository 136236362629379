import { nanoid } from 'nanoid'
import { MetricType, TrendAggregation, TrendingMetricConfig } from 'types'
import { deserializeQueryExpression } from 'datasource/serialization'
import {
  TrendingMetricDraftConfig,
  TrendingMetricDraftConfigError,
  ValidTrendingMetricDraftConfig,
} from './TrendingMetricsEditor.types'
import {
  isValidTagFilterDraft,
  tagFilterDraftErrors,
  toSerializedDraftTags,
} from 'components/TagsInput/TagsInputList.utils'
import {
  CustomOption,
  KnownOption,
} from 'datasource/Selects/VariableSelect.types'
import { MetricOption, toMetricOption } from 'utils/options/metricOptions'
import { GroupBase } from 'react-select'

export const DEFAULT_TRENDING_METRIC_CONFIG = {
  metric_name: 'http_req_duration',
  metric_type: MetricType.TREND,
  aggregation_function: 'histogram_quantile(0.95)',
  labels_selector: 'status!="0"',
}

const DEFAULT_TRENDING_METRIC_DRAFT_CONFIG = {
  metric_name: '',
  metric_type: MetricType.TREND,
  aggregation_function: '' as TrendAggregation,
  labels_selector: '',
}

export const toTrendingMetricDraftConfig = (
  config: TrendingMetricConfig
): TrendingMetricDraftConfig => {
  const {
    labels_selector,
    id,
    metric_type,
    metric_name,
    aggregation_function,
  } = config

  const deserializedTags = deserializeQueryExpression(labels_selector)
  const tags = Object.values(deserializedTags).flatMap((tag) => {
    return tag.values.map((value) => ({
      id: nanoid(),
      name: tag.name,
      operator: tag.operator,
      value: value,
    }))
  })

  return {
    id,
    metric: metric_name,
    type: metric_type,
    method: aggregation_function,
    tags,
  }
}

export const toNewTrendingMetricDraftConfig = (
  testId: number
): TrendingMetricDraftConfig => {
  const newDraftConfig = toTrendingMetricDraftConfig({
    // Satisfy the type TrendingMetricConfig, this value is overwritten in the return
    id: 1,
    load_test_id: testId,
    ...DEFAULT_TRENDING_METRIC_DRAFT_CONFIG,
  })

  return { ...newDraftConfig, id: nanoid() }
}

export const isNewTrendingMetricDraftConfig = (
  draftConfig: TrendingMetricDraftConfig
) => typeof draftConfig.id === 'string'

export const toValidTrendingMetricsDraftConfigs = (
  trendingMetricDraftConfigs: TrendingMetricDraftConfig[]
): ValidTrendingMetricDraftConfig[] => {
  const validationErrors = getTrendingMetricDraftConfigsErrors(
    trendingMetricDraftConfigs
  )
  return validationErrors.length > 0
    ? []
    : (trendingMetricDraftConfigs as ValidTrendingMetricDraftConfig[])
}

export const toTrendingMetricConfigPayload = (
  { tags, id, metric, type, method }: ValidTrendingMetricDraftConfig,
  testId: number
): TrendingMetricConfig => ({
  id: Number(id),
  metric_name: metric,
  metric_type: type,
  labels_selector: toSerializedDraftTags(tags),
  aggregation_function: method,
  load_test_id: testId,
})

export const getTrendingMetricDraftConfigErrors = ({
  id,
  tags,
  metric,
  method,
}: TrendingMetricDraftConfig): TrendingMetricDraftConfigError | undefined => {
  const invalidTagFilterDrafts = tags.filter(
    (tagFilterDraft) => !isValidTagFilterDraft(tagFilterDraft)
  )

  if (
    metric?.length !== 0 &&
    method?.length !== 0 &&
    invalidTagFilterDrafts.length === 0
  ) {
    return undefined
  }

  return {
    id,
    metric: !metric?.length,
    method: !method?.length,
    tags: tagFilterDraftErrors(tags),
  }
}

export const getTrendingMetricDraftConfigsErrors = (
  draftConfigs: TrendingMetricDraftConfig[]
) => {
  const draftConfigsErrors: TrendingMetricDraftConfigError[] = []

  draftConfigs.forEach((draftConfig) => {
    const errors = getTrendingMetricDraftConfigErrors(draftConfig)
    if (!errors) {
      return
    }

    draftConfigsErrors.push(errors)
  })
  return draftConfigsErrors
}

export const getUpdatedValidationErrors = (
  newDraftConfig: TrendingMetricDraftConfig,
  oldDraftConfigError: TrendingMetricDraftConfigError
): TrendingMetricDraftConfigError | undefined => {
  const newDraftConfigErrors =
    getTrendingMetricDraftConfigErrors(newDraftConfig)

  // Otherwise if there is a previous tag error, all new added tag rows will have errors as well
  const updatedTagsErrors = newDraftConfigErrors?.tags?.filter((newTagError) =>
    oldDraftConfigError?.tags?.find(({ id }) => id === newTagError.id)
  )

  return (
    newDraftConfigErrors && {
      ...oldDraftConfigError,
      ...{ ...newDraftConfigErrors, tags: updatedTagsErrors },
    }
  )
}

const isDraftEqualToOriginalConfig = (
  { tags, method, metric }: TrendingMetricDraftConfig,
  originalConfig: TrendingMetricConfig
) => {
  return (
    originalConfig.aggregation_function === method &&
    originalConfig.metric_name === metric &&
    originalConfig.labels_selector === toSerializedDraftTags(tags)
  )
}

const areDraftConfigsEqual = (
  draftConfig1: TrendingMetricDraftConfig,
  draftConfig2: TrendingMetricDraftConfig
) => {
  return (
    draftConfig1.method === draftConfig2.method &&
    draftConfig1.metric === draftConfig2.metric &&
    toSerializedDraftTags(draftConfig1.tags) ===
      toSerializedDraftTags(draftConfig2.tags)
  )
}

export const toActionCategories = (
  originalConfigs: TrendingMetricConfig[],
  draftConfigs: TrendingMetricDraftConfig[]
) => {
  const toUpdate: TrendingMetricDraftConfig[] = []
  const toDelete: TrendingMetricDraftConfig[] = []
  const toCreate: TrendingMetricDraftConfig[] = []

  draftConfigs.forEach((draftConfig) => {
    const existingOriginalConfig = originalConfigs.find(
      (originalConfig) => draftConfig.id === originalConfig.id
    )

    if (
      existingOriginalConfig &&
      !isDraftEqualToOriginalConfig(draftConfig, existingOriginalConfig)
    ) {
      toUpdate.push(draftConfig)
      return
    }

    // The original configs that are not present in the draft have a number id
    if (typeof draftConfig.id === 'number' && !existingOriginalConfig) {
      toDelete.push(draftConfig)
      return
    }

    // All newly added configs will have a nanoid()
    if (isNewTrendingMetricDraftConfig(draftConfig)) {
      toCreate.push(draftConfig)
    }
  })

  return {
    toUpdate,
    toDelete,
    toCreate,
  }
}

export const areOriginalConfigsChanged = (
  originalConfigs: TrendingMetricConfig[],
  draftConfigs: TrendingMetricDraftConfig[]
) => {
  const { toUpdate, toDelete, toCreate } = toActionCategories(
    originalConfigs,
    draftConfigs
  )
  return toUpdate.length > 0 || toDelete.length > 0 || toCreate.length > 0
}

export const getPositionOfFirstDraftDuplicate = (
  draftConfig: TrendingMetricDraftConfig,
  draftConfigs: TrendingMetricDraftConfig[]
): number | undefined => {
  const allDuplicates = draftConfigs.filter((config) =>
    areDraftConfigsEqual(draftConfig, config)
  )

  // No need to go further if the config is unique
  if (
    allDuplicates.length === 1 ||
    allDuplicates[0]?.id === draftConfig.id ||
    draftConfig.metric === '' ||
    (draftConfig.method as string) === ''
  ) {
    return
  }

  const originalDraftConfigIndex = allDuplicates.findIndex(
    (config) => allDuplicates[0]?.id !== config.id
  )
  return originalDraftConfigIndex > -1 ? originalDraftConfigIndex : undefined
}

export const canResetTrendingMetrics = (
  metricConfigs: TrendingMetricConfig[]
) => {
  let defaultTestMetric = metricConfigs[0]

  return (
    defaultTestMetric &&
    (defaultTestMetric.metric_name !==
      DEFAULT_TRENDING_METRIC_CONFIG.metric_name ||
      defaultTestMetric.labels_selector !==
        DEFAULT_TRENDING_METRIC_CONFIG.labels_selector ||
      defaultTestMetric.aggregation_function !==
        DEFAULT_TRENDING_METRIC_CONFIG.aggregation_function)
  )
}

// In case the selected metric is not emitted as an option after a script change
// or the default metric is not part of the emitted metrics, but is in fact a known metric,
// we still pass it down as a custom option but with a humanized label
export const getOptionsWithKnownNonEmittedMetrics = (
  trendingMetricOptions: Array<GroupBase<KnownOption<MetricOption>>>,
  metric?: string
): Array<GroupBase<KnownOption<MetricOption> | CustomOption>> => {
  const existingTrendingMetricOption = trendingMetricOptions.some((option) =>
    option.options.some((metricOption) => metricOption.value === metric)
  )

  if (existingTrendingMetricOption || !metric) {
    return trendingMetricOptions
  }

  const { label } = toMetricOption({ name: metric })
  const nonEmittedVariableOption: CustomOption = {
    type: 'custom',
    value: metric,
    name: label,
  }

  return [
    { label: 'Custom', options: [nonEmittedVariableOption] },
    ...trendingMetricOptions,
  ]
}
