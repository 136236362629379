import React from 'react'
import { Link } from 'react-router-dom'

import { BrowserMetricSummary } from 'types'
import { routeMap } from 'routeMap'
import { DocLink, docs } from 'components/DocLink'
import { Grid } from 'components/Grid'

import { BROWSER_CONTAINER_ID } from '../../../../../NewTest/BrowserDiscoverability'
import { hasBrowserMetrics } from '../../../Actions/Buttons.utils'
import { WebVitalOverview } from './WebVitalOverview'
import { Container, NoMetricsContainer } from './BrowserSummary.styled'
import { useCurrentProject } from 'projectContext'

interface BrowserSummaryProps {
  left: BrowserMetricSummary
  right: BrowserMetricSummary | null | undefined
}

export function BrowserSummary({ left, right }: BrowserSummaryProps) {
  const project = useCurrentProject()
  const hasMetrics = hasBrowserMetrics({ browser_metric_summary: left })

  return (
    <Container>
      <Grid gap={3}>
        <Grid.Column xs={6} sm={4} lg={2}>
          <WebVitalOverview
            name="lcp"
            left={left}
            right={right}
            disabled={!hasMetrics}
          />
        </Grid.Column>
        <Grid.Column xs={6} sm={4} lg={2}>
          <WebVitalOverview
            name="fid"
            left={left}
            right={right}
            disabled={!hasMetrics}
          />
        </Grid.Column>
        <Grid.Column xs={6} sm={4} lg={2}>
          <WebVitalOverview
            name="cls"
            left={left}
            right={right}
            disabled={!hasMetrics}
          />
        </Grid.Column>
        <Grid.Column xs={6} sm={4} lg={2}>
          <WebVitalOverview
            name="fcp"
            left={left}
            right={right}
            disabled={!hasMetrics}
          />
        </Grid.Column>
        <Grid.Column xs={6} sm={4} lg={2}>
          <WebVitalOverview
            name="inp"
            left={left}
            right={right}
            disabled={!hasMetrics}
          />
        </Grid.Column>
        <Grid.Column xs={6} sm={4} lg={2}>
          <WebVitalOverview
            name="ttfb"
            left={left}
            right={right}
            disabled={!hasMetrics}
          />
        </Grid.Column>
      </Grid>

      {!hasMetrics && (
        <NoMetricsContainer>
          To get started with the k6 browser module, start with our{' '}
          <Link to={`${routeMap.newTest(project.id)}#${BROWSER_CONTAINER_ID}`}>
            script example
          </Link>{' '}
          or check out our{' '}
          <DocLink article={docs.browser.runningBrowserTests}>
            documentation
          </DocLink>{' '}
          guide and our{' '}
          <DocLink article={docs.browser.api}>API documentation</DocLink>.
        </NoMetricsContainer>
      )}
    </Container>
  )
}
