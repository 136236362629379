import React, { useMemo } from 'react'
import styled from 'styled-components'

import { TestRun } from 'types'
import { spacing } from 'utils/styled'
import { MetaBar } from 'components/MetaBar'

import { getTestRunMetaData } from './TestRunMetaBar.utils'

interface MetaItemsProps {
  testRun: TestRun
  alignEnd?: boolean
}

export const MetaItems = ({ testRun, alignEnd }: MetaItemsProps) => {
  const metaData = useMemo(() => {
    return getTestRunMetaData(testRun)
  }, [testRun])

  return (
    <MetaBar.Section alignEnd={alignEnd}>
      <MetaBar.Item.Status testRun={testRun} />
      <MetaBar.Item.Vuh value={metaData.vuh} />
      <MetaBar.Item.Vus value={metaData.vus} />
      <MetaBar.Item.ExecutedDuration value={metaData.executionDuration} />
      <MetaBar.Item.LoadZones testRun={testRun} />
    </MetaBar.Section>
  )
}

interface CompareMetaBarProps {
  left: TestRun
  right: TestRun
}

export const CompareMetaBar = ({ left, right }: CompareMetaBarProps) => {
  return (
    <CompareMetaBarWrapper>
      <MetaBar>
        <MetaItems testRun={left} />
        <MetaItems testRun={right} alignEnd />
      </MetaBar>
    </CompareMetaBarWrapper>
  )
}

const CompareMetaBarWrapper = styled.div`
  padding: ${spacing(0, 0, 2, 0)};
`
