import React from 'react'
import { VerticalGroup } from '@grafana/ui'

import { Project, TestRunStats } from 'types'
import { CLIWrapper } from './NewTestCLIGuide.styles'
import { TabbedCLIGuide } from './TabbedCLIGuide'

interface NewTestCLIGuideProps {
  project: Project
  stats?: TestRunStats
}

export const NewTestCLIGuide = ({ project, stats }: NewTestCLIGuideProps) => {
  return (
    <CLIWrapper>
      <VerticalGroup align="center" spacing="none">
        <h3>Run a test from CLI</h3>
        <p>
          Download k6 and and trigger local or cloud executed tests from the
          command line.
        </p>
      </VerticalGroup>
      <TabbedCLIGuide project={project} stats={stats} />
    </CLIWrapper>
  )
}
