import React from 'react'
import { Container, Count, StyledProgressBar } from './VUhConsumption.styles'
import { Flex } from 'components/Flex'
import { spacing } from 'utils/styled'
import { isVUhQuotaReached, isVUhUsageAboveThreshold } from 'utils/VUh'

interface VUhConsumptionMeterProps {
  value?: number
  limit?: number | null
  label: string
  action: React.ReactNode
}

function getProgressBarColor(
  isAboveThreshold: boolean,
  isQuotaReached: boolean
) {
  if (isQuotaReached) {
    return 'red'
  }
  if (isAboveThreshold) {
    return 'orange'
  }
  return 'grey'
}

export function VUhConsumptionMeter({
  value,
  limit,
  label,
  action,
}: VUhConsumptionMeterProps) {
  const isAboveThreshold = isVUhUsageAboveThreshold(value, limit)
  const isQuotaReached = isVUhQuotaReached(value, limit)

  return (
    <Container>
      <Flex align="center" gap={1} justify="flex-end">
        <Count $error={isQuotaReached} $warning={isAboveThreshold}>
          {value ?? 0}/{limit ?? '-'} {label}
        </Count>
        {action}
      </Flex>
      <StyledProgressBar
        value={value ?? 0}
        max={limit ?? 0}
        color={getProgressBarColor(isAboveThreshold, isQuotaReached)}
        direction="horizontal"
        isWorking={false}
        height={`${spacing(0.5)}`}
      />
    </Container>
  )
}
