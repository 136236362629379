import styled from 'styled-components'
import { spacing } from 'utils/styled'

export const StyledBrowserDiscoverability = styled.div`
  padding: ${spacing(2)};
  width: 100%;
`

export const CopyContainer = styled.div`
  text-align: center;
`

export const Heading = styled.h2`
  display: inline-flex;
  align-items: center;
  gap: ${spacing(1)};
`
