import { TestRun } from 'types'
import { ExportDataStatus } from 'constants/tests'

export const getTestRunExportStatusText = (testRun: TestRun) => {
  return (
    (testRun.export?.export_status &&
      ExportDataStatus[testRun.export.export_status]) ||
    ''
  )
}
