import React, { useState } from 'react'

import { Button } from 'components/Button'
import { CreateScheduledTestModal } from 'components/ScheduledTests/ScheduledTestsModal'

export const CreateScheduleButton = ({ disabled }: { disabled: boolean }) => {
  const [isCreateScheduleModalOpen, setIsCreateScheduleModalOpen] =
    useState(false)

  return (
    <>
      <CreateScheduledTestModal
        isOpen={isCreateScheduleModalOpen}
        onDismiss={() => setIsCreateScheduleModalOpen(false)}
      />
      <Button
        disabled={disabled}
        onClick={() => setIsCreateScheduleModalOpen(true)}
      >
        Add schedule
      </Button>
    </>
  )
}
