import React, { ReactNode } from 'react'
import { Badge, BadgeProps } from '@grafana/ui'
import styled from 'styled-components'

const StyledLink = styled.a`
  display: inline-flex;

  &:hover {
    text-decoration: none;
  }
`

interface ProtectedTitleProps {
  title: ReactNode
  hasPermissions: boolean
  color?: BadgeProps['color']
  badge?: string
  badgeHref?: string
  tooltip?: string
}

interface BadgeWrapperProps {
  children: ReactNode
  isVisible: boolean
  href?: string
}

const BadgeWrapper = ({ children, href, isVisible }: BadgeWrapperProps) => {
  if (!isVisible) {
    return null
  }

  if (href) {
    return (
      <StyledLink href={href} target="_blank" rel="noreferrer">
        {children}
      </StyledLink>
    )
  }

  return <>{children}</>
}

export const ProtectedTitle = ({
  title,
  hasPermissions,
  color = 'orange',
  badge = 'Insufficient privileges',
  tooltip = 'This action requires an organization admin role.',
  badgeHref,
}: ProtectedTitleProps) => {
  return (
    <>
      {title}
      <BadgeWrapper href={badgeHref} isVisible={!hasPermissions}>
        <Badge
          text={badge}
          color={color}
          icon="gf-service-account"
          tooltip={tooltip}
        />
      </BadgeWrapper>
    </>
  )
}
