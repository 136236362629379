import React from 'react'

import { DocLink, docs } from 'components/DocLink'
import { isTestActive } from 'utils/testRun'
import {
  BreakdownTabEmptyView,
  BreakdownTabMessage,
  Content,
} from '../Breakdown.styles'
import { EmptyProps } from '../BreakdownTable'
import { useIsDarkTheme } from 'hooks/useIsDarkTheme'
import { ApplicationWindow } from 'components/ApplicationWindow'
import { ContainedImage } from 'pages/InitPage/InitPage.styled'
import tracesScreenshot from './assets/traces.png'
import tracesScreenshot2x from './assets/traces@2x.png'
import tracesScreenshotLight from './assets/traces-light.png'
import tracesScreenshotLight2x from './assets/traces-light@2x.png'

export function TracesEmptyView({ testRun }: EmptyProps<{}>) {
  const isDark = useIsDarkTheme()
  const tracesScreenshotSrc = isDark ? tracesScreenshot : tracesScreenshotLight

  const tracesScreenshotSrcSet = `${
    isDark ? tracesScreenshot2x : tracesScreenshotLight2x
  } 2x`

  if (isTestActive(testRun)) {
    return (
      <BreakdownTabMessage>Waiting for tracing data...</BreakdownTabMessage>
    )
  }

  return (
    <BreakdownTabEmptyView>
      <Content style={{ flexBasis: '650px' }}>
        <div>
          <h2>Traces</h2>
          <p>
            The k6 integration with Grafana Cloud Traces can help you dive
            deeper into the inner workings of your services during a test run.
            k6 aggregates and correlates all the tracing telemetry your services
            generate during a test, summarizes it, and gives you the tools to
            analyze and extract the insights you need.
          </p>

          <h3>How it works</h3>
          <p>
            Once you configure the integration, k6 test data is combined with
            the specific traces propagated from your test, and the aggregated
            metrics are displayed in this tab. You can then explore the results
            in a consolidated view, speeding up the time it takes to investigate
            the root cause of issues in your services.
          </p>

          <h3>Get started</h3>
          <p>To use this integration, you need to:</p>
          <ol>
            <li>
              Send your services&apos; tracing data to Grafana Cloud Traces.
            </li>
            <li>Enable the tracing feature in your k6 test.</li>
          </ol>
          <p>
            For more details, refer to the{' '}
            <DocLink article={docs.analyze.tracesIntegration}>docs</DocLink>.
          </p>
        </div>
      </Content>
      <Content style={{ flexBasis: '650px' }}>
        <ApplicationWindow>
          <ContainedImage
            src={tracesScreenshotSrc}
            srcSet={tracesScreenshotSrcSet}
            alt="View traces"
          />
        </ApplicationWindow>
      </Content>
    </BreakdownTabEmptyView>
  )
}
