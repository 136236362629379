import { type NodePath, propertyNode } from 'pages/Editor/utils/script/nodePath'
import { parseDuration } from 'utils/testScripts/parseDuration'
import {
  NEVER,
  ZodIssueCode,
  ZodSchema,
  ZodTypeDef,
  array,
  literal,
  number,
  object,
  string,
  symbol,
  type infer as Infer,
  union,
} from 'zod'

function isNodePathSegment(
  value: string | number | symbol
): value is NodePath[0] {
  return typeof value !== 'symbol' || value === propertyNode
}

const NodePathSegmentSchema = union([string(), number(), symbol()]).refine(
  isNodePathSegment,
  'Node path segment must be a string, number or the propertyNode-symbol.'
)

export const NodePathSchema = array(NodePathSegmentSchema)

export const InvalidJsonSchema = object({
  type: literal('invalid-json'),
  value: string(),
})

export type InvalidJson = Infer<typeof InvalidJsonSchema>

/**
 * Takes a string property, parses it as JSON and then validates it against the given schema.
 */
export function json<
  Output = any,
  Def extends ZodTypeDef = ZodTypeDef,
  Input = Output
>(
  schema: ZodSchema<Output, Def, Input>,
  message = 'Expected value to be valid JSON.'
) {
  return string()
    .transform((value, context) => {
      try {
        return JSON.parse(value)
      } catch (error) {
        const params: InvalidJson = {
          type: 'invalid-json',
          value,
        }

        context.addIssue({
          code: ZodIssueCode.custom,
          message,
          params,
          fatal: true,
        })

        return NEVER
      }
    })
    .pipe(schema)
}

export const InvalidDurationSchema = object({
  type: literal('invalid-duration'),
  duration: string(),
  path: NodePathSchema,
})

export type InvalidDuration = Infer<typeof InvalidDurationSchema>

export const Duration = string().superRefine((value, context) => {
  if (parseDuration(value).success) {
    return
  }

  const params: InvalidDuration = {
    type: 'invalid-duration',
    duration: value,
    path: context.path,
  }

  context.addIssue({
    code: ZodIssueCode.custom,
    message: `Unable to parse duration from "${value}".`,
    params,
  })
})

export const VUs = number().int().gte(1)

export const StageSchema = object({
  duration: Duration,
  target: number().int().gte(0),
})

export type Stage = Infer<typeof StageSchema>
