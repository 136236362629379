import React, { useMemo } from 'react'

import { GrpcUrl, TestRun, TestRunGRPCFilterBy } from 'types'
import { GRPC_ERROR_STATUSES } from 'constants/grpcStatusCodes'
import { RunDetailsFilters } from '../../Filters/Filters'
import { useURLFilters } from '../../Filters/Filters.hooks'

interface GRPCFiltersProps extends ReturnType<typeof useURLFilters<GrpcUrl>> {
  run: TestRun
}

export const GrpcUrlsFilters = ({
  addFilterValue,
  addNewFilter,
  filters,
  options,
  removeAllFilters,
  removeFilter,
  replaceFilters,
  run,
  updateSortBy,
}: GRPCFiltersProps) => {
  const presets = useMemo(
    () => [
      {
        label: 'Only URLs with error status',
        value: 'error-status',
        onChange: () => {
          replaceFilters([
            {
              by: TestRunGRPCFilterBy.Status,
              label: 'Status',
              values: GRPC_ERROR_STATUSES as unknown as string[],
            },
          ])
          updateSortBy({
            sortBy: 'grpc_metric_summary/requests_count',
            direction: 'desc',
          })
        },
      },
      {
        label: 'Order all by slowest response time',
        value: 'response-time',
        onChange: () => {
          removeAllFilters()
          updateSortBy({
            sortBy: 'grpc_metric_summary/duration/p95',
            direction: 'desc',
          })
        },
      },
    ],
    [removeAllFilters, replaceFilters, updateSortBy]
  )

  return (
    <RunDetailsFilters
      filters={filters}
      options={options}
      run={run}
      onClearFiltersClick={removeAllFilters}
      onAddNewFilterClick={addNewFilter}
      onRemoveFilterClick={removeFilter}
      onAddFilterValue={addFilterValue}
      presets={presets}
    />
  )
}
