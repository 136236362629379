import React from 'react'

import { Value } from './Value'
import { useSubscriptionRule } from 'data/useSubscriptions'
import { ProjectSettings } from 'types'
import { timeFormatterShort } from 'utils/formatters'

interface DurationMaxPerTestValueProps {
  limit?: ProjectSettings['duration_max_per_test']
}

export function DurationMaxPerTestValue({
  limit,
}: DurationMaxPerTestValueProps) {
  const subscriptionDurationMaxPerTest =
    useSubscriptionRule('tests.duration.max')
  const subscriptionDurationMaxPerTestInSeconds =
    subscriptionDurationMaxPerTest * 60

  const showWarning = !!limit && limit > subscriptionDurationMaxPerTestInSeconds
  const tooltip = showWarning ? (
    <>
      This limit is higher than what your active subscription allows.
      <br />
      The subscription limit (
      {timeFormatterShort(subscriptionDurationMaxPerTestInSeconds)}) will take
      precedence.
    </>
  ) : undefined

  return (
    <Value showWarning={showWarning} tooltip={tooltip}>
      {!limit ? '-' : timeFormatterShort(limit)}
    </Value>
  )
}
