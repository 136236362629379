import React from 'react'

import { exhaustive } from 'utils/typescript'
import { Protocol } from '../RunOverview.types'
import { GrpcSummary } from './GrpcSummary'
import { HttpSummary } from './HttpSummary'
import { WebSocketsSummary } from './WebSocketSummary'
import { BrowserSummary } from './BrowserSummary'

interface ProtocolSummaryProps {
  protocol: Protocol | undefined
}

export const ProtocolSummary = ({ protocol }: ProtocolSummaryProps) => {
  if (protocol === undefined) {
    return null
  }

  switch (protocol.type) {
    case 'http':
      return <HttpSummary left={protocol.left} right={protocol.right} />

    case 'ws':
      return <WebSocketsSummary left={protocol.left} right={protocol.right} />

    case 'grpc':
      return <GrpcSummary left={protocol.left} right={protocol.right} />

    case 'browser':
      return <BrowserSummary left={protocol.left} right={protocol.right} />

    default:
      return exhaustive(protocol)
  }
}
