import React from 'react'

import { getGrafanaUrl } from 'utils/env'

import { Section } from '../components/Section'
import { LearnMoreLink } from '../components/LearnMoreLink'
import { Flex } from 'components/Flex'

export function BillingSection() {
  const link = getGrafanaUrl('/pricing')

  return (
    <Section direction="column">
      <Flex direction="column" shrink={1} grow={0}>
        <Section.Heading $size="s">How billing works</Section.Heading>
        <Section.Preamble>
          Grafana Cloud k6 is available to all Grafana Cloud customers, no
          matter which plan you have. We bill you based on virtual user hours.
        </Section.Preamble>
        <div>
          <LearnMoreLink text="Read more about billing" href={link} />
        </div>
      </Flex>
    </Section>
  )
}
