import { getBackendSrv } from '@grafana/runtime'
import { useQuery } from '@tanstack/react-query'
import { Project } from 'types'
import { toProjectFolderDescendantsQueryKey } from './queryKeys'

export enum ResourceNames {
  // cspell:disable-next-line
  alertrule = 'alert rule',
  dashboard = 'dashboard',
  folder = 'folder',
  // cspell:disable-next-line

  librarypanel = 'library panel',
}

export type FolderDescendantCounts = {
  [key in keyof typeof ResourceNames]: number
}

export function useFolderDescendantCounts(
  project: Project,
  options?: { enabled?: boolean }
) {
  return useQuery({
    queryFn: () =>
      getBackendSrv().get<FolderDescendantCounts>(
        `api/folders/${project.grafana_folder_uid}/counts`
      ),
    queryKey: toProjectFolderDescendantsQueryKey(project.id),
    enabled: !!project.grafana_folder_uid && (options?.enabled ?? true),
  })
}
