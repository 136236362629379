import React from 'react'
import styled from 'styled-components'

import { ProjectLimitsRow } from './LimitsTable.types'
import { UsageReportsProjectName } from 'pages/ManagePage/tabs/UsageReports/components/UsageReportsProjectName'
import {
  DurationMaxPerTestValue,
  VUMaxPerTestValue,
  VUhMaxPerMonthValue,
  VUhUsageValue,
} from 'components/ProjectLimitsFormattedValues'
import { toPrecise } from 'utils/math'

export function VUhMaxPerMonthCell({
  vuh_max_per_month,
  summary,
}: ProjectLimitsRow) {
  if (summary) {
    return (
      <SummaryCell>
        <VUhMaxPerMonthValue limit={vuh_max_per_month} />
      </SummaryCell>
    )
  }

  return <VUhMaxPerMonthValue limit={vuh_max_per_month} />
}

export function ProjectNameCell({
  project_name,
  project_id,
  summary,
  deleted,
}: ProjectLimitsRow) {
  if (summary) {
    return <SummaryCell>Sum of VUh per month</SummaryCell>
  }

  return (
    <UsageReportsProjectName
      deleted={deleted!!}
      projectName={project_name}
      projectId={project_id}
    />
  )
}

export function VUMaxPerTestCell({
  vu_max_per_test,
  summary,
}: ProjectLimitsRow) {
  if (summary) {
    return null
  }

  return <VUMaxPerTestValue limit={vu_max_per_test} />
}

export function VUhUsageCell({
  vuh_max_per_month,
  vuh_usage,
  summary,
}: ProjectLimitsRow) {
  if (summary) {
    return <SummaryCell>{toPrecise(vuh_usage, 2)}</SummaryCell>
  }

  return <VUhUsageValue limit={vuh_max_per_month} value={vuh_usage} />
}

export function DurationMaxPerTestCell({
  duration_max_per_test,
  summary,
}: ProjectLimitsRow) {
  if (summary) {
    return null
  }

  return <DurationMaxPerTestValue limit={duration_max_per_test} />
}

const SummaryCell = styled.span`
  font-weight: 700;
`
