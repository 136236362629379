import { NewTest } from 'pages/NewTest'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Project } from 'types'
import { NotFound } from 'pages/NotFound'
import { getRoutePath } from 'routeMap'
import { NewTestScript } from '../pages/Editor/NewTestScriptPage'
import { NewBuilderTest } from '../pages/Editor/NewBuilderTestPage'
import { EditTest } from '../pages/Editor/EditTestPage'

interface TestRouteProps {
  project: Project
}

export function TestRoutes({ project }: TestRouteProps) {
  return (
    <Switch>
      <Route exact path={getRoutePath('newTest')}>
        <NewTest />
      </Route>
      <Route path={getRoutePath('newScript')}>
        <NewTestScript project={project} />
      </Route>
      <Route path={getRoutePath('newBuilderTest')}>
        <NewBuilderTest project={project} />
      </Route>
      <Route path={getRoutePath('editTest')}>
        <EditTest project={project} />
      </Route>
      {/* This is where we would handle '/projects/:projectId/tests/:testId'... */}
      <NotFound />
    </Switch>
  )
}
