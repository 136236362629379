import { useQuery } from '@tanstack/react-query'
import { Project } from 'types'
import { toProjectFolderAssignments } from './queryKeys'
import { getBackendSrv } from '@grafana/runtime'
import { ResourcePermission } from 'types/rbac'

export function useFolderAssignments(project: Project) {
  return useQuery({
    queryFn: () =>
      getBackendSrv().get<ResourcePermission[]>(
        `api/access-control/folders/${project.grafana_folder_uid}`
      ),
    queryKey: toProjectFolderAssignments(project.id),
    keepPreviousData: true,
  })
}
