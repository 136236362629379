import { UseQueryResult } from '@tanstack/react-query'
import { colorManipulator } from '@grafana/data'

import { TestRun } from 'types'
import {
  MetricColor,
  MetricConfig,
  MetricData,
  Plot,
  PlotType,
} from 'datasource/models'
import { useStreamingQueries } from 'hooks/useStreamingQuery'
import { toTimestamp } from 'utils/date'
import { ColorPool } from 'utils/colorPool'
import { useMetricClient } from 'data/clients/metrics/metrics'

export function getStartTime(testRun: TestRun) {
  return toTimestamp(testRun.started ?? testRun.created)
}

function toAlternatePlot(plot: Plot): Plot {
  if (plot.type !== PlotType.Line) {
    return plot
  }

  return {
    type: PlotType.Line,
    style: 'dashed',
  }
}

function toAlternateColor(
  color: MetricColor | undefined
): MetricColor | undefined {
  return color && (colorManipulator.emphasize(color, 0.5) as MetricColor)
}

interface FormattedMetric {
  testRun: TestRun
  started: number
  metric: MetricConfig
}

export function formatMetrics(
  left: TestRun,
  right: TestRun | undefined,
  metrics: MetricConfig[],
  colorPool: ColorPool
): FormattedMetric[] {
  const coloredMetrics = metrics.map((metric) => {
    const color = metric.color || colorPool.find(metric.preferredColor)

    return {
      ...metric,
      color,
    }
  })

  const leftStarted = getStartTime(left)

  if (right === undefined) {
    return coloredMetrics.map((metric) => ({
      testRun: left,
      started: leftStarted,
      metric,
    }))
  }

  const rightStarted = getStartTime(right)

  return coloredMetrics.flatMap((metric) => [
    {
      testRun: left,
      started: leftStarted,
      metric: { ...metric, label: `${metric.label} (left)` },
    },
    {
      testRun: right,
      started: rightStarted,
      metric: {
        ...metric,
        label: `${metric.label} (right)`,
        plot: toAlternatePlot(metric.plot),
        color: toAlternateColor(metric.color),
      },
    },
  ])
}

interface UseTimeSeriesArgs {
  left: TestRun
  right?: TestRun
  timestamp: 'absolute' | 'relative'
  metrics: MetricConfig[]
  enabled?: boolean
  colorPool: ColorPool
}

export function useTimeSeries({
  left,
  right,
  timestamp,
  metrics,
  enabled = true,
  colorPool,
}: UseTimeSeriesArgs): Array<UseQueryResult<MetricData>> {
  const client = useMetricClient()
  const leftStarted = getStartTime(left)

  return useStreamingQueries(left, {
    queries: formatMetrics(left, right, metrics, colorPool).map(
      ({ testRun, started, metric }) => ({
        enabled,

        queryKey: ['time-series', testRun.id, metric.query],
        queryFn: async () => {
          const timeSeries = await client.queryRange(testRun.id, metric)

          return timeSeries?.[0] ?? null
        },
        placeholderData: () => null,
        select(data: MetricData | null) {
          if (!data || data.data === null) {
            return { ...metric, data: null }
          }

          const offset = timestamp === 'relative' ? started - leftStarted : 0

          const values = data.data.values.map(({ timestamp, value }) => ({
            timestamp: Number(timestamp) - offset,
            value,
          }))

          return {
            ...metric,
            data: {
              ...data,
              values,
            },
          }
        },
      })
    ),
  })
}
