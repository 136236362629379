import { useMemo } from 'react'

import { type TestRun } from 'types'
import {
  useInsightsAuditsResults,
  useInsightsLatestExecution,
} from 'data/useCloudInsights'

export const useAuditResult = (id: string, testRun: TestRun) => {
  const { data: execution } = useInsightsLatestExecution(testRun)
  const { data: auditResults = [] } = useInsightsAuditsResults(
    testRun,
    execution
  )

  const results = useMemo(() => {
    return auditResults.find((result) => result.audit_id === id)
  }, [auditResults, id])

  return results
}
