import React, { useRef, useCallback } from 'react'
import {
  DatePickerWithInput,
  Field,
  HorizontalGroup,
  Input,
  Label,
  Select,
} from '@grafana/ui'
import { Controller, useWatch, useForm } from 'react-hook-form'
import { addMinutes, addMonths, formatISO } from 'date-fns'
import {
  NumericalDays,
  ScheduledTest,
  ScheduledTestPayload,
} from 'types/scheduledTests'
import { resolveTimeZone } from 'utils/date'
import { Flex } from 'components/Flex'
import { Switch } from 'components/Switch'
import { RadioButton } from 'components/RadioButton'
import { Listbox } from 'components/Listbox'
import { addPlural } from 'utils'
import { ScheduledTestFormProps } from './ScheduledTests.types'
import {
  Description,
  Divider,
  RadioButtonWrapper,
  StyledField,
} from './ScheduledTestsForm.styles'
import { DatePickerControl } from './DatePickerControl'
import styled from 'styled-components'

// This is a workaround for the z-index issue with the date picker
const DatePickerWithInputWorkaround = styled(DatePickerWithInput)`
  & + div {
    z-index: 2;
  }
`

export const ScheduledTestsForm = ({
  formActions,
  onSubmit,
  scheduledTest,
  testId,
  projectName,
  testName,
}: ScheduledTestFormProps) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm<ScheduledTestPayload>({
    defaultValues: getInitialValues(scheduledTest, testId),
  })

  const repeatSwitch = useRef<HTMLInputElement>(null)
  const interval = Number(
    useWatch({
      control,
      name: 'interval',
    })
  )

  const frequency = useWatch({
    control,
    name: 'frequency',
  })

  const endsType = useWatch({
    control,
    name: 'ends.type',
  })

  const checked = ![undefined, null, 'never'].includes(frequency)

  const handleRepeatToggle = useCallback(() => {
    setValue(
      'frequency',
      checked ? 'never' : scheduledTest?.frequency || 'daily'
    )
  }, [setValue, checked, scheduledTest?.frequency])

  const startsError = errors.starts
  const endsDatetimeError = errors.ends?.datetime
  const endsOccurrencesError = errors.ends?.occurrences
  const intervalError = errors.interval

  return (
    <>
      <Description>{`${projectName} > ${testName}`}</Description>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" gap={0.5}>
          <Field
            label={`Schedule starts (Local time ${resolveTimeZone()})`}
            error={startsError?.message}
            invalid={!!startsError}
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Please select a start date',
                },
              }}
              render={({ field }) => <DatePickerControl {...field} />}
              control={control}
              name="starts"
            />
          </Field>
          <Switch
            aria-label="Should the test repeat?"
            value={checked}
            onChange={handleRepeatToggle}
            offLabel="Don't repeat"
            onLabel="Repeat"
            ref={repeatSwitch}
          />
        </Flex>
        {checked && (
          <>
            <Divider />
            <Flex direction="column" gap={2}>
              <Flex direction="column" gap={0.5}>
                <Label>Repeat every</Label>
                <Field error={intervalError?.message} invalid={!!intervalError}>
                  <HorizontalGroup>
                    <Input
                      {...register('interval', {
                        required: {
                          value: true,
                          message: 'Please add an interval',
                        },
                      })}
                      type="number"
                      min="1"
                      id="interval"
                      width={7}
                      aria-label="Select interval"
                    />
                    <Controller
                      render={({ field }) => {
                        const { onChange, ...rest } = field

                        return (
                          <Select
                            {...rest}
                            aria-label="Select frequency"
                            onChange={(val) => {
                              onChange(val.value)

                              if (!val.value) {
                                repeatSwitch.current?.focus()
                              }
                            }}
                            options={[
                              { label: "Don't repeat", value: null },
                              {
                                label: addPlural('Day', interval),
                                value: 'daily',
                              },
                              {
                                label: addPlural('Month', interval),
                                value: 'monthly',
                              },
                              {
                                label: addPlural('Week', interval),
                                value: 'weekly',
                              },
                              {
                                label: addPlural('Hour', interval),
                                value: 'hourly',
                              },
                            ]}
                            tabSelectsValue={false}
                          />
                        )
                      }}
                      control={control}
                      name="frequency"
                    />
                  </HorizontalGroup>
                </Field>
              </Flex>
              {frequency === 'weekly' && (
                <Flex direction="column" gap={0.5}>
                  <Label id="weekdays">Repeat on</Label>
                  <Controller
                    render={({ field }) => {
                      return (
                        <Listbox<NumericalDays>
                          {...field}
                          aria-labelledby="weekdays"
                          direction="horizontal"
                          options={[
                            { label: 'Mon', value: 0 },
                            { label: 'Tue', value: 1 },
                            { label: 'Wed', value: 2 },
                            { label: 'Thu', value: 3 },
                            { label: 'Fri', value: 4 },
                            { label: 'Sat', value: 5 },
                            { label: 'Sun', value: 6 },
                          ]}
                          selected={field.value || []}
                          multiselect
                        />
                      )
                    }}
                    control={control}
                    name="weekly.days"
                  />
                </Flex>
              )}
            </Flex>
            <Divider />
            <div>
              <Field
                label="When should the schedule end?"
                role="group"
                aria-label="When should the schedule end?"
              >
                <Flex gap={2} direction="column">
                  <RadioButtonWrapper gap={5}>
                    <RadioButton
                      onChange={() => setValue('ends.type', 'never')}
                      checked={endsType === 'never'}
                      id="never"
                      name="ends"
                      label="Never"
                    />
                  </RadioButtonWrapper>
                  <RadioButtonWrapper gap={5}>
                    <RadioButton
                      onChange={() => setValue('ends.type', 'datetime')}
                      checked={endsType === 'datetime'}
                      id="on"
                      name="ends"
                      label="On"
                    />
                    <Controller
                      rules={{
                        required: {
                          value: endsType === 'datetime',
                          message: 'Please select an end date',
                        },
                      }}
                      render={({ field }) => (
                        <StyledField
                          error={endsDatetimeError?.message}
                          invalid={!!endsDatetimeError}
                        >
                          <DatePickerWithInputWorkaround
                            {...field}
                            value={field.value || undefined}
                            aria-label="Ends after what date"
                            disabled={endsType !== 'datetime'}
                            onChange={(val) => {
                              field.onChange({
                                target: {
                                  value: val || null,
                                },
                              })
                            }}
                          />
                        </StyledField>
                      )}
                      control={control}
                      name="ends.datetime"
                    />
                  </RadioButtonWrapper>
                  <RadioButtonWrapper gap={5}>
                    <RadioButton
                      onChange={() => setValue('ends.type', 'occurrences')}
                      checked={endsType === 'occurrences'}
                      id="after"
                      name="ends"
                      label="After"
                    />
                    <StyledField
                      error={endsOccurrencesError?.message}
                      invalid={!!endsOccurrencesError}
                    >
                      <Input
                        {...register('ends.occurrences', {
                          valueAsNumber: true,
                          required: {
                            value: endsType === 'occurrences',
                            message: 'Please add a number of runs',
                          },
                        })}
                        aria-label="Ends after how many runs"
                        disabled={endsType !== 'occurrences'}
                        suffix="runs"
                        type="number"
                        min={1}
                      />
                    </StyledField>
                  </RadioButtonWrapper>
                </Flex>
              </Field>
            </div>
          </>
        )}
        {formActions}
      </form>
    </>
  )
}

function getInitialValues(
  scheduledTest?: ScheduledTest,
  testId?: number
): ScheduledTestPayload {
  const { active, ends, frequency, interval, starts, weekly, test_id } =
    scheduledTest || {}

  return {
    test_id: test_id || testId!,
    active: active || true,
    ends: {
      datetime: ends?.datetime || formatISO(addMonths(new Date(), 2)),
      occurrences: ends?.occurrences || 10,
      type: ends?.type || 'never',
    },
    frequency: frequency || 'never',
    interval: interval || 1,
    starts: starts || formatISO(addMinutes(new Date(), 5)),
    weekly: weekly || {
      days: [],
    },
  }
}
