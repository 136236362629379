import { useEffect } from 'react'
import { sentryClient } from 'services/sentryClient'
import { SENTRY_DSN } from 'constants/index'
import { useDatasource } from 'datasourceContext'
import { config } from '@grafana/runtime'

export const SetupSentry = ({ pluginVersion }: { pluginVersion: string }) => {
  const { ds } = useDatasource()

  /**
   * Start Sentry
   */
  useEffect(() => {
    sentryClient.configure({
      dsn: SENTRY_DSN,
      version: pluginVersion,
      environment: ds.environment ?? process.env.SENTRY_ENV,
    })

    sentryClient.setContext('grafana', {
      Version: config.buildInfo.version,
      Topnav: true,
    })
  }, [pluginVersion, ds.environment])

  /**
   * Pause Sentry
   */
  useEffect(() => {
    return sentryClient.disable
  }, [])

  return null
}
