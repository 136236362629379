import React, { ChangeEvent } from 'react'
import { Icon, Input } from '@grafana/ui'
import styled from 'styled-components'

interface ProjectSettingsTableSearchProps {
  value: string
  onChange: (value: string) => void
}

export const ProjectRolesTableSearch = ({
  value,
  onChange,
}: ProjectSettingsTableSearchProps) => {
  return (
    <StyledInput
      placeholder="Search users by email"
      prefix={<Icon name="search" />}
      value={value}
      width={40}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
    />
  )
}

const StyledInput = styled(Input)`
  margin-left: auto;
`
