import { isTestFailed, isTestTimedOut } from 'utils/testRun'
import { TestRun } from 'types'
import { InsightsByStatus } from 'types/insights'

export const hasFailed = (testRun: TestRun, insights: InsightsByStatus) => {
  return (
    insights.FAILED.length > 0 ||
    isTestFailed(testRun) ||
    isTestTimedOut(testRun)
  )
}
