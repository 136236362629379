import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'
import { TestRun, TestRunFilter, TestRunFilterWithOptions } from 'types'
import { Button } from 'components/Button'

import { CustomFilter } from './CustomFilter'
import { FilterPicker } from './FilterPicker'
import { PresetFilters } from './PresetFilters'

import { RunDetailsFilterTestId } from 'types/dataTestIds'
import { FilterOption } from './Filters.constants'
import { color, spacing } from 'utils/styled'

interface RunDetailsFiltersProps<T extends TestRunFilterWithOptions> {
  filters: T[]
  options: FilterOption[]
  run: TestRun
  onClearFiltersClick?: () => void
  onRemoveFilterClick: ({ by }: { by: T['by'] }) => void
  onAddFilterValue: ({ by, value }: { by: T['by']; value: string[] }) => void
  onAddNewFilterClick: (filter: Omit<TestRunFilter, 'values'>) => void
  presets?: Array<{ onChange: () => void; label: string }>
}

export function RunDetailsFilters<T extends TestRunFilterWithOptions>({
  filters,
  options,
  run,
  onClearFiltersClick,
  onRemoveFilterClick,
  onAddFilterValue,
  onAddNewFilterClick,
  presets,
}: RunDetailsFiltersProps<T>) {
  const ref = useRef<HTMLDivElement>(null)

  const keepFocus = useCallback(() => {
    requestAnimationFrame(() => {
      ref.current?.querySelector('input')?.focus()
    })
  }, [])

  const handleOnClear = useCallback(() => {
    onClearFiltersClick?.()
    keepFocus()
  }, [keepFocus, onClearFiltersClick])

  const handleOnRemoveFilter = useCallback(
    (by: TestRunFilter['by']) => {
      onRemoveFilterClick({ by })
      keepFocus()
    },
    [keepFocus, onRemoveFilterClick]
  )

  return (
    <FiltersContainer data-testid={RunDetailsFilterTestId.Container}>
      {presets && <PresetFilters options={presets} />}
      {filters.map((filter, index) => (
        <CustomFilter
          key={filter.by}
          filter={filter}
          precedingFilters={filters.slice(0, index)}
          run={run}
          onRemoveButtonClick={handleOnRemoveFilter}
          onAddFilterValue={(value) =>
            onAddFilterValue({ by: filter.by, value })
          }
        />
      ))}

      {options.length > 0 && (
        <div ref={ref}>
          <FilterPicker
            options={options}
            onChange={(value) => {
              onAddNewFilterClick({
                by: value.value as T['by'],
                label: value.label!,
              })
            }}
          />
        </div>
      )}
      {onClearFiltersClick && filters.filter((f) => !f.required).length > 0 && (
        <Button
          icon="trash-alt"
          onClick={handleOnClear}
          size="md"
          variant="secondary"
        >
          Clear filters
        </Button>
      )}
    </FiltersContainer>
  )
}

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${color('background', 'secondary')};

  > div {
    margin-right: ${spacing(2)};
    padding: ${spacing(1, 0)};
  }
`
