import { config } from '@grafana/runtime'

export function hasRequiredFeatureToggles() {
  // @ts-expect-error externalServiceAccounts was added in Grafana 10.2.0
  // Remove this comment when the minimum Grafana version is updated to 10.2.0
  const { accessControlOnCall, nestedFolders, externalServiceAccounts } =
    config.featureToggles

  return [accessControlOnCall, nestedFolders, externalServiceAccounts].every(
    Boolean
  )
}
