import { useMutation, useQueryClient } from '@tanstack/react-query'

import { TestRun } from 'types'
import { useDatasource } from 'datasourceContext'
import { showAlert } from 'utils/showAlert'
import {
  toProjectsWithStatsKey,
  toTestQueryKey,
  toTestSearchQueryKey,
  toTestRunQueryKey,
} from 'data/queryKeys'
import { useAppContext } from 'appContext'
import { useCallback, useState } from 'react'
import { useConfirmation } from 'hooks/useConfirmation'
import { isTestActive, isTestRunning } from 'utils/testRun'
import { useHasUserProjectWriteAccess } from 'data/usePermissions'

const useStopTestRun = () => {
  const queryClient = useQueryClient()
  const { ds } = useDatasource()
  const { orgId } = useAppContext()

  return useMutation({
    mutationFn: (runID: TestRun['id']) => ds.stopTestRun(runID),
    onError: () =>
      showAlert('An error occurred when trying to stop test run.', 'error'),
    onSuccess: (run: TestRun) => {
      queryClient.invalidateQueries(toTestSearchQueryKey(run.project_id))
      queryClient.invalidateQueries(toTestQueryKey(run.test_id))
      queryClient.invalidateQueries(toProjectsWithStatsKey(orgId))
      queryClient.invalidateQueries(toTestRunQueryKey(run.test_id))
    },
  })
}

export const useStopTestRunAction = () => {
  const [testRun, setTestRun] = useState<TestRun | null>(null)
  const { mutateAsync: stopTestRun, isLoading, isSuccess } = useStopTestRun()
  const hasUserProjectWriteAccess = useHasUserProjectWriteAccess()

  const [confirmationProps, requestConfirmation] = useConfirmation(async () => {
    if (testRun) {
      await stopTestRun(testRun.id)
      setTestRun(null)
    }
  })

  const onClick = useCallback(
    (testRun: TestRun) => {
      setTestRun(testRun)
      requestConfirmation()
    },
    [requestConfirmation]
  )

  const canStopTestRun = useCallback(
    (testRun?: TestRun) => {
      if (!testRun) {
        return false
      }

      return (
        isLoading ||
        isSuccess ||
        !hasUserProjectWriteAccess ||
        !isTestActive(testRun) ||
        !isTestRunning(testRun)
      )
    },
    [isLoading, isSuccess, hasUserProjectWriteAccess]
  )

  const stopConfirmationProps = {
    ...confirmationProps,
    title: 'Stop test run?',
    body: 'Are you sure you want to stop this test run?',
    confirmText: 'Confirm',
  }

  return [stopConfirmationProps, onClick, canStopTestRun] as const
}
