import React from 'react'

import { ClipboardIconButton } from 'components/ClipboardIconButton'
import { Label } from './ProjectIdLabel.styles'

interface ProjectIdLabelProps {
  id: number
}

export const ProjectIdLabel = ({ id }: ProjectIdLabelProps) => {
  return (
    <>
      <Label>
        <div>
          Project id:&nbsp;<strong>{id}</strong>
        </div>
        <ClipboardIconButton
          data={id.toString()}
          placement="bottom"
          size="sm"
          aria-label="Copy Project Id"
        />
      </Label>
    </>
  )
}
