import { ProjectId } from 'types'

export const toProjectQueryKey = (projectId: number | undefined) =>
  ['project', projectId] as const

export const toBackgroundTaskQueryKey = (projectId: ProjectId) =>
  [...toProjectQueryKey(projectId), 'background-tasks'] as const

export const toProjectRolesQueryKey = (
  projectId: number,
  params: {
    page?: number
    count?: boolean
    orderBy?: string
    select?: string
    pageSize?: number
    emailFilter?: string
  } = {}
) => [...toProjectQueryKey(projectId), 'project-roles', params] as const

export const toTestCountQueryKey = (projectId: ProjectId) =>
  [...toProjectQueryKey(projectId), 'test-count'] as const

export const toTestListQueryKey = (projectId: number) =>
  [...toProjectQueryKey(projectId), 'tests'] as const

export const toTestSearchQueryKey = (projectId: number, ...params: unknown[]) =>
  [...toTestListQueryKey(projectId), ...params] as const

export const toTestOptionsQueryKey = (projectId: number) =>
  [...toTestListQueryKey(projectId), 'options'] as const

export const toProjectSettingsQueryKey = (projectId: number) =>
  [...toProjectQueryKey(projectId), 'project-settings'] as const

export const toProjectFolderDescendantsQueryKey = (projectId: number) =>
  [...toProjectQueryKey(projectId), 'folder-descendants'] as const

export const toProjectFolderAssignments = (projectId: number) =>
  [...toProjectQueryKey(projectId), 'folder-assignments'] as const
