import { useMutation, useQueryClient } from '@tanstack/react-query'

import { AdditionalUserData, ApiError } from 'types'
import { useDatasource } from 'datasourceContext'
import { useAccount } from 'data/useAccount'
import { toAccountQueryKey } from 'data/queryKeys'

type AppJSON = AdditionalUserData['app_json']

export const useUpdateAdditionalUserDataAppJson = () => {
  const queryClient = useQueryClient()

  const { ds } = useDatasource()
  const { data: account } = useAccount()

  const additionalUserData = account?.additional_user_data?.[0]
  const appJson = additionalUserData?.app_json ?? {}
  const user = account?.user

  return useMutation<AppJSON, ApiError, AppJSON, unknown>({
    mutationFn: (payload) =>
      ds.updateAdditionalUserData(user!.id, {
        app_json: { ...appJson, ...payload },
      }),
    onSuccess: () => {
      return queryClient.invalidateQueries(toAccountQueryKey(ds.uid))
    },
  })
}
