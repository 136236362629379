import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { type FetchError } from '@grafana/runtime'

import { K6DataSource } from 'datasource/datasource'
import { useDatasource } from 'datasourceContext'
import { toOrgSchedulesQueryKey } from 'data/queryKeys'
import {
  ScheduledTest,
  ScheduledTestPayload,
  ScheduledTestUpdatePayload,
  ScheduledTestsResponse,
} from 'types/scheduledTests'
import { toSelectQuery } from 'datasource/serialization'
import { useAppContext } from 'appContext'
import { showAlert } from 'utils/showAlert'

export function useScheduledTests() {
  const { ds } = useDatasource()
  const { orgId } = useAppContext()

  return useQuery({
    queryKey: toOrgSchedulesQueryKey(orgId),
    queryFn: () => loadSchedules(ds, orgId),
    onError: (error: FetchError) => {
      showAlert(`Status ${error.status}: Unable to load schedules`, 'error')
    },
    meta: {
      alertOnError: false,
    },
  })
}

function loadSchedules(ds: K6DataSource, orgId: number) {
  const select = toSelectQuery({
    expand: {
      load_test: {
        select: ['id', 'name', 'project_id', 'project_name'],
      },
    },
  })

  const params = {
    organization_id: orgId,
    ...select,
  }

  return ds
    .get<ScheduledTestsResponse>(`v4/schedules`, { params })
    .then((res) => {
      const schedules = res['k6-schedules'].map((schedule) => ({
        ...schedule,
        organization_id: orgId,
      }))

      return schedules
    })
}

interface UseUpdateScheduledTestProps {
  onSuccess?: () => void
}

export function useUpdateSchedule({ onSuccess }: UseUpdateScheduledTestProps) {
  const { ds } = useDatasource()
  const queryClient = useQueryClient()
  const { orgId } = useAppContext()

  return useMutation({
    mutationFn: (schedule: ScheduledTestUpdatePayload) =>
      updateSchedule(ds, { ...schedule, active: true }),
    onError: (error: FetchError) => {
      showAlert(`Status ${error.status}: Unable to update schedule`, 'error')
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(toOrgSchedulesQueryKey(orgId))
      showAlert('Schedule updated!', 'success')
      onSuccess?.()
    },
  })
}

function updateSchedule(ds: K6DataSource, payload: ScheduledTestUpdatePayload) {
  return ds.patch(`v4/schedules/${payload.id}`, { body: payload })
}

interface UseDisableScheduledTestProps {
  onSuccess?: () => void
  schedule: ScheduledTestPayload
}

export function useDisableSchedule({
  onSuccess,
  schedule,
}: UseDisableScheduledTestProps & { schedule: ScheduledTest }) {
  const { ds } = useDatasource()
  const queryClient = useQueryClient()
  const { orgId } = useAppContext()

  return useMutation({
    mutationFn: () =>
      updateSchedule(ds, {
        ...schedule,
        active: false,
      }),
    onError: (error: FetchError) => {
      showAlert(`Status ${error.status}: Unable to update schedule`, 'error')
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(toOrgSchedulesQueryKey(orgId))
      showAlert('Schedule disabled!', 'success')
      onSuccess?.()
    },
  })
}

interface UseCreateScheduledTestProps {
  onSuccess?: () => void
}

export function useCreateSchedule({ onSuccess }: UseCreateScheduledTestProps) {
  const { ds } = useDatasource()
  const queryClient = useQueryClient()
  const { orgId } = useAppContext()

  return useMutation({
    mutationFn: (schedule: ScheduledTestPayload) =>
      createSchedule(ds, schedule),
    onError: (error: FetchError) => {
      showAlert(`Status ${error.status}: Unable to create schedule`, 'error')
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(toOrgSchedulesQueryKey(orgId))
      await onSuccess?.()
      showAlert('Schedule added!', 'success')
    },
  })
}

function createSchedule(ds: K6DataSource, schedule: ScheduledTestPayload) {
  return ds.post(`v4/schedules`, { body: schedule })
}

export function useDeleteSchedule() {
  const { ds } = useDatasource()
  const queryClient = useQueryClient()
  const { orgId } = useAppContext()

  return useMutation({
    mutationFn: (schedule: ScheduledTest) => deleteSchedule(ds, schedule),
    onSuccess: async () => {
      await queryClient.invalidateQueries(toOrgSchedulesQueryKey(orgId))
      showAlert('Schedule deleted!', 'success')
    },
    onError: (error: FetchError) => {
      showAlert(`Status ${error.status}: Unable to delete schedule`, 'error')
    },
  })
}

function deleteSchedule(ds: K6DataSource, schedule: ScheduledTest) {
  return ds.delete(`v4/schedules/${schedule.id}`)
}
