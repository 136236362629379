import { GeneratorContext, SummaryRow } from '../types'
import { createTextPanel } from '../panels'
import { LoadZoneDistribution } from 'types/loadZone'
import { MetricBuilder } from 'utils/metrics'
import { HTTP_REQUEST_RATE, VUS_METRIC } from 'constants/metrics'
import { MetricData } from 'datasource/models'
import { findAtTimestamp, findMaxInSeries } from './utils'
import { requestRate, vus } from 'utils/formatters'
import dedent from 'dedent'

function calculateStats(vus: MetricData, avg: MetricData, max: MetricData) {
  const maxRequestRate = findMaxInSeries(max)

  return {
    peak: maxRequestRate.value,
    vus: findAtTimestamp(vus, maxRequestRate.timestamp),
    avg: avg.data?.values[0]?.value ?? 0,
  }
}

async function fetchData(
  { metrics, options }: GeneratorContext,
  loadZone: LoadZoneDistribution | undefined
) {
  const [[vus], [avg], [max]] = await Promise.all([
    metrics.queryRange(
      options.testRun.id,
      new MetricBuilder(VUS_METRIC).withLoadZone(loadZone).build()
    ),
    metrics.queryAggregate(
      options.testRun.id,
      new MetricBuilder(HTTP_REQUEST_RATE)
        .withLoadZone(loadZone)
        .asAggregate()
        .build()
    ),
    metrics.queryRange(
      options.testRun.id,
      new MetricBuilder(HTTP_REQUEST_RATE).withLoadZone(loadZone).build()
    ),
  ])

  if (vus === undefined || avg === undefined || max === undefined) {
    throw new Error('Missing data for generating throughput text.')
  }

  return calculateStats(vus, avg, max)
}

async function generateText(
  context: GeneratorContext,
  loadZone: LoadZoneDistribution | undefined
) {
  const stats = await fetchData(context, loadZone)

  const peak = requestRate(stats.peak)
  const avg = requestRate(stats.avg)

  if (loadZone === undefined) {
    // prettier-ignore
    return dedent`
      ## Throughput

      The test had an overall average request rate of **${avg}**, peaking at an
      average of **${peak}** while running **${vus(stats.vus)}**.
    `
  }

  // prettier-ignore
  return dedent`
    ## Throughput

    The average request rate from ${loadZone.name} was **${avg}**, peaking at **${peak}** 
    while running ${vus(stats.vus)}.
  `
}

export async function createThroughputText(
  context: GeneratorContext,
  loadZone: LoadZoneDistribution | undefined
): Promise<SummaryRow> {
  const text = await generateText(context, loadZone)

  return {
    height: 3,
    panels: [createTextPanel(text)],
  }
}
