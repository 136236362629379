import React from 'react'

import { TrendingMetricConfig } from 'types'
import { toMetricOption, withProtocolPrefix } from 'utils/options/metricOptions'
import { toTrendAggregationOption } from 'utils/options/aggregations/trends'
import { TagsTooltip } from '../Tags/TagsTooltip'
import { TagsMetaContainer, TrendMetaContainer } from './TrendMeta.styles'
import { deserializeQueryExpression } from 'datasource/serialization'

interface TrendMetaProps {
  shorthand?: boolean
  withTags?: boolean
  trend: TrendingMetricConfig
}

export const getTrendName = (
  trend: TrendingMetricConfig,
  shorthand?: boolean
) => {
  const metricOption = withProtocolPrefix(
    toMetricOption({ name: trend.metric_name })
  )
  const aggregationOption = toTrendAggregationOption(trend.aggregation_function)
  const displayKey = shorthand ? 'shorthand' : 'label'
  const displayValue =
    aggregationOption[displayKey] ?? aggregationOption['label']

  return `${metricOption.label} / ${displayValue}`
}

export const TrendMeta = ({ shorthand, trend, withTags }: TrendMetaProps) => {
  return (
    <TrendMetaContainer>
      <TrendMetaName trend={trend} shorthand={shorthand} />

      {withTags && <TrendMetaTags trend={trend} />}
    </TrendMetaContainer>
  )
}

export const TrendMetaName = ({ trend, shorthand }: TrendMetaProps) => (
  <span>{getTrendName(trend, shorthand)}</span>
)

export const TrendMetaTags = ({ trend }: TrendMetaProps) => {
  const tagsQuery = deserializeQueryExpression(trend.labels_selector)
  const tagExpressions = Object.values(tagsQuery)

  return (
    <TagsMetaContainer>
      Tags: <TagsTooltip tagExpressions={tagExpressions} />
    </TagsMetaContainer>
  )
}
