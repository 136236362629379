import styled from 'styled-components'
import { spacing } from 'utils/styled'

export const BreakdownTabEmptyView = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(2)};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  flex-wrap: wrap;
`

export const BreakdownTabMessage = styled(BreakdownTabEmptyView)`
  justify-content: center;
  align-items: center;
  min-height: 300px;
`

export const Content = styled.div`
  flex: 1;
  ol {
    margin: ${spacing(2, 0)};
    padding-left: ${spacing(4)};
  }
`
