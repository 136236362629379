import { FilterExpression, and, eq, expr, neq } from 'datasource/serialization'
import type { Group, GroupId } from 'types'

interface Grouped {
  group_id?: GroupId | null
}

export function byRootGroup<T extends Grouped>(
  filter: FilterExpression<T>
): FilterExpression<T> {
  return byGroup(null, filter)
}

export function byGroup<T extends Grouped>(
  group: Group | 'any' | null,
  filter: FilterExpression<T>
): FilterExpression<T> {
  if (group === 'any') {
    return and(neq('group_id', null), expr(filter))
  }

  return and(eq('group_id', group?.id ?? null), expr(filter))
}
