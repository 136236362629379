import styled from 'styled-components'
import { color, spacing } from 'utils/styled'

export const Container = styled.div`
  flex: 0 0 200px; // to give "needs improvement" some margin to fit on one line
  display: flex;
  flex-direction: column;
  gap: ${spacing(1.5)};
`

export const ShortName = styled.h3`
  color: ${color('text', 'primary')};
  font-weight: 700;
  margin-bottom: 0;
`

export const FullName = styled.span`
  color: ${color('text', 'secondary')};
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
`

export const Score = styled.h4`
  font-weight: 700;

  & > span {
    &:not(:last-child) {
      margin-right: ${spacing(0.5)};
    }
  }
`
