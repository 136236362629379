import React from 'react'
import styled from 'styled-components'

import { color, spacing } from 'utils/styled'
import { Flex } from 'components/Flex'

import magnifyingGlassCloudsUrl from 'assets/images/magnifying-glass-with-clouds.svg'

const ICON_SIZE = 128

interface CloudInsightsExecutionFailedProps {
  status: string | undefined
}

export const CloudInsightsExecutionFailed = ({
  status,
}: CloudInsightsExecutionFailedProps) => {
  const reason = status
    ? `Failure reason: ${status}`
    : `Oops! Insights ${"aren't"} working right now.`

  return (
    <Flex align="center" justify="center" direction="column" padding={3}>
      <MagnifyingGlassWithClouds />
      <Heading>{reason}</Heading>
      {!status && <p>Please check your test scripts for errors.</p>}
    </Flex>
  )
}

const Heading = styled.h3`
  text-align: center;
  color: ${color('warning', 'main')};
`

const MagnifyingGlassWithClouds = styled.div`
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  margin-bottom: ${spacing(2)};
  background-color: ${color('warning', 'main')};
  mask: url(${magnifyingGlassCloudsUrl}) no-repeat center;
`
