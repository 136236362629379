import { object, literal } from 'zod'
import { TestNameSchema } from '../testName'
import { script } from './script'
import { OptionsSchema } from '../options'

export const TestScriptDraftSchema = object({
  type: literal('script'),
  name: TestNameSchema,
  script: script.pipe(OptionsSchema).nullable(),
})
