import React from 'react'
import type { WebVitalValueConfig } from './types'
import { WEB_VITAL_SCORE } from './constants'
import {
  Container,
  ScoreCopy,
  Separator,
  Unit,
  Value,
} from './WebVitalValue.styled'
import { cx } from '@emotion/css'
import { RunsPageTestIds } from 'types/dataTestIds'

interface WebVitalValueProps {
  value: WebVitalValueConfig
  compareValue?: WebVitalValueConfig | null
}
export function WebVitalValue({ value, compareValue }: WebVitalValueProps) {
  const score = value.score ?? compareValue?.score ?? undefined
  const scoreCopy = score !== undefined ? WEB_VITAL_SCORE[score] : null
  const isComparing = compareValue !== undefined && compareValue !== null

  if (isComparing) {
    const showRightUnit =
      !!compareValue.unit && compareValue?.originalValue !== null
    const showLeftUnit =
      !showRightUnit && value.unit && value?.originalValue !== null

    return (
      <Container
        className={cx([`score--${score}`, isComparing && 'score--isComparing'])}
        data-testid={RunsPageTestIds.StatisticValue}
      >
        <Value>{value.value}</Value>
        {showLeftUnit && <Unit>{value.unit}</Unit>}
        <Separator>/</Separator>
        <Value $right>{compareValue.value}</Value>
        {showRightUnit && <Unit>{compareValue.unit}</Unit>}
      </Container>
    )
  }

  const showUnit = !!value.unit && value?.originalValue !== null
  const showScoreCopy = !!scoreCopy

  return (
    <Container
      className={`score--${score}`}
      data-testid={RunsPageTestIds.StatisticValue}
    >
      <Value>{value.value}</Value>
      {showUnit && <Unit>{value.unit}</Unit>}
      {showScoreCopy && <ScoreCopy>({scoreCopy})</ScoreCopy>}
    </Container>
  )
}
