import React from 'react'
import styled from 'styled-components'
import { Tooltip, Icon } from '@grafana/ui'
import { Controller, useFormContext } from 'react-hook-form'

import { addPlural } from 'utils'
import { spacing, color } from 'utils/styled'
import { Switch } from 'components/Switch'
import { StaticIP, StaticIPStatus } from 'types'

import { getUniqueLoadZonesFromStaticIPs } from './StaticIPsCalculatorFormIncludeOwnedIPs.utils'
import { StaticIPsCalculatorFormValues } from '../StaticIPsCalculator.types'

export const StaticIPsCalculatorFormIncludeOwnedIPs = ({
  staticIPs,
}: {
  staticIPs: StaticIP[]
}) => {
  const { control } = useFormContext<StaticIPsCalculatorFormValues>()

  const provisionedIPs = staticIPs.filter(
    (ip) =>
      ip.provisioning_status === StaticIPStatus.PROVISIONED ||
      ip.provisioning_status === StaticIPStatus.PROVISIONING
  )

  const staticIPsLength = provisionedIPs.length
  const uniqueLoadZonesLength =
    getUniqueLoadZonesFromStaticIPs(provisionedIPs).length

  return (
    <IncludeOwnedIPsContainer>
      <Controller
        control={control}
        name="includeOwnedIPs"
        render={({ field: { onChange, value, ...rest } }) => (
          <SwitchContainer>
            <Switch
              {...rest}
              aria-label="Should already owned IPs be included in the calculation?"
              value={value}
              onChange={(value) => onChange(value)}
              offLabel="Omit acquired IPs from calculation"
              onLabel="Include acquired IPs in calculation"
            />

            <Tooltip
              content={
                <>
                  When calculating how many IPs you need to acquire, you have
                  the option to include or exclude already owned IPs in the
                  calculation. You currently own{' '}
                  <strong>{staticIPsLength}</strong>{' '}
                  {addPlural('IP', staticIPsLength)} distributed in{' '}
                  <strong>{uniqueLoadZonesLength}</strong>{' '}
                  {addPlural('load zone', uniqueLoadZonesLength)}.
                </>
              }
              placement="auto"
            >
              <SwitchInfoCircle name="info-circle" />
            </Tooltip>
          </SwitchContainer>
        )}
      />
    </IncludeOwnedIPsContainer>
  )
}

const IncludeOwnedIPsContainer = styled.div`
  margin-top: ${spacing(3)};
  margin-bottom: ${spacing(3)};
`

const SwitchInfoCircle = styled(Icon)`
  margin-left: ${spacing(1.5)};
  position: relative;
  bottom: 1px;
  color: ${color('info', 'main')};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    display: none;
  }
`

const SwitchContainer = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    > label {
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }
  }
`
