import React, { ReactNode, useState } from 'react'
import { useSubscriptionRule } from 'data/useSubscriptions'
import { UpgradePromptModal } from '../UpgradePromptModal'
import { CTAUpgradeReason } from 'services/tracking.types'
import { useWillQueueTest } from 'data/useWillQueueTest'
import { useConcurrencyPolicy } from 'hooks/useConcurrencyPolicy'

interface MaxConcurrentRunsModalProps {
  isOpen: boolean
  actions: ReactNode
  onDismiss(): void
}

export function MaxConcurrentRunsModal({
  isOpen,
  actions,
  onDismiss,
}: MaxConcurrentRunsModalProps) {
  const maxConcurrentRuns = useSubscriptionRule('tests.concurrent.max')
  const concurrencyPolicy = useConcurrencyPolicy()
  const oneAllowed = maxConcurrentRuns === 1
  const canQueue = concurrencyPolicy === 'queue'

  return (
    <UpgradePromptModal
      isOpen={isOpen}
      reason={CTAUpgradeReason.MaxConcurrentTests}
      title="Test concurrency limit reached"
      actions={actions}
      onDismiss={onDismiss}
    >
      <p>
        Your current subscription only allows {maxConcurrentRuns}{' '}
        {oneAllowed ? 'test' : 'tests'} to be running at{' '}
        {oneAllowed ? 'a' : 'the same'} time.
      </p>
      {canQueue ? (
        <p>Starting another test will put the test in queue.</p>
      ) : (
        <p>
          A new test cannot be started until{' '}
          {oneAllowed
            ? 'the already running test'
            : 'one of the already running tests'}{' '}
          has finished.
        </p>
      )}
    </UpgradePromptModal>
  )
}

export const useMaxConcurrentRunsModal = (onRun: () => void) => {
  const [isOpen, setIsOpen] = useState(false)

  const willQueueTest = useWillQueueTest()

  const handleOnRun = async () => {
    const willQueue = await willQueueTest()

    if (willQueue) {
      setIsOpen(true)

      return
    }

    onRun()
  }

  const handleOnDismiss = () => {
    setIsOpen(false)
  }

  return {
    isOpen: isOpen,
    onDismiss: handleOnDismiss,
    onRun: handleOnRun,
    onQueue: onRun,
  }
}
