import React from 'react'
import styled from 'styled-components'

import { useBackgroundTasks } from 'data/useBackgroundTasks'
import { Tooltip } from 'components/Tooltip'
import { addPlural } from 'utils'
import { useActivityPanel } from 'activityPanelContextProvider'

import { ActivityCount } from './ActivityCount'

export const ActivityButton = () => {
  const { data: backgroundTasks = [] } = useBackgroundTasks()
  const activityPanel = useActivityPanel()
  const backgroundTasksCount = backgroundTasks.length

  if (!backgroundTasksCount) {
    return null
  }

  const ariaLabel = `${backgroundTasksCount} activity ${addPlural(
    `task`,
    backgroundTasksCount
  )} running`

  return (
    <Wrapper>
      <Tooltip content={ariaLabel}>
        <Button aria-label={ariaLabel} onClick={activityPanel.open}>
          <ActivityCount count={backgroundTasksCount} interactive />
        </Button>
      </Tooltip>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 0 ${({ theme }) => theme.spacing(0.75)};
`

const Button = styled.button`
  // so that focus state looks nice diagonal and not a square
  transform: rotate(45deg);

  > * {
    transform: rotate(-45deg);
  }
`
