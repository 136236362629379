import { IconButton } from '@grafana/ui'
import styled from 'styled-components'

import { IconWithPredefinedName } from 'types/icon'
import { fontSize, spacing } from 'utils/styled'

export const CellContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: ${spacing(6)};
  padding-right: ${spacing(2)};
  font-size: ${fontSize('bodySmall')};
`

export const CellContent = styled.div`
  flex: 1 1 0;
  overflow: hidden;
`

export const TableHeaderMenuIcon: IconWithPredefinedName = styled(
  IconButton
).attrs({
  name: 'ellipsis-v',
})`
  margin-right: 0;
`
