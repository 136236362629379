import React, { ReactNode } from 'react'
import { CellContainer, CellContent } from './TableCell.styles'

interface TableCellProps {
  className?: string
  icon?: ReactNode
  children?: ReactNode
}

export function TableCell({ className, icon, children }: TableCellProps) {
  return (
    <CellContainer className={className}>
      {icon}
      <CellContent>{children}</CellContent>
    </CellContainer>
  )
}
