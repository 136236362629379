import z, { RefinementCtx, ZodIssueCode, literal, object, string } from 'zod'

export const EmptyNameSchema = object({
  type: literal('empty-name'),
})

export type EmptyName = z.infer<typeof EmptyNameSchema>

function checkNameFormat(value: string, context: RefinementCtx) {
  if (value.trim() === '') {
    const params: EmptyName = {
      type: 'empty-name',
    }

    context.addIssue({
      code: ZodIssueCode.custom,
      message: 'Test name cannot be empty.',
      params,
      fatal: true,
    })
  }
}

export const TestNameSchema = string().superRefine(checkNameFormat)

export type TestName = z.infer<typeof TestNameSchema>
