import React, { ReactNode } from 'react'
import { IconName } from '@grafana/data'
import { Icon } from '@grafana/ui'

import {
  Description,
  SectionContent,
  SectionHeader,
  SectionTitle,
  StyledSection,
} from './Section.styles'
import { ProtectedTitle } from './ProtectedTitle'

interface SectionProps {
  icon?: IconName
  title?: ReactNode
  description?: ReactNode
  children?: ReactNode
  actions?: ReactNode
}

export function Section({
  icon,
  title,
  description,
  actions,
  children,
}: SectionProps) {
  return (
    <StyledSection>
      <SectionHeader>
        <div>
          {!!title && (
            <SectionTitle>
              {!!icon && <Icon name={icon} aria-hidden />}
              {title}
            </SectionTitle>
          )}

          {!!description && <Description>{description}</Description>}
        </div>
        {actions}
      </SectionHeader>
      {!!children && (
        <SectionContent direction="column" gap={2}>
          {children}
        </SectionContent>
      )}
    </StyledSection>
  )
}

Section.ProtectedTitle = ProtectedTitle
