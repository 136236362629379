export const FEATURE_FLAG_STORAGE_KEY = 'k6.settings.featureFlags'

export const FEATURE_FLAGS = [
  {
    name: 'dummyFeature',
    label: 'Demo Feature',
    description: 'No-op feature for demo purposes',
    active: false,
    env: {
      development: true,
      test: true,
      production: false,
    },
  },
  /**
   * Browser Timeline
   *
   */
  {
    name: 'browserTimeline',
    label: 'Browser Timeline',
    description: 'Browser Timeline component demo!',
    active: false,
    env: {
      development: true,
      test: true,
      production: false,
    },
  },
  {
    name: 'grafanaRBAC',
    label: 'Grafana RBAC',
    description: 'Use Grafana RBAC for access control',
    active: false,
    env: {
      development: true,
      test: true,
      production: false,
    },
  },
  /**
   * Cloud Insights
   *
   */
  {
    name: 'cloudInsights',
    label: 'Cloud Insights',
    description: 'Cloud Insights panel for test run page',
    active: false,
    env: {
      development: true,
      test: true,
      production: false,
    },
  },
] as const
