import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Project } from 'types'
import { useProject } from 'data/useProject'
import { PluginOrganizationWidePage } from 'components/PluginPage'
import { PluginPagePlaceholder } from 'components/PluginPagePlaceholder'
import { ErrorState } from 'pages/ProjectPage/components/ErrorState'
import { PageErrorBoundary } from 'components/ErrorBoundary'
import { ErrorBoundaryFullPageView } from 'services/tracking.types'

import {
  useProjectIdFromParams,
  useProjectIdFromRouteData,
} from './projectContext.utils'

export const ProjectContext = createContext<Project | undefined>(undefined)

type ProjectContextProviderProps = {
  children: ReactNode
}

const ProjectContextProviderComponent = ({
  children,
}: ProjectContextProviderProps) => {
  const [projectId, setProjectId] = useState<number>()

  const projectIdFromParams = useProjectIdFromParams()
  const projectIdFromRouteParams = useProjectIdFromRouteData()

  const { data: project, error: projectError } = useProject(projectId)

  useEffect(() => {
    if (projectIdFromParams) {
      setProjectId(projectIdFromParams)
    }
  }, [projectIdFromParams])

  useEffect(() => {
    if (projectIdFromRouteParams) {
      setProjectId(projectIdFromRouteParams)
    }
  }, [projectIdFromRouteParams])

  if (!projectId || !project) {
    return <PluginPagePlaceholder />
  }

  if (projectError) {
    return (
      <PluginOrganizationWidePage
        pageNav={{ text: '', hideFromBreadcrumbs: true }}
      >
        <ErrorState
          error={projectError}
          view={ErrorBoundaryFullPageView.ProjectContextProvider}
        />
      </PluginOrganizationWidePage>
    )
  }

  return (
    <StaticProjectContextProvider project={project}>
      {children}
    </StaticProjectContextProvider>
  )
}

export const ProjectContextProvider = (props: ProjectContextProviderProps) => {
  return (
    <PageErrorBoundary>
      <ProjectContextProviderComponent {...props} />
    </PageErrorBoundary>
  )
}

interface StaticProjectContextProviderProps {
  project: Project
  children: ReactNode
}

export const StaticProjectContextProvider = ({
  project,
  children,
}: StaticProjectContextProviderProps) => {
  return (
    <ProjectContext.Provider value={project}>
      {children}
    </ProjectContext.Provider>
  )
}

export const useCurrentProject = () => {
  const context = useContext(ProjectContext)

  if (context === undefined) {
    throw new Error(
      'useCurrentProject must be used within a ProjectContextProvider'
    )
  }

  return context
}
