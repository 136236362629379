import {
  QueryKey,
  UseInfiniteQueryOptions,
  useQueries,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { BG_TASKS_POLL_INTERVAL } from 'constants/index'
import {
  useOptionalInfiniteQuery,
  useOptionalQuery,
} from 'hooks/useOptionalQuery'
import { useRef } from 'react'
import { TestRun } from 'types'
import { useUpdateEffect } from 'usehooks-ts'
import { isTestActive } from 'utils/testRun'
import { Falsy } from 'utils/typescript'

const ONE_HOUR = 60 * 60 * 1000

function getRefetchInterval(testRun: TestRun) {
  return isTestActive(testRun) ? BG_TASKS_POLL_INTERVAL : false
}

function toStreamingQueryOptions<
  TOptions extends UseQueryOptions<any, any, any, any>
>(testRun: TestRun, query: TOptions): TOptions {
  return {
    staleTime: ONE_HOUR,
    refetchOnMount: isTestActive(testRun) ? 'always' : true,

    refetchInterval() {
      return getRefetchInterval(testRun)
    },

    ...query,
  }
}

function useRefetchWhenDone<TData = unknown, TError = unknown>(
  testRun: TestRun | undefined,
  results: UseQueryResult<TData, TError> | Array<UseQueryResult<TData, TError>>
) {
  const isActive = testRun && isTestActive(testRun)
  const wasActive = useRef(isActive)

  useUpdateEffect(() => {
    // If isActive is undefined then the test run is undefined as
    // well. If this happens we reset the state, because it's likely
    // caused by a new test run being loaded.
    if (isActive === undefined) {
      wasActive.current = undefined

      return
    }

    // If wasActive is still undefined, that means that we have just
    // learned if the test run is active or not, so we initialize it
    // to whatever its current state is.
    if (wasActive.current === undefined) {
      wasActive.current = isActive
    }

    // If the state hasn't changed, then there's nothing to refetch.
    if (isActive === wasActive.current) {
      return
    }

    // If we've gotten this far then the state of the test run changed
    // and we need to do a refetch.
    wasActive.current = isActive

    if (Array.isArray(results)) {
      results.forEach((result) => result.refetch())

      return
    }

    results.refetch()
  }, [results, isActive])
}

type UseStreamingQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  'refetchInterval'
>

export function useStreamingQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  testRun: TestRun,
  query: Falsy<UseStreamingQueryOptions<TQueryFnData, TError, TData, TQueryKey>>
) {
  const result = useOptionalQuery(
    query && toStreamingQueryOptions(testRun, query)
  )

  useRefetchWhenDone(testRun, result)

  return result
}

export function useStreamingQueries<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  testRun: TestRun | undefined,
  {
    queries,
  }: {
    queries: Array<
      UseStreamingQueryOptions<TQueryFnData, TError, TData, TQueryKey>
    >
  }
) {
  function toQueries(
    testRun: TestRun
  ): Array<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>> {
    return queries.map((query) => {
      return toStreamingQueryOptions(testRun, query)
    })
  }

  const results = useQueries({
    queries: testRun !== undefined ? toQueries(testRun) : [],
  })

  useRefetchWhenDone(testRun, results)

  return results
}

type UseInfiniteStreamingQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = Omit<
  UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryData, TQueryKey>,
  'refetchInterval'
>

export function useInfiniteStreamingQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  testRun: TestRun,
  query: Falsy<
    UseInfiniteStreamingQueryOptions<
      TQueryFnData,
      TError,
      TData,
      TQueryFnData,
      TQueryKey
    >
  >
) {
  const result = useOptionalInfiniteQuery(
    query && toStreamingQueryOptions(testRun, query)
  )

  useRefetchWhenDone(testRun, result)

  return result
}
