import React from 'react'

import {
  BestPracticeInvalidMetricNames,
  BestPracticeInvalidMetricNamesFailed,
} from 'types/insights/bestPractices'
import { addPlural } from 'utils'
import { docs, ReadMore } from 'components/DocLink'

import { InsightBody } from '../InsightBody'
import { InsightListItem } from '../InsightItem'
import { SkippedDescription } from '../SkippedDescription'

interface InvalidMetricNamesProps {
  insight: BestPracticeInvalidMetricNamesFailed
}

export const InvalidMetricNames = ({ insight }: InvalidMetricNamesProps) => {
  return (
    <InsightBody>
      <p>
        In this test you used a metric name that is not valid. k6 metric names
        must comply with OpenTelemetry and Prometheus limitations.
      </p>
      <h4>Recommendations:</h4>
      <ul>
        <li>
          Review the metric names in your script and ensure they only contain
          valid symbols of ASCII letters, numbers, or <code>_</code>. Metric
          names must not start with a number.
        </li>
      </ul>
      <p>
        <ReadMore article={docs.insights.bestPractices.invalidMetricNames} />
      </p>
    </InsightBody>
  )
}

interface InvalidMetricNamesItemProps {
  insight: BestPracticeInvalidMetricNames
}

const InvalidMetricNamesDescription = ({
  insight,
}: InvalidMetricNamesItemProps) => {
  switch (insight.status) {
    case 'PASSED':
      return (
        <>
          We did not detect any invalid metric names in your test.{' '}
          <ReadMore article={docs.insights.bestPractices.invalidMetricNames} />
        </>
      )

    case 'FAILED':
      const { length } = insight.data.invalid_metric_names
      return (
        <>
          Your test has {length} invalid metric {addPlural('name', length)}.
        </>
      )

    default:
      return <SkippedDescription insight={insight} />
  }
}

export const InvalidMetricNamesItem = ({
  insight,
}: InvalidMetricNamesItemProps) => {
  return (
    <InsightListItem header="Invalid Metric Names" insight={insight}>
      <InvalidMetricNamesDescription insight={insight} />
    </InsightListItem>
  )
}
