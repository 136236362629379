import React, { useEffect, useMemo, useState } from 'react'
import Prism, { Grammar } from 'prismjs'

import { identity } from 'utils/composition'
import { dedent as formatCode } from 'utils/string'
import { ClipboardIconButton } from 'components/ClipboardIconButton'
import {
  CodeSnippetGroupProps,
  CodeSnippetProps,
  CodeSnippetTabProps,
} from './CodeSnippet.types'
import { getEstimatedHeight, getTab } from './CodeSnippet.utils'
import {
  ButtonWrapper,
  Code,
  Section,
  CodeWrapper,
  GroupItem,
  TabGroup,
} from './CodeSnippet.styles'
import { TabsBar } from '../Tabs/TabsBar'
import { Tab } from '@grafana/ui'
import { DownloadBlob } from '../DownloadBlob'
import { ApplicationWindow } from '../ApplicationWindow'

const CodeSnippetTab = ({ active, tab, onClick }: CodeSnippetTabProps) => {
  const handleChangeTab = () => {
    if (!active) {
      onClick(tab.value)
    }
  }

  return <Tab label={tab.label} active={active} onChangeTab={handleChangeTab} />
}

const CodeSnippetGroup = ({
  active = false,
  group,
  onClick,
}: CodeSnippetGroupProps) => {
  const handleChangeGroup = () => {
    if (!active) {
      onClick(group.value)
    }
  }

  return (
    <GroupItem
      $active={active}
      key={`tabGroup-${group.value}`}
      onClick={handleChangeGroup}
    >
      {group.label}
    </GroupItem>
  )
}

export const CodeSnippet = ({
  canCopy = true,
  code,
  className,
  dedent = true,
  lang = 'js',
  initialTab,
  tabs = [],
  height,
}: CodeSnippetProps) => {
  const [activeTab, setActiveTab] = useState<string | undefined>(initialTab)
  const [activeGroup, setActiveGroup] = useState<string | undefined>()
  const tab = getTab(activeTab, tabs)
  const hasGroups = tab && 'groups' in tab
  const snippetTab = useMemo(() => {
    if (hasGroups) {
      return getTab(activeGroup, tab.groups)
    }

    return tab
  }, [hasGroups, activeGroup, tab])

  useEffect(() => {
    if (hasGroups && tab.selected) {
      setActiveGroup(tab.selected)
    }
  }, [hasGroups, tab])

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(initialTab)
    }
  }, [activeTab, initialTab])

  const formatter = snippetTab?.dedent ?? dedent ? formatCode : identity
  const snippet = snippetTab?.code ?? code
  const langSyntax = snippetTab?.lang || lang
  const derivedActiveTab = activeTab ?? tabs[0]?.value

  const highlightedSyntax = useMemo(
    () =>
      snippet &&
      Prism.highlight(
        formatter(snippet),
        Prism.languages[langSyntax] as Grammar,
        langSyntax
      ),
    [formatter, snippet, langSyntax]
  )

  return (
    <ApplicationWindow
      titleContent={
        tabs.length > 0 && (
          <TabsBar>
            {tabs.flatMap((tab) => {
              return (
                <CodeSnippetTab
                  key={`${tab.value}-tab`}
                  active={derivedActiveTab === tab.value}
                  tab={tab}
                  onClick={setActiveTab}
                />
              )
            })}
          </TabsBar>
        )
      }
      className={className}
    >
      <Section $minHeight={getEstimatedHeight(snippet)} $height={height}>
        <CodeWrapper>
          {hasGroups && (
            <TabGroup>
              {tab.groups.map((group, index) => {
                const isGroupActive =
                  !tab.groups.some((item) => item.value === activeGroup) &&
                  !index
                    ? true
                    : activeGroup === group.value
                return (
                  <CodeSnippetGroup
                    key={`${tab.value}-${group.value}`}
                    group={group}
                    active={isGroupActive}
                    onClick={setActiveGroup}
                  />
                )
              })}
            </TabGroup>
          )}
          <Code
            $lang={langSyntax}
            dangerouslySetInnerHTML={{ __html: highlightedSyntax ?? '' }}
          />
        </CodeWrapper>
        <ButtonWrapper>
          {snippetTab?.download && (
            <DownloadBlob
              filename={snippetTab.download}
              content={snippet || ''}
            />
          )}
          {canCopy && <ClipboardIconButton data={snippet ?? ''} />}
        </ButtonWrapper>
      </Section>
    </ApplicationWindow>
  )
}
