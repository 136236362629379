import { useCallback, useEffect, useMemo, useState } from 'react'
import { usePrevious } from 'hooks/usePrevious'
import {
  ActionCellProps,
  ActionHeaderCellProps,
  BatchAction,
  BatchItem,
  Descriptors,
} from 'components/DataTable/DataTable.types'
import { addPlural } from 'utils'

export const useBatching = <T>(items: Array<BatchItem<T>>): BatchAction<T> => {
  const itemIds = items.map((item) => item.id).sort()
  const prevItemIds = usePrevious(itemIds)
  const [isSelectionMode, setIsSelectionMode] = useState(false)
  const [selectedItems, setSelectedItems] = useState<Array<BatchItem<T>>>([])
  const isDisabled = items.length === 0

  const toggleSelectionMode = useCallback(() => {
    setIsSelectionMode(!isSelectionMode)
    setSelectedItems([])
  }, [isSelectionMode])

  const onItemSelect = useCallback(
    (item: BatchItem<T>) => {
      const existingItem = selectedItems.find(
        (selectedItem) => selectedItem.id === item.id
      )
      if (existingItem) {
        setSelectedItems(
          selectedItems.filter((selectedItem) => selectedItem.id !== item.id)
        )
      } else {
        setSelectedItems([...selectedItems, item])
      }
    },
    [selectedItems]
  )

  const clearSelectedItems = useCallback(() => {
    setSelectedItems([])
  }, [])

  const selectAllItems = useCallback(() => {
    setSelectedItems(items)
  }, [items])

  const reset = useCallback(() => {
    setIsSelectionMode(false)
    clearSelectedItems()
  }, [clearSelectedItems])

  useEffect(() => {
    const stillSelected = selectedItems.filter((selectedItem) =>
      itemIds.includes(selectedItem.id)
    )

    if (selectedItems.length && stillSelected.length === 0) {
      return reset()
    }

    if (prevItemIds?.length && prevItemIds?.join() !== itemIds.join()) {
      setSelectedItems(stillSelected)
    }
  }, [prevItemIds, itemIds, reset, selectedItems])

  const allItemsSelected = selectedItems.length === items.length

  return useMemo(() => {
    return {
      allItemsSelected,
      clearSelectedItems,
      isDisabled,
      isSelectionMode,
      onItemSelect,
      reset,
      selectAllItems,
      selectedItems,
      toggleSelectionMode,
    }
  }, [
    allItemsSelected,
    clearSelectedItems,
    isDisabled,
    isSelectionMode,
    onItemSelect,
    reset,
    selectAllItems,
    selectedItems,
    toggleSelectionMode,
  ])
}

export function generateBatchDeleteActions<T extends { id: string | number }>(
  batchAction: BatchAction<T>,
  handleDelete: (items: T[]) => void,
  isDisabled: (item: T) => boolean,
  descriptors: Descriptors
) {
  const { action = `Delete`, noun } = descriptors

  return {
    ...batchAction,
    onBatchConfirm: handleDelete,
    getHeaderCellProps: (selectedItems: T[]): ActionHeaderCellProps => ({
      confirmAriaLabel: addPlural(
        `${action} ${selectedItems.length} ${noun}`,
        selectedItems.length
      ),
      confirmVariant: `destructive`,
      confirmIcon: `trash-alt`,
      descriptors,
    }),
    getCellProps: (
      item: T,
      isSelectionMode: boolean,
      selectedItems: T[]
    ): ActionCellProps<T> => {
      const disabled = isDisabled(item)
      const ariaLabel = isSelectionMode ? `Select ${noun}` : `${action} ${noun}`

      return {
        'aria-label': ariaLabel,
        isChecked: !!selectedItems.find(
          (selectedItem: T) => selectedItem.id === item.id
        ),
        onClick: (item) => handleDelete([item]),
        disabled,
        name: `trash-alt`,
        variant: disabled ? 'secondary' : 'destructive',
      }
    },
  }
}
