import styled from 'styled-components'

import { type InsightScore } from 'types/cloudInsights'
import { getScoreColor } from 'utils/insights'
import { type PropsWithTheme } from 'utils/styled'

interface ElementProps extends PropsWithTheme {
  score?: InsightScore
}

export const Element = styled.span<ElementProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${getScoreColor};
  font-size: ${({ theme }) => theme.typography.h3.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`

export const SpinnerWrapper = styled.span`
  position: relative;
  top: -1px;
`
