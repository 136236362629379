import { Badge } from '@grafana/ui'
import { TrendingMetricDraftConfig } from './TrendingMetricsEditor.types'
import { getOrdinalNumber } from 'utils/formatters'
import { isNewTrendingMetricDraftConfig } from './TrendingMetricsEditor.utils'
import React from 'react'

interface SaveStateBadgeProps {
  draft: TrendingMetricDraftConfig
  duplicateOf?: number
  isDefault: boolean
}
export const SaveStateBadge = ({
  draft,
  duplicateOf,
  isDefault,
}: SaveStateBadgeProps) => {
  if (isDefault) {
    return (
      <Badge
        color="blue"
        text="Default"
        tooltip={`This is the default trending metric configuration.`}
      />
    )
  }
  if (duplicateOf) {
    return (
      <Badge
        color="red"
        text="Duplicate"
        tooltip={`This configuration is a duplicate of the ${getOrdinalNumber(
          duplicateOf + 1
        )} configuration. Duplicates will not be saved.`}
      />
    )
  }
  if (isNewTrendingMetricDraftConfig(draft)) {
    return (
      <Badge
        color="orange"
        text="Unsaved"
        tooltip="This configuration was added during this session and is not saved."
      />
    )
  }
  return null
}
