import { useMemo } from 'react'
import { MetricConfig } from 'datasource/models'
import { LoadGeneratorInstance } from 'types/insights/health'
import { VUS_METRIC } from 'constants/metrics'
import { instanceName } from 'utils/formatters'

export const useLoadGeneratorMetrics = (
  metric: MetricConfig,
  instances: LoadGeneratorInstance[]
) => {
  return useMemo(() => {
    return [
      VUS_METRIC,
      { ...metric, label: 'Global' },
      ...instances.map<MetricConfig>((instance) => {
        return {
          ...metric,
          label: instanceName(instance),
          query: {
            ...metric.query,
            tags: {
              instance_id: {
                name: 'instance_id',
                operator: 'equal',
                values: [instance.id.toString()],
              },
            },
          },
        }
      }),
    ]
  }, [metric, instances])
}
