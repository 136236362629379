import styled, { css } from 'styled-components'
import { color } from 'utils/styled'

const TableRowBase = styled.tr`
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${color('border', 'weak')};
`

interface TableRowProps {
  noHover?: boolean
  highlight?: boolean
}

export const TableRowStyles = styled(TableRowBase)<TableRowProps>`
  cursor: pointer;

  transition: ${({ theme }) =>
    theme.transitions.create('background-color', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeInOut,
    })};

  ${({ noHover: noHover }) =>
    !noHover &&
    css`
      &:hover {
        color: ${color('text', 'maxContrast')};
        background-color: ${color('action', 'hover')};
      }
    `}

  ${({ highlight }) =>
    highlight &&
    css`
      border-bottom: none;
      background-color: ${color('action', 'selected')};
    `}
`
