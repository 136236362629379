import { ComponentType, Key, ReactNode } from 'react'
import { PropertyPath } from 'utils/typescript'
import {
  GroupId,
  GroupLike,
  PagedItems,
  SortOptions,
  TestRun,
  TestRunAnalysis,
} from 'types'
import { QueryKey } from '@tanstack/react-query'
import { GroupWithScenarioName } from 'data/clients/entities/groups'

export type ViewType = 'list' | 'tree'

export interface TableGroupSettings {
  showEmpty: boolean
}

export type TableColumnSettings = Array<TableColumn<any>['id']>

export interface TableSettings {
  id: string
  type: 'tree' | 'list' | undefined
  treeView: TableGroupSettings
  columns: TableColumnSettings
}

interface TableContext {
  view: ViewType
  analysis: TestRunAnalysis
}

export interface ListColumn<T> {
  id: string | number
  name: string
  customStyles?: React.CSSProperties
  shrink?: boolean
  toggle?: 'on' | 'off' | 'none'
  numeric?: boolean
  sortBy?: PropertyPath<T>
  shouldRender?: (context: TableContext) => boolean

  /**
   * Renders the cell for a row in this column.
   *
   * @param left The main value and left hand side of comparisons.
   * @param right The right hand side of a comparison.
   *
   * If the value is `null` it means that there's no corresponding
   * value, e.g. there's was an http request made by the left hand
   * side that wasn't made in the right hand side.
   *
   * If the value is `undefined` then values are not being compared.
   *
   * @returns The rendered cell.
   */
  renderCell: (left: T, right: T | null | undefined) => ReactNode
}

interface TreeColumn<T> extends ListColumn<T> {
  renderGroup: (
    left: GroupLike,
    right: GroupLike | null | undefined
  ) => ReactNode
}

export type TableColumn<T> = TreeColumn<T> | ListColumn<T>

export interface Diff<T> {
  /**
   * The left hand side of the comparison.
   */
  left: T
  right?: T | null
}

type RowBodyProps<T, P> = P & {
  analysis: TestRunAnalysis
  value: T
}

export type EmptyProps<P> = P & {
  testRun: TestRun
}

type EmptyGroupProps<P> = P & {
  testRun: TestRun
}

interface Fetcher<T> {
  queryKey: QueryKey
  queryFn: () => PagedItems<T> | Promise<PagedItems<T>>
  select?: (data: PagedItems<T>) => PagedItems<T>
  enabled?: boolean
}

interface InfiniteFetcher<T> {
  queryKey: QueryKey
  queryFn: (page: number) => PagedItems<T> | Promise<PagedItems<T>>
  enabled?: boolean
}

type FetchPageArgs<T, P> = P & {
  testRun: TestRun
  page: number
  pageSize: number
  sortBy: SortOptions<T> | undefined
}

type FetchByRowsArgs<T, P> = P & {
  testRun: TestRun
  rows: T[]
}

type FetchByGroupArgs<T, P> = P & {
  testRun: TestRun
  group: GroupWithScenarioName | 'any'
  pageSize: number
  sortBy: SortOptions<T> | undefined
}

type FilterArgs<T, P> = P & {
  left: T
  right?: T | null
}

export type RowStatus = 'success' | 'error' | 'warning'

// TODO: TableBase or TableDefinitionBase?
interface TableDefinitionBase<T, P> {
  type?: 'list' | 'tree'

  id: string
  pageSize: number

  keyBy(row: T): Key

  // true is equivalent to getRowStatus 'success' and false is equivalent to 'error'
  // @see getRowStatus for more statuses
  isSuccess?: (row: T) => boolean

  // use instead of `isSuccess` if you want to use more than `success` and `error` statuses
  getRowStatus?: (row: T) => RowStatus

  fetchPage(args: FetchPageArgs<T, P>): Fetcher<T>
  fetchByRows(args: FetchByRowsArgs<T, P>): Fetcher<T>

  filter?: (args: FilterArgs<T, P>) => boolean

  RowBodyComponent: ComponentType<RowBodyProps<T, P>>
}

export interface ListTableDefinition<T, P> extends TableDefinitionBase<T, P> {
  type: 'list'

  paginator: 'always' | 'never'
  columns: Array<ListColumn<T>>
}

export interface TreeTableDefinition<T, P> extends TableDefinitionBase<T, P> {
  type: 'tree'

  columns: Array<TreeColumn<T>>

  getRowCount(group: GroupLike): number
  groupBy(row: T): GroupId | null | undefined
  groupId?(row: GroupWithScenarioName): string
  fetchByGroup: (args: FetchByGroupArgs<T, P>) => InfiniteFetcher<T>

  EmptyGroupComponent: ComponentType<EmptyGroupProps<P>>
}

export interface EitherTableDefinition<T, P> extends TableDefinitionBase<T, P> {
  type?: undefined
  paginator: 'always' | 'never'

  columns: Array<TreeColumn<T> | ListColumn<T>>

  groupBy(row: T): GroupId | null | undefined

  getRowCount(group: GroupLike): number
  fetchByGroup(args: FetchByGroupArgs<T, P>): InfiniteFetcher<T>

  EmptyGroupComponent: ComponentType<EmptyGroupProps<P>>
}

export type TableDefinition<T, P> =
  | ListTableDefinition<T, P>
  | TreeTableDefinition<T, P>
  | EitherTableDefinition<T, P>

interface TableBase<T, P> {
  pageSize: number

  keyBy(row: T): Key
  // true is equivalent to getRowStatus 'success' and false is equivalent to 'error'
  // @see getRowStatus for more statuses
  isSuccess?: (row: T) => boolean

  // use instead of `isSuccess` if you want to use more than `success` and `error` statuses
  getRowStatus?: (row: T) => RowStatus

  fetchPage(args: FetchPageArgs<T, P>): Fetcher<T>
  fetchByRows(args: FetchByRowsArgs<T, P>): Fetcher<T>

  filter?: (args: FilterArgs<T, P>) => boolean

  RowBodyComponent: ComponentType<RowBodyProps<T, P>>
}

export interface ListTable<T, P> extends TableBase<T, P> {
  paginator: 'always' | 'never'

  columns: Array<ListColumn<T>>
}

export interface TreeTable<T, P> extends TableBase<T, P> {
  columns: Array<TreeColumn<T>>

  groupBy(row: T): GroupId | null | undefined
  groupId?(row: GroupWithScenarioName): string
  fetchByGroup: (args: FetchByGroupArgs<T, P>) => InfiniteFetcher<T>

  EmptyGroupComponent: ComponentType<EmptyGroupProps<P>>
}

export type AnyTable<T, P> = ListTable<T, P> | TreeTable<T, P>

export function isTreeColumn<T>(
  column: TableColumn<T>
): column is TreeColumn<T> {
  return 'renderGroup' in column
}
