import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { routeMap } from 'routeMap'
import { ScheduledTest } from 'types/scheduledTests'
import { dateFormatter, formatDate, resolveTimeZone } from 'utils/date'
import { useCanUserScheduleTests } from 'data/usePermissions'
import { DataTable, type TableColumn } from 'components/DataTable'
import { BertMessage } from 'components/BertMessage'
import { TooltipCell } from 'components/DataTable/TooltipCell'
import { LoadingContainer } from 'components/LoadingContainer'

import {
  getFrequencyValue,
  endsSort,
  getEndsValue,
  isActive,
  frequencySort,
  nextRunSort,
} from './scheduledTestsTable.utils'
import { UpdateScheduleButton } from './UpdateScheduleButton'
import { DeleteScheduleButton } from './DeleteScheduleButton'
import { ActiveCell } from './ActiveCell'

const useColumns = (canScheduleTests: boolean) => {
  return useMemo(() => {
    const columns: Array<TableColumn<ScheduledTest>> = [
      {
        name: 'Active',
        sortable: true,
        sortField: 'active',
        selector: (scheduledTest) => (isActive(scheduledTest) ? 1 : 0),
        cell: (scheduledTest) => <ActiveCell scheduledTest={scheduledTest} />,
        width: '130px',
      },
      {
        name: 'First run',
        sortable: true,
        sortField: 'starts',
        minWidth: '140px',
        cell: ({ starts }) =>
          starts
            ? dateFormatter.tz(starts, `d MMM Y, HH:mm`, resolveTimeZone())
            : null,
      },
      {
        name: 'Next run',
        sortable: true,
        sortField: 'next_run',
        sortFunction: nextRunSort,
        minWidth: 'auto',
        cell: ({ next_run, active }) =>
          active && next_run ? formatDate(next_run, resolveTimeZone()) : null,
      },
      {
        name: 'Frequency',
        sortable: true,
        sortField: 'frequency',
        sortFunction: frequencySort,
        minWidth: 'auto',
        cell: (scheduledTest) => {
          const value = getFrequencyValue(scheduledTest)

          if (!value) {
            return null
          }

          return <TooltipCell tooltip={value}>{value}</TooltipCell>
        },
      },
      {
        name: 'Ends',
        sortable: true,
        sortFunction: endsSort,
        sortField: 'ends',
        minWidth: 'auto',
        cell: (scheduledTest) =>
          isActive(scheduledTest) ? getEndsValue(scheduledTest) : null,
      },
      {
        name: 'Project',
        sortable: true,
        sortField: 'load_test.project_name',
        minWidth: 'auto',
        cell: ({ load_test }) => (
          <Link to={routeMap.project(load_test.project_id)}>
            {load_test.project_name}
          </Link>
        ),
      },
      {
        name: 'Test',
        sortable: true,
        sortField: 'load_test.name',
        minWidth: 'auto',
        cell: ({ load_test, test_id }) => (
          <Link to={routeMap.test(test_id)}>{load_test.name}</Link>
        ),
      },
      ...(canScheduleTests
        ? [
            {
              name: <div className="sr-only">Manage</div>,
              cell: (scheduledTest: ScheduledTest) => (
                <UpdateScheduleButton scheduledTest={scheduledTest} />
              ),
              width: '195px',
            },
            {
              name: <div className="sr-only">Delete</div>,
              cell: (scheduledTest: ScheduledTest) => (
                <DeleteScheduleButton scheduledTest={scheduledTest} />
              ),
              width: '80px',
            },
          ]
        : []),
    ]

    return columns
  }, [canScheduleTests])
}

interface ScheduledTestsTableProps {
  data?: ScheduledTest[]
  isLoading: boolean
}

export const ScheduledTestsTable = ({
  data = [],
  isLoading,
}: ScheduledTestsTableProps) => {
  const defaultSortFieldId = 'next_run'
  const defaultSortOrder = 'asc'
  const canScheduleTests = useCanUserScheduleTests()
  const columns = useColumns(canScheduleTests)

  return (
    <LoadingContainer loading={isLoading}>
      <DataTable
        columns={columns}
        fixedHeader
        data={data}
        noDataComponent={
          <BertMessage
            description={
              <BertMessage.Text>{`Looks empty here! ${
                canScheduleTests ? 'Start by creating a new test schedule.' : ''
              }`}</BertMessage.Text>
            }
          />
        }
        defaultSortFieldId={defaultSortFieldId}
        defaultSortOrder={defaultSortOrder}
      />
    </LoadingContainer>
  )
}
