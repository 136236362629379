import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useAppContext } from 'appContext'
import { StaticIPsLoadZonesPayload } from 'types'

import { toStaticIPsQueryKey } from './queryKeys/staticIPs'
import { useDatasource } from '../datasourceContext'

export function useAcquireStaticIPs() {
  const queryClient = useQueryClient()
  const { orgId } = useAppContext()
  const { ds } = useDatasource()

  return useMutation({
    mutationFn: (payload: StaticIPsLoadZonesPayload) =>
      ds.acquireStaticIPs(orgId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries(toStaticIPsQueryKey(orgId))
    },
  })
}
