import { useMutation, useQueryClient } from '@tanstack/react-query'

import { DELETION_POLICY, TestRun } from 'types'
import { useAppContext } from 'appContext'
import { useDatasource } from 'datasourceContext'
import { showAlert } from 'utils/showAlert'
import {
  toSafeTestRunsQueryKey,
  toTestQueryKey,
  toTestRunQueryKey,
} from './queryKeys'
import { isTestSafe } from 'utils/testRun'

export const useToggleSafe = () => {
  const queryClient = useQueryClient()
  const { orgId } = useAppContext()
  const { ds } = useDatasource()

  return useMutation<
    TestRun,
    { data?: { error?: { message?: string } } },
    TestRun,
    unknown
  >({
    mutationFn: (testRun: TestRun) =>
      ds.setSafeFromDeletion({
        id: testRun.id,
        projectId: testRun.project_id,
        safe: !isTestSafe(testRun)
          ? DELETION_POLICY.NOT_DELETABLE
          : DELETION_POLICY.DELETABLE,
      }),
    onError: (err) => {
      const message =
        err?.data?.error?.message ??
        'An error occurred when trying to save test run'

      showAlert(message, 'error')
    },
    onSuccess: (testRun) => {
      const { id, test_id: testId } = testRun

      showAlert(
        `Test run set as ${
          isTestSafe(testRun) ? 'not deletable' : 'deletable'
        } by data retention policy`,
        'success'
      )

      return Promise.all([
        queryClient.invalidateQueries(toSafeTestRunsQueryKey(orgId)),
        queryClient.invalidateQueries(toTestRunQueryKey(id)),
        queryClient.invalidateQueries(toTestQueryKey(testId)),
      ])
    },
  })
}
