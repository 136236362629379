import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import type * as monacoType from 'monaco-editor/esm/vs/editor/editor.api'
import { useFormContext } from 'react-hook-form'
import { LoadingPlaceholder } from '@grafana/ui'
import { color, spacing } from 'utils/styled'
import { CodeEditor } from 'components/CodeEditor'
import { Button } from 'components/Button'
import { useDebouncedScriptValidation } from '../../hooks/useDebouncedScriptValidation'
import {
  NotificationChannelDraft,
  NotificationClient,
  NotificationScriptError,
} from '../../NotificationsTab.types'
import { toNotificationClientType } from './NotificationForm.utils'
import { NotificationTemplateContextDrawer } from './NotificationTemplateContextDrawer'
import { Flex } from '../../../../../../components/Flex'
import {
  NOTIFICATION_CHANNEL_DRAFTS,
  NOTIFICATION_CHANNELS_META,
} from '../../NotificationsTab.constants'

// node_modules/monaco-editor/esm/vs/editor/editor.api.d.ts
// we can't import this because it doesn't get tree-shaken and brings in 5MB of code
// yes, you read that right. 5. whole. megabytes. for this tiny enum.

enum MarkerSeverity {
  Hint = 1,
  Info = 2,
  Warning = 4,
  Error = 8,
}

interface NotificationScriptEditorProps {
  scriptErrorOnMutation?: NotificationScriptError
  handleIsValidatingScript(value: boolean): void
}

export const NotificationScriptEditor = ({
  scriptErrorOnMutation,
  handleIsValidatingScript,
}: NotificationScriptEditorProps) => {
  const form = useFormContext<NotificationChannelDraft>()
  const notificationDraft = form.getValues()
  const selectedClient = toNotificationClientType(notificationDraft)

  const [scriptValue, setScriptValue] = useState(notificationDraft.template)

  const [editorRef, setEditorRef] =
    useState<null | monacoType.editor.IStandaloneCodeEditor>(null)

  const [monacoInstance, setMonacoInstance] = useState<typeof monacoType>()

  const [isDocsDrawerOpen, setIsDocsDrawerOpen] = useState(false)

  const { isValidatingScript, scriptError } = useDebouncedScriptValidation(
    scriptValue,
    scriptErrorOnMutation
  )

  useEffect(() => {
    handleIsValidatingScript(isValidatingScript)
  }, [handleIsValidatingScript, isValidatingScript])

  useEffect(() => {
    const model = editorRef?.getModel()
    const didEditorProperlyMount = editorRef && monacoInstance && model

    if (!didEditorProperlyMount) {
      return
    }

    //Clear errors
    if (!scriptError) {
      monacoInstance.editor.setModelMarkers(model, 'notification-script', [])
      return
    }

    const { line, position, message } = scriptError

    if (model && line) {
      // If positions are not specified, mark the whole line (Jinja validation provides only line)
      monacoInstance.editor.setModelMarkers(model, 'notification-script', [
        {
          message: message,
          severity: MarkerSeverity.Error,
          startLineNumber: line,
          startColumn: position || model.getLineFirstNonWhitespaceColumn(line),
          endLineNumber: line,
          endColumn: position
            ? position + 1
            : model.getLineLastNonWhitespaceColumn(line),
        },
      ])
    }
  }, [scriptError, monacoInstance, editorRef])

  // As an uncontrolled input, the script editor is not  aware that script was changed unless it re-renders
  useEffect(() => {
    setScriptValue(notificationDraft.template)
  }, [notificationDraft.template])

  const onDrawerToggleHandler = () => {
    setIsDocsDrawerOpen((isToggled) => !isToggled)
  }

  const onEditorDidMount = (
    monaco: typeof monacoType,
    editorRef: monacoType.editor.IStandaloneCodeEditor | null
  ) => {
    setEditorRef(editorRef)
    setMonacoInstance(monaco)
  }

  const handleOnChange = (value: string) => {
    setScriptValue(value)
    form.setValue('template', value, { shouldDirty: true })
  }

  const onResetScript = () => {
    handleOnChange(NOTIFICATION_CHANNEL_DRAFTS[selectedClient].template)
  }

  const canResetScript =
    scriptValue !== NOTIFICATION_CHANNEL_DRAFTS[selectedClient].template

  return (
    <Wrapper>
      <Header>
        <p>
          Edit the notification template to customize the format and the
          information on your notification{' '}
        </p>
        <Flex direction="row">
          {canResetScript && (
            <Button
              onClick={onResetScript}
              size="md"
              variant="primary"
              fill="outline"
              tooltip={`Reset to the default ${NOTIFICATION_CHANNELS_META[selectedClient].name} template`}
            >
              Reset
            </Button>
          )}

          <Button
            onClick={onDrawerToggleHandler}
            icon="info-circle"
            size="md"
            variant="secondary"
          >
            Available Parameters
          </Button>
        </Flex>
      </Header>
      <EditorContainer>
        <CodeEditor
          value={scriptValue}
          onChange={handleOnChange}
          language={
            selectedClient === NotificationClient.EMAIL ? 'html' : 'json'
          }
          checkJinja
          onEditorDidMount={onEditorDidMount}
        />
        {isValidatingScript && (
          <LoadingIndicator>
            <LoadingPlaceholder text="Validating..." />
          </LoadingIndicator>
        )}
      </EditorContainer>
      <NotificationTemplateContextDrawer
        onClose={() => setIsDocsDrawerOpen(false)}
        isOpen={isDocsDrawerOpen}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  height: ${spacing(5)};
  padding: ${spacing(1, 2)};
  gap: ${spacing(4)};
  background-color: ${color('background', 'secondary')};

  button {
    margin-left: ${spacing(2)};
  }
  p:first-child {
    margin: 0;
  }
`

const EditorContainer = styled.div`
  position: relative;
  width: 100%;
`

const LoadingIndicator = styled.div`
  position: absolute;
  top: ${spacing(1)};
  right: ${spacing(1)};
  background-color: ${color('background', 'primary')};
  display: flex;
  align-items: center;
  padding: ${spacing(0.25, 2)};
  border: 1px solid ${({ theme }) => theme.colors.border.strong};

  div:first-child {
    margin: 0;
  }
`
