import React from 'react'

import { WebVitalINP } from 'types/insights/webVitals'
import { WEB_VITAL_CONFIG, WEB_VITALS_THRESHOLDS } from 'constants/webVitals'

import { InsightListItem } from '../InsightItem'
import { SkippedDescription } from '../SkippedDescription'
import { InsightBody } from '../InsightBody'
import { docs, ReadMore } from 'components/DocLink'

const minThreshold = WEB_VITALS_THRESHOLDS.web_vital_inp[0].toFixed(0)

const maxThreshold = WEB_VITALS_THRESHOLDS.web_vital_inp[1].toFixed(0)

interface WebVitalINPItemProps {
  insight: WebVitalINP
}

const WebVitalINPDescription = ({ insight }: WebVitalINPItemProps) => {
  switch (insight.status) {
    case 'PASSED':
      return (
        <>
          Overall responsiveness to user interactions was within {minThreshold}{' '}
          {WEB_VITAL_CONFIG.web_vital_inp.unit}.{' '}
          <ReadMore article={docs.insights.webVital.inp} />
        </>
      )

    case 'WARNING':
      return (
        <>
          Overall responsiveness exceeded {minThreshold}{' '}
          {WEB_VITAL_CONFIG.web_vital_inp.unit}.
        </>
      )

    case 'FAILED':
      return (
        <>
          Overall responsiveness exceeded {maxThreshold}{' '}
          {WEB_VITAL_CONFIG.web_vital_inp.unit}.
        </>
      )

    default:
      return <SkippedDescription insight={insight} />
  }
}

export const WebVitalINPItem = ({ insight }: WebVitalINPItemProps) => {
  return (
    <InsightListItem header="Interaction to Next Paint" insight={insight}>
      <WebVitalINPDescription insight={insight} />
    </InsightListItem>
  )
}

export const WebVitalINPContent = () => {
  return (
    <InsightBody>
      <p>
        Interaction to Next Paint (INP) is a metric that assesses a page{"'"}s
        overall responsiveness to user interactions by observing the latency of
        all click, tap, and keyboard interactions that occur throughout the
        lifespan of a user{"'"}s visit to a page.
      </p>
      <h4>Here are some strategies to improve Interaction to Next Paint:</h4>
      <ul>
        <li>
          <strong>Optimize JavaScript Execution:</strong> Minimize and optimize
          JavaScript code. Remove unnecessary scripts, and consider using code
          splitting to load only essential JavaScript for the current view.
        </li>
        <li>
          <strong>Async and Defer Attribute for Scripts:</strong> Use the async
          or defer attribute when including scripts in your HTML. This allows
          the browser to continue parsing the HTML without blocking, resulting
          in a faster page load and reduced INP.
        </li>
        <li>
          <strong>Web Workers:</strong> Consider using Web Workers to offload
          heavy JavaScript tasks to a separate thread, preventing them from
          blocking the main thread and improving responsiveness.
        </li>
        <li>
          <strong>Optimize Third-Party Scripts:</strong> Limit the use of
          third-party scripts, and if necessary, load them asynchronously.
          Third-party scripts can significantly contribute to INP if they are
          large or cause delays in execution.
        </li>
        <li>
          <strong>Minimize DOM size:</strong> When a webpage{"'"}s Document
          Object Model (DOM) is small, rendering tasks are usually quick.
          However, as the DOM size increases, rendering work tends to scale,
          leading to a non-linear relationship. Large DOMs pose challenges
          during the initial page render, requiring substantial rendering work,
          and during user interactions, where updates can be expensive, delaying
          the presentation of the next frame.
        </li>
      </ul>

      <p>
        Remember that the key to optimizing Interaction to Next Paint is to
        reduce any factors that can delay or interfere with user interactions on
        your website. Focusing on efficient resource loading, minimizing
        render-blocking, and prioritizing critical tasks can contribute to a
        more responsive user experience.
      </p>
    </InsightBody>
  )
}
