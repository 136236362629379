import React from 'react'
import styled from 'styled-components'

import { type TestRun } from 'types'
import { type InsightCategory } from 'types/cloudInsights'
import { Flex } from 'components/Flex'

import { SCORE_SIZE } from '../../constants'
import { useCategoryResult } from './CloudInsightsCategory.hooks'
import { AuditStats } from './AuditStats'
import { Score } from './Score'

interface HeaderProps {
  category: InsightCategory
  testRun: TestRun
}

export const Header = ({ category, testRun }: HeaderProps) => {
  const { data: result } = useCategoryResult(category.id, testRun)

  return (
    <Flex align="center" gap={1} padding={1} width="100%">
      <Flex
        align="center"
        justify="center"
        width={SCORE_SIZE}
        height={SCORE_SIZE}
      >
        <Score result={result} />
      </Flex>
      <Flex direction="column" width="100%">
        <Title>{category.title}</Title>
        <AuditStats category={category} testRun={testRun} />
      </Flex>
    </Flex>
  )
}

const Title = styled.h5`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  margin: 0;
  text-transform: uppercase;
`
