import React, { type ReactNode } from 'react'
import { Alert } from '@grafana/ui'

import { Button } from 'components/Button'
import { Flex } from 'components/Flex'

const defaultTitle = 'Something went wrong'
const defaultContent =
  'An error has occurred, this can be caused by either poor connectivity or an error with our servers. If you have an ad blocking extension installed in your browser, try disabling it and reload the page.'

interface ErrorBoundaryAlertProps {
  buttonText: string
  onClick: () => void
  title?: string
  content?: ReactNode | string
  className?: string
}
export const ErrorBoundaryAlert = ({
  buttonText,
  content = defaultContent,
  onClick,
  title = defaultTitle,
  className,
}: ErrorBoundaryAlertProps) => {
  return (
    <Alert severity="error" title={title} className={className}>
      <Flex align="center" justify="space-between" gap={2}>
        <div>{content}</div>

        <Button variant="secondary" onClick={onClick}>
          {buttonText}
        </Button>
      </Flex>
    </Alert>
  )
}
