import React from 'react'
import { Select } from '@grafana/ui'

import { FolderPermission, ResourcePermission } from 'types/rbac'

interface ProjectPermissionsDrawerProps {
  assignment: ResourcePermission
  onChange: (permission: FolderPermission) => void
}

export function PermissionsSelector({
  assignment: { isManaged, permission },
  onChange,
}: ProjectPermissionsDrawerProps) {
  return (
    <Select
      disabled={!isManaged}
      onChange={(p) => onChange(p.value as FolderPermission)}
      value={FolderPermission[permission]}
      options={Object.values(FolderPermission).map((p) => ({
        value: p,
        label: p,
      }))}
    />
  )
}
