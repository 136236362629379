import React from 'react'
import { GaugeArrow } from './GaugeArrow'
import { WebVitalValueConfig } from './types'
import {
  Legend,
  Partition,
  PartitionContainer,
} from './WebVitalBarGauge.styled'
import { getArrowPosition, webVitalFormatter } from './WebVitalBarGauge.utils'

interface WebVitalBarGaugeProps {
  value: WebVitalValueConfig
  compareValue?: WebVitalValueConfig | null
}

export function WebVitalBarGauge({
  value,
  compareValue = null,
}: WebVitalBarGaugeProps) {
  const { originalValue = null } = value
  const originalCompareValue = compareValue?.originalValue ?? null

  const hasValue = originalValue !== null
  const hasCompareValue = originalCompareValue !== null
  const thresholds = value.thresholds
  const [needsImprovement, poor] = thresholds

  const valuePosition = hasValue
    ? getArrowPosition(thresholds, originalValue)
    : 0
  const compareValuePosition = hasCompareValue
    ? getArrowPosition(thresholds, originalCompareValue)
    : 0

  const leftScore = value.score ?? undefined
  const rightScore = compareValue?.score ?? undefined

  return (
    <div>
      <PartitionContainer
        className={`score--${leftScore} score--${rightScore}`}
      >
        <Partition />
        <Partition />
        <Partition />
        {hasValue && (
          <GaugeArrow
            type={hasCompareValue ? 'primary' : undefined}
            value={valuePosition}
          />
        )}
        {hasCompareValue && (
          <GaugeArrow type="secondary" value={compareValuePosition} />
        )}
      </PartitionContainer>
      <Legend>
        <div>
          {webVitalFormatter(needsImprovement, value.unitType)}
          {value.unit}
        </div>
        <div>
          {webVitalFormatter(poor, value.unitType)}
          {value.unit}
        </div>
      </Legend>
    </div>
  )
}
