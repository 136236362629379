import styled from 'styled-components'

import { spacing } from 'utils/styled'

import { LoadZoneLabel } from '../LoadZoneLabel'

export const LoadZoneContainer = styled.div<{ $gridColumns: number }>`
  font-size: 13px;
  display: grid;
  grid-template-columns: ${({ $gridColumns }) =>
    `repeat(${$gridColumns}, auto)`};
  column-gap: ${spacing(1.25)};
  row-gap: ${spacing(1)};
  margin-bottom: ${spacing(1)};
  min-width: 200px;
`

export const Title = styled.span`
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`

export const Label = styled(LoadZoneLabel)`
  margin: 0;
`
