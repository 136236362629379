import styled from 'styled-components'
import { color, spacing } from 'utils/styled'
import { SectionStyled } from './components/Section.styled'

const pluginPagePadding = 3 // 24px

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing(pluginPagePadding)};
  margin: ${spacing(0, -pluginPagePadding - 1, -pluginPagePadding - 1)};
  padding: ${spacing(pluginPagePadding - 1)};
  border: 1px ${color('border', 'medium')} solid;

  // Match PluginPage media query
  @media (min-width: 769px) {
    margin: ${spacing(
      0,
      -pluginPagePadding,
      -pluginPagePadding
    )}; // negate padding from PluginPage
    padding: ${spacing(pluginPagePadding)}; // re-create PluginPage padding
  }

  > ${SectionStyled}:last-child {
    margin-bottom: 0;
  }

  > div {
    width: 100%;
    overflow-x: auto;
  }
`

export const ContainedImage = styled.img`
  max-width: 100%;
  object-fit: scale-down;
`
