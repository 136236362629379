import { Link } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components'
import { colorManipulator } from '@grafana/data'

import { color, spacing } from 'utils/styled'
import { Flex } from 'components/Flex'

const { lighten, alpha } = colorManipulator

const pendingAnimation = keyframes`
  0% {
    top: 110%;
  }
  100% {
    top: -210%;
  }
`

export const Container = styled.div`
  position: relative;
  min-height: 125px;
  height: 100%;
  width: 100%;
  background-color: ${color('background', 'primary')};
  border: 1px ${color('border', 'weak')} solid;
`

export const Body = styled(Flex)<{ isEmpty: boolean }>`
  padding-bottom: ${({ isEmpty }) => spacing(isEmpty ? 1 : 2)};
`

export const Bar = styled(Link)`
  position: relative;
  height: 100%;
  width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: background-color 0.2s ease-out;
  background-color: ${color('background', 'secondary')};
  overflow: hidden;

  &:hover {
    background-color: ${({ theme }) =>
      theme.isLight
        ? alpha(theme.colors.background.secondary, 0.6)
        : lighten(theme.colors.background.secondary, 0.1)};
  }
`

export const BarValue = styled.div<{ $color: string }>`
  position: relative;
  min-height: 2px;
  transition: height 0.3s ease-in-out, background-color 0.2s ease-out;
  height: 50%;
  overflow: hidden;
  border-radius: 1px;

  ${({ $color, theme }) => {
    const color = theme.visualization.getColorByName($color)
    if (!color) {
      return
    }
    return css`
      background-color: ${color};
    `
  }}

  &:hover {
    ${({ $color, theme }) =>
      !!$color &&
      $color !== 'transparent' &&
      css`
        background-color: ${lighten(
          theme.visualization.getColorByName($color),
          0.4
        )};
      `}

    .pass {
      background-color: #73bf69;
    }

    .fail {
      background-color: #d10e5c;
    }

    .error {
      background-color: rgba(204, 204, 220, 0.16);
    }

    .aborted {
      background-color: #f5b73d;
    }
  }
`

export const WorkingAnimation = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.245) 100%
    );
    height: 200%;
    width: 100%;
    content: '';
    display: block;
    animation: ${pendingAnimation} 2.5s infinite ease-in-out;
    filter: blur(50%);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }
`

export const PlaceholderText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`
