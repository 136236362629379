import {
  LegendDisplayMode,
  TooltipDisplayMode,
  SortOrder,
  LogsDedupStrategy,
  LogsSortOrder,
} from '@grafana/schema'
import { LogPanelOptions, TimeSeriesOptions } from 'types/panels'

export const timeSeriesPanelOptions: TimeSeriesOptions = {
  legend: {
    showLegend: true,
    displayMode: LegendDisplayMode.List,
    placement: 'bottom',
    calcs: [],
  },
  tooltip: {
    mode: TooltipDisplayMode.Multi,
    sort: SortOrder.None,
  },
}

export const logPanelOptions: LogPanelOptions = {
  showTime: true,
  showLabels: false,
  showCommonLabels: false,
  wrapLogMessage: false,
  prettifyLogMessage: false,
  enableLogDetails: true,
  dedupStrategy: LogsDedupStrategy.none,
  sortOrder: LogsSortOrder.Ascending,
}
