import styled from 'styled-components'

import { Card as CardBase } from 'components/Card'
import { TrendChart } from 'components/TrendChart'
import { spacing } from 'utils/styled'

export const TestCardTrendingChart = styled(TrendChart)`
  flex: 1 1 0;
`

export const SelectionOverlay = styled.div<{
  disabled: boolean
  selected: boolean
}>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: absolute;
  inset: 0;
  z-index: 2;
  transition: outline 100ms ease-in-out;
  outline: 2px solid
    ${({ selected, theme }) =>
      selected ? theme.colors.primary.border : 'transparent'};
  user-select: none;
`

export const TrendMetaWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${spacing(1)};
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
`

export const Card = styled(CardBase)`
  height: 100%;
`
