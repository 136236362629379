import { Flex } from 'components/Flex'
import styled from 'styled-components'
import { spacing } from 'utils/styled'

export const FiltersContainer = styled.div`
  > div {
    min-width: 320px;
  }
`

export const SpinnerWrapper = styled.div`
  margin-top: ${spacing(8)};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TopRow = styled.div`
  display: flex;
  margin-bottom: ${spacing(3)};
  justify-content: flex-end;
  gap: ${spacing(1)};
  position: relative;
  z-index: 0;

  &:empty {
    margin-bottom: 0;
  }
`

export const PageContainer = styled.div`
  container: page-container / inline-size;
`

export const SubTitleContainer = styled(Flex)`
  min-height: 30px;
`
