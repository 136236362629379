import { Account } from 'types'
import { useAccount } from 'data/useAccount'

export function useIsLegacyUser() {
  const { data: account } = useAccount()

  return isLegacyUser(account)
}

export function isLegacyUser(account?: Account) {
  if (!account) {
    return false
  }

  return typeof getLegacyUserOnboardingLevel(account) !== 'undefined'
}

export function getLegacyUserOnboardingLevel(account: Account | undefined) {
  const additionalUserData = account?.additional_user_data?.[0]
  const appJson = additionalUserData?.app_json

  return appJson?.gck6_onboarding
}
