import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { first } from 'lodash-es'
import { ButtonProps, Menu } from '@grafana/ui'
import { Button } from 'components/Button'
import { TestWithTrends } from 'types'
import { isTestActive } from 'utils/testRun'
import { routeMap } from 'routeMap'
import { useConfirmation } from 'hooks/useConfirmation'
import { useDeleteTests } from 'data/useDeleteTests'
import { useRunTest } from 'data/useRunTest'
import { useHasUserProjectWriteAccess } from 'data/usePermissions'
import { useStopTestRunAction } from 'data/useStopTestRun'
import { useTrendPolling } from 'data/useTestWithTrends'
import { ActionsMoreDropdown } from 'components/ActionsMoreDropdown'
import { ConfirmModal } from 'components/ConfirmModal'
import { LinkButton } from 'components/LinkButton'
import { RunButtonController } from 'components/RunButtonController'
import { useViewerButtonTooltipProps } from 'hooks/useViewerButtonTooltipProps'
import { Flex } from 'components/Flex'
import { useCurrentProject } from 'projectContext'
import { useMaxConcurrentRunsModal } from 'components/QueueTestModal/MaxConcurrentRunsModal'
import { QueueTestModal } from 'components/QueueTestModal/QueueTestModal'
import { isScriptTest, isUploadedTest } from 'utils/test'

export const RUN_BUTTON_UPLOADED_TOOLTIP =
  'A test run has been uploaded but remains pending execution.'
export const EDIT_BUTTON_UPLOADED_TOOLTIP = `${RUN_BUTTON_UPLOADED_TOOLTIP} The corresponding test script will only become visible once the test has been initiated and completed.`

interface TestActionsProps {
  test?: TestWithTrends
}

export const TestActions = ({ test }: TestActionsProps) => {
  const history = useHistory()
  const project = useCurrentProject()
  const hasUserProjectWriteAccess = useHasUserProjectWriteAccess()
  const [deleteConfirmationProps, handleConfirmDelete] =
    useConfirmation(handleDeleteTest)
  const { data: latestTest } = useTrendPolling(test!)
  const { mutateAsync: runTest, isLoading: isTestRunLoading } = useRunTest()
  const { mutateAsync: deleteTests } = useDeleteTests(project.id)
  const latestTestRun = first(latestTest.test_runs)
  const [stopTestRunConfirmationProps, handleStopTestRunClick, canStopTestRun] =
    useStopTestRunAction()
  const isStopDisabled = useMemo(
    () => canStopTestRun(latestTestRun),
    [canStopTestRun, latestTestRun]
  )

  const readUserButtonProps = useViewerButtonTooltipProps()

  const [runButtonUploadedTooltip, editButtonUploadedTooltip] = useMemo<
    [ButtonProps, ButtonProps] | [undefined, undefined]
  >(() => {
    if (test && isUploadedTest(test)) {
      return [
        {
          tooltip: RUN_BUTTON_UPLOADED_TOOLTIP,
        },
        {
          icon: 'info-circle',
          tooltip: EDIT_BUTTON_UPLOADED_TOOLTIP,
          disabled: true,
        },
      ]
    }

    return [undefined, undefined]
  }, [test])

  async function handleRunTest() {
    if (test) {
      const { id } = await runTest(test)
      history.push(routeMap.testRun(id))
    }
  }

  const { onRun, ...maxConcurrentRunsModalProps } =
    useMaxConcurrentRunsModal(handleRunTest)

  function handleDeleteTest() {
    if (test) {
      deleteTests([test]).then(() => {
        history.replace(routeMap.project(project.id))
      })
    }
  }

  if (!test) {
    return null
  }

  const isActive = !!latestTestRun && isTestActive(latestTestRun)
  const isScriptedTest = isScriptTest(test)

  return (
    <>
      <Flex gap={1} wrap="nowrap">
        <RunButtonController test={test}>
          {({ isDisabled }) => (
            <Button
              key="settings"
              disabled={isTestRunLoading || isDisabled}
              icon={isTestRunLoading ? 'fa fa-spinner' : 'play'}
              onClick={onRun}
              {
                ...runButtonUploadedTooltip /* Important: before `readUserButtonProps` */
              }
              {...readUserButtonProps}
            >
              Run
            </Button>
          )}
        </RunButtonController>
        <LinkButton
          to={routeMap.editTest(test)}
          button={{
            variant: 'secondary',
            icon: isScriptedTest ? 'brackets-curly' : 'cog',
            ...editButtonUploadedTooltip,
          }}
        >
          Edit
        </LinkButton>

        <ActionsMoreDropdown
          overlay={
            <DropMenu
              isActive={isActive}
              isDeleteDisabled={!hasUserProjectWriteAccess || isActive}
              isStopDisabled={isStopDisabled}
              onConfirmDelete={handleConfirmDelete}
              onConfirmStop={() =>
                latestTestRun && handleStopTestRunClick(latestTestRun)
              }
            />
          }
        />
      </Flex>

      <ConfirmModal
        {...deleteConfirmationProps}
        title={`Delete test "${test?.name}"?`}
        body="This action cannot be undone."
        confirmText="Delete"
      />
      <ConfirmModal {...stopTestRunConfirmationProps} />
      <QueueTestModal {...maxConcurrentRunsModalProps} />
    </>
  )
}

interface DropMenuProps {
  isActive: boolean
  isDeleteDisabled: boolean
  isStopDisabled: boolean
  onConfirmDelete: () => void
  onConfirmStop: () => void
}

const DropMenu = ({
  isActive,
  isDeleteDisabled,
  isStopDisabled,
  onConfirmDelete,
  onConfirmStop,
}: DropMenuProps) => {
  return (
    <>
      <Menu>
        {isActive && (
          <Menu.Item
            disabled={isStopDisabled}
            label="Stop Test"
            icon="minus-circle"
            onClick={onConfirmStop}
            destructive
          />
        )}
        <Menu.Item
          disabled={isDeleteDisabled}
          label="Delete test"
          icon="trash-alt"
          onClick={onConfirmDelete}
          destructive
        />
      </Menu>
    </>
  )
}
