import styled, { css } from 'styled-components'
import { IconButton } from '@grafana/ui'

export const IconButtonStyled = styled(IconButton)<{ $active: boolean }>`
  ${({ $active }) =>
    $active &&
    css`
      color: ${({ theme }) => theme.colors.success.text};
    `}
`
