import React, { FormEventHandler } from 'react'
import { useForm, FieldErrors, UseFormRegister } from 'react-hook-form'
import { Button, Field, Input, Modal } from '@grafana/ui'

import { EnvironmentVariable } from 'types'
import { toFormErrors } from 'utils/form'

import {
  useCreateEnvironmentVariable,
  useUpdateEnvironmentVariable,
} from '../../hooks/useEnvironmentVariable'
import { validation } from './EnvVariableModal.utils'
import { SubTitle } from './EnvVariableModal.styles'
import { showAlert } from 'utils/showAlert'

interface ActionEnvVarModalProps {
  isOpen: boolean
  values?: EnvironmentVariable
  onDismiss: () => void
}

interface EnvVarModalProps extends ActionEnvVarModalProps {
  isLoading: boolean
  actionText: string
  errors: FieldErrors<EnvironmentVariable>
  register: UseFormRegister<EnvironmentVariable>
  subTitle: string
  onSubmit: FormEventHandler<HTMLFormElement>
}

const EnvVariableModal = ({
  isOpen = false,
  isLoading = false,
  actionText,
  errors,
  register,
  subTitle,
  onSubmit,
  onDismiss,
}: EnvVarModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      title={`${actionText} environment variable`}
      onDismiss={onDismiss}
    >
      <SubTitle>{subTitle}</SubTitle>

      <form onSubmit={onSubmit}>
        <Field
          htmlFor="name"
          error={errors?.name?.message}
          invalid={!!errors?.name}
          label="Name"
        >
          <Input
            {...register('name', validation.name)}
            placeholder="Enter name"
            autoComplete="off"
            disabled={isLoading}
          />
        </Field>
        <Field
          htmlFor="description"
          error={errors?.description?.message}
          invalid={!!errors?.description}
          label="Description"
        >
          <Input
            {...register('description', validation.description)}
            placeholder="Enter description"
            autoComplete="off"
            disabled={isLoading}
          />
        </Field>
        <Field
          htmlFor="value"
          error={errors?.value?.message}
          invalid={!!errors?.value}
          label="Value"
        >
          <Input
            {...register('value', validation.value)}
            placeholder="Enter value"
            autoComplete="off"
            disabled={isLoading}
          />
        </Field>

        <Modal.ButtonRow>
          <Button variant="secondary" onClick={onDismiss} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isLoading}
            icon={isLoading ? 'fa fa-spinner' : undefined}
          >
            Submit
          </Button>
        </Modal.ButtonRow>
      </form>
    </Modal>
  )
}

export const CreateEnvVariableModal = ({
  onDismiss,
  ...props
}: ActionEnvVarModalProps) => {
  const {
    formState: { errors },
    handleSubmit: onSubmit,
    register,
    reset,
    setError,
  } = useForm<EnvironmentVariable>()

  const { mutate, isLoading } = useCreateEnvironmentVariable({
    onSuccess: handleDismiss,
    onError: (errors) => {
      const formErrors = toFormErrors(errors)

      if (formErrors.length > 0) {
        formErrors.forEach(([field, error]) => setError(field, error))
        return
      }

      showAlert('Failed to create environment variable', 'error')
    },
  })

  const handleSubmit = onSubmit((values) => {
    mutate(values)
  })

  function handleDismiss() {
    reset()
    onDismiss()
  }

  return (
    <EnvVariableModal
      {...props}
      errors={errors}
      actionText="Create new"
      isLoading={isLoading}
      register={register}
      subTitle="Create a new environment variable by providing a name and a value"
      onDismiss={handleDismiss}
      onSubmit={handleSubmit}
    />
  )
}

export const EditEnvVariableModal = ({
  onDismiss,
  values,
  ...props
}: ActionEnvVarModalProps) => {
  const {
    formState: { errors },
    handleSubmit: onSubmit,
    register,
    reset,
    setError,
  } = useForm<EnvironmentVariable>({ values })

  const { mutate, isLoading } = useUpdateEnvironmentVariable({
    onSuccess: handleDismiss,
    onError: (errors) => {
      const formErrors = toFormErrors(errors)

      if (formErrors.length > 0) {
        formErrors.forEach(([field, error]) => setError(field, error))
        return
      }

      showAlert('Failed to update environment variable', 'error')
    },
  })

  const handleSubmit = onSubmit((values) => {
    mutate(values)
  })

  function handleDismiss() {
    reset()
    onDismiss()
  }

  return (
    <EnvVariableModal
      {...props}
      errors={errors}
      actionText="Edit"
      isLoading={isLoading}
      register={register}
      subTitle="Edit environment variables configuration"
      onDismiss={handleDismiss}
      onSubmit={handleSubmit}
    />
  )
}
