import {
  LoadTestsV2Response,
  Test,
  TestId,
  TestRun,
  TestWithTrends,
  TrendingMetric,
} from 'types'
import { isTestActive } from 'utils/testRun'
import { useQuery } from '@tanstack/react-query'
import { useDatasource } from 'datasourceContext'
import { K6DataSource } from 'datasource/datasource'
import { BG_TASKS_POLL_INTERVAL } from '../constants'
import { toTestWithTrendsQueryKey } from 'data/queryKeys'
import { isDefined } from 'utils/typescript'
import { ODataQueryOptions, toSelectQuery } from 'datasource/serialization'
import { useActiveTestRuns } from './useActiveTestRuns'
import { toMetricByConfigId, toTestWithTrends } from 'utils/trends'
import { queryClient } from './queryClient'
import { toTestTrendingMetricsOptionsKey } from './queryKeys/trendingMetrics'
import { validRunsFilter } from 'datasource/queryData/entities'

type TestWithTrendsResponse = LoadTestsV2Response<Test | TrendingMetric[]>

export const testWithTrendsParams: ODataQueryOptions<Test> = {
  select: [
    'id',
    'name',
    'project_id',
    'created',
    'creation_process',
    'trending_metrics',
    'vuh_cost_total',
    'schedule',
    'updated',
  ],
  expand: {
    test_runs: {
      select: [
        'created',
        'distribution',
        'duration',
        'execution_duration',
        'id',
        'nodes',
        'note',
        'processing_status',
        'result_status',
        'run_process',
        'run_status',
        'started',
        'test_id',
        'vus',
        'vuh_cost',
        'delete_status',
      ],
      top: 40,
      orderBy: [['id', 'desc']],
      filter: validRunsFilter,
    },
  },
}

export const isCalculatingTrendingValues = (test: TestWithTrends) => {
  return !!test.trends.find(({ values }) =>
    values.find(({ calculated }) => !calculated)
  )
}

const hasActiveTestRun = (test: Test, activeRuns: TestRun[]) => {
  return (
    test.test_runs.some(isTestActive) ||
    activeRuns.some((run) => run.test_id === test.id)
  )
}

const fetchTestWithTrends = (ds: K6DataSource, id: TestId) => {
  return ds
    .get<TestWithTrendsResponse>(`loadtests/v2/tests/${id}`, {
      params: toSelectQuery(testWithTrendsParams),
    })
    .then((response) =>
      toTestWithTrends(
        response['k6-test'],
        toMetricByConfigId(response['k6-runs-trending-metrics'])
      )
    )
}

export const useTrendPolling = (test: TestWithTrends) => {
  const { ds } = useDatasource()

  const activeRuns = useActiveTestRuns()
    .map((query) => query.data)
    .filter(isDefined)
    .filter((run) => run.test_id === test.id)

  return useQuery({
    queryKey: toTestWithTrendsQueryKey(test.id),
    queryFn: () => fetchTestWithTrends(ds, test.id),
    enabled:
      hasActiveTestRun(test, activeRuns) || isCalculatingTrendingValues(test),
    initialData: test,
    refetchIntervalInBackground: true,
    refetchInterval: (test) => {
      if (
        test !== undefined &&
        (hasActiveTestRun(test, activeRuns) ||
          isCalculatingTrendingValues(test))
      ) {
        return BG_TASKS_POLL_INTERVAL
      }

      return false
    },
  })
}

export const useTestWithTrends = (
  id: TestId,
  { enabled, staleTime }: { enabled?: boolean; staleTime?: number } = {
    enabled: true,
  }
) => {
  const { ds } = useDatasource()

  return useQuery({
    queryKey: toTestWithTrendsQueryKey(id),
    queryFn: () => fetchTestWithTrends(ds, id),
    onSuccess: () => {
      queryClient.invalidateQueries(toTestTrendingMetricsOptionsKey(id))
    },
    enabled,
    staleTime,
  })
}
