import { uniq, uniqBy } from 'lodash'

import { type AnalysisMetrics, type MetricsByOrigin } from './AnalysisTab.types'
import { Metric, MetricCategory, MetricOrigin } from 'types'
import { toBaseUnit } from 'utils/units'
import { withProtocolPrefix } from 'utils/options/metricOptions'
import { getMethodOptions } from 'utils/options/aggregations/timeSeries'
import {
  type MetricConfig,
  BuiltinMetrics,
  BUILTIN_METRICS_VALUES,
  isMetricConfig,
  PlotType,
  TestRunConfigType,
  ColorGroups,
} from 'datasource/models'
import { ALL_METRICS } from 'constants/metrics'
import { ColorPool } from 'utils/colorPool'

export function getAnalysisMetrics(
  origins: MetricsByOrigin,
  colorPool: ColorPool
) {
  const { script, builtin } = origins
  const colorGroups = Object.values(ColorGroups)
  const customMetrics = script.map((metric, i) => {
    const index = i % colorGroups.length
    const colorGroup = colorGroups[index]!
    return generateCustomMetricConfig(metric, colorGroup)
  })

  const metricNames = [...script, ...builtin].map(({ name }) => name)

  const metrics = [...customMetrics, ...ALL_METRICS].filter(({ name }) =>
    metricNames.includes(name)
  )

  return format(metrics, colorPool)
}

export function sharedAnalysisMetrics({ main, compareWith }: AnalysisMetrics) {
  return uniqBy([...main, ...compareWith], ({ name }) => name)
}

export function metricsByOrigin(metrics: Metric[]) {
  return metrics.reduce<MetricsByOrigin>(
    (acc, metric) => {
      let { name, origin } = metric
      origin = isKnownMetric(name) ? MetricOrigin.BuiltIn : origin

      if (!acc[origin]) {
        acc[origin] = [metric]
      } else {
        acc[origin].push(metric)
      }

      return acc
    },
    {
      [MetricOrigin.BuiltIn]: [],
      [MetricOrigin.Script]: [],
    }
  )
}

export const INITIAL_NUMBER_OF_METRICS_TO_SHOW = 6

const HTTP_ORDER = [
  BuiltinMetrics.HTTP_REQ_DURATION,
  BuiltinMetrics.HTTP_REQS,
  BuiltinMetrics.HTTP_REQ_FAILED,
]

const WS_ORDER = [
  BuiltinMetrics.WS_SESSIONS,
  BuiltinMetrics.WS_SESSION_DURATION,
]

const GRPC_ORDER = [BuiltinMetrics.GRPC_REQS, BuiltinMetrics.GRPC_REQ_DURATION]

const BROWSER_ORDER = [
  BuiltinMetrics.BROWSER_FIRST_CONTENTFUL_PAINT,
  BuiltinMetrics.BROWSER_LARGEST_CONTENTFUL_PAINT,
  BuiltinMetrics.BROWSER_CUMULATIVE_LAYOUT_SHIFT,
  BuiltinMetrics.BROWSER_TIME_TO_FIRST_BYTE,
  BuiltinMetrics.BROWSER_INTERACTION_TO_NEXT_PAINT,
  BuiltinMetrics.BROWSER_FIRST_INPUT_DELAY,
]

export function getInitialMetrics(metrics: MetricConfig[]) {
  const unknownMetrics = metrics.filter(
    ({ metricCategory }) => metricCategory === MetricCategory.CUSTOM
  )
  const sortedMetrics = uniq([
    ...HTTP_ORDER,
    ...WS_ORDER,
    ...GRPC_ORDER,
    ...BROWSER_ORDER,
    BuiltinMetrics.VUS,
    ...BUILTIN_METRICS_VALUES,
  ])
    .map((name) => metrics.find((metric) => metric.name === name))
    .filter(isMetricConfig)

  return [...unknownMetrics, ...sortedMetrics].slice(
    0,
    INITIAL_NUMBER_OF_METRICS_TO_SHOW
  )
}

function generateCustomMetricConfig(
  customMetric: Metric,
  colorGroup: ColorGroups
): MetricConfig {
  const unit = toBaseUnit(customMetric.name)

  return {
    type: TestRunConfigType.Metric,
    metricType: customMetric.type,
    metricCategory: MetricCategory.CUSTOM,
    name: customMetric.name,
    label: customMetric.name,
    description: `Custom ${customMetric.type} metric.`,
    plot: {
      type: PlotType.Area,
    },
    preferredColor: {
      group: colorGroup,
    },
    unit,
    query: {
      type: 'series',
      metric: customMetric.name,
      method: getMethodOptions(customMetric)[0].method,
      tags: {},
    },
  }
}

function format(metrics: MetricConfig[], colorPool: ColorPool) {
  return metrics.map((metric) => {
    const stableColorAssignment = colorPool.find(metric.preferredColor)

    if (
      metric.metricCategory &&
      [
        MetricCategory.HTTP,
        MetricCategory.WS,
        MetricCategory.GRPC,
        MetricCategory.BROWSER,
      ].includes(metric.metricCategory)
    ) {
      return {
        ...metric,
        // @ts-expect-error TODO: fix this
        label: withProtocolPrefix({
          label: metric.label,
          type: metric.metricCategory,
        }).label,
        color: stableColorAssignment,
      }
    }

    return {
      ...metric,
      color: stableColorAssignment,
    }
  })
}

function isKnownMetric(metricName: BuiltinMetrics | string) {
  return BUILTIN_METRICS_VALUES.includes(metricName as BuiltinMetrics)
}
