import React from 'react'
import { useTheme2 } from '@grafana/ui'

import { Flex } from 'components/Flex'

interface ShieldIconProps {
  className?: string
  size?: number
}

export const ShieldIcon = ({ size = 16, ...props }: ShieldIconProps) => {
  const theme = useTheme2()

  return (
    <Flex align="center">
      <svg
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        width={`${size}px`}
        height={`${size}px`}
        {...props}
      >
        <circle cx="12" cy="12" r="12" fill={theme.colors.border.medium} />
        <path
          d="M 17.826 5.626 C 17.647 5.48 17.411 5.424 17.185 5.473 C 15.536 5.819 13.817 5.467 12.436 4.502 C 12.173 4.321 11.827 4.321 11.564 4.502 C 10.183 5.467 8.464 5.819 6.815 5.473 C 6.589 5.424 6.353 5.48 6.174 5.626 C 5.994 5.771 5.89 5.99 5.891 6.221 L 5.891 11.91 C 5.889 14.131 6.962 16.217 8.77 17.508 L 11.558 19.493 C 11.822 19.681 12.178 19.681 12.442 19.493 L 15.23 17.508 C 17.038 16.217 18.111 14.131 18.109 11.91 L 18.109 6.221 C 18.11 5.99 18.006 5.771 17.826 5.626 Z"
          style={{ fill: theme.colors.text.secondary }}
        />
      </svg>
    </Flex>
  )
}
