import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useAppContext } from 'appContext'
import { useDatasource } from 'datasourceContext'
import { Project } from 'types'
import { toProjectQueryKey, toProjectsQueryKey } from './queryKeys'
import { QueryOptions } from './queryClient'

export const useProjectsByOrgId = (
  orgId: number,
  options?: QueryOptions<Project[]>
) => {
  const queryClient = useQueryClient()
  const { ds } = useDatasource()

  return useQuery<Project[]>({
    queryKey: toProjectsQueryKey(orgId),
    queryFn: () => ds.fetchProjects(orgId),
    ...options,
    onSuccess(data) {
      options?.onSuccess?.(data)
      data.forEach((project) => {
        queryClient.setQueryData(toProjectQueryKey(project.id), project)
      })
    },
  })
}

export const useProjects = (options?: QueryOptions<Project[]>) => {
  const { orgId } = useAppContext()
  return useProjectsByOrgId(orgId, options)
}
