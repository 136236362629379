import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@grafana/ui'

import { Section } from 'components/Section'
import { DocLink, docs } from 'components/DocLink'
import { routeMap } from 'routeMap'
import { Flex } from 'components/Flex'
import { RevealablePassword } from 'components/RevealablePassword'
import { useAppContext } from 'appContext'
import { RegenApiTokenModal } from './components/RegenApiTokenModal'

export const PersonalAPITokenTab = () => {
  const { account } = useAppContext()
  const [isRegenApiTokenModalOpen, setIsRegenApiTokenModalOpen] =
    useState(false)

  return (
    <Section
      title="Personal API token"
      description={
        <>
          Your{' '}
          <DocLink article={docs.integrations.token.accountAPIToken}>
            API token
          </DocLink>{' '}
          allows you to{' '}
          <Link to={routeMap.cliGuide}>run cloud tests from the CLI</Link>{' '}
          linking the run to your account. Read more about personal account
          tokens in the{' '}
          <DocLink article={docs.integrations.token.accountAPIToken}>
            docs
          </DocLink>
          .
        </>
      }
    >
      <Flex gap={1} width="100%">
        <RevealablePassword value={account.token.key} readOnly />
        <Button onClick={() => setIsRegenApiTokenModalOpen(true)}>
          Regenerate API token
        </Button>
      </Flex>
      <RegenApiTokenModal
        isOpen={isRegenApiTokenModalOpen}
        onDismiss={() => setIsRegenApiTokenModalOpen(false)}
      />
    </Section>
  )
}
