import React from 'react'

import type { Trend } from 'types'
import { Flex } from 'components/Flex'

import { useMinimalVariation } from '../../hooks'
import { getSteps, getValueWithUnit } from './YAxis.utils'
import { Container, HorizontalLine, Label } from './YAxis.styles'

interface YAxisProps {
  labelRef: (element: HTMLSpanElement | null) => void
  height?: number
  maxValue: number
  trend?: Trend
  width?: number
  variant?: 'minimal' | 'default'
}

export const YAxis = ({
  labelRef,
  height = 125,
  maxValue,
  width = 0,
  trend,
  variant,
}: YAxisProps) => {
  const isMinimal = useMinimalVariation(width, variant, trend)
  const steps = !isMinimal ? getSteps(height, maxValue) : [maxValue || null, 0]

  const setRef = (index: number) => (element: HTMLSpanElement | null) => {
    const targetIndex = !isMinimal ? 0 : 1

    if (index === targetIndex) {
      labelRef(element)
    }
  }

  return (
    <Container
      isMinimal={isMinimal}
      gap={1}
      direction="column"
      width="100%"
      height="100%"
      padding={1}
      justify="space-between"
    >
      {steps.map((step, index) => (
        <Flex key={`yaxis-${index}`} gap={1} align="center">
          <Label ref={setRef(index)}>{getValueWithUnit(step, trend)}</Label>
          <HorizontalLine />
        </Flex>
      ))}
    </Container>
  )
}
