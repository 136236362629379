import React from 'react'
import { Span } from './types'
import { InteractionIcon } from './InteractionIcon'

interface LocatorProps {
  span: Span
  parentSpan: Span
  spans?: Span[]
}
export function Locator({ span, parentSpan }: LocatorProps) {
  const type = span.name.split('.')[1]
  const icon = type === 'click' ? 'crosshair' : 'ellipsis-h'

  return (
    <InteractionIcon
      parentSpan={parentSpan}
      span={span}
      icon={icon}
      tooltip={span.name}
    />
  )
}
