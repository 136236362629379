import styled from 'styled-components'
import { color, spacing } from 'utils/styled'

export const PageSection = styled.div`
  margin-bottom: ${spacing(3)};
  background-color: ${color('background', 'secondary')};

  &:last-child {
    margin-bottom: 0;
  }
`

export const CompactPageSection = styled(PageSection)`
  padding: ${spacing(1, 2)};
`

export const MinimalPageSection = styled(PageSection)`
  padding: 0;
`
