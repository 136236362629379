import { TableColumn } from 'react-data-table-component'

export enum LogsTabTestIds {
  IngestMessage = 'logs-ingest-message',
  EmptyLogs = 'logs-empty-view',
  EmptyQueryMessage = 'logs-empty-query-message',
  LogList = 'log-list',
}

export enum RunsPageTestIds {
  MetaBar = 'meta-bar',
  ThresholdsTable = 'thresholds-table',
  ChecksTable = 'checks-table',
  HttpTable = 'http-table',
  GRPCTable = 'grpc-table',
  WSTable = 'ws-table',
  TracesTable = 'traces-table',
  InsightsTable = 'insights-table',
  BrowserTable = 'browser-table',
  PerformanceOverviewChart = 'performance-overview-chart',
  ScenariosTable = 'scenarios-table',
  ScenarioChart = 'scenario-chart',
  SummaryStatistic = 'summary-statistic',
  StatisticLabel = 'statistic-label',
  StatisticValue = 'statistic-value',
  ThresholdsChart = 'threshold-chart',
  ChecksChart = 'checks-chart',
  HttpChart = 'http-chart',
  GRPCChart = 'grpc-chart',
  WSChart = 'ws-chart',
  BrowserChart = 'browser-chart',
  AnalysisChart = 'analysis-chart',
}

export enum RunsPageBreakdownTestIds {
  AnalysisSummaryText = 'analysis-summary-text',
}

export enum RunsTableTestIds {
  RunsTable = 'runs-table',
}

export enum TestPageTestIds {
  MetaBar = 'meta-bar',
  EditSchedule = 'edit-schedule',
}

export enum ProjectSettingsTestIds {
  ProjectRolesTable = 'project-roles-table',
}

export enum ScriptEditorTestIds {
  Editor = 'editor',
}

export enum ProjectPageTestIds {
  CardGrid = 'project-card-grid',
  Card = 'project-card',
}

export const BreakdownTableTestId = {
  Table: 'breakdown-table',
  HeaderRow: 'breakdown-table-header-row',
  Header(column: TableColumn<any>) {
    return `breakdown-table-header-${column.id}`
  },
}

export const RunDetailsFilterTestId = {
  Container: 'run-details-filter-container',
}

export enum CloudInsightTestId {
  Root = 'cloud-insights',
  Audit = 'cloud-insights-audit',
  Category = 'cloud-insights-category',
  Filter = 'cloud-insights-filter',
}
