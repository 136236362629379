import React from 'react'
import { Instructions } from 'components/Instructions'
import { Link } from 'react-router-dom'
import { routeMap } from 'routeMap'
import { CodeSnippet } from 'components/CodeSnippet'
import { PLZ_OPTIONS_EXAMPLE } from 'constants/scripts'
import { DocLink, docs } from '../../../../../../components/DocLink'

export const RunTestOnPlzGuide = () => {
  return (
    <>
      <Instructions.Block title="Run from the CLI">
        You can find a detailed guide on how to create and run a test from the
        CLI{' '}
        <Link to={routeMap.cliGuide} target="_blank">
          here
        </Link>
        .
        <p>
          To run a test from the CLI in a Private Load Zone you need to update
          the options object.
          <br /> More specifically, you need to update the{' '}
          <code>distribution</code> object and set the <code>loadZone</code> key
          to your Private Load Zone name. Private Load Zone names can be found
          in the Load Zones table, <b>ID</b> column or via metadata property of
          your CRD file.
        </p>
        <CodeSnippet code={PLZ_OPTIONS_EXAMPLE} lang="js" dedent={false} />
      </Instructions.Block>
      <Instructions.Block title="Run from the UI">
        You can find a detailed guide{' '}
        <DocLink article={docs.loadZones.runOnPrivateLoadZonesUi}>here</DocLink>{' '}
        on how to create and run a test from the UI using either the{' '}
        <DocLink article={docs.loadZones.runOnPrivateLoadZonesFromTestBuilder}>
          Test Builder
        </DocLink>{' '}
        or the{' '}
        <DocLink article={docs.loadZones.runOnPrivateLoadZonesFromScriptEditor}>
          Script Editor
        </DocLink>
        .
      </Instructions.Block>
    </>
  )
}
