import React, { useMemo, useRef } from 'react'
import { BrowserTimelineData } from './types'
import { getRoot, getSpans } from './BrowserTimeline.utils'
import { TimeSpan } from './BrowserTimeline.styled'
import { TestDuration } from './TestDuration'
import { SpanVisualizer } from './SpanVisualizer'
import { LoadingContainer } from '../LoadingContainer'

interface BrowserTimelineProps {
  data?: BrowserTimelineData
  isLoading?: boolean
  compact?: boolean
}

export function BrowserTimeline({
  data,
  isLoading = false,
  compact = false,
}: BrowserTimelineProps) {
  const timeSpanRef = useRef<HTMLDivElement>(null)
  const spans = useMemo(() => {
    return getSpans(data)
  }, [data])

  const root = useMemo(() => {
    return getRoot(spans)
  }, [spans])

  const hasData = !!data && spans.length > 0 && !!root
  if (!hasData && !isLoading) {
    return (
      <div>
        Unable to fetch tracing data for test run. Make sure test has started
        and/or refresh the page.
      </div>
    )
  }

  return (
    <div>
      <TimeSpan ref={timeSpanRef}>
        <LoadingContainer estimatedHeight={100} loading={isLoading}>
          {hasData && (
            <>
              <TestDuration rootSpan={root.span} top={timeSpanRef} />
              <SpanVisualizer root={root} spans={spans} compact={compact} />
            </>
          )}
        </LoadingContainer>
      </TimeSpan>
    </div>
  )
}
