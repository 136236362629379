import { useInfiniteQuery } from '@tanstack/react-query'

import { PaginatedAuditTrailParams } from 'types/auditTrail'
import { AUDIT_TRAIL_DEFAULT_PAGE_SIZE } from 'constants/index'
import { useDatasource } from 'datasourceContext'

import { hasMoreData } from './AuditTrail.utils'

export const useInfiniteAuditTrail = ({
  orgId,
  pageSize = AUDIT_TRAIL_DEFAULT_PAGE_SIZE,
  startTime,
  endTime,
  orderBy,
}: PaginatedAuditTrailParams) => {
  const { ds } = useDatasource()

  return useInfiniteQuery({
    queryKey: ['paginated-audit-trail', orgId, startTime, endTime, orderBy],
    queryFn: ({ pageParam }) => {
      return ds.getPaginatedAuditTrail({
        orgId,
        pageNumber: pageParam,
        pageSize,
        startTime,
        endTime,
        orderBy,
      })
    },
    getNextPageParam: (_, pages) => hasMoreData(pages) && pages.length + 1,
    keepPreviousData: true,
  })
}
