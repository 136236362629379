import { ValueMappingResult } from '@grafana/data'

import { NamedColors } from 'types'
import { TrendValueStatus } from 'types/trend'

export const TREND_VALUE_STATUS_MAP: Record<
  TrendValueStatus,
  ValueMappingResult
> = {
  [TrendValueStatus.Created]: {
    text: 'Created',
    color: NamedColors.Gray,
  },
  [TrendValueStatus.Initializing]: {
    text: 'Initializing',
    color: NamedColors.Purple,
  },
  [TrendValueStatus.Validated]: {
    text: 'Validated',
    color: NamedColors.Gray,
  },
  [TrendValueStatus.Queued]: {
    text: 'Queued',
    color: NamedColors.Gray,
  },
  [TrendValueStatus.Running]: {
    text: 'Running',
    color: NamedColors.Blue,
  },
  [TrendValueStatus.Stopping]: {
    text: 'Stopping',
    color: NamedColors.Purple,
  },
  [TrendValueStatus.AbortedByUser]: {
    text: 'Aborted by user',
    color: NamedColors.Orange,
  },
  [TrendValueStatus.AbortedByThreshold]: {
    text: 'Aborted by threshold',
    color: NamedColors.Red,
  },
  [TrendValueStatus.AbortedBySystem]: {
    text: 'Aborted by system',
    color: NamedColors.Red,
  },
  [TrendValueStatus.AbortedByLimit]: {
    text: 'Aborted by limit',
    color: NamedColors.Red,
  },
  [TrendValueStatus.AbortedByScriptError]: {
    text: 'Aborted by script error',
    color: NamedColors.Red,
  },
  [TrendValueStatus.TimedOut]: {
    text: 'Timed out',
    color: NamedColors.Red,
  },
  [TrendValueStatus.Finalizing]: {
    text: 'Finalizing',
    color: NamedColors.Purple,
  },
  [TrendValueStatus.Finished]: {
    text: 'Finished',
    color: NamedColors.Green,
  },
  [TrendValueStatus.Failed]: {
    text: 'Failed',
    color: NamedColors.Red,
  },
  [TrendValueStatus.Uploaded]: {
    text: 'Uploaded',
    color: NamedColors.Gray,
  },
}
