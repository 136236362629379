import React from 'react'
import { Menu } from '@grafana/ui'

import { TestRun } from 'types'

import {
  isTestActive,
  isTestRunExpired,
  isTestExportActive,
} from 'utils/testRun'
import { useHasUserProjectWriteAccess } from 'data/usePermissions'
import { useDownloadExportData } from './ExportData/ExportData.hooks'

interface MenuItemExportProps {
  testRun: TestRun
  onClick: () => void
}

export const MenuItemExport = ({ testRun, onClick }: MenuItemExportProps) => {
  const hasUserProjectWriteAccess = useHasUserProjectWriteAccess()
  const { downloadExportData } = useDownloadExportData(testRun.id)
  const isExporting = testRun.export
    ? isTestExportActive(testRun.export.export_status)
    : false

  const isDisabled =
    !hasUserProjectWriteAccess ||
    isTestActive(testRun) ||
    isExporting ||
    isTestRunExpired(testRun)

  const filePath = testRun?.export?.download_url ?? undefined

  const label = filePath
    ? 'Download export data'
    : isExporting
    ? 'Exporting test results'
    : 'Export data'

  async function handleClick() {
    if (filePath) {
      await downloadExportData()
      return
    }

    onClick()
  }

  return (
    <Menu.Item
      disabled={isDisabled}
      label={label}
      icon={isExporting ? 'fa fa-spinner' : 'download-alt'}
      onClick={handleClick}
    />
  )
}
