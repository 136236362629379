import { useMemo } from 'react'
import { sortBy } from 'lodash'

import { type TestRun } from 'types'
import { InsightCategory, type InsightCategoryId } from 'types/cloudInsights'
import { getScoreStatus, isCategoryId } from 'utils/insights'
import {
  useInsightsAudits,
  useInsightsAuditsResults,
  useInsightsCategoriesResults,
  useInsightsLatestExecution,
} from 'data/useCloudInsights'

import { useCloudInsightsAuditFilter } from '../CloudInsightsAuditFilter'

export const useCategoryResult = (
  categoryId: InsightCategoryId,
  testRun: TestRun
) => {
  const {
    data: execution,
    isError: isExecutionError,
    isFetched: isExecutionFetched,
    isLoading: isExecutionLoading,
  } = useInsightsLatestExecution(testRun)

  const {
    data: results = [],
    isError: isResultsError,
    isFetched: isResultsFetched,
    isLoading: isResultsLoading,
  } = useInsightsCategoriesResults(testRun, execution)

  const result = useMemo(() => {
    return results.find(isCategoryId(categoryId))
  }, [results, categoryId])

  return {
    data: result,
    isError: isExecutionError || isResultsError,
    isFetched: isExecutionFetched && isResultsFetched,
    isLoading: isExecutionLoading || isResultsLoading,
  }
}

interface UseAuditsProps {
  category: InsightCategory
  testRun: TestRun
}

export const useAudits = ({ category, testRun }: UseAuditsProps) => {
  const [filters] = useCloudInsightsAuditFilter()
  const { data: execution } = useInsightsLatestExecution(testRun)
  const { data: audits = [] } = useInsightsAudits(testRun, execution)
  const { data: auditResults = [] } = useInsightsAuditsResults(
    testRun,
    execution
  )

  return useMemo(() => {
    const auditsByCategory = audits.filter(isCategoryId(category.id))
    const auditsByStatus = auditsByCategory.filter((audit) => {
      const result = auditResults.find((result) => result.audit_id === audit.id)

      if (!result) {
        return false
      }

      if (result.status === 'executing') {
        return true
      }

      return filters.includes(getScoreStatus(result.score))
    })

    return sortBy(auditsByStatus, ['group_id'])
  }, [audits, auditResults, category.id, filters])
}
