import React from 'react'
import styled from 'styled-components'
import { Spinner } from '@grafana/ui'

import { useOrgRoles } from 'data/useOrgRoles'
import { RefetchIcon } from 'components/RefetchIcon'
import { spacing } from 'utils/styled'

import { RefetchIconContainer } from './TestRunMetaBar.styles'

export const StartedBy = ({ userId }: { userId: number }) => {
  const {
    data: roles,
    isFetching,
    isError,
    refetch,
  } = useOrgRoles({
    useErrorBoundary: false,
    alertOnError: false,
  })

  if (isError) {
    return (
      <span>
        Started by:{' '}
        <RefetchIconContainer>
          <RefetchIcon entity="user" onClick={() => refetch()} />
        </RefetchIconContainer>
      </span>
    )
  }

  if (!roles || isFetching) {
    return (
      <>
        Started by:{' '}
        <SpinnerContainer>
          <Spinner size={14} />
        </SpinnerContainer>
      </>
    )
  }

  const role = roles.find((role) => role.user_id === userId)

  if (!role) {
    return null
  }

  return <>Started by: {role.user_email}</>
}

const SpinnerContainer = styled.div`
  margin-left: ${spacing(0.5)};
`
