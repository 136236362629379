import {
  GrpcMetricSummary,
  HttpMetricSummary,
  Summary,
  WsMetricSummary,
} from 'types'
import { unitFormatter } from 'utils/formatters'
import { BreakdownTab } from './Breakdown.types'

export const getHttpCount = ({
  requests_count,
  failures_count,
}: HttpMetricSummary) => {
  return (
    requests_count !== null &&
    `(${unitFormatter(requests_count - failures_count)}/${unitFormatter(
      requests_count
    )})`
  )
}

export const getGrpcCount = ({ requests_count }: GrpcMetricSummary) => {
  return `(${unitFormatter(requests_count)})`
}

export const getWSCount = ({ sessions }: WsMetricSummary) => {
  return `(${unitFormatter(sessions ?? 0)})`
}

export const getChecksCount = (summary: Summary) => {
  if (!summary.checks_metric_summary) {
    return '(0/0)'
  }

  const { hits_total, hits_successes } = summary.checks_metric_summary
  return `(${unitFormatter(hits_successes ?? 0)}/${unitFormatter(
    hits_total ?? 0
  )})`
}

export const getThresholdsCount = (summary: Summary) => {
  if (!summary.thresholds_summary) {
    return '(0/0)'
  }

  const { total, successes } = summary.thresholds_summary
  return `(${successes}/${total})`
}

export function tabSupportsComparison(tab: string) {
  return tab !== BreakdownTab.LOGS && tab !== BreakdownTab.BROWSER
}

export const getScriptTabTexts = (isComparing = false) => ({
  label: isComparing ? 'SCRIPTS' : 'SCRIPT',
  suffix: `View executed ${isComparing ? 'scripts' : 'script'}`,
})
