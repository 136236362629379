import { useProjectsByOrgId } from 'data/useProjects'
import { useAccount } from 'data/useAccount'
import { getDefaultOrgId } from 'utils/account'

export const useDefaultProject = () => {
  const { data: account } = useAccount()
  const orgId = account && getDefaultOrgId(account)

  const { data } = useProjectsByOrgId(orgId!, { enabled: !!orgId })
  return (data ?? []).find((item) => item.is_k6 && item.is_default)
}
