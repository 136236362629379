import { useCallback, useState } from 'react'
import { ConfirmModalProps } from 'components/ConfirmModal'

export const useConfirmation = (
  handler: () => Promise<unknown> | unknown
): [
  Pick<ConfirmModalProps, 'isOpen' | 'isPending' | 'onConfirm' | 'onDismiss'>,
  () => void
] => {
  const [isOpen, setIsOpen] = useState(false)
  const [isPending, setIsPending] = useState(false)

  const onConfirm = async () => {
    setIsPending(true)
    try {
      await handler()
      setIsOpen(false)
    } finally {
      setIsPending(false)
    }
  }

  const onDismiss = useCallback(() => {
    setIsOpen(false)
    setIsPending(false)
  }, [])

  const requestConfirmation = useCallback(() => {
    setIsOpen(true)
  }, [])

  return [
    {
      isOpen,
      isPending,
      onConfirm,
      onDismiss,
    },
    requestConfirmation,
  ]
}
