import React, {
  forwardRef,
  type CSSProperties,
  type ReactNode,
  type Ref,
} from 'react'

import { FlexElementProps } from './Flex.types'
import { FlexElement } from './Flex.styles'

export interface FlexProps extends FlexElementProps {
  children: ReactNode
  className?: string
  as?: keyof JSX.IntrinsicElements
  style?: CSSProperties
}

export const Flex = forwardRef(function Flex(
  { children, as = 'div', direction, width, height, ...props }: FlexProps,
  ref: Ref<HTMLDivElement>
) {
  return (
    <FlexElement
      as={as}
      ref={ref}
      $direction={direction}
      $height={height}
      $width={width}
      {...props}
    >
      {children}
    </FlexElement>
  )
})
