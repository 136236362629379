import React, { useCallback, useMemo, useState } from 'react'
import { InlineFormLabel, MultiSelect } from '@grafana/ui'
import styled from 'styled-components'
import { SelectableValue } from '@grafana/data'

import { type TestRunAnalysis } from 'types'
import { type MetricConfig } from 'datasource/models'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { LoadingContainer } from 'components/LoadingContainer'
import { SelectLoadZone } from '../../ChartFilters/SelectLoadZone'
import { AnalysisSummary } from './AnalysisSummary'
import { useCustomMetrics } from './AnalysisTab.hooks'
import { type AnalysisMetrics } from './AnalysisTab.types'
import {
  getAnalysisMetrics,
  getInitialMetrics,
  metricsByOrigin,
  sharedAnalysisMetrics,
} from './AnalysisTab.utils'
import { AnalysisChart } from './AnalysisChart'
import { useColorPool } from 'hooks/useColorPool'
import { AnalysisDrawer } from './AnalysisDrawer'
import { uniqBy } from 'lodash'
import { QueryErrorBoundary } from 'components/ErrorBoundary'
import { ErrorBoundaryLocalView } from 'services/tracking.types'
import { ExploreLink } from './ExploreLink'

interface AnalysisTabProps {
  analysis: TestRunAnalysis
}

const AnalysisTabComponent = ({ analysis }: AnalysisTabProps) => {
  const { main, compareWith } = analysis
  const mainMetrics = useCustomMetrics(main.id)
  const compareWithMetrics = useCustomMetrics(compareWith?.id)
  const loading = compareWith
    ? mainMetrics.isLoading || compareWithMetrics.isLoading
    : mainMetrics.isLoading

  return (
    <LoadingContainer loading={loading}>
      {!loading && (
        <AnalysisTabContent
          analysis={analysis}
          analysisMetrics={{
            main: mainMetrics.data || [],
            compareWith: compareWithMetrics.data || [],
          }}
        />
      )}
    </LoadingContainer>
  )
}

export const AnalysisTab = (props: AnalysisTabProps) => (
  <QueryErrorBoundary view={ErrorBoundaryLocalView.AnalysisTab}>
    <AnalysisTabComponent {...props} />
  </QueryErrorBoundary>
)

type AnalysisTabContentProps = {
  analysis: TestRunAnalysis
  analysisMetrics: AnalysisMetrics
}

const AnalysisTabContent = ({
  analysis,
  analysisMetrics,
}: AnalysisTabContentProps) => {
  const [analysisDrawerOpen, setAnalysisDrawerOpen] = useState(false)
  const colorPool = useColorPool()
  const allAnalysisMetrics = useMemo(
    () => sharedAnalysisMetrics(analysisMetrics),
    [analysisMetrics]
  )
  const origins = useMemo(
    () => metricsByOrigin(allAnalysisMetrics),
    [allAnalysisMetrics]
  )
  const metrics = useMemo(
    () => getAnalysisMetrics(origins, colorPool),
    [origins, colorPool]
  )
  const initialMetrics = useMemo(() => getInitialMetrics(metrics), [metrics])
  const [metricsToShow, setMetricsToShow] =
    useState<MetricConfig[]>(initialMetrics)
  const [loadZone, setLoadZone] = useState<string | undefined>(``)
  const { main, compareWith } = analysis
  const nodes = uniqBy(
    [...main.nodes, ...(compareWith?.nodes || [])],
    'load_zone_id'
  )

  const selectOptions = metrics.map(({ description, label }) => {
    return { label, value: label, description }
  })

  const handleChange = (value: Array<SelectableValue<string>>) => {
    const selectedMetrics = value.map(({ label }) => {
      return metrics.find((metric) => metric.label === label)
    }) as MetricConfig[]

    setMetricsToShow(selectedMetrics)
  }

  const handleDrawerOpen = useCallback(() => {
    setAnalysisDrawerOpen(true)
  }, [])

  const handleDrawerClosed = useCallback(() => {
    setAnalysisDrawerOpen(false)
  }, [])

  const helpButtonTooltip = 'How to analyze results in explore'

  return (
    <>
      <AnalysisHeader>
        <Flex gap={1}>
          <ExploreLink
            main={main}
            compareWith={compareWith}
            metrics={metricsToShow}
          />
          <Button
            icon="question-circle"
            onClick={handleDrawerOpen}
            variant="secondary"
            tooltip={helpButtonTooltip}
            aria-label={helpButtonTooltip}
          />
        </Flex>
        <SelectWrapper>
          <InlineFormLabel width="auto">Metrics</InlineFormLabel>
          <MultiSelect
            isClearable
            onChange={handleChange}
            options={selectOptions}
            tabSelectsValue={false}
            value={metricsToShow.map(({ label }) => label)}
          />
        </SelectWrapper>
        <div>
          <SelectLoadZone
            nodes={nodes}
            onChange={setLoadZone}
            value={loadZone}
          />
        </div>
      </AnalysisHeader>
      <MetricsWrapper>
        <Grid gap={2} align="end" width="100%">
          {metricsToShow.map((metric) => (
            <Grid.Column key={metric.label} xs={12} lg={6}>
              <AnalysisChart
                analysis={analysis}
                colorPool={colorPool}
                loadZone={loadZone}
                metric={metric}
              />
            </Grid.Column>
          ))}
        </Grid>
        <AnalysisSummary origins={origins} handleClick={handleDrawerOpen} />
      </MetricsWrapper>
      {analysisDrawerOpen && (
        <AnalysisDrawer
          analysis={analysis}
          colorPool={colorPool}
          loadZone={loadZone}
          metric={metricsToShow[0] || (initialMetrics[0] as MetricConfig)}
          onClose={handleDrawerClosed}
        />
      )}
    </>
  )
}

const AnalysisHeader = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) => theme.breakpoints.down(`lg`)} {
    display: grid;
  }
`

const SelectWrapper = styled(Flex)`
  flex: 1 0 0;
`

const MetricsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  justify-content: center;
  min-height: 300px;
  padding: ${({ theme }) => theme.spacing(2)};
`
