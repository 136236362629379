import { HTTP_REQUEST_RATE, HTTP_RESPONSE_TIME_95 } from 'constants/metrics'
import { quantity, requestRate, timing } from 'utils/formatters'
import { GeneratorContext, SummaryRow } from '../types'
import { MetricBuilder } from 'utils/metrics'
import dedent from 'dedent'
import { createTextPanel } from '../panels'

async function fetchData({ metrics, options }: GeneratorContext) {
  const [[requestRate], [responseTime], [totalRequests]] = await Promise.all([
    metrics.queryAggregate(
      options.testRun.id,
      new MetricBuilder(HTTP_REQUEST_RATE).asAggregate().build()
    ),
    metrics.queryAggregate(
      options.testRun.id,
      new MetricBuilder(HTTP_RESPONSE_TIME_95).asAggregate().build()
    ),
    metrics.queryAggregate(
      options.testRun.id,
      new MetricBuilder(HTTP_REQUEST_RATE)
        .withMethod('increase')
        .asAggregate()
        .build()
    ),
  ])

  return {
    requestRate: requestRate?.data.values[0]?.value ?? 0,
    responseTime: responseTime?.data.values[0]?.value ?? 0,
    totalRequests: totalRequests?.data.values[0]?.value ?? 0,
  }
}

export async function generateOverviewText(context: GeneratorContext) {
  const stats = await fetchData(context)

  const responseTime = timing(stats.responseTime)
  const totalRequests = quantity(stats.totalRequests)
  const rate = requestRate(stats.requestRate)

  // prettier-ignore
  return dedent`
    ## Performance overview

    The 95th percentile response time of the system being tested was **${responseTime}**
    and **${totalRequests} requests** were made at an average request rate of
    **${rate}**.
  `
}

export async function createPerformanceOverviewText(
  context: GeneratorContext
): Promise<SummaryRow> {
  return {
    height: 3,
    panels: [createTextPanel(await generateOverviewText(context))],
  }
}
