import { useMemo } from 'react'

import type { Trend } from 'types'
import type {
  InsightCategory,
  InsightCategoryResultWithTestRun,
  InsightCategoryResultWithTitleAndTestRun,
} from 'types/cloudInsights'
import {
  isError as isQueryError,
  isFetched,
  isLoading as isQueryLoading,
} from 'utils/reactQuery'
import {
  useInsightsCategoriesList,
  useInsightsCategoriesResultsList,
  useInsightsLatestExecutionList,
} from 'data/useCloudInsights'

export const useCategoryResultHistory = (
  trend?: Trend,
  options: { useErrorBoundary?: boolean } = {}
) => {
  const testRuns = trend?.values.map(({ run }) => run) ?? []

  const latestExecutions = useInsightsLatestExecutionList(testRuns, options)
  const resultQueries = useInsightsCategoriesResultsList(latestExecutions)
  const staticQueries = useInsightsCategoriesList(latestExecutions)

  return useMemo(() => {
    const isResultError = resultQueries.some(isQueryError)
    const isResultLoading = resultQueries.some(isQueryLoading)
    const isStaticError = staticQueries.some(isQueryError)
    const isStaticLoading = staticQueries.some(isQueryLoading)

    const resultData = resultQueries
      .filter(isFetched)
      .map(({ data }) => data)
      .filter(isCategoryResults)
      .flat()

    const staticData = staticQueries
      .filter(isFetched)
      .map(({ data }) => data)
      .filter(isCategory)
      .flat()

    const resultsWithTitle = resultData.reduce((results, result) => {
      const assoc = staticData.find(
        (category) => category.id === result.category_id
      )

      if (!assoc) {
        return results
      }

      const item: InsightCategoryResultWithTitleAndTestRun = {
        ...result,
        category_title: assoc.title,
      }

      return [...results, item]
    }, [] as InsightCategoryResultWithTitleAndTestRun[])

    return {
      data: resultsWithTitle,
      isError: isResultError || isStaticError,
      isLoading: isResultLoading || isStaticLoading,
    }
  }, [resultQueries, staticQueries])
}

const isCategory = (data?: InsightCategory[]): data is InsightCategory[] => {
  return Array.isArray(data) && data.every((result) => !!result?.id)
}

const isCategoryResults = (
  data?: InsightCategoryResultWithTestRun[]
): data is InsightCategoryResultWithTestRun[] => {
  return Array.isArray(data) && data.every((result) => !!result?.category_id)
}
