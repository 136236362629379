import { Test } from '../types'
import { useSnoozeUntil } from './useSnoozeUntil'
import { useLocalStorage } from 'usehooks-ts'
import { isDistributedTest, isIngestTest } from 'utils/test'

type BannerState = {
  operator: true | number[]
  ingest: true | number[]
}

export const usePlzBannerDismiss = (test: Test) => {
  const testSpecificStorageKey = getTestSpecificStorageKey(test)

  const { isSnoozed, onSnooze } = useSnoozeUntil({
    storageKey: testSpecificStorageKey,
    minutes: 24 * 60,
  })

  const [state, setState] = useLocalStorage<BannerState>(
    'k6-plz-hide-banner-state',
    {
      ingest: [],
      operator: [],
    }
  )

  const onDismissForTest = () => {
    setState((prevState) => {
      if (isIngestTest(test)) {
        const prevIngest = Array.isArray(prevState.ingest)
          ? prevState.ingest
          : []
        return { ...prevState, ingest: [...prevIngest, test.id] }
      } else {
        const prevOperator = Array.isArray(prevState.operator)
          ? prevState.operator
          : []
        return { ...prevState, operator: [...prevOperator, test.id] }
      }
    })
  }

  const onDismissForType = () => {
    setState((prevState) => {
      if (isIngestTest(test)) {
        return { ...prevState, ingest: true }
      } else {
        return { ...prevState, operator: true }
      }
    })
  }

  const shouldDisplayBannerForIngest =
    isIngestTest(test) &&
    state.ingest !== true &&
    !state.ingest.includes(test.id)

  const shouldDisplayBannerForOperator =
    isDistributedTest(test) &&
    state.operator !== true &&
    !state.operator.includes(test.id)

  return {
    shouldDisplayBanner:
      (shouldDisplayBannerForIngest || shouldDisplayBannerForOperator) &&
      !isSnoozed,
    onDismissForType,
    onDismissForTest,
    onSnooze,
  }
}

const getTestSpecificStorageKey = (test: Test) =>
  isIngestTest(test)
    ? `k6-ingest-test-plz-banner-dismissed-${test.id}`
    : `k6-operator-test-plz-banner-dismissed-${test.id}`
