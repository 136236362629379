import React from 'react'

import { StaticIP, StaticIPStatus } from 'types'
import { addPlural } from 'utils'
import { ClipboardButton } from 'components/ClipboardButton'

export const StaticIPsCopyClipboard = ({
  staticIPs,
}: {
  staticIPs: StaticIP[]
}) => {
  const provisionedStaticIPs = staticIPs.filter(
    (ip) => ip.provisioning_status === StaticIPStatus.PROVISIONED
  )

  const provisionedLength = provisionedStaticIPs.length

  const getActionText = () => {
    if (provisionedLength === 0) {
      return 'There are no Static IPs to copy. Wait until at least one Static IP is provisioned.'
    }

    return `Copy ${provisionedLength} provisioned ${addPlural(
      'IP',
      provisionedLength
    )}`
  }

  return (
    <ClipboardButton
      disabled={provisionedLength === 0}
      actionText={getActionText()}
      data={provisionedStaticIPs.map((ip) => ip.ip).join('\n')}
      variant="secondary"
      fill="solid"
    >
      Copy IPs to clipboard
    </ClipboardButton>
  )
}
