import React, { useState } from 'react'
import { Button } from '@grafana/ui'
import styled from 'styled-components'

import { Project } from 'types'
import { useFolderAssignments } from 'data/useFolderAssignments'
import { LoadingContainer } from 'components/LoadingContainer'
import { Flex } from 'components/Flex'
import { PermissionsTable } from './PermissionsTable'
import { useOrganization } from 'data/useOrganization'
import { getGrafanaUrl } from 'utils/env'
import { InlineCollapse } from 'components/InlineCollapse'
import { spacing } from 'utils/styled'
import { AddPermissionForm } from './AddPermissionForm'
import { useChangeFolderUserPermission } from 'data/useChangeFolderPermission'

interface ProjectFolderPermissionsProps {
  project: Project
}

export function ProjectFolderPermissions({
  project,
}: ProjectFolderPermissionsProps) {
  const [isPermissionFormVisible, setPermissionFormVisible] = useState(false)
  const { mutateAsync: onUpdate, isLoading: isPermissionUpdatePending } =
    useChangeFolderUserPermission(project)

  const { data = [], isLoading } = useFolderAssignments(project)

  const organization = useOrganization()
  const link = getGrafanaUrl(
    organization?.grafana_org_slug
      ? `/orgs/${organization.grafana_org_slug}/members`
      : ''
  )

  const roles = data.filter((a) => a.builtInRole !== undefined)
  const users = data.filter((a) => a.userId !== undefined)
  const teams = data.filter((a) => a.teamId !== undefined)

  return (
    <LoadingContainer loading={isLoading}>
      <Container direction="column" gap={4}>
        <div>
          <InstructionsContainer>
            Use <a href="/org/teams">Grafana team management</a> to manage
            teams. To invite new users, follow these{' '}
            <InlineCollapse label="steps">
              <InstructionsList>
                <li>
                  Go to{' '}
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    grafana.com
                  </a>{' '}
                  and invite the new user.
                </li>
                <li>
                  Wait until the user has accepted the invite and logged in to
                  the Grafana instance.
                </li>
                <li>
                  After the first two steps have been completed the new user(s)
                  should appear in the dropdown below.
                </li>
              </InstructionsList>
            </InlineCollapse>
          </InstructionsContainer>
          <PermissionsTable
            type="role"
            assignments={roles}
            onUpdate={onUpdate}
          />
        </div>
        <PermissionsTable type="user" assignments={users} onUpdate={onUpdate} />
        <PermissionsTable type="team" assignments={teams} onUpdate={onUpdate} />
        <StyledButton
          icon="plus"
          disabled={isPermissionUpdatePending}
          onClick={() => setPermissionFormVisible(true)}
        >
          Add a permission
        </StyledButton>
        {isPermissionFormVisible && (
          <AddPermissionForm
            onAdd={onUpdate}
            onClose={() => setPermissionFormVisible(false)}
          />
        )}
      </Container>
    </LoadingContainer>
  )
}

const StyledButton = styled(Button)`
  align-self: flex-start;
`

const InstructionsList = styled.ol`
  padding-left: ${spacing(2)};
`

const InstructionsContainer = styled.p`
  margin-bottom: ${spacing(1)};
`

const Container = styled(Flex)`
  padding-bottom: ${spacing(2)};
`
