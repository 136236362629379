import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Alert, Button, Field, Input, Modal } from '@grafana/ui'

import { useCreateOrganizationToken } from 'data/useCreateOrganizationToken'

export function StackApiTokenCreateModal({
  show = false,
  onDismiss,
}: {
  show: boolean
  onDismiss?: () => void
}) {
  const inputRef = useRef<HTMLInputElement>(null)
  const { mutate, isLoading, isSuccess, error, reset } =
    useCreateOrganizationToken()
  const [nameError, setNameError] = useState<undefined | string>(undefined)
  const [generalError, setGeneralError] = useState<undefined | string>(
    undefined
  )
  const [value, setValue] = useState('')

  useEffect(() => {
    if (show) {
      requestAnimationFrame(() => {
        inputRef.current?.focus()
      })
    } else {
      setValue('')
      reset()
    }
  }, [reset, show])

  useEffect(() => {
    if (isSuccess) {
      onDismiss && onDismiss()
    }
  }, [isSuccess, onDismiss])

  useEffect(() => {
    const [message] = error?.data?.error?.field_errors?.name ?? []
    setNameError(message)
    setGeneralError(error?.data?.error?.message)
  }, [error])

  const createDisabled = isLoading || !value || !!generalError

  const handleCreate = () => {
    value && mutate(value)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    if (error) {
      reset()
    }
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (!createDisabled) {
      handleCreate()
    }
  }

  return (
    <Modal
      isOpen={show}
      title="Create new Grafana stack API token"
      onDismiss={onDismiss}
    >
      <Field error={nameError} invalid={!!nameError} label="Token name">
        <form onSubmit={handleSubmit}>
          <Input
            ref={inputRef}
            placeholder="Enter token name"
            value={value}
            autoComplete="off"
            disabled={isLoading}
            onChange={handleChange}
          />
        </form>
      </Field>
      {!nameError && generalError && (
        <Alert title={generalError} severity="error" />
      )}
      <Modal.ButtonRow>
        <Button variant="secondary" onClick={onDismiss} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={createDisabled}
          icon={isLoading ? 'fa fa-spinner' : undefined}
          onClick={handleCreate}
        >
          Create new token
        </Button>
      </Modal.ButtonRow>
    </Modal>
  )
}
