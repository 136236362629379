import React from 'react'

import { useRegenerateApiToken } from 'data/useRegenerateApiToken'
import { ConfirmModal } from 'components/ConfirmModal'

interface RegenApiTokenModalProps {
  isOpen: boolean
  onDismiss: () => void
}

export const RegenApiTokenModal = ({
  isOpen,
  onDismiss,
}: RegenApiTokenModalProps) => {
  const { mutate, isLoading } = useRegenerateApiToken({ onSuccess: onDismiss })

  return (
    <ConfirmModal
      title="Regenerate API token"
      body="This action will invalidate the current token and create a new one."
      confirmText="Confirm"
      isOpen={isOpen}
      isPending={isLoading}
      onConfirm={() => mutate()}
      onDismiss={onDismiss}
    />
  )
}
