import React, { useCallback, useEffect, useMemo, useState } from 'react'

import type { ISODateString, TestRun } from 'types'
import type { LogsFilter } from 'types/logs'
import { LogsTabTestIds } from 'types/dataTestIds'
import { toISODate } from 'utils/date'
import { serializeLokiQuery } from 'utils/logs'
import { nanoToMilliseconds } from 'utils/math'
import { isLocalExecution, isTestActive } from 'utils/testRun'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { LoadingContainer } from 'components/LoadingContainer'

import { useTimeRange } from '../../../TimeRangeContext'
import { LogsView } from './LogsView'
import { LogsTabEmptyView } from './LogsTabEmptyView'
import { isZeroState } from './LogsTab.utils'
import { useLogs } from './LogsTab.hooks'
import { Section } from './LogsTab.styles'
import { LocallyExecutedMessage } from '../LocallyExecutedMessage'
import { QueryErrorBoundary } from 'components/ErrorBoundary'
import { ErrorBoundaryLocalView } from 'services/tracking.types'

type LogState = {
  end?: ISODateString
  filters: LogsFilter
  page: number
  history: Array<ISODateString | undefined>
}

export const initialState: LogState = {
  end: undefined,
  filters: {},
  page: 0,
  history: [undefined],
}

interface LogsTabProps {
  run: TestRun
}

const LogsTabComponent = ({ run }: LogsTabProps) => {
  // ensure these are perfectly in sync.
  const [{ end, filters, page, history }, setParams] = useState(initialState)
  const { timeRange } = useTimeRange()

  const startDate = timeRange
    ? toISODate(timeRange.from)
    : toISODate(run.created)

  // Fallback to run.ended if no end date is provided to avoid
  // hitting time range limits in loki when viewing older runs
  const endDate = useMemo(() => {
    const time = end || run.ended

    if (!time) {
      return undefined
    }

    // teardown functions can be processed after the run ends
    // adding 30 seconds to ensure teardown logs are included
    const buffer = 30000
    const withBuffer = new Date(time).getTime() + buffer

    return toISODate(withBuffer)
  }, [end, run.ended])

  const {
    data: logs = [],
    isFetching,
    isLoading,
  } = useLogs(
    {
      runId: run.id,
      query: serializeLokiQuery(run.id, filters),
      start: startDate,
      end: endDate,
    },
    {
      enabled: !!startDate,
      streaming: isTestActive(run),
    }
  )

  const handleFilterChange = useCallback((newFilters: LogsFilter) => {
    setParams((prev) => ({
      ...initialState,
      filters: { ...prev.filters, ...newFilters },
    }))
  }, [])

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (isFetching) {
        return
      }

      if (newPage > page) {
        const newEndDate = logs.at(0)!.values[0]
        const newEndISO = toISODate(nanoToMilliseconds(newEndDate) + 1)
        const hasEntry = !!history[newPage]
        const newHistory = !hasEntry ? [...history, newEndISO] : history

        setParams((prev) => ({
          ...prev,
          end: newEndISO,
          page: newPage,
          history: newHistory,
        }))
      } else {
        setParams((prev) => ({
          ...prev,
          end: history[newPage],
          page: newPage,
        }))
      }
    },
    [isFetching, logs, page, history]
  )

  // reset params when time range changes
  useEffect(() => {
    const end = timeRange ? toISODate(timeRange.to) : undefined

    setParams({
      ...initialState,
      page: 0,
      end,
      history: [end],
    })
  }, [timeRange])

  if (isLoading) {
    return (
      <Section>
        <CenteredSpinner $height="250px" />
      </Section>
    )
  }

  if (isLocalExecution(run)) {
    return (
      <LocallyExecutedMessage
        type="logs"
        data-testid={LogsTabTestIds.IngestMessage}
      />
    )
  }

  if (isZeroState(logs, filters)) {
    return <LogsTabEmptyView />
  }

  return (
    <Section>
      <LoadingContainer loading={isFetching}>
        <LogsView
          isFetching={isFetching}
          filters={filters}
          logs={logs}
          page={page}
          testRun={run}
          onFilterChange={handleFilterChange}
          onPageChange={handlePageChange}
        />
      </LoadingContainer>
    </Section>
  )
}

export const LogsTab = (props: LogsTabProps) => (
  <QueryErrorBoundary view={ErrorBoundaryLocalView.LogsTab}>
    <LogsTabComponent {...props} />
  </QueryErrorBoundary>
)
