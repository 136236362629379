import styled from 'styled-components'

import { Flex } from 'components/Flex'
import { color, spacing } from 'utils/styled'
import { ProgressBar } from 'components/ProgressBar'
import { Alert, Icon } from '@grafana/ui'
import { ExternalLink } from 'components/ExternalLink'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing(0.5)};
  width: fit-content;
  min-width: 240px;
`

export const Count = styled.span<{ $error?: boolean; $warning?: boolean }>`
  color: ${({ $error, $warning, theme }) => {
    if ($error) {
      return theme.colors.error.text
    }

    if ($warning) {
      return theme.colors.warning.text
    }

    return theme.colors.text.secondary
  }};
`

export const StyledProgressBar = styled(ProgressBar)`
  height: ${spacing(0.5)};

  > div {
    height: inherit;
  }
`

export const TooltipContainer = styled(Flex)`
  width: 200px;
`

export const LinkIcon = styled(Icon)<{ $disabled?: boolean }>`
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.text.disabled : theme.colors.text.link};
`

export const StyledExternalLink = styled(ExternalLink)`
  align-items: center;
`

export const StyledAlert = styled(Alert)`
  flex-basis: 100%;
  color: ${color('text', 'primary')};
`

export const ButtonsContainer = styled(Flex)`
  margin-top: ${spacing(2)};
`
