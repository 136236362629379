import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { EnvironmentVariable } from 'types'
import { ResponseError } from 'types/errors'
import { showAlert } from 'utils/showAlert'
import { useAppContext } from 'appContext'
import { useDatasource } from 'datasourceContext'
import { toEnvironmentVariablesQueryKey } from 'data/queryKeys'

import { EnvironmentVariableError } from '../types'

export const useQueryEnvironmentVariables = () => {
  const { ds } = useDatasource()
  const { orgId } = useAppContext()

  return useQuery<EnvironmentVariable[], ResponseError<EnvironmentVariable>>({
    queryKey: toEnvironmentVariablesQueryKey(orgId),
    queryFn: () => ds.getEnvironmentVariables(orgId),
    onError: () =>
      showAlert('Environment variables have failed to load.', 'error'),
  })
}

interface UseCreateEnvironmentVariableProps {
  onError?: (error: EnvironmentVariableError) => void
  onSuccess?: (values: EnvironmentVariable[]) => void
}

export const useCreateEnvironmentVariable = ({
  onError,
  onSuccess,
}: UseCreateEnvironmentVariableProps) => {
  const { ds } = useDatasource()
  const { orgId } = useAppContext()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (variable: Omit<EnvironmentVariable, 'id'>) =>
      ds.createEnvironmentVariable(orgId, variable),
    onError,
    onSuccess: async (values) => {
      await queryClient.invalidateQueries(toEnvironmentVariablesQueryKey(orgId))
      showAlert('Environment variable created!', 'success')
      onSuccess?.(values)
    },
  })
}

interface UseUpdateEnvironmentVariableProps {
  onError?: (error: EnvironmentVariableError) => void
  onSuccess?: () => void
}

export const useUpdateEnvironmentVariable = ({
  onError,
  onSuccess,
}: UseUpdateEnvironmentVariableProps) => {
  const { ds } = useDatasource()
  const { orgId } = useAppContext()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (variable: EnvironmentVariable) =>
      ds.updateEnvironmentVariable(orgId, variable),
    onError,
    onSuccess: async () => {
      await queryClient.invalidateQueries(toEnvironmentVariablesQueryKey(orgId))
      showAlert('Environment variable updated!', 'success')
      onSuccess?.()
    },
  })
}

export const useDeleteEnvironmentVariable = () => {
  const { ds } = useDatasource()
  const { orgId } = useAppContext()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (variable: EnvironmentVariable) =>
      ds.deleteEnvironmentVariable(orgId, variable),
    onSuccess: async () => {
      await queryClient.invalidateQueries(toEnvironmentVariablesQueryKey(orgId))
      showAlert('Environment variable removed!', 'success')
    },
    onError: () => showAlert('Failed to delete environment variable.', 'error'),
  })
}
