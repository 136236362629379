import styled from 'styled-components'

export const TableScrollContainer = styled.div`
  overflow-x: auto;
`

export const TableRoot = styled.table`
  min-width: 100%;
`

export const TableBody = styled.tbody`
  position: relative;
`
