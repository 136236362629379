import {
  QueryKey,
  UseQueryOptions,
  useQuery,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from '@tanstack/react-query'
import { Falsy } from 'utils/typescript'

export function useOptionalQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(options: Falsy<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>>) {
  // False positive for this eslint rule
  // eslint-disable-next-line @tanstack/query/prefer-query-object-syntax
  return useQuery(
    options
      ? options
      : ({
          enabled: false,
          queryKey: ['___null'] as any as TQueryKey,
          queryFn: () => null,
          staleTime: Infinity,
          cacheTime: Infinity,
        } as any as UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>)
  )
}

export function useOptionalInfiniteQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: Falsy<
    UseInfiniteQueryOptions<
      TQueryFnData,
      TError,
      TData,
      TQueryFnData,
      TQueryKey
    >
  >
) {
  return useInfiniteQuery(
    options
      ? options
      : ({
          enabled: false,
          queryKey: ['___null_iq'] as any as TQueryKey,
          queryFn: () => null,
          staleTime: Infinity,
          cacheTime: Infinity,
        } as any as UseInfiniteQueryOptions<
          TQueryFnData,
          TError,
          TData,
          TQueryFnData,
          TQueryKey
        >)
  )
}
