import { Http, TestRun } from 'types'
import { useQuery } from '@tanstack/react-query'
import { useDatasource } from 'datasourceContext'
import { QueryOptions } from './queryClient'
import { toHttpQueryKey } from './queryKeys'

type HttpName = Pick<Http, 'name'>

export const useHttpNames = (
  testRun: TestRun,
  options?: QueryOptions<HttpName[]>
) => {
  const { ds } = useDatasource()

  return useQuery<HttpName[]>({
    queryKey: toHttpQueryKey(testRun.id, {
      select: 'name',
      ended: testRun.ended,
    }),
    queryFn: () =>
      ds
        .fetchHttp({ id: testRun.id, select: 'name', count: false })
        .then((res) => res.value ?? []),
    ...options,
  })
}

type HttpStatus = Pick<Http, 'status'>

export const useHttpStatuses = (
  testRun: TestRun,
  options?: QueryOptions<HttpStatus[]>
) => {
  const { ds } = useDatasource()

  return useQuery<HttpStatus[]>({
    queryKey: toHttpQueryKey(testRun.id, {
      select: 'status',
      ended: testRun.ended,
    }),
    queryFn: () =>
      ds
        .fetchHttp({ id: testRun.id, select: 'status', count: false })
        .then((res) => res.value ?? []),
    ...options,
  })
}
