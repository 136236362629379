import React from 'react'

import { PlaceholderValueStyled } from './PlaceholderValue.styled'

const VALUES: Array<[number, string]> = [
  [100, '#9B9A9A'],
  [50, '#BFBFBF'],
  [80, '#9B9A9A'],
  [55, '#6A6A6A'],
  [90, '#BFBFBF'],
  [40, '#B5B5B5'],
  [80, '#6A6A6A'],
  [90, '#BFBFBF'],
  [55, '#9B9A9A'],
  [30, '#6A6A6A'],
  [60, '#BFBFBF'],
  [85, '#9B9A9A'],
  [100, '#6A6A6A'],
  [55, '#BFBFBF'],
  [20, '#9B9A9A'],
]

export function PlaceholderValue({ index }: { index: number }) {
  const [height, color] = VALUES[index % VALUES.length]!

  return (
    <PlaceholderValueStyled
      style={{ height: `${height}%`, backgroundColor: color }}
    />
  )
}
