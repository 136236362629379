import styled from 'styled-components'
import { spacing } from 'utils/styled'

export const MetaBarItemStyled = styled.div`
  display: flex;
  gap: ${spacing(0.5)};
  align-items: center;

  svg {
    color: ${({ theme }) => theme.colors.text.secondary};
  }

  & > div {
    display: flex;
    align-items: center;
  }
`

export const NoWrap = styled.div`
  white-space: nowrap;
`
