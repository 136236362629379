import { useQuery } from '@tanstack/react-query'
import { useDatasource } from 'datasourceContext'
import { Tag, TestRun } from 'types'
import { toTagsWithValuesQueryKey } from './queryKeys/trendingMetrics'
import { KnownOption } from 'datasource/Selects/VariableSelect.types'
import { showAlert } from 'utils/showAlert'
import { isTestRunExpired } from 'utils/testRun'

export interface TagOptionWithValues {
  tagOption: KnownOption<string>
  tagValues: Array<KnownOption<string>>
}

export const useTagsOptionsWithValues = (
  testRun: TestRun,
  metricId?: string
) => {
  const { ds } = useDatasource()

  return useQuery({
    enabled: !isTestRunExpired(testRun),
    queryKey: toTagsWithValuesQueryKey(testRun.id),
    queryFn: () =>
      metricId ? ds.fetchTagsWithValues(testRun.id, metricId) : [],
    onError: () => {
      showAlert('Failed to fetch tags.', 'error')
    },
    meta: {
      alertOnError: false,
    },
    select(data: Tag[]): TagOptionWithValues[] {
      return data.map(({ name, values }) => ({
        tagOption: {
          type: 'known',
          label: name,
          value: name,
          data: name,
        },
        tagValues: values
          .filter(({ value }) => value !== '')
          .map(({ value }) => ({
            type: 'known',
            label: value,
            value: value,
            data: value,
          })),
      }))
    },
  })
}
