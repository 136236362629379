import React, { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { getRoute } from 'routeMap'
import { toFormErrors } from 'utils/form'
import { useConfirmPromptOnLeave } from 'hooks/useConfirmPrompt'
import { useIsUserOrgAdmin } from 'data/usePermissions'

import {
  useNotifications,
  useTestNotification,
  useUpdateNotification,
} from '../../hooks/useNotifications'
import {
  toNotificationClientType,
  toNotificationScriptError,
} from './NotificationForm.utils'
import {
  NotificationChannel,
  NotificationChannelDraft,
  NotificationScriptError,
} from '../../NotificationsTab.types'
import { NOTIFICATION_CHANNEL_DRAFTS } from '../../NotificationsTab.constants'
import { FormWrapper } from './NotificationForm.styles'
import { NotificationForm } from './NotificationForm'

interface EditNotificationFormProps {
  notificationChannel: NotificationChannel
}

const Form = ({ notificationChannel }: EditNotificationFormProps) => {
  const history = useHistory()
  const form = useForm<NotificationChannelDraft>({
    defaultValues: notificationChannel,
  })

  const onDismiss = useCallback(() => {
    history.push(getRoute('notifications'))
  }, [history])

  const [scriptError, setScriptError] = useState<NotificationScriptError>()

  const { mutate: testNotification, isLoading: isTestingNotification } =
    useTestNotification()

  const { mutateAsync: updateNotification, isLoading: isEditingNotification } =
    useUpdateNotification({
      onSuccess: onDismiss,
      onError: (errors) => {
        toFormErrors(errors).forEach(([field, error]) => {
          if (field !== 'template') {
            form.setError(field, error)
            return
          }

          const { errorDetails, fieldError } = toNotificationScriptError(errors)
          form.setError(field, fieldError, { shouldFocus: true })
          setScriptError(errorDetails)
        })
      },
    })

  const selectedClient = toNotificationClientType(notificationChannel)

  const showScript =
    notificationChannel.template !==
    NOTIFICATION_CHANNEL_DRAFTS[selectedClient].template

  const handleTestNotification = () => testNotification(notificationChannel.id)

  const handleSubmit = form.handleSubmit(
    async (notificationDraft) => await updateNotification(notificationDraft)
  )

  useConfirmPromptOnLeave({
    show: form.formState.isDirty && !isEditingNotification,
  })

  return (
    <FormProvider {...form}>
      <FormWrapper>
        <NotificationForm
          scriptError={scriptError}
          showScript={showScript}
          isLoading={isEditingNotification || isTestingNotification}
          onTestNotification={handleTestNotification}
          onDismissForm={onDismiss}
          onSubmitForm={handleSubmit}
        />
      </FormWrapper>
    </FormProvider>
  )
}

export const EditNotificationForm = () => {
  const { notificationId } = useParams<{ notificationId: string }>()

  const isOrgAdmin = useIsUserOrgAdmin()
  const { data } = useNotifications(isOrgAdmin)

  const [notificationChannelToUpdate, setNotificationChannelToUpdate] =
    useState<NotificationChannel>()

  useEffect(() => {
    setNotificationChannelToUpdate(
      data?.find((notification) => notification.id === Number(notificationId))
    )
  }, [data, notificationId])

  if (!notificationChannelToUpdate) {
    return null
  }

  return <Form notificationChannel={notificationChannelToUpdate} />
}
