import React from 'react'
import styled from 'styled-components'
import { BackgroundTask, isTestRunTask } from 'types'

import { TestRunActivity } from './TestRunActivity'

interface ActivityItemProps {
  item: BackgroundTask
  onModalOpen: () => void
  onModalClose: () => void
}

export const ActivityItem = ({
  item,
  onModalClose,
  onModalOpen,
}: ActivityItemProps) => {
  if (!isTestRunTask(item)) {
    // future ticket
    return null
  }

  return (
    <Wrapper>
      <TestRunActivity
        testRunID={item.test_run_id}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(3, 2)};
  border-bottom: ${({ theme }) => theme.colors.border.weak} 1px solid;
`
