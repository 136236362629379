import { measureText } from 'utils/measureText'

const MEASURING_TOLERANCE = 10 // Chrome is happy with this at 1 but Safari needs 10

export function getDisplayName(
  url: string,
  availableWidth: number,
  element: HTMLElement
): string {
  try {
    const singleLineWidth = measureText(url, element).width
    const availableWidthWithTolerance = availableWidth - MEASURING_TOLERANCE

    if (availableWidthWithTolerance >= Math.round(singleLineWidth)) {
      return url
    }

    const { hostname, pathname, search } = new URL(url)
    const withoutProtocol = `${hostname}${pathname}${search}`
    const withoutProtocolWidth = measureText(withoutProtocol, element).width

    if (availableWidthWithTolerance >= Math.round(withoutProtocolWidth)) {
      return withoutProtocol
    }

    // The URL is too long to show and no pathname & search exists
    // Better show URL without protocol rather than '.../'
    if (pathname === '/' && search === '') {
      return withoutProtocol
    }

    return `...${pathname}${search}`
  } catch (e) {
    return url
  }
}
