import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Tooltip, Icon } from '@grafana/ui'

import { routeMap } from 'routeMap'
import { color, spacing } from 'utils/styled'

interface UsageReportsProjectNameProps {
  deleted: boolean
  projectName: string
  projectId: number
}

export const UsageReportsProjectName = ({
  deleted,
  projectName,
  projectId,
}: UsageReportsProjectNameProps) => {
  if (deleted) {
    return (
      <DeletedProjectName>
        <span className="project-name">{projectName}</span>

        <Tooltip content="This project has been deleted">
          <span>
            <Icon name="info-circle" />
          </span>
        </Tooltip>
      </DeletedProjectName>
    )
  }

  return <Link to={routeMap.project(projectId)}>{projectName}</Link>
}

const DeletedProjectName = styled.div`
  display: flex;

  .project-name {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    margin-left: ${spacing(0.75)};
    color: ${color('info', 'main')};
  }
`
