import type {
  InsightCategoryId,
  InsightAudit,
  InsightCategoryResult,
  InsightCategoryResultResponse,
  InsightCategoryResultWithTestRun,
  InsightGroup,
  InsightPartialTestRun,
} from 'types/cloudInsights'

export const isCategoryId =
  (categoryId: InsightCategoryId) =>
  (entity?: InsightCategoryResult | InsightGroup | InsightAudit) => {
    return entity?.category_id === categoryId
  }

export const toCategoryWithTestRun = ({
  id,
  created,
  started,
}: InsightPartialTestRun) => {
  return (
    data: InsightCategoryResultResponse
  ): InsightCategoryResultWithTestRun[] => {
    return data.categories.map((category) => ({
      ...category,
      test_run: {
        id,
        created,
        started,
      },
    }))
  }
}
