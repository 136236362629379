import React, { HTMLProps, forwardRef } from 'react'
import { Field, Icon, Input, Label, Tooltip } from '@grafana/ui'
import styled from 'styled-components'

import { spacing } from 'utils/styled'

interface ProjectLimitsFormFieldProps
  extends Omit<HTMLProps<HTMLInputElement>, 'width'> {
  error?: string
  invalid: boolean
}

export const VUhMaxPerMonthField = forwardRef<
  HTMLInputElement,
  ProjectLimitsFormFieldProps
>(function VUhMaxPerMonthField({ error, invalid, ...inputProps }, ref) {
  return (
    <StyledField
      htmlFor="vuh_max_per_month"
      error={error}
      invalid={invalid}
      label="Max VUh per month"
    >
      <Input
        {...inputProps}
        ref={ref}
        id="vuh_max_per_month"
        placeholder="e.g. 1000"
        suffix="VUh"
        autoComplete="off"
        min={1}
        type="number"
      />
    </StyledField>
  )
})

export const VUMaxPerTestField = forwardRef<
  HTMLInputElement,
  ProjectLimitsFormFieldProps
>(function VUMaxPerTestField({ error, invalid, ...inputProps }, ref) {
  return (
    <StyledField
      htmlFor="vu_max_per_test"
      error={error}
      invalid={invalid}
      label="Max VUs per test"
    >
      <Input
        {...inputProps}
        ref={ref}
        id="vu_max_per_test"
        placeholder="e.g. 1000"
        suffix="VUs"
        autoComplete="off"
        min={1}
        type="number"
      />
    </StyledField>
  )
})

export const DurationMaxPerTestField = forwardRef<
  HTMLInputElement,
  ProjectLimitsFormFieldProps
>(function DurationMaxPerTestField({ error, invalid, ...inputProps }, ref) {
  const label = (
    <Tooltip
      placement="top"
      content={
        <>
          Duration should be specified in <b>h</b>, <b>m</b>, or <b>s</b>.
          <br />
          Multiple can be combined, e.g.{' '}
          <i>
            40<b>m</b>30<b>s</b>
          </i>
        </>
      }
    >
      <span>
        <Label>
          <StyledLabel>Max duration per test</StyledLabel>
          <Icon
            name="info-circle"
            size="xs"
            aria-label="Duration should be specified in h, m, or s. Multiple can be combined, e.g. 40m30s"
          />
        </Label>
      </span>
    </Tooltip>
  )

  return (
    <StyledField
      htmlFor="duration_max_per_test"
      error={error}
      invalid={invalid}
      label={label}
    >
      <Input
        {...inputProps}
        ref={ref}
        id="duration_max_per_test"
        placeholder="e.g. 1h"
        autoComplete="off"
        type="text"
      />
    </StyledField>
  )
})

const StyledField = styled(Field)`
  flex: 1;
`

const StyledLabel = styled.span`
  margin-right: ${spacing(0.5)};
  white-space: nowrap;
`
