import React from 'react'
import { Drawer } from '@grafana/ui'

import { useIsRBACEnabled } from 'data/usePermissions'
import { Project } from 'types'
import { ProjectRolesTable } from './ProjectLegacyPermissions'
import { ProjectFolderPermissions } from './ProjectFolderPermissions'
import { QueryErrorBoundary } from 'components/ErrorBoundary'
import { ErrorBoundaryLocalView } from 'services/tracking.types'

interface ProjectPermissionsDrawerProps {
  project: Project
  onClose: () => void
}

export function ProjectPermissionsDrawer({
  project,
  onClose,
}: ProjectPermissionsDrawerProps) {
  const isRBACEnabled = useIsRBACEnabled()

  return (
    <Drawer
      title="Manage permissions"
      subtitle={project.name}
      onClose={onClose}
      size="md"
    >
      {isRBACEnabled ? (
        <QueryErrorBoundary view={ErrorBoundaryLocalView.ProjectRolesTable}>
          <ProjectFolderPermissions project={project} />
        </QueryErrorBoundary>
      ) : (
        <ProjectRolesTable projectId={project.id} />
      )}
    </Drawer>
  )
}
