import { useEffect, useState } from 'react'
import { SeriesVisibilityChangeMode } from '@grafana/ui'
import { UseQueryResult } from '@tanstack/react-query'
import {
  ByNamesMatcherMode,
  ConfigOverrideRule,
  FieldMatcherID,
} from '@grafana/data'
import { MetricData } from 'datasource/models'

export function useFieldOverrides(series: Array<UseQueryResult<MetricData>>) {
  const [toggledFields, setToggledFields] = useState<string[]>([])
  const seriesLabels = series.map((s) => s.data?.label).join(':')

  useEffect(() => {
    setToggledFields([])
  }, [seriesLabels])

  const onToggleSeriesVisibility = (
    label: string,
    mode: SeriesVisibilityChangeMode
  ) => {
    if (mode === SeriesVisibilityChangeMode.AppendToSelection) {
      setToggledFields(
        toggledFields.includes(label)
          ? toggledFields.filter((f) => f !== label)
          : [...toggledFields, label]
      )
    }

    if (mode === SeriesVisibilityChangeMode.ToggleSelection) {
      if (toggledFields.length === 1 && toggledFields[0] === label) {
        setToggledFields([])
      } else {
        setToggledFields([label])
      }
    }
  }

  const overrides: ConfigOverrideRule[] =
    toggledFields.length > 0
      ? [
          {
            matcher: {
              id: FieldMatcherID.byNames,
              options: {
                mode: ByNamesMatcherMode.exclude,
                names: toggledFields,
              },
            },
            properties: [
              {
                id: 'custom.hideFrom',
                value: {
                  viz: true,
                  legend: false,
                  tooltip: false,
                },
              },
            ],
          },
        ]
      : []

  return {
    onToggleSeriesVisibility,
    overrides,
  }
}
