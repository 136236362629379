import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

export interface PillProps {
  children: ReactNode
  isSelected: boolean
  as?: keyof JSX.IntrinsicElements
}

export const Pill = ({
  as = 'button',
  children,
  isSelected,
  ...rest
}: PillProps) => {
  return (
    <StyledPill as={as} $isSelected={isSelected} {...rest}>
      {children}
    </StyledPill>
  )
}

export const StyledPill = styled.div<{ $isSelected: boolean }>`
  ${({ theme, $isSelected }) =>
    css`
      background: ${$isSelected
        ? theme.colors.action.selected
        : theme.colors.background.secondary};
      border: unset;
      border-radius: ${theme.shape.borderRadius(8)};
      padding: ${theme.spacing(0, 2)};
      font-size: ${theme.typography.bodySmall.fontSize};
      font-weight: ${theme.typography.fontWeightMedium};
      line-height: ${theme.typography.bodySmall.lineHeight};
      color: ${$isSelected
        ? theme.colors.text.primary
        : theme.colors.text.secondary};
      display: flex;
      align-items: center;
      height: 32px;

      &:hover {
        background: ${$isSelected
          ? theme.colors.action.focus
          : theme.colors.action.hover};
        color: ${theme.colors.text.primary};
      }
    `}
`
