import { FieldError, FieldErrors } from 'react-hook-form'
import { isEmpty } from 'lodash-es'

import { ApiError } from '../types'
import { ResponseError } from 'types/errors'
import { GENERIC_API_ERROR_MESSAGE } from 'constants/errors'

export const getFieldError = <FormFields>(error: ApiError) => {
  const fieldErrors = error.data?.error?.field_errors
  if (fieldErrors) {
    //Deal with errors one by one
    const erroneousField = Object.keys(fieldErrors)[0]
    const erroneousFieldMessages = erroneousField && fieldErrors[erroneousField]

    if (
      erroneousField &&
      erroneousFieldMessages &&
      erroneousFieldMessages.length > 0
    ) {
      return {
        field: erroneousField as keyof FormFields,
        message: erroneousFieldMessages[0],
      }
    }
    return
  }
  return
}

export const getNonFieldError = (error: ApiError) => {
  const nonFieldErrors = error.data.error?.field_errors?.non_field_errors
  if (nonFieldErrors && nonFieldErrors.length > 0) {
    return nonFieldErrors[0] || GENERIC_API_ERROR_MESSAGE
  }
  return
}

export const toFormErrors = <Fields>(
  error: ResponseError<Fields>
): Array<[keyof Fields, FieldError]> => {
  const fieldErrors = error.data?.error?.field_errors

  if (!fieldErrors) {
    return []
  }

  return Object.entries(fieldErrors).map(([field, message]) => [
    field as keyof Fields,
    { type: 'server', message: Array.isArray(message) ? message[0] : message },
  ])
}

export const toFormFieldError = (message: string): FieldError => {
  return {
    type: 'server',
    message: Array.isArray(message) ? message[0] : message,
  }
}

/**
 * ref: https://react-hook-form.com/api/useform/formstate/
 *
 * setError has no effect on isValid formState, isValid will always derive via the entire form validation result.
 */
export const isFormValid = (errors: FieldErrors) => isEmpty(errors)
