import React, { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Alert, Field, Icon, Input } from '@grafana/ui'
import styled from 'styled-components'

import { Button } from 'components/Button'
// import { ExternalLink } from 'components/ExternalLink'
import { spacing } from 'utils/styled'
import { useConfirmation } from 'hooks/useConfirmation'
import { ConfirmModal } from 'components/ConfirmModal'
import { IconFieldLabel } from 'components/IconFieldLabel'
import { LoadingContainer } from 'components/LoadingContainer'
import { useAcquireStaticIPs } from 'data/useAcquireStaticIPs'
// import { getGrafanaUrl } from 'utils/env'
import { StaticIP, StaticIPStatus } from 'types'
import { useSubscriptionRule } from 'data/useSubscriptions'

import { StaticIPsCalculatorLoadZonesForm } from './LoadZones'
import { StaticIPsCalculatorFormValues } from './StaticIPsCalculator.types'
import { StaticIPsCalculatorFormRow } from './StaticIPsCalculator.styled'
import { useCalculateStaticIPsForm } from './StaticIPsCalculatorForm.utils'
import { StaticIPsCalculatorFormIncludeOwnedIPs } from './IncludeOwnedIPs'
import { staticIPsFormDefaultValues } from '../StaticIPsCalculator.constants'

export const StaticIPsCalculatorForm = ({
  staticIPs,
  onSubmit: onSubmitProp,
}: {
  staticIPs: StaticIP[] | undefined
  onSubmit: () => void
}) => {
  const {
    handleSubmit,
    watch,
    register,
    control,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useFormContext<StaticIPsCalculatorFormValues>()

  const concurrentMax = useSubscriptionRule('tests.concurrent.max')

  const {
    mutateAsync: acquireStaticIPs,
    isError: isAcquireStaticIPsError,
    error: acquireStaticIPsError,
    reset: resetAcquireStaticIPsState,
  } = useAcquireStaticIPs()

  const [acquireConfirmationProps, openAcquireConfirmModal] = useConfirmation(
    () => {
      const formValues = getValues('loadZones')
      const acquireStaticIPsPayload = formValues.reduce((payload, loadZone) => {
        if (loadZone.loadZone && loadZone.staticIPs > 0) {
          payload[loadZone.loadZone] = loadZone.staticIPs
        }

        return payload
      }, {} as Record<string, number>)

      return acquireStaticIPs(acquireStaticIPsPayload, {
        onSuccess: () => {
          onSubmitProp()

          // Add timeout to avoid calculation re-triggering
          setTimeout(() => {
            reset(staticIPsFormDefaultValues, { keepDefaultValues: true })
          }, 500)
        },
      })
    }
  )

  const loadZones = useWatch({ control, name: 'loadZones', exact: true })
  const includeOwnedIPs = useWatch({
    control,
    name: 'includeOwnedIPs',
    exact: true,
  })

  const {
    calculateStaticIPs,
    calculateStaticIPsDebounced,
    isLoading: isStaticIPsLoading,
    isError,
    hasLoaded,
    hasUserNeededIPs,
  } = useCalculateStaticIPsForm(staticIPs)

  const staticIPsToAcquire = getValues('staticIPsToAcquire')
  const isStaticIPsToAcquireZero = staticIPsToAcquire === 0

  const showIncludeOwnedIPsSwitch =
    staticIPs &&
    staticIPs.filter(
      (ip) =>
        ip.provisioning_status === StaticIPStatus.PROVISIONED ||
        ip.provisioning_status === StaticIPStatus.PROVISIONING
    ).length > 0

  const onSubmit = () => {
    if (isStaticIPsLoading) {
      return
    }

    openAcquireConfirmModal()
  }

  // Set default static IPs and track includeOwnedIPs and loadZones changes
  useEffect(() => {
    calculateStaticIPs()
  }, [loadZones, includeOwnedIPs, calculateStaticIPs])

  // Track inputs and selects to re-calculate static IPs
  useEffect(() => {
    const subscription = watch((_, { name, type }) => {
      if (type !== 'change' || !name) {
        return
      }

      const inputName = name.includes('.') ? name.split('.')[2] : name

      const trackableEvents = [
        'parallelTests',
        'maximumVUs',
        'distribution',
        'loadZone',
      ]

      if (trackableEvents.includes(inputName!)) {
        calculateStaticIPsDebounced()
      }
    })

    return () => subscription.unsubscribe()
  }, [watch, calculateStaticIPsDebounced])

  return (
    <LoadingContainer loading={!hasLoaded}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StaticIPsCalculatorFormRow>
          <Field
            label={
              <IconFieldLabel
                label="Concurrent tests"
                tooltipContent="The number of concurrent tests being executed"
                placement="top"
              />
            }
            error={errors.parallelTests?.message}
            invalid={!!errors.parallelTests}
          >
            <Input
              placeholder="Enter parallel tests"
              type="number"
              {...register('parallelTests', {
                required: {
                  value: true,
                  message: 'Concurrent tests is required',
                },
                validate: {
                  positive: (value) =>
                    value <= 0
                      ? 'Concurrent tests must be a positive number'
                      : true,
                  max: (value) =>
                    value > concurrentMax
                      ? // TODO: Add CTA to upgrade subscription
                        `Concurrent tests subscription limit is ${concurrentMax}`
                      : true,
                },
              })}
            />
          </Field>

          <Field
            label={
              <IconFieldLabel
                label="Maximum VUs"
                tooltipContent="The maximum number of virtual users a single test can support"
                placement="top"
              />
            }
            error={errors.maximumVUs?.message}
            invalid={!!errors.maximumVUs}
          >
            <Input
              placeholder="Enter maximum VUs"
              type="number"
              {...register('maximumVUs', {
                required: {
                  value: true,
                  message: 'Maximum VUs is required',
                },
                validate: {
                  positive: (value) =>
                    value <= 0 ? 'Maximum VUs must be a positive number' : true,
                },
              })}
            />
          </Field>

          <Field label={`Total static IPs`}>
            <Input
              placeholder="Loading"
              suffix={isStaticIPsLoading ? <Icon name="fa fa-spinner" /> : null}
              {...register('staticIPsToAcquire')}
              disabled
            />
          </Field>

          <div />
        </StaticIPsCalculatorFormRow>

        <StaticIPsCalculatorLoadZonesForm
          isStaticIPsLoading={isStaticIPsLoading}
        />

        {showIncludeOwnedIPsSwitch && (
          <StaticIPsCalculatorFormIncludeOwnedIPs staticIPs={staticIPs} />
        )}

        {hasUserNeededIPs && (
          <StyledAlert
            severity="info"
            title="You have the needed amount of IPs to run the provided test configuration"
          />
        )}

        <SubmitButton
          disabled={isError || !isValid || isStaticIPsToAcquireZero}
          type="submit"
        >
          {isStaticIPsToAcquireZero
            ? 'Acquire static IPs'
            : `Acquire ${staticIPsToAcquire} static IPs`}
        </SubmitButton>
      </form>

      <ConfirmModal
        {...acquireConfirmationProps}
        onDismiss={() => {
          resetAcquireStaticIPsState()
          acquireConfirmationProps.onDismiss()
        }}
        confirmButtonVariant="primary"
        title="Confirm purchase"
        body={
          <>
            {isAcquireStaticIPsError && (
              <ErrorAlert
                severity="error"
                title={
                  // @ts-expect-error: TS doesn't know that data might be there
                  acquireStaticIPsError?.data?.error?.message ??
                  'Could not acquire static IPs, please try again later'
                }
              />
            )}

            <p>
              Are you sure you wanna to acquire{' '}
              <strong>{getValues('staticIPsToAcquire')} Static IPs</strong>?
            </p>

            <p>The pricing for Static IPs starts at $30/month for each IP.</p>

            {/* <ExternalLink url={getGrafanaUrl('/pricing')}>
              Read more about pricing & billing
            </ExternalLink> */}
          </>
        }
        confirmText="Acquire"
      />
    </LoadingContainer>
  )
}

const SubmitButton = styled(Button)`
  display: flex;
  justify-content: center;
  margin-top: ${spacing(3)};
  margin-bottom: ${spacing(3)};
  min-width: 190px;
`

const StyledAlert = styled(Alert)`
  max-width: 590px;
  margin-top: ${spacing(3)};
`

const ErrorAlert = styled(Alert)`
  font-size: 14px;
`
