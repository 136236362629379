import React, { ReactNode } from 'react'
import styled from 'styled-components'

type LayoutProps = {
  children: ReactNode
}

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
`

export const Layout = ({ children }: LayoutProps) => {
  return <Container>{children}</Container>
}
