import React, { useCallback, useMemo } from 'react'
import { Field, IconButton, Select } from '@grafana/ui'
import { SelectableValue } from '@grafana/data'
import { TestRun } from 'types'
import { describeMetricOption } from 'datasource/QueryEditor/MetricConfigEditor'
import {
  KnownOption,
  SelectOption,
  VariableSelect,
} from 'datasource/Selects/VariableSelect'
import { MetricOption } from 'utils/options/metricOptions'
import { TagFilterDraft } from 'components/TagsInput/types'
import {
  TrendingMetricDraftConfig,
  TrendingMetricDraftConfigError,
} from './TrendingMetricsEditor.types'
import { CAN_CONFIGURE_MULTIPLE_TRENDS } from '../TrendingMetricsModal'
import { getOptionsWithKnownNonEmittedMetrics } from './TrendingMetricsEditor.utils'
import { TagsSection } from './TagsSection'
import {
  HorizontalGroup,
  Title,
  TrendingMetricEditorPanel,
  TrendingMetricEditorPanelHeader,
} from './TrendingMetricsEditor.styles'
import {
  getTrendingMethodOptions,
  resolveMethodOption,
  TrendAggregationOption,
} from 'utils/options/aggregations/trends'
import { SaveStateBadge } from './SaveStateBadge'
import { GroupBase } from 'react-select'

interface MetricConfigEditorProps {
  lastRun: TestRun
  trendingMetricOptions: Array<GroupBase<KnownOption<MetricOption>>>
  draft: TrendingMetricDraftConfig
  draftConfigErrors?: TrendingMetricDraftConfigError
  onChange: (draft: TrendingMetricDraftConfig) => void
  onDeleteDraft: (draft: TrendingMetricDraftConfig) => void
  duplicateOf?: number
  canDelete: boolean
  isDefault: boolean
}

export const TrendingMetricEditor = ({
  trendingMetricOptions,
  draft,
  draftConfigErrors,
  onChange,
  onDeleteDraft,
  duplicateOf,
  canDelete,
  lastRun,
  isDefault,
}: MetricConfigEditorProps) => {
  const flatOptions = useMemo(
    () => trendingMetricOptions.flatMap((group) => group.options),
    [trendingMetricOptions]
  )

  const selectedTrendingMetricOption = useMemo(
    () => flatOptions.find((option) => option.value === draft.metric),
    [flatOptions, draft.metric]
  )

  const allOptions = getOptionsWithKnownNonEmittedMetrics(
    trendingMetricOptions,
    draft.metric
  )

  const methodOptions = getTrendingMethodOptions(draft?.type)
  const selectedMethod = methodOptions.find(
    (option) => option.value?.aggregation === draft.method
  )

  const handleMetricChange = useCallback(
    (option: SelectOption<MetricOption> | undefined) => {
      const method = resolveMethodOption(option)

      if (option?.type !== 'known' || !option.data.metric || !method) {
        return
      }

      const metricType = option.data.metric.type

      onChange({
        ...draft,
        metric: option.value,
        type: metricType,
        method: draft.type === metricType ? draft.method : method.aggregation,
      })
    },
    [draft, onChange]
  )

  const handleMethodChange = ({
    value,
  }: SelectableValue<TrendAggregationOption>) => {
    if (!value?.aggregation) {
      return
    }

    onChange({
      ...draft,
      method: value.aggregation,
    })
  }

  const handleTagsChange = (tags: TagFilterDraft[]) => {
    onChange({
      ...draft,
      tags,
    })
  }

  const handleDeleteDraft = () => {
    onDeleteDraft(draft)
  }

  return (
    <TrendingMetricEditorPanel>
      {CAN_CONFIGURE_MULTIPLE_TRENDS && (
        <TrendingMetricEditorPanelHeader>
          <Title>
            <SaveStateBadge
              draft={draft}
              isDefault={isDefault}
              duplicateOf={duplicateOf}
            />
          </Title>
          {canDelete && (
            <IconButton
              onClick={handleDeleteDraft}
              variant="destructive"
              name="trash-alt"
            />
          )}
        </TrendingMetricEditorPanelHeader>
      )}
      <HorizontalGroup>
        <Field label="Metric">
          <VariableSelect
            invalid={draftConfigErrors?.metric}
            allowCustomValue={false}
            isClearable={false}
            value={draft.metric}
            options={allOptions}
            describe={describeMetricOption}
            onChange={handleMetricChange}
          />
        </Field>
        <Field label="Aggregation">
          <Select
            invalid={draftConfigErrors?.method}
            value={selectedMethod}
            options={methodOptions}
            getOptionValue={({ value }) => value?.aggregation ?? ''}
            onChange={handleMethodChange}
          />
        </Field>
      </HorizontalGroup>
      <TagsSection
        errors={draftConfigErrors?.tags}
        metric={selectedTrendingMetricOption?.data?.metric}
        tags={draft.tags}
        onChange={handleTagsChange}
        testRun={lastRun}
      />
    </TrendingMetricEditorPanel>
  )
}
