import styled from 'styled-components'

export const Header = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`

export const SwitchWrapper = styled.div`
  white-space: nowrap;
`

export const Footer = styled.footer<{ $isEmptyQuery: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ $isEmptyQuery }) =>
    $isEmptyQuery ? 'end' : 'space-between'};
`
