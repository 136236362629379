import styled from 'styled-components'

import { DataTable as DataTableBase } from 'components/DataTable'
import { ProjectLimitsRow } from './LimitsTable.types'

export const DataTable = styled(DataTableBase<ProjectLimitsRow>)`
  .rdt_TableCell {
    width: 0;
    flex: 1;
  }
`
