import { difference, keyBy, merge, uniqBy, values } from 'lodash-es'
import { useMemo, useEffect, useState } from 'react'
import {
  TestRunFilter,
  TestRunTracesFilterBy,
  TestRunTracesFilter,
  TestRunFilterWithOptions,
} from 'types'
import { TRACES_FILTER_OPTIONS } from 'constants/tracesMetrics'
import { useURLFilters } from '../../Filters/Filters.hooks'
import { BreakdownTab } from '../Breakdown.types'
import { ViewType } from '../BreakdownTable'
import { usePrevious } from 'hooks/usePrevious'

const REQUIRED_FILTER_MAP: Record<ViewType, Array<TestRunFilter['by']>> = {
  list: [
    TestRunTracesFilterBy.Scenario,
    TestRunTracesFilterBy.Group,
    TestRunTracesFilterBy.URL,
    TestRunTracesFilterBy.Method,
    TestRunTracesFilterBy.Status,
  ],
  tree: [
    TestRunTracesFilterBy.URL,
    TestRunTracesFilterBy.Method,
    TestRunTracesFilterBy.Status,
  ],
}

export function useTracesFilters(view: 'list' | 'tree') {
  const [filtersCache, setFiltersCache] = useState<TestRunFilterWithOptions[]>(
    []
  )

  const filterOptions = useMemo(
    () =>
      TRACES_FILTER_OPTIONS.map((filter) => ({
        ...filter,
        required: REQUIRED_FILTER_MAP[view].includes(filter.value),
      })),
    [view]
  )

  const requiredFilters = useMemo(
    () =>
      filterOptions
        .filter((option) => option.required)
        .map((opt) => {
          return {
            ...opt,
            by: opt.value,
            values: [],
          }
        }),
    [filterOptions]
  )

  const { replaceFilters, filters, ...filterHook } =
    useURLFilters<TestRunTracesFilter>(BreakdownTab.TRACES, filterOptions)

  // Add required filters if not present
  useEffect(() => {
    if (requiredFilters.every((val) => filters.find((f) => f.by === val.by))) {
      return
    }

    replaceFilters(uniqBy([...requiredFilters, ...filters], 'by'))
  }, [requiredFilters, filters, replaceFilters])

  const previousView = usePrevious(view)
  const previousFilters = usePrevious(filters)

  // Store list view filters to use when switching back to list view
  // without filter repopulation and layout shifting
  useEffect(() => {
    if (previousView !== view) {
      setFiltersCache(previousFilters || [])
    }
  }, [view, previousFilters, previousView])

  function filtersByView(newView: ViewType) {
    const filtersToRemove = difference(
      REQUIRED_FILTER_MAP[view],
      REQUIRED_FILTER_MAP[newView]
    )

    const filtersWithCache = values(
      merge(keyBy(filtersCache, 'by'), keyBy(filters, 'by'))
    )

    return filtersWithCache.filter((f) => !filtersToRemove.includes(f.by))
  }

  return {
    ...filterHook,
    filters,
    replaceFilters,
    filtersByView,
  }
}
