import {
  TestRun,
  WebVitalName,
  WebVitalScore,
  BrowserMetricSummary,
} from 'types'
import { Insight, InsightsByStatus } from 'types/insights'

import { WebVitalsConfigName } from 'components/WebVitalGauge/constants'
import { WebVitalValueConfig } from 'components/WebVitalGauge/types'
import {
  getWebVitalScore,
  getWebVitalValueConfig,
} from 'components/WebVitalGauge/utils'

export type WebVitalMetricName = `web_vital_${WebVitalName}`

export function getWebVitalMetricName<K extends WebVitalName>(name: K) {
  return `web_vital_${name}` as const
}

export function getSummaryP75Value(
  name: WebVitalName,
  summary: BrowserMetricSummary | undefined
) {
  const metricName = getWebVitalMetricName(name)
  return summary?.[`${metricName}_p75`] ?? null
}

export function getP75ValueConfig(
  name: WebVitalName,
  summary: BrowserMetricSummary | undefined
): WebVitalValueConfig {
  const value = getSummaryP75Value(name, summary)

  return getWebVitalValueConfig(name, value)
}

const webVitalScoreToInsightStatus = (
  score?: WebVitalScore
): keyof Omit<InsightsByStatus, 'NOT_APPLICABLE'> => {
  switch (score) {
    case 'good':
      return 'PASSED'
    case 'poor':
      return 'FAILED'
    case 'needs_improvement':
      return 'WARNING'
    default:
      return 'SKIPPED'
  }
}

const getWebVitalStatus = (name: WebVitalsConfigName, testRun: TestRun) => {
  const metricName = `web_vital_${name}_p75` as const
  const value = testRun.browser_metric_summary?.[metricName] ?? null

  return webVitalScoreToInsightStatus(getWebVitalScore(name, value))
}

export const getBrowserInsights = (testRun: TestRun): Insight[] => {
  return [
    {
      category: 'web_vitals',
      created_at: testRun.created,
      data: {
        threshold: {},
      },
      id: 0,
      name: getWebVitalMetricName('cls'),
      status: getWebVitalStatus('cls', testRun),
      test_run_id: testRun.id,
    },
    {
      category: 'web_vitals',
      created_at: testRun.created,
      data: {
        threshold: {},
      },
      id: 0,
      name: getWebVitalMetricName('fcp'),
      status: getWebVitalStatus('fcp', testRun),
      test_run_id: testRun.id,
    },
    {
      category: 'web_vitals',
      created_at: testRun.created,
      data: {
        threshold: {},
      },
      id: 0,
      name: getWebVitalMetricName('fid'),
      status: getWebVitalStatus('fid', testRun),
      test_run_id: testRun.id,
    },
    {
      category: 'web_vitals',
      created_at: testRun.created,
      data: {
        threshold: {},
      },
      id: 0,
      name: getWebVitalMetricName('inp'),
      status: getWebVitalStatus('inp', testRun),
      test_run_id: testRun.id,
    },
    {
      category: 'web_vitals',
      created_at: testRun.created,
      data: {
        threshold: {},
      },
      id: 0,
      name: getWebVitalMetricName('lcp'),
      status: getWebVitalStatus('lcp', testRun),
      test_run_id: testRun.id,
    },
    {
      category: 'web_vitals',
      created_at: testRun.created,
      data: {
        threshold: {},
      },
      id: 0,
      name: getWebVitalMetricName('ttfb'),
      status: getWebVitalStatus('ttfb', testRun),
      test_run_id: testRun.id,
    },
  ]
}
