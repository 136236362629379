import React, { useState } from 'react'
import { Button, Modal } from '@grafana/ui'
import styled from 'styled-components'

import { spacing } from 'utils/styled'
import { useOrganization } from 'data/useOrganization'
import { getGrafanaUrl } from 'utils/env'
import { InlineCollapse } from 'components/InlineCollapse'

import { InviteUsersForm } from './InviteUsersForm'
import { ProjectId } from 'types'

export const ProjectSettingsInviteUsers = ({
  projectId,
}: {
  projectId: ProjectId
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const organization = useOrganization()

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const link = getGrafanaUrl(
    organization?.grafana_org_slug
      ? `/orgs/${organization.grafana_org_slug}/members`
      : ''
  )

  return (
    <>
      <Button onClick={openModal}>Add users</Button>

      <StyledModal
        title="Add users"
        isOpen={isModalOpen}
        onDismiss={closeModal}
        closeOnEscape
        trapFocus={false}
      >
        <p>
          Add existing users by email to join your project. <br />
          To invite new users, follow these{' '}
          <InlineCollapse label="steps">
            <InstructionsList>
              <li>
                Go to{' '}
                <a href={link} target="_blank" rel="noopener noreferrer">
                  grafana.com
                </a>{' '}
                and invite the new user.
              </li>
              <li>
                Wait until the user has accepted the invite and logged in to the
                Grafana instance.
              </li>
              <li>
                After the first two steps have been completed the new user(s)
                should appear in the dropdown below.
              </li>
            </InstructionsList>
          </InlineCollapse>
        </p>

        <InviteUsersForm onSubmit={closeModal} projectId={projectId}>
          {({ isSubmitting }) => (
            <Modal.ButtonRow>
              <Button variant="secondary" onClick={closeModal}>
                Cancel
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                Add users
              </Button>
            </Modal.ButtonRow>
          )}
        </InviteUsersForm>
      </StyledModal>
    </>
  )
}

const StyledModal = styled(Modal)`
  width: 520px;
`

const InstructionsList = styled.ol`
  padding-left: ${spacing(2)};
`
