import styled, { css } from 'styled-components'
import { color } from 'utils/styled'
import { CodeWrapper } from 'components/CodeSnippet/CodeSnippet.styles'

export const PromoCodeSnippetStyled = styled.div`
  display: flex;
  flex: 1 1 100%;

  ${({ theme }) =>
    theme.isLight &&
    css`
      ${CodeWrapper} {
        // default background melts into the card background
        background-color: ${color('background', 'primary')};
      }
    `}
`
