import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { HorizontalGroup } from '@grafana/ui'

import { Test, TestRun } from 'types'
import { isTestRunning } from 'utils/testRun'
import { useRunTest } from 'data/useRunTest'
import { useStopTestRunAction } from 'data/useStopTestRun'
import { useViewerButtonTooltipProps } from 'hooks/useViewerButtonTooltipProps'
import { routeMap } from 'routeMap'
import { Button } from 'components/Button'
import { ConfirmModal } from 'components/ConfirmModal'
import { BrowserFeedbackButton } from './BrowserFeedbackButton'
import { hasBrowserMetrics } from './Buttons.utils'
import { useMaxConcurrentRunsModal } from 'components/QueueTestModal/MaxConcurrentRunsModal'
import { QueueTestModal } from 'components/QueueTestModal/QueueTestModal'
import { NoteButton } from '../NoteButton'

export interface ButtonsProps {
  test: Test
  testRun: TestRun
}

export const Buttons = ({ test, testRun }: ButtonsProps) => {
  const history = useHistory()
  const readUserButtonProps = useViewerButtonTooltipProps()

  const { mutateAsync: runTest } = useRunTest({
    onSuccess: handleRunSuccess,
  })
  const [stopTestRunConfirmationProps, handleStopTestRunClick, canStopTestRun] =
    useStopTestRunAction()

  const handleRun = () => {
    runTest(test)
  }

  const { onRun, ...maxConcurrentRunsModalProps } =
    useMaxConcurrentRunsModal(handleRun)

  const isStopDisabled = useMemo(
    () => canStopTestRun(testRun),
    [canStopTestRun, testRun]
  )

  function handleRunSuccess(newTestRun: TestRun) {
    history.push(routeMap.testRun(newTestRun.id))
  }

  return (
    <>
      <HorizontalGroup>
        {hasBrowserMetrics(testRun) && <BrowserFeedbackButton />}

        <NoteButton testRun={testRun} />

        {isTestRunning(testRun) && (
          <Button
            icon="square-shape"
            disabled={isStopDisabled}
            variant="destructive"
            onClick={() => handleStopTestRunClick(testRun)}
            {...readUserButtonProps}
          >
            Stop Test
          </Button>
        )}
      </HorizontalGroup>

      <ConfirmModal {...stopTestRunConfirmationProps} />
      <QueueTestModal {...maxConcurrentRunsModalProps} />
    </>
  )
}
