import { StaticIP } from 'types'

export const getUniqueLoadZonesFromStaticIPs = (staticIPs: StaticIP[]) =>
  staticIPs.reduce((loadZones, staticIP) => {
    if (!loadZones.includes(staticIP.load_zone_identifier)) {
      loadZones.push(staticIP.load_zone_identifier)
    }

    return loadZones
  }, [] as string[])
