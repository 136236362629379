import { toPanelConfig } from 'components/Chart/Chart.utils'
import { toExploreHref } from 'components/Chart/ChartMenu'
import { MetricConfig } from 'datasource/models'
import { useDatasource } from 'datasourceContext'
import { useTimeRange } from 'pages/TestRunPage/TimeRangeContext'
import React, { useMemo } from 'react'
import { TestRun } from 'types'
import { LinkButton } from '@grafana/ui'

interface Props {
  main: TestRun
  compareWith?: TestRun
  metrics: MetricConfig[]
}

export function ExploreLink({ main, compareWith, metrics }: Props) {
  const { ds } = useDatasource()
  const { timeRange } = useTimeRange()

  const exploreHref = useMemo(() => {
    if (metrics.length === 0) {
      return undefined
    }

    const leftPanel = {
      testRun: main,
      config: toPanelConfig(ds, '', main, metrics),
    }

    const rightPanel = compareWith && {
      testRun: compareWith,
      config: toPanelConfig(ds, '', compareWith, metrics),
    }

    return toExploreHref(leftPanel, rightPanel, timeRange)
  }, [main, compareWith, metrics, ds, timeRange])

  return (
    <LinkButton href={exploreHref} disabled={!exploreHref} icon="compass">
      View in explore
    </LinkButton>
  )
}
