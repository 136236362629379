import { type TestRun } from 'types'
import {
  useInsightsAudits,
  useInsightsCategories,
  useInsightsGroups,
  useInsightsLatestExecution,
} from 'data/useCloudInsights'
import { useQueryParameter } from 'hooks/useQueryParameter'

export const useInsightsLoading = (testRun: TestRun) => {
  const { data: execution, isLoading: isExecutionLoading } =
    useInsightsLatestExecution(testRun)

  const { isLoading: isCategoriesLoading } = useInsightsCategories(
    testRun,
    execution
  )
  const { isLoading: isGroupsLoading } = useInsightsGroups(testRun, execution)
  const { isLoading: isAuditsLoading } = useInsightsAudits(testRun, execution)

  return (
    isExecutionLoading ||
    isCategoriesLoading ||
    isGroupsLoading ||
    isAuditsLoading
  )
}

export const INSIGHTS_FILTER_ID = 'insights'
export const INSIGHTS_FILTER_VALUE = 'open'

interface UseInsightFilterParams {
  open: boolean
}

export const useInsightsFilter = () => {
  return useQueryParameter<UseInsightFilterParams>({
    name: INSIGHTS_FILTER_ID,
    decoder: (value) => ({ open: !value?.startsWith('-') }),
    encoder: ({ open }) =>
      open ? INSIGHTS_FILTER_VALUE : `-${INSIGHTS_FILTER_VALUE}`,
  })
}
