import { StaticIP, StaticIPStatus } from 'types'

export const getFilteredStaticIPData = (
  staticIPs: StaticIP[],
  loadZoneFilters: string[],
  statusFilters: StaticIPStatus[]
) => {
  if (loadZoneFilters.length === 0 && statusFilters.length === 0) {
    return staticIPs
  }

  if (loadZoneFilters.length > 0 && statusFilters.length === 0) {
    return staticIPs.filter((staticIP) =>
      loadZoneFilters.includes(staticIP.load_zone_identifier)
    )
  }

  if (statusFilters.length > 0 && loadZoneFilters.length === 0) {
    return staticIPs.filter((staticIP) =>
      statusFilters.includes(staticIP.provisioning_status)
    )
  }

  return staticIPs.filter(
    (staticIP) =>
      loadZoneFilters.includes(staticIP.load_zone_identifier) &&
      statusFilters.includes(staticIP.provisioning_status)
  )
}
