import {
  Attribute,
  BrowserTimelineData,
  InclusiveAttribute,
  Span,
} from './types'
import {
  ATTR_WEB_VITAL_NAME,
  ATTR_WEB_VITAL_VALUE,
  ROOT_SPAN_ID,
} from './constants'
import { nanoToMilliseconds } from '../../utils/math'

export function getSpans(data?: BrowserTimelineData) {
  if (data === undefined || data.batches.length === 0) {
    return []
  }

  return data.batches
    .flatMap((batch) => batch.scopeSpans.flatMap((library) => library.spans))
    .sort((a, b) => Number(a.startTimeUnixNano) - Number(b.startTimeUnixNano))
}

export function getSpanById(spanId: string | undefined, spans: Span[]) {
  return spans.find((span) => span.spanId === spanId) ?? null
}

export function getSpanByParentSpanId(
  parentSpanId: string | undefined,
  spans: Span[]
) {
  return spans.find((span) => span.parentSpanId === parentSpanId) ?? null
}

export function getRoot(spans: Span[]) {
  const span = getSpanByParentSpanId(ROOT_SPAN_ID, spans)
  if (span === null) {
    return null
  }

  const children = getSpanChildren(span, spans)

  return {
    span,
    children,
  }
}

export function getSpanChildren(parentSpan: Span, spans: Span[]) {
  return spans.filter((span) => span.parentSpanId === parentSpan.spanId)
}

export function getDuration(span: Span) {
  try {
    const diff = Number(span.endTimeUnixNano) - Number(span.startTimeUnixNano)
    return nanoToMilliseconds(diff)
  } catch (error) {
    console.error(error)
    return 0
  }
}

export function getSpanXOffset(span: Span, spans: Span[]) {
  const root = getRoot(spans)
  if (!root || root.span.spanId === span.spanId) {
    return { rootOffset: 0 }
  }

  const parent = getSpanById(span.parentSpanId, spans)

  const rootOffset = getRelativeXOffset(span, root.span)
  const parentOffset = getRelativeXOffset(span, parent)

  return {
    rootOffset,
    parentOffset,
  }
}

export function getSpanTimings(span: Span, spans: Span[]) {
  const duration = getDuration(span)
  const xOffset = getSpanXOffset(span, spans)

  return {
    duration,
    ...xOffset,
  }
}

/**
 * Get the value of an attribute
 * @param {Attribute} attribute
 */
export function getAttributeValue(attribute: Attribute) {
  const {
    key: name,
    value: { stringValue, intValue, doubleValue },
  } = attribute as InclusiveAttribute
  let value: string | number | undefined = stringValue

  if (value === undefined) {
    if (intValue !== undefined) {
      value = Number(intValue) // can be number as string
    }
    if (doubleValue !== undefined) {
      value = Number(doubleValue) // can be number as string
    }
  }

  return { name, value: value ?? null }
}

export function getAttributeValueByKey(key: string, attributes: Attribute[]) {
  const attribute = attributes.find((attribute) => attribute.key === key)
  if (!attribute) {
    return null
  }

  return getAttributeValue(attribute).value
}

export function getWebVitalAttributes(attributes: Span['attributes']) {
  if (attributes === undefined) {
    return null
  }

  const name = getAttributeValueByKey(ATTR_WEB_VITAL_NAME, attributes)
  const value = getAttributeValueByKey(ATTR_WEB_VITAL_VALUE, attributes)

  const acceptedNames = ['TTFB', 'FCP', 'LCP', 'FID', 'CLS', 'INP']
  if (typeof name === 'string' && acceptedNames.includes(name)) {
    return {
      name,
      value,
    }
  }

  return null
}

export function getRelativeXOffset(child: Span, parent: Span | null = null) {
  const parentStart =
    parent !== null ? nanoToMilliseconds(parent.startTimeUnixNano) : 0
  const childStart = nanoToMilliseconds(child.startTimeUnixNano)

  return childStart - parentStart
}

export function getRelativeXOffsetPercent(child: Span, parent: Span) {
  const parentDuration = getDuration(parent)
  const offsetX = getRelativeXOffset(child, parent)

  return (offsetX / parentDuration) * 100
}

export function getAttribute(
  attributes: Attribute[] | undefined,
  name: string
) {
  if (attributes === undefined) {
    return undefined
  }

  const attribute = attributes.find((attribute) => attribute.key === name)
  if (!attribute) {
    return undefined
  }

  return getAttributeValue(attribute).value ?? undefined
}
