import React from 'react'
import { WebVitalName } from 'types'
import { WebVitalScoreWrapper } from './BrowserTab.styled'
import { getWebVitalValueConfig } from 'components/WebVitalGauge/utils'
import { BrowserUrlsTableColumn } from './constants'
import { getWebVitalMetricName, WebVitalMetricName } from 'utils/webVitals'
import { BrowserUrls } from 'types/k6Browser'
import { RowStatus } from '../BreakdownTable/types'

// The `p75` value is always 0, even if there are no values for the metric
// Checking `count` instead, to determine if the metric has a value
function hasValue(row: BrowserUrls, metricName: WebVitalMetricName) {
  return row.browser_http_metric_summary[metricName]?.count !== 0
}

export function createWebVitalColumn(
  name: WebVitalName
): BrowserUrlsTableColumn {
  const metricName = getWebVitalMetricName(name)
  const summaryKey = `${metricName}_p75` as const

  return {
    id: name,
    name: name.toUpperCase(),
    sortBy: `browser_http_metric_summary/${summaryKey}`,
    renderCell(row) {
      const rawValue = hasValue(row, metricName)
        ? row.browser_http_metric_summary[summaryKey]
        : null
      const value = getWebVitalValueConfig(name, rawValue)

      return (
        <WebVitalScoreWrapper className={`score--${value.score}`}>
          {String(value)}
        </WebVitalScoreWrapper>
      )
    },
  }
}

export function getRowStatus(row: BrowserUrls): RowStatus {
  const webVitalMetrics = [
    'web_vital_lcp',
    'web_vital_fid',
    'web_vital_cls',
    'web_vital_fcp',
    'web_vital_ttfb',
    'web_vital_inp',
  ] as const

  return webVitalMetrics.reduce<RowStatus>((acc, metricName) => {
    if (acc === 'error') {
      return acc
    }
    const name = metricName.substring('web_vital_'.length) as WebVitalName
    const rawValue = hasValue(row, metricName)
      ? row.browser_http_metric_summary[`${metricName}_p75`]
      : null
    const value = getWebVitalValueConfig(name, rawValue)

    if (value.score === 'needs_improvement') {
      return 'warning'
    }

    if (value.score === 'poor') {
      return 'error'
    }

    return acc
  }, 'success')
}
