import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useAppContext } from '../appContext'
import { useDatasource } from '../datasourceContext'
import { showAlert } from '../utils/showAlert'
import { toOrgTokensQueryKey } from './queryKeys'

export function useRegenerateOrganizationToken() {
  const queryClient = useQueryClient()
  const { orgId } = useAppContext()
  const { ds } = useDatasource()

  return useMutation({
    mutationFn: (token: string) => ds.regenerateOrganizationToken(token),
    async onSuccess() {
      showAlert('Grafana stack API token updated!', 'success')
      await queryClient.invalidateQueries(toOrgTokensQueryKey(orgId))
    },
    onError() {
      showAlert('Failed to generate new Grafana stack API token.', 'error')
    },
  })
}
