import { useMetricClient } from 'data/clients/metrics/metrics'
import { useOptionalQuery } from 'hooks/useOptionalQuery'
import { type TestRun } from 'types'

export function useCustomMetrics(testRunId?: TestRun['id']) {
  const metricClient = useMetricClient()

  const query = testRunId && {
    queryKey: ['query-editor', metricClient.id, 'metrics', testRunId],
    queryFn: () => metricClient.fetchAll(testRunId),
  }

  return useOptionalQuery(query)
}
