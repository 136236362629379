import React, { useCallback } from 'react'

import { type TestRun } from 'types'
import { CloudInsightTestId } from 'types/dataTestIds'
import { isInsightFailed } from 'utils/insights'
import {
  useInsightsLatestExecution,
  useInsightsLatestExecutionResult,
} from 'data/useCloudInsights'
import { CollapsableSection } from 'components/CollapsableSection'
import { LoadingContainer } from 'components/LoadingContainer'

import { INSIGHTS_MIN_HEIGHT } from './constants'
import { useAuditQueryParams } from './hooks'
import { CloudInsightsContent } from './components/CloudInsightsContent'
import { CloudInsightsExecutionFailed } from './components/CloudInsightsExecutionFailed'
import { CloudInsightsHeader } from './components/CloudInsightsHeader'
import { useInsightsFilter, useInsightsLoading } from './CloudInsights.hooks'
import { Container, Content } from './CloudInsights.styles'

interface CloudInsightsProps {
  testRun: TestRun
}

export function CloudInsights({ testRun }: CloudInsightsProps) {
  const [{ open }, setInsightsFilter] = useInsightsFilter()
  const [{ filter }, setAuditQueryParam] = useAuditQueryParams(testRun)
  const isInsightsLoading = useInsightsLoading(testRun)
  const { error: executionError } = useInsightsLatestExecution(testRun)
  const { data: executionResultData, isLoading: isExecutionResultLoading } =
    useInsightsLatestExecutionResult(testRun)

  const hasExecutionSkipped = executionError?.status === 404
  const hasExecutionFailed =
    hasExecutionSkipped || isInsightFailed(executionResultData)
  const isLoading =
    !hasExecutionSkipped && (isInsightsLoading || isExecutionResultLoading)

  const handleSectionToggle = useCallback(() => {
    setInsightsFilter('replace', {
      open: !open,
    })

    if (open && !!filter) {
      setAuditQueryParam('replace', {
        filter: '',
        open: false,
      })
    }
  }, [open, filter, setInsightsFilter, setAuditQueryParam])

  return (
    <Container data-testid={CloudInsightTestId.Root}>
      <CollapsableSection
        label={<CloudInsightsHeader testRun={testRun} />}
        isOpen={open}
        onToggle={handleSectionToggle}
      >
        <LoadingContainer
          loading={isLoading}
          estimatedHeight={INSIGHTS_MIN_HEIGHT}
        >
          <Content loading={isLoading}>
            {hasExecutionFailed ? (
              <CloudInsightsExecutionFailed
                status={executionResultData?.status_reason}
              />
            ) : (
              <CloudInsightsContent testRun={testRun} />
            )}
          </Content>
        </LoadingContainer>
      </CollapsableSection>
    </Container>
  )
}
