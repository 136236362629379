import styled from 'styled-components'

import { spacing } from 'utils/styled'

export const ExportModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing(2)};
  padding: ${spacing(5)} 0;
`

export const IconWrapper = styled.span`
  font-size: 20px;
`
