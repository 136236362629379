import { type InsightAudit, type InsightAuditResult } from 'types/cloudInsights'
import { getScoreStatus } from 'utils/insights'

export const hasAuditId =
  (audits: InsightAudit[]) => (result: InsightAuditResult) => {
    return audits.some((audit) => audit.id === result.audit_id)
  }

export const getAuditScoreCount = (results: InsightAuditResult[]) => {
  return results.reduce(
    (scores, result) => {
      const status = getScoreStatus(result.score)

      return {
        ...scores,
        [status]: scores[status] + 1,
      }
    },
    {
      good: 0,
      'needs-improvement': 0,
      poor: 0,
      skipped: 0,
    }
  )
}
