import React, { useMemo, useState } from 'react'

import { DataTable } from 'components/DataTable'
import { StaticIP } from 'types'
import { ConfirmModal } from 'components/ConfirmModal'
import { useConfirmation } from 'hooks/useConfirmation'
import { useLoadZones } from 'data/useLoadZones'
import { useDeprovisionStaticIPs } from 'data/useDeprovisionStaticIPs'

import {
  generateBatchDeleteActions,
  useBatching,
} from 'components/DataTable/hooks/useBatching'

import { staticIPsColumns } from './StaticIPsListColumns'
import { STATIC_IP_DELETABLE_STATUSES } from '../StaticIPs.constants'

interface Props {
  isFetching: boolean
  data: StaticIP[] | undefined
}

export const StaticIPsList = ({ isFetching, data = [] }: Props) => {
  const { data: loadZones = [] } = useLoadZones()
  const [staticIPsToDelete, setStaticIPsToDelete] = useState<StaticIP[]>([])
  const { mutateAsync: deprovisionStaticIPs } = useDeprovisionStaticIPs()

  const [acquireConfirmationProps, openAcquireConfirmModal] = useConfirmation(
    () => {
      return deprovisionStaticIPs(staticIPsToDelete.map((ip) => ip.id))
    }
  )

  const batchAction = useBatching<StaticIP>(
    (data ?? []).filter((item) =>
      STATIC_IP_DELETABLE_STATUSES.includes(item.provisioning_status)
    )
  )

  const actions = generateBatchDeleteActions(
    batchAction,
    (items) => {
      setStaticIPsToDelete(items)
      openAcquireConfirmModal()
    },
    (item) => !STATIC_IP_DELETABLE_STATUSES.includes(item.provisioning_status),
    {
      noun: 'IP',
      action: 'Delete',
    }
  )

  const formattedData = useMemo(() => {
    return data
      .map((item) => {
        const loadZone = loadZones.find(
          (zone) => zone.k6_load_zone_id === item.load_zone_identifier
        )

        return {
          ...item,
          load_zone: loadZone,
          load_zone_city: loadZone?.city,
        }
      })
      .sort((a, b) => a.id - b.id)
  }, [loadZones, data])

  return (
    <>
      <DataTable
        columns={staticIPsColumns}
        actions={actions}
        noDataMessage={''}
        data={formattedData}
        highlightOnHover={false}
        keyField="id"
        isFetching={isFetching && data.length > 0}
      />

      <ConfirmModal
        {...acquireConfirmationProps}
        title="Confirm action"
        body={
          <p>
            Are you sure you wanna to deprovision{' '}
            <strong>
              {staticIPsToDelete.length} Static IP
              {staticIPsToDelete.length > 1 ? 's' : ''}
            </strong>
            ?
          </p>
        }
        confirmText="Delete"
      />
    </>
  )
}
