import React from 'react'
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer'
import { Alert, PanelChrome } from '@grafana/ui'

import type { TestRunAnalysis } from 'types'
import type { TracesSummary } from 'types/traces'
import { useTracesExemplars, useTracesTimeSeries } from 'data/useTraces'
import { MetricConfig } from 'datasource/models'
import { ChartPanel } from 'components/Chart/ChartPanel'
import { LoadingContainer } from 'components/LoadingContainer'

import { useTimeRange } from '../../../TimeRangeContext'
import { ChartFilters } from '../../ChartFilters/ChartFilters'
import { useTracesChartTags } from './TracesChart.hooks'
import { LoadingState } from '@grafana/schema'
import { ChartMenu } from 'components/Chart/ChartMenu'
import { Flex } from 'components/Flex'
import { isTestRunWithData } from 'components/Chart/Chart.utils'
import { useColorPool } from 'hooks/useColorPool'

export function TracesChart({
  analysis,
  tracesSummary,
}: {
  analysis: TestRunAnalysis
  tracesSummary: TracesSummary
}) {
  const tags = useTracesChartTags({ tracesSummary })

  return (
    <ChartFilters tags={tags} nodes={analysis.main.nodes} type="traces">
      {(metrics) => (
        <Chart
          metrics={metrics}
          analysis={analysis}
          title={tracesSummary.span.name}
        />
      )}
    </ChartFilters>
  )
}

function Chart({
  analysis,
  metrics,
  title,
}: {
  metrics: MetricConfig[]
  analysis: TestRunAnalysis
  title: string
}) {
  const colorPool = useColorPool()
  const { timeRange, setTimeRange } = useTimeRange()

  const series = useTracesTimeSeries({
    left: analysis.main,
    right: analysis.compareWith,
    metrics,
    colorPool,
  })

  const exemplars = useTracesExemplars({
    left: analysis.main,
    right: analysis.compareWith,
    metric: metrics[0]!,
    colorPool,
    // Disable exemplars for span count chart
    enabled: metrics[0]?.query.metric !== 'span_count',
  })

  const isLoading = series.some((s) => s.isLoading)
  const isLoadingExemplars = exemplars.some((e) => e.isFetching)
  const isExemplarError = exemplars.some((e) => e.isError)
  const refetchExemplars = () =>
    exemplars.filter((e) => e.isError).map((e) => e.refetch())

  const noData =
    !isTestRunWithData(analysis.main) ||
    series.every((s) => s.data?.data?.values.length === 0)

  return (
    <ReactVirtualizedAutoSizer disableHeight>
      {(size) => (
        <LoadingContainer loading={isLoading} style={{ width: size.width }}>
          {isExemplarError && (
            <Alert
              title="Failed to load exemplars"
              onRemove={() => refetchExemplars()}
              buttonContent="Retry"
            >
              An error has occurred while fetching the exemplars, click retry to
              try again. If the problem persists, please contact support.
            </Alert>
          )}
          <PanelChrome
            width={size.width || 0}
            height={300}
            loadingState={
              isLoadingExemplars ? LoadingState.Loading : LoadingState.Done
            }
            actions={
              <Flex align="center" gap={2}>
                {!noData && (
                  <ChartMenu
                    title={title}
                    testRun={analysis.main}
                    compareWith={analysis.compareWith}
                    metrics={metrics}
                    timeRange={timeRange}
                  />
                )}
              </Flex>
            }
          >
            {(innerWidth, innerHeight) => (
              <ChartPanel
                width={innerWidth}
                height={innerHeight}
                series={series}
                testRun={analysis.main}
                annotations={exemplars.flatMap((e) => e.data || [])}
                compareWith={analysis.compareWith}
                timeRange={timeRange}
                onChangeTimeRange={setTimeRange}
              />
            )}
          </PanelChrome>
        </LoadingContainer>
      )}
    </ReactVirtualizedAutoSizer>
  )
}
