import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'

import { useDatasource } from 'datasourceContext'
import { ProjectRolesResponse } from 'datasource/datasource'
import {
  ApiError,
  ProjectId,
  ProjectRole,
  ProjectRoleWithUser,
  UpdateRoleBody,
} from 'types'
import { showAlert } from 'utils/showAlert'
import { addPlural } from 'utils'

import { toProjectRolesQueryKey } from './queryKeys'
import { QueryOptions } from './queryClient'
import { useUsers } from './useUsers'

type ProjectRolesParams = {
  pageSize?: number
  orderBy?: string
  emailFilter?: string
}

export const useProjectRoles = (
  projectId: ProjectId,
  {
    pageSize = 20,
    orderBy,
    emailFilter = '',
    alertOnError = true,
    ...options
  }: QueryOptions<ProjectRolesResponse> & {
    alertOnError?: boolean
  } & ProjectRolesParams = {}
) => {
  const [currentPage, setCurrentPage] = useState(1)
  const { ds } = useDatasource()

  useEffect(() => {
    setCurrentPage(1)
  }, [pageSize, orderBy, emailFilter])

  const response = useQuery<ProjectRolesResponse>({
    queryKey: toProjectRolesQueryKey(projectId, {
      page: currentPage,
      count: true,
      orderBy,
      pageSize,
      emailFilter,
    }),
    queryFn: () =>
      ds.fetchProjectRoles({
        projectId,
        pageSize,
        orderBy,
        count: true,
        page: currentPage,
        emailFilter,
      }),
    meta: {
      alertOnError,
    },
    ...options,
  })

  return { ...response, currentPage, fetchPage: setCurrentPage, orderBy }
}

export const useProjectRolesWithUser = (
  projectId: ProjectId,
  options: {
    enabled?: boolean
    keepPreviousData?: boolean
    useErrorBoundary?: boolean
    alertOnError?: boolean
  } & ProjectRolesParams = {}
) => {
  const {
    data: projectRolesResponse,
    isSuccess,
    currentPage,
    fetchPage,
    isFetching: isProjectRolesFetching,
    isLoading: isProjectRolesLoading,
    isError: isProjectRolesError,
    refetch: refetchProjectRoles,
  } = useProjectRoles(projectId, options)

  const projectRoles = useMemo(
    () => projectRolesResponse?.value ?? [],
    [projectRolesResponse]
  )

  const {
    data: users,
    isFetching: isUsersFetching,
    isLoading: isUsersLoading,
    isError: isUserError,
  } = useUsers(
    projectRoles.map((role) => role.user_id),
    {
      enabled: isSuccess,
      keepPreviousData: true,
      useCachedData: true,
      useErrorBoundary: options.useErrorBoundary ?? true,
      alertOnError: options.alertOnError ?? true,
    }
  )

  const projectRolesWithUser: ProjectRoleWithUser[] = useMemo(
    () =>
      projectRoles.map((role) => {
        return {
          ...role,
          user: isUserError
            ? null
            : (users ?? []).find((user) => user.id === role.user_id),
        }
      }),
    [projectRoles, users, isUserError]
  )

  return {
    currentPage,
    fetchPage,
    data: projectRolesWithUser,
    count: projectRolesResponse?.['@count'],
    isFetching: isProjectRolesFetching || isUsersFetching,
    isLoading: isProjectRolesLoading || isUsersLoading,
    isProjectRolesFetching,
    isProjectRolesError,
    refetch: refetchProjectRoles,
  }
}

type ProjectRoleEmailPayload = {
  id: ProjectId
  user_email: string
  user_id: number
}

export const useProjectRoleEmails = (
  projectId: ProjectId,
  options?: QueryOptions<ProjectRoleEmailPayload[]>
) => {
  const { ds } = useDatasource()
  const params = { select: 'id,user_email,user_id', count: false }

  return useQuery<ProjectRoleEmailPayload[]>({
    queryKey: toProjectRolesQueryKey(projectId, params),
    queryFn: () =>
      ds.fetchProjectRoles({ projectId, ...params }).then((res) => res.value),
    ...options,
  })
}

// TODO: Update when we have a batch delete endpoint
export const useDeleteProjectRoles = (projectId: ProjectId) => {
  const queryClient = useQueryClient()
  const { ds } = useDatasource()

  return useMutation({
    mutationFn: (roles: ProjectRole[]) => {
      const roleIds = roles.map((role) => role.id)
      return ds.deleteProjectRoles(projectId, roleIds)
    },
    onError: (error, roles) => {
      showAlert(
        addPlural(
          `Failed to remove ${roles.length} project member`,
          roles.length
        ),
        'error'
      )
    },
    onSuccess: (result, roles) => {
      showAlert(
        addPlural(
          `Successfully removed ${roles.length} project member`,
          roles.length
        ),
        'success'
      )
      queryClient.invalidateQueries(toProjectRolesQueryKey(projectId))
    },
  })
}

export const useCreateProjectRole = () => {
  const queryClient = useQueryClient()
  const { ds } = useDatasource()

  return useMutation<
    ProjectRole,
    ApiError,
    { role_id: number; project_id: number; user_id: number },
    unknown
  >({
    mutationFn: (body) => ds.createProjectRole(body),
    onSuccess: (_, role) => {
      queryClient.invalidateQueries(toProjectRolesQueryKey(role.project_id))
    },
  })
}

export const useUpdateProjectRole = () => {
  const queryClient = useQueryClient()
  const { ds } = useDatasource()

  return useMutation<ProjectRole, ApiError, UpdateRoleBody, unknown>({
    mutationFn: (role) => ds.updateProjectRole(role),
    onSuccess: (response) => {
      queryClient.setQueriesData(
        toProjectRolesQueryKey(response.project_id),
        (data?: ProjectRolesResponse) => {
          if (!data?.value) {
            return data
          }

          return {
            ...data,
            value: data.value.map((role) => {
              return role.id === response.id ? { ...role, ...response } : role
            }),
          }
        }
      )
    },
  })
}
