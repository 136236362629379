import styled from 'styled-components'
import { spacing } from 'utils/styled'
import { fadeIn } from 'theme'
import { LottieAnimation } from 'components/LottieAnimation'

export const StyledLottieAnimation = styled(LottieAnimation)``

export const AnimationContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  overflow: hidden;
  ${fadeIn}; // Fade-in animation

  ${({ theme }) => theme.breakpoints.up('sm')} {
    bottom: ${spacing(-2)};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    bottom: ${spacing(-3)};
  }

  ${StyledLottieAnimation} {
    position: absolute;
    top: 0;
    // Cut the bottom part slightly, letting the animation take more space
    bottom: -15%;
    aspect-ratio: 1 / 1;
    max-width: 100%;
  }
`
