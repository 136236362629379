import React from 'react'
import styled from 'styled-components'

import { CodeSnippet } from 'components/CodeSnippet'
import { TextButton } from 'components/TextButton'
import { docs } from 'components/DocLink'

export const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(2)};
`

export const Content = styled.div`
  flex: 1;

  h2 {
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  }

  ul {
    padding-left: ${({ theme }) => theme.spacing(2)};
  }

  li {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`

const SCENARIOS_SNIPPET = `
  export const options = {
    scenarios: {
      example_scenario: { 
        // Executor type to use ${docs.scenarios.executors.main}
        executor: 'shared-iterations',
        // common scenario configuration
        startTime: '10s',
        gracefulStop: '5s',
        env: { EXAMPLE_VAR: 'testing' },
        tags: { example_tag: 'testing' },
        // executor-specific configuration
        vus: 10,
        iterations: 200,
        maxDuration: '10s',
      }
    }
  }
`

export const ZeroState = () => (
  <Container>
    <Content>
      <CodeSnippet code={SCENARIOS_SNIPPET} />
    </Content>
    <Content>
      <h2>Benefits of using scenarios in your tests</h2>

      <ul>
        <li>
          Multiple scenarios can be declared in the same script, and each one
          can independently execute a different JavaScript function, which makes
          organizing tests easier and more flexible.
        </li>
        <li>
          Every scenario can use a distinct VU and iteration scheduling pattern,
          powered by a purpose-built executor. This enables modeling of advanced
          execution patterns which can better simulate real-world traffic.
        </li>
        <li>
          They can be configured to run in sequence or parallel, or in any mix
          of the two. Different environment variables and metric tags can be set
          per scenario.
        </li>
      </ul>
    </Content>
  </Container>
)

export const ZeroStateHint = ({ onClick }: { onClick: () => void }) => (
  <div>
    No scenario was configured in this test.{' '}
    <TextButton onClick={onClick}>Check out how to create one</TextButton>
  </div>
)
