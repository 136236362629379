import { useDebugGrafanaRole } from 'components/Devtools/hooks/useDevtoolsGrafanaRole'
import { contextSrv as grafanaContextSrv } from 'grafana/app/core/core'

function useContextSrv() {
  const debugRole = useDebugGrafanaRole()

  if (debugRole) {
    return {
      ...grafanaContextSrv,
      isEditor: debugRole === 'Editor' || debugRole === 'Admin',
      isGrafanaAdmin: debugRole === 'Admin',
      user: {
        ...grafanaContextSrv.user,
        orgRole: debugRole,
      },
    }
  }

  return grafanaContextSrv
}

export function useIsGrafanaEditor() {
  const contextSrv = useContextSrv()

  return contextSrv.isEditor
}

export function useGrafanaRole() {
  const contextSrv = useContextSrv()

  return contextSrv.user.orgRole
}

export function useCreateDashboardPermission() {
  const contextSrv = useContextSrv()

  if (contextSrv.hasPermission('dashboards:write')) {
    return 'all'
  }

  // Users that only have the create permission can only create dashboards in
  // the root folder (at least according to the tooltip in the permissions UI).
  if (contextSrv.hasPermission('dashboards:create')) {
    return 'general'
  }

  return 'none'
}

export function useCanCreateFolders() {
  const contextSrv = useContextSrv()

  return contextSrv.hasPermission('folders:create')
}
