import {
  HTTP_RESPONSE_TIME_95,
  HTTP_RESPONSE_TIME_AVG,
  HTTP_RESPONSE_TIME_MAX,
  VUS_METRIC,
} from 'constants/metrics'
import { LoadZoneDistribution } from 'types/loadZone'
import { GeneratorContext } from '../types'
import dedent from 'dedent'
import { timing, vus as formatVUs } from 'utils/formatters'
import { MetricData } from 'datasource/models'
import { createTextPanel } from '../panels'
import { findMaxInSeries, findAtTimestamp } from './utils'
import { MetricBuilder } from 'utils/metrics'

function calculateState(
  vus: MetricData,
  avg: MetricData,
  p95: MetricData,
  max: MetricData
) {
  const maxResponseTime = findMaxInSeries(max)

  return {
    max: maxResponseTime.value,
    vus: findAtTimestamp(vus, maxResponseTime.timestamp),
    avg: findAtTimestamp(avg, maxResponseTime.timestamp),
    p95: findAtTimestamp(p95, maxResponseTime.timestamp),
  }
}

async function fetchData(
  { metrics, options }: GeneratorContext,
  loadZone: LoadZoneDistribution | undefined
) {
  const [[vus], [avg], [p95], [max]] = await Promise.all([
    metrics.queryRange(
      options.testRun.id,
      new MetricBuilder(VUS_METRIC).withLoadZone(loadZone).build()
    ),
    metrics.queryRange(
      options.testRun.id,
      new MetricBuilder(HTTP_RESPONSE_TIME_AVG).withLoadZone(loadZone).build()
    ),
    metrics.queryRange(
      options.testRun.id,
      new MetricBuilder(HTTP_RESPONSE_TIME_95).withLoadZone(loadZone).build()
    ),
    metrics.queryRange(
      options.testRun.id,
      new MetricBuilder(HTTP_RESPONSE_TIME_MAX).withLoadZone(loadZone).build()
    ),
  ])

  if (
    vus === undefined ||
    avg === undefined ||
    p95 === undefined ||
    max === undefined
  ) {
    throw new Error('Missing data for generating response time text.')
  }

  return calculateState(vus, avg, p95, max)
}

async function generateResponseTimeText(
  context: GeneratorContext,
  loadZone: LoadZoneDistribution | undefined
) {
  const { vus, avg, p95, max } = await fetchData(context, loadZone)

  const locationText = loadZone !== undefined ? `from ${loadZone.name} ` : ''

  // prettier-ignore
  return dedent`
    ## Response time
    
    The maximum response time ${locationText}occurred at **${formatVUs(vus)}**, with a response 
    time of **${timing(max)}**. The average response time at the same point in time was **${timing(avg)}**, 
    with 95% of requests taking less than **${timing(p95)}**.
  `
}

export async function createResponseTimeText(
  context: GeneratorContext,
  loadZone: LoadZoneDistribution | undefined
) {
  return {
    height: 3,
    panels: [
      createTextPanel(await generateResponseTimeText(context, loadZone)),
    ],
  }
}
