import styled from 'styled-components'
import {
  StatsContainer,
  Value,
  Field,
  Label,
  Unit,
} from 'pages/ProjectsListPage/components/ProjectStats.styles'
import { color, spacing } from 'utils/styled'

export const StatsSummaryItemContainer = styled(Field)`
  flex: 1 1 0;
  width: 0;
  display: flex;
  flex-direction: column;
  padding: ${spacing(2)};
  background-color: ${color('background', 'primary')};
  position: relative;
  overflow: hidden;
`

// Tweaking so the tooltip appears on the mid-top of the label
export const LabelWrapper = styled.div`
  height: ${spacing(2)};
  ${Label} {
    background-color: ${color('background', 'primary')};
    padding-right: ${spacing(1)};
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const UsageSummaryContainer = styled(StatsContainer)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${Field} {
    margin-right: ${spacing(1)};
    &:last-child {
      margin-right: 0;
    }
  }

  ${Unit} {
    font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
  }

  ${Value} {
    color: ${color('info', 'text')};
    font-size: ${({ theme }) => theme.typography.h1.fontSize};
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const FailRate = styled.div`
  span {
    color: ${color('text', 'disabled')};
  }
`
