import styled from 'styled-components'

import { color, spacing } from 'utils/styled'

export const Container = styled.div`
  position: relative;
  min-height: 125px;
  height: 100%;
  width: 100%;
  background-color: ${color('background', 'primary')};
  border: 1px ${color('border', 'weak')} solid;
  padding: ${spacing(1, 1, 1, 0.5)};
`
