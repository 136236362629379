import { ResponseError } from 'types/errors'
import { ValueOf } from 'utils/typescript'
import { EVENT_TYPES } from './NotificationsTab.constants'

export enum NotificationClient {
  SLACK = 'slack',
  MS_TEAMS = 'microsoft_teams',
  EMAIL = 'email',
  WEBHOOK = 'webhook',
}

export enum NotificationChannelType {
  WEBHOOK = 'webhook',
  EMAIL = 'email',
}

export enum NotificationChannelMetadataType {
  SLACK = 'slack',
  MS_TEAMS = 'microsoft_teams',
  EMAIL = 'email',
}

export enum NotificationScriptTemplate {
  MS_TEAMS = 'default-microsoft_teams',
  SLACK = 'default-slack',
  EMAIL = 'default-email',
  GENERIC = 'default-generic',
}

export interface BaseNotificationChannelMetadata {
  name: string
  initial_type?: NotificationChannelMetadataType
  isSimpleInterface?: boolean
  email_subject?: string
}

export type NotifiableEventType = ValueOf<typeof EVENT_TYPES>

interface BaseNotificationsChannel {
  id: number
  type: NotificationChannelType
  events: NotifiableEventType[]
  url: string
  organization_id: number
  meta_data: BaseNotificationChannelMetadata
  template: string
}

interface MSTeamsNotificationChannelMetadata
  extends BaseNotificationChannelMetadata {
  initial_type: NotificationChannelMetadataType.MS_TEAMS
  isSimpleInterface: boolean
}

export interface MSTeamsNotificationChannel extends BaseNotificationsChannel {
  type: NotificationChannelType.WEBHOOK
  meta_data: MSTeamsNotificationChannelMetadata
}

interface SlackNotificationChannelMetadata
  extends BaseNotificationChannelMetadata {
  initial_type: NotificationChannelMetadataType.SLACK
  isSimpleInterface: boolean
}

export interface SlackNotificationChannel extends BaseNotificationsChannel {
  type: NotificationChannelType.WEBHOOK
  meta_data: SlackNotificationChannelMetadata
}

interface EmailNotificationChannelMetadata
  extends BaseNotificationChannelMetadata {
  initial_type: NotificationChannelMetadataType.EMAIL
  isSimpleInterface: boolean
  email_subject: string
}

export interface EmailNotificationChannel extends BaseNotificationsChannel {
  type: NotificationChannelType.EMAIL
  meta_data: EmailNotificationChannelMetadata
}

export interface CustomNotificationChannel extends BaseNotificationsChannel {
  type: NotificationChannelType.WEBHOOK
}

export type NotificationChannel =
  | MSTeamsNotificationChannel
  | SlackNotificationChannel
  | EmailNotificationChannel
  | CustomNotificationChannel

export interface NotificationChannelDraft
  extends Omit<NotificationChannel, 'id' | 'organization_id'> {
  id: NotificationScriptTemplate | number
  organization_id?: number
}

export type NotificationResponseError = ResponseError<NotificationChannelDraft>

export interface NotificationScriptValidationPayload {
  type: NotificationChannelType
  template: string
}

interface NotificationScriptJsonErrorDetails {
  line: string
  lineno: number
  colno: number
}

export type NotificationScriptErrors = [
  string,
  NotificationScriptJsonErrorDetails | undefined
]

export interface NotificationScriptError {
  line?: number
  position?: number
  message: string
  messageWithPosition?: string
}

export interface NotificationChannelsResponse {
  'notification-channels': NotificationChannel[]
}

export type NotificationsChannelPayload = NotificationChannel & {
  id: NotificationScriptTemplate
}
