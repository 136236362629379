import styled from 'styled-components'
import { MissingScriptMessage } from './MissingScriptMessage'
import { spacing } from 'utils/styled'

export const MissingScriptMessageTemplate = styled(MissingScriptMessage)`
  display: none;
  position: absolute;
  inset: 0 0 0 0;
  background-color: var(--vscode-editor-background);
`

export const DiffEditorMount = styled.div`
  padding: ${spacing(0, 2, 2, 2)};
  height: 600px;
  min-height: 600px;
`
