import React from 'react'

import { EnvironmentVariable } from 'types'
import { useIsUserOrgAdmin } from 'data/usePermissions'
import {
  DATA_TABLE_INITIAL_HEIGHT,
  type TableColumn,
} from 'components/DataTable'
import { LoadingContainer } from 'components/LoadingContainer'
import { RevealablePassword } from 'components/RevealablePassword'
import { BertMessage } from 'components/BertMessage'

import { DataTable } from './EnvVariablesTable.styles'
import { RowActions } from '../RowActions'

interface EnvVariablesTableProps {
  data?: EnvironmentVariable[]
  isLoading: boolean
}

const useColumns = () => {
  const isOrgAdmin = useIsUserOrgAdmin()

  const columns: Array<TableColumn<EnvironmentVariable>> = [
    {
      name: 'name',
      sortable: true,
      sortField: 'name',
      cell: (row) => <span>{row.name || '-'}</span>,
    },
    {
      name: 'description',
      sortable: true,
      sortField: 'description',
      cell: (row) => <span>{row.description || '-'}</span>,
    },
  ]

  if (isOrgAdmin) {
    columns.push(
      {
        name: 'value',
        // key is used to not randomly reveal the "variable" when using sort
        cell: (row) => (
          <RevealablePassword
            isRevealEnabled={isOrgAdmin}
            key={row.value}
            readOnly
            value={row.value}
            widthAuto={false}
          />
        ),
      },
      {
        name: '',
        right: true,
        width: '80px',
        cell(row) {
          return <RowActions variable={row} />
        },
      }
    )
  }

  return columns
}

export const EnvVariablesTable = ({
  data = [],
  isLoading,
}: EnvVariablesTableProps) => {
  const columns = useColumns()
  const isOrgAdmin = useIsUserOrgAdmin()

  return (
    <LoadingContainer
      loading={isLoading}
      style={{ minHeight: DATA_TABLE_INITIAL_HEIGHT, width: '100%' }}
    >
      <DataTable
        highlightOnHover
        columns={columns}
        data={data}
        fixedHeader
        noDataComponent={
          <BertMessage
            description={
              isOrgAdmin
                ? 'Looks empty here! Start by creating your first environment variable.'
                : 'No environment variables.'
            }
          />
        }
      />
    </LoadingContainer>
  )
}
