import {
  Test,
  TestRun,
  TestRunStatus,
  TestWithTrends,
  Trend,
  TrendDataPoint,
  TrendingMetric,
  TrendingMetricConfig,
} from 'types'
import { BarChartTrendDataPoint, TrendValueStatus } from 'types/trend'
import { isTestFailed, isTestProcessing } from 'utils/testRun'
import { exhaustive } from 'utils/typescript'

interface MetricByTestRunId {
  [id: string]: TrendingMetric
}

interface MetricsByConfigId {
  [id: string]: MetricByTestRunId
}

const toTrendDataPoints = (
  metrics: MetricByTestRunId,
  runs: TestRun[]
): TrendDataPoint[] => {
  return runs.flatMap((run) => {
    const config = metrics[run.id]

    if (config === undefined) {
      return []
    }

    return {
      calculated: config.calculated,
      timestamp: run.created,
      value: config.value,
      run,
    }
  })
}

export const toTrendValueStatus = (run: TestRun) => {
  switch (run.run_status) {
    case TestRunStatus.CREATED:
      return TrendValueStatus.Created

    case TestRunStatus.INITIALIZING:
      return TrendValueStatus.Initializing

    case TestRunStatus.VALIDATED:
      return TrendValueStatus.Validated

    case TestRunStatus.QUEUED:
      return TrendValueStatus.Queued

    case TestRunStatus.RUNNING:
      return TrendValueStatus.Running

    case TestRunStatus.ABORTED_USER:
      if (isTestProcessing(run)) {
        return TrendValueStatus.Stopping
      }

      return TrendValueStatus.AbortedByUser

    case TestRunStatus.ABORTED_THRESHOLD:
      return TrendValueStatus.AbortedByThreshold

    case TestRunStatus.ABORTED_LIMIT:
      return TrendValueStatus.AbortedByLimit

    case TestRunStatus.ABORTED_SYSTEM:
      return TrendValueStatus.AbortedBySystem

    case TestRunStatus.ABORTED_SCRIPT_ERROR:
      return TrendValueStatus.AbortedByScriptError

    case TestRunStatus.TIMED_OUT:
      return TrendValueStatus.TimedOut

    case TestRunStatus.UPLOADED:
      return TrendValueStatus.Uploaded

    case TestRunStatus.FINISHED:
      if (isTestProcessing(run)) {
        return TrendValueStatus.Finalizing
      }

      return isTestFailed(run)
        ? TrendValueStatus.Failed
        : TrendValueStatus.Finished

    default:
      return exhaustive(run.run_status)
  }
}

const toTrend = (
  config: TrendingMetricConfig,
  runs: TestRun[],
  metrics: MetricsByConfigId
): Trend => {
  const byTestRunId = metrics[config.id] ?? {}
  const values = toTrendDataPoints(byTestRunId, runs)

  return {
    ...config,
    values: values.reverse(),
  }
}

export const toTestWithTrends = (
  test: Test,
  metrics: MetricsByConfigId
): TestWithTrends => {
  return {
    ...test,
    trends: test.trending_metrics.map((config) =>
      toTrend(config, test.test_runs, metrics)
    ),
  }
}

export const toMetricByConfigId = (metrics: TrendingMetric[]) => {
  const result: MetricsByConfigId = {}

  for (const metric of metrics) {
    const byTestRunId = result[metric.id] ?? {}

    byTestRunId[metric.test_run_id] = metric

    result[metric.id] = byTestRunId
  }

  return result
}

export const toValueState = (trend: BarChartTrendDataPoint) => {
  if (trend.run === null) {
    return '-'
  }

  if (!trend.calculated) {
    return 'Calculating'
  }

  if (trend.value === null) {
    return '-'
  }

  return trend.value
}
