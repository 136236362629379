import React, { useMemo } from 'react'

import { Http, TestRun, TestRunHttpFilterBy } from 'types'

import { RunDetailsFilters } from '../../Filters/Filters'
import { useURLFilters } from '../../Filters/Filters.hooks'

interface HttpFiltersProps extends ReturnType<typeof useURLFilters<Http>> {
  run: TestRun
}

export const HttpFilters = ({
  addFilterValue,
  addNewFilter,
  filters,
  options,
  removeAllFilters,
  removeFilter,
  replaceFilters,
  run,
  updateSortBy,
}: HttpFiltersProps) => {
  const presets = useMemo(
    () => [
      {
        label: 'Highest error count URLs',
        value: 'highest-error-count',
        onChange: () => {
          replaceFilters([
            {
              by: TestRunHttpFilterBy.ExpectedResponse,
              label: 'Expected response',
              values: ['false'],
            },
          ])
          updateSortBy({
            sortBy: 'http_metric_summary/duration/count',
            direction: 'desc',
          })
        },
      },
      {
        label: 'Only URLs with error status',
        value: 'error-status',
        onChange: () => {
          replaceFilters([
            {
              by: TestRunHttpFilterBy.ExpectedResponse,
              label: 'Expected response',
              values: ['false'],
            },
          ])
          updateSortBy({
            sortBy: 'status',
            direction: 'desc',
          })
        },
      },
      {
        label: 'Order all by slowest',
        value: 'slowest',
        onChange: () => {
          removeAllFilters()
          updateSortBy({
            sortBy: 'http_metric_summary/duration/p95',
            direction: 'desc',
          })
        },
      },
    ],
    [replaceFilters, updateSortBy, removeAllFilters]
  )

  return (
    <RunDetailsFilters
      filters={filters}
      onAddFilterValue={addFilterValue}
      onAddNewFilterClick={addNewFilter}
      onClearFiltersClick={removeAllFilters}
      onRemoveFilterClick={removeFilter}
      options={options}
      presets={presets}
      run={run}
    />
  )
}
