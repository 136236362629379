import React from 'react'
import { ProjectWithStats } from 'types'
import { toRate } from 'utils/math'
import { MembersContainer } from 'pages/ProjectsListPage/components/ProjectCard.styles'
import { MostRecentRunStat } from 'pages/ProjectsListPage/components/MostRecentRunStat'
import { DataTable } from 'components/DataTable'
import { Tooltip } from 'components/Tooltip'
import { getRelativeDateDifference } from 'utils/date'
import { FailRate } from '../UsageReports.styles'
import { ProjectContributors } from './ProjectContributors'
import { contributorsSort, failRateSort } from './UsageReportsTable.utils'
import { UsageReportsProjectName } from './UsageReportsProjectName'

export type UsageReportsTableProps = {
  data: ProjectWithStats[]
  isFetching: boolean
}

const LastTestRunStatCell = ({
  lastRun,
}: {
  lastRun: ProjectWithStats['last_test_run']
}) => {
  const lastTestRunTime = lastRun?.ended ?? lastRun?.started ?? lastRun?.created
  const difference = lastTestRunTime
    ? getRelativeDateDifference(lastTestRunTime, Date.now())
    : ''

  return (
    <Tooltip
      content={`${lastRun?.test_name} - ${difference}`}
      interactive
      placement="top-start"
    >
      <span>
        <MostRecentRunStat testRun={lastRun} showLabel={false} />
      </span>
    </Tooltip>
  )
}

const FailRateCell = ({ stats }: { stats: ProjectWithStats }) => {
  return (
    <FailRate>
      <strong>{`${toRate(
        stats.test_runs_fail_count,
        stats.test_runs_started_count
      )}% `}</strong>
      <span>{`(${stats.test_runs_fail_count}/${stats.test_runs_started_count})`}</span>
    </FailRate>
  )
}

const columns = [
  {
    name: 'PROJECT',
    sortable: true,
    selector: (stats: ProjectWithStats) => stats.name,
    sortField: 'name',
    grow: 2,
    cell: (stats: ProjectWithStats) => (
      <UsageReportsProjectName
        deleted={stats.deleted}
        projectId={stats.id}
        projectName={stats.name}
      />
    ),
  },
  {
    name: 'TOTAL TEST RUNS',
    sortable: true,
    sortField: 'test_runs_started_count',
    grow: 1,
    cell: (stats: ProjectWithStats) => (
      <span>
        <b>{stats.test_runs_started_count + stats.test_runs_deleted_count}</b>{' '}
        test runs
      </span>
    ),
  },
  {
    name: 'VU hours',
    sortable: true,
    sortField: 'vuh_usage',
    grow: 1,
    cell: (stats: ProjectWithStats) => (
      <Tooltip
        content="Virtual User hours may fluctuate if a running test is stopped by the user or if it is aborted by the system"
        interactive
        placement="top-start"
      >
        <span>
          <b>{stats.vuh_usage || 0}</b> VUh
        </span>
      </Tooltip>
    ),
  },
  {
    name: 'FAILED BY THRESHOLD RATE',
    sortable: true,
    sortField: 'fail_rate',
    sortFunction: failRateSort,
    grow: 1,
    cell: (stats: ProjectWithStats) => <FailRateCell stats={stats} />,
  },
  {
    name: 'MOST RECENT TEST RUN',
    sortable: true,
    sortField: 'last_test_run.test_name',
    grow: 2,
    cell: ({ last_test_run, deleted }: ProjectWithStats) => {
      if (deleted) {
        return '-'
      }

      return <LastTestRunStatCell lastRun={last_test_run} />
    },
  },
  {
    name: 'CONTRIBUTORS',
    sortable: true,
    sortField: 'contributors',
    sortFunction: contributorsSort,
    grow: 1,
    cell: ({ contributors }: ProjectWithStats) => (
      <MembersContainer>
        <ProjectContributors contributors={contributors} />
      </MembersContainer>
    ),
  },
]

export const UsageReportsTable = ({
  data = [],
  isFetching,
}: UsageReportsTableProps) => {
  return (
    <DataTable
      columns={columns}
      data={data.sort((a, b) => Number(a.deleted) - Number(b.deleted))}
      isFetching={isFetching}
    />
  )
}
