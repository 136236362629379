import React from 'react'
import styled from 'styled-components'
import { IconButton } from '@grafana/ui'

import { type ScheduledTest } from 'types/scheduledTests'
import { useDeleteSchedule } from 'components/ScheduledTests/ScheduledTests.hooks'
import { useConfirmation } from 'hooks/useConfirmation'
import { ConfirmModal } from 'components/ConfirmModal'

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(1)};
`

export const DeleteScheduleButton = ({
  scheduledTest,
}: {
  scheduledTest: ScheduledTest
}) => {
  const { mutateAsync: deleteSchedule } = useDeleteSchedule()
  const [deleteConfirmationProps, handleConfirmDelete] = useConfirmation(
    async () => await deleteSchedule(scheduledTest)
  )

  return (
    <>
      <IconWrapper>
        <IconButton
          aria-label="Delete test schedule"
          name="trash-alt"
          onClick={handleConfirmDelete}
          tooltip="Delete test schedule"
          variant="destructive"
        />
      </IconWrapper>
      <ConfirmModal
        {...deleteConfirmationProps}
        title="Delete test schedule"
        body={`Are you sure you want to delete the test schedule for ${scheduledTest.load_test.name}?`}
        confirmText="Delete"
      />
    </>
  )
}
