import React, { useState } from 'react'
import { SelectableValue } from '@grafana/data'
import { InlineField as GrafanaInlineField, MultiSelect } from '@grafana/ui'

import {
  type AuditFilterValue,
  useCloudInsightsAuditFilter,
} from './CloudInsightsAuditFilter.context'
import styled from 'styled-components'
import { CloudInsightTestId } from 'types/dataTestIds'

const ID = 'insights-audit-filter'

const options: Array<SelectableValue<AuditFilterValue>> = [
  { label: 'Good', value: 'good' },
  { label: 'Needs Improvement', value: 'needs-improvement' },
  { label: 'Poor', value: 'poor' },
  { label: 'Skipped', value: 'skipped' },
]

const getInitialOption = (filters: AuditFilterValue[]) => {
  return (
    options.filter((option) => filters.includes(option.value!)) || [options[0]!]
  )
}

export const CloudInsightsAuditFilter = () => {
  const [filter, setFilter] = useCloudInsightsAuditFilter()
  const [value, setValue] = useState<Array<SelectableValue<AuditFilterValue>>>(
    getInitialOption(filter)
  )

  const handleChange = (options: Array<SelectableValue<AuditFilterValue>>) => {
    setValue(options)
    setFilter(options.map((option) => option.value!))
  }

  return (
    <InlineField
      data-testid={CloudInsightTestId.Filter}
      label="Status"
      htmlFor={ID}
      tooltip="Filter insights based on their score."
    >
      <MultiSelect
        inputId={ID}
        options={options}
        value={value}
        onChange={handleChange}
      />
    </InlineField>
  )
}

const InlineField = styled(GrafanaInlineField)`
  label {
    color: #6e9fff;
    font-weight: 500;
  }
`
