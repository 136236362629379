import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { color, spacing } from 'utils/styled'

export const LatestRunDetailsSection = styled.section`
  margin-bottom: ${spacing(1)};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 0.9rem;
  color: ${color('text', 'secondary')};
`

export const BadgeLink = styled(Link)`
  &:hover {
    filter: brightness(1.3);
  }
`

export const LatestRunDetail = styled.div`
  display: flex;
  align-items: baseline;
  gap: ${spacing(1)};
`
