import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { getRoute } from 'routeMap'
import { useIsUserOrgAdmin } from 'data/usePermissions'
import { PluginOrganizationWidePage } from 'components/PluginPage'
import { TabbedNavContent } from 'components/TabbedNav'
import { QueryErrorBoundary } from 'components/ErrorBoundary'
import { useSubscriptionRule } from 'data/useSubscriptions'

import { useManagePageNav } from './ManagePage.hooks'
import { ErrorBoundaryLocalView } from 'services/tracking.types'

import {
  AuditTrailTab,
  LoadZonesTab,
  SavedTestsTab,
  ScheduledTestsTab,
  UsageReportsTab,
  StaticIPsTab,
} from './tabs'

export const ManagePage = () => {
  const pageNav = useManagePageNav()
  const isUserOrgAdmin = useIsUserOrgAdmin()
  const staticIPsMax = useSubscriptionRule('organization.static_ips.max')

  return (
    <PluginOrganizationWidePage pageNav={pageNav}>
      <TabbedNavContent>
        <Switch>
          <Route path={getRoute('scheduledTests')}>
            <QueryErrorBoundary view={ErrorBoundaryLocalView.SchedulesTab}>
              <ScheduledTestsTab />
            </QueryErrorBoundary>
          </Route>

          {isUserOrgAdmin && (
            <Route path={getRoute('auditTrail')}>
              <AuditTrailTab />
            </Route>
          )}
          <Route path={getRoute('usageReports')}>
            <UsageReportsTab />
          </Route>
          <Route path={getRoute('savedTestRuns')}>
            <SavedTestsTab />
          </Route>
          <Route path={getRoute('loadZones')}>
            <LoadZonesTab />
          </Route>

          {staticIPsMax > 0 && (
            <Route path={getRoute('staticIps')}>
              <StaticIPsTab />
            </Route>
          )}

          <Route>
            <Redirect to={getRoute('scheduledTests')} />
          </Route>
        </Switch>
      </TabbedNavContent>
    </PluginOrganizationWidePage>
  )
}
