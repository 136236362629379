import React, { useMemo } from 'react'
import { type AbsoluteTimeRange } from '@grafana/data'

import { TestRunAnalysis, Threshold } from 'types'
import { calculatePrecision } from 'utils/math'
import { separatorFormatter } from 'utils/formatters'
import { ThresholdChart } from './ThresholdChart'
import { RunsPageTestIds } from 'types/dataTestIds'

import { useTimeRange } from '../../../TimeRangeContext'
import { ListTableDefinition } from '../BreakdownTable'
import { useRunDetailsThresholdsFilters } from '../../Filters/Filters.hooks'
import { ThresholdsFilters } from './ThresholdsFilters'
import { toThresholdsQueryKey } from 'data/queryKeys'
import {
  ParsedThreshold,
  ThresholdClient,
  useThresholdClient,
} from 'data/clients/entities/thresholds'
import { ThresholdTabEmptyView } from './ThresholdTabEmptyView'
import { EmptyFilterView } from 'components/EmptyFilterView'
import { BreakdownView } from '../BreakdownView'
import { FilterExpression, serializeRunFilter } from 'datasource/serialization'
import { ComparedValue } from '../ComparedValue'
import { QueryErrorBoundary } from 'components/ErrorBoundary'
import { ErrorBoundaryLocalView } from 'services/tracking.types'

interface ThresholdTableProps {
  client: ThresholdClient
  filter: FilterExpression<Threshold>
  timeRange: AbsoluteTimeRange | undefined
}

export const table: ListTableDefinition<ParsedThreshold, ThresholdTableProps> =
  {
    id: 'threshold-breakdown',
    paginator: 'always',
    type: 'list',

    pageSize: 20,

    keyBy(row) {
      return row.name
    },

    isSuccess({ tainted }) {
      return !tainted
    },

    fetchPage({ client, testRun, ...params }) {
      return {
        queryKey: toThresholdsQueryKey(testRun.id, params),
        queryFn: () => client.fetchPage(testRun.id, params),
      }
    },

    fetchByRows({ client, testRun, rows }) {
      const names = rows.map((threshold) => threshold.name).sort()

      return {
        queryKey: toThresholdsQueryKey(testRun.id, { names }),
        queryFn: () => client.fetchByNames(testRun.id, names),
      }
    },

    columns: [
      {
        id: 'name',
        name: 'Name',
        sortBy: 'name',
        toggle: 'none',

        renderCell: ({ parsed }) => parsed.name,
      },
      {
        id: 'condition',
        name: 'Condition',
        renderCell: ({ parsed }) => parsed.condition,
      },
      {
        id: 'calculated-value',
        name: 'Calculated value',
        renderCell: (left, right) => {
          const formatNull = (value: number | null) => {
            return value !== null
              ? separatorFormatter(value, calculatePrecision(value))
              : null
          }

          const leftValue = formatNull(left.calculated_value)
          const rightValue = right && formatNull(right.calculated_value)

          return (
            <>
              {left.stat} ={' '}
              <ComparedValue left={leftValue} right={rightValue} />
            </>
          )
        },
      },
    ],

    RowBodyComponent: ({ analysis, value }) => (
      <ThresholdChart analysis={analysis} threshold={value} />
    ),
  }

interface ThresholdTabProps {
  analysis: TestRunAnalysis
}

const ThresholdTabComponent = ({ analysis }: ThresholdTabProps) => {
  const client = useThresholdClient()
  const urlFiltersProps = useRunDetailsThresholdsFilters()
  const { timeRange } = useTimeRange()

  const { filters, sortBy, removeAllFilters, updateSortBy } = urlFiltersProps

  const handleClearFilter = () => {
    removeAllFilters()
  }

  const filter = useMemo(
    () => serializeRunFilter<Threshold>(filters),
    [filters]
  )

  const thresholdsSummaryTotal = analysis.main.thresholds_summary?.total ?? 0

  if (thresholdsSummaryTotal === 0) {
    return <ThresholdTabEmptyView testRun={analysis.main} />
  }

  return (
    <BreakdownView
      data-testid={RunsPageTestIds.ThresholdsTable}
      view="list"
      analysis={analysis}
      params={{ client, filter, timeRange }}
      table={table}
      header={<ThresholdsFilters run={analysis.main} {...urlFiltersProps} />}
      emptyTable={
        <EmptyFilterView onClear={handleClearFilter}>
          No thresholds match the current filter...
        </EmptyFilterView>
      }
      sortBy={sortBy}
      onSort={updateSortBy}
    />
  )
}

export const ThresholdTab = (props: ThresholdTabProps) => (
  <QueryErrorBoundary view={ErrorBoundaryLocalView.ThresholdsTab}>
    <ThresholdTabComponent {...props} />
  </QueryErrorBoundary>
)
