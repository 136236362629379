import React from 'react'
import { Icon, Spinner } from '@grafana/ui'

import { type InsightCategoryResult } from 'types/cloudInsights'
import { toPercentage } from 'utils/math'
import { Tooltip } from 'components/Tooltip'

import { Element, SpinnerWrapper } from './Score.styles'
import { isInsightFailed, isNumericScore } from 'utils/insights'

interface ScoreProps {
  result?: InsightCategoryResult
}

export const Score = ({ result }: ScoreProps) => {
  if (!result || result.status === 'executing') {
    return (
      <Element>
        <SpinnerWrapper>
          <Spinner size={20} />
        </SpinnerWrapper>
      </Element>
    )
  }

  if (!isNumericScore(result.score) || isInsightFailed(result)) {
    return (
      <Tooltip content={result?.status_reason ?? ''}>
        <Element>
          <Icon name="question-circle" size="xl" />
        </Element>
      </Tooltip>
    )
  }

  return (
    <Element score={result.score}>{toPercentage(result.score.value)}</Element>
  )
}
