import styled, { css, DefaultTheme } from 'styled-components'
import { MetricsExportStatus } from 'types'
import { MetaItem } from 'components/MetaItem'
import { createPulseAnimation } from 'theme/animation'

const getStatusColor = (status: MetricsExportStatus, theme: DefaultTheme) => {
  if (status === MetricsExportStatus.FINISHED) {
    return theme.colors.success.text
  }

  if (status === MetricsExportStatus.ERROR) {
    return theme.colors.error.text
  }

  return theme.colors.text.primary
}

export const ExportStatus = styled.span<{ status: number }>`
  color: ${({ status, theme }) => getStatusColor(status, theme)};
`

export const TooltipContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-column-gap: ${({ theme }) => theme.spacing(1)};
  font-size: 13px;
  line-height: 1.7;
`

export const FieldLabel = styled.div`
  grid-column: 1;
  font-weight: bold;
  white-space: nowrap;
`

export const FieldValue = styled.div`
  grid-column: 2;
  white-space: nowrap;
`

export const ErrorMessage = styled.div`
  grid-column: span 2;
  margin-top: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.colors.error.text};
  background-color: ${({ theme }) => theme.colors.background.primary};
  border-radius: 2px;
`

export const StyledMetaItem = styled(MetaItem)<{ status: MetricsExportStatus }>`
  svg {
    color: ${({ status, theme }) => getStatusColor(status, theme)};
    ${({ status }) =>
      status === MetricsExportStatus.EXPORTING &&
      css`
        animation: ${createPulseAnimation(0.5)} 1.25s infinite ease-in-out;
      `}
  }
`
