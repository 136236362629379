import React from 'react'
import { CodeSnippet } from 'components/CodeSnippet'
import { CodeSnippetProps } from 'components/CodeSnippet/CodeSnippet.types'
import { PromoCodeSnippetStyled } from './PromoCodeSnippet.styled'
import { docs } from 'components/DocLink'

const snippets: CodeSnippetProps['tabs'] = [
  {
    value: 'create-script',
    label: '1. Create a script',
    groups: [
      {
        value: 'simple-script',
        label: 'Simple script',
        dedent: false,
        download: 'script.js',
        code: `import http from 'k6/http'
import { sleep } from 'k6'

// See ${docs.options.main}
export const options = {
  vus: 10,
  duration: '30s',
  cloud: {
    name: 'YOUR TEST NAME',
  },
}

export default function () {
  http.get('https://test.k6.io')
  sleep(1)
}`,
      },
      {
        value: 'threshold-config',
        label: 'Threshold config',
        dedent: false,
        download: 'threshold-config.js',
        code: `import http from 'k6/http'
import { sleep } from 'k6'

export let options = {
  thresholds: {
    http_req_duration: ['p(95)<500', 'p(99)<1500'],
  },
}

export default function () {
  const BASE_URL = 'https://test-api.k6.io'

  http.get(\`\${BASE_URL}/public/crocodiles\`)
  http.get(\`\${BASE_URL}/public/crocodiles/1\`)
  http.get(\`\${BASE_URL}/public/crocodiles/2\`)
  sleep(0.3)
}`,
      },
      {
        value: 'api',
        label: 'API',
        dedent: false,
        download: 'api.js',
        code: `import http from 'k6/http'
import { check, sleep } from 'k6'

export default function () {
  const data = { username: 'username', password: 'password' }
  let res = http.post('https://myapi.com/login/', data)

  check(res, { 'success login': (r) => r.status === 200 })

  sleep(0.3)
}`,
      },
      {
        value: 'websockets',
        label: 'WebSockets',
        dedent: false,
        download: 'websockets.js',
        code: `import ws from 'k6/ws'

export default function () {
  let url = 'ws://echo.websocket.org'
  let params = { tags: { my_tag: 'hello' } }

  let res = ws.connect(url, params, function (socket) {
    socket.on('open', function open() {
      console.log('connected')

      socket.setInterval(function timeout() {
        socket.ping()
        console.log('Pinging every 1sec (setInterval test)')
      }, 1000)
    })

    socket.on('ping', function () {
      console.log('PING!')
    })

    socket.on('pong', function () {
      console.log('PONG!')
    })

    socket.on('close', function () {
      console.log('disconnected')
    })

    socket.setTimeout(function () {
      console.log('2 seconds passed, closing the socket')
      socket.close()
    }, 2000)
  })
}
`,
      },
      {
        value: 'grpc',
        label: 'gRPC',
        dedent: false,
        download: 'grpc.js',
        /* spell-checker:disable */
        code: `import grpc from 'k6/net/grpc'
import { sleep, check } from 'k6'

const client = new grpc.Client()
client.load(['definitions'], 'hello.proto')

export default function () {
  client.connect('grpcb.in:9001', { plaintext: false })

  const data = { greeting: 'Bert' }
  const response = client.invoke('hello.HelloService/SayHello', data)
  check(response, {
    'status is OK': (r) => r && r.status === grpc.StatusOK,
  })
  console.log(JSON.stringify(response.message))

  client.close()
  sleep(1)
}
`,
        /* spell-checker:enable */
      },
    ],
  },

  {
    value: 'run-test',
    label: '2. Run a test',
    groups: [
      {
        value: 'cloud',
        label: 'Cloud executed',
        code: 'k6 cloud script.js',
        lang: 'plain',
      },
      {
        value: 'local',
        label: 'Locally executed',
        code: `# Run locally, stream results to the Cloud
        k6 run -o cloud script.js`,
        lang: 'plain',
      },
    ],
  },
]

export function PromoCodeSnippet() {
  return (
    <PromoCodeSnippetStyled>
      <CodeSnippet lang="js" height="400px" tabs={snippets} />
    </PromoCodeSnippetStyled>
  )
}
