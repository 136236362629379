import React from 'react'

import { useProjectLimitsAndUsageReport } from 'hooks/useProjectLimitsAndUsageReport'
import { hasLimitsOrQuotas } from 'utils/limitsAndQuotas'
import { VUhConsumptionMeter } from './VUhConsumptionMeter'
import { NoLimitsWarning } from './NoLimitsWarning'
import { ProjectVUhInfoToggletip } from './ProjectVUhInfoToggletip'
import { useIsUserOrgAdmin } from 'data/usePermissions'

interface ProjectVUhConsumptionProps {
  projectId: number
}

export function ProjectVUhConsumption({
  projectId,
}: ProjectVUhConsumptionProps) {
  const isUserOrgAdmin = useIsUserOrgAdmin()
  const { limits, usage, isLoading } = useProjectLimitsAndUsageReport(projectId)

  if (isLoading || !limits) {
    return null
  }

  if (!hasLimitsOrQuotas(limits) && isUserOrgAdmin) {
    return <NoLimitsWarning projectId={projectId} />
  }

  const currentMonth = new Intl.DateTimeFormat('en-US', {
    month: 'long',
  }).format(new Date())

  const value = usage?.[0]?.vuh_usage
  const limit = limits?.vuh_max_per_month
  const label = `VUh in ${currentMonth}`

  return (
    <VUhConsumptionMeter
      value={value}
      limit={limit}
      label={label}
      action={<ProjectVUhInfoToggletip value={value} limit={limit} />}
    />
  )
}
