import { useMutation } from '@tanstack/react-query'

import { useAppContext } from 'appContext'
import { CalculateStaticIPsPayload } from 'types'

import { useDatasource } from '../datasourceContext'

export function useCalculateStaticIPs() {
  const { orgId } = useAppContext()
  const { ds } = useDatasource()

  return useMutation({
    mutationFn: (payload: CalculateStaticIPsPayload) =>
      ds.calculateStaticIps(orgId, payload),
  })
}
