import {
  K6DataSource,
  ODataCountPayload,
  ODataPayload,
} from 'datasource/datasource'
import { toSelectQuery } from 'datasource/serialization'
import { useDatasource } from 'datasourceContext'
import { Dictionary, groupBy, keyBy } from 'lodash'
import { Scenario, Group, TestRunId } from 'types'

type GroupsResponse = ODataPayload<Group[]> & ODataCountPayload
export type GroupWithScenarioName = Group & { scenario_name: string }

export interface GroupTreeNode {
  type: 'sub'
  left: GroupWithScenarioName
  // TODO: add scenarioName to right when implementing traces comparison
  right: Group | null | undefined
  children: GroupTreeNode[]
}

export interface ScenarioTreeNode {
  type: 'scenario'
  left: Scenario
  right: Scenario | null | undefined
  rootGroup: GroupTreeNode
}

export interface RootTreeNode {
  type: 'root'
  scenarios: ScenarioTreeNode[]
}

export type GroupTree = GroupTreeNode | ScenarioTreeNode | RootTreeNode

function buildGroupTree(
  group: Group,
  lhsGroupsByParent: Dictionary<Group[]>,
  rhsGroupsById: Dictionary<Group> | undefined,
  scenarioName: string
): GroupTreeNode {
  const tree: GroupTreeNode = {
    type: 'sub',
    left: { ...group, scenario_name: scenarioName },
    right: rhsGroupsById && (rhsGroupsById[group.id] ?? null),
    children: [],
  }

  for (const child of lhsGroupsByParent[group.id] ?? []) {
    tree.children.push(
      buildGroupTree(child, lhsGroupsByParent, rhsGroupsById, scenarioName)
    )
  }

  return tree
}

function buildTree(
  lhsScenarios: Scenario[],
  lhsGroups: Group[],
  rhsScenarios: Scenario[] | undefined,
  rhsGroups: Group[] | undefined
): RootTreeNode {
  const tree: RootTreeNode = {
    type: 'root',
    scenarios: [],
  }

  const groupsByParent = groupBy(lhsGroups, (group) => group.parent_id)

  const rhsGroupsById = rhsGroups && keyBy(rhsGroups, (group) => group.id)
  const rhsScenariosById =
    rhsScenarios && keyBy(rhsScenarios, (scenario) => scenario.id)

  for (const scenario of lhsScenarios) {
    const root = lhsGroups.find(
      (group) => group.scenario_id === scenario.id && group.parent_id === null
    )

    if (root === undefined) {
      throw new Error(
        `Could not find root group for scenario "${scenario.id}".`
      )
    }

    tree.scenarios.push({
      type: 'scenario',
      left: scenario,
      right: rhsScenariosById && (rhsScenariosById[scenario.id] ?? null),
      rootGroup: buildGroupTree(
        root,
        groupsByParent,
        rhsGroupsById,
        scenario.name
      ),
    })
  }

  return tree
}

class GroupClient {
  datasource: K6DataSource

  constructor(datasource: K6DataSource) {
    this.datasource = datasource
  }

  fetchGroups(testRunId: number) {
    const params = toSelectQuery<Group>({
      select: [
        'id',
        'parent_id',
        'scenario_id',
        'name',
        'group_duration_summary',
        'checks_metric_summary',
        'http_metric_summary',
        'grpc_metric_summary',
        'ws_metric_summary',
      ],
    })

    return this.datasource.get<GroupsResponse>(
      `loadtests/v4/test_runs(${testRunId})/groups`,
      { params }
    )
  }

  async fetchTree(testRunId: TestRunId, compareWithId: number | undefined) {
    const [groups, scenarios, groupsRight, scenariosRight] = await Promise.all([
      this.fetchGroups(testRunId),
      this.datasource.fetchScenarios(testRunId),
      compareWithId !== undefined ? this.fetchGroups(compareWithId) : undefined,
      compareWithId !== undefined
        ? this.datasource.fetchScenarios(compareWithId)
        : undefined,
    ])

    return buildTree(
      scenarios.value,
      groups.value,
      scenariosRight?.value,
      groupsRight?.value
    )
  }
}

export const useGroupClient = () => {
  const { ds } = useDatasource()

  return new GroupClient(ds)
}
