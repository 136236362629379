import { useQuery } from '@tanstack/react-query'
import { BG_TASKS_POLL_INTERVAL } from 'constants/index'
import { toBackgroundTaskQueryKey } from 'data/queryKeys'
import { useCurrentProject } from 'projectContext'
import { useProjectClient } from './clients/projects'

export const useBackgroundTasks = () => {
  const project = useCurrentProject()

  const projectClient = useProjectClient()

  return useQuery({
    queryKey: toBackgroundTaskQueryKey(project.id),
    queryFn: () => projectClient.fetchTasks(project.id),
    refetchInterval: BG_TASKS_POLL_INTERVAL,
    useErrorBoundary: false,
    meta: {
      alertOnError: false,
    },
  })
}
