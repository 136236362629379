import {
  TestRunHttpFilterBy,
  TestRunThresholdsFilterBy,
  TestRunChecksFilterBy,
  TestRunGRPCFilterBy,
  TestRunWebSocketsFilterBy,
  TestRunFilter,
  TestRunBrowserHttpFilterBy,
} from 'types'

export interface FilterOption {
  label: string
  value: TestRunFilter['by']
  multiSelect?: boolean
  required?: boolean
  allowCustomValue?: boolean
}

export const HTTP_FILTER_OPTIONS: FilterOption[] = [
  {
    label: 'URL',
    value: TestRunHttpFilterBy.URL,
    multiSelect: true,
    allowCustomValue: true,
  },
  { label: 'Status', value: TestRunHttpFilterBy.Status, multiSelect: true },
  { label: 'Method', value: TestRunHttpFilterBy.Method, multiSelect: true },
  { label: 'Scenario', value: TestRunHttpFilterBy.Scenario, multiSelect: true },
  { label: 'Expected response', value: TestRunHttpFilterBy.ExpectedResponse },
]

export const THRESHOLDS_FILTER_OPTIONS: FilterOption[] = [
  {
    label: 'Name',
    value: TestRunThresholdsFilterBy.Name,
    multiSelect: true,
    allowCustomValue: true,
  },
  { label: 'Tainted', value: TestRunThresholdsFilterBy.Tainted },
]

export const CHECKS_FILTER_OPTIONS: FilterOption[] = [
  {
    label: 'Name',
    value: TestRunChecksFilterBy.Name,
    multiSelect: true,
    allowCustomValue: true,
  },
]

export const GRPC_FILTER_OPTIONS: FilterOption[] = [
  {
    label: 'URL',
    value: TestRunGRPCFilterBy.URL,
    multiSelect: true,
    allowCustomValue: true,
  },
  { label: 'Status', value: TestRunGRPCFilterBy.Status, multiSelect: true },
  { label: 'Scenario', value: TestRunGRPCFilterBy.Scenario, multiSelect: true },
]

export const WEB_SOCKETS_FILTER_OPTIONS: FilterOption[] = [
  {
    label: 'URL',
    value: TestRunWebSocketsFilterBy.URL,
    multiSelect: true,
    allowCustomValue: true,
  },
  {
    label: 'Status',
    value: TestRunWebSocketsFilterBy.Status,
    multiSelect: true,
  },
  {
    label: 'Scenario',
    value: TestRunWebSocketsFilterBy.Scenario,
    multiSelect: true,
  },
]

export const BROWSER_HTTP_FILTER_OPTIONS: FilterOption[] = [
  {
    label: 'URL',
    value: TestRunBrowserHttpFilterBy.URL,
    multiSelect: true,
    allowCustomValue: true,
  },
  {
    label: 'Scenario',
    value: TestRunBrowserHttpFilterBy.Scenario,
    multiSelect: true,
  },
]
