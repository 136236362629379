import React from 'react'
import { TestRun } from 'types'
import { useTest } from 'data/useTest'
import {
  ErrorDescription,
  ErrorDetails,
  ErrorHeading,
  ErrorSectionOverlay,
} from './AbortedScriptErrorOverlay.styles'
import { Icon } from '@grafana/ui'
import { LinkButton } from 'components/LinkButton'
import { routeMap } from 'routeMap'

interface AbortedScriptErrorSectionProps {
  testRun: TestRun
}

export const AbortedScriptErrorOverlay = ({
  testRun,
}: AbortedScriptErrorSectionProps) => {
  const { data: test } = useTest(testRun?.test_id)

  return (
    <ErrorSectionOverlay>
      <ErrorHeading className="heading">
        <Icon name="exclamation-circle" size="xl" />
        <span>Aborted by script error</span>
      </ErrorHeading>
      <ErrorDescription>
        The test was aborted due to a script error. Please review your test
        script and make sure that it does not contain any errors then re-run the
        test.
      </ErrorDescription>

      {testRun.error_detail && (
        <ErrorDetails>{testRun.error_detail}</ErrorDetails>
      )}

      {test && (
        <LinkButton
          to={routeMap.editTest(test)}
          button={{ variant: 'primary' }}
        >
          Configure
        </LinkButton>
      )}
    </ErrorSectionOverlay>
  )
}
