import { TestRun } from 'types'
import { useHasBrowserTraces } from './BrowserTimeline.hooks'
import { LinkButton } from '@grafana/ui'
import React from 'react'
import { useFeatureFlag } from 'store/FeatureFlags'
import { routeMap } from 'routeMap'
import { isTestDone } from 'utils/testRun'

interface BrowserTimelineButtonProps {
  testRun: TestRun
}

export function BrowserTimelineButton({ testRun }: BrowserTimelineButtonProps) {
  const isDone = isTestDone(testRun)
  const browserTimeline = useFeatureFlag('browserTimeline')
  const hasTraces = useHasBrowserTraces(isDone ? testRun.id : undefined)
  if (!hasTraces && !browserTimeline) {
    return null
  }

  return (
    <LinkButton
      tooltip="Click to view the browser timeline (alpha)"
      href={routeMap.browserTimelineRun(testRun.id)}
      icon="list-ui-alt"
      variant="success"
      disabled={!hasTraces}
      size="sm"
    >
      Timeline
    </LinkButton>
  )
}
