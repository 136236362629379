import { useRouteMatch } from 'react-router-dom'
import { NavModelItem } from '@grafana/data'
import { getRoute } from 'routeMap'
import { useIsUserOrgAdmin } from 'data/usePermissions'

export const useSettingsPageNav = () => {
  const isPersonalToken = useRouteMatch(getRoute('personalToken'))
  const isStackToken = useRouteMatch(getRoute('stackToken'))
  const isEnvVars = useRouteMatch(getRoute('envVariables'))
  const isLimits = useRouteMatch(getRoute('limits'))
  const isNotifications = useRouteMatch(
    getRoute('notifications') ||
      getRoute('newNotification') ||
      getRoute('editNotification')
  )
  const isUserOrgAdmin = useIsUserOrgAdmin()

  const pageNav: NavModelItem = {
    icon: 'cog',
    text: 'Settings',
    subTitle: 'Manage k6 Settings',
    hideFromBreadcrumbs: true,
    children: [
      {
        icon: 'user',
        text: 'Personal API token',
        url: getRoute('personalToken'),
        active: !!isPersonalToken,
      },
      {
        icon: 'users-alt',
        text: 'Grafana Stack API token',
        url: getRoute('stackToken'),
        active: !!isStackToken,
      },
      {
        icon: 'brackets-curly',
        text: 'Environment variables',
        url: getRoute('envVariables'),
        active: !!isEnvVars,
      },
      {
        icon: 'flip',
        text: 'Limits and quotas',
        url: getRoute('limits'),
        active: !!isLimits,
        hideFromTabs: !isUserOrgAdmin,
      },
      {
        icon: 'bell',
        text: 'Notifications',
        url: getRoute('notifications'),
        active: !!isNotifications,
      },
    ],
  }

  return pageNav
}
