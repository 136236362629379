import { TestRun, TestWithTrends } from 'types'
import {
  getLatestTestRun,
  getDurationDisplay,
  getTestRunVUsDisplay,
  getTestRunVUhDisplay,
  getTestVUhDisplay,
  getTestScheduleDisplay,
} from 'components/MetaBar'

interface TestMetaData {
  duration: string
  latestRun?: TestRun
  nextRun: string | null
  testVUh: string
  vus: string
  vuh: string
}

export function getTestMetaData(test: TestWithTrends): TestMetaData {
  const testVUh = getTestVUhDisplay(test)
  const latestRun = getLatestTestRun(test)
  const vuh = getTestRunVUhDisplay(latestRun)
  const duration = getDurationDisplay(latestRun?.duration)
  const vus = getTestRunVUsDisplay(latestRun)
  const nextRun = getTestScheduleDisplay(test)

  return {
    duration,
    latestRun,
    nextRun,
    testVUh,
    vus,
    vuh,
  }
}

export function hasUpdatedSinceLastRun(test: TestWithTrends) {
  const latestRun = getLatestTestRun(test)
  const { updated } = test

  if (!latestRun || !updated) {
    return false
  }

  return new Date(updated) > new Date(latestRun.created)
}
