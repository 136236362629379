import React from 'react'
import { Link } from 'react-router-dom'

import {
  PluginPageProps,
  PluginPage as GrafanaPluginPage,
} from '@grafana/runtime'

import { ActivityButton } from 'components/ActivityPanel'
import { Logo } from 'components/Logo'
import { PageHeaderContainer, PageHeading, PageIcon } from './PluginPage.styles'
import { routeMap } from 'routeMap'

interface PluginPageFactoryProps extends PluginPageProps {
  hideLogo?: boolean
  placeholder?: boolean
}

/**
 * Organization wide plugin pages should be used for global type pages
 * Examples: Learn, Manage, Settings, Projects list.
 * */
export const PluginOrganizationWidePage = ({
  actions,
  renderTitle,
  hideLogo = false,
  children,
  ...props
}: PluginPageFactoryProps) => {
  const { pageNav: { icon } = {} } = props

  return (
    <GrafanaPluginPage
      {...props}
      actions={actions}
      renderTitle={(title: string) =>
        title ? (
          <PageHeaderContainer>
            {icon && <PageIcon name={icon} size="xxl" aria-hidden />}
            {!hideLogo && !icon && (
              <Link to={routeMap.home}>
                <Logo />
              </Link>
            )}
            <PageHeading>
              {renderTitle ? renderTitle(title) : title}
            </PageHeading>
          </PageHeaderContainer>
        ) : (
          <PageHeading>&nbsp;</PageHeading>
        )
      }
    >
      {children}
    </GrafanaPluginPage>
  )
}

export const PluginPage = (props: PluginPageFactoryProps) => {
  return (
    <PluginOrganizationWidePage
      {...props}
      actions={
        <>
          {props.actions}
          <ActivityButton />
        </>
      }
    />
  )
}
