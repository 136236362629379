import React, { useState } from 'react'

import { BertMessage } from 'components/BertMessage'
import { Body } from './NoProjectsState.styles'
import { Button } from 'components/Button'
import { CreateNewProjectModal } from 'pages/ProjectPage/components/CreateNewProject/CreateNewProjectModal'

export const NoProjectsState = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <Body>
      <BertMessage
        title="Looks like you don't have any projects yet."
        description={
          <>
            <BertMessage.Text>
              Fortunately, it&apos;s very easy to create one.
            </BertMessage.Text>
            <Button onClick={() => setIsModalOpen(true)}>
              Create new project
            </Button>
          </>
        }
      />
      <CreateNewProjectModal
        isOpen={isModalOpen}
        onDismiss={() => setIsModalOpen(false)}
      />
    </Body>
  )
}
