import React, { useEffect, useState } from 'react'
import { Toggletip } from '@grafana/ui'
import styled from 'styled-components'

import { IconButton } from 'components/IconButton'
import { TooltipProps } from 'components/Tooltip'

type InfoToggleTipTypes = 'info' | 'warning' | 'error'

interface InfoToggletipProps {
  content: React.ReactNode
  tooltip: string
  type?: InfoToggleTipTypes
  placement?: TooltipProps['placement']
}

export const InfoToggletip = ({
  content,
  tooltip,
  type = 'info',
  placement = 'top',
}: InfoToggletipProps) => {
  const [toggleTipOpen, setIsToggleTipOpen] = useState(false)

  const handleVisible = (visible: boolean) => {
    setIsToggleTipOpen(visible)
  }

  return (
    <Toggletip
      closeButton
      content={
        <ContentWrapper handleVisible={handleVisible}>{content}</ContentWrapper>
      }
      placement={placement}
    >
      <ToggleButton
        $type={type}
        aria-expanded={toggleTipOpen}
        aria-label={tooltip}
        name="info-circle"
        tooltipPlacement="top"
        // render an empty fragment because if the tooltip is undefined
        // it triggers a rerender and we lose keyboard focus
        // it renders a very small tooltip on the toggletip
        // that is hardly visible if you hover/focus away and back to it
        tooltip={toggleTipOpen ? <></> : tooltip}
      />
    </Toggletip>
  )
}

interface ContentWrapperProps {
  children: React.ReactNode
  handleVisible: (visible: boolean) => void
}

const ContentWrapper = ({ children, handleVisible }: ContentWrapperProps) => {
  useEffect(() => {
    handleVisible(true)

    return () => handleVisible(false)
  }, [handleVisible])

  return <ToggleTipContent>{children}</ToggleTipContent>
}

const ToggleButton = styled(IconButton)<{ $type?: InfoToggleTipTypes }>`
  color: ${({ $type, theme }) => {
    if ($type === 'error') {
      return theme.colors.error.text
    }

    if ($type === 'warning') {
      return theme.colors.warning.text
    }

    return theme.colors.primary.main
  }};
`

const ToggleTipContent = styled.div`
  margin: ${({ theme }) => theme.spacing(-2, 1, -2, -1)};
  text-transform: initial;

  a {
    color: ${({ theme }) => theme.colors.text.link};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`
