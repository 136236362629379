import React, { useEffect, useState } from 'react'
import { CodeEditor as GrafanaCodeEditor } from '@grafana/ui'
import type * as monacoType from 'monaco-editor/esm/vs/editor/editor.api'
import { ConstrainedEditorInstance } from 'constrained-editor-plugin'
import styled from 'styled-components'
import { css } from '@emotion/css'

import { Overlay } from 'components/Overlay'

import { CodeEditorProps, ConstrainedEditorProps } from './CodeEditor.types'
import {
  addK6Types,
  jinjaSyntaxClass,
  highlightJinjaSyntax,
  initializeConstrainedInstance,
  updateConstrainedEditorRanges,
} from './CodeEditor.utils'

const Wrapper = styled.div`
  position: relative;
  height: 100%;

  // Override TestBuilder's font smoothing
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: auto;

  // Override TestBuilder's width: 0, causes flickering in editor
  width: 100% !important;
`

const containerStyles = css`
  height: 100%;
  min-height: 600px;

  & > div {
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // Background styling for editable ranges (multi)
  .editableArea--multi-line {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.1);
  }

  > section {
    min-height: inherit;
  }

  .${jinjaSyntaxClass} {
    color: #c4b5fd;
  }
`
// TypeScript improves DX by providing types for `k6/global`
// but we don't want to show TS errors where they don't make sense
const diagnosticCodesToIgnore = [
  // TS2740: Type '{}' is missing the following properties from type 'X': a, b, c
  2740,
]

export const CodeEditor = ({
  checkJs = true,
  checkJinja = false,
  constrainedRanges,
  language = 'javascript',
  overlayMessage,
  readOnly,
  renderHeader,
  value,
  onBeforeEditorMount,
  onEditorDidMount,
  onChange,
  onDidChangeContentInEditableRange,
  onValidation,
}: CodeEditorProps & ConstrainedEditorProps) => {
  const [editorRef, setEditorRef] =
    useState<null | monacoType.editor.IStandaloneCodeEditor>(null)
  const [constrainedInstance, setConstrainedInstance] =
    useState<null | ConstrainedEditorInstance>(null)
  const [prevValue, setPrevValue] = useState(value)

  // GC
  useEffect(() => {
    return () => {
      if (constrainedInstance) {
        constrainedInstance.disposeConstrainer()
      }

      if (editorRef) {
        editorRef.dispose()
      }
    }
    // eslint-disable-next-line
  }, [])

  const handleValidation = (
    monaco: typeof monacoType,
    editor: monacoType.editor.IStandaloneCodeEditor
  ) => {
    if (!onValidation) {
      return
    }

    const markers = monaco.editor.getModelMarkers({})
    const hasError = markers.some((marker) => marker.severity > 1)
    const value = editor.getValue()
    onValidation(hasError, value)
  }

  const handleBeforeEditorMount = async (monaco: typeof monacoType) => {
    await onBeforeEditorMount?.(monaco)
    await addK6Types(monaco)

    const compilerOptions =
      monaco.languages.typescript.javascriptDefaults.getCompilerOptions()
    monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      ...compilerOptions,
      checkJs, // show errors for JS files, by default it check only TS
    })

    // Needed to make `checkJs` work and highlight errors
    monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      noSyntaxValidation: false,
      noSemanticValidation: false,
      noSuggestionDiagnostics: false,
      diagnosticCodesToIgnore,
    })

    if (checkJinja && language === 'json') {
      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: false,
      })
    }
  }

  const handleEditorDidMount = (
    editor: monacoType.editor.IStandaloneCodeEditor,
    monaco: typeof monacoType
  ) => {
    if (onEditorDidMount) {
      onEditorDidMount(monaco, editor)
    }

    setEditorRef(editor)
    const shouldCheckForJinja =
      checkJinja && (language === 'html' || language === 'json')

    const model = editor.getModel()

    monaco.editor.onDidChangeMarkers(() => {
      handleValidation(monaco, editor)
    })

    // Update jinja highlights on change
    editor.onDidChangeModelContent(() => {
      if (shouldCheckForJinja && highlightJinjaSyntax) {
        highlightJinjaSyntax(editor)
      }
    })

    // Initial jinja highlight
    if (shouldCheckForJinja && highlightJinjaSyntax) {
      highlightJinjaSyntax(editor)
    }

    if (constrainedRanges) {
      const instance = initializeConstrainedInstance(monaco, editor)
      if (!model) {
        return
      }

      updateConstrainedEditorRanges(
        instance,
        model,
        value,
        constrainedRanges,
        onDidChangeContentInEditableRange
      )
      setConstrainedInstance(instance)
    }
  }

  useEffect(() => {
    if (constrainedRanges && constrainedInstance) {
      const model = editorRef?.getModel()
      if (!model || prevValue === value) {
        return
      }
      if (
        updateConstrainedEditorRanges(
          constrainedInstance,
          model,
          value,
          constrainedRanges,
          onDidChangeContentInEditableRange
        )
      ) {
        setPrevValue(value)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, constrainedRanges])

  return (
    <Wrapper>
      {renderHeader && renderHeader({ scriptValue: value })}
      {overlayMessage && <Overlay>{overlayMessage}</Overlay>}
      <GrafanaCodeEditor
        value={value}
        language={language}
        showLineNumbers={true}
        showMiniMap={false}
        monacoOptions={{
          scrollBeyondLastLine: false,
          scrollbar: {
            alwaysConsumeMouseWheel: false,
          },
        }}
        onBeforeEditorMount={handleBeforeEditorMount}
        onEditorDidMount={handleEditorDidMount}
        readOnly={readOnly}
        containerStyles={containerStyles}
        onChange={onChange}
      />
    </Wrapper>
  )
}
