import { unsafeParseDuration } from 'utils/testScripts/parseDuration'
import type { Options } from '../schema/options/options'
import { Stage } from '../schema/primitives'
import { matchScenarios } from './utils'
import {
  DurationTooLong,
  ErrorSource,
  MaxDurationTooLong,
  TotalDurationTooLong,
} from './errors'
import { Rules } from 'types'

function calculateTotalDuration(
  startTime: string | undefined,
  duration: string
): number {
  const startTimeInSeconds = startTime ? unsafeParseDuration(startTime) : 0

  return startTimeInSeconds + unsafeParseDuration(duration)
}

function checkDuration(
  maxDuration: number,
  startTime: string | undefined,
  duration: string,
  source: ErrorSource
): DurationTooLong[] {
  const totalDuration = calculateTotalDuration(startTime, duration)

  if (totalDuration <= maxDuration) {
    return []
  }

  return [
    {
      type: 'duration-too-long',
      max: maxDuration,
      actual: totalDuration,
      source,
    },
  ]
}

function checkMaxDuration(
  allowed: number,
  startTime: string | undefined,
  maxDuration: string,
  source: ErrorSource
): MaxDurationTooLong[] {
  const totalDuration = calculateTotalDuration(startTime, maxDuration)

  if (totalDuration <= allowed) {
    return []
  }

  return [
    {
      type: 'max-duration-too-long',
      max: allowed,
      actual: totalDuration,
      source,
    },
  ]
}

function checkStages(
  maxDuration: number,
  startTime: string | undefined,
  stages: Stage[],
  source: ErrorSource
): TotalDurationTooLong[] {
  const startTimeInSeconds = startTime ? unsafeParseDuration(startTime) : 0

  const stagesInSeconds = stages.reduce((total, stage) => {
    return total + unsafeParseDuration(stage.duration)
  }, 0)

  const totalTime = startTimeInSeconds + stagesInSeconds

  if (totalTime <= maxDuration) {
    return []
  }

  return [
    {
      type: 'total-duration-too-long',
      max: maxDuration,
      actual: stagesInSeconds,
      source,
    },
  ]
}

export function duration(rules: Rules, options: Options) {
  // Max duration is in minutes, but we use seconds for comparisons
  const max = rules['tests.duration.max'] * 60

  return matchScenarios<
    DurationTooLong | MaxDurationTooLong | TotalDurationTooLong
  >(options, {
    'per-vu-iterations': ({ startTime, maxDuration = '10m' }, source) => {
      return checkMaxDuration(max, startTime, maxDuration, source)
    },

    'shared-iterations': ({ startTime, maxDuration = '10m' }, source) => {
      return checkMaxDuration(max, startTime, maxDuration, source)
    },

    'constant-vus': ({ startTime, duration }, source) => {
      return checkDuration(max, startTime, duration, source)
    },

    'constant-arrival-rate': (scenario, source) => {
      return checkDuration(max, scenario.startTime, scenario.duration, source)
    },

    'ramping-vus': (scenario, source) => {
      return checkStages(max, scenario.startTime, scenario.stages, source)
    },

    'ramping-arrival-rate': (scenario, source) => {
      return checkStages(max, scenario.startTime, scenario.stages, source)
    },
  })
}
