import React, { ReactNode } from 'react'
import { Icon, IconName } from '@grafana/ui'
import styled from 'styled-components'

type Props = {
  className?: string
  icon?: IconName | false | null
  children: ReactNode
}

const StyledMetaItem = styled.div`
  display: flex;
  align-items: center;
`

const StyledIcon = styled(Icon)`
  margin-right: 5px;
`

export const MetaItem = ({ icon, children, className, ...props }: Props) => {
  return (
    <StyledMetaItem className={className} {...props}>
      {icon && <StyledIcon name={icon} aria-hidden />}
      {children}
    </StyledMetaItem>
  )
}
