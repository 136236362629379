import styled from 'styled-components'
import { Field } from '@grafana/ui'

import { Flex } from 'components/Flex'

export const Description = styled.p`
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  color: ${({ theme }) => theme.colors.text.secondary};
`

export const RadioButtonWrapper = styled(Flex)`
  [data-testid='date-picker'] {
    position: fixed;
  }
`

export const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.weak};
  margin: ${({ theme }) => theme.spacing(2)} 0;
`

export const StyledField = styled(Field)`
  margin-bottom: 0;
`
