import { useSessionStorage } from 'usehooks-ts'

interface UseSnoozeUntilArgs {
  storageKey: string
  minutes?: number
}

export function useSnoozeUntil({
  storageKey,
  minutes = 24 * 60,
}: UseSnoozeUntilArgs) {
  const [snoozeUntil, setSnoozeUntil] = useSessionStorage(storageKey, 0)
  const isSnoozed = Date.now() < snoozeUntil

  const onSnooze = () => {
    setSnoozeUntil(Date.now() + minutes * 60 * 1000)
  }

  return { isSnoozed, onSnooze }
}
