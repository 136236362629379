import React, {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'

import { ActivityDrawer } from 'components/ActivityPanel'

type ActivityPanelContextType = {
  isOpen: boolean
  close: () => void
  open: () => void
}

export const ActivityPanelContext = createContext<
  undefined | ActivityPanelContextType
>(undefined)

export const ActivityPanelContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [activityPanelOpen, setActivityPanelOpen] = useState(false)

  const value = useMemo(() => {
    return {
      isOpen: activityPanelOpen,
      close: () => setActivityPanelOpen(false),
      open: () => setActivityPanelOpen(true),
    }
  }, [activityPanelOpen])

  return (
    <ActivityPanelContext.Provider value={value}>
      {children}
      {activityPanelOpen && <ActivityDrawer />}
    </ActivityPanelContext.Provider>
  )
}

export const useActivityPanel = () => {
  const context = useContext(ActivityPanelContext)

  if (context === undefined) {
    throw new Error(
      'useActivityPanel must be used within a ActivityPanelContextProvider'
    )
  }

  return context
}
