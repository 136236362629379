import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { FormProvider, useForm } from 'react-hook-form'

import { CollapsableSection } from 'components/CollapsableSection'
import { useSubscriptionRule } from 'data/useSubscriptions'
import { color, spacing } from 'utils/styled'
import { StaticIP } from 'types'

import { StaticIPsCalculatorForm } from './Form'
import { StaticIPsCalculatorFormValues } from './Form/StaticIPsCalculator.types'
import { staticIPsFormDefaultValues } from './StaticIPsCalculator.constants'

interface Props {
  isStaticIPsLoading: boolean
  isStaticIPsError: boolean
  staticIPs: StaticIP[] | undefined
}

export const StaticIPsCalculator = ({
  isStaticIPsLoading,
  isStaticIPsError,
  staticIPs,
}: Props) => {
  const concurrentMax = useSubscriptionRule('tests.concurrent.max')

  const formMethods = useForm<StaticIPsCalculatorFormValues>({
    defaultValues: {
      ...staticIPsFormDefaultValues,
      parallelTests: concurrentMax < 5 ? concurrentMax : 5,
    },
    mode: 'onChange',
    delayError: 600,
  })

  const [isOpen, setIsOpen] = useState(false)
  const [init, setInit] = useState(false)

  useEffect(() => {
    if (staticIPs && !isOpen && !init) {
      setIsOpen(staticIPs.length === 0)
      setInit(true)
    }
  }, [isStaticIPsLoading, isStaticIPsError, init, staticIPs, isOpen])

  const hasStaticIPs = staticIPs && staticIPs.length > 0

  return (
    <FormProvider {...formMethods}>
      <StaticIPsCalculatorContainer $hideCollapse={!hasStaticIPs}>
        <CollapsableSection
          label={
            <div>
              <h5>Add Static IPs</h5>
              <StaticIPsCalculatorDescription>
                Calculate how many Static IPs you will need to run the following
                test configuration.
              </StaticIPsCalculatorDescription>
            </div>
          }
          isOpen={hasStaticIPs ? isOpen : true}
          onToggle={() => setIsOpen((open) => !open)}
        >
          <StaticIPsCalculatorForm
            staticIPs={staticIPs}
            onSubmit={() => setIsOpen(false)}
          />
        </CollapsableSection>
      </StaticIPsCalculatorContainer>
    </FormProvider>
  )
}

const StaticIPsCalculatorContainer = styled.div<{ $hideCollapse: boolean }>`
  margin-top: ${spacing(1)};
  max-width: 760px;
  background-color: ${color('background', 'primary')};
  padding-left: ${spacing(2.5)};
  padding-top: ${spacing(2)};
  padding-right: ${spacing(0.5)};

  ${({ $hideCollapse }) =>
    $hideCollapse &&
    css`
      > div > button {
        display: none;
      }
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding-right: ${spacing(2.5)};
  }
`

const StaticIPsCalculatorDescription = styled.p`
  color: ${color('text', 'secondary')};
`
