import { useEffect, useState } from 'react'

import { SortOrder, TableColumn } from '../DataTable.types'
import { getSortedRows } from '../DataTable.utils'

export type DataTableSortValue = {
  sortBy: string | undefined
  sortOrder: SortOrder
}

const SORT_ORDER_CHOICES = [undefined, 'desc', 'asc'] as const

export function useSorting<T>(
  data: T[],
  columns: Array<TableColumn<T>>,
  defaultOrder: SortOrder,
  defaultField?: string,
  onSort?: (order?: string) => void,
  externalSort?: boolean
) {
  const [rows, setRows] = useState(data)
  const startingIndex = SORT_ORDER_CHOICES.indexOf(defaultOrder)
  const [sortIndex, setSortIndex] = useState(
    startingIndex > -1
      ? startingIndex
      : SORT_ORDER_CHOICES.findIndex((v) => v === undefined)
  )
  const [selectedColumn, setSelectedColumn] = useState(
    findColumnByField(columns, defaultField)
  )

  const onSortHandler = (column: TableColumn<T>) => {
    const currentSortField = column.sortField
    const differentField = currentSortField !== selectedColumn?.sortField
    const nextIndex = getNextIndex(sortIndex, differentField)

    setSortIndex(nextIndex)
    setSelectedColumn(column)
    const result = toResult(column.sortField, SORT_ORDER_CHOICES[nextIndex])
    onSort?.(result)
  }

  useEffect(() => {
    const sortedRows = externalSort
      ? data
      : getSortedRows<T>(
          data,
          columns,
          selectedColumn,
          SORT_ORDER_CHOICES[sortIndex]
        )
    setRows(sortedRows)
  }, [data, columns, selectedColumn, sortIndex, externalSort])

  const orderBy = toResult(
    selectedColumn?.sortField,
    SORT_ORDER_CHOICES[sortIndex]
  )
  return { orderBy, onSortHandler, rows }
}

function getNextIndex(sortIndex: number, differentField: boolean) {
  if (differentField) {
    return 1
  }

  return sortIndex === SORT_ORDER_CHOICES.length - 1 ? 0 : sortIndex + 1
}

function toResult(sortBy?: string, sortOrder?: SortOrder) {
  return sortOrder ? `${sortBy} ${sortOrder}` : undefined
}

function findColumnByField<T>(
  columns: Array<TableColumn<T>>,
  sortField?: string
) {
  if (!sortField) {
    return undefined
  }

  return columns.find((column) => column.sortField === sortField)
}
