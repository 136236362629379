import React, { useMemo } from 'react'

import { TestRunAnalysis } from 'types'
import { TracesFilters } from './TracesFilters'
import { tracesTable } from './tracesTable'
import { RunsPageTestIds } from 'types/dataTestIds'
import { useDatasource } from 'datasourceContext'
import { BreakdownView } from '../BreakdownView'
import { LoadingContainer } from 'components/LoadingContainer'
import { useTracesAvailable } from 'data/useTraces'
import { TracesEmptyView } from './TracesEmptyView'
import { EmptyFilterView } from 'components/EmptyFilterView'
import { useIsFetching } from '@tanstack/react-query'
import { toTracesFilterOptionsKey } from 'data/queryKeys'
import {
  useEnumParameter,
  useUpdateQueryParameters,
} from 'hooks/useQueryParameter'
import { ViewType } from '../BreakdownTable'
import { useTracesFilters } from './useTracesFilters'
import { encodeFilters } from '../../Filters/Filters.hooks'
import { QueryErrorBoundary } from 'components/ErrorBoundary'
import { ErrorBoundaryLocalView } from 'services/tracking.types'

interface Props {
  analysis: TestRunAnalysis
}

function TracesTabComponent({ analysis }: Props) {
  const { ds } = useDatasource()
  const updateQueryParams = useUpdateQueryParameters()

  const [view] = useEnumParameter('view', ['list', 'tree'] as const)

  const filterProps = useTracesFilters(view)

  const { filters, sortBy, updateSortBy, filtersByView } = filterProps

  const { data: isTracesAvailable, isLoading: isLoadingTracesAvailable } =
    useTracesAvailable({ run: analysis.main })

  const handleViewToggle = (newView: ViewType) => {
    const filtersEncoded = encodeFilters(filtersByView(newView))

    updateQueryParams({
      view: newView,
      traces: filtersEncoded,
    })
  }

  const isFetchingFilters = !!useIsFetching({
    queryKey: toTracesFilterOptionsKey(analysis.main.id),
  })

  const isFetchingRequiredFilters =
    isFetchingFilters ||
    filters.length === 0 ||
    filters.some((filter) => filter.required && filter.values.length === 0)

  // Skip fetching when optional filters are not filled yet
  const nonEmptyFilters = useMemo(
    () => filters.filter((f) => f.values.length !== 0),
    [filters]
  )

  if (isTracesAvailable === false) {
    return <TracesEmptyView testRun={analysis.main} />
  }

  return (
    <LoadingContainer loading={isLoadingTracesAvailable}>
      <BreakdownView
        data-testid={RunsPageTestIds.TracesTable}
        view={view}
        analysis={analysis}
        params={{
          filters: nonEmptyFilters,
          ds,
          enabled: !isFetchingRequiredFilters,
        }}
        table={tracesTable}
        emptyTable={
          <EmptyFilterView>
            No results match the current filter...
          </EmptyFilterView>
        }
        sortBy={sortBy}
        onSort={updateSortBy}
        onViewChange={handleViewToggle}
        header={<TracesFilters run={analysis.main} {...filterProps} />}
      />
    </LoadingContainer>
  )
}

export function TracesTab(props: Props) {
  return (
    <QueryErrorBoundary view={ErrorBoundaryLocalView.TracesTab}>
      <TracesTabComponent {...props} />
    </QueryErrorBoundary>
  )
}
