import { useQuery } from '@tanstack/react-query'
import { useDatasource } from 'datasourceContext'

import { toTestQueryKey } from './queryKeys'

export const useTest = (
  id: number | null | undefined,
  { enabled, staleTime }: { enabled?: boolean; staleTime?: number } = {
    enabled: true,
  }
) => {
  const { ds } = useDatasource()

  return useQuery({
    queryFn: () => ds.fetchTest(id!),
    queryKey: toTestQueryKey(id),
    enabled: !!id && enabled,
    staleTime,
  })
}
