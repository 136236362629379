import { GeneratorContext, SummaryRow } from '../types'
import { createTimeSeriesPanel } from '../panels'
import {
  DATA_RECEIVED,
  DATA_SENT,
  HTTP_REQUEST_RATE,
  HTTP_RESPONSE_TIME_90,
  HTTP_RESPONSE_TIME_95,
  HTTP_RESPONSE_TIME_99,
  HTTP_RESPONSE_TIME_AVG,
  HTTP_RESPONSE_TIME_MAX,
  VUS_METRIC,
} from 'constants/metrics'
import { LoadZoneDistribution } from 'types/loadZone'
import { MetricConfig } from 'datasource/models'
import { MetricBuilder } from 'utils/metrics'

function scopeToLoadZone(loadZone: LoadZoneDistribution | undefined) {
  return (metric: MetricConfig) => {
    if (loadZone === undefined) {
      return metric
    }

    return new MetricBuilder(metric).withLoadZone(loadZone).build()
  }
}

const VUS: MetricConfig = {
  ...VUS_METRIC,
  label: 'VUs',
}

const PERFORMANCE_OVERVIEW_METRICS: MetricConfig[] = [
  VUS,
  HTTP_RESPONSE_TIME_95,
  { ...HTTP_REQUEST_RATE, label: 'Request rate' },
]

export function createPerformanceOverviewChart(
  context: GeneratorContext,
  loadZone?: LoadZoneDistribution
): SummaryRow {
  const metrics = PERFORMANCE_OVERVIEW_METRICS.map(scopeToLoadZone(loadZone))

  return {
    height: 6,
    panels: [createTimeSeriesPanel(context, metrics)],
  }
}

const RESPONSE_TIME_METRICS: MetricConfig[] = [
  VUS,
  { ...HTTP_RESPONSE_TIME_AVG, label: 'Avg.' },
  { ...HTTP_RESPONSE_TIME_90, label: '90th percentile' },
  { ...HTTP_RESPONSE_TIME_95, label: '95th percentile' },
  { ...HTTP_RESPONSE_TIME_99, label: '99th percentile' },
  { ...HTTP_RESPONSE_TIME_MAX, label: 'Max' },
]

export function createResponseTimeChart(
  context: GeneratorContext,
  loadZone?: LoadZoneDistribution
): SummaryRow {
  const metrics = RESPONSE_TIME_METRICS.map(scopeToLoadZone(loadZone))

  return {
    height: 6,
    panels: [createTimeSeriesPanel(context, metrics)],
  }
}

const THROUGHPUT_METRICS: MetricConfig[] = [
  VUS,
  { ...HTTP_REQUEST_RATE, label: 'Requests per second' },
]

export function createThroughputChart(
  context: GeneratorContext,
  loadZone: LoadZoneDistribution | undefined
): SummaryRow {
  const metrics = THROUGHPUT_METRICS.map(scopeToLoadZone(loadZone))

  return {
    height: 6,
    panels: [createTimeSeriesPanel(context, metrics)],
  }
}

const BANDWIDTH_METRICS: MetricConfig[] = [
  VUS,
  { ...DATA_SENT, label: 'Data sent' },
  { ...DATA_RECEIVED, label: 'Data received' },
]

export function createBandwidthChart(
  context: GeneratorContext,
  loadZone: LoadZoneDistribution | undefined
): SummaryRow {
  const metrics = BANDWIDTH_METRICS.map(scopeToLoadZone(loadZone))

  return {
    height: 6,
    panels: [createTimeSeriesPanel(context, metrics)],
  }
}
