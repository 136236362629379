import React, { useState } from 'react'
import { Dropdown, IconButton, Tooltip } from '@grafana/ui'

import { ProjectSelectorWrapper, Title } from './ProjectSelector.styles'
import { useProjects } from 'data/useProjects'
import { ProjectSelectorOverlay } from './components/ProjectSelectorOverlay'
import { CreateNewProjectModal } from '../CreateNewProject'

interface ProjectSelectorProps {
  title?: string
}

export const ProjectSelector = ({ title }: ProjectSelectorProps) => {
  const { data: projects = [], isLoading } = useProjects()
  const [isCreateNewProjectModalOpen, setIsCreateNewProjectModalOpen] =
    useState(false)

  return (
    <>
      <Dropdown
        placement="bottom-start"
        overlay={
          <ProjectSelectorOverlay
            projects={projects}
            onCreateNewProjectBtnClick={() =>
              setIsCreateNewProjectModalOpen(true)
            }
          />
        }
      >
        <ProjectSelectorWrapper>
          {title && (
            <Tooltip content={title} placement="bottom-end">
              <Title>{title}</Title>
            </Tooltip>
          )}
          <IconButton
            name={isLoading ? 'fa fa-spinner' : 'angle-down'}
            size="xl"
            disabled={isLoading}
          />
        </ProjectSelectorWrapper>
      </Dropdown>

      <CreateNewProjectModal
        isOpen={isCreateNewProjectModalOpen}
        onDismiss={() => setIsCreateNewProjectModalOpen(false)}
      />
    </>
  )
}
