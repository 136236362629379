import { LoadTestsV2Response, ProjectId, Test } from 'types'
import { useQuery } from '@tanstack/react-query'

import { K6DataSource } from 'datasource/datasource'
import { useDatasource } from 'datasourceContext'
import { ODataQueryOptions, toSelectQuery } from 'datasource/serialization'
import { toTestCountQueryKey } from 'data/queryKeys'

type TestIDAndProjectId = Pick<Test, 'id' | 'project_id'>
type TestsWithCountResponse = LoadTestsV2Response<TestIDAndProjectId[]>

const testsWithCount: ODataQueryOptions<TestIDAndProjectId> = {
  select: ['id', 'project_id'],
}

const fetchTestCount = (ds: K6DataSource, projectId: ProjectId) => {
  const select = toSelectQuery(testsWithCount)
  const params = {
    ...select,
    project_id: projectId,
    page_size: 1,
    page: 1,
  }

  return ds
    .get<TestsWithCountResponse>('loadtests/v2/tests', { params })
    .then((response) => {
      // meta.count returns 1 when no tests exist so we have to check for the existence of tests 😕
      return response['k6-tests'].length > 0 && response.meta
        ? response.meta.count
        : 0
    })
}

export const useTestCount = (projectId?: ProjectId) => {
  const { ds } = useDatasource()

  return useQuery({
    queryKey: toTestCountQueryKey(projectId!),
    queryFn: () => fetchTestCount(ds, projectId!),
    placeholderData: 0,
    enabled: !!projectId,
  })
}
