import React, { useState } from 'react'
import {
  Checkbox,
  Field,
  FieldSet,
  InlineField,
  InlineSwitch,
  Modal,
  Select,
  TabsBar,
} from '@grafana/ui'
import useKonami from 'use-konami'

import { useFeatureFlags } from 'store/FeatureFlags'
import { useShowInitPage2 } from 'pages/InitPage/InitPage.hooks'
import { useOnboarding } from 'hooks/useOnboarding'
import { Flex } from 'components/Flex'
import { Tab } from 'components/Tabs/Tab'
import { useAllUserToggles } from '../../hooks/useUserToggle'
import styled, { keyframes } from 'styled-components'
import { spacing } from 'utils/styled'
import { ORGANIZATION_ROLE_TYPES, PROJECT_ROLE_TYPES } from 'constants/roles'
import { OrgRole, SelectableValue } from '@grafana/data'
import { useDevToolsProjectRole } from './hooks/useDevtoolsProjectRole'
import { useDevToolsOrgRole } from './hooks/useDevtoolsOrgRole'
import { useDebugTools } from './hooks/useDebugTools'
import { useDevToolsGrafanaRole } from './hooks/useDevtoolsGrafanaRole'

type TabOption = 'feature-flags' | 'toggles' | 'debugging'

const Heading = styled.h3`
  margin-bottom: 16px;
`

const blink = keyframes`
   0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
`

const DebugWarning = styled.div`
  z-index: 1000;

  position: fixed;
  font-size: 2rem;
  bottom: 20px;
  left: 20px;
  cursor: pointer;

  animation: ${blink} 1s infinite;
`

const orgRoleOptions = [
  { label: 'Admin', value: ORGANIZATION_ROLE_TYPES.ADMIN },
  { label: 'Editor', value: ORGANIZATION_ROLE_TYPES.READ_WRITE },
  { label: 'Viewer', value: ORGANIZATION_ROLE_TYPES.READ_ONLY },
]

const grafanaRoleOptions = [
  { label: 'Admin', value: OrgRole.Admin },
  { label: 'Editor', value: OrgRole.Editor },
  { label: 'Viewer', value: OrgRole.Viewer },
]

const projectRoleOptions = [
  { label: 'Admin', value: PROJECT_ROLE_TYPES.ADMIN },
  { label: 'Editor', value: PROJECT_ROLE_TYPES.READ_WRITE },
  { label: 'Viewer', value: PROJECT_ROLE_TYPES.READ_ONLY },
]

const TabContent = styled.div`
  padding-top: ${spacing(2)};
`

export function Devtools() {
  const [isOpen, setIsOpen] = useState(false)

  useKonami({
    onUnlock() {
      handleModalOpen()
    },
  })

  const handleModalOpen = () => {
    setIsOpen(true)
  }

  const handleModalDismiss = () => {
    setIsOpen(false)
  }

  if (process.env.NODE_ENV === 'production') {
    return <UserFeatures isOpen={isOpen} onDismiss={handleModalDismiss} />
  }

  return (
    <DeveloperFeatures
      isOpen={isOpen}
      onOpen={handleModalOpen}
      onDismiss={handleModalDismiss}
    />
  )
}

interface DeveloperFeaturesProps {
  isOpen: boolean
  onDismiss: () => void
  onOpen: () => void
}

const DeveloperFeatures = ({
  isOpen,
  onOpen,
  onDismiss,
}: DeveloperFeaturesProps) => {
  const { featureFlags, setFeatureFlag } = useFeatureFlags()
  const [showInitPage, setShowInitPage] = useShowInitPage2()
  const [onboardingStage, setOnboardingStage] = useOnboarding()

  const [hasEnabledDebugging, setHasEnabledDebugging] = useDebugTools()

  const [userOrgRole, setUserOrgRole] = useDevToolsOrgRole()
  const [userProjectRole, setUserProjectRole] = useDevToolsProjectRole()
  const [userGrafanaRole, setUserGrafanaRole] = useDevToolsGrafanaRole()

  const userToggles = useAllUserToggles()

  const hasFeatureFlags = featureFlags.length > 0
  const isOnboarding = onboardingStage !== 'none'

  const [activeTab, setActiveTab] = useState<TabOption>(
    hasFeatureFlags ? 'feature-flags' : 'toggles'
  )

  const handleChangeTab = (tab: TabOption) => () => {
    setActiveTab(tab)
  }

  const handleShowInitPageChange = () => {
    setShowInitPage((state) => !state)
  }

  const handleProjectRoleChange = (
    value: SelectableValue<PROJECT_ROLE_TYPES | null> | null
  ) => {
    setUserProjectRole(value?.value ?? null)
  }

  const handleOrgRoleChange = (
    value: SelectableValue<ORGANIZATION_ROLE_TYPES | null> | null
  ) => {
    setUserOrgRole(value?.value ?? null)
  }

  const handleGrafanaRoleChange = (value: SelectableValue<OrgRole> | null) => {
    setUserGrafanaRole(value?.value ?? null)
  }

  const handleDebugWarningClick = () => {
    onOpen()
    setActiveTab('debugging')
  }

  const handleToggleDebugging = () => {
    setHasEnabledDebugging((state) => !state)
  }

  const handleOnboardingChange = () => {
    if (isOnboarding) {
      onDismiss()
    }

    setOnboardingStage(!isOnboarding ? 'experienced' : 'none')
  }

  return (
    <>
      <Modal isOpen={isOpen} title="Devtools" onDismiss={onDismiss}>
        <TabsBar>
          {hasFeatureFlags && (
            <Tab
              label="Feature Flags"
              active={activeTab === 'feature-flags'}
              onChangeTab={handleChangeTab('feature-flags')}
            />
          )}
          <Tab
            label="User Toggles"
            active={activeTab === 'toggles'}
            onChangeTab={handleChangeTab('toggles')}
          />
          <Tab
            label="Debugging"
            active={activeTab === 'debugging'}
            onChangeTab={handleChangeTab('debugging')}
          />
        </TabsBar>
        <TabContent>
          {activeTab === 'feature-flags' && (
            <>
              <Heading>Feature Flags</Heading>
              <FieldSet>
                <Flex direction="column" align="start" gap={2}>
                  {featureFlags.map(
                    (flag) =>
                      flag.env[process.env.NODE_ENV] && (
                        <Checkbox
                          key={flag.name}
                          checked={flag.active}
                          label={flag.label}
                          description={flag.description}
                          onChange={() =>
                            setFeatureFlag(flag.name, !flag.active)
                          }
                        />
                      )
                  )}
                </Flex>
              </FieldSet>
            </>
          )}

          {activeTab === 'toggles' && (
            <>
              <Heading>User Toggles</Heading>
              <FieldSet>
                {userToggles.map((toggle) => (
                  <Checkbox
                    key={toggle.option.key}
                    checked={toggle.dismissed}
                    label={toggle.option.label}
                    description={toggle.option.description}
                    onChange={() => toggle.dismiss(!toggle.dismissed)}
                  />
                ))}
              </FieldSet>
            </>
          )}

          {process.env.NODE_ENV === 'development' &&
            activeTab === 'debugging' && (
              <>
                <Flex justify="space-between">
                  <Heading>Debugging</Heading>
                  <InlineField label="Enabled" transparent>
                    <InlineSwitch
                      transparent
                      value={hasEnabledDebugging}
                      onChange={handleToggleDebugging}
                    />
                  </InlineField>
                </Flex>
                <FieldSet label="States">
                  <Flex align="start" direction="column" gap={2}>
                    <Checkbox
                      checked={showInitPage}
                      label="Force init state"
                      description="Force the plugin to show the initialization state on load."
                      onChange={handleShowInitPageChange}
                    />
                    <Checkbox
                      checked={!isOnboarding}
                      label="Force onboarding state"
                      description="Force the plugin to show the onboarding guide."
                      onChange={handleOnboardingChange}
                    />
                  </Flex>
                </FieldSet>
                <FieldSet label="Account">
                  <Field
                    label="Grafana role"
                    description={
                      <>
                        View the UI as if the user had the selected Grafana
                        role.
                      </>
                    }
                  >
                    <Select
                      isClearable
                      placeholder="Use user's actual role"
                      value={userGrafanaRole}
                      options={grafanaRoleOptions}
                      onChange={handleGrafanaRoleChange}
                    />
                  </Field>
                  <Field
                    label="Organization role"
                    description={
                      <>
                        View the UI as if the user had the selected org. role.
                      </>
                    }
                  >
                    <Select
                      isClearable
                      placeholder="Use user's actual role"
                      value={userOrgRole}
                      options={orgRoleOptions}
                      onChange={handleOrgRoleChange}
                    />
                  </Field>
                  <Field
                    label="Project role"
                    description={
                      <>
                        View the UI as if the user had the selected project
                        role.
                      </>
                    }
                  >
                    <Select
                      isClearable
                      placeholder="Use user's actual role"
                      value={userProjectRole}
                      options={projectRoleOptions}
                      onChange={handleProjectRoleChange}
                    />
                  </Field>
                </FieldSet>
              </>
            )}
        </TabContent>
        <Modal.ButtonRow>
          <span>v{process.env.PLUGIN_VERSION}</span>
        </Modal.ButtonRow>
      </Modal>
      {process.env.NODE_ENV === 'development' && hasEnabledDebugging && (
        <DebugWarning onClick={handleDebugWarningClick}>
          🚨 DEBUGGING ENABLED 🚨
        </DebugWarning>
      )}
    </>
  )
}

interface UserFeaturesProps {
  isOpen: boolean
  onDismiss: () => void
}

/**
 * A modal for toggling beta features for customers.
 */
const UserFeatures = ({ isOpen, onDismiss }: UserFeaturesProps) => {
  const { featureFlags = [], setFeatureFlag } = useFeatureFlags()
  const hasFeatureFlags = featureFlags.length > 0

  return (
    <Modal isOpen={isOpen} title="Beta Features" onDismiss={onDismiss}>
      {hasFeatureFlags ? (
        <FieldSet>
          <Flex direction="column" align="start" gap={2}>
            {featureFlags.map(
              (flag) =>
                flag.env[process.env.NODE_ENV] && (
                  <Checkbox
                    key={flag.name}
                    checked={flag.active}
                    label={flag.label}
                    description={flag.description}
                    onChange={() => setFeatureFlag(flag.name, !flag.active)}
                  />
                )
            )}
          </Flex>
        </FieldSet>
      ) : (
        <p>
          There are no beta features available for your current environment.
        </p>
      )}
    </Modal>
  )
}
