import styled from 'styled-components'

import { spacing } from 'utils/styled'
import { Flex } from 'components/Flex'

export const Container = styled(Flex)<{
  isEmpty?: boolean
  isMinimal?: boolean
}>`
  height: calc(100% - ${spacing(3)});
  padding: ${({ isEmpty, isMinimal }) => {
    const bottom = isEmpty ? 0 : !isMinimal ? 3 : 0.5

    return spacing(0, 1, bottom, 1)
  }};
`

export const Bar = styled(Flex)`
  position: relative;
  flex: 1 0 auto;
`
