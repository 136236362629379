import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { routeMap } from 'routeMap'
import { Project, ProjectId, Test, TestId, TestRun } from 'types'
import type { EditingState, EditorState, RunConfirmation } from './types'
import { isTestRunnable } from 'utils/test'
import { useHasWriteAccessToProject } from 'data/usePermissions'
import { InvalidState, ValidState, useValidationState } from './validation'
import type { TestDraft } from './drafts'
import { getWriteMode, getSaveState } from './Editor.utils'
import { nanoid } from 'nanoid'
import type * as h from 'history'
import { useWillQueueTest } from 'data/useWillQueueTest'
import { mustForceRun } from './utils'
import { useConcurrencyPolicy } from 'hooks/useConcurrencyPolicy'

export function useRouteToRun(isNew: boolean) {
  const history = useHistory()

  return useCallback(
    ({ id }: TestRun) => {
      const path = routeMap.testRun(id)

      if (isNew) {
        history.replace(path)
      }

      history.push(path)
    },
    [history, isNew]
  )
}

type DraftCallback = (
  draft: TestDraft,
  state: ValidState | InvalidState
) => void

export function useEditorState(
  project: Project,
  test: Test | null,
  draft: TestDraft
) {
  const hasWriteAccess = useHasWriteAccessToProject(project.id)

  const [validationState, validate] = useValidationState(project, draft)
  const [editingState, setEditingState] = useState<EditingState>('editing')

  const saveState = useMemo(() => getSaveState(test, draft), [test, draft])
  const writeMode = useMemo(
    () => getWriteMode(test, hasWriteAccess),
    [test, hasWriteAccess]
  )

  const state = useMemo<EditorState>(
    () => ({
      isRunnable: test === null || isTestRunnable(test),
      writeMode,
      saveState,
      validationState,
      editingState,
    }),
    [test, writeMode, saveState, validationState, editingState]
  )

  function toSaveCallback(fn: DraftCallback) {
    return (draft: TestDraft) => {
      setEditingState('saving')

      return validate('save', draft)
        .then(({ aborted, state }) => {
          if (aborted) {
            return
          }

          return fn(draft, state)
        })
        .finally(() => {
          setEditingState('editing')
        })
    }
  }

  function toRunCallback(fn: DraftCallback) {
    return (draft: TestDraft) => {
      setEditingState('starting')

      return validate('run', draft)
        .then(({ aborted, state }) => {
          if (aborted) {
            return
          }

          return fn(draft, state)
        })
        .finally(() => {
          setEditingState('editing')
        })
    }
  }

  return { state, toSaveCallback, toRunCallback }
}

function getParams(location: h.Location) {
  try {
    return new URLSearchParams(location.hash.replace(/^#/, ''))
  } catch {
    return new URLSearchParams()
  }
}

interface EditingSessionOptions<T> {
  initialValue: () => T
  delay?: number
  projectId: ProjectId
  testId?: TestId | null
}

export function useEditingSession<T>({
  initialValue,
  projectId,
  testId,
  delay = 2000,
}: EditingSessionOptions<T>): [T, Dispatch<SetStateAction<T>>] {
  const history = useHistory()
  const location = useLocation()

  const sessionId = getParams(location).get('s')
  const storageKey =
    sessionId &&
    `grafana.k6.project[${projectId}].test[${
      testId ?? 'new'
    }].editor.session-${sessionId}`

  const [value, setValue] = useState<T>(() => {
    const storedValue = storageKey && sessionStorage.getItem(storageKey)
    const parsedValue = storedValue ? (JSON.parse(storedValue) as T) : null

    if (parsedValue !== null) {
      return parsedValue
    }

    return initialValue()
  })

  useEffect(() => {
    if (sessionId !== null) {
      return
    }

    const newSessionId = nanoid()
    const params = getParams(history.location)

    params.append('s', newSessionId)

    history.replace({
      ...history.location,
      hash: '#' + params.toString(),
    })
  }, [sessionId, history])

  useEffect(() => {
    if (storageKey === null) {
      return
    }

    const timeout = setTimeout(() => {
      sessionStorage.setItem(storageKey, JSON.stringify(value))
    }, delay)

    return () => {
      clearTimeout(timeout)
    }
  }, [storageKey, value, delay])

  return [value, setValue]
}

export function useRequiresConfirmation(
  state: EditorState
): () => Promise<RunConfirmation> {
  const checkWillQueue = useWillQueueTest()
  const concurrencyPolicy = useConcurrencyPolicy()

  return async () => {
    const mightQueue = await checkWillQueue()

    if (concurrencyPolicy === 'abort' && mightQueue) {
      return 'concurrency-limit-reached'
    }

    const mustForce = mustForceRun(state.validationState)

    if (mustForce && mightQueue) {
      return 'force-queue'
    }

    if (mustForce) {
      return 'force-run'
    }

    if (mightQueue) {
      return 'queue'
    }

    return 'none'
  }
}
