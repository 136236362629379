import React from 'react'
import { Span } from './types'
import { TimelineItem } from './TimelineItem'
import {
  getDuration,
  getRelativeXOffset,
  getSpanChildren,
} from './BrowserTimeline.utils'
import { StyledSpanVisualizer } from './SpanVisualizer.styled'

const SPAN_IGNORE = [
  'browser.newContext',
  'browserContext.newPage',
  'browser.newPage',
]

interface SpanVisualizerProps {
  root: {
    span: Span
    children: Span[]
  }
  spans: Span[]
  compact?: boolean
}

export function SpanVisualizer({
  root,
  spans,
  compact = false,
}: SpanVisualizerProps) {
  const rootDuration = getDuration(root.span)

  return (
    <StyledSpanVisualizer>
      {root.children.map((child) => {
        if (SPAN_IGNORE.includes(child.name)) {
          return null
        }
        const spanChildren = getSpanChildren(child, spans)
        const xOffset = getRelativeXOffset(child, root.span)

        return (
          <TimelineItem
            key={child.spanId}
            compact={compact}
            xOffset={xOffset}
            rootDuration={rootDuration}
            span={child}
            spanChildren={spanChildren}
          />
        )
      })}
    </StyledSpanVisualizer>
  )
}
