import React from 'react'
import styled from 'styled-components'
import { CustomScrollbar, Drawer, IconButton } from '@grafana/ui'

import { ColorPool } from 'utils/colorPool'
import { Flex } from 'components/Flex'
import { DocLink, docs } from 'components/DocLink'
import { TestRunAnalysis } from 'types'
import { type MetricConfig } from 'datasource/models'
import { AnalysisChart } from './AnalysisChart'

type AnalysisDrawerProps = {
  onClose: () => void
  analysis: TestRunAnalysis
  colorPool: ColorPool
  loadZone?: string
  metric: MetricConfig
}

export const AnalysisDrawer = ({
  analysis,
  colorPool,
  loadZone,
  metric,
  onClose,
}: AnalysisDrawerProps) => {
  return (
    <Drawer
      aria-label="Analysis panel"
      onClose={onClose}
      title={
        <DrawerTitleWrapper>
          <Flex align="center" gap={2.5}>
            <MarginLessTitle>
              <div>Analyzing results with Grafana Explore</div>
            </MarginLessTitle>
          </Flex>
          <div>
            <IconButton
              aria-label="Close analysis panel"
              name="times"
              onClick={onClose}
            />
          </div>
        </DrawerTitleWrapper>
      }
      size="md"
    >
      <CustomScrollbar autoHide={false} hideHorizontalTrack>
        <p>
          Learn how to dive deeper into your test results using the Grafana
          Cloud k6 datasource.
        </p>
        <h3>Explore queries in-depth</h3>
        <p>
          Any chart with data in the Grafana Cloud k6 plugin can be opened in
          Grafana Explore and the query modified via the built-in Grafana Cloud
          k6 datasource.
        </p>
        <p>
          You can try right now with one of the metrics generated by this test
          run. In the chart below, start by clicking the actions menu (the three
          vertical dots) and then click the &apos;Explore&apos; link.
        </p>
        <ChartWrapper>
          <AnalysisChart
            analysis={analysis}
            colorPool={colorPool}
            loadZone={loadZone}
            metric={metric}
            showAggregationSelect={false}
          />
        </ChartWrapper>
        <h3>Customize, correlate and compare</h3>
        <p>
          The Grafana Cloud k6 datasource supports a variety of query types
          which can easily be customized with their own options.
        </p>
        <p>
          When querying metrics you can dive deeper into your test run results
          by adding custom metrics and custom tags.
        </p>
        <p>
          Within Grafana Explore you can query multiple datasources in one
          place, allowing you to easily visualize the impact of your test runs
          against different systems.
        </p>
        {/* This is a div because the doc link has an icon from @grafana/ui which renders a div
          so the nesting is invalid and we get validateDOMNesting warnings for tests, etc.
        */}
        <div>
          Learn more by reading the{' '}
          <DocLink article={docs.analyze.correlate} showIcon>
            correlating results in Grafana documentation
          </DocLink>
          .
        </div>
      </CustomScrollbar>
    </Drawer>
  )
}

const DrawerTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(3, 2)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.weak};
`

const MarginLessTitle = styled.h2`
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};
`

const ChartWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`
