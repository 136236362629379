import React, {
  useCallback,
  useState,
  useRef,
  KeyboardEventHandler,
  ReactNode,
} from 'react'
import styled from 'styled-components'
import { styleMixins } from '@grafana/ui'
import { ControllerRenderProps } from 'react-hook-form'

import { Pill, type PillProps } from 'components/Pill'

interface Option<T> {
  value: T
  label: string
}

interface ListboxProps<T> extends ControllerRenderProps {
  'aria-labelledby': string
  direction: 'vertical' | 'horizontal'
  options: Array<Option<T>>
  multiselect?: boolean
  selected: T[]
  OptionComponent?: React.ComponentType<{
    children: ReactNode
    isSelected: boolean
  }>
}

const List = styled.ul<{ $direction: 'vertical' | 'horizontal' }>`
  display: flex;
  flex-direction: ${({ $direction }) =>
    $direction === 'vertical' ? 'column' : 'row'};
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(1)};
  list-style: none;
`

const ListItem = styled.li`
  cursor: pointer;

  &:focus-visible {
    ${({ theme }) => styleMixins.focusCss(theme)}
  }
`

const keyMap = {
  vertical: {
    next: 'ArrowDown',
    prev: 'ArrowUp',
  },
  horizontal: {
    next: 'ArrowRight',
    prev: 'ArrowLeft',
  },
}

// https://www.w3.org/WAI/ARIA/apg/patterns/listbox/
export const Listbox = <T extends string | number>({
  'aria-labelledby': ariaLabelledBy,
  direction,
  options,
  multiselect,
  selected,
  onChange,
  OptionComponent = ListBoxPill,
  ...props
}: ListboxProps<T>) => {
  const ref = useRef<HTMLLIElement[]>([])
  const startingFocus = selected.length ? getIndex(options, selected[0]) : 0
  const [focussed, setFocussed] = useState(startingFocus)

  const handleClick = useCallback(
    (option: Option<T>) => {
      moveFocus(getIndex(options, option.value))

      if (multiselect) {
        const newValue = selected.includes(option.value)
          ? selected.filter((value) => value !== option.value)
          : [...selected, option.value]
        return onChange(newValue)
      }

      const newValue = selected.includes(option.value) ? [] : [option.value]
      onChange(newValue)
    },
    [multiselect, onChange, selected, options]
  )

  const moveFocus = (newVal: number) => {
    setFocussed(newVal)
    ref.current?.[newVal]?.focus()
  }

  const handleKeyDown: KeyboardEventHandler<HTMLUListElement> = (event) => {
    if (event.key === keyMap[direction].next) {
      event.preventDefault()

      if (focussed !== options.length - 1) {
        moveFocus(focussed + 1)
      }
    }

    if (event.key === keyMap[direction].prev) {
      event.preventDefault()

      if (focussed !== 0) {
        moveFocus(focussed === 0 ? 0 : focussed - 1)
      }
    }

    if (['Enter', ' '].includes(event.key)) {
      event.preventDefault()
      const option = options[focussed]

      if (option) {
        handleClick(option)
      }
    }

    if (event.key === 'Home') {
      event.preventDefault()
      moveFocus(0)
    }

    if (event.key === 'End') {
      event.preventDefault()
      moveFocus(options.length - 1)
    }
  }

  return (
    <List
      $direction={direction}
      onKeyDown={handleKeyDown}
      role="listbox"
      aria-labelledby={ariaLabelledBy}
      aria-multiselectable={multiselect}
      aria-orientation={direction}
      {...props}
    >
      {options.map((option, i) => {
        const isSelected = selected.includes(option.value)

        return (
          <ListItem
            key={option.value}
            role="option"
            aria-selected={isSelected}
            onClick={() => handleClick(option)}
            ref={(node) => {
              if (node) {
                ref.current[i] = node
              }
            }}
            tabIndex={i === focussed ? 0 : -1}
          >
            <OptionComponent isSelected={isSelected}>
              {option.label}
            </OptionComponent>
          </ListItem>
        )
      })}
    </List>
  )
}

const ListBoxPill = (props: PillProps) => {
  return <Pill {...props} as="div" />
}

function getIndex<T>(options: Array<Option<T>>, value: T) {
  return options.findIndex((option) => option.value === value)
}
