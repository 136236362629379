import { type AbsoluteTimeRange } from '@grafana/data'

import { SortOptions, TestRun, TestRunId } from 'types'
import { K6DataSource } from 'datasource/datasource'
import { FilterExpression, includes } from 'datasource/serialization'
import { useDatasource } from 'datasourceContext'
import { BrowserUrls, BrowserUrlsSelect } from 'types/k6Browser'
import { useQuery } from '@tanstack/react-query'
import { toBrowserHttpQueryKey } from '../../queryKeys'
import { QueryOptions } from '../../queryClient'
import { calculateSkipQueryParam } from 'utils/pagination'
import { EntityClient } from './entityClient'

interface FetchPageParams {
  filter?: FilterExpression<BrowserUrls>
  page?: number
  pageSize?: number
  sortBy?: SortOptions<BrowserUrls>
  timeRange?: AbsoluteTimeRange
  select?: BrowserUrlsSelect[]
  count?: boolean
}

export class BrowserUrlsClient extends EntityClient<BrowserUrls> {
  constructor(datasource: K6DataSource) {
    super(datasource)
  }

  toUrl(testRunId: string | number): string {
    return `/loadtests/v4/test_runs(${testRunId})/browser_urls`
  }

  select(entity: BrowserUrls): BrowserUrls {
    return entity
  }

  fetchByIds(testRunId: TestRunId, ids: string[]) {
    return this.fetchPage(testRunId, {
      filter: includes('id', ids),
      page: 1,
      pageSize: ids.length,
      sortBy: undefined,
    })
  }

  async fetchBrowserUrls(
    testRunId: TestRunId,
    {
      filter,
      page,
      pageSize,
      sortBy,
      timeRange,
      select,
      count,
    }: FetchPageParams
  ) {
    return this.fetch({
      testRunId,
      query: {
        select: select ?? [
          'id',
          'scenario_id',
          'group_id',
          'name',
          'scenario',
          'browser_http_metric_summary',
        ],
        count: count ?? true,
        filter,
        orderBy: sortBy && [[sortBy.sortBy, sortBy.direction]],
        skip:
          page && pageSize
            ? calculateSkipQueryParam({ page, pageSize })
            : undefined,
        top: pageSize,
      },
      timeRange,
    })
  }

  async fetchNames(testRunId: TestRunId) {
    return this.fetchBrowserUrls(testRunId, { select: ['name'] })
  }

  async fetchPage(testRunId: TestRunId, params: FetchPageParams) {
    return this.fetchBrowserUrls(testRunId, params)
  }
}

export function useBrowserUrlsClient() {
  const { ds } = useDatasource()

  return new BrowserUrlsClient(ds)
}

type BrowserHttpName = Pick<BrowserUrls, 'name'>

export function useBrowserHttpNames(
  testRun: TestRun,
  options?: QueryOptions<BrowserHttpName[]>
) {
  const client = useBrowserUrlsClient()
  return useQuery<BrowserHttpName[]>({
    queryKey: toBrowserHttpQueryKey(testRun.id, {
      select: ['name'],
      ended: testRun.ended,
    }),
    queryFn: () =>
      client.fetchNames(testRun.id).then(({ items }) => items ?? []),
    ...options,
  })
}
