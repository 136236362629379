import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'

import { useDatasource } from 'datasourceContext'
import {
  LoadTestsV2Response,
  Test,
  TestSortOptions,
  TrendingMetric,
} from 'types'
import { ODataQueryOptions, toSelectQuery } from 'datasource/serialization'
import { testWithTrendsParams } from 'data/useTestWithTrends'
import { K6DataSource } from 'datasource/datasource'
import { toMetricByConfigId, toTestWithTrends } from 'utils/trends'
import { toTestSearchQueryKey, toTestWithTrendsQueryKey } from 'data/queryKeys'
import { useActiveTestRuns } from 'data/useActiveTestRuns'

type TestsWithMetrics = LoadTestsV2Response<Test[] | TrendingMetric[]>

interface FetchTests {
  projectId: number
  select: ODataQueryOptions<Test>
  page: number
  search?: string
  orderBy?: string
  pageSize?: number
}

export const DEFAULT_RUNS_TO_FETCH = 20

const fetchTests = async (
  ds: K6DataSource,
  {
    select,
    search,
    orderBy,
    projectId,
    pageSize = DEFAULT_RUNS_TO_FETCH,
    page,
  }: FetchTests
) => {
  const params = {
    ...toSelectQuery(select),
    q: search,
    order_by: orderBy,
    project_id: projectId,
    page_size: pageSize,
    page: page,
  }

  const response = await ds.get<TestsWithMetrics>('loadtests/v2/tests', {
    params,
  })

  const metricsByConfigId = toMetricByConfigId(
    response['k6-runs-trending-metrics']
  )
  const tests = response['k6-tests'].map((test) =>
    toTestWithTrends(test, metricsByConfigId)
  )

  return {
    count: response.meta?.count ?? tests.length,
    tests,
  }
}

export const useTestSearch = (
  projectId?: number,
  search?: string,
  orderBy?: TestSortOptions
) => {
  const { ds } = useDatasource()
  const queryClient = useQueryClient()
  const activeRuns = useActiveTestRuns()

  const hasMissingData = useMemo(() => {
    for (const { data: activeRun } of activeRuns) {
      // If the test isn't returned by getQueryData, it means that it's new, and we need to re-fetch
      // Note: `search` may cause new test to be hidden, hence polling will continue until it has been loaded
      if (
        activeRun &&
        !queryClient.getQueryData(toTestWithTrendsQueryKey(activeRun.test_id))
      ) {
        return true
      }
    }

    return false
  }, [activeRuns, queryClient])

  const params = {
    select: testWithTrendsParams,
    search,
    orderBy,
  }

  const queryKey = projectId
    ? toTestSearchQueryKey(projectId, params)
    : undefined
  const queryFn = projectId
    ? ({ pageParam: page = 1 }) =>
        fetchTests(ds, { ...params, projectId, page })
    : undefined

  const { data, ...queryParams } = useInfiniteQuery({
    queryKey,
    queryFn,
    enabled: !!projectId,
    getNextPageParam: (lastPage, pages) => {
      if (pages.length === lastPage.count) {
        return undefined
      }

      return pages.length + 1
    },
    onSuccess({ pages }) {
      for (const page of pages) {
        for (const test of page.tests) {
          queryClient.setQueryData(toTestWithTrendsQueryKey(test.id), test)
        }
      }
    },
  })

  const { isFetching, refetch } = queryParams
  useEffect(() => {
    if (!isFetching && hasMissingData) {
      refetch()
    }
  }, [hasMissingData, isFetching, refetch])

  const tests = useMemo(
    () => data?.pages.flatMap((page) => page.tests) ?? [],
    [data]
  )

  return {
    tests,
    ...queryParams,
  }
}
