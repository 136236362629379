import { Icon, styleMixins } from '@grafana/ui'
import styled from 'styled-components'
import { color, fontSize, spacing } from 'utils/styled'

export const Header = styled.div<{ disabled: boolean }>`
  display: flex;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};
  box-sizing: border-box;
  flex-direction: row-reverse;
  position: relative;
  justify-content: space-between;
  font-size: ${fontSize('body')};
  padding: ${spacing(0.5)} 0;
  z-index: 1;

  &:focus-within {
    ${({ theme }) => styleMixins.focusCss(theme)};
  }
`

export const ChevronIcon = styled(Icon)`
  color: ${color('text', 'secondary')};
`

export const Button = styled.button`
  all: unset;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;

  &:focus-visible {
    outline: none;
    outline-offset: unset;
    transition: none;
    box-shadow: none;
  }
`

export const Content = styled.div``

export const Label = styled.div`
  display: flex;
  flex: 1 1 0;
`
