import { useSessionStorage } from 'usehooks-ts'
import { OrgRole } from '@grafana/data'

import { useDebugTools } from './useDebugTools'

export function useDebugGrafanaRole() {
  const [debugToolsEnabled] = useDebugTools()
  const [grafanaRole] = useDevToolsGrafanaRole()

  return debugToolsEnabled ? grafanaRole : null
}

export function useDevToolsGrafanaRole() {
  return useSessionStorage<OrgRole | null>('devtools.grafanaRole', null)
}
