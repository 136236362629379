import React from 'react'

import { EmptyProps } from '../BreakdownTable'
import { isTestActive } from 'utils/testRun'
import { CodeSnippet } from 'components/CodeSnippet'
import { DocLink, docs } from 'components/DocLink'
import {
  BreakdownTabEmptyView,
  BreakdownTabMessage,
  Content,
} from '../Breakdown.styles'

const WS_SNIPPET = `
  import ws from 'k6/ws';

  export default function () {
    const url = 'ws://echo.websocket.org';

    const resp = ws.connect(url, null, function (socket) {
      socket.on('open', function () {
        console.log('WebSocket connection established!');
        socket.close();
      });
    });
  }
`

type WSEmptyViewProps = EmptyProps<{}>

export const WSEmptyView = ({ testRun }: WSEmptyViewProps) => {
  if (isTestActive(testRun)) {
    return (
      <BreakdownTabMessage>
        Waiting for some WebSocket sessions to be started...
      </BreakdownTabMessage>
    )
  }

  return (
    <BreakdownTabEmptyView>
      <Content>
        <h2>Working with WebSockets</h2>
        <p>
          You can test your WebSocket APIs by using our built-in WebSocket APIs.{' '}
          {"Here's"} a simple example of opening a session.
        </p>
        <p>
          Read more about working with WebSockets in the{' '}
          <DocLink article={docs.httpRequests.main}>docs</DocLink>.
        </p>
      </Content>
      <Content>
        <CodeSnippet code={WS_SNIPPET} />
      </Content>
    </BreakdownTabEmptyView>
  )
}
