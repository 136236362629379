import { useCallback, useMemo } from 'react'
import { useTheme2 } from '@grafana/ui'

import type { Trend, TrendDataPoint } from 'types'
import { useTestRunExecutionDuration } from 'data/useTestRunExecutionDuration'

import { createConfig } from './ValueTooltip.utils'

export function useTooltipConfig(trend: Trend, dataPoint: TrendDataPoint) {
  const executionDuration = useTestRunExecutionDuration(dataPoint.run)

  return useMemo(() => {
    return createConfig(trend, dataPoint, executionDuration)
  }, [trend, dataPoint, executionDuration])
}

export function useGetVisualizationColor() {
  const theme = useTheme2()
  return useCallback(
    (color: string) => theme.visualization.getColorByName(color),
    [theme]
  )
}

export function useGetContrastText() {
  const theme = useTheme2()
  return useCallback(
    (backgroundColor: string) => theme.colors.getContrastText(backgroundColor),
    [theme]
  )
}
