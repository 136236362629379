import React from 'react'
import { Flex } from 'components/Flex'
import { Section } from '../components/Section'
import { StartTesting } from '../components/StartTesting'

export function InitializeCTASection({
  onInitialize,
}: {
  onInitialize: () => void
}) {
  return (
    <Section>
      <Flex direction="column" shrink={1} grow={0} gap={3}>
        <Section.Heading $size="s">
          Ready to try Grafana Cloud k6?
        </Section.Heading>
        <Flex direction="column" align="flex-start">
          <StartTesting onInitialize={onInitialize}>Start testing</StartTesting>
        </Flex>
      </Flex>
    </Section>
  )
}
