import { useProjects } from 'data/useProjects'
import { Project } from 'types'

export function useProjectNameValidation(currentProject?: Project) {
  const { data: projects = [] } = useProjects()

  return {
    validate: {
      maxLength: (value: string) =>
        value.length > 255
          ? 'Ensure this field has no more than 255 characters.'
          : true,
      required: (value: string) =>
        value.trim().length === 0 ? 'Project name is required' : true,
      unique: (value: string) => {
        const nameTaken = projects.find((project) => {
          return (
            project.name.toLowerCase() === value.toLowerCase() &&
            project.id !== currentProject?.id // allow to change case of existing project name
          )
        })

        if (nameTaken) {
          return `project already exists, please enter a unique project name`
        }

        return true
      },
    },
  }
}
