import React from 'react'

import { RowActions } from '../RowActions'
import {
  SortOrder,
  defaultSortFunc,
  type TableColumn,
} from 'components/DataTable'
import { type ProjectLimitsRow } from './LimitsTable.types'
import {
  DurationMaxPerTestCell,
  ProjectNameCell,
  VUMaxPerTestCell,
  VUhMaxPerMonthCell,
  VUhUsageCell,
} from './LimitsTableCell'

function alwaysSortSummaryLast(
  a: ProjectLimitsRow,
  b: ProjectLimitsRow,
  sortOrder: SortOrder,
  sortField: keyof ProjectLimitsRow
) {
  const aField = a[sortField]
  const bField = b[sortField]

  if (a.summary) {
    return 1
  }

  if (b.summary) {
    return -1
  }

  return defaultSortFunc(aField, bField, sortOrder)
}

function getSortFunc(sortField: keyof ProjectLimitsRow) {
  return (a: ProjectLimitsRow, b: ProjectLimitsRow, sortOrder: SortOrder) =>
    alwaysSortSummaryLast(a, b, sortOrder, sortField)
}

export function getColumns(): Array<TableColumn<ProjectLimitsRow>> {
  return [
    {
      name: 'Project',
      sortable: true,
      sortField: 'project_name',
      sortFunction: getSortFunc('project_name'),
      cell: (row) => <ProjectNameCell {...row} />,
    },
    {
      name: 'Max VUh per month',
      sortable: true,
      sortField: 'vuh_max_per_month',
      sortFunction: getSortFunc('vuh_max_per_month'),
      cell: (row) => <VUhMaxPerMonthCell {...row} />,
    },
    {
      name: 'VUh use (current month)',
      sortable: true,
      sortField: 'vuh_usage',
      sortFunction: getSortFunc('vuh_usage'),
      cell: (row) => <VUhUsageCell {...row} />,
    },
    {
      name: 'Max VUs per test',
      sortable: true,
      sortField: 'vu_max_per_test',
      sortFunction: getSortFunc('vu_max_per_test'),
      cell: (row) => <VUMaxPerTestCell {...row} />,
    },
    {
      name: 'Max duration per test',
      sortable: true,
      sortField: 'duration_max_per_test',
      sortFunction: getSortFunc('duration_max_per_test'),
      cell: (row) => <DurationMaxPerTestCell {...row} />,
    },
    {
      name: '',
      right: true,
      width: '80px',
      cell(row) {
        if (row.summary) {
          return null
        }

        return <RowActions projectLimits={row} />
      },
    },
  ]
}
