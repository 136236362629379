import { css, cx } from '@emotion/css'
import {
  Button,
  ButtonVariant,
  HorizontalGroup,
  Input,
  Modal,
  useStyles2,
} from '@grafana/ui'
import { GrafanaTheme2 } from '@grafana/data'

import React, { useEffect, useRef, useState } from 'react'

export interface ConfirmModalProps {
  isOpen: boolean
  isPending?: boolean
  canConfirm?: boolean
  title: string
  body: React.ReactNode
  description?: React.ReactNode
  className?: string
  confirmText: string
  confirmationText?: string
  dismissText?: string
  modalClass?: string
  confirmButtonVariant?: ButtonVariant
  onConfirm(): void
  onDismiss(): void
}

// For the most part, this is a copy of ConfirmModal from @grafana/ui
// with the extra addition of the `isPending` prop.
export const ConfirmModal = ({
  isOpen,
  canConfirm = true,
  isPending = false,
  title,
  body,
  description,
  className,
  confirmText,
  confirmationText,
  dismissText = 'Cancel',
  modalClass,
  onConfirm,
  onDismiss,
  confirmButtonVariant = 'destructive',
}: ConfirmModalProps): JSX.Element => {
  const [disabled, setDisabled] = useState(Boolean(confirmationText))
  const styles = useStyles2(getStyles)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const onConfirmationTextChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    setDisabled(
      confirmationText?.localeCompare(event.currentTarget.value) !== 0
    )
  }

  useEffect(() => {
    // for some reason autoFocus property did no work on this button, but this does
    if (isOpen) {
      buttonRef.current?.focus()
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      setDisabled(Boolean(confirmationText))
    }
  }, [isOpen, confirmationText])

  return (
    <Modal
      className={cx(styles.modal(isPending), className, modalClass)}
      title={title}
      isOpen={isOpen || isPending}
      onDismiss={onDismiss}
    >
      <div className={styles.modalText}>
        {body}
        {description ? (
          <div className={styles.modalDescription}>{description}</div>
        ) : null}
        {confirmationText ? (
          <div className={styles.modalConfirmationInput}>
            <HorizontalGroup>
              <Input
                placeholder={`Type ${confirmationText} to confirm`}
                onChange={onConfirmationTextChange}
              />
            </HorizontalGroup>
          </div>
        ) : null}
      </div>
      <Modal.ButtonRow>
        <Button
          variant="secondary"
          onClick={onDismiss}
          fill="outline"
          disabled={isPending}
        >
          {dismissText}
        </Button>
        <Button
          variant={confirmButtonVariant}
          onClick={onConfirm}
          ref={buttonRef}
          disabled={isPending || disabled || !canConfirm}
          icon={isPending ? 'fa fa-spinner' : undefined}
        >
          {confirmText}
        </Button>
      </Modal.ButtonRow>
    </Modal>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  modal: (hideCloseButton = false) => css`
    width: 500px;

    ${hideCloseButton &&
    `[aria-label='Close dialogue'] {
      display: none;
    }`}
  `,
  modalText: css({
    fontSize: theme.typography.h5.fontSize,
    color: theme.colors.text.primary,
  }),
  modalDescription: css({
    fontSize: theme.typography.body.fontSize,
  }),
  modalConfirmationInput: css({
    paddingTop: theme.spacing(2),
  }),
})
