import type { TrendDataPoint } from 'types'

export const getPaddedArray = <T, P = undefined>(
  source: T[],
  length: number,
  padding?: P
): Array<T | P> => {
  const items = source?.slice(-length) ?? []

  return [...items, ...Array(length - items.length).fill(undefined)]
}

export const getMinMaxValue = (
  dataPoints: Array<TrendDataPoint | undefined>
): [number, number] => {
  const values = dataPoints.reduce((acc: number[], dataPoint) => {
    if (dataPoint && typeof dataPoint.value === 'number') {
      acc.push(dataPoint.value)
    }

    return acc
  }, [])

  if (values.length > 0) {
    return [Math.min(...values), Math.max(...values)]
  }

  return [0, 0]
}

export const getBarHeight = (max: number, value: number) => {
  return `${Math.round((value / max) * 100)}%`
}

export const roundToNearest = (num = 0) => {
  const minLog = 2
  const roundNum = Math.round(num)
  const log = String(roundNum).length - 1
  const unit = Number(String(1).padEnd(log < minLog ? minLog : log, '0'))

  return Math.ceil(roundNum / unit) * unit
}
