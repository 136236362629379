export const ROOT_SPAN_ID = undefined

export const ATTR_NAVIGATION_URL = 'navigation.url'
export const ATTR_WEB_VITAL_NAME = 'web_vital.name'
export const ATTR_WEB_VITAL_VALUE = 'web_vital.value'
export const ATTR_WEB_VITAL_RATING = 'web_vital.rating'

export const SPAN_NAME_LOCATOR_CLICK = 'locator.click'
export const SPAN_NAME_LOCATOR_TYPE = 'locator.type'
export const SPAN_NAME_K6_TEST_RUN_ID = 'k6.test_run_id'

export const SPAN_NAME_WEB_VITAL = 'web_vital'

export const LOCATOR_SPAN_TYPES = [
  SPAN_NAME_LOCATOR_CLICK,
  SPAN_NAME_LOCATOR_TYPE,
]

export const WEB_VITAL_RATING_UNKNOWN = '-'
export const WEB_VITAL_RATING_POOR = 'poor'
export const WEB_VITAL_RATING_NEEDS_IMPROVEMENT = 'needs_improvement'
export const WEB_VITAL_RATING_GOOD = 'good'
