import { useProject } from 'data/useProject'
import { TestRoutes } from 'routes/TestRoutes'
import { ProjectPage } from 'pages/ProjectPage'
import React from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'

export function ProjectRoutes() {
  const { path } = useRouteMatch()
  const { projectId } = useParams<{ projectId: string }>()

  const { isInitialLoading, data: project } = useProject(+projectId)

  if (isInitialLoading) {
    return <div>Loading...</div>
  }

  if (!project) {
    return <div>Project not found</div>
  }

  return (
    <Switch>
      <Route path={`${path}/:testId(\\d+|new)`}>
        <TestRoutes project={project} />
      </Route>
      <Route>
        <ProjectPage />
      </Route>
    </Switch>
  )
}
