import { Icon, IconSize } from '@grafana/ui'
import styled, { css } from 'styled-components'

interface IndicatorProps {
  $color: string
  $size: IconSize
}

// Copied shamelessly from here: https://github.com/grafana/grafana/blob/831e7697ae573c65de8fd7e18aa0bbd69c432452/packages/grafana-ui/src/components/Icon/utils.ts#L19
const getSvgSize = ({ $size }: IndicatorProps) => {
  switch ($size) {
    case 'xs':
      return 12
    case 'sm':
      return 14
    case 'md':
      return 16
    case 'lg':
      return 18
    case 'xl':
      return 24
    case 'xxl':
      return 36
    case 'xxxl':
      return 48
  }
}

export const Indicator = styled.span<IndicatorProps>`
  display: inline-flex;
  align-items: stretch;
  min-width: ${getSvgSize}px;
  min-height: ${getSvgSize}px;
  padding: 2px;

  &::before {
    content: '';
    display: block;
    min-width: 100%;
    min-height: 100%;
    background: ${({ $color }) => $color};
    border-radius: 3px;
  }
`

interface ColoredIconProps {
  $color?: string
}

export const ColoredIcon = styled(Icon)<ColoredIconProps>`
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
`
