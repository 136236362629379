import { getBackendSrv } from '@grafana/runtime'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { FolderPermission } from 'types/rbac'
import { toProjectFolderAssignments } from './queryKeys'
import { Project } from 'types'
import { exhaustive } from 'utils/typescript'

interface FolderUserPermissionParams {
  type: 'user'
  userId: number
  permission: FolderPermission | ''
}

interface FolderTeamPermissionParams {
  type: 'team'
  teamId: number
  permission: FolderPermission | ''
}

interface FolderRolePermissionParams {
  type: 'role'
  builtInRole: string
  permission: FolderPermission | ''
}

export type ChangeFolderPermissionParams =
  | FolderUserPermissionParams
  | FolderTeamPermissionParams
  | FolderRolePermissionParams

export function useChangeFolderUserPermission(project: Project) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: ChangeFolderPermissionParams) => {
      const folderPath = `api/access-control/folders/${project.grafana_folder_uid}`

      switch (params.type) {
        case 'user':
          return getBackendSrv().post(`${folderPath}/users/${params.userId}`, {
            permission: params.permission,
          })
        case 'team':
          return getBackendSrv().post(`${folderPath}/teams/${params.teamId}`, {
            permission: params.permission,
          })
        case 'role':
          return getBackendSrv().post(
            `${folderPath}/builtInRoles/${params.builtInRole}`,
            {
              permission: params.permission,
            }
          )
        default:
          return exhaustive(params)
      }
    },
    onSuccess: (_, { permission }) => {
      queryClient.invalidateQueries(toProjectFolderAssignments(project.id))
    },
  })
}
