import React, { useEffect } from 'react'
import { Controller, useWatch, useForm } from 'react-hook-form'
import { Field, Select } from '@grafana/ui'

import { useProjects } from 'data/useProjects'
import { SelectInfinite } from 'components/SelectInfinite'
import { ScheduledTestSelectValues } from 'components/ScheduledTests'
import { useTestOptions } from 'data/useTestOptions'

interface ScheduledTestsSelectProps {
  formActions: React.ReactNode
  onSubmit: (scheduledTest: any) => void
  selectValues?: ScheduledTestSelectValues
}

export const ScheduledTestsSelect = ({
  formActions,
  onSubmit,
  selectValues,
}: ScheduledTestsSelectProps) => {
  const { data: projects = [], isLoading: projectsLoading } = useProjects()
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<ScheduledTestSelectValues>({
    values: selectValues,
  })

  const projectId = useWatch({
    control,
    name: 'project_id',
  })

  const {
    tests,
    fetchNextPage,
    isLoading: testsLoading,
  } = useTestOptions(projectId)

  useEffect(() => {
    if (projects.length) {
      const defaultProject = projects.find((project) => project.is_default)

      if (defaultProject) {
        setValue('project_id', defaultProject.id)
        setValue('project_name', defaultProject.name)
      }
    }
  }, [projects, setValue])

  const projectIdError = errors.project_id
  const testIdError = errors.test_id
  const projectInputId = 'select-project'
  const testInputId = 'select-test'

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        htmlFor={projectInputId}
        label="Select a project"
        error={projectIdError?.message}
        invalid={!!projectIdError}
      >
        <Controller
          rules={{
            required: {
              value: true,
              message: 'Please select a project',
            },
          }}
          render={({ field }) => {
            const { onChange, ...rest } = field

            return (
              <Select
                {...rest}
                disabled={projectsLoading}
                inputId={projectInputId}
                onChange={(val) => {
                  onChange(val.value)
                  setValue('project_name', val.label!)
                  setValue('test_id', null!)
                }}
                options={projects.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
                tabSelectsValue={false}
              />
            )
          }}
          control={control}
          name="project_id"
        />
      </Field>
      <Field
        htmlFor={testInputId}
        label="Choose a test"
        error={testIdError?.message}
        invalid={!!testIdError}
      >
        <Controller
          rules={{
            required: {
              value: true,
              message: 'Please choose a test',
            },
          }}
          render={({ field }) => {
            const { onChange, ...rest } = field

            return (
              <SelectInfinite
                {...rest}
                disabled={!projectId}
                inputId={testInputId}
                isLoading={testsLoading}
                loadNext={fetchNextPage}
                noOptionsMessage={
                  testsLoading ? 'loading...' : 'no available tests'
                }
                onChange={(val) => {
                  onChange(val.value)
                  setValue('test_name', val.label!)
                }}
                options={tests}
                tabSelectsValue={false}
              />
            )
          }}
          control={control}
          name="test_id"
        />
      </Field>
      {formActions}
    </form>
  )
}
