import styled from 'styled-components'
import { Icon as IconBase } from '@grafana/ui'

import { spacing } from 'utils/styled'
import { Flex } from 'components/Flex'

const ICON_SIZE = '24px'

export const Container = styled.div`
  padding: ${spacing(1.5, 2)};
`

export const Icon = styled(IconBase)`
  fill: ${({ theme }) => theme.colors.text.secondary};
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
`

export const Heading = styled(Flex)`
  font-weight: 600;
  text-transform: uppercase;
`

export const Description = styled.div`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.text.secondary};
`
