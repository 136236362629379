import { discriminatedUnion } from 'zod'
import { TestBuilderDraftSchema } from './builder'
import { TestScriptDraftSchema } from './script'

export const TestDraftSchema = discriminatedUnion('type', [
  TestBuilderDraftSchema,
  TestScriptDraftSchema,
])

export { formatErrors, hasFatalError } from '../errors'

export type * from './primitives'
export type * from './options/scenario'
