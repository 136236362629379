import { useURLFilters } from '../../Filters/Filters.hooks'
import { BrowserUrls } from 'types/k6Browser'
import { TestRun } from 'types'
import React from 'react'
import { RunDetailsFilters } from '../../Filters/Filters'

interface BrowserHttpFiltersProps
  extends ReturnType<typeof useURLFilters<BrowserUrls>> {
  run: TestRun
}

export function BrowserHttpFilters({
  addFilterValue,
  addNewFilter,
  filters,
  options,
  removeAllFilters,
  removeFilter,
  run,
  updateSortBy,
}: BrowserHttpFiltersProps) {
  return (
    <RunDetailsFilters
      filters={filters}
      options={options}
      run={run}
      onClearFiltersClick={removeAllFilters}
      onAddNewFilterClick={addNewFilter}
      onRemoveFilterClick={removeFilter}
      onAddFilterValue={addFilterValue}
    />
  )
}
