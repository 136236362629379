import styled from 'styled-components'
import { spacing } from 'utils/styled'

export const MetaBarStyled = styled.div`
  display: flex;
  gap: ${spacing(2)};
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    flex-basis: auto;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md + 1}px) {
    flex-basis: 0;
  }
`
