import {
  type infer as Infer,
  object,
  string,
  record,
  literal,
  number,
  array,
  discriminatedUnion,
} from 'zod'
import { Duration, VUs, StageSchema } from '../primitives'

const BrowserOptionsSchema = object({
  type: string(),
})

const ExecutorOptionsSchema = object({
  browser: BrowserOptionsSchema.optional(),
})

const ScenarioBaseSchema = object({
  startTime: Duration.optional(),
  gracefulStop: Duration.optional(),
  exec: string().optional(),
  env: record(string()).optional(),
  tags: record(string()).optional(),
  options: ExecutorOptionsSchema.optional(),
})

const SharedIterationsScenarioSchema = ScenarioBaseSchema.merge(
  object({
    executor: literal('shared-iterations'),
    vus: VUs.optional(),
    iterations: number().optional(),
    maxDuration: Duration.optional(),
  })
)

export type SharedIterationsScenario = Infer<
  typeof SharedIterationsScenarioSchema
>

const PerVUIterationsScenarioSchema = ScenarioBaseSchema.merge(
  object({
    executor: literal('per-vu-iterations'),
    vus: VUs.optional(),
    iterations: number().optional(),
    maxDuration: Duration.optional(),
  })
)

export type PerVUIterationsScenario = Infer<
  typeof PerVUIterationsScenarioSchema
>

const ConstantVUsScenarioSchema = ScenarioBaseSchema.merge(
  object({
    executor: literal('constant-vus'),
    duration: Duration,
    vus: VUs.optional(),
  })
)

export type ConstantVUsScenario = Infer<typeof ConstantVUsScenarioSchema>

const RampingVUsScenarioSchema = ScenarioBaseSchema.merge(
  object({
    executor: literal('ramping-vus'),
    stages: array(StageSchema),
    startVUs: VUs.optional(),
    gracefulRampDown: Duration.optional(),
  })
)

export type RampingVUsScenario = Infer<typeof RampingVUsScenarioSchema>

const ConstantArrivalRateScenarioSchema = ScenarioBaseSchema.merge(
  object({
    executor: literal('constant-arrival-rate'),
    duration: Duration,
    rate: number(),
    preAllocatedVUs: VUs,
    timeUnit: Duration.optional(),
    maxVUs: VUs.optional(),
  })
)

export type ConstantArrivalRateScenario = Infer<
  typeof ConstantArrivalRateScenarioSchema
>

const RampingArrivalRateScenarioSchema = ScenarioBaseSchema.merge(
  object({
    executor: literal('ramping-arrival-rate'),
    startRate: number().optional(),
    timeUnit: Duration.optional(),
    preAllocatedVUs: VUs,
    stages: array(StageSchema),
    maxVUs: VUs.optional(),
  })
)

export type RampingArrivalRateScenario = Infer<
  typeof RampingArrivalRateScenarioSchema
>

const ExternallyControlledScenarioSchema = ScenarioBaseSchema.merge(
  object({
    executor: literal('externally-controlled'),
    duration: Duration,
    vus: VUs.optional(),
    maxVUs: VUs.optional(),
  })
)

export type ExternallyControlledScenario = Infer<
  typeof ExternallyControlledScenarioSchema
>

export const ScenarioSchema = discriminatedUnion('executor', [
  SharedIterationsScenarioSchema,
  PerVUIterationsScenarioSchema,
  ConstantVUsScenarioSchema,
  RampingVUsScenarioSchema,
  ConstantArrivalRateScenarioSchema,
  RampingArrivalRateScenarioSchema,
  ExternallyControlledScenarioSchema,
])

export type Scenario = Infer<typeof ScenarioSchema>
