import { useQuery } from '@tanstack/react-query'
import { useAppContext } from 'appContext'
import { ApiError, ProjectWithStats } from 'types'
import { useDatasource } from 'datasourceContext'
import {
  toProjectsWithStatsKey,
  toUsageReportsSummaryQueryKey,
} from 'data/queryKeys'
import {
  UsageReportsParams,
  UsageReportsResponse,
} from 'pages/ManagePage/tabs/UsageReports/UsageReports.types'
import { serializeUsageReportParams } from 'pages/ManagePage/tabs/UsageReports/UsageReports.utils'
import { getHighestRules } from 'utils/subscription'
import {
  useActiveSubscriptions,
  useHasOnlyTrialTypeSubscriptions,
  useSubscriptionRule,
} from 'data/useSubscriptions'
import { QueryOptions } from './queryClient'

export const useUsageReportSummary = (params: UsageReportsParams) => {
  const { orgId } = useAppContext()
  const { ds } = useDatasource()
  const hasUsageReports = useSubscriptionRule('organization.usage_reports')

  return useQuery({
    enabled: hasUsageReports,
    queryKey: toUsageReportsSummaryQueryKey(
      orgId,
      serializeUsageReportParams(params)
    ),
    queryFn: () =>
      ds
        .fetchUsageReportSummary(orgId, params)
        .then((response) => response.usage_report_summary),
  })
}

export const useUsageReports = (
  params: UsageReportsParams,
  options: QueryOptions<UsageReportsResponse, ApiError, ProjectWithStats[]> = {}
) => {
  const { orgId } = useAppContext()
  const { ds } = useDatasource()
  const hasUsageReports = useSubscriptionRule('organization.usage_reports')

  return useQuery<UsageReportsResponse, ApiError, ProjectWithStats[]>({
    queryKey: toProjectsWithStatsKey(orgId, serializeUsageReportParams(params)),
    queryFn: () => ds.fetchUsageReports(orgId, params),
    select: (response) => response.usage_reports,
    enabled: hasUsageReports,
    ...options,
  })
}

export const useFreeVUhsUsageReport = () => {
  const activeSubscriptions = useActiveSubscriptions()
  const hasOnlyTrialSubscriptions = useHasOnlyTrialTypeSubscriptions()
  if (!hasOnlyTrialSubscriptions || !activeSubscriptions) {
    return
  }

  const rules = getHighestRules(activeSubscriptions)
  const maxVUhs = rules['tests.vuh.max']
  const vuhsUsage = activeSubscriptions[0]?.vuh ?? 0

  return {
    maxVUhs,
    vuhsUsage,
    vuhLimitReached: vuhsUsage >= maxVUhs,
  }
}
