import { type TestRun } from 'types'
import { type InsightAudit } from 'types/cloudInsights'
import {
  useInsightsAudits,
  useInsightsLatestExecution,
} from 'data/useCloudInsights'
import { useQueryParameter } from 'hooks/useQueryParameter'

export const AUDIT_FILTER_ID = 'insights-audit'

interface UseInsightFilterParams {
  open: boolean
  filter: string
}

const useParams = (audits: InsightAudit[] = []) => {
  const auditIds = audits.map((audit) => audit.id)

  return useQueryParameter<UseInsightFilterParams>({
    name: AUDIT_FILTER_ID,
    decoder: (filter) => {
      const open = filter !== null

      if (filter && auditIds.includes(filter)) {
        return { open, filter }
      }

      return {
        open: false,
        filter: '',
      }
    },
    encoder: ({ open, filter }) => (open ? filter : null),
  })
}

export const useAuditQueryParams = (testRun: TestRun) => {
  const { data: execution } = useInsightsLatestExecution(testRun)
  const { data: audits = [] } = useInsightsAudits(testRun, execution)

  return useParams(audits)
}
