import { useQueryParameter } from 'hooks/useQueryParameter'
import { useCallback, useMemo } from 'react'
import {
  GrpcMetricSummary,
  HttpMetricSummary,
  Summary,
  WsMetricSummary,
  hasGrpcMetricSummary,
  hasHttpMetricSummary,
  hasWsMetricSummary,
  hasBrowserMetricSummary,
  BrowserMetricSummary,
} from 'types'
import { BreakdownTab } from './Breakdown.types'
import { BROWSER_DISCOVERABILITY } from 'constants/browser'

interface SupportedProtocols {
  http?: HttpMetricSummary
  grpc?: GrpcMetricSummary
  ws?: WsMetricSummary
  browser?: BrowserMetricSummary
}

export const useSupportedProtocols = (
  summary: Summary | undefined
): SupportedProtocols => {
  return useMemo(() => {
    if (summary === undefined) {
      return {}
    }

    return {
      http: hasHttpMetricSummary(summary.http_metric_summary)
        ? summary.http_metric_summary
        : undefined,
      ws: hasWsMetricSummary(summary.ws_metric_summary)
        ? summary.ws_metric_summary
        : undefined,
      grpc: hasGrpcMetricSummary(summary.grpc_metric_summary)
        ? summary.grpc_metric_summary
        : undefined,
      browser: hasBrowserMetricSummary(summary.browser_metric_summary)
        ? summary.browser_metric_summary
        : undefined,
    }
  }, [summary])
}

const getDefaultTab = (protocols: SupportedProtocols) => {
  if (protocols.http) {
    return BreakdownTab.HTTP
  }

  if (protocols.grpc) {
    return BreakdownTab.GRPC
  }

  if (protocols.ws) {
    return BreakdownTab.WS
  }

  /*
  if (protocols.browser) {
    return BreakdownTab.BROWSER
  } */

  return BreakdownTab.CHECKS
}

export const useBreakdownTab = (protocols: SupportedProtocols) => {
  const parser = useCallback(
    (value: string | null) => {
      switch (value) {
        case BreakdownTab.CHECKS:
        case BreakdownTab.LOGS:
        case BreakdownTab.SCRIPT:
        case BreakdownTab.THRESHOLDS:
        case BreakdownTab.HTTP:
        case BreakdownTab.TRACES:
        case BreakdownTab.ANALYSIS:
          return value

        case BreakdownTab.GRPC:
          return protocols.http ? BreakdownTab.GRPC : getDefaultTab(protocols)

        case BreakdownTab.WS:
          return protocols.http ? BreakdownTab.WS : getDefaultTab(protocols)

        case BreakdownTab.BROWSER:
          return protocols.browser || BROWSER_DISCOVERABILITY
            ? BreakdownTab.BROWSER
            : getDefaultTab(protocols)

        default:
          return getDefaultTab(protocols)
      }
    },
    [protocols]
  )

  return useQueryParameter({
    name: 'tab',
    decoder: parser,
    encoder: (value) => value,
  })
}

export const useCurrentTab = () => {
  const [tab] = useQueryParameter({
    name: 'tab',
    decoder: (value) => value,
    encoder: (value) => value,
  })

  return tab ?? undefined
}
