import { ProjectId, Test } from 'types'

export enum TrackingEventName {
  CTAUpgrade = 'grafana_cloud_k6_cta_upgrade',
  TestCreated = 'grafana_cloud_k6_test_created',
  TestRun = 'grafana_cloud_k6_test_run',
  ErrorBoundary = 'grafana_cloud_k6_error_boundary',
}

export enum CTAUpgradeReason {
  MaxConcurrentTests = 'max_concurrent_tests_reached',
  MaxSavedTests = 'max_saved_tests_reached',
  MaxVUsExceeded = 'max_vus_exceeded',
  MaxDurationExceeded = 'max_duration_exceeded',
  TotalLoadZonesExceeded = 'total_load_zones_exceeded',
}

export enum ErrorBoundaryType {
  Local = 'local',
  FullPage = 'full_page',
}

export enum ErrorBoundaryLocalView {
  SchedulesTab = 'schedules_tab',
  WebSocketsTab = 'websockets_tab',
  TracesTab = 'traces_tab',
  ThresholdsTab = 'thresholds_tab',
  ScriptTab = 'script_tab',
  DiffScriptsTab = 'diff_scripts_tab',
  LogsTab = 'logs_tab',
  HttpTab = 'http_tab',
  GrpcTab = 'grpc_tab',
  ChecksTab = 'checks_tab',
  BrowserTab = 'browser_tab',
  AnalysisTab = 'analysis_tab',
  BreakdownRow = 'breakdown_row',
  ProjectSettingsLimits = 'project_settings_limits',
  ProjectVUhConsumption = 'project_vuh_consumption',
  StackAPITokens = 'stack_api_tokens',
  PerformanceOverview = 'performance_overview',
  PerformanceInsights = 'performance_insights',
  PerformanceDetails = 'performance_details',
  PerformanceOverviewComparison = 'performance_overview_comparison',
  PerformanceDetailsComparison = 'performance_details_comparison',
  ProjectRolesTable = 'project_roles_table',
}

export enum ErrorBoundaryFullPageView {
  InitializePage = 'initialize_page',
  AppContextProvider = 'app_context_provider',
  ProjectContextProvider = 'project_context_provider',
  RootPage = 'root_page',
  ProjectPage = 'project_page',
  NotificationsTab = 'notifications_tab',
  SettingsPage = 'settings_page',
  StaticIPs = 'static_ips',
  TrendingMetricsModal = 'trending_metrics_modal',
  PageErrorBoundary = 'page_error_boundary',
  RecorderPage = 'recorder_page',
}

export enum TrackingActionType {
  Click = 'click',
}

interface TrackingEventBase {
  eventName: TrackingEventName
  projectId?: ProjectId
}

export interface CTAUpgradeTrackingEvent extends TrackingEventBase {
  eventName: TrackingEventName.CTAUpgrade
  reason: CTAUpgradeReason
  action: TrackingActionType
}

interface TestCreatedTrackingEvent extends TrackingEventBase {
  eventName: TrackingEventName.TestCreated
  source: Test['creation_process']
}

interface TestRunTrackingEvent extends TrackingEventBase {
  eventName: TrackingEventName.TestRun
  source: Test['creation_process']
}

interface ErrorBoundaryFullPageTrackingEvent extends TrackingEventBase {
  eventName: TrackingEventName.ErrorBoundary
  type: ErrorBoundaryType.FullPage
  isApiError: boolean
  view: ErrorBoundaryFullPageView
}

interface ErrorBoundaryLocalTrackingEvent extends TrackingEventBase {
  eventName: TrackingEventName.ErrorBoundary
  type: ErrorBoundaryType.Local
  isApiError: boolean
  view: ErrorBoundaryLocalView
}

export type TrackingEvent =
  | CTAUpgradeTrackingEvent
  | TestCreatedTrackingEvent
  | TestRunTrackingEvent
  | ErrorBoundaryFullPageTrackingEvent
  | ErrorBoundaryLocalTrackingEvent
