import React from 'react'

import { useHasUserProjectWriteAccess } from 'data/usePermissions'
import { LinkButton } from 'components/LinkButton'
import { routeMap } from 'routeMap'
import { useCurrentProject } from 'projectContext'

export const CreateFromScriptButton = ({
  scriptValue,
}: {
  scriptValue: string
}) => {
  const project = useCurrentProject()
  const hasUserProjectWriteAccess = useHasUserProjectWriteAccess()

  if (!hasUserProjectWriteAccess) {
    return null
  }

  return (
    <LinkButton
      to={{
        pathname: routeMap.newTestScript(project.id),
        state: { scriptValue },
      }}
      button={{ variant: 'secondary' }}
    >
      Create test from script
    </LinkButton>
  )
}
