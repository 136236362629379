import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useAppContext } from 'appContext'

import { toStaticIPsQueryKey } from './queryKeys/staticIPs'
import { useDatasource } from '../datasourceContext'
import { showAlert } from 'utils/showAlert'

export function useDeprovisionStaticIPs() {
  const queryClient = useQueryClient()
  const { orgId } = useAppContext()
  const { ds } = useDatasource()

  return useMutation({
    mutationFn: (payload: number[]) => ds.deprovisionStaticIPs(orgId, payload),
    onError: () => {
      showAlert(
        'Could not deprovision static IPs, please try again later',
        'error'
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries(toStaticIPsQueryKey(orgId))
    },
  })
}
