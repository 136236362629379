import styled from 'styled-components'
import { spacing } from 'utils/styled'

export const ProjectSelectorWrapper = styled.div`
  display: flex;
  gap: ${spacing(1)};
  align-items: center;
  cursor: pointer;
`

export const Title = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 600px;
`
