import {
  QueryVariableModel,
  ScopedVars,
  TypedVariableModel,
} from '@grafana/data'
import { getTemplateSrv } from '@grafana/runtime'
import { K6DataSource } from 'datasource/datasource'
import {
  MetricConfig,
  QueryBody,
  QueryConfig,
  TestRunConfigType,
  QueryType,
  VariableQuery,
} from 'datasource/models'
import { fromNumericVariable } from 'datasource/templates'
import { mapValues } from 'lodash-es'
import { LATEST_RUN_OPTION_VALUE } from './QueryEditor.constants'

interface AppVariableModel extends QueryVariableModel {
  query: VariableQuery
}

const resolveMetricVariables = (
  config: MetricConfig,
  scopedVars: ScopedVars
): MetricConfig | null => {
  const templateSrv = getTemplateSrv()

  return {
    ...config,
    query: {
      ...config.query,
      metric: templateSrv.replace(config.query.metric, scopedVars),
      tags: mapValues(config.query.tags, (tag) => {
        return {
          ...tag,
          name: templateSrv.replace(tag.name, scopedVars),
          value: tag.values.map((value) =>
            templateSrv.replace(value, scopedVars)
          ),
        }
      }),
    },
  }
}

const resolveConfigVariables = (
  config: QueryConfig,
  scopedVars: ScopedVars
): QueryConfig | null => {
  switch (config.type) {
    case TestRunConfigType.Metric:
      return resolveMetricVariables(config, scopedVars)

    default:
      return config
  }
}

/**
 * Takes a query and goes through all the properties in it that might have variables and resolves them. The
 * end result should be an query that has no variables left it in.
 *
 * If a resolved value has the wrong type, e.g. test run id is not an integer, then it should return null so that
 * we don't make requests to the API that we know will fail.
 */
export const resolveVariables = (
  config: QueryBody,
  scopedVars: ScopedVars
): QueryBody | null => {
  const testRunId =
    config.testRunId === LATEST_RUN_OPTION_VALUE
      ? config.testRunId
      : fromNumericVariable(config.testRunId)?.toString()

  const projectId = fromNumericVariable(config.projectId)?.toString()
  const testId = fromNumericVariable(config.testId)?.toString()

  const query = resolveConfigVariables(config.config, scopedVars)

  if (query === null) {
    return null
  }

  return {
    testRunId,
    testId,
    projectId,
    type: QueryType.TestRun,
    config: query,
  }
}

/**
 * Asserts whether the given variable is a variable defined by this app or some other type (a constant, text box, etc.) so
 * that we can check if its a test run variable or a test variable, etc.
 */
export const isAppVariableModel = (
  datasource: K6DataSource,
  model: TypedVariableModel
): model is AppVariableModel => {
  return (
    model.type === 'query' &&
    model.datasource?.type === datasource.type &&
    typeof model.query === 'object'
  )
}
