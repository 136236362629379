import { css, keyframes } from 'styled-components'

export const createPulseAnimation = (opacityTo = 0.5) => keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: ${opacityTo};
  }

  100% {
    opacity: 1;
  }
`

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const fadeIn = css`
  animation: ${fadeInAnimation} 250ms cubic-bezier(0.4, 0, 0.2, 1) 1;
`
