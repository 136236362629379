import styled from 'styled-components'
import { spacing } from 'utils/styled'

export const Container = styled.div`
  margin-top: ${spacing(1)};
`

export const NoMetricsContainer = styled.div`
  flex: 0 0 100%;
  padding: ${spacing(3, 0, 2)};
`
