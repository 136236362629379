import React, { useEffect, useMemo, useState } from 'react'

import { TestRunAnalysis } from 'types'
import { ViewType, Diff as ComparedValue, AnyTable, RowStatus } from './types'
import { StyledBoundary, ValueRowBody } from './ValueRow.styles'
import { useRowToggle } from './TableStateProvider'
import { TableRow, TableRowIcon, TableData } from './Table'
import { useSelectedColumns } from './TableSettingsContext'
import { ErrorBoundaryLocalView } from 'services/tracking.types'

function getRowIcon(success: boolean | undefined | RowStatus) {
  switch (success) {
    case true:
    case 'success':
      return 'check-circle'
    case false:
    case 'error':
      return 'times-circle'
    case 'warning':
      return 'exclamation-circle'
    default:
      return undefined
  }
}

interface ValueRowProps<T, P> {
  view: ViewType
  analysis: TestRunAnalysis
  value: ComparedValue<T>
  params: P
  level: number
  table: AnyTable<T, P>
}

export function ValueRow<T, P>({
  view,
  analysis,
  value: { left, right },
  params,
  level,
  table: { columns, keyBy, isSuccess, RowBodyComponent, getRowStatus },
}: ValueRowProps<T, P>) {
  const [expanded, toggleRow] = useRowToggle(keyBy(left))
  const [rowBodyEl, setRowBodyEl] = useState<HTMLTableRowElement | null>(null)

  const selectedColumns = useSelectedColumns(columns)

  useEffect(() => {
    if (rowBodyEl === null) {
      return
    }

    rowBodyEl.scrollIntoView({
      block: 'nearest',
      behavior: 'smooth',
    })
  }, [rowBodyEl])

  const status = useMemo(() => {
    return isSuccess?.(left) ?? getRowStatus?.(left)
  }, [left, isSuccess, getRowStatus])

  return (
    <>
      <TableRow highlight={expanded} expanded={expanded} onToggle={toggleRow}>
        {selectedColumns.map((column, index) => (
          <TableData
            key={column.id}
            type="value"
            column={column}
            index={index}
            level={level}
            customStyles={column.customStyles}
            rowIcon={
              <TableRowIcon
                name={getRowIcon(status)}
                bordered={view === 'list'}
                status={status}
              />
            }
          >
            {column.renderCell(left, right)}
          </TableData>
        ))}
      </TableRow>
      {expanded && (
        <ValueRowBody ref={setRowBodyEl}>
          <StyledBoundary
            content="
              An error has occurred, click retry to try again. If the problem persists, please contact support."
            view={ErrorBoundaryLocalView.BreakdownRow}
          >
            <RowBodyComponent analysis={analysis} value={left} {...params} />
          </StyledBoundary>
        </ValueRowBody>
      )}
    </>
  )
}
