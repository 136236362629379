import { useCallback } from 'react'
import { useUsersReadWriteProjectIds } from 'data/usePermissions'

export const useIsProjectReadOnlyById = () => {
  const projectIdsWithReadWriteAccess = useUsersReadWriteProjectIds()

  const getIsProjectReadOnly = useCallback(
    (projectId: number) =>
      !projectIdsWithReadWriteAccess.find((id) => id === projectId),
    [projectIdsWithReadWriteAccess]
  )

  return {
    projectIdsWithReadWriteAccess,
    getIsProjectReadOnly,
  }
}
