import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDatasource } from '../datasourceContext'
import { toOrgTokensQueryKey } from './queryKeys'
import { showAlert } from '../utils/showAlert'
import { useAppContext } from '../appContext'

export function useDeleteOrganizationToken() {
  const queryClient = useQueryClient()
  const { orgId } = useAppContext()
  const { ds } = useDatasource()

  return useMutation({
    mutationFn: (token: string) => ds.deleteOrganizationToken(token),
    async onSuccess() {
      showAlert('Grafana stack API token removed!', 'success')
      await queryClient.invalidateQueries(toOrgTokensQueryKey(orgId))
    },
    onError() {
      showAlert('Failed to delete Grafana stack API token.', 'error')
    },
  })
}
