import React from 'react'

import { WebVitalFID } from 'types/insights/webVitals'
import { WEB_VITAL_CONFIG, WEB_VITALS_THRESHOLDS } from 'constants/webVitals'

import { InsightListItem } from '../InsightItem'
import { SkippedDescription } from '../SkippedDescription'
import { InsightBody } from '../InsightBody'
import { docs, ReadMore } from 'components/DocLink'

const minThreshold = WEB_VITALS_THRESHOLDS.web_vital_fid[0].toFixed(0)
const maxThreshold = WEB_VITALS_THRESHOLDS.web_vital_fid[1].toFixed(0)

interface WebVitalFIDItemProps {
  insight: WebVitalFID
}

const WebVitalFIDDescription = ({ insight }: WebVitalFIDItemProps) => {
  switch (insight.status) {
    case 'PASSED':
      return (
        <>
          First input delay was within the {minThreshold}{' '}
          {WEB_VITAL_CONFIG.web_vital_fid.unit} threshold.{' '}
          <ReadMore article={docs.insights.webVital.fid} />
        </>
      )

    case 'WARNING':
      return (
        <>
          Exceeded the {minThreshold} {WEB_VITAL_CONFIG.web_vital_fid.unit}{' '}
          threshold.
        </>
      )

    case 'FAILED':
      return (
        <>
          Exceeded the {maxThreshold} {WEB_VITAL_CONFIG.web_vital_fid.unit}{' '}
          threshold.
        </>
      )

    default:
      return <SkippedDescription insight={insight} />
  }
}

export const WebVitalFIDItem = ({ insight }: WebVitalFIDItemProps) => {
  return (
    <InsightListItem header="First Input Delay" insight={insight}>
      <WebVitalFIDDescription insight={insight} />
    </InsightListItem>
  )
}

export const WebVitalFIDContent = () => {
  return (
    <InsightBody>
      <p>
        First Input Delay (FID) is a crucial user-centric metric, measuring the
        responsiveness of a web page by quantifying the delay between a user
        {"'"}s first interaction (e.g., clicking a button) and the browser{"'"}s
        response. Improving FID is essential for providing a more responsive and
        interactive user experience.
      </p>
      <h4>Here are several strategies to enhance First Input Delay:</h4>
      <ul>
        <li>
          <strong>Async and Defer Attribute for Scripts:</strong> Use the
          `async` or `defer` attribute when including scripts in your HTML. This
          allows the browser to continue parsing the HTML without blocking,
          resulting in a faster page load.
        </li>
        <li>
          <strong>Optimize JavaScript Execution:</strong> Minimize and optimize
          JavaScript code to reduce execution time. Eliminate unnecessary
          scripts, and consider code splitting to load only the necessary
          JavaScript for the current view.
        </li>
        <li>
          <strong>Optimize Third-Party Scripts:</strong> Limit the use of
          third-party scripts, and if necessary, load them asynchronously.
          Third-party scripts can significantly contribute to FID if they are
          large or cause delays in execution.
        </li>
        <li>
          <strong>Web Workers:</strong> Consider using Web Workers to offload
          heavy JavaScript tasks to a separate thread, preventing them from
          blocking the main thread and improving responsiveness.
        </li>
      </ul>

      <p>
        Implementing these strategies can contribute to a noticeable improvement
        in First Input Delay, creating a more responsive and satisfying user
        experience on your website. Remember that FID is closely tied to the
        user{"'"}s perception of a site{"'"}s interactivity, so ongoing
        optimization efforts are essential.
      </p>
    </InsightBody>
  )
}
