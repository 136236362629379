import React, { useMemo } from 'react'

import { TestRun } from 'types'
import { RunsPageTestIds } from 'types/dataTestIds'
import { Insight, InsightsByStatus } from 'types/insights'
import { InsightFilter } from '../Insights.types'
import { filterInsights } from '../Insights.utils'
import { EmptyInsightList } from './EmptyInsightList'
import { exhaustive } from 'utils/typescript'
import { DataTable } from 'components/DataTable'
import { TableColumn } from 'react-data-table-component'
import { ExpanderComponentProps } from 'react-data-table-component/dist/src/DataTable/types'
import * as BestPractice from './BestPractices'
import * as Http from './HTTP'
import * as Health from './Health'
import * as WebVital from './WebVitals'
import {
  InsightListExpanderIcon,
  InsightListExpanderIconWrapper,
  InsightListWrapper,
} from './InsightList.styles'
import { getWebVitalMetricName } from 'utils/webVitals'

interface InsightRowProps {
  testRun: TestRun
  insight: Insight
}

const InsightRowItem = ({ insight }: InsightRowProps) => {
  /* spell-checker: disable */
  switch (insight.name) {
    case 'http_load_throughput_limit':
      return <Http.ThroughputLimitItem insight={insight} />

    case 'http_load_increased_failure_rate':
      return <Http.IncreasedFailureRateItem insight={insight} />

    case 'http_load_high_failure_rate':
      return <Http.HighFailureRateItem insight={insight} />

    case 'best_practice_too_many_urls':
      return <BestPractice.TooManyUrlsItem insight={insight} />

    case 'best_practice_too_many_groups':
      return <BestPractice.TooManyGroupsItem insight={insight} />

    case 'best_practice_too_many_metrics':
      return <BestPractice.TooManyMetricsItem insight={insight} />

    case 'best_practice_too_many_time_series':
      return <BestPractice.TooManyTimeSeriesItem insight={insight} />

    case 'best_practice_third_party_content':
      return <BestPractice.ThirdPartyContentItem insight={insight} />

    case 'best_practice_outdated_k6_release_used':
      return <BestPractice.OutdatedK6ReleaseUsedItem insight={insight} />

    case 'best_practice_invalid_metric_names':
      return <BestPractice.InvalidMetricNamesItem insight={insight} />

    case 'health_high_loadgen_cpu_usage':
      return <Health.HighLoadGeneratorCPUUsageItem insight={insight} />

    case 'health_high_loadgen_mem_usage':
      return <Health.HighLoadGeneratorMemoryUsageItem insight={insight} />

    case getWebVitalMetricName('cls'):
      return <WebVital.WebVitalCLSItem insight={insight} />

    case getWebVitalMetricName('fcp'):
      return <WebVital.WebVitalFCPItem insight={insight} />

    case getWebVitalMetricName('fid'):
      return <WebVital.WebVitalFIDItem insight={insight} />

    case getWebVitalMetricName('inp'):
      return <WebVital.WebVitalINPItem insight={insight} />

    case getWebVitalMetricName('lcp'):
      return <WebVital.WebVitalLCPItem insight={insight} />

    case getWebVitalMetricName('ttfb'):
      return <WebVital.WebVitalTTFBItem insight={insight} />

    default:
      return exhaustive(insight, <></>)
  }
  /* spell-checker: enable */
}

interface InsightRowBodyProps extends ExpanderComponentProps<InsightRowProps> {}

const InsightRowBody = ({
  data: { testRun, insight },
}: InsightRowBodyProps) => {
  if (insight.status !== 'FAILED' && insight.status !== 'WARNING') {
    return null
  }

  switch (insight.name) {
    case 'http_load_throughput_limit':
      return <Http.ThroughputLimit testRun={testRun} insight={insight} />

    case 'http_load_increased_failure_rate':
      return <Http.IncreasedFailureRate testRun={testRun} insight={insight} />

    case 'http_load_high_failure_rate':
      return <Http.HighFailureRate insight={insight} />

    case 'best_practice_too_many_urls':
      return <BestPractice.TooManyUrls />

    case 'best_practice_too_many_groups':
      return <BestPractice.TooManyGroups />

    case 'best_practice_too_many_metrics':
      return <BestPractice.TooManyMetrics />

    case 'best_practice_too_many_time_series':
      return <BestPractice.TooManyTimeSeries insight={insight} />

    case 'best_practice_third_party_content':
      return <BestPractice.ThirdPartyContent insight={insight} />

    case 'best_practice_outdated_k6_release_used':
      return <BestPractice.OutdatedK6ReleaseUsed insight={insight} />

    case 'best_practice_invalid_metric_names':
      return <BestPractice.InvalidMetricNames insight={insight} />

    case 'health_high_loadgen_cpu_usage':
      return (
        <Health.HighLoadGeneratorCPUUsage testRun={testRun} insight={insight} />
      )

    case 'health_high_loadgen_mem_usage':
      return (
        <Health.HighLoadGeneratorMemoryUsage
          testRun={testRun}
          insight={insight}
        />
      )

    case getWebVitalMetricName('cls'):
      return <WebVital.WebVitalCLSContent />

    case getWebVitalMetricName('fcp'):
      return <WebVital.WebVitalFCPContent />

    case getWebVitalMetricName('fid'):
      return <WebVital.WebVitalFIDContent />

    case getWebVitalMetricName('inp'):
      return <WebVital.WebVitalINPContent />

    case getWebVitalMetricName('lcp'):
      return <WebVital.WebVitalLCPContent />

    case getWebVitalMetricName('ttfb'):
      return <WebVital.WebVitalTTFBContent />

    default:
      return exhaustive(insight, <></>)
  }
}

const columns: Array<TableColumn<InsightRowProps>> = [{ cell: InsightRowItem }]

interface InsightsListProps {
  testRun: TestRun
  filter: InsightFilter
  insights: InsightsByStatus
}

export const InsightList = ({
  testRun,
  filter,
  insights,
}: InsightsListProps) => {
  const filteredInsights = useMemo(
    () =>
      filterInsights(insights, filter).map((insight) => ({ testRun, insight })),
    [testRun, insights, filter]
  )

  if (filteredInsights.length === 0) {
    return (
      <EmptyInsightList testRun={testRun} filter={filter} insights={insights} />
    )
  }

  return (
    <InsightListWrapper>
      <DataTable
        columns={columns}
        data={filteredInsights}
        data-testid={RunsPageTestIds.InsightsTable}
        noHeader
        noTableHead
        highlightOnHover
        conditionalRowStyles={[
          {
            when: (row) => row.insight.status === 'WARNING',
            classNames: ['needs-improvement'],
          },
          {
            when: (row) => row.insight.status === 'FAILED',
            classNames: ['failed'],
          },
        ]}
        expandOnRowClicked
        expandableRows
        expandableIcon={{
          collapsed: (
            <InsightListExpanderIconWrapper>
              <InsightListExpanderIcon name="angle-down" />
            </InsightListExpanderIconWrapper>
          ),
          expanded: (
            <InsightListExpanderIconWrapper>
              <InsightListExpanderIcon name="angle-up" />
            </InsightListExpanderIconWrapper>
          ),
        }}
        expandableRowsComponent={InsightRowBody}
        expandableRowDisabled={(props) =>
          !['FAILED', 'WARNING'].includes(props.insight.status)
        }
      />
    </InsightListWrapper>
  )
}
