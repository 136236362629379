import React, { CSSProperties, useState } from 'react'
import { usePopper } from 'react-popper'
import { Icon, Spinner } from '@grafana/ui'
import styled from 'styled-components'

import type { Trend, TrendDataPoint } from 'types'

import { isDataPointCalculating } from '../../utils'
import { spacing } from 'utils/styled'
import {
  useGetContrastText,
  useGetVisualizationColor,
  useTooltipConfig,
} from './ValueTooltip.hooks'
import { usePrivateLoadZoneIsHanging } from 'components/TestRunPendingState/TestRunPendingState.hooks'
import { LoadZoneTestHangingWarning } from 'components/LoadZoneTestHangingWarning'
import { Flex } from 'components/Flex'

import {
  RowLabel,
  RowValue,
  RunStatus,
  StyledValueRow,
  ValueTooltipStyled,
} from './ValueTooltip.styled'

interface ValueTooltipProps {
  valueElement: HTMLElement | null
  trend: Trend
  dataPoint: TrendDataPoint
  show: boolean
  titleColor: CSSProperties['backgroundColor']
}

export function ValueTooltip({
  trend,
  dataPoint,
  valueElement,
  titleColor = 'transparent',
  show = false,
}: ValueTooltipProps) {
  const isHanging = usePrivateLoadZoneIsHanging(dataPoint.run)
  const getVisualizationColor = useGetVisualizationColor()
  const getContrastText = useGetContrastText()
  const config = useTooltipConfig(trend, dataPoint)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(valueElement, popperElement, {
    placement: 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  })

  const backgroundColor = getVisualizationColor(titleColor)
  const color = getContrastText(backgroundColor)
  const isCalculating = isDataPointCalculating(dataPoint)

  return (
    <ValueTooltipStyled
      data-testid={`value-tooltip`}
      // data-show={show}
      data-show={true}
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
    >
      <RunStatus style={{ backgroundColor, color }}>
        <Flex align="center" gap={0.5}>
          {isHanging && <Icon name="exclamation-triangle" aria-hidden />}
          <div>{config.status}</div>
        </Flex>
      </RunStatus>
      {isHanging && (
        <StatusInfo>
          <LoadZoneTestHangingWarning shortMessage showIcon={false} />
        </StatusInfo>
      )}
      <ValueRow label="Started" value={config.started} />
      <ValueRow label="Metric" value={config.metric} />
      <ValueRow label="Aggregation" value={config.aggregation} />
      <ValueRow label="Value" value={config.value} loading={isCalculating} />
      <ValueRow label="VUs" value={config.vus} />
      <ValueRow label="Duration" value={config.executionDuration} />
    </ValueTooltipStyled>
  )
}

interface ValueRowProps {
  label: string
  value: string | number
  loading?: boolean
}

function ValueRow({ label, value, loading = false }: ValueRowProps) {
  return (
    <StyledValueRow>
      <RowLabel>{label}:</RowLabel>
      <RowValue>{loading ? <Spinner /> : value}</RowValue>
    </StyledValueRow>
  )
}

const StatusInfo = styled.div`
  margin-bottom: ${spacing(1)};
`
