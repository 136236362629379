import styled from 'styled-components'

import { spacing } from 'utils/styled'
import { Flex } from 'components/Flex'

export const Container = styled(Flex)<{ isMinimal?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  padding-bottom: ${({ padding = 0, isMinimal }) =>
    !isMinimal ? spacing(4) : spacing(padding)};
  width: 100%;
`

export const Label = styled.span<{ visible?: boolean }>`
  white-space: nowrap;
  font-size: 10px;
  opacity: ${({ visible = true }) => (visible ? 1 : 0)};
`

export const HorizontalLine = styled.hr`
  margin: 0;
  width: 100%;
`
