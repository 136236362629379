import React from 'react'
import { useHistory } from 'react-router-dom'
import { TableColumn } from 'react-data-table-component'
import { useIsUserOrgAdmin } from 'data/usePermissions'
import { routeMap } from 'routeMap'
import { BertMessage } from 'components/BertMessage'
import { DATA_TABLE_INITIAL_HEIGHT, DataTable } from 'components/DataTable'
import { LoadingContainer } from 'components/LoadingContainer'
import { NotificationsTableRowActions } from './NotificationsTableRowActions'
import { NotificationChannel } from '../../NotificationsTab.types'
import { EventsTooltip } from './EventsTooltip'
import { NotificationName } from './NotificationName'
import { NotificationsTableZeroState } from './NotificationsTableZeroState'
import { useNotifications } from '../../hooks/useNotifications'

const getColumns = (onUpdateNotification: (id: number) => void) => {
  const columns: Array<TableColumn<NotificationChannel>> = [
    {
      name: 'name',
      sortField: 'name',
      maxWidth: '300px',
      cell: (row) => <NotificationName notification={row} />,
    },
    {
      name: 'events',
      cell: (row) => <EventsTooltip events={row.events} />,
    },
    {
      name: '',
      right: true,
      width: '80px',
      cell: (row) => (
        <NotificationsTableRowActions
          notification={row}
          handleUpdateNotification={onUpdateNotification}
        />
      ),
    },
  ]

  return columns
}

export const NotificationsTable = () => {
  const isOrgAdmin = useIsUserOrgAdmin()
  const { data = [], isLoading } = useNotifications(isOrgAdmin)

  const history = useHistory()

  const handleEditNotification = (id: number) =>
    history.push(routeMap.editNotification(id))

  if (!isOrgAdmin) {
    return (
      <BertMessage description="You don't have permissions to view or setup new notifications" />
    )
  }

  if (data.length === 0 && !isLoading) {
    return <NotificationsTableZeroState />
  }

  return (
    <LoadingContainer
      loading={isLoading}
      style={{ minHeight: DATA_TABLE_INITIAL_HEIGHT }}
    >
      <DataTable
        highlightOnHover
        isFetching={isLoading}
        columns={getColumns(handleEditNotification)}
        data={data}
        noDataComponent={null}
        fixedHeader
      />
    </LoadingContainer>
  )
}
