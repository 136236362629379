import React from 'react'

import { useIsLegacyUser } from 'hooks/useIsLegacyUser'
import { useOnboarding } from 'hooks/useOnboarding'

import { WelcomeLegacyUser } from './WelcomeLegacyUser'
import { WelcomeNewUser } from './WelcomeNewUser'

export function WelcomeModal() {
  const [stage, setStage] = useOnboarding()
  const isLegacyUser = useIsLegacyUser()
  const isOpen = stage === 'none'

  if (isLegacyUser) {
    return <WelcomeLegacyUser isOpen={isOpen} onChange={setStage} />
  }

  return <WelcomeNewUser isOpen={isOpen} onChange={setStage} />
}
