import React, { HTMLAttributes } from 'react'
import { BreakdownTabMessage } from './Breakdown.styles'
import { exhaustive } from 'utils/typescript'

type DataUnavailableEntity = 'logs' | 'script'

function getTypeText(tab: DataUnavailableEntity) {
  switch (tab) {
    case 'logs':
      return 'Logs'

    case 'script':
      return 'Scripts'

    default:
      return exhaustive(tab)
  }
}

interface LocallyExecutedMessageProps extends HTMLAttributes<HTMLDivElement> {
  type: DataUnavailableEntity
}

export function LocallyExecutedMessage({
  type,
  ...props
}: LocallyExecutedMessageProps) {
  return (
    <BreakdownTabMessage {...props}>
      <p>
        {getTypeText(type)} are not available in the cloud when the test has
        been executed locally with <code>k6 run -o cloud</code>.
      </p>
    </BreakdownTabMessage>
  )
}
