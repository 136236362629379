import styled from 'styled-components'
import promoBackground from 'assets/promoBackground.svg'
import { Section } from '../components/Section'
import { spacing } from 'utils/styled'

export const GradientSectionStyled = styled(Section)`
  background-image: url('${promoBackground}');
  background-size: cover;
  overflow: hidden;
  border: none;
  color: #fff;

  & [class$='-PromoIcon'],
  & .gradient-step,
  & h3,
  & h5 {
    color: #fff;
  }

  & a {
    color: #fff;
    margin-top: ${spacing(2)};
  }
`
GradientSectionStyled.PromoItems = Section.PromoItems
