import React from 'react'

import { useGrafanaSubscriptionUrl } from 'hooks/useGrafanaSubscriptionUrl'
import { useTrackAction } from '../services/tracking'
import { useCurrentProject } from '../projectContext'
import { LinkButton } from './LinkButton'

import {
  CTAUpgradeReason,
  TrackingActionType,
  TrackingEventName,
} from 'services/tracking.types'

interface UpgradeSubscriptionButtonProps {
  reason: CTAUpgradeReason
}

export function UpgradeSubscriptionButton({
  reason,
}: UpgradeSubscriptionButtonProps) {
  const project = useCurrentProject()
  const trackAction = useTrackAction()

  const upgradeLink = useGrafanaSubscriptionUrl()

  const handleCtaPress = () => {
    trackAction({
      eventName: TrackingEventName.CTAUpgrade,
      action: TrackingActionType.Click,
      projectId: project.id,
      reason,
    })
  }

  return (
    <LinkButton
      external
      to={upgradeLink}
      target="_blank"
      button={{ onClick: handleCtaPress }}
    >
      Upgrade
    </LinkButton>
  )
}
