import styled from 'styled-components'
import { Button } from '@grafana/ui'
import { color, fontSize, spacing } from 'utils/styled'

export const EditNoteButton = styled(Button)`
  // Override both default and the on hover background colors
  background-color: transparent !important;
  font-size: 14px;
  height: 15px;
  font-weight: 400;
  line-height: unset;
  padding: 0;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.shade};
  }

  svg {
    margin-right: 5px;
  }
`

export const ErrorText = styled.p`
  font-size: ${fontSize('bodySmall')};
  margin-bottom: 0;
`

export const ErrorTitle = styled.span`
  color: ${color('error', 'text')};
`

export const ErrorDetail = styled.span`
  color: ${color('text', 'secondary')};
`

export const RefetchIconContainer = styled.span`
  button {
    margin-left: ${spacing(0.5)};
    position: relative;
    top: 3px;
  }
`

export const Body = styled.div`
  padding: ${spacing(2, 0)};
`
