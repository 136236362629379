import React from 'react'

import { TestRun, TestRunTracesFilter } from 'types'

import { RunDetailsFilters } from '../../Filters/Filters'
import { useURLFilters } from '../../Filters/Filters.hooks'

interface TracesFiltersProps
  extends ReturnType<typeof useURLFilters<TestRunTracesFilter>> {
  run: TestRun
}

export function TracesFilters({
  addFilterValue,
  addNewFilter,
  filters,
  options,
  removeFilter,
  run,
  replaceFilters,
}: TracesFiltersProps) {
  function clearOptionalFilters() {
    replaceFilters(filters.filter((filter) => filter.required))
  }

  return (
    <RunDetailsFilters
      filters={filters}
      options={options}
      run={run}
      onAddNewFilterClick={addNewFilter}
      onRemoveFilterClick={removeFilter}
      onAddFilterValue={addFilterValue}
      onClearFiltersClick={clearOptionalFilters}
    />
  )
}
