import * as monacoType from 'monaco-editor'
import { useEffect, useState } from 'react'
import { useMonaco } from 'components/GrafanaMonaco'

type DiffEditor = monacoType.editor.IStandaloneDiffEditor
type DiffNavigator = monacoType.editor.IDiffNavigator

export function useDiffEditor(mount: HTMLElement | null) {
  const monaco = useMonaco()
  const [editor, setEditor] = useState<DiffEditor | null>(null)

  useEffect(() => {
    if (mount === null || monaco === null) {
      return
    }

    setEditor(
      monaco.editor.createDiffEditor(mount, {
        readOnly: true,
        contextmenu: false,
        lineNumbers: 'on',
        renderValidationDecorations: 'on',
        automaticLayout: true,
      })
    )
  }, [mount, monaco])

  useEffect(() => {
    return () => editor?.dispose()
  }, [editor])

  return editor
}

export function useDiffNavigator(editor: DiffEditor | null) {
  const monaco = useMonaco()
  const [navigator, setNavigator] = useState<DiffNavigator | null>(null)

  useEffect(() => {
    if (editor === null || monaco === null) {
      return
    }

    setNavigator(
      monaco.editor.createDiffNavigator(editor, {
        followsCaret: true,
        ignoreCharChanges: true,
        alwaysRevealFirst: true,
      })
    )
  }, [editor, monaco])

  useEffect(() => {
    return () => navigator?.dispose()
  }, [navigator])

  return navigator
}
