import { useQuery } from '@tanstack/react-query'
import { useDatasource } from '../datasourceContext'
import { toProvisioningStateQueryKey } from './queryKeys'
import { isLockedDown } from './queryClient'

/**
 * Returns whether the grafana instance has been initialized (provisioned) in k6 cloud or not
 *
 * @return {undefined|boolean}
 *  - `undefined` means that we are fetching
 *  - `boolean` means that we have a definitive value
 */
export function useProvisioningState() {
  const { ds } = useDatasource()

  return useQuery({
    queryKey: toProvisioningStateQueryKey(),
    queryFn: async () => ds.fetchGrafanaAppInitialized(),
    staleTime: Infinity,
    useErrorBoundary: isLockedDown,
    meta: {
      alertOnError: false,
    },
  })
}
