import React from 'react'
import { Value } from './Value'
import { ProjectSettings } from 'types'
import { useSubscriptionRule } from 'data/useSubscriptions'
import { vusFormatter } from 'utils/formatters'

interface VUMaxPerTestValueProps {
  limit?: ProjectSettings['vu_max_per_test']
}

export function VUMaxPerTestValue({ limit }: VUMaxPerTestValueProps) {
  const subscriptionVUMaxPerTest = useSubscriptionRule('tests.users.max')
  const showWarning = !!limit && limit > subscriptionVUMaxPerTest
  const tooltip = showWarning ? (
    <>
      This limit is higher than what your active subscription allows.
      <br />
      The subscription limit ({vusFormatter(subscriptionVUMaxPerTest)}) will
      take precedence.
    </>
  ) : undefined

  return (
    <Value showWarning={showWarning} tooltip={tooltip}>
      {limit ?? '-'}
    </Value>
  )
}
