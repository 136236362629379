import React, { forwardRef, useMemo } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Link } from 'react-router-dom'
import { colorManipulator } from '@grafana/data'

import { color as styledColor } from 'utils/styled'
import { getBarSize } from './ProgressBar.utils'
const { lighten, alpha } = colorManipulator

type Direction = 'vertical' | 'horizontal'

interface ProgressBarPropsBase
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  color: string
  direction: Direction
  isWorking: boolean
  max: number
  to?: string
  value: number | null
  height?: string
  width?: string
}

interface ProgressBarPropsVertical extends ProgressBarPropsBase {
  direction: 'vertical'
  width?: ProgressBarPropsBase['width']
  height?: undefined
}

interface ProgressBarPropsHorizontal extends ProgressBarPropsBase {
  direction: 'horizontal'
  height: ProgressBarPropsBase['height']
  width?: undefined
}

type ProgressBarProps = ProgressBarPropsVertical | ProgressBarPropsHorizontal

export const ProgressBar = forwardRef<HTMLAnchorElement, ProgressBarProps>(
  function ProgressBar(
    { color, direction, height, max, isWorking, to, value, width, ...props },
    ref
  ) {
    const styles = useMemo(() => {
      const barSize = getBarSize(value ?? 0, max)

      if (direction === 'vertical') {
        return {
          width,
          height: barSize,
        }
      }

      return {
        height,
        width: barSize,
      }
    }, [direction, height, max, value, width])

    const Component = to ? Link : 'div'

    return (
      <Bar
        as={Component}
        to={to || ``}
        ref={ref}
        {...props}
        $color={color}
        $interactive={!!to}
      >
        <BarValue style={styles}>
          {isWorking && <WorkingAnimation $direction={direction} />}
        </BarValue>
      </Bar>
    )
  }
)

export const BarValue = styled.div`
  position: relative;
  min-height: 2px;
  transition: height 0.3s ease-in-out, background-color 0.2s ease-out;
  transition-property: height, width, background-color;
  overflow: hidden;
  border-radius: inherit;
`

export const Bar = styled(Link)<{ $color: string; $interactive: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: background-color 0.2s ease-out;
  background-color: ${styledColor('background', 'secondary')};
  overflow: hidden;
  border-radius: 2px;

  ${BarValue} {
    ${({ $color, theme }) => {
      const color = theme.visualization.getColorByName($color)
      if (!color) {
        return
      }
      return css`
        background-color: ${color};
      `
    }}
  }

  ${({ $color, $interactive, theme }) =>
    $interactive &&
    css`
      &:hover,
      &:focus-visible {
        background-color: ${() =>
          theme.isLight
            ? alpha(theme.colors.background.secondary, 0.6)
            : lighten(theme.colors.background.secondary, 0.1)};

        ${BarValue} {
          ${() =>
            !!$color &&
            $color !== 'transparent' &&
            css`
              background-color: ${lighten(
                theme.visualization.getColorByName($color),
                0.4
              )};
            `}
        }
      }
    `}
`

const pendingAnimationVertical = keyframes`
  0% {
    top: 110%;
  }
  100% {
    top: -210%;
  }
`

const pendingAnimationHorizontal = keyframes`
  0% {
    transform: translateX(-110%);
  }
  100% {
    transform: translateX(210%);
  }
`

export const EmptyBar = styled.div`
  background-color: transparent;
  height: 100%;
  width: 20px;
`

export const WorkingAnimation = styled.div<{ $direction: Direction }>`
  --barBorder: 1px solid rgba(255, 255, 255, 0.5);
  --workingStartingColor: rgba(255, 255, 255, 0);
  --workingFinishingColor: rgba(255, 255, 255, 0.245);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    display: block;
    filter: blur(50%);
  }

  ${({ $direction }) =>
    $direction === 'vertical' &&
    css`
      &::after {
        animation: ${pendingAnimationVertical} 2.5s infinite ease-in-out;
        background: linear-gradient(
          0deg,
          var(--workingStartingColor) 0%,
          var(--workingFinishingColor) 100%
        );
        border-top: var(--barBorder);
        height: 200%;
        width: 100%;
      }
    `}

  ${({ $direction }) =>
    $direction === 'horizontal' &&
    css`
      &::after {
        animation: ${pendingAnimationHorizontal} 2.5s infinite ease-in-out;
        background: linear-gradient(
          90deg,
          var(--workingStartingColor) 0%,
          var(--workingFinishingColor) 100%
        );
        border-right: var(--barBorder);
        height: 100%;
        width: 200%;
      }
    `}
`
