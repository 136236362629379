import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { UnregisterCallback } from 'history'

interface UseConfirmPromptArgs {
  show: boolean
  message?: string
}

export const DEFAULT_LEAVE_MESSAGE =
  'Are you sure you want to leave without saving your changes?'

const onWindowOrTabClose =
  (when: boolean, message: string) => (event: BeforeUnloadEvent) => {
    if (!when) {
      return
    }

    if (typeof event === 'undefined') {
      event = window.event as BeforeUnloadEvent
    }

    event.preventDefault()
    event.returnValue = message
    return event
  }

export const useConfirmPromptOnLeave = ({
  show,
  message = DEFAULT_LEAVE_MESSAGE,
}: UseConfirmPromptArgs) => {
  const history = useHistory()
  const unblockNavCallbacksRef = useRef<UnregisterCallback>()

  useEffect(() => {
    const beforeUnloadCallback = onWindowOrTabClose(show, message)
    window.addEventListener('beforeunload', beforeUnloadCallback)

    if (show) {
      unblockNavCallbacksRef.current = history.block((_, action) => {
        // Don't show prompt twice for redirects
        if (action === 'REPLACE') {
          return
        }

        return message
      })
    }

    return () => {
      if (unblockNavCallbacksRef.current) {
        unblockNavCallbacksRef.current()
        unblockNavCallbacksRef.current = undefined
      }

      window.removeEventListener('beforeunload', beforeUnloadCallback)
    }
  }, [message, show, history])
}
