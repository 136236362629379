import {
  tuple,
  object,
  unknown,
  literal,
  type infer as Infer,
  string,
} from 'zod'
import { json } from './primitives'
import { TestNameSchema } from './testName'
import { OptionsSchema } from './options'

const TestBuilderErrorTypeSchema = object({
  message: string(),
})

export const TestBuilderErrorSchema = object({
  type: literal('test-builder-error'),
  details: TestBuilderErrorTypeSchema,
})

export type TestBuilderError = Infer<typeof TestBuilderErrorSchema>

const TestBuilderConfigSchema = tuple([
  object({
    log: object({
      options: OptionsSchema,
    }),
  }),
]).rest(unknown())

export type TestBuilderConfig = Infer<typeof TestBuilderConfigSchema>

export const TestBuilderDraftSchema = object({
  type: literal('builder'),
  name: TestNameSchema,
  error: TestBuilderErrorTypeSchema.optional(),
  request_builder_config: json(TestBuilderConfigSchema),
})
