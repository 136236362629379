import React from 'react'
import { Tooltip, Button } from '@grafana/ui'

import { useHasUserProjectWriteAccess } from 'data/usePermissions'
import { LinkButton } from './LinkButton'
import { routeMap } from 'routeMap'
import { useCurrentProject } from 'projectContext'

interface CreateTestButtonProps {
  label?: string
}

export const CreateTestButton = ({
  label = 'Create new test',
}: CreateTestButtonProps) => {
  const project = useCurrentProject()
  const hasUserProjectWriteAccess = useHasUserProjectWriteAccess()

  if (hasUserProjectWriteAccess) {
    return <LinkButton to={routeMap.newTest(project.id)}>{label}</LinkButton>
  }

  return (
    <Tooltip content="You don't have permission to create new tests in this project">
      <span>
        <Button disabled>{label}</Button>
      </span>
    </Tooltip>
  )
}
