import React, { Fragment, PropsWithChildren, useMemo } from 'react'

import { LoadZoneDistribution } from 'types/loadZone'
import { DocLink, docs } from 'components/DocLink'
import { Flex } from 'components/Flex'
import { Tooltip } from 'components/Tooltip'
import { LoadZoneContainer, Title, Label } from './LoadZoneTooltip.styles'
import { getColumnCount } from './LoadZoneTooltip.utils'

interface LoadZoneTooltipProps {
  loadZones: LoadZoneDistribution[]
  showNodes: boolean
}

const LoadZoneTooltipContent = ({
  loadZones,
  showNodes,
}: LoadZoneTooltipProps) => {
  const hasPrivate = loadZones.some((lz) => lz.isPublic === false)
  const columns = useMemo(
    () => getColumnCount(hasPrivate, showNodes),
    [hasPrivate, showNodes]
  )

  return (
    <>
      <LoadZoneContainer $gridColumns={columns}>
        <Title>Load zone</Title>
        {showNodes && <Title>Public IP</Title>}
        {showNodes && <Title>Size</Title>}
        {hasPrivate && <Title>Private</Title>}

        {loadZones.map((zone) => (
          <Fragment key={zone.id}>
            <Label loadZone={zone} showLabel showPercentage />
            {showNodes && <span>{zone.publicIp || '-'}</span>}
            {showNodes && <span>{zone.size || '-'}</span>}
            {hasPrivate && <span>{zone.isPublic ? '-' : 'Yes'}</span>}
          </Fragment>
        ))}
      </LoadZoneContainer>

      <Flex justify="right">
        <DocLink article={docs.loadZones.main}>Need help?</DocLink>
      </Flex>
    </>
  )
}

export const LoadZoneTooltip = ({
  children,
  loadZones,
  showNodes,
}: PropsWithChildren<LoadZoneTooltipProps>) => {
  return (
    <Tooltip
      interactive
      content={
        <LoadZoneTooltipContent loadZones={loadZones} showNodes={showNodes} />
      }
      placement="bottom"
      show={!!loadZones.length}
    >
      <div>{children}</div>
    </Tooltip>
  )
}
