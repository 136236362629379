import React from 'react'

import { useCanUserScheduleTests } from 'data/usePermissions'
import { Section } from 'components/Section'
import { DocLink, docs } from 'components/DocLink'

import { useScheduledTests } from 'components/ScheduledTests/ScheduledTests.hooks'
import { CreateScheduleButton } from './components/CreateScheduleButton'
import { ScheduledTestsTable } from './components'

export const ScheduledTestsTab = () => {
  const canSchedule = useCanUserScheduleTests()
  const { data, isLoading } = useScheduledTests()

  return (
    <Section
      title="Scheduled tests"
      description={
        <>
          You can schedule tests to run at fixed times and regular intervals.
          Read more about scheduled tests in the{' '}
          <DocLink article={docs.manage.scheduledTests}>docs</DocLink>.
        </>
      }
      actions={canSchedule && <CreateScheduleButton disabled={isLoading} />}
    >
      <ScheduledTestsTable data={data} isLoading={isLoading} />
    </Section>
  )
}
