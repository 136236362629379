import React from 'react'

import { useIsUserOrgAdmin } from 'data/usePermissions'
import { useFreeVUhsUsageReport } from 'data/useUsageReports'

import { useGrafanaSubscriptionUrl } from 'hooks/useGrafanaSubscriptionUrl'
import { Tooltip } from '../Tooltip'
import { LinkIcon, StyledExternalLink } from './VUhConsumption.styles'
import { VUhConsumptionMeter } from './VUhConsumptionMeter'

export const TotalVUhConsumption = () => {
  const isUserOrgAdmin = useIsUserOrgAdmin()
  const url = useGrafanaSubscriptionUrl()

  const freeVUhsUsageReport = useFreeVUhsUsageReport()

  if (!freeVUhsUsageReport) {
    return null
  }

  const upgradeButton = (
    <Tooltip
      show={!isUserOrgAdmin}
      content="Contact your admin to upgrade plan"
    >
      <span>
        <StyledExternalLink url={url} disabled={!isUserOrgAdmin}>
          Upgrade
          <LinkIcon
            $disabled={!isUserOrgAdmin}
            aria-hidden
            name="arrow-right"
          />
        </StyledExternalLink>
      </span>
    </Tooltip>
  )

  const vuhsUsage = freeVUhsUsageReport.vuhsUsage
  const maxVUhs = freeVUhsUsageReport.maxVUhs

  return (
    <VUhConsumptionMeter
      value={vuhsUsage}
      limit={maxVUhs}
      label="total VUh"
      action={upgradeButton}
    />
  )
}
