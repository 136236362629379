import React, { ButtonHTMLAttributes, MouseEvent, useState } from 'react'
import styled, { css } from 'styled-components'
import { IconButton, IconSize } from '@grafana/ui'

import { useTimeout } from 'usehooks-ts'
import { copyToClipboard } from 'utils/clipboard'

export interface ClipboardIconButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  actionText?: string
  activeText?: string
  data: string
  hasAlert?: boolean
  placement?: 'top' | 'bottom'
  size?: IconSize
}

interface IconButtonStyledProps {
  $active: boolean
}

const IconButtonStyled = styled(IconButton)<IconButtonStyledProps>`
  ${({ $active }) =>
    $active &&
    css`
      color: ${({ theme }) => theme.colors.success.text};
    `}
`

export const ClipboardIconButton = ({
  actionText = `Copy to clipboard`,
  activeText = `Copied!`,
  data,
  hasAlert = true,
  size,
  ...props
}: ClipboardIconButtonProps) => {
  const [active, setActive] = useState(false)

  function handleCopy(ev: MouseEvent) {
    ev.preventDefault()
    ev.stopPropagation()

    copyToClipboard(data)

    hasAlert && setActive(true)
  }

  function handleRemoveAlert() {
    setActive(false)
  }

  useTimeout(handleRemoveAlert, active ? 3000 : null)

  return (
    <IconButtonStyled
      {...props}
      aria-label={props['aria-label'] || `Copy`}
      name={active ? 'check' : 'clipboard-alt'}
      onClick={handleCopy}
      size={size}
      $active={active}
      // set a key to force re-rendering of the tooltip due to positioning bug in grafana/ui
      tooltip={
        <div key={active ? `active` : `inactive`}>
          {active ? activeText : actionText}
        </div>
      }
    />
  )
}
