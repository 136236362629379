import {
  RefinementCtx,
  ZodIssueCode,
  number,
  object,
  record,
  string,
  type infer as Infer,
} from 'zod'
import { InvalidDistributionTotal } from './errors'

type LoadZoneDistribution = Infer<typeof LoadZoneDistributionSchema>

function hasTotalOf100Percent(
  distribution: Record<string, LoadZoneDistribution>,
  context: RefinementCtx
) {
  const total = Object.values(distribution).reduce(
    (sum, { percent }) => sum + percent,
    0
  )

  if (total === 100) {
    return
  }

  const params: InvalidDistributionTotal = {
    type: 'invalid-distribution-total',
    path: context.path,
  }

  context.addIssue({
    code: ZodIssueCode.custom,
    params,
  })
}

const LoadZoneDistributionSchema = object({
  loadZone: string(),
  percent: number().int().gt(0).lte(100),
})

export const CloudOptionsSchema = object({
  distribution: record(LoadZoneDistributionSchema)
    .superRefine(hasTotalOf100Percent)
    .optional(),
})

/**
 * @deprecated This is the old schema, use `CloudOptionsSchema` directly instead.
 */
export const ExtOptionsSchema = object({
  loadimpact: CloudOptionsSchema.optional(),
})
