import styled from 'styled-components'

import { spacing } from 'utils/styled'

export const ButtonWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  gap: ${spacing(2)};
  overflow: visible !important;

  & > button {
    margin: 0;
  }
`

export const Strong = styled.strong`
  word-break: break-word;
`
