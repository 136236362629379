import { isBoolean, isNumber } from 'lodash-es'

import type {
  InsightAuditResult,
  InsightCategoryResult,
  InsightExecutionResult,
  InsightBinaryScore,
  InsightNumericScore,
  InsightScore,
  InsightScoreStatus,
} from 'types/cloudInsights'
import { InsightNumericScoreThreshold } from 'constants/insights'

export const getBinaryScoreStatus = (value?: boolean): InsightScoreStatus => {
  if (!isBoolean(value)) {
    return 'skipped'
  }

  return !!value ? 'good' : 'poor'
}

export const getNumericScoreStatus = (
  value?: number | null
): InsightScoreStatus => {
  if (!isNumber(value)) {
    return 'skipped'
  }

  if (value >= InsightNumericScoreThreshold.PASS) {
    return 'good'
  }

  if (value >= InsightNumericScoreThreshold.WARN) {
    return 'needs-improvement'
  }

  return 'poor'
}

export const getScoreStatus = (score?: InsightScore): InsightScoreStatus => {
  if (isBinaryScore(score)) {
    return getBinaryScoreStatus(score.value)
  }

  if (isNumericScore(score)) {
    return getNumericScoreStatus(score.value)
  }

  return 'skipped'
}

export const isBinaryScore = (
  score?: InsightScore
): score is InsightBinaryScore => score?.type === 'binary'

export const isNumericScore = (
  score?: InsightScore
): score is InsightNumericScore => score?.type === 'numeric'

export function isInsightExecuting(
  entity?: InsightExecutionResult | InsightCategoryResult | InsightAuditResult
) {
  return entity?.status === 'executing'
}

export function isInsightFailed(
  entity?: InsightExecutionResult | InsightCategoryResult | InsightAuditResult
) {
  return entity?.status === 'failed'
}

export function isInsightSucceeded(
  entity?: InsightExecutionResult | InsightCategoryResult | InsightAuditResult
) {
  return entity?.status === 'succeeded'
}
