import React from 'react'
import styled from 'styled-components'

import { type InsightAudit, type InsightAuditResult } from 'types/cloudInsights'
import { spacing } from 'utils/styled'
import { MarkdownRenderer } from 'components/MarkdownRenderer'

import { INITIAL_HEIGHT } from './constants'

interface ContentProps {
  audit: InsightAudit
  result?: InsightAuditResult
}

export const Content = ({ result }: ContentProps) => {
  const hasExplanation = !!result?.explanation

  return (
    <ContentContainer>
      {hasExplanation && (
        <>
          <Explanation data={result.explanation} />
          <hr />
        </>
      )}
      <Actions data={result?.actions} />
    </ContentContainer>
  )
}

const ContentContainer = styled.div`
  padding: ${spacing(2, 2, 2, 7)};
  min-height: ${INITIAL_HEIGHT}px;
`

interface ExplanationProps {
  data: InsightAuditResult['explanation']
}

const Explanation = ({ data }: ExplanationProps) => {
  return (
    <ExplanationContainer>
      <Title>Explanation</Title>
      <MarkdownRenderer markdown={data} />
    </ExplanationContainer>
  )
}

const ExplanationContainer = styled.div`
  em {
    color: inherit;
  }

  ol,
  ul {
    padding-left: ${spacing(2)};
    margin-bottom: ${spacing(2)};
  }
`

interface ActionsProps {
  data?: InsightAuditResult['actions']
}

const Actions = ({ data = [] }: ActionsProps) => {
  const hasActions = data.length > 0

  return (
    <div>
      <Title>Actions</Title>
      <List>
        {!hasActions && <li>No actions needed</li>}

        {hasActions &&
          data.map((item, index) => (
            <li key={index}>
              <MarkdownRenderer markdown={item} />
            </li>
          ))}
      </List>
    </div>
  )
}

const Title = styled.h6`
  text-transform: uppercase;
`

const List = styled.ul`
  padding-left: ${spacing(2)};

  p {
    margin-bottom: 0;
  }
`
