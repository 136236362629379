import React from 'react'
import styled from 'styled-components'
import { Alert, VerticalGroup } from '@grafana/ui'

import { createCLIGuideNavModel } from 'navModels'
import { CLIRunGuide } from 'components/CLIRunGuide'
import { spacing } from 'utils/styled'
import { PluginOrganizationWidePage } from 'components/PluginPage'
import { useUsersReadWriteProjectIds } from 'data/usePermissions'
import { DocLink, docs } from 'components/DocLink'

const Container = styled.div`
  display: flex;
  gap: ${spacing(2)};
  flex-flow: column nowrap;
  align-items: center;
  margin: 0 auto;
  max-width: 1100px;
`

const Text = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
`

export const CLIGuide = () => {
  const pageNav = createCLIGuideNavModel()
  const projectIdsWithReadWriteAccess = useUsersReadWriteProjectIds()

  return (
    <PluginOrganizationWidePage
      pageNav={pageNav}
      renderTitle={() => null}
      hideLogo
    >
      <Container>
        <VerticalGroup align="center" spacing="none">
          <h1>Run a k6 Cloud test from your CLI</h1>
          <Text>
            Download <b>k6</b> and and trigger local or cloud executed tests
            from the command line.
          </Text>

          {projectIdsWithReadWriteAccess.length === 0 && (
            <AlertContainer>
              <Alert
                // @ts-expect-error: Expects a string while a ReactNode is valid
                title={
                  <>
                    Running cloud tests from the CLI requires having at least
                    one{' '}
                    <DocLink article={docs.projects.members}>
                      project editor role
                    </DocLink>
                    . Contact an admin user to gain access.
                  </>
                }
                severity="warning"
              />
            </AlertContainer>
          )}
        </VerticalGroup>
        <CLIRunGuide
          projectId="'YOUR PROJECT ID'"
          testName="YOUR TEST NAME"
          script=""
          scriptName="test.js"
        />
      </Container>
    </PluginOrganizationWidePage>
  )
}

const AlertContainer = styled.div`
  margin: ${spacing(2)} 0;
`
