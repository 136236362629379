import { NavModelItem, PageLayoutType } from '@grafana/data'
import { PluginPage } from '@grafana/runtime'
import React, { forwardRef, ReactNode, Ref, useMemo } from 'react'
import { PageTitle } from './PageTitle'
import { PageContainer } from './Page.styled'

interface PageProps {
  navText?: NavModelItem['text']
  title?: ReactNode
  subTitle?: ReactNode
  children: ReactNode | ReactNode[]
  hideFromBreadcrumbs?: NavModelItem['hideFromBreadcrumbs']
  forwardedRef?: Ref<HTMLDivElement>
}

function PageComponent(
  {
    navText,
    children,
    hideFromBreadcrumbs = true,
    title = 'Performance testing',
    subTitle = 'Grafana Cloud k6',
    forwardedRef,
  }: PageProps,
  ref?: Ref<HTMLDivElement>
) {
  const pageNav = useMemo(() => {
    const navModel: NavModelItem = { text: navText ? navText : '' }

    if (hideFromBreadcrumbs) {
      navModel.hideFromBreadcrumbs = true
    }

    return navModel
  }, [navText, hideFromBreadcrumbs])

  return (
    <PluginPage
      subTitle={subTitle}
      pageNav={pageNav}
      renderTitle={() => <PageTitle title={title} />}
      layout={PageLayoutType.Standard}
    >
      <PageContainer>{children}</PageContainer>
    </PluginPage>
  )
}

export const Page = forwardRef(PageComponent)
