import React from 'react'

import { GrpcMetricSummary } from 'types'
import { Grid } from 'components/Grid'

import { Statistic } from './Statistic'
import {
  formatCount,
  formatRequestRate,
  formatResponseTime,
} from '../../Breakdown/ComparedValue'

interface GrpcSummaryProps {
  left: GrpcMetricSummary
  right: GrpcMetricSummary | null | undefined
}

export const GrpcSummary = ({ left, right }: GrpcSummaryProps) => {
  const requestCount = formatCount(left.requests_count, right?.requests_count)
  const requestRate = formatRequestRate(left.rps_mean, right?.rps_mean)
  const responseTime = formatResponseTime(
    left.duration.p95,
    right?.duration.p95
  )

  return (
    <Grid gap={1}>
      <Grid.Column xs={6} sm={4} md={3} xl={2}>
        <Statistic label="REQUESTS MADE" unit="reqs" {...requestCount} />
      </Grid.Column>
      <Grid.Column xs={6} sm={4} md={3} xl={2}>
        <Statistic label="AVG. RPS" unit="reqs/s" {...requestRate} />
      </Grid.Column>
      <Grid.Column xs={6} sm={4} md={3} xl={2}>
        <Statistic label="P95 RESPONSE TIME" unit="ms" {...responseTime} />
      </Grid.Column>
    </Grid>
  )
}
