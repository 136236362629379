import { unsafeParseDuration } from 'utils/testScripts/parseDuration'
import { validateScenarios } from './utils'
import { Rules } from 'types'
import { Options } from '../schema/options/options'
import { StartingTooLate } from './errors'

export function startTime(rules: Rules, options: Options) {
  // Max duration is in minutes, but we use seconds for comparisons
  const maxDuration = rules['tests.duration.max'] * 60

  return validateScenarios(
    options,
    ({ startTime }, source): StartingTooLate[] => {
      if (startTime === undefined) {
        return []
      }

      const duration = unsafeParseDuration(startTime)

      if (duration <= maxDuration) {
        return []
      }

      return [
        {
          type: 'starting-too-late',
          max: maxDuration,
          actual: duration,
          source,
        },
      ]
    }
  )
}
