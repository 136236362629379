import React, { ReactNode } from 'react'

import { Category, Status } from 'types/insights/common'
import { Insight } from 'types/insights'
import {
  FailedStatusIcon,
  InsightItemDescription,
  InsightItemHeading,
  InsightItemIcon,
  InsightItemRoot,
  InsightItemText,
  WarningStatusIcon,
  PassedStatusIcon,
  SkippedStatusIcon,
} from './InsightItem.styles'

type CategoryNames = Record<Category, ReactNode>

const categoryNames: CategoryNames = {
  http_load: 'HTTP Performance',
  best_practices: 'Best Practice',
  health: 'Health',
  web_vitals: 'Web Vitals',
}

const statusIcons: Record<Status, ReactNode> = {
  PASSED: <PassedStatusIcon />,
  WARNING: <WarningStatusIcon />,
  FAILED: <FailedStatusIcon />,
  SKIPPED: <SkippedStatusIcon />,
  CREATED: <SkippedStatusIcon />,
  'N/A': <SkippedStatusIcon />,
}

interface InsightListItemProps {
  header: ReactNode
  insight: Insight
  children: ReactNode
}

export const InsightListItem = ({
  header,
  insight,
  children,
}: InsightListItemProps) => {
  return (
    <InsightItemRoot className={insight.status.toLowerCase()}>
      <InsightItemIcon>{statusIcons[insight.status]}</InsightItemIcon>
      <InsightItemText>
        <InsightItemHeading>
          {header} 
          <span className="category">{categoryNames[insight.category]}</span>
        </InsightItemHeading>
        <InsightItemDescription>{children}</InsightItemDescription>
      </InsightItemText>
    </InsightItemRoot>
  )
}
