import React from 'react'
import { Modal } from '@grafana/ui'
import { SummaryExport, SummaryExportProps } from './SummaryExport'

interface SummaryExportModalProps extends SummaryExportProps {
  isOpen: boolean
  onClose: () => void
}

export function SummaryExportModal({
  isOpen,
  onClose,
  ...props
}: SummaryExportModalProps) {
  return (
    <Modal isOpen={isOpen} title="Export summary" onDismiss={onClose}>
      <SummaryExport {...props} onClose={onClose} />
    </Modal>
  )
}
