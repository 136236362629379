import React from 'react'
import styled from 'styled-components'
import { Tooltip } from 'components/Tooltip'
import { spacing } from 'utils/styled'
import { NotificationIcon } from '../NotificationIcon'
import { NotificationChannel } from '../../NotificationsTab.types'
import { toNotificationClientType } from '../NotificationForm/NotificationForm.utils'

interface NotificationNameProps {
  notification: NotificationChannel
}

export const NotificationName = ({ notification }: NotificationNameProps) => {
  return (
    <NotificationNameWrapper>
      <NotificationIcon clientType={toNotificationClientType(notification)} />
      <Tooltip
        placement="bottom"
        interactive
        content={notification.meta_data.name}
      >
        <span>{notification.meta_data.name}</span>
      </Tooltip>
    </NotificationNameWrapper>
  )
}

const NotificationNameWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: ${spacing(1)};
  }
`
