import {
  BROWSER_DEFAULT_METRICS,
  CHECK_DEFAULT_METRICS,
  GRPC_DEFAULT_METRICS,
  GRPC_REQUEST_RATE,
  GRPC_RESPONSE_TIME,
  HTTP_DEFAULT_METRICS,
  HTTP_FAILURE_RATE,
  HTTP_REQUEST_RATE,
  HTTP_RESPONSE_TIME_95,
  HTTP_RESPONSE_TIME_PERCENTILES,
  HTTP_TIMING_BREAKDOWN,
  VUS_METRIC,
  WS_DEFAULT_METRICS,
  WS_MESSAGES_RECEIVED,
  WS_MESSAGES_SENT,
  WS_PING,
} from 'constants/metrics'
import {
  SPAN_COUNT,
  SPAN_DURATION,
  SPAN_DURATION_PERCENTILES,
} from 'constants/tracesMetrics'
import { PlotType } from 'datasource/models'
import { NonEmptyList } from 'utils/typescript'
import { FilterPreset } from './ChartFilters.types'

// Require at least one preset
type FilterPresets = NonEmptyList<FilterPreset>

const HTTP_BASE_PRESETS: FilterPresets = [
  {
    label: 'Response time',
    metrics: [{ ...HTTP_RESPONSE_TIME_95, plot: { type: PlotType.Area } }],
  },
  {
    label: 'Response time percentiles',
    metrics: HTTP_RESPONSE_TIME_PERCENTILES,
  },
  {
    label: 'Timing breakdown',
    metrics: HTTP_TIMING_BREAKDOWN,
  },
]

export const HTTP_SUCCESS_PRESETS: FilterPresets = [
  {
    label: 'Performance overview',
    metrics: HTTP_DEFAULT_METRICS,
  },
  {
    label: 'Request rate',
    metrics: [{ ...HTTP_REQUEST_RATE, plot: { type: PlotType.Area } }],
  },
  ...HTTP_BASE_PRESETS,
]

export const BROWSER_PRESETS: FilterPresets = [
  {
    label: 'Web Vitals',
    metrics: [VUS_METRIC, ...BROWSER_DEFAULT_METRICS],
  },
]

export const HTTP_FAILED_PRESETS: FilterPresets = [
  {
    label: 'Failed request rate',
    metrics: [
      VUS_METRIC,
      { ...HTTP_FAILURE_RATE, plot: { type: PlotType.Bars } },
    ],
  },
  ...HTTP_BASE_PRESETS,
]

export const WS_PRESETS: FilterPresets = [
  {
    label: 'Performance overview',
    metrics: WS_DEFAULT_METRICS,
  },
  {
    label: 'Messages sent/received',
    metrics: [WS_MESSAGES_SENT, WS_MESSAGES_RECEIVED],
  },
  {
    label: 'Ping duration',
    metrics: [WS_PING],
  },
]

export const GRPC_SUCCESS_PRESETS: FilterPresets = [
  {
    label: 'Performance overview',
    metrics: GRPC_DEFAULT_METRICS,
  },
  {
    label: 'gRPC request rate',
    metrics: [{ ...GRPC_REQUEST_RATE, plot: { type: PlotType.Area } }],
  },
  {
    label: 'gRPC response time',
    metrics: [{ ...GRPC_RESPONSE_TIME, plot: { type: PlotType.Area } }],
  },
]

export const GRPC_FAILED_PRESETS: FilterPresets = [
  {
    label: 'Failed request rate',
    metrics: [
      VUS_METRIC,
      { ...GRPC_REQUEST_RATE, plot: { type: PlotType.Bars }, color: '#d10e5c' },
    ],
  },
  {
    label: 'gRPC response time',
    metrics: [{ ...GRPC_RESPONSE_TIME, plot: { type: PlotType.Area } }],
  },
]

export const CHECK_PRESETS: FilterPresets = [
  {
    label: 'Passes/Failures',
    metrics: CHECK_DEFAULT_METRICS,
  },
]

export const TRACES_PRESETS: FilterPresets = [
  {
    label: 'Span duration',
    metrics: [SPAN_DURATION],
  },

  {
    label: 'Span count',
    metrics: [SPAN_COUNT],
  },

  {
    label: 'Span duration percentiles',
    metrics: SPAN_DURATION_PERCENTILES,
  },
]
