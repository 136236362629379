import { useQuery } from '@tanstack/react-query'

import { SavedTestRunsResponse } from 'types/testRun'
import { toSafeTestRunsQueryKey } from 'data/queryKeys'
import { useDatasource } from 'datasourceContext'

interface UseSavedTestsProps {
  orgId: number
}

export const useSafeTests = ({ orgId }: UseSavedTestsProps) => {
  const { ds } = useDatasource()

  return useQuery<SavedTestRunsResponse>({
    queryKey: toSafeTestRunsQueryKey(orgId),
    queryFn: () => ds.getSafeTestRuns(orgId),
    placeholderData: {
      max_safes: 0,
      k6_runs: [],
    },
    useErrorBoundary: false,
  })
}
