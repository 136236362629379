import React from 'react'
import { IconButton } from '@grafana/ui'
import { useDeleteOrganizationToken } from 'data/useDeleteOrganizationToken'
import { useRegenerateOrganizationToken } from 'data/useRegenerateOrganizationToken'
import { useConfirmation } from 'hooks/useConfirmation'
import { ConfirmModal } from 'components/ConfirmModal'
import { ButtonWrapper, StyledStrong } from './RowActions.styled'

export function RowActions({ token, name }: { token: string; name: string }) {
  const { mutateAsync: deleteToken } = useDeleteOrganizationToken()
  const { mutateAsync: regenerateToken } = useRegenerateOrganizationToken()
  const [deleteConfirmationProps, handleConfirmDelete] = useConfirmation(() =>
    deleteToken(token)
  )
  const [regenerateConfirmationProps, handleConfirmRegenerate] =
    useConfirmation(() => regenerateToken(token))

  return (
    <ButtonWrapper>
      <IconButton
        name="sync"
        onClick={handleConfirmRegenerate}
        tooltip="Regenerate token"
      />
      <IconButton
        name="trash-alt"
        variant="destructive"
        tooltip="Delete token"
        onClick={handleConfirmDelete}
      />
      <ConfirmModal
        {...deleteConfirmationProps}
        title="Delete Grafana stack API token"
        confirmText="Delete token"
        body={
          <span>
            Are you sure you want to delete token{' '}
            <StyledStrong>&quot;{name}&quot;</StyledStrong>?
          </span>
        }
      />
      <ConfirmModal
        {...regenerateConfirmationProps}
        title="Regenerate Grafana stack API token"
        confirmText="Regenerate token"
        body={
          <span>
            Are you sure you want to regenerate token{' '}
            <StyledStrong>&quot;{name}&quot;</StyledStrong>?
          </span>
        }
      />
    </ButtonWrapper>
  )
}
