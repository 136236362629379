import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ApiError, TestRun } from 'types'
import { useDatasource } from 'datasourceContext'
import { showAlert } from 'utils/showAlert'
import { toTestQueryKey, toTestRunQueryKey } from './queryKeys'

export const useSetAsBaseline = () => {
  const queryClient = useQueryClient()
  const { ds } = useDatasource()

  return useMutation<TestRun, ApiError, TestRun, unknown>({
    mutationFn: (testRun: TestRun) => ds.setAsBaseline(testRun.id),
    onError: () =>
      showAlert(
        'An error occurred when trying to set test run as baseline.',
        'error'
      ),
    onSuccess: ({ id, test_id: testId }) => {
      queryClient.invalidateQueries(toTestRunQueryKey(id))
      queryClient.invalidateQueries(toTestQueryKey(testId))
    },
  })
}
