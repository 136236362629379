import React from 'react'
import { BrowserMetricSummary } from 'types'

interface BrowserTestSummaryProps {
  summary: BrowserMetricSummary
}

export function BrowserTestSummary(_props: BrowserTestSummaryProps) {
  return <>Showing the 75th percentile web vitals recorded during test run.</>
}
