import React from 'react'
import { Button, HorizontalGroup } from '@grafana/ui'

import { ConfirmModal } from 'components/ConfirmModal'
import { useConfirmation } from 'hooks/useConfirmation'
import { Test } from 'types'

interface SelectionActionsProps {
  selection: Test[]
  onClearSelection: () => void
  onDeleteTests: (runs: Test[]) => Promise<void>
}

export const SelectionActions = ({
  selection,
  onClearSelection,
  onDeleteTests,
}: SelectionActionsProps) => {
  const [deleteConfirmationProps, handleConfirmDelete] = useConfirmation(() =>
    onDeleteTests(selection)
  )

  return (
    <>
      <HorizontalGroup justify="flex-end">
        <Button onClick={onClearSelection} variant="secondary">
          Clear selection
        </Button>
        <Button
          onClick={handleConfirmDelete}
          variant="destructive"
          disabled={selection.length === 0}
        >
          Delete selected ({selection.length})
        </Button>
      </HorizontalGroup>
      <ConfirmModal
        {...deleteConfirmationProps}
        title="Delete selected tests?"
        body={`${selection.length} test will be deleted. This action cannot be undone.`}
        confirmText="Delete"
      />
    </>
  )
}
