import { useMutation } from '@tanstack/react-query'
import {
  DashboardSchema,
  ErrorResponseSchema,
  ExistingFolder,
  FolderOption,
  FolderSchema,
  RootFolder,
  SummaryExportOptions,
} from './types'
import { isFetchError } from '@grafana/runtime'
import { useDatasource } from 'datasourceContext'
import { generateSummaryDashboard } from './generator'
import { grafanaClient } from 'data/httpClient'
import { GeneratorContext } from './generator/types'
import { MetricClient } from 'data/clients/metrics/metrics'

function createFolder(
  folder: FolderOption
): Promise<ExistingFolder | RootFolder> {
  if (folder.type !== 'new') {
    return Promise.resolve(folder)
  }

  const body = {
    title: folder.title,
  }

  return grafanaClient
    .post({ url: `/folders`, body })
    .then(FolderSchema.parse)
    .then((response) => ({
      type: 'existing',
      title: response.title,
      uid: response.uid,
    }))
}

async function createDashboard(
  context: GeneratorContext,
  folder: ExistingFolder | RootFolder
) {
  const body = {
    dashboard: await generateSummaryDashboard(context),
    folderUid: folder.type === 'existing' ? folder.uid : undefined,
  }

  return grafanaClient
    .post({ url: `/dashboards/db`, body })
    .then(DashboardSchema.parse)
}

export function useExportSummary() {
  const { ds } = useDatasource()

  return useMutation({
    async mutationFn(options: SummaryExportOptions) {
      const context: GeneratorContext = {
        metrics: new MetricClient(ds),
        datasourceRef: {
          type: ds.type,
          uid: ds.uid,
        },
        options,
      }

      try {
        const folder = await createFolder(options.folder)
        const dashboard = await createDashboard(context, folder)

        return {
          type: 'success' as const,
          data: dashboard,
        }
      } catch (error) {
        if (!isFetchError(error)) {
          throw error
        }

        if (error.status === 403) {
          return {
            type: 'error' as const,
            error: {
              status: 'insufficient-permissions',
              folder: options.folder,
            } as const,
          }
        }

        if (error.status !== 412) {
          throw error
        }

        return {
          type: 'error' as const,
          error: ErrorResponseSchema.parse(error.data),
        }
      }
    },
    meta: {
      alertOnError: false,
    },
  })
}
