// If this were exported as a separate module in @grafana/k6-test-builder we could import it from there
// however currently you have to import the whole test builder to use it so it is duplicated here

export function convertDurationToSeconds(duration = '') {
  const regexp = new RegExp(/(\d+|\d+\.\d+)([hms])/gim)
  const matches = [...duration.matchAll(regexp)]
  return matches.reduce<number>((accumulator, match) => {
    const [, length, resolution] = match
    const numericLength = Number(length)
    if (!numericLength) {
      return accumulator
    }
    switch (resolution) {
      case 's':
        return accumulator + numericLength
      case 'm':
        return accumulator + numericLength * 60
      case 'h':
        return accumulator + numericLength * 60 * 60
      default:
        return accumulator
    }
  }, 0)
}
