import { groupBy, orderBy, uniqBy } from 'lodash'
import {
  type DataFrame,
  type FieldConfigSource,
  type ValueMappingResult,
  EventBusSrv,
  FieldColorModeId,
  FieldMatcherID,
  FieldType,
  LoadingState,
  MappingType,
  MutableDataFrame,
} from '@grafana/data'
import {
  type GraphFieldConfig,
  AxisPlacement,
  GraphTresholdsStyleMode,
  LegendDisplayMode,
  ScaleDistribution,
  SortOrder,
  TooltipDisplayMode,
} from '@grafana/schema'
import { type PanelContext } from '@grafana/ui'

import {
  InsightPartialTestRun,
  type InsightCategoryResultWithTestRun,
  type InsightCategoryResultWithTitleAndTestRun,
} from 'types/cloudInsights'
import { type TrendPanelOptions } from 'types/panels'
import { createGraphFieldConfig } from 'utils/dataFrame'
import { formatDate } from 'utils/date'
import { isInsightExecuting, isNumericScore } from 'utils/insights'
import { toPercentage } from 'utils/math'

import { PanelDataWithoutTimeRange } from './Chart.types'

const X_FIELD_NAME = 'Scores'
const Y_FIELD_VALUE_SKIPPED = 0
const Y_FIELD_VALUE_ANALYZING = -1
const Y_FIELD_VALUE_FAILED = -2

const eventBus = new EventBusSrv()

const getScoreValue = (result: InsightCategoryResultWithTestRun) => {
  if (isNumericScore(result.score)) {
    return toPercentage(result.score.value)
  }

  if (isInsightExecuting(result)) {
    return Y_FIELD_VALUE_ANALYZING
  }

  return Y_FIELD_VALUE_FAILED
}

const mapValueToTestRunDate = (
  acc: Record<string, ValueMappingResult>,
  value: InsightPartialTestRun,
  index: number
) => {
  return {
    ...acc,
    [index]: {
      index,
      text: `Started: ${formatDate(value.started || value.created)}`,
    },
  }
}

const toSeriesFrame = (
  history: InsightCategoryResultWithTitleAndTestRun[]
): DataFrame => {
  const first = history[0]
  const frame = new MutableDataFrame()

  if (!first) {
    return frame
  }

  // Categories receive a unique ID per execution so we cannot use this to link results together, the only persistent property we have to match against is the title.
  const categories = uniqBy(history, 'category_title')
  const groupedByTestRunId = Object.values(groupBy(history, 'test_run.id'))
  // Since we've already grouped by test run ID, we can safely assume that the first result in each group is the same test run.
  const testRuns = groupedByTestRunId.map((results) => results[0]!.test_run)

  orderBy([...categories], ['category_title']).forEach((category) => {
    const values = groupedByTestRunId.map((results) => {
      // Find the result for the current category and given test run
      const result = results.find(
        (result) => result.category_title === category.category_title
      )

      if (!result) {
        return Y_FIELD_VALUE_SKIPPED
      }

      return getScoreValue(result)
    })

    frame.addField({
      name: category.category_title,
      type: FieldType.number,
      values,
      config: createGraphFieldConfig({
        mappings: [
          {
            type: MappingType.ValueToText,
            options: {
              [Y_FIELD_VALUE_ANALYZING]: {
                index: 0,
                text: 'analyzing...',
              },
              [Y_FIELD_VALUE_FAILED]: {
                index: 0,
                text: 'failed',
              },
            },
          },
        ],
      }),
    })
  })

  frame.addField({
    name: X_FIELD_NAME,
    type: FieldType.number,
    values: Array.from({ length: groupedByTestRunId.length }).map((_, i) => i),
    config: createGraphFieldConfig({
      mappings: [
        {
          type: MappingType.ValueToText,
          options: testRuns.reduce(mapValueToTestRunDate, {}),
        },
      ],
    }),
  })

  return frame
}

interface ToPanelDataProps {
  history: InsightCategoryResultWithTitleAndTestRun[]
}

export const toPanelData = ({
  history,
}: ToPanelDataProps): PanelDataWithoutTimeRange => {
  if (!Object.keys(history).length) {
    return {
      state: LoadingState.NotStarted,
      series: undefined,
    }
  }

  const seriesFrame: DataFrame = toSeriesFrame(history)

  return {
    state: LoadingState.Done,
    series: [seriesFrame],
  }
}

export const createFieldConfig = (
  options: Partial<FieldConfigSource<GraphFieldConfig>>
): FieldConfigSource<GraphFieldConfig> => {
  return {
    defaults: {
      custom: {
        axisLabel: '',
        axisPlacement: AxisPlacement.Auto,
        hideFrom: {
          tooltip: false,
          viz: false,
          legend: false,
        },
        lineWidth: 2,
        pointSize: 5,
        scaleDistribution: {
          type: ScaleDistribution.Linear,
        },
        spanNulls: false,
        thresholdsStyle: {
          mode: GraphTresholdsStyleMode.Off,
        },
      },
      color: {
        mode: FieldColorModeId.PaletteClassic,
      },
      unit: 'none',
      decimals: 0,
    },
    overrides: [
      ...(options.overrides ?? []),
      {
        matcher: {
          id: FieldMatcherID.byName,
          options: X_FIELD_NAME,
        },
        properties: [
          {
            id: 'custom.axisPlacement',
            value: 'hidden',
          },
        ],
      },
    ],
  }
}

export const context: PanelContext = {
  eventBus,
  canAddAnnotations: () => false,
  canEditAnnotations: () => false,
  canDeleteAnnotations: () => false,
}

export const options: TrendPanelOptions = {
  tooltip: {
    mode: TooltipDisplayMode.Multi,
    sort: SortOrder.None,
  },
  legend: {
    showLegend: true,
    calcs: [],
    displayMode: LegendDisplayMode.List,
    placement: 'bottom',
  },
  xField: X_FIELD_NAME,
}
