import React, { ReactNode, createContext, useContext, useState } from 'react'
import { type AbsoluteTimeRange } from '@grafana/data'

interface ProviderProps {
  children: ReactNode
  isDisabled?: boolean
}

interface ContextProps {
  timeRange?: AbsoluteTimeRange
  setTimeRange: (timeRange?: AbsoluteTimeRange) => void
}

const Context = createContext<Partial<ContextProps>>({})

const TimeRangeProvider = ({ children, isDisabled = false }: ProviderProps) => {
  const [timeRange, setTimeRange] = useState<AbsoluteTimeRange | undefined>()

  const handleSetTimeRange = (timeRange?: AbsoluteTimeRange) => {
    if (isDisabled) {
      return
    }

    setTimeRange(timeRange)
  }

  const context = { timeRange, setTimeRange: handleSetTimeRange }

  return <Context.Provider value={context}>{children}</Context.Provider>
}

const useTimeRange = () => {
  const state = useContext(Context)

  if (typeof state === 'undefined') {
    throw new Error('useTimeRange must be used within a TimeRangeProvider')
  }

  return state as ContextProps
}

export { TimeRangeProvider, useTimeRange }
