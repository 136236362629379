import React, { ReactNode, useMemo } from 'react'

import { Test, TestRun } from 'types'
import { RunsPageTestIds } from 'types/dataTestIds'
import { isTestDone, isTestSafe } from 'utils/testRun'
import { Flex } from 'components/Flex'
import { MetaBar, icons } from 'components/MetaBar'
import { Sticky } from 'components/Sticky'

import { CompareWithDropdown } from '../CompareWithDropdown'
import { TimeRangeResetButton } from '../TimeRangeResetButton'
import { APMMetaItem } from './APMMetaItem'
import { StartedBy } from './StartedBy'
import { getTestRunMetaData } from './TestRunMetaBar.utils'
import {
  Body,
  ErrorDetail,
  ErrorText,
  ErrorTitle,
} from './TestRunMetaBar.styles'
import { BrowserTimelineButton } from 'components/BrowserTimeline/BrowserTimelineButton'

const safeTooltipText = 'Result will not be deleted by data retention policy'

interface TestRunMetaBarProps {
  test: Test
  testRun: TestRun
  actions?: ReactNode
  hideError?: boolean
  isSticky?: boolean
}

export const TestRunMetaBar = ({
  test,
  testRun,
  actions,
  hideError = false,
  isSticky,
}: TestRunMetaBarProps) => {
  const isBaselineTest = testRun.is_baseline
  const isSafe = isTestSafe(testRun)

  const safeTooltip =
    isSafe && isBaselineTest
      ? `${safeTooltipText}. Note: This run also has safe status`
      : safeTooltipText

  const metaData = useMemo(() => {
    return getTestRunMetaData(testRun)
  }, [testRun])

  return (
    <>
      {!hideError && testRun.error_detail && (
        <div>
          <ErrorText>
            <ErrorTitle>TEST ERROR DETAIL:</ErrorTitle>{' '}
            <ErrorDetail>{testRun.error_detail}</ErrorDetail>
          </ErrorText>
        </div>
      )}

      <Sticky disabled={!isSticky} direction="column" marginBottom={1}>
        <Body>
          <MetaBar data-testid={RunsPageTestIds.MetaBar}>
            <MetaBar.Section>
              <MetaBar.Item.Status testRun={testRun} />
              <MetaBar.Item
                hidden={!isBaselineTest}
                icon={icons.baseline}
                tooltip={`Baseline run. ${safeTooltip}`}
              />
              <MetaBar.Item
                hidden={isBaselineTest || !isSafe}
                icon={icons.savedRun}
                tooltip={safeTooltipText}
              />
              <MetaBar.Item.Vuh value={metaData.vuh} />
              <MetaBar.Item.Vus value={metaData.vus} />
              <MetaBar.Item.ExecutedDuration
                hidden={!isTestDone(testRun)}
                value={metaData.executionDuration}
              />
              <MetaBar.Item.LoadZones testRun={testRun} />
              <MetaBar.Item icon={icons.user}>
                <StartedBy userId={testRun.user_id} />
              </MetaBar.Item>
              <BrowserTimelineButton testRun={testRun} />

              {testRun.metrics_exports?.map((metrics) => (
                <APMMetaItem key={metrics.id} data={metrics} />
              ))}
            </MetaBar.Section>
            <MetaBar.Section fixedWidth>
              {isSticky && <TimeRangeResetButton />}
              <CompareWithDropdown test={test} testRun={testRun} />
              {actions && <Flex>{actions}</Flex>}
            </MetaBar.Section>
          </MetaBar>
        </Body>
      </Sticky>
    </>
  )
}
