import React, { useMemo } from 'react'

import {
  VUS_METRIC,
  GRPC_REQUEST_RATE,
  GRPC_RESPONSE_TIME,
  HTTP_FAILURE_RATE,
  HTTP_REQUEST_RATE,
  HTTP_RESPONSE_TIME_95,
  WS_SESSION_DURATION,
  WS_SESSION_RATE,
  BROWSER_DEFAULT_METRICS,
} from 'constants/metrics'
import { Scenario, TestRunAnalysis } from 'types'
import { TagQueryBuilder, MetricBuilder } from 'utils/metrics'
import { exhaustive } from 'utils/typescript'
import { MetricConfig } from 'datasource/models'
import { Chart } from 'components/Chart'

import { useTimeRange } from '../../../TimeRangeContext'
import { ProtocolType } from '../RunOverview.types'
import { RunsPageTestIds } from 'types/dataTestIds'

type ProtocolMetrics = {
  [P in ProtocolType]: MetricConfig[]
}

const metricsByProtocol: ProtocolMetrics = {
  http: [HTTP_REQUEST_RATE, HTTP_RESPONSE_TIME_95, HTTP_FAILURE_RATE],
  grpc: [GRPC_REQUEST_RATE, GRPC_RESPONSE_TIME],
  ws: [WS_SESSION_RATE, WS_SESSION_DURATION],
  browser: BROWSER_DEFAULT_METRICS,
}

function formatChartTitle(protocol: ProtocolType, { name }: Scenario) {
  switch (protocol) {
    case 'http':
      return `HTTP ${name}`

    case 'grpc':
      return `GRPC ${name}`

    case 'ws':
      return `WebSockets ${name}`

    case 'browser':
      return `Browser ${name}`

    default:
      return exhaustive(protocol)
  }
}

interface ScenarioChartProps {
  protocol: ProtocolType
  analysis: TestRunAnalysis
  scenario: Scenario
}

export function ScenarioChart({
  protocol,
  scenario,
  analysis,
}: ScenarioChartProps) {
  const { timeRange, setTimeRange } = useTimeRange()

  const metrics = useMemo(() => {
    const scenarioTag = new TagQueryBuilder()
      .equal('scenario', scenario.name)
      .build()

    return [
      VUS_METRIC,
      ...metricsByProtocol[protocol].map((metric) =>
        new MetricBuilder(metric).withTags(scenarioTag).build()
      ),
    ]
  }, [scenario.name, protocol])

  return (
    <Chart
      title={formatChartTitle(protocol, scenario)}
      metrics={metrics}
      testRun={analysis.main}
      compareWith={analysis.compareWith}
      timeRange={timeRange}
      onChangeTimeRange={setTimeRange}
      dataTestId={RunsPageTestIds.ScenarioChart}
    />
  )
}
