import React, { ReactNode } from 'react'
import styled, { useTheme } from 'styled-components'
import {
  CellStyles,
  ColumnTypeStyleProps,
  ColumnTypeStyles,
  getColumnStyle,
} from './styles'
import { TableCell } from './TableCell'
import { TableColumn } from '../types'
import { spacing } from 'utils/styled'

interface TableDataStylesProps extends ColumnTypeStyleProps {}

export const TableDataStyles = styled.td<TableDataStylesProps>`
  ${ColumnTypeStyles}

  padding-right: ${spacing(2)};
`

export const FullWidthTableData = styled.td.attrs({ colSpan: 1000 })`
  ${CellStyles}
`

interface TableDataProps {
  type: 'group' | 'value'
  index: number
  level: number
  rowIcon: ReactNode
  column: TableColumn<any>
  children: ReactNode
  customStyles?: React.CSSProperties
}

export function TableData({
  type,
  index,
  column,
  level,
  rowIcon,
  children,
  customStyles = {},
}: TableDataProps) {
  const theme = useTheme()

  return (
    <TableDataStyles
      group={type === 'group'}
      numeric={column.numeric}
      style={{ ...getColumnStyle(index, theme, level), ...customStyles }}
    >
      <TableCell icon={index === 0 && rowIcon}>{children}</TableCell>
    </TableDataStyles>
  )
}
