import { Icon, SelectContainer } from '@grafana/ui'
import React, { useState } from 'react'
import styled from 'styled-components'

import { TestRunsSelect } from '../TestRunsSelect/TestRunsSelect'
import { color } from 'utils/styled'
import { Test, TestRun } from 'types'
import { SelectableValue } from '@grafana/data'
import { SelectInfinite } from 'components/SelectInfinite'
import { useTestOptions } from 'data/useTestOptions'

interface TestAndRunsSelectProps {
  projectId: number
  currentTestRun: TestRun
  comparedTestRun?: TestRun
  disabled?: boolean
  hideValue?: boolean
  testRunSelectId: string
  testRunSelectAriaLabel?: string
  width?: number
  onTestRunChange: (option: SelectableValue<Test['id']>) => void
}

export function TestAndRunSelect({
  projectId,
  currentTestRun,
  comparedTestRun,
  disabled = false,
  hideValue = false,
  testRunSelectId,
  testRunSelectAriaLabel,
  width,
  onTestRunChange,
}: TestAndRunsSelectProps) {
  const [autoOpen, setAutoOpen] = useState(false)
  const [selectedTestId, setSelectedTestId] = useState(currentTestRun.test_id)
  const [selectedTestRunId, setSelectedTestRunId] = useState<
    number | undefined
  >(currentTestRun.id)

  const {
    tests,
    isLoading: testsLoading,
    fetchNextPage,
  } = useTestOptions(projectId)

  const handleTestChange = (option: SelectableValue<Test['id']>) => {
    if (option.value === undefined) {
      return
    }

    setSelectedTestId(option.value)
    setSelectedTestRunId(undefined)

    const testRunSelect = document.getElementById(testRunSelectId)
    if (testRunSelect instanceof HTMLElement) {
      setAutoOpen(true)
      requestAnimationFrame(() => {
        testRunSelect.focus()
        setAutoOpen(false)
      })
    }
  }

  const handleTestRunChange = (option: SelectableValue<TestRun['id']>) => {
    if (option.value === undefined) {
      return
    }

    onTestRunChange(option)
  }

  return (
    <SelectWrapper $width={width}>
      <SelectInfinite
        value={selectedTestId}
        options={tests}
        onChange={handleTestChange}
        disabled={disabled}
        isLoading={testsLoading}
        aria-label="Select test"
        loadNext={fetchNextPage}
        noOptionsMessage={testsLoading ? 'loading...' : 'no available tests'}
        tabSelectsValue={false}
        components={{
          SelectContainer: StyledSelectContainerTest,
          DropdownIndicator: TestSelectDropdownIcon,
        }}
      />
      <TestRunsSelect
        openMenuOnFocus={autoOpen}
        dropdownDirection="left"
        value={hideValue ? undefined : selectedTestRunId}
        onChange={handleTestRunChange}
        selected={[
          currentTestRun,
          ...(comparedTestRun ? [comparedTestRun] : []),
        ]}
        disabled={disabled}
        aria-label={testRunSelectAriaLabel}
        inputId={testRunSelectId}
        testId={selectedTestId}
        components={{
          SelectContainer: StyledSelectContainerRun,
        }}
        tabSelectsValue={false}
      />
    </SelectWrapper>
  )
}

const SelectWrapper = styled.div<{ $width?: number }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: ${({ theme, $width }) => ($width ? theme.spacing($width) : '100%')};
  border: 1px solid ${color('border', 'medium')};
  border-radius: ${({ theme }) => theme.shape.radius.default};
  background: ${color('background', 'canvas')};
`

const StyledSelectContainerTest = styled(SelectContainer)`
  border: none;

  // Adjust padding to match the visual spacing of the input on the right
  [class$='input-suffix'] {
    padding-right: 0;
  }
`

const StyledSelectContainerRun = styled(SelectContainer)`
  border: none;
  // prevent placeholder from wrapping
  white-space: nowrap;
`

function TestSelectDropdownIcon() {
  return <Icon name="angle-right" size="md" aria-hidden />
}
