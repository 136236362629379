import { useQuery } from '@tanstack/react-query'
import { toTestRunBrowserSearchTracesQueryKey } from './queryKeys'
import { useTestRunClient } from './clients/testRuns'

type TestRunId = number

export function useTestRunSearchTraces(id?: TestRunId) {
  const client = useTestRunClient()

  return useQuery({
    queryKey: toTestRunBrowserSearchTracesQueryKey(id!),
    queryFn: () => client.searchBrowserTraces(id!),
    enabled: !!id,
  })
}
