import React from 'react'

import { WsMetricSummary } from 'types'
import { Grid } from 'components/Grid'

import { Statistic } from './Statistic'
import { formatCount, formatResponseTime } from '../../Breakdown/ComparedValue'

interface WebSocketsSummaryProps {
  left: WsMetricSummary
  right: WsMetricSummary | null | undefined
}

export const WebSocketsSummary = ({ left, right }: WebSocketsSummaryProps) => {
  const sessionCount = formatCount(left.sessions, right?.sessions)
  const msgsSent = formatCount(left.msgs_sent, right?.msgs_sent)
  const msgsReceived = formatCount(left.msgs_received, right?.msgs_received)
  const connectingTime = formatResponseTime(
    left.connecting.p95,
    right?.connecting.p95
  )
  const sessionDuration = formatResponseTime(
    left.session_duration.p95,
    right?.session_duration.p95
  )

  return (
    <Grid gap={1}>
      <Grid.Column xs={6} md={4} xl={2}>
        <Statistic label="SESSION COUNT" unit="sessions" {...sessionCount} />
      </Grid.Column>
      <Grid.Column xs={6} md={4} xl={2}>
        <Statistic label="MESSAGES SENT" unit="msgs" {...msgsSent} />
      </Grid.Column>
      <Grid.Column xs={6} md={4} xl={2}>
        <Statistic label="MESSAGES RECEIVED" unit="msgs" {...msgsReceived} />
      </Grid.Column>
      <Grid.Column xs={6} md={4} xl={2}>
        <Statistic label="P95 CONNECTING TIME" unit="ms" {...connectingTime} />
      </Grid.Column>
      <Grid.Column xs={6} md={4} xl={2}>
        <Statistic
          label="P95 SESSION DURATION"
          unit="ms"
          {...sessionDuration}
        />
      </Grid.Column>
    </Grid>
  )
}
