import React from 'react'
import { Link } from 'react-router-dom'

import { InfoToggletip } from 'components/InfoToggletip'
import { useIsUserOrgAdmin } from 'data/usePermissions'
import { getRoute } from 'routeMap'
import { isVUhUsageAboveThreshold, isVUhQuotaReached } from 'utils/VUh'

interface ProjectVUhInfoToggletipProps {
  value?: number
  limit?: number | null
}

export function ProjectVUhInfoToggletip({
  value,
  limit,
}: ProjectVUhInfoToggletipProps) {
  const isUserOrgAdmin = useIsUserOrgAdmin()
  const isAboveThreshold = isVUhUsageAboveThreshold(value, limit)
  const isQuotaReached = isVUhQuotaReached(value, limit)

  if (!isAboveThreshold && !isQuotaReached) {
    return null
  }

  return (
    <InfoToggletip
      tooltip="About this limit"
      type={isQuotaReached ? 'error' : 'warning'}
      placement="auto"
      content={
        <>
          {isQuotaReached ? (
            <p>You{"'"}ve reached your monthly VUh quota.</p>
          ) : (
            <p>
              You{"'"}ve used {'>'}90% of your monthly VUh quota.
            </p>
          )}
          {isUserOrgAdmin ? (
            <>
              Limits and quotas help you control your resources more effectively
              and avoid unexpected costs. Review and manage them in{' '}
              <Link to={getRoute('limits')}>settings</Link>
            </>
          ) : (
            <>
              Contact your stack admin to review and manage project limits and
              quotas
            </>
          )}
          .
        </>
      }
    />
  )
}
