import React, { FormEvent, useMemo, useState } from 'react'
import { startOfDay, subDays } from 'date-fns'
import { useDebounce } from 'usehooks-ts'
import { SelectableValue } from '@grafana/data'
import { Button, HorizontalGroup, Icon, Input, Select } from '@grafana/ui'

import { createProjectListNavModel } from 'navModels'
import { Project, ProjectSortOptions, ProjectWithStats } from 'types'
import { toISODate, toTimestamp } from 'utils/date'
import { useCanUserCreateProjects } from 'data/usePermissions'
import { useProjects } from 'data/useProjects'
import { useSubscriptionRule } from 'data/useSubscriptions'
import { useFreeVUhsUsageReport, useUsageReports } from 'data/useUsageReports'
import { useDefaultProject } from 'hooks/useDefaultProject'
import { CreateNewProjectModal } from 'pages/ProjectPage/components/CreateNewProject'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { EmptyFilterView } from 'components/EmptyFilterView'
import { Grid } from 'components/Grid'
import { PluginOrganizationWidePage } from 'components/PluginPage'
import { TotalVUhConsumption } from 'components/VUhConsumption/TotalVUhConsumption'

import { ProjectCard } from './components/ProjectCard'
import { WelcomeModal } from './components/WelcomeModal'
import { TopRow } from './ProjectsListPage.styles'
import { useAppContext } from 'appContext'
import { NoProjectsAssignedState } from './components/NoProjectAssignedState'
import { NoProjectsState } from './components/NoProjectsState'

const selectOptions = [
  { label: 'Created', value: ProjectSortOptions.Created },
  { label: 'Name', value: ProjectSortOptions.Name },
]

const SEARCH_WAIT_INTERVAL = 300
const STATS_PERIOD_DAYS = 30
const STATS_START_TIME = toISODate(
  subDays(startOfDay(new Date()), STATS_PERIOD_DAYS)
)

export const ProjectsListPage = () => {
  const { account } = useAppContext()

  const hasUsageReports = useSubscriptionRule('organization.usage_reports')
  const freeVUhsUsageReport = useFreeVUhsUsageReport()

  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(
    searchValue,
    searchValue !== '' ? SEARCH_WAIT_INTERVAL : 0
  )
  const [sortOption, setSortOption] = useState<ProjectSortOptions>(
    ProjectSortOptions.Created
  )

  const canCreateProjects = useCanUserCreateProjects()

  const pageNav = createProjectListNavModel()

  const handleSearchChange = (e: FormEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value)
  }

  const handleSortOptionChange = (
    selectedOption: SelectableValue<ProjectSortOptions>
  ) => {
    if (!selectedOption.value) {
      return
    }
    setSortOption(selectedOption.value)
  }

  const handleClearFilters = () => {
    setSearchValue('')
  }

  if (!canCreateProjects && account.project_roles.length === 0) {
    return (
      <PluginOrganizationWidePage pageNav={pageNav}>
        <NoProjectsAssignedState />
      </PluginOrganizationWidePage>
    )
  }

  return (
    <>
      <PluginOrganizationWidePage
        pageNav={pageNav}
        subTitle={freeVUhsUsageReport && <TotalVUhConsumption />}
        actions={<Actions />}
      >
        <TopRow>
          <HorizontalGroup size={32}>
            <Input
              aria-label="Search by project name"
              prefix={<Icon name="search" aria-hidden />}
              placeholder={'Search by project name'}
              onInput={handleSearchChange}
              value={searchValue}
            />
            <Select
              aria-label="Order projects by"
              options={selectOptions}
              value={sortOption}
              width={18}
              isSearchable={false}
              onChange={handleSortOptionChange}
            />
          </HorizontalGroup>
        </TopRow>
        <Content
          searchValue={debouncedSearchValue}
          sortOption={sortOption}
          hasUsageReports={hasUsageReports}
          onClearFilters={handleClearFilters}
        />
      </PluginOrganizationWidePage>

      <WelcomeModal />
    </>
  )
}

const Actions = () => {
  const [isCreateNewProjectModalOpen, setIsCreateNewProjectModalOpen] =
    useState(false)
  const canUserCreateProjects = useCanUserCreateProjects()

  return (
    <>
      <CreateNewProjectModal
        isOpen={isCreateNewProjectModalOpen}
        onDismiss={() => setIsCreateNewProjectModalOpen(false)}
      />
      {canUserCreateProjects ? (
        <Button onClick={() => setIsCreateNewProjectModalOpen(true)}>
          Create new project
        </Button>
      ) : null}
    </>
  )
}

interface ContentProps {
  searchValue: string
  sortOption: ProjectSortOptions
  hasUsageReports: boolean
  onClearFilters: () => void
}

const Content = ({
  searchValue,
  sortOption,
  hasUsageReports,
  onClearFilters,
}: ContentProps) => {
  const { data: projects = [], ...projectsQuery } = useProjects()
  const { data: projectsWithStats = [], ...projectsWithStatsQuery } =
    useUsageReports(
      { startTime: STATS_START_TIME },
      {
        useErrorBoundary: false,
        meta: {
          alertOnError: 'Failed to fetch project statistics',
        },
      }
    )
  const defaultProject = useDefaultProject()
  const isLoading = hasUsageReports
    ? projectsWithStatsQuery.isLoading && projectsQuery.isLoading
    : projectsQuery.isLoading

  // Temporary workaround until we get a new projects endpoint
  const displayedProjects: Array<Project & { stats?: ProjectWithStats }> =
    useMemo(() => {
      return projects
        .filter(({ name }) =>
          name.toLowerCase().includes(searchValue.toLowerCase())
        )
        .map((project) => {
          if (!hasUsageReports) {
            return project
          }

          return {
            ...project,
            stats: projectsWithStats.find(({ id }) => project.id === id),
          }
        })
        .sort((a, b) => {
          if (sortOption === ProjectSortOptions.Name) {
            return a.name.localeCompare(b.name)
          }

          return toTimestamp(b.created) - toTimestamp(a.created)
        })
    }, [projects, searchValue, hasUsageReports, projectsWithStats, sortOption])

  const xxlColumnSize = displayedProjects.length > 3 ? 3 : 4

  if (isLoading) {
    return <CenteredSpinner />
  }

  if (displayedProjects.length === 0 && searchValue === '') {
    return <NoProjectsState />
  }

  if (displayedProjects.length === 0) {
    return (
      <EmptyFilterView onClear={onClearFilters}>
        No project matched the query <b>{searchValue}</b>
      </EmptyFilterView>
    )
  }

  return (
    <Grid gap={2}>
      {displayedProjects.map((project) => {
        return (
          <Grid.Column
            key={project.id}
            xs={12}
            md={6}
            xl={4}
            xxl={xxlColumnSize}
          >
            <ProjectCard
              project={project}
              isDefault={project.id === defaultProject?.id}
              stats={project.stats}
              statsPeriod={`last ${STATS_PERIOD_DAYS} days`}
            />
          </Grid.Column>
        )
      })}
    </Grid>
  )
}
