const bundleInstallCommand = `kubectl apply -f https://raw.githubusercontent.com/grafana/k6-operator/main/bundle.yaml`

const helmInstallCommand = `helm repo add grafana https://grafana.github.io/helm-charts
helm install k6-operator grafana/k6-operator`

export const createNamespaceCommand = `kubectl create namespace plz-ns`

export const createSecretCommand = `kubectl create secret generic grafana-k6-token -n plz-ns --from-literal=token=<GRAFANA_API_STACK_TOKEN>`

export const yamlConfig = `apiVersion: k6.io/v1alpha1
kind: PrivateLoadZone
metadata:
  name: my-first-plz
  namespace: plz-ns
spec:
  token: grafana-k6-token
  resources:
    limits:
      cpu: 256m
      memory: 1024Mi`

export const applyCommand = `kubectl apply -f <NAME_OF_FILE_FROM_LAST_STEP>`

export const installationTabs = [
  {
    label: 'Bundle',
    value: 'bundle',
    code: bundleInstallCommand,
  },
  {
    label: 'Helm',
    value: 'helm',
    code: helmInstallCommand,
  },
]
