import React from 'react'
import { BreakdownTabEmptyView, Content } from '../Breakdown.styles'
import { CodeSnippet } from 'components/CodeSnippet'
import { tabs } from '../../../../NewTest/BrowserDiscoverability'
import { DocLink, docs } from '../../../../../components/DocLink'

export function NoMetrics() {
  return (
    <BreakdownTabEmptyView>
      <Content style={{ flexBasis: '450px' }}>
        <h2>Run your first Browser tests </h2>
        <p>
          Browser testing has landed in Grafana Cloud k6! With the browser
          module, you can run browser tests on top of your existing k6 protocol
          tests to measure web performance metrics and find issues difficult to
          catch on the protocol level. Whether you want to run a hybrid
          performance test, a functional or an end-to-end test, the browser
          module can give you insights into your user experience.
        </p>
        <p>
          Here&apos;s a sample script that covers a user journey flow of logging
          in to an application. k6 browser draws inspiration from Playwright, so
          you don&apos;t need to learn a new API to start writing browser tests
          with k6.
        </p>
        <p>
          To get started with the k6 browser module, check out our{' '}
          <DocLink article={docs.browser.runningBrowserTests}>
            documentation
          </DocLink>{' '}
          guide and our{' '}
          <DocLink article={docs.browser.api}>API documentation</DocLink>.
        </p>
      </Content>
      <Content style={{ flex: '1 0 650px' }}>
        <CodeSnippet height="256px" tabs={tabs} lang="js" />
      </Content>
    </BreakdownTabEmptyView>
  )
}

export function renderNoMetrics() {
  return <NoMetrics />
}
