import { Button, ButtonProps, Dropdown } from '@grafana/ui'
import React, { ReactElement } from 'react'

interface ActionsMoreDropdownProps {
  overlay: ReactElement | (() => ReactElement)
  variant?: ButtonProps['variant']
  fill?: ButtonProps['fill']
}

export function ActionsMoreDropdown({
  overlay,
  variant = 'secondary',
  fill = 'outline',
}: ActionsMoreDropdownProps) {
  return (
    <Dropdown overlay={overlay} placement="bottom-end">
      <Button
        aria-label="Open dropdown menu"
        size="md"
        variant={variant}
        icon="ellipsis-v"
        fill={fill}
      />
    </Dropdown>
  )
}
