import React, { ReactNode } from 'react'
import { Dropdown, Icon, Menu } from '@grafana/ui'
import { ProjectContributor, User } from 'types'
import { useUsers } from 'data/useUsers'
import { Avatar } from 'components/Avatar'
import { CenteredSpinner } from 'components/CenteredSpinner'
import {
  List as ContributorsList,
  ListItem as ContributorListItem,
  ViewAll,
} from 'components/Members/Members'
import {
  MoreMembersButton as MoreContributorsButton,
  List,
  ListItem,
  Email,
} from 'components/Members/MembersDropdown'

interface ProjectContributorsProps {
  contributors: ProjectContributor[]
  maxContributors?: number
}

export const ProjectContributors = ({
  contributors,
  maxContributors = 2,
}: ProjectContributorsProps) => {
  const {
    data: users,
    isFetching: isUsersFetching,
    isLoading: isUsersLoading,
  } = useUsers(
    contributors.map(({ user_id }) => user_id),
    {
      keepPreviousData: true,
      useCachedData: true,
      useErrorBoundary: true,
      alertOnError: true,
    }
  )

  return (
    <ContributorsList>
      {users &&
        users.slice(0, maxContributors).map((user) => (
          <ContributorListItem key={user.id}>
            <Avatar gravatar_url={user.gravatar_url} tooltip={user.email} />
          </ContributorListItem>
        ))}

      {users && users.length > maxContributors && (
        <li>
          <ContributorsDropdown
            users={users}
            isFetching={isUsersFetching && !isUsersLoading}
          >
            <ViewAll>All</ViewAll> <Icon name="angle-down" />
          </ContributorsDropdown>
        </li>
      )}
    </ContributorsList>
  )
}

export const ContributorsDropdown = ({
  users,
  children,
  isFetching,
}: {
  users: User[]
  children: ReactNode
  isFetching?: boolean
}) => {
  return (
    <Dropdown
      overlay={
        <ContributorsDropdownList users={users} isFetching={isFetching} />
      }
    >
      <MoreContributorsButton>{children}</MoreContributorsButton>
    </Dropdown>
  )
}

const ContributorsDropdownList = ({
  users,
  isFetching = false,
}: {
  users: User[]
  isFetching?: boolean
}) => {
  return (
    <Menu>
      <List>
        {users.map((user) => (
          <ListItem key={user.id}>
            <Avatar gravatar_url={user?.gravatar_url} />
            <Email title={user.email}>{user.email}</Email>
          </ListItem>
        ))}

        {isFetching && <CenteredSpinner $height="58px" />}
      </List>
    </Menu>
  )
}
