import { Button, IconButton, Select } from '@grafana/ui'
import React, { useState } from 'react'
import styled from 'styled-components'

import { ChangeFolderPermissionParams } from 'data/useChangeFolderPermission'
import { FolderPermission, FolderPermissionTarget } from 'types/rbac'
import { spacing } from 'utils/styled'
import { Flex } from 'components/Flex'
import { GrafanaUserPicker } from 'components/GrafanaUserPicker'
import { GrafanaTeamPicker } from 'components/GrafanaTeamPicker'
import { OrgRole } from '@grafana/data'

const orgRoleOptions = Object.values(OrgRole)
  // @ts-expect-error: 'None' doesn't exist in our local version of @grafana/data
  .filter((r) => r !== 'None')
  .map((r) => ({
    value: r,
    label: r,
  }))

interface AddPermissionFormProps {
  onAdd: (params: ChangeFolderPermissionParams) => void
  onClose: () => void
}

export function AddPermissionForm({ onAdd, onClose }: AddPermissionFormProps) {
  const [target, setPermissionTarget] = useState<
    FolderPermissionTarget | undefined
  >()
  const [teamId, setTeamId] = useState(-1)
  const [userId, setUserId] = useState(-1)
  const [builtInRole, setBuiltinRole] = useState('')
  const [permission, setPermission] = useState<FolderPermission>(
    FolderPermission.Edit
  )

  const targetOptions = [
    { value: 'role', label: 'Role' },
    { value: 'user', label: 'User' },
    { value: 'team', label: 'Team' },
  ]

  const isValid = () =>
    (target === 'team' && teamId > 0) ||
    (target === 'user' && userId > 0) ||
    (target === 'role' && builtInRole)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onAdd({ userId, teamId, builtInRole, permission, type: target! })
    onClose()
  }

  return (
    <div className="cta-form" aria-label="Permissions slider">
      <CloseButton
        aria-label="Close"
        name="times"
        onClick={onClose}
        tooltip="Close"
      />
      <h5>Add permission</h5>

      <form name="addPermission" onSubmit={onSubmit}>
        <Flex gap={1} wrap="wrap">
          <Select
            aria-label="Role to add new permission to"
            value={target}
            options={targetOptions}
            onChange={(v) => {
              setPermissionTarget(v.value as FolderPermissionTarget)
            }}
            width={12}
          />

          {target === 'user' && (
            <GrafanaUserPicker
              onSelected={(u) => setUserId(u.value || 0)}
              width={24}
            />
          )}

          {target === 'team' && (
            <GrafanaTeamPicker
              onSelected={(t) => setTeamId(t.value?.id || 0)}
              width={20}
            />
          )}

          {target === 'role' && (
            <Select
              aria-label={'Built-in role picker'}
              options={orgRoleOptions}
              onChange={(r) => setBuiltinRole(r.value || '')}
              width={12}
            />
          )}

          <Select
            aria-label="Permission Level"
            width={12}
            value={FolderPermission[permission]}
            options={Object.values(FolderPermission).map((p) => ({
              label: p,
              value: p,
            }))}
            onChange={(v) => {
              setPermission(v.value as FolderPermission)
            }}
          />
          <Button type="submit" disabled={!isValid()}>
            Save
          </Button>
        </Flex>
      </form>
    </div>
  )
}

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${spacing(1)};
  right: ${spacing(0.5)};
`
