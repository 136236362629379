import React, { useCallback, useState } from 'react'

import { type TestRun } from 'types'
import { type InsightCategory } from 'types/cloudInsights'
import { CloudInsightTestId } from 'types/dataTestIds'
import { isCategoryId } from 'utils/insights'
import { useOnce } from 'hooks/useOnce'
import { CollapsableSection } from 'components/CollapsableSection'

import { useAuditQueryParams } from '../../hooks'
import { Header } from './Header'
import { Container } from './CloudInsightsCategory.styles'
import { useAudits } from './CloudInsightsCategory.hooks'
import { CloudInsightsAudit } from '../CloudInsightsAudit'

interface CloudInsightsCategoryProps {
  category: InsightCategory
  testRun: TestRun
}

export const CloudInsightsCategory = ({
  category,
  testRun,
}: CloudInsightsCategoryProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const [{ filter }, setAuditQueryParam] = useAuditQueryParams(testRun)
  const audits = useAudits({ category, testRun })

  const hasAudits = audits.length > 0
  const audit = audits.find((audit) => audit.id === filter)

  const handleCategoryToggle = useCallback(() => {
    if (isOpen && audit) {
      setAuditQueryParam('replace', {
        filter: '',
        open: false,
      })
    }

    setIsOpen(!isOpen)
  }, [isOpen, audit, setAuditQueryParam])

  useOnce(() => {
    setIsOpen(isCategoryId(category.id)(audit))
  }, !!audit)

  return (
    <Container $hasAudits={hasAudits} data-testid={CloudInsightTestId.Category}>
      <CollapsableSection
        key={category.id}
        disabled={!hasAudits}
        label={<Header category={category} testRun={testRun} />}
        isOpen={isOpen}
        onToggle={handleCategoryToggle}
      >
        {audits.map((audit) => (
          <CloudInsightsAudit key={audit.id} audit={audit} testRun={testRun} />
        ))}
      </CollapsableSection>
    </Container>
  )
}
