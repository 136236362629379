import type * as acorn from 'acorn'
import type { NodePath } from './nodePath'
import { getPropertyName } from './utils'

interface BuildOk<T> {
  ok: true
  value: T
}

interface BuildError {
  path: NodePath
  node: acorn.Node
  message: string
}

interface BuildFail {
  ok: false
  errors: BuildError[]
}

type BuildResult<T> = BuildOk<T> | BuildFail

function ok<T>(value: T): BuildResult<T> {
  return {
    ok: true,
    value,
  }
}

function fail<T>(error: BuildError): BuildResult<T> {
  return {
    ok: false,
    errors: [error],
  }
}

function map2<T1, T2, R>(
  first: BuildResult<T1>,
  second: BuildResult<T2>,
  fn: (value1: T1, value2: T2) => R
): BuildResult<R> {
  if (!first.ok && !second.ok) {
    return {
      ok: false,
      errors: [...first.errors, ...second.errors],
    }
  }

  if (!first.ok) {
    return {
      ok: false,
      errors: first.errors,
    }
  }

  if (!second.ok) {
    return {
      ok: false,
      errors: second.errors,
    }
  }

  return {
    ok: true,
    value: fn(first.value, second.value),
  }
}

function join<T>(
  previous: BuildResult<unknown>,
  next: BuildResult<T>
): BuildResult<T> {
  if (!previous.ok && !next.ok) {
    return {
      ok: false,
      errors: [...previous.errors, ...next.errors],
    }
  }

  if (!previous.ok) {
    return {
      ok: false,
      errors: previous.errors,
    }
  }

  if (!next.ok) {
    return {
      ok: false,
      errors: next.errors,
    }
  }

  return {
    ok: true,
    value: next.value,
  }
}

function concat<T>(
  first: BuildResult<T[]>,
  second: BuildResult<T>
): BuildResult<T[]> {
  if (!second.ok && !first.ok) {
    return {
      ok: false,
      errors: [...first.errors, ...second.errors],
    }
  }

  if (!first.ok) {
    return first
  }

  if (!second.ok) {
    return second
  }

  return {
    ok: true,
    value: [...first.value, second.value],
  }
}

function buildArrayElement(
  path: NodePath,
  node: acorn.ArrayExpression['elements'][number]
) {
  if (node === null) {
    return ok(null)
  }

  if (node.type === 'SpreadElement') {
    return fail({
      path,
      node,
      message: 'Spread elements are not supported',
    })
  }

  return buildValue(path, node)
}

function evalUnaryExpression(path: NodePath, node: acorn.UnaryExpression) {
  const value = buildValue(path, node.argument)

  if (!value.ok) {
    return value
  }

  switch (node.operator) {
    case '+':
      return ok(+(value.value as any))

    case '-':
      return ok(-(value.value as any))

    case '!':
      return ok(!(value.value as any))

    default:
      return fail({
        path,
        node,
        message: `Unsupported unary operator: ${node.operator}`,
      })
  }
}

function evalBinaryExpression(path: NodePath, node: acorn.BinaryExpression) {
  if (node.left.type === 'PrivateIdentifier') {
    return fail({
      path,
      node,
      message: `Unsupported left hand side of binary expression: ${node.left.type}`,
    })
  }

  const left = buildValue(path, node.left)

  if (!left.ok) {
    return left
  }

  const right = buildValue(path, node.right)

  if (!right.ok) {
    return right
  }

  switch (node.operator) {
    case '+':
      return ok((left.value as any) + (right.value as any))

    case '-':
      return ok((left.value as any) - (right.value as any))

    case '*':
      return ok((left.value as any) * (right.value as any))

    case '/':
      return ok((left.value as any) / (right.value as any))

    case '%':
      return ok((left.value as any) % (right.value as any))

    case '==':
      // eslint-disable-next-line eqeqeq
      return ok((left.value as any) == (right.value as any))

    case '!=':
      // eslint-disable-next-line eqeqeq
      return ok((left.value as any) != (right.value as any))

    case '===':
      return ok((left.value as any) === (right.value as any))

    case '!==':
      return ok((left.value as any) !== (right.value as any))

    default:
      return fail({
        path,
        node,
        message: `Unsupported binary operator: ${node.operator}`,
      })
  }
}

function evalLogicalExpression(path: NodePath, node: acorn.LogicalExpression) {
  const left = buildValue(path, node.left)

  if (!left.ok) {
    return left
  }

  // Logical expressions are short-circuiting, so we need to hold off evaluating
  // the right-hand side until after testing the left-hand side.
  switch (node.operator) {
    case '&&':
      return left.value ? buildValue(path, node.right) : left

    case '||':
      return left.value ? left : buildValue(path, node.right)

    default:
      return fail({
        path,
        node,
        message: `Unsupported logical operator: ${node.operator}`,
      })
  }
}

function evalConditionalExpression(
  path: NodePath,
  node: acorn.ConditionalExpression
) {
  const test = buildValue(path, node.test)

  if (!test.ok) {
    return test
  }

  return test.value
    ? buildValue(path, node.consequent)
    : buildValue(path, node.alternate)
}

function buildValue(
  path: NodePath,
  node: acorn.Expression
): BuildResult<unknown> {
  switch (node.type) {
    case 'Literal':
      return ok(node.value)

    case 'ObjectExpression':
      return buildObject(path, node)

    case 'UnaryExpression':
      return evalUnaryExpression(path, node)

    case 'BinaryExpression':
      return evalBinaryExpression(path, node)

    case 'LogicalExpression':
      return evalLogicalExpression(path, node)

    case 'ConditionalExpression':
      return evalConditionalExpression(path, node)

    case 'ArrayExpression':
      return node.elements
        .map((element, index) => buildArrayElement([...path, index], element))
        .reduce(concat, ok([] as unknown[]))

    default:
      return fail({
        path,
        node,
        message: `Unsupported expression type: ${node.type}`,
      })
  }
}

/**
 * Given an object expression, this function will attempt to evaluate it
 * and return a plain javascript object. It will fail if the object is
 * reliant on runtime features, such as function calls.
 */
export function buildObject(
  path: NodePath,
  node: acorn.ObjectExpression
): BuildResult<unknown> {
  let result: BuildResult<Record<string, unknown>> = ok({})

  return node.properties.reduce((result, property) => {
    if (property.type !== 'Property') {
      return join(
        result,
        fail({
          path,
          node: property,
          message: `Unsupported property type: ${property.type}`,
        })
      )
    }

    const key = getPropertyName(property)

    if (key === null) {
      return join(
        result,
        fail({
          path,
          node: property,
          message: `Unsupported property key type: ${property.key.type}`,
        })
      )
    }

    return map2(
      result,
      buildValue([...path, key], property.value),
      (result, value) => ({
        ...result,
        [key]: value,
      })
    )
  }, result)
}
