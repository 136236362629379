import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import { TestWithTrends } from 'types'
import { TestPageTestIds } from 'types/dataTestIds'
import { useProject } from 'data/useProject'
import { toTestWithTrendsQueryKey } from 'data/queryKeys'
import { useCanUserScheduleTests } from 'data/usePermissions'
import { Flex } from 'components/Flex'
import { MetaBar, icons } from 'components/MetaBar'
import { Tooltip } from 'components/Tooltip'
import {
  CreateScheduledTestModal,
  EditScheduledTestModal,
} from 'components/ScheduledTests/ScheduledTestsModal'
import { Button } from '@grafana/ui'

export const ScheduledItem = ({
  nextRun,
  test,
}: {
  nextRun: string | null
  test: TestWithTrends
}) => {
  const { data } = useProject(test.project_id)
  const queryClient = useQueryClient()
  const canUserScheduleTests = useCanUserScheduleTests()
  const projectName = data?.name || ''
  const [isOpen, setIsOpen] = useState(false)
  const text = nextRun ? nextRun : 'Set up a schedule'

  const scheduleButton = (
    <Button
      data-testid={TestPageTestIds.EditSchedule}
      onClick={() => setIsOpen(true)}
      fill="text"
      variant="secondary"
      icon={icons.schedule}
      size="sm"
    >
      {text}
    </Button>
  )

  const value = nextRun ? (
    <Flex align="center" gap={0.5}>
      <div>Next run:</div>
      <Tooltip content="Edit schedule">{scheduleButton}</Tooltip>
    </Flex>
  ) : (
    scheduleButton
  )

  const readOnlyValue = nextRun ? `Next run: ${nextRun}` : `No schedule set`
  const content = canUserScheduleTests ? value : readOnlyValue

  const handleDismiss = () => {
    setIsOpen(false)
  }

  const handleOnSuccess = async () => {
    await queryClient.invalidateQueries(toTestWithTrendsQueryKey(test.id))
  }

  const props = {
    isOpen,
    onDismiss: handleDismiss,
    onSuccess: handleOnSuccess,
  }

  return (
    <>
      <MetaBar.Item value={content} />
      {test.schedule ? (
        <EditScheduledTestModal
          {...props}
          scheduledTest={{
            ...test.schedule,
            load_test: {
              id: test.id,
              name: test.name,
              project_id: test.project_id,
              project_name: projectName,
            },
          }}
        />
      ) : (
        <CreateScheduledTestModal
          {...props}
          selectedTest={{
            test_id: test.id,
            test_name: test.name,
            project_id: test.project_id,
            project_name: projectName,
          }}
        />
      )}
    </>
  )
}
