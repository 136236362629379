import styled from 'styled-components'
import { color, spacing } from 'utils/styled'

export const Section = styled.section`
  padding: ${spacing(2)};
  background-color: ${color('background', 'secondary')};
  min-height: 250px;

  [class$='logDetailsDefaultCursor']:hover {
    background-color: transparent;
  }

  .scrollbar-view > div {
    margin-top: 0;
  }

  .wrapper {
    opacity: 0.5;
  }
`
