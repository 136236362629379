import React, { Component, ErrorInfo, ReactNode } from 'react'

import { sentryClient } from 'services/sentryClient'
import { ErrorState } from 'pages/ProjectPage/components/ErrorState'

import { NotFound } from 'pages/NotFound'
import { isNotFound } from 'data/queryClient'
import { PluginPage, isFetchError } from '@grafana/runtime'
import { ErrorBoundaryFullPageView } from 'services/tracking.types'

interface ErrorBoundaryState {
  error: unknown
}

export class PageErrorBoundary extends Component<
  { children?: ReactNode },
  ErrorBoundaryState
> {
  state = {
    error: null,
  }

  static getDerivedStateFromError(error: unknown) {
    return { error }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // Fetch errors handled in datasource
    if (!isFetchError(error)) {
      sentryClient.captureException(error, {
        contexts: {
          react: {
            componentStack: info.componentStack,
          },
        },
      })
    }
  }

  render() {
    if (isNotFound(this.state.error)) {
      return <NotFound />
    }

    if (this.state.error) {
      return (
        <PluginPage>
          <ErrorState
            error={this.state.error}
            view={ErrorBoundaryFullPageView.PageErrorBoundary}
          />
        </PluginPage>
      )
    }

    return this.props.children
  }
}
