import React, {
  ChangeEvent,
  FocusEvent,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useRenameOrganizationToken } from 'data/useRenameOrganizationToken'
import { IconButton, Input } from '@grafana/ui'

interface TokenNameInputProps {
  name: string
  token: string
}

export function TokenNameInput({ name, token }: TokenNameInputProps) {
  const formRef = useRef<HTMLFormElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [locked, setLocked] = useState(true)
  const { mutateAsync, isLoading, error } = useRenameOrganizationToken()
  const [value, setValue] = useState(name)

  useEffect(() => {
    setValue(name)
  }, [name])

  useEffect(() => {
    if (error) {
      setValue(name)
      setLocked(true)
    }
  }, [error, name])

  const isInvalid = !value || value.length > 255
  const canSave = !isInvalid && value !== name

  const handleSave = useCallback(() => {
    if (canSave) {
      mutateAsync({ name: value, token }).catch(() => {
        setValue(name)
      })
    }

    setLocked(true)
  }, [canSave, mutateAsync, name, token, value])

  const handleEditToggle = useCallback(() => {
    setLocked(false)

    requestAnimationFrame(() => {
      inputRef.current?.focus()
    })
  }, [])

  const handleLostFocus = useCallback(
    (event: FocusEvent<HTMLInputElement | HTMLButtonElement>) => {
      if (!formRef.current?.contains(event.relatedTarget)) {
        setLocked(true)
        setValue(name)
      }
    },
    [name]
  )

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    handleSave()
  }

  const suffix = useMemo(() => {
    return locked ? (
      <IconButton
        key="pen"
        name="pen"
        tooltip="Click to edit"
        onClick={handleEditToggle}
      />
    ) : (
      <IconButton
        key="save"
        name="save"
        tooltip="Click to save"
        onClick={handleSave}
        variant={canSave ? 'primary' : undefined}
        disabled={isInvalid}
        onBlur={handleLostFocus}
      />
    )
  }, [
    handleLostFocus,
    canSave,
    handleEditToggle,
    handleSave,
    isInvalid,
    locked,
  ])

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <Input
        value={value}
        ref={inputRef}
        readOnly={locked}
        suffix={suffix}
        onChange={handleChange}
        loading={isLoading}
        invalid={isInvalid}
        onBlur={handleLostFocus}
      />
    </form>
  )
}
