import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { PageErrorBoundary } from 'components/ErrorBoundary'

export function PageRoute({ path, children, render, ...props }: RouteProps) {
  return (
    <Route
      {...props}
      path={path}
      render={(props) => (
        <PageErrorBoundary key={props.match.url}>
          {render !== undefined ? render(props) : <>{children}</>}
        </PageErrorBoundary>
      )}
    />
  )
}
