import React, { useEffect } from 'react'
import { Redirect, Switch, useHistory } from 'react-router-dom'

import { CenteredSpinner } from 'components/CenteredSpinner'
import { useCreateAccountProvisioning } from 'data/useCreateAccountProvisioning'
import { InitPage } from 'pages/InitPage/InitPage'
import { InitPageSimple } from 'pages/InitPage/InitPageSimple'
import { ErrorPage } from 'pages/InitPage/components/ErrorPage'
import { Page } from 'pages/InitPage/components/Page'
import { getRoute } from 'routeMap'
import { PageRoute } from './PageRoute'

export function InitRoutes() {
  const history = useHistory()
  const {
    mutateAsync: createAccountProvisioning,
    isLoading,
    isSuccess,
    isError,
  } = useCreateAccountProvisioning()

  useEffect(() => {
    if (isSuccess) {
      history.replace(getRoute('home'))
    }
  }, [history, isSuccess])

  if (isLoading) {
    return (
      <Page navText="Initializing...">
        <div className="page-loader-wrapper">
          <CenteredSpinner />
        </div>
      </Page>
    )
  }

  if (isError) {
    return (
      <ErrorPage
        title="Initialization error"
        message="Unable to initialize k6 for your stack."
      />
    )
  }

  return (
    <Switch>
      <PageRoute path={getRoute('home')} exact>
        <InitPage onInitialize={createAccountProvisioning} />
      </PageRoute>
      <PageRoute
        path={[
          getRoute('projectsList'),
          getRoute('settings'),
          getRoute('manage'),
          getRoute('learn'),
        ]}
      >
        <InitPageSimple onInitialize={createAccountProvisioning} />
      </PageRoute>
      <Redirect to={getRoute('home')} />
    </Switch>
  )
}
