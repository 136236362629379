import React from 'react'

import { Section } from 'components/Section'
import { LimitsTable } from './components/LimitsTable'
import { useOrgProjectSettings } from 'data/useProjectSettings'
import { DocLink, docs } from 'components/DocLink'

export const LimitsTab = () => {
  const { data = [], isLoading } = useOrgProjectSettings()
  return (
    <Section
      title="Limits and quotas"
      description={
        <>
          You can define per project limits and quotas. Once set, we will abort
          tests that exceed the monthly VUh quota or the per test VU/Duration
          limit.
          <br />
          Learn more in the{' '}
          <DocLink article={docs.projects.limits}>
            project limits and quotas documentation
          </DocLink>
          .
        </>
      }
    >
      <LimitsTable data={data} isLoading={isLoading} />
    </Section>
  )
}
