import { useCallback, useEffect, useState } from 'react'
import { Project } from 'types'
import type { TestDraft } from '../drafts'
import { useDatasource } from 'datasourceContext'
import { useSubscriptionRules } from 'data/useSubscriptions'
import type { ValidationContext, ValidationReason } from './types'
import { ValidationError } from './errors'
import { validateDraft } from './validate'

interface ValidatingState {
  type: 'validating'
  errors: ValidationError[]
}

export interface ValidState {
  type: 'valid'
  errors: []
}

export interface InvalidState {
  type: 'invalid'
  errors: [ValidationError, ...ValidationError[]]
}

export type ValidationState = ValidatingState | ValidState | InvalidState

export function useValidationState(project: Project, draft: TestDraft) {
  const rules = useSubscriptionRules()
  const { ds: datasource } = useDatasource()

  const [validationState, setValidationState] = useState<ValidationState>(
    draft.readOnly
      ? { type: 'valid', errors: [] }
      : { type: 'validating', errors: [] }
  )

  const validate = useCallback(
    async (
      reason: ValidationReason,
      draft: TestDraft,
      signal?: AbortSignal
    ) => {
      const context: ValidationContext = {
        reason,
        project,
        datasource,
        rules,
      }

      const errors = await validateDraft(context, draft)

      if (signal?.aborted) {
        return {
          aborted: true,
        } as const
      }

      if (errors.length > 0) {
        const state: InvalidState = {
          type: 'invalid',
          errors: errors as [ValidationError, ...ValidationError[]],
        }

        setValidationState(state)

        return {
          aborted: false,
          state,
        } as const
      }

      const state: ValidState = {
        type: 'valid',
        errors: [],
      }

      setValidationState(state)

      return {
        aborted: false,
        state,
      } as const
    },
    [datasource, rules, project]
  )

  useEffect(() => {
    const controller = new AbortController()

    setValidationState((state) => ({
      type: 'validating',
      errors: state.errors,
    }))

    const handle = setTimeout(() => {
      validate('change', draft, controller.signal)
    }, 400)

    return () => {
      controller.abort()

      clearTimeout(handle)
    }
  }, [draft, validate])

  return [validationState, validate] as const
}

export type { ValidationError }
