import styled from 'styled-components'
import { color, spacing } from 'utils/styled'

export const StatsContainer = styled.div`
  font-size: 0.8rem;
  color: ${color('text', 'primary')};

  time {
    font-style: italic;
    white-space: nowrap;
    font-size: 0.7rem;
    color: ${color('text', 'secondary')};
  }

  h6 {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-gap: ${spacing(1)};
    align-items: center;
    text-align: center;
    color: ${color('text', 'secondary')};
    font-size: 0.8rem;
    font-weight: 400;
    margin-bottom: ${spacing(1)};

    &::before,
    &::after {
      content: '';
      border-top: 1px solid;
      opacity: 0.25;
    }
  }
`

export const MostRecentRunContainer = styled.div`
  flex: 1 0 100%;
  width: 100%;

  > div {
    width: 100%;
    display: flex;
    align-items: baseline;

    a {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  time {
    font-style: italic;
    white-space: nowrap;
    font-size: 0.7rem;
    color: ${color('text', 'secondary')};
  }
`

export const Field = styled.div`
  flex: 1;
`

export const Value = styled.div`
  font-size: ${({ theme }) => theme.typography.h5.fontSize};
  font-weight: 500;
`

export const Unit = styled.span`
  font-size: 0.7rem;
  font-weight: 400;
  color: ${color('text', 'secondary')};
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  gap: ${spacing(0.5)};
  text-transform: uppercase;
  white-space: nowrap;
`
