import styled from 'styled-components'
import ChartPlaceholderImage from 'assets/images/chart_placeholder.svg'
import { color, fontSize, spacing, value } from 'utils/styled'

export const TrendChartContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TrendChartWrapper = styled.div`
  flex: 1 1 0;
`

export const TrendChartLegend = styled.div`
  height: ${spacing(3)};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: ${fontSize('bodySmall')};
  span:last-child {
    padding-left: ${spacing(1)};
  }
  // Legend text is slightly misaligned from the chart panel due to
  // the PanelChrome having a border of 3px, so we need to correct it.
  padding: 3px;
`

interface ChartPlaceholderWrapperProps {
  $height: number
}

interface ChartPlaceholderBackgroundProps {
  $hasPlaceholder: boolean
}

export const ChartPlaceholderWrapper = styled.div<ChartPlaceholderWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: ${value('$height')}px;
`

export const ChartPlaceholderContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  color: ${color('text', 'secondary')};
`

export const ChartPlaceholderBackground = styled.div<ChartPlaceholderBackgroundProps>`
  flex: 1 1 0;
  width: 100%;
  background-image: url(${ChartPlaceholderImage});
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: ${({ $hasPlaceholder }) =>
    $hasPlaceholder ? `auto 54%` : `auto 100%`};
  max-height: 60%;
  opacity: 0.3;
`
