import styled from 'styled-components'

import { Card } from 'components/Card'
import { UsersLabel, RefetchIconContainer } from 'components/Members/Members'
import { spacing } from 'utils/styled'

export const StyledCard = styled(Card)`
  min-height: 96px;
  height: 100%;
`

export const MembersContainer = styled.div`
  min-height: 32px;
  margin-bottom: ${spacing(2)};
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  ${UsersLabel} {
    font-size: 0.8rem;
    text-transform: uppercase;
  }

  ${RefetchIconContainer} {
    position: relative;
    top: -2px;
  }
`
