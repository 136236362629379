import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TableColumn } from 'react-data-table-component'
import debounce from 'lodash/debounce'

import { useProjectRolesWithUser } from 'data/useProjectRoles'
import { ProjectId, ProjectRoleWithUser } from 'types'
import { ORGANIZATION_ROLE_TYPES } from 'constants/roles'
import { useAppContext } from 'appContext'
import { ProjectSettingsTestIds } from 'types/dataTestIds'
import { LoadingContainer } from 'components/LoadingContainer'
import { EmptyFilterView } from 'components/EmptyFilterView'
import { DataTable } from 'components/DataTable'
import { Avatar } from 'components/Avatar'
import { ConfirmModal } from 'components/ConfirmModal'
import {
  generateBatchDeleteActions,
  useBatching,
} from 'components/DataTable/hooks/useBatching'

import { ProjectRoleCell } from './ProjectRoleCell'
import { ProjectRolesTableSearch } from './ProjectRolesTableSearch'
import { useDeleteProjectRolesConfirmation } from './ProjectRolesTable.hooks'
import { Flex } from 'components/Flex'
import { ProjectSettingsInviteUsers } from './InviteUsersForm'

const PAGE_SIZE = 20
const DEFAULT_SORTING = '-id' // We sort by default to show latest users

function getColumns(
  projectId: ProjectId
): Array<TableColumn<ProjectRoleWithUser>> {
  return [
    {
      name: '',
      cell: (row) => (
        <Avatar gravatar_url={row.user?.gravatar_url} isLoading={!row.user} />
      ),
      width: '42px',
      style: {
        paddingRight: '0px',
      },
    },
    {
      name: 'Email',
      sortable: true,
      sortField: 'user_email',
      cell: (row) => <span>{row.user_email}</span>,
      grow: 1,
    },
    {
      name: 'Role',
      sortable: true,
      sortField: 'role_id',
      cell: (row) => <ProjectRoleCell role={row} projectId={projectId} />,
      maxWidth: '200px',
    },
  ]
}

interface ProjectRolesTableProps {
  projectId: ProjectId
}

export const ProjectRolesTable = ({ projectId }: ProjectRolesTableProps) => {
  const [orderBy, setOrderBy] = useState<string>(DEFAULT_SORTING)
  const { emailFilter, debouncedEmailFilter, handleOnEmailFilterChange } =
    useEmailFilter()
  const { account } = useAppContext()

  const handleOnSort = useCallback((order: string | undefined) => {
    if (!order) {
      setOrderBy(DEFAULT_SORTING)
      return
    }

    const [sortBy, sortOrder] = order.split(' ') as [string, 'desc' | 'asc']
    setOrderBy(sortOrder === 'desc' ? `-${sortBy}` : sortBy)
  }, [])

  const {
    data: projectRoles,
    currentPage,
    fetchPage,
    count,
    isLoading,
    isFetching,
  } = useProjectRolesWithUser(projectId, {
    orderBy,
    pageSize: PAGE_SIZE,
    keepPreviousData: true,
    emailFilter: debouncedEmailFilter,
  })

  const hasNextPageStaleData = useHasNextPageHasStaleData(
    isFetching,
    count,
    currentPage
  )
  const nonAdminRoles = useMemo(
    () =>
      projectRoles.filter(
        (role) =>
          role.org_role_id !== ORGANIZATION_ROLE_TYPES.ADMIN &&
          role.user_id !== account.user.id
      ),
    [projectRoles, account]
  )

  const batchAction = useBatching<ProjectRoleWithUser>(nonAdminRoles)
  const [deleteConfirmationProps, handleDelete] =
    useDeleteProjectRolesConfirmation(projectId, batchAction.reset)

  const isDisabled = useCallback(
    (role: ProjectRoleWithUser) =>
      role.org_role_id === ORGANIZATION_ROLE_TYPES.ADMIN ||
      role.user_id === account.user.id,
    [account.user.id]
  )

  const actions = useMemo(
    () =>
      generateBatchDeleteActions(batchAction, handleDelete, isDisabled, {
        noun: 'user',
        action: 'Remove',
      }),
    [batchAction, handleDelete, isDisabled]
  )

  return (
    <LoadingContainer loading={isLoading || hasNextPageStaleData}>
      <Flex direction="column" gap={1}>
        <Flex gap={1} justify="flex-end">
          <ProjectRolesTableSearch
            value={emailFilter}
            onChange={handleOnEmailFilterChange}
          />
          <ProjectSettingsInviteUsers projectId={projectId} />
        </Flex>

        <DataTable
          actions={actions}
          columns={getColumns(projectId)}
          data-testid={ProjectSettingsTestIds.ProjectRolesTable}
          noDataComponent={
            <EmptyFilterView onClear={() => handleOnEmailFilterChange('')}>
              No records matched your filter(s)
            </EmptyFilterView>
          }
          data={projectRoles}
          highlightOnHover={false}
          keyField="user_id"
          isFetching={isFetching}
          pagination
          paginationServer
          paginationPerPage={PAGE_SIZE}
          paginationTotalRows={count}
          currentPage={currentPage}
          onChangePage={fetchPage}
          onSort={handleOnSort}
          sortServer
        />
      </Flex>
      <ConfirmModal title="Confirm action" {...deleteConfirmationProps} />
    </LoadingContainer>
  )
}

// To minimize layout shift we show a loader if the data has been modified (user added/deleted)
const useHasNextPageHasStaleData = (
  isFetching: boolean,
  count: number | undefined,
  currentPage: number
) => {
  const [prevCount, setPrevCount] = useState<number>()
  const [prevPage, setPrevPage] = useState<number>()

  useEffect(() => {
    if (!isFetching) {
      setPrevCount(count)
      setPrevPage(currentPage)
    }
  }, [count, currentPage, isFetching])

  return prevCount !== count && prevPage !== currentPage
}

const useEmailFilter = () => {
  const [emailFilter, setEmailFilter] = useState('')
  const [debouncedEmailFilter, setDebouncedEmailFilter] = useState(emailFilter)

  const updateDebouncedEmailFilter = useMemo(
    () => debounce((filter) => setDebouncedEmailFilter(filter), 500),
    []
  )

  return {
    emailFilter,
    debouncedEmailFilter,
    handleOnEmailFilterChange: (value: string) => {
      setEmailFilter(value)

      if (value === '') {
        updateDebouncedEmailFilter.cancel()
        setDebouncedEmailFilter('')
      } else {
        updateDebouncedEmailFilter(value)
      }
    },
  }
}
