import styled from 'styled-components'
import { spacing } from 'utils/styled'

export const SummaryExportMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
`

export const ExportSettings = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing(1)};
`
