import React from 'react'
import styled from 'styled-components'
import { noop } from 'lodash-es'

import { Select, Tooltip } from '@grafana/ui'
import { SelectableValue } from '@grafana/data'

import {
  ORGANIZATION_ROLE_TYPES,
  PROJECT_ROLE_TYPES,
  PROJECT_TYPE_CHOICES,
} from 'constants/roles'
import { useUpdateProjectRole } from 'data/useProjectRoles'
import { ProjectRoleWithUser } from 'types'
import { showAlert } from 'utils/showAlert'

export const ProjectRoleCell = ({
  role,
  projectId,
}: {
  role: ProjectRoleWithUser
  projectId: number
}) => {
  const { mutate: updateProjectRole, isLoading } = useUpdateProjectRole()

  const handleOnChange = (value: SelectableValue<number>) => {
    const valueNumber = Number(value.value)

    if (isLoading || valueNumber === role.role_id) {
      return
    }

    updateProjectRole(
      {
        id: role.id,
        user_id: role.user_id,
        role_id: valueNumber,
        project_id: projectId,
      },
      {
        onSuccess: () => showAlert('Successfully updated project role'),
        onError: () =>
          showAlert(
            'Failed to update project role, please try again later',
            'error'
          ),
      }
    )
  }

  if (role.role_id === PROJECT_ROLE_TYPES.ADMIN) {
    const isOrgAdmin = role.org_role_id === ORGANIZATION_ROLE_TYPES.ADMIN
    const label = isOrgAdmin ? 'Admin' : 'Project admin'

    return (
      <Tooltip
        content={`Can not change ${!isOrgAdmin ? 'project ' : ''}admin role`}
      >
        <SelectContainer>
          <Select
            disabled
            value={role.role_id}
            options={[{ label, value: role.role_id }]}
            onChange={noop}
          />
        </SelectContainer>
      </Tooltip>
    )
  }

  return (
    <SelectContainer>
      <Select
        value={role.role_id}
        options={PROJECT_TYPE_CHOICES}
        onChange={handleOnChange}
      />
    </SelectContainer>
  )
}

const SelectContainer = styled.span`
  max-width: 240px;
`
