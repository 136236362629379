import React from 'react'
import { Instructions } from 'components/Instructions'
import { CodeSnippet } from 'components/CodeSnippet'
import {
  applyCommand,
  createNamespaceCommand,
  createSecretCommand,
  installationTabs,
  yamlConfig,
} from './LoadZoneGuide.utils'
import { Link } from 'react-router-dom'
import { routeMap } from 'routeMap'

export const SetupPlzGuide = () => {
  return (
    <Instructions.Section>
      <Instructions>
        <Instructions.Step title="Install the k6 Kubernetes Operator">
          <CodeSnippet
            code=""
            lang="plain"
            tabs={installationTabs}
            initialTab="bundle"
          />
        </Instructions.Step>

        <Instructions.Step title="Create a Kubernetes namespace:">
          <CodeSnippet
            code={createNamespaceCommand}
            dedent={false}
            lang="plain"
          />
        </Instructions.Step>

        <Instructions.Step
          title={
            <>
              Create a Kubernetes secret with a{' '}
              <Link to={routeMap.stackToken}>Grafana Stack API token</Link>:
            </>
          }
        >
          <CodeSnippet code={createSecretCommand} lang="plain" />
        </Instructions.Step>

        <Instructions.Step title="Create a YAML file with the PLZ resource definition. The simplest is as follows:">
          <CodeSnippet code={yamlConfig} dedent={false} lang="plain" />
        </Instructions.Step>

        <Instructions.Step title="Apply the PLZ with the above file:">
          <CodeSnippet code={applyCommand} lang="plain" />
        </Instructions.Step>
      </Instructions>
    </Instructions.Section>
  )
}
