import styled, { css } from 'styled-components'
import { color, fontSize, spacing } from 'utils/styled'
import { ColumnTypeStyles } from './styles'

export const HeaderRow = styled.tr``

interface ColumnHeadingProps {
  shrink?: boolean
  numeric?: boolean
}

export const ColumnHeading = styled.th<ColumnHeadingProps>`
  ${ColumnTypeStyles}

  // This padding is one less than the padding of the TableData
  // component because it aligns the sort-order icon a little bit better.
  padding-right: ${spacing(1)};

  ${({ shrink = false }) => {
    return (
      shrink &&
      css`
        width: 1%;
      `
    )
  }}

  text-transform: uppercase;
`

interface SortButtonProps {
  sorting: boolean
}

export const SortButton = styled.button<SortButtonProps>`
  display: inline-flex;
  align-items: center;

  font-size: ${fontSize('bodySmall')};
  font-weight: 500;
  text-transform: uppercase;

  padding: 0;
  border: none;
  background: none;

  &:hover {
    opacity: 0.7;
  }

  ${({ sorting }) =>
    sorting &&
    css`
      color: ${color('primary', 'main')};
    `}
`

export const TableMenuOverlay = styled.div`
  width: fit-content;
  background-color: ${color('background', 'primary')};
  box-shadow: ${({ theme }) => theme.shadows.z3};
  padding: ${spacing(1, 1)};
`
