import React from 'react'
import { Icon, IconSize } from '@grafana/ui'

import { BadgeColorCodes, BadgeColors, TestRun } from 'types'
import { getRunStatusColor, getTestStatusText } from 'pages/utils'
import { ColoredIcon, Indicator } from './StatusIcon.styles'
import { isTestActive, isTestSafe } from 'utils/testRun'
import { usePrivateLoadZoneIsHanging } from 'components/TestRunPendingState/TestRunPendingState.hooks'
import { Tooltip } from 'components/Tooltip'
import { LoadZoneTestHangingWarning } from '../LoadZoneTestHangingWarning'

export interface StatusIconProps {
  testRun?: TestRun
  size?: IconSize
  spinner?: boolean
  showSaveState?: boolean
}

export const StatusIcon = ({
  testRun,
  size = 'md',
  spinner = false,
  showSaveState = false,
}: StatusIconProps) => {
  const isHanging = usePrivateLoadZoneIsHanging(testRun)

  if (testRun === undefined) {
    return <Indicator $color={BadgeColors.GRAY} $size={size} />
  }

  if (isHanging) {
    return (
      <Tooltip
        placement={'bottom-start'}
        content={<LoadZoneTestHangingWarning showIcon={false} />}
      >
        <Icon
          name="exclamation-triangle"
          size={size}
          color={BadgeColorCodes[BadgeColors.ORANGE]}
        />
      </Tooltip>
    )
  }

  const statusText = getTestStatusText(testRun)

  const statusColor = getRunStatusColor(testRun)

  if (showSaveState && testRun.is_baseline) {
    return (
      <Tooltip content={`${statusText} (Baseline)`} placement={'bottom-start'}>
        <ColoredIcon
          type="solid"
          name="star"
          size={size}
          $color={statusColor}
        />
      </Tooltip>
    )
  }

  if (showSaveState && isTestSafe(testRun)) {
    return (
      <Tooltip content={`${statusText} (Saved)`} placement={'bottom-start'}>
        <ColoredIcon
          type="solid"
          name="lock"
          size={size}
          $color={statusColor}
        />
      </Tooltip>
    )
  }

  return (
    <Tooltip content={statusText} placement="bottom-start">
      {spinner && isTestActive(testRun) ? (
        <Icon name="fa fa-spinner" />
      ) : (
        <Indicator $color={statusColor} $size={size} />
      )}
    </Tooltip>
  )
}
