import React from 'react'
import { Button } from '@grafana/ui'

import { useTimeRange } from '../TimeRangeContext'

export const TimeRangeResetButton = () => {
  const { timeRange, setTimeRange } = useTimeRange()

  if (!timeRange) {
    return null
  }

  return (
    <Button fill="outline" icon="history" onClick={() => setTimeRange()}>
      Reset time range
    </Button>
  )
}
