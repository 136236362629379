import React from 'react'
import styled from 'styled-components'
import { useTheme2 } from '@grafana/ui'
import { useIsDarkTheme } from 'hooks/useIsDarkTheme'

const StyledSVG = styled.svg`
  position: absolute;
  top: -2px;
  z-index: 1; // To be above legend dots

  &.up {
    top: unset;
    bottom: -2px;
    transform: rotate(180deg);
  }
`

interface GaugeArrowProps {
  value: number
  type?: 'primary' | 'secondary'
}

export function GaugeArrow({ value, type }: GaugeArrowProps) {
  const isDarkTheme = useIsDarkTheme()
  const theme = useTheme2()
  const direction = type === 'secondary' ? 'up' : 'down'
  const fill =
    type === 'primary' ? theme.colors.primary.text : theme.colors.text.primary
  const strokeColor = isDarkTheme
    ? theme.colors.background.secondary
    : theme.colors.background.canvas

  return (
    <StyledSVG
      width="10px"
      className={direction}
      viewBox="0 0 35.6 17.8"
      style={{ left: `calc(${value}% - 5px)` }}
    >
      <rect
        transform="translate(5.8 -14) rotate(45 12 12)"
        width="24"
        height="24"
        fill={fill}
        stroke={strokeColor}
        strokeWidth="4"
      />
    </StyledSVG>
  )
}
