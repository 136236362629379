import React from 'react'
import { Link } from 'react-router-dom'

import { routeMap } from 'routeMap'
import { DocLink, docs } from 'components/DocLink'
import { ConfirmModal, ConfirmModalProps } from 'components/ConfirmModal'
import { useMaxSavedRunsModal } from 'components/MaxSavedRunsModal'
import { TestRun } from 'types'
import { isTestSafe } from 'utils/testRun'
import { CenteredSpinner } from '../../../../../../components/CenteredSpinner'
import { useIsTestSafeOnOpen } from './ToggleSafe.hooks'

interface ToggleSafeProps
  extends Pick<
    ConfirmModalProps,
    'isOpen' | 'isPending' | 'onConfirm' | 'onDismiss'
  > {
  testRun: TestRun
}

export const ToggleSafe = ({
  testRun,
  ...confirmationProps
}: ToggleSafeProps) => {
  const isSafe = useIsTestSafeOnOpen(
    confirmationProps.isOpen,
    isTestSafe(testRun)
  )

  const { count, maxAllowed, isLoading, isError } = useMaxSavedRunsModal(
    confirmationProps.onConfirm
  )

  return (
    <ConfirmModal
      {...confirmationProps}
      confirmButtonVariant="primary"
      title={isSafe ? 'Remove safe status' : 'Save test results'}
      body={
        isLoading ? (
          <CenteredSpinner />
        ) : (
          <>
            {!isError && (
              <p>
                You have saved {count} out of {maxAllowed} test results allowed
                by your subscription.
              </p>
            )}
            <p>
              {isSafe
                ? 'By removing the safe status, you are making the test results deletable by the '
                : 'Saving the test results prevents it from being deleted by the '}
              <DocLink article={docs.subscription.dataRetention}>
                data retention policy
              </DocLink>
              .
            </p>
            <p>
              You can find saved results on{' '}
              <Link to={routeMap.savedTestRuns}>saved tests page</Link>.
            </p>
          </>
        )
      }
      canConfirm={!isLoading}
      confirmText={isSafe ? 'Remove' : 'Save'}
    />
  )
}
