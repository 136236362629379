import { Icon } from '@grafana/ui'
import styled from 'styled-components'
import { color, spacing } from 'utils/styled'

export const PageHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`

export const PageHeading = styled.h1`
  margin: 0;
`

export const PageIcon = styled(Icon)`
  color: ${color('text', 'secondary')};
  margin-right: ${spacing(1)};
`
