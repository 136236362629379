import React, { useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useAppContext } from 'appContext'
import { getRoute } from 'routeMap'
import { ConfirmModal } from 'components/ConfirmModal'
import { toFormErrors } from 'utils/form'

import { toNotificationScriptError } from './NotificationForm.utils'
import {
  NotificationChannelDraft,
  NotificationClient,
  NotificationScriptError,
} from '../../NotificationsTab.types'
import { NOTIFICATION_CHANNEL_DRAFTS } from '../../NotificationsTab.constants'
import { useCreateNotification } from '../../hooks/useNotifications'
import { FormWrapper } from './NotificationForm.styles'
import { NotificationClientSelect } from '../NotificationClientSelect'
import { NotificationForm } from './NotificationForm'
import { useConfirmPromptOnLeave } from 'hooks/useConfirmPrompt'

const DEFAULT_CLIENT = NotificationClient.SLACK

export const CreateNotificationForm = () => {
  const { account } = useAppContext()
  const history = useHistory()
  const { state: locationState } = useLocation<{
    notificationClient?: NotificationClient
  }>()
  const preselectedNotificationClient =
    locationState?.notificationClient || DEFAULT_CLIENT
  const [scriptError, setScriptError] = useState<NotificationScriptError>()

  const [isDiscardChangesOpen, setIsDiscardChangesOpen] = useState(false)

  const form = useForm<NotificationChannelDraft>({
    defaultValues: NOTIFICATION_CHANNEL_DRAFTS[preselectedNotificationClient],
  })

  const onDismiss = useCallback(() => {
    history.push(getRoute('notifications'))
  }, [history])

  // If user changes client and has dirtied the form, they'll be prompted to discard changes, this value will contain the next selectedClient value if the user confirms
  const [nextSelectedClient, setNextSelectedClient] =
    useState<NotificationClient>()
  const [selectedClient, setSelectedClient] = useState(
    preselectedNotificationClient
  )

  const resetNotificationDraft = useCallback(
    (clientType: NotificationClient) => {
      form.reset({
        ...NOTIFICATION_CHANNEL_DRAFTS[clientType],
        // The weird design of the endpoints dictate that when dealing with an email notification, the URL field, will contain the emails of the recipients
        url: clientType === NotificationClient.EMAIL ? account.user.email : '',
      })
    },
    [account.user.email, form]
  )

  const handleChangeClient = useCallback(
    (clientType: NotificationClient) => {
      if (Object.keys(form.formState.dirtyFields).length > 0) {
        setNextSelectedClient(clientType)
        setIsDiscardChangesOpen(true)
        return
      }

      setSelectedClient(clientType)
      resetNotificationDraft(clientType)
    },
    [form.formState.dirtyFields, resetNotificationDraft]
  )

  const { mutateAsync: createNotification, isLoading: isCreatingNotification } =
    useCreateNotification({
      onSuccess: onDismiss,
      onError: (errors) => {
        toFormErrors(errors).forEach(([field, error]) => {
          if (field !== 'template') {
            form.setError(field, error)
            return
          }

          const { fieldError, errorDetails } = toNotificationScriptError(errors)
          form.setError(field, fieldError, { shouldFocus: true })
          setScriptError(errorDetails)
        })
      },
    })

  const handleSubmitForm = form.handleSubmit(
    async (notificationDraft) => await createNotification(notificationDraft)
  )

  const handleConfirmDiscardChanges = useCallback(() => {
    setIsDiscardChangesOpen(false)

    if (!nextSelectedClient) {
      return
    }

    setSelectedClient(nextSelectedClient)
    resetNotificationDraft(nextSelectedClient)
    setNextSelectedClient(undefined)
  }, [nextSelectedClient, resetNotificationDraft])

  const handleCancelDiscardChanges = () => {
    setNextSelectedClient(undefined)
    setIsDiscardChangesOpen(false)
  }

  useConfirmPromptOnLeave({
    show:
      Object.keys(form.formState.dirtyFields).length > 0 &&
      !isCreatingNotification,
  })

  return (
    <FormProvider {...form}>
      <FormWrapper>
        <NotificationClientSelect
          onClick={handleChangeClient}
          selectedClient={selectedClient}
          isRadio
        />
        <NotificationForm
          isNewNotification
          scriptError={scriptError}
          isLoading={isCreatingNotification}
          onSubmitForm={handleSubmitForm}
          onDismissForm={onDismiss}
        />
      </FormWrapper>
      <ConfirmModal
        title="Discard changes?"
        body="Changing notification type will discard the changes made for the new notification."
        confirmText="Confirm"
        isOpen={isDiscardChangesOpen}
        onConfirm={handleConfirmDiscardChanges}
        onDismiss={handleCancelDiscardChanges}
      />
    </FormProvider>
  )
}
