import { TestRunWithMetrics } from 'types'
import { isTestActive, isTestRunning } from 'utils/testRun'
import { ActionCellProps } from 'components/DataTable/DataTable.types'

type GetCellProps = (
  item: TestRunWithMetrics,
  isSelectionMode: boolean,
  selectedItems: TestRunWithMetrics[]
) => ActionCellProps<TestRunWithMetrics>

export const switchCellProps = (
  deleteTestRunAction: GetCellProps,
  stopTestRunAction: GetCellProps
): GetCellProps => {
  return (item, isSelectionMode, selectedItems) => {
    if (isTestActive(item)) {
      return stopTestRunAction(item, isSelectionMode, selectedItems)
    }

    return deleteTestRunAction(item, isSelectionMode, selectedItems)
  }
}

export const createStopTestRunAction = ({
  onClick,
}: {
  onClick: (testRun: TestRunWithMetrics) => void
}) => {
  return (
    item: TestRunWithMetrics,
    isSelectionMode: boolean
  ): ActionCellProps<TestRunWithMetrics> => {
    return {
      'aria-label': 'Stop test run',
      disabled: isSelectionMode || !isTestRunning(item),
      onClick,
      name: 'minus-circle',
      variant: 'destructive',
    }
  }
}
