import { orderBy } from 'lodash-es'

import { TestRun, TestRunWithMetrics } from 'types'
import { dateFormatter, resolveTimeZone } from 'utils/date'
import {
  getTestRunLabel,
  isTestRunExpired,
  isTestRunComparable,
} from 'utils/testRun'

import { SelectableTestRun } from './TestRunsSelect.types'

interface GroupedOption {
  label: string
  options: SelectableTestRun[]
  expanded: boolean
}

const formatDate = (date: string) => {
  return dateFormatter.tz(date, 'MMM dd HH:mm', resolveTimeZone())
}

const createOption = (
  selected: TestRun[],
  testRun: TestRunWithMetrics
): SelectableTestRun => {
  const label = getTestRunLabel(testRun)
  const date = testRun.started && formatDate(testRun.started)
  const [left, right] = selected
  const isBeingCompared = left && right
  const isLeft = isBeingCompared && testRun.id === left?.id
  const isRight = isBeingCompared && testRun.id === right?.id

  return {
    value: testRun.id,
    label: !!testRun.note && date ? `${date} - ${label}` : label,
    disabled:
      selected.some((run) => run.id === testRun.id) ||
      !isTestRunComparable(testRun),
    testRun: testRun,
    suffix: isLeft ? 'Left' : isRight ? 'Right' : '',
  }
}

export const createGroupedOptions = (
  selected: TestRun[],
  testRuns: TestRunWithMetrics[]
) => {
  const options = testRuns
    .filter((testRun) => !isTestRunExpired(testRun))
    .map((testRun) => createOption(selected, testRun))

  return groupByDate(options)
}

// Pretty much the same as lodash version, but it uses a Map instead of an
// object to guarantee that the insertion order of keys is preserved.
function groupBy<K, T>(values: T[], keySelector: (value: T) => K) {
  return values.reduce((result, value) => {
    const key = keySelector(value)
    const list = result.get(key) ?? []

    list.push(value)

    result.set(key, list)

    return result
  }, new Map<K, T[]>())
}

export function groupByDate(options: SelectableTestRun[]): GroupedOption[] {
  const timezone = resolveTimeZone()

  const datedOptions = options.map((option) => {
    const date = dateFormatter.parseTz(
      option.testRun.started ?? option.testRun.created,
      timezone
    )

    return {
      year: date.getFullYear(),
      date,
      option,
    }
  })

  const orderedOptions = orderBy(datedOptions, (option) => option.date, 'desc')

  const showYears = new Set(datedOptions.map((option) => option.year)).size > 1
  const labelFormat = showYears ? 'yyyy MMM' : 'MMM'

  const groupedOptions = groupBy(orderedOptions, (option) =>
    dateFormatter.format(option.date, labelFormat)
  )

  return [...groupedOptions.entries()].map(([label, options]) => {
    return {
      label,
      expanded: true,
      options: options.map((option) => option.option),
    }
  })
}
