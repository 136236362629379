import React, { useMemo } from 'react'
import { type AbsoluteTimeRange } from '@grafana/data'

import { TestRunAnalysis, WSValue, hasWsMetricSummary } from 'types'
import { RunsPageTestIds } from 'types/dataTestIds'
import { WSChart } from './WSChart'

import { useTimeRange } from '../../../TimeRangeContext'
import { useRunDetailsWebSocketsFilters } from '../../Filters/Filters.hooks'
import { WSFilters } from './WSFilters'
import { TableDefinition } from '../BreakdownTable'
import { toWebSocketsQueryKey } from 'data/queryKeys'
import { WebSocketClient, useWebSocketClient } from 'data/clients/entities/ws'
import { useEnumParameter } from 'hooks/useQueryParameter'
import { WSUrlCell } from './WSUrlCell'
import { TooltipCell } from '../BreakdownTable/Table/TooltipCell'
import { WSEmptyView } from './WSEmptyView'
import { EmptyFilterView } from 'components/EmptyFilterView'
import { BreakdownView } from '../BreakdownView'
import { FilterExpression, serializeRunFilter } from 'datasource/serialization'
import { byGroup, byRootGroup } from '../utils'
import { asCount, asTiming } from '../ComparedValue'
import { QueryErrorBoundary } from 'components/ErrorBoundary'
import { ErrorBoundaryLocalView } from 'services/tracking.types'

interface WebSocketTableProps {
  client: WebSocketClient
  filter: FilterExpression<WSValue>
  timeRange: AbsoluteTimeRange | undefined
}

export const table: TableDefinition<WSValue, WebSocketTableProps> = {
  id: 'websocket-breakdown',
  paginator: 'always',
  pageSize: 20,

  keyBy(row) {
    return row.id
  },

  getRowCount({ ws_metric_summary }) {
    return ws_metric_summary.sessions ?? 0
  },

  groupBy(row) {
    return row.group_id
  },

  fetchPage({ client, testRun, filter, ...rest }) {
    const params = {
      ...rest,
      filter: byRootGroup(filter),
    }

    return {
      queryKey: toWebSocketsQueryKey(testRun.id, params),
      queryFn: () => client.fetchPage(testRun.id, params),
    }
  },

  fetchByRows({ client, testRun, rows }) {
    const ids = rows.map((ws) => ws.id).sort()

    return {
      queryKey: toWebSocketsQueryKey(testRun.id, { ids }),
      queryFn: () => client.fetchByIds(testRun.id, ids),
    }
  },

  fetchByGroup({ client, testRun, group, filter, ...rest }) {
    const params = {
      ...rest,
      filter: byGroup(group, filter),
    }

    return {
      queryKey: toWebSocketsQueryKey(testRun.id, params),
      queryFn: (page) => client.fetchPage(testRun.id, { ...params, page }),
    }
  },

  columns: [
    {
      id: 'name',
      name: 'URL',
      sortBy: 'name',
      toggle: 'none',

      renderCell: ({ name }) => {
        return <WSUrlCell name={name} />
      },
      renderGroup: ({ name }) => <>{name}</>,
    },
    {
      id: 'scenario',
      name: 'Scenario',
      sortBy: 'scenario',
      toggle: 'none',

      renderCell: ({ scenario }) => (
        <TooltipCell show={scenario !== 'default'} tooltip={scenario}>
          {scenario}
        </TooltipCell>
      ),
    },
    {
      id: 'status',
      name: 'Status',
      sortBy: 'status',
      toggle: 'none',

      renderCell: (row) => row.status,
      renderGroup: () => <></>,
    },
    {
      id: 'session-count',
      name: 'Session count',
      sortBy: 'ws_metric_summary/sessions',
      numeric: true,
      renderCell: asCount(
        ({ ws_metric_summary }) => ws_metric_summary.sessions
      ),
      renderGroup: asCount(
        ({ ws_metric_summary }) => ws_metric_summary.sessions
      ),
    },
    {
      id: 'connecting time',
      name: 'P95 connecting time',
      sortBy: 'ws_metric_summary/connecting/p95',
      numeric: true,

      renderCell: asTiming(
        ({ ws_metric_summary }) => ws_metric_summary.connecting.p95
      ),
      renderGroup: asTiming(
        ({ ws_metric_summary }) => ws_metric_summary.connecting.p95
      ),
    },
    {
      id: 'session-duration',
      name: 'P95 session duration',
      sortBy: 'ws_metric_summary/session_duration/p95',
      numeric: true,

      renderCell: asTiming(
        ({ ws_metric_summary }) => ws_metric_summary.session_duration.p95
      ),
      renderGroup: asTiming(
        ({ ws_metric_summary }) => ws_metric_summary.session_duration.p95
      ),
    },
  ],

  RowBodyComponent: ({ analysis, value }) => (
    <WSChart analysis={analysis} url={value} />
  ),
  EmptyGroupComponent: () => (
    <>No WebSocket sessions were started during test execution.</>
  ),
}

interface WSTabProps {
  analysis: TestRunAnalysis
}

const WSTabComponent = ({ analysis }: WSTabProps) => {
  const client = useWebSocketClient()
  const [view, setView] = useEnumParameter('view', ['list', 'tree'] as const)
  const urlFiltersProps = useRunDetailsWebSocketsFilters()
  const { timeRange } = useTimeRange()

  const { filters, sortBy, removeAllFilters, updateSortBy } = urlFiltersProps

  const handleViewToggle = () => {
    setView('replace', view === 'list' ? 'tree' : 'list')
  }

  const handleClearFilter = () => {
    removeAllFilters()
  }

  const filter = useMemo(() => serializeRunFilter<WSValue>(filters), [filters])

  if (!hasWsMetricSummary(analysis.main.ws_metric_summary)) {
    return <WSEmptyView testRun={analysis.main} />
  }

  return (
    <BreakdownView
      data-testid={RunsPageTestIds.WSTable}
      view={view}
      analysis={analysis}
      params={{ client, filter, timeRange }}
      table={table}
      header={<WSFilters run={analysis.main} {...urlFiltersProps} />}
      emptyTable={
        <EmptyFilterView onClear={handleClearFilter}>
          No WebSocket sessions match the current filter...
        </EmptyFilterView>
      }
      onViewChange={handleViewToggle}
      sortBy={sortBy}
      onSort={updateSortBy}
    />
  )
}

export const WSTab = (props: WSTabProps) => (
  <QueryErrorBoundary view={ErrorBoundaryLocalView.WebSocketsTab}>
    <WSTabComponent {...props} />
  </QueryErrorBoundary>
)
