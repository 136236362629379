import React, { useState } from 'react'
import { Button } from '@grafana/ui'
import { StackApiTokenCreateModal } from './StackApiTokenCreateModal'

export function CreateStackApiTokenButton(props: { className?: string }) {
  const [showModal, setShowModal] = useState(false)

  const handleDismiss = () => {
    setShowModal(false)
  }

  const handleShowModal = () => {
    setShowModal(true)
  }

  return (
    <>
      <Button onClick={handleShowModal} {...props}>
        Create token
      </Button>
      <StackApiTokenCreateModal show={showModal} onDismiss={handleDismiss} />
    </>
  )
}
