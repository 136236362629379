import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import {
  ProjectLimitsFormValues,
  useProjectLimitsFormDefaultValues,
} from 'components/ProjectLimitsForm'
import { type ProjectSettings } from 'types'
import { timeFormatterShort } from 'utils/formatters'

interface UseLimitsFormArgs {
  projectLimits?: ProjectSettings
}

export function useLimitsForm({ projectLimits }: UseLimitsFormArgs) {
  const form = useForm<ProjectLimitsFormValues>()
  const projectLimitsFormDefaultValues = useProjectLimitsFormDefaultValues()

  const { reset } = form

  useEffect(() => {
    if (!projectLimits) {
      reset(projectLimitsFormDefaultValues)
      return
    }

    reset({
      ...projectLimits,
      duration_max_per_test: projectLimits.duration_max_per_test
        ? timeFormatterShort(projectLimits.duration_max_per_test)
        : null,
    })
  }, [reset, projectLimits, projectLimitsFormDefaultValues])

  return form
}
