import { TagValue } from 'types'
import { HTTP_METHODS } from 'constants/index'
import { ScenariosPayload } from 'datasource/datasource'

export const emptyScenarioPayload: ScenariosPayload = {
  value: [],
  '@count': 0,
}

export const booleanOptions = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
]

export const isGrpcStatusCode = (tag: TagValue) => {
  const statusCode = Number.parseInt(tag.value, 10)

  return statusCode >= 0 && statusCode <= 16
}

export const isHttpMethod = (tag: TagValue) => {
  return HTTP_METHODS.includes(tag.value)
}
