import React from 'react'
import { SupportLink } from 'components/SupportLink'
import { getTestRunLabel, isTestActive } from 'utils/testRun'
import { ErrorCode, TestRunAnalysis, TestRunStatus } from 'types'
import { Protocol } from '../RunOverview.types'
import { GRPCTestSummary } from './GRPCTestSummary'
import { HttpTestSummary } from './HttpTestSummary'
import { WebSocketTestSummary } from './WebSocketTestSummary'
import { DocLink, docs } from 'components/DocLink'
import { exhaustive } from 'utils/typescript'
import { QueryParamLink } from 'components/QueryParamLink'
import { BreakdownTab } from '../../Breakdown'
import { BrowserTestSummary } from './BrowserTestSummary'

interface EndOfTestSummaryProps {
  analysis: TestRunAnalysis | undefined
  protocol: Protocol | undefined
  protocols: Protocol[]
}

const TryAgainOrContactSupport = () => {
  return (
    <>
      Please try to run the test again. If the problem still persist, please{' '}
      <SupportLink>contact our support</SupportLink>.
    </>
  )
}

export const TestSummary = ({
  analysis,
  protocol,
  protocols,
}: EndOfTestSummaryProps) => {
  if (analysis) {
    const { main, compareWith } = analysis

    if (compareWith !== undefined) {
      return (
        <>
          Showing the compared results of {getTestRunLabel(analysis.main)} (left
          with solid lines) and {getTestRunLabel(analysis.compareWith)} (right
          with dashed lines).
        </>
      )
    }

    if (isTestActive(main)) {
      return <>The test is running...</>
    }

    if (main.run_status === TestRunStatus.ABORTED_SCRIPT_ERROR) {
      return (
        <>
          The test was aborted due to a script error. Please review your test
          script and make sure that it does not contain any errors then re-run
          the test.
        </>
      )
    }

    if (main.run_status === TestRunStatus.ABORTED_SYSTEM) {
      return (
        <>
          The test was aborted by the system. <TryAgainOrContactSupport />
        </>
      )
    }

    if (main.run_status === TestRunStatus.ABORTED_LIMIT) {
      return <>The test was aborted due to hard limit being crossed.</>
    }

    if (main.run_status === TestRunStatus.ABORTED_THRESHOLD) {
      return (
        <>
          The test was aborted because a threshold that was configured to stop
          the test was exceeded. See the{' '}
          <QueryParamLink name="tab" value={BreakdownTab.THRESHOLDS}>
            Thresholds
          </QueryParamLink>{' '}
          tab for more details.
        </>
      )
    }

    if (main.run_status === TestRunStatus.ABORTED_USER) {
      return <>The test was manually aborted.</>
    }

    if (main.run_status === TestRunStatus.TIMED_OUT) {
      if (main.error_code === ErrorCode.TEST_RUN_NO_DATA_TIMEOUT) {
        return (
          <>
            The test run timed-out while waiting for data.{' '}
            <TryAgainOrContactSupport />
          </>
        )
      }

      return (
        <>
          The test timed-out. <TryAgainOrContactSupport />
        </>
      )
    }
  }

  if (protocols && protocols.length === 0) {
    return (
      <>
        No data was recorded for this test run. Try to increase the duration of
        the test. Read more about test configurations in the{' '}
        <DocLink article={docs.root.k6}>docs</DocLink>.
      </>
    )
  }

  if (protocol) {
    switch (protocol.type) {
      case 'http':
        return <HttpTestSummary summary={protocol.left} />

      case 'ws':
        return <WebSocketTestSummary summary={protocol.left} />

      case 'grpc':
        return <GRPCTestSummary summary={protocol.left} />

      case 'browser':
        return <BrowserTestSummary summary={protocol.left} />

      default:
        return exhaustive(protocol, <></>)
    }
  }

  return <>Loading...</>
}
