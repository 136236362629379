import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { color, spacing } from 'utils/styled'
import { ComparedValue } from '../../Breakdown/ComparedValue'
import { RunsPageTestIds } from 'types/dataTestIds'

interface SummaryStatisticProps {
  label: ReactNode
  left: string
  right?: string | null
  unit: string
}

const StatisticContainer = styled.div`
  margin: 8px 0 8px;
  padding-left: ${spacing(2)};
  border-left: 1px ${color('border', 'weak')} solid;

  .statistic-label {
    font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
  }

  h2 {
    color: ${color('info', 'text')};
    font-weight: 600;
    margin-right: ${spacing(2)};
    font-size: ${({ theme }) => theme.typography.h1.fontSize};

    span.statistic-unit {
      font-weight: normal;
      font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
      margin-left: 0.2rem;
      color: ${color('text', 'secondary')};
    }
  }
`

export const Statistic = ({
  label,
  left,
  right,
  unit,
}: SummaryStatisticProps) => {
  return (
    <StatisticContainer data-testid={RunsPageTestIds.SummaryStatistic}>
      <div
        className="statistic-label"
        data-testid={RunsPageTestIds.StatisticLabel}
      >
        {label}
      </div>
      <h2>
        <span data-testid={RunsPageTestIds.StatisticValue}>
          <ComparedValue left={left} right={right} />
        </span>
        <span className="statistic-unit">{unit}</span>
      </h2>
    </StatisticContainer>
  )
}
