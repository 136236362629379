import { ReactNode } from 'react'
import styled from 'styled-components'

import { color, spacing } from 'utils/styled'
import { Flex, FlexProps } from 'components/Flex'

interface StickyProps extends FlexProps {
  children: ReactNode
  disabled?: boolean
  placement?: 'top' | 'bottom'
  marginBottom?: number
}

export const Sticky = styled(Flex)<StickyProps>`
  position: ${({ disabled }) => (disabled ? 'static' : 'sticky')};
  z-index: ${({ theme }) => theme.zIndex.navbarFixed};
  margin: ${spacing(0, -3)};
  padding: ${spacing(0, 3)};
  background-color: ${color('background', 'primary')};

  ${({ placement = 'top' }) =>
    placement === 'top'
      ? `
    top: 0;
    bottom: auto;
  `
      : `
    top: auto;
    bottom: 0;
  `}

  ${({ marginBottom, theme }) =>
    marginBottom &&
    `
      margin-bottom: ${theme.spacing(marginBottom)};
    `}
`
