import React from 'react'

import type { Trend, TrendDataPoint } from 'types'

import { DEFAULT_TREND_BAR_COUNT } from '../../constants'
import { useMinimalVariation } from '../../hooks'
import { EmptyBar } from '../EmptyBar'
import { PlaceholderValue } from '../PlaceholderValue'
import { Value } from '../Value'
import { XAxisLabel } from '../XAxisLabel'
import { useOffsetPosition, useStepCount } from './Bars.hooks'
import { Bar, Container } from './Bars.styles'

interface BarsProps {
  isEmpty: boolean
  barCount: number
  maxValue: number
  trend: Trend | undefined
  offset: number
  width: number
  values: Array<TrendDataPoint | undefined>
  variant?: 'minimal' | 'default'
}

export const Bars = ({
  barCount,
  values,
  offset,
  maxValue,
  trend,
  variant,
  width,
  isEmpty,
}: BarsProps) => {
  const isMinimal = useMinimalVariation(width, variant, trend)
  const steps = useStepCount(width)
  const offsetWidth = useOffsetPosition(isMinimal ? 1 : 2, offset)

  return (
    <Container
      isEmpty={isEmpty}
      isMinimal={isMinimal}
      justify="space-between"
      gap={barCount > DEFAULT_TREND_BAR_COUNT ? '1%' : '2%'}
      width={`calc(100% - ${offsetWidth}px)`}
    >
      {values.map((value, index) => {
        if (trend === undefined || value === undefined) {
          if (isEmpty) {
            return (
              <Bar key={index} direction="column" justify="flex-end">
                <PlaceholderValue key={index} index={index} />
              </Bar>
            )
          }

          return (
            <Bar key={index} direction="column" justify="flex-end">
              <EmptyBar key={index} />
            </Bar>
          )
        }

        return (
          <Bar key={index} direction="column" justify="flex-end">
            <Value max={maxValue} trend={trend} dataPoint={value} />

            {!isMinimal && index % steps === 0 && (
              <XAxisLabel dataPoint={value} />
            )}
          </Bar>
        )
      })}
    </Container>
  )
}
