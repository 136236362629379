import React from 'react'
import { TreeTable } from '../types'
import { FullWidthRow, TableCell } from '../Table'
import { TestRun } from 'types'

interface EmptyGroupRowProps<T, P> {
  testRun: TestRun
  params: P
  table: TreeTable<T, P>
}

export function EmptyGroupRow<T, P>({
  testRun,
  params,
  table,
}: EmptyGroupRowProps<T, P>) {
  return (
    <FullWidthRow>
      <TableCell>
        <table.EmptyGroupComponent testRun={testRun} {...params} />
      </TableCell>
    </FullWidthRow>
  )
}
