import React from 'react'
import { Drawer } from '@grafana/ui'

import { CodeSnippet } from 'components/CodeSnippet'

const settingsSnippet = `
  export const options = {
    cloud: {
      staticIPs: true,
    },
  };
`

export const StaticIPsGuide = ({ onClose }: { onClose: () => void }) => {
  return (
    <Drawer
      title="How to use Static IPs"
      subtitle="Run tests from Static IPs by including a parameter in cloud options."
      scrollableContent
      onClose={onClose}
      size="md"
    >
      <p>
        To run a test using Static IPs include <code>staticIPs</code> parameter
        in cloud options. Cloud options are configured via the{' '}
        <code>cloud</code> object.
      </p>

      <CodeSnippet code={settingsSnippet} lang="js" />
    </Drawer>
  )
}
