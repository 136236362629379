import React from 'react'
import { DiffMode } from './DiffModeToggle.types'
import { RadioButtonGroup } from '@grafana/ui'
import { SelectableValue } from '@grafana/data'

const diffModeOptions: Array<SelectableValue<DiffMode>> = [
  {
    value: 'side-by-side',
    label: 'Split',
    description: 'Show changes side-by-side.',
    icon: 'columns',
  },
  {
    value: 'inline',
    label: 'Inline',
    description: 'Show changes inline.',
    icon: 'gf-layout-simple',
  },
]

interface ViewModeToggleProps {
  disabled: boolean
  diffMode: DiffMode
  onChange: (view: DiffMode) => void
}

export const DiffModeToggle = ({
  disabled,
  diffMode,
  onChange,
}: ViewModeToggleProps) => {
  return (
    <RadioButtonGroup<DiffMode>
      id="diff-mode"
      value={diffMode}
      options={diffModeOptions}
      disabled={disabled}
      onChange={onChange}
    />
  )
}
