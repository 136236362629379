import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { getRoute } from 'routeMap'
import { PluginOrganizationWidePage } from 'components/PluginPage'
import {
  EnvVariablesTab,
  NotificationsTab,
  PersonalAPITokenTab,
  StackAPITokenTab,
} from './tabs'
import { useSettingsPageNav } from './SettingsPage.hooks'
import { TabbedNavContent } from 'components/TabbedNav'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { LimitsTab } from './tabs/LimitsTab/LimitsTab'
import { useIsUserOrgAdmin } from 'data/usePermissions'
import { ErrorBoundaryFullPageView } from 'services/tracking.types'

export function SettingsPage() {
  const pageNav = useSettingsPageNav()
  const isUserOrgAdmin = useIsUserOrgAdmin()

  return (
    <PluginOrganizationWidePage pageNav={pageNav}>
      <TabbedNavContent>
        <ErrorBoundary view={ErrorBoundaryFullPageView.SettingsPage}>
          <Switch>
            <Route path={getRoute('personalToken')}>
              <PersonalAPITokenTab />
            </Route>
            <Route path={getRoute('stackToken')}>
              <StackAPITokenTab />
            </Route>
            <Route path={getRoute('envVariables')}>
              <EnvVariablesTab />
            </Route>
            {isUserOrgAdmin && (
              <Route path={getRoute('limits')}>
                <LimitsTab />
              </Route>
            )}
            <Route path={getRoute('notifications')}>
              <NotificationsTab />
            </Route>
            <Route>
              <Redirect to={getRoute('personalToken')} />
            </Route>
          </Switch>
        </ErrorBoundary>
      </TabbedNavContent>
    </PluginOrganizationWidePage>
  )
}
