import styled from 'styled-components'
import { color, spacing } from 'utils/styled'

export const Partition = styled.div`
  flex: 0 0 33.333333%;
`

export const PartitionContainer = styled.div`
  display: flex;
  height: 10px;
  width: 100%;
  position: relative;
  background-color: ${({ theme }) =>
    theme.colors.mode === 'dark'
      ? theme.colors.background.primary
      : theme.colors.border.weak};

  &.score--good ${Partition}:nth-child(1) {
    background-color: ${({ theme }) =>
      theme.visualization.getColorByName('green')};
  }

  &.score--needs_improvement ${Partition}:nth-child(2) {
    background-color: ${({ theme }) =>
      theme.visualization.getColorByName('orange')};
  }

  &.score--poor ${Partition}:nth-child(3) {
    background-color: ${({ theme }) =>
      theme.visualization.getColorByName('red')};
  }
`

export const Legend = styled.div`
  position: relative;
  font-size: 10px;
  display: flex;
  justify-content: center;
  margin-top: ${spacing(1.5)};
  color: ${color('text', 'primary')};

  & > div {
    flex: 0 0 33.33333%;
    text-align: center;

    &::before {
      content: '';
      display: block;
      width: 1px;
      border-left: 1px ${color('border', 'weak')} solid;
      border-image: linear-gradient(
          0deg,
          ${color('border', 'strong')} 50%,
          ${color('border', 'weak')} 50%
        )
        1;
      position: absolute;
      top: -${spacing(2.75)};
      left: 33.333333%;
      height: 20px;
    }
    &::after {
      content: '';
      display: block;
      width: ${spacing(0.5)};
      height: ${spacing(0.5)};
      border-radius: 50%;
      position: absolute;
      top: -${spacing(1.75)};
      left: calc(33.333333% - 1.5px);
      background-color: ${({ theme }) =>
        theme.visualization.getColorByName('grey')};
    }

    &:nth-child(2) {
      &::before {
        left: 66.666666%;
      }
      &::after {
        left: calc(66.666666% - 1.5px);
      }
    }
  }
`
