import type {
  InsightExecutionWithTestRun,
  InsightExecutionResponse,
  InsightExecutionResultResponse,
  InsightExecutionResultWithTestRun,
  InsightPartialTestRun,
} from 'types/cloudInsights'

export const getLatestExecution = (
  executions: InsightExecutionWithTestRun[] = []
) => {
  return executions.reduce((max, execution) => {
    return max?.version > execution.version ? max : execution
  }, undefined as unknown as InsightExecutionWithTestRun)
}

export const toExecutionWithTestRun = ({
  id,
  created,
  started,
}: InsightPartialTestRun) => {
  return (data: InsightExecutionResponse): InsightExecutionWithTestRun[] => {
    return data.executions.map((execution) => ({
      ...execution,
      test_run: {
        id,
        created,
        started,
      },
    }))
  }
}

export const toExecutionResultWithTestRun = ({
  id,
  created,
  started,
}: InsightPartialTestRun) => {
  return (
    data: InsightExecutionResultResponse
  ): InsightExecutionResultWithTestRun => {
    return {
      ...data,
      test_run: {
        id,
        created,
        started,
      },
    }
  }
}
