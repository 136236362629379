import styled, { css } from 'styled-components'
import { color, spacing } from 'utils/styled'

export const pageSectionShadow = css`
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
`

export const PlainSection = styled.div<{ $direction?: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
`

export const SectionStyled = styled(PlainSection)`
  border: 1px ${({ theme }) => theme.colors.border.weak} solid;
  padding: ${spacing(4)};
  background-color: ${color('background', 'secondary')};
  border-radius: ${({ theme }) => theme.shape.borderRadius(1)};
  ${({ theme }) => theme.isDark && pageSectionShadow};
`

export const Heading = styled.h3<{ $size?: 's' | 'm' | 'l' }>`
  margin: ${spacing(0, 0, 2)};
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;

  ${({ $size }) => {
    if ($size === 's') {
      return css`
        font-size: 28px;
        line-height: 32px;
      `
    }

    if ($size === 'm') {
      return css`
        font-size: 32px;
        line-height: 46px;
      `
    }

    return css`
      font-size: 36px;
      line-height: 46px;
    `
  }}
`

export const Preamble = styled.p`
  font-size: 20px;
  line-height: 30px;
  color: ${color('text', 'secondary')};
`

export const Divider = styled.div`
  border-left: 1px #fff solid;
  opacity: 0.2;
  margin: ${spacing(2, 0)};

  &:last-of-type {
    display: none;
  }
`

export const ItemsContainer = styled.div`
  display: flex;
  gap: ${spacing(4)};
  justify-content: space-between;
`

export const PromoItemContainer = styled.div`
  display: flex;
  position: relative;
  flex: 0 1 400px;
  gap: ${spacing(2)};

  & > [class$='-PromoIcon'] {
    flex: 0 0 44px;
    transition: ${({ theme }) => theme.transitions.create('flex-basis')};
  }
`

export const PromoItemContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > a {
    margin-top: ${spacing(1.5)};
  }
`

export const PromoItemContent = styled.div`
  opacity: 0.8;
  font-weight: 400;
`

export const PromoItemHeading = styled.h5`
  color: ${color('text', 'maxContrast')};
`
