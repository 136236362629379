import React, { useCallback } from 'react'
import { IconButton } from '@grafana/ui'
import { useConfirmation } from 'hooks/useConfirmation'
import { Tooltip } from 'components/Tooltip'
import { ConfirmModal } from 'components/ConfirmModal'
import { useDeleteNotificationChannel } from '../../hooks/useNotifications'
import { NotificationChannel } from '../../NotificationsTab.types'
import { ButtonWrapper } from './NotificationsTableRowActions.styled'
import { useIsUserOrgAdmin } from 'data/usePermissions'

interface NotificationsTableRowActionsProps {
  notification: NotificationChannel
  handleUpdateNotification(id: number): void
}

export function NotificationsTableRowActions({
  notification,
  handleUpdateNotification: onUpdateNotification,
}: NotificationsTableRowActionsProps) {
  const isOrgAdmin = useIsUserOrgAdmin()

  const { mutateAsync: deleteNotification } = useDeleteNotificationChannel()

  const [deleteConfirmationProps, handleConfirmDelete] = useConfirmation(() =>
    deleteNotification(notification)
  )

  const handleUpdateNotification = useCallback(() => {
    if (isOrgAdmin) {
      onUpdateNotification(notification.id)
    }
  }, [isOrgAdmin, notification, onUpdateNotification])

  const handleDeleteNotifications = useCallback(() => {
    if (isOrgAdmin) {
      handleConfirmDelete()
    }
  }, [handleConfirmDelete, isOrgAdmin])

  return (
    <>
      <Tooltip
        show={!isOrgAdmin}
        content={
          !isOrgAdmin
            ? "You don't have permissions to update or delete notifications"
            : ''
        }
      >
        <ButtonWrapper>
          <IconButton
            name="edit"
            disabled={!isOrgAdmin}
            onClick={handleUpdateNotification}
          />
          <IconButton
            name="trash-alt"
            disabled={!isOrgAdmin}
            variant="destructive"
            onClick={handleDeleteNotifications}
          />
        </ButtonWrapper>
      </Tooltip>
      <ConfirmModal
        {...deleteConfirmationProps}
        title="Delete notification"
        confirmText="Delete notification"
        body={
          <span>
            Are you sure you want to delete notification{' '}
            <strong>&quot;{notification.meta_data.name}&quot;</strong>?
          </span>
        }
      />
    </>
  )
}
