import { useInfiniteQuery } from '@tanstack/react-query'

import { Test } from 'types'
import { useDatasource } from 'datasourceContext'
import { useMemo } from 'react'
import { SelectableValue } from '@grafana/data'
import { toTestOptionsQueryKey } from './queryKeys'

type MinimalTest = Pick<Test, 'id' | 'name'>

interface MinimalTestsResponse {
  'k6-tests': MinimalTest[]
  meta: {
    count: number
  }
}

export function useTestOptions(projectId: number) {
  const { ds } = useDatasource()

  const { data, ...query } = useInfiniteQuery({
    queryKey: toTestOptionsQueryKey(projectId),
    queryFn: async ({ pageParam }) => {
      const params = {
        project_id: projectId,
        page: pageParam,
        page_size: 20,
      }

      const response = await ds.get<MinimalTestsResponse>(
        `loadtests/v2/tests`,
        { params }
      )

      const tests = response['k6-tests']

      return {
        count: response.meta?.count ?? tests.length,
        tests,
      }
    },
    getNextPageParam: (lastPage, pages) => {
      if (pages.length === lastPage.count) {
        return undefined
      }

      return pages.length + 1
    },
    // Avoid jumpy select when searching
    keepPreviousData: true,
    enabled: Boolean(projectId),
  })

  const tests = useMemo(
    () => data?.pages.flatMap((page) => page.tests) ?? [],
    [data]
  )
  return {
    tests: tests.map<SelectableValue<number>>((test) => ({
      label: test.name,
      value: test.id,
    })),
    ...query,
  }
}
