import styled from 'styled-components'
import { spacing } from 'utils/styled'

export const EventsContainer = styled.div`
  display: flex;
`

export const EventsTooltipWrapper = styled.div`
  display: flex;
`

export const EventsWrapper = styled.div`
  align-items: center;
  cursor: pointer;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  span {
    text-transform: initial;
    font-weight: 600;
  }
`

export const RemainingEventsCount = styled.div`
  margin-left: ${spacing(1)};
  font-weight: 500;
  cursor: pointer;
`

export const EventsTooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 600;
  color: #fff;
`

export const EventsTitle = styled.span`
  font-weight: 600;
  margin-bottom: ${spacing(0.5)};
`
