import { getBackendSrv } from '@grafana/runtime'
import { useQuery } from '@tanstack/react-query'
import { ExistingFolder, GetFoldersResponseSchema } from '../types'

export function useDashboardFolderOptions() {
  return useQuery({
    queryKey: ['folders'],
    queryFn: () => {
      return getBackendSrv()
        .get(`/api/folders`)
        .then(GetFoldersResponseSchema.parse)
    },
    select(data) {
      return data.map<ExistingFolder>((folder) => ({
        type: 'existing',
        title: folder.title,
        uid: folder.uid,
      }))
    },
  })
}
