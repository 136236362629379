import { LoadTestsV2Response, Project, Test } from 'types'
import { K6DataSource } from 'datasource/datasource'
import { isFetchError } from '@grafana/runtime'
import { TestDraft } from 'pages/Editor/drafts'
import {
  ApiErrorSchema,
  ServerValidationError,
  UnknownServerError,
  parseErrorMessage,
} from './errors'
import { Options } from '../schema/options'

export async function isNameAvailable(
  datasource: K6DataSource,
  draft: TestDraft
) {
  const params = new URLSearchParams({
    $select: 'id,name',
    q: draft.name,
    project_id: draft.projectId.toString(),
    page: '1',
    page_size: '500',
  })

  const tests = await datasource
    .get<LoadTestsV2Response<Test[]>>(`loadtests/v2/tests?${params.toString()}`)
    .then((response) => response['k6-tests'])

  // We need to filter because the search may give a partial
  // match, e.g. "foo" would match a test called "foo bar".
  return tests
    .filter((test) => test.name === draft.name)
    .every((test) => test.id === draft.id)
}

export async function validateWithApi(
  datasource: K6DataSource,
  project: Project,
  options: Options
): Promise<Array<UnknownServerError | ServerValidationError>> {
  try {
    await datasource.post('/loadtests/v2/options/validate', {
      body: {
        project_id: project.id,
        options: options,
      },
    })

    return []
  } catch (error) {
    if (!isFetchError(error)) {
      throw error
    }

    const parsedError = ApiErrorSchema.safeParse(error.data)

    if (!parsedError.success) {
      return [
        {
          type: 'unknown-server-error',
          data: error.data,
        },
      ]
    }

    // The validation API may return errors which are dependent on runtime
    // conditions, e.g. the max concurrency limit. Since these issues could
    // be mixed with other validation errors, we need to parse the error and
    // filter these errors out.
    const errors = parseErrorMessage(parsedError.data.error.message)

    if (errors.length === 0) {
      return []
    }

    return [
      {
        type: 'server-validation-error',
        parsed: errors,
        error: parsedError.data.error,
      },
    ]
  }
}
