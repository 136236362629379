import { TempoSearchTrace } from 'types/tempo'
import { millisecondsToSeconds } from 'utils/math'

export function tracesToOptions(traces: TempoSearchTrace[]) {
  return traces.map((trace) => ({
    value: trace.traceID,
    label: optionLabelFormatter(trace),
  }))
}

function formatTimestampNano(timestamp: string) {
  const date = new Date(Number(timestamp) / 1000000)
  return date.toISOString()
}

export function optionLabelFormatter(trace: TempoSearchTrace) {
  const date = formatTimestampNano(trace.startTimeUnixNano)
  const dur = millisecondsToSeconds(trace.durationMs)
  return `${trace.rootServiceName}:${trace.rootTraceName} ${date} (${dur}s)`
}
