type Href = `https://grafana.com/docs/${'k6/latest' | 'grafana-cloud'}${string}`
type Docs = { [name: string]: Href | Docs }

const K6_DOCS_URL = process.env.K6_DOCS_URL
const GRAFANA_DOCS_URL = process.env.GRAFANA_DOCS_URL
const GRAFANA_K6_DOCS_URL = `${GRAFANA_DOCS_URL}/k6` as const

export const docs = {
  root: {
    k6: K6_DOCS_URL,
    grafana: GRAFANA_K6_DOCS_URL,
  },
  gettingStarted: {
    installation: `${K6_DOCS_URL}/get-started/installation`,
    runYouFirstTests: `${GRAFANA_K6_DOCS_URL}/get-started/run-your-first-tests`,
    runCloudTestsFromCLI: `${GRAFANA_K6_DOCS_URL}/get-started/run-cloud-tests-from-the-cli`,
    streamToCloud: `${GRAFANA_K6_DOCS_URL}/get-started/run-cloud-tests-from-the-cli/#run-locally-and-stream-to-the-cloud`,
    vuHours: `${GRAFANA_K6_DOCS_URL}/get-started/vu-hours`,
  },
  metrics: {
    builtIn: `${K6_DOCS_URL}/using-k6/metrics/reference/`,
    custom: `${K6_DOCS_URL}/using-k6/metrics/create-custom-metrics/`,
  },
  httpRequests: {
    main: `${GRAFANA_K6_DOCS_URL}/analyze-results/inspect-test-results/inspect-request-data/`,
    urlGrouping: `${K6_DOCS_URL}/using-k6/http-requests/#url-grouping`,
    tags: `${K6_DOCS_URL}/using-k6/http-requests/#http-request-tags`,
  },
  modules: {
    localFilesystem: `${K6_DOCS_URL}/using-k6/modules#local-filesystem-modules`,
  },
  thresholds: {
    main: `${GRAFANA_K6_DOCS_URL}/analyze-results/inspect-test-results/inspect-thresholds/`,
  },
  scenarios: {
    main: `${K6_DOCS_URL}/using-k6/scenarios`,
    executors: {
      main: `${K6_DOCS_URL}/using-k6/scenarios/#scenario-executors`,
      sharedIterations: `${K6_DOCS_URL}/using-k6/scenarios/executors/shared-iterations/`,
      constantVUs: `${K6_DOCS_URL}/using-k6/scenarios/executors/constant-vus/`,
      rampingVUs: `${K6_DOCS_URL}/using-k6/scenarios/executors/ramping-vus/`,
    },
  },
  options: {
    main: `${K6_DOCS_URL}/using-k6/k6-options/`,
    discardResponseBodies: `${K6_DOCS_URL}/using-k6/k6-options/reference/#discard-response-bodies`,
  },
  cloudOptions: {
    options: `${GRAFANA_K6_DOCS_URL}/author-run/cloud-scripting-extras/cloud-options/`,
    optionsAndSyntax: `${GRAFANA_K6_DOCS_URL}/author-run/cloud-scripting-extras/cloud-options/#options-and-syntax`,
  },
  loadZones: {
    main: `${GRAFANA_K6_DOCS_URL}/author-run/cloud-scripting-extras/load-zones/`,
    private: `${GRAFANA_K6_DOCS_URL}/author-run/private-load-zone-v2/`,
    privateLoadZonesAdditionalInfo: `${GRAFANA_K6_DOCS_URL}/author-run/private-load-zone-v2/#additional-information`,
    runOnPrivateLoadZonesUi: `${GRAFANA_K6_DOCS_URL}/author-run/private-load-zone-v2/#from-the-ui`,
    runOnPrivateLoadZonesFromTestBuilder: `${GRAFANA_K6_DOCS_URL}/author-run/private-load-zone-v2/#test-builder`,
    runOnPrivateLoadZonesFromScriptEditor: `${GRAFANA_K6_DOCS_URL}/author-run/private-load-zone-v2/#script-editor`,
  },
  tutorials: {
    automatedPerformanceTesting: {
      localOrCloudExecution: `${K6_DOCS_URL}/get-started/running-k6/#execution-modes`,
    },
  },
  javascript: {
    main: `${K6_DOCS_URL}/javascript-api`,
    sleep: `${K6_DOCS_URL}/javascript-api/k6/sleep`,
    http: {
      setResponseCallback: `${K6_DOCS_URL}/javascript-api/k6-http/set-response-callback`,
    },
  },
  analyze: {
    inspect: {
      logs: `${GRAFANA_K6_DOCS_URL}/analyze-results/inspect-test-results/inspect-logs/`,
      performanceOverview: `${GRAFANA_K6_DOCS_URL}/analyze-results/inspect-test-results/#performance-overview`,
    },
    correlate: `${GRAFANA_K6_DOCS_URL}/analyze-results/correlate-results-in-grafana/`,
    tracesIntegration: `${GRAFANA_K6_DOCS_URL}/analyze-results/integration-with-grafana-cloud-traces`,
    manageResults: {
      saveResults: `${GRAFANA_K6_DOCS_URL}/analyze-results/manage-test-results/#save-a-test-result`,
    },
  },
  insights: {
    overview: `${GRAFANA_K6_DOCS_URL}/analyze-results`,
    disabling: `${GRAFANA_K6_DOCS_URL}/analyze-results/get-performance-insights/#disabling-performance-insights`,
    abortReason: {
      user: `${GRAFANA_K6_DOCS_URL}/reference/cloud-test-status-codes/#aborted-by-user`,
      system: `${GRAFANA_K6_DOCS_URL}/reference/cloud-test-status-codes/#aborted-by-system`,
      scriptError: `${GRAFANA_K6_DOCS_URL}/reference/cloud-test-status-codes/#aborted-by-script-error`,
      threshold: `${GRAFANA_K6_DOCS_URL}/reference/cloud-test-status-codes/#aborted-by-threshold`,
      limit: `${GRAFANA_K6_DOCS_URL}/reference/cloud-test-status-codes/#aborted-by-limit`,
    },
    bestPractices: {
      tooManyGroups: `${GRAFANA_K6_DOCS_URL}/analyze-results/get-performance-insights/#too-many-groups`,
      tooManyMetrics: `${GRAFANA_K6_DOCS_URL}/analyze-results/get-performance-insights/#too-many-metrics`,
      tooManyUrls: `${GRAFANA_K6_DOCS_URL}/analyze-results/get-performance-insights/#too-many-urls`,
      tooManyTimeSeries: `${GRAFANA_K6_DOCS_URL}/analyze-results/get-performance-insights/#too-many-time-series`,
      thirdPartyContent: `${GRAFANA_K6_DOCS_URL}/analyze-results/get-performance-insights/#third-party-content`,
      outdatedK6ReleaseUsed: `${GRAFANA_K6_DOCS_URL}/analyze-results/get-performance-insights/#outdated-k6-release-used`,
      invalidMetricNames: `${GRAFANA_K6_DOCS_URL}/analyze-results/get-performance-insights/#invalid-metric-names`,
    },
    http: {
      highFailureRate: `${GRAFANA_K6_DOCS_URL}/analyze-results/get-performance-insights/#high-http-failure-rate`,
      increasedFailureRate: `${GRAFANA_K6_DOCS_URL}/analyze-results/get-performance-insights/#increased-http-failure-rate`,
      throughputLimit: `${GRAFANA_K6_DOCS_URL}/analyze-results/get-performance-insights/#throughput-limit`,
    },
    health: {
      highLoadGeneratorCPUUsage: `${GRAFANA_K6_DOCS_URL}/analyze-results/get-performance-insights/#high-load-generator-cpu-usage`,
      highLoadGeneratorMemoryUsage: `${GRAFANA_K6_DOCS_URL}/analyze-results/get-performance-insights/#high-load-generator-memory-usage`,
    },
    // TODO: Waiting for web vital to be added to the performance insights documentation. https://github.com/grafana/k6-cloud/issues/1887
    webVital: {
      cls: `${GRAFANA_K6_DOCS_URL}/analyze-results/get-performance-insights/#cumulative-layout-shift-cls`,
      fcp: `${GRAFANA_K6_DOCS_URL}/analyze-results/get-performance-insights/#first-contentful-paint-fcp`,
      fid: `${GRAFANA_K6_DOCS_URL}/analyze-results/get-performance-insights/#first-input-delay-fid`,
      inp: `${GRAFANA_K6_DOCS_URL}/analyze-results/get-performance-insights/#interaction-to-next-paint-inp`,
      lcp: `${GRAFANA_K6_DOCS_URL}/analyze-results/get-performance-insights/#largest-contentful-paint-lcp`,
      ttfb: `${GRAFANA_K6_DOCS_URL}/analyze-results/get-performance-insights/#time-to-first-byte-ttfb`,
    },
    runStatus: {
      queued: `${GRAFANA_K6_DOCS_URL}/reference/cloud-test-status-codes/#queued`,
    },
    testComparison: `${GRAFANA_K6_DOCS_URL}/analyze-results/test-comparison/`,
    trendingMetric: `${GRAFANA_K6_DOCS_URL}/analyze-results/test-comparison/#customize-the-trending-metric`,
    tagsHelp: `${K6_DOCS_URL}/using-k6/tags-and-groups#tags`,
  },
  checks: {
    main: `${GRAFANA_K6_DOCS_URL}/analyze-results/inspect-test-results/inspect-checks/`,
  },
  integrations: {
    main: `${K6_DOCS_URL}/misc/integrations/`,
    token: {
      accountAPIToken: `${GRAFANA_K6_DOCS_URL}/author-run/tokens-and-cli-authentication`,
      organizationAPIToken: `${GRAFANA_K6_DOCS_URL}/author-run/tokens-and-cli-authentication`,
    },
  },
  manage: {
    auditTrail: `${GRAFANA_K6_DOCS_URL}/reference/audit-trails`,
    environmentVariables: `${GRAFANA_K6_DOCS_URL}/author-run/cloud-scripting-extras/cloud-environment-variables/`,
    notifications: `${GRAFANA_K6_DOCS_URL}/author-run/send-notifications`,
    savedTestRuns: `${GRAFANA_K6_DOCS_URL}/analyze-results/share-test-results`,
    scheduledTests: `${GRAFANA_K6_DOCS_URL}/author-run/schedule-a-test`,
    usageReports: `${GRAFANA_K6_DOCS_URL}/projects-and-users/usage-reports`,
    staticIPs: `${GRAFANA_K6_DOCS_URL}/projects-and-users/manage-static-ips`,
  },
  subscription: {
    dataRetention: `${GRAFANA_DOCS_URL}/billing-and-usage/k6-billing/`,
  },
  accountManagement: {
    cloudPortal: `${GRAFANA_DOCS_URL}/account-management/cloud-portal/`,
  },
  testBuilder: {
    'results-visualization/cloud': `${GRAFANA_K6_DOCS_URL}/get-started/run-cloud-tests-from-the-cli/#run-locally-and-stream-to-the-cloud`,
    'request-builder': `${GRAFANA_K6_DOCS_URL}/get-started/run-your-first-tests/`,
    thresholds: `${K6_DOCS_URL}/using-k6/thresholds`,
  },
  projects: {
    members: `${GRAFANA_K6_DOCS_URL}/projects-and-users/manage-project-members/`,
    limits: `${GRAFANA_K6_DOCS_URL}/projects-and-users/limits-and-quotas/`,
  },
  traces: {
    main: `${K6_DOCS_URL}/javascript-api/k6-experimental/tracing/`,
  },
  browser: {
    runningBrowserTests: `${K6_DOCS_URL}/using-k6-browser/running-browser-tests/`,
    api: `${K6_DOCS_URL}/javascript-api/k6-experimental/browser/`,
  },
  costManagementAndBilling: {
    lengthOfDataRetention: `${GRAFANA_DOCS_URL}/cost-management-and-billing/understand-your-invoice/k6-invoice/#length-of-data-retention`,
  },
} as const

type DeepPropertyTypeUnion<T extends Docs> = {
  [P in keyof T]: T[P] extends Docs ? DeepPropertyTypeUnion<T[P]> : T[P]
}[keyof T]

export type DocArticle = DeepPropertyTypeUnion<typeof docs>
