import { TestRun } from 'types/index'
import {
  getDurationDisplay,
  getTestRunVUhDisplay,
  getTestRunVUsDisplay,
} from 'components/MetaBar'

interface TestRunMetaData {
  vuh: string
  executionDuration: string
  vus: string
}

export const getTestRunMetaData = (testRun: TestRun): TestRunMetaData => {
  return {
    vuh: getTestRunVUhDisplay(testRun),
    vus: getTestRunVUsDisplay(testRun),
    executionDuration: getDurationDisplay(testRun?.execution_duration),
  }
}
