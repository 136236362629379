import React, { ReactElement, useEffect, useMemo } from 'react'
import { SortOptions, TestRunAnalysis } from 'types'
import {
  ListTableDefinition,
  TableDefinition,
  TreeTableDefinition,
  ViewType,
  isTreeColumn,
} from './types'
import { ListView } from './ListView'
import { TreeView } from './TreeView'
import { usePagedList } from './ListView.hooks'
import { LoadingContainer } from 'components/LoadingContainer'
import { useNumericParameter } from 'hooks/useQueryParameter'
import { TableStateProvider } from './TableStateProvider'
import { isTestActive } from 'utils/testRun'

interface BreakdownTableProps<T, P, S> {
  view: ViewType
  analysis: TestRunAnalysis
  params: P
  table: TableDefinition<T, P>
  emptyTable?: ReactElement
  sortBy?: SortOptions<S>
  onReady?: () => void
  onSort: (sortBy: SortOptions<S> | undefined) => void
  dataTestId?: string
}

export function BreakdownTable<T, P, S>({
  view,
  analysis,
  table,
  params,
  emptyTable,
  sortBy,
  onReady,
  onSort,
  dataTestId,
}: BreakdownTableProps<T, P, S>) {
  const [page, setPage] = useNumericParameter('page', 1)

  const { data, isLoading: isLoadingData } = usePagedList({
    testRun: analysis.main,
    params,
    table,
    page,
    sortBy,
  })

  // Reset to the first page when current page has no data because of filters
  useEffect(() => {
    if (data?.items.length === 0 && page !== 1) {
      setPage('replace', 1)
    }
  }, [data?.items.length, page, setPage])

  const renderedTable = useMemo(() => {
    const context = {
      view,
      analysis,
    }

    return {
      ...table,
      columns: table.columns.filter(
        (column) => column.shouldRender?.(context) ?? true
      ),
    }
  }, [table, analysis, view])

  const listTable: ListTableDefinition<T, P> | false = useMemo(() => {
    return (
      renderedTable.type !== 'tree' && {
        ...renderedTable,
        type: 'list',
      }
    )
  }, [renderedTable])

  const treeTable: TreeTableDefinition<T, P> | false = useMemo(() => {
    return (
      renderedTable.type !== 'list' && {
        ...renderedTable,
        type: 'tree',
        columns: renderedTable.columns.filter(isTreeColumn),
      }
    )
  }, [renderedTable])

  const handlePageChange = (newPage: number) => {
    setPage('push', newPage)
  }

  const handleViewLoaded = () => {
    onReady?.()
  }

  return (
    <TableStateProvider>
      <LoadingContainer loading={isLoadingData} estimatedHeight={100}>
        {data?.count === 0 &&
          emptyTable &&
          React.cloneElement(emptyTable, { onReady: handleViewLoaded })}
        {data && data.count > 0 && (
          <div data-testid={dataTestId}>
            {view === 'tree' && treeTable && (
              <TreeView
                expandOnInit={
                  !isTestActive(analysis.main) &&
                  !analysis.compareWith &&
                  data.count < table.pageSize
                }
                analysis={analysis}
                params={params}
                table={treeTable}
                sortBy={sortBy}
                onLoaded={handleViewLoaded}
                onSortChange={onSort}
              />
            )}
            {view === 'list' && listTable && (
              <ListView
                analysis={analysis}
                params={params}
                table={listTable}
                page={page}
                sortBy={sortBy}
                onLoaded={handleViewLoaded}
                onSortChange={onSort}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        )}
      </LoadingContainer>
    </TableStateProvider>
  )
}
