import React, { useCallback, useState } from 'react'
import { useSubscriptionRule } from 'data/useSubscriptions'
import { useAppContext } from 'appContext'
import { useSafeTests } from 'pages/ManagePage/tabs/SavedTestsTab/SavedTestsTab.hooks'
import { CTAUpgradeReason } from 'services/tracking.types'
import { UpgradePromptModal } from './UpgradePromptModal'
import { DocLink, docs } from './DocLink'
import { useGrafanaSubscriptionUrl } from 'hooks/useGrafanaSubscriptionUrl'
import { ExternalLink } from './ExternalLink'
import { Link } from 'react-router-dom'
import { routeMap } from '../routeMap'
import { CenteredSpinner } from './CenteredSpinner'
import { Button } from './Button'

interface MaxSavedRunsModalProps {
  isOpen: boolean
  isLoading: boolean
  maxAllowed: number
  onDismiss(): void
}

export const MaxSavedRunsModal = ({
  isOpen,
  isLoading,
  maxAllowed,
  onDismiss,
}: MaxSavedRunsModalProps) => {
  const upgradeLink = useGrafanaSubscriptionUrl()

  return (
    <UpgradePromptModal
      isOpen={isOpen}
      title="Save test results"
      reason={CTAUpgradeReason.MaxSavedTests}
      actions={[
        <Button
          key="close"
          variant="secondary"
          onClick={onDismiss}
          fill="outline"
        >
          Close
        </Button>,
      ]}
      onDismiss={onDismiss}
    >
      {isLoading && <CenteredSpinner />}
      {!isLoading && (
        <>
          <p>
            You have saved {maxAllowed} test results, which is the maximum
            number allowed by your subscription. In order to save more test
            results you will need to{' '}
            <ExternalLink url={upgradeLink}>
              {' '}
              upgrade your subscription
            </ExternalLink>
            .
          </p>
          <p>
            Saving the test results prevents it from being deleted by the{' '}
            <DocLink article={docs.subscription.dataRetention}>
              data retention policy
            </DocLink>
            .
          </p>
          <p>
            You can find saved results on{' '}
            <Link to={routeMap.savedTestRuns}>saved tests page</Link>.
          </p>
        </>
      )}
    </UpgradePromptModal>
  )
}

export const useMaxSavedRunsModal = (onToggleSafe: () => void) => {
  const { orgId } = useAppContext()
  const { data, isFetching, error } = useSafeTests({ orgId })
  const maxSafeCount = useSubscriptionRule('data.retention_safe.max')

  const [isOpen, setIsOpen] = useState(false)

  const savedRuns = (data?.k6_runs && data.k6_runs.length) || 0
  const maxSavesReached = savedRuns === maxSafeCount

  const handleToggleSafe = useCallback(() => {
    if (maxSavesReached && !error) {
      setIsOpen(true)
      return
    }
    onToggleSafe()
  }, [error, maxSavesReached, onToggleSafe])

  const handleOnDismiss = () => {
    setIsOpen(false)
  }

  return {
    isOpen,
    isLoading: isFetching,
    isError: !!error,
    maxAllowed: maxSafeCount,
    count: savedRuns,
    maxSavesReached,
    onDismiss: handleOnDismiss,
    onToggleSafe: handleToggleSafe,
  }
}
