import { useQuery } from '@tanstack/react-query'
import { toGroupsQueryKey } from './queryKeys'
import { useDatasource } from 'datasourceContext'

export function useGroups(testRunId: number) {
  const { ds } = useDatasource()

  return useQuery({
    queryKey: toGroupsQueryKey(testRunId),
    queryFn: () => ds.fetchGroups({ id: testRunId }),
    select: (data) => data.value,
  })
}
