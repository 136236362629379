import React from 'react'
import { get } from 'lodash-es'
import { ActionsColumnCell } from './ActionsColumnCell'
import { ActionsColumnHeader } from './ActionsColumnHeader'
import {
  BatchItem,
  DataTableActions,
  SortOrder,
  type TableColumn,
} from './DataTable.types'

export const createActionsColumn = <T,>(
  batchAction: DataTableActions<T>
): TableColumn<T> => {
  return {
    minWidth: `153px`,
    name: <ActionsColumnHeader {...batchAction} />,
    cell: (row: T) => {
      const { getCellProps, onItemSelect, isSelectionMode, selectedItems } =
        batchAction
      const handleOnChange = () => {
        onItemSelect(row as BatchItem<T>)
      }

      const props = getCellProps(row, isSelectionMode, selectedItems)

      return (
        <ActionsColumnCell
          {...props}
          isSelectionMode={isSelectionMode}
          onChange={handleOnChange}
          onClick={() => props.onClick(row)}
        />
      )
    },
  }
}

export function getSortedRows<T>(
  data: T[],
  columns: Array<TableColumn<T>>,
  selectedColumn?: TableColumn<T>,
  sortOrder?: SortOrder
) {
  if (!data) {
    return []
  }

  if (!selectedColumn || !sortOrder) {
    return data
  }

  return [...data].sort((a, b) => {
    if (selectedColumn.sortFunction) {
      return selectedColumn.sortFunction(a, b, sortOrder)
    }

    const field = selectedColumn.sortField
    const valueA = getValue<T>(columns, a, field!)
    const valueB = getValue<T>(columns, b, field!)

    return defaultSortFunc(valueA, valueB, sortOrder)
  })
}

export function defaultSortFunc<T>(a: T, b: T, sortOrder: SortOrder) {
  const subjects: [T, T] = [a, b]

  if (sortOrder === `desc`) {
    subjects.reverse()
  }

  if (typeof a === 'number' && typeof b === 'number') {
    return numberSort(subjects[0], subjects[1])
  }

  return makeValueComparable(subjects[0]).localeCompare(
    makeValueComparable(subjects[1])
  )
}

function numberSort<T>(a: T, b: T) {
  if (a > b) {
    return 1
  }

  if (a! < b) {
    return -1
  }

  return 0
}

function makeValueComparable(value: unknown) {
  if (typeof value === 'string') {
    return value
  }

  if (
    typeof value === 'number' ||
    typeof value === 'bigint' ||
    typeof value === 'boolean'
  ) {
    return value.toString()
  }

  return ''
}

function getValue<T>(columns: Array<TableColumn<T>>, entry: T, field: string) {
  const column = columns.find((col) => col.sortField === field)

  if (column?.selector) {
    return column?.selector(entry)
  }

  return get(entry, field)
}
