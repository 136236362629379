import React, { useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { IconButton } from '@grafana/ui'

import { SavedTestRun } from 'types/testRun'
import { routeMap } from 'routeMap'
import { formatDate } from 'utils/date'
import { timeFormatter } from 'utils/formatters'
import { isSecureRun } from 'utils/testRun'
import { getTestStatusText } from 'pages/utils'
import { DataTable } from 'components/DataTable'
import { Flex } from 'components/Flex'
import { LoadingContainer } from 'components/LoadingContainer'
import { LoadZoneOrLocal } from 'components/LoadZoneOrLocal'
import { StatusIcon } from 'components/StatusIcon'
import { Tooltip } from 'components/Tooltip'
import { type TableColumn } from 'components/DataTable/DataTable.types'
import { useHasWriteAccessToProject } from 'data/usePermissions'
import { BertMessage } from 'components/BertMessage'

import { SavedTestRunsModal } from '../SavedTestRunsModal'
import { ActionCell, Cell } from './SavedTestRunsTable.styles'

const getVUSDisplay = (testRun: SavedTestRun) => {
  return testRun.duration > 0 && testRun.vus
    ? `${testRun.vus} VUs, ${timeFormatter(testRun.duration)}${
        isSecureRun(testRun) ? ' (secure)' : ''
      }`
    : '-'
}

interface UseColumnsProps {
  setTestRun: (testRun: SavedTestRun) => void
}

const useColumns = ({ setTestRun }: UseColumnsProps) => {
  return useMemo(() => {
    let columns: Array<TableColumn<SavedTestRun>> = [
      {
        name: 'Status',
        selector: (testRun) => getTestStatusText(testRun),
        sortable: true,
        sortField: 'run_status',
        cell: (testRun) => (
          <Flex data-tag="allowRowEvents" align="center" gap={2}>
            <StatusIcon
              testRun={testRun}
              size="md"
              spinner={true}
              showSaveState={true}
            />{' '}
            <span data-tag="allowRowEvents">{getTestStatusText(testRun)}</span>
          </Flex>
        ),
      },
      {
        name: 'Run',
        sortable: true,
        sortField: 'started',
        cell: ({ id, started }) => (
          <Cell data-tag="allowRowEvents">
            <Link to={routeMap.testRun(id)}>
              {started ? formatDate(started) : '-'}
            </Link>
          </Cell>
        ),
      },
      {
        name: 'Project',
        sortable: true,
        sortField: 'project_name',
        cell: (testRun) => (
          <Cell data-tag="allowRowEvents">
            <Link to={routeMap.project(testRun.project_id)}>
              {testRun.project_name}
            </Link>
          </Cell>
        ),
      },
      {
        name: 'Test',
        sortable: true,
        sortField: 'test_name',
        cell: (testRun) => (
          <Cell data-tag="allowRowEvents">
            <Link to={routeMap.test(testRun.test_id)}>{testRun.test_name}</Link>
          </Cell>
        ),
      },
      {
        name: 'Config',
        cell: getVUSDisplay,
      },
      {
        name: 'Load Distribution',
        ignoreRowClick: true,
        cell: (testRun) => {
          if (testRun.nodes.length === 0) {
            return '-'
          }

          return (
            <LoadZoneOrLocal
              compact={testRun.nodes.length > 1}
              testRun={testRun}
            />
          )
        },
      },
      {
        allowOverflow: true,
        button: true,
        grow: 0,
        width: '40px',
        cell: (testRun) => {
          return <DeleteColumn testRun={testRun} setTestRun={setTestRun} />
        },
      },
    ]

    return columns
  }, [setTestRun])
}

const DeleteColumn = ({
  testRun,
  setTestRun,
}: {
  testRun: SavedTestRun
  setTestRun: (testRun: SavedTestRun) => void
}) => {
  const useHasProjectWriteAccess = useHasWriteAccessToProject(
    testRun.project_id
  )

  if (!useHasProjectWriteAccess) {
    return null
  }

  return (
    <Tooltip
      content={'You cannot remove baseline test runs'}
      show={testRun.is_baseline}
    >
      <ActionCell>
        <IconButton
          aria-label="Remove saved test"
          disabled={testRun.is_baseline}
          name="trash-alt"
          size="lg"
          variant="destructive"
          onClick={() => setTestRun(testRun)}
        />
      </ActionCell>
    </Tooltip>
  )
}

interface SavedTestRunsTableProps {
  data?: SavedTestRun[]
  isLoading: boolean
}

export const SavedTestRunsTable = ({
  data = [],
  isLoading,
}: SavedTestRunsTableProps) => {
  const [testRun, setTestRun] = useState<SavedTestRun | null>(null)
  const history = useHistory()
  const columns = useColumns({ setTestRun })

  function handleRowClick(testRun: SavedTestRun) {
    history.push(routeMap.testRun(testRun.id))
  }

  return (
    <LoadingContainer loading={isLoading}>
      <DataTable
        columns={columns}
        data={data}
        isFetching={isLoading}
        noDataComponent={
          <BertMessage
            description={
              <BertMessage.Text>
                Looks empty here! Save a test by selecting{' '}
                <strong>save test results</strong> in the top right menu when
                viewing a test run
              </BertMessage.Text>
            }
          />
        }
        onRowClicked={handleRowClick}
      />
      <SavedTestRunsModal
        testRun={testRun}
        onDismiss={() => setTestRun(null)}
      />
    </LoadingContainer>
  )
}
