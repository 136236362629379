import React, { useEffect, useRef, useState } from 'react'
import { IconButton } from '@grafana/ui'
import { ScriptTabButtonsContainer, ScriptTabWrapper } from './ScriptTab.styles'

import { sentryClient } from 'services/sentryClient'
import { LoadingContainer } from 'components/LoadingContainer'
import { TestRun } from 'types'
import { BreakdownTabHeader } from '../BreakdownTabHeader'
import { DiffModeToggle } from './DiffModeToggle'
import { HijackMonaco, useMonaco } from 'components/GrafanaMonaco'
import { DiffMessage } from './DiffMessage'
import { DiffMode } from './DiffModeToggle.types'
import { useDiffEditor, useDiffNavigator } from './DiffScriptsTab.hooks'
import { displayOverlayMessage } from './DiffScriptsTab.utils'
import { MissingBothScriptsMessage } from './MissingScriptMessage'
import {
  DiffEditorMount,
  MissingScriptMessageTemplate,
} from './DiffScriptsTab.styles'
import { QueryErrorBoundary } from 'components/ErrorBoundary'
import { ErrorBoundaryLocalView } from 'services/tracking.types'

interface DiffScriptsTabProps {
  left: TestRun
  right: TestRun
}

function DiffScriptsTabComponent({ left, right }: DiffScriptsTabProps) {
  const [changes, setChanges] = useState<number>()
  const [diffMode, setDiffMode] = useState<DiffMode>('side-by-side')
  const [hasExternalImports, setHasExternalImports] = useState(false)

  const [mount, setMount] = useState<HTMLDivElement | null>(null)

  const missingScriptTemplateEl = useRef<HTMLDivElement | null>(null)

  const monaco = useMonaco()
  const editor = useDiffEditor(mount)
  const navigator = useDiffNavigator(editor)

  const handleDiffModeChange = (value: DiffMode) => {
    editor?.updateOptions({
      renderSideBySide: value === 'side-by-side',
    })

    setDiffMode(value)
  }

  const handlePreviousClick = () => {
    navigator?.previous()
  }

  const handleNextClick = () => {
    navigator?.next()
  }

  useEffect(() => {
    const handle = editor?.onDidUpdateDiff(() => {
      setChanges(editor?.getLineChanges()?.length ?? undefined)
    })

    return () => handle?.dispose()
  }, [editor])

  useEffect(() => {
    if (monaco === null || editor === null) {
      return
    }

    if (left.script === null && right.script === null) {
      return
    }

    // If we are missing a script in a test run, we fallback to the
    // script in the other one to simulate there not being any difference.
    // The view will be covered up so the user won't see it anyway.
    const original = monaco.editor.createModel(
      left.script ?? right.script ?? '',
      'javascript'
    )
    const modified = monaco.editor.createModel(
      right.script ?? left.script ?? '',
      'javascript'
    )

    const callbackHandle = editor?.onDidUpdateDiff(async () => {
      try {
        const { getExternalImportDecorations } = await import(
          /* webpackChunkName: "AnalyzeScripts" */ './analyzeScripts'
        )
        const leftImports = getExternalImportDecorations(monaco, left.script)
        const rightImports = getExternalImportDecorations(monaco, right.script)

        editor?.getOriginalEditor().createDecorationsCollection(leftImports)
        editor?.getModifiedEditor().createDecorationsCollection(rightImports)

        setHasExternalImports(leftImports.length > 0 || rightImports.length > 0)
        setChanges(editor?.getLineChanges()?.length ?? undefined)
      } catch (error) {
        sentryClient.captureException(error)
      }
    })

    editor.setModel({
      original,
      modified,
    })

    // When the right script is missing, we need to hide the overview ruler
    // so that the overlay covers the entire editor.
    editor.updateOptions({
      renderOverviewRuler: right.script !== null,
    })

    const templateEl =
      missingScriptTemplateEl.current ?? document.createElement('div')

    const leftOverlay = displayOverlayMessage(
      editor.getOriginalEditor(),
      left.script,
      templateEl
    )

    const rightOverlay = displayOverlayMessage(
      editor.getModifiedEditor(),
      right.script,
      templateEl
    )

    return () => {
      callbackHandle?.dispose()
      leftOverlay?.dispose()
      rightOverlay?.dispose()
    }
  }, [monaco, editor, left.script, right.script])

  const hasScripts = left.script !== null || right.script !== null
  const hasBothScripts = left.script !== null && right.script !== null

  if (!hasScripts) {
    return (
      <ScriptTabWrapper>
        <MissingBothScriptsMessage />
      </ScriptTabWrapper>
    )
  }

  return (
    <ScriptTabWrapper>
      <HijackMonaco />
      <BreakdownTabHeader>
        <DiffMessage
          left={left}
          right={right}
          changes={changes}
          hasExternals={hasExternalImports}
        />
        <ScriptTabButtonsContainer>
          <IconButton
            name="arrow-up"
            disabled={changes === undefined || changes === 0}
            tooltip="Previous change"
            onClick={handlePreviousClick}
          />
          <IconButton
            name="arrow-down"
            disabled={changes === undefined || changes === 0}
            tooltip="Next change"
            onClick={handleNextClick}
          />

          <DiffModeToggle
            diffMode={diffMode}
            disabled={!hasBothScripts}
            onChange={handleDiffModeChange}
          />
        </ScriptTabButtonsContainer>
      </BreakdownTabHeader>
      <LoadingContainer loading={changes === undefined}>
        <DiffEditorMount ref={setMount} />
      </LoadingContainer>
      <MissingScriptMessageTemplate ref={missingScriptTemplateEl} />
    </ScriptTabWrapper>
  )
}

export function DiffScriptsTab(props: DiffScriptsTabProps) {
  return (
    <QueryErrorBoundary view={ErrorBoundaryLocalView.DiffScriptsTab}>
      <DiffScriptsTabComponent {...props} />
    </QueryErrorBoundary>
  )
}
