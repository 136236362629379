import { NoteModal } from 'components/NoteModal'
import React, { useMemo, useState } from 'react'
import { TestRun } from 'types'
import { Button, ButtonVariant, IconName, PopoverContent } from '@grafana/ui'
import { useHasUserProjectWriteAccess } from 'data/usePermissions'
import { getNoteMenuLabel } from 'utils/note'
import { isTestRunExpired } from 'utils/testRun'

interface ButtonsProps {
  icon: IconName
  tooltip?: PopoverContent
  variant?: ButtonVariant
}

interface NoteButtonProps {
  testRun: TestRun
}

export function NoteButton({ testRun }: NoteButtonProps) {
  const { note } = testRun
  const [open, setOpen] = useState(false)
  const hasWriteAccess = useHasUserProjectWriteAccess()
  const hideUI = !hasWriteAccess && note.length === 0 // No note and no write access

  const disabled = isTestRunExpired(testRun) && note.length === 0
  const disabledTooltip = disabled
    ? getEditNoteDisabledText('create')
    : undefined

  const buttonsProps = useMemo<ButtonsProps>(() => {
    return note.length > 0
      ? {
          icon: hasWriteAccess ? 'edit' : 'file-alt',
          tooltip: note,
          variant: 'success',
        }
      : { icon: 'plus' }
  }, [hasWriteAccess, note])

  const handleOpen = () => setOpen(true)
  const handleDismiss = () => setOpen(false)

  const buttonText = getNoteMenuLabel(note, hasWriteAccess)

  if (hideUI) {
    return null
  }

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="secondary"
        disabled={disabled}
        tooltip={disabledTooltip}
        {...buttonsProps}
      >
        {buttonText}
      </Button>
      <NoteModal isOpen={open} onDismiss={handleDismiss} run={testRun} />
    </>
  )
}

export const getEditNoteDisabledText = (action: 'create' | 'edit') => {
  return `It is not possible to ${action} notes for runs with the metric data deleted following the retention period.`
}
