import { differenceBy, intersectionBy } from 'lodash-es'

import { FeatureFlag } from './FeatureFlags.types'
import { FEATURE_FLAGS } from './FeatureFlags.constants'

export const getFeatureFlags = () => {
  return FEATURE_FLAGS.filter((flag) => flag.env[process.env.NODE_ENV])
}

export const hasFlagsChanged = (
  current: FeatureFlag[] = [],
  latest: FeatureFlag[] = []
) => {
  if (current.length !== latest.length) {
    return true
  }

  return differenceBy(current, latest, 'name').length > 0
}

export const updateFlags = (
  current: FeatureFlag[] = [],
  latest: FeatureFlag[] = []
) => {
  const keepFlags = intersectionBy(current, latest, 'name')
  const newFlags = differenceBy(latest, current, 'name')

  return [...keepFlags, ...newFlags]
}
