import { Span } from './types'
import React from 'react'
import { getWebVitalAttributes } from './BrowserTimeline.utils'
import { WebVitalName } from '../../types'
import { WebVitalColor, WebVitalIndicator } from './WebVital.styled'
import { getWebVitalValueConfig } from '../WebVitalGauge'

interface WebVitalProps {
  span: Span
  parentSpan?: Span
  spans?: Span[]
}

export function WebVital({ span }: WebVitalProps) {
  const attribute = getWebVitalAttributes(span.attributes)
  if (attribute === null) {
    return null
  }

  const webVital = getWebVitalValueConfig(
    attribute.name.toLowerCase() as WebVitalName,
    attribute.value as number
  )
  if (!webVital.score) {
    return null
  }

  return (
    <WebVitalIndicator $score={webVital.score}>
      <div>{attribute.name}:</div>
      <WebVitalColor $score={webVital.score}>
        {webVital.toString()}
      </WebVitalColor>
    </WebVitalIndicator>
  )
}
