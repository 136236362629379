import { useProjectClient } from './clients/projects'
import { useAppContext } from 'appContext'
import { useSubscriptionRule } from './useSubscriptions'
import { isTestRunTask } from 'types'

/**
 * The concurrency limit is enforced globally for an organization, but a user might not
 * have access to all projects. Unfortunately we don't have any API for getting the number
 * of running tests across an organization, so this is a best-effort attempt.
 *
 * Luckily, the worst thing that happens if we get this wrong is that we queue a test without
 * showing the "test will be queued" message.
 */
export function useWillQueueTest() {
  const projectClient = useProjectClient()
  const maxConcurrentTests = useSubscriptionRule('tests.concurrent.max')

  const { orgId } = useAppContext()

  // We intentionally don't use useQuery here because we want to know if the
  // test will be queued at the exact moment the user tries to start a test.
  // It also simplifies the code quite a lot.
  return async () => {
    const projects = await projectClient.fetchAll(orgId)

    const tasks = await Promise.all(
      projects.map((project) => projectClient.fetchTasks(project.id))
    )

    const runningTests = tasks.flat().filter(isTestRunTask)

    return runningTests.length >= maxConcurrentTests
  }
}
