import React from 'react'
import { Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import { ProjectPage } from 'pages/ProjectPage'
import { TestPage } from 'pages/TestPage'
import { TestRunPage } from 'pages/TestRunPage'
import { NewTest } from 'pages/NewTest'
import { CLIGuide } from 'pages/CLIGuide'
import { findMatchingRoute, getRoute } from '../routeMap'
import { SettingsPage } from '../pages/SettingsPage'
import { ProjectsListPage } from 'pages/ProjectsListPage'
import { ManagePage } from 'pages/ManagePage/ManagePage'
import { LearnPage } from 'pages/LearnPage'
import { useShowInitPage } from 'pages/InitPage/InitPage.hooks'
import { useProvisioningState } from 'data/useProvisioningState'
import { AppContextProvider } from 'appContext'
import { RedirectToDefaultRoute } from 'components/RedirectToDefaultRoute'
import { Layout } from 'components/Layout'
import { PluginPagePlaceholder } from 'components/PluginPagePlaceholder'
import { ErrorPage } from 'pages/InitPage/components/ErrorPage'
import { InitRoutes } from 'pages/InitPage'
import { NotFound } from 'pages/NotFound'
import { RecorderPage } from 'pages/Recorder'
import { ComparePage } from 'pages/TestRunPage/ComparePage'
import { ProjectContextProvider } from 'projectContext'
import { ActivityPanelContextProvider } from 'activityPanelContextProvider'
import { ProjectRoutes } from 'routes/ProjectRoutes'
import { BrowserTimelinePage } from 'pages/BrowserTimelinePage/BrowserTimelinePage'
import { useFeatureFlag } from 'store/FeatureFlags'
import { PageRoute } from './PageRoute'

function ProjectRedirect() {
  const match = useRouteMatch()
  const location = useLocation()
  const redirectPath = location.pathname.replace(match.url, '')
  const matchedRoute = findMatchingRoute(redirectPath)

  return <Redirect to={matchedRoute} />
}

export function Routes() {
  const showInitPage = useShowInitPage()

  const {
    data: provisioningState,
    error: provisioningError,
    isFetching,
  } = useProvisioningState()

  const browserTimelineEnabled = useFeatureFlag('browserTimeline') // FEATURE GATE: browserTimeline

  if (provisioningError) {
    return <ErrorPage title="Initialization error" />
  }

  if (isFetching) {
    return <PluginPagePlaceholder />
  }

  // Only used when k6 hasn't been provisioned
  /*
   * Enable:
   * <URL>?showInitPage to enable
   *
   * Disable:
   * <URL>?showInitPage=false
   * */

  if (provisioningState?.initialized === false || showInitPage) {
    return <InitRoutes />
  }

  return (
    <AppContextProvider>
      <Layout>
        <Switch>
          <PageRoute path={getRoute('projectsList')} exact>
            <ProjectsListPage />
          </PageRoute>
          <PageRoute path={getRoute('recorder')}>
            <RecorderPage />
          </PageRoute>
          <PageRoute path={getRoute('cliGuide')}>
            <CLIGuide />
          </PageRoute>
          <PageRoute path={getRoute('settings')}>
            <SettingsPage />
          </PageRoute>
          <PageRoute path={getRoute('manage')}>
            <ManagePage />
          </PageRoute>
          <PageRoute path={getRoute('learn')} exact>
            <LearnPage />
          </PageRoute>
          <PageRoute path={getRoute('home')} exact>
            <RedirectToDefaultRoute />
          </PageRoute>

          {/* FEATURE GATE: browserTimeline */}
          {browserTimelineEnabled && (
            <PageRoute path={getRoute('browserTimeline')} exact>
              <BrowserTimelinePage />
            </PageRoute>
          )}

          {/* FEATURE GATE: browserTimeline */}
          {browserTimelineEnabled && (
            <PageRoute path={getRoute('browserTimelineRun')} exact>
              <BrowserTimelinePage />
            </PageRoute>
          )}

          <ProjectContextProvider>
            <ActivityPanelContextProvider>
              <Switch>
                <PageRoute path={getRoute('projectRoute')}>
                  <ProjectRoutes />
                </PageRoute>
                <PageRoute path={getRoute('project')} exact>
                  <ProjectPage />
                </PageRoute>
                <PageRoute path={getRoute('projectsRedirect')}>
                  <ProjectRedirect />
                </PageRoute>
                <PageRoute exact path={getRoute('newTest')}>
                  <NewTest />
                </PageRoute>
                <PageRoute path={getRoute('test')}>
                  <TestPage />
                </PageRoute>
                <PageRoute path={getRoute('testRunCompare')} exact>
                  <ComparePage />
                </PageRoute>
                <PageRoute path={getRoute('testRun')} exact>
                  <TestRunPage />
                </PageRoute>
              </Switch>
            </ActivityPanelContextProvider>
          </ProjectContextProvider>

          <PageRoute>
            <NotFound />
          </PageRoute>
        </Switch>
      </Layout>
    </AppContextProvider>
  )
}
