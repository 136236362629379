import React, { useMemo } from 'react'
import { type AbsoluteTimeRange } from '@grafana/data'

import { Check, TestRunAnalysis } from 'types'
import { RunsPageTestIds } from 'types/dataTestIds'
import { ChecksChart } from './ChecksChart'

import { useTimeRange } from '../../../TimeRangeContext'
import { useRunDetailsChecksFilters } from '../../Filters/Filters.hooks'
import { ChecksFilters } from './ChecksFilters'
import { ChecksCounters } from './ChecksCounters'
import { TableDefinition, ViewType } from '../BreakdownTable'
import { toChecksQueryKey } from 'data/queryKeys'
import {
  CheckClient,
  CheckWithScenario,
  useCheckClient,
} from 'data/clients/entities/checks'
import { useEnumParameter } from 'hooks/useQueryParameter'
import { EmptyFilterView } from 'components/EmptyFilterView'
import { BreakdownView } from '../BreakdownView'
import { FilterExpression, serializeRunFilter } from 'datasource/serialization'
import { asCount, asPercent } from '../ComparedValue'
import { byGroup, byRootGroup } from '../utils'
import { ChecksEmptyView } from './ChecksEmptyView'
import { QueryErrorBoundary } from 'components/ErrorBoundary'
import { ErrorBoundaryLocalView } from 'services/tracking.types'

interface ChecksTableProps {
  client: CheckClient
  filter: FilterExpression<Check>
  timeRange: AbsoluteTimeRange | undefined
}

export const table: TableDefinition<CheckWithScenario, ChecksTableProps> = {
  id: 'checks-breakdown',
  paginator: 'always',
  pageSize: 20,

  keyBy(row) {
    return row.id
  },

  groupBy(row) {
    return row.group_id
  },

  isSuccess(row) {
    return row.metric_summary.fail_count === 0
  },

  getRowCount({ checks_metric_summary }) {
    return checks_metric_summary.total
  },

  fetchPage({ client, testRun, filter, ...rest }) {
    const params = {
      ...rest,
      filter: byRootGroup(filter),
    }

    return {
      queryKey: toChecksQueryKey(testRun.id, params),
      queryFn: () => client.fetchPage(testRun.id, params),
    }
  },

  fetchByRows({ client, testRun, rows }) {
    const ids = rows.map((check) => check.id).sort()

    return {
      queryKey: toChecksQueryKey(testRun.id, { ids }),
      queryFn: () => client.fetchByIds(testRun.id, ids),
    }
  },

  fetchByGroup({ client, testRun, group, filter, ...rest }) {
    const params = {
      ...rest,
      filter: byGroup(group, filter),
    }

    return {
      queryKey: toChecksQueryKey(testRun.id, params),
      queryFn: (page) => client.fetchPage(testRun.id, { ...params, page }),
    }
  },

  columns: [
    {
      id: 'success-indicator',
      name: '',
      shrink: true,
      toggle: 'none',

      shouldRender: ({ analysis }) => analysis.compareWith === undefined,
      renderCell: (left, right) =>
        right === undefined && <ChecksCounters summary={left.metric_summary} />,
    },
    {
      id: 'name',
      name: 'Name',
      sortBy: 'name',
      toggle: 'none',

      renderCell: ({ name }) => name,
      renderGroup: ({ name }) => name,
    },
    {
      id: 'scenario',
      name: 'Scenario',
      sortBy: 'scenario_id',
      toggle: 'none',

      shouldRender: ({ view }) => view === 'list',
      renderCell: ({ scenario }) => scenario,
    },
    {
      id: 'success-rate',
      name: 'Success rate',
      sortBy: 'metric_summary/success_rate',
      numeric: true,

      renderCell: asPercent(
        ({ metric_summary }) => metric_summary.success_rate
      ),
      renderGroup: asPercent(
        ({ checks_metric_summary: { hits_successes, hits_total } }) => {
          if (hits_successes === null || hits_total === null) {
            return null
          }

          return hits_successes / hits_total
        }
      ),
    },
    {
      id: 'success-count',
      name: 'Success count',
      sortBy: 'metric_summary/success_count',
      numeric: true,

      renderCell: asCount((row) => row.metric_summary.success_count),
      renderGroup: asCount((row) => row.checks_metric_summary.hits_successes),
    },
    {
      id: 'fail-count',
      name: 'Fail count',
      sortBy: 'metric_summary/fail_count',
      numeric: true,

      renderCell: asCount((row) => row.metric_summary.fail_count),
      renderGroup: asCount(({ checks_metric_summary }) => {
        if (
          checks_metric_summary.hits_total === null ||
          checks_metric_summary.hits_successes === null
        ) {
          return null
        }

        return (
          checks_metric_summary.hits_total -
          checks_metric_summary.hits_successes
        )
      }),
    },
  ],

  RowBodyComponent: ({ analysis, value }) => (
    <ChecksChart analysis={analysis} check={value} />
  ),
  EmptyGroupComponent: () => (
    <>No checks were evaluated during test execution.</>
  ),
}

interface ChecksTabProps {
  analysis: TestRunAnalysis
}

const ChecksTabComponent = ({ analysis }: ChecksTabProps) => {
  const { timeRange } = useTimeRange()
  const client = useCheckClient()
  const urlFiltersProps = useRunDetailsChecksFilters()
  const { filters, sortBy, removeAllFilters, updateSortBy } = urlFiltersProps

  const [view, setView] = useEnumParameter('view', ['list', 'tree'] as const)

  const handleViewToggle = (newView: ViewType) => {
    setView('replace', newView)
  }

  const handleClearFilter = () => {
    removeAllFilters()
  }

  const filter = useMemo(() => serializeRunFilter<Check>(filters), [filters])

  const checksSummaryTotal = analysis.main.checks_metric_summary?.total ?? 0

  if (checksSummaryTotal === 0) {
    return <ChecksEmptyView testRun={analysis.main} />
  }

  return (
    <BreakdownView
      data-testid={RunsPageTestIds.ChecksTable}
      view={view}
      analysis={analysis}
      params={{ client, filter, timeRange }}
      table={table}
      header={<ChecksFilters run={analysis.main} {...urlFiltersProps} />}
      emptyTable={
        <EmptyFilterView onClear={handleClearFilter}>
          No checks match the current filter...
        </EmptyFilterView>
      }
      onViewChange={handleViewToggle}
      sortBy={sortBy}
      onSort={updateSortBy}
    />
  )
}

export const ChecksTab = (props: ChecksTabProps) => (
  <QueryErrorBoundary view={ErrorBoundaryLocalView.ChecksTab}>
    <ChecksTabComponent {...props} />
  </QueryErrorBoundary>
)
