import React from 'react'
import { Container, FullName, ShortName } from './WebVitalGauge.styled'
import { WebVitalBarGauge } from './WebVitalBarGauge'
import { WebVitalValue } from './WebVitalValue'
import { WebVitalName } from './types'
import { WEB_VITAL_CONFIG } from './constants'
import { getWebVitalValueConfig } from './utils'
import { RunsPageTestIds } from 'types/dataTestIds'

interface WebVitalGaugeProps {
  disabled?: boolean
  name: WebVitalName
  value: number | null
  compareValue?: number | null
}

export function WebVitalGauge({
  value,
  name,
  compareValue = null,
  disabled,
}: WebVitalGaugeProps) {
  const valueConfig = getWebVitalValueConfig(name, value)
  const compareValueConfig =
    compareValue !== null
      ? getWebVitalValueConfig(name, compareValue ?? undefined)
      : undefined
  const config = WEB_VITAL_CONFIG[name]

  return (
    <Container data-testid={RunsPageTestIds.SummaryStatistic}>
      <div data-testid={RunsPageTestIds.StatisticLabel}>
        <ShortName>{config.name}</ShortName>
        <FullName>{config.longName}</FullName>
      </div>
      {!disabled && (
        <div>
          <WebVitalValue
            value={valueConfig}
            compareValue={compareValueConfig}
          />
          <WebVitalBarGauge
            value={valueConfig}
            compareValue={compareValueConfig}
          />
        </div>
      )}
    </Container>
  )
}
