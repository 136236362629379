import React, { MouseEvent, PropsWithChildren, ReactElement } from 'react'
import { Badge, BadgeColor, Tooltip } from '@grafana/ui'

import { BadgeColorCodes, TestRun } from 'types'
import {
  getTestRunColorString,
  getTestStatusDocsLink,
  getTestStatusText,
} from 'pages/utils'
import { isTestAborted, isTestDone, isTestFailed } from 'utils/testRun'
import {
  BadgeLink,
  LatestRunDetail,
  LatestRunDetailsSection,
} from './LatestRunDetails.styles'
import { routeMap } from 'routeMap'
import { DurationMetaItem, VUsMetaItem } from 'components/MetaItem'
import { TimeSinceLastRun } from 'components/TimeSince'
import { DocLink } from 'components/DocLink'

interface LastRunProps {
  run: TestRun
}

const LastRun = ({ run }: LastRunProps) => {
  return (
    <>
      LAST RUN
      <TimeSinceLastRun timestamp={run.created} />
    </>
  )
}

interface RunStatusBadgeProps extends TestRun {}

const RunStatusBadge = (run: RunStatusBadgeProps) => {
  const color = getTestRunColorString(run) as BadgeColor

  const handleLinkClick = (event: MouseEvent) => {
    event.stopPropagation()
  }

  return (
    <RunStatusTooltip run={run}>
      <BadgeLink
        style={{ color: BadgeColorCodes[color] }}
        onClick={handleLinkClick}
        to={routeMap.testRun(run.id)}
      >
        <Badge color={color} text={getTestStatusText(run)} />
      </BadgeLink>
    </RunStatusTooltip>
  )
}

interface RunStatusBadgeTooltipProps {
  run?: TestRun
  children: ReactElement
}

export const RunStatusTooltip = ({
  run,
  children,
}: PropsWithChildren<RunStatusBadgeTooltipProps>) => {
  if (!run) {
    return <>{children}</>
  }

  const docsLink = getTestStatusDocsLink(run)

  if (!docsLink) {
    return <>{children}</>
  }

  return (
    <Tooltip
      interactive
      content={
        <>
          <DocLink article={docsLink}>
            Read more about what {getTestStatusText(run)} means
          </DocLink>
        </>
      }
    >
      {children}
    </Tooltip>
  )
}

interface TestRunDetailsProps {
  testRun: TestRun
}

const TestRunDetails = ({ testRun }: TestRunDetailsProps) => {
  return (
    <>
      <VUsMetaItem testRun={testRun} />
      <DurationMetaItem testRun={testRun} />
    </>
  )
}

export type LatestRunDetailsProps = {
  testRun: TestRun | undefined
}

export const LatestRunDetails = ({ testRun }: LatestRunDetailsProps) => {
  if (!testRun) {
    return <LatestRunDetailsSection>No test runs</LatestRunDetailsSection>
  }

  const showStatusBadge =
    isTestAborted(testRun) || !isTestDone(testRun) || isTestFailed(testRun)

  return (
    <LatestRunDetailsSection>
      <LatestRunDetail>
        <LastRun run={testRun} />
      </LatestRunDetail>
      <LatestRunDetail>
        {showStatusBadge ? (
          <RunStatusBadge {...testRun} />
        ) : (
          <TestRunDetails testRun={testRun} />
        )}
      </LatestRunDetail>
    </LatestRunDetailsSection>
  )
}
