import React, { useMemo } from 'react'

import {
  TestRun,
  TestRunThresholdsFilter,
  TestRunThresholdsFilterBy,
} from 'types'

import { RunDetailsFilters } from '../../Filters/Filters'
import { useURLFilters } from '../../Filters/Filters.hooks'

interface ThresholdsFiltersProps
  extends ReturnType<typeof useURLFilters<TestRunThresholdsFilter>> {
  run: TestRun
}

export const ThresholdsFilters = ({
  addFilterValue,
  addNewFilter,
  filters,
  options,
  removeAllFilters,
  removeFilter,
  replaceFilters,
  run,
}: ThresholdsFiltersProps) => {
  const presets = useMemo(
    () => [
      {
        label: 'Only failed thresholds',
        value: 'failed',
        onChange: () => {
          replaceFilters([
            {
              by: TestRunThresholdsFilterBy.Tainted,
              label: 'Tainted',
              values: ['true'],
            },
          ])
        },
      },
      {
        label: 'Only passed thresholds',
        value: 'passed',
        onChange: () => {
          replaceFilters([
            {
              by: TestRunThresholdsFilterBy.Tainted,
              label: 'Tainted',
              values: ['false'],
            },
          ])
        },
      },
    ],
    [replaceFilters]
  )

  return (
    <RunDetailsFilters
      filters={filters}
      options={options}
      run={run}
      presets={presets}
      onClearFiltersClick={removeAllFilters}
      onAddNewFilterClick={addNewFilter}
      onRemoveFilterClick={removeFilter}
      onAddFilterValue={addFilterValue}
    />
  )
}
