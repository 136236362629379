import React from 'react'
import { Link } from 'react-router-dom'
import { Badge, IconButton, Tooltip } from '@grafana/ui'

import { CardBody, CardHeader } from 'components/Card'
import { routeMap } from 'routeMap'
import { Members } from 'components/Members'
import {
  useIsRBACEnabled,
  useIsUserProjectAdminByProjectId,
} from 'data/usePermissions'
import { MembersContainer, StyledCard } from './ProjectCard.styles'
import { Project, ProjectWithStats } from 'types'
import { ProjectStats } from './ProjectStats'
import { useSubscriptionRule } from 'data/useSubscriptions'
import { ProjectMenu } from 'components/ProjectMenu'

interface ProjectCardProps {
  project: Project
  isDefault: boolean
  statsPeriod: string
  stats?: ProjectWithStats
}

export const ProjectCard = ({
  project,
  isDefault,
  stats,
  statsPeriod,
}: ProjectCardProps) => {
  const isUserProjectAdmin = useIsUserProjectAdminByProjectId(project.id)
  const isRBACEnabled = useIsRBACEnabled()
  const hasUsageReports = useSubscriptionRule('organization.usage_reports')

  return (
    <StyledCard>
      <CardHeader>
        <h5>
          <Link to={routeMap.project(project.id)}>{project.name}</Link>
        </h5>
        {isDefault && (
          <Tooltip
            interactive
            content={
              <>
                Tests started via k6 CLI are saved in this project
                <br />
                unless{' '}
                <Link to={routeMap.cliGuide}>
                  projectID is specified in test options.
                </Link>
                <br />
                <br />
                <i>The default project cannot be changed or deleted</i>
              </>
            }
          >
            <span>
              <Badge text="Default" color="purple" />
            </span>
          </Tooltip>
        )}
        {isUserProjectAdmin && (
          <ProjectMenu project={project} isDefault={isDefault}>
            <IconButton
              aria-label="Open project menu"
              name="ellipsis-v"
              size="lg"
            />
          </ProjectMenu>
        )}
      </CardHeader>

      <CardBody>
        {isUserProjectAdmin && !isRBACEnabled && (
          <MembersContainer>
            <Members
              projectId={project.id}
              maxMembers={4}
              showAddButton={false}
            />
          </MembersContainer>
        )}
        {hasUsageReports && <ProjectStats stats={stats} period={statsPeriod} />}
      </CardBody>
    </StyledCard>
  )
}
