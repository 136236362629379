import { TabContent } from '@grafana/ui'
import styled from 'styled-components'

import { PageSection } from 'components/PageSection'
import { Header } from 'components/CodeSnippet/CodeSnippet.styles'
import { color, spacing } from 'utils/styled'

export const RunDetailsSection = styled(PageSection)`
  padding: 0;
  margin-bottom: ${spacing(3)};
  background-color: ${color('background', 'secondary')};

  .loading,
  .enter,
  .empty-filter-view,
  .rdt_TableRow,
  .rdt_TableHeadRow,
  .rdt_ExpanderRow {
    background-color: ${color('background', 'secondary')};
  }

  .rdt_TableRow:hover {
    background-color: ${color('action', 'hover')};
  }

  ${Header} {
    background-color: ${color('secondary', 'main')};
  }

  .wrapper > div {
    background-color: transparent;
  }
`

export const RunDetailsTabContent = styled(TabContent)`
  background-color: transparent;
`

export const SelectTestComparisonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`

export const TimeRangeResetButtonWrapper = styled.div`
  padding: ${spacing(2)};
`
