import React, { ReactNode } from 'react'
import { Logo } from 'components/Logo' // has built-in right margin
import { Heading, PageTitleStyled } from './PageTitle.styled'

export function PageTitle({ title = '' }: { title?: ReactNode }) {
  return (
    <PageTitleStyled>
      <Logo />
      <Heading>{title}</Heading>
    </PageTitleStyled>
  )
}
