import React from 'react'
import styled, { css } from 'styled-components'
import { Tooltip } from '../Tooltip'
import { TagExpressionText, TagName, TagValue } from './TagExpression'
import { DocLink, docs } from '../DocLink'
import { color, spacing } from 'utils/styled'
import { ClipboardIconButton } from '../ClipboardIconButton'
import { TagExpression } from 'types'

type TagsTooltipProps = {
  tagExpressions: TagExpression[]
}

const PREVIEW_TAGS_COUNT = 3

export const TagsTooltip = ({ tagExpressions }: TagsTooltipProps) => {
  // Sort expressions by length in order to show as less text as possible in the tags preview
  const sortedTagExpressions = tagExpressions.sort(
    (a, b) =>
      Object.values(a).join('').length - Object.values(b).join('').length
  )

  const previewTagsExpressions = sortedTagExpressions.slice(
    0,
    PREVIEW_TAGS_COUNT
  )

  return (
    <Tooltip
      placement="bottom-end"
      interactive
      content={
        <span>
          <TooltipContent>
            <Title>TAGS:</Title>
            {sortedTagExpressions.map((expression) => {
              const { name, operator, values } = expression
              const parsedOperator = operator === 'equal' ? 'is' : 'is not'
              return (
                <TagExpressionWrapper key={`${name}.${operator}.${values}`}>
                  <TagExpressionText expression={expression} />
                  <ClipboardIconButton
                    data={`${name} ${parsedOperator} ${values[0]}`}
                  />
                </TagExpressionWrapper>
              )
            })}
          </TooltipContent>
          <DocLinkContainer>
            <DocLink article={docs.insights.tagsHelp}>Need help?</DocLink>
          </DocLinkContainer>
        </span>
      }
    >
      <Content>
        <TagsWrapper>
          {sortedTagExpressions.length > 0 &&
            previewTagsExpressions
              .slice(0, PREVIEW_TAGS_COUNT)
              .map((tag, index) => (
                <>
                  {index !== 0 ? ', ' : ''}
                  <TagExpressionText expression={tag} />
                </>
              ))}
        </TagsWrapper>
        {sortedTagExpressions.length > PREVIEW_TAGS_COUNT && (
          <RemainingTagsCount>{`+${
            sortedTagExpressions.length - PREVIEW_TAGS_COUNT
          }`}</RemainingTagsCount>
        )}
      </Content>
    </Tooltip>
  )
}

const Content = styled.div`
  display: flex;
`

const TagsWrapper = styled.div`
  align-items: center;
  cursor: pointer;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      max-width: calc(50vw - 50px);
    }

    @media (min-width: ${theme.breakpoints.values.lg}px) and (max-width: ${theme
        .breakpoints.values.xl}px) {
      max-width: calc(25vw - 50px);
    }

    @media (min-width: ${theme.breakpoints.values.xl}px) and (max-width: ${theme
        .breakpoints.values.xxl}px) {
      max-width: calc(40vw - 50px);
    }
  `}

  span {
    text-transform: initial;
  }

  ${TagName} {
    margin-left: 3px;
  }
`

const RemainingTagsCount = styled.div`
  margin-left: 8px;
  font-weight: 500;

  ${({ theme }) =>
    css`
      color: ${color('text', 'primary')};
      :hover {
        color: ${color('text', 'primary')};
      }
    `}
`

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 600;
`

const TagExpressionWrapper = styled.div`
  margin-top: ${spacing(0.5)};
  display: flex;
  justify-content: center;

  ${TagValue} {
    margin-right: ${spacing(1)};
    color: ${color('text', 'maxContrast')};
  }
`

const Title = styled.span`
  margin-bottom: 3px;
`

const DocLinkContainer = styled.div`
  width: 100%;
  text-align: right;
  a {
    color: ${color('text', 'link')};
  }
`
