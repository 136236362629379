import React from 'react'
import { Button } from '@grafana/ui'
import { useHistory } from 'react-router-dom'
import { getRoute } from 'routeMap'
import styled from 'styled-components'
import { Flex } from 'components/Flex'
import { spacing } from 'utils/styled'
import { useIsUserOrgAdmin } from 'data/usePermissions'
import { useNotifications } from '../hooks/useNotifications'

export const CreateNotificationButton = () => {
  const isOrgAdmin = useIsUserOrgAdmin()
  const history = useHistory()

  const { data } = useNotifications(isOrgAdmin)

  const notificationData = data || []

  const handleCreateNotification = () =>
    history.push(getRoute('newNotification'))

  if (notificationData?.length === 0) {
    return null
  }

  return (
    <ButtonContainer justify="flex-end" width="100%">
      <Button
        tooltip={
          !isOrgAdmin
            ? "You don't have permissions to setup new notifications"
            : ''
        }
        disabled={!isOrgAdmin}
        onClick={handleCreateNotification}
      >
        Create new notification
      </Button>
    </ButtonContainer>
  )
}

const ButtonContainer = styled(Flex)`
  margin-top: ${spacing(2)};
`
