import React, { FunctionComponent, ReactNode } from 'react'
import { PromoCardsStyled } from './PromoCards.styled'
import { HeaderContainer, SmallPromoCardStyled } from './SmallPromoCard.styled'
import { useTheme2 } from '@grafana/ui'
import { css } from '@emotion/css'

interface PromoItem {
  icon: FunctionComponent
  title: ReactNode
  text: ReactNode
  moreURL?: string
}

export interface PromoCardsProps {
  items: PromoItem[]
}

export function PromoCards({ items }: PromoCardsProps) {
  const theme = useTheme2()
  const styles = css`
    @container (max-width: ${theme.breakpoints.values.xl}) {
      & > div {
        flex-basis: 50% !important;
      }
    }

    @container (max-width: ${theme.breakpoints.values.sm}px) {
      flex-direction: column;
      & > div {
        max-width: 100%;
        flex: 0 0 0 !important;
      }
    }
  `

  return (
    <PromoCardsStyled className={styles}>
      {items.map((item, index) => (
        <Card key={index} {...item} />
      ))}
    </PromoCardsStyled>
  )
}

function Card({ icon: Icon, title, text }: PromoItem) {
  const theme = useTheme2()
  const styles = css`
    @container (max-width: ${theme.breakpoints.values.sm}px) {
      & [class$='-PromoIcon'] {
        flex: 0 0 22px !important;
        height: 22px;
      }
    }
  `
  return (
    <SmallPromoCardStyled className={styles}>
      <HeaderContainer>
        <Icon />
        <h4>{title}</h4>
      </HeaderContainer>
      <span>{text}</span>
    </SmallPromoCardStyled>
  )
}
