import { GrafanaTheme2, ThemeVizHue } from '@grafana/data'

import {
  PreferredColor,
  ColorShades,
  ColorGroups,
  ColorHue,
  MetricColor,
} from 'datasource/models'

const greyHues: ThemeVizHue = {
  name: ColorGroups.Grey,
  shades: [
    {
      name: 'super-light-grey',
      color: '#f5f5f5',
    },
    {
      name: 'light-grey',
      color: '#a0a2ba',
    },
    {
      name: 'grey',
      color: '#868686',
    },
    {
      name: 'semi-dark-grey',
      color: '#686868',
    },
    {
      name: 'dark-grey',
      color: '#454545',
    },
  ],
}

export class ColorPool {
  visualization: GrafanaTheme2['visualization']
  allHues: ColorHue[]
  usedHues: Map<string, number>
  usedExact: Map<string, number>

  constructor(visualization: GrafanaTheme2['visualization']) {
    const allHues = [...visualization.hues, greyHues]
      .map(({ shades }) => shades.map(({ name }) => name).reverse())
      .flat() as ColorHue[]

    this.visualization = visualization
    this.allHues = allHues
    this.usedHues = new Map()
    this.usedExact = new Map()
  }

  getColorByName(hue: ColorHue) {
    if (hue.includes('grey')) {
      const color = greyHues.shades.find((shade) => shade.name === hue)?.color

      if (!color) {
        throw new Error(`Could not find color for ${hue}`)
      }

      return color
    }

    return this.visualization.getColorByName(hue)
  }

  isAvailable(key: string) {
    return (this.usedExact.get(key) ?? 0) === 0
  }

  getHue(group?: ColorGroups, shade?: ColorShades): ColorHue {
    if (!group) {
      return this.allHues[0] as ColorHue
    }

    return `${shade}${group}` as ColorHue
  }

  closestHue(hue: ColorHue): ColorHue {
    const remaining = [...this.allHues].filter((hue) => {
      return this.usedHues.get(hue) !== 1
    })

    if (!hue) {
      return remaining[0] as ColorHue
    }

    const originalHueIndex = this.allHues.indexOf(hue)
    const closestHueIndex = closestIndex(
      this.allHues,
      remaining,
      originalHueIndex
    )

    if (closestHueIndex === -1 || closestHueIndex === undefined) {
      this.reset()
      return hue
    }

    const nextHue = this.allHues[closestHueIndex] as ColorHue
    return nextHue
  }

  reset() {
    this.usedHues = new Map()
    this.usedExact = new Map()
  }

  find(preferred: PreferredColor = {}) {
    // Try to use the preferred, standard color for the metric
    if (preferred.exact && this.isAvailable(preferred.exact)) {
      return this.take(preferred.exact)
    }

    const { group, shade = ColorShades.Primary } = preferred
    const hue = this.getHue(group, shade)
    const color = this.getColorByName(hue)

    if (this.isAvailable(color)) {
      return this.take(color, hue)
    }

    const newHue = this.closestHue(hue)
    const newColor = this.getColorByName(newHue)
    return this.take(newColor, newHue)
  }

  private take(color: string, hue?: string) {
    this.usedExact.set(color, 1)

    if (hue) {
      this.usedHues.set(hue, 1)
    }

    return color as MetricColor
  }
}

export function closestIndex<T>(
  original: T[],
  remaining: T[],
  originalIndex: number
) {
  const remainingIndexes = remaining.map((value) => original.indexOf(value))
  const halfway = original.length / 2
  const favorHigh = originalIndex + 1 > halfway
  const withDistances = mapIndicesWithDistances(remainingIndexes, originalIndex)
  const sorted = sortByDistance(withDistances, favorHigh)

  return sorted[0]?.index
}

export function mapIndicesWithDistances(
  indexes: number[],
  targetIndex: number
) {
  return indexes.map((indexValue) => {
    return {
      index: indexValue,
      distance: Math.abs(indexValue - targetIndex),
    }
  })
}

export function sortByDistance(
  distancedIndices: Array<{ distance: number; index: number }>,
  favorHigh: boolean
) {
  return [...distancedIndices].sort((a, b) => {
    const sameDistance = a.distance === b.distance

    if (favorHigh && sameDistance) {
      return -1
    }

    return a.distance - b.distance
  })
}
