import { UseQueryResult } from '@tanstack/react-query'
import { DynamicConfigValue } from '@grafana/data'

import { K6DataSource } from 'datasource/datasource'
import { LogPanelConfig, TimeSeriesPanelConfig } from 'types/panels'
import { TestRun } from 'types'
import { TestRunWithData } from './Chart.types'

import { DEFAULT_FIELD_CONFIG } from 'utils/fieldConfigs'
import { toQueryTarget } from 'utils/dataFrame'
import {
  LogsConfig,
  MetricConfig,
  MetricData,
  Query,
  TestRunConfigType,
} from 'datasource/models'
import { logPanelOptions, timeSeriesPanelOptions } from './panelOptions'

import { exhaustive } from 'utils/typescript'

interface MetricWithQuery {
  metric: MetricConfig | LogsConfig
  query: Query
}

const toMetricWithQuery = (testRun: TestRun) => {
  return (
    metric: MetricConfig | LogsConfig,
    index: number
  ): MetricWithQuery => {
    return {
      metric,
      query: toQueryTarget(index, testRun, metric),
    }
  }
}

const ignoredObjectPaths = new Set([
  'color',
  'thresholds',
  'custom.thresholdsStyle',
])

const toDynamicValueOverrides = (
  target: object,
  path = ''
): DynamicConfigValue[] => {
  if (target === null) {
    return []
  }

  const result: DynamicConfigValue[] = []

  for (let [property, value] of Object.entries(target)) {
    if (value === undefined) {
      continue
    }

    const propertyPath = path + property

    if (
      !ignoredObjectPaths.has(propertyPath) &&
      typeof value === 'object' &&
      value !== null &&
      !Array.isArray(value)
    ) {
      result.push(...toDynamicValueOverrides(value, propertyPath + '.'))

      continue
    }

    result.push({
      id: propertyPath,
      value,
    })
  }

  return result
}

export const toPanelConfig = (
  datasource: K6DataSource,
  title: string,
  testRun: TestRun,
  metrics: MetricConfig[] | LogsConfig[]
): TimeSeriesPanelConfig | LogPanelConfig => {
  const queries = metrics.map(toMetricWithQuery(testRun))

  if (metrics.some((metric) => metric.type !== metrics[0]?.type)) {
    throw new Error('Different type metrics in one panel are not supported')
  }

  if (!metrics[0]) {
    throw new Error('At least one metric is required')
  }

  const metricType = metrics[0]?.type

  const commonPanelConfig = {
    id: 0,
    gridPos: {
      h: 5,
      w: 12,
      x: 0,
      y: 5,
    },
    title: title,
    fieldConfig: {
      defaults: {},
      overrides: [],
    },
    datasource: {
      uid: datasource.uid,
      type: datasource.type,
    },
    targets: queries.map((item) => item.query),
  }

  switch (metricType) {
    case TestRunConfigType.Metric:
    case TestRunConfigType.Traces: {
      return {
        ...commonPanelConfig,
        fieldConfig: {
          defaults: DEFAULT_FIELD_CONFIG,
          overrides: [],
        },
        type: 'timeseries',
        options: timeSeriesPanelOptions,
      }
    }

    case TestRunConfigType.Logs: {
      return {
        ...commonPanelConfig,
        type: 'logs',
        options: logPanelOptions,
      }
    }

    default:
      return exhaustive(metricType)
  }
}

export const isTestRunWithData = (
  testRun: TestRun
): testRun is TestRunWithData => {
  return !!testRun.started && !!testRun.ended
}

export const hasSeriesValues = (series: Array<UseQueryResult<MetricData>>) => {
  return series.some((result) => result.data?.data?.values?.length ?? 0 > 0)
}

export const toTestRunWithData = (testRun: TestRun | undefined) => {
  if (testRun === undefined || !isTestRunWithData(testRun)) {
    return undefined
  }

  return testRun
}
