import React from 'react'
import styled from 'styled-components'
import { Icon } from '@grafana/ui'

import { spacing } from 'utils/styled'
import { BadgeColorCodes, StaticIPStatus } from 'types'

export const StaticIPsStatusLabel = ({
  status,
}: {
  status: StaticIPStatus
}) => {
  return (
    <ColumnContainer>
      <StatusIcon
        name="circle-mono"
        $color={PROVISIONING_STATUS_COLORS[status]}
      />
      <Text>{PROVISIONING_STATUS_TEXT[status]}</Text>
    </ColumnContainer>
  )
}

const PROVISIONING_STATUS_TEXT = {
  [StaticIPStatus.PROVISIONED]: 'Provisioned',
  [StaticIPStatus.PROVISIONING]: 'Provisioning',
  [StaticIPStatus.PROVISIONING_ERROR]: 'Error',
  [StaticIPStatus.PROVISIONING_ERROR_QUOTA_REACHED]: 'Error',
  [StaticIPStatus.RELEASING_ERROR]: 'Error',
  [StaticIPStatus.RELEASING]: 'Deleting',
}

const PROVISIONING_STATUS_COLORS = {
  [StaticIPStatus.PROVISIONED]: BadgeColorCodes['green'],
  [StaticIPStatus.PROVISIONING]: BadgeColorCodes['blue'],
  [StaticIPStatus.PROVISIONING_ERROR]: BadgeColorCodes['red'],
  [StaticIPStatus.PROVISIONING_ERROR_QUOTA_REACHED]: BadgeColorCodes['red'],
  [StaticIPStatus.RELEASING_ERROR]: BadgeColorCodes['red'],
  [StaticIPStatus.RELEASING]: BadgeColorCodes['purple'],
}

const ColumnContainer = styled.div`
  display: flex;
  align-items: center;
`

const Text = styled.span`
  margin-left: ${spacing(1)};
`

const StatusIcon = styled(Icon)<{ $color: string }>`
  color: ${(props) => props.$color};
`
