import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { getRoute } from 'routeMap'
import { useIsUserOrgAdmin } from 'data/usePermissions'
import { Section } from 'components/Section'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { DocLink, docs } from 'components/DocLink'
import { CreateNotificationForm } from './components/NotificationForm/CreateNotificationForm'
import { EditNotificationForm } from './components/NotificationForm/EditNotificationForm'
import { NotificationsTable } from './components/NotificationsTable/NotificationsTable'
import { CreateNotificationButton } from './components/CreateNotificationButton'
import { ErrorBoundaryFullPageView } from 'services/tracking.types'

export const NotificationsTab = () => {
  const isUserOrgAdmin = useIsUserOrgAdmin()

  return (
    <Section
      title={
        <Section.ProtectedTitle
          title="Notifications"
          hasPermissions={isUserOrgAdmin}
        />
      }
      description={
        <>
          Notifications allow external services to be informed when certain
          events happen withing your stack projects. Read more about
          notifications in the{' '}
          <DocLink article={docs.manage.notifications}>docs</DocLink>.
        </>
      }
      actions={<CreateNotificationButton />}
    >
      <ErrorBoundary view={ErrorBoundaryFullPageView.NotificationsTab}>
        <Switch>
          <Route path={getRoute('notifications')} exact>
            <NotificationsTable />
          </Route>
          <Route path={getRoute('newNotification')} exact>
            <CreateNotificationForm />
          </Route>
          <Route path={getRoute('editNotification')} exact>
            <EditNotificationForm />
          </Route>
        </Switch>
      </ErrorBoundary>
    </Section>
  )
}
