import {
  object,
  RefinementCtx,
  ZodIssueCode,
  array,
  number,
  record,
  type infer as Infer,
  type IssueData,
} from 'zod'
import { ExtOptionsSchema, CloudOptionsSchema } from './cloudOptions'
import { Duration, StageSchema, VUs } from '../primitives'
import { ScenarioSchema } from './scenario'
import { InvalidShorthandCombination, InvalidUseOfVUs } from './errors'

/**
 * @deprecated This is the old schema, use `OptionsBaseSchema` instead.
 */
const LegacyOptionsBaseSchema = object({
  ext: ExtOptionsSchema.optional(),
})

const OptionsBaseSchema = LegacyOptionsBaseSchema.extend({
  cloud: CloudOptionsSchema.optional(),
})

const RawOptionsSchema = OptionsBaseSchema.extend({
  duration: Duration.optional(),
  stages: array(StageSchema).optional(),
  iterations: number().int().gte(1).optional(),
  scenarios: record(ScenarioSchema).optional(),
  vus: VUs.optional(),
})

type RawOptions = Infer<typeof RawOptionsSchema>

function toShorthandError(
  first: InvalidShorthandCombination['first'],
  second: InvalidShorthandCombination['second']
): IssueData {
  const params: InvalidShorthandCombination = {
    type: 'invalid-shorthand-combination',
    first,
    second,
  }

  return {
    code: ZodIssueCode.custom,
    path: [first, '$property'],
    params,
    fatal: true,
  }
}

// In order to create a better error message we use a refinement instead of a union.
function refineRawOptionsSchema(options: RawOptions, context: RefinementCtx) {
  const { iterations, duration, vus, stages, scenarios } = options
  if (scenarios !== undefined) {
    if (vus !== undefined) {
      const params: InvalidUseOfVUs = {
        type: 'invalid-use-of-vus',
        conflict: 'scenario',
      }

      context.addIssue({
        code: ZodIssueCode.custom,
        message:
          'The "vus" option cannot be used together with the "scenarios" option.',
        params,
        fatal: true,
      })
    }

    if (iterations !== undefined) {
      context.addIssue(toShorthandError('iterations', 'scenarios'))
    }

    if (duration !== undefined) {
      context.addIssue(toShorthandError('duration', 'scenarios'))
    }

    if (stages !== undefined) {
      context.addIssue(toShorthandError('stages', 'scenarios'))
    }
  }

  if (iterations !== undefined) {
    if (stages !== undefined) {
      context.addIssue(toShorthandError('stages', 'iterations'))
    }

    if (duration !== undefined) {
      context.addIssue(toShorthandError('duration', 'iterations'))
    }
  }

  if (duration !== undefined) {
    if (iterations !== undefined) {
      context.addIssue(toShorthandError('iterations', 'duration'))
    }

    if (stages !== undefined) {
      context.addIssue(toShorthandError('stages', 'duration'))
    }
  }

  if (stages !== undefined) {
    if (iterations !== undefined) {
      context.addIssue(toShorthandError('iterations', 'stages'))
    }

    if (duration !== undefined) {
      context.addIssue(toShorthandError('duration', 'stages'))
    }
  }
}

// This transform will create a type safe union of the different ways
// to configure a test, e.g. using shorthand properties. It allows
// us to check which kind of configuration we are dealing with by checking
// which properties are defined, e.g. `"duration" in options`.
function transformRawOptionsSchema({
  iterations,
  duration,
  vus,
  stages,
  scenarios,
  ...rest
}: RawOptions) {
  if (duration !== undefined) {
    return {
      duration,
      vus: vus ?? 1,
      iterations: undefined,
      stages: undefined,
      scenarios: undefined,
      ...rest,
    }
  }

  if (scenarios !== undefined) {
    return {
      scenarios,
      vus: undefined,
      iterations: undefined,
      duration: undefined,
      stages: undefined,
      ...rest,
    }
  }

  if (stages !== undefined) {
    return {
      stages,
      vus,
      iterations: undefined,
      duration: undefined,
      scenarios: undefined,
      ...rest,
    }
  }

  if (iterations !== undefined) {
    return {
      iterations,
      vus: vus,
      duration: undefined,
      stages: undefined,
      scenarios: undefined,
      ...rest,
    }
  }

  return {
    vus,
    iterations: undefined,
    duration: undefined,
    stages: undefined,
    scenarios: undefined,
    ...rest,
  }
}

export const OptionsSchema = RawOptionsSchema.passthrough()
  .superRefine(refineRawOptionsSchema)
  .transform(transformRawOptionsSchema)

export type Options = Infer<typeof OptionsSchema>

export * from './scenario'
