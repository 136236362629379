import React, { ReactNode } from 'react'

import { ExternalLink } from 'components/ExternalLink'
import { DocArticle } from './docs'

interface DocsLinkProps {
  article: DocArticle
  children: ReactNode
  showIcon?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

export const DocLink = ({ article, children, ...props }: DocsLinkProps) => {
  return (
    <ExternalLink url={article} {...props}>
      {children}
    </ExternalLink>
  )
}
