import React, { useState } from 'react'

import type { Trend } from 'types'

import { DEFAULT_TREND_BAR_COUNT } from './constants'
import { Bars } from './components/Bars'
import { YAxis } from './components/YAxis'
import { roundToNearest } from './TrendBarChart.utils'
import { useMinMaxValue, usePaddedTrendValues } from './TrendBarChart.hooks'
import { Body, Container, PlaceholderText } from './TrendBarChart.styled'
import { useElementSize } from 'usehooks-ts'

interface TrendBarChartProps {
  trend?: Trend
  barCount?: number
  calculating?: boolean
  className?: string
  placeholder?: string
  variant?: 'minimal' | 'default'
}

export function TrendBarChart({
  trend,
  barCount = DEFAULT_TREND_BAR_COUNT,
  calculating = false,
  className,
  placeholder,
  variant = 'default',
}: TrendBarChartProps) {
  const values = usePaddedTrendValues(trend, barCount)
  const [_min, max] = useMinMaxValue(values)
  const [yAxisLabelRef, setYAxisLabelRef] = useState<HTMLSpanElement | null>(
    null
  )
  const [ref, { height, width }] = useElementSize()

  const isEmpty = !calculating && !values.some((value) => value !== undefined)
  const isPlaceholderVisible = isEmpty && !!placeholder
  const labelWidth = yAxisLabelRef?.offsetWidth ?? 0
  const maxBuffered = roundToNearest(max * 1.15)

  return (
    <Container className={className} ref={ref}>
      <Body
        isEmpty={isEmpty}
        gap={0.5}
        align="flex-end"
        justify="flex-end"
        height="100%"
        width="100%"
      >
        {!isEmpty && (
          <YAxis
            labelRef={setYAxisLabelRef}
            height={height}
            maxValue={maxBuffered}
            width={width}
            trend={trend}
            variant={variant}
          />
        )}

        <Bars
          barCount={barCount}
          offset={labelWidth}
          maxValue={maxBuffered}
          width={width ?? 0}
          trend={trend}
          values={values}
          variant={variant}
          isEmpty={isEmpty}
        />
      </Body>

      {isPlaceholderVisible && (
        <PlaceholderText>
          <span>{placeholder}</span>
        </PlaceholderText>
      )}
    </Container>
  )
}
