import {
  GraphFieldConfig,
  LegendDisplayMode,
  SortOrder,
  TooltipDisplayMode,
} from '@grafana/schema'
import { DEFAULT_FIELD_CONFIG } from 'utils/fieldConfigs'
import { MetricConfig } from 'datasource/models'
import { toGrafanaLikeRefID, toQueryTarget } from 'utils/dataFrame'
import { DraftPanelModel, GeneratorContext } from './types'
import { FieldMatcherID } from '@grafana/data'
import { TimeSeriesOptions } from 'types/panels'

declare module '@grafana/data' {
  export interface PanelModel<
    TOptions = any,
    TCustomFieldConfig = any,
    _TFieldConfig = any
  > {
    transparent?: boolean
  }
}

export function createStatPanel(
  title: string,
  { datasourceRef, options }: GeneratorContext,
  config: MetricConfig
): DraftPanelModel {
  const aggregateConfig: MetricConfig = {
    ...config,
    query: {
      ...config.query,
      type: 'aggregate',
    },
  }

  return {
    type: 'stat',
    datasource: datasourceRef,
    title,
    fieldConfig: {
      defaults: {},
      overrides: [],
    },
    options: {
      reduceOptions: {
        values: false,
        calcs: ['lastNotNull'],
        fields: '',
      },
      orientation: 'auto',
      textMode: 'auto',
      wideLayout: true,
      colorMode: 'value',
      graphMode: 'none',
      justifyMode: 'auto',
      showPercentChange: false,
    },
    transformations: [],
    transparent: false,
    targets: [
      toQueryTarget(0, options.testRun, aggregateConfig, datasourceRef),
    ],
  }
}

export function createTimeSeriesPanel(
  { datasourceRef, options }: GeneratorContext,
  configs: MetricConfig[]
): DraftPanelModel<TimeSeriesOptions, GraphFieldConfig> {
  return {
    type: 'timeseries',
    datasource: datasourceRef,
    title: '',
    fieldConfig: {
      defaults: DEFAULT_FIELD_CONFIG,
      overrides: configs.map((config, index) => {
        return {
          matcher: {
            id: FieldMatcherID.byFrameRefID,
            options: toGrafanaLikeRefID(index),
          },
          properties: [
            {
              id: 'displayName',
              value: config.label,
            },
          ],
        }
      }),
    },
    options: {
      legend: {
        showLegend: true,
        displayMode: LegendDisplayMode.List,
        placement: 'bottom',
        calcs: [],
      },
      tooltip: {
        mode: TooltipDisplayMode.Multi,
        sort: SortOrder.None,
      },
    },
    transformations: [],
    transparent: false,
    targets: configs.map((config, index) => {
      return toQueryTarget(index, options.testRun, config, datasourceRef)
    }) as any,
  }
}

export function createTextPanel(content: string): DraftPanelModel {
  return {
    type: 'text',
    fieldConfig: {
      defaults: {},
      overrides: [],
    },
    transparent: true,
    transformations: [],
    options: {
      mode: 'markdown',
      content,
    },
  }
}
