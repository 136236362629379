import React, { ChangeEvent, useState } from 'react'

import { TestRun } from 'types'
import { LogsFilter, ParsedLog } from 'types/logs'
import { hasActiveFilter } from '../LogsTab.utils'
import { EmptyQueryMessage } from './EmptyQueryMessage'
import { LogControls } from './LogControls'
import { LogFilter } from './LogFilter/LogFilter'
import { LogList } from './LogList'
import { Footer, Header, SwitchWrapper } from './LogsView.styles'
import { InlineSwitch } from '@grafana/ui'

interface LogsViewProps {
  isFetching: boolean
  filters: LogsFilter
  logs: ParsedLog[]
  page: number
  testRun: TestRun
  onFilterChange: (filters: LogsFilter) => void
  onPageChange: (page: number) => void
}

export const LogsView = ({
  isFetching,
  filters,
  logs,
  page,
  testRun,
  onFilterChange,
  onPageChange,
}: LogsViewProps) => {
  const isEmptyQuery = !logs.length && hasActiveFilter(filters)
  const [wrapLogMessage, setWrapLogMessage] = useState(false)

  return (
    <>
      <Header>
        <LogFilter
          value={filters}
          testRun={testRun}
          onChange={onFilterChange}
        />
        <SwitchWrapper>
          <InlineSwitch
            id="wrap-log-lines-switch"
            label="Wrap lines"
            onChange={(v: ChangeEvent<HTMLInputElement>) => {
              setWrapLogMessage(v.target.checked)
            }}
            value={wrapLogMessage}
            showLabel
            transparent
          />
        </SwitchWrapper>
      </Header>

      {isEmptyQuery ? (
        <EmptyQueryMessage filters={filters} onFilterChange={onFilterChange} />
      ) : (
        <LogList
          filters={filters}
          logs={logs}
          testRun={testRun}
          wrapLogMessage={wrapLogMessage}
        />
      )}

      <Footer $isEmptyQuery={isEmptyQuery}>
        <LogControls
          isFetching={isFetching}
          isEmptyQuery={isEmptyQuery}
          logs={logs}
          page={page}
          testRun={testRun}
          onPageChange={onPageChange}
        />
      </Footer>
    </>
  )
}
