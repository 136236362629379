import React, {
  useLayoutEffect,
  useRef,
  useState,
  ReactNode,
  CSSProperties,
} from 'react'
import styled from 'styled-components'
import { Spinner } from '@grafana/ui'
import clsx from 'clsx'

import { useContentRect } from 'hooks/useContentRect'

const SpinnerOverlayStyles = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;

  &.enter {
    opacity: 1;
  }

  opacity: 0;
  transition: opacity 50ms 50ms ease-in-out;

  &.loaded,
  &.loaded.enter {
    opacity: 0;
  }
`

interface SpinnerOverlayProps {
  loading: boolean
  spinner: ReactNode
}

const SpinnerOverlay = ({ loading, spinner }: SpinnerOverlayProps) => {
  const [mounted, setMounted] = useState(false)

  useLayoutEffect(() => {
    setMounted(true)
  }, [])

  return (
    <SpinnerOverlayStyles
      className={clsx(mounted && 'enter', !loading && 'loaded')}
      data-testid="spinner"
    >
      {spinner}
    </SpinnerOverlayStyles>
  )
}

const toCssProperties = (
  state: LoadingState,
  height: number | undefined
): CSSProperties => {
  return {
    height: state !== 'done' && height !== undefined ? height : undefined,
    transition:
      state === 'transitioning' ? 'height 200ms ease-in-out' : undefined,
    overflow: state !== 'done' ? 'hidden' : undefined,
  }
}

const LoadingContainerStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  > .wrapper {
    opacity: 0;
  }

  &.transitioning,
  &.done {
    > .wrapper {
      transition: opacity 200ms 200ms ease-in-out;
      opacity: 1;
    }
  }
`

type LoadingState = 'loading' | 'transitioning' | 'done'

export interface LoadingContainerProps {
  className?: string
  loading: boolean
  spinner?: ReactNode
  estimatedHeight?: number
  style?: CSSProperties
  children: ReactNode
}

export const LoadingContainer = ({
  className,
  loading = false,
  spinner = <Spinner inline={false} />,
  estimatedHeight,
  style,
  children,
}: LoadingContainerProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [state, setState] = useState<LoadingState>(loading ? 'loading' : 'done')
  const contentRect = useContentRect(wrapperRef)

  useLayoutEffect(() => {
    if (state === 'loading' && !loading) {
      setState('transitioning')
    }

    if (state !== 'loading' && loading) {
      setState('loading')
    }
  }, [loading, state])

  const handleTransitionEnd = () => {
    if (state === 'transitioning') {
      setState('done')
    }
  }

  return (
    <LoadingContainerStyles
      className={clsx(className, state)}
      style={{
        ...style,
        ...toCssProperties(state, contentRect?.height || estimatedHeight),
      }}
    >
      {spinner && state !== 'done' && (
        <SpinnerOverlay loading={state === 'loading'} spinner={spinner} />
      )}
      <div
        ref={wrapperRef}
        className="wrapper"
        onTransitionEnd={handleTransitionEnd}
      >
        {children}
      </div>
    </LoadingContainerStyles>
  )
}
