import { useQuery, useQueryClient, QueryKey } from '@tanstack/react-query'
import { useDatasource } from 'datasourceContext'
import { uniqBy } from 'lodash-es'
import { User } from 'types'

import { toUsersQueryKey } from './queryKeys'
import { QueryOptions } from './queryClient'

export const useUsers = (
  userIds: number[],
  {
    useCachedData = false,
    alertOnError = true,
    ...options
  }: QueryOptions<User[]> & {
    useCachedData?: boolean
    alertOnError?: boolean
  } = {}
) => {
  const queryClient = useQueryClient()
  const { ds } = useDatasource()

  return useQuery<User[]>({
    queryKey: toUsersQueryKey(userIds),
    queryFn: async () => {
      if (!useCachedData) {
        return ds.fetchUsers(userIds)
      }

      // In order to not over-fetch users we check whether a particular user already exists in the cache.
      // If data for the user already exists we return the cached user.
      const queriesData = queryClient.getQueriesData([toUsersQueryKey([])[0]])
      const existingUsers = getExistingUsersFromCache(queriesData, userIds)
      const areAllUsersInCache = existingUsers.length === userIds.length

      if (areAllUsersInCache) {
        return existingUsers
      }

      if (existingUsers.length > 0 && !areAllUsersInCache) {
        const userIdsToFetch = userIds.filter(
          (userId) => !existingUsers.find((user) => user.id === userId)
        )
        const newUsers = await ds.fetchUsers(userIdsToFetch)
        return [...existingUsers, ...newUsers]
      }

      return ds.fetchUsers(userIds)
    },
    meta: {
      alertOnError,
    },
    ...options,
  })
}

const getExistingUsersFromCache = (
  queriesData: Array<[QueryKey, unknown]>,
  userIds: number[]
) => {
  const cachedUsers = queriesData.reduce<User[]>((users, queryData) => {
    const [_, cachedUsers] = queryData

    if (!cachedUsers) {
      return users
    }

    return uniqBy([...users, ...(cachedUsers as User[])], (value) => value.id)
  }, [])

  return userIds.reduce<User[]>((users, userId) => {
    const existingUser = cachedUsers.find((user) => user.id === userId)
    return existingUser ? [...users, existingUser] : users
  }, [])
}
