import { TestRunId } from 'types'
import { useFeatureFlag } from 'store/FeatureFlags'
import { useTestRunSearchTraces } from 'data/useTestRunSearchTraces'

export function useHasBrowserTraces(testRunId?: TestRunId) {
  const browserTimeline = useFeatureFlag('browserTimeline')

  // If the browserTimeline feature flag is not enabled, we don't need to check for traces
  // passing undefined as the targetId will cause the underlying react-query hook to not fetch any data
  const targetId = browserTimeline ? testRunId : undefined

  const { data } = useTestRunSearchTraces(targetId)
  return Boolean(data?.traces.length)
}
