import {
  BuiltinMetrics,
  ColorGroups,
  ColorShades,
  MetricConfig,
  PlotType,
  TestRunConfigType,
} from 'datasource/models'
import { TimeSeriesUnit } from 'types/panels'
import { MetricCategory, MetricType, TagQuery } from 'types'

export const NON_ZERO_STATUS_QUERY: TagQuery = {
  status: { name: 'status', operator: 'not-equal', values: ['0'] },
}

export const VUS_METRIC: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.GAUGE,
  metricCategory: MetricCategory.STANDARD,
  name: BuiltinMetrics.VUS,
  description: 'The number of virtual users running.',
  label: 'VUs',
  plot: { type: PlotType.Area },
  unit: TimeSeriesUnit.VUs,
  preferredColor: {
    exact: '#86868659',
    group: ColorGroups.Grey,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.VUS,
    method: 'sum(last by (instance_id))',
    tags: {},
  },
}

export const VUS_MAX_METRIC: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.GAUGE,
  metricCategory: MetricCategory.STANDARD,
  name: BuiltinMetrics.VUS_MAX,
  description: 'The max number of possible VUs.',
  label: 'Max VUs',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.VUs,
  preferredColor: {
    shade: ColorShades.Light,
    group: ColorGroups.Grey,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.VUS_MAX,
    method: 'sum(last by (instance_id))',
    tags: {},
  },
}

export const ITERATIONS_METRIC: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.COUNTER,
  metricCategory: MetricCategory.STANDARD,
  name: BuiltinMetrics.ITERATIONS,
  description: 'The number of iterations completed.',
  label: 'Iterations',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Iterations,
  query: {
    type: 'series',
    metric: BuiltinMetrics.ITERATIONS,
    method: 'rate',
    tags: {},
  },
}

export const ITERATION_DURATION: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.STANDARD,
  name: BuiltinMetrics.ITERATION_DURATION,
  description: 'The time it took for a full iteration to complete.',
  label: 'Iteration Duration',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Milliseconds,
  query: {
    type: 'series',
    metric: BuiltinMetrics.ITERATION_DURATION,
    method: 'histogram_quantile(0.95)',
    tags: {},
  },
}

export const DROPPED_ITERATIONS: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.COUNTER,
  metricCategory: MetricCategory.STANDARD,
  name: BuiltinMetrics.DROPPED_ITERATIONS,
  description: 'The number of iterations that were dropped.',
  label: 'Dropped Iterations',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Iterations,
  query: {
    type: 'series',
    metric: BuiltinMetrics.DROPPED_ITERATIONS,
    method: 'rate',
    tags: {},
  },
}

export const GROUP_DURATION: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.STANDARD,
  name: BuiltinMetrics.GROUP_DURATION,
  label: 'Group Duration',
  description: 'The time it took to execute a group.',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Milliseconds,
  query: {
    type: 'series',
    metric: BuiltinMetrics.GROUP_DURATION,
    method: 'histogram_avg',
    tags: {},
  },
}

export const HTTP_REQUEST_RATE: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.COUNTER,
  metricCategory: MetricCategory.HTTP,
  name: BuiltinMetrics.HTTP_REQS,
  description: 'The number of HTTP requests made.',
  label: 'Request Rate',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.RequestsPerSecond,
  preferredColor: {
    exact: '#7d64ff',
    shade: ColorShades.SemiDark,
    group: ColorGroups.Purple,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.HTTP_REQS,
    method: 'rate',
    tags: NON_ZERO_STATUS_QUERY,
  },
}

export const HTTP_RESPONSE_TIME: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.HTTP,
  name: BuiltinMetrics.HTTP_REQ_DURATION,
  label: 'Response Time',
  description: 'The time it took to process a complete request.',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Milliseconds,
  preferredColor: {
    exact: '#04cdff',
    shade: ColorShades.Light,
    group: ColorGroups.Blue,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.HTTP_REQ_DURATION,
    method: 'histogram_quantile(0.95)',
    tags: NON_ZERO_STATUS_QUERY,
  },
}

export const HTTP_FAILURE_RATE: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.RATE,
  metricCategory: MetricCategory.HTTP,
  name: BuiltinMetrics.HTTP_REQ_FAILED,
  label: 'Failure Rate',
  description: 'The rate of failed requests returned from the remote host.',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.RequestsPerSecond,
  preferredColor: {
    exact: '#d10e5c',
    group: ColorGroups.Red,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.HTTP_REQ_FAILED,
    method: 'rate_nz',
    tags: NON_ZERO_STATUS_QUERY,
  },
}

export const HTTP_REQUEST_WAITING: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.HTTP,
  name: BuiltinMetrics.HTTP_REQ_WAITING,
  label: 'Waiting',
  description: 'The time spent waiting for a response from the remote host.',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Milliseconds,
  preferredColor: {
    exact: '#7f80a0',
    group: ColorGroups.Grey,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.HTTP_REQ_WAITING,
    method: 'histogram_quantile(0.95)',
    tags: NON_ZERO_STATUS_QUERY,
  },
}

export const HTTP_REQUEST_CONNECTING: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.HTTP,
  label: 'Connecting',
  name: BuiltinMetrics.HTTP_REQ_CONNECTING,
  description:
    'The time spent establishing a TCP connection to the remote host.',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Milliseconds,
  preferredColor: {
    exact: '#f4871e',
    group: ColorGroups.Orange,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.HTTP_REQ_CONNECTING,
    method: 'histogram_quantile(0.95)',
    tags: NON_ZERO_STATUS_QUERY,
  },
}

export const HTTP_REQUEST_RECEIVING: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.HTTP,
  name: BuiltinMetrics.HTTP_REQ_RECEIVING,
  label: 'Receiving',
  description: 'The time spent receiving data from the remote host.',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Milliseconds,
  query: {
    type: 'series',
    metric: BuiltinMetrics.HTTP_REQ_RECEIVING,
    method: 'histogram_quantile(0.95)',
    tags: NON_ZERO_STATUS_QUERY,
  },
}

export const HTTP_REQUEST_SENDING: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.HTTP,
  name: BuiltinMetrics.HTTP_REQ_SENDING,
  description: 'The time spent sending data to the remote host.',
  label: 'Sending',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Milliseconds,
  preferredColor: {
    exact: '#ffb900',
    shade: ColorShades.SemiDark,
    group: ColorGroups.Yellow,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.HTTP_REQ_SENDING,
    method: 'histogram_quantile(0.95)',
    tags: NON_ZERO_STATUS_QUERY,
  },
}

export const HTTP_REQUEST_TLS_HANDSHAKING: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.HTTP,
  label: 'TLS Handshaking',
  name: BuiltinMetrics.HTTP_REQ_TLS_HANDSHAKING,
  description: 'The time spent establishing a secure TLS connection.',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Milliseconds,
  preferredColor: {
    exact: '#d870a9',
    shade: ColorShades.SuperLight,
    group: ColorGroups.Red,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.HTTP_REQ_TLS_HANDSHAKING,
    method: 'histogram_quantile(0.95)',
    tags: NON_ZERO_STATUS_QUERY,
  },
}

export const HTTP_REQUEST_BLOCKED: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.HTTP,
  label: 'Blocked',
  name: BuiltinMetrics.HTTP_REQ_BLOCKED,
  description: 'The time spent waiting on a TCP connection slot.',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Milliseconds,
  preferredColor: {
    exact: '#66bc43',
    shade: ColorShades.Light,
    group: ColorGroups.Green,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.HTTP_REQ_BLOCKED,
    method: 'histogram_quantile(0.95)',
    tags: NON_ZERO_STATUS_QUERY,
  },
}

export const WS_SESSION_RATE: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.COUNTER,
  metricCategory: MetricCategory.WS,
  name: BuiltinMetrics.WS_SESSIONS,
  description: 'The total number of started sessions.',
  label: 'Sessions',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.SessionsPerSecond,
  preferredColor: {
    exact: '#7d64ff',
    shade: ColorShades.Dark,
    group: ColorGroups.Purple,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.WS_SESSIONS,
    method: 'rate',
    tags: {},
  },
}

export const WS_SESSION_DURATION: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.WS,
  label: 'Session Duration',
  name: BuiltinMetrics.WS_SESSION_DURATION,
  description: 'How long sessions stayed open.',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Milliseconds,
  preferredColor: {
    exact: '#ff9800',
    group: ColorGroups.Orange,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.WS_SESSION_DURATION,
    method: 'histogram_quantile(0.95)',
    tags: {},
  },
}

export const WS_MESSAGES_SENT: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.COUNTER,
  metricCategory: MetricCategory.WS,
  label: 'Messages Sent',
  description: 'The number of messages sent.',
  name: BuiltinMetrics.WS_MSGS_SENT,
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.MessagesPerSecond,
  preferredColor: {
    exact: '#3f51b5',
    shade: ColorShades.Dark,
    group: ColorGroups.Blue,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.WS_MSGS_SENT,
    method: 'ratio',
    tags: {},
  },
}

export const WS_MESSAGES_RECEIVED: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.COUNTER,
  metricCategory: MetricCategory.WS,
  label: 'Messages Received',
  description: 'The number of messages received.',
  name: BuiltinMetrics.WS_MSGS_RECEIVED,
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.MessagesPerSecond,
  preferredColor: {
    exact: '#03a9f4',
    shade: ColorShades.Light,
    group: ColorGroups.Blue,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.WS_MSGS_RECEIVED,
    method: 'ratio',
    tags: {},
  },
}

export const WS_CONNECTING: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.WS,
  label: 'Connecting',
  description: 'The time it took to establish a connection.',
  name: BuiltinMetrics.WS_CONNECTING,
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Milliseconds,
  preferredColor: {
    exact: '#f4871e',
    shade: ColorShades.SemiDark,
    group: ColorGroups.Orange,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.WS_CONNECTING,
    method: 'histogram_quantile(0.95)',
    tags: {},
  },
}

export const WS_PING: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.WS,
  label: 'Ping Duration',
  plot: { type: PlotType.Area },
  description: 'The response time when sending a ping to the remote host.',
  name: BuiltinMetrics.WS_PING,
  unit: TimeSeriesUnit.Milliseconds,
  preferredColor: {
    exact: '#50cafc',
    shade: ColorShades.SuperLight,
    group: ColorGroups.Blue,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.WS_PING,
    method: 'histogram_quantile(0.95)',
    tags: {},
  },
}

// The metric does not exist, we emulate it using grpc_req_duration
// https://github.com/grafana/grafana-k6-app/pull/778
export const GRPC_REQUEST_RATE: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.GRPC,
  label: 'Request Rate',
  description: 'The number of gRPC calls made.',
  name: BuiltinMetrics.GRPC_REQS,
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.RequestsPerSecond,
  preferredColor: {
    exact: '#7d64ff',
    shade: ColorShades.SemiDark,
    group: ColorGroups.Purple,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.GRPC_REQ_DURATION,
    method: 'histogram_count_rate',
    tags: {},
  },
}

export const GRPC_RESPONSE_TIME: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.GRPC,
  label: 'Response Time',
  name: BuiltinMetrics.GRPC_REQ_DURATION,
  description: 'The time it took to process a complete call.',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Milliseconds,
  preferredColor: {
    group: ColorGroups.Purple,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.GRPC_REQ_DURATION,
    method: 'histogram_quantile(0.95)',
    tags: {},
  },
}

export const LOAD_GENERATOR_CPU_PERCENT: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.GAUGE,
  metricCategory: MetricCategory.SYSTEM,
  label: 'CPU Usage',
  description: 'The CPU utilization of the load generator.',
  name: BuiltinMetrics.LOAD_GENERATOR_CPU_PERCENT,
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Percent,
  preferredColor: {
    exact: '#f472d0',
    shade: ColorShades.SuperLight,
    group: ColorGroups.Red,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.LOAD_GENERATOR_CPU_PERCENT,
    method: 'max(last by (instance_id))',
    tags: {},
  },
}

export const LOAD_GENERATOR_MEMORY_USED_PERCENT: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.GAUGE,
  metricCategory: MetricCategory.SYSTEM,
  label: 'Memory Usage',
  description: 'The memory utilization of the load generator.',
  name: BuiltinMetrics.LOAD_GENERATOR_MEMORY_USED_PERCENT,
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Percent,
  preferredColor: {
    exact: '#37bc9b',
    shade: ColorShades.Light,
    group: ColorGroups.Green,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.LOAD_GENERATOR_MEMORY_USED_PERCENT,
    method: 'max(last by (instance_id))',
    tags: {},
  },
}

export const LOAD_GENERATOR_FILE_HANDLES: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.GAUGE,
  metricCategory: MetricCategory.SYSTEM,
  label: 'File Handles',
  description: 'The number of file handles open on the load generator.',
  name: BuiltinMetrics.LOAD_GENERATOR_FILE_HANDLES,
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Files,
  query: {
    type: 'series',
    metric: BuiltinMetrics.LOAD_GENERATOR_FILE_HANDLES,
    method: 'max(last by (instance_id))',
    tags: {},
  },
}

export const DATA_SENT: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.COUNTER,
  metricCategory: MetricCategory.STANDARD,
  label: 'Data Sent',
  description: 'The amount of data (in bytes) that was sent.',
  name: BuiltinMetrics.DATA_SENT,
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Bytes,
  preferredColor: {
    shade: ColorShades.Light,
    group: ColorGroups.Purple,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.DATA_SENT,
    method: 'rate',
    tags: {},
  },
}

export const DATA_RECEIVED: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.COUNTER,
  metricCategory: MetricCategory.STANDARD,
  label: 'Data Received',
  description: 'The amount of data (in bytes) that was received.',
  name: BuiltinMetrics.DATA_RECEIVED,
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Bytes,
  preferredColor: {
    exact: '#04cdff',
    shade: ColorShades.Light,
    group: ColorGroups.Blue,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.DATA_RECEIVED,
    method: 'rate',
    tags: {},
  },
}

export const CHECKS_METRIC: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.RATE,
  metricCategory: MetricCategory.STANDARD,
  label: 'Checks',
  name: BuiltinMetrics.CHECKS,
  description: 'The number of checks in a test run.',
  plot: { type: PlotType.Bars },
  unit: TimeSeriesUnit.Checks,
  query: {
    type: 'series',
    metric: BuiltinMetrics.CHECKS,
    method: 'increase_nz',
    tags: {},
  },
}

export const BROWSER_WEB_VITAL_LCP: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.BROWSER_WEB_VITAL,
  label: 'LCP',
  name: BuiltinMetrics.BROWSER_LARGEST_CONTENTFUL_PAINT,
  description:
    'The time it takes for the largest content element in the viewport to become visible.',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Milliseconds,
  preferredColor: {
    group: ColorGroups.Yellow,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.BROWSER_LARGEST_CONTENTFUL_PAINT,
    method: 'histogram_quantile(0.75)',
    tags: {},
  },
}

export const BROWSER_WEB_VITAL_FID: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.BROWSER_WEB_VITAL,
  label: 'FID',
  name: BuiltinMetrics.BROWSER_FIRST_INPUT_DELAY,
  description:
    'The time it takes for the browser to respond to the first user interaction.',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Milliseconds,
  preferredColor: {
    shade: ColorShades.Light,
    group: ColorGroups.Blue,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.BROWSER_FIRST_INPUT_DELAY,
    method: 'histogram_quantile(0.75)',
    tags: {},
  },
}

export const BROWSER_WEB_VITAL_CLS: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.BROWSER_WEB_VITAL,
  label: 'CLS',
  name: BuiltinMetrics.BROWSER_CUMULATIVE_LAYOUT_SHIFT,
  description:
    'The sum of all unexpected layout shifts whilst the page is loading.',
  plot: { type: PlotType.Bars },
  unit: TimeSeriesUnit.None,
  preferredColor: {
    shade: ColorShades.SemiDark,
    group: ColorGroups.Orange,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.BROWSER_CUMULATIVE_LAYOUT_SHIFT,
    method: 'histogram_quantile(0.75)',
    tags: {},
  },
}

export const BROWSER_WEB_VITAL_TTFB: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.BROWSER_WEB_VITAL,
  label: 'TTFB',
  name: BuiltinMetrics.BROWSER_TIME_TO_FIRST_BYTE,
  description:
    'The time it takes for the browser to receive the first byte of the first response.',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Milliseconds,
  preferredColor: {
    group: ColorGroups.Purple,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.BROWSER_TIME_TO_FIRST_BYTE,
    method: 'histogram_quantile(0.75)',
    tags: {},
  },
}

export const BROWSER_WEB_VITAL_INP: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.BROWSER_WEB_VITAL,
  label: 'INP',
  name: BuiltinMetrics.BROWSER_INTERACTION_TO_NEXT_PAINT,
  description:
    "Interaction to Next Paint assesses a page's overall responsiveness to user interactions.",
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Milliseconds,
  preferredColor: {
    group: ColorGroups.Blue,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.BROWSER_INTERACTION_TO_NEXT_PAINT,
    method: 'histogram_quantile(0.75)',
    tags: {},
  },
}

export const BROWSER_WEB_VITAL_FCP: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.BROWSER_WEB_VITAL,
  label: 'FCP',
  name: BuiltinMetrics.BROWSER_FIRST_CONTENTFUL_PAINT,
  description:
    'The time it takes for the first content element in the viewport to become visible.',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Milliseconds,
  preferredColor: {
    group: ColorGroups.Red,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.BROWSER_FIRST_CONTENTFUL_PAINT,
    method: 'histogram_quantile(0.75)',
    tags: {},
  },
}

export const BROWSER_HTTP_REQ_DURATION: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.TREND,
  metricCategory: MetricCategory.BROWSER,
  label: 'Response Time',
  name: BuiltinMetrics.BROWSER_HTTP_REQ_DURATION,
  description:
    'The time spent receiving data from the remote host in the browser.',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Milliseconds,
  query: {
    type: 'series',
    metric: BuiltinMetrics.BROWSER_HTTP_REQ_DURATION,
    method: 'histogram_quantile(0.95)',
    tags: NON_ZERO_STATUS_QUERY,
  },
}

export const BROWSER_HTTP_FAILURE_RATE: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.RATE,
  metricCategory: MetricCategory.BROWSER,
  name: BuiltinMetrics.BROWSER_HTTP_REQ_FAILED,
  label: 'Failure rate',
  description:
    'The rate of failed requests returned from the remote host in the browser.',
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.RequestsPerSecond,
  preferredColor: {
    exact: '#d10e5c',
    group: ColorGroups.Red,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.HTTP_REQ_FAILED,
    method: 'rate_nz',
    tags: NON_ZERO_STATUS_QUERY,
  },
}

export const BROWSER_DATA_SENT: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.COUNTER,
  metricCategory: MetricCategory.BROWSER,
  label: 'Data Sent',
  description: 'The amount of data (in bytes) that was sent from the browser.',
  name: BuiltinMetrics.BROWSER_DATA_SENT,
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Bytes,
  preferredColor: {
    group: ColorGroups.Purple,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.BROWSER_DATA_SENT,
    method: 'rate',
    tags: {},
  },
}

export const BROWSER_DATA_RECEIVED: MetricConfig = {
  type: TestRunConfigType.Metric,
  metricType: MetricType.COUNTER,
  metricCategory: MetricCategory.BROWSER,
  label: 'Data received',
  description:
    'The amount of data (in bytes) that was received in the browser.',
  name: BuiltinMetrics.BROWSER_DATA_RECEIVED,
  plot: { type: PlotType.Line, style: 'solid' },
  unit: TimeSeriesUnit.Bytes,
  preferredColor: {
    exact: '#04cdff',
    shade: ColorShades.Light,
    group: ColorGroups.Blue,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.BROWSER_DATA_RECEIVED,
    method: 'rate',
    tags: {},
  },
}

export const CHECK_PASSES: MetricConfig = {
  ...CHECKS_METRIC,
  label: 'Passes',
  description: 'The number of checks that passed.',
  preferredColor: {
    exact: '#28a745',
    group: ColorGroups.Green,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.CHECKS,
    method: 'increase_nz',
    tags: {},
  },
}

export const CHECK_FAILURES: MetricConfig = {
  ...CHECKS_METRIC,
  label: 'Failures',
  description: 'The number of checks that failed.',
  preferredColor: {
    exact: '#dc3545',
    group: ColorGroups.Red,
  },
  query: {
    type: 'series',
    metric: BuiltinMetrics.CHECKS,
    method: 'increase_z',
    tags: {},
  },
}

export const HTTP_RESPONSE_TIME_90: MetricConfig = {
  ...HTTP_RESPONSE_TIME,
  description:
    'The 90th percentile of the time it took to process complete requests.',
  query: {
    ...HTTP_RESPONSE_TIME.query,
    method: 'histogram_quantile(0.90)',
  },
}

export const HTTP_RESPONSE_TIME_95: MetricConfig = {
  ...HTTP_RESPONSE_TIME,
  description:
    'The 95th percentile of the time it took to process complete requests.',
  query: {
    ...HTTP_RESPONSE_TIME.query,
    method: 'histogram_quantile(0.95)',
  },
}

export const HTTP_RESPONSE_TIME_99: MetricConfig = {
  ...HTTP_RESPONSE_TIME,
  label: 'P99 Response time',
  description:
    'The 99th percentile of the time it took to process complete requests.',
  query: {
    ...HTTP_RESPONSE_TIME.query,
    method: 'histogram_quantile(0.99)',
  },
}

export const HTTP_RESPONSE_TIME_AVG: MetricConfig = {
  ...HTTP_RESPONSE_TIME,
  label: 'Avg. Response time',
  description: 'The average time it took to process complete requests.',
  query: {
    ...HTTP_RESPONSE_TIME.query,
    method: 'histogram_avg',
  },
}

export const HTTP_RESPONSE_TIME_MEDIAN: MetricConfig = {
  ...HTTP_RESPONSE_TIME,
  label: 'Median Response time',
  description: 'The median time it took to process complete requests.',
  query: {
    ...HTTP_RESPONSE_TIME.query,
    method: 'histogram_quantile(0.5)',
  },
}

export const HTTP_RESPONSE_TIME_MAX: MetricConfig = {
  ...HTTP_RESPONSE_TIME,
  label: 'Max Response time',
  description: 'The max time it took to process complete requests.',
  query: {
    ...HTTP_RESPONSE_TIME.query,
    method: 'histogram_max',
  },
}

export const WS_DEFAULT_METRICS = [
  VUS_METRIC,
  WS_SESSION_RATE,
  WS_SESSION_DURATION,
]

export const HTTP_DEFAULT_METRICS = [
  VUS_METRIC,
  HTTP_REQUEST_RATE,
  HTTP_RESPONSE_TIME_95,
  HTTP_FAILURE_RATE,
]

export const HTTP_RESPONSE_TIME_PERCENTILES = [
  { ...HTTP_RESPONSE_TIME_95, label: '0.95' },
  { ...HTTP_RESPONSE_TIME_99, label: '0.99' },
  { ...HTTP_RESPONSE_TIME_AVG, label: 'Avg.' },
  { ...HTTP_RESPONSE_TIME_MEDIAN, label: 'Median' },
]

export const HTTP_TIMING_BREAKDOWN = [
  HTTP_REQUEST_WAITING,
  HTTP_REQUEST_CONNECTING,
  HTTP_REQUEST_RECEIVING,
  HTTP_REQUEST_SENDING,
  HTTP_REQUEST_TLS_HANDSHAKING,
  HTTP_REQUEST_BLOCKED,
  HTTP_RESPONSE_TIME_95,
]

export const GRPC_DEFAULT_METRICS = [
  VUS_METRIC,
  GRPC_REQUEST_RATE,
  GRPC_RESPONSE_TIME,
]

export const BROWSER_DEFAULT_METRICS = [
  BROWSER_WEB_VITAL_LCP,
  BROWSER_WEB_VITAL_FID,
  BROWSER_WEB_VITAL_CLS,
  BROWSER_WEB_VITAL_FCP,
  BROWSER_WEB_VITAL_INP,
  BROWSER_WEB_VITAL_TTFB,
]

export const CHECK_DEFAULT_METRICS = [VUS_METRIC, CHECK_PASSES, CHECK_FAILURES]

export const ALL_STANDARD_METRICS = [
  VUS_METRIC,
  VUS_MAX_METRIC,
  ITERATIONS_METRIC,
  ITERATION_DURATION,
  DROPPED_ITERATIONS,
  GROUP_DURATION,
  CHECKS_METRIC,
  DATA_SENT,
  DATA_RECEIVED,
]

export const ALL_HTTP_METRICS = [
  HTTP_REQUEST_RATE,
  HTTP_FAILURE_RATE,

  HTTP_RESPONSE_TIME,
  HTTP_REQUEST_BLOCKED,
  HTTP_REQUEST_CONNECTING,
  HTTP_REQUEST_TLS_HANDSHAKING,
  HTTP_REQUEST_SENDING,
  HTTP_REQUEST_WAITING,
  HTTP_REQUEST_RECEIVING,
]

export const ALL_WS_METRICS = [
  WS_SESSION_RATE,
  WS_SESSION_DURATION,
  WS_MESSAGES_SENT,
  WS_MESSAGES_RECEIVED,
  WS_CONNECTING,
  WS_PING,
]

export const ALL_GRPC_METRICS = [GRPC_REQUEST_RATE, GRPC_RESPONSE_TIME]

export const ALL_BROWSER_WEB_VITAL_METRICS = [
  BROWSER_WEB_VITAL_LCP,
  BROWSER_WEB_VITAL_FID,
  BROWSER_WEB_VITAL_CLS,
  BROWSER_WEB_VITAL_FCP,
  BROWSER_WEB_VITAL_INP,
  BROWSER_WEB_VITAL_TTFB,
]

export const ALL_BROWSER_HTTP_METRICS = [
  BROWSER_HTTP_REQ_DURATION,
  BROWSER_HTTP_FAILURE_RATE,
  BROWSER_DATA_SENT,
  BROWSER_DATA_RECEIVED,
]

export const ALL_SYSTEM_METRICS = [
  LOAD_GENERATOR_CPU_PERCENT,
  LOAD_GENERATOR_MEMORY_USED_PERCENT,
  LOAD_GENERATOR_FILE_HANDLES,
]

export const ALL_METRICS = [
  ...ALL_STANDARD_METRICS,
  ...ALL_HTTP_METRICS,
  ...ALL_WS_METRICS,
  ...ALL_GRPC_METRICS,
  ...ALL_BROWSER_WEB_VITAL_METRICS,
  ...ALL_BROWSER_HTTP_METRICS,
  ...ALL_SYSTEM_METRICS,
]
