import React from 'react'
import styled from 'styled-components'
import { Icon } from '@grafana/ui'
import { NotificationClient } from '../NotificationsTab.types'

import WebhooksLogo from 'assets/images/webhooks_logo.svg'
import MsTeamsLogo from 'assets/images/ms_teams_logo.svg'
import SlackLogo from 'assets/images/slack_logo.svg'

interface NotificationNameProps {
  clientType?: NotificationClient
}

export const NotificationIcon = ({ clientType }: NotificationNameProps) => {
  switch (clientType) {
    case NotificationClient.MS_TEAMS:
      return <NotificationIconStyled src={MsTeamsLogo} />
    case NotificationClient.SLACK:
      return <NotificationIconStyled src={SlackLogo} />
    case NotificationClient.EMAIL:
      return <Icon size="xxl" name="envelope" />
    case NotificationClient.WEBHOOK:
      return <NotificationIconStyled src={WebhooksLogo} />
    default:
      return null
  }
}

const NotificationIconStyled = styled.img`
  height: 32px;
  width: 32px;
`
