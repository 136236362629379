import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Icon, Modal } from '@grafana/ui'

import { spacing } from 'utils/styled'
import { useUpdateAdditionalUserDataAppJson } from 'data/useAdditionalUserData'
import { OnboardingStage } from 'hooks/useOnboarding'
import { DocLink, docs } from 'components/DocLink'
import { Flex } from 'components/Flex'

interface WelcomeLegacyUserProps {
  isOpen: boolean
  onChange: (stage: OnboardingStage) => void
}

export const WelcomeLegacyUser = ({
  isOpen,
  onChange,
}: WelcomeLegacyUserProps) => {
  const [page, setPage] = useState(0)
  const { mutate } = useUpdateAdditionalUserDataAppJson()

  const title = pages[page]!.title
  const Content = pages[page]!.content

  const isLastPage = page === pages.length - 1

  function handleDismiss() {
    mutate({ gck6_onboarding: 1 })
    onChange('experienced')
  }

  return (
    <Modal trapFocus title={title} isOpen={isOpen} onDismiss={handleDismiss}>
      <Content />

      <Modal.ButtonRow>
        <Button
          disabled={page === 0}
          variant="secondary"
          onClick={() => setPage((page) => page - 1)}
        >
          Back
        </Button>
        {!isLastPage ? (
          <Button onClick={() => setPage((page) => page + 1)}>Next</Button>
        ) : (
          <Button onClick={handleDismiss}>Close</Button>
        )}
      </Modal.ButtonRow>
    </Modal>
  )
}

const Introduction = () => {
  return (
    <Flex gap={3}>
      <Icon name="link" size="xxl" />
      <div>
        <p>
          Your two accounts are now linked and in sync. When you run a test -
          results will show up in both platforms. This is to help ease you into
          a new workflow of using Grafana Cloud k6.
        </p>
        <p>
          Grafana is powerful - we recommend you take time to explore it. For
          example, you could: Set up k8s monitoring and combine that data with
          results from your performance test. Or, you could use one of our many
          data source plugins to pull data from other tools you may be using. In
          all cases - this will make root cause analysis easier for you.
        </p>
      </div>
    </Flex>
  )
}
const introduction = {
  title: 'Your k6 cloud account is now linked to Grafana Cloud!',
  content: Introduction,
}

const WhatsNew = () => {
  return (
    <Flex gap={3}>
      <Icon name="unlock" size="xxl" />
      <div>
        <p>
          <strong>
            In addition we&apos;ve built some new features solely supported in
            Grafana Cloud k6:
          </strong>
        </p>
        <List>
          <li>
            Distributed Tracing in Grafana Cloud k6 - Easily correlate traces
            with your test results
          </li>
          <li>
            Private Load Zones - Leverage our k8s operator as part of Grafana
            cloud k6 for testing internal services
          </li>
          <li>
            Quotas and Limits - Take control of how your organization uses GCk6
            with limits you can set on a per project basis
          </li>
        </List>
      </div>
    </Flex>
  )
}
const whatsNew = {
  title: 'Whats new?',
  content: WhatsNew,
}

const WhatsDifferent = () => {
  return (
    <Flex gap={3}>
      <Icon name="code-branch" size="xxl" />
      <div>
        <p>
          <strong>
            We also had to make a few changes to GCk6 to fit into the Grafana
            Cloud ecosystem:
          </strong>
        </p>
        <List>
          <li>
            APM export was not migrated, instead, leverage Grafana Data Source
            Plugins as a more powerful way to combine data from your tests and
            systems under test
          </li>
          {/* <li>
            PDF report generation - we had to change how we generate PDF
            reports, these are now done via custom dashboards where you can use
            the built in PDF generation from Grafana
          </li> */}
          <li>
            Analysis tab - We had to rethink this because of the powerful
            &quot;explore&quot; feature in Grafana. We made it easy to move from
            test results {'->'} analysis in context of your test {'->'} explore
          </li>
        </List>
      </div>
    </Flex>
  )
}
const whatsDifferent = {
  title: "What's different?",
  content: WhatsDifferent,
}

const WhereToLearnMore = () => {
  return (
    <Flex gap={3}>
      <Icon name="book-open" size="xxl" />
      <div>
        <p>
          <strong>Update your settings</strong>: We used a placeholder
          organization name for the migration. You can read instructions for
          updating your organization name and other settings{' '}
          <DocLink article={docs.accountManagement.cloudPortal}>here</DocLink>.
        </p>
        <p>
          <strong>Explore our docs</strong>: There&apos;s new features for you
          to check out! We&apos;ve documented Grafana Cloud k6 within
          Grafana&apos;s Docs if you want to learn more you can find all of our
          documentation <DocLink article={docs.root.k6}>here</DocLink>.
        </p>
      </div>
    </Flex>
  )
}

const whereToLearnMore = {
  title: "What's next?",
  content: WhereToLearnMore,
}

const pages = [
  introduction,
  whatsNew,
  whatsDifferent,
  whereToLearnMore,
] as const

const List = styled.ul`
  margin: 0;
  padding-left: ${spacing(2)};

  & > li {
    margin-bottom: ${spacing(1)};
  }
`
