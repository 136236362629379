import React, { ReactNode } from 'react'
import {
  CollapsableSectionHeaderRoot,
  CollapsableSectionActions,
  CollapsableSectionLabel,
  CollapsableSectionHeading,
  CollapsableSectionIcon,
  CollapsableSectionDescription,
} from './CollapsableSectionHeader.styles'

interface InsightsHeaderProps {
  heading: ReactNode
  icon?: ReactNode
  description?: ReactNode
  actions?: ReactNode
}

export const CollapsableSectionHeader = ({
  heading,
  icon,
  description,
  actions,
}: InsightsHeaderProps) => {
  return (
    <CollapsableSectionHeaderRoot>
      {icon && <CollapsableSectionIcon>{icon}</CollapsableSectionIcon>}
      <CollapsableSectionLabel>
        <CollapsableSectionHeading>{heading}</CollapsableSectionHeading>
        <CollapsableSectionDescription>
          {description}
        </CollapsableSectionDescription>
      </CollapsableSectionLabel>
      <CollapsableSectionActions>{actions}</CollapsableSectionActions>
    </CollapsableSectionHeaderRoot>
  )
}
