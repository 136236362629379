import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { Icon, Select, SelectContainer } from '@grafana/ui'
import { Button } from 'components/Button'

export const PresetFilters = ({
  options,
}: {
  options: Array<{ onChange: () => void; label: string }>
}) => {
  return (
    <Select
      components={{
        SelectContainer: StyledSelectContainer,
      }}
      onChange={({ onChange }) => onChange()}
      options={options}
      renderControl={forwardRef(function Control({ onClick, onBlur }, ref) {
        return (
          <div ref={ref}>
            <Button onClick={onClick} onBlur={onBlur}>
              Quick select
              <Icon name="angle-down" size="xl" />
            </Button>
          </div>
        )
      })}
      tabSelectsValue={false}
      width="auto"
    />
  )
}

const StyledSelectContainer = styled(SelectContainer)`
  border: none;
  background: none;
  font-size: unset;
  line-height: unset;
`
