import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Dropdown, IconButton, Menu } from '@grafana/ui'

import { ConfirmModal } from 'components/ConfirmModal'
import { RunButtonController } from 'components/RunButtonController'
import { useRunTest } from 'data/useRunTest'
import { useStopTestRunAction } from 'data/useStopTestRun'
import { useConfirmation } from 'hooks/useConfirmation'
import { Test, TestRun, TestWithTrends } from 'types'
import { isTestActive, isTestRunning } from 'utils/testRun'
import { routeMap } from 'routeMap'
import { TrendingMetricsModal } from 'components/TrendingMetricsModal'
import { useMaxConcurrentRunsModal } from 'components/QueueTestModal/MaxConcurrentRunsModal'
import { QueueTestModal } from 'components/QueueTestModal/QueueTestModal'

interface TestMenuProps {
  test: TestWithTrends
  lastRun: TestRun | undefined
  onDeleteTest: (test: Test) => void
  onSelectTest: (test: Test) => void
}

export const TestMenu = ({
  test,
  lastRun,
  onDeleteTest,
  onSelectTest,
}: TestMenuProps) => {
  const history = useHistory()

  const hasTestRuns = lastRun !== undefined

  const isActive = hasTestRuns && isTestActive(lastRun)
  const isRunning = hasTestRuns && isTestRunning(lastRun)

  const { mutateAsync: runTest, isLoading: isRunPending } = useRunTest()
  const [deleteConfirmationProps, handleConfirmDelete] = useConfirmation(() =>
    onDeleteTest(test)
  )
  const [stopTestRunConfirmationProps, handleStopTestRunClick, canStopTestRun] =
    useStopTestRunAction()
  const isStopDisabled = useMemo(
    () => canStopTestRun(lastRun),
    [canStopTestRun, lastRun]
  )

  const [isTrendingMetricsModalOpen, setIsTrendingMetricsModalOpen] =
    useState(false)

  const handleRunTest = () => {
    runTest(test).then((data) => history.push(routeMap.testRun(data.id)))
  }

  const handleConfigureTest = () => {
    history.push(routeMap.editTest(test))
  }

  const handleSelectTest = () => {
    onSelectTest(test)
  }

  const handleClickCustomizeTrendingMetrics = () => {
    setIsTrendingMetricsModalOpen(true)
  }

  const { onRun, ...maxConcurrentRunsModalProps } =
    useMaxConcurrentRunsModal(handleRunTest)

  const menu = (
    <Menu>
      <Menu.Item label="Configure" icon="cog" onClick={handleConfigureTest} />
      {!isActive && (
        <RunButtonController test={test}>
          {({ isDisabled }) =>
            !isDisabled && (
              <Menu.Item
                label={hasTestRuns ? 'Re-run test' : 'Run test'}
                icon={hasTestRuns ? 'sync' : 'play'}
                onClick={onRun}
                disabled={isRunPending}
              />
            )
          }
        </RunButtonController>
      )}

      {isRunning && (
        <Menu.Item
          label="Stop test"
          icon="minus-circle"
          onClick={() => handleStopTestRunClick(lastRun)}
          disabled={isStopDisabled}
        />
      )}
      <Menu.Item
        label="Customize trend"
        icon="graph-bar"
        onClick={handleClickCustomizeTrendingMetrics}
      />
      <Menu.Item
        label="Select"
        icon="check"
        onClick={handleSelectTest}
        disabled={isActive}
      />
      <Menu.Item
        label="Delete test"
        icon="trash-alt"
        onClick={handleConfirmDelete}
        disabled={isActive}
        destructive
      />
    </Menu>
  )

  return (
    <>
      <Dropdown overlay={menu} placement="bottom-end">
        <IconButton aria-label="Open test menu" name="ellipsis-v" size="lg" />
      </Dropdown>
      <ConfirmModal
        {...deleteConfirmationProps}
        title={`Delete test "${test.name}"?`}
        body="This action cannot be undone."
        confirmText="Delete"
      />
      <ConfirmModal {...stopTestRunConfirmationProps} />
      <QueueTestModal {...maxConcurrentRunsModalProps} />
      <TrendingMetricsModal
        test={test}
        isOpen={isTrendingMetricsModalOpen}
        onDismiss={() => {
          setIsTrendingMetricsModalOpen(false)
        }}
      />
    </>
  )
}
