import { EnvironmentVariable } from 'types'
import k6GlobalTypes from '!raw-loader!@types/k6/global.d.ts'

const createEnvVar = ({ description = '', name }: EnvironmentVariable) => `
/**
 * ${description ?? ''}
 */ 
"${name}": string
`

export const getEnvVarType = (data: EnvironmentVariable[]) => {
  if (data.length === 0) {
    return k6GlobalTypes
  }

  const declaration = 'const __ENV: { [name: string]: string };'
  const envVarsType = data.map(createEnvVar).join(';')

  return k6GlobalTypes.replaceAll(
    declaration,
    `const __ENV: { ${envVarsType} };`
  )
}
