import { useMutation, useQueryClient } from '@tanstack/react-query'

import { Test, TestRun } from 'types'
import { useTrackFeature } from 'hooks/useTrackFeature'
import { useDatasource } from 'datasourceContext'
import {
  toProjectsWithStatsKey,
  toTestRunsWithMetricsQueryKey,
  toTestSearchQueryKey,
  toTestWithTrendsQueryKey,
} from './queryKeys'
import { useAppContext } from 'appContext'
import { useTrackAction } from 'services/tracking'
import { TrackingEventName } from 'services/tracking.types'

interface UseRunTestProps {
  onSuccess?: (data: TestRun) => void
}

interface UseRunTestMutationArgs {
  id: Test['id']
  creation_process?: Test['creation_process']
}

export const useRunTest = ({ onSuccess }: UseRunTestProps = {}) => {
  const { ds } = useDatasource()
  const { orgId } = useAppContext()

  const queryClient = useQueryClient()
  const trackFeature = useTrackFeature()
  const trackAction = useTrackAction()

  const handleSuccess = (
    run: TestRun,
    mutationArgs: UseRunTestMutationArgs
  ) => {
    if (mutationArgs.creation_process) {
      trackAction({
        eventName: TrackingEventName.TestRun,
        projectId: run.project_id,
        source: mutationArgs.creation_process,
      })
    }

    trackFeature('start_test')

    queryClient.invalidateQueries({
      queryKey: toTestSearchQueryKey(run.project_id),
    })

    queryClient.invalidateQueries({
      queryKey: toTestWithTrendsQueryKey(run.test_id),
    })

    queryClient.invalidateQueries({
      queryKey: toTestRunsWithMetricsQueryKey(run.test_id),
    })

    queryClient.invalidateQueries({
      queryKey: toProjectsWithStatsKey(orgId),
    })

    onSuccess?.(run)
  }

  return useMutation({
    mutationFn({ id }) {
      return ds.runTest(id)
    },
    onSuccess: handleSuccess,
  })
}
