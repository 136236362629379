import React, { useEffect, useState } from 'react'
import { SortOptions, TestRunAnalysis } from 'types'
import { TreeTableDefinition } from '../types'
import { Table } from '../Table'
import { ScenarioRows } from './ScenarioRows'
import { useExpandOnInit, useGroupTree } from './hooks'
import { useFilteredTree } from './TreeView.hooks'
import { LoadingContainer } from 'components/LoadingContainer'

interface TreeViewProps<T, P, S> {
  expandOnInit: boolean
  analysis: TestRunAnalysis
  params: P
  table: TreeTableDefinition<T, P>
  sortBy: SortOptions<S> | undefined
  onLoaded: () => void
  onSortChange: (sortBy: SortOptions<S> | undefined) => void
}

export function TreeView<T, P, S>({
  expandOnInit,
  analysis,
  params,
  table,
  sortBy,
  onLoaded,
  onSortChange,
}: TreeViewProps<T, P, S>) {
  const { data: tree, isFetching } = useGroupTree(analysis)

  const [loading, setLoading] = useState(true)

  const initialized = useExpandOnInit({
    enabled: expandOnInit,
    testRun: analysis.main,
    tree,
    params,
    table,
    sortBy,
  })

  const filteredTree = useFilteredTree({
    tree,
    table,
  })

  const handleLoading = () => {
    setLoading(true)
  }

  const handleLoaded = () => {
    onLoaded()

    setLoading(false)
  }

  useEffect(() => {
    if (tree !== undefined && initialized && tree.scenarios.length > 1) {
      onLoaded()

      setLoading(false)
    }
  }, [tree, initialized, onLoaded])

  return (
    <LoadingContainer loading={loading}>
      <Table
        view="tree"
        loading={isFetching}
        table={table}
        sortBy={sortBy}
        onSortChange={onSortChange}
      >
        {filteredTree && initialized && (
          <ScenarioRows
            analysis={analysis}
            tree={filteredTree}
            level={0}
            params={params}
            sortBy={sortBy}
            table={table}
            onLoading={handleLoading}
            onLoaded={handleLoaded}
          />
        )}
      </Table>
    </LoadingContainer>
  )
}
