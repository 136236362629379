import {
  object,
  literal,
  number,
  type infer as Infer,
  string,
  discriminatedUnion,
} from 'zod'
import { ScenarioSchema } from '../schema/options/scenario'

const DurationSourceSchema = object({
  type: literal('duration'),
  scenario: ScenarioSchema,
})

export type DurationSource = Infer<typeof DurationSourceSchema>

const IterationsSourceSchema = object({
  type: literal('iterations'),
  scenario: ScenarioSchema,
})

export type IterationsSource = Infer<typeof IterationsSourceSchema>

const StagesSourceSchema = object({
  type: literal('stages'),
  scenario: ScenarioSchema,
})

export type StagesSource = Infer<typeof StagesSourceSchema>

const ScenarioSourceSchema = object({
  type: literal('scenario'),
  name: string(),
  scenario: ScenarioSchema,
})

export type ScenarioSource = Infer<typeof ScenarioSourceSchema>

const ErrorSourceSchema = discriminatedUnion('type', [
  DurationSourceSchema,
  IterationsSourceSchema,
  StagesSourceSchema,
  ScenarioSourceSchema,
])

export type ErrorSource = Infer<typeof ErrorSourceSchema>

export const DurationTooLongSchema = object({
  type: literal('duration-too-long'),
  max: number(),
  actual: number(),
  source: ErrorSourceSchema,
})

export type DurationTooLong = Infer<typeof DurationTooLongSchema>

export const MaxDurationTooLongSchema = object({
  type: literal('max-duration-too-long'),
  max: number(),
  actual: number(),
  source: ErrorSourceSchema,
})

export type MaxDurationTooLong = Infer<typeof MaxDurationTooLongSchema>

export const TotalDurationTooLongSchema = object({
  type: literal('total-duration-too-long'),
  max: number(),
  actual: number(),
  source: ErrorSourceSchema,
})

export type TotalDurationTooLong = Infer<typeof TotalDurationTooLongSchema>

export const LegacyTooManyLoadZonesSchema = object({
  type: literal('legacy-too-many-load-zones'),
  max: number(),
  actual: number(),
})

export type LegacyTooManyLoadZones = Infer<typeof LegacyTooManyLoadZonesSchema>

export const TooManyLoadZonesSchema = object({
  type: literal('too-many-load-zones'),
  max: number(),
  actual: number(),
})

export type TooManyLoadZones = Infer<typeof TooManyLoadZonesSchema>

export const TooManyVUsSchema = object({
  type: literal('too-many-vus'),
  max: number(),
  actual: number(),
})

export type TooManyVUs = Infer<typeof TooManyVUsSchema>

export const StartingTooLateSchema = object({
  type: literal('starting-too-late'),
  max: number(),
  actual: number(),
  source: ErrorSourceSchema,
})

export type StartingTooLate = Infer<typeof StartingTooLateSchema>

export const TooManyBrowserVUsSchema = object({
  type: literal('too-many-browser-vus'),
  max: number(),
  actual: number(),
})

export type TooManyBrowserVUs = Infer<typeof TooManyBrowserVUsSchema>
