import styled from 'styled-components'
import { color, spacing } from 'utils/styled'

export const Value = styled.span<{ $right?: boolean }>``

export const Container = styled.h4`
  display: flex;
  gap: ${spacing(0.5)};
  align-items: flex-end;
  color: ${color('text', 'secondary')};
  font-weight: 700;
  line-height: 100%;

  &.score--good:not(&.score--isComparing) ${Value}:first-child {
    color: ${({ theme }) => theme.visualization.getColorByName('green')};
  }

  &.score--needs_improvement:not(&.score--isComparing) ${Value}:first-child {
    color: ${({ theme }) => theme.visualization.getColorByName('orange')};
  }

  &.score--poor:not(&.score--isComparing) ${Value}:first-child {
    color: ${({ theme }) => theme.visualization.getColorByName('red')};
  }

  &.score--isComparing ${Value}:first-child {
    color: ${color('info', 'text')};
  }
`

export const Separator = styled.span``

export const ScoreCopy = styled.span`
  font-weight: normal;
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
  line-height: 100%;
`

export const Unit = styled(ScoreCopy)`
  font-weight: normal;
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
  line-height: 100%;
  margin-left: -${spacing(0.5)};
`
