import React, { useCallback, useEffect, useMemo } from 'react'
import { ModalProps } from '@grafana/ui'
import { TestWithTrends } from 'types'

import { Button } from 'components/Button'
import { LoadingContainer } from 'components/LoadingContainer'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { useTrendPolling } from 'data/useTestWithTrends'
import { useLastActiveRun } from 'data/useTestRuns'
import {
  useResetTrendingMetrics,
  useTrendingMetrics,
  useUpdateTrendingMetrics,
} from 'data/useTrendingMetrics'
import { usePrevious } from 'hooks/usePrevious'

import { TrendingMetricsEditor } from './TrendingMetricsEditor'
import { useTrendingMetricDraftConfig } from './TrendingMetricsEditor/TrendingMetricsEditor.hooks'
import { canResetTrendingMetrics } from './TrendingMetricsEditor/TrendingMetricsEditor.utils'
import { isTestAborted, isTestActive } from 'utils/testRun'
import {
  ButtonRow,
  ModalActionsContainer,
  TrendingMetricsModal as Modal,
} from './TrendingMetricsModal.styles'
import { ErrorBoundaryFullPageView } from 'services/tracking.types'

// This flag is temporary until we enable creating multiple trend metrics.
// Set it to true to enable multiple trends mode
export const CAN_CONFIGURE_MULTIPLE_TRENDS = false

export interface TrendingMetricsModalProps
  extends Omit<ModalProps, 'title' | 'onDismiss'> {
  test: TestWithTrends
  onDismiss: () => void
}

export const TrendingMetricsModal = ({
  test,
  isOpen,
  onDismiss,
}: TrendingMetricsModalProps) => {
  const { data: lastRun, isFetching: isFetchingLastRun } = useLastActiveRun(
    test.id,
    !!isOpen
  )

  const prevIsOpen = usePrevious(isOpen)
  const { refetch: pollTrendingMetrics, isRefetching } = useTrendPolling(test)
  const {
    data: metrics,
    isInitialLoading: isInitialLoadingMetricOptions,
    isRefetching: isRefetchingMetricOptions,
    isError: metricsError,
    refetch: refetchMetricOptions,
  } = useTrendingMetrics(test, isOpen)

  const fetchingMetrics =
    isInitialLoadingMetricOptions || isRefetchingMetricOptions

  const {
    mutateAsync: updateTrendingMetrics,
    isLoading: updatingTrendingMetrics,
  } = useUpdateTrendingMetrics(test)
  const {
    mutateAsync: resetTrendingMetrics,
    isLoading: resettingTrendingMetrics,
  } = useResetTrendingMetrics(test)

  const isActive = lastRun && isTestActive(lastRun)

  useEffect(() => {
    if (!prevIsOpen && isOpen && lastRun && isActive) {
      refetchMetricOptions()
    }
  }, [prevIsOpen, isOpen, lastRun, isActive, refetchMetricOptions])

  const {
    draftConfigs,
    updateConfig,
    addConfig,
    deleteConfig,
    checkDraftIsValid,
    errors,
    hasChanged,
  } = useTrendingMetricDraftConfig(test, isOpen)

  const onSuccess = useCallback(() => {
    pollTrendingMetrics().then(() => {
      onDismiss()
    })
  }, [onDismiss, pollTrendingMetrics])

  const isAborted = lastRun && isTestAborted(lastRun)
  const hasMetricOptions = metrics && metrics?.length > 0

  const canReset = useMemo(
    () =>
      canResetTrendingMetrics(test.trending_metrics) &&
      !isAborted &&
      hasMetricOptions,
    [hasMetricOptions, isAborted, test.trending_metrics]
  )

  const canSubmit = useMemo(
    () =>
      !updatingTrendingMetrics &&
      !resettingTrendingMetrics &&
      !isRefetching &&
      !isAborted &&
      hasMetricOptions &&
      hasChanged,
    [
      hasChanged,
      hasMetricOptions,
      isAborted,
      isRefetching,
      resettingTrendingMetrics,
      updatingTrendingMetrics,
    ]
  )

  const onSubmit = useCallback(async () => {
    const isDraftValid = checkDraftIsValid()
    if (canSubmit && isDraftValid) {
      await updateTrendingMetrics(draftConfigs, { onSuccess })
    }
  }, [
    canSubmit,
    draftConfigs,
    onSuccess,
    updateTrendingMetrics,
    checkDraftIsValid,
  ])

  const onResetTrendingMetrics = async () => {
    if (canReset) {
      await resetTrendingMetrics(draftConfigs, { onSuccess })
    }
  }

  return (
    <Modal title="Trending Metric" isOpen={isOpen} onDismiss={onDismiss}>
      <LoadingContainer
        loading={isFetchingLastRun || fetchingMetrics}
        estimatedHeight={300}
      >
        <ErrorBoundary view={ErrorBoundaryFullPageView.TrendingMetricsModal}>
          <TrendingMetricsEditor
            test={test}
            lastRun={lastRun}
            metrics={metrics}
            isOpen={isOpen}
            draftConfigs={draftConfigs}
            draftConfigsErrors={errors}
            onChange={updateConfig}
            onDeleteDraft={deleteConfig}
            onInsertDraft={addConfig}
            hasError={metricsError}
          />
          <ButtonRow>
            <Button
              className="reset-trending-metric-btn"
              onClick={onResetTrendingMetrics}
              variant="primary"
              fill="text"
              hidden={!canReset}
              disabled={!canReset}
            >
              Reset to default trending metric
            </Button>
            <ModalActionsContainer>
              <Button onClick={onDismiss} variant="secondary">
                Cancel
              </Button>
              <Button
                icon={
                  updatingTrendingMetrics || resettingTrendingMetrics
                    ? 'fa fa-spinner'
                    : undefined
                }
                disabled={!canSubmit}
                onClick={onSubmit}
              >
                Save
              </Button>
            </ModalActionsContainer>
          </ButtonRow>
        </ErrorBoundary>
      </LoadingContainer>
    </Modal>
  )
}
