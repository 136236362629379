import { resolveTimeZone } from 'utils/date'

export const getDateLabel = (date: Date) => {
  return new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: 'short',
    timeZone: resolveTimeZone(),
  }).format(date)
}

export const getTimeLabel = (date: Date) => {
  return new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
    timeZone: resolveTimeZone(),
  }).format(date)
}
