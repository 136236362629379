import React, { ReactNode } from 'react'
import { config } from '@grafana/runtime'
import semver from 'semver'
import styled from 'styled-components'

import plugin from '../plugin.json'
import { BertMessage } from './BertMessage'

export const UnsupportedGrafanaVersionPage = ({
  children,
}: {
  children: ReactNode
}) => {
  const buildVersion = semver.coerce(config.buildInfo.version) ?? ''
  const minGrafanaVersion = plugin.dependencies.grafanaDependency

  if (!semver.satisfies(buildVersion, minGrafanaVersion)) {
    return (
      <Container>
        <BertMessage
          type="error"
          title={`Grafana ${buildVersion} is not supported`}
          description={
            <BertMessage.Text>
              To use k6 app upgrade your instance version to{' '}
              <strong>
                {semver.coerce(minGrafanaVersion)?.version} or higher
              </strong>
            </BertMessage.Text>
          }
        />
      </Container>
    )
  }

  return <>{children}</>
}

const Container = styled.div`
  padding-top: ${({ theme }) => theme.spacing(16)};
`
