import React from 'react'
import { Button, Icon, Modal } from '@grafana/ui'

import { Test, TestRun } from 'types'
import { getTestRunLabel, isTestExportFinished } from 'utils/testRun'
import { resolveTimeZone } from 'utils/date'

import {
  useDownloadExportData,
  useExportAlert,
  useExportData,
} from './ExportData.hooks'

import { ExportModalBody, IconWrapper } from './ExportData.styles'

interface ExportDataProps {
  isModalOpen: boolean
  test: Test
  testRun: TestRun
  onModalDismiss: () => void
}

export const ExportData = ({
  isModalOpen,
  onModalDismiss,
  test,
  testRun,
}: ExportDataProps) => {
  useExportAlert({ test, testRun })

  return (
    <ExportModal
      test={test}
      testRun={testRun}
      isOpen={isModalOpen}
      onDismiss={onModalDismiss}
    />
  )
}

interface ExportModalProps {
  isOpen: boolean
  test: Test
  testRun: TestRun
  onDismiss: () => void
}

export const ExportModal = ({
  isOpen,
  test,
  testRun,
  onDismiss,
}: ExportModalProps) => {
  const { mutate, isSuccess } = useExportData(testRun)

  const title =
    (!isSuccess ? 'Start' : 'Generating') +
    ` data export: ${test.name}, ${getTestRunLabel(testRun, resolveTimeZone())}`

  function handleConfirm() {
    mutate()
  }

  return (
    <Modal title={title} isOpen={isOpen} onDismiss={onDismiss}>
      <ExportModalContent
        isActive={isSuccess}
        testRun={testRun}
        onDismiss={onDismiss}
      />
      <Modal.ButtonRow>
        <Button variant="secondary" onClick={onDismiss} fill="outline">
          {!isSuccess ? 'Cancel' : 'Close'}
        </Button>
        {!isSuccess && <Button onClick={handleConfirm}>Confirm</Button>}
      </Modal.ButtonRow>
    </Modal>
  )
}

interface ExportModalContentProps {
  isActive: boolean
  testRun: TestRun
  onDismiss: () => void
}

const ExportModalContent = ({
  isActive,
  testRun,
  onDismiss,
}: ExportModalContentProps) => {
  const { downloadExportData, isDownloading } = useDownloadExportData(
    testRun.id
  )

  const handleClick = async () => {
    return downloadExportData().then(() => {
      onDismiss()
    })
  }

  if (isTestExportFinished(testRun)) {
    return (
      <ExportModalBody>
        <h2>Your exported data is ready</h2>
        <Button
          icon="cloud-download"
          size="md"
          onClick={handleClick}
          disabled={isDownloading}
          tooltip={isDownloading ? 'Download is in progress' : undefined}
        >
          download
        </Button>
      </ExportModalBody>
    )
  }

  if (isActive) {
    return (
      <ExportModalBody>
        <h2>Getting ready...</h2>
        <IconWrapper>
          <Icon name="fa fa-spinner" />
        </IconWrapper>
      </ExportModalBody>
    )
  }

  return (
    <p>
      Sometimes exporting data from your test results can take a long time. When
      the data is ready we will provide you with a download link.
    </p>
  )
}
