export const toUsersQueryKey = (userIds: number[]) =>
  ['users', userIds] as const

export const toUserRunStatsQueryKey = (userId: number) =>
  ['user-run-stats', userId] as const

export const toGrafanaUsersQueryKey = (query: string) =>
  ['grafana-users', query] as const

export const toGrafanaTeamsQueryKey = (query: string) =>
  ['grafana-teams', query] as const
