import React, { ComponentProps } from 'react'
import { Icon, Label, Tooltip } from '@grafana/ui'
import styled from 'styled-components'
import { color, spacing } from 'utils/styled'

interface StaticCalculatorHelperFieldLabelProps {
  label: string
  tooltipContent: string
  placement?: ComponentProps<typeof Tooltip>['placement']
  iconName?: ComponentProps<typeof Icon>['name']
}

export const IconFieldLabel = ({
  label,
  tooltipContent,
  placement = 'auto',
  iconName = 'info-circle',
}: StaticCalculatorHelperFieldLabelProps) => {
  return (
    <Label>
      <Container>
        <span>{label}</span>
        <Tooltip content={tooltipContent} placement={placement}>
          <Icon name={iconName} />
        </Tooltip>
      </Container>
    </Label>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(0.25)};

  svg {
    color: ${color('info', 'main')};
  }
`
