import React from 'react'
import { Button } from '../Button'
import { MaxConcurrentRunsModal } from './MaxConcurrentRunsModal'
import { useConcurrencyPolicy } from 'hooks/useConcurrencyPolicy'

interface QueueRunModalProps {
  isOpen: boolean
  onDismiss: () => void
  onQueue: () => void
}

export function QueueTestModal({
  isOpen,
  onDismiss,
  onQueue,
}: QueueRunModalProps) {
  const concurrencyPolicy = useConcurrencyPolicy()
  const canQueue = concurrencyPolicy === 'queue'

  return (
    <MaxConcurrentRunsModal
      isOpen={isOpen}
      actions={
        canQueue ? (
          <Button key="queue" variant="secondary" onClick={onQueue}>
            Queue
          </Button>
        ) : (
          <Button key="queue" variant="secondary" onClick={onDismiss}>
            Close
          </Button>
        )
      }
      onDismiss={onDismiss}
    />
  )
}
