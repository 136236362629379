import { useOrganization } from 'data/useOrganization'
import { getGrafanaUrl } from 'utils/env'

export const useGrafanaSubscriptionUrl = () => {
  const organization = useOrganization()

  return getGrafanaUrl(
    organization?.grafana_org_slug
      ? `/orgs/${organization.grafana_org_slug}/subscription`
      : `/pricing`
  )
}
