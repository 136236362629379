import React, { useState } from 'react'
import { kebabCase } from 'lodash-es'
import { Button, ClipboardButton } from '@grafana/ui'

import { Test, TestRun } from 'types'
import { downloadScript } from 'utils/downloadFile'
import { CLIRunGuide } from 'components/CLIRunGuide'
import { useHasUserProjectWriteAccess } from 'data/usePermissions'
import { CodeEditorWithEnvVars } from 'components/CodeEditor'
import {
  ScriptTabButtonsContainer,
  MessageContainer,
  ScriptTabWrapper,
  ScriptEditorWrapper,
} from './ScriptTab.styles'
import { CreateFromScriptButton } from './CreateFromScriptButton'
import { isLocalExecution } from 'utils/testRun'
import { LocallyExecutedMessage } from '../LocallyExecutedMessage'
import { BreakdownTab } from '../Breakdown.types'
import { BreakdownTabMessage } from '../Breakdown.styles'
import { BreakdownTabHeader } from '../BreakdownTabHeader'
import { MissingScriptMessage } from './MissingScriptMessage'
import { QueryErrorBoundary } from 'components/ErrorBoundary'
import { ErrorBoundaryLocalView } from 'services/tracking.types'

type Preview = 'cli-guide' | 'inline' | 'download'

interface ScriptTabProps {
  run: TestRun
  test: Test
}

interface ScriptPreviewProps {
  preview: Preview
  script: string
  test: Test
}

const ScriptPreview = ({ preview, script, test }: ScriptPreviewProps) => {
  const scriptName = test.name ? `${kebabCase(test.name)}.js` : 'test.js'

  function handleDownload() {
    downloadScript(script, scriptName)
  }

  if (preview === 'cli-guide') {
    return (
      <CLIRunGuide
        projectId={test.project_id}
        testName={test.name}
        scriptName={scriptName}
        script={script}
      />
    )
  }

  if (preview === 'download') {
    return (
      <ScriptTabWrapper>
        <MessageContainer>
          <div>Script is too large to preview</div>
          <Button fill="text" onClick={handleDownload} icon="download-alt">
            Download script
          </Button>
        </MessageContainer>
      </ScriptTabWrapper>
    )
  }

  return (
    <ScriptTabWrapper>
      <ScriptEditorWrapper>
        <CodeEditorWithEnvVars
          value={script}
          readOnly={true}
          overlayMessage={null}
        />
      </ScriptEditorWrapper>
    </ScriptTabWrapper>
  )
}

const ScriptTabComponent = ({ run, test }: ScriptTabProps) => {
  const [preview, setPreview] = useState<Preview>('inline')
  const hasUserProjectWriteAccess = useHasUserProjectWriteAccess()

  const script = run.script ?? ''
  const isScriptShown = preview === 'inline'
  const isTooLargeForPreview = script.length > 200000

  function handleTogglePreviewClick() {
    const view = isTooLargeForPreview ? 'download' : 'inline'
    setPreview(preview === 'cli-guide' ? view : 'cli-guide')
  }

  if (isLocalExecution(run)) {
    return <LocallyExecutedMessage type={BreakdownTab.SCRIPT} />
  }

  if (!script) {
    return (
      <BreakdownTabMessage>
        <MissingScriptMessage />
      </BreakdownTabMessage>
    )
  }

  return (
    <ScriptTabWrapper>
      <BreakdownTabHeader>
        <ScriptTabButtonsContainer>
          {isScriptShown && !isTooLargeForPreview && (
            <>
              <ClipboardButton variant="secondary" getText={() => script}>
                Copy script
              </ClipboardButton>
              <CreateFromScriptButton scriptValue={script} />
            </>
          )}

          {hasUserProjectWriteAccess && (
            <Button
              onClick={handleTogglePreviewClick}
              variant="secondary"
              icon={isScriptShown ? 'info-circle' : 'file-alt'}
            >
              {preview !== 'cli-guide'
                ? 'Run script from CLI'
                : 'See test script'}
            </Button>
          )}
        </ScriptTabButtonsContainer>
      </BreakdownTabHeader>

      <ScriptPreview preview={preview} script={script} test={test} />
    </ScriptTabWrapper>
  )
}

export function ScriptTab(props: ScriptTabProps) {
  return (
    <QueryErrorBoundary view={ErrorBoundaryLocalView.ScriptTab}>
      <ScriptTabComponent {...props} />
    </QueryErrorBoundary>
  )
}
