import React, { useState } from 'react'
import { Drawer, TabContent } from '@grafana/ui'

import { ExternalLink } from 'components/ExternalLink'
import { Instructions } from 'components/Instructions'
import { TabsBar } from 'components/Tabs/TabsBar'
import { Tab } from 'components/Tabs/Tab'

import { RunTestOnPlzGuide } from './RunTestOnPlzGuide'
import { SetupPlzGuide } from './SetupPlzGuide'

interface LoadZoneGuideProps {
  isOpen: boolean
  onClose: () => void
}

export const LoadZoneGuide = ({ isOpen, onClose }: LoadZoneGuideProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  if (!isOpen) {
    return null
  }

  return (
    <Drawer scrollableContent onClose={onClose}>
      <Instructions.CloseButton onClick={onClose} name="times" />
      <Instructions.TitleContainer>
        <h3>How to use Private Load Zones</h3>
        <p>
          Private Load Zones are designed to run on top of Kubernetes. They are
          managed by our{' '}
          <ExternalLink url="https://github.com/grafana/k6-operator">
            open-source Kubernetes Operator
          </ExternalLink>{' '}
          and are defined using{' '}
          <ExternalLink url="https://kubernetes.io/docs/concepts/extend-kubernetes/api-extension/custom-resources/">
            Custom Resource Definitions
          </ExternalLink>
        </p>
        <TabsBar>
          <Tab
            key={0}
            label="Set up Private Load Zones"
            active={activeTabIndex === 0}
            onChangeTab={() => setActiveTabIndex(0)}
          />
          <Tab
            key={1}
            label="Run a test in a Private Load Zone"
            active={activeTabIndex === 1}
            onChangeTab={() => setActiveTabIndex(1)}
          />
        </TabsBar>
      </Instructions.TitleContainer>
      <Instructions.Container>
        <TabContent>
          {activeTabIndex === 0 && <SetupPlzGuide />}
          {activeTabIndex === 1 && <RunTestOnPlzGuide />}
        </TabContent>
      </Instructions.Container>
    </Drawer>
  )
}
