import React from 'react'

import { DATA_TABLE_INITIAL_HEIGHT } from 'components/DataTable'
import { LoadingContainer } from 'components/LoadingContainer'
import { BertMessage } from 'components/BertMessage'
import { useProjectLimitsWithSummary } from './LimitsTable.hooks'
import { getColumns } from './LimitsTable.utils'
import { type ProjectSettings } from 'types'
import { DataTable } from './LimitsTable.styles'

interface LimitsTableProps {
  data: ProjectSettings[]
  isLoading: boolean
}

export const LimitsTable = ({ data, isLoading }: LimitsTableProps) => {
  const { dataWithSummary, isSummaryLoading } =
    useProjectLimitsWithSummary(data)
  const columns = getColumns()

  return (
    <LoadingContainer
      loading={isLoading || isSummaryLoading}
      style={{ minHeight: DATA_TABLE_INITIAL_HEIGHT, width: '100%' }}
    >
      <DataTable
        highlightOnHover
        fixedHeader
        keyField="project_id"
        columns={columns}
        data={dataWithSummary}
        noDataComponent={
          <BertMessage description="Looks empty here! Start by setting usage limits for one of your projects." />
        }
      />
    </LoadingContainer>
  )
}
