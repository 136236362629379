import React from 'react'
import {
  Label,
  Unit,
  Value,
} from 'pages/ProjectsListPage/components/ProjectStats.styles'
import { Tooltip } from 'components/Tooltip'
import { LabelWrapper, StatsSummaryItemContainer } from '../UsageReports.styles'

interface StatsSummaryItemProps {
  label: string
  tooltip?: string
  count?: number
  unit: string
}

export const StatsSummaryItem = ({
  label,
  count,
  unit,
  tooltip,
}: StatsSummaryItemProps) => {
  return (
    <StatsSummaryItemContainer>
      <LabelWrapper>
        <Tooltip
          show={!!tooltip}
          content={tooltip || ''}
          interactive
          placement="top-start"
        >
          <Label>
            <span>{label}</span>
          </Label>
        </Tooltip>
      </LabelWrapper>
      <Value>
        {count ?? 0} <Unit>{unit}</Unit>
      </Value>
    </StatsSummaryItemContainer>
  )
}
