import { Test, TestRun, TestWithTrends } from 'types'
import { orderBy } from 'lodash-es'
import { timeFormatter } from 'utils/formatters'
import { resolveTimeZone } from 'utils/date'

const FALLBACK_DISPLAY_VALUE = '-'
const CONSISTENT_LOCALE = 'en-US'

export function getLatestTestRun(test: Test) {
  const sortByStarted = (items: TestRun[]) =>
    orderBy(items, (item) => item.started || item.created, 'desc')

  return sortByStarted(
    test.test_runs.filter((run) => run.test_id === test.id)
  )[0]
}

export function getDurationDisplay(duration?: number) {
  if (!duration) {
    return FALLBACK_DISPLAY_VALUE
  }

  return timeFormatter(duration)
}

export function getTestRunVUsDisplay(testRun?: TestRun) {
  if (!testRun || !testRun.vus) {
    return FALLBACK_DISPLAY_VALUE
  }
  if (testRun.vus === 1) {
    return `${testRun.vus} VU`
  }

  return `${Number(testRun.vus).toLocaleString()} VUs`
}

function toVUhDisplay(vuh = 0) {
  return vuh > 0 ? `${vuh} VUh` : FALLBACK_DISPLAY_VALUE
}

export function getTestVUhDisplay(test?: TestWithTrends) {
  return toVUhDisplay(test?.vuh_cost_total)
}

export function getTestRunVUhDisplay(testRun?: TestRun) {
  return toVUhDisplay(testRun?.vuh_cost)
}

export function getTestScheduleDisplay(test?: Test) {
  const schedule = test?.schedule

  if (!schedule?.next_run || !schedule?.active) {
    return null
  }

  const dateTimeFormatter = new Intl.DateTimeFormat(CONSISTENT_LOCALE, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
    timeZone: resolveTimeZone(),
    hour12: false,
  })
  const formattedDate = dateTimeFormatter.format(new Date(schedule.next_run))

  return formattedDate
}
