import React, { MouseEvent, ReactNode, useMemo } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

interface QueryParamLinkProps {
  name: string
  value: string
  clear?: boolean
  children: ReactNode
}

export const QueryParamLink = ({
  name,
  value,
  clear = false,
  children,
}: QueryParamLinkProps) => {
  const history = useHistory()
  const location = useLocation()

  const to = useMemo(() => {
    const searchParams = new URLSearchParams(clear ? '' : location.search)

    searchParams.set(name, value)

    return {
      ...location,
      search: searchParams.toString(),
    }
  }, [location, name, value, clear])

  const handleClick = (ev: MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault()
    ev.stopPropagation()

    history.push(to)
  }

  return (
    <Link to={to} onClick={handleClick}>
      {children}
    </Link>
  )
}
