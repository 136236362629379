import React from 'react'
import { Span } from './types'
import { getDuration } from './BrowserTimeline.utils'
import {
  InteractionIcons,
  Item,
  ItemRow,
  StyledTimelineItem,
} from './TimelineItem.styled'
import { WebVital } from './WebVital'
import { Locator } from './Locator'
import { Tooltip } from '@grafana/ui'
import { Screenshot } from './Screenshot'
import { getLowestWebVitalRating, getNavigationUrl } from './TimelineItem.utils'
import { LOCATOR_SPAN_TYPES, SPAN_NAME_WEB_VITAL } from './constants'
import { cx } from '@emotion/css'
import { DataRow } from './DataRow'
import { millisecondsToSeconds } from '../../utils/math'
import { OtherSpan } from './OtherSpan'

interface TimelineItemProps {
  xOffset: number
  rootDuration: number
  span: Span
  spanChildren: Span[]
  compact: boolean
}

export function TimelineItem({
  xOffset,
  span,
  spanChildren,
  rootDuration,
  compact = false,
}: TimelineItemProps) {
  const webVitalsSpans = spanChildren.filter(
    (child) => child.name === SPAN_NAME_WEB_VITAL
  )
  const hasWebVitals = webVitalsSpans.length > 0
  const lowestWebVitalRating = getLowestWebVitalRating(webVitalsSpans)
  const locatorSpans = spanChildren.filter((child) =>
    LOCATOR_SPAN_TYPES.includes(child.name)
  )

  const screenshots = spanChildren.filter(
    (child) => child.name === 'page.screenshot'
  )

  const spanDuration = getDuration(span)

  const leftOverSpans = spanChildren.filter((child) => {
    return !webVitalsSpans.includes(child) && !locatorSpans.includes(child)
  })

  const durationWidth = `${(spanDuration / rootDuration) * 100}%`
  const itemXOffset = `${(xOffset / rootDuration) * 100}%`
  const navigationUrl = getNavigationUrl(span)

  const ratingClassName = `web-vital-rating--${lowestWebVitalRating}`

  const hasInteractions = locatorSpans.length > 0 || screenshots.length > 0

  if (compact) {
    return (
      <Tooltip content={navigationUrl ?? 'unknown error'}>
        <StyledTimelineItem
          className={cx(['compact', ratingClassName])}
          style={{
            left: itemXOffset,
            width: durationWidth,
            position: 'relative',
          }}
        />
      </Tooltip>
    )
  }

  return (
    <ItemRow className={ratingClassName}>
      <StyledTimelineItem
        className="web-vital-rating-color"
        style={{ left: itemXOffset, width: durationWidth }}
      />
      {hasInteractions && (
        <InteractionIcons style={{ left: itemXOffset, width: durationWidth }}>
          {locatorSpans.map((child) => {
            return <Locator key={child.spanId} span={child} parentSpan={span} />
          })}
          {screenshots.map((child) => {
            return (
              <Screenshot key={child.spanId} span={child} parentSpan={span} />
            )
          })}
        </InteractionIcons>
      )}
      <Item
        className={cx(['web-vital-rating-border-top'])}
        style={{ left: itemXOffset, minWidth: durationWidth }}
      >
        <DataRow />
        <DataRow icon="clock-nine">
          {millisecondsToSeconds(spanDuration)}s
        </DataRow>
        <DataRow icon="link-h">{navigationUrl}</DataRow>
        {hasWebVitals && (
          <DataRow icon="dashboard">
            {webVitalsSpans.map((child) => {
              return <WebVital key={child.spanId} span={child} />
            })}
          </DataRow>
        )}
        <DataRow icon="record-audio">
          {leftOverSpans.map((child) => {
            return <OtherSpan key={child.spanId} span={child} />
          })}
        </DataRow>
        <DataRow />
      </Item>
    </ItemRow>
  )
}
