export const SCORE_COPY = {
  good: 'Good',
  needs_improvement: 'Needs improvement',
  poor: 'Poor',
} as const

export const LONG_NAME = {
  LCP: 'Largest Contentful Paint',
  FID: 'First Input Delay',
  CLS: 'Cumulative Layout Shift',
  FCP: 'First Contentful Paint',
  INP: 'Interaction to next paint',
  TTFB: 'Time to First Byte',
} as const

export const WEB_VITAL_CONFIG = {
  web_vital_lcp: {
    name: 'LCP',
    longName: LONG_NAME.LCP,
    unit: 'seconds',
  },
  web_vital_fid: {
    name: 'FID',
    longName: LONG_NAME.FID,
    unit: 'milliseconds',
  },
  web_vital_cls: {
    name: 'CLS',
    longName: LONG_NAME.CLS,
    unit: 'score',
  },
  web_vital_fcp: {
    name: 'FCP',
    longName: LONG_NAME.FCP,
    unit: 'seconds',
  },
  web_vital_inp: {
    name: 'INP',
    longName: LONG_NAME.INP,
    unit: 'milliseconds',
  },
  web_vital_ttfb: {
    name: 'TTFB',
    longName: LONG_NAME.TTFB,
    unit: 'milliseconds',
  },
} as const

export type WebVitalsConfigName = keyof typeof WEB_VITAL_CONFIG

export const WEB_VITALS_THRESHOLDS: Record<
  WebVitalsConfigName,
  [number, number]
> = {
  web_vital_lcp: [2500, 4000],
  web_vital_fid: [100, 300],
  web_vital_cls: [0.1, 0.25],
  web_vital_fcp: [1800, 3000],
  web_vital_ttfb: [800, 1800],
  web_vital_inp: [200, 500],
}
