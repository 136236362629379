import React, { ReactNode } from 'react'
import {
  ApplicationWindowStyled,
  ContentContainer,
} from './ApplicationWindow.styled'
import { TitleBar } from './TitleBar'

interface ApplicationWindowProps {
  titleContent?: ReactNode
  children?: ReactNode
  className?: string
}

export function ApplicationWindow({
  titleContent,
  children,
  className,
}: ApplicationWindowProps) {
  return (
    <ApplicationWindowStyled className={className}>
      <TitleBar>{titleContent}</TitleBar>
      <ContentContainer className="ApplicationWindowStyled-titleBar">
        {children}
      </ContentContainer>
    </ApplicationWindowStyled>
  )
}
