import React from 'react'
import { SkippedDescription } from '../SkippedDescription'
import { InsightListItem } from '../InsightItem'
import {
  HealthLoadGeneratorHighMemoryUsage,
  HealthLoadGeneratorHighMemoryUsageFailed,
} from 'types/insights/health'
import { InsightBody } from '../InsightBody'
import { DocLink, docs, ReadMore } from 'components/DocLink'
import { instanceName } from 'utils/formatters'
import { TestRun } from 'types'
import { useLoadGeneratorMetrics } from './hooks'
import { LOAD_GENERATOR_MEMORY_USED_PERCENT } from 'constants/metrics'
import { Chart } from 'components/Chart'

interface HighLoadGeneratorMemoryUsageProps {
  testRun: TestRun
  insight: HealthLoadGeneratorHighMemoryUsageFailed
}

export const HighLoadGeneratorMemoryUsage = ({
  testRun,
  insight,
}: HighLoadGeneratorMemoryUsageProps) => {
  const metrics = useLoadGeneratorMetrics(
    LOAD_GENERATOR_MEMORY_USED_PERCENT,
    insight.data.instances
  )

  const intro =
    insight.data.instances[0] !== undefined
      ? `The cloud load generator ${instanceName(
          insight.data.instances[0]
        )} showed high memory utilization during your test`
      : `There were ${insight.data.instances.length} load generator instances showing high memory utilization during your test.`

  return (
    <InsightBody>
      <p>
        {intro}. When the resources on a load generator are consistently high,
        test results may become erratic and inaccurate as the performance of the
        load generator itself begins to degrade. High memory utilization can
        also cause{' '}
        <DocLink article={docs.insights.health.highLoadGeneratorCPUUsage}>
          high CPU utilization
        </DocLink>
        . A good rule of thumb is that memory usage should not consistently
        exceed 80% during a test. The graph below shows the actual memory
        utilization during this test.
      </p>
      <p>
        <Chart
          title="High Load Generator Memory Usage"
          testRun={testRun}
          metrics={metrics}
        />
      </p>
      <h4>Recommendations:</h4>
      <ul>
        <li>
          Add or increase think time using{' '}
          <DocLink article={docs.javascript.sleep}>sleep</DocLink>, to slow down
          the request rate. You may want to increase the number of total VUs to
          compensate.
        </li>
        <li>
          Select more load zones to spread out the number of VUs across more
          regions and load generators.
        </li>
        <li>
          Remove or consolidate logs and custom metrics in the test script.
        </li>
        <li>
          <DocLink article={docs.thresholds.main}>Set a threshold</DocLink> for
          memory utilization to be notified when this happens again.
        </li>
        <li>
          Consider{' '}
          <DocLink article={docs.options.discardResponseBodies}>
            discarding response bodies
          </DocLink>{' '}
          where possible, and use <code>responseType</code> to capture only the
          response bodies you require.
        </li>
      </ul>
      <p>
        <ReadMore article={docs.insights.health.highLoadGeneratorMemoryUsage} />
      </p>
    </InsightBody>
  )
}

interface HighLoadGeneratorMemoryUsageItemProps {
  insight: HealthLoadGeneratorHighMemoryUsage
}

const HighLoadGeneratorMemoryUsageDescription = ({
  insight,
}: HighLoadGeneratorMemoryUsageItemProps) => {
  switch (insight.status) {
    case 'PASSED':
      return (
        <>
          The memory utilization of the load generators remained healthy
          throughout the test.{' '}
          <ReadMore
            article={docs.insights.health.highLoadGeneratorMemoryUsage}
          />
        </>
      )

    case 'FAILED':
      return (
        <>
          One or more of the load generators in this test displayed high memory
          utilization.
        </>
      )

    default:
      return <SkippedDescription insight={insight} />
  }
}

export const HighLoadGeneratorMemoryUsageItem = ({
  insight,
}: HighLoadGeneratorMemoryUsageItemProps) => {
  return (
    <InsightListItem
      header="High Load Generator Memory Usage"
      insight={insight}
    >
      <HighLoadGeneratorMemoryUsageDescription insight={insight} />
    </InsightListItem>
  )
}
