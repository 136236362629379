import React from 'react'
import styled from 'styled-components'
import { cx } from '@emotion/css'
import { InlineSwitch } from '@grafana/ui'
import { color, fontSize, spacing } from 'utils/styled'
import { Button } from 'components/Button'
import { NotificationClient } from '../../NotificationsTab.types'
import { NOTIFICATION_CHANNELS_META } from '../../NotificationsTab.constants'
import { NotificationIcon } from '../NotificationIcon'

interface NotificationFormHeaderProps {
  clientType: NotificationClient
  isNewNotification?: boolean
  title?: string
  isAdvancedToggled: boolean
  isFormDirty: boolean
  onToggleAdvanced: React.Dispatch<React.SetStateAction<boolean>>
  onTestNotification?(): void
}

export const NotificationFormHeader = ({
  clientType,
  isNewNotification,
  title: notificationChannelName,
  isAdvancedToggled,
  isFormDirty,
  onToggleAdvanced,
  onTestNotification,
}: NotificationFormHeaderProps) => {
  const handleToggleAdvanced = () => {
    onToggleAdvanced((isToggled) => !isToggled)
  }
  const title = `${
    isNewNotification
      ? `Create ${NOTIFICATION_CHANNELS_META[clientType].name}`
      : notificationChannelName
  }`

  const isSwitchDisabled =
    !clientType || clientType === NotificationClient.WEBHOOK

  return (
    <Wrapper>
      <Header>
        {!isNewNotification && <NotificationIcon clientType={clientType} />}
        <div>
          <Title>{title}</Title>
          {!isNewNotification && (
            <Subtitle>
              {NOTIFICATION_CHANNELS_META[clientType].description}
            </Subtitle>
          )}
        </div>
      </Header>
      <Actions>
        {onTestNotification && (
          <Button
            variant="primary"
            fill="outline"
            disabled={isFormDirty}
            onClick={!isFormDirty ? onTestNotification : undefined}
            tooltip={isFormDirty ? 'Save changes to test notification' : ''}
          >
            Send test notification
          </Button>
        )}
        <InlineSwitch
          transparent
          className={cx(
            'toggle-notification-script',
            isSwitchDisabled && 'disabled'
          )}
          showLabel
          label="Advanced mode"
          disabled={isSwitchDisabled}
          value={isAdvancedToggled}
          onChange={handleToggleAdvanced}
        />
      </Actions>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing(3)};
`

const Header = styled.div`
  display: flex;
  align-items: center;

  > img,
  svg {
    margin-right: ${spacing(2)};
  }
`

const Title = styled.span`
  font-size: ${fontSize('h4')};
  display: block;
`

const Subtitle = styled.span`
  font-size: ${fontSize('bodySmall')};
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(2)};
  .toggle-notification-script {
    // Looks better close to the buttons
    height: 36px;
    &.disabled > div > label {
      background: ${color('border', 'strong')};
      border-color: ${color('border', 'strong')};
    }
  }
`
