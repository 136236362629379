import { ScheduledTest } from 'types/scheduledTests'
import { formatDate, resolveTimeZone } from 'utils/date'
import { defaultSortFunc, SortOrder } from 'components/DataTable'
import { addPlural } from 'utils'

const DAYS_IN_A_WEEK = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
const SCHEDULE_FREQUENCY = {
  never: 'never',
  daily: 'daily',
  monthly: 'monthly',
  weekly: 'weekly',
  hourly: 'hourly',
}

const intervals = {
  [SCHEDULE_FREQUENCY.hourly]: 'hour',
  [SCHEDULE_FREQUENCY.daily]: 'day',
  [SCHEDULE_FREQUENCY.monthly]: 'month',
}

export function isActive({ active, starts }: ScheduledTest) {
  return active && starts !== null
}

export function nextRunSort(
  a: ScheduledTest,
  b: ScheduledTest,
  sortOrder: SortOrder
) {
  const aNextRun = getNextRunValue(a)
  const bNextRun = getNextRunValue(b)

  return alwaysSortNullLast(aNextRun, bNextRun, sortOrder)
}

function getNextRunValue(scheduledTest: ScheduledTest) {
  if (!isActive(scheduledTest) || !scheduledTest.next_run) {
    return null
  }

  return scheduledTest.next_run
}

export function frequencySort(
  a: ScheduledTest,
  b: ScheduledTest,
  sortOrder: SortOrder
) {
  const aFrequency = getFrequencySortValue(a)
  const bFrequency = getFrequencySortValue(b)

  return alwaysSortNullLast(aFrequency, bFrequency, sortOrder)
}

function getFrequencySortValue(scheduledTest: ScheduledTest) {
  const { frequency, interval } = scheduledTest
  // we want to double sort by frequency and interval but we can't run a double sort
  // so we prepend the interval to ensure it doesn't affect the primary frequency sort

  if (!isActive(scheduledTest)) {
    return null
  }

  if (frequency === SCHEDULE_FREQUENCY.hourly) {
    return 0 + Number(`0.${interval}`)
  }

  if (frequency === SCHEDULE_FREQUENCY.daily) {
    return 1 + Number(`0.${interval}`)
  }

  if (frequency === SCHEDULE_FREQUENCY.weekly) {
    return 2 + Number(`0.${interval}`)
  }

  if (frequency === SCHEDULE_FREQUENCY.monthly) {
    return 3 + Number(`0.${interval}`)
  }

  if (!frequency) {
    return 4
  }

  return null
}

function alwaysSortNullLast<T>(a: T, b: T, sortOrder: SortOrder) {
  if (a === b) {
    return 0
  }

  if (a === null) {
    return 1
  }

  if (b === null) {
    return -1
  }

  return defaultSortFunc(a, b, sortOrder)
}

export function endsSort(
  a: ScheduledTest,
  b: ScheduledTest,
  sortOrder: SortOrder
) {
  const aEnds = getEndsSortValue(a)
  const bEnds = getEndsSortValue(b)

  return alwaysSortNullLast(aEnds, bEnds, sortOrder)
}

function getEndsSortValue(scheduledTest: ScheduledTest) {
  const { ends, frequency } = scheduledTest
  const { datetime, type } = ends

  if (frequency === null || !isActive(scheduledTest)) {
    return null
  }

  if (type === 'occurrences') {
    return 0
  }

  if (type === 'datetime' && datetime) {
    return 1
  }

  if (type === 'never') {
    return 2
  }

  return null
}

export function getFrequencyValue(scheduledTest: ScheduledTest) {
  const { frequency, interval, weekly } = scheduledTest

  if (!isActive(scheduledTest)) {
    return null
  }

  if (!frequency) {
    return 'Run once'
  }

  switch (frequency) {
    case SCHEDULE_FREQUENCY.hourly:
    case SCHEDULE_FREQUENCY.daily:
    case SCHEDULE_FREQUENCY.monthly: {
      if (interval === 1) {
        return `Every ${intervals[frequency]}`
      }

      return `Every ${interval} ${intervals[frequency]}s`
    }
    case SCHEDULE_FREQUENCY.weekly: {
      const days =
        [...weekly.days]
          .sort()
          .map((day) => DAYS_IN_A_WEEK[day])
          .join(', ') || 'week'

      const allDays = weekly.days.length === 7

      if (interval === 1) {
        if (allDays) {
          return 'Every day'
        }

        return `Every ${days}`
      }

      if (allDays) {
        return `Every ${interval} weeks on every day`
      }

      return `Every ${interval} weeks on ${days}`
    }
    default: {
      return null
    }
  }
}

export function getEndsValue(scheduledTest: ScheduledTest) {
  const { ends, frequency } = scheduledTest
  const { type, occurrences, datetime } = ends

  if (frequency === null) {
    return null
  }

  if (type === 'never') {
    return 'Never'
  }

  if (type === 'occurrences' && occurrences) {
    return addPlural(`${occurrences} run`, occurrences)
  }

  if (type === 'datetime' && datetime) {
    return formatDate(datetime, resolveTimeZone())
  }

  return null
}
