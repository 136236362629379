import React, { PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'
import { spacing } from 'utils/styled'
import { Flex } from '../Flex'
import { IconButton, IconButtonProps } from '../IconButton'

const StyleContainer = styled.div`
  padding-bottom: ${spacing(3)};
`

const StyledSection = styled(Flex)`
  gap: ${spacing(2)};
  flex-direction: column;
  margin-bottom: ${spacing(3)};

  p {
    margin-bottom: ${spacing(0.5)};
  }
`

const StyledInstructionsList = styled.ol`
  padding-left: ${spacing(2.5)};
  list-style: none;
  counter-reset: step;
`

const StyledStep = styled.li`
  position: relative;
  padding-bottom: ${spacing(3)};
  padding-left: ${spacing(3)};
  counter-increment: step;

  h3 {
    position: relative;
  }

  &:before {
    content: counters(step, '.') ' ';

    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-12px, 1px);
    z-index: 1;
    font-size: 20px;
    line-height: 1;
  }

  &:after {
    content: '';

    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: ${({ theme }) => theme.colors.background.canvas};
    transform: translate(-21.5px, -4px);
  }
`

const StyledInstructionsBlock = styled.div`
  padding-bottom: ${spacing(3)};

  > div {
    h5 {
      margin: ${spacing(3)} 0 ${spacing(1)};
    }

    p {
      margin: ${spacing(2)} 0 ${spacing(1)};
    }

    ul,
    ol {
      padding-left: ${spacing(2.5)};
      margin-bottom: ${spacing(1)};
    }
  }
`

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: ${spacing(2)};

  button {
    z-index: 1;
  }
`

const TabsSeparator = styled.div`
  position: absolute;
  bottom: 0;
  width: calc(100% + ${spacing(4)});
  height: ${spacing(0.5)};
  margin-left: ${spacing(-2)};
  background-color: ${({ theme }) => theme.colors.border.weak};
`

const TitleContainerStyled = styled.div`
  position: relative;
  margin-bottom: ${spacing(3)};
`

interface SectionProps {
  title?: ReactNode
  children: ReactNode
}

interface StepProps {
  title: ReactNode
  children?: ReactNode
}

interface InstructionsProps {
  children: ReactNode
}

const Section = ({ title, children }: SectionProps) => {
  return (
    <StyledSection>
      {title && <h4>{title}</h4>}
      {children}
    </StyledSection>
  )
}

const Step = ({ title, children }: StepProps) => {
  return (
    <StyledStep>
      <h4>{title}</h4>
      {children}
    </StyledStep>
  )
}

export const Instructions = ({ children }: InstructionsProps) => {
  return <StyledInstructionsList>{children}</StyledInstructionsList>
}

const Block = ({ title, children }: SectionProps) => {
  return (
    <StyledInstructionsBlock>
      <h4>{title}</h4>
      <div>{children}</div>
    </StyledInstructionsBlock>
  )
}

const CloseButton = (props: IconButtonProps) => (
  <CloseButtonWrapper>
    <IconButton {...props} />
  </CloseButtonWrapper>
)
const TitleContainer = ({ children }: PropsWithChildren) => (
  <TitleContainerStyled>
    {children}
    <TabsSeparator />
  </TitleContainerStyled>
)

Instructions.Container = StyleContainer
Instructions.Step = Step
Instructions.Section = Section
Instructions.Block = Block
Instructions.TitleContainer = TitleContainer
Instructions.CloseButton = CloseButton
