import React from 'react'
import { getValueFormat } from '@grafana/data'

import { toUnit } from 'utils/units'
import { TrendingMetricConfig, TrendingMetric } from 'types'

interface TrendCellProps {
  metrics: TrendingMetric[]
  trend: TrendingMetricConfig
}

export const TrendCell = ({ metrics, trend }: TrendCellProps) => {
  const metric = metrics.find((metric) => metric.id === trend.id)

  if (!metric?.calculated) {
    return <>?</>
  }

  if (metric === undefined || metric.value === null) {
    return <>{`-`}</>
  }

  const unit = toUnit({
    metric: metric.metric_name,
    method: metric.aggregation_function,
  })

  const formatter = getValueFormat(unit)
  const value = formatter(metric.value)

  return (
    <>
      {[value.prefix, value.text, value.suffix]
        .filter((value) => value !== undefined)
        .join(' ')}
    </>
  )
}
