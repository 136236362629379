import React from 'react'
import styled from 'styled-components'
import { IconButton } from '@grafana/ui'

import { color, spacing } from 'utils/styled'
import { Flex } from 'components/Flex'

interface BannerProps {
  onDismiss: () => void
}

export const Banner = ({ onDismiss }: BannerProps) => {
  return (
    <Container justify="space-between" align="center" gap={1}>
      <Text>
        Access environment variables by using <code>__ENV</code> global variable
        in the script editor
      </Text>
      <IconButton
        name="times"
        aria-label="Close message"
        tooltip="Close message"
        onClick={onDismiss}
      />
    </Container>
  )
}

const Container = styled(Flex)`
  line-height: 1.1;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: ${spacing(2)};
  background-color: ${color('background', 'primary')};
  width: max-content;

  &:before {
    content: '';
    background-color: ${({ theme }) =>
      theme.isDark ? 'transparent' : color('border', 'weak')};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`

const Text = styled.p`
  margin-bottom: 0;
  position: relative;
  z-index: 1;
`
