import { Button } from 'components/Button'
import React from 'react'
import { FullWidthRow, TableCell } from '../Table'

interface ShowMorePaginatorProps {
  hasMore: boolean
  isFetching: boolean
  onShowMore: () => void
}

export function ShowMorePaginator({
  hasMore,
  isFetching,
  onShowMore,
}: ShowMorePaginatorProps) {
  if (!hasMore) {
    return null
  }

  const handleShowMore = () => {
    if (isFetching) {
      return
    }

    onShowMore()
  }

  return (
    <FullWidthRow>
      <TableCell>
        <Button
          size="sm"
          fill="outline"
          variant="secondary"
          icon={isFetching ? 'fa fa-spinner' : 'arrow-down'}
          onClick={handleShowMore}
        >
          Show more...
        </Button>
      </TableCell>
    </FullWidthRow>
  )
}
