import React from 'react'
import { Alert } from '@grafana/ui'

import { GENERIC_API_ERROR_MESSAGE } from 'constants/errors'
import { useIsUserOrgAdmin } from 'data/usePermissions'

import { useQueryEnvironmentVariables } from './hooks'
import { EnvVariablesTable } from './components/EnvVariablesTable'
import { CreateEnvVariableButton } from './components/CreateEnvVariableButton'

import { Section } from 'components/Section'
import { DocLink, docs } from 'components/DocLink'

export const EnvVariablesTab = () => {
  const isUserOrgAdmin = useIsUserOrgAdmin()
  const { data, error, isLoading } = useQueryEnvironmentVariables()

  return (
    <Section
      title={
        <Section.ProtectedTitle
          title="Environment variables"
          hasPermissions={isUserOrgAdmin}
        />
      }
      description={
        <>
          Environment Variables are key-value pairs that you configure at the
          stack level. Using environment variables reduces data exposure and is
          an easy way to re-use and change values across different test scripts.
          Read more about environment variables in the{' '}
          <DocLink article={docs.manage.environmentVariables}>docs</DocLink>.
        </>
      }
      actions={isUserOrgAdmin && !isLoading && <CreateEnvVariableButton />}
    >
      {error ? (
        <Alert title={GENERIC_API_ERROR_MESSAGE} />
      ) : (
        <EnvVariablesTable data={data} isLoading={isLoading} />
      )}
    </Section>
  )
}
