import React, { useEffect, useMemo, useState } from 'react'

import { LoadingContainer } from 'components/LoadingContainer'
import { useScenarios } from 'data/useScenarios'
import { TestRunAnalysis } from 'types'
import { ProtocolType } from '../RunOverview.types'
import { filterScenariosByProtocol } from './Scenarios.utils'
import { ZeroState, ZeroStateHint } from './ZeroState'
import { Switch } from 'components/Switch'
import { ScenarioTable, ScenarioTableProps } from './ScenarioTable'
import { Controls, Line } from './Scenarios.styles'

interface ScenarioSummaryProps {
  analysis: TestRunAnalysis
  protocol: ProtocolType
}

export function Scenarios({ analysis, protocol }: ScenarioSummaryProps) {
  const { isLoading, data } = useScenarios(analysis.main, {
    keepPreviousData: true,
  })

  const [showScenarios, setShowScenarios] = useState<boolean>()

  const handleToggle = () => {
    setShowScenarios((isOn) => !isOn)
  }

  const scenarios = useMemo(
    () => filterScenariosByProtocol(data?.value, protocol),
    [data?.value, protocol]
  )

  const showZeroState =
    data?.value.length === 0 ||
    data?.value.every((scenario) => scenario.name === 'default')

  const showZeroStateHint = showZeroState && !showScenarios

  // Show 0 when only default scenario is present
  const scenarioCount = showZeroState ? 0 : scenarios.length

  // Switch toggle on when there's more than 1 scenario and user
  // haven't manually switched it off
  useEffect(() => {
    if (scenarios.length > 1) {
      setShowScenarios((isOn) => (isOn === undefined ? true : isOn))
    }
  }, [scenarios])

  return (
    <LoadingContainer loading={isLoading}>
      <Controls>
        <h2>Scenarios ({scenarioCount})</h2>
        <Line />
        <Switch
          aria-label="Show scenarios?"
          value={showScenarios}
          onChange={handleToggle}
          offLabel="HIDE"
          onLabel="SHOW"
        />
      </Controls>

      {showZeroStateHint && <ZeroStateHint onClick={handleToggle} />}

      {showScenarios && (
        <ScenarioContent
          showZeroState={showZeroState}
          analysis={analysis}
          protocol={protocol}
        />
      )}
    </LoadingContainer>
  )
}

interface ScenarioContentProps extends ScenarioTableProps {
  showZeroState?: boolean
}

const ScenarioContent = ({
  showZeroState,
  ...tableProps
}: ScenarioContentProps) => {
  if (showZeroState) {
    return <ZeroState />
  }

  return <ScenarioTable {...tableProps} />
}
