import React, { useState } from 'react'
import { Button } from '@grafana/ui'

import { CreateEnvVariableModal } from './EnvVariableModal/EnvVariableModal'

export function CreateEnvVariableButton() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>New variable</Button>
      <CreateEnvVariableModal
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
      />
    </>
  )
}
