import { useQueries, UseQueryResult } from '@tanstack/react-query'
import { toTestRunQueryKey } from 'data/queryKeys'
import { useBackgroundTasks } from './useBackgroundTasks'
import { isTestRunTask } from 'types'
import { FetchByIdResponse, useTestRunClient } from './clients/testRuns'

export const useActiveTestRuns = (): Array<
  UseQueryResult<FetchByIdResponse>
> => {
  const testRuns = useTestRunClient()
  const { data = [] } = useBackgroundTasks()

  return useQueries({
    queries: data.filter(isTestRunTask).map((task) => ({
      queryKey: toTestRunQueryKey(task.test_run_id),
      queryFn: () => testRuns.fetchById(task.test_run_id),
    })),
  })
}
