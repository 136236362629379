import { InsightScore, type InsightScoreStatus } from 'types/cloudInsights'
import { type PropsWithTheme } from 'utils/styled'

import { getScoreStatus } from './insight'

export const getScoreThemeColor = ({
  status,
  theme,
}: PropsWithTheme & { status: InsightScoreStatus }) => {
  if (status === 'skipped') {
    return theme.colors.text.secondary
  }

  if (status === 'good') {
    return theme.colors.success.main
  }

  if (status === 'needs-improvement') {
    return theme.colors.warning.main
  }

  return theme.colors.error.main
}

interface GetScoreColorProps extends PropsWithTheme {
  score?: InsightScore
}

export const getScoreColor = ({ score, theme }: GetScoreColorProps) => {
  const status = getScoreStatus(score)

  return getScoreThemeColor({ status, theme })
}
