import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useMemo,
} from 'react'
import { Link } from 'react-router-dom'
import { Alert, VerticalGroup } from '@grafana/ui'
import { getDataSourceSrv } from '@grafana/runtime'

import { K6DataSource } from 'datasource/datasource'

import { DATASOURCE_TYPE } from 'constants/index'
import { PluginPagePlaceholder } from 'components/PluginPagePlaceholder'
import { useGrafanaRole } from 'hooks/useGrafanaRBAC'

type DatasourceContextType = {
  ds: K6DataSource
}

interface DatasourceContextProviderProps {
  children: ReactNode
}

const DatasourceContext = createContext<undefined | DatasourceContextType>(
  undefined
)

const srv = getDataSourceSrv()

export function useDataSourceSrv() {
  const instanceSettings = srv
    .getList()
    .find((instance) => instance.type === DATASOURCE_TYPE)

  const [isLoading, setIsLoading] = useState(false)
  const [dataSourceSrv, setDataSourceSrv] = useState<
    K6DataSource | null | undefined
  >(undefined)

  useEffect(() => {
    async function loadDataSource() {
      try {
        setIsLoading(true)

        if (!instanceSettings) {
          setDataSourceSrv(null)

          return
        }

        const ds = await srv.get(instanceSettings?.uid)
        setDataSourceSrv(ds as K6DataSource)
      } catch (error) {
        setDataSourceSrv(null)
      } finally {
        setIsLoading(false)
      }
    }

    loadDataSource()
  }, [instanceSettings])

  return useMemo(() => {
    return {
      dataSourceSrv,
      // undefined means that we haven't tried to load it yet
      isLoading: dataSourceSrv === undefined || isLoading,
    }
  }, [dataSourceSrv, isLoading])
}

export const DatasourceAccessDeniedErrorState = () => {
  const role = useGrafanaRole()

  return (
    <PluginPagePlaceholder>
      <Alert title="You don't have access to the datasource." severity="error">
        <VerticalGroup>
          <div>
            {`To gain access ask your administrator to add "Query" permissions to ${role} role in`}{' '}
            <Link
              style={{ textDecoration: 'underline' }}
              to="/connections/datasources/edit/Grafana%20Cloud%20k6"
            >
              datasource permissions
            </Link>
            .
          </div>
        </VerticalGroup>
      </Alert>
    </PluginPagePlaceholder>
  )
}

export const DatasourceContextProvider = ({
  children,
}: DatasourceContextProviderProps) => {
  const { dataSourceSrv: ds, isLoading } = useDataSourceSrv()

  if (isLoading) {
    return <PluginPagePlaceholder />
  }

  if (ds === null || ds === undefined) {
    return <DatasourceAccessDeniedErrorState />
  }

  return (
    <DatasourceContext.Provider value={{ ds }}>
      {children}
    </DatasourceContext.Provider>
  )
}

interface StaticDatasourceContextProviderProps {
  datasource: K6DataSource
  children: ReactNode
}

export const StaticDatasourceContextProvider = ({
  datasource: ds,
  children,
}: StaticDatasourceContextProviderProps) => {
  return (
    <DatasourceContext.Provider value={{ ds }}>
      {children}
    </DatasourceContext.Provider>
  )
}

export const useDatasource = () => {
  const context = useContext(DatasourceContext)

  if (context === undefined) {
    throw new Error(
      'useDatasource must be used within a DatasourceContextProvider'
    )
  }

  return context
}
