import React from 'react'
import { Label, MultiSelect } from '@grafana/ui'
import { union, uniqBy } from 'lodash'
import { SelectableValue } from '@grafana/data'
import styled from 'styled-components'

import { Flex } from 'components/Flex'
import { useLoadZones } from 'data/useLoadZones'
import { StaticIP, StaticIPStatus } from 'types'

import { StaticIPsLoadZoneLabel } from '../StaticIPsLoadZoneLabel'
import { StaticIPsStatusLabel } from '../StaticIPsStatusLabel'
import { STATIC_IP_ERROR_STATUSES } from '../StaticIPs.constants'

interface Props {
  data: StaticIP[] | undefined
  onLoadZoneSelect: (loadZones: string[]) => void
  onStatusSelect: (status: StaticIPStatus[]) => void
}

export const StaticIPsListFilters = ({
  data = [],
  onLoadZoneSelect,
  onStatusSelect,
}: Props) => {
  const { data: loadZones = [] } = useLoadZones()

  const loadZonesOptions = uniqBy(data, 'load_zone_identifier').map(
    (staticIP) => ({
      value: staticIP.load_zone_identifier,
      label: (
        <StaticIPsLoadZoneLabel
          loadZone={
            loadZones.find(
              (lz) => lz.k6_load_zone_id === staticIP.load_zone_identifier
            )!
          }
        />
      ),
    })
  )

  const statusOptions = uniqBy(data, 'provisioning_status').map((staticIP) => ({
    value: staticIP.provisioning_status,
    label: <StaticIPsStatusLabel status={staticIP.provisioning_status} />,
  }))

  // We don't differentiate between PROVISIONING_ERROR, PROVISIONING_ERROR_QUOTA_REACHED
  // RELEASING_ERROR errors in the UI, so just show ERROR status for all of them
  const handleOnStatusChange = (
    options: Array<SelectableValue<StaticIPStatus>>
  ) => {
    const values = options.map((option) => option.value!)
    const hasError = values.some((value) =>
      STATIC_IP_ERROR_STATUSES.includes(value)
    )

    onStatusSelect(hasError ? union(values, STATIC_IP_ERROR_STATUSES) : values)
  }

  return (
    <Container>
      <Flex gap={1} wrap="wrap">
        <Flex align="flex-start" gap={0.5} direction="column">
          <Label>Load zone</Label>
          <MultiSelect
            onChange={(e) => {
              onLoadZoneSelect(e.map((option) => option.value!))
            }}
            // @ts-expect-error: Label can be a react element
            options={loadZonesOptions}
            className="multiple-select"
          />
        </Flex>

        <Flex align="flex-start" gap={0.5} direction="column">
          <Label>Status</Label>
          <MultiSelect
            onChange={handleOnStatusChange}
            // @ts-expect-error: Label can be a react element
            options={statusOptions}
            className="multiple-select"
          />
        </Flex>
      </Flex>
    </Container>
  )
}

const Container = styled.div`
  .multiple-select {
    min-width: 200px;
    height: 30px;
  }
`
