import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { MultiSelect, Select, Field } from '@grafana/ui'
import { omit } from 'lodash-es'

import { PROJECT_ROLE_TYPES, PROJECT_TYPE_CHOICES } from 'constants/roles'
import { useOrgRoles } from 'data/useOrgRoles'
import { showAlert } from 'utils/showAlert'

import {
  useCreateProjectRole,
  useProjectRoleEmails,
} from 'data/useProjectRoles'
import { ProjectId } from 'types'

export const InviteUsersForm = ({
  projectId,
  children,
  onSubmit,
}: {
  projectId: ProjectId
  children: (props: { isSubmitting: boolean }) => ReactNode
  onSubmit: () => void
}) => {
  const { options, isLoading } = useOptions(projectId)
  const { mutateAsync: createProjectRole } = useCreateProjectRole()

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onChange',
    defaultValues: { emails: [] as any, role: '' as any },
  })

  const emailSelect = omit(
    register('emails', {
      required: { value: true, message: 'Please select at least one email' },
    }),
    ['onBlur', 'onChange']
  )

  const roleSelect = omit(
    register('role', {
      required: { value: true, message: 'Please select a role' },
    }),
    ['onBlur', 'onChange']
  )

  const handleUpdate = async (values: {
    role: PROJECT_ROLE_TYPES
    emails: Array<{ label: string; value: number }>
  }) => {
    try {
      await Promise.all(
        values.emails.map((email) =>
          createProjectRole({
            role_id: values.role,
            project_id: projectId,
            user_id: email.value,
          })
        )
      )

      showAlert('Successfully added project members', 'success')
      onSubmit()
    } catch (err) {
      showAlert(
        'Failed to add project members, please try again later',
        'error'
      )
    }
  }

  return (
    <Form onSubmit={handleSubmit(handleUpdate)}>
      <Field
        label="Email address"
        error={errors.emails?.message as string}
        invalid={!!errors.emails}
      >
        <MultiSelect
          loadingMessage=""
          value={getValues().emails}
          options={options}
          isLoading={isLoading}
          onChange={(e) => {
            clearErrors('emails')
            setValue('emails', e)
          }}
          onCreateOption={(value) =>
            setValue('emails', [...getValues().emails, value])
          }
          placeholder="Select one or more emails"
          {...emailSelect}
        />
      </Field>

      <Field
        label="Role"
        error={errors.role?.message as string}
        invalid={!!errors.role}
      >
        <Select
          options={PROJECT_TYPE_CHOICES}
          placeholder="Select permission role"
          onChange={(value) => {
            clearErrors('role')
            setValue('role', value.value)
          }}
          {...roleSelect}
        />
      </Field>

      {children({ isSubmitting })}
    </Form>
  )
}

const useOptions = (projectId: ProjectId) => {
  const { data: orgRoles = [], isLoading: isOrgRolesLoading } = useOrgRoles()
  const { data: projectRoles = [], isLoading: isProjectRolesLoading } =
    useProjectRoleEmails(projectId)

  // Filter out users who are already members of the project
  const options = orgRoles
    .filter(
      (role) =>
        !projectRoles?.find(
          (projectRole) => projectRole.user_id === role.user_id
        )
    )
    .map((role) => ({ label: role.user_email, value: role.user_id }))

  return {
    options,
    isLoading: isOrgRolesLoading || isProjectRolesLoading,
  }
}

const Form = styled.form`
  margin-top: ${({ theme }) => theme.spacing(4)};
`
