import React from 'react'

import { DocLink, docs } from 'components/DocLink'
import { ConfirmModal, ConfirmModalProps } from 'components/ConfirmModal'

export const SetAsBaseline = (
  props: Pick<
    ConfirmModalProps,
    'isOpen' | 'isPending' | 'onConfirm' | 'onDismiss'
  >
) => {
  return (
    <ConfirmModal
      {...props}
      confirmButtonVariant="primary"
      title="Set this test run as baseline"
      body={
        <>
          <p>
            A <b>baseline test run</b> is useful when doing continuous testing
            of your application performance.
          </p>
          <p>
            Continuous or regression testing is a type of testing that ensures
            that a previous version of your application still performs the same
            way after your application or system change.
          </p>
          <p>
            You might want to set a <b>baseline test run</b> when your system
            reaches your performance expectations to compare it against later
            test runs to find how the performance of your application changes.
          </p>
          <p>
            <DocLink article={docs.insights.testComparison}>
              Read more about how to compare test results
            </DocLink>
          </p>
          <p>
            Setting a test run as <b>baseline test run</b> will make the results
            non-deletable by{' '}
            <DocLink article={docs.subscription.dataRetention}>
              data retention policy
            </DocLink>
          </p>
        </>
      }
      confirmText="Set as baseline"
    />
  )
}
