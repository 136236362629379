import React from 'react'
import styled from 'styled-components'

import { AuditTrail } from 'types/auditTrail'
import { formatDate } from 'utils/date'
import { DataTable, type SortOrder } from 'components/DataTable'
import { InfiniteScroll } from 'components/InfiniteScroll'
import { Tooltip } from 'components/Tooltip'
import { LoadingContainer } from 'components/LoadingContainer'

export type AuditTrailTableProps = {
  defaultSortOrder?: SortOrder
  defaultSortFieldId?: string
  data: AuditTrail[]
  isFetching: boolean
  isReady: boolean
  onNextPage: () => void
  onSort: (orderBy?: string) => void
}

const TooltipText = styled.span`
  cursor: pointer;
`

const columns = [
  {
    name: 'CREATED',
    grow: 1,
    sortable: true,
    sortField: 'created',
    cell: (log: AuditTrail) => <span>{formatDate(log.created)}</span>,
  },
  {
    name: 'TRIGGERED BY',
    grow: 2,
    cell: (log: AuditTrail) => (
      <Tooltip
        content={log.triggered_by_textual}
        interactive
        placement="top-start"
      >
        <TooltipText>{log.triggered_by_textual}</TooltipText>
      </Tooltip>
    ),
  },
  {
    name: 'EVENT',
    grow: 3,
    cell: (log: AuditTrail) => (
      <Tooltip content={log.event_textual} interactive placement="top-start">
        <TooltipText>{log.event_textual}</TooltipText>
      </Tooltip>
    ),
  },
  {
    name: 'IP ADDRESS',
    grow: 1,
    sortable: true,
    sortField: 'ip_address',
    cell: (log: AuditTrail) => <span>{log.ip_address}</span>,
  },
]

export const AuditTrailTable = ({
  data = [],
  defaultSortOrder,
  defaultSortFieldId,
  isFetching,
  isReady,
  onNextPage,
  onSort,
}: AuditTrailTableProps) => {
  return (
    <LoadingContainer loading={!isReady}>
      <InfiniteScroll isLoading={isFetching} loadNext={onNextPage}>
        <DataTable
          columns={columns}
          data={data}
          defaultSortOrder={defaultSortOrder}
          defaultSortFieldId={defaultSortFieldId}
          isFetching={isFetching}
          onSort={onSort}
          sortServer
        />
      </InfiniteScroll>
    </LoadingContainer>
  )
}
