import React, { useState } from 'react'
import { Icon } from '@grafana/ui'

import { type ScheduledTest } from 'types/scheduledTests'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { EditScheduledTestModal } from 'components/ScheduledTests/ScheduledTestsModal'

export const UpdateScheduleButton = ({
  scheduledTest,
}: {
  scheduledTest: ScheduledTest
}) => {
  const [isScheduledTestModalOpen, setIsScheduledTestModalOpen] =
    useState(false)

  return (
    <div>
      <Button
        fill="text"
        onClick={() => {
          setIsScheduledTestModalOpen(true)
        }}
        variant="secondary"
      >
        <Flex gap={1} align="center">
          <Icon name="edit" />
          {scheduledTest.active ? 'Edit schedule' : 'Set up schedule'}
        </Flex>
      </Button>
      <EditScheduledTestModal
        onDismiss={() => setIsScheduledTestModalOpen(false)}
        isOpen={isScheduledTestModalOpen}
        scheduledTest={scheduledTest}
      />
    </div>
  )
}
