import { TestRun } from 'types'
import { ScenariosPayload } from 'datasource/datasource'
import { useDatasource } from 'datasourceContext'

import { toScenariosQueryKey } from './queryKeys'
import { QueryOptions } from './queryClient'
import { useStreamingQuery } from 'hooks/useStreamingQuery'

export const useScenarios = (
  testRun: TestRun,
  options?: QueryOptions<ScenariosPayload>
) => {
  const { ds } = useDatasource()

  return useStreamingQuery<ScenariosPayload>(testRun, {
    queryKey: toScenariosQueryKey(testRun.id),
    queryFn: () => ds.fetchScenarios(testRun.id),
    ...options,
  })
}
