import { isEqual, sum } from 'lodash'

import { LoadZone } from 'types/loadZone'
import { StaticIPsCalculatorFormValues } from '../StaticIPsCalculator.types'

export const getLoadZoneIdsFromFormValues = (
  loadZones: StaticIPsCalculatorFormValues['loadZones']
) => loadZones.map((c) => c.loadZone)

export const getEvenLoadZoneDistribution = (loadZoneLength: number) => {
  const accumulator = { total: 0, totalRounded: 0 }

  return Array(loadZoneLength)
    .fill(100 / loadZoneLength)
    .map((value) => {
      const total = accumulator.total + value
      const roundTotal = Math.round(total)
      const rounded = roundTotal - accumulator.totalRounded
      accumulator.total = total
      accumulator.totalRounded += rounded
      return rounded
    })
}

export const getNextLoadZone = (
  loadZonesValues: StaticIPsCalculatorFormValues['loadZones'],
  loadZones?: LoadZone[]
) =>
  (loadZones ?? [])
    .filter((loadZone) => loadZone.public)
    .find(
      (loadZone) =>
        !loadZonesValues
          .map((value) => value.loadZone)
          .includes(loadZone.k6_load_zone_id)
    )

export const getLoadZoneFormValuesDistributionSum = (
  loadZoneFormValues: StaticIPsCalculatorFormValues['loadZones']
) => sum(loadZoneFormValues.map((l) => +l.distribution))

export const hasUserChangedDistribution = (
  loadZonesFormValues: StaticIPsCalculatorFormValues['loadZones']
) =>
  !isEqual(
    loadZonesFormValues.map((loadZone) => +loadZone.distribution),
    getEvenLoadZoneDistribution(loadZonesFormValues.length)
  )
