import type { LoadZone } from 'types/loadZone'

const sortList = (sort: string | undefined) => (a: LoadZone, b: LoadZone) => {
  if (sort?.includes('asc')) {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
  }

  if (sort?.includes('desc')) {
    return a.name > b.name ? -1 : a.name < b.name ? 1 : 0
  }

  return a.public === b.public ? 0 : a.public ? 1 : -1
}

export const getList = (
  data: LoadZone[],
  sort: string | undefined,
  isPublicZonesVisible?: boolean
) => {
  if (isPublicZonesVisible) {
    return [...data].sort(sortList(sort))
  }

  return data.filter((loadZone) => !loadZone.public).sort(sortList(sort))
}
