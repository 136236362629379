import { isNumber } from 'lodash-es'
import { intervalToDuration, parseISO, toDate } from 'date-fns'

import { DateLike } from 'types'
import { LoadGeneratorInstance } from 'types/insights/health'
import { TimeSeriesUnit } from 'types/panels'

import { isMultipleOf, toPrecise } from './math'
import { formatUnit } from './units'

export const responseTimeFormatter = (responseTimeMS: number) => {
  const precision = responseTimeMS > 10 ? 0 : 2
  const fixedNumber = Number(Number(responseTimeMS).toFixed(precision))

  return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const separatorFormatter = (
  num?: number | null,
  precision = 0,
  delimiter = ' '
) => {
  if (num === undefined || num === null) {
    return ''
  }
  return String(toPrecise(num, precision)).replace(
    /\B(?=(\d{3})+(?!\d))/g,
    delimiter
  )
}

export const vusFormatter = (vus: number) => {
  if (vus === 0) {
    return `-`
  }

  if (vus === 1) {
    return `${vus} VU`
  }

  return `${separatorFormatter(vus)} VUs`
}

export const unitFormatter = (num: number, precision = 1) => {
  if (!isNumber(num)) {
    return ''
  }

  if (num / 1000000 >= 1) {
    return isMultipleOf(1000000, num)
      ? `${num / 1000000}M`
      : `${toPrecise(num / 1000000, precision)}M`
  }

  if (num / 1000 >= 1) {
    return isMultipleOf(1000, num)
      ? `${num / 1000}K`
      : `${toPrecise(num / 1000, precision)}K`
  }

  return toPrecise(Math.ceil(num), precision).toString()
}

export const timeFormatter = (timeInSeconds = 0) => {
  const totalSeconds = Math.round(timeInSeconds)
  const seconds = Math.round(totalSeconds % 60)

  if (totalSeconds < 0) {
    return '-'
  }

  if (totalSeconds < 60) {
    return `${totalSeconds}s`
  }

  if (seconds > 0) {
    const minutes = Math.round((timeInSeconds - seconds) / 60)

    return `${minutes}min ${seconds}s`
  }

  return `${Math.round(totalSeconds / 60)}min`
}

// Format time in seconds to the k6 duration string (e.g. 1h, 1m, 1s, 1h1m1s, 1h1m, 1m1s).
export const timeFormatterShort = (timeInSeconds = 0) => {
  const duration = intervalToDuration({ start: 0, end: timeInSeconds * 1000 })
  const { hours, minutes, seconds } = duration

  const hoursString = hours ? `${hours}h` : ''
  const minutesString = minutes ? `${minutes}m` : ''
  const secondsString = seconds ? `${seconds}s` : ''

  return `${hoursString}${minutesString}${secondsString}`
}

interface QuantityOptions {
  delimiter?: string
  precision?: number
}

export const quantity = (value: number, opts: QuantityOptions = {}) => {
  const { delimiter = ' ', precision = 0 } = opts

  return separatorFormatter(value, precision, delimiter)
}

export const vus = (value: number) => {
  if (value === 1) {
    return `${quantity(value)} VU`
  }

  return `${quantity(value)} VUs`
}

interface TimingOptions extends QuantityOptions {
  unit?: string
}

export const timing = (value: number, opts: TimingOptions = {}) => {
  return formatUnit(
    value,
    TimeSeriesUnit.Milliseconds,
    calculatePrecision(value)
  )
}

const KILOBYTE = 1024
const MEGABYTE = 1024 * KILOBYTE
const GIGABYTE = 1024 * MEGABYTE
const TERABYTE = 1024 * GIGABYTE

const getBytePrecision = (value: number) => {
  if (value >= 100) {
    return 0
  }

  if (value >= 10) {
    return 1
  }

  return 2
}

export const data = (bytes: number) => {
  let value, abbrev

  if (bytes >= TERABYTE) {
    value = bytes / TERABYTE
    abbrev = 'TB'
  } else if (bytes >= GIGABYTE) {
    value = bytes / GIGABYTE
    abbrev = 'GB'
  } else if (bytes >= MEGABYTE) {
    value = bytes / MEGABYTE
    abbrev = 'MB'
  } else if (bytes >= KILOBYTE) {
    value = bytes / KILOBYTE
    abbrev = 'KB'
  } else {
    value = bytes
    abbrev = 'bytes'
  }

  value = Math.round(value * 100) / 100

  return `${toPrecise(value, getBytePrecision(value))} ${abbrev}`
}
export const dataRate = (bytes: number) => `${data(bytes)}/s`

interface DurationOptions {
  verbose?: boolean
  precision?: number
  milliseconds?: boolean
}

export const duration = (
  seconds: number,
  opts: DurationOptions | 'verbose' = {}
) => {
  const { verbose, precision }: DurationOptions =
    opts === 'verbose' ? { verbose: true } : opts

  const parts = [
    [Math.floor(seconds / 3600), 'hours'],
    [Math.floor((seconds % 3600) / 60), verbose ? 'minutes' : 'min'],
    [Math.floor(seconds % 60), verbose ? 'seconds' : 'sec'],
    [],
  ]

  const formatted = parts
    .filter((p) => p[0] !== 0)
    .slice(0, precision)
    .flatMap((p) => p)

  if (formatted.length === 0) {
    return `0 ${verbose ? 'seconds' : 'sec'}`
  }

  return formatted.join(' ')
}

const coerceDate = (date: DateLike) => {
  if (typeof date === 'string') {
    return parseISO(date)
  }

  return toDate(date)
}

interface TimeDifferenceOptions extends DurationOptions {}

export const timeDifference = (
  start: DateLike,
  end: DateLike,
  opts: TimeDifferenceOptions = {}
) => {
  const startDate = coerceDate(start)
  const endDate = coerceDate(end)

  const difference = endDate.getTime() - startDate.getTime()

  return duration(difference / 1000, opts)
}

const calculatePrecision = (value: number) => {
  if (value < 1) {
    return 2
  }

  if (value < 10) {
    return 1
  }

  return 0
}

interface RequestRateOptions extends QuantityOptions {
  unit?: 'full' | 'abbreviated' | 'none'
}

export const requestRate = (value: number, opts: RequestRateOptions = {}) => {
  const { precision = calculatePrecision(value), unit = 'abbreviated' } = opts
  const rate = quantity(value, {
    ...opts,
    precision,
  })

  if (unit === 'none') {
    return rate
  }

  if (unit === 'full') {
    return `${rate} requests/second`
  }

  return `${rate} reqs/s`
}

export const instanceName = (instance: LoadGeneratorInstance) =>
  `#${instance.id} (${instance.load_zone})`

interface PercentageOpts {
  precision?: number
}

export const percentage = (value: number, opts: PercentageOpts = {}) => {
  const { precision = 0 } = opts
  const percent = toPrecise(value * 100, precision)

  return `${percent}%`
}

export const withNumericSuffix = (numeric: string) => {
  return getOrdinalNumber(Number(numeric))
}

const pluralRules = new Intl.PluralRules('en-US', { type: 'ordinal' })

const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
])

export const getOrdinalNumber = (value: string | number): string => {
  const target = typeof value === 'string' ? Number(value) : value

  const rule = pluralRules.select(target)
  const suffix = suffixes.get(rule)

  return `${target}${suffix}`
}
