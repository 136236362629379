import React, { HTMLProps, ReactNode, useMemo, useState } from 'react'
import { IconButton, Input } from '@grafana/ui'
import { ClipboardIconButton } from 'components/ClipboardIconButton'
import styled from 'styled-components'

/**
 * Calculates the width of the revealable input based on its value or overridden width
 * Adds 8px per character with a 80px cushion to display the reveal and copy to clipboard buttons
 * */
const calculateWidth = (
  widthAuto: boolean,
  value: string | number,
  width?: number
) => {
  if (width) {
    return width
  }

  const valueLength = value.toString().length
  return widthAuto && valueLength !== 0 ? valueLength * 8 + 80 : undefined
}

interface RevealablePasswordProps
  extends Omit<HTMLProps<HTMLInputElement>, 'as' | 'prefix' | 'size' | 'ref'> {
  /** Enables/disables reveal action */
  isRevealEnabled?: boolean
  /** Sets the width to a multiple of 8px. Should only be used with inline forms. Setting width of the container is preferred in other cases.*/
  width?: number
  /** Sets the input width based on the number of characters at a rate of 9.5px/character.*/
  widthAuto?: boolean
  /** Show an invalid state around the input */
  invalid?: boolean
  /** Show an icon as a prefix in the input */
  prefix?: ReactNode
  /** Show an icon as a suffix in the input */
  suffix?: ReactNode
  /** Show a loading indicator as a suffix in the input */
  loading?: boolean
  /** Add a component as an addon before the input  */
  addonBefore?: ReactNode
  /** Add a component as an addon after the input */
  addonAfter?: ReactNode
  value?: string | number
}

export function RevealablePassword({
  isRevealEnabled = true,
  value = '',
  width,
  widthAuto = true,
  ...props
}: RevealablePasswordProps) {
  const [reveal, setReveal] = useState(false)

  const widthAdjusted = useMemo(
    () => calculateWidth(widthAuto, value, width),
    [widthAuto, value, width]
  )

  const { type, suffix } = useMemo(() => {
    // In app.k6.io we show the current state.
    // eye-slash when value is hidden and eye when value is visible
    // Alt. would be to show an icon that reflects what happens when you click the button,
    // e.g. eye to show value (instead of eye-slash to show value)
    return {
      type: reveal ? 'text' : 'password',
      suffix: (
        <>
          {isRevealEnabled && (
            <IconButton
              tooltip={reveal ? 'Hide value' : 'Show value'}
              name={reveal ? 'eye' : 'eye-slash'}
              onClick={() => setReveal(!reveal)}
            />
          )}
          <ClipboardIconButton disabled={!value} data={value.toString()} />
        </>
      ),
    }
  }, [value, reveal, isRevealEnabled])

  return (
    <RevealableContainer width={widthAdjusted}>
      <StyledInput
        {...props}
        value={value}
        type={type}
        suffix={!props.loading && suffix}
      />
    </RevealableContainer>
  )
}

const RevealableContainer = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : '100%')} !important;
`

const StyledInput = styled(Input)`
  input[readonly] {
    background-color: ${({ theme }) => theme.components.input.background};
  }
`
