import React from 'react'
import { Span } from './types'
import { Tooltip } from '@grafana/ui'
import styled from 'styled-components'
import { color, spacing } from '../../utils/styled'
import { getAttributeValue } from './BrowserTimeline.utils'

interface OtherSpanProps {
  span: Span
}

const StyledOtherSpan = styled.div`
  background-color: ${color('background', 'canvas')};
  color: ${color('text', 'maxContrast')};
  padding: ${spacing(0.5, 1)};
`

export function OtherSpan({ span }: OtherSpanProps) {
  const hasAttributes = span.attributes && span.attributes.length > 0

  if (!hasAttributes) {
    return <StyledOtherSpan>{span.name}</StyledOtherSpan>
  }

  return (
    <Tooltip
      content={
        <div>
          {span.attributes &&
            span.attributes.map((attr, index) => {
              return (
                <div key={index}>
                  <div>
                    {attr.key}: {getAttributeValue(attr).value}
                  </div>
                </div>
              )
            })}
        </div>
      }
    >
      <StyledOtherSpan>{span.name}</StyledOtherSpan>
    </Tooltip>
  )
}
