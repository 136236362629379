import React, { ReactNode } from 'react'
import { type BadgeColor, Icon, IconName, Tooltip } from '@grafana/ui'

import { TestRun } from 'types'
import { useTestRunStatus } from 'data/useTestRunStatus'
import { LoadZoneOrLocal } from 'components/LoadZoneOrLocal'
import { StatusBadge } from 'components/StatusBadge'
import { MetaBarItemStyled, NoWrap } from './MetaBarItem.styled'
import { icons } from './constants'

interface MetaBarItemProps {
  icon?: IconName
  value?: ReactNode
  tooltip?: string
  children?: ReactNode | ReactNode[]
  hidden?: boolean
}

const Item = ({ icon, value, tooltip, children, hidden }: MetaBarItemProps) => {
  if (hidden) {
    return null
  }
  const content = value ?? children
  const item = (
    <MetaBarItemStyled>
      {!!icon && <Icon aria-hidden name={icon} />}
      {!!content && <div>{content}</div>}
    </MetaBarItemStyled>
  )

  return tooltip ? <Tooltip content={tooltip}>{item}</Tooltip> : item
}

const Vuh = (props: MetaBarItemProps) => {
  return (
    <Item icon={icons.vuh} tooltip="VUh used for this test run" {...props} />
  )
}

const Vus = (props: MetaBarItemProps) => {
  return <Item icon={icons.vus} tooltip="Peak VUs" {...props} />
}

const ConfiguredDuration = (props: MetaBarItemProps) => {
  return (
    <Item icon={icons.duration} tooltip="Configured test duration" {...props} />
  )
}

const ExecutedDuration = (props: MetaBarItemProps) => {
  return <Item icon={icons.duration} tooltip="Test duration" {...props} />
}

interface MetaBarItemLoadZonesProps extends MetaBarItemProps {
  compact?: boolean
  showNodes?: boolean
  testRun?: TestRun
}

const LoadZones = ({
  compact,
  showNodes,
  testRun,
  ...props
}: MetaBarItemLoadZonesProps) => {
  if (!testRun) {
    return null
  }

  return (
    <Item hidden={!testRun} icon={icons.loadZones} {...props}>
      {!compact && <NoWrap>Load zones:</NoWrap>}
      <LoadZoneOrLocal testRun={testRun} showPercentage showNodes={showNodes} />
    </Item>
  )
}

const Status = ({ testRun }: { testRun: TestRun }) => {
  const { statusColor, statusIcon, statusText, width } =
    useTestRunStatus(testRun)

  return (
    <StatusBadge
      width={width}
      color={statusColor as BadgeColor}
      icon={statusIcon}
      text={statusText}
    />
  )
}

export const MetaBarItem = Object.assign(Item, {
  Vuh,
  Vus,
  ConfiguredDuration,
  ExecutedDuration,
  LoadZones,
  Status,
})
