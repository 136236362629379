import { proxyClient } from 'data/httpClient'
import { TestRun } from 'types'
import { LoadZone } from 'types/loadZone'
import { combineLoadZonesDistribution } from 'utils'

export class LoadZoneClient {
  async forTestRun(testRun: TestRun) {
    const loadZones = await this.fetchAll(testRun.organization_id)

    return combineLoadZonesDistribution(
      testRun.distribution,
      testRun.nodes,
      loadZones
    )
  }

  async fetchAll(organizationId: number) {
    return proxyClient
      .get<{ load_zones: LoadZone[] }>({
        url: '/cloud/v3/load-zones',
        params: { organization_id: organizationId.toString() },
      })
      .then((resp) => resp.load_zones)
  }
}
