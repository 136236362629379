import React, { memo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  ShouldForwardProp,
  StyleSheetManager,
  ThemeProvider,
} from 'styled-components'
import { merge } from 'lodash-es'
import { DefaultOptions, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AppRootProps } from '@grafana/data'
import { useTheme2 } from '@grafana/ui'
import isPropValid from '@emotion/is-prop-valid'

import { REACT_QUERY_ERROR_TYPES } from 'constants/errors'
import { DatasourceContextProvider } from 'datasourceContext'
import { FeatureFlagsProvider } from 'store/FeatureFlags'
import { queryClient } from 'data/queryClient'
import { GlobalStyles } from 'theme/global'
import { Routes } from 'routes'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { SetupSentry } from 'components/SetupSentry'
import { Devtools } from 'components/Devtools/Devtools'
import { GrafanaMonacoProvider } from 'components/GrafanaMonaco'
import { UnsupportedGrafanaVersionPage } from 'components/UnsupportedGrafanaVersionPage'
import { ErrorBoundaryFullPageView } from 'services/tracking.types'

export const RootPage = memo(function RootPage(props: AppRootProps) {
  const theme = useTheme2()
  const history = useHistory()

  useEffect(() => {
    queryClient.setDefaultOptions(
      merge<DefaultOptions, DefaultOptions>(queryClient.getDefaultOptions(), {
        queries: { meta: { history } },
      })
    )
  }, [history])

  return (
    <StyleSheetManager
      shouldForwardProp={shouldForwardProp as ShouldForwardProp<'web'>}
    >
      <ThemeProvider theme={theme}>
        <ErrorBoundary view={ErrorBoundaryFullPageView.RootPage}>
          <QueryClientProvider client={queryClient}>
            <UnsupportedGrafanaVersionPage>
              <DatasourceContextProvider>
                <GrafanaMonacoProvider>
                  <FeatureFlagsProvider>
                    <GlobalStyles />
                    <SetupSentry pluginVersion={props.meta.info.version} />
                    <Routes />
                    <ReactQueryDevtools
                      initialIsOpen={false}
                      position="bottom-right"
                      errorTypes={REACT_QUERY_ERROR_TYPES}
                    />
                    <Devtools />
                  </FeatureFlagsProvider>
                </GrafanaMonacoProvider>
              </DatasourceContextProvider>
            </UnsupportedGrafanaVersionPage>
          </QueryClientProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </StyleSheetManager>
  )
})

// This implements the default behavior from styled-components v5
// https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
// I love how they suggest using utils from emotion 🤦
const shouldForwardProp = (prop: string, target: string | unknown) => {
  if (typeof target === 'string') {
    return isPropValid(prop)
  }

  return true
}
