import React, { forwardRef, useState } from 'react'
import type * as monacoType from 'monaco-editor/esm/vs/editor/editor.api'
import styled from 'styled-components'

import { useDatasource } from 'datasourceContext'
import { useAppContext } from 'appContext'
import { useUserToggle } from 'hooks/useUserToggle'

import { CodeEditorProps, ConstrainedEditorProps } from '../CodeEditor.types'
import { CodeEditor } from '../CodeEditor'
import { Banner } from './Banner'
import { getEnvVarType } from './CodeEditorWithEnvVars.utils'

export const CodeEditorWithEnvVars = forwardRef(function CodeEditorWithEnvVars(
  { readOnly, ...props }: CodeEditorProps & ConstrainedEditorProps,
  ref
) {
  const { dismiss: dismissEnvVarsBanner, dismissed: envVarsBannerDismissed } =
    useUserToggle('env-vars-banner')
  const [showBanner, setShowBanner] = useState(false)
  const { ds } = useDatasource()
  const { orgId } = useAppContext()

  async function handleBeforeEditorMount(monaco: typeof monacoType) {
    const data = await ds.getEnvironmentVariables(orgId)
    const type = getEnvVarType(data)

    // Sometimes re-declaration of k6/global does not propagate. Resetting extra libs fixes that.
    monaco.languages.typescript.javascriptDefaults.setExtraLibs([])
    monaco.languages.typescript.javascriptDefaults.addExtraLib(
      `declare module 'k6/global' { ${type} }`
    )

    setShowBanner(data.length > 0 && !readOnly && !envVarsBannerDismissed)
  }

  function handleDismiss() {
    dismissEnvVarsBanner()
    setShowBanner(false)
  }

  return (
    <Container>
      {showBanner && <Banner onDismiss={handleDismiss} />}
      <CodeEditor
        {...props}
        readOnly={readOnly}
        onBeforeEditorMount={handleBeforeEditorMount}
      />
    </Container>
  )
})

const Container = styled.div`
  position: relative;
  height: 100%;
`
