import { useEffect, useState } from 'react'

export function useScrollToId(
  targetId: string,
  behavior: ScrollBehavior = 'auto'
) {
  const location = window.location
  const [hasScrolled, setHasScrolled] = useState(false)

  useEffect(() => {
    if (location.hash.slice(1) === targetId) {
      requestAnimationFrame(() => {
        const targetElement = document.getElementById(targetId)
        if (!hasScrolled && targetElement) {
          targetElement?.scrollIntoView({
            behavior,
            block: 'start',
          })
        }
        setHasScrolled(true)
      })
    }
  }, [location, hasScrolled, targetId, behavior])

  return targetId
}
