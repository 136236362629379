import {
  GroupTreeNode,
  ScenarioTreeNode,
  RootTreeNode,
} from 'data/clients/entities/groups'
import { useMemo } from 'react'
import { GroupLike } from 'types'
import { hasValue } from 'utils/typescript'
import { useTreeViewSettings } from '../TableSettingsContext'
import { TreeTableDefinition } from '../types'

interface UseFilteredTreeOptions<T, P> {
  tree: RootTreeNode | undefined
  table: TreeTableDefinition<T, P>
}

export function useFilteredTree<T, P>({
  tree,
  table,
}: UseFilteredTreeOptions<T, P>) {
  const settings = useTreeViewSettings()

  return useMemo(() => {
    if (tree === undefined || settings.showEmpty) {
      return tree
    }

    function isEmpty(node: GroupLike) {
      return table.getRowCount(node) === 0
    }

    function hideGroups(node: GroupTreeNode): GroupTreeNode | null {
      // We only consider the left branch of the tree, because we don't
      // have any way of getting the outer join of requests under a group.
      if (isEmpty(node.left)) {
        return null
      }

      return {
        ...node,
        children: node.children.map(hideGroups).filter(hasValue),
      }
    }

    function hideScenarios(node: ScenarioTreeNode) {
      if (isEmpty(node.left)) {
        return null
      }

      const rootGroup = hideGroups(node.rootGroup)

      return (
        rootGroup && {
          ...node,
          rootGroup,
        }
      )
    }

    function hideTree(node: RootTreeNode): RootTreeNode {
      return {
        type: 'root',
        scenarios: node.scenarios.map(hideScenarios).filter(hasValue),
      }
    }

    return hideTree(tree)
  }, [table, tree, settings])
}
