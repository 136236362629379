import React, { useMemo } from 'react'

import type { Http, TestRunAnalysis } from 'types'
import { Chart } from 'components/Chart'
import { TagQueryBuilder } from 'utils/metrics'

import { useTimeRange } from '../../../TimeRangeContext'
import { ChartFilters } from '../../ChartFilters/ChartFilters'
import { ViewTraces } from './TracesLink'
import { RunsPageTestIds } from 'types/dataTestIds'

interface Props {
  analysis: TestRunAnalysis
  url: Http
}

export const HttpChart = ({ analysis, url }: Props) => {
  const { timeRange, setTimeRange } = useTimeRange()

  const tags = useMemo(() => {
    return new TagQueryBuilder()
      .equal('method', url.method)
      .equal('name', url.name)
      .equal('status', url.status)
      .equal('expected_response', url.expected_response)
      .build()
  }, [url.method, url.name, url.status, url.expected_response])

  const tracesLink = <ViewTraces run={analysis.main} request={url} />

  return (
    <ChartFilters
      tags={tags}
      nodes={analysis.main.nodes}
      type="http"
      requestSuccessful={url.expected_response}
      actions={tracesLink}
    >
      {(metrics) => (
        <Chart
          title={`${url.method} ${url.name}`}
          testRun={analysis.main}
          compareWith={analysis.compareWith}
          metrics={metrics}
          timeRange={timeRange}
          onChangeTimeRange={setTimeRange}
          dataTestId={RunsPageTestIds.HttpChart}
        />
      )}
    </ChartFilters>
  )
}
