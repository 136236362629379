import React, { useMemo } from 'react'
import { useTimeRange } from '../../../TimeRangeContext'
import { useBrowserUrlsClient } from 'data/clients/entities/browserUrls'
import { useRunDetailsBrowserFilters } from '../../Filters/Filters.hooks'
import { serializeRunFilter } from 'datasource/serialization'
import { TestRunAnalysis } from 'types'
import { BrowserUrls } from 'types/k6Browser'
import { BreakdownView } from '../BreakdownView'
import { RunsPageTestIds } from 'types/dataTestIds'
import { EmptyFilterView } from 'components/EmptyFilterView'
import { BREAKDOWN_TABLE } from './constants'
import { BrowserHttpFilters } from './BrowserHttpFilters'
import { NoMetrics } from './NoMetrics'

interface BrowserBreakdownViewProps {
  analysis: TestRunAnalysis
}

export function BrowserBreakdownView({ analysis }: BrowserBreakdownViewProps) {
  const { timeRange } = useTimeRange()
  const client = useBrowserUrlsClient()
  const view = 'list' // Unclear yet what the TREE view would look like
  const urlFiltersProps = useRunDetailsBrowserFilters()
  const { filters, sortBy, removeAllFilters, updateSortBy } = urlFiltersProps

  const handleClearFilter = () => {
    removeAllFilters()
  }

  const filter = useMemo(
    () => serializeRunFilter<BrowserUrls>(filters),
    [filters]
  )

  const httpRequestCount =
    analysis.main.browser_metric_summary?.http_request_count ?? 0

  if (httpRequestCount === 0) {
    return <NoMetrics />
  }

  return (
    <BreakdownView
      data-testid={RunsPageTestIds.BrowserTable}
      view={view}
      analysis={analysis}
      params={{ client, filter, timeRange }}
      table={BREAKDOWN_TABLE}
      header={<BrowserHttpFilters run={analysis.main} {...urlFiltersProps} />}
      emptyTable={
        <EmptyFilterView onClear={handleClearFilter}>
          No BROWSER requests match the current filter...
        </EmptyFilterView>
      }
      sortBy={sortBy}
      onSort={updateSortBy}
    />
  )
}
