import styled from 'styled-components'
import { fadeIn } from 'theme'

export const Body = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 0 auto;
  max-width: 940px;
  ${fadeIn};
`

export const Card = styled.div`
  padding: 30px;
  margin: 20px;
  max-width: 400px;
  background: ${({ theme }) => theme.colors.background.secondary};
  border: 1px solid ${({ theme }) => theme.colors.border.weak};
`
