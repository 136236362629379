import type { Options } from '../schema/options/options'
import { LegacyTooManyLoadZones, TooManyLoadZones } from './errors'
import { Rules } from 'types'

export function loadZones(
  rules: Rules,
  options: Options
): Array<LegacyTooManyLoadZones | TooManyLoadZones> {
  const maxLoadZones = rules['load_test.load_zones.max']

  const isLegacy = isLegacyCloudOptions(options)
  const cloudOptions = isLegacy ? options.ext?.loadimpact : options.cloud
  const distribution = cloudOptions?.distribution ?? {}
  const loadZones = Object.values(distribution).length

  if (loadZones <= maxLoadZones) {
    return []
  }

  return [
    {
      type: isLegacy ? 'legacy-too-many-load-zones' : 'too-many-load-zones',
      max: maxLoadZones,
      actual: loadZones,
    },
  ]
}

const isLegacyCloudOptions = (options: Options) => {
  return (
    typeof options.ext?.loadimpact !== 'undefined' &&
    typeof options.cloud === 'undefined'
  )
}
