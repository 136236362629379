import React from 'react'
import { PluginPagePlaceholder } from 'components/PluginPagePlaceholder'
import { ErrorState } from 'pages/ProjectPage/components/ErrorState'
import { ErrorBoundaryFullPageView } from 'services/tracking.types'
import { Flex } from 'components/Flex'
import { BertMessage } from 'components/BertMessage'
import { Organization } from 'types'
import { getGrafanaUrl } from 'utils/env'

export function AppContextErrorState({ error }: { error: unknown }) {
  return (
    <PluginPagePlaceholder>
      <ErrorState
        error={error}
        view={ErrorBoundaryFullPageView.AppContextProvider}
      />
    </PluginPagePlaceholder>
  )
}

export function AppContextFeatureToggleErrorState({
  org,
}: {
  org?: Organization
}) {
  const path = org?.grafana_org_slug
    ? `/orgs/${org.grafana_org_slug}/tickets/new?supportTopic=grafana%20k6`
    : `/help/`

  const link = getGrafanaUrl(path)

  return (
    <PluginPagePlaceholder>
      <BertMessage
        type="error"
        title="Contact support"
        description={
          <BertMessage.Text>
            <Flex direction="column" gap={1}>
              Grafana Cloud k6 requires the following feature toggles to be
              enabled:
              <ul>
                <li>
                  <code>accessControlOnCall</code>
                </li>
                <li>
                  <code>nestedFolders</code>
                </li>
                <li>
                  <code>externalServiceAccounts</code>
                </li>
              </ul>
              <p>
                Please{' '}
                <a target="_blank" href={link} rel="noreferrer">
                  contact support
                </a>{' '}
                to enable these features.
              </p>
            </Flex>
          </BertMessage.Text>
        }
      />
    </PluginPagePlaceholder>
  )
}
