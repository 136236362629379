import React, { forwardRef, Ref } from 'react'
import { IconButton as GUIIconButton, Tooltip } from '@grafana/ui'

export type IconButtonProps = React.ComponentProps<typeof GUIIconButton>

export const IconButton = forwardRef(function IconButton(
  props: IconButtonProps,
  ref: Ref<HTMLButtonElement>
) {
  if (props.disabled && props.tooltip) {
    const { tooltip, ...buttonProps } = props

    return (
      <Tooltip content={tooltip}>
        <span>
          <GUIIconButton {...buttonProps} ref={ref} />
        </span>
      </Tooltip>
    )
  }

  return <GUIIconButton {...props} ref={ref} />
})
