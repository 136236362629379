import dedent from 'dedent'
import { LoadZoneDistribution } from 'types/loadZone'
import { data, dataRate, vus } from 'utils/formatters'
import { GeneratorContext, SummaryRow } from '../types'
import { createTextPanel } from '../panels'
import { DATA_RECEIVED, DATA_SENT, VUS_METRIC } from 'constants/metrics'
import { MetricBuilder } from 'utils/metrics'
import { MetricData } from 'datasource/models'
import { findMaxInSeries, findAtTimestamp } from './utils'

function calculateStats(
  vus: MetricData,
  sent: MetricData,
  received: MetricData
) {
  const maxSent = findMaxInSeries(sent)
  const maxReceived = findMaxInSeries(received)

  return {
    sent: {
      max: maxSent.value,
      vus: findAtTimestamp(vus, maxSent.timestamp),
    },
    received: {
      max: maxReceived.value,
      vus: findAtTimestamp(vus, maxReceived.timestamp),
    },
  }
}

async function fetchData(
  { metrics, options: { testRun } }: GeneratorContext,
  loadZone: LoadZoneDistribution | undefined
) {
  const [[vus], [sent], [received], [totalSent], [totalReceived]] =
    await Promise.all([
      metrics.queryRange(
        testRun.id,
        new MetricBuilder(VUS_METRIC).withLoadZone(loadZone).build()
      ),
      metrics.queryRange(
        testRun.id,
        new MetricBuilder(DATA_SENT).withLoadZone(loadZone).build()
      ),
      metrics.queryRange(
        testRun.id,
        new MetricBuilder(DATA_RECEIVED).withLoadZone(loadZone).build()
      ),
      metrics.queryAggregate(
        testRun.id,
        new MetricBuilder(DATA_SENT).withMethod('value').asAggregate().build()
      ),

      metrics.queryAggregate(
        testRun.id,
        new MetricBuilder(DATA_RECEIVED)
          .withMethod('value')
          .asAggregate()
          .build()
      ),
    ])

  if (
    vus === undefined ||
    sent === undefined ||
    received === undefined ||
    totalSent === undefined ||
    totalReceived === undefined
  ) {
    throw new Error('Missing data for generating response time text.')
  }

  return {
    totals: {
      sent: totalSent.data?.values[0]?.value ?? 0,
      received: totalReceived.data?.values[0]?.value ?? 0,
    },
    stats: calculateStats(vus, sent, received),
  }
}

async function generateText(
  context: GeneratorContext,
  loadZone: LoadZoneDistribution | undefined
) {
  const { totals, stats } = await fetchData(context, loadZone)

  const vusSent = vus(stats.sent.vus)
  const maxSent = dataRate(stats.sent.max)

  const vusReceived = vus(stats.received.vus)
  const maxReceived = dataRate(stats.received.max)

  const totalSent = data(totals.sent)
  const totalReceived = data(totals.received)

  const locationText = loadZone !== undefined ? `from ${loadZone.name} ` : ''

  // prettier-ignore
  return dedent`
    ## Bandwidth

    The amount of data sent ${locationText}peaked at **${vusSent}**, sending **${maxSent}** of 
    data. Data received had its peak at **${vusReceived}** with **${maxReceived}** being received. 
    A total of **${totalSent}** was sent and **${totalReceived}** received during the course of 
    the entire run.
  `
}

export async function createBandwidthText(
  context: GeneratorContext,
  loadZone: LoadZoneDistribution | undefined
): Promise<SummaryRow> {
  const text = await generateText(context, loadZone)

  return {
    height: 3,
    panels: [createTextPanel(text)],
  }
}
