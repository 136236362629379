import { Validate } from 'react-hook-form'

import { convertDurationToSeconds } from 'utils/convertDurationToSeconds'
import { isDurationValid } from 'utils/isDurationValid'
import { useSubscriptionRule } from 'data/useSubscriptions'
import { ProjectSettings } from 'types'
import { timeFormatterShort } from 'utils/formatters'
import { useMemo } from 'react'

export interface ProjectLimitsFormValues
  extends Omit<
    ProjectSettings,
    'organization_id' | 'project_id' | 'duration_max_per_test'
  > {
  duration_max_per_test: string | null
}

function nullOrPositiveInteger(value: number | null) {
  if (value === null || (Number.isInteger(value) && value > 0)) {
    return true
  }

  return 'Value must be a positive integer or blank.'
}

function nullOrValidNonZeroDuration(value: string | null) {
  if (value === null || (value && isDurationValid(value))) {
    return true
  }

  return 'Value must be a valid duration (e.g. 1h20m) or blank.'
}

export function useLimitsFormValidation(): {
  vuh_max_per_month: Record<
    string,
    Validate<number | null, ProjectLimitsFormValues>
  >
  vu_max_per_test: Record<
    string,
    Validate<number | null, ProjectLimitsFormValues>
  >
  duration_max_per_test: Record<
    string,
    Validate<string | null, ProjectLimitsFormValues>
  >
} {
  const subscriptionVUMaxPerTest = useSubscriptionRule('tests.users.max')
  const subscriptionDurationMaxPerTest =
    useSubscriptionRule('tests.duration.max')

  return {
    vuh_max_per_month: {
      nullOrPositiveInteger,
    },
    vu_max_per_test: {
      nullOrPositiveInteger,
      notGreaterThanSubscription: (value) => {
        if (value && value > subscriptionVUMaxPerTest) {
          return `Value cannot be greater than your subscription limit (${subscriptionVUMaxPerTest} VUs)`
        }

        return true
      },
    },
    duration_max_per_test: {
      nullOrValidNonZeroDuration,
      notGreaterThanSubscription: (value) => {
        const subscriptionDurationMaxPerTestInSeconds =
          subscriptionDurationMaxPerTest * 60
        if (
          value &&
          convertDurationToSeconds(value) >
            subscriptionDurationMaxPerTestInSeconds
        ) {
          return `Value cannot be greater than your subscription limit (${timeFormatterShort(
            subscriptionDurationMaxPerTestInSeconds
          )})`
        }

        return true
      },
    },
  }
}

export const useProjectLimitsFormDefaultValues = () => {
  const vuhMaxPerMonthFromSub = useSubscriptionRule(
    'organization.projects.default_max_vuh_per_month'
  )

  const vuMaxPerTestFromSub = useSubscriptionRule(
    'organization.projects.default_max_vus_per_test'
  )

  const durationMaxPerTestFromSub = useSubscriptionRule(
    'organization.projects.default_max_duration_per_test'
  )

  // In case defaults are not provided in the backend we provide them here.
  const limits = useMemo(() => {
    return {
      vuh_max_per_month: vuhMaxPerMonthFromSub ?? 1000,
      vu_max_per_test: vuMaxPerTestFromSub ?? 1000,
      duration_max_per_test: timeFormatterShort(
        durationMaxPerTestFromSub ?? 3600
      ),
    }
  }, [vuhMaxPerMonthFromSub, vuMaxPerTestFromSub, durationMaxPerTestFromSub])

  return limits
}
