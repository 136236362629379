import styled from 'styled-components'
import { Icon } from '@grafana/ui'

export const InsightListExpanderIconWrapper = styled.div`
  display: none;

  align-items: center;
  justify-content: right;
  flex: 1 1 0;
  position: absolute;
  right: 0;

  color: ${({ theme }) => theme.colors.text.secondary};
`

export const InsightListExpanderIcon = styled(Icon)`
  min-width: 48px;
`

export const InsightListWrapper = styled.div`
  .rdt_TableRow {
    flex-direction: row-reverse;

    &.failed ${InsightListExpanderIconWrapper} {
      display: flex;
    }

    > .rdt_TableCell:nth-child(2) {
      padding-left: 16px;
    }
  }
`
