import { toTestRunQueryKey } from 'data/queryKeys'
import { useStreamingQueries } from 'hooks/useStreamingQuery'
import { PagedItems, TestRun } from 'types'
import { Diff, ListTable, TreeTable } from './types'
import { Dictionary, keyBy } from 'lodash'
import { useMemo } from 'react'

interface UseComparedValuesOptions<T, P> {
  left: TestRun
  right: TestRun | undefined
  pages: Array<PagedItems<T>> | PagedItems<T> | undefined
  params: P
  table: TreeTable<T, P> | ListTable<T, P>
}

export function useComparedValues<T, P>({
  left,
  right,
  pages = [],
  params,
  table,
}: UseComparedValuesOptions<T, P>) {
  function toPagedComparison(
    page: PagedItems<T>,
    rhs?: Dictionary<T>
  ): PagedItems<Diff<T>> {
    return {
      ...page,
      items: page.items.map((item) => {
        const key = table.keyBy(item)

        return {
          left: item,
          right: rhs && (rhs[key] ?? null),
        }
      }),
    }
  }

  const pageArray = Array.isArray(pages) ? pages : [pages]

  return useStreamingQueries(left, {
    queries: pageArray.map((page) => {
      // If right is undefined, it means that we're not comparing.
      // By using this combination of placeholderData and select
      // we get a query that will be mapped to the correct type but
      // won't be stored in the cache.
      if (right === undefined || page.items.length === 0) {
        return {
          cacheTime: 0,
          queryKey: [...toTestRunQueryKey(left.id), 'comparison-placeholder'],
          queryFn: () => {
            return { count: 0, items: [] }
          },
          select() {
            return toPagedComparison(page)
          },
        }
      }

      const { queryKey, queryFn } = table.fetchByRows({
        ...params,
        testRun: right,
        rows: page.items,
      })

      return {
        queryKey,
        queryFn,

        select(comparisons: PagedItems<T>) {
          return toPagedComparison(page, keyBy(comparisons.items, table.keyBy))
        },

        keepPreviousData: true,
      }
    }),
  })
}

export function useFilteredPage<T, P>(
  { filter }: ListTable<T, P> | TreeTable<T, P>,
  page: PagedItems<Diff<T>>,
  params: P
) {
  return useMemo(() => {
    if (filter === undefined) {
      return page
    }

    return {
      ...page,
      items: page.items.filter((diff) => filter({ ...diff, ...params })),
    }
  }, [page, params, filter])
}
