import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useDatasource } from 'datasourceContext'
import { Test } from 'types'
import { showAlert } from 'utils/showAlert'
import {
  toProjectsWithStatsKey,
  toTestCountQueryKey,
  toTestSearchQueryKey,
} from './queryKeys'
import { useAppContext } from 'appContext'

export const useDeleteTests = (projectId: number) => {
  const queryClient = useQueryClient()
  const { ds } = useDatasource()
  const { orgId } = useAppContext()

  return useMutation({
    mutationFn: (tests: Test[]) => ds.deleteTests(tests.map((test) => test.id)),
    onSuccess: (_, [first, ...rest]) => {
      if (first === undefined) {
        return
      }

      queryClient.invalidateQueries(toTestCountQueryKey(projectId))
      queryClient.invalidateQueries(toTestSearchQueryKey(projectId))
      queryClient.invalidateQueries(toProjectsWithStatsKey(orgId))

      const message =
        rest.length > 0
          ? `Successfully deleted ${rest.length + 1} tests.`
          : `Successfully deleted test "${first.name}".`

      showAlert(message, 'success')
    },
    onError: (_, [first, ...rest]) => {
      if (first === undefined) {
        return
      }

      queryClient.invalidateQueries(toTestSearchQueryKey(projectId))

      const message =
        rest.length > 0
          ? 'Failed to delete one or more of the selected tests.'
          : `Failed to delete test "${first.name}".`

      showAlert(message, 'error')
    },
  })
}
