import React, { ChangeEvent, useState } from 'react'
import {
  Button,
  Checkbox,
  ControlledCollapse,
  Field,
  Input,
  Modal,
} from '@grafana/ui'
import { Test, TestRun } from 'types'
import { FolderSelect } from './FolderSelect/FolderSelect'
import { FolderOption, SummarySections } from './types'
import { useExportSummary } from './SummaryExport.hooks'
import { ExportErrorMessage } from './ExportErrorMessage'
import { ExportSettings, SummaryExportMessage } from './SummaryExport.styles'
import { parseISO } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { useCreateDashboardPermission } from 'hooks/useGrafanaRBAC'
import { resolveTimeZone } from 'utils/date'
import { useAvailableProtocols } from '../RunOverview/RunOverview.hooks'

function getDefaultName(test: Test, testRun: TestRun) {
  const created = parseISO(testRun.created)

  return `Summary of "${test.name}" - ${formatInTimeZone(
    created,
    resolveTimeZone(),
    'yyyy-MM-dd HH:mm'
  )}`
}

export interface SummaryExportProps {
  test: Test
  testRun: TestRun
  onClose: () => void
}

export function SummaryExport({ test, testRun, onClose }: SummaryExportProps) {
  const availableProtocols = useAvailableProtocols({ main: testRun })

  const [name, setName] = useState(() => getDefaultName(test, testRun))
  const [folder, setFolder] = useState<FolderOption>({
    type: 'root',
  })
  const [includeLoadZones, setIncludeLoadZones] = useState(true)

  const [sections, setSections] = useState<SummarySections>({
    responseTime: true,
    throughput: true,
    bandwidth: true,
  })

  const {
    data = { type: 'pending', error: undefined },
    mutateAsync: exportSummary,
    isLoading,
    reset,
  } = useExportSummary()

  const createDashboardPermission = useCreateDashboardPermission()

  const handleNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    reset()

    setName(ev.target.value)
  }

  const handleFolderChange = (folder: FolderOption) => {
    reset()

    setFolder(folder)
  }

  const handleIncludeLoadZonesChange = () => {
    setIncludeLoadZones(!includeLoadZones)
  }

  const handleExport = () => {
    if (name === '' || folder === undefined) {
      return
    }

    exportSummary({
      test,
      testRun,
      name,
      folder,
      sections,
      include: {
        loadZones: includeLoadZones,
      },
    })
  }

  const handleResponseTimeChange = () => {
    setSections({
      ...sections,
      responseTime: !sections.responseTime,
    })
  }

  const handleThroughputChange = () => {
    setSections({
      ...sections,
      throughput: !sections.throughput,
    })
  }

  const handleBandwidthChange = () => {
    setSections({
      ...sections,
      bandwidth: !sections.bandwidth,
    })
  }

  if (createDashboardPermission === 'none') {
    return null
  }

  if (!availableProtocols.some((protocol) => protocol.type === 'http')) {
    return (
      <>
        <SummaryExportMessage>
          <div>
            The summary export currently only works with HTTP metrics and this
            test run does not have any.
          </div>
        </SummaryExportMessage>
        <Modal.ButtonRow>
          <Button onClick={onClose}>Close</Button>
        </Modal.ButtonRow>
      </>
    )
  }

  if (data && data.type === 'success') {
    return (
      <>
        <SummaryExportMessage>
          <div>
            Your summary was created and can be accessed{' '}
            <a href={data.data.url} target="_blank" rel="noreferrer">
              here
            </a>
            .
          </div>
        </SummaryExportMessage>
        <Modal.ButtonRow>
          <Button onClick={onClose}>Close</Button>
        </Modal.ButtonRow>
      </>
    )
  }

  return (
    <>
      <ExportSettings>
        <div>
          <Field label="Name" disabled={isLoading}>
            <Input value={name} onChange={handleNameChange} />
          </Field>
          {createDashboardPermission === 'all' && (
            <Field label="Folder" disabled={isLoading}>
              <FolderSelect value={folder} onChange={handleFolderChange} />
            </Field>
          )}
        </div>

        <ControlledCollapse label="Sections" collapsible isOpen={true}>
          <div>
            <Checkbox
              value={sections.responseTime}
              label="Response time"
              description="Include a breakdown of the response time in different percentiles."
              onChange={handleResponseTimeChange}
            />
          </div>
          <div>
            <Checkbox
              value={sections.throughput}
              label="Throughput"
              description="Include a breakdown of the request rate."
              onChange={handleThroughputChange}
            />
          </div>
          <div>
            <Checkbox
              value={sections.bandwidth}
              label="Bandwidth"
              description="Include a breakdown of the amount of data sent and received."
              onChange={handleBandwidthChange}
            />
          </div>
        </ControlledCollapse>

        <ControlledCollapse label="Options" collapsible>
          <Checkbox
            checked={includeLoadZones}
            label="Include load zones"
            description="Generate individual sections for each load zone in the test run."
            onChange={handleIncludeLoadZonesChange}
          />
        </ControlledCollapse>

        <ExportErrorMessage error={data?.error} />
      </ExportSettings>
      <Modal.ButtonRow>
        <Button
          disabled={isLoading || data.type === 'error' || folder === undefined}
          onClick={handleExport}
        >
          {folder?.type === 'new' ? 'Create folder and export' : 'Export'}
        </Button>
      </Modal.ButtonRow>
    </>
  )
}
