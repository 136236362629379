import React from 'react'

import { Buttons, ButtonsProps } from './Buttons'
import { Options, OptionsProps } from './Options'
import { ActionsContainer } from './Actions.styled'

interface ActionsProps extends OptionsProps, ButtonsProps {}

export function Actions({
  fill,
  test,
  testRun,
  variant,
  onDeleteRun,
}: ActionsProps) {
  return (
    <ActionsContainer>
      <Buttons test={test} testRun={testRun} />
      <Options
        fill={fill}
        variant={variant}
        test={test}
        testRun={testRun}
        onDeleteRun={onDeleteRun}
      />
    </ActionsContainer>
  )
}
