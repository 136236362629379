import styled from 'styled-components'
import { InlineLabel } from '@grafana/ui'
import { color, spacing } from 'utils/styled'
import { Link } from 'react-router-dom'

export const CompareHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${spacing(2)};

  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.navbarFixed};

  margin: ${spacing(0, -3)};
  padding: ${spacing(2, 3)};

  background-color: ${color('background', 'primary')};
`

export const CompareFieldRow = styled.div`
  display: flex;
  flex: 1 1 0;
  align-items: center;
  gap: ${spacing(2)};
`

export const CompareLabelLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`

const CompareLabel = styled(InlineLabel).attrs({
  width: 'auto',
  transparent: true,
})`
  font-size: 1rem;
  border-bottom-width: 3px;
  border-radius: 0;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
  }
`

export const LeftCompareLabel = styled(CompareLabel)`
  color: ${color('info', 'text')};
  border-bottom-style: solid;
`

export const RightCompareLabel = styled(CompareLabel)`
  color: ${color('text', 'primary')};
  border-bottom-style: dashed;
`

export const SwapLink = styled(Link)`
  display: flex;
`
