import React from 'react'
import styled from 'styled-components'
import { Icon } from '@grafana/ui'

import { LoadZones } from 'components/LoadZones'
import { isLocalExecution } from 'utils/testRun'
import { TestRun } from 'types'
import { Tooltip } from 'components/Tooltip'
import { spacing } from 'utils/styled'

const LocalExecutionWrapper = styled.div`
  & > div {
    transform: translateY(-1px);
    margin: ${spacing(0, 0.5)};
  }
`

interface LoadZoneOrLocalProps {
  compact?: boolean
  limit?: number
  showNodes?: boolean
  showPercentage?: boolean
  testRun: TestRun
}

export const LoadZoneOrLocal = ({
  compact,
  limit,
  showNodes,
  showPercentage,
  testRun,
}: LoadZoneOrLocalProps) => {
  if (isLocalExecution(testRun)) {
    return (
      <Tooltip content="This test run was executed locally">
        <LocalExecutionWrapper>
          <Icon aria-hidden name="arrow" />
          Local execution
        </LocalExecutionWrapper>
      </Tooltip>
    )
  }

  return (
    <LoadZones
      compact={compact}
      limit={limit}
      nodes={testRun.nodes}
      distribution={testRun.distribution}
      showNodes={showNodes}
      showPercentage={showPercentage}
    />
  )
}
