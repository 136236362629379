import React from 'react'
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer'
import { type AbsoluteTimeRange } from '@grafana/data'
import { PanelChrome } from '@grafana/ui'

import type { TestRun } from 'types'
import { isFetched } from 'utils/reactQuery'
import { type MetricAnnotation, type MetricConfig } from 'datasource/models'
import { Flex } from 'components/Flex'
import { LoadingContainer } from 'components/LoadingContainer'
import { useColorPool } from 'hooks/useColorPool'
import { ColorPool } from 'utils/colorPool'

import { useTimeSeries } from './Chart.hooks'
import { hasSeriesValues, isTestRunWithData } from './Chart.utils'
import { ChartMenu } from './ChartMenu'
import { ChartPanel } from './ChartPanel'

interface ChartProps {
  title: string
  testRun: TestRun
  compareWith?: TestRun
  loading?: boolean
  height?: number
  annotations?: MetricAnnotation[]
  metrics: MetricConfig[]
  timeRange?: AbsoluteTimeRange
  onChangeTimeRange?: (timeRange?: AbsoluteTimeRange) => void
  colorPool?: ColorPool
  dataTestId?: string
}

export const Chart = ({
  title,
  testRun,
  compareWith,
  metrics,
  annotations = [],
  loading = false,
  height = 300,
  timeRange,
  onChangeTimeRange,
  colorPool: existingColorPool,
  dataTestId,
}: ChartProps) => {
  const colorPool = useColorPool(existingColorPool)

  const series = useTimeSeries({
    left: testRun,
    right: compareWith,
    timestamp: compareWith ? 'relative' : 'absolute',
    metrics,
    enabled: metrics.length > 0,
    colorPool,
  })

  const noData = !isTestRunWithData(testRun) || !hasSeriesValues(series)
  const isLoading = loading || !series.every(isFetched)

  return (
    <ReactVirtualizedAutoSizer disableHeight data-testid={dataTestId}>
      {(size) => (
        <LoadingContainer
          loading={isLoading}
          style={{ width: size.width || 0 }}
        >
          <PanelChrome
            width={size.width || 0}
            height={height}
            actions={
              <Flex align="center" gap={2}>
                {!noData && (
                  <ChartMenu
                    title={title}
                    testRun={testRun}
                    compareWith={compareWith}
                    metrics={metrics}
                    timeRange={timeRange}
                  />
                )}
              </Flex>
            }
          >
            {(innerWidth, innerHeight) => (
              <ChartPanel
                testRun={testRun}
                compareWith={compareWith}
                series={series}
                annotations={annotations}
                width={innerWidth}
                height={innerHeight}
                timeRange={timeRange}
                onChangeTimeRange={onChangeTimeRange}
              />
            )}
          </PanelChrome>
        </LoadingContainer>
      )}
    </ReactVirtualizedAutoSizer>
  )
}
