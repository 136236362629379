import { dateTime, LoadingState, PanelData } from '@grafana/data'

import { LogsFilter, ParsedLog } from 'types/logs'
import { createTimeRange } from 'utils/date'
import { createLogsDataFrame } from 'utils/logs'

export const createPanelData = (
  logs: ParsedLog[],
  filters: LogsFilter
): PanelData => {
  const searchWords = filters.queryFilter ? [filters.queryFilter] : []
  const startDate = logs.at(0)!.values[0]
  const endDate = logs.at(-1)!.values[0]

  return {
    state: LoadingState.Done,
    series: [createLogsDataFrame('logs', logs, { searchWords })],
    timeRange: createTimeRange(
      dateTime(new Date(startDate)),
      dateTime(new Date(endDate))
    ),
  }
}
