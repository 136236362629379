import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { DocLink, docs } from 'components/DocLink'
import { spacing } from 'utils/styled'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  padding: ${spacing(8)};

  > div {
    max-width: 700px;
    text-align: center;
  }
`

interface MissingScriptMessageProps {
  className?: string
}

export const MissingScriptMessage = forwardRef<
  HTMLDivElement,
  MissingScriptMessageProps
>((props, ref) => (
  <Container ref={ref} {...props}>
    <div>
      The script for this test run is not available. This is likely because the
      test was{' '}
      <DocLink article={docs.gettingStarted.streamToCloud}>
        streamed to the cloud
      </DocLink>{' '}
      or because it was run with the{' '}
      <code>
        <DocLink article={docs.cloudOptions.optionsAndSyntax}>
          deleteSensitiveData
        </DocLink>
      </code>{' '}
      option set to true.
    </div>
  </Container>
))

MissingScriptMessage.displayName = 'MissingScriptMessage'

export const MissingBothScriptsMessage = forwardRef<
  HTMLDivElement,
  MissingScriptMessageProps
>((props, ref) => (
  <Container ref={ref} {...props}>
    <div>
      Neither test run has a script. This is likely because the they were{' '}
      <DocLink article={docs.gettingStarted.streamToCloud}>
        streamed to the cloud
      </DocLink>{' '}
      or because they were run with the{' '}
      <code>
        <DocLink article={docs.cloudOptions.optionsAndSyntax}>
          deleteSensitiveData
        </DocLink>
      </code>{' '}
      option set to true.
    </div>
  </Container>
))

MissingBothScriptsMessage.displayName = 'MissingBothScriptsMessage'
