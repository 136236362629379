import React from 'react'
import styled from 'styled-components'
import { orderBy } from 'lodash'

import { type TestRun } from 'types'
import { InsightCategory } from 'types/cloudInsights'
import { spacing } from 'utils/styled'
import {
  useInsightsCategories,
  useInsightsLatestExecution,
} from 'data/useCloudInsights'
import { Flex } from 'components/Flex'

import { CloudInsightsCategory } from './CloudInsightsCategory'
import {
  CloudInsightsAuditFilter,
  CloudInsightsAuditFilterProvider,
} from './CloudInsightsAuditFilter'

interface CloudInsightsContentProps {
  testRun: TestRun
}

export const CloudInsightsContent = ({
  testRun,
}: CloudInsightsContentProps) => {
  const { data: execution } = useInsightsLatestExecution(testRun)
  const { data: categories = [] } = useInsightsCategories(testRun, execution)

  return (
    <div>
      <HorizontalRule />
      <CloudInsightsAuditFilterProvider>
        <Flex padding={0.5}>
          <CloudInsightsAuditFilter />
        </Flex>
        <Flex direction="column">
          {orderByTitle(categories).map((category) => (
            <CloudInsightsCategory
              key={`category-${category.id}`}
              category={category}
              testRun={testRun}
            />
          ))}
        </Flex>
      </CloudInsightsAuditFilterProvider>
    </div>
  )
}

const orderByTitle = (categories: InsightCategory[] = []) => {
  return orderBy([...categories], ['title'])
}

const HorizontalRule = styled.hr`
  margin: ${spacing(0, 0, 0.5, 0)};
`
