import styled from 'styled-components'

import { type InsightScore } from 'types/cloudInsights'
import { getScoreColor } from 'utils/insights'
import { Flex } from 'components/Flex'

import { SCORE_SIZE } from '../../../constants'

export const Container = styled(Flex).attrs<{ score?: InsightScore }>({
  align: 'center',
  justify: 'center',
  width: SCORE_SIZE,
})`
  color: ${getScoreColor};
`

export const Text = styled.span`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: ${({ theme }) => theme.typography.h5.fontSize};
`
