import { IconName } from '@grafana/ui'
import { TableColumn } from '../types'
import { SortOptions } from 'types'

export function getSortIcon(
  column: TableColumn<any>,
  options: SortOptions<any> | undefined
): IconName {
  if (options === undefined || column.sortBy !== options.sortBy) {
    return 'angle-right'
  }

  return options.direction === 'asc' ? 'angle-up' : 'angle-down'
}
