import { FieldConfig } from '@grafana/data'
import { DataSourceRef, GraphFieldConfig } from '@grafana/schema'
import { LogsConfig, MetricConfig, Query, QueryType } from 'datasource/models'
import { TestRun } from 'types'

/**
 * A convenience function that gives you auto-complete when configuring
 * field in a DataFrame
 */
export function createGraphFieldConfig(config: FieldConfig<GraphFieldConfig>) {
  return config
}

const LETTERS_IN_ALPHABET = 26
const CHAR_CODE_A = 'A'.charCodeAt(0)

export function toGrafanaLikeRefID(index: number) {
  return String.fromCharCode(CHAR_CODE_A + (index % LETTERS_IN_ALPHABET))
}

export function toQueryTarget(
  index: number,
  testRun: TestRun,
  config: MetricConfig | LogsConfig,
  datasource?: DataSourceRef
): Query {
  return {
    refId: toGrafanaLikeRefID(index),
    version: 4,
    datasource,
    body: {
      type: QueryType.TestRun,
      projectId: testRun.project_id.toString(),
      testId: testRun.test_id.toString(),
      testRunId: testRun.id.toString(),
      config,
    },
  }
}
