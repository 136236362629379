import React from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { spacing } from 'utils/styled'
import { Icon, IconName } from '@grafana/ui'
import { RowStatus } from '../types'

interface RowIconContainerProps {
  empty?: boolean
  status?: boolean | undefined | RowStatus
  bordered?: boolean
}

function getStatusColor(theme: DefaultTheme, status?: boolean | RowStatus) {
  switch (status) {
    case true:
    case 'success':
      return theme.colors.success.main
    case false:
    case 'error':
      return theme.colors.error.main
    case 'warning':
      return theme.colors.warning.main
    default:
      return 'inherit'
  }
}

export const RowIconContainer = styled.div<RowIconContainerProps>`
  display: flex;

  justify-content: center;
  align-items: center;

  min-width: ${({ theme, empty }) => theme.spacing(empty ? 2 : 6)};
  height: ${spacing(6)};

  color: ${({ theme, status }) => {
    return getStatusColor(theme, status) ?? 'inherit'
  }};

  border-left: 2px solid;
  border-color: ${({ theme, bordered, status }) => {
    if (!bordered) {
      return 'transparent'
    }

    return getStatusColor(theme, status) ?? 'transparent'
  }};
`

interface TableRowIconProps {
  name?: IconName
  bordered?: boolean
  status?: boolean | RowStatus
}

export function TableRowIcon({
  name,
  bordered = false,
  status,
}: TableRowIconProps) {
  return (
    <RowIconContainer
      empty={name === undefined}
      bordered={bordered}
      status={status}
    >
      {name && <Icon name={name} size="xl" />}
    </RowIconContainer>
  )
}
