import React, {
  RefObject,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Icon, Pagination, Spinner } from '@grafana/ui'
import { noop } from 'lodash-es'
import DataTableComponent, {
  type PaginationComponentProps,
  type IDataTableProps,
} from 'react-data-table-component'

import { calculateTotalPages } from 'utils/pagination'

import { useTableTheme } from './DataTable.hooks'
import {
  DataTableWrapper,
  PaginationWrapper,
  SortIconWrapper,
  SpinnerWrapper,
} from './DataTable.styles'
import { useSorting } from './hooks/useSorting'
import { DataTableEmptyState } from './DataTableEmptyState'
import { TableColumn, DataTableProps, SortIconProps } from './DataTable.types'
import { createActionsColumn } from './DataTable.utils'
import { useContentRect } from '../../hooks/useContentRect'

const WRAPPER_DEFAULT_HEIGHT = 100

export function DataTable<T>({
  'data-testid': dataTestId,
  actions,
  columns: initialColumns,
  currentPage,
  data,
  defaultSortFieldId,
  defaultSortOrder,
  expandableRows,
  isFetching = false,
  noDataMessage = 'There are no records to display',
  onChangePage = noop,
  onRowClicked,
  onSort,
  paginationComponent = GrafanaPagination,
  paginationPerPage = 20,
  paginationServer,
  sortServer,
  fixedHeader,
  fixedHeaderScrollHeight,
  ...rest
}: DataTableProps<T>) {
  const ref = useRef<HTMLDivElement>(null)
  const theme = useTableTheme()
  const PaginationComponent = paginationComponent
  const paginationProps = currentPage !== undefined ? { currentPage } : {}
  const columns = useMemo(() => {
    return [
      ...initialColumns,
      ...(actions ? [createActionsColumn<T>(actions)] : []),
    ]
  }, [actions, initialColumns])

  const externalSort = sortServer || paginationServer
  const { orderBy, onSortHandler, rows } = useSorting(
    data,
    columns,
    defaultSortOrder,
    defaultSortFieldId,
    onSort,
    externalSort
  )

  const handleSort = (column: TableColumn<T>) => {
    onSortHandler(column)
  }

  useEffect(() => {
    // remove tabindex from disabled sort columns
    if (rows.length) {
      ref.current?.querySelectorAll('[disabled]').forEach((el) => {
        el.setAttribute('tabindex', '-1')
      })
    }
  }, [rows.length])

  const wrapperRect = useContentRect(ref)
  const [wrapperHeight, setWrapperHeight] = useState(WRAPPER_DEFAULT_HEIGHT)

  useEffect(() => {
    if (wrapperHeight !== WRAPPER_DEFAULT_HEIGHT && wrapperRect?.height) {
      setWrapperHeight(wrapperRect.height)
    }
  }, [wrapperHeight, wrapperRect?.height])

  useLayoutEffect(() => {
    if (wrapperRect?.height) {
      setWrapperHeight(wrapperRect?.height)
    }
  }, [wrapperRect?.height])

  const scrollHeight =
    fixedHeader && !fixedHeaderScrollHeight
      ? `${wrapperHeight}px`
      : fixedHeaderScrollHeight

  const wrapperPainted = fixedHeader ? !!wrapperHeight : true

  return (
    <DataTableWrapper
      $isEmpty={data.length === 0}
      $isFetching={isFetching}
      $isRowClickable={!!onRowClicked}
      $expandableRows={expandableRows}
      $fixedHeader={fixedHeader}
      data-testid={dataTestId}
      ref={ref}
    >
      {wrapperPainted && (
        <DataTableComponent<T>
          data={rows}
          expandableRows={expandableRows}
          noDataComponent={
            <DataTableEmptyState isFetching={isFetching}>
              {noDataMessage}
            </DataTableEmptyState>
          }
          paginationPerPage={paginationPerPage}
          paginationComponent={(props) => (
            <PaginationComponent
              {...props}
              {...paginationProps}
              onChangePage={onChangePage}
            />
          )}
          columns={columns as IDataTableProps<T>['columns']}
          sortIcon={<SortIcon orderBy={orderBy} columns={columns} />}
          fixedHeader={fixedHeader}
          fixedHeaderScrollHeight={scrollHeight}
          {...rest}
          theme={theme}
          onRowClicked={onRowClicked}
          onSort={handleSort}
          sortServer
        />
      )}

      {(!wrapperPainted || isFetching) && (
        <SpinnerWrapper>
          <Spinner size={34} />
        </SpinnerWrapper>
      )}
    </DataTableWrapper>
  )
}

const GrafanaPagination = ({
  currentPage,
  rowCount,
  rowsPerPage,
  onChangePage,
}: PaginationComponentProps) => {
  const numberOfPages = calculateTotalPages({
    totalSize: rowCount,
    pageSize: rowsPerPage,
  })

  if (numberOfPages <= 1) {
    return null
  }

  return (
    <PaginationWrapper>
      <Pagination
        currentPage={currentPage}
        numberOfPages={numberOfPages}
        onNavigate={(page: number) => onChangePage(page, rowsPerPage)}
      />
    </PaginationWrapper>
  )
}

const SortIcon = <T,>({ orderBy, columns }: SortIconProps<T>) => {
  const ref = useRef<HTMLDivElement>(null)
  const [column, setColumn] = useState<TableColumn<T>>()

  // When default sorting is set
  useEffect(() => {
    if (ref) {
      setColumn(columns[getSortedColumnIndex(ref)])
    }
  }, [columns, ref])

  const order = getColumnSortOrder({ column, orderBy })

  if (!order) {
    ref.current?.parentElement?.parentElement?.classList.remove('active')
  } else {
    ref.current?.parentElement?.parentElement?.classList.add('active')
  }

  return (
    <SortIconWrapper ref={ref} $isSorted={!!order}>
      <Icon
        aria-hidden
        name={
          !order ? 'angle-right' : order === 'desc' ? 'angle-down' : 'angle-up'
        }
      />
    </SortIconWrapper>
  )
}

const getSortedColumnIndex = (ref: RefObject<HTMLDivElement>) => {
  const columnId =
    ref.current?.parentElement?.parentElement?.getAttribute('data-column-id')
  return !ref.current || !columnId ? -1 : +columnId - 1
}

const getColumnSortOrder = <T,>({
  column,
  orderBy,
}: {
  orderBy?: string
  column?: TableColumn<T>
}) => {
  const [field, order] = (orderBy || '').split(' ')
  return !!field && !!order && column?.sortField === field ? order : undefined
}
