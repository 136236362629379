import React, { useState } from 'react'
import { ButtonProps, Menu } from '@grafana/ui'

import { Test, TestRun } from 'types'
import { useHasUserProjectWriteAccess } from 'data/usePermissions'
import { useSubscriptionRule } from 'data/useSubscriptions'
import { ActionsMoreDropdown } from 'components/ActionsMoreDropdown'
import { useConfirmation } from 'hooks/useConfirmation'
import { useSetAsBaseline } from 'data/useSetAsBaseline'
import { useToggleSafe } from 'data/useToggleSafe'
import { docs } from 'components/DocLink'

import { MenuItemExport } from './MenuItemExport'
import { ExportData } from './ExportData'
import { SetAsBaseline } from './SetAsBaseline'
import { canExportSummary } from './Options.utils'
import { isTestActive, isTestRunExpired, isTestSafe } from 'utils/testRun'
import { ToggleSafe } from './ToggleSafe'
import {
  MaxSavedRunsModal,
  useMaxSavedRunsModal,
} from 'components/MaxSavedRunsModal'
import { SummaryExportModal } from '../../SummaryExport/SummaryExportModal'
import { useCreateDashboardPermission } from 'hooks/useGrafanaRBAC'

export interface OptionsProps {
  test: Test
  testRun: TestRun
  onDeleteRun: () => void
  fill?: ButtonProps['fill']
  variant?: ButtonProps['variant']
}

export const Options = ({
  test,
  testRun,
  fill,
  variant,
  onDeleteRun,
}: OptionsProps) => {
  const [isExportDataModalOpen, setIsExportDataModalOpen] = useState(false)
  const [isSummaryExportModalOpen, setIsExportSummaryModalOpen] =
    useState(false)

  const { mutateAsync: setAsBaseline } = useSetAsBaseline()
  const { mutateAsync: toggleSafe } = useToggleSafe()

  const [setAsBaselineConfirmationProps, handleConfirmSetAsBaseline] =
    useConfirmation(async () => {
      await setAsBaseline(testRun)
    })

  const [toggleSafeConfirmationProps, handleConfirmToggleSafe] =
    useConfirmation(async () => {
      await toggleSafe(testRun)
    })

  const isSafe = isTestSafe(testRun)

  const { onToggleSafe, ...maxSavedRunsModalProps } = useMaxSavedRunsModal(
    handleConfirmToggleSafe
  )

  const handleExportData = () => {
    setIsExportDataModalOpen(true)
  }

  const handleExportSummary = () => {
    setIsExportSummaryModalOpen(true)
  }

  const handleExportDataModalDismiss = () => {
    setIsExportDataModalOpen(false)
  }

  const handleSummaryExportModalClose = () => {
    setIsExportSummaryModalOpen(false)
  }

  return (
    <>
      <ActionsMoreDropdown
        fill={fill}
        variant={variant}
        overlay={
          <DropMenu
            testRun={testRun}
            onDeleteRun={onDeleteRun}
            onExportData={handleExportData}
            onExportSummary={handleExportSummary}
            onSetAsBaseline={handleConfirmSetAsBaseline}
            onToggleSafe={isSafe ? handleConfirmToggleSafe : onToggleSafe}
          />
        }
      />

      <ExportData
        test={test}
        testRun={testRun}
        isModalOpen={isExportDataModalOpen}
        onModalDismiss={handleExportDataModalDismiss}
      />

      <SetAsBaseline {...setAsBaselineConfirmationProps} />
      <ToggleSafe testRun={testRun} {...toggleSafeConfirmationProps} />
      <MaxSavedRunsModal {...maxSavedRunsModalProps} />
      <SummaryExportModal
        isOpen={isSummaryExportModalOpen}
        test={test}
        testRun={testRun}
        onClose={handleSummaryExportModalClose}
      />
    </>
  )
}

interface DropMenuProps extends Pick<OptionsProps, 'testRun' | 'onDeleteRun'> {
  onExportData: () => void
  onExportSummary: () => void
  onSetAsBaseline: () => void
  onToggleSafe: () => void
}

const DropMenu = ({
  testRun,
  onDeleteRun,
  onExportSummary,
  onExportData,
  onSetAsBaseline,
  onToggleSafe,
}: DropMenuProps) => {
  const hasUserProjectWriteAccess = useHasUserProjectWriteAccess()
  const createDashboardPermission = useCreateDashboardPermission()
  const maxSafeCount = useSubscriptionRule('data.retention_safe.max')
  const isSafe = isTestSafe(testRun)
  const isRunExpired = isTestRunExpired(testRun)

  return (
    <Menu>
      <Menu.Item
        label="Set as baseline"
        icon="star"
        disabled={
          testRun.is_baseline || !hasUserProjectWriteAccess || isRunExpired
        }
        onClick={onSetAsBaseline}
      />
      <Menu.Item
        label={isSafe ? 'Remove safe status' : 'Save test result'}
        icon="lock"
        disabled={
          !hasUserProjectWriteAccess || maxSafeCount === 0 || isRunExpired
        }
        onClick={onToggleSafe}
      />

      <Menu.Divider />

      <MenuItemExport testRun={testRun} onClick={onExportData} />

      <Menu.Item
        disabled={
          !canExportSummary(testRun) || createDashboardPermission === 'none'
        }
        label="Export summary"
        icon="dashboard"
        onClick={onExportSummary}
      />

      <Menu.Divider />

      <Menu.Item
        label="Help"
        icon="question-circle"
        url={docs.analyze.inspect.performanceOverview}
        target="_blank"
      />

      <Menu.Divider />

      <Menu.Item
        disabled={!hasUserProjectWriteAccess || isTestActive(testRun)}
        label="Delete test run"
        icon="trash-alt"
        destructive
        onClick={onDeleteRun}
      />
    </Menu>
  )
}
