import { StaticIPsCalculatorFormValues } from './Form/StaticIPsCalculator.types'

export const staticIPsFormDefaultValues: StaticIPsCalculatorFormValues = {
  parallelTests: 5,
  maximumVUs: 100,
  staticIPsToAcquire: 2,
  includeOwnedIPs: true,
  loadZones: [
    { loadZone: 'amazon:us:ashburn', distribution: 100, staticIPs: 2 },
  ],
}
