import { useMemo } from 'react'
import {
  useQuery,
  useQueries,
  type UseQueryOptions,
} from '@tanstack/react-query'

import type { ApiError, TestRun, TestRunWithMetrics } from 'types'
import type {
  InsightAudit,
  InsightAuditResponse,
  InsightAuditResult,
  InsightAuditResultResponse,
  InsightCategory,
  InsightCategoryResponse,
  InsightCategoryResultWithTestRun,
  InsightCategoryResultResponse,
  InsightExecution,
  InsightExecutionWithTestRun,
  InsightExecutionResponse,
  InsightGroup,
  InsightGroupResponse,
  InsightPartialTestRun,
  InsightExecutionResultResponse,
  InsightExecutionResultWithTestRun,
} from 'types/cloudInsights'
import { K6DataSource } from 'datasource/datasource'
import { useDatasource } from 'datasourceContext'
import {
  getLatestExecution,
  toCategoryWithTestRun,
  toExecutionWithTestRun,
  toExecutionResultWithTestRun,
} from 'utils/insights'

import {
  toInsightsAuditsQueryKey,
  toInsightsAuditsResultsQueryKey,
  toInsightsCategoriesQueryKey,
  toInsightsCategoriesResultsQueryKey,
  toInsightsGroupsQueryKey,
  toInsightsExecutionsQueryKey,
  toInsightsExecutionResultsQueryKey,
} from './queryKeys'

const REFRESH_INTERVAL = 3000

type TestRunVariant = TestRun | TestRunWithMetrics | InsightPartialTestRun

const createInsightsExecutionsQuery = (
  ds: K6DataSource,
  testRun?: TestRunVariant,
  options: UseQueryOptions<
    InsightExecutionResponse,
    ApiError,
    InsightExecutionWithTestRun[]
  > = {}
): UseQueryOptions<
  InsightExecutionResponse,
  ApiError,
  InsightExecutionWithTestRun[]
> => ({
  queryKey: toInsightsExecutionsQueryKey(testRun?.id),
  queryFn: () => ds.fetchInsightsExecutions(testRun!.id),
  select: testRun && toExecutionWithTestRun(testRun),
  staleTime: Infinity,
  useErrorBoundary: options.useErrorBoundary ?? false,
  enabled: !!testRun && (options?.enabled ?? true),
})

export const useInsightsExecutions = (
  testRun?: TestRunVariant,
  options: UseQueryOptions<
    InsightExecutionResponse,
    ApiError,
    InsightExecutionWithTestRun[]
  > = {}
) => {
  const { ds } = useDatasource()

  return useQuery<
    InsightExecutionResponse,
    ApiError,
    InsightExecutionWithTestRun[]
  >(createInsightsExecutionsQuery(ds, testRun, options))
}

export const useInsightsExecutionsList = (
  testRuns: TestRunVariant[],
  options: UseQueryOptions<
    InsightExecutionResponse,
    ApiError,
    InsightExecutionWithTestRun[]
  > = {}
) => {
  const { ds } = useDatasource()

  return useQueries<
    Array<
      UseQueryOptions<
        InsightExecutionResponse,
        ApiError,
        InsightExecutionWithTestRun[]
      >
    >
  >({
    queries: testRuns.map((testRun) =>
      createInsightsExecutionsQuery(ds, testRun, options)
    ),
  })
}

export const useInsightsLatestExecution = (
  testRun?: TestRunVariant,
  options: UseQueryOptions<
    InsightExecutionResponse,
    ApiError,
    InsightExecutionWithTestRun[]
  > = {}
) => {
  const { data: executions = [], ...props } = useInsightsExecutions(
    testRun,
    options
  )

  const latest = useMemo(() => {
    return getLatestExecution(executions)
  }, [executions])

  return { data: latest, ...props }
}

export const useInsightsLatestExecutionList = (
  testRuns: TestRunVariant[],
  options: UseQueryOptions<
    InsightExecutionResponse,
    ApiError,
    InsightExecutionWithTestRun[]
  > = {}
) => {
  const executionsListQueries = useInsightsExecutionsList(testRuns, options)

  return useMemo(() => {
    const result = executionsListQueries
      .map(({ data = [] }) => getLatestExecution(data))
      .filter(Boolean)

    return result
  }, [executionsListQueries])
}

const createInsightsExecutionResultQuery = (
  ds: K6DataSource,
  testRun?: InsightPartialTestRun,
  execution?: InsightExecution,
  options?: { enabled?: boolean; useErrorBoundary?: boolean }
): UseQueryOptions<
  InsightExecutionResultResponse,
  ApiError,
  InsightExecutionResultWithTestRun
> => ({
  queryKey: toInsightsExecutionResultsQueryKey(testRun?.id, execution?.id),
  queryFn: () => ds.fetchInsightsExecutionResult(testRun!.id, execution!.id),
  initialData: (testRun
    ? toExecutionResultWithTestRun(testRun)
    : {}) as InsightExecutionResultWithTestRun,
  select: toExecutionResultWithTestRun(testRun!),
  useErrorBoundary: options?.useErrorBoundary ?? false,
  enabled: !!testRun?.id && !!execution && (options?.enabled ?? true),
  refetchInterval: (result) => {
    const hasData = !!result
    const isDone = result?.status !== 'executing'

    if (hasData && isDone) {
      return 0
    }

    return REFRESH_INTERVAL
  },
})

export const useInsightsExecutionResult = (
  testRun?: TestRunVariant,
  execution?: InsightExecution
) => {
  const { ds } = useDatasource()

  return useQuery<
    InsightExecutionResultResponse,
    ApiError,
    InsightExecutionResultWithTestRun
  >(createInsightsExecutionResultQuery(ds, testRun, execution))
}

export const useInsightsLatestExecutionResult = (testRun?: TestRunVariant) => {
  const { data: execution } = useInsightsLatestExecution(testRun)

  return useInsightsExecutionResult(testRun, execution)
}

export const useInsightsExecutionResultList = (
  executions: InsightExecutionWithTestRun[] = [],
  options?: { useErrorBoundary?: boolean }
) => {
  const { ds } = useDatasource()

  return useQueries<
    Array<
      UseQueryOptions<
        InsightExecutionResultResponse,
        ApiError,
        InsightExecutionResultWithTestRun
      >
    >
  >({
    queries: executions.map((execution) =>
      createInsightsExecutionResultQuery(
        ds,
        execution.test_run,
        execution,
        options
      )
    ),
  })
}

const createInsightsCategoriesQuery = (
  ds: K6DataSource,
  testRun: InsightPartialTestRun,
  execution?: InsightExecution,
  options: UseQueryOptions<
    InsightCategoryResponse,
    ApiError,
    InsightCategory[]
  > = {}
): UseQueryOptions<InsightCategoryResponse, ApiError, InsightCategory[]> => ({
  queryKey: toInsightsCategoriesQueryKey(testRun?.id, execution?.id),
  queryFn: () => ds.fetchInsightsCategories(testRun!.id, execution!.id),
  select: (data) => data.categories,
  staleTime: Infinity,
  useErrorBoundary: options.useErrorBoundary ?? true,
  enabled: !!testRun && !!execution,
})

export const useInsightsCategories = (
  testRun?: TestRunVariant,
  execution?: InsightExecution,
  options: UseQueryOptions<
    InsightCategoryResponse,
    ApiError,
    InsightCategory[]
  > = {}
) => {
  const { ds } = useDatasource()

  return useQuery<InsightCategoryResponse, ApiError, InsightCategory[]>(
    createInsightsCategoriesQuery(ds, testRun!, execution, options)
  )
}

export const useInsightsCategoriesList = (
  executions: InsightExecutionWithTestRun[] = []
) => {
  const { ds } = useDatasource()

  return useQueries<
    Array<UseQueryOptions<InsightCategoryResponse, ApiError, InsightCategory[]>>
  >({
    queries: executions.map((execution) =>
      createInsightsCategoriesQuery(ds, execution.test_run, execution)
    ),
  })
}

const createInsightsCategoriesResultsQuery = (
  ds: K6DataSource,
  testRun: InsightPartialTestRun,
  execution?: InsightExecution
): UseQueryOptions<
  InsightCategoryResultResponse,
  ApiError,
  InsightCategoryResultWithTestRun[]
> => ({
  queryKey: toInsightsCategoriesResultsQueryKey(testRun?.id, execution?.id),
  queryFn: () => ds.fetchInsightsCategoriesResults(testRun?.id, execution!.id),
  select: toCategoryWithTestRun(testRun || {}),
  useErrorBoundary: false,
  enabled: !!testRun?.id && !!execution,
  refetchInterval: (results = []) => {
    const hasData = !!results.length
    const isDone = results.every((result) => result.status !== 'executing')

    if (hasData && isDone) {
      return 0
    }

    return REFRESH_INTERVAL
  },
})

export const useInsightsCategoriesResults = (
  testRun: TestRunVariant,
  execution?: InsightExecution
) => {
  const { ds } = useDatasource()

  return useQuery<
    InsightCategoryResultResponse,
    ApiError,
    InsightCategoryResultWithTestRun[]
  >(createInsightsCategoriesResultsQuery(ds, testRun, execution))
}

export const useInsightsCategoriesResultsList = (
  executions: InsightExecutionWithTestRun[] = []
) => {
  const { ds } = useDatasource()

  return useQueries<
    Array<
      UseQueryOptions<
        InsightCategoryResultResponse,
        ApiError,
        InsightCategoryResultWithTestRun[]
      >
    >
  >({
    queries: executions.map((execution) =>
      createInsightsCategoriesResultsQuery(ds, execution.test_run, execution)
    ),
  })
}

export const useInsightsGroups = (
  testRun: TestRunVariant,
  execution?: InsightExecution
) => {
  const { ds } = useDatasource()

  return useQuery<InsightGroupResponse, ApiError, InsightGroup[]>({
    queryKey: toInsightsGroupsQueryKey(testRun.id, execution?.id),
    queryFn: () => ds.fetchInsightsGroups(testRun.id!, execution!.id),
    select: (data) => data.groups,
    staleTime: Infinity,
    useErrorBoundary: true,
    enabled: !!testRun && !!execution,
  })
}

export const useInsightsAudits = (
  testRun: TestRunVariant,
  execution?: InsightExecution
) => {
  const { ds } = useDatasource()

  return useQuery<InsightAuditResponse, ApiError, InsightAudit[]>({
    queryKey: toInsightsAuditsQueryKey(testRun.id, execution?.id),
    queryFn: () => ds.fetchInsightsAudits(testRun.id!, execution!.id),
    select: (data) => data.audits,
    staleTime: Infinity,
    useErrorBoundary: true,
    enabled: !!testRun && !!execution,
  })
}

export const useInsightsAuditsResults = (
  testRun: TestRunVariant,
  execution?: InsightExecution
) => {
  const { ds } = useDatasource()

  return useQuery<InsightAuditResultResponse, ApiError, InsightAuditResult[]>({
    queryKey: toInsightsAuditsResultsQueryKey(testRun.id, execution?.id),
    queryFn: () => ds.fetchInsightsAuditsResults(testRun.id!, execution!.id),
    select: (data) => data.audits,
    useErrorBoundary: false,
    enabled: !!testRun && !!execution,
    refetchInterval: (results = []) => {
      const hasData = !!results.length
      const isDone = results.every((result) => result.status !== 'executing')

      if (hasData && isDone) {
        return 0
      }

      return REFRESH_INTERVAL
    },
  })
}
