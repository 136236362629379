import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Project, Test, TestRun } from 'types'
import { routeMap } from 'routeMap'
import {
  isTestAbortedByScriptError,
  isTestRunExpired,
  isTestPreparingToStart,
} from 'utils/testRun'
import { createTestRunPageNavModel } from 'navModels'
import { useFeatureFlag } from 'store/FeatureFlags'
import { useRun } from 'data/useRun'
import { useTest } from 'data/useTest'
import { useDeleteTestRunsConfirmation } from 'data/useDeleteTestRuns'
import { useMediaQuery } from 'hooks/useMediaQuery'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { PluginPage } from 'components/PluginPage'
import { ConfirmModal } from 'components/ConfirmModal'
import { QueryErrorBoundary } from 'components/ErrorBoundary'
import { Sticky } from 'components/Sticky'
import { TestRunPendingState } from 'components/TestRunPendingState'
import { useDismissPendingState } from 'components/TestRunPendingState/TestRunPendingState.hooks'
import { useCurrentProject } from 'projectContext'

import { useTimeRange, TimeRangeProvider } from './TimeRangeContext'
import { Breakdown, TestRunMetaBar } from './components'
import { Actions } from './components/Actions'
import { CloudInsights } from './components/CloudInsights'
import { Insights } from './components/Insights'
import { RunOverview } from './components/RunOverview'
import { TestRunsNav } from './components/TestRunsNav'
import { TimeRangeResetButton } from './components/TimeRangeResetButton'
import { TimeRangeResetButtonWrapper } from './TestRunPage.styles'
import { useInsightsLatestExecution } from 'data/useCloudInsights'
import { ErrorBoundaryLocalView } from 'services/tracking.types'

export const TestRunPage = () => {
  const { runId } = useParams<{ runId: string }>()
  const project = useCurrentProject()

  const pageNav = createTestRunPageNavModel(project)

  const { data: testRun } = useRun(+runId, {
    staleTime: 1000, // Mitigate duplicate requests on app bootstrap
  })

  const { data: test } = useTest(testRun?.test_id)

  const loaded = testRun && test && project

  if (!loaded) {
    return (
      <PluginPage pageNav={pageNav}>
        <CenteredSpinner $height="300px" />
      </PluginPage>
    )
  }

  return (
    <TimeRangeProvider>
      <TestRunPageContent test={test} testRun={testRun} project={project} />
    </TimeRangeProvider>
  )
}

interface TestRunPageContentProps {
  test: Test
  testRun: TestRun
  project: Project
}

const TestRunPageContent = ({
  test,
  testRun,
  project,
}: TestRunPageContentProps) => {
  const history = useHistory()
  const pageNav = createTestRunPageNavModel(project, test, testRun)

  const { timeRange } = useTimeRange()
  const isSmallScreen = useMediaQuery('lg')
  const testId = testRun.test_id

  const [isPendingStateDismissed, dismissPendingState] = useDismissPendingState(
    testRun.id
  )

  const handleOnSuccess = () => {
    history.push(routeMap.test(testId))
  }

  const [confirmModalProps, handleDelete] = useDeleteTestRunsConfirmation(
    testId,
    handleOnSuccess
  )

  if (isTestPreparingToStart(testRun) && !isPendingStateDismissed) {
    return (
      <TestRunPendingState testRun={testRun} onDismiss={dismissPendingState} />
    )
  }

  const actions = (
    <Actions
      test={test}
      testRun={testRun}
      onDeleteRun={() => handleDelete([testRun])}
    />
  )

  return (
    <PluginPage
      pageNav={pageNav}
      subTitle="Test results"
      renderTitle={(title) => (
        <TestRunsNav title={title} test={test} testRun={testRun} />
      )}
      actions={actions}
    >
      <TestRunMetaBar
        isSticky={!isSmallScreen}
        test={test}
        testRun={testRun}
        hideError={isTestAbortedByScriptError(testRun)}
      />
      {!isTestPreparingToStart(testRun) && (
        <>
          <QueryErrorBoundary
            title="Performance Overview"
            content="An error has occurred while fetching the test overview, this can be caused by either poor connectivity or an error with our servers. If you have an ad blocking extension installed in your browser, try disabling it and reload the page."
            view={ErrorBoundaryLocalView.PerformanceOverview}
          >
            <RunOverview analysis={{ main: testRun }} test={test} />
          </QueryErrorBoundary>
          {!isTestRunExpired(testRun) && (
            <QueryErrorBoundary
              title="Performance Insights"
              content="An error has occurred while fetching insights, click retry request to try again. If the problem persists, please contact support."
              view={ErrorBoundaryLocalView.PerformanceInsights}
            >
              <SwitchInsights testRun={testRun} />
            </QueryErrorBoundary>
          )}

          {!isTestRunExpired(testRun) && (
            <QueryErrorBoundary
              title="Performance Details"
              content="An error has occurred while fetching the test data, click retry request to try again. If the problem persists, please contact support."
              view={ErrorBoundaryLocalView.PerformanceDetails}
            >
              <Breakdown test={test} analysis={{ main: testRun }} />
            </QueryErrorBoundary>
          )}
        </>
      )}

      <ConfirmModal title="Confirm action" {...confirmModalProps} />

      {timeRange && isSmallScreen && (
        <Sticky justify="flex-end" placement="bottom">
          <TimeRangeResetButtonWrapper>
            <TimeRangeResetButton />
          </TimeRangeResetButtonWrapper>
        </Sticky>
      )}
    </PluginPage>
  )
}

interface SwitchInsightsProps {
  testRun: TestRun
}

const SwitchInsights = ({ testRun }: SwitchInsightsProps) => {
  const isFeatureCloudInsightsEnabled = useFeatureFlag('cloudInsights')
  const { error } = useInsightsLatestExecution(testRun, {
    enabled: isFeatureCloudInsightsEnabled,
    useErrorBoundary: false,
  })

  const isCloudInsightsEnabled =
    isFeatureCloudInsightsEnabled && error?.status !== 404

  // TODO - Remove insights when this feature is officially released
  if (!isCloudInsightsEnabled) {
    return <Insights testRun={testRun} />
  }

  return <CloudInsights testRun={testRun} />
}
