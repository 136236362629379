import React from 'react'

import { BertMessage } from 'components/BertMessage'

export const NoProjectsAssignedState = () => {
  return (
    <BertMessage
      type="error"
      title="Looks like you don't have any projects assigned."
      description="Contact an admin user to gain access."
    />
  )
}
