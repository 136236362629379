import React, { ReactNode } from 'react'
import { TableBody, TableRoot, TableScrollContainer } from './Table.styles'
import { TableHeader } from './TableHeader'
import { TableDefinition, ViewType } from '../types'
import { LoadingOverlay } from './LoadingOverlay'
import { SortOptions } from 'types'
import { BreakdownTableTestId } from 'types/dataTestIds'

interface TableProps<T, P, S> {
  view: ViewType
  loading: boolean
  table: TableDefinition<T, P>
  sortBy: SortOptions<S> | undefined
  children: ReactNode
  onSortChange: (sortBy: SortOptions<S> | undefined) => void
}

export function Table<T, P, S>({
  view,
  loading,
  table,
  sortBy,
  children,
  onSortChange,
}: TableProps<T, P, S>) {
  return (
    <TableScrollContainer>
      <TableRoot data-testid={BreakdownTableTestId.Table}>
        <thead>
          <TableHeader
            view={view}
            table={table}
            sortBy={sortBy}
            onSortChange={onSortChange}
          />
        </thead>

        <TableBody>
          <LoadingOverlay loading={loading} />
          {children}
        </TableBody>
      </TableRoot>
    </TableScrollContainer>
  )
}
