export const getLoginCommand = (token = '') => `k6 login cloud --token ${token}`

export const getCloudCommand = (scriptName: string) => `k6 cloud ${scriptName}`

export const getRunCommands = (scriptName: string) => [
  {
    label: 'Cloud executed test',
    value: 'cloud',
    code: getCloudCommand(scriptName),
  },
  {
    label: 'Local execution, streamed results',
    value: 'local',
    code: `k6 run --out cloud ${scriptName}`,
  },
]

export const getProjectSnippet = (
  testName: string,
  projectId: number | string
) =>
  `export const options = {
  cloud: {
    projectID: ${projectId},
    // Test runs with the same name groups test runs together
    name: '${testName}'
  }
}`

export const installationTabs = [
  {
    label: 'Linux (Debian/Ubuntu)',
    value: 'debian',
    code: `sudo apt-key adv --keyserver hkp://keyserver.ubuntu.com:80 --recv-keys C5AD17C747E3415A3642D57D77C6C491D6AC1D69
echo "deb https://dl.k6.io/deb stable main" | sudo tee /etc/apt/sources.list.d/k6.list
sudo apt-get update
sudo apt-get install k6`,
  },
  {
    label: 'Linux (Fedora/CentOS)',
    value: 'fedora',
    code: `sudo dnf install https://dl.k6.io/rpm/repo.rpm
sudo dnf install k6`,
  },
  {
    label: 'macOS',
    value: 'osx',
    code: 'brew install k6',
  },
  {
    label: 'Windows',
    value: 'windows',
    code: `// Using Chocolatey to install the unofficial k6 package
choco install k6
// Or download the official k6 installer: https://dl.k6.io/msi/k6-latest-amd64.msi`,
  },
  {
    label: 'Docker',
    value: 'docker',
    code: 'docker pull grafana/k6',
  },
]
