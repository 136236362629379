import { Span, WebVitalRating } from './types'
import {
  ATTR_NAVIGATION_URL,
  ATTR_WEB_VITAL_RATING,
  WEB_VITAL_RATING_GOOD,
  WEB_VITAL_RATING_NEEDS_IMPROVEMENT,
  WEB_VITAL_RATING_POOR,
  WEB_VITAL_RATING_UNKNOWN,
} from './constants'
import { getAttributeValueByKey } from './BrowserTimeline.utils'

export function getNavigationUrl(span: Span) {
  return String(
    getAttributeValueByKey(ATTR_NAVIGATION_URL, span?.attributes ?? []) ?? ''
  )
}

export function getLowestWebVitalRating(spans: Span[]): WebVitalRating {
  const ratings = spans.map((span) => getWebVitalRating(span))

  if (ratings.includes(WEB_VITAL_RATING_POOR)) {
    return WEB_VITAL_RATING_POOR
  }

  if (ratings.includes(WEB_VITAL_RATING_NEEDS_IMPROVEMENT)) {
    return WEB_VITAL_RATING_NEEDS_IMPROVEMENT
  }

  return ratings.includes(WEB_VITAL_RATING_GOOD)
    ? WEB_VITAL_RATING_GOOD
    : WEB_VITAL_RATING_UNKNOWN
}

export function getWebVitalRating(span: Span): WebVitalRating {
  const rating = getAttributeValueByKey(
    ATTR_WEB_VITAL_RATING,
    span.attributes ?? []
  )

  return rating === null ? '-' : (rating as WebVitalRating)
}
