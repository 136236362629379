import type { Project } from 'types'
import { format } from 'date-fns'

export const getCreateScriptSnippet = (project: Project) =>
  `import http from 'k6/http';
import { sleep } from 'k6';

export const options = {
  vus: 10,
  duration: '30s',
  cloud: {
    // Project: ${project.name}
    projectID: ${project.id},
    // Test runs with the same name groups test runs together.
    name: '${`Test (${format(new Date(), 'dd/MM/yyyy-HH:mm:ss')})`}'
  }
};

export default function() {
  http.get('https://test.k6.io');
  sleep(1);
}`
