import React, {
  useMemo,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react'
import styled from 'styled-components'
import { Badge, Icon, IconButton } from '@grafana/ui'

import type { LoadZone } from 'types/loadZone'
import { spacing } from 'utils/styled'
import { useLoadZones } from 'data/useLoadZones'
import { useIsUserOrgAdmin } from 'data/usePermissions'
import { useMediaQuery } from 'hooks/useMediaQuery'
import { BertMessage } from 'components/BertMessage'
import { ClipboardIconButton } from 'components/ClipboardIconButton'
import { DataTable, type TableColumn } from 'components/DataTable'
import { Flex } from 'components/Flex'
import { LoadingContainer } from 'components/LoadingContainer'
import { Tooltip } from 'components/Tooltip'

import { DeleteModal } from '../DeleteModal'
import { getList } from './LoadZoneTable.utils'

interface UseColumnsProps {
  isUserOrgAdmin: boolean
  onDelete: Dispatch<SetStateAction<LoadZone | null>>
}

const useColumns = ({ isUserOrgAdmin, onDelete }: UseColumnsProps) => {
  const isLargeMobile = useMediaQuery('md')

  return useMemo(
    (): Array<TableColumn<LoadZone>> => [
      {
        name: 'Name',
        sortable: true,
        sortField: 'name',
        grow: 1,
        minWidth: '250px',
        cell: ({ name, public: isPublic }) => (
          <Flex align="center" gap={1}>
            {isLargeMobile && (
              <Badge
                aria-label={isPublic ? 'Public' : 'Private'}
                color={isPublic ? 'blue' : 'purple'}
                icon={isPublic ? 'cloud' : 'lock'}
                text=""
              />
            )}

            <LoadZoneTitle>{name}</LoadZoneTitle>

            {!isLargeMobile && (
              <Badge
                color={isPublic ? 'blue' : 'purple'}
                icon={isPublic ? 'cloud' : 'lock'}
                text={isPublic ? 'Public' : 'Private'}
              />
            )}
          </Flex>
        ),
      },
      {
        name: 'ID',
        sortable: true,
        sortField: 'k6_load_zone_id',
        grow: 1,
        minWidth: '200px',
        cell: ({ k6_load_zone_id }) => (
          <Flex gap={1}>
            {k6_load_zone_id}
            <ClipboardIconButton
              data={k6_load_zone_id}
              placement="bottom"
              size="sm"
              aria-label="Copy Load zone Id"
            />
          </Flex>
        ),
      },
      {
        name: 'Status',
        sortField: 'available',
        maxWidth: '150px',
        cell: ({ available }) => {
          return (
            <Flex gap={1}>
              <AvailabilityIcon
                aria-hidden
                name={available ? 'check-circle' : 'times-circle'}
                $isAvailable={available}
              />
              <span>{available ? 'Online' : 'Offline'}</span>
            </Flex>
          )
        },
      },
      {
        name: 'Public',
        selector: ({ public: isPublic }) => isPublic,
        sortField: 'public',
        omit: true,
      },
      {
        name: '',
        grow: 0,
        minWidth: '75px',
        cell: (loadZone) => {
          if (loadZone.public) {
            return null
          }

          const tooltipContent = !isUserOrgAdmin
            ? 'You do not have the permissions to remove load zones'
            : 'You cannot remove active load zones'

          return (
            <Tooltip
              content={tooltipContent}
              show={loadZone.available || !isUserOrgAdmin}
            >
              <ActionCell>
                <IconButton
                  aria-label="Remove saved test"
                  disabled={!isUserOrgAdmin || loadZone.available}
                  name="trash-alt"
                  size="lg"
                  variant="destructive"
                  onClick={() => isUserOrgAdmin && onDelete(loadZone)}
                />
              </ActionCell>
            </Tooltip>
          )
        },
      },
    ],
    [isLargeMobile, isUserOrgAdmin, onDelete]
  )
}

interface LoadZoneTableProps {
  isPublicZonesVisible?: boolean
}

export const LoadZoneTable = ({ isPublicZonesVisible }: LoadZoneTableProps) => {
  const [sort, setSort] = useState<string | undefined>()
  const [zoneToDelete, setZoneToDelete] = useState<LoadZone | null>(null)
  const isUserOrgAdmin = useIsUserOrgAdmin()
  const { data = [], isFetching } = useLoadZones()
  const columns = useColumns({ isUserOrgAdmin, onDelete: setZoneToDelete })
  const list = useMemo(
    () => getList(data, sort, isPublicZonesVisible),
    [data, sort, isPublicZonesVisible]
  )

  return (
    <>
      <LoadingContainer loading={isFetching}>
        <DataTable
          columns={columns}
          data={list}
          noDataComponent={
            <BertMessage
              description={
                <BertMessage.Text>
                  Looks empty here! To get started follow the instructions to
                  create a private load zone.
                </BertMessage.Text>
              }
            />
          }
          onSort={setSort}
        />
      </LoadingContainer>

      <DeleteModal
        loadZone={zoneToDelete}
        onDismiss={() => setZoneToDelete(null)}
      />
    </>
  )
}

const ActionCell = styled.span`
  padding: ${spacing(1)};
  width: 'auto';
`

const AvailabilityIcon = styled(Icon)<{ $isAvailable: boolean }>`
  color: ${({ $isAvailable, theme }) =>
    $isAvailable ? theme.colors.success.main : theme.colors.error.main};
`

const LoadZoneTitle = styled.p`
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
