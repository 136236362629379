import {
  HTTP_FAILURE_RATE,
  HTTP_REQUEST_RATE,
  HTTP_RESPONSE_TIME_95,
  HTTP_RESPONSE_TIME_AVG,
} from 'constants/metrics'
import { createStatPanel } from '../panels'
import { GeneratorContext } from '../types'

export function createStatsRow(context: GeneratorContext) {
  return {
    height: 6,
    panels: [
      createStatPanel('Max Throughput', context, HTTP_REQUEST_RATE),
      createStatPanel('HTTP Failures', context, HTTP_FAILURE_RATE),
      createStatPanel('Avg. Response Time', context, HTTP_RESPONSE_TIME_AVG),
      createStatPanel('95% Response Time', context, HTTP_RESPONSE_TIME_95),
    ],
  }
}
