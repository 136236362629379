import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Input, Modal, Field } from '@grafana/ui'
import styled from 'styled-components'

import { ApiError, Project } from 'types'
import { useProjectNameValidation } from './ProjectMenu.hooks'
import { useUpdateProject } from 'data/useProject'
import { showAlert } from 'utils/showAlert'

interface RenameProjectModalProps {
  project: Project
  isOpen: boolean
  onDismiss: () => void
}

export function RenameProjectModal({
  project,
  isOpen,
  onDismiss,
}: RenameProjectModalProps) {
  const projectNameValidation = useProjectNameValidation(project)
  const { mutate: updateProject, isLoading } = useUpdateProject()

  const {
    handleSubmit,
    register,
    formState: { isDirty, errors },
    setError,
    setFocus,
    reset,
  } = useForm({
    defaultValues: {
      name: project?.name,
    },
  })

  const handleUpdate = (data: { name?: string }) => {
    if (!project?.id || !data.name) {
      return
    }

    updateProject(
      { projectId: project.id, body: { name: data.name } },
      {
        onSuccess: () => {
          onDismiss()
          showAlert('Project successfully updated', 'success')
        },
        onError: (err: ApiError) => {
          const nameError = err?.data?.error?.field_errors?.name

          if (nameError) {
            setError('name', { message: nameError })
            return
          }

          showAlert('Failed to update project, please try again later', 'error')
        },
      }
    )
  }

  useEffect(() => {
    if (project.name) {
      reset({ name: project.name })
    }
  }, [reset, project.name])

  useEffect(() => {
    if (isOpen) {
      setFocus('name')
    }
  }, [isOpen, setFocus])

  return (
    <StyledModal title="Rename project" isOpen={isOpen} onDismiss={onDismiss}>
      <form onSubmit={handleSubmit(handleUpdate)}>
        <StyledField
          label="Project name"
          error={errors.name?.message}
          invalid={!!errors.name}
        >
          <Input {...register('name', projectNameValidation)} />
        </StyledField>
        <Modal.ButtonRow>
          <Button variant="secondary" onClick={onDismiss}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!isDirty || isLoading}
            icon={isLoading ? 'fa fa-spinner' : undefined}
          >
            Rename
          </Button>
        </Modal.ButtonRow>
      </form>
    </StyledModal>
  )
}

const StyledField = styled(Field)`
  margin-bottom: 0;
`

const StyledModal = styled(Modal)`
  width: 480px;
`
