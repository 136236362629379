import { toFormFieldError } from 'utils/form'

import {
  NotificationChannel,
  NotificationChannelMetadataType,
  NotificationClient,
  NotificationChannelDraft,
  NotificationScriptError,
  NotificationScriptErrors,
  NotificationResponseError,
} from '../../NotificationsTab.types'

const isValidUrl = (urlString: string) => {
  try {
    new URL(urlString)
    return true
  } catch (error) {
    return 'This is not a valid URL'
  }
}

const required = {
  required: 'This field is required',
}

export const validation = {
  name: required,
  events: required,
  subject: required,
  emails: required,
}

export const validUrlField = {
  validate: isValidUrl,
}

export const toNotificationClientType = (
  notification: NotificationChannel | NotificationChannelDraft
): NotificationClient => {
  switch (notification.meta_data.initial_type) {
    case NotificationChannelMetadataType.MS_TEAMS:
      return NotificationClient.MS_TEAMS
    case NotificationChannelMetadataType.SLACK:
      return NotificationClient.SLACK
    case NotificationChannelMetadataType.EMAIL:
      return NotificationClient.EMAIL
    default:
      return NotificationClient.WEBHOOK
  }
}

// This function will parse the field error of the "template" field and provide the position of the error, so it can be highlighted in the editor
const toScriptError = (
  errors: string[],
  message: string
): NotificationScriptError => {
  const [_badScript, jsonErrorDetails] = errors as NotificationScriptErrors

  if (jsonErrorDetails) {
    return {
      line: jsonErrorDetails.lineno,
      position: jsonErrorDetails.colno,
      messageWithPosition: message,
      // In the following message: "Template render resulted in invalid JSON: Expecting ',' delimiter: line 3 column 3 (char 26)"
      // the regex will filter out the position details: "Template render resulted in invalid JSON: Expecting ',' delimiter
      message: message.replace(/:\sline\s\d+\scolumn\s\d+\s\(char\s\d+\)/, ''),
    }
  }

  // In the following message: "Jinja2 Template parsing (line 4): unexpected '/'", the regex will fetch the line number
  const lineMatch = message.match(/line (\d+)/)
  const line = lineMatch ? lineMatch[1] : undefined
  return {
    line: line ? Number(line) : undefined,
    messageWithPosition: message,
    // In the following message: "Jinja2 Template parsing (line 4): unexpected '/'", the regex will remove the line number statement
    message: message.replace(/\s*\(line \d+\)/, ''),
  }
}

const toNotificationScriptErrorDetails = (
  error: NotificationResponseError
): NotificationScriptError => {
  const message = error.data?.error.message
  // It is safe to assume that this object will be present because this function will be invoked only during script validation
  const scriptError = error.data!.error.field_errors!.template
  return toScriptError(scriptError, message || '')
}

export const toNotificationScriptError = (error: NotificationResponseError) => {
  const errorDetails = toNotificationScriptErrorDetails(error)
  const fieldError = toFormFieldError(
    errorDetails.messageWithPosition || errorDetails.message
  )

  return {
    errorDetails,
    fieldError,
  }
}
