import React from 'react'
import styled from 'styled-components'
import { spacing } from 'utils/styled'

interface LogoProps {
  className?: string
}

const StyledSvg = styled.svg`
  height: 32px;
  width: 32px;
  margin-right: ${spacing(2)};
`

export const Logo = ({ className }: LogoProps) => (
  <StyledSvg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 373 361.997"
  >
    <path
      id="prefix__k6_logo_-_Purple_default_active_"
      d="M373.5 362H.5L124.674 97.127l74.8 54.989L297.037 0zm-136.76-57.21h.8a35.615 35.615 0 0024.76-9.952 32.462 32.462 0 0010.644-24.481 29.719 29.719 0 00-10.258-23.5 31.209 31.209 0 00-21.287-9.167h-.612a11.123 11.123 0 00-3.1.425l19.691-29.27-15.689-10.945-7.424 10.95-18.972 28.978c-3.26 4.8-5.988 9.034-7.677 11.974a68.856 68.856 0 00-4.511 9.7 30.175 30.175 0 00-2.155 11.176 32.426 32.426 0 0010.511 24.215 35.075 35.075 0 0024.534 9.952zm-83.394-31.506l21.74 30.747h23.243l-25.572-35.67 22.712-31.532-15.075-10.429-6.652 8.781-20.423 28.792v-57.89l-20.356-16.591v114.524h20.356v-30.761zm83.421 12.307a14.968 14.968 0 110-29.936h.133a14.632 14.632 0 0110.55 4.511 13.936 13.936 0 014.511 10.377 15.207 15.207 0 01-15.181 15.021z"
      transform="translate(-.5 -.001)"
      style={{ fill: '#7d64ff' }}
    />
  </StyledSvg>
)
