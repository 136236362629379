import { useState } from 'react'
import { useInterval } from 'usehooks-ts'

import { TestRun } from 'types'
import { isTestRunning } from 'utils/testRun'
import {
  getTestRunColorString,
  getTestRunBadgeIcon,
  getTestStatusText,
} from 'pages/utils'

const COUNTDOWN_INTERVAL = 1000

export function useTestRunStatus(testRun?: TestRun) {
  const isRunning = testRun && isTestRunning(testRun)
  const count = useCountdown(testRun)
  const statusColor = getTestRunColorString(testRun)
  const statusIcon = getTestRunBadgeIcon(testRun)
  const status = testRun ? getTestStatusText(testRun) : ``
  const width = isRunning ? '150px' : undefined
  const statusText = !isRunning ? status : `Remaining - ${count}`

  return {
    statusColor,
    statusIcon,
    statusText,
    width,
  }
}

const useCountdown = (testRun?: TestRun) => {
  const [count, setCount] = useState(getCountdownTimer(testRun))
  useInterval(
    () => setCount(getCountdownTimer(testRun)),
    testRun && isTestRunning(testRun) ? COUNTDOWN_INTERVAL : null
  )

  return count
}

const getCountdownTimer = (testRun?: TestRun) => {
  if (!testRun || !testRun.started) {
    return '--:--:--'
  }

  const duration = testRun.duration * 1000 // in milliseconds
  const started = new Date(testRun.started).getTime()
  const now = Date.now()
  const end = started + duration

  if (end > now) {
    const { totalHours, minutes, seconds } = getTimeRemaining(end, now)
    return `${totalHours}:${minutes}:${seconds}`
  }

  return '--:--:--'
}

const getTimeRemaining = (endTime: number, timeNow: number) => {
  if (endTime > timeNow) {
    const total = endTime - timeNow

    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor((total / 1000 / 60) % 60)
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
    const days = Math.floor(total / (1000 * 60 * 60 * 24))

    const totalHours = days * 24 + hours

    return {
      total,
      days: days,
      hours: pad(hours),
      totalHours: pad(totalHours),
      minutes: pad(minutes),
      seconds: pad(seconds),
    }
  }

  return {
    hours: '00',
    minutes: '00',
    seconds: '00',
  }
}

const pad = (value: number) => value.toString().padStart(2, '0')
