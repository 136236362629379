import React, { ComponentType } from 'react'
import { UseQueryResult } from '@tanstack/react-query'
import { AbsoluteTimeRange, DataFrame, PanelProps } from '@grafana/data'
import { PanelRenderer, PanelRendererProps } from '@grafana/runtime'
import { GraphFieldConfig, PanelContextProvider } from '@grafana/ui'

import type { TestRun } from 'types'
import type { TimeSeriesOptions } from 'types/panels'
import { type MetricAnnotation, type MetricData } from 'datasource/models'
import { isFetched } from 'utils/reactQuery'
import { isTestActive } from 'utils/testRun'

import { NoDataOverlay } from './NoDataOverlay'
import {
  hasSeriesValues,
  isTestRunWithData,
  toTestRunWithData,
} from './Chart.utils'
import { panelContext, toPanelData } from './ChartPanel.utils'
import { DEFAULT_FIELD_CONFIG } from 'utils/fieldConfigs'
import { useFieldOverrides } from './ChartPanel.hooks'
import { timeSeriesPanelOptions } from './panelOptions'

export const TypedPanelRenderer = PanelRenderer as ComponentType<
  PanelRendererProps<PanelProps<TimeSeriesOptions>['options'], GraphFieldConfig>
>

interface ChartPanelProps {
  testRun: TestRun
  compareWith: TestRun | undefined
  series: Array<UseQueryResult<MetricData>>
  annotations: MetricAnnotation[] | DataFrame[]
  width: number
  height: number
  timeRange?: AbsoluteTimeRange
  onChangeTimeRange?: (timeRange: AbsoluteTimeRange) => void
}

export const ChartPanel = ({
  testRun,
  compareWith,
  series,
  annotations,
  width,
  height,
  timeRange,
  onChangeTimeRange,
}: ChartPanelProps) => {
  const { overrides, onToggleSeriesVisibility } = useFieldOverrides(series)

  if (!isTestRunWithData(testRun) || !hasSeriesValues(series)) {
    return (
      <NoDataOverlay>
        {isTestActive(testRun) && 'Waiting for initial data to arrive...'}
      </NoDataOverlay>
    )
  }

  const panelData = toPanelData(
    testRun,
    toTestRunWithData(compareWith),
    series.some((item) => item.isLoading),
    series.filter(isFetched).map((item) => item.data),
    annotations,
    timeRange
  )

  return (
    <PanelContextProvider value={{ ...panelContext, onToggleSeriesVisibility }}>
      <TypedPanelRenderer
        title=""
        pluginId="timeseries"
        onOptionsChange={() => {}}
        width={width}
        height={height}
        data={panelData}
        options={timeSeriesPanelOptions}
        fieldConfig={{ defaults: DEFAULT_FIELD_CONFIG, overrides }}
        onChangeTimeRange={onChangeTimeRange}
      />
    </PanelContextProvider>
  )
}
