import React, { useCallback, useState } from 'react'

import { type TestRun } from 'types'
import { type InsightAudit } from 'types/cloudInsights'
import { CloudInsightTestId } from 'types/dataTestIds'
import { isInsightSucceeded } from 'utils/insights'
import { CollapsableSection } from 'components/CollapsableSection'
import { LoadingContainer } from 'components/LoadingContainer'

import { useAuditQueryParams } from '../../hooks'
import { INITIAL_HEIGHT } from './constants'
import { Content } from './Content'
import { Header } from './Header'
import { useAuditResult } from './CloudInsightsAudit.hooks'
import { Container } from './CloudInsightsAudit.styles'

interface CloudInsightsAuditProps {
  audit: InsightAudit
  testRun: TestRun
}

export const CloudInsightsAudit = ({
  audit,
  testRun,
}: CloudInsightsAuditProps) => {
  const [{ filter }, setAuditQueryParam] = useAuditQueryParams(testRun)
  const [isOpen, setIsOpen] = useState(filter === audit.id)
  const result = useAuditResult(audit.id, testRun)

  const isSucceeded = isInsightSucceeded(result)

  const handleSectionToggle = useCallback(() => {
    if (!isSucceeded) {
      return
    }

    setAuditQueryParam('replace', {
      filter: audit.id,
      open: !isOpen,
    })

    setIsOpen(!isOpen)
  }, [audit.id, isSucceeded, isOpen, setAuditQueryParam])

  return (
    <Container data-testid={CloudInsightTestId.Audit}>
      <CollapsableSection
        disabled={!isSucceeded}
        label={<Header audit={audit} result={result} testRun={testRun} />}
        isOpen={isSucceeded && isOpen}
        onToggle={handleSectionToggle}
      >
        <LoadingContainer loading={!result} estimatedHeight={INITIAL_HEIGHT}>
          <Content audit={audit} result={result} />
        </LoadingContainer>
      </CollapsableSection>
    </Container>
  )
}
