import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { color, spacing } from 'utils/styled'

export const Overlay = styled.div`
  width: 240px;
  background-color: ${color('background', 'primary')};
  box-shadow: ${({ theme }) => theme.shadows.z3};
`

export const SearchFieldWrapper = styled.div`
  padding: ${spacing(1)};
`

export const CreateNewProjectBtnContainer = styled.div`
  padding: ${spacing(1)};

  button {
    width: 100%;
  }
`

export const ProjectList = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 320px;
`

export const NoResultsMessage = styled.div`
  padding: ${spacing(0, 2)};
  line-height: ${spacing(4)};
  color: ${color('text', 'primary')};
`

export const ProjectLink = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${spacing(1)};
  padding: ${spacing(0, 2)};
  min-height: ${spacing(4)};
  line-height: ${spacing(4)};
  color: ${color('text', 'primary')};

  &:hover,
  &:focus,
  &:focus-visible {
    background: ${color('action', 'hover')};
    color: ${color('text', 'primary')};
    text-decoration: none;
  }
`

export const ProjectLinkLabel = styled.span`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
