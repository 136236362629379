import styled from 'styled-components'
import { color, spacing } from 'utils/styled'

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;

  form {
    margin-top: ${spacing(1)};
    padding: ${spacing(2)};
    background-color: ${color('background', 'primary')};

    // Targets text inputs, added for consistency with other disabled inputs and buttons
    input:disabled {
      cursor: not-allowed;
    }
  }
`

export const FormButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${spacing(2)};
`
