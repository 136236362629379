import React from 'react'
import { useLocation } from 'react-router-dom'
import { Project } from 'types'
import { TestDraft, toNewScriptDraft } from './drafts'
import { EditorChunk } from './EditorChunk'
import { PluginPage } from 'components/PluginPage'
import { createNewScriptTestPageNavModel } from 'navModels'
import { useEditingSession } from './Editor.hooks'

interface NewScriptEditorLocationState {
  scriptValue?: string
}

interface NewScriptEditorProps {
  project: Project
}

export function NewTestScript({ project }: NewScriptEditorProps) {
  const location = useLocation<NewScriptEditorLocationState>()
  const pageNav = createNewScriptTestPageNavModel(project)

  const [draft, setDraft] = useEditingSession<TestDraft>({
    initialValue: () => toNewScriptDraft(project, location.state?.scriptValue),
    projectId: project.id,
  })

  const handleDraftChange = (draft: TestDraft) => {
    setDraft(draft)
  }

  return (
    <PluginPage pageNav={pageNav}>
      <EditorChunk
        draft={draft}
        project={project}
        onChange={handleDraftChange}
      />
    </PluginPage>
  )
}
