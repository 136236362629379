import styled, { css } from 'styled-components'
import { fadeIn } from 'theme'

export const Body = styled.div<{ $fullWidth?: boolean }>`
  display: flex;
  margin: 0 auto;
  flex-flow: column nowrap;
  align-items: center;
  max-width: 940px;
  ${fadeIn};
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
`
