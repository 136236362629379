import { IStandaloneCodeEditor } from 'components/GrafanaMonaco'

export function displayOverlayMessage(
  editor: IStandaloneCodeEditor,
  script: string | null,
  template: HTMLElement
) {
  if (script !== null) {
    return null
  }

  const message = template.cloneNode(true) as HTMLElement

  message.style.display = 'flex'

  editor.getDomNode()?.appendChild(message)

  return {
    dispose() {
      editor.getDomNode()?.removeChild(message)
    },
  }
}
