import styled from 'styled-components'
import { Checkbox, InlineSwitch } from '@grafana/ui'
import { fontSize, spacing } from 'utils/styled'

export const MenuGroupBody = styled.div`
  padding: ${spacing(0, 1)};
`

export const MenuSwitch = styled(InlineSwitch).attrs({
  transparent: true,
  showLabel: true,
})`
  display: flex;
  flex: 1 1 0;
  padding: 0;

  label {
    font-size: ${fontSize('bodySmall')};
    font-weight: 500;
    margin: 0;
    padding: 0;
    flex: 1 1 0;
    user-select: none;
  }
`

export const ColumnPicker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing(1)};
  margin-top: ${spacing(1)};
`

export const MenuCheckbox = styled(Checkbox)`
  padding-right: ${spacing(1)};
  user-select: none;
`
