import React, { useCallback, useState } from 'react'
import { Alert, Button, Checkbox } from '@grafana/ui'
import { Flex } from 'components/Flex'
import { DocLink, docs } from 'components/DocLink'
import { usePlzBannerDismiss } from 'hooks/usePlzBannerDismiss'
import styled, { css } from 'styled-components'
import { Test } from 'types'
import { spacing } from 'utils/styled'
import { isIngestTest } from 'utils/test'

interface PrivateLoadZonesBannerProps {
  test: Test
}

export const PrivateLoadZonesBanner = ({
  test,
}: PrivateLoadZonesBannerProps) => {
  const { shouldDisplayBanner, onDismissForType, onDismissForTest, onSnooze } =
    usePlzBannerDismiss(test)

  const [isDismissForAllChecked, setIsDismissForAllChecked] = useState(false)

  const handleOnDismiss = useCallback(() => {
    if (isDismissForAllChecked) {
      onDismissForType()
    } else {
      onDismissForTest()
    }
  }, [isDismissForAllChecked, onDismissForTest, onDismissForType])

  if (!shouldDisplayBanner) {
    return null
  }

  return (
    <Banner title="" severity="info">
      <Container
        justify="space-between"
        align="flex-end"
        direction="row"
        gap={spacing(1)}
      >
        <Message test={test} />
        <ButtonsContainer justify="flex-end" align="center" gap={1}>
          <Checkbox
            value={isDismissForAllChecked}
            label="Don't show this again"
            onChange={() => {
              setIsDismissForAllChecked(!isDismissForAllChecked)
            }}
          />
          <Button
            size="sm"
            variant="secondary"
            fill="outline"
            onClick={handleOnDismiss}
          >
            Dismiss
          </Button>
          <Button
            size="sm"
            variant="secondary"
            fill="outline"
            onClick={onSnooze}
          >
            Not now
          </Button>
        </ButtonsContainer>
      </Container>
    </Banner>
  )
}

const Message = ({ test }: PrivateLoadZonesBannerProps) => {
  const isIngest = isIngestTest(test)

  if (isIngest) {
    return (
      <p>
        We noticed you&apos;re running your tests using{' '}
        <code>k6 run --out cloud</code>. If you&apos;re using Kubernetes to
        deploy your application, check out the{' '}
        <DocLink article={docs.loadZones.private}>Private Load Zones</DocLink>{' '}
        feature to run distributed k6 tests from <b>within your network</b>.
      </p>
    )
  }

  return (
    <p>
      We noticed you&apos;re running your tests using the k6-operator. You can
      now use Private Load Zones to start Grafana Cloud k6 tests outside of your
      Kubernetes cluster. Refer to Set up{' '}
      <DocLink article={docs.loadZones.private}>Private Load Zones</DocLink> for
      more details.
    </p>
  )
}

const Banner = styled(Alert)`
  p {
    margin-bottom: 0;
  }
`

const Container = styled(Flex)`
  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.values.xl}px) {
      flex-direction: column;
      align-items: unset;
    }
  `}
`

const ButtonsContainer = styled(Flex)`
  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.values.xl}px) {
      flex-direction: row-reverse;
    }
  `};
  min-width: 295px;
`
