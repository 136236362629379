import { TestDraft } from '../drafts'
import { TestDraftSchema, formatErrors } from './schema'
import { ValidationContext } from './types'
import { isNameAvailable, validateWithApi } from './api'
import { z } from 'zod'
import { checkRules } from './rules'
import { ValidationError } from './errors'

function getOptionsObject(draft: z.infer<typeof TestDraftSchema>) {
  switch (draft.type) {
    case 'script':
      return draft.script

    case 'builder':
      return draft.request_builder_config[0].log.options
  }
}

export async function validateDraft(
  context: ValidationContext,
  draft: TestDraft
): Promise<ValidationError[]> {
  const validatedSchema = await TestDraftSchema.safeParseAsync(draft)

  if (!validatedSchema.success) {
    return formatErrors(validatedSchema.error.issues)
  }

  const nameAvailable = await isNameAvailable(context.datasource, draft)

  if (!nameAvailable) {
    return [
      {
        type: 'name-unavailable',
      },
    ]
  }

  // We only show the test builder error when the user has pressed the
  // run button, because it's perfectly valid to want to save a test
  // even though it won't run.
  if (
    context.reason === 'run' &&
    draft.type === 'builder' &&
    draft.error !== undefined
  ) {
    return [
      {
        type: 'test-builder-error',
        details: draft.error,
      },
    ]
  }

  const options = getOptionsObject(validatedSchema.data)

  if (options === null) {
    return []
  }

  const ruleErrors = checkRules(context.rules, options)

  if (ruleErrors.length > 0) {
    return ruleErrors
  }

  return validateWithApi(context.datasource, context.project, options)
}
