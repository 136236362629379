import styled from 'styled-components'
import { spacing } from 'utils/styled'

export const ErrorSectionOverlay = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${spacing(4, 4)};
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-top: ${({ theme }) => theme.isLight && '2px solid #fff'};
  margin-bottom: ${spacing(3)};

  & > * {
    margin-bottom: ${spacing(3)};
  }

  a:last-of-type {
    margin-bottom: 0;
  }
`

export const ErrorDescription = styled.p`
  text-align: center;
`

export const ErrorDetails = styled.pre`
  max-width: 1100px;
  font-size: ${({ theme }) => theme.typography.h6.fontSize};
  color: ${({ theme }) => theme.colors.text.primary};
`

export const ErrorHeading = styled.div`
  color: ${({ theme }) => theme.colors.error.main};
  display: flex;
  align-items: center;
  margin-bottom: ${spacing(1)};

  span {
    font-size: ${({ theme }) => theme.typography.h4.fontSize};
    margin-left: ${spacing(1)};
  }
`
