import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Alert, Drawer, IconButton } from '@grafana/ui'

import { useBackgroundTasks } from 'data/useBackgroundTasks'
import { Flex } from 'components/Flex'
import { ActivityItem } from './ActivityItem'
import { ActivityCount } from './ActivityCount'
import { useActivityPanel } from 'activityPanelContextProvider'
import { useCurrentProject } from 'projectContext'

export const ActivityDrawer = () => {
  const activityPanel = useActivityPanel()
  const project = useCurrentProject()
  const { data: backgroundTasks = [], isError } = useBackgroundTasks()
  const [enableClickOutside, setEnableClickOutside] = useState(true)
  const backgroundTasksCount = backgroundTasks.length

  const handleClose = useCallback(() => {
    activityPanel.close()
  }, [activityPanel])

  const handleOpenModal = () => {
    setEnableClickOutside(false)
  }

  const handleCloseModal = () => {
    setEnableClickOutside(true)
  }

  return (
    <Drawer
      aria-label="Activity panel"
      closeOnMaskClick={enableClickOutside}
      onClose={handleClose}
      title={
        <DrawerTitleWrapper>
          <Flex align="center" gap={2.5}>
            <ActivityCount count={backgroundTasksCount} />
            <MarginLessTitle>
              <div>Activity</div>
              <div className="sr-only">for</div>
              <Subtitle>{project.name}</Subtitle>
            </MarginLessTitle>
          </Flex>
          <div>
            <IconButton
              aria-label="Close activity panel"
              name="times"
              onClick={handleClose}
            />
          </div>
        </DrawerTitleWrapper>
      }
      size="sm"
    >
      {isError && (
        <Alert severity="error" title="Something went wrong">
          We will keep checking for updates once a connection has been
          established
        </Alert>
      )}
      <NegativeMargin>
        {backgroundTasks.map((item) => (
          <ActivityItem
            key={item.test_run_id}
            item={item}
            onModalOpen={handleOpenModal}
            onModalClose={handleCloseModal}
          />
        ))}
      </NegativeMargin>
    </Drawer>
  )
}

const NegativeMargin = styled.div`
  margin: ${({ theme }) => theme.spacing(-2)};
`

const DrawerTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(3, 2)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.weak};
`

const MarginLessTitle = styled.h2`
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};
`

const Subtitle = styled.div`
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
`
