import React from 'react'
import { Project } from 'types'
import { TestDraft, toNewBuilderDraft } from './drafts'
import { EditorChunk } from './EditorChunk'
import { PluginPage } from 'components/PluginPage'
import { createNewBuilderTestPageNavModel } from 'navModels'
import { useEditingSession } from './Editor.hooks'

interface NewScriptEditorProps {
  project: Project
}

export function NewBuilderTest({ project }: NewScriptEditorProps) {
  const pageNav = createNewBuilderTestPageNavModel(project)

  const [draft, setDraft] = useEditingSession<TestDraft>({
    initialValue: () => toNewBuilderDraft(project),
    projectId: project.id,
  })

  const handleDraftChange = (draft: TestDraft) => {
    setDraft(draft)
  }

  return (
    <PluginPage pageNav={pageNav}>
      <EditorChunk
        draft={draft}
        project={project}
        onChange={handleDraftChange}
      />
    </PluginPage>
  )
}
