import React, { useMemo } from 'react'
import { type AbsoluteTimeRange } from '@grafana/data'

import { GrpcUrl, TestRunAnalysis, hasGrpcMetricSummary } from 'types'
import { RunsPageTestIds } from 'types/dataTestIds'
import { FilterExpression, serializeRunFilter } from 'datasource/serialization'

import { toGrpcQueryKey } from 'data/queryKeys'
import { GrpcClient, useGrpcClient } from 'data/clients/entities/grpc'
import { useEnumParameter } from 'hooks/useQueryParameter'
import { EmptyFilterView } from 'components/EmptyFilterView'

import { useTimeRange } from '../../../TimeRangeContext'
import { useRunDetailsGRPCFilters } from '../../Filters/Filters.hooks'
import { byGroup, byRootGroup } from '../utils'
import { TableDefinition, ViewType } from '../BreakdownTable'
import { asCount, asRequestRate, asTiming } from '../ComparedValue'
import { BreakdownView } from '../BreakdownView'
import { TooltipCell } from '../BreakdownTable/Table/TooltipCell'
import { GRPCUrlsChart } from './GRPCUrlsChart'
import { GrpcUrlsFilters } from './GrpcUrlsFilters'
import { getGRPCStatusText } from './GRPCUrlsTab.utils'
import { GRPCStatusTooltipContent } from './GRPCStatusTooltipContent'
import { GRPCUrlCell } from './GRPCUrlCell'
import { GRPCEmptyView } from './GRPCEmptyView'
import { QueryErrorBoundary } from 'components/ErrorBoundary'
import { ErrorBoundaryLocalView } from 'services/tracking.types'

interface GrpcUrlTableProps {
  client: GrpcClient
  filter: FilterExpression<GrpcUrl>
  timeRange: AbsoluteTimeRange | undefined
}

export const table: TableDefinition<GrpcUrl, GrpcUrlTableProps> = {
  id: 'grpc-breakdown',
  paginator: 'always',
  pageSize: 20,

  keyBy(row) {
    return row.id
  },

  getRowCount({ grpc_metric_summary }) {
    return grpc_metric_summary.requests_count ?? 0
  },

  groupBy(row) {
    return row.group_id
  },

  isSuccess(row) {
    return row.expected_response ?? false
  },

  fetchPage({ client, testRun, filter, ...rest }) {
    const params = {
      ...rest,
      filter: byRootGroup(filter),
    }

    return {
      queryKey: toGrpcQueryKey(testRun.id, params),
      queryFn: () => client.fetchPage(testRun.id, params),
    }
  },

  fetchByRows({ client, testRun, rows }) {
    const ids = rows.map((grpc) => grpc.id).sort()

    return {
      queryKey: toGrpcQueryKey(testRun.id, { ids }),
      queryFn: () => client.fetchByIds(testRun.id, ids),
    }
  },

  fetchByGroup({ client, testRun, group, filter, ...rest }) {
    const params = {
      ...rest,
      filter: byGroup(group, filter),
    }

    return {
      queryKey: toGrpcQueryKey(testRun.id, params),
      queryFn: (page) => client.fetchPage(testRun.id, { ...params, page }),
    }
  },

  columns: [
    {
      id: 'name',
      name: 'URL',
      sortBy: 'name',
      toggle: 'none',

      renderCell: ({ host, name }) => <GRPCUrlCell host={host} name={name} />,
      renderGroup: ({ name }) => <>{name}</>,
    },
    {
      id: 'scenario',
      name: 'Scenario',
      sortBy: 'scenario',
      toggle: 'none',

      // There won't be any point of a tooltip if scenario is default
      renderCell: ({ scenario }) => (
        <TooltipCell show={scenario !== 'default'} tooltip={scenario}>
          {scenario}
        </TooltipCell>
      ),
    },
    {
      id: 'status',
      name: 'Status',
      sortBy: 'status',
      toggle: 'none',

      renderCell: ({ status }) => (
        <TooltipCell tooltip={<GRPCStatusTooltipContent status={status} />}>
          {getGRPCStatusText(status)} ({status})
        </TooltipCell>
      ),
      renderGroup: () => <></>,
    },
    {
      id: 'avg_rps',
      name: 'Avg RPS',
      sortBy: 'grpc_metric_summary/rps_mean',
      numeric: true,

      renderCell: asRequestRate(
        ({ grpc_metric_summary }) => grpc_metric_summary.rps_mean
      ),
      renderGroup: asRequestRate(
        ({ grpc_metric_summary }) => grpc_metric_summary.rps_mean
      ),
    },
    {
      id: 'count',
      name: 'Count',
      sortBy: 'grpc_metric_summary/requests_count',
      numeric: true,

      renderCell: asCount(
        ({ grpc_metric_summary }) => grpc_metric_summary.requests_count
      ),
      renderGroup: asCount(
        ({ grpc_metric_summary }) => grpc_metric_summary.requests_count
      ),
    },
    {
      id: 'min',
      name: 'Min',
      sortBy: 'grpc_metric_summary/duration/min',
      numeric: true,

      renderCell: asTiming(
        ({ grpc_metric_summary }) => grpc_metric_summary.duration.min
      ),
      renderGroup: asTiming(
        ({ grpc_metric_summary }) => grpc_metric_summary.duration.min
      ),
    },
    {
      id: 'avg',
      name: 'Avg',
      sortBy: 'grpc_metric_summary/duration/mean',
      numeric: true,

      renderCell: asTiming(
        ({ grpc_metric_summary }) => grpc_metric_summary.duration.mean
      ),
      renderGroup: asTiming(
        ({ grpc_metric_summary }) => grpc_metric_summary.duration.mean
      ),
    },
    {
      id: 'stddev',
      name: 'Stddev',
      sortBy: 'grpc_metric_summary/duration/stdev',
      numeric: true,

      renderCell: asTiming(
        ({ grpc_metric_summary }) => grpc_metric_summary.duration.stdev
      ),
      renderGroup: asTiming(
        ({ grpc_metric_summary }) => grpc_metric_summary.duration.stdev
      ),
    },
    {
      id: 'p95',
      name: 'P95',
      sortBy: 'grpc_metric_summary/duration/p95',
      numeric: true,

      renderCell: asTiming(
        ({ grpc_metric_summary }) => grpc_metric_summary.duration.p95
      ),
      renderGroup: asTiming(
        ({ grpc_metric_summary }) => grpc_metric_summary.duration.p95
      ),
    },
    {
      id: 'p99',
      name: 'P99',
      sortBy: 'grpc_metric_summary/duration/p99',
      numeric: true,

      renderCell: asTiming(
        ({ grpc_metric_summary }) => grpc_metric_summary.duration.p99
      ),
      renderGroup: asTiming(
        ({ grpc_metric_summary }) => grpc_metric_summary.duration.p99
      ),
    },
    {
      id: 'max',
      name: 'Max',
      sortBy: 'grpc_metric_summary/duration/max',
      numeric: true,

      renderCell: asTiming(
        ({ grpc_metric_summary }) => grpc_metric_summary.duration.max
      ),
      renderGroup: asTiming(
        ({ grpc_metric_summary }) => grpc_metric_summary.duration.max
      ),
    },
  ],

  RowBodyComponent: ({ analysis, value }) => (
    <GRPCUrlsChart analysis={analysis} url={value} />
  ),
  EmptyGroupComponent: () => (
    <>No gRPC requests were issued during test execution.</>
  ),
}

interface GRPCUrlsTabProps {
  analysis: TestRunAnalysis
}

const GRPCUrlsTabComponent = ({ analysis }: GRPCUrlsTabProps) => {
  const client = useGrpcClient()
  const [view, setView] = useEnumParameter('view', ['list', 'tree'] as const)
  const urlFiltersProps = useRunDetailsGRPCFilters()
  const { timeRange } = useTimeRange()

  const { filters, sortBy, removeAllFilters, updateSortBy } = urlFiltersProps

  const handleViewToggle = (newView: ViewType) => {
    setView('replace', newView)
  }

  const handleClearFilter = () => {
    removeAllFilters()
  }

  const filter = useMemo(() => serializeRunFilter(filters), [filters])

  if (!hasGrpcMetricSummary(analysis.main.grpc_metric_summary)) {
    return <GRPCEmptyView testRun={analysis.main} />
  }

  return (
    <BreakdownView
      data-testid={RunsPageTestIds.GRPCTable}
      view={view}
      analysis={analysis}
      params={{ client, filter, timeRange }}
      table={table}
      header={<GrpcUrlsFilters run={analysis.main} {...urlFiltersProps} />}
      emptyTable={
        <EmptyFilterView onClear={handleClearFilter}>
          No gRPC calls match the current filter...
        </EmptyFilterView>
      }
      onViewChange={handleViewToggle}
      sortBy={sortBy}
      onSort={updateSortBy}
    />
  )
}

export const GRPCUrlsTab = (props: GRPCUrlsTabProps) => (
  <QueryErrorBoundary view={ErrorBoundaryLocalView.GrpcTab}>
    <GRPCUrlsTabComponent {...props} />
  </QueryErrorBoundary>
)
