import { useCallback, useMemo, useState } from 'react'
import { debounce } from 'lodash'

import {
  UseFormGetValues,
  UseFormSetValue,
  useFormContext,
} from 'react-hook-form'

import {
  CalculateStaticIPsResponse,
  StaticIP,
  StaticIPStatus,
  StaticIPsLoadZonesPayload,
} from 'types'

import { useCalculateStaticIPs } from 'data/useCalculateStaticIPs'
import { showAlert } from 'utils/showAlert'
import { useSubscriptionRule } from 'data/useSubscriptions'

import { StaticIPsCalculatorFormValues } from './StaticIPsCalculator.types'

export const useCalculateStaticIPsForm = (
  staticIPs: StaticIP[] | undefined
) => {
  const [hasLoaded, setHasLoaded] = useState(false)
  const { mutate, isLoading, isError } = useCalculateStaticIPs()
  const [hasUserNeededIPs, setHasUserNeededIPs] = useState(false)
  const concurrentMax = useSubscriptionRule('tests.concurrent.max')

  const { setValue, getValues, clearErrors } =
    useFormContext<StaticIPsCalculatorFormValues>()

  const calculateStaticIPs = useCallback(() => {
    const values = getValues()
    const payload = getCalculateStaticIPsPayloadFromForm(values)

    // Form is invalid, no need to send out a request
    if (
      values.parallelTests > concurrentMax ||
      !getIsCalculateStaticIPsPayloadValid(payload)
    ) {
      setStaticIPsValuesToZero(setValue, getValues)
      return
    }

    return mutate(payload, {
      onSuccess: (data) => {
        const { userOwnsNeededIPs } = setStaticIPsValuesFromResponse(
          data,
          setValue,
          getValues,
          staticIPs,
          values.parallelTests
        )

        setHasUserNeededIPs(userOwnsNeededIPs)
        clearErrors()
      },
      onError: (error: any) => {
        if (error.status >= 400) {
          showAlert(
            'Could not calculate static IPs, please try changing form values',
            'error'
          )
        }

        setHasUserNeededIPs(false)
        setStaticIPsValuesToZero(setValue, getValues)
      },
      onSettled: () => {
        setHasLoaded(true)
      },
    })
  }, [mutate, getValues, setValue, clearErrors, concurrentMax, staticIPs])

  const calculateStaticIPsDebounced = useMemo(
    () => debounce(calculateStaticIPs, 300),
    [calculateStaticIPs]
  )

  return {
    calculateStaticIPs,
    calculateStaticIPsDebounced,
    isLoading,
    isError,
    hasLoaded,
    hasUserNeededIPs,
  }
}

export const getCalculateStaticIPsPayloadFromForm = (
  payload: StaticIPsCalculatorFormValues
) => {
  return {
    vus: payload.maximumVUs,
    loadzones: payload.loadZones.reduce((loadZones, currentLoadZone) => {
      if (currentLoadZone.loadZone) {
        loadZones[currentLoadZone.loadZone] = +currentLoadZone.distribution
      }

      return loadZones
    }, {} as StaticIPsLoadZonesPayload),
  }
}

export const getIsCalculateStaticIPsPayloadValid = (
  payload: ReturnType<typeof getCalculateStaticIPsPayloadFromForm>
) => {
  const loadZonesSum = Object.values(payload.loadzones).reduce(
    (sum, num) => sum + num,
    0
  )

  return loadZonesSum === 100 && payload.vus > 0
}

export const setStaticIPsValuesFromResponse = (
  response: CalculateStaticIPsResponse,
  setValue: UseFormSetValue<StaticIPsCalculatorFormValues>,
  getValues: UseFormGetValues<StaticIPsCalculatorFormValues>,
  staticIPs: StaticIP[] = [],
  parallelTests: number
) => {
  const includeOwnedIPs = getValues('includeOwnedIPs')
  const staticIPsLoadZoneMap = getLoadZoneMapFromStaticIPs(staticIPs)

  let staticIPsFromResponse = response.object

  // If user wants to run parallel tests, we need to multiply the response
  if (parallelTests > 1) {
    Object.entries(staticIPsFromResponse).forEach(([key, value]) => {
      staticIPsFromResponse[key] = value * +parallelTests
    })
  }

  // In case user wants to include owned IPs, we need to subtract them from the response
  if (includeOwnedIPs) {
    Object.entries(staticIPsFromResponse).forEach(([key, value]) => {
      staticIPsFromResponse[key] = Math.max(
        0,
        value - (staticIPsLoadZoneMap[key] ?? 0)
      )
    })
  }

  const staticIPsToAcquireSum = Object.values(staticIPsFromResponse).reduce(
    (sum, num) => sum + num,
    0
  )

  setValue('staticIPsToAcquire', staticIPsToAcquireSum)

  getValues('loadZones').forEach((loadZone, index) => {
    // Find static IPs to acquire by load zone
    const staticIPsLoadZoneResponse = Object.entries(
      staticIPsFromResponse
    ).find(
      ([loadZoneFromResponse]) => loadZoneFromResponse === loadZone.loadZone
    )
    if (staticIPsLoadZoneResponse) {
      setValue(`loadZones.${index}.staticIPs`, staticIPsLoadZoneResponse[1])
    }
  })

  return {
    userOwnsNeededIPs: includeOwnedIPs && staticIPsToAcquireSum === 0,
  }
}

export const setStaticIPsValuesToZero = (
  setValue: UseFormSetValue<StaticIPsCalculatorFormValues>,
  getValues: UseFormGetValues<StaticIPsCalculatorFormValues>
) => {
  setValue('staticIPsToAcquire', 0)

  getValues('loadZones').forEach((_, index) => {
    setValue(`loadZones.${index}.staticIPs`, 0)
  })
}

export const getLoadZoneMapFromStaticIPs = (staticIPs: StaticIP[]) =>
  staticIPs.reduce(
    (loadZoneMap, { provisioning_status, load_zone_identifier }) => {
      // Only consider provisioned Static IPs
      const provisionedStatuses = [
        StaticIPStatus.PROVISIONED,
        StaticIPStatus.PROVISIONING,
      ]

      if (!provisionedStatuses.includes(provisioning_status)) {
        return loadZoneMap
      }

      if (loadZoneMap.hasOwnProperty(load_zone_identifier)) {
        loadZoneMap[load_zone_identifier]++
      } else {
        loadZoneMap[load_zone_identifier] = 1
      }

      return loadZoneMap
    },
    {} as Record<string, number>
  )
