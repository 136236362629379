import styled from 'styled-components'

import { EnvironmentVariable } from 'types'
import { DataTable as DataTableBase } from 'components/DataTable'

export const DataTable = styled(DataTableBase<EnvironmentVariable>)`
  .rdt_TableCell {
    width: 0;
    flex: 1;
  }
`
