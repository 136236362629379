import { useMemo } from 'react'
import { TagQueryBuilder } from 'utils/metrics'
import { TracesSummary } from 'types/traces'
import { TestRunTracesFilterBy } from 'types'

export function useTracesChartTags({
  tracesSummary,
}: {
  tracesSummary: TracesSummary
}) {
  return useMemo(
    () =>
      new TagQueryBuilder()
        .equal(TestRunTracesFilterBy.Scenario, tracesSummary.test_run.scenario)
        .equal(TestRunTracesFilterBy.Group, tracesSummary.test_run.group)
        .equal(TestRunTracesFilterBy.URL, tracesSummary.protocol.url)
        .equal(TestRunTracesFilterBy.Method, tracesSummary.protocol.method)
        .equal(TestRunTracesFilterBy.Status, tracesSummary.protocol.status)
        .equal(TestRunTracesFilterBy.SpanStatus, tracesSummary.span.status_code)
        .equal(
          TestRunTracesFilterBy.ServiceName,
          tracesSummary.span.service_name
        )
        .equal(TestRunTracesFilterBy.SpanName, tracesSummary.span.name)
        .equal(TestRunTracesFilterBy.SpanKind, tracesSummary.span.kind)
        .build(),
    [
      tracesSummary.test_run.scenario,
      tracesSummary.test_run.group,
      tracesSummary.protocol.url,
      tracesSummary.protocol.method,
      tracesSummary.protocol.status,
      tracesSummary.span.status_code,
      tracesSummary.span.service_name,
      tracesSummary.span.name,
      tracesSummary.span.kind,
    ]
  )
}
