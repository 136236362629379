import React, { useMemo } from 'react'

import type { TestRunAnalysis, WSValue } from 'types'
import { TagQueryBuilder } from 'utils/metrics'
import { Chart } from 'components/Chart'

import { useTimeRange } from '../../../TimeRangeContext'
import { ChartFilters } from '../../ChartFilters/ChartFilters'
import { RunsPageTestIds } from 'types/dataTestIds'

interface WSChartProps {
  analysis: TestRunAnalysis
  url: WSValue
}

export const WSChart = ({ analysis, url }: WSChartProps) => {
  const { timeRange, setTimeRange } = useTimeRange()

  const tags = useMemo(() => {
    return new TagQueryBuilder()
      .equal('url', url.name)
      .equal('status', url.status)
      .build()
  }, [url])

  return (
    <ChartFilters
      tags={tags}
      type="ws"
      nodes={analysis.main.nodes}
      requestSuccessful={true}
    >
      {(metrics) => (
        <Chart
          title={`${url.name} (${url.status})`}
          testRun={analysis.main}
          compareWith={analysis.compareWith}
          metrics={metrics}
          timeRange={timeRange}
          onChangeTimeRange={setTimeRange}
          dataTestId={RunsPageTestIds.WSChart}
        />
      )}
    </ChartFilters>
  )
}
