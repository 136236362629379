import React, { useState } from 'react'
import styled from 'styled-components'
import { Alert } from '@grafana/ui'

import { DocLink, docs } from 'components/DocLink'
import { Section } from 'components/Section'
import { useStaticIPs } from 'data/useStaticIPs'
import { ErrorState } from 'pages/ProjectPage/components/ErrorState'
import { spacing } from 'utils/styled'
import { StaticIPStatus } from 'types'
import { LoadingContainer } from 'components/LoadingContainer'
import { Flex } from 'components/Flex'
import { Button } from 'components/Button'
import { SupportLink } from 'components/SupportLink'

import { StaticIPsCalculator } from './StaticIPsCalculator/StaticIPsCalculator'
import { StaticIPsList } from './StaticIPsList/StaticIPsList'
import { StaticIPsListFilters } from './StaticIPsList/StaticIPsListFilters'
import { getFilteredStaticIPData } from './StaticIPsTab.utils'
import { StaticIPsCopyClipboard } from './StaticIPsCopyClipboard'
import { StaticIPsGuide } from './StaticIPsGuide'
import { STATIC_IP_ERROR_STATUSES } from './StaticIPs.constants'
import { ErrorBoundaryFullPageView } from 'services/tracking.types'

// TODO: Change docs link
export const StaticIPsTab = () => {
  const [loadZoneFilters, setLoadZoneFilters] = useState<string[]>([])
  const [statusFilters, setStatusFilters] = useState<StaticIPStatus[]>([])
  const [isGuideOpen, setIsGuideOpen] = useState(false)

  let {
    data: staticIPs,
    isLoading: isStaticIPsLoading,
    isFetching: isStaticIPsFetching,
    isError: isStaticIPsError,
    error: staticIPsError,
  } = useStaticIPs()

  const filteredStaticIPs = getFilteredStaticIPData(
    staticIPs ?? [],
    loadZoneFilters,
    statusFilters
  )

  const staticIPHasError = (staticIPs ?? []).some((ip) =>
    STATIC_IP_ERROR_STATUSES.includes(ip.provisioning_status)
  )

  return (
    <>
      <Section
        title="Static IPs"
        description={
          <>
            Run your tests from Static IPs. Read more about Static IPs in the{' '}
            <DocLink article={docs.manage.staticIPs}>docs</DocLink>.
          </>
        }
      >
        {isStaticIPsError ? (
          <ErrorStateContainer>
            <ErrorState
              error={staticIPsError}
              view={ErrorBoundaryFullPageView.StaticIPs}
            />
          </ErrorStateContainer>
        ) : (
          <section>
            <LoadingContainer loading={isStaticIPsLoading}>
              <StaticIPsCalculator
                isStaticIPsLoading={isStaticIPsLoading}
                isStaticIPsError={isStaticIPsError}
                staticIPs={staticIPs}
              />

              {staticIPHasError && (
                <StyledAlert
                  // @ts-expect-error: Label can be a react element
                  title={
                    <>
                      Something went wrong during the acquisition of IPs, please{' '}
                      <SupportLink>contact support</SupportLink>
                    </>
                  }
                  severity="error"
                ></StyledAlert>
              )}

              {staticIPs && staticIPs.length > 0 && (
                <ActionsContainer>
                  <Flex justify="space-between" align="end">
                    <Flex align="end" gap={2} wrap="wrap">
                      <StaticIPsListFilters
                        data={staticIPs}
                        onLoadZoneSelect={(loadZones: string[]) =>
                          setLoadZoneFilters(loadZones)
                        }
                        onStatusSelect={(status: StaticIPStatus[]) =>
                          setStatusFilters(status)
                        }
                      />

                      <StaticIPsCopyClipboard staticIPs={filteredStaticIPs} />
                    </Flex>

                    <Button
                      fill="text"
                      icon="info-circle"
                      variant="primary"
                      onClick={() => setIsGuideOpen(true)}
                    >
                      How to use Static IPs?
                    </Button>
                  </Flex>
                </ActionsContainer>
              )}

              <StaticIPsList
                data={filteredStaticIPs}
                isFetching={isStaticIPsFetching}
              />
            </LoadingContainer>
          </section>
        )}
      </Section>

      {isGuideOpen && <StaticIPsGuide onClose={() => setIsGuideOpen(false)} />}
    </>
  )
}

const ActionsContainer = styled.div`
  margin: ${spacing(2)} 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xl}px) {
    > div {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      gap: ${spacing(2)};
    }
  }
`

const ErrorStateContainer = styled.div`
  margin-top: ${spacing(4)};
`

const StyledAlert = styled(Alert)`
  margin-top: ${spacing(4)};
  max-width: 760px;
`
