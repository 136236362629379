import React from 'react'
import { Button, Modal as GrafanaModal } from '@grafana/ui'

import { LoadZone } from 'types/loadZone'
import { useDeleteLoadZone } from 'data/useLoadZones'

interface DeleteModalProps {
  loadZone: LoadZone | null
  onDismiss: () => void
}

export const DeleteModal = ({ loadZone, onDismiss }: DeleteModalProps) => {
  const { mutateAsync: deleteLoadZone, isLoading } = useDeleteLoadZone()

  async function handleDelete() {
    if (!loadZone) {
      return
    }

    await deleteLoadZone(loadZone.id)
    onDismiss()
  }

  if (!loadZone) {
    return null
  }

  return (
    <GrafanaModal
      title="Delete private load zone"
      isOpen={!!loadZone}
      onDismiss={onDismiss}
    >
      <div>
        <p>
          Are you sure you want to delete <strong>{loadZone.name}</strong>
        </p>
      </div>
      <GrafanaModal.ButtonRow>
        <Button variant="secondary" onClick={onDismiss} fill="outline">
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          icon={isLoading ? 'fa fa-spinner' : undefined}
          variant="destructive"
          onClick={handleDelete}
        >
          Delete
        </Button>
      </GrafanaModal.ButtonRow>
    </GrafanaModal>
  )
}
