import React, { useState, useEffect, useMemo } from 'react'
import { TimeRangeInput } from '@grafana/ui'
import { TimeRange } from '@grafana/data'
import { Section } from 'components/Section'
import { DocLink, docs } from 'components/DocLink'
import { Flex } from 'components/Flex'
import { useSubscriptionRule } from 'data/useSubscriptions'
import { initialTimeRange } from './UsageReports.utils'
import { ProjectsSelect } from './components/ProjectsSelect'
import { ContributorsSelect } from './components/ContributorsSelect'
import { useUsageReports, useUsageReportSummary } from 'data/useUsageReports'
import { toRate } from 'utils/math'
import { StatsSummaryItem } from './components/StatsSummaryItem'
import { UsageSummaryContainer } from './UsageReports.styles'
import { UsageReportsTable } from './components/UsageReportsTable'
import { UsageReportsParams } from './UsageReports.types'
import { useGrafanaSubscriptionUrl } from 'hooks/useGrafanaSubscriptionUrl'
import { Button } from 'components/Button'

export const UsageReportsTab = () => {
  const hasUsageReportsSubscription = useSubscriptionRule(
    'organization.usage_reports'
  )
  const upgradeLink = useGrafanaSubscriptionUrl()

  return (
    <Section
      title={
        <Section.ProtectedTitle
          title="Usage reports"
          hasPermissions={!!hasUsageReportsSubscription}
          badge="Upgrade subscription"
          badgeHref={upgradeLink}
          tooltip="Upgrade your subscription plan to unlock the feature."
        />
      }
      description={
        <>
          Discover valuable information about your company testing. Read more
          about Usage reports in the{' '}
          <DocLink article={docs.manage.usageReports}>docs</DocLink>.
        </>
      }
    >
      {hasUsageReportsSubscription && <Content />}
    </Section>
  )
}

const Content = () => {
  const [timeRange, setTimeRange] = useState(initialTimeRange)
  const [contributors, setContributors] = useState<string[]>([])
  const [projects, setProjects] = useState<string[]>([])

  const [resetProjectOptions, setResetProjectOptions] = useState(true) // Track when to reset project options
  const [projectOptions, setProjectOptions] = useState<
    Array<{ label: string; value: string }>
  >([])

  const handleSetTimeRange = (timeRange: TimeRange) => {
    setProjects([])
    setContributors([])
    setTimeRange(timeRange)
    setResetProjectOptions(true)
  }

  const canReset =
    timeRange !== initialTimeRange ||
    projects.length > 0 ||
    contributors.length > 0

  const onReset = () => {
    handleSetTimeRange(initialTimeRange)
    setProjects([])
    setContributors([])
  }

  const params: UsageReportsParams = useMemo(
    () => ({
      projects,
      contributors,
      startTime: timeRange.from.toISOString(),
      endTime: timeRange.to.toISOString(),
    }),
    [projects, contributors, timeRange]
  )

  const {
    data: usageReports,
    refetch: fetchUsageReports,
    isLoading,
  } = useUsageReports(params, {
    onSuccess: (reports) => {
      if (resetProjectOptions) {
        setProjectOptions(
          reports.map((report) => ({
            label: report.name,
            value: report.id.toString(),
          }))
        )

        setResetProjectOptions(false)
      }
    },
  })

  const { data: usageReportSummary, refetch: fetchUsageReportSummary } =
    useUsageReportSummary(params)

  useEffect(() => {
    fetchUsageReports()
    fetchUsageReportSummary()
  }, [params, fetchUsageReports, fetchUsageReportSummary])

  return (
    <Flex direction="column" gap={3}>
      <Flex gap={3} wrap="wrap" align="flex-end">
        <Flex align="flex-start" gap={1} direction="column">
          <span>Period</span>
          <TimeRangeInput
            clearable
            value={timeRange}
            onChange={handleSetTimeRange}
          />
        </Flex>
        <Flex align="flex-start" gap={1} direction="column">
          <span>Projects</span>
          <ProjectsSelect
            value={projects}
            onChange={setProjects}
            options={projectOptions}
          />
        </Flex>
        <Flex align="flex-start" gap={1} direction="column">
          <span>Contributors</span>
          <ContributorsSelect value={contributors} onChange={setContributors} />
        </Flex>
        {canReset && (
          <Button size="md" type="button" variant="secondary" onClick={onReset}>
            Reset all filters
          </Button>
        )}
      </Flex>

      <UsageSummaryContainer>
        <StatsSummaryItem
          label="Projects"
          tooltip="Projects which contain at least one test run"
          count={usageReportSummary?.projects_count}
          unit="projects"
        />
        <StatsSummaryItem
          label="Tests created"
          count={usageReportSummary?.tests_created_count}
          unit="tests"
        />
        <StatsSummaryItem
          tooltip="Virtual User hours may fluctuate if a running test is stopped by the user or if it is aborted by the system"
          label="VU hours"
          count={usageReportSummary?.vuh_usage}
          unit="VUh"
        />
        <StatsSummaryItem
          label="Failed by Threshold Rate"
          count={toRate(
            usageReportSummary?.test_runs_fail_count,
            usageReportSummary?.test_runs_started_count
          )}
          unit="%"
        />

        <StatsSummaryItem
          label="Contributors"
          tooltip="Project members who have run at least one test"
          count={usageReportSummary?.contributors_count}
          unit="contributors"
        />
      </UsageSummaryContainer>
      <UsageReportsTable isFetching={isLoading} data={usageReports || []} />
    </Flex>
  )
}
