import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useAppContext } from '../appContext'
import { useDatasource } from '../datasourceContext'
import { showAlert } from '../utils/showAlert'
import { toOrgTokensQueryKey } from './queryKeys'

interface ResponseError {
  data?: {
    error: {
      code: number
      field_errors?: Record<string, string[]>
      message: string
    }
  }
  status: number
  statusText: string
}

const DEFAULT_ERROR_TEXT = 'Unable to update token name'
export function useRenameOrganizationToken() {
  const queryClient = useQueryClient()
  const { orgId } = useAppContext()
  const { ds } = useDatasource()

  return useMutation({
    mutationFn: ({ token, name }: { token: string; name: string }) =>
      ds.renameOrganizationToken(token, name),
    async onSuccess() {
      showAlert('Grafana stack API token updated!', 'success')
      await queryClient.invalidateQueries(toOrgTokensQueryKey(orgId))
    },
    onError(error: ResponseError) {
      if (error.data?.error.field_errors) {
        const [message] = error.data?.error?.field_errors?.name ?? []
        if (message) {
          showAlert(`${DEFAULT_ERROR_TEXT}: ${message}`, 'error')
          return
        }
      }
      showAlert(`${DEFAULT_ERROR_TEXT}.`, 'error')
    },
  })
}
