import { SelectableValue } from '@grafana/data'
import { Select } from '@grafana/ui'
import React from 'react'
import { FilterPreset } from './ChartFilters.types'
import { InlineElement, InlineLabel } from './ChartFilters.styles'

interface SelectMetricProps {
  presets: FilterPreset[]
  onChange: (preset: FilterPreset) => void
  value: FilterPreset
}

export const SelectMetric = ({
  presets,
  onChange,
  value,
}: SelectMetricProps) => {
  const options = presets.map((preset) => ({
    value: preset,
    label: preset.label,
  }))
  if (options.length <= 1) {
    return null
  }

  const handleChange = ({ value }: SelectableValue<FilterPreset>) => {
    if (!value) {
      return
    }
    onChange(value)
  }

  return (
    <InlineElement>
      <InlineLabel>Metric</InlineLabel>
      <Select options={options} onChange={handleChange} value={value} />
    </InlineElement>
  )
}
