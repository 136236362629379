import React from 'react'
import { SortOptions, TestRunAnalysis } from 'types'
import { TreeTable } from '../types'
import { GroupChildren } from './GroupChildren'
import { useScenarioToggle } from '../TableStateProvider'
import { RootTreeNode, ScenarioTreeNode } from 'data/clients/entities/groups'
import { TableRowIcon, TableRow, TableData } from '../Table'
import { useSelectedColumns } from '../TableSettingsContext'

interface ScenarioProps<T, P> {
  analysis: TestRunAnalysis
  node: ScenarioTreeNode
  params: P
  table: TreeTable<T, P>
  level: number
  sortBy: SortOptions<T> | undefined
}

function ScenarioNode<T, P>({
  analysis,
  node,
  params,
  table,
  level,
  sortBy,
}: ScenarioProps<T, P>) {
  const [expanded, toggleScenario] = useScenarioToggle(node.left)
  const columns = useSelectedColumns(table.columns)

  return (
    <>
      <TableRow expanded={expanded} onToggle={toggleScenario}>
        {columns.map((column, index) => (
          <TableData
            key={column.id}
            type="group"
            column={column}
            index={index}
            level={level}
            rowIcon={<TableRowIcon name={expanded ? 'book-open' : 'book'} />}
          >
            {column.renderGroup(node.left, node.right)}
          </TableData>
        ))}
      </TableRow>
      {expanded && (
        <ScenarioChildren
          analysis={analysis}
          node={node}
          params={params}
          table={table}
          level={level + 1}
          sortBy={sortBy}
        />
      )}
    </>
  )
}

interface ScenarioChildrenProps<T, P> {
  analysis: TestRunAnalysis
  node: ScenarioTreeNode
  params: P
  table: TreeTable<T, P>
  level: number
  sortBy: SortOptions<T> | undefined
  onLoading?: () => void
  onLoaded?: () => void
}

function ScenarioChildren<T, P>({
  analysis,
  node,
  level,
  ...props
}: ScenarioChildrenProps<T, P>) {
  return (
    <GroupChildren
      analysis={analysis}
      node={node.rootGroup}
      level={level}
      {...props}
    />
  )
}

interface ScenarioRowsProps<T, P> {
  analysis: TestRunAnalysis
  tree: RootTreeNode
  params: P
  table: TreeTable<T, P>
  level: number
  sortBy: SortOptions<T> | undefined
  onLoading?: () => void
  onLoaded?: () => void
}

export function ScenarioRows<T, P>({
  analysis,
  tree,
  params,
  table,
  level,
  sortBy,
  onLoading,
  onLoaded,
}: ScenarioRowsProps<T, P>) {
  if (tree.scenarios.length === 1 && tree.scenarios[0]) {
    return (
      <ScenarioChildren
        analysis={analysis}
        node={tree.scenarios[0]}
        level={level}
        params={params}
        sortBy={sortBy}
        table={table}
        onLoading={onLoading}
        onLoaded={onLoaded}
      />
    )
  }

  return (
    <>
      {tree.scenarios.map((node) => (
        <ScenarioNode
          key={node.left.id}
          analysis={analysis}
          node={node}
          level={level}
          params={params}
          sortBy={sortBy}
          table={table}
        />
      ))}
    </>
  )
}
