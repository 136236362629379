import styled from 'styled-components'
import { spacing } from 'utils/styled'

export const StaticIPsCalculatorFormRow = styled.div`
  display: flex;
  gap: ${spacing(1)};

  > div {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: block;
    margin-bottom: ${spacing(3)};

    > div {
      width: 100%;
    }
  }
`
