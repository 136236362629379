import { TestBuilderConfig } from '../validation/schema/builder'

export const DEFAULT_BUILDER_CONFIG = JSON.stringify([
  {
    log: {
      options: {
        iterations: undefined,
        duration: undefined,
        stages: undefined,
        vus: undefined,
        scenarios: {
          Scenario_1: {
            executor: 'ramping-vus',
            stages: [
              { duration: '1m', target: 20 },
              { duration: '3m30s', target: 20 },
              { duration: '1m', target: 0 },
            ],
          },
        },
        cloud: {
          distribution: {
            'amazon:us:ashburn': {
              loadZone: 'amazon:us:ashburn',
              percent: 100,
            },
          },
        },
      },
    },
  },
] satisfies TestBuilderConfig)
