import React from 'react'
import styled from 'styled-components'
import { Badge, type BadgeProps } from '@grafana/ui'

interface StatusBadgeProps extends BadgeProps {
  width?: string
}

export const StatusBadge = ({ color, icon, text, width }: StatusBadgeProps) => {
  return <StyledBadge $width={width} color={color} icon={icon} text={text} />
}

export const StyledBadge = styled(Badge)<{ $width?: string }>`
  width: ${({ $width }) =>
    $width}; // to not change in size when time is ticking
  white-space: nowrap;
`
