import React from 'react'
import { NotificationClientSelect } from '../NotificationClientSelect'
import { useIsUserOrgAdmin } from 'data/usePermissions'

export const NotificationsTableZeroState = () => {
  const isOrgAdmin = useIsUserOrgAdmin()

  return (
    <NotificationClientSelect
      ctaDisabled={!isOrgAdmin}
      ctaTooltip={
        !isOrgAdmin
          ? "You don't have permissions to setup new notifications"
          : ''
      }
    />
  )
}
