import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useDatasource } from '../datasourceContext'
import { useAppContext } from '../appContext'
import { toOrgTokensQueryKey } from './queryKeys'
import { showAlert } from '../utils/showAlert'

interface ResponseError {
  data?: {
    error: {
      code: number
      field_errors?: Record<string, string[]>
      message: string
    }
  }
  status: number
  statusText: string
}

export function useCreateOrganizationToken() {
  const queryClient = useQueryClient()
  const { orgId } = useAppContext()
  const { ds } = useDatasource()

  return useMutation<unknown, ResponseError, string, unknown>({
    mutationFn: (name) => ds.createOrganizationToken(orgId, name),
    async onSuccess() {
      showAlert('New Grafana stack API token generated!', 'success')
      await queryClient.invalidateQueries(toOrgTokensQueryKey(orgId))
    },
    onError(error) {
      if (error?.data?.error) {
        return
      }
      showAlert('Failed to create new Grafana stack API token.', 'error')
    },
  })
}
