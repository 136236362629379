import styled from 'styled-components'
import { spacing } from 'utils/styled'

export const BreakdownTabHeader = styled.div`
  display: grid;
  grid-template-areas: 'lead actions';
  grid-template-columns: 1fr auto;
  gap: ${spacing(2)};
  padding: ${spacing(2)};
  min-height: ${spacing(10)};
`
