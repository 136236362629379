import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useDatasource } from 'datasourceContext'
import { ApiError, Project } from 'types'
import {
  toAccountQueryKey,
  toOrgProjectSettingsQueryKey,
  toProjectsQueryKey,
  toProjectsWithStatsKey,
} from 'data/queryKeys'
import { useAppContext } from 'appContext'

export const useCreateProject = () => {
  const queryClient = useQueryClient()
  const appContext = useAppContext()
  const { ds } = useDatasource()

  return useMutation<
    Project,
    ApiError,
    { organization_id: number; name: string },
    unknown
  >({
    mutationFn: ds.createProject.bind(ds),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(toProjectsQueryKey(appContext.orgId)),
        queryClient.invalidateQueries(toProjectsWithStatsKey(appContext.orgId)),
        queryClient.invalidateQueries(
          toOrgProjectSettingsQueryKey(appContext.orgId)
        ),
        queryClient.invalidateQueries(toAccountQueryKey(ds.uid)),
      ])
    },
  })
}
