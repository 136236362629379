import React from 'react'
import { Drawer } from '@grafana/ui'
import styled from 'styled-components'
import { spacing } from 'utils/styled'

interface NotificationTemplateContextDrawerProps {
  onClose(): void
  isOpen: boolean
}

export const NotificationTemplateContextDrawer = ({
  onClose,
  isOpen,
}: NotificationTemplateContextDrawerProps) => {
  if (!isOpen) {
    return null
  }
  return (
    <Drawer
      title="Template Context"
      width="50%"
      onClose={onClose}
      scrollableContent
    >
      <ParameterGroups>
        {Object.values(PARAMETERS_META).map(({ description, parameters }) => (
          <div key={description}>
            <p dangerouslySetInnerHTML={{ __html: description }} />
            <p>
              <ParametersList>
                {parameters.map(({ parameter, type, description }) => (
                  <li key={parameter}>
                    <b>{parameter}</b> ({type}) - {description}
                  </li>
                ))}
              </ParametersList>
            </p>
          </div>
        ))}
      </ParameterGroups>
    </Drawer>
  )
}

const ParameterGroups = styled.div`
  padding-left: ${spacing(2)};
  b {
    color: ${({ theme }) => (theme.isDark ? '#fff' : '#000')};
  }
`

const ParametersList = styled.ul`
  margin-left: ${spacing(2)};
`

interface ParameterMeta {
  parameter: string
  type: string
  description: string
}

interface ParameterGroupMeta {
  description: string
  parameters: ParameterMeta[]
}

const PARAMETERS_META: Record<string, ParameterGroupMeta> = {
  test: {
    description:
      'These are made available to the template at runtime and is accessed in the Jinja {{}} tags etc.',
    parameters: [
      {
        parameter: 'test.organization_id',
        type: 'int',
        description: 'The organization associated with this test run.',
      },
      {
        parameter: 'test.project_id',
        type: 'int',
        description: 'The project associated with this test run.',
      },
      {
        parameter: 'test.project_url',
        type: 'string',
        description: 'URL to the project page in k6 Cloud.',
      },
      {
        parameter: 'test.id',
        type: 'int',
        description: 'Load test run ID.',
      },
      {
        parameter: 'test.url',
        type: 'string',
        description: "URL to the test run's results in k6 Cloud.",
      },
      {
        parameter: 'test.load_test_id',
        type: 'int',
        description: 'The load test associated with this test run.',
      },
      {
        parameter: 'test.load_test_url',
        type: 'string',
        description: 'URL to the load test overview page in k6 Cloud.',
      },
      {
        parameter: 'test.name',
        type: 'string',
        description: 'Name of load test run.',
      },
      {
        parameter: 'test.was_scheduled',
        type: 'int',
        description: '1 or 0 depending on if this was a scheduled test or not.',
      },
      {
        parameter: 'test.started',
        type: 'string',
        description: 'ISO timestamp when test run started (UTC).',
      },
      {
        parameter: 'test.ended',
        type: 'string',
        description: 'ISO timestamp when test run ended (UTC).',
      },
      {
        parameter: 'test.status',
        type: 'int',
        description: 'Run status of the test run (-2..9).',
      },
      {
        parameter: 'test.status_text',
        type: 'string',
        description:
          "Run status as human-readable text ('Finished', 'Timed out' etc).",
      },
      {
        parameter: 'test.result',
        type: 'int',
        description: '0 if passed, 1 if failed.',
      },
      {
        parameter: 'test.result_text',
        type: 'string',
        description: "Result as text ('Passed' or 'Failed').",
      },
    ],
  },
  user: {
    description:
      'Information about the <b>user</b> associated with this test-run.',
    parameters: [
      {
        parameter: 'user.id',
        type: 'int',
        description: 'The user starting this test run.',
      },
      {
        parameter: 'user.name',
        type: 'string',
        description:
          'The full name of the user (first and last name, if available).',
      },
      {
        parameter: 'user.avatar',
        type: 'string',
        description: "This is a Gravatar link based on user's email address.",
      },
    ],
  },
  event: {
    description: 'This holds details about the <b>event</b> itself.',
    parameters: [
      {
        parameter: 'event.id',
        type: 'string',
        description: 'Event unique hash.',
      },
      {
        parameter: 'event.type',
        type: 'string',
        description:
          "This is the event trigger type that fired, like 'test.finished.all'. If no event was specified, this will be the string {}.",
      },
      {
        parameter: 'event.text',
        type: 'string',
        description:
          "This is a more readable description of the event type, like 'Test finished running'. It will be 'Unknown' in the case of the event type.",
      },
    ],
  },
}
