import { Rules } from 'types'
import { calculateTotalVUs } from './utils'
import type { Options, Scenario } from '../schema/options/options'
import { TooManyBrowserVUs, TooManyVUs } from './errors'

export function vus(rules: Rules, options: Options): TooManyVUs[] {
  const maxAllowedVUs = rules['tests.users.max']
  const totalVUs = calculateTotalVUs(options)

  if (totalVUs <= maxAllowedVUs) {
    return []
  }

  return [
    {
      type: 'too-many-vus',
      max: maxAllowedVUs,
      actual: totalVUs,
    },
  ]
}

function isBrowser(scenario: Scenario) {
  return scenario.options?.browser !== undefined
}

export function browserVUs(
  rules: Rules,
  options: Options
): TooManyBrowserVUs[] {
  const maxAllowedVUs = rules['tests.browser_users.max']
  const browserVUs = calculateTotalVUs(options, isBrowser)

  if (browserVUs <= maxAllowedVUs) {
    return []
  }

  return [
    {
      type: 'too-many-browser-vus',
      max: maxAllowedVUs,
      actual: browserVUs,
    },
  ]
}
