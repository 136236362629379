import {
  CreationProcess,
  TestId,
  type Project,
  type ProjectId,
  type Test,
} from 'types'
import type { CreateOrUpdateTestBody } from 'data/useCreateTest'
import { getTestScript, isBuilderTest, isReadOnlyTest } from 'utils/test'
import { format } from 'date-fns'
import { DEFAULT_SCRIPT } from './ScriptEditor/ScriptExamplesMenu.constants'
import { DEFAULT_BUILDER_CONFIG } from './BuilderEditor/constants'

type TestDraftBase = {
  id: TestId | null
  projectId: ProjectId
  name: string
  readOnly: boolean
}

export interface BuilderTestDraft extends TestDraftBase {
  type: 'builder'
  request_builder_config: string
  error?: {
    message: string
  }
  config: {
    apm: any[]
    distribution: 'even' | 'manual'
  }
  script: string
}

export interface ScriptTestDraft extends TestDraftBase {
  type: 'script'
  script: string | null
  source: CreationProcess
}

export type TestDraft = BuilderTestDraft | ScriptTestDraft

function generateTestName(creationProcess: CreationProcess): string {
  const date = format(new Date(), 'dd/MM/yyyy-HH:mm:ss')
  switch (creationProcess) {
    case CreationProcess.TestBuilder:
      return `Test builder (${date})`

    default:
      return `Test script (${date})`
  }
}

export function toNewScriptDraft(
  project: Project,
  script = DEFAULT_SCRIPT
): ScriptTestDraft {
  return {
    type: 'script',
    id: null,
    projectId: project.id,
    name: generateTestName(CreationProcess.Script),
    script,
    source: CreationProcess.Script,
    readOnly: false,
  }
}

export function toNewBuilderDraft(project: Project): BuilderTestDraft {
  return {
    type: 'builder',
    id: null,
    projectId: project.id,
    name: generateTestName(CreationProcess.TestBuilder),
    request_builder_config: DEFAULT_BUILDER_CONFIG,
    config: {
      apm: [],
      distribution: 'even',
    },
    script: '',
    error: undefined,
    readOnly: false,
  }
}

export function toTestDraft(test: Test): TestDraft {
  if (isBuilderTest(test)) {
    return {
      type: 'builder',
      readOnly: false,
      id: test.id,
      projectId: test.project_id,
      name: test.name,
      request_builder_config:
        test.request_builder_config ?? DEFAULT_BUILDER_CONFIG,
      config: test.config,
      script: test.script ?? '',
      error: undefined,
    }
  }

  return {
    type: 'script',
    readOnly: isReadOnlyTest(test),
    id: test.id,
    projectId: test.project_id,
    name: test.name,
    script: getTestScript(test) || null,
    source: test.creation_process,
  }
}

export function toCreateOrUpdateTestPayload(
  draft: TestDraft
): CreateOrUpdateTestBody {
  const props = {
    id: draft.id,
    project_id: draft.projectId,
    name: draft.name,
  }

  if (draft.type === 'builder') {
    return {
      ...props,
      creation_process: CreationProcess.TestBuilder,
      request_builder_config: draft.request_builder_config,
      config: draft.config,
      script: draft.script,
    }
  }

  return {
    ...props,
    request_builder_config: null,
    creation_process: draft.source,
    script: draft.script ?? '',
  }
}
