import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { color } from 'utils/styled'

interface DataTableEmptyStateProps {
  children: ReactNode
  isFetching: boolean
}

export const EmptyWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
  background-color: ${color('background', 'secondary')};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
`

export const DataTableEmptyState = ({
  children,
  isFetching,
}: DataTableEmptyStateProps) => {
  return !isFetching ? <EmptyWrapper>{children}</EmptyWrapper> : null
}
