import { useLocalStorage } from 'usehooks-ts'

import { TestRunStats } from 'types'
import { useAccount } from 'data/useAccount'
import { useUserTestRunStats } from 'data/useUserTestRunStats'
import { getLegacyUserOnboardingLevel } from 'hooks/useIsLegacyUser'

export type OnboardingStage = 'none' | 'welcomed' | 'experienced' | null

export const ONBOARDING_STORAGE_KEY = 'k6.user.onboarding'

/**
 * determine onboarding stage based on the user not having run any tests prior,
 * and only one project exists.
 */
export function useOnboarding() {
  const { data: account } = useAccount()
  const { data: testStats, isFetched: isTestStatsFetched } =
    useUserTestRunStats()

  const isNewLegacyUser = getLegacyUserOnboardingLevel(account) === 0
  const isNewUser = !hasUserRunTests(testStats)
  const isNewToApp = isNewLegacyUser || isNewUser

  const initialValue = isTestStatsFetched
    ? isNewToApp
      ? 'none'
      : 'experienced'
    : null

  /**
   * Cannot pass initial value here as this hooks saves it to state and will
   * not update unless setStage is called.
   */
  const [stage, setStage] = useLocalStorage<OnboardingStage>(
    ONBOARDING_STORAGE_KEY,
    null
  )

  return [stage || initialValue, setStage] as const
}

function hasUserRunTests(testStats: TestRunStats | undefined) {
  const { cli_started_test_runs = 0, ui_started_test_runs = 0 } =
    testStats || {}

  return cli_started_test_runs > 0 || ui_started_test_runs > 0
}
