import React from 'react'
import styled from 'styled-components'
import { Icon } from '@grafana/ui'

import { type TestRun } from 'types'
import { type InsightAudit, type InsightAuditResult } from 'types/cloudInsights'
import { isInsightFailed } from 'utils/insights'
import {
  useInsightsGroups,
  useInsightsLatestExecution,
} from 'data/useCloudInsights'
import { useMediaQuery } from 'hooks/useMediaQuery'
import { Flex } from 'components/Flex'
import { MarkdownRenderer } from 'components/MarkdownRenderer'
import { Tooltip } from 'components/Tooltip'

import { Score } from './Score'

interface HeaderProps {
  audit: InsightAudit
  result?: InsightAuditResult
  testRun: TestRun
}

export const Header = ({ audit, result, testRun }: HeaderProps) => {
  const isMobile = useMediaQuery('md')
  const { data: execution } = useInsightsLatestExecution(testRun)
  const { data: groups = [] } = useInsightsGroups(testRun, execution)

  const hasFailed = isInsightFailed(result)
  const group = groups.find((group) => group.id === audit.group_id)

  return (
    <Container>
      <Flex align="center" gap={1} padding={1}>
        <Tooltip content={result?.status_reason ?? ''} show={hasFailed}>
          <div>
            <Score result={result} />
          </div>
        </Tooltip>
        <Flex direction="column" width="100%">
          <Flex
            align={!isMobile ? 'center' : 'start'}
            direction={!isMobile ? 'row' : 'column-reverse'}
            gap={0.5}
          >
            <Title>{audit.title}</Title>
            {!isMobile && (
              <Icon name="angle-double-right" aria-label="Title divider" />
            )}
            <Group>{group ? group.title : 'Ungrouped'}</Group>
          </Flex>
          <Desc>
            <MarkdownRenderer markdown={audit.description} />
          </Desc>
        </Flex>
      </Flex>
    </Container>
  )
}

const Title = styled.h6`
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  margin-bottom: 0;
`

const Group = styled.span`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.secondary};
`

const Desc = styled.p`
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: 0;

  em {
    color: inherit;
  }

  p {
    margin-bottom: 0;
  }
`

const Container = styled.div`
  width: 100%;
`
