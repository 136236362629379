import React from 'react'
import { Icon } from '@grafana/ui'
import styled, { css } from 'styled-components'

import { ScheduledTest } from 'types/scheduledTests'

import { Flex } from 'components/Flex'
import { isActive } from './scheduledTestsTable.utils'

const Wrapper = styled(Flex)<{ $isInActive: boolean }>`
  ${({ $isInActive }) =>
    $isInActive &&
    css`
      color: ${({ theme }) => theme.colors.text.secondary};
    `};
`

export const ActiveCell = ({
  scheduledTest,
}: {
  scheduledTest: ScheduledTest
}) => {
  const active = isActive(scheduledTest)

  return (
    <Wrapper align="center" gap={2} $isInActive={!active}>
      <Icon aria-hidden name="calendar-alt" />
      {active ? 'Active' : 'Inactive'}
    </Wrapper>
  )
}
