import React, { MouseEvent, ReactNode, SyntheticEvent } from 'react'
import { Dropdown, Menu } from '@grafana/ui'
import { TableMenuOverlay } from './TableHeader.styles'
import {
  TableColumn,
  TableColumnSettings,
  TableDefinition,
  ViewType,
} from '../types'
import { RowIconContainer } from './TableRowIcon'
import { TableHeaderMenuIcon } from './TableCell.styles'
import { useTableSettings } from '../TableSettingsContext'
import {
  MenuSwitch,
  ColumnPicker,
  MenuGroupBody,
  MenuCheckbox,
} from './TableSettingsMenu.styles'

interface TableSettingsMenuProps<T, P> {
  view: ViewType
  table: TableDefinition<T, P>
}

export function TableSettingsMenu<T, P>(props: TableSettingsMenuProps<T, P>) {
  return (
    <RowIconContainer>
      <Dropdown
        placement="bottom-start"
        overlay={<TableSettingsDropdown {...props} />}
      >
        <TableHeaderMenuIcon aria-label="Table settings" />
      </Dropdown>
    </RowIconContainer>
  )
}

function TableSettingsDropdown<T, P>({
  view,
  table,
}: TableSettingsMenuProps<T, P>) {
  const [settings, setSettings] = useTableSettings()

  const columns = table.columns.filter(
    ({ toggle }) => toggle === undefined || toggle !== 'none'
  )

  const handleShowEmptyToggle = (ev: SyntheticEvent) => {
    ev.preventDefault()
    ev.stopPropagation()

    setSettings({
      ...settings,
      treeView: {
        ...settings.treeView,
        showEmpty: !settings.treeView.showEmpty,
      },
    })
  }

  const handleColumnToggle = (columns: TableColumnSettings) => {
    setSettings({
      ...settings,
      columns: columns,
    })
  }

  const preventDropdownFromClosing = (ev: MouseEvent<HTMLInputElement>) => {
    ev.stopPropagation()
  }

  return (
    <TableMenuOverlay onClick={preventDropdownFromClosing}>
      {settings.type !== 'list' && view === 'tree' && (
        <MenuGroup label="Tree view">
          <div onClick={handleShowEmptyToggle}>
            <MenuSwitch
              label="Show empty groups"
              value={settings.treeView.showEmpty}
            />
          </div>
        </MenuGroup>
      )}
      <MenuGroup label="Columns">
        <ColumnPicker>
          {columns.map((column) => (
            <ColumnCheckbox
              key={column.id}
              settings={settings.columns}
              column={column}
              onToggle={handleColumnToggle}
            />
          ))}
        </ColumnPicker>
      </MenuGroup>
    </TableMenuOverlay>
  )
}

interface MenuGroupProps {
  label: string
  children: ReactNode
}

const MenuGroup = ({ label, children }: MenuGroupProps) => {
  return (
    <Menu.Group label={label}>
      <MenuGroupBody>{children}</MenuGroupBody>
    </Menu.Group>
  )
}

interface ColumnCheckboxProps<T> {
  settings: TableColumnSettings
  column: TableColumn<T>
  onToggle: (settings: TableColumnSettings) => void
}

function ColumnCheckbox<T>({
  settings,
  column,
  onToggle,
}: ColumnCheckboxProps<T>) {
  const enabled = settings.includes(column.id)

  const handleChange = () => {
    onToggle(
      enabled
        ? settings.filter((id) => id !== column.id)
        : [...settings, column.id]
    )
  }

  return (
    <MenuCheckbox label={column.name} value={enabled} onChange={handleChange} />
  )
}
