import { useMutation, useQueryClient } from '@tanstack/react-query'
import { update } from 'lodash-es'
import { type FetchError } from '@grafana/runtime'

import { TestRun, TestRunNotePayload, TestRuns, TestWithTrends } from 'types'
import { useDatasource } from 'datasourceContext'
import {
  toTestRunQueryKey,
  toTestRunsWithMetricsQueryKey,
  toTestWithTrendsQueryKey,
} from 'data/queryKeys'
import { showAlert } from 'utils/showAlert'

const updateNote = (id: number, note: string) => (run: TestRun) =>
  run.id === id ? { ...run, note } : run
const mapNote =
  (id: number, note: string) =>
  (runs: TestRun[] = []) =>
    runs.map(updateNote(id, note))

export const useUpdateTestRunNote = (
  testRun: TestRun,
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient()
  const { ds } = useDatasource()

  return useMutation({
    mutationFn: (payload: TestRunNotePayload) =>
      ds.updateTestRunNote(testRun.id, payload),
    onError: (error: FetchError) => {
      showAlert(`Status ${error.status}: Unable to save note`, 'error')
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        toTestWithTrendsQueryKey(testRun.test_id),
        (data?: TestWithTrends) => {
          if (!data?.test_runs) {
            return data
          }

          update(data, 'test_runs', mapNote(testRun.id, response.note))
          return data
        }
      )

      queryClient.setQueriesData(
        toTestRunsWithMetricsQueryKey(testRun.test_id),
        (data?: TestRuns) => {
          if (!data) {
            return data
          }

          update(data, 'k6-runs', mapNote(testRun.id, response.note))
          return data
        }
      )

      queryClient.setQueryData(
        toTestRunQueryKey(testRun.id),
        (data?: TestRun) => {
          if (!data) {
            return data
          }

          return { ...data, note: response.note }
        }
      )

      showAlert(`Note updated for test run #${testRun.id}`, 'success')
      onSuccess?.()
    },
  })
}
