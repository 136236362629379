import {
  MutableDataFrame,
  NullValueMode,
  SpecialValueMatch,
} from '@grafana/data'
import { MappingType } from '@grafana/schema'
import { K6DataSource } from 'datasource/datasource'
import {
  and,
  neq,
  FilterExpression,
  toSelectQuery,
} from 'datasource/serialization'
import { getTestStatusText } from 'pages/utils'
import { PLUGIN_ROOT } from 'routeMap'
import {
  LoadTestsV2Response,
  Test,
  TestRun,
  TestRunDeleteStatus,
  TrendingMetric,
} from 'types'
import { METRIC_COLUMN_WIDTH } from './constants'
import { combineLoadZonesDistribution } from 'utils'
import { isLocalExecution } from 'utils/testRun'
import { runStatusColorMappings } from './utils'
import { LoadZoneDistribution } from 'types/loadZone'
import { TestRunsConfig } from 'datasource/models'

export const validRunsFilter: FilterExpression<TestRun> = and(
  neq('delete_status', TestRunDeleteStatus.DELETED_USER),
  neq('delete_status', TestRunDeleteStatus.DELETION_IN_PROGRESS)
)

export async function queryTestRuns(
  ds: K6DataSource,
  testId: string | undefined,
  config: TestRunsConfig
) {
  if (!testId) {
    return null
  }

  const params = toSelectQuery<Test>({
    select: ['id'],
    expand: {
      test_runs: {
        select: [
          'id',
          'started',
          'run_status',
          'result_status',
          'vuh_cost',
          'vus',
          'duration',
          'load_time',
          'note',
          'distribution',
          'nodes',
          'run_process',
        ],
        skip: config.pagination?.skip,
        top: config.pagination?.take,
        filter: validRunsFilter,
      },
    },
  })

  // It's a bit weird to query for a test and to get the test runs, but
  // skip, top and orderby are not supported on the /runs endpoint but
  // are supported when expanding test_runs.
  const { 'k6-test': test } = await ds.get<
    LoadTestsV2Response<Test | TrendingMetric[]>
  >(`loadtests/v2/tests/${testId}`, {
    params,
  })

  if (test.test_runs.length === 0) {
    return null
  }

  return runsToTableDataFrame(test.test_runs)
}

function runsToTableDataFrame(runs: TestRun[]) {
  const frame = new MutableDataFrame()

  frame.meta = {
    preferredVisualisationType: 'table',
  }

  frame.addField({
    name: 'id',
    values: runs.map((run) => run.id),
    config: {
      custom: {
        hidden: true,
      },
    },
  })

  frame.addField({
    name: 'Started',
    // For some reason, when there's only one row null dates will be displayed as "null".
    // Even weirder is that we can't use a `SpecialValueMatch.Null` to handle this. Instead
    // we have to use something that becomes NaN and then use `SpecialValueMatch.NaN` to
    // map it to the fallback message.
    values: runs.map((run) => run.started ?? '(Not started)'),
    config: {
      unit: 'dateTimeAsIso',
      nullValueMode: NullValueMode.Ignore,
      mappings: [
        {
          type: MappingType.SpecialValue,
          options: {
            match: SpecialValueMatch.NaN,
            result: {
              text: 'Not started',
              index: 0,
            },
          },
        },
      ],
      links: [
        {
          url: `${PLUGIN_ROOT}/runs/\${__data.fields.id}`,
          title: 'View run details',
        },
      ],
    },
  })

  frame.addField({
    name: 'Status',
    values: runs.map((run) => getTestStatusText(run)),
    config: {
      mappings: [
        {
          type: MappingType.ValueToText,
          options: runStatusColorMappings(runs),
        },
      ],
      custom: {
        cellOptions: {
          type: 'color-text',
        },
      },
    },
  })

  frame.addField({
    name: 'VUs',
    values: runs.map((run) => run.vus),

    config: {
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  frame.addField({
    name: 'VUh',
    values: runs.map((run) => run.vuh_cost),

    config: {
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  frame.addField({
    name: 'Duration',
    // Exclude -1
    values: runs.map((run) => Math.max(run.duration, 0)),
    config: {
      unit: 'dthms',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  frame.addField({
    name: 'Response time (P95)',
    values: runs.map((run) => run.load_time || '-'),
    config: {
      unit: 'ms',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  frame.addField({
    name: 'Load distribution',
    values: runs.map(loadZonesOrLocal),
    config: {
      custom: {
        inspect: true,
      },
    },
  })

  frame.addField({
    name: 'Note',
    values: runs.map((run) => run.note),
    config: {
      custom: {
        inspect: true,
      },
    },
  })

  return frame
}

function loadZonesOrLocal(run: TestRun) {
  if (isLocalExecution(run)) {
    return 'Local execution'
  }

  const loadZones = combineLoadZonesDistribution(
    run.distribution,
    run.nodes,
    []
  )

  if (loadZones.length === 1 && loadZones[0]) {
    return getLoadZoneLabel(loadZones[0])
  }

  return loadZones.map((lz) => `${lz.city} ${lz.loadPercent}%`).join(', ')
}

function getLoadZoneLabel(loadZone: LoadZoneDistribution) {
  return loadZone.isPublic ? loadZone.city : loadZone.name
}
