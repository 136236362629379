import React from 'react'

import { DocLink, docs } from 'components/DocLink'
import { CodeSnippet } from 'components/CodeSnippet'
import {
  Content,
  BreakdownTabEmptyView,
  BreakdownTabMessage,
} from '../Breakdown.styles'
import { EmptyProps } from '../BreakdownTable'
import { isTestActive } from 'utils/testRun'

const GRPC_SNIPPET = `
  import grpc from 'k6/net/grpc';
  import { check, sleep } from 'k6';

  const client = new grpc.Client();
  client.load(['definitions'], 'hello.proto');

  export default () => {
    client.connect('grpcb.in:9001', {
      // plaintext: false
    }); 

    const data = { greeting: 'Bert' };
    const response = client.invoke('hello.HelloService/SayHello', data);

    check(response, {
      'status is OK': (r) => r && r.status === grpc.StatusOK,
    });

    console.log(JSON.stringify(response.message));

    client.close();
    sleep(1);
  };
`

type GRPCEmptyViewProps = EmptyProps<{}>

export const GRPCEmptyView = ({ testRun }: GRPCEmptyViewProps) => {
  if (isTestActive(testRun)) {
    return (
      <BreakdownTabMessage>
        Waiting for the first gRPC calls to be made...
      </BreakdownTabMessage>
    )
  }

  return (
    <BreakdownTabEmptyView>
      <Content>
        <h2>Making gRPC calls</h2>
        <p>
          k6 has built-in support for writing gRPC tests. Simply load your{' '}
          {"API's"} definition and start making requests. Here is a simple
          example.
        </p>
        <p>
          Read more about making gRPC calls in the{' '}
          <DocLink article={docs.httpRequests.main}>docs</DocLink>.
        </p>
      </Content>
      <Content>
        <CodeSnippet code={GRPC_SNIPPET} />
      </Content>
    </BreakdownTabEmptyView>
  )
}
