import React from 'react'

import { Flex } from 'components/Flex'

import { PROMO_STEP_ITEMS } from '../constants'
import { Section } from '../components/Section'
import { GradientSectionStyled } from './GradientSection.styled'

export function HowGrafanaWorksSection() {
  return (
    <GradientSectionStyled>
      <Flex direction="column" gap={2}>
        <Section.Heading>How Grafana Cloud k6 works</Section.Heading>
        <Section.PromoItems items={PROMO_STEP_ITEMS} />
      </Flex>
    </GradientSectionStyled>
  )
}
