import React from 'react'
import styled from 'styled-components'

import { RunsPageBreakdownTestIds } from 'types/dataTestIds'
import { MetricOrigin } from 'types'
import { Flex } from 'components/Flex'
import { TextButton } from 'components/TextButton'
import { addPlural } from 'utils'
import { type MetricsByOrigin } from './AnalysisTab.types'
import { color } from 'utils/styled'

type AnalysisSummaryProps = {
  handleClick: () => void
  origins: MetricsByOrigin
}

export const AnalysisSummary = ({
  handleClick,
  origins,
}: AnalysisSummaryProps) => {
  const customMetrics = origins[MetricOrigin.Script].length
  const builtinMetrics = origins[MetricOrigin.BuiltIn].length

  return (
    <Flex direction="column">
      <SummaryText data-testid={RunsPageBreakdownTestIds.AnalysisSummaryText}>
        This test run generated{` `}
        <Strong>{customMetrics}</Strong> custom{' '}
        {addPlural(`metric`, customMetrics)} and{` `}
        <Strong>{builtinMetrics}</Strong> system{' '}
        {addPlural(`metric`, builtinMetrics)}.
      </SummaryText>
      <TextButton onClick={handleClick}>
        Use Grafana Explore to dive deeper into the results
      </TextButton>
    </Flex>
  )
}

const SummaryText = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`

const Strong = styled.strong`
  color: ${color('text', 'maxContrast')};
`
