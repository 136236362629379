import { GrafanaTheme2 } from '@grafana/data'
import { CSSProperties } from 'react'
import { css } from 'styled-components'
import { spacing } from 'utils/styled'

export const CellStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    padding-left: ${spacing(2)};
  }
`

const NumericCellStyles = css`
  ${CellStyles};

  text-align: right;
`

const NumericGroupStyles = css`
  ${NumericCellStyles};

  opacity: 0.6;
`

export interface ColumnTypeStyleProps {
  group?: boolean
  numeric?: boolean | undefined
}

export function ColumnTypeStyles({
  group = false,
  numeric = false,
}: ColumnTypeStyleProps) {
  if (!numeric) {
    return CellStyles
  }

  if (group) {
    return NumericGroupStyles
  }

  return NumericCellStyles
}

export function getColumnStyle(
  columnIndex: number,
  theme: GrafanaTheme2,
  level: number
): CSSProperties | undefined {
  if (columnIndex !== 0) {
    return undefined
  }

  return {
    paddingLeft: theme.spacing(2 + level * 4),
  }
}
