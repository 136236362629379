import styled from 'styled-components'

import { spacing } from 'utils/styled'
import { Flex } from 'components/Flex'

export const Container = styled(Flex)`
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(calc(100% + ${spacing(0.5)}));
  font-size: 10px;
`

export const DateLabel = styled.span`
  display: block;
  white-space: nowrap;
`

export const TimeLabel = styled.span`
  display: block;
`
