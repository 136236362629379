import React, { ReactNode, Reducer, useMemo, useReducer } from 'react'
import { AggregationMethod, TagQuery, TestRunNode } from 'types'
import { MetricBuilder } from 'utils/metrics'
import { exhaustive } from 'utils/typescript'
import {
  BROWSER_PRESETS,
  CHECK_PRESETS,
  GRPC_FAILED_PRESETS,
  GRPC_SUCCESS_PRESETS,
  HTTP_FAILED_PRESETS,
  HTTP_SUCCESS_PRESETS,
  TRACES_PRESETS,
  WS_PRESETS,
} from './ChartFilters.constants'
import { FilterPreset } from './ChartFilters.types'
import { FiltersContainer } from './ChartFilters.styles'
import { SelectAggregation } from './SelectAggregation'
import { SelectLoadZone } from './SelectLoadZone'
import { SelectMetric } from './SelectMetric'
import { MetricConfig } from 'datasource/models'
import { Flex } from 'components/Flex'

type RequestType = 'http' | 'ws' | 'grpc' | 'checks' | 'traces' | 'browser'

interface ChartFiltersProps {
  children: (metrics: MetricConfig[]) => ReactNode
  tags: TagQuery
  nodes: TestRunNode[]
  type: RequestType
  requestSuccessful?: boolean
  actions?: ReactNode
}

interface Filters {
  preset: FilterPreset
  aggregationMethod?: AggregationMethod
  loadZone?: string
}

export const ChartFilters = ({
  children,
  tags,
  nodes,
  type,
  requestSuccessful,
  actions,
}: ChartFiltersProps) => {
  const presets = getFilterPreset(
    `${type}_${requestSuccessful ? 'success' : 'failed'}`
  )

  const [filters, setFilters] = useReducer<Reducer<Filters, Partial<Filters>>>(
    (prev, next) => ({ ...prev, ...next }),
    {
      preset: presets[0],
    }
  )

  const { preset, aggregationMethod, loadZone } = filters

  const handlePresetChange = (value: FilterPreset) => {
    setFilters({
      preset: value,
      aggregationMethod: undefined,
    })
  }

  const handleAggChange = (value: AggregationMethod) => {
    setFilters({
      aggregationMethod: value,
    })
  }

  const handleLoadZoneChange = (value?: string) => {
    setFilters({
      loadZone: value,
    })
  }

  const metrics = useMemo(() => {
    return preset.metrics.map(applyFilters(tags, aggregationMethod, loadZone))
  }, [preset.metrics, tags, aggregationMethod, loadZone])
  return (
    <>
      <Flex justify="space-between" wrap="wrap" paddingVertical={2}>
        <FiltersContainer gap={3}>
          <SelectMetric
            presets={presets}
            value={preset}
            onChange={handlePresetChange}
          />
          {type !== 'traces' && (
            <SelectLoadZone
              nodes={nodes}
              onChange={handleLoadZoneChange}
              value={loadZone}
            />
          )}
          <SelectAggregation
            metrics={preset.metrics}
            value={aggregationMethod}
            onChange={handleAggChange}
          />
        </FiltersContainer>

        {actions}
      </Flex>

      {children(metrics)}
    </>
  )
}

const applyFilters = (
  tags: TagQuery,
  aggregationMethod?: AggregationMethod,
  loadZone?: string
) => {
  return (metric: MetricConfig) => {
    const builder = new MetricBuilder({
      ...metric,
      query: {
        ...metric.query,
        method: aggregationMethod ?? metric.query.method,
      },
    })

    if (loadZone) {
      builder.withTags({
        loadZone: { operator: 'equal', name: 'load_zone', values: [loadZone] },
      })
    }

    // VUs does not have request tags, skip tags query
    if (metric.query.metric === 'vus') {
      return builder.build()
    }

    return builder.withTags(tags).build()
  }
}

const getFilterPreset = (type: `${RequestType}_${'success' | 'failed'}`) => {
  switch (type) {
    case 'http_success':
      return HTTP_SUCCESS_PRESETS

    case 'http_failed':
      return HTTP_FAILED_PRESETS

    case 'grpc_success':
      return GRPC_SUCCESS_PRESETS

    case 'grpc_failed':
      return GRPC_FAILED_PRESETS

    case 'ws_failed':
    case 'ws_success':
      return WS_PRESETS

    case 'checks_success':
    case 'checks_failed':
      return CHECK_PRESETS
    case 'traces_success':
    case 'traces_failed':
      return TRACES_PRESETS

    case 'browser_success':
    case 'browser_failed':
      return BROWSER_PRESETS

    default:
      return exhaustive(type)
  }
}
