import React, { useState } from 'react'
import { TimeRangeInput } from '@grafana/ui'

import { Section } from 'components/Section'
import { DocLink, docs } from 'components/DocLink'
import { useSubscriptionRule } from 'data/useSubscriptions'
import { Flex } from 'components/Flex'
import { AuditTrailTable } from './components'
import { PaginationSummary } from 'components/PaginationSummary'
import { useAppContext } from 'appContext'
import { useInfiniteAuditTrail } from './AuditTrail.hooks'
import {
  getAuditTrails,
  getEndDate,
  getOrderByParam,
  getPageCount,
  getStartDate,
  hasMoreData,
  initialTimeRange,
} from './AuditTrail.utils'
import { useGrafanaSubscriptionUrl } from 'hooks/useGrafanaSubscriptionUrl'

export const AuditTrailTab = () => {
  const hasAuditTrailSubscription = useSubscriptionRule(
    'organization.audit_trail.view'
  )
  const upgradeLink = useGrafanaSubscriptionUrl()

  return (
    <Section
      title={
        <Section.ProtectedTitle
          title="Audit trail"
          hasPermissions={!!hasAuditTrailSubscription}
          {...(!hasAuditTrailSubscription && {
            badge: 'Upgrade subscription',
            badgeHref: upgradeLink,
            tooltip: 'Upgrade your subscription plan to unlock the feature.',
          })}
        />
      }
      description={
        <>
          The Audit Trail can provide valuable insights into the activity within
          your stack. Read more about Audit Trail in the{' '}
          <DocLink article={docs.manage.auditTrail}>docs</DocLink>.
        </>
      }
    >
      {hasAuditTrailSubscription && <Content />}
    </Section>
  )
}

const Content = () => {
  const [timeRange, setTimeRange] = useState(initialTimeRange)
  const defaultField = 'created'
  const defaultSortDirection = 'desc'
  const [sort, setSort] = useState<string | undefined>(
    `${defaultField} ${defaultSortDirection}`
  )
  const { orgId } = useAppContext()

  const { data, fetchNextPage, isFetching, isInitialLoading } =
    useInfiniteAuditTrail({
      orgId,
      pageSize: 25,
      orderBy: getOrderByParam(sort),
      startTime: getStartDate(timeRange),
      endTime: getEndDate(timeRange),
    })

  const hasIncomingData = hasMoreData(data?.pages)
  const allData = getAuditTrails(data?.pages)

  function handleNextPage() {
    hasIncomingData && fetchNextPage()
  }

  return (
    <div>
      <Flex align="center" gap={1}>
        <span>Period</span>
        <TimeRangeInput clearable value={timeRange} onChange={setTimeRange} />
      </Flex>
      <AuditTrailTable
        defaultSortOrder={defaultSortDirection}
        defaultSortFieldId={defaultField}
        isFetching={isFetching}
        isReady={!isInitialLoading}
        data={allData}
        onNextPage={handleNextPage}
        onSort={setSort}
      />
      {!!allData.length && !hasIncomingData && !isFetching && (
        <PaginationSummary
          page={data?.pages?.length ?? 0}
          totalPages={getPageCount(data?.pages)}
        />
      )}
    </div>
  )
}
