import React, { ReactNode, createContext, useState, useContext } from 'react'
import type * as monacoType from 'monaco-editor/esm/vs/editor/editor.api'
import { ReactMonacoEditor } from '@grafana/ui'

interface MonacoContext {
  monaco: MonacoNamespace | null
  setMonaco: (monaco: MonacoNamespace) => void
}

const context = createContext<MonacoContext | null>(null)

export type MonacoNamespace = typeof monacoType

export type IStandaloneCodeEditor = monacoType.editor.IStandaloneCodeEditor
export type IStandaloneDiffEditor = monacoType.editor.IStandaloneDiffEditor
export type IModelDeltaDecoration = monacoType.editor.IModelDeltaDecoration

interface MonacoHijackContextProviderProps {
  children: ReactNode
}

/**
 * This context is used to make sure we only need to hijack the Monaco-namespace once.
 */
export function GrafanaMonacoProvider({
  children,
}: MonacoHijackContextProviderProps) {
  const [monaco, setMonaco] = useState<MonacoNamespace | null>(null)

  return (
    <context.Provider value={{ monaco, setMonaco }}>
      {children}
    </context.Provider>
  )
}

export function useMonaco() {
  const monacoContext = useContext(context)

  if (monacoContext === null) {
    throw new Error(
      'useMonaco must be used within a MonacoHijackContextProvider'
    )
  }

  return monacoContext.monaco
}

export function HijackMonaco() {
  const monacoContext = useContext(context)

  const handleMonacoMount = (monaco: MonacoNamespace) => {
    monacoContext?.setMonaco(monaco)
  }

  if (monacoContext?.monaco !== null) {
    return null
  }

  return (
    <div style={{ display: 'none' }}>
      <ReactMonacoEditor beforeMount={handleMonacoMount} />
    </div>
  )
}
