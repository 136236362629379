import styled from 'styled-components'
import { color } from 'utils/styled'

export const TimeSpan = styled.div`
  position: relative;
  width: 100%;
  background-color: ${color('background', 'secondary')};
  overflow: auto;
  max-height: calc(50vh);
`
