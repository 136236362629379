import React, { ReactNode, createContext } from 'react'
import { useEffectOnce, useLocalStorage } from 'usehooks-ts'

import { FeatureFlag, FeatureFlagName } from './FeatureFlags.types'
import { FEATURE_FLAG_STORAGE_KEY } from './FeatureFlags.constants'
import {
  getFeatureFlags,
  hasFlagsChanged,
  updateFlags,
} from './FeatureFlags.utils'

interface ProviderProps {
  children: ReactNode
}

export interface FeatureFlagsContextProps {
  featureFlags: FeatureFlag[]
  setFeatureFlag: (key: FeatureFlagName, value: boolean) => void
}

export const FeatureFlagsContext = createContext<
  Partial<FeatureFlagsContextProps>
>({})

const FeatureFlagsProvider = ({ children }: ProviderProps) => {
  const [featureFlags, setFeatureFlags] = useLocalStorage<FeatureFlag[]>(
    FEATURE_FLAG_STORAGE_KEY,
    []
  )

  const handleSetFeatureFlag = (name: FeatureFlagName, value: boolean) => {
    const hasFlag = featureFlags.some((flag) => flag.name === name)

    if (!hasFlag) {
      return
    }

    const newFlags = featureFlags.map((flag) => {
      if (flag.name === name) {
        return { ...flag, active: value }
      }

      return flag
    })

    setFeatureFlags(newFlags)
  }

  const context = {
    featureFlags,
    setFeatureFlag: handleSetFeatureFlag,
  }

  /**
   * This effect will synchronize the feature flags from local storage
   */
  useEffectOnce(() => {
    const latestFlags = getFeatureFlags()

    if (hasFlagsChanged(featureFlags, latestFlags)) {
      setFeatureFlags(updateFlags(featureFlags, latestFlags))
    }
  })

  return (
    <FeatureFlagsContext.Provider value={context}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}

export { FeatureFlagsProvider }
