import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Field, Icon, Input, Select } from '@grafana/ui'

import {
  Controller,
  FieldError,
  useFieldArray,
  useFormContext,
} from 'react-hook-form'

import { Button } from 'components/Button'
import { IconButton } from 'components/IconButton'
import { useLoadZones } from 'data/useLoadZones'

import { StaticIPsCalculatorFormValues } from '../StaticIPsCalculator.types'
import { StaticIPsCalculatorFormRow } from '../StaticIPsCalculator.styled'
import { StaticIPsLoadZoneLabel } from '../../../StaticIPsLoadZoneLabel'

import {
  getEvenLoadZoneDistribution,
  getLoadZoneFormValuesDistributionSum,
  getLoadZoneIdsFromFormValues,
  getNextLoadZone,
  hasUserChangedDistribution,
} from './StaticIPsCalculatorFormLoadZones.utils'

export const StaticIPsCalculatorLoadZonesForm = ({
  isStaticIPsLoading,
}: {
  isStaticIPsLoading: boolean
}) => {
  const {
    control,
    getValues,
    trigger,
    setValue,
    formState: { errors },
    register,
  } = useFormContext<StaticIPsCalculatorFormValues>()

  const { data: loadZones, isLoading: isLoadZonesLoading } = useLoadZones()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'loadZones',
  })

  const handleOnNewLoadZoneClick = () => {
    const loadZonesFormValues = getValues('loadZones')
    const nextLoadZone = getNextLoadZone(loadZonesFormValues, loadZones)

    if (!nextLoadZone) {
      return
    }

    // Don't set even distribution if the user manually changed values
    if (hasUserChangedDistribution(loadZonesFormValues)) {
      append({
        loadZone: nextLoadZone?.k6_load_zone_id!,
        distribution: Math.floor(100 / (loadZonesFormValues.length + 1)),
        staticIPs: 0,
      })

      setTimeout(() => {
        trigger(`loadZones.${loadZonesFormValues.length}.distribution`)
      }, 100)

      return
    }

    const loadZoneDistribution = getEvenLoadZoneDistribution(
      loadZonesFormValues.length + 1
    )

    // Change existing load zone distribution percentage
    loadZonesFormValues.forEach((_, index) => {
      setValue(`loadZones.${index}.distribution`, loadZoneDistribution[index]!)
    })

    append({
      loadZone: nextLoadZone?.k6_load_zone_id!,
      distribution: loadZoneDistribution[loadZoneDistribution.length - 1]!,
      staticIPs: 0,
    })
  }

  const handleOnRemoveLoadZone = (index: number) => {
    const loadZonesFormValues = getValues('loadZones')
    const newLength = loadZonesFormValues.length - 1

    // Leave the current distribution and don't set to equal values
    if (hasUserChangedDistribution(loadZonesFormValues)) {
      remove(index)

      setTimeout(() => {
        trigger(`loadZones.${newLength - 1}.distribution`)
      }, 100)

      return
    }

    const loadZoneDistribution = getEvenLoadZoneDistribution(newLength)

    loadZonesFormValues.forEach((_, loadZoneIndex) => {
      if (loadZoneIndex === index) {
        return
      }

      setValue(
        `loadZones.${loadZoneIndex}.distribution`,
        loadZoneDistribution[loadZoneDistribution.length - 1]!
      )

      loadZoneDistribution.pop()
    })

    remove(index)
  }

  const getDistributionErrorMessage = (error?: FieldError) => {
    if (!error) {
      return
    }

    if (error.type === 'sum') {
      return `Distribution sum must be equal to 100%, current sum is ${getLoadZoneFormValuesDistributionSum(
        getValues('loadZones')
      )}%`
    }

    return error.message
  }

  const getLoadZoneOptions = useCallback(
    (value?: string) =>
      (loadZones ?? [])
        .filter((loadZone) => loadZone.public)
        .filter((loadZone) => {
          const selectedValues = getLoadZoneIdsFromFormValues(
            getValues('loadZones')
          )

          const loadZoneValue = loadZone.k6_load_zone_id
          const currentValue = value

          if (selectedValues.includes(loadZoneValue)) {
            return loadZoneValue === currentValue
          }

          return true
        })
        .map((loadZone) => ({
          value: loadZone.k6_load_zone_id,
          label: <StaticIPsLoadZoneLabel loadZone={loadZone} />,
        })),
    [getValues, loadZones]
  )

  const distributionInputOptions = useMemo(
    () => ({
      required: {
        value: true,
        message: 'Distribution is required',
      },
      validate: {
        positive: (value: number) =>
          value <= 0 ? 'Distribution must be a positive number' : true,
        sum: (_: number, formValues: StaticIPsCalculatorFormValues) => {
          const loadZoneDistributionSum = getLoadZoneFormValuesDistributionSum(
            formValues.loadZones
          )

          if (loadZoneDistributionSum !== 100) {
            // This error doesn't matter
            // We'll return a custom error from getDistributionErrorMessage fn
            return `DISTRIBUTION_ERROR`
          }

          return true
        },
      },
    }),
    []
  )

  return (
    <>
      {fields.map((field, index) => (
        <StaticIPsCalculatorFormRow key={field.id}>
          <Field label="Load zone">
            <Controller
              control={control}
              name={`loadZones.${index}.loadZone`}
              render={({ field }) => (
                <Select
                  className="select"
                  // @ts-expect-error: Label can be a react element
                  options={getLoadZoneOptions(field.value)}
                  value={field.value}
                  placeholder="Select load zone"
                  onChange={(project) => {
                    field.onChange(project.value!)
                  }}
                  isLoading={isLoadZonesLoading}
                />
              )}
            />
          </Field>

          <Field
            label="Distribution"
            error={getDistributionErrorMessage(
              errors?.loadZones?.[index]?.distribution
            )}
            invalid={!!errors?.loadZones?.[index]?.distribution}
          >
            <Input
              placeholder="Enter distribution"
              type="number"
              max={100}
              suffix="%"
              {...register(
                `loadZones.${index}.distribution`,
                distributionInputOptions
              )}
            />
          </Field>

          <Field label="Static IPs">
            <Input
              {...register(`loadZones.${index}.staticIPs`)}
              placeholder="Loading"
              disabled
              suffix={isStaticIPsLoading ? <Icon name="fa fa-spinner" /> : null}
            />
          </Field>

          {fields.length > 1 ? (
            <RemoveRowButtonContainer>
              <IconButton
                name="trash-alt"
                onClick={() => handleOnRemoveLoadZone(index)}
              />
            </RemoveRowButtonContainer>
          ) : (
            <div />
          )}
        </StaticIPsCalculatorFormRow>
      ))}

      <Button
        fill="text"
        icon="plus"
        size="sm"
        onClick={handleOnNewLoadZoneClick}
      >
        Add new load zone
      </Button>
    </>
  )
}

const RemoveRowButtonContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 26px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    > button {
      margin-left: auto;
    }
  }
`
