import {
  TitleBarContainer,
  WindowControlItem,
  WindowControls,
} from './ApplicationWindow.styled'
import React, { ReactNode } from 'react'

interface TitleBarProps {
  children?: ReactNode
}
export function TitleBar({ children }: TitleBarProps) {
  return (
    <TitleBarContainer className="ApplicationWindowStyled-titleBar">
      <WindowControls>
        <WindowControlItem />
        <WindowControlItem />
        <WindowControlItem />
      </WindowControls>
      {children}
    </TitleBarContainer>
  )
}
