export enum BreakdownTab {
  HTTP = 'http',
  THRESHOLDS = 'thresholds',
  SCRIPT = 'script',
  CHECKS = 'checks',
  GRPC = 'grpc',
  WS = 'ws',
  LOGS = 'logs',
  TRACES = 'traces',
  ANALYSIS = 'analysis',
  BROWSER = 'browser',
}
