export interface ResourcePermission {
  id: number
  resourceId: string
  isManaged: boolean
  isInherited: boolean
  isServiceAccount: boolean
  userId?: number
  userLogin?: string
  userAvatarUrl?: string
  team?: string
  teamId?: number
  teamAvatarUrl?: string
  builtInRole?: string
  actions: string[]
  permission: FolderPermission
  warning?: string
}

export enum FolderPermission {
  Admin = 'Admin',
  Edit = 'Edit',
  View = 'View',
}

export type FolderPermissionTarget = 'user' | 'team' | 'role'
