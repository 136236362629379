import React, { useState } from 'react'
import { IconButton } from '@grafana/ui'

import { EnvironmentVariable } from 'types'
import { useConfirmation } from 'hooks/useConfirmation'
import { ConfirmModal } from 'components/ConfirmModal'

import { useDeleteEnvironmentVariable } from '../../hooks'
import { EditEnvVariableModal } from '../EnvVariableModal'
import { ButtonWrapper, Strong } from './RowActions.styled'

interface RowActionsProps {
  variable: EnvironmentVariable
}

export function RowActions({ variable }: RowActionsProps) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const { mutateAsync: deleteVariable } = useDeleteEnvironmentVariable()
  const [deleteConfirmationProps, handleConfirmDelete] = useConfirmation(() =>
    deleteVariable(variable)
  )

  return (
    <ButtonWrapper>
      <IconButton
        name="edit"
        tooltip="Update variable"
        onClick={() => setIsEditModalOpen(true)}
      />
      <IconButton
        name="trash-alt"
        variant="destructive"
        tooltip="Delete variable"
        onClick={handleConfirmDelete}
      />

      <ConfirmModal
        {...deleteConfirmationProps}
        title="Delete environment variable"
        confirmText="Delete variable"
        body={
          <span>
            Are you sure you want to delete variable{' '}
            <Strong>&quot;{variable.name}&quot;</Strong>?
          </span>
        }
      />

      <EditEnvVariableModal
        values={variable}
        isOpen={isEditModalOpen}
        onDismiss={() => setIsEditModalOpen(false)}
      />
    </ButtonWrapper>
  )
}
