export function isVUhUsageAboveThreshold(
  usage?: number,
  limit?: number | null,
  threshold = 0.9
) {
  return usage && limit ? usage / limit > threshold : false
}

export function isVUhQuotaReached(usage?: number, limit?: number | null) {
  return usage && limit ? usage >= limit : false
}
