import React from 'react'
import { ButtonProps } from '@grafana/ui'
import { Link, LinkProps } from 'react-router-dom'
import { Button } from './Button'
import { ExternalLink } from './ExternalLink'

interface LinkButtonBaseProps {
  className?: string
  external?: boolean
  target?: LinkProps['target']
  button?: ButtonProps
  children?: ButtonProps['children']
}

interface InternalLinkButtonProps extends LinkButtonBaseProps {
  external?: false
  to: LinkProps['to']
}

interface ExternalLinkButtonProps extends LinkButtonBaseProps {
  external: true
  to: string
}

type LinkButtonProps = InternalLinkButtonProps | ExternalLinkButtonProps

export const LinkButton = ({
  className,
  to,
  children,
  button,
  target,
  external,
}: LinkButtonProps) => {
  if (button && button.disabled) {
    return (
      <Button className={className} {...button}>
        {children}
      </Button>
    )
  }

  if (external) {
    return (
      <ExternalLink url={to}>
        <Button className={className} {...button}>
          {children}
        </Button>
      </ExternalLink>
    )
  }

  if (!!to) {
    return (
      <Link to={to} target={target} style={{ textDecoration: 'none' }}>
        <Button className={className} {...button}>
          {children}
        </Button>
      </Link>
    )
  }

  return null
}
