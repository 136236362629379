import React from 'react'
import styled from 'styled-components'

import { combineLoadZonesDistribution } from 'utils'
import { RefetchIcon } from 'components/RefetchIcon'
import { useLoadZones } from 'data/useLoadZones'

import { LoadZoneTooltip } from './components/LoadZoneTooltip'
import { LoadZoneLabel } from './components/LoadZoneLabel'
import { LoadZoneProps } from './LoadZones.types'
import { List, Spinner } from './LoadZones.styles'

export const LoadZones = ({
  compact,
  nodes,
  distribution,
  limit = 4,
  showNodes = true,
  showPercentage,
}: LoadZoneProps) => {
  const {
    data: loadZones,
    refetch,
    isError,
    isLoading,
  } = useLoadZones({ useErrorBoundary: false, alertOnError: false })

  if (isError) {
    return (
      <RefetchIconContainer>
        <RefetchIcon entity="load zones" onClick={() => refetch()}>
          Retry
        </RefetchIcon>
      </RefetchIconContainer>
    )
  }

  if (!loadZones || isLoading) {
    return <Spinner size={14} />
  }

  const zones = combineLoadZonesDistribution(distribution, nodes, loadZones)
  const visibleZones = zones.slice(0, limit)
  const restZones = zones.slice(limit)

  return (
    <LoadZoneTooltip loadZones={zones} showNodes={showNodes}>
      <List>
        {visibleZones.map((zone) => (
          <li key={zone.id}>
            <LoadZoneLabel
              loadZone={zone}
              showLabel={!compact}
              showPercentage={showPercentage}
            />
          </li>
        ))}

        {restZones.length > 0 && (
          <li>
            <NoWrap>
              ...<strong>({restZones.length})</strong>
            </NoWrap>
          </li>
        )}
      </List>
    </LoadZoneTooltip>
  )
}

const RefetchIconContainer = styled.div`
  button {
    width: fit-content;
  }
`

const NoWrap = styled.span`
  white-space: nowrap;
`
