export const WEB_VITAL_SCORE = {
  good: 'Good',
  needs_improvement: 'Needs improvement',
  poor: 'Poor',
} as const

export const WEB_VITAL_CONFIG = {
  lcp: {
    name: 'LCP',
    longName: 'Largest Contentful Paint',
    unit: 'seconds',
    thresholds: [2500, 4000],
  },
  fid: {
    name: 'FID',
    longName: 'First Input Delay',
    unit: 'milliseconds',
    thresholds: [100, 300],
  },
  cls: {
    name: 'CLS',
    longName: 'Cumulative Layout Shift',
    unit: 'score',
    thresholds: [0.1, 0.25],
  },
  fcp: {
    name: 'FCP',
    longName: 'First Contentful Paint',
    unit: 'seconds',
    thresholds: [1800, 3000],
  },
  inp: {
    name: 'INP',
    longName: 'Interaction to next paint',
    unit: 'milliseconds',
    thresholds: [200, 500],
  },
  ttfb: {
    name: 'TTFB',
    longName: 'Time to First Byte',
    unit: 'milliseconds',
    thresholds: [800, 1800],
  },
} as const

export type WebVitalsConfigName = keyof typeof WEB_VITAL_CONFIG
