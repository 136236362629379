import { useQuery } from '@tanstack/react-query'

import { useDatasource } from 'datasourceContext'
import { useAppContext } from 'appContext'

import { toStaticIPsQueryKey } from './queryKeys/staticIPs'
import { StaticIP, StaticIPStatus } from 'types'

export const useStaticIPs = () => {
  const { ds } = useDatasource()
  const { orgId } = useAppContext()

  return useQuery({
    queryKey: toStaticIPsQueryKey(orgId),
    queryFn: () => ds.fetchStaticIPs(orgId),
    useErrorBoundary: false,
    refetchInterval: (data = []) => {
      const isActiveStatus = (staticIP: StaticIP) =>
        [StaticIPStatus.PROVISIONING, StaticIPStatus.RELEASING].includes(
          staticIP.provisioning_status
        )

      return data.some(isActiveStatus) ? 3000 : 0
    },
  })
}
