import { Test, TestRun } from 'types'
import { literal, array, number, object, string, z } from 'zod'

export interface ExistingFolder {
  type: 'existing'
  title: string
  uid: string
}

export interface NewFolder {
  type: 'new'
  title: string
  placeholderId: string
}

export interface RootFolder {
  type: 'root'
}

export type FolderOption = RootFolder | ExistingFolder | NewFolder

export interface SummarySections {
  responseTime: boolean
  throughput: boolean
  bandwidth: boolean
}

export interface SummaryExportOptions {
  test: Test
  testRun: TestRun
  name: string
  folder: FolderOption
  sections: SummarySections
  include: {
    loadZones: boolean
  }
}

export const FolderSchema = object({
  id: number(),
  uid: string(),
  title: string(),
})

export const DashboardSchema = object({
  uid: string(),
  url: string(),
})

export const ErrorResponseSchema = object({
  status: literal('name-exists'),
  message: string(),
})

interface InsufficientPermissionsError {
  status: 'insufficient-permissions'
  folder: FolderOption
}

export type ErrorResponse =
  | z.infer<typeof ErrorResponseSchema>
  | InsufficientPermissionsError

export const GetFoldersResponseSchema = array(FolderSchema)
