import { useCallback, useEffect, useState } from 'react'
import { useSessionStorage } from 'usehooks-ts'

export function useShowInitPage() {
  const [value, setValue] = useState(sessionStorage.getItem('showInitPage'))
  const showInitPageParam = new URLSearchParams(window.location.search).get(
    'showInitPage'
  )
  const paramValue = showInitPageParam === 'false' ? 'false' : 'true'

  // In PROD, webpack will consider anything beneath this IF to be dead code, hence removed
  if (process.env.NODE_ENV === 'production') {
    return false
  }

  // Bending the rules-of-hooks to not ship DEV code to production
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (showInitPageParam === null) {
      return
    }

    if (paramValue !== value) {
      sessionStorage.setItem('showInitPage', paramValue)
      setValue(paramValue)
    }
  }, [paramValue, showInitPageParam, value])

  return value === 'true'
}

export function useSetShowInitPage() {
  // In PROD, webpack will consider anything beneath this IF to be dead code, hence removed
  if (process.env.NODE_ENV === 'production') {
    return false
  }

  // Bending the rules-of-hooks to not ship DEV code to production
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback((value: boolean) => {
    sessionStorage.setItem('showInitPage', String(value))
  }, [])
}

export function useShowInitPage2() {
  return useSessionStorage('showInitPage', false)
}
