import React from 'react'
import styled from 'styled-components'
import { Icon } from '@grafana/ui'

import { ConfirmModal, ConfirmModalProps } from 'components/ConfirmModal'
import { Project } from 'types'
import { spacing } from 'utils/styled'
import {
  useFolderDescendantCounts,
  FolderDescendantCounts,
  ResourceNames,
} from 'data/useFolderDescendantCounts'
import { Entries } from 'utils/typescript'

interface DeleteProjectModalProps
  extends Pick<
    ConfirmModalProps,
    'isOpen' | 'isPending' | 'onConfirm' | 'onDismiss'
  > {
  project: Project
}

function formatFolderDescendantCounts(counts?: FolderDescendantCounts) {
  if (!counts) {
    return ''
  }

  const entries = Object.entries(counts) as Entries<FolderDescendantCounts>

  const formattedEntries = entries
    .filter(([, value]) => value !== 0)
    .map(([key, value]) => {
      const formattedKey =
        (ResourceNames[key] ?? key) + (value !== 1 ? 's' : '')
      return `${value} ${formattedKey}`
    })

  if (formattedEntries.length === 0) {
    return ''
  }

  return `(${formattedEntries.join(', ')})`
}

export function DeleteProjectModal({
  project,
  ...props
}: DeleteProjectModalProps) {
  const { data, isLoading } = useFolderDescendantCounts(project, {
    enabled: props.isOpen,
  })

  return (
    <StyledConfirmModal
      {...props}
      title={`Delete project "${project?.name}"?`}
      body={
        <>
          This action cannot be undone. This will delete all the tests in the
          project, along with its
          <StyledList>
            <li>test runs and their data</li>
            <li>scripts stored in the cloud</li>
            <li>test builder configurations</li>
            {!!project.grafana_folder_uid && (
              <li>
                <a href={`/dashboards/f/${project.grafana_folder_uid}`}>
                  project folder
                </a>{' '}
                and its contents{' '}
                {isLoading ? (
                  <Icon name="fa fa-spinner" aria-label="loading" aria-busy />
                ) : (
                  formatFolderDescendantCounts(data)
                )}
              </li>
            )}
          </StyledList>
          If you want to keep the test data you should first export and download
          it. Please type <code>DELETE</code> to confirm.
        </>
      }
      confirmText="Delete"
      confirmationText="DELETE"
    />
  )
}

const StyledList = styled.ul`
  margin: ${spacing(1, 0)};
  padding-left: ${spacing(3)};
`

const StyledConfirmModal = styled(ConfirmModal)`
  [class$='-modalHeader'] > h2 {
    word-break: break-all;
  }
`
