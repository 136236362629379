import { literal, object, string, union, type infer as Infer } from 'zod'
import { NodePathSchema } from '../primitives'

export const InvalidDistributionTotalSchema = object({
  type: literal('invalid-distribution-total'),
  path: NodePathSchema,
})

export type InvalidDistributionTotal = Infer<
  typeof InvalidDistributionTotalSchema
>

export const ShorthandPropertySchema = union([
  literal('iterations'),
  literal('duration'),
  literal('stages'),
])

export const InvalidShorthandCombinationSchema = object({
  type: literal('invalid-shorthand-combination'),
  first: ShorthandPropertySchema,
  second: ShorthandPropertySchema.or(literal('scenarios')),
})

export type InvalidShorthandCombination = Infer<
  typeof InvalidShorthandCombinationSchema
>

export const InvalidUseOfVUsSchema = object({
  type: literal('invalid-use-of-vus'),
  conflict: string(),
})

export type InvalidUseOfVUs = Infer<typeof InvalidUseOfVUsSchema>
