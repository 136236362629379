import React from 'react'
import { useParams } from 'react-router-dom'

import { TestWithTrends } from 'types'
import { createTestPageNavModel } from 'navModels'
import { useCurrentProject } from 'projectContext'
import { useTestWithTrends } from 'data/useTestWithTrends'
import { PageSection } from 'components/PageSection'
import { PluginPage } from 'components/PluginPage'
import { Flex } from 'components/Flex'

import { PrivateLoadZonesBanner } from './components/PrivateLoadZonesBanner'
import { RunsTable } from './components/RunsTable'
import { TestActions } from './components/TestActions'
import { TestMetaBar } from './components/TestMetaBar/TestMetaBar'
import { Trends } from './components/Trends'
import {
  TestPageBodyPlaceholder,
  TestPageLoadingContainer,
} from './TestPage.styles'

export const TestPage = () => {
  const { testId } = useParams<{ testId: string }>()
  const project = useCurrentProject()
  const { data: test, isLoading: isLoadingTest } = useTestWithTrends(+testId, {
    staleTime: 1000, // Mitigate duplicate requests on app bootstrap
  })

  const pageNav = createTestPageNavModel(project, test)

  return (
    <PluginPage
      pageNav={pageNav}
      subTitle="Test overview"
      actions={test && <TestActions test={test} />}
    >
      <TestPageLoadingContainer loading={isLoadingTest}>
        {test ? <TestPageBody test={test} /> : <TestPageBodyPlaceholder />}
      </TestPageLoadingContainer>
    </PluginPage>
  )
}

interface TestPageBodyProps {
  test: TestWithTrends
}

const TestPageBody = ({ test }: TestPageBodyProps) => {
  return (
    <Flex gap={2} direction="column">
      <Flex align="center">
        <TestMetaBar test={test} />
        <PrivateLoadZonesBanner test={test} />
      </Flex>

      <Trends test={test} />

      <PageSection>
        <RunsTable test={test} />
      </PageSection>
    </Flex>
  )
}
