import React, { useEffect } from 'react'

import { Test, TestRunAnalysis, TestRunResultStatus } from 'types'
import { HttpTab } from './HttpTab'
import { ThresholdTab } from './ThresholdTab'
import { ChecksTab } from './ChecksTab'
import { ScriptTab } from './ScriptTab'
import { DiffScriptsTab } from './ScriptTab/DiffScriptsTab'
import { LogsTab } from './LogsTab'
import { GRPCUrlsTab } from './GRPCUrlsTab'
import { WSTab } from './WSTab'
import { exhaustive } from 'utils/typescript'
import {
  RunDetailsSection,
  RunDetailsTabContent,
} from '../../TestRunPage.styles'
import { useBreakdownTab, useSupportedProtocols } from './Breakdown.hooks'
import { Tab } from 'components/Tabs/Tab'
import { TabsBar } from 'components/Tabs/TabsBar'
import {
  getChecksCount,
  getGrpcCount,
  getHttpCount,
  getScriptTabTexts,
  getThresholdsCount,
  getWSCount,
} from './Breakdown.utils'
import { BreakdownTab } from './Breakdown.types'
import { useSearchParams } from 'hooks/useSearchParams'
import { UseQueryParameterSetterOptions } from 'hooks/useQueryParameter'
import { ComparedLeftValue } from './ComparedValue'
import { TracesTab } from './TracesTab'
import { AnalysisTab } from './AnalysisTab'
import { BrowserTab } from './BrowserTab'
import { NewFeatureLabel } from '../RunOverview/NewFeatureLabel'
import { BROWSER_DISCOVERABILITY } from 'constants/browser'

const TAB_PARAM_OPTIONS: UseQueryParameterSetterOptions = {
  filter(name) {
    return name !== 'page'
  },
}

interface BreakdownContentProps {
  activeTab: BreakdownTab
  test: Test
  analysis: TestRunAnalysis
}

const BreakdownContent = ({
  activeTab,
  analysis,
  test,
}: BreakdownContentProps) => {
  switch (activeTab) {
    case BreakdownTab.CHECKS:
      return <ChecksTab analysis={analysis} />

    case BreakdownTab.THRESHOLDS:
      return <ThresholdTab analysis={analysis} />

    case BreakdownTab.HTTP:
      return <HttpTab analysis={analysis} />

    case BreakdownTab.GRPC:
      return <GRPCUrlsTab analysis={analysis} />

    case BreakdownTab.WS:
      return <WSTab analysis={analysis} />

    case BreakdownTab.SCRIPT:
      if (analysis.compareWith !== undefined) {
        return (
          <DiffScriptsTab left={analysis.main} right={analysis.compareWith} />
        )
      }

      return <ScriptTab run={analysis.main} test={test} />

    case BreakdownTab.LOGS:
      return <LogsTab run={analysis.main} />

    case BreakdownTab.TRACES:
      return <TracesTab analysis={analysis} />

    case BreakdownTab.BROWSER:
      return <BrowserTab analysis={analysis} />

    case BreakdownTab.ANALYSIS:
      return <AnalysisTab analysis={analysis} />

    default:
      return exhaustive(activeTab)
  }
}

export interface BreakdownProps {
  analysis: TestRunAnalysis
  test: Test
}

export const Breakdown = ({ analysis, test }: BreakdownProps) => {
  const supportedProtocols = useSupportedProtocols(analysis.main)
  const [tab, toTabHref] = useBreakdownTab(supportedProtocols)
  const searchParams = useSearchParams()

  // Show thresholds tab if result_status is 1 (failed by threshold)
  useEffect(() => {
    const activeTab = searchParams.get('tab')
    // Switch tab to 'thresholds' only if tab param is null
    if (
      analysis.main.result_status === TestRunResultStatus.FAILED &&
      activeTab === null
    ) {
      toTabHref('replace', BreakdownTab.THRESHOLDS)
    }
  }, [searchParams, analysis.main.result_status, toTabHref])

  const isComparing = analysis.compareWith !== undefined
  const showHTTPTab =
    supportedProtocols.http ||
    (!supportedProtocols.ws && !supportedProtocols.grpc)

  const scriptTabTexts = getScriptTabTexts()

  return (
    <>
      <TabsBar>
        <Tab
          label="THRESHOLDS"
          href={toTabHref('href', BreakdownTab.THRESHOLDS, TAB_PARAM_OPTIONS)}
          active={tab === BreakdownTab.THRESHOLDS}
          aria-label="Tab Thresholds"
          suffix={() => (
            <div>
              <ComparedLeftValue isComparing={isComparing}>
                {getThresholdsCount(analysis.main)}
              </ComparedLeftValue>
            </div>
          )}
        />
        <Tab
          label="CHECKS"
          href={toTabHref('href', BreakdownTab.CHECKS, TAB_PARAM_OPTIONS)}
          active={tab === BreakdownTab.CHECKS}
          aria-label="Tab Checks"
          suffix={() => (
            <div>
              <ComparedLeftValue isComparing={isComparing}>
                {getChecksCount(analysis.main)}
              </ComparedLeftValue>
            </div>
          )}
        />
        {showHTTPTab && (
          <Tab
            label="HTTP"
            href={toTabHref('href', BreakdownTab.HTTP, TAB_PARAM_OPTIONS)}
            active={tab === BreakdownTab.HTTP}
            aria-label="Tab HTTP"
            suffix={() => (
              <div>
                <ComparedLeftValue isComparing={isComparing}>
                  {supportedProtocols.http
                    ? getHttpCount(supportedProtocols.http)
                    : '(0/0)'}
                </ComparedLeftValue>
              </div>
            )}
          />
        )}
        {supportedProtocols.ws && (
          <Tab
            label="WEBSOCKETS"
            href={toTabHref('href', BreakdownTab.WS, TAB_PARAM_OPTIONS)}
            active={tab === BreakdownTab.WS}
            aria-label="Tab WebSockets"
            suffix={() => (
              <div>
                <ComparedLeftValue isComparing={isComparing}>
                  {supportedProtocols.ws && getWSCount(supportedProtocols.ws)}
                </ComparedLeftValue>
              </div>
            )}
          />
        )}
        {supportedProtocols.grpc && (
          <Tab
            label="GRPC"
            href={toTabHref('href', BreakdownTab.GRPC, TAB_PARAM_OPTIONS)}
            active={tab === BreakdownTab.GRPC}
            aria-label="Tab gRPC"
            suffix={() => (
              <div>
                <ComparedLeftValue isComparing={isComparing}>
                  {supportedProtocols.grpc &&
                    getGrpcCount(supportedProtocols.grpc)}
                </ComparedLeftValue>
              </div>
            )}
          />
        )}
        <Tab
          label={scriptTabTexts.label}
          href={toTabHref('href', BreakdownTab.SCRIPT, TAB_PARAM_OPTIONS)}
          active={tab === BreakdownTab.SCRIPT}
          aria-label="Tab Script"
          suffix={() => <div>{scriptTabTexts.suffix}</div>}
        />
        {!analysis.compareWith && (
          <Tab
            label="LOGS"
            href={toTabHref('href', BreakdownTab.LOGS, TAB_PARAM_OPTIONS)}
            active={tab === BreakdownTab.LOGS}
            aria-label="Tab Logs"
            suffix={() => <div>Execution logs</div>}
          />
        )}

        <Tab
          label="TRACES"
          href={toTabHref('href', BreakdownTab.TRACES, TAB_PARAM_OPTIONS)}
          active={tab === BreakdownTab.TRACES}
          aria-label="Tab Traces"
          suffix={() => <div>View tracing data</div>}
        />

        {!analysis.compareWith &&
          (supportedProtocols.browser || BROWSER_DISCOVERABILITY) && (
            <Tab
              label={<NewFeatureLabel label="BROWSER" />}
              href={toTabHref('href', BreakdownTab.BROWSER, TAB_PARAM_OPTIONS)}
              active={tab === BreakdownTab.BROWSER}
              aria-label="Tab Browser"
              suffix={() => (
                <div>
                  <ComparedLeftValue isComparing={isComparing}>
                    Browser metrics
                  </ComparedLeftValue>
                </div>
              )}
            />
          )}
        <Tab
          label="ANALYSIS"
          href={toTabHref('href', BreakdownTab.ANALYSIS, TAB_PARAM_OPTIONS)}
          active={tab === BreakdownTab.ANALYSIS}
          aria-label="Tab Analysis"
          suffix={() => <div>Explore test results</div>}
        />
      </TabsBar>
      <RunDetailsSection>
        <RunDetailsTabContent>
          <BreakdownContent analysis={analysis} test={test} activeTab={tab} />
        </RunDetailsTabContent>
      </RunDetailsSection>
    </>
  )
}
