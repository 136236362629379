import React from 'react'

import { DocLink, docs } from 'components/DocLink'
import { CodeSnippet } from 'components/CodeSnippet'
import {
  BreakdownTabEmptyView,
  BreakdownTabMessage,
  Content,
} from '../Breakdown.styles'
import { TestRun } from 'types'
import { isTestActive } from 'utils/testRun'

const THRESHOLDS_SNIPPET = `
  import http from 'k6/http'
  import { sleep } from 'k6'

  export let options = {
    thresholds: {
      // During the whole test execution, the error rate must be lower than 1%.
      http_req_failed: ['rate<0.01'],
    },
  }

  export default function() {
    http.get('https://test-api.k6.io/public/crocodiles/1/')
    sleep(1)
  }
`

interface ThresholdTabEmptyViewProps {
  testRun: TestRun
}

export const ThresholdTabEmptyView = ({
  testRun,
}: ThresholdTabEmptyViewProps) => {
  if (isTestActive(testRun)) {
    return <BreakdownTabMessage>Processing thresholds...</BreakdownTabMessage>
  }

  return (
    <BreakdownTabEmptyView>
      <Content>
        <h2>What is a threshold?</h2>
        <p>
          Thresholds are global pass/fail criteria that you can configure k6 to
          use, that can act on any result metrics. They are specified in the
          options section of a k6 script.
        </p>
        <p>
          Here is a sample script that uses a custom Rate metric together with a
          threshold to fail the whole load test if more than 1% of requests fail
          to get a 200-response from the server.
        </p>
        <p>
          Read more about thresholds in the{' '}
          <DocLink article={docs.thresholds.main}>docs</DocLink>.
        </p>
      </Content>
      <Content>
        <CodeSnippet code={THRESHOLDS_SNIPPET} />
      </Content>
    </BreakdownTabEmptyView>
  )
}
