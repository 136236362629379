import React from 'react'

import { DocLink, docs } from 'components/DocLink'
import { CodeSnippet } from 'components/CodeSnippet'
import {
  Content,
  BreakdownTabEmptyView,
  BreakdownTabMessage,
} from '../Breakdown.styles'
import { EmptyProps } from '../BreakdownTable'
import { isTestActive } from 'utils/testRun'

const HTTP_SNIPPET = `
  import { sleep } from 'k6'
  import http from 'k6/http'

  export let options = {
    duration: '5m',
    vus: 10,
  }

  const url = 'https://test-api.k6.io/public/crocodiles/'

  export default function() {
    const response = http.get(url)

    sleep(1)
  }
`

type HttpEmptyViewProps = EmptyProps<{}>

export const HttpEmptyView = ({ testRun }: HttpEmptyViewProps) => {
  const isRunning = isTestActive(testRun)

  if (isRunning) {
    return (
      <BreakdownTabMessage>
        Waiting for the first HTTP requests to arrive...
      </BreakdownTabMessage>
    )
  }

  return (
    <BreakdownTabEmptyView>
      <Content>
        <h2>Making HTTP requests</h2>
        <p>
          When creating a new load test, the first thing {"you'll"} often do is
          define the HTTP requests that will be used to test your system. Here
          is a simple example that performs a <b>GET</b> request.
        </p>
        <p>
          Read more about making HTTP requests in the{' '}
          <DocLink article={docs.httpRequests.main}>docs</DocLink>.
        </p>
      </Content>
      <Content>
        <CodeSnippet code={HTTP_SNIPPET} />
      </Content>
    </BreakdownTabEmptyView>
  )
}
