import { formatISO, parseISO } from 'date-fns'
import {
  dateTime,
  FieldConfigSource,
  FieldType,
  LoadingState,
  MappingType,
  MutableDataFrame,
  NullValueMode,
  PanelData,
  TimeRange,
} from '@grafana/data'
import {
  AxisPlacement,
  LegendDisplayMode,
  SortOrder,
  VisibilityMode,
  VizOrientation,
} from '@grafana/schema'
import { GraphFieldConfig, StackingMode, TooltipDisplayMode } from '@grafana/ui'

import { NamedColors, Trend, TrendDataPoint } from 'types'
import { BarChartPanelOptions, TimeSeriesUnit } from 'types/panels'
import {
  BarChartTrendDataPoint,
  BarChartTrendValueStatus,
  PlaceholderDataPoint,
} from 'types/trend'
import { TREND_VALUE_STATUS_MAP } from 'constants/trend'
import { duration, vus } from 'utils/formatters'
import { toUnit } from 'utils/units'
import { isTestActive } from 'utils/testRun'
import { createGraphFieldConfig } from 'utils/dataFrame'
import { toTrendAggregationOption } from 'utils/options/aggregations/trends'
import { toMetricOption, withProtocolPrefix } from 'utils/options/metricOptions'
import { toTrendValueStatus, toValueState } from 'utils/trends'

const calculateAvgTimeBetweenRuns = (values: TrendDataPoint[]) => {
  const timestamps = values.map((value) => parseISO(value.timestamp).getTime())
  const min = timestamps[0]

  if (min === undefined) {
    return 24 * 60 * 60 * 1000
  }

  const result = timestamps.reduce(
    ({ sum, previous }, current) => {
      return {
        sum: sum + (current - previous),
        previous: current,
      }
    },
    { sum: 0, previous: min }
  )

  return result.sum / values.length
}

const createTimeRange = (values: BarChartTrendDataPoint[]): TimeRange => {
  const timestamps = values.map((value) => parseISO(value.timestamp).getTime())

  const from = Math.min(0, ...timestamps)
  const to = Math.max(Date.now(), ...timestamps)

  return {
    from: dateTime(from),
    to: dateTime(to),
    raw: {
      from: formatISO(from),
      to: formatISO(to),
    },
  }
}

enum ValueState {
  Calculating = 'calculating',
  NoValue = 'no-value',
}

const MAX_VALUE_EMPTY_BARS = 20

const getMaxValue = (trendValues: BarChartTrendDataPoint[]) => {
  const values = trendValues.map((value) => value.value ?? 0)

  if (values.every((value) => value === 0)) {
    return MAX_VALUE_EMPTY_BARS
  }

  return Math.max(0, ...values)
}

const toBarChartTrendValueStatus = (trend: BarChartTrendDataPoint) => {
  if (trend.run === null) {
    return 'placeholder'
  }

  return toTrendValueStatus(trend.run)
}

const toPlotValue = (
  expectedStatus: BarChartTrendValueStatus,
  trendValue: BarChartTrendDataPoint,
  maxValue: number
) => {
  if (trendValue.run === null) {
    return 0
  }

  const status = toTrendValueStatus(trendValue.run)

  if (status !== expectedStatus) {
    return null
  }

  if (isTestActive(trendValue.run)) {
    return maxValue
  }

  // We always want a bit of the bar showing so that user can see the
  // test run status, so we make sure that we have a large enough value
  // to be displayed. This is ok because we're using another field to
  // display the actual value in the tooltip.
  return Math.max(maxValue / 20, trendValue.value ?? 0)
}

const ghostConfig = createGraphFieldConfig({
  custom: {
    hideFrom: {
      legend: true,
      tooltip: true,
      viz: false,
    },
    fillOpacity: 20,
  },
  nullValueMode: NullValueMode.Ignore,
  color: {
    mode: 'fixed',
    fixedColor: NamedColors.Gray,
  },
})

const createTrendFrame = (
  trend: Trend,
  values: BarChartTrendDataPoint[],
  xAxis: boolean
) => {
  const aggregationOption = toTrendAggregationOption(trend.aggregation_function)
  const metricOption = withProtocolPrefix(
    toMetricOption({ name: trend.metric_name })
  )

  const unit = toUnit({
    metric: trend.metric_name,
    method: trend.aggregation_function,
  })

  const maxValue = getMaxValue(values)

  const timestamps = values.map((value) => parseISO(value.timestamp).getTime())
  const statuses = values.map(toBarChartTrendValueStatus)
  const ids = values.map((value) => (value.run !== null ? value.run.id : null))
  const uniqueStatuses = [...new Set(statuses)].sort()

  const frame = new MutableDataFrame()

  frame.refId = 'trend'

  frame.addField({
    name: 'timestamp',
    type: FieldType.time,
    config: createGraphFieldConfig({
      displayName: 'Started',
      custom: {
        hideFrom: {
          legend: true,
          tooltip: false,
          viz: false,
        },
        axisPlacement: xAxis ? AxisPlacement.Bottom : AxisPlacement.Hidden,
      },
    }),
    values: timestamps,
  })

  for (const status of uniqueStatuses) {
    frame.addField({
      name: 'value-' + status,
      type: FieldType.number,
      config: createGraphFieldConfig({
        unit,
        custom: {
          hideFrom: {
            legend: true,
            tooltip: true,
            viz: false,
          },
          axisGridShow: false,
        },
        color: {
          mode: 'fixed',
          fixedColor:
            status !== 'placeholder'
              ? TREND_VALUE_STATUS_MAP[status].color
              : NamedColors.Gray,
        },
        nullValueMode: NullValueMode.Ignore,
        max: maxValue,
      }),
      values: values.map((value) => toPlotValue(status, value, maxValue)),
    })
  }

  frame.addField({
    name: 'ghost',
    type: FieldType.number,
    config: { ...ghostConfig, max: maxValue },
    values: values.map((value) => (value.run !== null ? maxValue : null)),
  })

  frame.addField({
    name: 'status',
    type: FieldType.string,
    config: createGraphFieldConfig({
      displayName: 'Status',
      custom: {
        hideFrom: {
          legend: true,
          tooltip: false,
          viz: true,
        },
      },
      mappings: [
        {
          type: MappingType.ValueToText,
          options: {
            ...TREND_VALUE_STATUS_MAP,
            placeholder: {
              text: '-',
            },
          },
        },
      ],
    }),
    values: statuses,
  })

  frame.addField({
    name: 'metric',
    type: FieldType.string,
    config: createGraphFieldConfig({
      displayName: 'Metric',
      custom: {
        hideFrom: {
          legend: true,
          tooltip: false,
          viz: true,
        },
      },
    }),
    values: values.map(() => metricOption.label),
  })

  frame.addField({
    name: 'aggregation',
    type: FieldType.string,
    config: createGraphFieldConfig({
      displayName: 'Aggregation',
      custom: {
        hideFrom: {
          legend: true,
          tooltip: false,
          viz: true,
        },
      },
    }),
    values: values.map(
      () => aggregationOption.shorthand ?? aggregationOption.label
    ),
  })

  // This field takes care of the formatting of the value in the tooltip.
  frame.addField({
    name: 'valueText',
    type: FieldType.string,
    config: createGraphFieldConfig({
      displayName: 'Value',
      unit,
      custom: {
        hideFrom: {
          legend: true,
          tooltip: false,
          viz: true,
        },
      },
      nullValueMode: NullValueMode.Ignore,
      mappings: [
        {
          type: MappingType.ValueToText,
          options: {
            [ValueState.Calculating]: {
              text: 'Calculating',
            },
            [ValueState.NoValue]: {
              text: '-',
            },
          },
        },
      ],
    }),
    values: values.map(toValueState),
  })

  frame.addField({
    name: 'vus',
    type: FieldType.string,
    config: createGraphFieldConfig({
      displayName: 'VUs',
      custom: {
        hideFrom: {
          legend: true,
          tooltip: false,
          viz: true,
        },
      },
    }),
    values: values.map((value) => {
      return value.run && value.run.vus > 0 ? vus(value.run.vus) : '-'
    }),
  })

  frame.addField({
    name: 'duration',
    type: FieldType.string,
    config: createGraphFieldConfig({
      displayName: 'Duration',
      custom: {
        hideFrom: {
          legend: true,
          tooltip: false,
          viz: true,
        },
      },
    }),
    values: values.map((value) => {
      return value.run && value.run.duration > 0
        ? duration(value.run.duration)
        : '-'
    }),
  })

  // This field is supposed to add a link to the test run in the tooltip, but variable substitution is not
  // implemented in the PanelRenderer so the link will be incorrect. I've asked the plugins platform teams
  // about it, and hopefully it will get implemented at some point. Until then, I'm just hiding it.
  //
  frame.addField({
    name: 'id',
    type: FieldType.number,
    config: createGraphFieldConfig({
      custom: {
        hideFrom: {
          legend: true,
          tooltip: true,
          viz: true,
        },
      },
      links: [
        {
          title: 'Open test run',
          url: `${window.location.protocol}//${window.location.host}/a/k6-app/runs/\${__value.text}`,
        },
      ],
    }),
    values: ids,
  })

  return frame
}

export const createPanelData = (
  trend: Trend,
  barCount: number,
  xAxis: boolean
): PanelData => {
  const avgTimeBetweenRuns = calculateAvgTimeBetweenRuns(trend.values)

  const sliced = trend.values.slice(-barCount)
  const padding = barCount - sliced.length

  const padded = [
    ...sliced,
    ...Array(padding)
      .fill(null)
      .map<PlaceholderDataPoint>((_value, index) => ({
        run: null,
        timestamp: formatISO(Date.now() + index * avgTimeBetweenRuns),
        calculated: true,
        value: 0,
      })),
  ]

  return {
    state: LoadingState.Done,
    series: [createTrendFrame(trend, padded, xAxis)],
    timeRange: createTimeRange(padded),
  }
}

export const createDefaultFieldConfig =
  (): FieldConfigSource<GraphFieldConfig> => {
    return {
      defaults: {
        custom: {
          lineWidth: 0,
          fillOpacity: 100,
          axisPlacement: AxisPlacement.Hidden,
        },
        color: {
          mode: 'palette-classic',
        },
        unit: TimeSeriesUnit.Milliseconds,
      },
      overrides: [],
    }
  }

export const createPanelOptions = (): BarChartPanelOptions => {
  return {
    barWidth: 0.6,
    groupWidth: 0.2,
    barRadius: 0,
    legend: {
      showLegend: false,
      calcs: [],
      displayMode: LegendDisplayMode.List,
      placement: 'bottom',
    },
    orientation: VizOrientation.Vertical,
    showValue: VisibilityMode.Never,
    stacking: StackingMode.Normal,
    tooltip: {
      mode: TooltipDisplayMode.Multi,
      sort: SortOrder.None,
    },
    xField: 'timestamp',
    xTickLabelMaxLength: 200,
    xTickLabelRotation: 0,
    xTickLabelSpacing: 100,
  }
}
