import { WebVitalUnit } from './types'
import { exhaustive } from 'utils/typescript'

export function webVitalFormatter(value: number | null, unit: WebVitalUnit) {
  if (value === null) {
    return '-'
  }

  switch (unit) {
    case 'milliseconds':
      return value.toFixed(0)
    case 'seconds':
      return (value > 0 ? value / 1000 : 0).toFixed(2)
    case 'score':
      return value.toFixed(2)
    default:
      return exhaustive(unit)
  }
}

export function getArrowPosition(
  thresholds: [number, number] | readonly [number, number],
  value: number
) {
  const [good, needsImprovement] = thresholds

  // Good
  if (value <= good) {
    return (value / good) * 33.333333
  }

  // Needs improvement
  if (value <= needsImprovement) {
    const sectionSize = needsImprovement - good
    const relativeValue = value - good

    return 33.333333 + (relativeValue / sectionSize) * 33.333333
  }

  // Poor
  const sectionSize = good
  const relativeValue = value - needsImprovement

  return Math.min(66.666666 + (relativeValue / sectionSize) * 33.333333, 100)
}
