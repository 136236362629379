import React from 'react'
import { Flex } from 'components/Flex'

import { Section } from '../components/Section'
import { PromoCards } from '../components/PromoCards'
import { PROMO_CARD_ITEMS } from '../constants'

export function DesignedToHelpTeamsSection() {
  return (
    <Section>
      <Flex direction="column" gap={3}>
        <Section.Heading>
          Designed to help teams shift testing left
        </Section.Heading>
        <Section.Preamble>
          Grafana Cloud k6 allows you to view, run, and visualize metrics.
        </Section.Preamble>
        <PromoCards items={PROMO_CARD_ITEMS} />
      </Flex>
    </Section>
  )
}
