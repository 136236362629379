import { useEffect, useRef } from 'react'

// Preserves 'safety' status when modal opens to prevent flickering during test run refetch
export function useIsTestSafeOnOpen(isOpen: boolean, isSafe: boolean) {
  const isTestSafeOnOpen = useRef(isSafe)

  useEffect(() => {
    if (!isOpen && isTestSafeOnOpen.current !== isSafe) {
      isTestSafeOnOpen.current = isSafe
    }
  }, [isOpen, isSafe])

  return isTestSafeOnOpen.current
}
