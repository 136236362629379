import React, { ReactNode } from 'react'
import { Modal } from '@grafana/ui'

import styled from 'styled-components'
import { CTAUpgradeReason } from '../services/tracking.types'
import { UpgradeSubscriptionButton } from './UpgradeSubscriptionButton'

const StyledModal = styled(Modal)`
  width: 500px;
`

const Content = styled.div`
  font-size: ${({ theme }) => theme.typography.h5.fontSize};
  color: ${({ theme }) => theme.colors.text.primary};
`

export interface UpgradePromptModalProps {
  isOpen: boolean
  title: string
  actions: ReactNode
  reason: CTAUpgradeReason
  children: ReactNode
  onDismiss: () => void
}

export const UpgradePromptModal = ({
  isOpen,
  title,
  actions,
  reason,
  children,
  onDismiss,
}: UpgradePromptModalProps) => {
  return (
    <StyledModal title={title} isOpen={isOpen} onDismiss={onDismiss}>
      <Content>{children}</Content>
      <Modal.ButtonRow>
        {actions}
        <UpgradeSubscriptionButton reason={reason} />
      </Modal.ButtonRow>
    </StyledModal>
  )
}
