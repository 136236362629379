import { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { Test, TestRun } from 'types'
import { showAlert } from 'utils/showAlert'
import { getTestRunLabel, isTestExportFinished } from 'utils/testRun'
import { resolveTimeZone } from 'utils/date'
import { useDatasource } from 'datasourceContext'
import { toTestRunQueryKey } from 'data/queryKeys'
import { useTestRunClient } from 'data/clients/testRuns'

import { useOnce } from 'hooks/useOnce'
import { usePrevious } from 'hooks/usePrevious'

import { getTestRunExportStatusText } from './ExportData.utils'

export const useExportData = (testRun: TestRun) => {
  const queryClient = useQueryClient()
  const { ds } = useDatasource()

  return useMutation({
    mutationFn: () => ds.createTestRunExport(testRun),
    onSuccess: () => {
      queryClient.invalidateQueries(toTestRunQueryKey(testRun.id))
    },
  })
}

interface UseExportAlertProps {
  test: Test
  testRun: TestRun
}

export const useExportAlert = ({ test, testRun }: UseExportAlertProps) => {
  const prevTestRun = usePrevious(testRun)
  const hasActiveExportFinished =
    !!prevTestRun &&
    !isTestExportFinished(prevTestRun) &&
    isTestExportFinished(testRun)

  useOnce(() => {
    showAlert(
      `Export "${test.name}, ${getTestRunLabel(
        testRun,
        resolveTimeZone()
      )}" ${getTestRunExportStatusText(testRun)}.`,
      'success'
    )
  }, hasActiveExportFinished)
}

export const useDownloadExportData = (testRunId: number) => {
  const testRuns = useTestRunClient()
  const [isDownloading, setIsDownloading] = useState(false)

  const downloadExportData = async () => {
    setIsDownloading(true)
    showAlert('The download should begin shortly')
    try {
      const { export: exportMeta } = await testRuns.fetchDownloadMeta(testRunId)
      if (exportMeta?.download_url) {
        const a = document.createElement('a')
        a.href = exportMeta.download_url
        a.click()
        a.remove()
      }
    } catch (e) {
      showAlert('An issue occurred while downloading the export data', 'error')
    } finally {
      setIsDownloading(false)
    }
  }

  return {
    downloadExportData,
    isDownloading,
  }
}
