import styled from 'styled-components'
import { color, spacing } from '../../utils/styled'
import { webVitalColor } from './WebVital.utils'
import { WebVitalScore } from '../WebVitalGauge'

export const WebVitalIndicator = styled.div<{ $score: WebVitalScore }>`
  display: inline-flex;
  margin-right: ${spacing(1)};
  line-height: 1;
  gap: ${spacing(0.5)};
  color: ${color('text', 'secondary', 0.6)};
`

export const WebVitalColor = styled.div<{ $score: WebVitalScore }>`
  color: ${webVitalColor};
`
