import React, { useMemo } from 'react'

import type { GrpcUrl, TestRunAnalysis } from 'types'
import { TagQueryBuilder } from 'utils/metrics'
import { Chart } from 'components/Chart'

import { useTimeRange } from '../../../TimeRangeContext'
import { ChartFilters } from '../../ChartFilters/ChartFilters'

import { getGRPCStatusText } from './GRPCUrlsTab.utils'
import { RunsPageTestIds } from 'types/dataTestIds'

type GRPCUrlsChartProps = {
  url: GrpcUrl
  analysis: TestRunAnalysis
}

export const GRPCUrlsChart = ({ analysis, url }: GRPCUrlsChartProps) => {
  const { timeRange, setTimeRange } = useTimeRange()

  const tags = useMemo(() => {
    return new TagQueryBuilder()
      .equal('name', url.name)
      .equal('method', url.method)
      .equal('status', url.status)
      .build()
  }, [url.name, url.method, url.status])

  return (
    <ChartFilters
      tags={tags}
      type="grpc"
      requestSuccessful={url.expected_response}
      nodes={analysis.main.nodes}
    >
      {(metrics) => (
        <Chart
          title={`${url.host}${url.name} (${getGRPCStatusText(url.status)})`}
          testRun={analysis.main}
          compareWith={analysis.compareWith}
          metrics={metrics}
          timeRange={timeRange}
          onChangeTimeRange={setTimeRange}
          dataTestId={RunsPageTestIds.GRPCChart}
        />
      )}
    </ChartFilters>
  )
}
