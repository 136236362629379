import { MetricData } from 'datasource/models'
import { maxBy } from 'lodash'

const EMPTY_DATA_POINT = { timestamp: 0, value: 0 }

export function findMaxInSeries(series: MetricData) {
  const max = maxBy(series.data?.values, (point) => point.value)

  return max ?? EMPTY_DATA_POINT
}

export function findAtTimestamp(series: MetricData, timestamp: number) {
  const point = series.data?.values.find(
    (point) => point.timestamp >= timestamp
  )

  return point?.value ?? 0
}
