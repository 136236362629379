import styled from 'styled-components'
import { color, spacing } from '../../utils/styled'

export const ApplicationWindowStyled = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border-radius: ${({ theme }) =>
    theme.shape.borderRadius(2)}; // same as code/pre
  overflow: hidden;
`

export const TitleBarContainer = styled.header`
  background-color: ${({ theme }) => theme.colors.border.medium};
  min-height: 42px; // Height of TabBar
  display: flex;
  align-items: center;
  padding: ${spacing(0, 2)};
`

export const WindowControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  margin-right: 10px;
`

export const WindowControlItem = styled.span`
  width: 10px;
  height: 10px;
  background: ${({ theme }) => theme.colors.border.weak};
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px ${color('border', 'weak')} solid;
  border-top-width: 0;
`
