import { getBackendSrv } from '@grafana/runtime'
import { useQuery } from '@tanstack/react-query'
import { toGrafanaTeamsQueryKey, toGrafanaUsersQueryKey } from './queryKeys'

export interface GrafanaUser {
  userId: number
  login: string
  avatarUrl: string
}

export interface GrafanaTeam {
  id: number
  name: string
  avatarUrl: string
}

export function useGrafanaUserOptions(query: string) {
  return useQuery({
    queryFn: () =>
      getBackendSrv().get<GrafanaUser[]>(
        `/api/org/users/lookup?query=${query}&limit=100`
      ),
    queryKey: toGrafanaUsersQueryKey(query),
    select: (data = []) => {
      return data.map((user) => ({
        id: user.userId,
        value: user.userId,
        label: user.login,
        imgUrl: user.avatarUrl,
        login: user.login,
      }))
    },
  })
}

export function useGrafanaTeamOptions(query: string) {
  return useQuery({
    queryFn: async () => {
      const { teams } = await getBackendSrv().get<{
        teams: GrafanaTeam[]
      }>(
        // cspell: disable-next-line
        `/api/teams/search?perpage=100&page=1&query=${query}`
      )

      return teams
    },
    queryKey: toGrafanaTeamsQueryKey(query),
    select: (data = []) =>
      data.map((team) => ({
        value: team,
        label: team.name,
        imgUrl: team.avatarUrl,
      })),
  })
}
