import React from 'react'
import styled, { css } from 'styled-components'
import { TagExpression } from 'types'
import { color } from 'utils/styled'

export const TagName = styled.span`
  font-weight: 500;
`

export const TagOperator = styled.span`
  opacity: 0.7;
  margin: 0 3px;
`

export const TagValue = styled.span`
  color: ${color('text', 'link')};
`

interface TagExpressionWrapperProps {
  $italic?: boolean
}

export const TagExpressionWrapper = styled.span<TagExpressionWrapperProps>`
  ${({ theme, $italic }) =>
    $italic
      ? css`
          color: ${color('text', 'primary')};
          font-style: italic;
        `
      : null};
`

interface TagExpressionWrapperProps {
  $underlined?: boolean
}

interface TagExpressionProps {
  expression: TagExpression
  match?: boolean
}

export const TagExpressionText = ({
  expression,
  match,
}: TagExpressionProps) => {
  return (
    <TagExpressionWrapper $italic={match === false}>
      <TagName>{expression.name}</TagName>
      <TagOperator>
        {expression.operator === 'equal' ? 'is' : 'is not'}
      </TagOperator>
      <TagValue>{expression.values[0]}</TagValue>
    </TagExpressionWrapper>
  )
}
