import { PropsWithTheme } from 'utils/styled'
import { exhaustive } from '../../utils/typescript'
import { WebVitalScore } from '../WebVitalGauge'

export function webVitalColor() {
  return ({ theme, $score }: PropsWithTheme & { $score: WebVitalScore }) => {
    switch ($score) {
      case 'good':
        return theme.visualization.getColorByName('green')
      case 'needs_improvement':
        return theme.visualization.getColorByName('orange')
      case 'poor':
        return theme.visualization.getColorByName('red')
      default:
        return exhaustive($score, theme.visualization.getColorByName('gray'))
    }
  }
}
