import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Badge } from '@grafana/ui'
import { kebabCase } from 'lodash'

import {
  useHasActiveSubscription,
  useSubscriptionRule,
} from 'data/useSubscriptions'
import { useCanUserRunTest, useIsUserOrgAdmin } from 'data/usePermissions'
import { useGrafanaSubscriptionUrl } from 'hooks/useGrafanaSubscriptionUrl'
import { useFreeVUhsUsageReport } from 'data/useUsageReports'
import { ExternalLink } from './ExternalLink'
import { Test } from 'types'
import {
  isCloudCliTestRunnable,
  isCouldCliTest,
  isIngestTest,
} from 'utils/test'
import { DocLink, docs } from './DocLink'
import { useProjectLimitsAndUsageReport } from 'hooks/useProjectLimitsAndUsageReport'
import { useCurrentProject } from 'projectContext'
import { isVUhQuotaReached } from 'utils/VUh'
import { getRoute } from 'routeMap'
import { Tooltip } from './Tooltip'

type Props = {
  children: (options: { isDisabled: boolean }) => ReactNode
  test?: Test
}

export const addJSExtension = (str: string) =>
  str.includes('.js') ? str : `${str}.js`

export const toKebabCase = (str: string) =>
  str.includes(' ') ? kebabCase(str) : str

export const RunButtonController = ({ children, test }: Props) => {
  const tooltipPlacement = 'top'
  const freeVUhsUsageReport = useFreeVUhsUsageReport()
  const project = useCurrentProject()
  const { limits, usage } = useProjectLimitsAndUsageReport(project.id)
  const canUserRunTest = useCanUserRunTest(test)
  const hasActiveSubscription = useHasActiveSubscription()
  const hasCloudExecution = useSubscriptionRule(
    'load_test.k6_cloud_execution.allow'
  )

  const isFreeVUhQuotaReached = freeVUhsUsageReport?.vuhLimitReached
  const isProjectVUhQuotaReached = isVUhQuotaReached(
    usage?.[0]?.vuh_usage,
    limits?.vuh_max_per_month
  )

  const buttonElement = children({ isDisabled: !canUserRunTest })
  const tooltipContent = getRunButtonTooltipContent({
    test,
    isFreeVUhQuotaReached,
    isProjectVUhQuotaReached,
    hasActiveSubscription,
    hasCloudExecution,
  })

  return (
    <Tooltip
      interactive
      content={<>{tooltipContent}</>}
      show={tooltipContent !== null}
      placement={tooltipPlacement}
    >
      <span>{buttonElement}</span>
    </Tooltip>
  )
}

interface RunButtonTooltipContentProps {
  test?: Test
  isFreeVUhQuotaReached?: boolean
  isProjectVUhQuotaReached?: boolean
  hasActiveSubscription?: boolean
  hasCloudExecution?: boolean
}

const getRunButtonTooltipContent = ({
  test,
  isFreeVUhQuotaReached,
  isProjectVUhQuotaReached,
  hasActiveSubscription,
  hasCloudExecution,
}: RunButtonTooltipContentProps) => {
  if (isFreeVUhQuotaReached) {
    return <VUhLimitReachedTooltip />
  }

  if (isProjectVUhQuotaReached) {
    return <VUhQuotaReached />
  }

  if (!hasActiveSubscription) {
    return <NoSubscription />
  }

  if (!hasCloudExecution) {
    return <NoCloudExec />
  }

  if (test && isIngestTest(test)) {
    return <CliTestRun test={test} />
  }

  if (test && isCouldCliTest(test) && !isCloudCliTestRunnable(test)) {
    return <CloudCliTestRun test={test} />
  }

  return null
}

const NoSubscription = () => {
  const plansUrl = useGrafanaSubscriptionUrl()

  return (
    <>
      No active subscription, go to{' '}
      <ExternalLink url={plansUrl}>plans</ExternalLink> to upgrade
    </>
  )
}

const VUhQuotaReached = () => {
  const isUserOrgAdmin = useIsUserOrgAdmin()

  return (
    <>
      <p>You&apos;ve reached your monthly VUh quota.</p>
      {isUserOrgAdmin ? (
        <>
          Limits and quotas help you control your resources more effectively and
          avoid unexpected costs. Review and manage them in{' '}
          <Link to={getRoute('limits')}>settings</Link>
        </>
      ) : (
        <>
          Contact your stack admin to review and manage project limits and
          quotas
        </>
      )}
      .
    </>
  )
}

export const VUhLimitReachedTooltip = () => {
  const freeVUhsUsageReport = useFreeVUhsUsageReport()
  const url = useGrafanaSubscriptionUrl()
  const isUserOrgAdmin = useIsUserOrgAdmin()

  return (
    <>
      The limit of {freeVUhsUsageReport?.maxVUhs} VUh/month has been reached.
      Upgrade to a paid subscription to continue testing.
      <br />
      {isUserOrgAdmin && <ExternalLink url={url}>Upgrade plan</ExternalLink>}
    </>
  )
}

const NoCloudExec = () => {
  return (
    <>
      Subscription does not allow for test runs to be started from the cloud.
      You can run tests locally and{' '}
      <DocLink article={docs.testBuilder['results-visualization/cloud']}>
        stream the results
      </DocLink>
      .
    </>
  )
}

const CliTestRun = ({ test }: { test: Test }) => {
  const filename = addJSExtension(toKebabCase(test.name))

  return (
    <>
      Last test was started from CLI and can not be re-run from the cloud. Here
      is the CLI command to run the test:{' '}
      <Badge color="purple" text={`k6 run ${filename} -o cloud`} />
    </>
  )
}

const CloudCliTestRun = ({ test }: { test: Test }) => {
  const filename = addJSExtension(toKebabCase(test.name))

  return (
    <>
      Due to the absence of a recent run, or due to the retention policies
      removing the data of previous runs, the script is not available. Here is
      the CLI command to run the test:{' '}
      <Badge color="purple" text={`k6 cloud ${filename}`} />
    </>
  )
}
