export const SENTRY_DSN =
  'https://0309037a823a45ce87cb07d8bce6e93e@o175050.ingest.sentry.io/4504807876788224'

export const BG_TASKS_POLL_INTERVAL = 10000

export const DATASOURCE_TYPE = 'k6-datasource'
export const HTTP_METHODS = [
  'GET',
  'POST',
  'PUT',
  'PATCH',
  'DELETE',
  'HEAD',
  'OPTIONS',
  'CONNECT',
  'TRACE',
]

export const AUDIT_TRAIL_DEFAULT_PAGE_SIZE = 50

export const LOGS_LIMIT = 1000
export const LOGS_POLLING_INTERVAL = 5000
