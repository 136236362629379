import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { type BadgeColor, Icon } from '@grafana/ui'

import { toPrecise } from 'utils/math'
import { spacing } from 'utils/styled'
import { BadgeColors, type TestRun } from 'types'
import { useRun } from 'data/useRun'
import { useHasUserProjectWriteAccess } from 'data/usePermissions'
import { useStopTestRunAction } from 'data/useStopTestRun'
import { useTest } from 'data/useTest'
import { useTestRunStatus } from 'data/useTestRunStatus'
import { routeMap } from 'routeMap'
import { usePrivateLoadZoneIsHanging } from 'components/TestRunPendingState/TestRunPendingState.hooks'
import { IconButton } from 'components/IconButton'
import { StatusBadge } from 'components/StatusBadge'
import { Flex } from 'components/Flex'
import { ConfirmModal } from 'components/ConfirmModal'
import { ProgressBar } from 'components/ProgressBar'
import { useActivityPanel } from 'activityPanelContextProvider'
import { LoadZoneTestHangingWarning } from '../LoadZoneTestHangingWarning'

interface TestRunActivityProps {
  onModalClose: () => void
  onModalOpen: () => void
  testRunID: TestRun['id']
}

export const TestRunActivity = ({
  onModalClose,
  onModalOpen,
  testRunID,
}: TestRunActivityProps) => {
  const activityPanel = useActivityPanel()
  const { data: testRun, isLoading: testRunLoading } = useRun(testRunID)

  const { data: test, isLoading: testLoading } = useTest(testRun?.test_id)
  const { statusColor, statusIcon, statusText, width } =
    useTestRunStatus(testRun)

  const isHanging = usePrivateLoadZoneIsHanging(testRun)

  const hasUserProjectWriteAccess = useHasUserProjectWriteAccess()
  const [stopTestRunConfirmationProps, handleStopTestRunClick, canStopTestRun] =
    useStopTestRunAction()
  const { value } = useMemo(() => getRunStatus(testRun), [testRun])
  const isDisabled = useMemo(
    () => canStopTestRun(testRun),
    [canStopTestRun, testRun]
  )
  const isLoading = testRunLoading || testLoading
  const titleId = `${testRunID}-title`
  const title = isLoading ? 'Loading...' : test?.name

  const onDismiss = useCallback(() => {
    onModalClose()
    stopTestRunConfirmationProps.onDismiss()
  }, [onModalClose, stopTestRunConfirmationProps])

  const onConfirm = useCallback(async () => {
    await stopTestRunConfirmationProps.onConfirm()
    onModalClose()
  }, [onModalClose, stopTestRunConfirmationProps])

  const handleStopModalOpen = useCallback(() => {
    if (testRun) {
      onModalOpen()
      handleStopTestRunClick(testRun)
    }
  }, [handleStopTestRunClick, onModalOpen, testRun])

  const tooltip = isDisabled ? 'Cannot stop test' : 'Stop test'

  return (
    <section aria-labelledby={titleId}>
      <Flex direction="column" gap={2}>
        <Flex justify="space-between">
          <StyledLink
            onClick={activityPanel.close}
            to={routeMap.testRun(testRunID)}
          >
            <Heading id={titleId}>
              {isHanging && (
                <Icon
                  name="exclamation-triangle"
                  color={BadgeColors.ORANGE}
                  aria-hidden
                />
              )}
              {title}
            </Heading>
          </StyledLink>
          {hasUserProjectWriteAccess && (
            <IconButton
              aria-label={tooltip}
              disabled={isDisabled}
              variant="destructive"
              name="minus-circle"
              tooltip={tooltip}
              size="lg"
              onClick={handleStopModalOpen}
            />
          )}
        </Flex>
        <RoundedProgressBar
          color={statusColor}
          direction="horizontal"
          height="12px"
          isWorking
          max={100}
          value={value}
        />
        <StatusWrapper direction="column">
          {isHanging && (
            <LoadZoneTestHangingWarning showIcon={false} shortMessage />
          )}
          <div>
            <StatusBadge
              color={statusColor as BadgeColor}
              icon={statusIcon}
              text={statusText}
              width={width}
            />
          </div>
        </StatusWrapper>
      </Flex>
      <ConfirmModal
        {...stopTestRunConfirmationProps}
        onDismiss={onDismiss}
        onConfirm={onConfirm}
      />
    </section>
  )
}

const RoundedProgressBar = styled(ProgressBar)`
  ${({ theme }) => `
    border-radius: ${theme.shape.borderRadius(3)};
  `}
`

// There's a very particular bug with some screen readers (VO)
// where when using screen reader focus or search the link
// is actionable this way round rather than it being within the heading

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.primary};
`

const StatusWrapper = styled(Flex)`
  gap: ${spacing(2)};
`

const Heading = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${spacing(0.5)};

  ${({ theme }) => {
    const as = `h6`

    return css`
      margin: 0;
      font-size: ${theme.typography[as].fontSize};
      font-family: ${theme.typography[as].fontFamily};
      letter-spacing: ${theme.typography[as].letterSpacing};
      font-weight: ${theme.typography[as].fontWeight};
      line-height: ${theme.typography[as].lineHeight};
    `
  }}
`

function getRunStatus(testRun?: TestRun) {
  if (!testRun || !testRun.started || !testRun.duration) {
    return {
      value: 100,
    }
  }

  return {
    value: getRunProgressPercent(testRun),
  }
}

function getRunProgressPercent(testRun: TestRun) {
  if (!testRun.started || !testRun.duration) {
    return 0
  }

  const { started, duration } = testRun
  const durationMs = duration * 1000
  const startTimeMs = new Date(started).getTime()
  const nowTime = new Date().getTime()

  return toPrecise(((nowTime - startTimeMs) / durationMs) * 100, 2)
}
