import { useQuery } from '@tanstack/react-query'
import { toTestRunBrowserTracesQueryKey } from './queryKeys'
import { useTestRunClient } from './clients/testRuns'
import { TraceId } from '../types/tempo'
import { TestRunId } from '../types'

export function useTestRunTraceById(testRunId?: TestRunId, traceId?: TraceId) {
  const client = useTestRunClient()

  return useQuery({
    queryKey: toTestRunBrowserTracesQueryKey(testRunId!, traceId!),
    queryFn: () => client.fetchBrowserTraceById(testRunId!, traceId!),
    enabled: !!testRunId && !!traceId,
  })
}
