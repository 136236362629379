import React from 'react'
import { CodeSnippet } from 'components/CodeSnippet'
import {
  CopyContainer,
  Heading,
  StyledBrowserDiscoverability,
} from './BrowserDiscoverability.styled'
import { Flex } from 'components/Flex'
import { Badge } from '@grafana/ui'
import { DocLink, docs } from 'components/DocLink'
import { useScrollToId } from 'hooks/useScrollToId'
import BROWSER_FILL_FORM_SCRIPT from 'assets/snippets/browser_fill_form.js?raw'
export const BROWSER_CONTAINER_ID = 'discover-browser-tests'

export const tabs = [
  {
    label: 'browser-test.js',
    value: 'browser-test',
    dedent: false,
    code: BROWSER_FILL_FORM_SCRIPT,
    download: 'browser-test.js',
  },
]

export function BrowserDiscoverability() {
  const scrollTargetId = useScrollToId(BROWSER_CONTAINER_ID, 'smooth') // 'smooth' creates a nice scroll animation

  return (
    <StyledBrowserDiscoverability id={scrollTargetId}>
      <Flex direction="column">
        <CopyContainer>
          <Heading>
            <Badge color="blue" text="BETA" />
            Run your first Browser tests
          </Heading>

          <p>
            Browser testing has landed in Grafana Cloud k6! With the browser
            module, you can run browser tests on top of your existing k6
            protocol tests to measure web performance metrics and find issues
            difficult to catch on the protocol level. To get started with the k6
            browser module, check out our{' '}
            <DocLink article={docs.browser.runningBrowserTests}>
              documentation
            </DocLink>{' '}
            guide and our{' '}
            <DocLink article={docs.browser.api}>API documentation</DocLink>.
          </p>
        </CopyContainer>
        <CodeSnippet height="256px" tabs={tabs} lang="js" />
      </Flex>
    </StyledBrowserDiscoverability>
  )
}
