import styled from 'styled-components'

export const WebVitalScoreWrapper = styled.span`
  &.score--undefined {
    // inherit color (merely here to show that this class name is used)
  }

  &.score--good {
    // inherit color (green is a bit over the top)
  }

  &.score--needs_improvement {
    color: ${({ theme }) => theme.visualization.getColorByName('orange')};
  }

  &.score--poor {
    color: ${({ theme }) => theme.visualization.getColorByName('red')};
  }
`
