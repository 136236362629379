import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'
import { Button } from '@grafana/ui'
import { Checkbox } from 'components/Checkbox'

import { DataTableActions } from './DataTable.types'
import { spacing } from 'utils/styled'
import { Tooltip } from 'components/Tooltip'

const ActionColumnHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${spacing(1)};

  .batch-mode-action-group {
    background-color: ${({ theme }) => theme.colors.secondary.main};
    display: flex;
    align-items: center;
    border-radius: 2px;
    padding-right: ${spacing(1)};

    &:hover {
      background-color: ${({ theme }) => theme.colors.secondary.shade};
    }

    button:hover {
      background-color: transparent;
    }

    & > p {
      margin: ${({ theme }) =>
        `0 ${theme.spacing(0.5)} 0 -${theme.spacing(0.5)}`};
      min-width: 25px;
      text-align: center;
    }
  }
`

const BatchModeToggleWrapper = styled.div`
  padding-right: ${({ theme }) => theme.spacing(1)};
`

export const ActionsColumnHeader = <T,>({
  allItemsSelected,
  isDisabled,
  clearSelectedItems,
  getHeaderCellProps,
  isSelectionMode,
  onBatchConfirm,
  selectAllItems,
  selectedItems,
  toggleSelectionMode,
}: DataTableActions<T>) => {
  const wrapper = useRef<HTMLDivElement>(null)

  const keepFocus = useCallback(() => {
    requestAnimationFrame(() => {
      wrapper.current?.querySelector('input')?.focus()
    })
  }, [])

  const handleToggleClick = useCallback(() => {
    toggleSelectionMode()
    keepFocus()
  }, [keepFocus, toggleSelectionMode])

  const handleCheckboxClick = useCallback(() => {
    if (!isSelectionMode) {
      handleToggleClick()
    }

    allItemsSelected ? clearSelectedItems() : selectAllItems()
  }, [
    allItemsSelected,
    clearSelectedItems,
    handleToggleClick,
    isSelectionMode,
    selectAllItems,
  ])

  const { confirmAriaLabel, confirmVariant, confirmIcon, descriptors } =
    getHeaderCellProps(selectedItems)
  const switchOffTooltip = `Switch off batch mode`
  const selectAllTooltip = allItemsSelected
    ? `Deselect all ${descriptors.noun}s`
    : `Select all ${descriptors.noun}s`

  return (
    <ActionColumnHeader ref={wrapper}>
      {isSelectionMode ? (
        <>
          <Tooltip content={confirmAriaLabel}>
            <Button
              aria-label={confirmAriaLabel}
              disabled={selectedItems.length === 0}
              tooltip={confirmAriaLabel}
              variant={confirmVariant}
              fill="solid"
              icon={confirmIcon}
              onClick={() => onBatchConfirm(selectedItems)}
            />
          </Tooltip>
          <div className="batch-mode-action-group">
            <Tooltip content={switchOffTooltip}>
              <Button
                aria-label={switchOffTooltip}
                size="md"
                fill="text"
                variant="primary"
                icon="times"
                onClick={handleToggleClick}
                tooltip={switchOffTooltip}
              />
            </Tooltip>
            <p>{`(${selectedItems.length})`}</p>
            <Checkbox
              aria-label={selectAllTooltip}
              checked={allItemsSelected}
              onChange={handleCheckboxClick}
              tooltip={selectAllTooltip}
            />
          </div>
        </>
      ) : (
        <BatchModeToggleWrapper>
          <Checkbox
            aria-label={selectAllTooltip}
            checked={false}
            disabled={isDisabled}
            onChange={handleCheckboxClick}
            tooltip={selectAllTooltip}
          />
        </BatchModeToggleWrapper>
      )}
    </ActionColumnHeader>
  )
}
