import { ValidationState } from './validation'
import { hasFatalError as hasFatalErrorInArray } from './validation/schema'

export function hasFatalError(state: ValidationState): boolean {
  return hasFatalErrorInArray(state.errors)
}

export function canRun(state: ValidationState): boolean {
  return !hasFatalError(state)
}

export function mustForceRun(state: ValidationState): boolean {
  return state.errors.length > 0
}
