import { useCallback, useEffect, useMemo, useState } from 'react'

import { useTagValues } from 'data/useTagValues'
import { useHttpNames, useHttpStatuses } from 'data/useHttpUrls'
import { useCheckNames } from 'data/useChecks'
import { useThresholdNames } from 'data/useThresholds'
import { useGRPCNames } from 'data/useGRPC'
import { useScenarios } from 'data/useScenarios'
import { useWebSocketsNames, useWebSocketsStatus } from 'data/useWebSockets'

import {
  booleanOptions,
  emptyScenarioPayload,
  isGrpcStatusCode,
  isHttpMethod,
} from './CustomFilter.utils'

import {
  TestRun,
  TestRunHttpFilterBy,
  TestRunThresholdsFilterBy,
  TestRunChecksFilterBy,
  TestRunFilter,
  TestRunGRPCFilterBy,
  TestRunWebSocketsFilterBy,
  TestRunTracesFilterBy,
  TestRunFilterWithOptions,
  TestRunBrowserHttpFilterBy,
  Scenario,
} from 'types'
import { uniqBy } from 'lodash-es'
import { useTracesFilterOptions } from 'data/useTraces'
import { tracesSummaryToOption } from 'utils/traces'
import { useBrowserHttpNames } from 'data/clients/entities/browserUrls'

type Option = { label: string; value: string }
type OptionsHookReturn = { options: Option[]; isLoading: boolean }

const toOption = (label: string, value = label): Option => ({
  label,
  value,
})

const toOptionByName = <T extends { name: string }>(value: T): Option =>
  toOption(value.name)

const toOptionByValue = <T extends { value: string }>(value: T): Option =>
  toOption(value.value)

const toOptionByStatus = <T extends { status: number }>(value: T): Option =>
  toOption(value.status.toString())

/**
 * Used for reference equality in dependency arrays to prevent needless re-triggering of hooks.
 */
const emptyArray: [] = []

const useOptions = <T>(
  values: T[] | undefined = emptyArray,
  callback: (value: T) => Option
) => {
  return useMemo(
    () => uniqBy(values.map(callback), (option) => option.value),
    [values, callback]
  )
}

const useHttpURLOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isLoading } = useHttpNames(run)

  const options = useOptions(data, toOptionByName)

  return { options, isLoading }
}

const useBrowserHttpURLOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isLoading } = useBrowserHttpNames(run)

  const options = useOptions(data, toOptionByName)

  return { options, isLoading }
}

const useThresholdNameOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isLoading } = useThresholdNames(run)

  const options = useOptions(data, toOptionByName)

  return { options, isLoading }
}

const useCheckNameOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isLoading } = useCheckNames(run)

  const options = useOptions(data, toOptionByName)

  return { options, isLoading }
}

const useBooleanOptions = (): OptionsHookReturn => {
  return {
    options: booleanOptions,
    isLoading: false,
  }
}

const useHttpMethodOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isLoading } = useTagValues(run, 'method')

  const methods = useMemo(() => data?.filter(isHttpMethod), [data])

  const options = useOptions(methods, toOptionByValue)

  return { options, isLoading }
}

const useHttpStatusOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isLoading } = useHttpStatuses(run)

  const options = useOptions(data, toOptionByStatus)

  return { options, isLoading }
}

const useFilteredScenariosOptions = (
  run: TestRun,
  filter: (scenario: Scenario) => boolean
): OptionsHookReturn => {
  const { data = emptyScenarioPayload, isLoading } = useScenarios(run)

  const scenarios = useMemo(() => {
    return data.value.filter(filter)
  }, [filter, data])

  const options = useOptions(scenarios, toOptionByName)

  return { options, isLoading }
}

const useHttpScenariosOptions = (run: TestRun): OptionsHookReturn => {
  return useFilteredScenariosOptions(
    run,
    ({ http_metric_summary }) => http_metric_summary.duration.count > 0
  )
}

const useBrowserScenariosOptions = (run: TestRun): OptionsHookReturn => {
  return useFilteredScenariosOptions(
    run,
    ({ browser_metric_summary }) =>
      browser_metric_summary.http_request_count > 0
  )
}

const useGrpcURLOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isLoading } = useGRPCNames(run)

  const options = useOptions(data, toOptionByName)

  return { options, isLoading }
}

const useGRPCStatusOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isLoading } = useTagValues(run, 'status')

  const statusCodes = useMemo(() => {
    return data?.filter(isGrpcStatusCode)
  }, [data])

  const options = useOptions(statusCodes, toOptionByValue)

  return { options: options as unknown as Option[], isLoading }
}

const useGRPCScenariosOptions = (run: TestRun): OptionsHookReturn => {
  const { data = emptyScenarioPayload, isLoading } = useScenarios(run)

  const scenarios = useMemo(() => {
    return data.value.filter(
      ({ grpc_metric_summary }) => grpc_metric_summary.duration.count > 0
    )
  }, [data])

  const options = useOptions(scenarios, toOptionByName)

  return { options, isLoading }
}

const useWebSocketsURLOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isLoading } = useWebSocketsNames(run)

  const options = useOptions(data, toOptionByName)

  return { options, isLoading }
}

const useWebSocketsStatusOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isLoading } = useWebSocketsStatus(run)

  const options = useOptions(data, toOptionByStatus)

  return { options, isLoading }
}

const useWebSocketsScenariosOptions = (run: TestRun): OptionsHookReturn => {
  const { data = emptyScenarioPayload, isLoading } = useScenarios(run)

  const scenarios = useMemo(() => {
    return data.value.filter(
      ({ ws_metric_summary }) => ws_metric_summary.session_duration.count > 0
    )
  }, [data])

  const options = useOptions(scenarios, toOptionByName)

  return { options, isLoading }
}

const useTracesScenarioOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isLoading } = useTracesFilterOptions({ run })

  const options = useOptions(
    data,
    useCallback(
      (summary) =>
        tracesSummaryToOption(summary, TestRunTracesFilterBy.Scenario),
      []
    )
  )

  return { options, isLoading }
}

const useTracesGroupOptions = (
  run: TestRun,
  precedingFilters: TestRunFilter[]
): OptionsHookReturn => {
  const { data, isLoading } = useTracesFilterOptions({
    run,
    filters: precedingFilters,
    enabled: precedingFilters.every((f) => f.values.length !== 0),
  })

  const options = useOptions(
    data,
    useCallback(
      (summary) => tracesSummaryToOption(summary, TestRunTracesFilterBy.Group),
      []
    )
  )

  return { options, isLoading }
}

const useTracesUrlOptions = (
  run: TestRun,
  precedingFilters: TestRunFilter[]
): OptionsHookReturn => {
  const { data, isLoading } = useTracesFilterOptions({
    run,
    filters: precedingFilters,
    enabled: precedingFilters.every((f) => f.values.length !== 0),
  })

  const options = useOptions(
    data,
    useCallback(
      (summary) => tracesSummaryToOption(summary, TestRunTracesFilterBy.URL),
      []
    )
  )

  return { options, isLoading }
}

const useTracesMethodOptions = (
  run: TestRun,
  precedingFilters: TestRunFilter[]
): OptionsHookReturn => {
  const { data, isLoading } = useTracesFilterOptions({
    run,
    filters: precedingFilters,
    enabled: precedingFilters.every((f) => f.values.length !== 0),
  })

  const options = useOptions(
    data,
    useCallback(
      (summary) => tracesSummaryToOption(summary, TestRunTracesFilterBy.Method),
      []
    )
  )

  return { options, isLoading }
}

const useTracesHttpStatusOptions = (
  run: TestRun,
  precedingFilters: TestRunFilter[]
): OptionsHookReturn => {
  const { data, isLoading } = useTracesFilterOptions({
    run,
    filters: precedingFilters,
    enabled: precedingFilters.every((f) => f.values.length !== 0),
  })

  const options = useOptions(
    data,
    useCallback(
      (summary) => tracesSummaryToOption(summary, TestRunTracesFilterBy.Status),
      []
    )
  )

  return { options, isLoading }
}

const useTracesSpanNameOptions = (
  run: TestRun,
  precedingFilters: TestRunFilter[]
): OptionsHookReturn => {
  const { data, isLoading } = useTracesFilterOptions({
    run,
    filters: precedingFilters,
  })

  const options = useOptions(
    data,
    useCallback(
      (summary) =>
        tracesSummaryToOption(summary, TestRunTracesFilterBy.SpanName),
      []
    )
  )

  return { options, isLoading }
}

const useTracesServiceNameOptions = (
  run: TestRun,
  precedingFilters: TestRunFilter[]
): OptionsHookReturn => {
  const { data, isLoading } = useTracesFilterOptions({
    run,
    filters: precedingFilters,
  })

  const options = useOptions(
    data,
    useCallback(
      (summary) =>
        tracesSummaryToOption(summary, TestRunTracesFilterBy.ServiceName),
      []
    )
  )

  return { options, isLoading }
}

const useTracesSpanKindOptions = (
  run: TestRun,
  precedingFilters: TestRunFilter[]
): OptionsHookReturn => {
  const { data, isLoading } = useTracesFilterOptions({
    run,
    filters: precedingFilters,
  })

  const options = useOptions(
    data,
    useCallback(
      (summary) =>
        tracesSummaryToOption(summary, TestRunTracesFilterBy.SpanKind),
      []
    )
  )

  return { options, isLoading }
}

const useTracesSpanStatusOptions = (
  run: TestRun,
  precedingFilters: TestRunFilter[]
): OptionsHookReturn => {
  const { data, isLoading } = useTracesFilterOptions({
    run,
    filters: precedingFilters,
  })

  const options = useOptions(
    data,
    useCallback(
      (summary) =>
        tracesSummaryToOption(summary, TestRunTracesFilterBy.SpanStatus),
      []
    )
  )

  return { options, isLoading }
}

const dataHooks = {
  [TestRunHttpFilterBy.URL]: useHttpURLOptions,
  [TestRunHttpFilterBy.ExpectedResponse]: useBooleanOptions,
  [TestRunHttpFilterBy.Method]: useHttpMethodOptions,
  [TestRunHttpFilterBy.Status]: useHttpStatusOptions,
  [TestRunHttpFilterBy.Scenario]: useHttpScenariosOptions,
  [TestRunChecksFilterBy.Name]: useCheckNameOptions,
  [TestRunThresholdsFilterBy.Name]: useThresholdNameOptions,
  [TestRunThresholdsFilterBy.Tainted]: useBooleanOptions,
  [TestRunGRPCFilterBy.URL]: useGrpcURLOptions,
  [TestRunGRPCFilterBy.Status]: useGRPCStatusOptions,
  [TestRunGRPCFilterBy.Scenario]: useGRPCScenariosOptions,
  [TestRunWebSocketsFilterBy.URL]: useWebSocketsURLOptions,
  [TestRunWebSocketsFilterBy.Status]: useWebSocketsStatusOptions,
  [TestRunWebSocketsFilterBy.Scenario]: useWebSocketsScenariosOptions,
  [TestRunTracesFilterBy.URL]: useTracesUrlOptions,
  [TestRunTracesFilterBy.Status]: useTracesHttpStatusOptions,
  [TestRunTracesFilterBy.Scenario]: useTracesScenarioOptions,
  [TestRunTracesFilterBy.Method]: useTracesMethodOptions,
  [TestRunTracesFilterBy.Group]: useTracesGroupOptions,
  [TestRunTracesFilterBy.SpanName]: useTracesSpanNameOptions,
  [TestRunTracesFilterBy.ServiceName]: useTracesServiceNameOptions,
  [TestRunTracesFilterBy.SpanKind]: useTracesSpanKindOptions,
  [TestRunTracesFilterBy.SpanStatus]: useTracesSpanStatusOptions,
  [TestRunBrowserHttpFilterBy.URL]: useBrowserHttpURLOptions,
  [TestRunBrowserHttpFilterBy.Scenario]: useBrowserScenariosOptions,
}

/**
 * TODO: Switching out the hooks this way is essentially breaking the no-conditional-hooks rule, since
 * two invocations of the hook could yield different hooks. It should instead use `useQuery` and simply
 * switch out the `UseQueryOptions` object.
 */
export function useCustomFilterOptions<T extends TestRunFilterWithOptions>(
  filter: T,
  run: TestRun,
  precedingFilters: TestRunFilterWithOptions[]
) {
  const [userCreatedOptions, setUserCreatedOptions] = useState<
    Array<{ label: string; value: string }>
  >([])

  const { options, isLoading } = dataHooks[filter.by](run, precedingFilters)

  useEffect(() => {
    if (!filter.allowCustomValue) {
      return
    }

    setUserCreatedOptions(
      filter.values
        .filter((value) => !options.find((option) => option.value === value))
        .map((value) => ({
          value,
          label: value,
        }))
    )
  }, [filter, options])

  return { options: [...options, ...userCreatedOptions], isLoading }
}
