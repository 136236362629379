import React from 'react'
import { Icon } from '@grafana/ui'
import { ColumnHeading, HeaderRow, SortButton } from './TableHeader.styles'
import { TableColumn, TableDefinition, ViewType } from '../types'
import { getSortIcon } from './TableHeader.utils'
import { SortOptions } from 'types'
import { TableCell } from './TableCell'
import { useSelectedColumns } from '../TableSettingsContext'
import { TableSettingsMenu } from './TableSettingsMenu'
import { BreakdownTableTestId } from 'types/dataTestIds'

interface HeaderCellProps<T, S> {
  column: TableColumn<T>
  sortBy: SortOptions<S> | undefined
  onSortChange: (options: SortOptions<S> | undefined) => void
}

function HeaderCell<T, S>({
  column,
  sortBy,
  onSortChange,
}: HeaderCellProps<T, S>) {
  if (!column.sortBy) {
    return <>{column.name}</>
  }

  const isActive = column.sortBy === sortBy?.sortBy

  const handleButtonClick = () => {
    if (column.sortBy === undefined || onSortChange === undefined) {
      return
    }

    if (!isActive) {
      onSortChange({
        sortBy: column.sortBy,
        direction: 'desc',
      })

      return
    }

    if (sortBy?.direction === 'asc') {
      onSortChange(undefined)

      return
    }

    onSortChange({
      ...sortBy,
      direction: 'asc',
    })
  }

  return (
    <SortButton
      aria-pressed={isActive}
      sorting={isActive}
      onClick={handleButtonClick}
    >
      {column.name} <Icon size="lg" name={getSortIcon(column, sortBy)} />
    </SortButton>
  )
}

interface TableHeaderProps<T, P, S> {
  view: ViewType
  table: TableDefinition<T, P>
  sortBy: SortOptions<S> | undefined
  onSortChange: (options: SortOptions<S> | undefined) => void
}

export function TableHeader<T, P, S>({
  view,
  table,
  sortBy,
  onSortChange,
}: TableHeaderProps<T, P, S>) {
  const columns = useSelectedColumns(table.columns)

  return (
    <HeaderRow data-testid={BreakdownTableTestId.HeaderRow}>
      {/* If the users disable all columns, we still want to display the menu button so that they can re-enable them again. */}
      {columns.length === 0 && (
        <ColumnHeading>
          <TableCell icon={<TableSettingsMenu view={view} table={table} />} />
        </ColumnHeading>
      )}
      {columns.map((column, index) => (
        <ColumnHeading
          key={column.id}
          numeric={column.numeric}
          shrink={column.shrink ?? false}
          aria-sort={addAriaSort(sortBy, column.sortBy)}
          data-testid={BreakdownTableTestId.Header(column)}
        >
          <TableCell
            icon={
              index === 0 && <TableSettingsMenu view={view} table={table} />
            }
          >
            <HeaderCell
              column={column}
              sortBy={sortBy}
              onSortChange={onSortChange}
            />
          </TableCell>
        </ColumnHeading>
      ))}
    </HeaderRow>
  )
}

function addAriaSort<T>(sortBy?: SortOptions<T>, columnSortBy?: string) {
  const isActiveColumn = columnSortBy === sortBy?.sortBy

  if (isActiveColumn && sortBy?.direction === 'asc') {
    return 'ascending'
  }

  if (isActiveColumn && sortBy?.direction === 'desc') {
    return 'descending'
  }

  return undefined
}
