import React, { ComponentProps, MouseEvent } from 'react'

import { TestRunWithMetrics } from 'types'
import { getTestRunColorString } from 'pages/utils'
import { getTestRunLabel, isTestSafe } from 'utils/testRun'
import { toBaseUnit } from 'utils/units'
import { resolveTimeZone } from 'utils/date'

import { SelectableTestRun } from '../TestRunsSelect.types'
import { getDuration, getTiming, getVus } from './Option.utils'
import {
  Button,
  ColumnContainer,
  Container,
  DisplayName,
  Icon,
  ResponseTime,
  Suffix,
  VirtualUsers,
} from './Option.styles'

interface OptionInnerProps {
  id: string
  onClick: (event: MouseEvent) => void
  onMouseMove: (event: MouseEvent) => void
  onMouseOver: (event: MouseEvent) => void
  tabIndex: number
}

interface OptionProps extends SelectableTestRun {
  data: SelectableTestRun
  innerProps: OptionInnerProps
}

export const Option = ({
  data,
  innerProps,
  innerRef,
  isFocused,
}: OptionProps) => {
  const { disabled, suffix, testRun } = data

  const metric = testRun.metrics[0]
  const unit = metric && toBaseUnit(metric.metric_name)

  return (
    <Container ref={innerRef}>
      <Button
        {...innerProps}
        $focused={isFocused}
        fullWidth
        disabled={disabled}
        fill="text"
        size="xs"
        variant="secondary"
      >
        <ColumnContainer>
          <OptionIcon testRun={testRun} />
          <DisplayName>
            {getTestRunLabel(testRun, resolveTimeZone())}
          </DisplayName>
          <ResponseTime>
            {metric && unit ? getTiming(metric.value, unit) : '-'}
          </ResponseTime>
          <VirtualUsers>{getVus(testRun.vus)}</VirtualUsers>
          <span>{getDuration(testRun.execution_duration)}</span>
          <Suffix>{suffix}</Suffix>
        </ColumnContainer>
      </Button>
    </Container>
  )
}

interface OptionIconProps {
  testRun: TestRunWithMetrics
}

interface IconProps extends ComponentProps<typeof Icon> {
  $color: string
}

const OptionIcon = ({ testRun }: OptionIconProps) => {
  const props: Omit<IconProps, 'name'> = {
    type: 'solid',
    size: 'xs',
    $color: getTestRunColorString(testRun),
  }

  if (testRun.is_baseline) {
    return <Icon {...props} name="star" />
  }

  if (isTestSafe(testRun)) {
    return <Icon {...props} name="lock" />
  }

  return <Icon {...props} name="circle-mono" />
}
