import React, { useEffect, useRef } from 'react'
import { PagedItems, SortOptions, TestRunAnalysis } from 'types'
import { PaginationWrapper, TableContainer } from './ListView.styles'
import { Diff, ListTableDefinition } from './types'
import { Pagination } from '@grafana/ui'
import { Table } from './Table'
import { ValueRow } from './ValueRow'
import { usePagedList } from './ListView.hooks'
import { useTableStateContext } from './TableStateProvider'
import { calculateTotalPages } from 'utils/pagination'
import { useComparedValues, useFilteredPage } from './hooks'

interface PageRowsProps<T, P> {
  analysis: TestRunAnalysis
  page: PagedItems<Diff<T>>
  params: P
  table: ListTableDefinition<T, P>
}

function PageRows<T, P>({
  analysis,
  page,
  table,
  params,
}: PageRowsProps<T, P>) {
  const filteredPage = useFilteredPage(table, page, params)

  return (
    <>
      {filteredPage.items.map((row) => (
        <ValueRow
          key={table.keyBy(row.left)}
          view="list"
          analysis={analysis}
          value={row}
          params={params}
          table={table}
          level={0}
        />
      ))}
    </>
  )
}

interface ListProps<T, P, S> {
  analysis: TestRunAnalysis
  params: P
  table: ListTableDefinition<T, P>
  page: number
  sortBy: SortOptions<S> | undefined
  onLoaded: () => void
  onSortChange: (sortBy: SortOptions<S> | undefined) => void
  onPageChange: (newPage: number) => void
}

export function ListView<T, P, S>({
  analysis,
  params,
  table,
  page,
  sortBy,
  onLoaded,
  onSortChange,
  onPageChange,
}: ListProps<T, P, S>) {
  const loaded = useRef(false)
  const currentPage = useRef(page)

  const { collapseAll } = useTableStateContext()

  const { isLoading, isFetching, isPreviousData, data } = usePagedList({
    testRun: analysis.main,
    params,
    table,
    page,
    sortBy,
  })

  const [rows] = useComparedValues({
    left: analysis.main,
    right: analysis.compareWith,
    pages: data,
    params,
    table,
  })

  useEffect(() => {
    if (!loaded.current && !isLoading) {
      onLoaded()

      loaded.current = true
    }
  }, [isLoading, onLoaded])

  useEffect(() => {
    if (currentPage.current !== page && !isPreviousData) {
      collapseAll()

      currentPage.current = page
    }
  }, [page, isPreviousData, collapseAll])

  const handleOnNavigate = (page: number) => {
    onPageChange(page)
  }

  const pageCount = calculateTotalPages({
    totalSize: data?.count || 0,
    pageSize: table.pageSize,
  })

  return (
    <>
      <TableContainer>
        <Table
          view="list"
          loading={isFetching || (rows !== undefined && rows.isFetching)}
          table={table}
          sortBy={sortBy}
          onSortChange={onSortChange}
        >
          {rows?.data && (
            <PageRows
              analysis={analysis}
              page={rows.data}
              params={params}
              table={table}
            />
          )}
        </Table>
      </TableContainer>
      {table.paginator === 'always' && (
        <PaginationWrapper>
          <Pagination
            currentPage={page}
            numberOfPages={pageCount}
            onNavigate={handleOnNavigate}
            hideWhenSinglePage
          />
        </PaginationWrapper>
      )}
    </>
  )
}
