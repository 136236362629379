import { useHistory } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { useDatasource } from 'datasourceContext'
import { useAppContext } from 'appContext'
import { showAlert } from 'utils/showAlert'
import { ApiError } from 'types'
import { routeMap } from 'routeMap'

import {
  toOrgProjectSettingsQueryKey,
  toProjectQueryKey,
  toProjectsQueryKey,
  toProjectsWithStatsKey,
} from './queryKeys'

export const useProject = (projectId: number | undefined) => {
  const { ds } = useDatasource()

  return useQuery({
    enabled: projectId !== undefined,

    queryKey: toProjectQueryKey(projectId),
    queryFn: () => ds.fetchProject(projectId!),
  })
}

export const useUpdateProject = () => {
  const queryClient = useQueryClient()
  const { ds } = useDatasource()
  const appContext = useAppContext()

  return useMutation<
    void,
    ApiError,
    { projectId: number; body: { name: string } },
    unknown
  >({
    mutationFn: (payload) => ds.updateProject(payload.projectId, payload.body),
    onSuccess: (_, params) => {
      queryClient.invalidateQueries(toProjectQueryKey(params.projectId))
      queryClient.invalidateQueries(toProjectsQueryKey(appContext.orgId))
      queryClient.invalidateQueries(toProjectsWithStatsKey(appContext.orgId))
    },
  })
}

export const useDeleteProject = () => {
  const queryClient = useQueryClient()
  const { ds } = useDatasource()
  const appContext = useAppContext()
  const history = useHistory()

  return useMutation<void, ApiError, number, unknown>({
    mutationFn: (projectId) => ds.deleteProject(projectId),
    onError: () =>
      showAlert('Failed to delete project, please try again later', 'error'),
    onSuccess: async (_, projectId) => {
      await Promise.all([
        queryClient.invalidateQueries(toProjectsQueryKey(appContext.orgId)),
        queryClient.invalidateQueries(toProjectsWithStatsKey(appContext.orgId)),
        queryClient.invalidateQueries(
          toOrgProjectSettingsQueryKey(appContext.orgId)
        ),
      ])
      history.push(routeMap.projectsList)
      queryClient.removeQueries(toProjectQueryKey(projectId))
    },
  })
}
