import { GaugeIcon } from './components/icons/GaugeIcon'
import { RocketIcon } from './components/icons/RocketIcon'
import { ClockIcon } from './components/icons/ClockIcon'
import { getGradientCircleIcon } from './components/icons/GradientCircle'
import { Step } from './components/Step'
import { docs } from 'components/DocLink'

export const GRADIENT_PROMO_ITEMS = [
  {
    icon: GaugeIcon,
    title: 'Shift left and collaborate on testing',
    text: 'A developer-centric experience that bridges the gaps between the traditional Dev, Testing, and Ops silos, allowing them to collaborate on testing.',
  },
  {
    icon: RocketIcon,
    title: 'Boost release confidence and velocity',
    text: 'Integrate performance testing into the CI/CD process, allowing teams to continuously test existing SLOs and deploy new features without breaking them.',
  },
  {
    icon: ClockIcon,
    /* cspell:disable-next-line */
    title: 'Reduce MTTR with full stack visibility',
    text: 'Correlate load test results and server-side data to gain full context for troubleshooting performance issues — without switching between platforms.',
  },
]

export const PROMO_STEP_ITEMS = [
  {
    icon: Step,
    title: 'Create tests',
    text: 'Write a test in Javascript in your text editor or IDE or use our no-code tools to speed up test creation.',
    moreURL: docs.javascript.main,
  },
  {
    icon: Step,
    title: 'Run cloud tests',
    text: 'From the CLI, run the k6 cloud command. In the Grafana Cloud k6 app, use the script editor, test builder or by re-running a listed test.',
    moreURL: docs.gettingStarted.runYouFirstTests,
  },
  {
    icon: Step,
    title: 'Analyze test results',
    text: 'View real-time result visualization as the test runs and correlate the data with other data sources in Grafana dashboards.',
    moreURL: docs.insights.overview,
  },
]

export const PROMO_CARD_ITEMS = [
  {
    icon: getGradientCircleIcon('cli'),
    title: 'Works seamlessly with CLI',
    text: 'Trigger tests from the k6 CLI.',
  },
  {
    icon: getGradientCircleIcon('renewal'),
    title: 'Automate',
    text: 'Integrate CLI into your automation pipelines. Pass/fail based on the test result.',
  },
  {
    icon: getGradientCircleIcon('rocket'),
    title: 'Scale with confidence across the globe',
    text: 'Run up to 1 MM+ VUs, 5MM+ requests per second across 21 geographic locations.',
  },
  {
    icon: getGradientCircleIcon('chart'),
    title: 'Analyze test results',
    text: 'Correlate and troubleshoot issues quickly within purpose built result analysis.',
  },
  {
    icon: getGradientCircleIcon('users'),
    title: 'Collaborate across teams',
    text: 'Central location for result storage and organizations, easily share results across teams and maintaining your culture of performance/reliability.',
  },
  {
    icon: getGradientCircleIcon('record'),
    title: 'Record test scripts',
    text: 'Onboard and familiarize users quickly with our GUI Test builder or browser recorder, designed to help you get started.',
  },
]
