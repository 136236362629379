import React, { useState } from 'react'

import type { Trend, TrendDataPoint } from 'types'
import { routeMap } from 'routeMap'
import { ProgressBar } from 'components/ProgressBar'
import { ValueTooltip } from '../ValueTooltip'
import { getProgressState, getBackgroundColor } from './Value.utils'
import { useShowTooltip } from './Value.hooks'

interface ValueProps {
  max: number
  trend: Trend
  dataPoint: TrendDataPoint
}

export function Value({ max, trend, dataPoint }: ValueProps) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const { showTooltip, onHideTooltip, onShowTooltip } = useShowTooltip()
  const progressState = getProgressState(dataPoint)
  const backgroundColor = getBackgroundColor(dataPoint)

  const value = progressState === 'finished' ? dataPoint.value : max

  return (
    <>
      <ProgressBar
        aria-label={`Go to test run ${dataPoint.run.id}`}
        color={backgroundColor}
        direction="vertical"
        isWorking={progressState !== 'finished'}
        max={max}
        onMouseOut={onHideTooltip}
        onMouseOver={onShowTooltip}
        onFocus={onShowTooltip}
        onBlur={onHideTooltip}
        ref={setReferenceElement}
        to={routeMap.testRun(dataPoint.run.id)}
        value={value}
      />

      {showTooltip && (
        <ValueTooltip
          show
          trend={trend}
          dataPoint={dataPoint}
          valueElement={referenceElement}
          titleColor={backgroundColor}
        />
      )}
    </>
  )
}
