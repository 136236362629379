import React from 'react'

import { LogsTabTestIds } from 'types/dataTestIds'
import { DocLink, docs } from 'components/DocLink'
import { CodeSnippet } from 'components/CodeSnippet'
import { BreakdownTabEmptyView, Content } from '../Breakdown.styles'

const LOGS_SNIPPET = `
  export default function main() {
    console.log('log')
    console.debug('debug')
    console.info('info')
    console.warn('warn')
    console.error('error')
  }
`

export const LogsTabEmptyView = () => {
  return (
    <BreakdownTabEmptyView data-testid={LogsTabTestIds.EmptyLogs}>
      <Content>
        <h2>Logs</h2>
        <p>
          In this tab {"you'll"} find execution logs and console.log output.
          Logging can be very useful when debugging a test script or as a means
          to get extra textual context when unexpected errors happen.
        </p>
        <p>
          {"Don't"} go overboard though, we have caps on how many log lines we
          store and {"we'll"} only display the last 1000 log lines in this tab.
        </p>
        <p>
          Read more about Logs in the{' '}
          <DocLink article={docs.analyze.inspect.logs}>docs</DocLink>.
        </p>
      </Content>
      <Content>
        <CodeSnippet code={LOGS_SNIPPET} />
      </Content>
    </BreakdownTabEmptyView>
  )
}
