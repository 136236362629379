import { useMemo } from 'react'
import { uniq } from 'lodash-es'

import { useAppContext } from 'appContext'
import { useCurrentProject } from 'projectContext'
import { ORGANIZATION_ROLE_TYPES, PROJECT_ROLE_TYPES } from 'constants/roles'
import { Test } from 'types'
import { isTestRunnable } from 'utils/test'

import {
  useHasActiveSubscription,
  useSubscriptionRule,
} from './useSubscriptions'
import { useFreeVUhsUsageReport } from './useUsageReports'
import { useProjects } from './useProjects'
import { useOrganization } from './useOrganization'

export const useIsRBACEnabled = () => {
  const org = useOrganization()

  return org?.grafana_rbac_enabled ?? false
}

const useOrgRole = () => {
  const { orgId, account } = useAppContext()

  if (!orgId) {
    return
  }

  return account.organization_roles.find(
    (role) => role.organization_id === orgId
  )
}

const useProjectRole = () => {
  const { account } = useAppContext()
  const project = useCurrentProject()

  return account.project_roles.find((role) => role.project_id === project.id)
}

export const useProjectRoleByProjectId = (projectId: number) => {
  const { account } = useAppContext()

  if (!account) {
    return
  }

  return account.project_roles.find((role) => role.project_id === projectId)
}

export const useProjectRoleGrantedByTeamByProjectId = (projectId: number) => {
  const { account } = useAppContext()

  if (!account) {
    return
  }

  return account.project_roles_granted_by_teams.find(
    (role) => role.project_id === projectId
  )
}

export const useIsUserOrgAdmin = () => {
  const orgRole = useOrgRole()

  if (!orgRole) {
    return false
  }

  return orgRole.role_id === ORGANIZATION_ROLE_TYPES.ADMIN
}

export const useIsUserOrgReadWrite = () => {
  const orgRole = useOrgRole()

  if (!orgRole) {
    return false
  }

  return orgRole.role_id === ORGANIZATION_ROLE_TYPES.READ_WRITE
}

export const useHasUserProjectWriteAccess = () => {
  const project = useCurrentProject()

  return useHasWriteAccessToProject(project.id)
}

export const useIsUserProjectAdmin = () => {
  const isUserOrgAdmin = useIsUserOrgAdmin()

  // Project admin can only be granted directly and not via a team
  const projectRole = useProjectRole()

  // If current user is an org admin he already has the needed permissions
  if (isUserOrgAdmin) {
    return true
  }

  if (!projectRole) {
    return false
  }

  return projectRole.role_id === PROJECT_ROLE_TYPES.ADMIN
}

export const useIsUserProjectAdminByProjectId = (projectId: number) => {
  const isUserOrgAdmin = useIsUserOrgAdmin()
  const projectRole = useProjectRoleByProjectId(projectId)

  if (isUserOrgAdmin) {
    return true
  }

  if (!projectRole) {
    return false
  }

  return projectRole.role_id === PROJECT_ROLE_TYPES.ADMIN
}

export const useHasWriteAccessToProject = (projectId: number) => {
  const isUserOrgAdmin = useIsUserOrgAdmin()
  const projectRole = useProjectRoleByProjectId(projectId)
  const projectRoleGrantedByTeam =
    useProjectRoleGrantedByTeamByProjectId(projectId)

  if (isUserOrgAdmin) {
    return true
  }

  return [projectRole, projectRoleGrantedByTeam].some(
    (role) =>
      role?.role_id === PROJECT_ROLE_TYPES.READ_WRITE ||
      role?.role_id === PROJECT_ROLE_TYPES.ADMIN
  )
}

export const useCanUserRunTests = () => {
  const hasActiveSubscription = useHasActiveSubscription()
  const hasUserProjectWriteAccess = useHasUserProjectWriteAccess()
  const hasCloudExecution = useSubscriptionRule(
    'load_test.k6_cloud_execution.allow'
  )
  const freeVUhsUsageReport = useFreeVUhsUsageReport()

  const hasReachedVUhLimit =
    freeVUhsUsageReport && freeVUhsUsageReport.vuhLimitReached

  return (
    hasActiveSubscription &&
    hasUserProjectWriteAccess &&
    hasCloudExecution &&
    !hasReachedVUhLimit
  )
}

export const useCanUserRunTest = (test?: Test | null) => {
  const canUserRunTests = useCanUserRunTests()

  // New test
  if (!test) {
    return canUserRunTests
  }

  return canUserRunTests && isTestRunnable(test)
}

export const useCanUserCreateProjects = () => {
  const isUserOrgAdmin = useIsUserOrgAdmin()
  const isUserOrgReadWrite = useIsUserOrgReadWrite()

  return isUserOrgAdmin || isUserOrgReadWrite
}

export const useUsersReadWriteProjectIds = () => {
  const { account } = useAppContext()
  const { project_roles = [], project_roles_granted_by_teams = [] } = account

  const projectIds = useMemo(() => {
    return uniq(
      [...project_roles, ...project_roles_granted_by_teams]
        .filter(
          ({ role_id }) =>
            role_id === PROJECT_ROLE_TYPES.READ_WRITE ||
            role_id === PROJECT_ROLE_TYPES.ADMIN
        )
        .map(({ project_id }) => project_id)
    )
  }, [project_roles, project_roles_granted_by_teams])

  return projectIds
}

export const useCanUserScheduleTests = () => {
  const isUserOrgAdmin = useIsUserOrgAdmin()
  const isUserOrgReadWrite = useIsUserOrgReadWrite()

  return isUserOrgAdmin || isUserOrgReadWrite
}

export const useIsDefaultProjectAdmin = () => {
  const { account } = useAppContext()
  const { data: projects = [] } = useProjects()

  const defaultProject = projects.find((project) => project.is_default)

  if (!account || !defaultProject) {
    return false
  }

  const defaultProjectRole = account.project_roles.find(
    (role) => role.project_id === defaultProject.id
  )

  if (!defaultProjectRole) {
    return false
  }

  return defaultProjectRole.role_id === PROJECT_ROLE_TYPES.ADMIN
}
