export async function parseScript(script: string) {
  try {
    const acorn = await import('acorn')

    return acorn.parse(script, {
      ecmaVersion: 2023,
      sourceType: 'module',
      locations: true,
    })
  } catch {
    return null
  }
}
