import { css } from '@emotion/css'
import { useTheme2 } from '@grafana/ui'
import { ThemeBreakpointsKey } from '@grafana/data'

export function useResponsiveRow(breakpoint: ThemeBreakpointsKey) {
  const theme = useTheme2()

  return css`
    @container (max-width: ${theme.breakpoints.values[breakpoint]}px) {
      flex-direction: column;
      //gap: ${theme.spacing(3)};
      & > div {
        flex-basis: auto !important;
      }
    }

    @container (max-width: ${theme.breakpoints.values.md}px) {
      .hidden-md {
        display: none;
      }
    }
  `
}
