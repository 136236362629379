import React, { useMemo } from 'react'
import { TestRun } from 'types'
import { isTestDone } from 'utils/testRun'
import { useMediaQuery } from 'hooks/useMediaQuery'
import { Sticky } from 'components/Sticky'
import { Body } from '../TestRunPage/components/TestRunMetaBar/TestRunMetaBar.styles'
import { RunsPageTestIds } from 'types/dataTestIds'
import { icons, MetaBar } from 'components/MetaBar'
import { StartedBy } from '../TestRunPage/components/TestRunMetaBar/StartedBy'
import { getTestRunMetaData } from '../TestRunPage/components/TestRunMetaBar/TestRunMetaBar.utils'
import { Button } from '@grafana/ui'

interface TestMetaProps {
  testRun: TestRun
  onCompactClick: () => void
  compact: boolean
}
export function TestMeta({ testRun, onCompactClick, compact }: TestMetaProps) {
  const metaData = useMemo(() => {
    return getTestRunMetaData(testRun)
  }, [testRun])

  const isSmallScreen = useMediaQuery('lg')

  return (
    <>
      <Sticky disabled={!isSmallScreen} direction="column" marginBottom={1}>
        <Body>
          <MetaBar data-testid={RunsPageTestIds.MetaBar}>
            <MetaBar.Section>
              <MetaBar.Item.Status testRun={testRun} />
              <MetaBar.Item.Vuh value={metaData.vuh} />
              <MetaBar.Item.Vus value={metaData.vus} />
              <MetaBar.Item.ExecutedDuration
                hidden={!isTestDone(testRun)}
                value={metaData.executionDuration}
              />
              <MetaBar.Item.LoadZones testRun={testRun} />
              <MetaBar.Item icon={icons.user}>
                <StartedBy userId={testRun.user_id} />
              </MetaBar.Item>
              <MetaBar.Item>
                <Button
                  size="sm"
                  variant="primary"
                  fill={compact ? 'solid' : 'outline'}
                  onClick={onCompactClick}
                >
                  Compact
                </Button>
              </MetaBar.Item>
            </MetaBar.Section>
          </MetaBar>
        </Body>
      </Sticky>
    </>
  )
}
