import { type AbsoluteTimeRange } from '@grafana/data'

import type { Http, SortOptions, TestRunId } from 'types'
import { K6DataSource } from 'datasource/datasource'
import { FilterExpression, includes } from 'datasource/serialization'
import { useDatasource } from 'datasourceContext'
import { EntityClient } from './entityClient'

interface FetchPageParams {
  filter: FilterExpression<Http>
  page: number
  pageSize: number
  sortBy: SortOptions<Http> | undefined
  timeRange?: AbsoluteTimeRange
}

export class HttpUrlClient extends EntityClient<Http> {
  constructor(datasource: K6DataSource) {
    super(datasource)
  }

  toUrl(testRunId: string | number): string {
    return `/loadtests/v4/test_runs(${testRunId})/http_urls`
  }

  fetchByIds(testRunId: TestRunId, ids: string[]) {
    return this.fetchPage(testRunId, {
      filter: includes('id', ids),
      page: 1,
      pageSize: ids.length,
      sortBy: undefined,
    })
  }

  fetchPage(
    testRunId: TestRunId,
    { filter, page, pageSize, sortBy, timeRange }: FetchPageParams
  ) {
    return this.fetch({
      testRunId,
      query: {
        select: [
          'id',
          'scenario_id',
          'group_id',
          'name',
          'method',
          'status',
          'scenario',
          'expected_response',
          'http_metric_summary',
        ],
        filter,
        orderBy: sortBy && [[sortBy.sortBy, sortBy.direction]],
        skip: (page - 1) * pageSize,
        top: pageSize,
        count: true,
      },
      timeRange,
    })
  }
}

export function useHttpUrlClient() {
  const { ds } = useDatasource()

  return new HttpUrlClient(ds)
}
