export const getNoteMenuLabel = (
  note: string,
  hasUserProjectWriteAccess: boolean
) => {
  const hasNote = note.length > 0

  if (hasNote) {
    return hasUserProjectWriteAccess ? 'Edit note' : 'Read note'
  }

  return 'Create note'
}
