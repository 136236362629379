import React from 'react'
import { ToolbarButtonRow, ToolbarButton } from '@grafana/ui'

import { TestRun } from 'types'
import { ParsedLog } from 'types/logs'
import { LOGS_LIMIT } from 'constants/index'
import { isTestActive } from 'utils/testRun'
import { DocLink, docs } from 'components/DocLink'

interface LogControlsProps {
  isEmptyQuery: boolean
  isFetching: boolean
  logs: ParsedLog[]
  page: number
  testRun: TestRun
  onPageChange: (page: number) => void
}

export const LogControls = ({
  isEmptyQuery,
  isFetching,
  logs,
  page,
  testRun,
  onPageChange,
}: LogControlsProps) => {
  const isMaxLogCount = logs.length === LOGS_LIMIT
  const isTestRunActive = isTestActive(testRun)

  function handleOlderClick() {
    onPageChange(page + 1)
  }

  function handleNewerClick() {
    onPageChange(page - 1)
  }

  return (
    <>
      {!isEmptyQuery && (
        <ToolbarButtonRow>
          <ToolbarButton
            variant="primary"
            disabled={isFetching || isTestRunActive || !isMaxLogCount}
            onClick={handleOlderClick}
          >
            Older
          </ToolbarButton>
          <ToolbarButton
            variant="primary"
            disabled={isFetching || isTestRunActive || page === 0}
            onClick={handleNewerClick}
          >
            Newer
          </ToolbarButton>
        </ToolbarButtonRow>
      )}

      <div>
        Read more about Logs in the{' '}
        <DocLink article={docs.analyze.inspect.logs}>docs</DocLink>.
      </div>
    </>
  )
}
