import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Icon, MultiSelect, MultiSelectCommonProps } from '@grafana/ui'

import { Selectable } from 'datasource/Selects/types'
import { OrganizationRole } from 'types'
import { RefetchIcon } from 'components/RefetchIcon'
import { spacing } from 'utils/styled'
import { useOrgRoles } from 'data/useOrgRoles'

export const getContributorsOptions = (
  orgRoles: OrganizationRole[] | undefined
): Array<Selectable<string>> => {
  if (!orgRoles) {
    return []
  }

  return orgRoles.map(({ user_email, user_id }) => ({
    label: user_email,
    value: user_id.toString(),
  }))
}

interface ContributorsSelectProps
  extends Omit<MultiSelectCommonProps<string>, 'onChange' | 'value'> {
  value: string[]
  onChange(value: string[]): void
}

export const ContributorsSelect = (props: ContributorsSelectProps) => {
  const { data: orgRoles, refetch, isError, isFetching } = useOrgRoles()
  const [isErrorState, setIsErrorState] = useState(false) // Separate error state to keep error message while refetching

  useEffect(() => {
    if (!isFetching) {
      setIsErrorState(isError)
    }
  }, [isError, isFetching])

  return (
    <ContributorsContainer>
      <MultiSelect
        {...props}
        value={props.value}
        className="multiple-select"
        placeholder="All project contributors"
        isClearable
        options={getContributorsOptions(orgRoles)}
        onChange={(selectedOptions) => {
          const values = selectedOptions.map(({ value }) => value!)
          props.onChange(values)
        }}
      />

      {isErrorState && (
        <span className="icon-container">
          {isFetching ? (
            <Icon name="fa fa-spinner" />
          ) : (
            <RefetchIcon entity="contributors" onClick={() => refetch()} />
          )}
        </span>
      )}
    </ContributorsContainer>
  )
}

export const ContributorsContainer = styled.div`
  display: flex;
  align-items: center;

  .icon-container {
    margin-left: ${spacing(1)};
    height: auto;
    display: flex;
  }
`
