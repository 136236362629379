import React, { ReactNode, useState } from 'react'
import { Collapse, Icon } from '@grafana/ui'
import styled from 'styled-components'

import { color } from 'utils/styled'

interface Props {
  label: ReactNode
  children?: ReactNode
}

export const InlineCollapse = ({ label, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <StyledCollapse
      label={
        <StyledHeader>
          {label}
          <Icon name={`${isOpen ? 'angle-up' : 'angle-down'}`} />
        </StyledHeader>
      }
      isOpen={isOpen}
      onToggle={(o) => setIsOpen(o)}
    >
      {children}
    </StyledCollapse>
  )
}

const StyledHeader = styled.span`
  font-weight: 400;
  background: none;
  border: 0;
  color: ${color('text', 'link')};

  &:hover {
    text-decoration: underline;
  }
`

const StyledCollapse = styled(Collapse)`
  && {
    display: inline;
    border: 0;

    button {
      padding: 0;
    }
  }
`
