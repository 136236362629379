import React, { useReducer } from 'react'
import { Button } from '@grafana/ui'

import { useMediaQuery } from 'hooks/useMediaQuery'
import { DocLink, docs } from 'components/DocLink'
import { Flex } from 'components/Flex'
import { Section } from 'components/Section'
import { Switch } from 'components/Switch'
import { ExternalLink } from 'components/ExternalLink'

import { LoadZoneGuide } from './components/LoadZoneGuide'
import { LoadZoneTable } from './components/LoadZoneTable'
import { useLoadZones } from 'data/useLoadZones'
import { spacing } from 'utils/styled'
import styled from 'styled-components'

const FEEDBACK_FORM = 'https://forms.gle/FQVp1292r8R9h3jN9'

const toggle = (bool: boolean) => !bool

export const LoadZonesTab = () => {
  const isMobile = useMediaQuery('sm')

  const { data: loadZones = [] } = useLoadZones()

  const showFeedbackButton =
    loadZones.length > 0 && loadZones.some((loadZone) => !loadZone.public)

  const [isPublicZonesVisible, toggleIsPublicZonesVisible] = useReducer(
    toggle,
    true
  )
  const [isOpen, toggleIsOpen] = useReducer(toggle, false)

  return (
    <Section
      title="Load Zones"
      description={
        <>
          A <DocLink article={docs.loadZones.main}>load zone</DocLink> is a
          location where you can run tests in Grafana Cloud. We provide various
          load zones all over the world ready to be used. Alternatively, you can
          create your own private load zone and run it in your infrastructure.
          Learn more in the{' '}
          <DocLink article={docs.loadZones.private}>
            private load zone documentation
          </DocLink>
          .
        </>
      }
    >
      <div>
        <ActionsHeader
          align={!isMobile ? 'center' : 'start'}
          direction={!isMobile ? 'row' : 'column'}
          gap={!isMobile ? 1 : 2}
          justify="space-between"
          width="100%"
        >
          {!isMobile && (
            <Switch
              aria-label="Show all load zones"
              onLabel="Show all load zones"
              value={isPublicZonesVisible}
              onChange={toggleIsPublicZonesVisible}
            />
          )}

          <ButtonsContainer
            align={!isMobile ? 'center' : 'start'}
            direction={!isMobile ? 'row' : 'column'}
            gap={1}
          >
            <Button
              fill="text"
              icon="info-circle"
              variant="primary"
              onClick={toggleIsOpen}
            >
              How to use Private Load Zones?
            </Button>
            {showFeedbackButton && (
              <ExternalLink url={FEEDBACK_FORM}>
                <Button icon="envelope" variant="primary" size="sm">
                  Share feedback
                </Button>
              </ExternalLink>
            )}
          </ButtonsContainer>
          {isMobile && (
            <Flex direction="column" width="100%">
              <hr style={{ width: '100%' }} />
              <Switch
                aria-label="Show all load zones"
                onLabel="Show all load zones"
                value={isPublicZonesVisible}
                onChange={toggleIsPublicZonesVisible}
              />
            </Flex>
          )}
        </ActionsHeader>

        <LoadZoneGuide isOpen={isOpen} onClose={toggleIsOpen} />
        <LoadZoneTable isPublicZonesVisible={isPublicZonesVisible} />
      </div>
    </Section>
  )
}

const ActionsHeader = styled(Flex)`
  min-height: ${spacing(5)};
`
const ButtonsContainer = styled(Flex)`
  > button {
    padding-left: ${spacing(1)};
    padding-right: ${spacing(1)};
  }
`
