import { HistogramQuantile, MetricType, TrendAggregation } from 'types'
import { withNumericSuffix } from 'utils/formatters'
import { NonEmptyList } from '../../typescript'
import { SelectableValue } from '@grafana/data'
import { SelectOption } from 'datasource/Selects/VariableSelect.types'
import { MetricOption } from '../metricOptions'

type StandardQuantiles =
  | HistogramQuantile<50>
  | HistogramQuantile<90>
  | HistogramQuantile<95>
  | HistogramQuantile<99>

export interface TrendAggregationOption {
  aggregation: TrendAggregation
  label: string
  shorthand?: string
  metricType: MetricType
  description: string
}

interface StrictTrendAggregationOption<A extends TrendAggregation>
  extends TrendAggregationOption {
  aggregation: A
}

type TrendAggregationsByName = {
  [P in
    | Exclude<TrendAggregation, HistogramQuantile>
    | StandardQuantiles]: StrictTrendAggregationOption<P>
}

const aggregationsByName: TrendAggregationsByName = {
  // Counter
  increase: {
    label: 'Increase',
    description: 'Counter increase in time period',
    metricType: MetricType.COUNTER,
    aggregation: 'increase',
  },
  value: {
    label: 'Cumulative sum',
    shorthand: 'Cum. sum',
    description: 'Counter total value up to the end of time period',
    metricType: MetricType.COUNTER,
    aggregation: 'value',
  },
  rate: {
    label: 'Rate',
    description: 'Average rate of counter increase per second',
    metricType: MetricType.COUNTER,
    aggregation: 'rate',
  },
  'max_rate(1)': {
    label: 'Max rate',
    description: 'Peak counter increase per second over time period',
    metricType: MetricType.COUNTER,
    aggregation: 'max_rate(1)',
  },

  // Gauge
  'sum(max by (instance_id))': {
    label: 'Sum of max',
    description: 'Sum of: Largest reported gauge value',
    metricType: MetricType.GAUGE,
    aggregation: 'sum(max by (instance_id))',
  },
  'sum(avg by (instance_id))': {
    label: 'Sum of avg',
    description: 'Sum of: Average of reported gauge values',
    metricType: MetricType.GAUGE,
    aggregation: 'sum(avg by (instance_id))',
  },
  avg: {
    label: 'Avg',
    description: 'Average of reported gauge values',
    metricType: MetricType.GAUGE,
    aggregation: 'avg',
  },
  min: {
    label: 'Min',
    description: 'Smallest reported gauge value',
    metricType: MetricType.GAUGE,
    aggregation: 'min',
  },
  max: {
    label: 'Max',
    description: 'Largest reported gauge value',
    metricType: MetricType.GAUGE,
    aggregation: 'max',
  },
  last: {
    label: 'Last',
    description: 'Latest reported gauge value',
    metricType: MetricType.GAUGE,
    aggregation: 'last',
  },

  // Rate
  ratio: {
    label: 'Non-zero fraction',
    description: 'Fraction of reported non-zero values, e.g. successful Checks',
    metricType: MetricType.RATE,
    aggregation: 'ratio',
  },
  rate_nz: {
    label: 'Rate (non-zero)',
    description: 'Total count increase per second',
    metricType: MetricType.RATE,
    aggregation: 'rate_nz',
  },
  rate_z: {
    label: 'Rate (zero)',
    description: 'Total count increase per second',
    metricType: MetricType.RATE,
    aggregation: 'rate_z',
  },
  rate_total: {
    label: 'Rate',
    description: 'Total count increase per second',
    metricType: MetricType.RATE,
    aggregation: 'rate_total',
  },
  increase_nz: {
    label: 'Increase (non-zero)',
    description: 'Non-zero observations count increase over time period',
    metricType: MetricType.RATE,
    aggregation: 'increase_nz',
  },
  increase_z: {
    label: 'Increase (zero)',
    description: 'Non-zero observations count increase over time period',
    metricType: MetricType.RATE,
    aggregation: 'increase_z',
  },
  increase_total: {
    label: 'Increase',
    description: 'Count increase over time period',
    metricType: MetricType.RATE,
    aggregation: 'increase_total',
  },
  value_nz: {
    label: 'Cumulative sum (non-zero)',
    shorthand: 'Cum. sum (non-zero)',
    description:
      'Non-zero observations count value up to the end of time period',
    metricType: MetricType.RATE,
    aggregation: 'value_nz',
  },
  value_z: {
    label: 'Cumulative sum (zero)',
    shorthand: 'Cum. sum (zero)',
    description:
      'Non-zero observations count value up to the end of time period',
    metricType: MetricType.RATE,
    aggregation: 'value_z',
  },
  value_total: {
    label: 'Cumulative sum',
    shorthand: 'Cum. sum',
    description: 'Total count value up to the end of time period',
    metricType: MetricType.RATE,
    aggregation: 'value_total',
  },

  // Trend
  histogram_avg: {
    label: 'Avg',
    description: 'Average of values',
    metricType: MetricType.TREND,
    aggregation: 'histogram_avg',
  },
  histogram_max: {
    label: 'Max',
    description: 'Maximum observed value',
    metricType: MetricType.TREND,
    aggregation: 'histogram_max',
  },
  histogram_min: {
    label: 'Min',
    description: 'Minimum observed value',
    metricType: MetricType.TREND,
    aggregation: 'histogram_min',
  },
  histogram_stddev: {
    label: 'Standard deviation',
    shorthand: 'Std. dev',
    description: 'Standard deviation',
    metricType: MetricType.TREND,
    aggregation: 'histogram_stddev',
  },
  'histogram_quantile(0.50)': {
    label: 'Mean',
    description: '50th percentile of observed values',
    metricType: MetricType.TREND,
    aggregation: 'histogram_quantile(0.50)',
  },
  'histogram_quantile(0.90)': {
    label: '90th percentile',
    shorthand: 'P90',
    description: '90th percentile of observed values',
    metricType: MetricType.TREND,
    aggregation: 'histogram_quantile(0.90)',
  },
  'histogram_quantile(0.95)': {
    label: '95th percentile',
    shorthand: 'P95',
    description: '95th percentile of observed values',
    metricType: MetricType.TREND,
    aggregation: 'histogram_quantile(0.95)',
  },
  'histogram_quantile(0.99)': {
    label: '99th percentile',
    shorthand: 'P99',
    description: '99th percentile of observed values',
    metricType: MetricType.TREND,
    aggregation: 'histogram_quantile(0.99)',
  },
}

const trendingMethodOptions = [
  ...Object.values(aggregationsByName),
] as NonEmptyList<TrendAggregationOption>

const isHistogramQuantile = (
  aggregation: TrendAggregation
): aggregation is HistogramQuantile =>
  aggregation.startsWith('histogram_quantile')

export const toTrendAggregationOption = (
  aggregation: TrendAggregation
): TrendAggregationOption => {
  if (isHistogramQuantile(aggregation)) {
    const standardQuantile =
      aggregationsByName[aggregation as StandardQuantiles]

    if (standardQuantile !== undefined) {
      return standardQuantile
    }

    const [, percentage, fraction] =
      /^histogram_quantile\(0\.(\d\d)(\d*)\)$/.exec(aggregation) || [
        '',
        '00',
        '',
      ]

    const withFraction = percentage + (fraction !== '' ? '.' + fraction : '')
    const withSuffix = withNumericSuffix(withFraction)

    return {
      aggregation: aggregation,
      metricType: MetricType.TREND,
      label: `${withSuffix} Percentile`,
      shorthand: `P${withFraction}`,
      description: `Histogram 0.${percentage}-quantile`,
    }
  }

  if (aggregationsByName[aggregation] === undefined) {
    return {
      aggregation: aggregation,
      metricType: MetricType.COUNTER,
      label: aggregation,
      description: '',
    }
  }

  return aggregationsByName[aggregation]
}

export const getTrendingMethodOptions = (
  metricType: MetricType | null | undefined
): Array<SelectableValue<TrendAggregationOption>> => {
  return trendingMethodOptions
    .filter((aggregationMethod) => aggregationMethod.metricType === metricType)
    .map((option) => ({
      label: option.label,
      value: option,
      description: option.description,
    }))
}

export const resolveMethodOption = (
  metricOption?: SelectOption<MetricOption>
): TrendAggregationOption | undefined => {
  if (
    metricOption?.type !== 'known' ||
    !metricOption.data.metric?.type ||
    !metricOption.value
  ) {
    return
  }

  const methodOptions = getTrendingMethodOptions(metricOption.data.metric.type)
  const derivedMethodOption = methodOptions.find((methodOption) => {
    return methodOption.value?.metricType === metricOption.data.metric?.type
  })

  if (!derivedMethodOption?.value) {
    return trendingMethodOptions[0]
  }

  const isDefaultMethodOption = trendingMethodOptions.find(
    (method) => method.metricType === derivedMethodOption?.value?.metricType
  )
  return isDefaultMethodOption
    ? derivedMethodOption?.value
    : trendingMethodOptions[0]
}
