import React, { Component, ErrorInfo, ReactNode } from 'react'
import { isFetchError } from '@grafana/runtime'

import { sentryClient } from 'services/sentryClient'
import { ErrorState } from 'pages/ProjectPage/components/ErrorState'
import { ErrorBoundaryFullPageView } from 'services/tracking.types'

interface ErrorBoundaryProps {
  children?: ReactNode
  view: ErrorBoundaryFullPageView
}

interface ErrorBoundaryState {
  error: unknown
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = {
    error: null,
  }

  static getDerivedStateFromError(error: unknown) {
    return { error }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // Fetch errors handled in datasource
    if (!isFetchError(error)) {
      sentryClient.captureException(error, {
        contexts: {
          react: {
            componentStack: info.componentStack,
          },
        },
      })
    }
  }

  render() {
    if (this.state.error) {
      return <ErrorState error={this.state.error} view={this.props.view} />
    }

    return this.props.children
  }
}
