import {
  RUN_PROCESS,
  CreationProcess,
  Test,
  TestRunStatus,
  BUILDER_CREATION_PROCESSES,
  SCRIPT_CREATION_PROCESSES,
} from 'types'

import { isSecureRun, isTestRunExpired } from './testRun'
import { inArray } from './array'
import { DEFAULT_SCRIPT } from 'constants/scripts'

export const getTestScript = (test: Test | null) => {
  return test?.script ?? test?.test_runs[0]?.script ?? DEFAULT_SCRIPT
}

export const isCLITest = (test: Test) => {
  const latestRun = test.test_runs[0]

  return (
    latestRun?.id !== undefined &&
    [RUN_PROCESS.K6_CLOUD, RUN_PROCESS.K6_INGEST].includes(
      latestRun.run_process
    )
  )
}

export const isIngestTest = (test: Test) => {
  return test.test_runs[0]?.run_process === RUN_PROCESS.K6_INGEST
}

export const isDistributedTest = (test: Test) => {
  return (
    test.test_runs[0]?.run_process === RUN_PROCESS.K6_INGEST_WITH_THRESHOLDS
  )
}

export const isBuilderTest = (test: Test) => {
  return BUILDER_CREATION_PROCESSES.includes(test.creation_process)
}

export const isScriptTest = (test: Test) => {
  return SCRIPT_CREATION_PROCESSES.includes(test.creation_process)
}

export const isReadOnlyTest = (test: Test) => {
  return isCLITest(test) || isBuilderTest(test) || isSecureTest(test)
}

export const isCouldCliTest = (test: Test) => {
  return test.creation_process === CreationProcess.Cloud
}

export const isScriptEditableByCreationProcess = inArray([
  CreationProcess.Script,
  CreationProcess.TestBuilder,
  CreationProcess.ScriptChromeExtension,
  CreationProcess.ScriptFirefoxExtension,
  CreationProcess.BuilderChromeExtension,
  CreationProcess.BuilderFirefoxExtension,
])

export const isTestScriptEditable = (test?: Test) => {
  return (
    test &&
    !isSecureTest(test) &&
    (isScriptEditableByCreationProcess(test.creation_process) ||
      isUnknownScriptType(test))
  )
}

export const isTestRunnable = (test: Test) => {
  const latestRun = test.test_runs[0]

  const isLastTestRunFromIngest =
    latestRun?.run_process === RUN_PROCESS.K6_INGEST

  const isTestCreatedCorrectly = [
    CreationProcess.TestBuilder,
    CreationProcess.Script,
    CreationProcess.ScriptChromeExtension,
    CreationProcess.ScriptFirefoxExtension,
    CreationProcess.BuilderChromeExtension,
    CreationProcess.BuilderFirefoxExtension,
    CreationProcess.Cloud,
  ].includes(test.creation_process)

  if (isCouldCliTest(test)) {
    return isCloudCliTestRunnable(test)
  }

  return (
    isTestCreatedCorrectly && !isLastTestRunFromIngest && !isSecureTest(test)
  )
}

export const isCloudCliTestRunnable = (test: Test) => {
  const latestRun = test.test_runs[0]

  return !!latestRun && !isTestRunExpired(latestRun) && !isSecureTest(test)
}

export function isSecureTest(test: Test) {
  const lastTestRun = test.test_runs[0]

  if (!lastTestRun) {
    return false
  }

  return isSecureRun(lastTestRun)
}

export const isUnknownScriptType = (test: Test) => {
  return (
    test.creation_process === CreationProcess.Unknown && test.script !== null
  )
}

// Returns whether the latest test run was ran with from the CLI with `--upload-only`
// IMPORTANT: `--upload-only` will queue a script that is not visible in the UI (a previously
// uploaded script may lead the user to think they are looking at the uploaded script)
export const isUploadedTest = (test: Test) => {
  const [latestRun] = test.test_runs

  return latestRun?.run_status === TestRunStatus.UPLOADED
}
