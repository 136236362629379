import styled from 'styled-components'
import { Icon } from '@grafana/ui'

import { IconWithPredefinedName } from 'types/icon'

export const InsightItemRoot = styled.div`
  font-size: 1rem;

  display: flex;
  align-items: center;
  min-height: 50px;
  pointer-events: none;

  a {
    pointer-events: all;
  }

  .category {
    :before {
      content: '»';
      padding: 0 5px;
    }

    font-weight: 500;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`

export const InsightItemIcon = styled.div``

export const InsightItemText = styled.div`
  display: flex;
  flex-direction: column;
`

export const InsightItemHeading = styled.div`
  margin-bottom: 5px;
`

export const InsightItemDescription = styled.div`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 0.8rem;
`

const StatusIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`

export const PassedStatusIcon: IconWithPredefinedName = styled(
  StatusIcon
).attrs({
  name: 'check-circle',
})`
  color: ${({ theme }) => theme.colors.success.main};
`

export const WarningStatusIcon: IconWithPredefinedName = styled(
  StatusIcon
).attrs({
  name: 'exclamation-triangle',
})`
  color: ${({ theme }) => theme.colors.warning.main};
`

export const FailedStatusIcon: IconWithPredefinedName = styled(
  StatusIcon
).attrs({
  name: 'exclamation-circle',
})`
  color: ${({ theme }) => theme.colors.error.main};
`

export const SkippedStatusIcon: IconWithPredefinedName = styled(
  StatusIcon
).attrs({
  name: 'question-circle',
})`
  color: ${({ theme }) => theme.colors.text.primary};
`
