import React from 'react'
import { StyledLink } from './LearnMoreLink.styled'

export function LearnMoreLink({
  href,
  text = 'Learn more',
}: {
  href: string
  text?: string
}) {
  return (
    <StyledLink href={href} target="_blank" rel="noopener">
      {text}&nbsp;&gt;
    </StyledLink>
  )
}
