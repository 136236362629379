import React from 'react'

import { TestRun } from 'types'
import { useTestRunExecutionDuration } from 'data/useTestRunExecutionDuration'
import { MetaItem } from './MetaItem'

interface DurationMetaItemProps {
  testRun: TestRun
  icon?: boolean
}

export const DurationMetaItem = ({
  testRun,
  ...props
}: DurationMetaItemProps) => {
  if (!testRun.execution_duration || testRun.execution_duration <= -1) {
    return <>-</>
  }

  return <Content testRun={testRun} {...props} />
}

const Content = ({ icon = true, testRun, ...props }: DurationMetaItemProps) => {
  const duration = useTestRunExecutionDuration(testRun)

  return (
    <MetaItem icon={icon && 'clock-nine'} {...props}>
      {duration}
    </MetaItem>
  )
}
