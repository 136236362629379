import { Modal } from '@grafana/ui'
import { colorManipulator } from '@grafana/data'

import styled from 'styled-components'
import { spacing } from 'utils/styled'

const { alpha } = colorManipulator

export const TrendingMetricsModal = styled(Modal)`
  width: 645px;

  & div:nth-child(2) {
    padding-top: 0;
  }
`

export const ButtonRow = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;

  .reset-trending-metric-btn {
    position: absolute;
    left: 0;
    top: 0;
    background: ${({ theme }) => alpha(theme.colors.primary.main, 0.075)};

    &:hover {
      background: ${({ theme }) => alpha(theme.colors.primary.main, 0.15)};
    }
  }
`

export const ModalActionsContainer = styled.div`
  button:first-child {
    margin-right: ${spacing(2)};
  }
`
