import { useParams } from 'react-router-dom'
import { routeMap } from 'routeMap'

export function useParamTestRunId() {
  const { runId } = useParams<{ runId?: string }>()
  return runId ? Number(runId) : undefined
}

export function useBrowserTimelinePageNav() {
  const runId = useParamTestRunId()
  const url = runId
    ? routeMap.browserTimelineRun(runId)
    : routeMap.browserTimeline
  return { text: 'Browser Timeline', url }
}
