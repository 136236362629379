import React from 'react'
import {
  CompareFieldRow,
  CompareHeaderContainer,
  CompareLabelLink,
  LeftCompareLabel,
  RightCompareLabel,
  SwapLink,
} from './CompareHeader.styles'
import { Icon } from '@grafana/ui'
import { useHistory } from 'react-router-dom'
import { SelectableValue } from '@grafana/data'

import { Test, TestRun } from 'types'
import { routeMap } from 'routeMap'

import { TimeRangeResetButton } from '../../components/TimeRangeResetButton'
import { useCurrentTab } from '../Breakdown/Breakdown.hooks'
import { TestAndRunSelect } from '../TestAndRunSelect'

interface CompareHeaderProps {
  showTimeRangeReset: boolean
  left: TestRun
  right: TestRun
}

export function CompareHeader({
  showTimeRangeReset,
  left,
  right,
}: CompareHeaderProps) {
  const history = useHistory()
  const tab = useCurrentTab()

  const handleLeftChange = ({
    disabled,
    testRun,
  }: SelectableValue<TestRun['id']>) => {
    if (!disabled) {
      history.push(
        routeMap.testRunCompare({
          left: testRun.id,
          right: right.id,
          tab,
        })
      )
    }
  }

  const handleRightChange = ({
    disabled,
    testRun,
  }: SelectableValue<Test['id']>) => {
    if (!disabled) {
      history.push(
        routeMap.testRunCompare({
          left: left.id,
          right: testRun.id,
          tab,
        })
      )
    }
  }

  return (
    <CompareHeaderContainer>
      <CompareFieldRow>
        <CompareLabelLink to={routeMap.testRun(left.id, tab)}>
          <LeftCompareLabel>Left</LeftCompareLabel>
        </CompareLabelLink>
        <TestAndRunSelect
          projectId={left.project_id}
          testRunSelectId="left-test-run-select"
          testRunSelectAriaLabel="Select left test run to compare"
          currentTestRun={left}
          comparedTestRun={right}
          onTestRunChange={handleLeftChange}
        />
        <SwapLink
          to={routeMap.testRunCompare({ left: right.id, right: left.id, tab })}
          aria-label="Swap places of the compared test runs"
        >
          <Icon name="exchange-alt" size="xl" />
        </SwapLink>
        <CompareLabelLink to={routeMap.testRun(right.id, tab)}>
          <RightCompareLabel>Right</RightCompareLabel>
        </CompareLabelLink>
        <TestAndRunSelect
          projectId={right.project_id}
          testRunSelectId="right-test-run-select"
          testRunSelectAriaLabel="Select right test run to compare"
          currentTestRun={right}
          comparedTestRun={left}
          onTestRunChange={handleRightChange}
        />
      </CompareFieldRow>

      {!showTimeRangeReset && <TimeRangeResetButton />}
    </CompareHeaderContainer>
  )
}
