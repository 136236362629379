import React from 'react'

import { PluginOrganizationWidePage } from 'components/PluginPage'
import { Section } from 'components/Section'
import { DocLink, docs } from 'components/DocLink'
import { routeMap } from 'routeMap'
import { createLearnPageNavModel } from 'navModels'
import { Link } from '@grafana/ui'

export const LearnPage = () => {
  const pageNav = createLearnPageNavModel()

  return (
    <PluginOrganizationWidePage pageNav={pageNav}>
      <Section
        icon="info-circle"
        title="CLI guide"
        description={
          <>Trigger a local or cloud executed tests from the command line.</>
        }
      >
        <Link href={routeMap.cliGuide}>CLI instructions</Link>
      </Section>
      <Section
        icon="book-open"
        title="k6 OSS docs"
        description={
          <>
            Read through our docs to learn more about performance testing with
            k6.
          </>
        }
      >
        <DocLink article={docs.root.k6}>OSS docs</DocLink>
      </Section>
      <Section
        icon="brackets-curly"
        title="k6 JavaScript API"
        description={<>Write test scripts using our easy-to-learn JS APIs.</>}
      >
        <DocLink article={docs.javascript.main}>JS API docs</DocLink>
      </Section>
      <Section
        icon="book-open"
        title="Grafana Cloud k6 docs"
        description={
          <>
            Find out how Grafana Cloud k6 can take your performance testing
            efforts to the next level.
          </>
        }
      >
        <DocLink article={docs.root.grafana}>Cloud docs</DocLink>
      </Section>
      <Section
        icon="library-panel"
        title="Integrations"
        description={
          <>Integrate k6 into your existing development environment.</>
        }
      >
        <DocLink article={docs.integrations.main}>Integrations docs</DocLink>
      </Section>
    </PluginOrganizationWidePage>
  )
}
