/* cspell:disable */
import { BrowserTimelineData } from '../../components/BrowserTimeline/types'

export const new_trace: BrowserTimelineData = {
  batches: [
    {
      resource: {
        attributes: [{ key: 'service.name', value: { stringValue: 'k6' } }],
      },
      scopeSpans: [
        {
          scope: { name: 'k6.browser' },
          spans: [
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '+JtIE8PYpmY=',
              name: 'iteration',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559404006497000',
              endTimeUnixNano: '1705559412236410542',
              attributes: [
                { key: 'test.iteration.number', value: { intValue: '3' } },
                { key: 'test.vu', value: { intValue: '2' } },
                { key: 'test.scenario', value: { stringValue: '1VU_10Iter' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'QitEbOBwGWI=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'navigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559409960637000',
              endTimeUnixNano: '1705559410492362417',
              attributes: [
                {
                  key: 'navigation.url',
                  value: { stringValue: 'https://test.k6.io/my_messages.php' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'JjF/elvuVh0=',
              parentSpanId: 'QitEbOBwGWI=',
              name: 'page.waitForNavigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559410018407000',
              endTimeUnixNano: '1705559410499641375',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'V/yAjXcnp/Y=',
              parentSpanId: 'QitEbOBwGWI=',
              name: 'locator.click',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559410018490000',
              endTimeUnixNano: '1705559410231627917',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'ZJXON2RCDSc=',
              parentSpanId: 'QitEbOBwGWI=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559410231508000',
              endTimeUnixNano: '1705559410231514000',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'LCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 296.40000000596046 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'JITm34V3lkY=',
              parentSpanId: 'QitEbOBwGWI=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559410248760000',
              endTimeUnixNano: '1705559410248779458',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FID' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 0.19999998807907104 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '8ZCGZZAMnDk=',
              parentSpanId: 'QitEbOBwGWI=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559410483126000',
              endTimeUnixNano: '1705559410483132125',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'INP' } },
                { key: 'web_vital.value', value: { doubleValue: 16 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '5DPMN6/LsrI=',
              parentSpanId: 'QitEbOBwGWI=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559410483158000',
              endTimeUnixNano: '1705559410483159417',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'CLS' } },
                { key: 'web_vital.value', value: { doubleValue: 1 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'MAxuxvszpqk=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'navigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559410492372000',
              endTimeUnixNano: '1705559411052534625',
              attributes: [
                {
                  key: 'navigation.url',
                  value: { stringValue: 'https://test.k6.io/my_messages.php' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'pwFedFYDADY=',
              parentSpanId: 'MAxuxvszpqk=',
              name: 'locator.type',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559410499772000',
              endTimeUnixNano: '1705559410571935625',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'SaJKOZu6Drs=',
              parentSpanId: 'MAxuxvszpqk=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559410499832000',
              endTimeUnixNano: '1705559410499834000',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'TTFB' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 244.69999998807907 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'eimNJoOKADk=',
              parentSpanId: 'MAxuxvszpqk=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559410512401000',
              endTimeUnixNano: '1705559410512405542',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 275.19999998807907 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'KT72An7m9OY=',
              parentSpanId: 'MAxuxvszpqk=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559410560844000',
              endTimeUnixNano: '1705559410560849584',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'LCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 275.19999998807907 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'QJwP0HgF844=',
              parentSpanId: 'MAxuxvszpqk=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559410567437000',
              endTimeUnixNano: '1705559410567439667',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FID' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 0.6000000238418579 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'BhNEz8l3ra8=',
              parentSpanId: 'MAxuxvszpqk=',
              name: 'locator.type',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559410571967000',
              endTimeUnixNano: '1705559410575402667',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'dEL432k2tfs=',
              parentSpanId: 'MAxuxvszpqk=',
              name: 'page.waitForNavigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559410575461000',
              endTimeUnixNano: '1705559411054746333',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'xRCY2TkriVw=',
              parentSpanId: 'MAxuxvszpqk=',
              name: 'locator.click',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559410575475000',
              endTimeUnixNano: '1705559410788516958',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'cn/7XcFB2mQ=',
              parentSpanId: 'MAxuxvszpqk=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559411034106000',
              endTimeUnixNano: '1705559411034127459',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'INP' } },
                { key: 'web_vital.value', value: { doubleValue: 8 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'sOuwRJ2yR7w=',
              parentSpanId: 'MAxuxvszpqk=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559411034172000',
              endTimeUnixNano: '1705559411034173708',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'CLS' } },
                { key: 'web_vital.value', value: { doubleValue: 0 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'KSYa4DulVyA=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'navigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559411052558000',
              endTimeUnixNano: '1705559411626350542',
              attributes: [
                {
                  key: 'navigation.url',
                  value: { stringValue: 'https://test.k6.io/my_messages.php' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'I1eHHRxycic=',
              parentSpanId: 'KSYa4DulVyA=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559411055626000',
              endTimeUnixNano: '1705559411055628833',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'TTFB' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 236.40000000596046 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '7CjDD8cVSnc=',
              parentSpanId: 'KSYa4DulVyA=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559411062614000',
              endTimeUnixNano: '1705559411062618166',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 273.30000001192093 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'juBq4BdCWQo=',
              parentSpanId: 'KSYa4DulVyA=',
              name: 'page.waitForNavigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559411119727000',
              endTimeUnixNano: '1705559411628590291',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'Re+6/T+9CcI=',
              parentSpanId: 'KSYa4DulVyA=',
              name: 'locator.click',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559411119839000',
              endTimeUnixNano: '1705559411626505292',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'LZ52tbPpMhY=',
              parentSpanId: 'KSYa4DulVyA=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559411356384000',
              endTimeUnixNano: '1705559411356391250',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'LCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 273.30000001192093 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'Ax/WCn1Khpk=',
              parentSpanId: 'KSYa4DulVyA=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559411362218000',
              endTimeUnixNano: '1705559411362223917',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FID' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 0.7999999821186066 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'MRFsb+HAhp0=',
              parentSpanId: 'KSYa4DulVyA=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559411618581000',
              endTimeUnixNano: '1705559411618590209',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'INP' } },
                { key: 'web_vital.value', value: { doubleValue: 8 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '490Ioy0JExk=',
              parentSpanId: 'KSYa4DulVyA=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559411618695000',
              endTimeUnixNano: '1705559411618720291',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'CLS' } },
                { key: 'web_vital.value', value: { doubleValue: 0 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'C/EZKZPtA6k=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'navigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559411626366000',
              endTimeUnixNano: '1705559412040285292',
              attributes: [
                {
                  key: 'navigation.url',
                  value: { stringValue: 'https://test.k6.io/my_messages.php' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'TOY3TsA4yh8=',
              parentSpanId: 'C/EZKZPtA6k=',
              name: 'page.waitForNavigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559411628843000',
              endTimeUnixNano: '1705559412046999959',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '3g3aOfhuCGw=',
              parentSpanId: 'C/EZKZPtA6k=',
              name: 'locator.click',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559411628930000',
              endTimeUnixNano: '1705559412040329208',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'TJSo0J7y/zE=',
              parentSpanId: 'C/EZKZPtA6k=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559411631459000',
              endTimeUnixNano: '1705559411631462292',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'TTFB' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 245.19999998807907 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'bli8c2TQttY=',
              parentSpanId: 'C/EZKZPtA6k=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559411639247000',
              endTimeUnixNano: '1705559411639253125',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 282.19999998807907 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'QCarrWO+dBU=',
              parentSpanId: 'C/EZKZPtA6k=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559411889833000',
              endTimeUnixNano: '1705559411889838875',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'LCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 282.19999998807907 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '4qdkfyYQ2bg=',
              parentSpanId: 'C/EZKZPtA6k=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559411907610000',
              endTimeUnixNano: '1705559411907617500',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FID' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 0.09999999403953552 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '42AQLKaLIUk=',
              parentSpanId: 'C/EZKZPtA6k=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559412027449000',
              endTimeUnixNano: '1705559412027463083',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'INP' } },
                { key: 'web_vital.value', value: { doubleValue: 8 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'qYjqd/tQjAA=',
              parentSpanId: 'C/EZKZPtA6k=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559412027518000',
              endTimeUnixNano: '1705559412027520000',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'CLS' } },
                { key: 'web_vital.value', value: { doubleValue: 0 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'hkIlPcRElQQ=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'navigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559412040299000',
              endTimeUnixNano: '1705559412220264792',
              attributes: [
                {
                  key: 'navigation.url',
                  value: { stringValue: 'https://test.k6.io/' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'Xt8YmSn4WhU=',
              parentSpanId: 'hkIlPcRElQQ=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559412052362000',
              endTimeUnixNano: '1705559412052366167',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'TTFB' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 125.40000000596046 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'O3NYcDFd9dY=',
              parentSpanId: 'hkIlPcRElQQ=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559412065457000',
              endTimeUnixNano: '1705559412065460250',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 175.09999999403954 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'ChwJ8GpZkxw=',
              parentSpanId: 'hkIlPcRElQQ=',
              name: 'page.screenshot',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559412102168000',
              endTimeUnixNano: '1705559412167510583',
              attributes: [
                {
                  key: 'screenshot.path',
                  value: { stringValue: 'end_page_title.png' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'MHFADVaywkY=',
              parentSpanId: 'hkIlPcRElQQ=',
              name: 'page.close',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559412167543000',
              endTimeUnixNano: '1705559412220321583',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'LvmDVODBFsc=',
              parentSpanId: 'hkIlPcRElQQ=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559412219416000',
              endTimeUnixNano: '1705559412219420208',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'LCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 175.09999999403954 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'V0BF9C9VvJI=',
              parentSpanId: 'hkIlPcRElQQ=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559412219429000',
              endTimeUnixNano: '1705559412219429417',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'CLS' } },
                { key: 'web_vital.value', value: { doubleValue: 0 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
          ],
        },
      ],
    },
    {
      resource: {
        attributes: [{ key: 'service.name', value: { stringValue: 'k6' } }],
      },
      scopeSpans: [
        {
          scope: { name: 'k6.browser' },
          spans: [
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '1sJJqzahxP0=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'browser.newPage',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559404148994000',
              endTimeUnixNano: '1705559404197229083',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'eA2ktKjQWgA=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'browser.newContext',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559404149004000',
              endTimeUnixNano: '1705559404149563500',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'lKgYE5uzfX4=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'browserContext.newPage',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559404149571000',
              endTimeUnixNano: '1705559404197222833',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'OqQXNawuLpg=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'navigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559404192210000',
              endTimeUnixNano: '1705559404639663000',
              attributes: [
                {
                  key: 'navigation.url',
                  value: { stringValue: 'about:blank' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'Td7CkpSnlV0=',
              parentSpanId: 'OqQXNawuLpg=',
              name: 'page.goto',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559404197311000',
              endTimeUnixNano: '1705559404894849458',
              attributes: [
                {
                  key: 'page.goto.url',
                  value: { stringValue: 'https://test.k6.io/' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'k0jsX8FtsKg=',
              parentSpanId: 'MRhtfpMz30A=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559404837828000',
              endTimeUnixNano: '1705559404837837542',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FCP' } },
                { key: 'web_vital.value', value: { doubleValue: 639.5 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'kEJGosSx8MY=',
              parentSpanId: 'MRhtfpMz30A=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559404899104000',
              endTimeUnixNano: '1705559404899107000',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'TTFB' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 417.90000000596046 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
          ],
        },
      ],
    },
    {
      resource: {
        attributes: [{ key: 'service.name', value: { stringValue: 'k6' } }],
      },
      scopeSpans: [
        {
          scope: { name: 'k6.browser' },
          spans: [
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'MRhtfpMz30A=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'navigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559404639704000',
              endTimeUnixNano: '1705559405302636750',
              attributes: [
                {
                  key: 'navigation.url',
                  value: { stringValue: 'https://test.k6.io/' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '9rUZQtvgcYM=',
              parentSpanId: 'MRhtfpMz30A=',
              name: 'page.waitForNavigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559404894958000',
              endTimeUnixNano: '1705559405303852417',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '+D1QORf4BCo=',
              parentSpanId: 'MRhtfpMz30A=',
              name: 'locator.click',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559404894982000',
              endTimeUnixNano: '1705559405302698917',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'A0Q0Y62VZEs=',
              parentSpanId: 'MRhtfpMz30A=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559405179037000',
              endTimeUnixNano: '1705559405179040125',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'LCP' } },
                { key: 'web_vital.value', value: { doubleValue: 639.5 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'PpzEs+u7EOQ=',
              parentSpanId: 'MRhtfpMz30A=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559405179969000',
              endTimeUnixNano: '1705559405179974542',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FID' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 3.199999988079071 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'e2l1zx7IA8I=',
              parentSpanId: 'MRhtfpMz30A=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559405299885000',
              endTimeUnixNano: '1705559405299888416',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'INP' } },
                { key: 'web_vital.value', value: { doubleValue: 8 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'HbEnvDKwO40=',
              parentSpanId: 'MRhtfpMz30A=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559405299899000',
              endTimeUnixNano: '1705559405299899459',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'CLS' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 0.5 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'poor' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'XTnO3VtArIw=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'navigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559405302638000',
              endTimeUnixNano: '1705559405760099500',
              attributes: [
                {
                  key: 'navigation.url',
                  value: { stringValue: 'https://test.k6.io/contacts.php' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '0UXnF506KDk=',
              parentSpanId: 'XTnO3VtArIw=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559405308457000',
              endTimeUnixNano: '1705559405308459792',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'TTFB' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 1337.2000000178814 },
                },
                {
                  key: 'web_vital.rating',
                  value: { stringValue: 'needs_improvement' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'XjoqI8bVlrg=',
              parentSpanId: 'XTnO3VtArIw=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559405321531000',
              endTimeUnixNano: '1705559405321536375',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 141.40000000596046 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'ucOeCOtQuNI=',
              parentSpanId: 'XTnO3VtArIw=',
              name: 'locator.click',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559405366652000',
              endTimeUnixNano: '1705559405760153250',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'tq6M5V7tdic=',
              parentSpanId: 'XTnO3VtArIw=',
              name: 'page.waitForNavigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559405367021000',
              endTimeUnixNano: '1705559405770219959',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'BsctoNscVY0=',
              parentSpanId: 'XTnO3VtArIw=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559405602500000',
              endTimeUnixNano: '1705559405602522917',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'LCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 141.40000000596046 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'mJZ2oSbT8vo=',
              parentSpanId: 'XTnO3VtArIw=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559405614473000',
              endTimeUnixNano: '1705559405614480708',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FID' } },
                { key: 'web_vital.value', value: { doubleValue: 1.5 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'y3eSQbhNvRA=',
              parentSpanId: 'XTnO3VtArIw=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559405740131000',
              endTimeUnixNano: '1705559405740147416',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'INP' } },
                { key: 'web_vital.value', value: { doubleValue: 8 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'wqDyobKWpO0=',
              parentSpanId: 'XTnO3VtArIw=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559405740195000',
              endTimeUnixNano: '1705559405740197042',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'CLS' } },
                { key: 'web_vital.value', value: { doubleValue: 0 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '8vTw6kynULQ=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'navigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559405760114000',
              endTimeUnixNano: '1705559406178304459',
              attributes: [
                {
                  key: 'navigation.url',
                  value: { stringValue: 'https://test.k6.io/' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'ezpjKvWCIsw=',
              parentSpanId: '8vTw6kynULQ=',
              name: 'page.waitForNavigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559405770458000',
              endTimeUnixNano: '1705559406179274458',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'Tom1C8DULso=',
              parentSpanId: '8vTw6kynULQ=',
              name: 'locator.click',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559405770512000',
              endTimeUnixNano: '1705559406178422667',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'KH5QgyNCyb8=',
              parentSpanId: '8vTw6kynULQ=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559405779175000',
              endTimeUnixNano: '1705559405779180541',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'TTFB' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 126.60000002384186 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '26skEApgeZo=',
              parentSpanId: '8vTw6kynULQ=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559405797143000',
              endTimeUnixNano: '1705559405797150042',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FCP' } },
                { key: 'web_vital.value', value: { doubleValue: 196 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'plsnyRzvKHg=',
              parentSpanId: '8vTw6kynULQ=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406028608000',
              endTimeUnixNano: '1705559406028618000',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'LCP' } },
                { key: 'web_vital.value', value: { doubleValue: 196 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'q10czo/b8LA=',
              parentSpanId: '8vTw6kynULQ=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406040897000',
              endTimeUnixNano: '1705559406040902250',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FID' } },
                { key: 'web_vital.value', value: { doubleValue: 0.5 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'zdLHtUY8ha4=',
              parentSpanId: '8vTw6kynULQ=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406167380000',
              endTimeUnixNano: '1705559406167392833',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'INP' } },
                { key: 'web_vital.value', value: { doubleValue: 16 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'ytZcV12GaQo=',
              parentSpanId: '8vTw6kynULQ=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406167436000',
              endTimeUnixNano: '1705559406167437708',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'CLS' } },
                { key: 'web_vital.value', value: { doubleValue: 0 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'Dk5EKvjg87M=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'navigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406178320000',
              endTimeUnixNano: '1705559406600340083',
              attributes: [
                {
                  key: 'navigation.url',
                  value: { stringValue: 'https://test.k6.io/news.php' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'sVpki6oi9XM=',
              parentSpanId: 'Dk5EKvjg87M=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406181897000',
              endTimeUnixNano: '1705559406181899833',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'TTFB' } },
                { key: 'web_vital.value', value: { doubleValue: 122.5 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'qEjxXmWHGtY=',
              parentSpanId: 'Dk5EKvjg87M=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406209422000',
              endTimeUnixNano: '1705559406209424833',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 183.19999998807907 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'tqOz/2G7JBA=',
              parentSpanId: 'Dk5EKvjg87M=',
              name: 'page.waitForNavigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406233774000',
              endTimeUnixNano: '1705559406610819875',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'Z9jRLGaLeUc=',
              parentSpanId: 'Dk5EKvjg87M=',
              name: 'locator.click',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406233804000',
              endTimeUnixNano: '1705559406600466125',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'smgMX3U7q+M=',
              parentSpanId: 'Dk5EKvjg87M=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406449094000',
              endTimeUnixNano: '1705559406449107792',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'LCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 183.19999998807907 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'xxYXMs9jF9k=',
              parentSpanId: 'Dk5EKvjg87M=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406468579000',
              endTimeUnixNano: '1705559406468590084',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FID' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 0.19999998807907104 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'oW+y4mvPLdE=',
              parentSpanId: 'Dk5EKvjg87M=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406586019000',
              endTimeUnixNano: '1705559406586037875',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'INP' } },
                { key: 'web_vital.value', value: { doubleValue: 16 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'L6oiqaz+YsI=',
              parentSpanId: 'Dk5EKvjg87M=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406586093000',
              endTimeUnixNano: '1705559406586097000',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'CLS' } },
                { key: 'web_vital.value', value: { doubleValue: 0 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'Cu9jpjq//ZE=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'navigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406600357000',
              endTimeUnixNano: '1705559407027889084',
              attributes: [
                {
                  key: 'navigation.url',
                  value: { stringValue: 'https://test.k6.io/' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'C9SyEWBWIiY=',
              parentSpanId: 'Cu9jpjq//ZE=',
              name: 'page.waitForNavigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406611003000',
              endTimeUnixNano: '1705559407028444333',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'LOeEjJ9puz0=',
              parentSpanId: 'Cu9jpjq//ZE=',
              name: 'locator.click',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406611032000',
              endTimeUnixNano: '1705559406869682875',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'SJ32ucj7iZo=',
              parentSpanId: 'Cu9jpjq//ZE=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406619021000',
              endTimeUnixNano: '1705559406619025333',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'TTFB' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 128.30000001192093 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'iyW6iO686c4=',
              parentSpanId: 'Cu9jpjq//ZE=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406635459000',
              endTimeUnixNano: '1705559406635476959',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 186.7000000178814 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'E0W9LXQ0F68=',
              parentSpanId: 'Cu9jpjq//ZE=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406869669000',
              endTimeUnixNano: '1705559406869676209',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'LCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 186.7000000178814 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'r1TNiHLWItc=',
              parentSpanId: 'Cu9jpjq//ZE=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559406881830000',
              endTimeUnixNano: '1705559406881837208',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FID' } },
                { key: 'web_vital.value', value: { doubleValue: 2.5 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'udkPsp/JGUI=',
              parentSpanId: 'Cu9jpjq//ZE=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407008956000',
              endTimeUnixNano: '1705559407008967542',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'INP' } },
                { key: 'web_vital.value', value: { doubleValue: 24 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '7sXjVMcPCbA=',
              parentSpanId: 'Cu9jpjq//ZE=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407009041000',
              endTimeUnixNano: '1705559407009074084',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'CLS' } },
                { key: 'web_vital.value', value: { doubleValue: 0 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'Ji4M7//nwxs=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'navigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407027902000',
              endTimeUnixNano: '1705559407288808250',
              attributes: [
                {
                  key: 'navigation.url',
                  value: {
                    stringValue: 'https://test.k6.io/pi.php?decimals=3',
                  },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'iaTycv5B4+w=',
              parentSpanId: 'Ji4M7//nwxs=',
              name: 'page.screenshot',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407028547000',
              endTimeUnixNano: '1705559407098154125',
              attributes: [
                { key: 'screenshot.path', value: { stringValue: 'pi.png' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'JUF1BJRrM7o=',
              parentSpanId: 'Ji4M7//nwxs=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407055022000',
              endTimeUnixNano: '1705559407055030750',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 181.30000001192093 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'Zh4DNcnbqkI=',
              parentSpanId: 'Ji4M7//nwxs=',
              name: 'page.waitForNavigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407151728000',
              endTimeUnixNano: '1705559407298574875',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'FgriFpjFpAM=',
              parentSpanId: 'Ji4M7//nwxs=',
              name: 'page.goto',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407151739000',
              endTimeUnixNano: '1705559407298576375',
              attributes: [
                {
                  key: 'page.goto.url',
                  value: { stringValue: 'https://test.k6.io/' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'vMy5vvvsIN8=',
              parentSpanId: 'Ji4M7//nwxs=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407283041000',
              endTimeUnixNano: '1705559407283048250',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'LCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 181.30000001192093 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'pB4W3mOE6w0=',
              parentSpanId: 'Ji4M7//nwxs=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407283072000',
              endTimeUnixNano: '1705559407283073083',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'CLS' } },
                { key: 'web_vital.value', value: { doubleValue: 0 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '12HVzRqmehE=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'navigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407288815000',
              endTimeUnixNano: '1705559407689056417',
              attributes: [
                {
                  key: 'navigation.url',
                  value: { stringValue: 'https://test.k6.io/' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'SxtiZfVHYi8=',
              parentSpanId: '12HVzRqmehE=',
              name: 'page.waitForNavigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407298768000',
              endTimeUnixNano: '1705559407689865834',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '+uEtXe2LJTU=',
              parentSpanId: '12HVzRqmehE=',
              name: 'locator.click',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407298799000',
              endTimeUnixNano: '1705559407689114083',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'hUJq7nQZFew=',
              parentSpanId: '12HVzRqmehE=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407305128000',
              endTimeUnixNano: '1705559407305132541',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'TTFB' } },
                { key: 'web_vital.value', value: { doubleValue: 127 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'oBsIZ2FcnzU=',
              parentSpanId: '12HVzRqmehE=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407321583000',
              endTimeUnixNano: '1705559407321608375',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 168.59999999403954 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '4niPG4BdG5Y=',
              parentSpanId: '12HVzRqmehE=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407556212000',
              endTimeUnixNano: '1705559407556217667',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'LCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 168.59999999403954 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'YPSaN1N/oBI=',
              parentSpanId: '12HVzRqmehE=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407566870000',
              endTimeUnixNano: '1705559407566875084',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FID' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 2.300000011920929 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'cwcwlWbepas=',
              parentSpanId: '12HVzRqmehE=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407685073000',
              endTimeUnixNano: '1705559407685080000',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'INP' } },
                { key: 'web_vital.value', value: { doubleValue: 0 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'ohIE5avse5g=',
              parentSpanId: '12HVzRqmehE=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407685108000',
              endTimeUnixNano: '1705559407685108500',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'CLS' } },
                { key: 'web_vital.value', value: { doubleValue: 0 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '6cLpEk7PKkM=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'navigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407689060000',
              endTimeUnixNano: '1705559408111533208',
              attributes: [
                {
                  key: 'navigation.url',
                  value: { stringValue: 'https://test.k6.io/flip_coin.php' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'gC4jaV8VMEw=',
              parentSpanId: '6cLpEk7PKkM=',
              name: 'page.waitForNavigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407689997000',
              endTimeUnixNano: '1705559408113250959',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'nEb4lWClIbU=',
              parentSpanId: '6cLpEk7PKkM=',
              name: 'locator.click',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407690014000',
              endTimeUnixNano: '1705559407968732916',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'g45dvSTXxBA=',
              parentSpanId: '6cLpEk7PKkM=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407693843000',
              endTimeUnixNano: '1705559407693844750',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'TTFB' } },
                { key: 'web_vital.value', value: { doubleValue: 120 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '6Xm8ORlgSI0=',
              parentSpanId: '6cLpEk7PKkM=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407714080000',
              endTimeUnixNano: '1705559407714083458',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 149.40000000596046 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'i7oNsecDtMg=',
              parentSpanId: '6cLpEk7PKkM=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407968513000',
              endTimeUnixNano: '1705559407968519375',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'LCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 149.40000000596046 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'EWNECqquBP4=',
              parentSpanId: '6cLpEk7PKkM=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559407991499000',
              endTimeUnixNano: '1705559407991510875',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FID' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 0.29999998211860657 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'E7qWEt4iZtU=',
              parentSpanId: '6cLpEk7PKkM=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408102540000',
              endTimeUnixNano: '1705559408102551792',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'INP' } },
                { key: 'web_vital.value', value: { doubleValue: 0 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'hgALrZRQCDI=',
              parentSpanId: '6cLpEk7PKkM=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408102592000',
              endTimeUnixNano: '1705559408102593541',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'CLS' } },
                { key: 'web_vital.value', value: { doubleValue: 0 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'MJYdEAEn/W8=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'navigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408111545000',
              endTimeUnixNano: '1705559408515519792',
              attributes: [
                {
                  key: 'navigation.url',
                  value: {
                    stringValue: 'https://test.k6.io/flip_coin.php?bet=heads',
                  },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'h2dNAQYvFHU=',
              parentSpanId: 'MJYdEAEn/W8=',
              name: 'page.waitForNavigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408113512000',
              endTimeUnixNano: '1705559408516628750',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'QtTuw8nDgRo=',
              parentSpanId: 'MJYdEAEn/W8=',
              name: 'locator.click',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408113574000',
              endTimeUnixNano: '1705559408379231125',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '2ImEBcNCCbs=',
              parentSpanId: 'MJYdEAEn/W8=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408114257000',
              endTimeUnixNano: '1705559408114259833',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'TTFB' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 120.09999999403954 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'FaGJ16eFEgA=',
              parentSpanId: 'MJYdEAEn/W8=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408123742000',
              endTimeUnixNano: '1705559408123746167',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 154.19999998807907 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'AfXuP4OjQdA=',
              parentSpanId: 'MJYdEAEn/W8=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408379071000',
              endTimeUnixNano: '1705559408379073500',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'LCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 154.19999998807907 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'kls0PL6UjGE=',
              parentSpanId: 'MJYdEAEn/W8=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408405336000',
              endTimeUnixNano: '1705559408405347125',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FID' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 0.19999998807907104 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'hh1fPvGPuLA=',
              parentSpanId: 'MJYdEAEn/W8=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408512055000',
              endTimeUnixNano: '1705559408512061917',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'INP' } },
                { key: 'web_vital.value', value: { doubleValue: 8 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'MBXr+LHV0KQ=',
              parentSpanId: 'MJYdEAEn/W8=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408512123000',
              endTimeUnixNano: '1705559408512126708',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'CLS' } },
                { key: 'web_vital.value', value: { doubleValue: 0 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'EPhfvCsIe9g=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'navigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408515530000',
              endTimeUnixNano: '1705559408972484209',
              attributes: [
                {
                  key: 'navigation.url',
                  value: {
                    stringValue: 'https://test.k6.io/flip_coin.php?bet=tails',
                  },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'I626t1yrI5M=',
              parentSpanId: 'EPhfvCsIe9g=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408517430000',
              endTimeUnixNano: '1705559408517433334',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'TTFB' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 121.19999998807907 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'CREpAhePqIk=',
              parentSpanId: 'EPhfvCsIe9g=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408529444000',
              endTimeUnixNano: '1705559408529451625',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FCP' } },
                { key: 'web_vital.value', value: { doubleValue: 149 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'RswCRItPKDk=',
              parentSpanId: 'EPhfvCsIe9g=',
              name: 'page.screenshot',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408585232000',
              endTimeUnixNano: '1705559408640031583',
              attributes: [
                {
                  key: 'screenshot.path',
                  value: { stringValue: 'flip_coin.png' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'xegQeqsqV7w=',
              parentSpanId: 'EPhfvCsIe9g=',
              name: 'page.waitForNavigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408640125000',
              endTimeUnixNano: '1705559408981261750',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '3uiAJCqdOAc=',
              parentSpanId: 'EPhfvCsIe9g=',
              name: 'locator.click',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408640146000',
              endTimeUnixNano: '1705559408972561917',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'ZehmoNeIDaQ=',
              parentSpanId: 'EPhfvCsIe9g=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408833518000',
              endTimeUnixNano: '1705559408833525583',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'LCP' } },
                { key: 'web_vital.value', value: { doubleValue: 149 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '1LN4Gtr7fF4=',
              parentSpanId: 'EPhfvCsIe9g=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408838024000',
              endTimeUnixNano: '1705559408838029792',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FID' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 0.20000001788139343 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'q1RRKks2UkE=',
              parentSpanId: 'EPhfvCsIe9g=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408967498000',
              endTimeUnixNano: '1705559408967503375',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'INP' } },
                { key: 'web_vital.value', value: { doubleValue: 8 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'hswm32n9D4U=',
              parentSpanId: 'EPhfvCsIe9g=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408967522000',
              endTimeUnixNano: '1705559408967523041',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'CLS' } },
                { key: 'web_vital.value', value: { doubleValue: 0 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'e15K+KZcEjw=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'navigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408972490000',
              endTimeUnixNano: '1705559409383580791',
              attributes: [
                {
                  key: 'navigation.url',
                  value: { stringValue: 'https://test.k6.io/' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'uR8l6pipIpk=',
              parentSpanId: 'e15K+KZcEjw=',
              name: 'page.waitForNavigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408981450000',
              endTimeUnixNano: '1705559409384759166',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'ZP73/8GtRcw=',
              parentSpanId: 'e15K+KZcEjw=',
              name: 'locator.click',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408981488000',
              endTimeUnixNano: '1705559409246454625',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'irjn1BoGlww=',
              parentSpanId: 'e15K+KZcEjw=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559408988402000',
              endTimeUnixNano: '1705559408988407667',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'TTFB' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 125.59999999403954 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'gVn9OZ55f5U=',
              parentSpanId: 'e15K+KZcEjw=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559409002380000',
              endTimeUnixNano: '1705559409002383000',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 168.69999998807907 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'VeBQzKxCWqM=',
              parentSpanId: 'e15K+KZcEjw=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559409246557000',
              endTimeUnixNano: '1705559409246609500',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'LCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 168.69999998807907 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'g/OS/ntRs5A=',
              parentSpanId: 'e15K+KZcEjw=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559409252258000',
              endTimeUnixNano: '1705559409252261875',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FID' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 4.0999999940395355 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'HBnv3ajCqys=',
              parentSpanId: 'e15K+KZcEjw=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559409380152000',
              endTimeUnixNano: '1705559409380157167',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'INP' } },
                { key: 'web_vital.value', value: { doubleValue: 8 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'JKVq6MKLOLA=',
              parentSpanId: 'e15K+KZcEjw=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559409380186000',
              endTimeUnixNano: '1705559409380186833',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'CLS' } },
                { key: 'web_vital.value', value: { doubleValue: 0 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'dY+VguRWKWY=',
              parentSpanId: '+JtIE8PYpmY=',
              name: 'navigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559409383589000',
              endTimeUnixNano: '1705559409960591208',
              attributes: [
                {
                  key: 'navigation.url',
                  value: { stringValue: 'https://test.k6.io/my_messages.php' },
                },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '0od0oPVKUJU=',
              parentSpanId: 'dY+VguRWKWY=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559409385945000',
              endTimeUnixNano: '1705559409385946917',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'TTFB' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 129.59999999403954 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'MfEvZR4M9Ug=',
              parentSpanId: 'dY+VguRWKWY=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559409407183000',
              endTimeUnixNano: '1705559409407189875',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 169.80000001192093 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'FNnI7kdQvqY=',
              parentSpanId: 'dY+VguRWKWY=',
              name: 'locator.type',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559409442046000',
              endTimeUnixNano: '1705559409452720208',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: '14RNNzZh+ro=',
              parentSpanId: 'dY+VguRWKWY=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559409446080000',
              endTimeUnixNano: '1705559409446094000',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'LCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 169.80000001192093 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'Nit1OB8oGkA=',
              parentSpanId: 'dY+VguRWKWY=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559409447746000',
              endTimeUnixNano: '1705559409447751458',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FID' } },
                { key: 'web_vital.value', value: { doubleValue: 0.5 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'qNbPuXcH+EU=',
              parentSpanId: 'dY+VguRWKWY=',
              name: 'locator.type',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559409452787000',
              endTimeUnixNano: '1705559409459943625',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'U0h0o+Wwjy8=',
              parentSpanId: 'dY+VguRWKWY=',
              name: 'page.waitForNavigation',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559409460053000',
              endTimeUnixNano: '1705559409961689958',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'pyHqfvW2F0M=',
              parentSpanId: 'dY+VguRWKWY=',
              name: 'locator.click',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559409460071000',
              endTimeUnixNano: '1705559409679052292',
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'HPC/piDyPs0=',
              parentSpanId: 'dY+VguRWKWY=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559409941800000',
              endTimeUnixNano: '1705559409941940375',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'INP' } },
                { key: 'web_vital.value', value: { doubleValue: 0 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'ICp7L+4pdRY=',
              parentSpanId: 'dY+VguRWKWY=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559409942069000',
              endTimeUnixNano: '1705559409942070875',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'CLS' } },
                { key: 'web_vital.value', value: { doubleValue: 0 } },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'BMr3kZi6UNY=',
              parentSpanId: 'QitEbOBwGWI=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559409963140000',
              endTimeUnixNano: '1705559409963145125',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'TTFB' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 245.90000000596046 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
            {
              traceId: 'aW7cTVqtEtMwIHvrRbr7UQ==',
              spanId: 'tKSUaUkyru8=',
              parentSpanId: 'QitEbOBwGWI=',
              name: 'web_vital',
              kind: 'SPAN_KIND_INTERNAL',
              startTimeUnixNano: '1705559409976962000',
              endTimeUnixNano: '1705559409976969333',
              attributes: [
                { key: 'web_vital.name', value: { stringValue: 'FCP' } },
                {
                  key: 'web_vital.value',
                  value: { doubleValue: 296.40000000596046 },
                },
                { key: 'web_vital.rating', value: { stringValue: 'good' } },
              ],
              status: {},
            },
          ],
        },
      ],
    },
  ],
}
