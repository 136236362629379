import dedent from 'dedent'
import { Test, TestRun, TestRunResultStatus, TestRunStatus } from 'types'
import { exhaustive } from 'utils/typescript'
import {
  duration,
  quantity,
  requestRate,
  timeDifference,
} from 'utils/formatters'
import { format, parseISO } from 'date-fns'
import { GeneratorContext, SummaryRow } from '../types'
import { createTextPanel } from '../panels'

export interface TestRunPassed {
  type: 'passed'
}

export interface TestRunFailed {
  type: 'failed'
}

export type AbortionReason =
  | 'user'
  | 'system'
  | 'script-error'
  | 'threshold'
  | 'limit'

export interface TestRunAborted {
  type: 'aborted'
  reason: AbortionReason
}

export type TestRunResult = TestRunPassed | TestRunFailed | TestRunAborted

export function toTestRunResult(testRun: TestRun): TestRunResult {
  switch (testRun.run_status) {
    case TestRunStatus.ABORTED_USER:
      return { type: 'aborted', reason: 'user' }

    case TestRunStatus.ABORTED_SYSTEM:
      return { type: 'aborted', reason: 'system' }

    case TestRunStatus.ABORTED_SCRIPT_ERROR:
      return { type: 'aborted', reason: 'script-error' }

    case TestRunStatus.ABORTED_THRESHOLD:
      return { type: 'aborted', reason: 'threshold' }

    case TestRunStatus.ABORTED_LIMIT:
      return { type: 'aborted', reason: 'limit' }

    default:
      return {
        type:
          testRun.result_status === TestRunResultStatus.PASSED
            ? 'passed'
            : 'failed',
      }
  }
}

function toAbortedReason(result: TestRunAborted): string {
  switch (result.reason) {
    case 'user':
      return 'because the user requested it'

    case 'system':
      return 'due to a system error'

    case 'script-error':
      return 'because an error occurred with the test script'

    case 'threshold':
      return 'due to a critical threshold being exceeded'

    case 'limit':
      return 'because a limit of what was allowed was reached'

    default:
      return exhaustive(result.reason)
  }
}

function generateText(test: Test, testRun: TestRun) {
  const vus = quantity(testRun.vus)

  const created = parseISO(testRun.created)
  const started = testRun.started ? parseISO(testRun.started) : created
  const ended = testRun.ended ? parseISO(testRun.ended) : started

  const targetDuration = duration(testRun.duration)
  const actualDuration = timeDifference(started, ended, { precision: 1 })

  const result = toTestRunResult(testRun)

  const statusText = result.type === 'passed' ? 'be successful' : 'have failed'

  const abortedText =
    result.type === 'aborted'
      ? `, but ended after about ${actualDuration} ${toAbortedReason(result)}`
      : ''

  const totalRequests = quantity(
    testRun.http_metric_summary?.requests_count ?? 0
  )
  const maxRequestRate = requestRate(testRun.http_metric_summary?.rps_max ?? 0)

  // prettier-ignore
  return dedent`
    # Summary
   
    This report summarizes a test run of the test "${test.name}". It was performed on
    ${format(created, 'PPP')} and is considered to ${statusText}.
    
    The test was configured to run up to **${vus} VUs** for ${targetDuration}${abortedText}.
    A total of **${totalRequests} requests** were made with a max throughput of 
    **${maxRequestRate}**. The sections below give a more detailed breakdown.
  `
}

export function createSummaryPanel({ options }: GeneratorContext): SummaryRow {
  const content = generateText(options.test, options.testRun)

  return {
    height: 4,
    panels: [createTextPanel(content)],
  }
}
