import React from 'react'
import { HorizontalGroup, VerticalGroup } from '@grafana/ui'

import { createNewTestPageNavModel } from 'navModels'
import { routeMap } from 'routeMap'
import { LinkButton } from 'components/LinkButton'
import { useCurrentProject } from 'projectContext'
import { TestBuilderIllustration } from 'components/TestBuilderIllustration'
import { ScriptEditorIllustration } from 'components/ScriptEditorIllustration'
import { PluginPage } from 'components/PluginPage'
import { NewTestCLIGuide } from 'components/NewTestCLIGuide'
import { Body, Card } from './NewTest.styles'
import { SpinnerWrapper } from '../ProjectPage/ProjectPage.styles'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { useUserTestRunStats } from 'data/useUserTestRunStats'
import { BrowserDiscoverability } from './BrowserDiscoverability'

export const NewTest = () => {
  const project = useCurrentProject()
  const { data: stats, isFetching } = useUserTestRunStats()

  const pageNav = createNewTestPageNavModel(project)

  return (
    <PluginPage
      pageNav={pageNav}
      subTitle="The best developer experience for performance testing"
    >
      {isFetching ? (
        <SpinnerWrapper>
          <CenteredSpinner $height="auto" />
        </SpinnerWrapper>
      ) : (
        <Body>
          <NewTestCLIGuide project={project} stats={stats} />
          <HorizontalGroup justify="center" wrap={true}>
            <VerticalGroup align="center" spacing="none" height="auto">
              <p>
                Not ready to script locally or utilize the CLI? Use our Test
                Builder or Script Editor to get started.
              </p>
            </VerticalGroup>
            <HorizontalGroup>
              <Card>
                <HorizontalGroup>
                  <VerticalGroup>
                    <h3>Test Builder</h3>
                    <p>
                      Use our interactive UI to compose GET, POST, PUT, PATCH,
                      DELETE, HEAD and OPTIONS requests.
                    </p>
                    <LinkButton to={routeMap.newBuilderTest(project.id)}>
                      Start Building
                    </LinkButton>
                  </VerticalGroup>
                  <HorizontalGroup>
                    <TestBuilderIllustration />
                  </HorizontalGroup>
                </HorizontalGroup>
              </Card>
              <Card>
                <HorizontalGroup>
                  <VerticalGroup>
                    <h3>Script Editor</h3>
                    <p>
                      Use our code samples as a foundation for your script or
                      start from a clean slate.
                    </p>
                    <LinkButton to={routeMap.newTestScript(project.id)}>
                      Start Scripting
                    </LinkButton>
                  </VerticalGroup>
                  <HorizontalGroup>
                    <ScriptEditorIllustration />
                  </HorizontalGroup>
                </HorizontalGroup>
              </Card>
            </HorizontalGroup>
          </HorizontalGroup>
          <BrowserDiscoverability />
        </Body>
      )}
    </PluginPage>
  )
}
