import React from 'react'
import { Badge } from '@grafana/ui'

import { type TestRun } from 'types'
import { type InsightScoreStatus } from 'types/cloudInsights'
import { isInsightExecuting, isInsightFailed } from 'utils/insights'
import { exhaustive } from 'utils/typescript'
import {
  useInsightsLatestExecution,
  useInsightsExecutionResult,
} from 'data/useCloudInsights'
import { Flex } from 'components/Flex'

import {
  Container,
  Description,
  Heading,
  Icon,
} from './CloudInsightsHeader.styles'
import { useCategoriesStatus } from './CloudInsightsHeader.hooks'

interface CloudInsightsHeaderProps {
  testRun: TestRun
}

export function CloudInsightsHeader({ testRun }: CloudInsightsHeaderProps) {
  const { data: execution, error: executionError } =
    useInsightsLatestExecution(testRun)
  const { data: executionResultData } = useInsightsExecutionResult(
    testRun,
    execution
  )
  const {
    status,
    isLoading: isCategoriesLoading,
    isExecuting: isCategoriesExecuting,
  } = useCategoriesStatus(testRun, execution)

  const hasExecutionSkipped = executionError?.status === 404
  const hasExecutionFailed =
    hasExecutionSkipped || isInsightFailed(executionResultData)
  const isExecutionExecuting = isInsightExecuting(executionResultData)
  const isLoading = !hasExecutionSkipped && isCategoriesLoading

  return (
    <Container>
      <Flex align="center" gap={1}>
        <Icon name="heart-rate" size="xl" />
        <div>
          <Heading gap={1}>
            <span>Cloud Insights</span>
            <Badge text="NEW" color="orange" />
          </Heading>

          <Description>
            {getStatusText({
              status,
              isFailed: hasExecutionFailed,
              isLoading: isLoading,
              isProcessing: isExecutionExecuting || isCategoriesExecuting,
            })}
          </Description>
        </div>
      </Flex>
    </Container>
  )
}

interface GetStatsTextProps {
  status: InsightScoreStatus | undefined
  isFailed: boolean
  isLoading: boolean
  isProcessing: boolean
}

const getStatusText = ({
  status,
  isFailed,
  isLoading,
  isProcessing,
}: GetStatsTextProps) => {
  if (isProcessing || !status) {
    return 'Your test results are being analyzed...'
  }

  if (isLoading) {
    return 'Loading...'
  }

  if (isFailed) {
    return 'An error occurred while fetching insights.'
  }

  switch (status) {
    case 'skipped':
      return 'No insights available.'
    case 'good':
      return 'Our automated algorithms could not find any issues.'
    case 'needs-improvement':
      return 'Our automated algorithms have analyzed the test results and have found some minor issues.'
    case 'poor':
      return 'Our automated algorithms have analyzed the test results and have found some critical issues.'
    default:
      return exhaustive(status)
  }
}
