import React from 'react'

import styled from 'styled-components'

import { useGrafanaSubscriptionUrl } from 'hooks/useGrafanaSubscriptionUrl'
import { spacing } from 'utils/styled'

import { LinkButton } from 'components/LinkButton'
import { CollapsableSection } from 'components/CollapsableSection'
import { CollapsableSectionHeader } from 'components/CollapsableSection/CollapsableSectionHeader'
import { NoDataSectionOverlay } from 'components/Chart/NoDataOverlay.styles'

import {
  RunDetailsSection,
  RunDetailsTabContent,
} from 'pages/TestRunPage/TestRunPage.styles'
import { RunOverviewIcon } from 'pages/TestRunPage/components/RunOverview/RunOverviewIcon'
import { SectionContent } from 'pages/TestRunPage/components/styles'
import { PERFORMANCE_OVERVIEW_HEADER } from 'pages/TestRunPage/components/RunOverview/RunOverview'
import { TabsBar } from 'components/Tabs/TabsBar'
import { Tab } from 'components/Tabs/Tab'
import { Test, TestRun } from 'types'
import { getScriptTabTexts } from '../Breakdown/Breakdown.utils'
import { ScriptTab } from '../Breakdown/ScriptTab'
import { useIsFreeOrTrialSubscription } from 'data/useSubscriptions'
import { DocLink, docs } from 'components/DocLink'

export const TextExpiredRunDataInfoMessage = () => (
  <>
    For more details, refer to{' '}
    <DocLink article={docs.costManagementAndBilling.lengthOfDataRetention}>
      Length of data retention
    </DocLink>
    {' and '}
    <DocLink article={docs.analyze.manageResults.saveResults}>
      Save a test result
    </DocLink>
  </>
)

export const ExpiredRunOverview = ({
  run,
  test,
}: {
  run: TestRun
  test: Test
}) => {
  const url = useGrafanaSubscriptionUrl()
  const isFreeSubscription = useIsFreeOrTrialSubscription()
  const scriptTabTexts = getScriptTabTexts()

  return (
    <Container>
      <RunDetailsSection>
        <CollapsableSection
          isOpen={true}
          disabled={true}
          onToggle={() => {}}
          label={
            <CollapsableSectionHeader
              icon={<RunOverviewIcon />}
              heading={PERFORMANCE_OVERVIEW_HEADER}
              description={
                <>
                  The performance overview is not available because of the test
                  data was deleted by retention.
                </>
              }
            />
          }
        >
          <SectionContent>
            <TextExpiredOverlayStyled>
              <h2>Test data has expired</h2>
              <p>
                The test run data has been deleted based on your plan&apos;s
                data retention policy. <br />
                <TextExpiredRunDataInfoMessage />
              </p>
              {isFreeSubscription && (
                <LinkButton
                  button={{ icon: 'rocket', size: 'lg' }}
                  external
                  to={url}
                  target="_blank"
                >
                  Upgrade
                </LinkButton>
              )}
            </TextExpiredOverlayStyled>
          </SectionContent>
        </CollapsableSection>
      </RunDetailsSection>

      <TabsBar>
        <Tab
          label={scriptTabTexts.label}
          active={true}
          suffix={() => <div>{scriptTabTexts.suffix}</div>}
        />
      </TabsBar>
      <RunDetailsSection>
        <RunDetailsTabContent>
          <ScriptTab run={run} test={test} />
        </RunDetailsTabContent>
      </RunDetailsSection>
    </Container>
  )
}

const TextExpiredOverlayStyled = styled(NoDataSectionOverlay)`
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.shape.borderRadius(1)};
  border: 1px solid ${({ theme }) => theme.colors.border.weak};
  gap: ${spacing(2)};

  > p,
  h2 {
    text-align: center;
    margin: 0;
  }

  > a {
    margin-top: ${spacing(2)};
  }
`

const Container = styled.div`
  ${TabsBar} {
    button {
      display: flex;
      flex-direction: column;
    }
  }
`
