import React, { ComponentType } from 'react'

import { getUserOS } from 'utils/platform'
import { CodeSnippet } from 'components/CodeSnippet'
import { DocLink, docs } from 'components/DocLink'
import { DownloadLink } from 'components/DownloadLink'
import { Instructions } from 'components/Instructions'
import {
  getLoginCommand,
  getProjectSnippet,
  getRunCommands,
  installationTabs,
} from './CLIRunGuide.utils'
import { GuideContainer, Text } from './CLIRunGuide.styles'
import { useAppContext } from 'appContext'

interface CommonProps {
  projectId: number | string
  script: string
  scriptName: string
  testName: string
}

interface CLIRunGuideProps {
  steps?: Array<ComponentType<CommonProps>>
}

function CLIRunGuideBase({
  steps = [Installation, Login, CreateTest, Run],
  ...props
}: CLIRunGuideProps & CommonProps) {
  return (
    <GuideContainer>
      <Instructions>
        {steps.map((Step, index) => (
          <Step key={`step-${index}`} {...props} />
        ))}
      </Instructions>
    </GuideContainer>
  )
}

function Installation() {
  return (
    <Instructions.Step title="Install k6 on your platform">
      <CodeSnippet
        code=""
        lang="plain"
        tabs={installationTabs}
        initialTab={getUserOS()}
      />
      <Text>
        For additional ways of installing, visit our{' '}
        <DocLink article={docs.gettingStarted.installation}>
          installation docs
        </DocLink>
        .
      </Text>
    </Instructions.Step>
  )
}

function Login() {
  const { account } = useAppContext()

  return (
    <Instructions.Step title="Login to your account">
      <CodeSnippet code={getLoginCommand(account.token.key)} lang="plain" />
    </Instructions.Step>
  )
}

function CreateTest({
  script = '',
  scriptName,
  testName,
  projectId,
}: CommonProps) {
  return (
    <Instructions.Step
      title={!!script ? 'Download the test script' : 'Create a test script'}
    >
      {!!script && (
        <Text>
          Download the{' '}
          <DownloadLink data={script} filename={scriptName}>
            test script
          </DownloadLink>
          .
        </Text>
      )}

      {!script && (
        <>
          <Text>Create a new test script called test.js by running</Text>
          <CodeSnippet code="k6 new test.js" lang="js" dedent={false} />
        </>
      )}

      <Text>
        In the <DocLink article={docs.options.main}>script options</DocLink> for
        test.js, you can specify the test name and which project the test should
        run for.
      </Text>
      <CodeSnippet
        code={getProjectSnippet(testName, projectId)}
        lang="js"
        dedent={false}
      />
    </Instructions.Step>
  )
}

function Run({ scriptName }: CommonProps) {
  return (
    <Instructions.Step title="Run cloud test">
      <CodeSnippet code="" tabs={getRunCommands(scriptName)} lang="plain" />
      <Text>
        If you need help understanding the results, see our docs on{' '}
        <DocLink article={docs.insights.overview}>result analysis</DocLink>.
      </Text>
    </Instructions.Step>
  )
}

export const CLIRunGuide = Object.assign(CLIRunGuideBase, {
  Installation: Installation,
  Login: Login,
  CreateTest: CreateTest,
  Run: Run,
})
