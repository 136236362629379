import { useQuery } from '@tanstack/react-query'
import { useDatasource } from 'datasourceContext'
import { Threshold, TestRun } from 'types'
import { toThresholdsQueryKey } from './queryKeys'

type ThresholdName = Pick<Threshold, 'name'>

export const useThresholdNames = (testRun: TestRun) => {
  const { ds } = useDatasource()

  return useQuery<ThresholdName[]>({
    queryKey: toThresholdsQueryKey(testRun.id, {
      select: 'name',
      ended: testRun.ended,
    }),
    queryFn: () =>
      ds
        .fetchThresholds({ id: testRun.id, select: 'name', count: false })
        .then((res) => res.value ?? []),
  })
}
