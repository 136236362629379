import { useLocalStorage } from 'usehooks-ts'

const items = {
  'browser-recorder-overlay': {
    key: 'getting-started-browser-recorder-overlay-dismissed',
    label: 'Dismissed browser recorder overlay',
    description:
      'User dismissed the overlay shown in script editor promoting the browser recorder.',
  },
  'unset-project-limits-warning': {
    key: 'unset-project-limits-warning-dismissed',
    label: 'Dismissed unset project limits warning',
    description:
      'User dismissed the warning shown when project limits and quotas are unset.',
  },
  'env-vars-banner': {
    key: 'env-vars-banner-dismissed',
    label: 'Dismissed environment variables banner',
    description:
      'User dismissed the banner shown in the script editor promoting environment variables.',
  },
}

export function useUserToggle(key: keyof typeof items) {
  const [dismissed, setDismissed] = useLocalStorage(items[key].key, false)

  function dismiss(newValue = true) {
    setDismissed(newValue)
  }

  return {
    option: items[key],
    dismissed,
    dismiss,
  }
}

export function useAllUserToggles() {
  return [
    useUserToggle('browser-recorder-overlay'),
    useUserToggle('env-vars-banner'),
    useUserToggle('unset-project-limits-warning'),
  ]
}
