import React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { Project, Test } from 'types'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { useTest } from 'data/useTest'
import { routeMap } from 'routeMap'
import { TestDraft, toTestDraft } from './drafts'
import { EditorChunk } from './EditorChunk'
import { PluginPage } from 'components/PluginPage'
import { createEditTestPageNavModel } from 'navModels'
import { useEditingSession } from './Editor.hooks'

interface EditorWrapperProps {
  project: Project
  test: Test
}

function EditorWrapper({ project, test }: EditorWrapperProps) {
  const pageNav = createEditTestPageNavModel(project, test)

  const [draft, setDraft] = useEditingSession({
    initialValue: () => toTestDraft(test),
    projectId: project.id,
    testId: test.id,
  })

  const handleDraftChange = (draft: TestDraft) => {
    setDraft(draft)
  }

  const handleDraftRevert = () => {
    setDraft(toTestDraft(test))
  }

  return (
    <PluginPage pageNav={pageNav} renderTitle={() => test.name}>
      <EditorChunk
        draft={draft}
        project={project}
        test={test}
        onChange={handleDraftChange}
        onRevert={handleDraftRevert}
      />
    </PluginPage>
  )
}

interface TestRouteProps {
  project: Project
}

export function EditTest({ project }: TestRouteProps) {
  const { testId } = useParams<{ testId: string }>()
  const { data } = useTest(+testId)

  if (data === undefined) {
    return <CenteredSpinner />
  }

  // Let's be kind to the user if they entered the wrong project id in the url.
  if (data.project_id !== project.id) {
    return <Redirect to={routeMap.editTest(data)} />
  }

  return <EditorWrapper project={project} test={data} />
}
