import styled from 'styled-components'
import { color, spacing } from '../../utils/styled'

export const SectionContainer = styled.div`
  display: flex;
  background-color: ${color('background', 'canvas')};
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 3;
`

export const DurationSection = styled.div`
  display: flex;
  background-color: ${color('background', 'secondary')};
  padding: ${spacing(0.5)};
  justify-content: flex-start;
  border-right: 1px ${color('border', 'weak')} solid;
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
`
