import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Alert } from '@grafana/ui'
import styled from 'styled-components'

import { CreateStackApiTokenButton } from './components/CreateStackApiTokenButton'
import { StackApiTokensTable } from './components/StackApiTokensTable'
import { Section } from 'components/Section'
import { DocLink, docs } from 'components/DocLink'
import { useIsUserOrgAdmin } from 'data/usePermissions'
import { routeMap } from 'routeMap'
import { useOrganizationTokens } from 'data/useOrganizationTokens'
import { GENERIC_API_ERROR_MESSAGE } from 'constants/errors'
import { QueryErrorBoundary } from 'components/ErrorBoundary'
import { ErrorBoundaryLocalView } from 'services/tracking.types'

export const StackAPITokenTab = () => {
  const isUserOrgAdmin = useIsUserOrgAdmin()
  const [hasLoadedData, setHasLoadedData] = useState(false)

  const handleOnLoad = useCallback(() => {
    setHasLoadedData(true)
  }, [])

  return (
    <Section
      title={
        <Section.ProtectedTitle
          title="Grafana Stack API token"
          hasPermissions={isUserOrgAdmin}
        />
      }
      description={
        <>
          Grafana Stack API token allows{' '}
          <Link to={routeMap.cliGuide}>running cloud tests from the CLI</Link>{' '}
          without linking the run to a specific account, e.g. when running tests
          from a CI platform. Read&nbsp;more about Grafana Stack API tokens in
          the{' '}
          <DocLink article={docs.integrations.token.organizationAPIToken}>
            docs
          </DocLink>
          .
        </>
      }
      actions={<StyledCreateStackApiTokenButton $visible={hasLoadedData} />}
    >
      <QueryErrorBoundary view={ErrorBoundaryLocalView.StackAPITokens}>
        {isUserOrgAdmin && <Content onLoad={handleOnLoad} />}
      </QueryErrorBoundary>
    </Section>
  )
}

export const Content = ({ onLoad }: { onLoad: () => void }) => {
  const { data, error, isFetching } = useOrganizationTokens()

  useEffect(() => {
    if (data) {
      onLoad()
    }
  }, [data, onLoad])

  if (error && !isFetching) {
    return <Alert title={GENERIC_API_ERROR_MESSAGE} />
  }

  return <StackApiTokensTable data={data} isLoading={isFetching} />
}

type ButtonProps = {
  $visible: boolean
}

const StyledCreateStackApiTokenButton = styled(
  CreateStackApiTokenButton
)<ButtonProps>`
  visibility: ${({ $visible }) => !$visible && 'hidden'};
`
