import { useContext } from 'react'

import { FeatureFlagName } from './FeatureFlags.types'
import {
  FeatureFlagsContext,
  FeatureFlagsContextProps,
} from './FeatureFlags.context'

export const useFeatureFlags = () => {
  const state = useContext(FeatureFlagsContext)

  if (typeof state === 'undefined') {
    throw new Error(
      'useFeatureFlags must be used within a FeatureFlagsProvider'
    )
  }

  return state as FeatureFlagsContextProps
}

export const useFeatureFlag = (name: FeatureFlagName) => {
  const { featureFlags } = useFeatureFlags()
  const feature = featureFlags.find((flag) => flag.name === name)

  return feature?.active ?? false
}
