import React from 'react'
import { Section } from 'components/Section'
import { DocLink, docs } from 'components/DocLink'
import { useAppContext } from 'appContext'
import { useSafeTests } from './SavedTestsTab.hooks'
import { SavedTestRunsTable } from './components/SavedTestRunsTable'

export const SavedTestsTab = () => {
  const { orgId } = useAppContext()
  const { data, isFetching } = useSafeTests({ orgId })

  return (
    <Section
      title="Saved tests"
      description={
        <>
          Save important test results from getting deleted by our{' '}
          <DocLink article={docs.subscription.dataRetention}>
            data retention policy
          </DocLink>
          . Read more about how to save test results in the{' '}
          <DocLink article={docs.manage.savedTestRuns}>docs</DocLink>.
        </>
      }
    >
      <SavedTestRunsTable isLoading={isFetching} data={data?.k6_runs} />
    </Section>
  )
}
