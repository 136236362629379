import React from 'react'
import { type AbsoluteTimeRange } from '@grafana/data'

import { Test, TestRunAnalysis } from 'types'
import { isTestAbortedByScriptError, isTestRunExpired } from 'utils/testRun'
import { Chart } from 'components/Chart'
import { CollapsableSection } from 'components/CollapsableSection/CollapsableSection'
import { CollapsableSectionHeader } from 'components/CollapsableSection/CollapsableSectionHeader'
import { Tab } from 'components/Tabs/Tab'
import { TabsBar } from 'components/Tabs/TabsBar'
import { RunDetailsSection } from 'pages/TestRunPage/TestRunPage.styles'

import { useTimeRange } from '../../TimeRangeContext'
import { SectionContent } from '../styles'
import { ProtocolSummary } from './ProtocolSummary'
import { useAvailableProtocols, useOverviewState } from './RunOverview.hooks'
import { Summary } from './RunOverview.styles'
import { RunOverviewIcon } from './RunOverviewIcon'
import { Scenarios } from './Scenarios'
import { TestSummary } from './TestSummary'
import { AbortedScriptErrorOverlay } from './StatusOverlays/AbortedScriptErrorOverlay'
import { ExpiredRunOverview } from './ExpiredRunOverview'
import { Protocol } from './RunOverview.types'
import { RunsPageTestIds } from 'types/dataTestIds'

export const PERFORMANCE_OVERVIEW_HEADER = 'PERFORMANCE OVERVIEW'

interface RunOverviewProps {
  test: Test
  analysis: TestRunAnalysis
  timeRange?: AbsoluteTimeRange
  zoom?: boolean
  onChangeTimeRange?: (timeRange?: AbsoluteTimeRange) => void
}

const PROTOCOL_ARIA_LABEL: Record<Protocol['type'], string> = {
  http: 'HTTP',
  ws: 'WebSockets',
  grpc: 'gRPC',
  browser: 'Browser',
}

export const RunOverview = ({ analysis, test }: RunOverviewProps) => {
  const { timeRange, setTimeRange } = useTimeRange()
  const protocols = useAvailableProtocols(analysis)

  const [{ open, active }, setOverviewState] = useOverviewState(protocols)

  const isRunExpired = isTestRunExpired(analysis.main)

  const handleSectionToggle = () => {
    if (isRunExpired) {
      return
    }

    setOverviewState('replace', {
      open: !open,
      active,
    })
  }

  if (isTestAbortedByScriptError(analysis.main)) {
    return <AbortedScriptErrorOverlay testRun={analysis.main} />
  }

  if (isRunExpired) {
    return <ExpiredRunOverview run={analysis.main} test={test} />
  }

  return (
    <RunDetailsSection>
      <CollapsableSection
        isOpen={open}
        label={
          <CollapsableSectionHeader
            icon={<RunOverviewIcon />}
            heading={PERFORMANCE_OVERVIEW_HEADER}
            description={
              <TestSummary
                analysis={analysis}
                protocol={active}
                protocols={protocols}
              />
            }
            actions={
              <TabsBar>
                {protocols.length > 1 &&
                  protocols.map((protocol) => (
                    <Tab
                      key={protocol.type}
                      label={protocol.label}
                      href={setOverviewState('href', {
                        open: true,
                        active: protocol,
                      })}
                      active={active?.type === protocol.type}
                      aria-label={`Tab overview ${
                        PROTOCOL_ARIA_LABEL[protocol.type]
                      }`}
                    />
                  ))}
              </TabsBar>
            }
          />
        }
        onToggle={handleSectionToggle}
      >
        <SectionContent>
          <Summary>
            <ProtocolSummary protocol={active} />
          </Summary>
          <Chart
            title={`Performance Overview (${active?.label ?? 'HTTP'})`}
            testRun={analysis.main}
            compareWith={analysis.compareWith}
            metrics={active?.metrics ?? []}
            timeRange={timeRange}
            onChangeTimeRange={setTimeRange}
            dataTestId={RunsPageTestIds.PerformanceOverviewChart}
          />
        </SectionContent>

        {active && (
          <SectionContent>
            <Scenarios analysis={analysis} protocol={active.type} />
          </SectionContent>
        )}
      </CollapsableSection>
    </RunDetailsSection>
  )
}
