import styled, { css } from 'styled-components'
import { color, spacing } from 'utils/styled'
import { TabsBar } from '../Tabs/TabsBar'

export const Header = styled.header`
  background-color: ${({ theme }) => theme.colors.border.medium};
  min-height: 42px; // Height of TabBar
  display: flex;
  align-items: center;
  padding: ${spacing(0, 2)};
`

export const Section = styled.section<{ $minHeight: number; $height?: string }>`
  display: flex;
  height: ${({ $height }) => $height};
  min-height: ${({ $minHeight, $height }) => !$height && `${$minHeight}px`};
  position: relative;
`

export const CodeWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  border-radius: 0 0 ${({ theme }) => theme.shape.borderRadius(2)}
    ${({ theme }) => theme.shape.borderRadius(2)};
  overflow: hidden;
  background-color: ${color('background', 'canvas')}; // same as MonacoEditor
`

export const Code = styled.code<{ $lang: string }>`
  overflow: auto;
  flex-grow: 1;
  display: block;
  // cspell: disable line
  font-family: Menlo, Monaco, 'Courier New', monospace;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text.primary} !important;
  text-shadow: none;
  white-space: pre-wrap;
  ${({ $lang }) =>
    $lang === 'js' ? JSStyles : SHStyles} // Override global code styles
  margin: 0;
  padding: ${spacing(2)};
  border: none;
  background-color: transparent;
`

export const ButtonWrapper = styled.div`
  position: absolute;
  top: ${spacing(1)};
  right: ${spacing(1)};
`

const CodeStyleBase = css`
  .token.boolean,
  .token.string {
    color: ${({ theme }) =>
      theme.visualization.getColorByName('semi-dark-orange')};
  }

  .token.constant {
    color: ${({ theme }) => theme.visualization.getColorByName('light-blue')};
  }

  .token.function {
    color: ${({ theme }) => theme.colors.text.primary};
  }

  .token.punctuation {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`

const JSStyles = css`
  ${CodeStyleBase}

  .token.keyword {
    color: ${({ theme }) => theme.visualization.getColorByName('light-blue')};
  }

  .token.number {
    color: ${({ theme }) => theme.visualization.getColorByName('green')};
  }

  .token.operator {
    color: ${({ theme }) => theme.visualization.getColorByName('gray')};
    background-color: transparent;
  }

  .token.comment {
    opacity: 0.4;
  }
`

const SHStyles = css`
  ${CodeStyleBase}

  .token.property {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`

export const TabGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing(2, 2, 2, 1.5)};
  border-right: 1px ${color('border', 'medium')} solid;
  flex: 0 0 200px;
`

export const GroupItem = styled.div<{ $active?: boolean }>`
  position: relative;
  padding-left: ${spacing(1.5)};
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: ${spacing(1)};
  cursor: pointer;
  opacity: ${({ $active }) => ($active ? 1 : 0.65)};
  font-weight: ${({ $active }) => $active && 500};
  min-height: ${spacing(3)};
  line-height: 1.21em; // To make wrapped text look nice

  &::after {
    display: ${({ $active }) => ($active ? 'block' : 'none')};
    font-weight: 500;
    content: ' ';
    height: ${spacing(3)};
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: ${spacing(0.5)};
    border-radius: ${({ theme }) => theme.shape.borderRadius(1)};
    background-image: ${({ theme }) => theme.colors.gradients.brandVertical};
  }

  &:hover {
    text-decoration: underline;
    opacity: 1;
  }
`

export const TabsBarStyled = styled(TabsBar)`
  width: calc(100%);
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  a {
    @media (max-width: 1407px) {
      font-size: 13px;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`
