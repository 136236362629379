import { isUndefined } from 'lodash-es'

import { LogsFilter, ParsedLog } from 'types/logs'
import { nanoToMilliseconds } from 'utils/math'

export const getEndDate = (logs: ParsedLog[]) => {
  const lastLog = logs.at(0)?.values

  if (typeof lastLog === 'undefined') {
    return
  }

  return nanoToMilliseconds(lastLog[0]) + 1
}

export const hasActiveFilter = ({
  level = [],
  loadZone = [],
  queryFilter,
}: LogsFilter) => {
  return (
    level.length > 0 ||
    loadZone.length > 0 ||
    (!isUndefined(queryFilter) && queryFilter !== '')
  )
}

export const isZeroState = (logs: ParsedLog[], filters: LogsFilter) =>
  !logs.length && !hasActiveFilter(filters)
