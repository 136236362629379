import styled from 'styled-components'
import React from 'react'
import { Icon } from '@grafana/ui'
import { spacing } from 'utils/styled'
import { DocLink, docs } from './DocLink'
import { Flex } from './Flex'

interface LoadZoneTestHangingWarningProps {
  showIcon?: boolean
  shortMessage?: boolean
}

export const LoadZoneTestHangingWarning = ({
  showIcon = true,
  shortMessage = false,
}: LoadZoneTestHangingWarningProps) => {
  const message = shortMessage ? (
    <div>
      A misconfigured private load zone may cause long test
      initialization.&nbsp;
      <DocLink article={docs.loadZones.privateLoadZonesAdditionalInfo}>
        Read more
      </DocLink>
    </div>
  ) : (
    <div>
      Test is taking a long time to start, likely because of a misconfigured
      private load zone.&nbsp;Find out more in the&nbsp;
      <DocLink article={docs.loadZones.privateLoadZonesAdditionalInfo}>
        docs.
      </DocLink>
    </div>
  )

  return (
    <Message align="flex-start">
      {showIcon && <Icon name="exclamation-triangle" aria-hidden />}
      {message}
    </Message>
  )
}

const Message = styled(Flex)`
  white-space: break-spaces;
  gap: ${spacing(0.5)};
  line-height: 1.3;

  svg {
    line-height: 1.3;
    color: ${({ theme }) => theme.colors.warning.text};
  }
`
