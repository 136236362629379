import { TestId } from 'types'

export const toTestQueryKey = (testId?: number | null) =>
  ['test', testId] as const

export const toTestRunsWithMetricsQueryKey = (
  testId: TestId,
  page?: number,
  pageSize?: number,
  includeDeleted?: boolean
) =>
  [
    ...toTestQueryKey(testId),
    'test-run-trends',
    includeDeleted ? 'include-deleted' : undefined,
    ...(page !== undefined ? [page] : []),
    ...(pageSize !== undefined ? [pageSize] : []),
  ] as const

export const toLastNonExpiredTestRunWithMetricsQueryKey = (testId: TestId) =>
  [...toTestQueryKey(testId), 'last-non-expired-test-run-trend'] as const

export const toLastExpiredTestRun = (testId: TestId) =>
  [...toTestQueryKey(testId), 'last-expired-test-run'] as const

export const toTestWithTrendsQueryKey = (testId: TestId) =>
  [...toTestQueryKey(testId), 'test-with-trends'] as const
