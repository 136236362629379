import React, { useState } from 'react'
import { Button } from '@grafana/ui'
import { TrendingMetricsModal } from 'components/TrendingMetricsModal'
import { TestWithTrends } from 'types'

interface TrendSettingsButtonProps {
  test: TestWithTrends
}

export function TrendSettingsButton({ test }: TrendSettingsButtonProps) {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button
        icon="chart-line"
        size="sm"
        fill="text"
        variant="secondary"
        onClick={handleOpen}
      >
        Trend settings
      </Button>
      <TrendingMetricsModal isOpen={open} test={test} onDismiss={handleClose} />
    </>
  )
}
