import React, { useEffect, useState } from 'react'
import { Icon, useTheme2 } from '@grafana/ui'
import { ErrorBoundary } from 'react-error-boundary'

import { TestRun, TestRunStatus } from 'types'
import { getAnimationData } from 'assets/lottie/testRun'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { AnimationStep } from 'components/LottieAnimation'
import { PluginPage } from 'components/PluginPage'
import { LoadZoneTestHangingWarning } from 'components/LoadZoneTestHangingWarning'
import { Flex } from 'components/Flex'
import { Button } from 'components/Button'
import { useTest } from 'data/useTest'
import { useCurrentProject } from 'projectContext'
import { RunStatusTooltip } from 'pages/ProjectPage/components/LatestRunDetails'

import { createTestRunPageNavModel } from 'navModels'
import {
  AnimationContainer,
  StyledLottieAnimation,
} from './TestRunPendingState.styles'
import { usePrivateLoadZoneIsHanging } from './TestRunPendingState.hooks'

type PendingAnimationStep = {
  statuses: TestRunStatus[]
  text: string
  progress: number
  step: AnimationStep
}

const ANIMATION_STEPS: PendingAnimationStep[] = [
  {
    statuses: [TestRunStatus.CREATED],
    text: 'Validating',
    progress: 25,
    step: {
      transition: [0, 40],
      segment: [40, 160],
    },
  },
  {
    statuses: [TestRunStatus.QUEUED, TestRunStatus.VALIDATED],
    text: 'Queuing for a slot',
    progress: 50,
    step: {
      transition: [160, 310],
      segment: [310, 390],
    },
  },
  {
    statuses: [TestRunStatus.INITIALIZING],
    text: 'Allocating servers',
    progress: 75,
    step: {
      transition: [390, 610],
      segment: [610, 640],
    },
  },
  {
    statuses: [TestRunStatus.RUNNING, TestRunStatus.FINISHED],
    text: 'Running test',
    progress: 100,
    step: {
      transition: [640, 875],
      segment: [875, 1022],
    },
  },
]

const getAnimationStep = (testRun?: TestRun) => {
  const status = testRun ? testRun.run_status : TestRunStatus.CREATED

  return (
    ANIMATION_STEPS.find((step) => step.statuses.includes(status)) ||
    ANIMATION_STEPS[0]!
  )
}

interface TestRunPendingStateProps {
  testRun: TestRun
  onDismiss(): void
}

export const TestRunPendingState = ({
  testRun,
  onDismiss,
}: TestRunPendingStateProps) => {
  const { data: test } = useTest(testRun.test_id)
  const { text, step, progress } = getAnimationStep(testRun)
  const { isLight } = useTheme2()
  const [testRunAnimation, setTestRunAnimation] = useState<null | any>(null)
  const project = useCurrentProject()

  const isHanging = usePrivateLoadZoneIsHanging(testRun)
  const pageNav = createTestRunPageNavModel(project, test, testRun)

  useEffect(() => {
    const loadAnimation = async () => {
      const animation = await getAnimationData({ isLight })
      setTestRunAnimation(animation)
    }

    loadAnimation()
  }, [isLight])

  return (
    <PluginPage
      pageNav={pageNav}
      renderTitle={() => <>Setting up test ({progress}%)</>}
      subTitle={
        <Flex gap={1} align="center" justify="space-between">
          <RunStatusTooltip run={testRun}>
            <>{isHanging ? <LoadZoneTestHangingWarning /> : text}</>
          </RunStatusTooltip>
          <Button type="button" fill="text" onClick={onDismiss}>
            Skip animation <Icon name="angle-right" />
          </Button>
        </Flex>
      }
    >
      <ErrorBoundary fallbackRender={() => <CenteredSpinner />}>
        <AnimationContainer>
          {testRunAnimation !== null && (
            <StyledLottieAnimation
              animationData={testRunAnimation}
              step={step}
            />
          )}
        </AnimationContainer>
      </ErrorBoundary>
    </PluginPage>
  )
}
