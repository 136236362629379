import React from 'react'
import styled, { DefaultTheme } from 'styled-components'

import { Tooltip } from '@grafana/ui'
import { createPulseAnimation } from 'theme/animation'
import { colorManipulator } from '@grafana/data'

type Size = 'sm' | 'md' | 'lg'

interface AvatarProps {
  isLoading?: boolean
  gravatar_url?: string
  size?: Size
  tooltip?: string
}

export const Avatar = ({
  gravatar_url,
  tooltip,
  isLoading = false,
  size = 'sm',
}: AvatarProps) => {
  const src = getGravatarURL(gravatar_url)

  if (!!tooltip) {
    return (
      <Tooltip content={tooltip}>
        <span>
          <AvatarImage src={src} size={size} isLoading={isLoading} />
        </span>
      </Tooltip>
    )
  }

  return <AvatarImage src={src} size={size} isLoading={isLoading} />
}

const AvatarImage = ({
  src,
  isLoading,
  size,
}: {
  src: string
  isLoading: boolean
  size: Size
}) => {
  if (isLoading) {
    return <AvatarLoading size={size} />
  }

  return <StyledImg src={src} size={size} />
}

const sizes = {
  sm: 25,
  md: 32,
  lg: 44,
}

const StyledImg = styled.img<{ size: Size }>`
  width: ${({ size }) => sizes[size]}px;
  height: ${({ size }) => sizes[size]}px;
  border-radius: 100%;
`

const AvatarLoading = styled.div<{ size: Size }>`
  width: ${({ size }) => sizes[size]}px;
  height: ${({ size }) => sizes[size]}px;
  background-color: ${({ theme }) => getLoadingBgColor(theme)};
  border-radius: 100%;
  animation: ${createPulseAnimation(0.25)} 2s infinite ease-in-out;
`

const getLoadingBgColor = (theme: DefaultTheme) => {
  const { lighten, darken } = colorManipulator

  const color = theme.colors.background.secondary
  const opacity = 0.1

  return theme.isDark ? lighten(color, opacity) : darken(color, opacity)
}

const DEFAULT_GRAVATAR_URL = 'https://secure.gravatar.com/avatar/1?f=y&d=retro'

const getGravatarURL = (url: string | undefined) => {
  try {
    if (url) {
      if (url.startsWith('/avatar')) {
        return url
      }

      const gravatarURL = new URL(url)
      const params = { f: 'y', d: 'retro' }

      gravatarURL.search = new URLSearchParams(params).toString()
      return gravatarURL.toString()
    }

    return DEFAULT_GRAVATAR_URL
  } catch {
    return DEFAULT_GRAVATAR_URL
  }
}
