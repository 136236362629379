import React from 'react'
import { MultiSelect, MultiSelectCommonProps } from '@grafana/ui'
import styled from 'styled-components'

interface ProjectsSelectProps
  extends Omit<
    MultiSelectCommonProps<string>,
    'onChange' | 'value' | 'options'
  > {
  value: string[]
  onChange(value: string[]): void
  options: Array<{ label: string; value: string }>
}

export const ProjectsSelect = ({
  value,
  onChange,
  options,
}: ProjectsSelectProps) => {
  return (
    <Container>
      <MultiSelect
        className="usage-project-select multiple-select"
        placeholder="All projects"
        isClearable
        options={options}
        value={value}
        onChange={(selectedOptions) => {
          const values = selectedOptions.map(({ value }) => value!)
          onChange(values)
        }}
      />
    </Container>
  )
}

const Container = styled.div`
  min-width: 150px;
`
