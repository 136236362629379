import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { TableColumn } from 'react-data-table-component'
import { trim, truncate } from 'lodash-es'
import { Icon } from '@grafana/ui'

import { Test, TestRunWithMetrics } from 'types'
import { InsightExecutionResultWithTestRun } from 'types/cloudInsights'
import { RunsTableTestIds } from 'types/dataTestIds'
import { getDateInTimezone, resolveTimeZone } from 'utils/date'
import { isInsightFailed, isNumericScore } from 'utils/insights'
import { toPercentage } from 'utils/math'
import { isTestActive, isTestRunExpired } from 'utils/testRun'
import { useFeatureFlag } from 'store/FeatureFlags'
import { getTestStatusText } from 'pages/utils'
import { routeMap } from 'routeMap'
import { useDeleteTestRunsConfirmation } from 'data/useDeleteTestRuns'
import { useHasUserProjectWriteAccess } from 'data/usePermissions'
import { isInvalidPageError, PAGE_SIZE, useTestRuns } from 'data/useTestRuns'
import { useStopTestRunAction } from 'data/useStopTestRun'
import { ConfirmModal } from 'components/ConfirmModal'
import {
  generateBatchDeleteActions,
  useBatching,
} from 'components/DataTable/hooks/useBatching'
import { LoadingContainer } from 'components/LoadingContainer'
import { LoadZoneOrLocal } from 'components/LoadZoneOrLocal'
import { DurationMetaItem, VUsMetaItem, VUhMetaItem } from 'components/MetaItem'
import { NoteModal } from 'components/NoteModal'
import { Tooltip } from 'components/Tooltip'
import { getTrendName } from 'components/TrendChart'
import { OverflownTextTooltip } from 'components/OverflownTextTooltip'
import { Flex } from 'components/Flex'
import { StatusIcon } from 'components/StatusIcon'

import { TrendCell } from './TrendCell'
import { VUhHeaderCell } from './VUhHeaderCell'
import { createStopTestRunAction, switchCellProps } from './RunsTable.utils'
import { usePageParameter, useTestRunInsightResults } from './RunsTable.hooks'
import {
  CreateNoteButton,
  EditNoteButton,
  NoteCellBody,
  NoteIcon,
  NoteText,
  NoteWrapper,
  RunsDataTable,
  RunsDataTablePlaceholder,
  Score,
} from './RunsTable.styles'

interface RunsTableProps {
  test: Test
}

export const RunsTable = ({ test }: RunsTableProps) => {
  const history = useHistory()
  const isReadOnly = !useHasUserProjectWriteAccess()
  const [currentPage, setCurrentPage] = usePageParameter()
  const [editNoteTestRun, setEditNoteTestRun] = useState<
    TestRunWithMetrics | undefined
  >(undefined)

  const {
    data: testRunsData,
    isLoading: isTestRunsLoading,
    isRefetching,
    isPreviousData,
    error,
  } = useTestRuns(test.id, currentPage)
  const { runs = [], totalPages } = testRunsData || {}

  const isFeatureCloudInsightsEnabled = useFeatureFlag('cloudInsights')
  const testRunInsightScores = useTestRunInsightResults(runs, {
    enabled: isFeatureCloudInsightsEnabled,
    useErrorBoundary: false,
  })

  const [stopTestRunConfirmationProps, handleStopTestRunClick] =
    useStopTestRunAction()
  const stopTestRunAction = createStopTestRunAction({
    onClick: (testRun) => handleStopTestRunClick(testRun),
  })

  const filteredRuns = runs.filter((run) => !isTestActive(run))
  const batchAction = useBatching<TestRunWithMetrics>(filteredRuns)
  const [confirmModalProps, handleDelete] = useDeleteTestRunsConfirmation(
    test.id,
    batchAction.reset
  )
  const isDisabled = (testRun: TestRunWithMetrics) => isTestActive(testRun)
  const actions = generateBatchDeleteActions(
    batchAction,
    handleDelete,
    isDisabled,
    {
      noun: 'test run',
      action: 'Delete',
    }
  )

  const getCellProps = switchCellProps(actions.getCellProps, stopTestRunAction)

  const columns = useColumns({
    metrics: test.trending_metrics,
    handleEditNote: setEditNoteTestRun,
    timeZone: resolveTimeZone(),
    insights: testRunInsightScores,
    isReadOnly,
  })

  const handleRowClick = useCallback(
    (testRun: TestRunWithMetrics) => {
      history.push(routeMap.testRun(testRun.id))
    },
    [history]
  )

  const handleChangePage = useCallback(
    (page: number) => {
      setCurrentPage('push', page)
      batchAction.reset()
    },
    [setCurrentPage, batchAction]
  )

  const handleDismissNoteModal = useCallback(() => {
    setEditNoteTestRun(undefined)
  }, [])

  useEffect(() => {
    if (isInvalidPageError(error)) {
      setCurrentPage('replace', 1)
    }
  }, [error, setCurrentPage])

  return (
    <LoadingContainer loading={isTestRunsLoading}>
      {isTestRunsLoading && <RunsDataTablePlaceholder />}
      {!isTestRunsLoading && (
        <>
          <RunsDataTable
            columns={columns}
            data-testid={RunsTableTestIds.RunsTable}
            isFetching={isRefetching && isPreviousData}
            data={runs}
            onRowClicked={handleRowClick}
            highlightOnHover={true}
            pointerOnHover={true}
            noDataMessage="No tests have been run yet"
            pagination
            paginationServer
            currentPage={currentPage}
            paginationPerPage={PAGE_SIZE}
            paginationTotalRows={PAGE_SIZE * (totalPages ?? 0)}
            onChangePage={handleChangePage}
            {...(isReadOnly ? {} : { actions: { ...actions, getCellProps } })}
          />

          <ConfirmModal {...stopTestRunConfirmationProps} />

          <ConfirmModal title="Confirm action" {...confirmModalProps} />

          {editNoteTestRun && (
            <NoteModal
              isOpen={Boolean(editNoteTestRun)}
              onDismiss={handleDismissNoteModal}
              run={editNoteTestRun}
            />
          )}
        </>
      )}
    </LoadingContainer>
  )
}

interface ColumnsProps {
  metrics: Test['trending_metrics']
  handleEditNote: (row?: TestRunWithMetrics) => void
  timeZone: string
  insights: {
    data: InsightExecutionResultWithTestRun[]
    isLoading: boolean
  }
  isReadOnly: boolean
}

type RunTableColumn = TableColumn<TestRunWithMetrics>

function useColumns({
  metrics,
  handleEditNote,
  insights,
  timeZone,
  isReadOnly,
}: ColumnsProps) {
  const isFeatureCloudInsightsEnabled = useFeatureFlag('cloudInsights')

  return useMemo(() => {
    const columns: RunTableColumn[] = [
      {
        name: 'Status',
        // TODO - update this when feature is officially released
        cell: (row) =>
          isFeatureCloudInsightsEnabled ? (
            <OverflownTextTooltip>
              <span data-tag="allowRowEvents">{getTestStatusText(row)}</span>
            </OverflownTextTooltip>
          ) : (
            <OverflownTextTooltip>
              <Flex align="center" gap={1}>
                <StatusIcon
                  testRun={row}
                  size="lg"
                  spinner={true}
                  showSaveState={true}
                />

                <span data-tag="allowRowEvents">{getTestStatusText(row)}</span>
              </Flex>
            </OverflownTextTooltip>
          ),
        minWidth: '150px',
      },
      {
        name: 'Run',
        minWidth: '120px',
        cell: ({ id, started }) => (
          <span data-tag="allowRowEvents">
            <Link to={routeMap.testRun(id)}>
              {started
                ? getDateInTimezone(started, 'MMM dd, HH:mm', timeZone)
                : '-'}
            </Link>
          </span>
        ),
      },
      {
        name: 'VUs',
        cell: (row) => (
          <VUsMetaItem data-tag="allowRowEvents" testRun={row} icon={false} />
        ),
      },
      {
        name: <VUhHeaderCell />,
        cell: (row) => (
          <VUhMetaItem data-tag="allowRowEvents" testRun={row} icon={false} />
        ),
      },
      {
        name: 'Duration',
        minWidth: '115px',
        cell: (row) => (
          <DurationMetaItem
            data-tag="allowRowEvents"
            testRun={row}
            icon={false}
          />
        ),
      },
      {
        name: 'Load distribution',
        cell: (row) => {
          return (
            <LoadZoneOrLocal
              compact={row.distribution.length > 1}
              testRun={row}
            />
          )
        },
        width: '166px',
      },
      ...metrics.map<RunTableColumn>((trend) => {
        return {
          name: (
            <OverflownTextTooltip>
              {getTrendName(trend, true)}
            </OverflownTextTooltip>
          ),
          cell: (row) => (
            <OverflownTextTooltip allowRowEvents>
              <TrendCell
                data-tag="allowRowEvents"
                metrics={row.metrics}
                trend={trend}
              />
            </OverflownTextTooltip>
          ),
          center: true,
          grow: 2,
        }
      }),
      {
        name: 'Note',
        minWidth: '120px',
        cell: (row) => {
          const trimmed = trim(row.note)
          const noteCell = truncate(trimmed)
          const noteTooltip = truncate(trimmed, { length: 500 })
          const isRunExpired = isTestRunExpired(row)

          if (isReadOnly || isRunExpired) {
            return (
              <Tooltip content={noteTooltip}>
                <span>{noteCell}</span>
              </Tooltip>
            )
          }

          if (!row.note) {
            return (
              <NoteCellBody>
                <CreateNoteButton
                  fill="text"
                  onClick={() => handleEditNote(row)}
                >
                  Create note
                </CreateNoteButton>
              </NoteCellBody>
            )
          }

          return (
            <NoteCellBody>
              <EditNoteButton
                tabIndex={-1}
                tooltip={noteTooltip}
                fill="text"
                onClick={() => handleEditNote(row)}
              >
                <NoteWrapper>
                  <NoteText>{noteCell}</NoteText>
                  <NoteIcon name="pen" size="lg" />
                </NoteWrapper>
              </EditNoteButton>
            </NoteCellBody>
          )
        },
      },
    ]

    // TODO - add as 1st column when this feature is officially released
    if (isFeatureCloudInsightsEnabled) {
      columns.splice(0, 0, {
        name: 'Score',
        cell: (row) => {
          const { data: results = [], isLoading } = insights
          const result = results.find(
            (result) => result?.test_run.id === row.id
          )
          const score = result?.score
          const isResultMissing = !isLoading && !result

          if (isResultMissing) {
            return (
              <Tooltip content="Insights data is missing">
                <Icon name="question-circle" size="lg" color="gray" />
              </Tooltip>
            )
          }

          if (isInsightFailed(result)) {
            return (
              <Tooltip
                content={result?.status_reason ?? 'Insights failed to execute'}
              >
                <Icon name="question-circle" size="lg" color="gray" />
              </Tooltip>
            )
          }

          if (!isNumericScore(score)) {
            return (
              <Icon
                data-tag="allowRowEvents"
                name="fa fa-spinner"
                size="md"
                color="gray"
              />
            )
          }

          return (
            <Flex
              data-tag="allowRowEvents"
              align="center"
              justify="center"
              width="100%"
            >
              <Score score={score}>{toPercentage(score.value)}</Score>
            </Flex>
          )
        },
        grow: 0,
        width: '75px',
      })
    }

    return columns
  }, [
    handleEditNote,
    isFeatureCloudInsightsEnabled,
    isReadOnly,
    metrics,
    insights,
    timeZone,
  ])
}
