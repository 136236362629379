import { useState } from 'react'
import { useDebounce } from 'usehooks-ts'

// Show the tooltip after a short delay to avoid flickering
export const useShowTooltip = () => {
  const [showTooltip, setShowTooltip] = useState(false)
  const debouncedShowTooltip = useDebounce(showTooltip, 100)

  return {
    showTooltip: showTooltip && debouncedShowTooltip,
    onShowTooltip: () => setShowTooltip(true),
    onHideTooltip: () => setShowTooltip(false),
  }
}
