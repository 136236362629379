import styled from 'styled-components'
import { isNumber } from 'lodash-es'

import { FlexStyleProps } from './Flex.types'

export const FlexElement = styled.div<FlexStyleProps>`
  display: flex;
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  flex-direction: ${({ $direction }) => $direction};
  flex-wrap: ${({ wrap }) => wrap};
  gap: ${({ theme, gap }) =>
    !gap || !isNumber(gap) ? gap : theme.spacing(gap)};
  column-gap: ${({ theme, columnGap }) =>
    columnGap && theme.spacing(columnGap)};
  row-gap: ${({ theme, rowGap }) => rowGap && theme.spacing(rowGap)};
  flex-grow: ${({ grow }) => grow};
  flex-shrink: ${({ shrink }) => shrink};
  flex-basis: ${({ basis }) => basis};
  order: ${({ order }) => order};
  padding: ${({ theme, padding }) => padding && theme.spacing(padding)};
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};

  ${({ paddingVertical, theme }) =>
    paddingVertical &&
    `
      padding-top: ${theme.spacing(paddingVertical)};
      padding-bottom: ${theme.spacing(paddingVertical)};
    `}

  ${({ paddingHorizontal, theme }) =>
    paddingHorizontal &&
    `
      padding-left: ${theme.spacing(paddingHorizontal)};
      padding-right: ${theme.spacing(paddingHorizontal)};
    `}
`
