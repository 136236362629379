import React, { useEffect, useMemo, useState } from 'react'
import { BrowserTimeline } from 'components/BrowserTimeline'
import { PluginOrganizationWidePage } from 'components/PluginPage'
import {
  useBrowserTimelinePageNav,
  useParamTestRunId,
} from './BrowserTimelinePage.hooks'
import { Alert, Select } from '@grafana/ui'
import { SelectableValue } from '@grafana/data'
import { useTest } from 'data/useTest'
import { useRun } from 'data/useRun'
import { TestMeta } from './TestMeta'
import { useTestRunSearchTraces } from 'data/useTestRunSearchTraces'
import { useTestRunTraceById } from 'data/useTestRunTraceById'
import { LoadingContainer } from 'components/LoadingContainer'
import { isTestDone } from 'utils/testRun'

// Static trace is used when runId is not available
import { new_trace } from './new_trace'
import { tracesToOptions } from './BrowserTimelinePage.utils'

/** FEATURE GATE: browserTimeline */
export function BrowserTimelinePage() {
  const pageNav = useBrowserTimelinePageNav()
  const runId = useParamTestRunId()
  const [compact, setCompact] = useState(false)
  const [selectedOption, setSelectedOption] = useState<
    SelectableValue<string> | undefined
  >(undefined)
  const { data: testRun, isFetching: isFetchingRun } = useRun(runId)
  const isDone = !runId || (testRun && isTestDone(testRun))
  const { data: test, isFetching: isFetchingTest } = useTest(
    testRun?.test_id ?? 0
  )
  const {
    data: tempoResponse,
    isFetching: isFetchingTraces,
    error,
  } = useTestRunSearchTraces(isDone ? runId : undefined)

  const { data: batchData, isFetching: isFetchingTrace } = useTestRunTraceById(
    runId,
    selectedOption?.value
  )

  const options = useMemo(() => {
    const traces = tempoResponse?.traces ?? []
    return tracesToOptions(traces)
  }, [tempoResponse])

  useEffect(() => {
    const [first] = options
    if (first) {
      setSelectedOption(first)
    }
  }, [options])

  const handleCompactChange = () => {
    setCompact(!compact)
  }

  const isLoading = isFetchingRun || isFetchingTest
  const isPendingTraceLoad =
    isLoading || isFetchingTraces || isFetchingTrace || !isDone
  const hasTraces = options.length > 0

  const handleSelectTrace = (value: SelectableValue<string>) => {
    setSelectedOption(value)
  }

  if (error) {
    return <div>Unable to load data</div>
  }

  const hasData = testRun && test

  // Load demo data if test run is not available
  const timelineData = !runId ? new_trace : batchData

  return (
    <PluginOrganizationWidePage
      pageNav={pageNav}
      renderTitle={() => 'Browser Timeline'}
    >
      <LoadingContainer estimatedHeight={100} loading={isLoading}>
        {hasData && (
          <TestMeta
            testRun={testRun}
            compact={compact}
            onCompactClick={handleCompactChange}
          />
        )}
        {!isDone && <Alert security="info" title="Test is still running" />}
        {hasTraces && (
          <Select
            loadingMessage="Loading traces..."
            isLoading={isFetchingTraces}
            placeholder="Select trace"
            onChange={handleSelectTrace}
            options={options}
            value={selectedOption}
          ></Select>
        )}
        <BrowserTimeline
          data={timelineData}
          isLoading={isPendingTraceLoad}
          compact={compact}
        />
      </LoadingContainer>
    </PluginOrganizationWidePage>
  )
}
