import React from 'react'

import { Value } from './Value'
import { ProjectSettings } from 'types'
import { isVUhQuotaReached, isVUhUsageAboveThreshold } from 'utils/VUh'

interface VUhUsageValueProps {
  limit?: ProjectSettings['vuh_max_per_month']
  value?: number
}

export function VUhUsageValue({ value, limit }: VUhUsageValueProps) {
  const isAboveThreshold = isVUhUsageAboveThreshold(value, limit)
  const isQuotaReached = isVUhQuotaReached(value, limit)

  return (
    <Value
      showWarning={isAboveThreshold}
      tooltip={
        <>
          {isQuotaReached && (
            <>
              You{"'"}ve reached your VUh quota this month.
              <br />
            </>
          )}
          {!isQuotaReached && isAboveThreshold && (
            <>
              You{"'"}ve used {'>'}90% of your VUh quota this month.
              <br />
            </>
          )}
          Virtual User hours may fluctuate if a running test is stopped by the
          user or if it is aborted by the system.
        </>
      }
    >
      {value ?? 0}
    </Value>
  )
}
