import styled from 'styled-components'
import { spacing } from 'utils/styled'

export const TrendMetaContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const TagsMetaContainer = styled.div`
  margin-left: ${spacing(1)};
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: ${({ theme }) => theme.typography.size.sm};
`
