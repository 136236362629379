import React from 'react'
import styled from 'styled-components'
import { color } from 'utils/styled'
import { ErrorResponse } from './types'

const Message = styled.div`
  text-align: center;
  color: ${color('error', 'text')};
`

interface ExportErrorMessageProps {
  error: ErrorResponse | undefined
}

export function ExportErrorMessage({ error }: ExportErrorMessageProps) {
  if (error === undefined) {
    return null
  }

  switch (error.status) {
    case 'name-exists':
      return (
        <Message>
          A dashboard with this name already exists in the chosen folder.
        </Message>
      )

    case 'insufficient-permissions':
      if (error.folder.type === 'new') {
        return (
          <Message>
            You {"don't"} have permission to create new folders.
          </Message>
        )
      }

      return (
        <Message>
          You {"don't"} have permission to create dashboards in this folder.
        </Message>
      )

    default:
      return <Message>Something went wrong. Please try again.</Message>
  }
}
