import React, { MouseEvent } from 'react'
import { first } from 'lodash-es'
import { Link } from 'react-router-dom'

import { Checkbox } from '@grafana/ui'

import { Test, TestWithTrends } from 'types'
import { ProjectPageTestIds } from 'types/dataTestIds'
import { Tooltip } from 'components/Tooltip'
import { LatestRunDetails } from '../LatestRunDetails'
import { useHasUserProjectWriteAccess } from 'data/usePermissions'
import { TestMenu } from '../TestMenu'
import { routeMap } from 'routeMap'
import { Card, SelectionOverlay, TrendMetaWrapper } from './TestCard.styles'
import { CardBody, CardHeader } from 'components/Card'
import { isTestActive } from 'utils/testRun'
import {
  isCalculatingTrendingValues,
  useTrendPolling,
} from 'data/useTestWithTrends'
import { StatusIcon } from 'components/StatusIcon'
import { TrendBarChart } from 'components/TrendBarChart/TrendBarChart'
import { TrendMeta } from 'components/TrendChart'

interface TestCardProps {
  test: TestWithTrends
  selected: boolean
  showSelection: boolean
  onSelectTest: (test: Test, shiftKey?: boolean) => void
  onDeleteTest: (test: Test) => void
  index: number // used for popper to get a new ref when order is changed
}

export const TestCard = ({
  test,
  selected,
  showSelection,
  onSelectTest,
  onDeleteTest,
  index,
}: TestCardProps) => {
  const hasUserProjectWriteAccess = useHasUserProjectWriteAccess()

  const { data: trendingTest } = useTrendPolling(test)
  const latestTest = trendingTest ?? test

  const latestTestRun = first(latestTest.test_runs)
  const isCalculating = isCalculatingTrendingValues(latestTest)
  const isActive = latestTestRun !== undefined && isTestActive(latestTestRun)

  const handleSelectTest = (ev: MouseEvent<HTMLDivElement>) => {
    !isActive && onSelectTest(latestTest, ev.shiftKey)
  }

  const [trend] = latestTest.trends

  return (
    <Card data-testid={ProjectPageTestIds.Card} key={index}>
      <CardHeader>
        <StatusIcon testRun={latestTestRun} size="lg" />
        <h5>
          <Link to={routeMap.test(latestTest.id)}>{latestTest.name}</Link>
        </h5>

        {hasUserProjectWriteAccess &&
          (!showSelection ? (
            <TestMenu
              test={latestTest}
              lastRun={latestTestRun}
              onDeleteTest={onDeleteTest}
              onSelectTest={onSelectTest}
            />
          ) : (
            <Checkbox checked={selected} readOnly />
          ))}
      </CardHeader>

      <CardBody>
        <LatestRunDetails testRun={first(latestTest.test_runs)} />
        <TrendBarChart
          variant="minimal"
          trend={trend}
          calculating={isCalculating}
        />
        <TrendMetaWrapper>
          {!!trend && <TrendMeta trend={trend} />}
        </TrendMetaWrapper>
      </CardBody>

      {showSelection && (
        <Tooltip
          show={isActive}
          content="Cannot select a test that is currently running."
          placement="top"
        >
          <SelectionOverlay
            selected={selected}
            disabled={isActive}
            onClick={handleSelectTest}
          />
        </Tooltip>
      )}
    </Card>
  )
}
