import React from 'react'
import { Select, SelectCommonProps } from '@grafana/ui'

import { MenuList } from './MenuList'
import {
  SelectInfiniteContext,
  useSelectInfiniteContext,
  SelectInfiniteContextType,
} from './SelectInfiniteContext'

export type SelectInfiniteDropdownDirection = 'right' | 'left'

interface SelectInfiniteProps<T> extends SelectCommonProps<T> {
  isLoading: boolean
  loadNext: () => void
  dropdownDirection?: SelectInfiniteDropdownDirection
}

export const SelectInfinite = <T,>({
  isLoading,
  loadNext,
  options,
  ...props
}: SelectInfiniteProps<T>) => {
  const context = {
    isLoading,
    loadNext,
    options,
  } as SelectInfiniteContextType<T>

  return (
    <SelectInfiniteContext.Provider value={context}>
      <SelectInner {...props} />
    </SelectInfiniteContext.Provider>
  )
}

export const SelectInner = <T,>(props: SelectCommonProps<T>) => {
  const { options, isLoading } = useSelectInfiniteContext<T>()

  return (
    <Select
      options={options}
      isLoading={isLoading}
      {...props}
      components={{ MenuList, ...props.components }}
    />
  )
}
