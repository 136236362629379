import React, { ReactNode } from 'react'
import { Alert, Button } from '@grafana/ui'
import { useIsGrafanaEditor } from 'hooks/useGrafanaRBAC'

export function StartTesting({
  children,
  onInitialize,
}: {
  children: ReactNode
  onInitialize: () => void
}) {
  const isGrafanaEditor = useIsGrafanaEditor()

  if (!isGrafanaEditor) {
    return (
      <>
        <Alert title="" severity="warning">
          Initialization of the plugin requires either Admin or Editor role.
        </Alert>
        <Button disabled>{children}</Button>
      </>
    )
  }

  return (
    <Button size="lg" icon="rocket" onClick={onInitialize}>
      {children}
    </Button>
  )
}
