import { type InsightScoreStatus } from 'types/cloudInsights'

enum ScoreIcon {
  good = 'check-circle',
  poor = 'times-circle',
  'needs-improvement' = 'exclamation-circle',
  skipped = 'question-circle',
}

export const getScoreIcon = (status: InsightScoreStatus) => {
  return ScoreIcon[status] || ScoreIcon.skipped
}
