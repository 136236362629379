import React from 'react'
import { LinkButton } from '@grafana/ui'

const GOOGLE_FORM_URL = 'https://forms.gle/6bkkMFb9JBnuTLzs5'

/* eslint-disable react/jsx-no-target-blank */
export function BrowserFeedbackButton() {
  return (
    <LinkButton
      target="_blank"
      fill="text"
      icon="envelope"
      variant="primary"
      rel="noopener"
      href={GOOGLE_FORM_URL}
      tooltip={
        <>
          Browser test is a <strong>new feature</strong>, let us know how we can
          improve.
        </>
      }
    >
      Share feedback
    </LinkButton>
  )
}
