import React, { useMemo } from 'react'
import { type AbsoluteTimeRange } from '@grafana/data'

import { Scenario, SortOptions, TestRunAnalysis } from 'types'
import { isDefined } from 'utils/typescript'
import { ProtocolType } from '../RunOverview.types'
import {
  BreakdownTable,
  ListTableDefinition,
} from '../../Breakdown/BreakdownTable'
import {
  ScenarioClient,
  useScenarioClient,
} from 'data/clients/entities/scenarios'
import { toScenariosQueryKey } from 'data/queryKeys'

import { useTimeRange } from '../../../TimeRangeContext'
import { ListColumn } from '../../Breakdown/BreakdownTable/types'
import {
  asCount,
  asRequestRate,
  asTiming,
  asWebVital,
} from '../../Breakdown/ComparedValue'
import { TooltipCell } from '../../Breakdown/BreakdownTable/Table/TooltipCell'
import { useSortBy } from 'hooks/useSortBy'
import { ScenarioChart } from './ScenarioChart'
import { TableSettingsContext } from '../../Breakdown/BreakdownTable/TableSettingsContext'
import { filterScenariosByProtocol } from './Scenarios.utils'
import { getWebVitalScore } from 'components/WebVitalGauge'
import { RunsPageTestIds } from 'types/dataTestIds'

type ScenarioColumns = Array<ListColumn<Scenario>>

const httpColumns: ScenarioColumns = [
  {
    id: 'name',
    name: 'Name',
    sortBy: 'name',
    toggle: 'none',

    renderCell: (scenario) => (
      <TooltipCell tooltip={scenario.name}>{scenario.name}</TooltipCell>
    ),
  },
  {
    id: 'request-count',
    name: 'Request count',
    sortBy: 'http_metric_summary/requests_count',

    renderCell: asCount(
      ({ http_metric_summary }) => http_metric_summary.requests_count
    ),
  },
  {
    id: 'failed-requests',
    name: 'Failed requests',
    sortBy: 'http_metric_summary/failures_count',

    renderCell: asCount(
      ({ http_metric_summary }) => http_metric_summary.failures_count
    ),
  },
  {
    id: 'p95-response-time',
    name: 'P95 response time',
    sortBy: 'http_metric_summary/duration/p95',

    renderCell: asTiming(
      ({ http_metric_summary }) => http_metric_summary.duration.p95
    ),
  },
  {
    id: 'avg-rps',
    name: 'Avg RPS',
    sortBy: 'http_metric_summary/rps_mean',

    renderCell: asRequestRate(
      ({ http_metric_summary }) => http_metric_summary.rps_mean
    ),
  },
]

const grpcColumns: ScenarioColumns = [
  {
    id: 'name',
    name: 'Name',
    sortBy: 'name',
    toggle: 'none',

    renderCell: (scenario) => (
      <TooltipCell tooltip={scenario.name}>{scenario.name}</TooltipCell>
    ),
  },
  {
    id: 'request-count',
    name: 'Request count',
    sortBy: 'grpc_metric_summary/requests_count',

    renderCell: asCount(
      ({ grpc_metric_summary }) => grpc_metric_summary.requests_count
    ),
  },
  {
    id: 'p95-response-time',
    name: 'P95 response time',
    sortBy: 'grpc_metric_summary/duration/p95',

    renderCell: asTiming(
      ({ grpc_metric_summary }) => grpc_metric_summary.duration.p95
    ),
  },
  {
    id: 'avg-rps',
    name: 'Avg RPS',
    sortBy: 'grpc_metric_summary/rps_mean',

    renderCell: asRequestRate(
      ({ grpc_metric_summary }) => grpc_metric_summary.rps_mean
    ),
  },
]

const wsColumns: ScenarioColumns = [
  {
    id: 'name',
    name: 'Name',
    sortBy: 'name',
    toggle: 'none',

    renderCell: (scenario) => (
      <TooltipCell tooltip={scenario.name}>{scenario.name}</TooltipCell>
    ),
  },
  {
    id: 'session-count',
    name: 'Session count',
    sortBy: 'ws_metric_summary/sessions',

    renderCell: asCount(({ ws_metric_summary }) => ws_metric_summary.sessions),
  },
  {
    id: 'p95-connecting-time',
    name: 'P95 connecting time',
    sortBy: 'ws_metric_summary/connecting/p95',

    renderCell: asTiming(
      ({ ws_metric_summary }) => ws_metric_summary.connecting.p95
    ),
  },
  {
    id: 'p95-session-duration',
    name: 'P95 session duration',
    sortBy: 'ws_metric_summary/session_duration/p95',

    renderCell: asTiming(
      ({ ws_metric_summary }) => ws_metric_summary.session_duration.p95
    ),
  },
]

const browserColumns: ScenarioColumns = [
  {
    id: 'name',
    name: 'Name',
    sortBy: 'name',
    toggle: 'none',

    renderCell: (scenario) => {
      return <TooltipCell tooltip={scenario.name}>{scenario.name}</TooltipCell>
    },
  },
  {
    id: 'lcp',
    name: 'LCP',
    // Caution: The backend is unable to sort on 'browser_metric_summary/*'
    //sortBy: 'browser_metric_summary/web_vital_lcp_p75',
    renderCell: asWebVital('lcp'),
  },
  {
    id: 'fid',
    name: 'FID',
    // Caution: The backend is unable to sort on 'browser_metric_summary/*'
    //sortBy: 'browser_metric_summary/web_vital_fid_p75',
    renderCell: asWebVital('fid'),
  },
  {
    id: 'cls',
    name: 'CLS',
    // Caution: The backend is unable to sort on 'browser_metric_summary/*'
    //sortBy: 'browser_metric_summary/web_vital_cls_p75',
    renderCell: asWebVital('cls'),
  },
  {
    id: 'fcp',
    name: 'FCP',
    // Caution: The backend is unable to sort on 'browser_metric_summary/*'
    //sortBy: 'browser_metric_summary/web_vital_fcp_p75',
    renderCell: asWebVital('fcp'),
  },
  {
    id: 'inp',
    name: 'INP',
    // Caution: The backend is unable to sort on 'browser_metric_summary/*'
    //sortBy: 'browser_metric_summary/web_vital_inp_p75',
    renderCell: asWebVital('inp'),
  },
  {
    id: 'ttfb',
    name: 'TTFB',
    // Caution: The backend is unable to sort on 'browser_metric_summary/*'
    //sortBy: 'browser_metric_summary/web_vital_ttfb_p75',
    renderCell: asWebVital('ttfb'),
  },
]

const httpSortFields = httpColumns
  .map((column) => column.sortBy)
  .filter(isDefined)
const grpcSortFields = grpcColumns
  .map((column) => column.sortBy)
  .filter(isDefined)
const wsSortFields = wsColumns.map((column) => column.sortBy).filter(isDefined)
const browserSortFields = browserColumns
  .map((column) => column.sortBy)
  .filter(isDefined)

interface ScenarioListTableProps {
  client: ScenarioClient
  protocol: ProtocolType
  timeRange?: AbsoluteTimeRange
}

const protocolColumns: Record<ProtocolType, ScenarioColumns> = {
  http: httpColumns,
  ws: wsColumns,
  grpc: grpcColumns,
  browser: browserColumns,
}

function getScenarioTable(
  protocol: ProtocolType
): ListTableDefinition<Scenario, ScenarioListTableProps> {
  return {
    id: `scenarios-table-${protocol}`,
    type: 'list',

    paginator: 'never',
    pageSize: 1000,
    getRowStatus:
      protocol === 'browser'
        ? (row) => {
            const metrics = row.browser_metric_summary
            const {
              web_vital_lcp_p75,
              web_vital_cls_p75,
              web_vital_fid_p75,
              web_vital_fcp_p75,
              web_vital_inp_p75,
              web_vital_ttfb_p75,
            } = metrics
            const scoreList = [
              getWebVitalScore('lcp', web_vital_lcp_p75),
              getWebVitalScore('cls', web_vital_cls_p75),
              getWebVitalScore('fid', web_vital_fid_p75),
              getWebVitalScore('fcp', web_vital_fcp_p75),
              getWebVitalScore('inp', web_vital_inp_p75),
              getWebVitalScore('ttfb', web_vital_ttfb_p75),
            ]

            if (scoreList.some((score) => score === 'poor')) {
              return 'error'
            }

            if (scoreList.some((score) => score === 'needs_improvement')) {
              return 'warning'
            }

            return 'success'
          }
        : undefined,
    keyBy(scenario) {
      return scenario.id
    },

    fetchPage({ client, testRun, sortBy, timeRange }) {
      return {
        queryKey: toScenariosQueryKey(testRun.id, { sortBy, timeRange }),
        queryFn() {
          return client.fetchAll(testRun.id, sortBy, timeRange)
        },
        select(data) {
          return {
            ...data,
            items: filterScenariosByProtocol(data.items, protocol),
          }
        },
      }
    },

    fetchByRows({ client, testRun, rows }) {
      const ids = rows.map((row) => row.id).sort()

      return {
        queryKey: toScenariosQueryKey(testRun.id, { ids }),
        queryFn() {
          return client.fetchByIds(testRun.id, ids)
        },
        select(data) {
          return {
            ...data,
            items: filterScenariosByProtocol(data.items, protocol),
          }
        },
      }
    },

    columns: protocolColumns[protocol],
    RowBodyComponent: ({ value, analysis, protocol }) => (
      <ScenarioChart protocol={protocol} analysis={analysis} scenario={value} />
    ),
  }
}

type ProtocolSortOptions = {
  [P in ProtocolType]: SortOptions<Scenario> | undefined
}

export interface ScenarioTableProps {
  protocol: ProtocolType
  analysis: TestRunAnalysis
}

export const ScenarioTable = ({ protocol, analysis }: ScenarioTableProps) => {
  const { timeRange } = useTimeRange()
  const client = useScenarioClient()
  const [httpSortBy, setHttpSortBy] = useSortBy<Scenario>('sh', httpSortFields)
  const [grpcSortBy, setGrpcSortBy] = useSortBy<Scenario>('sg', grpcSortFields)
  const [wsSortBy, setWsSortBy] = useSortBy<Scenario>('sw', wsSortFields)
  const [browserSortBy, setBrowserSortBy] = useSortBy<Scenario>(
    'sb',
    browserSortFields
  )
  const params = useMemo(
    () => ({ client, protocol, timeRange }),
    [client, protocol, timeRange]
  )

  const sortBy: ProtocolSortOptions = {
    http: httpSortBy,
    grpc: grpcSortBy,
    ws: wsSortBy,
    browser: browserSortBy,
  }

  const handleSortChange = (sortBy: SortOptions<Scenario> | undefined) => {
    switch (protocol) {
      case 'http':
        return setHttpSortBy('replace', sortBy)

      case 'grpc':
        return setGrpcSortBy('replace', sortBy)

      case 'ws':
        return setWsSortBy('replace', sortBy)

      case 'browser':
        return setBrowserSortBy('replace', sortBy)
    }
  }

  const table = useMemo(() => getScenarioTable(protocol), [protocol])

  return (
    <TableSettingsContext table={table}>
      <BreakdownTable
        dataTestId={RunsPageTestIds.ScenariosTable}
        view="list"
        analysis={analysis}
        params={params}
        table={table}
        sortBy={sortBy[protocol]}
        onSort={handleSortChange}
      />
    </TableSettingsContext>
  )
}
