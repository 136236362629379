import React, { useMemo } from 'react'
import { AbsoluteTimeRange } from '@grafana/data'
import { Dropdown, IconButton, Menu } from '@grafana/ui'
import { parseISO } from 'date-fns'

import type { TestRun } from 'types'
import type { LogPanelConfig, TimeSeriesPanelConfig } from 'types/panels'
import { showAlert } from 'utils/showAlert'
import { isTestFailedWithoutData } from 'utils/testRun'
import { copyToClipboard } from 'utils/clipboard'
import { LogsConfig, MetricConfig } from 'datasource/models'
import { useDatasource } from 'datasourceContext'
import { useTrackFeature } from 'hooks/useTrackFeature'

import { toPanelConfig } from './Chart.utils'

const GRAFANA_PANEL_COPY_HACK_KEY = 'panel-copy'

interface TestRunPanelConfig {
  testRun: TestRun
  config: TimeSeriesPanelConfig | LogPanelConfig
}

const getTestRunRange = (testRun: Pick<TestRun, 'started' | 'ended'>) => {
  const { started, ended } = testRun

  if (!started || !ended) {
    return undefined
  }

  return {
    from: parseISO(started).getTime().toString(),
    to: parseISO(ended).getTime().toString(),
  }
}

const getTimeRange = (timeRange: AbsoluteTimeRange) => {
  return {
    from: new Date(timeRange.from).getTime().toString(),
    to: new Date(timeRange.to).getTime().toString(),
  }
}

export const toExploreHref = (
  left: TestRunPanelConfig,
  right: TestRunPanelConfig | undefined,
  timeRange?: AbsoluteTimeRange
) => {
  const search = new URLSearchParams({
    left: JSON.stringify({
      datasource: left.config.datasource?.uid,
      queries: left.config.targets,
      range: timeRange
        ? getTimeRange(timeRange)
        : getTestRunRange(left.testRun),
    }),
    ...(right && {
      right: JSON.stringify({
        datasource: right.config.datasource?.uid,
        queries: right.config.targets,
        range: timeRange
          ? getTimeRange(timeRange)
          : getTestRunRange(right.testRun),
      }),
    }),
  })

  return `/explore?${search}`
}

interface ChartMenuOverlayProps {
  leftPanel: TestRunPanelConfig
  rightPanel: TestRunPanelConfig | undefined
  timeRange?: AbsoluteTimeRange
}

const ChartMenuOverlay = ({
  leftPanel,
  rightPanel,
  timeRange,
}: ChartMenuOverlayProps) => {
  const trackFeature = useTrackFeature()

  const exploreHref = useMemo(
    () => toExploreHref(leftPanel, rightPanel, timeRange),
    [leftPanel, rightPanel, timeRange]
  )

  const handleCopy = () => {
    const config = JSON.stringify(leftPanel.config)

    copyToClipboard(config)
    window.localStorage[GRAFANA_PANEL_COPY_HACK_KEY] = config

    showAlert('Copied to Clipboard!', 'success')
    trackFeature('copy_to_clipboard')
  }

  return (
    <Menu>
      <Menu.Item
        label="Explore"
        disabled={isTestFailedWithoutData(leftPanel.testRun)}
        icon="compass"
        url={exploreHref}
      />
      {rightPanel === undefined && (
        <Menu.Item
          label="Copy to Clipboard"
          disabled={isTestFailedWithoutData(leftPanel.testRun)}
          icon="clipboard-alt"
          onClick={handleCopy}
        />
      )}
    </Menu>
  )
}

interface ChartProps {
  title: string
  testRun: TestRun
  compareWith: TestRun | undefined
  metrics: MetricConfig[] | LogsConfig[]
  timeRange?: AbsoluteTimeRange
}

export const ChartMenu = ({
  title,
  testRun,
  compareWith,
  metrics,
  timeRange,
}: ChartProps) => {
  const { ds } = useDatasource()

  const leftPanel = useMemo(() => {
    return {
      testRun,
      config: toPanelConfig(ds, title, testRun, metrics),
    }
  }, [ds, title, testRun, metrics])

  const rightPanel = useMemo(() => {
    return (
      compareWith && {
        testRun: compareWith,
        config: toPanelConfig(ds, title, compareWith, metrics),
      }
    )
  }, [ds, title, compareWith, metrics])

  return (
    <Dropdown
      overlay={
        <ChartMenuOverlay
          leftPanel={leftPanel}
          rightPanel={rightPanel}
          timeRange={timeRange}
        />
      }
      placement="bottom-end"
    >
      <IconButton aria-label="Open chart menu" name="ellipsis-v" />
    </Dropdown>
  )
}
