import React from 'react'

import { LoadZoneDistribution } from 'types/loadZone'
import { Flag } from 'components/Flag'
import { ShieldIcon } from 'components/ShieldIcon'

import { DeletedItem, FlagWrapper, Item, Label } from './LoadZoneLabel.styles'

export type LoadZoneTooltipProps = {
  loadZones: LoadZoneDistribution[]
}

interface LoadZoneLabelProps {
  className?: string
  loadZone: LoadZoneDistribution
  showLabel?: boolean
  showPercentage?: boolean
}

export const LoadZoneLabel = ({
  loadZone,
  className,
  showLabel = false,
  showPercentage = false,
}: LoadZoneLabelProps) => {
  const { city, country, loadPercent, name, isPublic } = loadZone

  const deletedLabel = !isPublic && !name ? 'deleted' : undefined

  return (
    <Label className={className}>
      <Item>
        {country ? (
          <FlagWrapper>
            <Flag name={country.toLowerCase()} />
          </FlagWrapper>
        ) : !isPublic ? (
          <ShieldIcon aria-hidden />
        ) : null}
      </Item>
      {showLabel && (
        <>
          {deletedLabel ? (
            <DeletedItem>{deletedLabel}</DeletedItem>
          ) : (
            <Item>{!isPublic ? name : city}</Item>
          )}
        </>
      )}
      {showPercentage && loadPercent !== 100 && <Item>{loadPercent}%</Item>}
    </Label>
  )
}
