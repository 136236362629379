import { NavModelItem } from '@grafana/data'
import { routeMap } from 'routeMap'
import { Project, Test, TestRun } from 'types'
import { formatDate, resolveTimeZone } from 'utils/date'

const LOADING_BREADCRUMB: NavModelItem = {
  id: 'loading',
  text: 'Loading...',
}

function createNavModel(
  base: NavModelItem,
  items: NavModelItem[] | undefined = [LOADING_BREADCRUMB]
): NavModelItem {
  // Only display the first occurrence of the loading breadcrumbs,
  // so that we don't end up with a long list of them, i.e.
  // Projects > Loading... > Loading... > Loading... > Loading...
  if (base.id === 'loading') {
    return base
  }

  return items.reduce((acc, item) => {
    return {
      ...item,
      parentItem: acc,
    }
  }, base)
}

export function createProjectListNavModel(): NavModelItem {
  return { text: 'Projects', url: routeMap.projectsList }
}

export function createProjectPageNavModel(
  project: Project | undefined
): NavModelItem {
  const projects = createProjectListNavModel()

  return createNavModel(
    projects,
    project && [
      {
        text: project.name,
        url: routeMap.project(project.id),
      },
    ]
  )
}

export function createTestPageNavModel(
  project: Project | undefined,
  test: Test | undefined
): NavModelItem {
  const projectBreadcrumbs = createProjectPageNavModel(project)

  return createNavModel(
    projectBreadcrumbs,
    test && [
      {
        text: test.name,
        description: 'Test overview',
        url: routeMap.test(test.id),
      },
    ]
  )
}

export function createNewTestPageNavModel(
  project: Project | undefined
): NavModelItem {
  const projectBreadcrumbs = createProjectPageNavModel(project)

  return createNavModel(
    projectBreadcrumbs,
    project && [
      {
        text: 'New test',
        description: 'Create a new test',
        url: routeMap.newTest(project?.id),
      },
    ]
  )
}

export function createRecorderPageNavModel(): NavModelItem {
  const projectListBreadcrumbs = createProjectListNavModel()

  return createNavModel(projectListBreadcrumbs, [
    {
      text: 'Save your recorded test',
      url: routeMap.recorder,
    },
  ])
}

export function createTestRunPageNavModel(
  project: Project,
  test?: Test,
  testRun?: TestRun
): NavModelItem {
  const testBreadcrumbs = createTestPageNavModel(project, test)

  return createNavModel(
    testBreadcrumbs,
    testRun && [
      {
        text: formatDate(testRun.started || testRun.created, resolveTimeZone()),
        description: 'Test results',
        url: routeMap.testRun(testRun.id),
      },
    ]
  )
}

interface ComparePageNavModelParams {
  left?: TestRun
  right?: TestRun
  tab?: string
}

export function createComparePageNavModel(
  project: Project,
  test?: Test,
  { left, right, tab }: ComparePageNavModelParams = {}
): NavModelItem {
  const testBreadcrumbs = createTestPageNavModel(project, test)

  return createNavModel(
    testBreadcrumbs,
    left &&
      right && [
        {
          text: 'Comparing results',
          description: 'Comparing results',
          url: routeMap.testRunCompare({ left: left.id, right: right.id, tab }),
        },
      ]
  )
}

export function createLearnPageNavModel() {
  return { text: 'Learn', url: routeMap.learn }
}

export function createCLIGuideNavModel() {
  const learnBreadcrumbs = createLearnPageNavModel()

  return createNavModel(learnBreadcrumbs, [
    {
      text: 'Run a k6 Cloud test from your CLI',
      description: 'CLI guide',
      url: routeMap.cliGuide,
    },
  ])
}

export function createSettingsPageNavModel() {
  return { text: 'Settings', url: routeMap.settings }
}

export function createManagePageNavModel() {
  return { text: 'Manage', url: routeMap.manage }
}

export function createNewScriptTestPageNavModel(
  project: Project | undefined
): NavModelItem {
  const projectBreadcrumbs = createNewTestPageNavModel(project)

  return createNavModel(
    projectBreadcrumbs,
    project && [
      {
        text: 'Test script',
        url: routeMap.newTestScript(project?.id),
      },
    ]
  )
}

export function createNewBuilderTestPageNavModel(
  project: Project | undefined
): NavModelItem {
  const projectBreadcrumbs = createNewTestPageNavModel(project)

  return createNavModel(
    projectBreadcrumbs,
    project && [
      {
        text: 'Test Builder',
        url: routeMap.newBuilderTest(project?.id),
      },
    ]
  )
}

export function createEditTestPageNavModel(
  project: Project | undefined,
  test: Test | undefined
) {
  const testBreadcrumbs = createTestPageNavModel(project, test)

  return createNavModel(
    testBreadcrumbs,
    test && [
      {
        text: 'Edit test',
        description: 'Edit test',
        url: routeMap.editTest(test),
      },
    ]
  )
}
