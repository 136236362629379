import React, { useState } from 'react'
import { Button, Checkbox } from '@grafana/ui'

import { getRoute } from 'routeMap'
import { DocLink, docs } from 'components/DocLink'
import { Flex } from 'components/Flex'
import { LinkButton } from 'components/LinkButton'
import { ButtonsContainer, StyledAlert } from './VUhConsumption.styles'
import { useNoLimitsWarningState } from './NoLimitsWarning.hooks'

interface NoLimitsWarningProps {
  projectId: number
}

export function NoLimitsWarning({ projectId }: NoLimitsWarningProps) {
  const [isDismissForAllChecked, setIsDismissedForAllChecked] = useState(false)
  const { isVisible, onDismiss, onSnooze } = useNoLimitsWarningState(projectId)

  if (!isVisible) {
    return null
  }

  return (
    <StyledAlert
      title="Consider setting limits and quotas for this project"
      severity="info"
      elevated
    >
      Control your resources more effectively and avoid unexpected costs by
      setting limits and quotas. Learn more about this feature in the{' '}
      <DocLink article={docs.projects.limits}>
        project limits and quotas docs
      </DocLink>
      <ButtonsContainer gap={1} justify="space-between" wrap="wrap">
        <Flex gap={1}>
          <LinkButton
            to={getRoute('limits')}
            button={{
              variant: 'primary',
              fill: 'outline',
            }}
          >
            Manage limits and quotas
          </LinkButton>
          <Button variant="secondary" fill="outline" onClick={onSnooze}>
            Not now
          </Button>
        </Flex>
        <Flex align="center" gap={1}>
          <Checkbox
            value={isDismissForAllChecked}
            onChange={() => {
              setIsDismissedForAllChecked(!isDismissForAllChecked)
            }}
            label="Dismiss for all projects"
          />
          <Button
            variant="secondary"
            fill="outline"
            onClick={() => {
              onDismiss(isDismissForAllChecked)
            }}
          >
            Dismiss
          </Button>
        </Flex>
      </ButtonsContainer>
    </StyledAlert>
  )
}
