import React from 'react'
import { Tooltip } from '@grafana/ui'
import { getValueFormat } from '@grafana/data'

import { APM_EXPORT_STATUS_NAMES, APM_PROVIDERS } from 'constants/tests'
import { MetricsExport } from 'types'
import {
  ErrorMessage,
  ExportStatus,
  FieldLabel,
  FieldValue,
  StyledMetaItem,
  TooltipContent,
} from './APMMetaItem.styles'

interface APMMetaItemProps {
  data: MetricsExport
}

const formatter = getValueFormat('dateTimeAsSystem')
const formatDate = (date: string | null) =>
  date ? formatter(new Date(date).getTime()).text : '-'

export const APMMetaItem = ({ data }: APMMetaItemProps) => {
  const hasErrorDetail = !!data.error_detail_public

  const tooltipContent = (
    <TooltipContent>
      <FieldLabel>Status</FieldLabel>
      <FieldValue>
        <ExportStatus status={data.export_status}>
          {APM_EXPORT_STATUS_NAMES[data.export_status]}
        </ExportStatus>
      </FieldValue>

      <FieldLabel>Started</FieldLabel>
      <FieldValue>{formatDate(data.started)}</FieldValue>

      <FieldLabel>Last export</FieldLabel>
      <FieldValue>{formatDate(data.end_time_lock)}</FieldValue>

      <FieldLabel>Finished</FieldLabel>
      <FieldValue>{formatDate(data.finished)}</FieldValue>

      {hasErrorDetail && (
        <ErrorMessage>{data.error_detail_public}</ErrorMessage>
      )}
    </TooltipContent>
  )

  return (
    <Tooltip content={tooltipContent}>
      {/* This wrapper is a workaround for a positioning issue with Tooltip */}
      <span>
        <StyledMetaItem icon="cloud-upload" status={data.export_status}>
          {APM_PROVIDERS[data.provider] || data.provider}
        </StyledMetaItem>
      </span>
    </Tooltip>
  )
}
