import { useQuery } from '@tanstack/react-query'
import { useDatasource } from 'datasourceContext'
import { Check, TestRun } from 'types'
import { toChecksQueryKey } from './queryKeys'

type CheckName = Pick<Check, 'name'>

export function useCheckNames(testRun: TestRun) {
  const { ds } = useDatasource()

  return useQuery<CheckName[]>({
    queryKey: toChecksQueryKey(testRun.id, {
      select: 'name',
      ended: testRun.ended,
    }),
    queryFn: () =>
      ds
        .fetchChecks({ id: testRun.id, select: 'name', count: false })
        .then((res) => res.value ?? []),
  })
}
