import React from 'react'
import { Button, Tooltip } from '@grafana/ui'

import { ResourcePermission } from 'types/rbac'

function getName(item: ResourcePermission) {
  if (item.userId) {
    return item.userLogin
  }
  if (item.teamId) {
    return item.team
  }
  return item.builtInRole
}

interface RowActionsProps {
  assignment: ResourcePermission
  onDelete: () => void
}

export function RowActions({ assignment, onDelete }: RowActionsProps) {
  return assignment.isManaged ? (
    <Button
      size="sm"
      icon="times"
      variant="destructive"
      onClick={onDelete}
      aria-label={`Remove permission for ${getName(assignment)}`}
    />
  ) : (
    <Tooltip
      content={
        assignment.isInherited
          ? 'Inherited Permission'
          : 'Provisioned Permission'
      }
    >
      <Button size="sm" icon="lock" disabled />
    </Tooltip>
  )
}
