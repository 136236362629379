import React from 'react'
import { Icon } from '@grafana/ui'
import {
  DiffMessageContainer,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
} from './DiffMessage.styles'
import { TestRun } from 'types'

interface DiffMessageProps {
  left: TestRun
  right: TestRun
  changes: number | undefined
  hasExternals: boolean
}

export function DiffMessage({
  left,
  right,
  changes,
  hasExternals,
}: DiffMessageProps) {
  if (changes === undefined) {
    return (
      <DiffMessageContainer>
        <Icon name="fa fa-spinner" size="lg" /> Comparing scripts...
      </DiffMessageContainer>
    )
  }

  if (left.script === null || right.script === null) {
    return (
      <DiffMessageContainer>
        <InfoIcon size="lg" /> One of the test runs is missing a script and may
        be different from the other.
      </DiffMessageContainer>
    )
  }

  if (changes > 0) {
    return (
      <DiffMessageContainer>
        <WarningIcon size="lg" /> There are {changes} change(s) between the test
        runs. This may have impacted the test results.
      </DiffMessageContainer>
    )
  }

  if (hasExternals) {
    return (
      <DiffMessageContainer>
        <InfoIcon size="lg" /> There were no changes between the entry scripts,
        but it has external imports which may have changed.
      </DiffMessageContainer>
    )
  }

  return (
    <DiffMessageContainer>
      <SuccessIcon size="lg" /> There are no changes between the two scripts.
    </DiffMessageContainer>
  )
}
