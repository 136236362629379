import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import clsx from 'clsx'
import { Checkbox as GrafanaCheckbox, styleMixins } from '@grafana/ui'
import { Tooltip } from 'components/Tooltip'

export type CheckboxProps = React.ComponentProps<typeof GrafanaCheckbox> & {
  tooltip?: string
}

const checkboxSize = 2

const Wrapper = styled.div`
  position: relative;

  input.checkbox {
    display: block;
    width: ${({ theme }) => theme.spacing(checkboxSize)};
    height: ${({ theme }) => theme.spacing(checkboxSize)};
    appearance: none;
    background: ${({ theme }) => theme.components.input.background};
    border: 1px solid ${({ theme }) => theme.components.input.borderColor};
    border-radius: ${({ theme }) => theme.shape.borderRadius()};

    &:checked {
      border: none;
    }

    &:hover:not(:disabled) {
      cursor: pointer;
      border-color: ${({ theme }) => theme.components.input.borderHover};
    }

    &:disabled {
      background-color: ${({ theme }) =>
        theme.colors.action.disabledBackground};
      cursor: not-allowed;
    }

    &:focus,
    &:focus-visible {
      ${({ theme }) => styleMixins.focusCss(theme)};
    }

    &:focus:not(:focus-visible) {
      /* styleMixins.getMouseFocusStyles */
      outline: none;
      box-shadow: none;
    }
  }
`
type CheckmarkProps = {
  $checked: boolean
  $disabled?: boolean
  $hovered: boolean
}

const Checkmark = styled.div<CheckmarkProps>`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;

  ${({ $checked, $disabled, $hovered, theme }) =>
    $checked &&
    css`
      background: ${$disabled
        ? theme.colors.action.disabledBackground
        : $hovered
        ? theme.colors.primary.shade
        : theme.colors.primary.main};
      width: ${theme.spacing(checkboxSize)};
      height: ${theme.spacing(checkboxSize)};
      border-radius: ${theme.shape.borderRadius()};

      &::after {
        content: '';
        position: absolute;
        z-index: 2;
        left: 5px;
        top: 1px;
        width: 6px;
        height: 12px;
        border-style: solid;
        border-color: ${$disabled
          ? theme.colors.action.disabledText
          : theme.colors.primary.contrastText};
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        pointer-events: none;
      }
    `}
`

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox(props, ref) {
    const {
      checked,
      label,
      description,
      value,
      htmlValue,
      onChange,
      disabled,
      className,
      tooltip,
      ...inputProps
    } = props
    const [internalValue, setInternalValue] = useState<boolean>(
      Boolean(checked || value)
    )
    const [hovered, setHovered] = useState<boolean>(false)

    useEffect(() => {
      setInternalValue(Boolean(checked || value))
    }, [checked, value])

    const handleOnChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(e)
        }
      },
      [onChange]
    )

    if (tooltip) {
      return (
        <Wrapper>
          <Tooltip content={tooltip}>
            <input
              className={clsx('checkbox', className)}
              type="checkbox"
              checked={internalValue}
              disabled={disabled}
              onChange={handleOnChange}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              {...inputProps}
              ref={ref}
            />
          </Tooltip>
          <Checkmark
            $checked={internalValue}
            $disabled={disabled}
            $hovered={hovered}
          />
        </Wrapper>
      )
    }

    return <GrafanaCheckbox {...props} ref={ref} />
  }
)
