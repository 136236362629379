import styled from 'styled-components'

import { pageSectionShadow } from 'pages/InitPage/components/Section.styled'
import { color, spacing } from 'utils/styled'

export const TabbedNavContent = styled.div`
  display: flex;
  height: ${({ theme }) => `calc(100% + ${theme.spacing(3)})`};
  margin-top: ${spacing(-3)};
  border: 1px ${({ theme }) => theme.colors.border.weak} solid;
  padding: ${spacing(3)};
  background-color: ${color('background', 'secondary')};
  border-radius: ${({ theme }) => theme.shape.borderRadius(1)};
  border-start-end-radius: 0;
  border-start-start-radius: 0;
  border-top: 0;
  background-clip: padding-box;
  ${({ theme }) => theme.isDark && pageSectionShadow};

  .wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`
