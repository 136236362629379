import { useAccount } from './useAccount'
import type { TestRunStats } from 'types'
import { toUserRunStatsQueryKey } from './queryKeys'
import { useDatasource } from '../datasourceContext'
import { useQuery } from '@tanstack/react-query'

export function useUserTestRunStats(retryOnMount = true) {
  const { ds } = useDatasource()
  const { data: account = { user: { id: 0 } } } = useAccount()
  const { user } = account

  return useQuery<TestRunStats>({
    queryKey: toUserRunStatsQueryKey(user.id),
    queryFn: () => ds.fetchUserTestRunStats(user),
    enabled: user.id !== 0,
    retryOnMount,
  })
}
