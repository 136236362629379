import React from 'react'
import { Link } from 'react-router-dom'
import { routeMap } from 'routeMap'
import { ProjectWithStats, TestRunBase } from 'types'
import { useRun } from 'data/useRun'
import { Label, MostRecentRunContainer } from './ProjectStats.styles'
import { StatusIcon } from 'components/StatusIcon'
import { TimeSinceLastRun } from 'components/TimeSince'
import { Flex } from 'components/Flex'

interface MostRecentRunStatProps {
  showLabel?: boolean
  testRun?: ProjectWithStats['last_test_run']
}

export const MostRecentRunStat = ({
  testRun,
  showLabel = true,
}: MostRecentRunStatProps) => {
  const lastTestRunTime = testRun?.ended ?? testRun?.started ?? testRun?.created

  return (
    <MostRecentRunContainer>
      {showLabel && <Label>Most recent run</Label>}
      <Flex align="center" gap={0.5}>
        {testRun ? (
          <>
            <Status testRun={testRun} />
            <Link to={routeMap.testRun(testRun.id)}>
              {testRun.test_name}
            </Link>{' '}
            {lastTestRunTime && (
              <TimeSinceLastRun timestamp={lastTestRunTime} />
            )}
          </>
        ) : (
          '-'
        )}
      </Flex>
    </MostRecentRunContainer>
  )
}

const Status = ({ testRun }: { testRun: TestRunBase }) => {
  const { data: run } = useRun(testRun.id, {
    useErrorBoundary: false,
    alertOnError: false,
  })

  return <StatusIcon testRun={run} size="xs" />
}
