import React from 'react'
import styled from 'styled-components'

import { type TestRun } from 'types'
import { type InsightCategory } from 'types/cloudInsights'
import { isTestActive } from 'utils/testRun'
import { Flex } from 'components/Flex'

import { getAuditScoreCount } from './AuditStats.utils'
import { useCategoryAuditResults } from './AuditStats.hooks'

interface CategoryAuditStatsProps {
  category: InsightCategory
  testRun: TestRun
}

export const AuditStats = ({ category, testRun }: CategoryAuditStatsProps) => {
  const { data: auditResults = [], isLoading } = useCategoryAuditResults(
    category.id,
    testRun
  )

  const {
    good,
    'needs-improvement': needsImprovement,
    poor,
    skipped,
  } = getAuditScoreCount(auditResults)

  return (
    <Container>
      {isLoading && (
        <span>{isTestActive(testRun) ? 'analyzing...' : 'loading...'}</span>
      )}

      {!isLoading && (
        <Flex gap={0.5} align="center">
          <SmallText>{good} Good</SmallText>
          <SmallText>/</SmallText>
          <SmallText>{needsImprovement} Needs Improvement</SmallText>
          <SmallText>/</SmallText>
          <SmallText>{poor} Poor</SmallText>
          <SmallText>/</SmallText>
          <SmallText>{skipped} Skipped</SmallText>
        </Flex>
      )}
    </Container>
  )
}

const SmallText = styled.span`
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
`

const Container = styled.div`
  margin: 0;
  color: ${({ theme }) => theme.colors.text.secondary};
`
