import { useEffect, useMemo, useState } from 'react'

import { TestRun, TestRunId, TestRunStatus } from 'types'
import { useHasPrivateLoadZones } from 'hooks/useHasPrivateLoadZones'
import { useSessionStorage } from 'usehooks-ts'

export const PRIVATE_LOAD_ZONES_HANGING_THRESHOLD = 3 * 60000

export const usePrivateLoadZoneIsHanging = (testRun?: TestRun) => {
  const hasPrivateLoadZones = useHasPrivateLoadZones(testRun)

  const isInitializing = testRun?.run_status === TestRunStatus.INITIALIZING

  const [elapsedTime, setElapsedTime] = useState<number | undefined>()

  useEffect(() => {
    if (!testRun || !hasPrivateLoadZones || !isInitializing) {
      return
    }

    let interval: NodeJS.Timeout

    const calculatedElapsedTime = () => {
      const createdAt = new Date(testRun.created)
      const currentTime = new Date()
      const timeDifference = currentTime.getTime() - createdAt.getTime()
      setElapsedTime(timeDifference)
    }

    if (!elapsedTime) {
      calculatedElapsedTime()
      return
    }

    if (elapsedTime <= PRIVATE_LOAD_ZONES_HANGING_THRESHOLD) {
      interval = setTimeout(() => {
        calculatedElapsedTime()
      }, 1000)
    }

    return () => {
      clearInterval(interval)
    }
  }, [elapsedTime, hasPrivateLoadZones, isInitializing, testRun])

  const isTakingTooLong =
    elapsedTime && elapsedTime > PRIVATE_LOAD_ZONES_HANGING_THRESHOLD

  return isInitializing && hasPrivateLoadZones && isTakingTooLong
}

export const useDismissPendingState = (
  testRunId: TestRunId
): [boolean, () => void] => {
  const key = `k6.pending-state-dismissed.${testRunId}`
  const [dismissed, setDismissed] = useSessionStorage(key, false)

  return useMemo(
    () => [dismissed, () => setDismissed(true)],
    [dismissed, setDismissed]
  )
}
