import React from 'react'
import { Button as GUIButton, ButtonProps, Tooltip } from '@grafana/ui'

export function Button(props: ButtonProps) {
  if (props.disabled && props.tooltip) {
    const { tooltip, ...buttonProps } = props

    return (
      <Tooltip content={tooltip}>
        <span>
          <GUIButton {...buttonProps} />
        </span>
      </Tooltip>
    )
  }

  return <GUIButton {...props} />
}
