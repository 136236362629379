import React, { MouseEvent, useState } from 'react'
import styled, { css } from 'styled-components'
import { Button, ButtonProps } from '@grafana/ui'

import { useTimeout } from 'usehooks-ts'
import { copyToClipboard } from 'utils/clipboard'

interface ClipboardButtonProps extends ButtonProps {
  data: string
  actionText?: string
  activeText?: string
  hasAlert?: boolean
}

const ButtonStyled = styled(Button)<{ $active: boolean }>`
  ${({ $active }) =>
    $active &&
    css`
      svg {
        color: ${({ theme }) => theme.colors.success.text};
      }
    `}
`

export const ClipboardButton = ({
  actionText = `Copy to clipboard`,
  activeText = `Copied!`,
  data,
  hasAlert = true,
  size = 'md',
  fill = 'text',
  variant = 'secondary',
  children,
  ...props
}: ClipboardButtonProps) => {
  const [active, setActive] = useState(false)

  function handleCopy(ev: MouseEvent) {
    ev.preventDefault()
    ev.stopPropagation()

    copyToClipboard(data)

    hasAlert && setActive(true)
  }

  function handleRemoveAlert() {
    setActive(false)
  }

  useTimeout(handleRemoveAlert, active ? 3000 : null)

  return (
    <ButtonStyled
      {...props}
      aria-label={props['aria-label'] || `Copy`}
      icon={active ? 'check' : 'clipboard-alt'}
      onClick={handleCopy}
      size={size}
      variant={variant}
      fill={fill}
      $active={active}
      // set a key to force re-rendering of the tooltip due to positioning bug in grafana/ui
      tooltip={
        <div key={active ? `active` : `inactive`}>
          {active ? activeText : actionText}
        </div>
      }
    >
      {children}
    </ButtonStyled>
  )
}
