import styled from 'styled-components'
import { Icon } from '@grafana/ui'

import { IconWithPredefinedName } from 'types/icon'
import { color, spacing } from 'utils/styled'

export const WarningIcon: IconWithPredefinedName = styled(Icon).attrs({
  name: 'exclamation-triangle',
})`
  color: ${color('warning', 'main')};
`

export const InfoIcon: IconWithPredefinedName = styled(Icon).attrs({
  name: 'info-circle',
})`
  color: ${color('info', 'main')};
`

export const SuccessIcon: IconWithPredefinedName = styled(Icon).attrs({
  name: 'check-circle',
})`
  color: ${color('success', 'main')};
`

export const DiffMessageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(1)};
  padding: ${spacing(0, 1)};
`
