import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { Flex } from 'components/Flex'
import { Page } from './components/Page'
import { StartTesting } from './components/StartTesting'
import { Section } from './components/Section'
import { routeMap } from 'routeMap'
import { spacing } from 'utils/styled'

const COPY = {
  [routeMap.projectsList]: {
    title: 'Projects',
    preamble:
      'Organize your k6 tests in projects and grant the right access to your organization members.',
  },
  [routeMap.settings]: {
    title: 'Settings',
    preamble:
      'Set up API tokens to integrate performance testing into your CI/CD process.',
  },
  [routeMap.manage]: {
    title: 'Manage',
    preamble:
      'Manage and create testing schedules to automate testing and use SLOs as the pass/fail criteria.',
  },
  [routeMap.learn]: {
    title: 'Learn',
    preamble:
      'Explore the easy-to-use, well-documented JavaScript APIs and learn how to start tests from the CLI.',
  },
}

export function InitPageSimple({ onInitialize }: { onInitialize: () => void }) {
  const match = useRouteMatch({
    path: [
      routeMap.projectsList,
      routeMap.settings,
      routeMap.manage,
      routeMap.learn,
    ],
  })
  const { title, preamble } = COPY[match?.path ?? routeMap.projectsList]!

  return (
    <Page title={title}>
      <Section>
        <Flex direction="column" align="center" width="100%">
          <Heading>Start your Grafana Cloud k6 experience</Heading>
          <Preamble>{preamble}</Preamble>
          <StartTesting onInitialize={onInitialize}>Start testing</StartTesting>
        </Flex>
      </Section>
    </Page>
  )
}

const Heading = styled.h2`
  margin-bottom: ${spacing(2)};
`

const Preamble = styled.p`
  margin-bottom: ${spacing(3)};
`
