import { TableDefinition } from '../BreakdownTable'
import { BrowserUrls } from 'types/k6Browser'
import { byGroup, byRootGroup } from '../utils'
import { toBrowserUrlsWebVitalsQueryKey } from 'data/queryKeys'
import { BrowserUrlsClient } from 'data/clients/entities/browserUrls'
import { FilterExpression } from 'datasource/serialization'
import { AbsoluteTimeRange } from '@grafana/data'
import { renderRowBodyComponent } from './RowBodyComponent'
import { renderNoMetrics } from './NoMetrics'
import { columns } from './columns'
import { getRowStatus } from './BrowserTab.utils'

interface BrowserUrlsTableProps {
  client: BrowserUrlsClient
  filter: FilterExpression<BrowserUrls>
  timeRange: AbsoluteTimeRange | undefined
}

export type BrowserUrlsTableDefinition = TableDefinition<
  BrowserUrls,
  BrowserUrlsTableProps
>

export type BrowserUrlsTableColumn =
  BrowserUrlsTableDefinition['columns'][number]

export const BREAKDOWN_TABLE: BrowserUrlsTableDefinition = {
  id: 'browser-breakdown',
  paginator: 'always',
  pageSize: 20,

  keyBy(row) {
    return row.id
  },
  groupBy(row) {
    return row.group_id
  },
  getRowStatus(data) {
    return getRowStatus(data)
  },
  getRowCount({ browser_http_metric_summary }) {
    return browser_http_metric_summary.http_request_count ?? 0
  },
  fetchPage({ client, testRun, filter, ...rest }) {
    const params = {
      ...rest,
      filter: byRootGroup(filter),
    }
    return {
      queryKey: toBrowserUrlsWebVitalsQueryKey(testRun.id, params),
      queryFn: () => client.fetchPage(testRun.id, params),
    }
  },
  fetchByRows({ client, testRun, rows }) {
    const ids = rows.map((url) => url.id).sort()

    return {
      queryKey: toBrowserUrlsWebVitalsQueryKey(testRun.id, { ids }),
      queryFn: () => client.fetchByIds(testRun.id, ids),
    }
  },
  fetchByGroup({ client, testRun, group, filter, ...rest }) {
    const params = {
      ...rest,
      filter: byGroup(group, filter),
    }

    return {
      queryKey: toBrowserUrlsWebVitalsQueryKey(testRun.id, params),
      queryFn: (page) => client.fetchPage(testRun.id, { ...params, page }),
    }
  },
  RowBodyComponent({ analysis, value }) {
    return renderRowBodyComponent(analysis, value)
  },
  EmptyGroupComponent() {
    return renderNoMetrics()
  },
  columns,
}
