import React, { ReactNode } from 'react'
import { ViewType } from './BreakdownTable/types'
import { Switch } from 'components/Switch'
import { BreakdownTabHeader } from './BreakdownTabHeader'
import styled from 'styled-components'
import { spacing } from 'utils/styled'

const SwitchWrapper = styled.div`
  padding-top: ${spacing(1.5)};
`

interface BreakdownHeaderProps {
  view?: ViewType | false
  children: ReactNode
  onViewChange?: (view: ViewType) => void
}

export function BreakdownHeader({
  view,
  children,
  onViewChange,
}: BreakdownHeaderProps) {
  function handleViewToggle() {
    onViewChange?.(view === 'list' ? 'tree' : 'list')
  }

  return (
    <BreakdownTabHeader>
      <div>{children}</div>
      {view && onViewChange !== undefined && (
        <SwitchWrapper>
          <Switch
            aria-label="View as a tree?"
            value={view === 'tree'}
            transparent
            onLabel="TREE"
            offLabel="LIST"
            onChange={handleViewToggle}
          />
        </SwitchWrapper>
      )}
    </BreakdownTabHeader>
  )
}
