import React, { ReactNode } from 'react'
import { isFetchError } from '@grafana/runtime'
import { QueryErrorResetBoundary } from '@tanstack/react-query'
import { ErrorBoundary } from 'react-error-boundary'

import { ErrorBoundaryAlert } from 'components/ErrorBoundary'
import { isLockedDown } from 'data/queryClient'
import { useTrackAction } from 'services/tracking'

import {
  ErrorBoundaryLocalView,
  ErrorBoundaryType,
  TrackingEventName,
} from 'services/tracking.types'

export const DEFAULT_RETRY_BUTTON_TEXT = 'Retry Request'

interface QueryErrorBoundaryProps {
  children: ReactNode
  view: ErrorBoundaryLocalView // Used to track where the error occurred
  title?: string
  content?: ReactNode | string
  className?: string
}

export function QueryErrorBoundary({
  children,
  view,
  title,
  content,
  className,
}: QueryErrorBoundaryProps) {
  const trackAction = useTrackAction()

  const onError = (error: unknown) => {
    if (isLockedDown(error)) {
      throw error
    }

    trackAction({
      eventName: TrackingEventName.ErrorBoundary,
      type: ErrorBoundaryType.Local,
      isApiError: isFetchError(error),
      view,
    })
  }

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          onError={onError}
          fallbackRender={({ resetErrorBoundary }) => (
            <ErrorBoundaryAlert
              buttonText={DEFAULT_RETRY_BUTTON_TEXT}
              content={content}
              onClick={resetErrorBoundary}
              title={title}
              className={className}
            />
          )}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  )
}
