import styled from 'styled-components'
import { color, spacing } from 'utils/styled'

export const Card = styled.div`
  position: relative;
  background: ${color('background', 'secondary')};
  padding: ${spacing(2)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const CardHeader = styled.header`
  display: flex;
  align-items: center;
  gap: ${spacing(1)};
  margin-bottom: ${spacing(2)};

  h5 {
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    font-weight: 500;

    a {
      color: ${color('text', 'primary')};

      &:hover {
        color: ${color('text', 'maxContrast')};
      }
    }
  }

  button {
    margin-right: 0;
  }
`

export const CardBody = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
`
