import React from 'react'
import { UseQueryResult } from '@tanstack/react-query'

import { hasBrowserMetricSummary, TestRun } from 'types'
import { InsightsByStatus } from 'types/insights'
import { CollapsableSectionHeader } from 'components/CollapsableSection/CollapsableSectionHeader'
import { Tab } from 'components/Tabs/Tab'
import { TabsBar } from 'components/Tabs/TabsBar'

import { useInsightFilter } from '../Insights.hooks'
import { InsightsSummary } from './InsightsSummary'
import { InsightsIcon } from './InsightsIcon'

const getAllCount = (insights: InsightsByStatus) => {
  return (
    insights.PASSED.length +
    insights.WARNING.length +
    insights.FAILED.length +
    insights.SKIPPED.length +
    insights.CREATED.length +
    insights.NOT_APPLICABLE.length
  )
}

const getSkippedCount = (insights: InsightsByStatus) => {
  return (
    insights.SKIPPED.length +
    insights.CREATED.length +
    insights.NOT_APPLICABLE.length
  )
}

interface InsightsHeaderProps {
  testRun: TestRun
  insights: UseQueryResult<InsightsByStatus>
}

export const InsightsHeader = ({ testRun, insights }: InsightsHeaderProps) => {
  const [{ filter }, toHref] = useInsightFilter(insights.data)

  return (
    <CollapsableSectionHeader
      icon={<InsightsIcon />}
      heading="PERFORMANCE INSIGHTS"
      description={<InsightsSummary testRun={testRun} insights={insights} />}
      actions={
        insights.data && (
          <TabsBar>
            <Tab
              label="All"
              href={toHref('href', { open: true, filter: 'all' })}
              active={filter === 'all'}
              counter={getAllCount(insights.data)}
            />
            <Tab
              label="Passed"
              href={toHref('href', { open: true, filter: 'passed' })}
              active={filter === 'passed'}
              counter={insights.data.PASSED.length}
            />

            {hasBrowserMetricSummary(testRun.browser_metric_summary) && (
              <Tab
                label="Warning"
                href={toHref('href', {
                  open: true,
                  filter: 'warning',
                })}
                active={filter === 'warning'}
                counter={insights.data.WARNING.length}
              />
            )}

            <Tab
              label="Failed"
              href={toHref('href', { open: true, filter: 'failed' })}
              active={filter === 'failed'}
              counter={insights.data.FAILED.length}
            />
            <Tab
              label="Skipped"
              href={toHref('href', { open: true, filter: 'skipped' })}
              active={filter === 'skipped'}
              counter={getSkippedCount(insights.data)}
            />
          </TabsBar>
        )
      }
    />
  )
}
