import React from 'react'
import { ClipboardContent } from '../BreakdownTable/ClipboardContent'
import { TooltipCell } from '../BreakdownTable/Table/TooltipCell'

interface GRPCUrlCellProps {
  host: string
  name: string
}

export function GRPCUrlCell({ host, name }: GRPCUrlCellProps) {
  return (
    <TooltipCell
      tooltip={
        <ClipboardContent
          actionText="Copy url to clipboard"
          data={`${host}/${name}`}
        >
          {name}
        </ClipboardContent>
      }
    >
      {name}
    </TooltipCell>
  )
}
