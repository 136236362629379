import React from 'react'
import { TestRunAnalysis } from 'types'
import { QueryErrorBoundary } from 'components/ErrorBoundary'
import { BrowserBreakdownView } from './BrowserBreakdownView'
import { ErrorBoundaryLocalView } from 'services/tracking.types'

interface BrowserTabProps {
  analysis: TestRunAnalysis
}

function BrowserTabComponent({ analysis }: BrowserTabProps) {
  return <BrowserBreakdownView analysis={analysis} />
}

export function BrowserTab(props: BrowserTabProps) {
  return (
    <QueryErrorBoundary view={ErrorBoundaryLocalView.BrowserTab}>
      <BrowserTabComponent {...props} />
    </QueryErrorBoundary>
  )
}
