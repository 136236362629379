import React, { forwardRef } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { CustomControlProps, IconButton, SelectContainer } from '@grafana/ui'
import { SelectableValue } from '@grafana/data'

import { Test, TestRun } from 'types'
import { spacing } from 'utils/styled'
import { routeMap } from 'routeMap'
import { TestRunsSelect } from '../TestRunsSelect'

interface TestRunsNavProps {
  title?: string
  test: Test
  testRun: TestRun
}

export const TestRunsNav = ({ title, test, testRun }: TestRunsNavProps) => {
  const history = useHistory()

  const handleChange = ({ value: id }: SelectableValue<Test['id']>) => {
    if (id === undefined) {
      return
    }

    history.push(routeMap.testRun(Number(id)))
  }

  if (test.test_runs.length === 1) {
    return <>{title}</>
  }

  return (
    <TestRunsSelect
      testId={test.id}
      selected={[testRun]}
      onChange={(option) => handleChange(option)}
      tabSelectsValue={false}
      components={{
        SelectContainer: StyledSelectContainer,
      }}
      renderControl={forwardRef(function Control(
        { onClick, onBlur }: CustomControlProps<number>,
        ref
      ) {
        return (
          <SelectorWrapper
            ref={ref}
            tabIndex={-1}
            onClick={onClick}
            onBlur={onBlur}
          >
            <span>{title}</span>
            <IconButton
              onBlur={onBlur}
              name="angle-down"
              size="xl"
              aria-label="Navigate test runs"
              tooltip="Navigate test runs"
            />
          </SelectorWrapper>
        )
      })}
    />
  )
}

const SelectorWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  gap: ${spacing(1)};
  align-items: center;
  cursor: pointer;
`

const StyledSelectContainer = styled(SelectContainer)`
  border: none;
  background: none;
  font-size: unset;
  line-height: unset;
`
