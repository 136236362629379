import { useRouteMatch } from 'react-router-dom'
import { NavModelItem } from '@grafana/data'

import { getRoute } from 'routeMap'
import { useIsUserOrgAdmin } from 'data/usePermissions'
import { useSubscriptionRule } from 'data/useSubscriptions'

import { TabSuffixNew } from './TabSuffixNew'

export const useManagePageNav = () => {
  const isAuditTrail = useRouteMatch(getRoute('auditTrail'))
  const isSavedTests = useRouteMatch(getRoute('savedTestRuns'))
  const isScheduledTests = useRouteMatch(getRoute('scheduledTests'))
  const isUsageReports = useRouteMatch(getRoute('usageReports'))
  const isPrivateLoadZones = useRouteMatch(getRoute('loadZones'))
  const isStaticIps = useRouteMatch(getRoute('staticIps'))

  const staticIPsMax = useSubscriptionRule('organization.static_ips.max')
  const isUserOrgAdmin = useIsUserOrgAdmin()

  const pageNav: NavModelItem = {
    icon: 'sliders-v-alt',
    text: 'Manage',
    subTitle: 'Manage yours tests and data',
    hideFromBreadcrumbs: true,
    children: [
      {
        icon: 'calendar-alt',
        text: 'Scheduled tests',
        url: getRoute('scheduledTests'),
        active: !!isScheduledTests,
      },
      {
        icon: 'database',
        text: 'Audit trail',
        url: getRoute('auditTrail'),
        active: !!isAuditTrail,
        hideFromTabs: !isUserOrgAdmin,
      },
      {
        icon: 'gf-landscape',
        text: 'Usage reports',
        url: getRoute('usageReports'),
        active: !!isUsageReports,
      },
      {
        icon: 'map-marker',
        text: 'Load zones',
        url: getRoute('loadZones'),
        active: !!isPrivateLoadZones,
        tabSuffix: TabSuffixNew,
      },
      {
        icon: 'lock',
        text: 'Saved tests',
        url: getRoute('savedTestRuns'),
        active: !!isSavedTests,
      },
      {
        icon: 'cloud',
        text: 'Static IPs',
        url: getRoute('staticIps'),
        active: !!isStaticIps,
        hideFromTabs: !isUserOrgAdmin || staticIPsMax === 0,
      },
    ],
  }

  return pageNav
}
