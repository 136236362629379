import { useMemo } from 'react'

import { type TestRun } from 'types'
import {
  type InsightExecution,
  type InsightScoreStatus,
} from 'types/cloudInsights'
import { getScoreStatus, isInsightExecuting } from 'utils/insights'
import { useInsightsCategoriesResults } from 'data/useCloudInsights'

enum StatusPrecedence {
  skipped = 0,
  good = 1,
  'needs-improvement' = 2,
  poor = 3,
}

export const useCategoriesStatus = (
  testRun: TestRun,
  execution?: InsightExecution
) => {
  const { data: results = [], ...props } = useInsightsCategoriesResults(
    testRun,
    execution
  )

  const status = useMemo(() => {
    return results.reduce<InsightScoreStatus>((result, next) => {
      const status = getScoreStatus(next.score)

      if (StatusPrecedence[status] > StatusPrecedence[result]) {
        return status
      }

      return result
    }, 'skipped')
  }, [results])

  const isExecuting = useMemo(() => {
    return results.some((result) => isInsightExecuting(result))
  }, [results])

  return {
    ...props,
    status,
    isExecuting,
  }
}
