import { CSSProperties } from 'react'
import styled from 'styled-components'
import { isNumber } from 'lodash-es'

const containerName = 'grid'
interface GridCommonProps {
  columns?: number
}

export interface GridElementProps extends GridCommonProps {
  align?: CSSProperties['alignItems']
  gap?: number
  justify?: CSSProperties['justifyContent']
  height?: string | number
  width?: string | number
}

interface GridStyleProps extends Omit<GridElementProps, 'width' | 'height'> {
  $height?: string | number
  $width?: string | number
}

export const GridElement = styled.div<GridStyleProps>`
  container: ${containerName} / inline-size;
  display: grid;
  grid-template-columns: ${({ columns = 12 }) => `repeat(${columns}, 1fr)`};
  grid-template-rows: auto;
  gap: ${({ gap = 1, theme }) =>
    !gap || !isNumber(gap) ? gap : theme.spacing(gap)};
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
`

export interface GridColumnElementProps {
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
  xxl?: number
}

export const GridColumnElement = styled.div<GridColumnElementProps>`
  @container ${containerName} (min-width: ${({ theme }) =>
    theme.breakpoints.values.xs}px) {
    ${({ xs }) => xs && `grid-column: span ${xs}`};
  }

  @container ${containerName} (min-width: ${({ theme }) =>
    theme.breakpoints.values.sm}px) {
    ${({ sm }) => sm && `grid-column: span ${sm}`};
  }

  @container ${containerName} (min-width: ${({ theme }) =>
    theme.breakpoints.values.md}px) {
    ${({ md }) => md && `grid-column: span ${md}`};
  }

  @container ${containerName} (min-width: ${({ theme }) =>
    theme.breakpoints.values.lg}px) {
    ${({ lg }) => lg && `grid-column: span ${lg}`};
  }

  @container ${containerName} (min-width: ${({ theme }) =>
    theme.breakpoints.values.xl}px) {
    ${({ xl }) => xl && `grid-column: span ${xl}`};
  }

  @container ${containerName} (min-width: ${({ theme }) =>
    theme.breakpoints.values.xxl}px) {
    ${({ xxl }) => xxl && `grid-column: span ${xxl}`};
  }

  @supports not (container-type: inline-size) {
    @media (min-width: ${({ theme }) => theme.breakpoints.values.xs}px) {
      ${({ xs }) => xs && `grid-column: span ${xs}`};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      ${({ sm }) => sm && `grid-column: span ${sm}`};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
      ${({ md }) => md && `grid-column: span ${md}`};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
      ${({ lg }) => lg && `grid-column: span ${lg}`};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.values.xl}px) {
      ${({ xl }) => xl && `grid-column: span ${xl}`};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.values.xxl}px) {
      ${({ xxl }) => xxl && `grid-column: span ${xxl}`};
    }
  }
`
