import { useMutation, useQueryClient } from '@tanstack/react-query'

import { Account } from 'types'
import { K6DataSource } from 'datasource/datasource'
import { showAlert } from 'utils/showAlert'
import { useDatasource } from 'datasourceContext'
import { useAccount } from 'data/useAccount'
import { toAccountQueryKey } from 'data/queryKeys'

interface UseRegenerateApiToken {
  onSuccess?: (account: Account) => void
}

const mutation = (ds: K6DataSource, token?: string) => {
  if (!token) {
    return Promise.reject('token is required')
  }

  return ds.regenerateUserToken(token)
}

export const useRegenerateApiToken = ({
  onSuccess,
}: UseRegenerateApiToken = {}) => {
  const queryClient = useQueryClient()
  const { ds } = useDatasource()
  const { data: account } = useAccount()

  return useMutation({
    mutationFn: () => mutation(ds, account?.token.key),
    onSuccess: (token) => {
      if (!account) {
        return
      }

      const newAccount: Account = {
        ...account,
        token: {
          key: token,
        },
      }

      queryClient.setQueryData(toAccountQueryKey(ds.uid), newAccount)
      onSuccess?.(newAccount)
      showAlert('New user token generated!', 'success')
    },
    onError: () => {
      showAlert('Failed to generate new token.', 'error')
    },
  })
}
