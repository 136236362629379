import { useCallback, useState } from 'react'

import { ProjectId, ProjectRoleWithUser } from 'types'
import { ConfirmModalProps } from 'components/ConfirmModal'
import { useConfirmation } from 'hooks/useConfirmation'
import { useDeleteProjectRoles } from 'data/useProjectRoles'

export const useDeleteProjectRolesConfirmation = (
  projectId: ProjectId,
  onSuccess: () => void
): [Omit<ConfirmModalProps, 'title'>, (...args: any) => void] => {
  const { mutateAsync: deleteProjectRoles } = useDeleteProjectRoles(projectId)
  const [projectRoles, setProjectRoles] = useState<ProjectRoleWithUser[]>([])

  const handleDeleteProjectRoles = async () => {
    await deleteProjectRoles(projectRoles, {
      onSuccess,
    })
  }

  const [confirmModalProps, openModal] = useConfirmation(
    handleDeleteProjectRoles
  )

  const requestConfirmation = useCallback(
    (users: ProjectRoleWithUser[]) => {
      setProjectRoles(users)
      openModal()
    },
    [openModal]
  )

  return [
    {
      ...confirmModalProps,
      body: getBody(projectRoles),
      confirmText: getConfirmText(projectRoles),
    },
    requestConfirmation,
  ]
}

function getBody(projectRoles: ProjectRoleWithUser[]) {
  if (projectRoles.length > 1) {
    return `Are you sure you want to remove ${projectRoles.length} users?`
  }

  const role = projectRoles[0]

  if (role) {
    return `Are you sure you want to remove the user ${role.user_email}?`
  }

  return `No users selected`
}

function getConfirmText(projectRoles: ProjectRoleWithUser[]) {
  if (projectRoles.length > 1) {
    return `Remove ${projectRoles.length} users`
  }

  return `Remove user`
}
