import { MetricConfig, MetricData } from 'datasource/models'
import { useDatasource } from 'datasourceContext'
import { useStreamingQueries, useStreamingQuery } from 'hooks/useStreamingQuery'
import { TestRun, TestRunFilter } from 'types'
import {
  addExemplarsOffset,
  addMetricDataOffset,
  exemplarsToDF,
  testRunFiltersToTagQuery,
} from 'utils/traces'
import {
  toTracesAvailableKey,
  toTracesExemplarsKey,
  toTracesFilterOptionsKey,
  toTracesTimeSeriesKey,
} from './queryKeys'
import { formatMetrics, getStartTime } from 'components/Chart/Chart.hooks'
import { ColorPool } from 'utils/colorPool'
import { createTimeRange } from 'utils/date'
import { Exemplar } from 'types/traces'

export function useTracesTimeSeries({
  left,
  right,
  metrics,
  colorPool,
}: {
  left: TestRun
  right?: TestRun
  metrics: MetricConfig[]
  colorPool: ColorPool
}) {
  const { ds } = useDatasource()
  const leftStarted = getStartTime(left)

  return useStreamingQueries(left, {
    queries: formatMetrics(left, right, metrics, colorPool).map(
      ({ testRun, started, metric }) => {
        const range = createTimeRange(
          testRun.created || '0',
          testRun.ended || '0'
        )
        const offset = right ? started - leftStarted : 0

        return {
          queryKey: toTracesTimeSeriesKey(testRun.id, metric),
          queryFn: () => ds.fetchTracesTimeSeries(testRun.id, metric, range),
          select: (data: MetricData[]) => {
            // Prometheus API may return more than one series for a single metric
            // but required traces filters narrow it down to one series.
            if (data[0]) {
              return addMetricDataOffset(data[0], offset)
            }

            return {
              ...metric,
              data: {
                values: [],
              },
            }
          },
        }
      }
    ),
  })
}

export function useTracesExemplars({
  left,
  right,
  metric,
  enabled,
  colorPool,
}: {
  left: TestRun
  right?: TestRun
  metric: MetricConfig
  enabled: boolean
  colorPool: ColorPool
}) {
  const { ds } = useDatasource()
  const leftStarted = getStartTime(left)

  return useStreamingQueries(left, {
    queries: formatMetrics(left, right, [metric], colorPool).map(
      ({ testRun, started, metric }) => {
        const range = createTimeRange(
          testRun.created || '0',
          testRun.ended || '0'
        )

        const offset = right ? started - leftStarted : 0
        return {
          queryKey: toTracesExemplarsKey(testRun.id, metric),
          queryFn: () => ds.fetchTracesExemplars(testRun.id, metric, range),
          select: (data: Exemplar[] | undefined) => {
            if (!data) {
              return
            }

            return exemplarsToDF(addExemplarsOffset(data, offset))
          },

          useErrorBoundary: false,
          enabled,
        }
      }
    ),
  })
}

export function useTracesAvailable({
  run,
  enabled,
  filters = [],
}: {
  run: TestRun
  enabled?: boolean
  filters?: TestRunFilter[]
}) {
  const { ds } = useDatasource()
  const tags = testRunFiltersToTagQuery(filters).buildArray()

  return useStreamingQuery(run, {
    enabled,
    queryKey: toTracesAvailableKey(run.id, filters),
    queryFn: () => ds.fetchTracesAvailable(run.id, tags),
  })
}

export function useTracesFilterOptions({
  run,
  filters = [],
  enabled = true,
}: {
  run: TestRun
  filters?: TestRunFilter[]
  enabled?: boolean
}) {
  const { ds } = useDatasource()
  const tags = testRunFiltersToTagQuery(filters).buildArray()

  return useStreamingQuery(run, {
    enabled,
    queryKey: toTracesFilterOptionsKey(run.id, tags),
    queryFn: () =>
      ds.fetchTracesSummary({
        id: run.id,
        tags,
      }),
    select: (data) => data.summaries,
    keepPreviousData: true,
  })
}
