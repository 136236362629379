import React from 'react'
import { Icon } from '@grafana/ui'
import { ProjectWithStats } from 'types'
import { Tooltip } from 'components/Tooltip'
import { toPrecise } from 'utils/math'
import {
  Field,
  Label,
  StatsContainer,
  Unit,
  Value,
} from './ProjectStats.styles'
import { Flex } from 'components/Flex'
import { MostRecentRunStat } from './MostRecentRunStat'

interface ProjectStatsProps {
  stats?: ProjectWithStats
  period: string
}

const getSuccessRate = (stats?: ProjectWithStats) => {
  if (!stats) {
    return
  }

  const {
    test_runs_success_count,
    test_runs_fail_count,
    test_runs_timed_out_count,
  } = stats
  // Used instead of test_runs_started_count to prevent active from affecting the success rate
  const endedRunsCount =
    test_runs_success_count + test_runs_fail_count + test_runs_timed_out_count

  if (endedRunsCount === 0) {
    return
  }

  return toPrecise((test_runs_success_count / endedRunsCount) * 100, 2)
}

export const ProjectStats = ({ stats, period }: ProjectStatsProps) => {
  const successRate = getSuccessRate(stats)
  const { test_runs_started_count, tests_created_count, last_test_run } =
    stats ?? {}

  return (
    <StatsContainer>
      <h6>Project activity ({period})</h6>
      <Flex wrap="wrap" gap={1}>
        <Field>
          <Label>Tests created</Label>
          <Value>
            {tests_created_count ?? 0} <Unit>tests</Unit>
          </Value>
        </Field>

        <Field>
          <Label>Tests started</Label>
          <Value>
            {test_runs_started_count ?? 0} <Unit>test runs</Unit>
          </Value>
        </Field>

        <Field>
          <Label>
            Success rate
            <Tooltip
              content="Percentage of successful test runs"
              placement="top"
            >
              <Icon name="info-circle" size="sm" />
            </Tooltip>
          </Label>
          <Value>
            {successRate ?? '-'}
            {successRate !== undefined && <Unit>%</Unit>}
          </Value>
        </Field>
        <MostRecentRunStat testRun={last_test_run} />
      </Flex>
    </StatsContainer>
  )
}
