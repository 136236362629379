import React from 'react'
import { IconButton } from '@grafana/ui'
import { Checkbox } from 'components/Checkbox'
import styled from 'styled-components'
import clsx from 'clsx'

import { spacing } from 'utils/styled'
import { ActionCellProps } from './DataTable.types'

export const ActionCell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: unset;
  padding-right: ${spacing(1)};

  &:not(.batch-checkbox) {
    padding-right: ${spacing(0.5)};
  }
`

type ActionsColumnCellProps<T> = ActionCellProps<T> & {
  isSelectionMode: boolean
  onChange: () => void
  onClick: () => void
}

export const ActionsColumnCell = <T,>({
  'aria-label': ariaLabel,
  disabled,
  isSelectionMode,
  isChecked,
  name,
  onChange,
  onClick,
  variant,
}: ActionsColumnCellProps<T>) => {
  return (
    <ActionCell className={clsx(isSelectionMode && `batch-checkbox`)}>
      {isSelectionMode ? (
        <Checkbox
          aria-label={ariaLabel}
          checked={isChecked}
          disabled={disabled}
          onChange={onChange}
          tooltip={ariaLabel}
        />
      ) : (
        <IconButton
          aria-label={ariaLabel}
          disabled={disabled}
          name={name}
          onClick={onClick}
          tooltip={ariaLabel}
          variant={variant}
        />
      )}
    </ActionCell>
  )
}
