import { getValueFormat } from '@grafana/data'

import type { Trend, TrendDataPoint } from 'types'
import { formatDate, resolveTimeZone } from 'utils/date'
import { toMetricOption, withProtocolPrefix } from 'utils/options/metricOptions'
import { toTrendAggregationOption } from 'utils/options/aggregations/trends'
import { vus } from 'utils/formatters'
import { toValueState } from 'utils/trends'
import { toUnit } from 'utils/units'
import { getTestStatusText } from 'pages/utils'

export function createConfig(
  trend: Trend,
  dataPoint: TrendDataPoint,
  executionDuration: string
) {
  const aggregationOption = toTrendAggregationOption(trend.aggregation_function)

  const unit = toUnit({
    metric: trend.metric_name,
    method: trend.aggregation_function,
  })

  const formatter = getValueFormat(unit)

  const formattedValue =
    typeof dataPoint.value === 'number' ? formatter(dataPoint.value) : undefined

  const value =
    formattedValue !== undefined
      ? [
          formattedValue.prefix,
          formattedValue.text,
          formattedValue.suffix,
        ].join(' ')
      : toValueState(dataPoint)

  return {
    started: formatDate(
      dataPoint.run.started || dataPoint.run.created,
      resolveTimeZone()
    ),
    status: getTestStatusText(dataPoint.run),
    metric: withProtocolPrefix(toMetricOption({ name: trend.metric_name }))
      .label,
    aggregation: aggregationOption.shorthand ?? aggregationOption.label,
    value,
    valueUnit: unit,
    vus: dataPoint.run.vus > 0 ? vus(dataPoint.run.vus) : '-',
    executionDuration,
  }
}
