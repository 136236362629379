import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Tooltip } from 'components/Tooltip'
import { spacing } from 'utils/styled'

const TooltipContent = styled.div`
  padding: ${spacing(0.5)};
`

interface TooltipCellProps {
  show?: boolean
  tooltip: ReactNode
  children: ReactNode
}

export function TooltipCell({
  show = true,
  tooltip,
  children,
}: TooltipCellProps) {
  return (
    <Tooltip
      show={show}
      interactive
      placement="top"
      content={<TooltipContent>{tooltip}</TooltipContent>}
    >
      <div>{children}</div>
    </Tooltip>
  )
}
