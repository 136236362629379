import { matchPath, useLocation, useRouteMatch } from 'react-router-dom'

import { useTestWithTrends } from 'data/useTestWithTrends'
import { useTest } from 'data/useTest'
import { useComparedRun, useRun } from 'data/useRun'

import { getRoute, getRoutePath } from 'routeMap'

export const useProjectIdFromRouteData = () => {
  const { testRouteMatch, testRunRouteMatch, testRunCompareRouteMatch } =
    useGetRouteMatches()

  const { data: testWithTrends } = useTestWithTrends(
    +testRouteMatch?.params.testId!,
    {
      enabled: !!testRouteMatch && testRouteMatch.isExact,
    }
  )

  // Not exact test matches script & builder, trends are not needed
  const { data: test } = useTest(+testRouteMatch?.params.testId!, {
    enabled: !!testRouteMatch && !testRouteMatch.isExact,
  })

  const { data: testRun } = useRun(+testRunRouteMatch?.params.runId!, {
    enabled: !!testRunRouteMatch,
  })

  const { data: comparedLeftRun } = useComparedRun(
    +testRunCompareRouteMatch?.params.leftRunId!,
    {
      enabled: !!testRunCompareRouteMatch,
    }
  )

  if (testRouteMatch) {
    return testWithTrends?.project_id || test?.project_id
  }

  if (testRunRouteMatch) {
    return testRun?.project_id
  }

  if (testRunCompareRouteMatch) {
    return comparedLeftRun?.project_id
  }

  return undefined
}

const useGetRouteMatches = () => {
  const location = useLocation()

  const testRouteMatch = useRouteMatch<{ testId: string }>(getRoutePath('test'))
  const testRunRouteMatch = useRouteMatch<{ runId: string }>(
    getRoutePath('testRun')
  )

  let testRunCompareRouteMatch = useRouteMatch<{ leftRunId: string }>(
    getRoutePath('testRunCompare')
  )

  // Query params are handled differently
  if (testRunCompareRouteMatch) {
    const leftRunId = new URLSearchParams(location.search).get('left')

    if (leftRunId) {
      testRunCompareRouteMatch = {
        ...testRunCompareRouteMatch,
        params: { ...testRunCompareRouteMatch.params, leftRunId },
      }
    }
  }

  return {
    testRouteMatch,
    testRunRouteMatch,
    testRunCompareRouteMatch,
  }
}

export const useProjectIdFromParams = () => {
  const { pathname } = useLocation()

  const pathMatch = matchPath<{ projectId: string }>(pathname, {
    path: getRoute('project'),
  })

  const projectFromParams = pathMatch?.params?.projectId
  return projectFromParams ? Number(projectFromParams) : undefined
}
