import { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useDatasource } from 'datasourceContext'
import { ApiError, TestRun, TestId } from 'types'
import { toTestQueryKey, toTestSearchQueryKey } from './queryKeys'
import { showAlert } from 'utils/showAlert'
import { ConfirmModalProps } from 'components/ConfirmModal'
import { resolveTimeZone, getDateInTimezone } from 'utils/date'
import { useConfirmation } from 'hooks/useConfirmation'
import { useCurrentProject } from 'projectContext'

const MAX_NOTE_LENGTH = 125

export const useDeleteTestRuns = (testId: TestId) => {
  const queryClient = useQueryClient()
  const { ds } = useDatasource()
  const project = useCurrentProject()

  const deleteTestRuns = useMutation<unknown, ApiError, TestRun[], unknown>({
    mutationFn: (testRuns) => ds.deleteTestRuns(testRuns.map(({ id }) => id)),
    onSuccess: (_, testRuns) => {
      const { length } = testRuns
      const text =
        length > 1
          ? `Successfully deleted ${length} test runs.`
          : `Successfully deleted test run.`
      showAlert(text, 'success')
    },
    onError: (_, testRuns) => {
      const { length } = testRuns
      const text =
        length > 1
          ? `Failed to delete test runs.`
          : `Failed to delete test run.`
      showAlert(text, 'error')
    },
    onSettled: () => {
      const queryKeysContainingRun = [
        toTestQueryKey(testId),
        toTestSearchQueryKey(project.id),
      ]
      queryKeysContainingRun.forEach((queryKey) =>
        queryClient.invalidateQueries(queryKey)
      )
    },
  })

  return deleteTestRuns
}

export const useDeleteTestRunsConfirmation = (
  testId: TestId,
  onSuccess: () => void
): [Omit<ConfirmModalProps, 'title'>, (...args: any) => void] => {
  const { mutateAsync: deleteTestRuns } = useDeleteTestRuns(testId)
  const [testRuns, setTestRuns] = useState<TestRun[]>([])

  const handleDeleteTestRuns = async () => {
    await deleteTestRuns(testRuns, {
      onSuccess,
    })
  }

  const [confirmModalProps, openModal] = useConfirmation(handleDeleteTestRuns)

  const requestConfirmation = (runs: TestRun[]) => {
    setTestRuns(runs)
    openModal()
  }

  return [
    {
      ...confirmModalProps,
      body: getBody(testRuns),
      confirmText: getConfirmText(testRuns),
    },
    requestConfirmation,
  ]
}

function getBody(testRuns: TestRun[]) {
  if (testRuns.length > 1) {
    return `Are you sure you want to delete ${testRuns.length} test runs?`
  }

  const testRun = testRuns[0]

  if (testRun) {
    return `Are you sure you want to delete the test run${getSuffixText(
      testRun
    )}`
  }

  return `No test runs selected`
}

function getSuffixText(testRun: TestRun) {
  const { started, note } = testRun
  const shorterNote =
    note.length < MAX_NOTE_LENGTH
      ? note
      : `${note.slice(0, MAX_NOTE_LENGTH)}...`
  const noteText = note.length > 0 ? ` (${shorterNote})` : ''

  if (started) {
    return ` ${getDateInTimezone(
      started,
      'MMM dd, HH:mm',
      resolveTimeZone()
    )}${noteText}`
  }

  return noteText
}

function getConfirmText(testRuns: TestRun[]) {
  if (testRuns.length > 1) {
    return `Delete ${testRuns.length} test runs`
  }

  return `Delete test run`
}
