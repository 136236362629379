import { orderBy } from 'lodash-es'

import { useProjectsByOrgId } from 'data/useProjects'
import { useAccount } from 'data/useAccount'
import { getDefaultOrgId } from 'utils/account'
import { QueryOptions } from 'data/queryClient'
import { Project } from 'types'

export const useDefaultProjectOrFirstProject = (
  options?: QueryOptions<Project[]>
) => {
  const { data: account } = useAccount()
  const orgId = account && getDefaultOrgId(account)

  const { data = [] } = useProjectsByOrgId(orgId!, {
    ...options,
    enabled: !!orgId,
    meta: {
      alertOnError: false,
    },
  })
  const k6Projects = data.filter((project) => project.is_k6)

  return k6Projects.length > 0
    ? orderBy(k6Projects, 'is_default', 'desc')[0]
    : undefined
}
