import { useTheme2 } from '@grafana/ui'

import { Trend } from 'types'

export const useMinimalVariation = (
  width: number,
  variant?: 'minimal' | 'default',
  trend?: Trend
) => {
  const theme = useTheme2()

  return (
    !trend?.values.length ||
    variant === 'minimal' ||
    width <= theme.breakpoints.values.sm
  )
}
