import { startOfMonth } from 'date-fns'

import { ProjectSettings } from 'types'
import { ProjectLimitsRow } from './LimitsTable.types'
import { useProjects } from 'data/useProjects'
import { toISODate } from 'utils/date'
import { useUsageReports } from 'data/useUsageReports'
import { useAppContext } from 'appContext'

const startOfTheMonth = toISODate(startOfMonth(new Date()))

export function useProjectLimitsWithSummary(data: ProjectSettings[]) {
  const { data: projectStats = [], isLoading: isLoadingProjectStats } =
    useUsageReports({ startTime: startOfTheMonth })
  const { data: projects = [], isLoading: isLoadingProjects } = useProjects()
  const { orgId } = useAppContext()

  const projectLimitsRows = data.map((row) => {
    const project = projects.find((project) => project.id === row.project_id)

    return {
      ...row,
      project_name: project?.name || '',
      vuh_usage:
        projectStats.find(({ id }) => id === row.project_id)?.vuh_usage ?? 0,
      deleted: false,
    }
  })

  const deletedProjectLimitRows = projectStats.reduce(
    (deletedProjectLimitRows, currentProjectStat) => {
      const projectLimitRowExists = projectLimitsRows.some(
        (limit) => limit.project_id === currentProjectStat.id
      )

      if (projectLimitRowExists) {
        return deletedProjectLimitRows
      }

      deletedProjectLimitRows.push({
        organization_id: orgId,
        project_name: currentProjectStat.name,
        project_id: currentProjectStat.id!,
        vuh_usage: currentProjectStat.vuh_usage!,
        duration_max_per_test: null,
        vu_max_per_test: null,
        vuh_max_per_month: null,
        deleted: true,
      })

      return deletedProjectLimitRows
    },
    [] as typeof projectLimitsRows
  )

  const allProjectLimitRows = [...projectLimitsRows, ...deletedProjectLimitRows]

  // react-data-table-component doesn't support custom table footers
  // so we need this hack to display the summary row
  const summaryRow = allProjectLimitRows.reduce<ProjectLimitsRow>(
    (acc, row) => {
      acc.vuh_usage += row.vuh_usage
      acc.vuh_max_per_month =
        (acc.vuh_max_per_month ?? 0) + (row.vuh_max_per_month ?? 0)

      return acc
    },
    {
      project_id: NaN,
      organization_id: NaN,
      project_name: '',
      vuh_usage: 0,
      vuh_max_per_month: 0,
      vu_max_per_test: null,
      duration_max_per_test: null,
      summary: true,
    }
  )

  return {
    dataWithSummary: [...allProjectLimitRows, summaryRow],
    isSummaryLoading: isLoadingProjects || isLoadingProjectStats,
  }
}
