import React, { ReactNode } from 'react'

import {
  percentage,
  responseTimeFormatter,
  separatorFormatter,
  timing,
  unitFormatter,
} from 'utils/formatters'

import styled from 'styled-components'
import { color } from 'utils/styled'
import { WebVitalName, BrowserMetricSummary } from 'types'
import { getP75ValueConfig } from 'utils/webVitals'
import { StyledWebVitalValue } from './ComparedValue.styled'

const LeftValue = styled.span`
  white-space: nowrap;
  color: ${color('primary', 'text')};
`

const Separator = styled.span`
  color: ${color('text', 'secondary')};
`

const RightValue = styled.span`
  white-space: nowrap;
  color: ${color('text', 'secondary')};
`

interface LeftValueOnlyProps {
  isComparing: boolean
  children: ReactNode
}

export function ComparedLeftValue({
  isComparing,
  children,
}: LeftValueOnlyProps) {
  if (!isComparing) {
    return <>{children}</>
  }

  return <LeftValue>{children}</LeftValue>
}

interface ComparedValueProps {
  left: string | null | ReactNode
  right: string | null | ReactNode | undefined
}

export function ComparedValue({ left, right }: ComparedValueProps) {
  if (right === undefined) {
    return <>{left ?? '-'}</>
  }

  return (
    <>
      <LeftValue>{left ?? '-'}</LeftValue> <Separator>/</Separator>{' '}
      <RightValue>{right ?? '-'}</RightValue>
    </>
  )
}

function nullFormatter<T, R extends any[]>(
  formatter: (value: T, ...args: [...R]) => string
) {
  return (value: T | null, ...args: [...R]) => {
    if (value === null) {
      return '-'
    }

    return formatter(value, ...args)
  }
}

type SelectorFn<T> = (props: T) => number | null

function toFormatter<Args extends any[]>(
  formatter: (value: number, ...args: [...Args]) => string,
  ...args: [...Args]
) {
  const formatNull = nullFormatter(formatter)

  return (left: number | null, right?: number | null) => {
    return {
      left: formatNull(left, ...args),
      right: right !== undefined ? formatNull(right, ...args) : undefined,
    }
  }
}

function toSelectorFormatter<T>(
  selector: SelectorFn<T>,
  formatter: (
    left: number | null,
    right?: number | null
  ) => { left: string; right: string | undefined }
) {
  return (left: T, right?: T | null) => {
    return formatter(selector(left), right && selector(right))
  }
}

export const formatCount = toFormatter(unitFormatter)

export function asCount<T>(selector: SelectorFn<T>) {
  const format = toSelectorFormatter(selector, formatCount)

  return function Count(left: T, right?: T | null) {
    return <ComparedValue {...format(left, right)} />
  }
}

export const formatTiming = toFormatter(timing)

export function asTiming<T>(selector: SelectorFn<T>) {
  const format = toSelectorFormatter(selector, formatTiming)

  return function Timing(left: T, right?: T | null) {
    return <ComparedValue {...format(left, right)} />
  }
}

export const formatPercent = toFormatter(percentage)

export function asPercent<T>(selector: SelectorFn<T>) {
  const format = toSelectorFormatter(selector, formatPercent)

  return function Percentage(left: T, right?: T | null) {
    return <ComparedValue {...format(left, right)} />
  }
}

export const formatRequestRate = toFormatter(separatorFormatter, 2)

export function asRequestRate<T>(selector: SelectorFn<T>) {
  const format = toSelectorFormatter(selector, formatRequestRate)

  return function RequestRate(left: T, right?: T | null) {
    return <ComparedValue {...format(left, right)} />
  }
}

export const formatResponseTime = toFormatter(responseTimeFormatter)

type PropsWithBrowserMetrics = { browser_metric_summary: BrowserMetricSummary }

export function asWebVital<T extends PropsWithBrowserMetrics>(
  name: WebVitalName
) {
  const _formatter = (props: T) => {
    if (!props) {
      return null
    }
    const p75Config = getP75ValueConfig(name, props.browser_metric_summary)
    return (
      <StyledWebVitalValue className={`rating--${p75Config.score}`}>
        {p75Config.value}
        {p75Config.value !== '-' ? p75Config.unit : ''}
      </StyledWebVitalValue>
    )
  }

  return function Score(left: T, right?: T | null) {
    return (
      <ComparedValue
        left={_formatter(left)}
        right={right && _formatter(right)}
      />
    )
  }
}
