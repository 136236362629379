import React, { useState } from 'react'
import { IconButton } from '@grafana/ui'

import { useConfirmation } from 'hooks/useConfirmation'
import { ConfirmModal } from 'components/ConfirmModal'

import { ButtonWrapper } from './RowActions.styled'
import { useClearProjectLimits } from 'data/useProjectSettings'
import { ProjectLimitsRow } from '../LimitsTable/LimitsTable.types'
import { ProjectLimitsModal } from 'components/ProjectLimitsModal'
import { hasLimitsOrQuotas } from 'utils/limitsAndQuotas'

interface RowActionsProps {
  projectLimits: ProjectLimitsRow
}

export function RowActions({ projectLimits }: RowActionsProps) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const { mutateAsync } = useClearProjectLimits()
  const [clearConfirmationProps, handleConfirmClear] = useConfirmation(() =>
    mutateAsync(projectLimits.project_id)
  )

  const hasLimits = hasLimitsOrQuotas(projectLimits)

  if (projectLimits.deleted) {
    return null
  }

  return (
    <ButtonWrapper>
      <IconButton
        name="edit"
        aria-label="Edit project limits"
        tooltip="Edit limits"
        onClick={() => {
          setIsEditModalOpen(true)
        }}
      />
      {hasLimits && (
        <IconButton
          name="sync"
          aria-label="Clear project limits"
          variant="destructive"
          tooltip="Clear limits"
          onClick={handleConfirmClear}
        />
      )}

      <ConfirmModal
        {...clearConfirmationProps}
        title="Clear project limits?"
        confirmText="Clear limits"
        body={`This will remove all limits for project "${projectLimits.project_name}".`}
        description="Your subscription limits will still apply."
      />

      <ProjectLimitsModal
        projectLimits={projectLimits}
        isOpen={isEditModalOpen}
        onDismiss={() => setIsEditModalOpen(false)}
      />
    </ButtonWrapper>
  )
}
