import { ProjectWithStats } from 'types'
import { defaultSortFunc, SortOrder } from 'components/DataTable'
import { toRate } from 'utils/math'

export const failRateSort = (
  a: ProjectWithStats,
  b: ProjectWithStats,
  sortOrder: SortOrder
) => {
  return defaultSortFunc(
    toRate(a.test_runs_fail_count, a.test_runs_started_count),
    toRate(b.test_runs_fail_count, b.test_runs_started_count),
    sortOrder
  )
}

export const contributorsSort = (
  a: ProjectWithStats,
  b: ProjectWithStats,
  sortOrder: SortOrder
) => {
  return defaultSortFunc(
    a.contributors.length,
    b.contributors.length,
    sortOrder
  )
}
