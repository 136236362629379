import React from 'react'
import { MultiSelect, MultiSelectCommonProps } from '@grafana/ui'
import { Selectable } from 'datasource/Selects/types'
import { NotifiableEventType } from '../../NotificationsTab.types'
import {
  EVENT_TYPE_LABELS,
  EVENT_TYPES,
} from '../../NotificationsTab.constants'

export const notifiableEventTypeSelect: Array<Selectable<NotifiableEventType>> =
  Object.values(EVENT_TYPES).map((value) => ({
    label: EVENT_TYPE_LABELS[value],
    value,
  }))

interface NotificationEventSelectProps
  extends Omit<MultiSelectCommonProps<string>, 'onChange'> {
  onChange(value: string[]): void
}

export const NotificationEventSelect = (
  props: NotificationEventSelectProps
) => {
  return (
    <MultiSelect
      {...props}
      className="multiple-select"
      placeholder="Select events"
      isClearable
      options={notifiableEventTypeSelect}
      onChange={(selectedOptions) => {
        const values = (
          selectedOptions as Array<Selectable<NotifiableEventType>>
        ).map(({ value }) => value!)
        props.onChange(values)
      }}
    />
  )
}
