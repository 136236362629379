import styled from 'styled-components'
import { color, spacing } from 'utils/styled'

export const ScriptTabWrapper = styled.div`
  background-color: ${color('background', 'secondary')};
`

export const ScriptEditorWrapper = styled.div`
  padding: ${spacing(0, 2, 2)};
`

export const ScriptTabButtonsContainer = styled.div`
  display: grid;
  grid-column: actions;
  align-items: center;
  justify-content: end;
  grid-auto-flow: column;
  gap: ${spacing(1)};
`

export const MessageContainer = styled.div`
  flex-direction: column;
  text-align: center;
  grid-column: lead;
  padding: ${spacing(2, 0, 8)};

  &:only-child {
    padding-top: ${spacing(8)};
  }

  button {
    margin-top: ${spacing(1)};
  }
`
