import React from 'react'
import { ISODateString } from 'types'
import { getRelativeDateDifference } from 'utils/date'

interface TimeSinceLastRunProps {
  timestamp: ISODateString
}

export const TimeSinceLastRun = ({ timestamp }: TimeSinceLastRunProps) => {
  if (!timestamp) {
    return null
  }

  const difference = getRelativeDateDifference(timestamp, Date.now())

  return <time>{difference}</time>
}
