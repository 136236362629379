import React from 'react'

import { Grid } from 'components/Grid'

import { NOTIFICATION_CHANNELS_META } from '../NotificationsTab.constants'
import { NotificationClient } from '../NotificationsTab.types'
import { NotificationClientCard } from './NotificationClientCard'

interface NotificationClientPickerProps {
  selectedClient?: NotificationClient
  isRadio?: boolean
  ctaDisabled?: boolean
  ctaTooltip?: string
  onClick?(clientType: NotificationClient): void
}

export const NotificationClientSelect = ({
  onClick,
  isRadio,
  selectedClient,
  ctaDisabled,
  ctaTooltip,
}: NotificationClientPickerProps) => {
  const cardsMeta = Object.values(NOTIFICATION_CHANNELS_META).map(
    (channelMeta) => channelMeta
  )

  const handleOnClick = (clientType: NotificationClient) => {
    onClick && onClick(clientType)
  }

  return (
    <div>
      <Grid gap={2}>
        {cardsMeta.map(({ client }) => (
          <Grid.Column key={client} xs={12} sm={6} lg={3}>
            <NotificationClientCard
              isRadio={isRadio}
              notificationClient={client}
              isSelected={isRadio ? client === selectedClient : undefined}
              ctaDisabled={ctaDisabled}
              ctaTooltip={ctaTooltip}
              onClick={() => handleOnClick(client)}
            />
          </Grid.Column>
        ))}
      </Grid>
    </div>
  )
}
