import React from 'react'
import { TestRun } from 'types'
import { vus } from 'utils/formatters'
import { MetaItem } from './MetaItem'

interface VUsMetaItemProps {
  testRun: TestRun
  icon?: boolean
}

export const VUsMetaItem = ({
  testRun,
  icon = true,
  ...props
}: VUsMetaItemProps) => {
  if (testRun.vus <= 0) {
    return <>-</>
  }

  return (
    <MetaItem icon={icon && 'users-alt'} {...props}>
      {vus(testRun.vus)}
    </MetaItem>
  )
}
