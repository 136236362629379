import { Options } from '../schema/options/options'
import { duration } from './duration'
import { loadZones } from './loadZones'
import { browserVUs, vus } from './vus'
import { startTime } from './startTime'
import { Rules } from 'types'

export function checkRules(rules: Rules, options: Options) {
  return [
    ...duration(rules, options),
    ...loadZones(rules, options),
    ...vus(rules, options),
    ...browserVUs(rules, options),
    ...startTime(rules, options),
  ]
}
