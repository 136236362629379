import React, { ReactElement, ReactNode } from 'react'
import { BreakdownTable, TableDefinition, ViewType } from './BreakdownTable'
import { SortOptions, TestRunAnalysis } from 'types'
import { BreakdownHeader } from './BreakdownViewHeader'
import { TableSettingsContext } from './BreakdownTable/TableSettingsContext'

interface BreakdownViewProps<T, P, S> {
  view: ViewType
  'data-testid': string
  analysis: TestRunAnalysis
  params: P
  table: TableDefinition<T, P>

  header?: ReactNode
  emptyTable: ReactElement

  onViewChange?: (view: ViewType) => void
  sortBy?: SortOptions<S>
  onSort: (sortBy: SortOptions<S> | undefined) => void
}

export function BreakdownView<T, P, S>({
  view,
  'data-testid': testId,
  analysis,
  params,
  table,
  header,
  emptyTable,
  sortBy,
  onSort,
  onViewChange,
}: BreakdownViewProps<T, P, S>) {
  return (
    <TableSettingsContext table={table}>
      {header && (
        <BreakdownHeader
          view={table.type === undefined && view}
          onViewChange={onViewChange}
        >
          {header}
        </BreakdownHeader>
      )}
      <BreakdownTable
        data-testid={testId}
        view={view}
        analysis={analysis}
        table={table}
        params={params}
        emptyTable={emptyTable}
        sortBy={sortBy}
        onSort={onSort}
      />
    </TableSettingsContext>
  )
}
