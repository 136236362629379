import React from 'react'
import { Tooltip } from 'components/Tooltip'
import { NotifiableEventType } from '../../NotificationsTab.types'
import {
  EventsContainer,
  EventsTitle,
  EventsTooltipContent,
  EventsTooltipWrapper,
  EventsWrapper,
  RemainingEventsCount,
} from './EventsTooltip.styles'
import { EVENT_TYPE_LABELS } from '../../NotificationsTab.constants'

const PREVIEW_EVENTS_COUNT = 5

type EventsTooltipProps = {
  events: NotifiableEventType[]
}

export const EventsTooltip = ({ events }: EventsTooltipProps) => {
  if (events.length === 0) {
    return <EventsContainer>-</EventsContainer>
  }

  const hiddenEventsCount = events.length - PREVIEW_EVENTS_COUNT
  const hasHiddenEvents = hiddenEventsCount > 0

  const eventsCommaSeparated = events
    .slice(0, PREVIEW_EVENTS_COUNT)
    .map(
      (event, index) => `${index !== 0 ? ', ' : ''} ${EVENT_TYPE_LABELS[event]}`
    )

  return (
    <EventsContainer>
      <Tooltip
        interactive
        content={
          <span>
            <EventsTitle>Events:</EventsTitle>
            <EventsTooltipContent>
              {events.map((event) => (
                <span key={event}>{EVENT_TYPE_LABELS[event]}</span>
              ))}
            </EventsTooltipContent>
          </span>
        }
      >
        <EventsTooltipWrapper>
          <EventsWrapper>
            {eventsCommaSeparated.map((event, index) => (
              <span key={event}>{event}</span>
            ))}
          </EventsWrapper>
          {hasHiddenEvents && (
            <RemainingEventsCount>{`+${hiddenEventsCount}`}</RemainingEventsCount>
          )}
        </EventsTooltipWrapper>
      </Tooltip>
    </EventsContainer>
  )
}
