import React from 'react'
import styled from 'styled-components'

import { Flag } from 'components/Flag'
import { spacing } from 'utils/styled'
import { LoadZone } from 'types/loadZone'

export const StaticIPsLoadZoneLabel = ({
  loadZone,
}: {
  loadZone: LoadZone
}) => {
  return (
    <OptionContainer>
      <Flag name={loadZone.country!.toLowerCase()} />
      <span>{loadZone.city}</span>
    </OptionContainer>
  )
}

const OptionContainer = styled.div`
  display: flex;
  gap: ${spacing(1)};
  align-items: center;
  width: 100%;

  img {
    width: 16px;
  }
`
