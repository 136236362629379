import styled from 'styled-components'
import { color, spacing } from 'utils/styled'

export const ValueTooltipStyled = styled.div`
  z-index: 100;
  padding: ${spacing(1)};
  border-radius: ${({ theme }) => theme.shape.borderRadius(1)};
  white-space: nowrap;
  overflow: hidden;
  background-color: ${color('background', 'secondary')};
  box-shadow: ${({ theme }) => theme.shadows.z2};
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};

  &[data-show='false'] {
    visibility: hidden;
  }
`

export const RunStatus = styled.h5`
  display: flex;
  justify-content: center;
  padding: ${spacing(0.5)};
  margin: -${spacing(1)} -${spacing(1)} ${spacing(1)} -${spacing(1)};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
`

export const StyledValueRow = styled.div`
  display: flex;
  gap: ${spacing(1)};
`

export const RowLabel = styled.div`
  font-weight: 700;
  width: 90px;
`
export const RowValue = styled.div``
