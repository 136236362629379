import { useQuery } from '@tanstack/react-query'
import { useDatasource } from 'datasourceContext'
import { TagValue, TestRun } from 'types'

import { toTagValueQueryKey } from './queryKeys'
import { QueryOptions } from './queryClient'

export const useTagValues = (
  testRun: TestRun,
  tagName: string,
  options?: QueryOptions<TagValue[]>
) => {
  const { ds } = useDatasource()

  return useQuery<TagValue[]>({
    queryKey: toTagValueQueryKey(testRun.id, { tagName, ended: testRun.ended }),
    queryFn: () => ds.fetchTagValues(testRun.id, tagName),
    ...options,
  })
}
