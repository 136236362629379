import React, { useMemo } from 'react'
import { SelectCommonProps } from '@grafana/ui'

import { Test, TestRun } from 'types'
import { useAllTestRuns } from 'data/useTestRuns'

import { Option } from './Option'
import { useTestRunGroups } from './TestRunsSelect.hooks'

import {
  SelectInfinite,
  SelectInfiniteDropdownDirection,
} from 'components/SelectInfinite'

interface TestRunsSelectProps extends SelectCommonProps<TestRun['id']> {
  testId: Test['id']
  selected?: TestRun[]
  dropdownDirection?: SelectInfiniteDropdownDirection
}

export const TestRunsSelect = ({
  testId,
  selected = [],
  ...selectProps
}: TestRunsSelectProps) => {
  const { data, fetchNextPage, isFetching } = useAllTestRuns(testId)

  const testRuns = useMemo(
    () => data?.pages.flatMap((page) => page.runs) ?? [],
    [data]
  )
  const groups = useTestRunGroups(selected, testRuns)

  return (
    <SelectInfinite
      {...selectProps}
      components={{ Option, ...selectProps.components }}
      isLoading={isFetching}
      loadNext={fetchNextPage}
      noOptionsMessage={isFetching ? 'loading...' : 'no available test runs'}
      options={groups}
      placeholder="Select a test run"
      tabSelectsValue={false}
    />
  )
}
