import {
  NotificationChannelMetadataType,
  NotificationChannelDraft,
  NotificationScriptTemplate,
  NotificationChannelType,
  NotificationClient,
} from './NotificationsTab.types'

export const EVENT_TYPES = {
  EVENT_TEST_STARTED_MANUAL: 'test.started.manual',
  EVENT_TEST_STARTED_SCHEDULED: 'test.started.scheduled',
  EVENT_TEST_FINISHED_SUCCESS: 'test.finished.success',
  EVENT_TEST_FINISHED_FAILED: 'test.finished.failed',

  EVENT_TEST_FINISHED: 'test.finished.finished',
  EVENT_TEST_FINISHED_TIMED_OUT: 'test.finished.timed_out',
  EVENT_TEST_FINISHED_USER: 'test.finished.aborted_user',
  EVENT_TEST_FINISHED_SYSTEM: 'test.finished.aborted_system',
  EVENT_TEST_FINISHED_SCRIPT_ERROR: 'test.finished.aborted_script_error',
  EVENT_TEST_FINISHED_THRESHOLD: 'test.finished.aborted_threshold',
  EVENT_TEST_FINISHED_LIMIT: 'test.finished.aborted_limit',
}

export const EVENT_TYPE_LABELS = {
  [EVENT_TYPES.EVENT_TEST_STARTED_MANUAL]: 'Test started',
  [EVENT_TYPES.EVENT_TEST_STARTED_SCHEDULED]: 'Scheduled test started',
  [EVENT_TYPES.EVENT_TEST_FINISHED_SUCCESS]: 'Test finished successfully',
  [EVENT_TYPES.EVENT_TEST_FINISHED_FAILED]: 'Test failed',
  [EVENT_TYPES.EVENT_TEST_FINISHED]: 'Test finished',
  [EVENT_TYPES.EVENT_TEST_FINISHED_TIMED_OUT]: 'Test timed out',
  [EVENT_TYPES.EVENT_TEST_FINISHED_USER]: 'Test aborted (by user)',
  [EVENT_TYPES.EVENT_TEST_FINISHED_SYSTEM]: 'Test aborted (by system)',
  [EVENT_TYPES.EVENT_TEST_FINISHED_SCRIPT_ERROR]: 'Test aborted (script error)',
  [EVENT_TYPES.EVENT_TEST_FINISHED_THRESHOLD]: 'Test aborted (by threshold)',
  [EVENT_TYPES.EVENT_TEST_FINISHED_LIMIT]: 'Test aborted (by limit)',
}

const GENERIC_SCRIPT_TEMPLATE = `{
  "text": "k6.io example webhook template",
  "test_run": {
      "name": "{{test.name}}",
      "id": {{test.id}},
      "link": "{{test.url}}",
      "load_test_id": {{test.load_test_id}},
      "load_test_link": "{{test.load_test_url}}",
      "project_id": {{test.project_id}},
      "organization_id": {{test.organization_id}},
      "user_id": {{user.id}},
      "user_name": "{{user.name}}",
      "user_avatar": "{{user.avatar}}",
      "was_scheduled": "{% if test.was_scheduled %}yes{% else %}no{% endif %}",
      "test_started": "{{test.started}}",
      "test_ended": "{{test.ended}}"
  },
  "event": {
      "id": "{{event.id}}",
      "type": "{{event.type}}",
      "text": "{{event.text}}"
  },
  "status": {
      "run_status": {
          "title": "{{test.status_text}}",
          "value": {{test.status}},
          "color": "{% if test.status in [7, 8, 9] %}red{% else %}green{% endif %}"
      },
      "result_status": {
          "title": "{{test.result_text}}",
          "value": {{test.result}},
          "color": "{% if test.result == 1 %}red{% else %}green{% endif %}"
      }
  },
  "errors": [
      {% for error in errors %}
        {
          "code": {{error.code}},
          "error_created": "{{error.created}}",
          "error_detail": "{{error.detail}}"
        }{{"," if not loop.last}}
      {% endfor %}
  ]
}`

const SLACK_SCRIPT_TEMPLATE = `{
  "username": "k6.io",
  "text": "Load test run - {{test.name}}",
  "attachments": [{
    "color": "{% if test.result == 0 %}#5fa761{% else %}#c02b2b{% endif %}",
    "blocks": [
      {
        "type": "context",
        "elements": [
          {
            "type": "image",
            "image_url": "{% if user.avatar %}{{user.avatar}}{% else %}https://logos.k6.io/20200514/k6-logo-transparent-pad-96x96.png{% endif %}",
            "alt_text": "{{user.name}}"
          },
          {
            "type": "mrkdwn",
            "text": "Started by *{{user.name}}*"
          }
        ]
      },
      {% if event.type.startswith('test.finished') %}
      {
        "type": "section",
        "fields": [
          {
            "type": "mrkdwn",
            "text": "*Scheduled*"
          },
          {
            "type": "mrkdwn",
            "text": "*{% if test.was_scheduled %}Scheduled by{% else %}Started by{% endif %}*"
          },
          {
            "type": "plain_text",
            "text": "{% if test.was_scheduled %}Yes{% else %}No{% endif %}"
          },
          {
            "type": "plain_text",
            "text": "{{user.name}}"
          }
        ]
      },
      {
        "type": "section",
        "fields": [
          {
            "type": "mrkdwn",
            "text": "*Started*"
          },
          {
            "type": "mrkdwn",
            "text": "*Ended*"
          },
          {
            "type": "plain_text",
            "text": "{{test.started}}"
          },
          {
            "type": "plain_text",
            "text": "{{test.ended}}"
          }
        ]
      },
      {
        "type": "section",
        "fields": [
          {
            "type": "mrkdwn",
            "text": "*Status*"
          },
          {
            "type": "mrkdwn",
            "text": " "
          },
          {
            "type": "plain_text",
            "text": "{{test.status_text}} ({{test.result_text}})"
          }
        ]
      }
      {% else %}
      {
        "type": "section",
        "fields": [
          {
            "type": "mrkdwn",
            "text": "*Scheduled*"
          },
          {
            "type": "plain_text",
            "text": "{% if test.was_scheduled %}Yes{% else %}No{% endif %}"
          }
        ]
      }
      {% endif %}
      ,{
        "type": "actions",
        "elements": [
          {
            "type": "button",
            "text": {
              "type": "plain_text",
              "text": "Go to test result"
            },
            "url": "{{ test.url }}"
          },
          {
            "type": "button",
            "text": {
              "type": "plain_text",
              "text": "Go to project dashboard"
            },
            "url": "{{ test.project_url }}"
          }
        ]
      }
    ]
  }]
}`

const MICROSOFT_TEAMS_SCRIPT_TEMPLATE = `{
  "@type": "MessageCard",
  "@context": "http://schema.org/extensions",
  "themeColor": "{% if test.result == 0 %}5fa761{% else %}c02b2b{% endif %}",
  "summary": "Load test run {{test.name}} {% if test.result == 0 %}succeeded{% else %}failed{% endif %} at {{test.ended}}.",
  "sections": [
    {
      "activityTitle": "Load test run **{{test.name}}**",
      {% if event.type.startswith('test.started') %}
        "activitySubtitle": "Started by **{{user.name}}**",
      {% else %}
        "activitySubtitle": "**{% if test.result == 0 %}Succeeded{% else %}Failed{% endif %}** at {{test.ended}}",
      {% endif %}
      "activityImage": "{% if user.avatar %}{{user.avatar}}{% else %}https://logos.k6.io/20200514/k6-logo-transparent-pad-96x96.png{% endif %}",
      "facts": [
        {
          "name": "Scheduled",
          "value": "{% if test.was_scheduled %}Yes{% else %}No{% endif %}"
        }
        {% if event.type.startswith('test.finished') %}
        ,{
          "name": "{% if test.was_scheduled %}Scheduled by{% else %}Started by{% endif %}",
          "value": "{{user.name}}"
        },
        {
          "name": "Status",
          "value": "{{test.status_text }} ({{test.result_text}})"
        },
        {
          "name": "Started",
          "value": "{{test.started}}"
        }
        ,{
            "name": "Ended",
            "value": "{{test.ended}}"
          }
        {% endif %}
      ],
      "markdown": true
    }
  ],
  "potentialAction": [
    {
      "@type": "ViewAction",
      "name": "Go to test result",
      "target": ["{{test.url}}"]
    },
    {
      "@type": "ViewAction",
      "name": "Go to project dashboard",
      "target": ["{{test.project_url}}"]
    }
  ]
}`

const EMAIL_TEAMS_SCRIPT_TEMPLATE = `This is an email notification from <a href="https://k6.io">k6.io</a>.
Your test '{{test.name}}' fired the event '{{event.type}}' ({{event.text}}).
<ul>
  <li>Test-run <a href="{{test.url}}">{{test.id}}</a> ({{test.url}}) started {{test.started}}{% if test.ended %}, ended {{test.ended}}{% endif %}</li>
  <li>User: {{user.name}} (Project {{test.project_id}}, Organization {{test.organization_id}})</li>
  <li>Status: {{test.status_text}}</li>
  <li>Result: {{test.result_text}}</li>
  {% if errors %}
    <li>Error(s):
      <ul>
        {% for error in errors %}
          <li>{{error.created}} (code {{error.code}}): {{error.detail}}</li>
        {% endfor %}
      </ul>
    </li>
  {% endif %}
</ul>`

export const NOTIFICATION_CHANNEL_DRAFTS: Record<
  NotificationClient,
  NotificationChannelDraft
> = {
  [NotificationClient.SLACK]: {
    meta_data: {
      isSimpleInterface: true,
      initial_type: NotificationChannelMetadataType.SLACK,
      name: 'Slack Webhook',
    },
    type: NotificationChannelType.WEBHOOK,
    template: SLACK_SCRIPT_TEMPLATE,
    url: '',
    events: [],
    id: NotificationScriptTemplate.SLACK,
  },
  [NotificationClient.MS_TEAMS]: {
    meta_data: {
      isSimpleInterface: true,
      initial_type: NotificationChannelMetadataType.MS_TEAMS,
      name: 'Microsoft Teams Webhook',
    },
    type: NotificationChannelType.WEBHOOK,
    template: MICROSOFT_TEAMS_SCRIPT_TEMPLATE!,
    url: '',
    events: [],
    id: NotificationScriptTemplate.MS_TEAMS,
  },
  [NotificationClient.WEBHOOK]: {
    type: NotificationChannelType.WEBHOOK,
    meta_data: {
      name: 'Custom Webhook',
    },
    template: GENERIC_SCRIPT_TEMPLATE,
    url: '',
    events: [],
    id: NotificationScriptTemplate.GENERIC,
  },
  [NotificationClient.EMAIL]: {
    meta_data: {
      isSimpleInterface: true,
      initial_type: NotificationChannelMetadataType.EMAIL,
      name: 'Email Notification',
    },
    type: NotificationChannelType.EMAIL,
    template: EMAIL_TEAMS_SCRIPT_TEMPLATE,
    url: '',
    events: [
      EVENT_TYPES.EVENT_TEST_FINISHED_FAILED,
      EVENT_TYPES.EVENT_TEST_FINISHED_TIMED_OUT,
      EVENT_TYPES.EVENT_TEST_FINISHED_SYSTEM,
      EVENT_TYPES.EVENT_TEST_FINISHED_SCRIPT_ERROR,
      EVENT_TYPES.EVENT_TEST_FINISHED_THRESHOLD,
      EVENT_TYPES.EVENT_TEST_FINISHED_LIMIT,
    ],
    id: NotificationScriptTemplate.EMAIL,
  },
}

interface NotificationChannelMeta {
  client: NotificationClient
  name: string
  label: string
  description: string
}

export const NOTIFICATION_CHANNELS_META: Record<
  NotificationClient,
  NotificationChannelMeta
> = {
  [NotificationClient.SLACK]: {
    client: NotificationClient.SLACK,
    label: 'Slack',
    name: 'Slack Webhook',
    description: 'Send notifications to Slack for important test events',
  },
  [NotificationClient.MS_TEAMS]: {
    client: NotificationClient.MS_TEAMS,
    label: 'Microsoft Teams',
    name: 'Microsoft Teams Webhook',
    description:
      'Send notifications to Microsoft Teams for important test events',
  },
  [NotificationClient.WEBHOOK]: {
    client: NotificationClient.WEBHOOK,
    label: 'Custom Webhook',
    name: 'Custom Webhook',
    description: 'Send notifications to a Webhook with a custom template',
  },
  [NotificationClient.EMAIL]: {
    client: NotificationClient.EMAIL,
    label: 'Email',
    name: 'Email Notification',
    description: 'Send notifications to email based on your own template',
  },
}
