import { useState } from 'react'
import { useInterval } from 'usehooks-ts'

import { TestRun } from 'types'
import { duration } from 'utils/formatters'
import { isTestDone, isTestProcessing, isTestRunning } from 'utils/testRun'

export function useTestRunExecutionDuration(testRun: TestRun) {
  const [count, setCount] = useState(getTimeElapsed(testRun))
  useInterval(
    () => setCount(getTimeElapsed(testRun)),
    !isTestDone(testRun) ? 1000 : null
  )

  if (isTestDone(testRun) || isTestProcessing(testRun)) {
    return duration(testRun.execution_duration)
  }

  return count
}

function getTimeElapsed(testRun: TestRun) {
  if (testRun.started && isTestRunning(testRun)) {
    const now = new Date().getTime()
    const started = new Date(testRun.started).getTime()
    return duration((now - started) / 1000)
  }

  return `-`
}
