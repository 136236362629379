import styled from 'styled-components'
import { spacing } from 'utils/styled'

export const Row = styled.div`
  display: flex;
  gap: ${spacing(3)};
`

export const PromoCardsStyled = styled(Row)`
  flex-wrap: wrap;
  & > div {
    flex: 1 1 30%;
    min-width: 300px;
  }
`
