import React, { useMemo } from 'react'

import { Check, TestRunAnalysis } from 'types'
import { Chart } from 'components/Chart'
import { TagQueryBuilder } from 'utils/metrics'

import { useTimeRange } from '../../../TimeRangeContext'
import { ChartFilters } from '../../ChartFilters/ChartFilters'
import { RunsPageTestIds } from 'types/dataTestIds'

interface ChecksChartProps {
  analysis: TestRunAnalysis
  check: Check
}

export const ChecksChart = ({
  analysis: analysis,
  check,
}: ChecksChartProps) => {
  const { timeRange, setTimeRange } = useTimeRange()

  const tags = useMemo(() => {
    return new TagQueryBuilder().equal('check', check.name).build()
  }, [check.name])

  return (
    <ChartFilters
      tags={tags}
      type="checks"
      requestSuccessful={true}
      nodes={analysis.main.nodes}
    >
      {(metrics) => (
        <Chart
          title={`Check: ${check.name}`}
          testRun={analysis.main}
          compareWith={analysis.compareWith}
          metrics={metrics}
          timeRange={timeRange}
          onChangeTimeRange={setTimeRange}
          dataTestId={RunsPageTestIds.ChecksChart}
        />
      )}
    </ChartFilters>
  )
}
