import { useMemo } from 'react'

import { type TestRun } from 'types'
import { type InsightCategoryId } from 'types/cloudInsights'
import { isCategoryId } from 'utils/insights'
import {
  useInsightsAudits,
  useInsightsAuditsResults,
  useInsightsLatestExecution,
} from 'data/useCloudInsights'

import { hasAuditId } from './AuditStats.utils'

export const useCategoryAuditResults = (
  categoryId: InsightCategoryId,
  testRun: TestRun
) => {
  const { data: execution, isLoading: isExecutionLoading } =
    useInsightsLatestExecution(testRun)
  const { data: audits = [], isLoading: isAuditsLoading } = useInsightsAudits(
    testRun,
    execution
  )
  const { data: auditResults = [], isLoading: isAuditsResultsLoading } =
    useInsightsAuditsResults(testRun, execution)

  const results = useMemo(() => {
    const auditsByCategory = audits.filter(isCategoryId(categoryId))
    return auditResults.filter(hasAuditId(auditsByCategory))
  }, [auditResults, audits, categoryId])

  return {
    data: results,
    isLoading: isExecutionLoading || isAuditsLoading || isAuditsResultsLoading,
  }
}
