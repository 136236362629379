import { Span } from './types'
import React, { RefObject, useCallback, useEffect, useRef } from 'react'
import { DurationSection, SectionContainer } from './TestDuration.styled'
import { nanoToMilliseconds } from '../../utils/math'

interface TestDurationProps {
  top?: RefObject<HTMLElement> | null
  rootSpan: Span
}

const maxSections = 10

function toSeconds(value: number) {
  const seconds = value > 0 ? value / 1000 : 0

  return seconds.toFixed(1)
}

function roundDuration(value: number) {
  return Math.round(value / 100) * 100
}

export function TestDuration({ rootSpan, top = null }: TestDurationProps) {
  const start = nanoToMilliseconds(rootSpan.startTimeUnixNano)
  const end = nanoToMilliseconds(rootSpan.endTimeUnixNano)
  const duration = end - start
  const sectionDuration = roundDuration(duration / maxSections)
  const totalSections = Math.ceil(duration / sectionDuration)
  const sectionWidth = `${100 / totalSections}%`
  const containerRef = useRef<HTMLDivElement>(null)
  const sections = Array(totalSections)
    .fill(null)
    .map((_, index) => {
      return index * sectionDuration
    })

  const scrollHandler = useCallback(() => {
    if (top === null || containerRef.current === null || top.current === null) {
      return
    }

    containerRef.current.style.left = -top.current.scrollLeft + 'px'
  }, [top, containerRef])

  useEffect(() => {
    const subject = top?.current
    if (subject === null && containerRef.current) {
      return
    }

    subject?.addEventListener('scroll', scrollHandler)

    return () => {
      subject?.removeEventListener('scroll', scrollHandler)
    }
  }, [top, containerRef, scrollHandler])

  return (
    <SectionContainer ref={containerRef}>
      {sections.map((sectionValue) => {
        return (
          <DurationSection key={sectionValue} style={{ width: sectionWidth }}>
            {toSeconds(sectionValue)}s
          </DurationSection>
        )
      })}
    </SectionContainer>
  )
}
