import constrainedEditor, {
  ConstrainedModel,
  ConstrainedEditorInstance,
  RestrictionObject,
} from 'constrained-editor-plugin'
import type * as monacoType from 'monaco-editor/esm/vs/editor/editor.api'
// @ts-expect-error no types
import { Range } from 'monaco-editor/esm/vs/editor/common/core/range'
import { sentryClient } from 'services/sentryClient'
import { showAlert } from 'utils/showAlert'

const MonacoRange = Range as unknown as typeof monacoType.Range

// Get value map from restrictions array
// Used for updating restriction values
export function getValueMapFromRestrictions(restrictions: RestrictionObject[]) {
  const valueMap: Record<string, string> = {}
  for (const { label, value } of restrictions) {
    valueMap[label] = value
  }

  return valueMap
}

type EditableRangesMap = Record<string, RestrictionObject>

export function initializeConstrainedInstance(
  monaco: typeof monacoType,
  editor: monacoType.editor.IStandaloneCodeEditor
) {
  const instance = constrainedEditor(monaco)
  instance.initializeIn(editor)

  return instance
}

export function addEditableRanges(
  constrainedEditorInstance: ConstrainedEditorInstance,
  model: ConstrainedModel | monacoType.editor.ITextModel,
  ranges: RestrictionObject[],
  onDidChangeContentInEditableRange?: (
    currentChanges: any,
    allChanges: any,
    currentRanges: EditableRangesMap
  ) => void
) {
  const constrainedModel = constrainedEditorInstance.addRestrictionsTo(
    model,
    ranges
  )
  constrainedModel.updateValueInEditableRanges(
    getValueMapFromRestrictions(ranges)
  )
  if (typeof onDidChangeContentInEditableRange === 'function') {
    constrainedModel.onDidChangeContentInEditableRange(
      onDidChangeContentInEditableRange
    )
  }
}

export function updateConstrainedEditorRanges(
  constrainedEditorInstance: ConstrainedEditorInstance,
  model: ConstrainedModel | monacoType.editor.ITextModel,
  value: string,
  ranges: RestrictionObject[],
  onDidChangeContentInEditableRange?: (
    currentChanges: any,
    allChanges: any,
    currentRanges: EditableRangesMap
  ) => void
) {
  if ('disposeRestrictions' in model) {
    model.disposeRestrictions()
  }
  model.setValue(value)
  try {
    addEditableRanges(
      constrainedEditorInstance,
      model,
      ranges,
      onDidChangeContentInEditableRange
    )
    return true
  } catch (error) {
    return false
  }
}

export const jinjaSyntaxClass = 'jinja-syntax'

const getJinjaSyntaxRanges = (
  model: monacoType.editor.ITextModel
): monacoType.Range[] => {
  const code = model.getValue()
  const jinjaRegex = /\{\{.*?\}\}|\{%.*?%\}/g

  let jinjaSyntaxMatch
  const jinjaSyntaxRanges: monacoType.Range[] = []

  while ((jinjaSyntaxMatch = jinjaRegex.exec(code)) !== null) {
    const jinjaSyntaxStartPosition = model.getPositionAt(jinjaSyntaxMatch.index)
    const jinjaSyntaxEndPosition = model.getPositionAt(
      jinjaSyntaxMatch.index + jinjaSyntaxMatch[0].length
    )
    const jinjaSyntaxRange = new MonacoRange(
      jinjaSyntaxStartPosition.lineNumber,
      jinjaSyntaxStartPosition.column,
      jinjaSyntaxEndPosition.lineNumber,
      jinjaSyntaxEndPosition.column
    )
    jinjaSyntaxRanges.push(jinjaSyntaxRange)
  }

  return jinjaSyntaxRanges
}

export const createJinjaDecorator = (range: monacoType.Range) => ({
  range: range,
  options: {
    isWholeLine: false,
    inlineClassName: jinjaSyntaxClass,
  },
})

export const isJinjaDecorator = ({
  options,
}: monacoType.editor.IModelDecoration) =>
  options.inlineClassName === jinjaSyntaxClass

export const highlightJinjaSyntax = (
  editor: monacoType.editor.IStandaloneCodeEditor
) => {
  const model = editor.getModel()
  if (!model) {
    return
  }

  // Remove old decorations in the jinja scope
  const allDecorations = model.getAllDecorations()
  const oldJinjaDecorators = allDecorations.filter((decorator) =>
    isJinjaDecorator(decorator)
  )
  editor.removeDecorations(oldJinjaDecorators.map(({ id }) => id))

  //Add new updated jinja decorators
  const newJinjaDecorationRanges = getJinjaSyntaxRanges(model)
  const newJinjaSyntaxDecorations = newJinjaDecorationRanges.map((range) =>
    createJinjaDecorator(range)
  )
  editor.createDecorationsCollection(newJinjaSyntaxDecorations)
}

export const addK6Types = async (monaco: typeof monacoType) => {
  try {
    const k6Types = await import(
      /* webpackChunkName: "k6-types" */ './k6.types'
    )
    Object.entries(k6Types.default).map(([name, type]) => {
      // Import types as modules for code completions
      monaco.languages.typescript.javascriptDefaults.addExtraLib(
        `declare module '${name}' { ${type} }`
      )
    })

    // Remove TS errors for remote libs imports
    monaco.languages.typescript.javascriptDefaults.addExtraLib(
      "declare module 'https://*'"
    )
  } catch (error) {
    sentryClient.captureException(error)
    showAlert('Error loading k6 types', 'error')
  }
}
