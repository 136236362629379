export const icons = {
  vuh: 'fire',
  vus: 'users-alt',
  duration: 'clock-nine',
  localExecution: 'arrow',
  loadZones: 'database',
  baseline: 'star',
  user: 'user',
  savedRun: 'lock',
  schedule: 'calendar-alt',
} as const
