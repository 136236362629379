import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from '@grafana/ui'
import { isFetchError } from '@grafana/runtime'

import { BertMessage } from 'components/BertMessage'
import { SupportLink } from 'components/SupportLink'
import { isLockedDown } from 'data/queryClient'
import { useTrackActionWithoutOrg } from 'services/tracking'

import {
  ErrorBoundaryFullPageView,
  ErrorBoundaryType,
  TrackingEventName,
} from 'services/tracking.types'

const Description = styled.div`
  text-align: center;
`

interface ErrorStateProps {
  supportEnabled?: boolean
  error: unknown
  view: ErrorBoundaryFullPageView // Used to track where the error occurred
}

function getErrorStateText(error: unknown) {
  if (isLockedDown(error)) {
    return {
      title: 'This service is temporarily locked down for maintenance.',
      description: 'Thanks for your patience.',
    }
  }

  return {
    title: 'Something went wrong.',
    description:
      'This can be caused by either poor connectivity or an error with our servers. If you have an ad blocking extension installed in your browser, try disabling it and reload the page.',
  }
}

export function ErrorState({
  supportEnabled = false,
  error,
  view,
}: ErrorStateProps) {
  const trackAction = useTrackActionWithoutOrg()
  const history = useHistory()
  const { title, description } = getErrorStateText(error)

  function reloadPage() {
    history.go(0)
  }

  useEffect(() => {
    trackAction({
      eventName: TrackingEventName.ErrorBoundary,
      type: ErrorBoundaryType.FullPage,
      isApiError: isFetchError(error),
      view,
    })
  }, [trackAction, error, view])

  return (
    <BertMessage
      type="error"
      title={title}
      description={
        <Description>
          <BertMessage.Text>
            {description}
            {supportEnabled && (
              <>
                Try to reload the page, if the problem persists please{' '}
                <SupportLink>contact support</SupportLink>
              </>
            )}
          </BertMessage.Text>
          <Button onClick={reloadPage}>Reload page</Button>
        </Description>
      }
    />
  )
}
