import React, { useMemo } from 'react'

import { LinkButton } from 'components/LinkButton'
import { encodeFilters } from '../../Filters/Filters.hooks'
import { Http, TestRun, TestRunFilter, TestRunTracesFilterBy } from 'types'
import { routeMap } from 'routeMap'
import { useTracesAvailable } from 'data/useTraces'
import { useGroups } from 'data/useGroups'

interface Props {
  run: TestRun
  request: Http
}

export function ViewTraces({ run, request }: Props) {
  const { data: groups = [], isLoading: isLoadingGroups } = useGroups(run.id)

  const group = useMemo(
    () => groups.find((group) => group.id === request.group_id),
    [groups, request.group_id]
  )

  const filters: TestRunFilter[] = [
    {
      by: TestRunTracesFilterBy.Scenario,
      values: [request.scenario],
    },
    {
      by: TestRunTracesFilterBy.Group,
      values: group ? [group.name] : [],
    },
    {
      by: TestRunTracesFilterBy.URL,
      values: [request.name],
    },
    {
      by: TestRunTracesFilterBy.Method,
      values: [request.method],
    },
    {
      by: TestRunTracesFilterBy.Status,
      values: [request.status.toString()],
    },
  ]

  const { data: isTracesAvailable } = useTracesAvailable({
    run,
    enabled: !isLoadingGroups,
    filters: filters.filter((filter) => filter.values.length !== 0),
  })

  if (!isTracesAvailable) {
    return null
  }

  const filtersEncoded = encodeFilters(filters)
  const url = `${routeMap.testRun(run.id)}?tab=traces&traces=${filtersEncoded}`

  return <LinkButton to={url}>View traces</LinkButton>
}
