import React, { RefObject, useState, ReactElement, useRef } from 'react'
import { Tooltip } from './Tooltip'
import styled from 'styled-components'

const TooltipContent = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  // Needed for centered DataTable columns
  && {
    width: auto;
  }
`

function useIsOverflow(ref: RefObject<HTMLElement>) {
  const [isOverflow, setIsOverflow] = useState<boolean>()

  React.useLayoutEffect(() => {
    const { current } = ref

    if (current) {
      const hasOverflow = current.scrollWidth > current.clientWidth

      setIsOverflow(hasOverflow)
    }
  }, [ref])

  return isOverflow
}

export function OverflownTextTooltip({
  children,
  allowRowEvents,
}: {
  children: ReactElement | string
  allowRowEvents?: boolean
}) {
  const ref = useRef<HTMLElement>(null)
  const isOverflown = useIsOverflow(ref)

  return (
    <Tooltip content={children} show={!!isOverflown}>
      <TooltipContent ref={ref} data-tag={allowRowEvents && 'allowRowEvents'}>
        {children}
      </TooltipContent>
    </Tooltip>
  )
}
