import React from 'react'
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer'
import { PanelRenderer } from '@grafana/runtime'
import { PanelContextProvider } from '@grafana/ui'

import { InsightCategoryResultWithTitleAndTestRun } from 'types/cloudInsights'
import { useFieldOverrides } from 'hooks/useFieldOverrides'
import { NoDataOverlay } from 'components/Chart/NoDataOverlay'

import { type TypedPanelRendererProps } from './Chart.types'
import { createFieldConfig, context, options, toPanelData } from './Chart.utils'
import { Container } from './Chart.styles'

const TypedPanelRenderer = PanelRenderer as TypedPanelRendererProps

interface ChartProps {
  history: InsightCategoryResultWithTitleAndTestRun[]
}

export const Chart = ({ history }: ChartProps) => {
  const { overrides, onToggleSeriesVisibility } = useFieldOverrides()

  const data = toPanelData({ history })
  const fieldConfig = createFieldConfig({ overrides })

  if (!data.series) {
    return <NoDataOverlay>No insights data available...</NoDataOverlay>
  }

  return (
    <Container>
      <ReactVirtualizedAutoSizer>
        {(size) => (
          <PanelContextProvider
            value={{ ...context, onToggleSeriesVisibility }}
          >
            <TypedPanelRenderer
              title=""
              pluginId="trend"
              width={size.width || 0}
              height={size.height || 0}
              options={options}
              fieldConfig={fieldConfig}
              data={data}
            />
          </PanelContextProvider>
        )}
      </ReactVirtualizedAutoSizer>
    </Container>
  )
}
