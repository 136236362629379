interface GetAnimationDataArgs {
  isLight: boolean
}

const importAnimationData = async ({ isLight }: GetAnimationDataArgs) => {
  if (isLight) {
    return (
      await import(
        /* webpackChunkName: "animationDataLight" */ './light/animationData.json'
      )
    ).default
  }

  return (
    await import(
      /* webpackChunkName: "animationDataDark" */ './dark/animationData.json'
    )
  ).default
}

export const getAnimationData = async ({ isLight }: GetAnimationDataArgs) => {
  const data = await importAnimationData({ isLight })

  return {
    ...data,
    assets: data.assets.map((asset) => {
      if (asset.p) {
        return {
          ...asset,
          u: '',
          p: require(`./${isLight ? 'light' : 'dark'}/images/${asset.p}`),
        }
      }

      return asset
    }),
  }
}
