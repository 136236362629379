import { EmptyWrapper } from 'components/DataTable/DataTableEmptyState'
import styled from 'styled-components'
import { color, spacing } from 'utils/styled'
import { Flex } from '../Flex'

export const StyledSection = styled.section`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  &:not(:only-child) {
    // Additional margin between page header and content was requested
    margin-bottom: ${spacing(8)};
  }

  ${EmptyWrapper} {
    padding-bottom: 0;
    color: ${color('text', 'secondary')};
  }
`

export const Description = styled.p`
  color: ${color('text', 'secondary')};
  max-width: 1100px;
`

export const SectionTitle = styled.h4`
  display: flex;
  gap: ${spacing(1)};
  align-items: center;
`

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: ${spacing(2)};

  > div {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: ${spacing(2)};
  }
`

export const SectionContent = styled(Flex)`
  &,
  & > div {
    flex: 1;
  }

  div[role='alert'] {
    flex-grow: unset;
  }
`
