import React, { ReactElement } from 'react'
import { Icon } from '@grafana/ui'

import { DataTable, TableColumn } from 'components/DataTable'
import { FolderPermission, ResourcePermission } from 'types/rbac'
import { Flex } from 'components/Flex'
import { RowActions } from './RowActions'
import { PermissionsSelector } from './PermissionsSelector'
import { exhaustive } from 'utils/typescript'
import { Avatar } from 'components/Avatar'
import { ChangeFolderPermissionParams } from 'data/useChangeFolderPermission'

type AssignmentType = 'role' | 'user' | 'team'

function createPayload(
  type: AssignmentType,
  assignment: ResourcePermission,
  permission: FolderPermission | ''
): ChangeFolderPermissionParams {
  switch (type) {
    case 'role':
      return {
        type,
        builtInRole: assignment.builtInRole!,
        permission,
      }
    case 'user':
      return {
        type,
        userId: assignment.userId!,
        permission,
      }
    case 'team':
      return {
        type,
        teamId: assignment.teamId!,
        permission,
      }
    default:
      return exhaustive(type)
  }
}

function getColumns(
  type: AssignmentType,
  onUpdate: (params: ChangeFolderPermissionParams) => void
): Array<TableColumn<ResourcePermission>> {
  const sharedColumns: Array<TableColumn<ResourcePermission>> = [
    {
      name: 'Permission',
      cell: (assignment) => (
        <PermissionsSelector
          assignment={assignment}
          onChange={(permission) =>
            onUpdate(createPayload(type, assignment, permission))
          }
        />
      ),
    },
    {
      name: '',
      right: true,
      width: '54px',
      cell: (assignment) => (
        <RowActions
          assignment={assignment}
          onDelete={() => onUpdate(createPayload(type, assignment, ''))}
        />
      ),
    },
  ]

  if (type === 'role') {
    return [
      {
        name: 'Role',
        sortable: true,
        sortField: 'builtInRole',
        grow: 1,
        cell: ({ builtInRole }) => (
          <Flex gap={1} align="center">
            <Icon size="xl" name="shield" aria-hidden />
            {builtInRole}
          </Flex>
        ),
      },
      ...sharedColumns,
    ]
  }

  if (type === 'user') {
    return [
      {
        name: 'User',
        grow: 1,
        sortable: true,
        sortField: 'userLogin',
        cell: ({ userLogin, userAvatarUrl }) => (
          <Flex gap={1} align="center">
            <Avatar gravatar_url={userAvatarUrl} />
            {userLogin}
          </Flex>
        ),
      },
      ...sharedColumns,
    ]
  }

  return [
    {
      name: 'Team',
      grow: 1,
      sortable: true,
      sortField: 'team',
      cell: ({ team, teamAvatarUrl }) => (
        <Flex gap={1} align="center">
          <Avatar gravatar_url={teamAvatarUrl} />
          {team}
        </Flex>
      ),
    },
    ...sharedColumns,
  ]
}

interface RolesTableProps {
  type: AssignmentType
  assignments: ResourcePermission[]
  noDataComponent?: ReactElement | null
  onUpdate: (params: ChangeFolderPermissionParams) => void
}

function getKeyFieldId(type: AssignmentType) {
  switch (type) {
    case 'role':
      return 'builtInRole'
    case 'user':
      return 'userLogin'
    case 'team':
      return 'team'
    default:
      return exhaustive(type)
  }
}

export function PermissionsTable({
  assignments,
  type,
  noDataComponent = null,
  onUpdate,
}: RolesTableProps) {
  if (assignments.length === 0) {
    return noDataComponent
  }

  return (
    <DataTable
      columns={getColumns(type, onUpdate)}
      data={assignments}
      highlightOnHover={false}
      defaultSortFieldId={getKeyFieldId(type)}
      defaultSortOrder="asc"
      keyField={getKeyFieldId(type)}
    />
  )
}
