import React from 'react'
import { PanelRenderer } from '@grafana/runtime'

import { LogsFilter, ParsedLog } from 'types/logs'
import { LogsTabTestIds } from 'types/dataTestIds'
import { createPanelData } from './LogList.utils'
import { Container } from './LogList.styles'
import { PanelChrome } from '@grafana/ui'
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer'
import { Flex } from 'components/Flex'
import { ChartMenu } from 'components/Chart/ChartMenu'
import { TestRun } from 'types'
import { LogsConfig, TestRunConfigType } from 'datasource/models'
import { logPanelOptions } from 'components/Chart/panelOptions'
import { useTimeRange } from 'pages/TestRunPage/TimeRangeContext'

interface LogsPanelProps {
  filters: LogsFilter
  logs: ParsedLog[]
  testRun: TestRun
  wrapLogMessage: boolean
}

export const LogList = ({
  filters,
  logs,
  testRun,
  wrapLogMessage,
}: LogsPanelProps) => {
  const { timeRange } = useTimeRange()

  const metrics: LogsConfig[] = [
    {
      filters,
      type: TestRunConfigType.Logs,
    },
  ]
  return (
    <Container data-testid={LogsTabTestIds.LogList}>
      <ReactVirtualizedAutoSizer>
        {(size) => (
          <PanelChrome
            width={size.width ?? 0}
            height={size.height ?? 0}
            title="Logs"
            actions={
              <Flex align="center" gap={2}>
                <ChartMenu
                  title="Logs"
                  testRun={testRun}
                  compareWith={undefined}
                  metrics={metrics}
                  timeRange={timeRange}
                />
              </Flex>
            }
          >
            {(innerWidth, innerHeight) => (
              <div style={{ height: innerHeight, width: innerWidth }}>
                <PanelRenderer
                  title="Logs"
                  pluginId="logs"
                  width={innerWidth}
                  height={innerHeight}
                  data={createPanelData(logs, filters)}
                  options={{
                    ...logPanelOptions,
                    wrapLogMessage,
                  }}
                />
              </div>
            )}
          </PanelChrome>
        )}
      </ReactVirtualizedAutoSizer>
    </Container>
  )
}
