import { useQueryParameter } from 'hooks/useQueryParameter'

function encodeCompareId(value: number | undefined) {
  return value !== undefined && Number.isInteger(value)
    ? value.toString()
    : null
}

function decodeCompareId(value: string | null) {
  if (value === null) {
    return undefined
  }

  const id = Number(value)

  if (!Number.isInteger(id)) {
    return undefined
  }

  return id
}

export function useLeftId() {
  return useQueryParameter({
    name: 'left',
    encoder: encodeCompareId,
    decoder: decodeCompareId,
  })
}

export function useRightId() {
  return useQueryParameter({
    name: 'right',
    encoder: encodeCompareId,
    decoder: decodeCompareId,
  })
}
