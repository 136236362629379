import styled from 'styled-components'

import { spacing } from 'utils/styled'

export const Cell = styled.span`
  width: 'auto';
`

export const ActionCell = styled.span`
  padding: ${spacing(1)};
  width: 'auto';
`
