import React, { useEffect, useMemo } from 'react'

import type { TestRunAnalysis, Threshold } from 'types'
import { Chart } from 'components/Chart'
import { sentryClient } from 'services/sentryClient'

import { useTimeRange } from '../../../TimeRangeContext'
import { getThresholdMetrics } from './ThresholdChart.utils'
import { RunsPageTestIds } from 'types/dataTestIds'

interface ThresholdChartProps {
  analysis: TestRunAnalysis
  threshold: Threshold
}

export const ThresholdChart = ({
  analysis,
  threshold,
}: ThresholdChartProps) => {
  const { timeRange, setTimeRange } = useTimeRange()

  const metrics = useMemo(() => getThresholdMetrics(threshold), [threshold])

  useEffect(() => {
    if (metrics === null) {
      sentryClient.captureException(
        new Error(
          `Unable to parse the following threshold name: ${threshold.name}`
        )
      )
    }
  }, [metrics, threshold.name])

  return (
    <Chart
      title={`Threshold: ${threshold.name}`}
      testRun={analysis.main}
      compareWith={analysis.compareWith}
      metrics={metrics ?? []}
      timeRange={timeRange}
      onChangeTimeRange={setTimeRange}
      dataTestId={RunsPageTestIds.ThresholdsChart}
    />
  )
}
