import { useEffect, useState } from 'react'
import { usePrevious } from 'hooks/usePrevious'
import {
  NotificationChannelDraft,
  NotificationScriptError,
} from '../NotificationsTab.types'
import { useValidateNotification } from './useNotifications'
import { toNotificationScriptError } from '../components/NotificationForm/NotificationForm.utils'
import { useFormContext } from 'react-hook-form'

// The amount of time it'll take to asynchronously validate after changing a bad script
const SCRIPT_VALIDATION_DEBOUNCE = 500

export const useDebouncedScriptValidation = (
  scriptValue: string,
  scriptErrorOnMutation?: NotificationScriptError
) => {
  const form = useFormContext<NotificationChannelDraft>()
  const notificationDraft = form.getValues()

  const [scriptError, setScriptError] = useState<
    NotificationScriptError | undefined
  >(scriptErrorOnMutation)

  const [isValidatingScript, setIsValidatingScript] = useState(false)

  const prevScriptValue = usePrevious(scriptValue)

  const { mutateAsync: validateScript } = useValidateNotification({
    onSuccess: () => {
      form.clearErrors('template')
      setScriptError(undefined)
    },
    onError: (errors) => {
      const { errorDetails, fieldError } = toNotificationScriptError(errors)
      form.setError('template', fieldError, { shouldFocus: true })
      setScriptError(errorDetails)
    },
  })

  useEffect(() => {
    if (scriptError && prevScriptValue !== scriptValue) {
      setIsValidatingScript(true)
    }
  }, [scriptError, scriptValue, prevScriptValue])

  useEffect(() => {
    setScriptError(scriptErrorOnMutation)
  }, [scriptErrorOnMutation])

  useEffect(() => {
    if (!isValidatingScript) {
      return
    }
    const debouncedValidation = setTimeout(() => {
      validateScript({
        type: notificationDraft.type,
        template: scriptValue,
      }).finally(() => setIsValidatingScript(false))
    }, SCRIPT_VALIDATION_DEBOUNCE)

    return () => {
      clearTimeout(debouncedValidation)
    }
  }, [
    validateScript,
    notificationDraft.type,
    setIsValidatingScript,
    scriptValue,
    isValidatingScript,
  ])

  return {
    isValidatingScript,
    scriptError,
  }
}
