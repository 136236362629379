import React, { FormEvent } from 'react'
import { useDebounce } from 'usehooks-ts'
import { HorizontalGroup, Icon, Input, Select, Button } from '@grafana/ui'
import { SelectableValue } from '@grafana/data'

import { Test, TestSortOptions } from 'types'
import { ProjectPageTestIds } from 'types/dataTestIds'
import { useCurrentProject } from 'projectContext'
import { createProjectPageNavModel } from 'navModels'
import { useDeleteTests } from 'data/useDeleteTests'
import { useIsRBACEnabled, useIsUserProjectAdmin } from 'data/usePermissions'
import { useTestCount } from 'data/useTestCount'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { CreateTestButton } from 'components/CreateTestButton'
import { EmptyFilterView } from 'components/EmptyFilterView'
import { Grid } from 'components/Grid'
import { InfiniteScroll } from 'components/InfiniteScroll'
import { Members } from 'components/Members'
import { PluginPage } from 'components/PluginPage'
import { ProjectIdLabel } from 'components/ProjectIdLabel'
import { ProjectMenu } from 'components/ProjectMenu'
import { QueryErrorBoundary } from 'components/ErrorBoundary'
import { useQueryParameter } from 'hooks/useQueryParameter'
import {
  ProjectVUhConsumption,
  TotalVUhConsumption,
} from 'components/VUhConsumption'
import { useFreeVUhsUsageReport } from 'data/useUsageReports'

import { useTestSearch } from './hooks/useTestSearch'
import { useTestSelection } from './hooks/useTestSelection'
import { EmptyState } from './components/EmptyState'
import { ErrorState } from './components/ErrorState'
import { OnboardingModal } from './components/OnboardingModal'
import { ProjectSelector } from './components/ProjectSelector'
import { SelectionActions } from './components/SelectionActions'
import { TestCard } from './components/TestCard'

import { isTestSelected } from './ProjectPage.utils'
import {
  ErrorBoundaryFullPageView,
  ErrorBoundaryLocalView,
} from 'services/tracking.types'

import {
  FiltersContainer,
  PageContainer,
  SpinnerWrapper,
  SubTitleContainer,
  TopRow,
} from './ProjectPage.styles'
import { useDefaultProject } from 'hooks/useDefaultProject'

const selectOptions = [
  { label: 'Last test run', value: TestSortOptions.LastTestRun },
  { label: 'Created', value: TestSortOptions.Created },
  { label: 'Name', value: TestSortOptions.Name },
]

const SEARCH_WAIT_INTERVAL = 200

export const ProjectPage = () => {
  const [searchValue, setSearchValue] = useQueryParameter<string>({
    name: 'q',
    encoder: (value) => value,
    decoder: (value) => value || '',
  })

  const [sortOption, setSortOption] = useQueryParameter<TestSortOptions>({
    name: 'order',
    encoder: (value) => value,
    decoder: (value) =>
      (value as TestSortOptions) || TestSortOptions.LastTestRun,
  })

  const debouncedSearchValue = useDebounce(
    searchValue,
    searchValue !== '' ? SEARCH_WAIT_INTERVAL : 0
  )

  const project = useCurrentProject()

  const pageNav = createProjectPageNavModel(project)
  const isUserProjectAdmin = useIsUserProjectAdmin()
  const isRBACEnabled = useIsRBACEnabled()

  const count = useTestCount(project.id)
  const search = useTestSearch(project.id, debouncedSearchValue, sortOption)
  const selection = useTestSelection(search.tests)
  const { mutateAsync: deleteTests } = useDeleteTests(project.id)

  const serverError = count.error || search.error
  const isProjectEmpty = !count.isFetching && count.data === 0
  const showProjectNameFilter = !(
    isProjectEmpty ||
    !!serverError ||
    count.isFetching
  )

  const handleSearchValueChanged = (search: string) => {
    setSearchValue('replace', search)
  }

  const handleSortOptionChanged = (
    selectedOption: SelectableValue<TestSortOptions>
  ) => {
    setSortOption('replace', selectedOption.value as TestSortOptions)
  }

  const handleDeleteTests = async (tests: Test[]) => {
    await deleteTests(tests)
    selection.onClearSelection()
  }

  return (
    <>
      <PluginPage
        pageNav={pageNav}
        subTitle={<SubTitle projectId={project.id} />}
        renderTitle={(title) => <ProjectSelector title={title} />}
        actions={
          <Actions
            showSelection={selection.showSelection}
            selection={selection.selection}
            onDeleteTests={handleDeleteTests}
            onClearSelection={selection.onClearSelection}
          />
        }
      >
        <PageContainer>
          <TopRow>
            {showProjectNameFilter && (
              <HorizontalGroup height={32}>
                <FiltersContainer>
                  <HorizontalGroup>
                    <Input
                      aria-label="Search by test name"
                      prefix={<Icon name="search" />}
                      placeholder={'Search by test name'}
                      onInput={(e: FormEvent<HTMLInputElement>) => {
                        handleSearchValueChanged(e.currentTarget.value)
                      }}
                      value={searchValue}
                    />
                    <Select
                      aria-label="Order tests by"
                      options={selectOptions}
                      value={sortOption}
                      width={18}
                      isSearchable={false}
                      onChange={handleSortOptionChanged}
                    />
                  </HorizontalGroup>
                </FiltersContainer>
              </HorizontalGroup>
            )}

            {isUserProjectAdmin && !isRBACEnabled && (
              <Members projectId={project.id} />
            )}
          </TopRow>

          <Content
            isLoading={search.isLoading || count.isFetching}
            isProjectEmpty={isProjectEmpty}
            serverError={serverError}
            onDeleteTests={handleDeleteTests}
            onSearchValueChanged={handleSearchValueChanged}
            search={search}
            searchValue={debouncedSearchValue}
            selection={selection}
          />
        </PageContainer>
      </PluginPage>

      <OnboardingModal />
    </>
  )
}

interface ProjectPageSubTitleProps {
  projectId: number
}

const SubTitle = ({ projectId }: ProjectPageSubTitleProps) => {
  const freeVUhsUsageReport = useFreeVUhsUsageReport()

  return (
    <SubTitleContainer gap={2} justify="space-between" wrap="wrap">
      <ProjectIdLabel id={projectId} />
      {freeVUhsUsageReport !== undefined ? (
        <TotalVUhConsumption />
      ) : (
        <QueryErrorBoundary view={ErrorBoundaryLocalView.ProjectVUhConsumption}>
          <ProjectVUhConsumption projectId={projectId} />
        </QueryErrorBoundary>
      )}
    </SubTitleContainer>
  )
}

interface ProjectPageActionsProps {
  showSelection: boolean
  selection: Test[]
  onDeleteTests: (ids: Test[]) => Promise<void>
  onClearSelection: () => void
}

const Actions = ({
  showSelection,
  selection,
  onDeleteTests,
  onClearSelection,
}: ProjectPageActionsProps) => {
  const project = useCurrentProject()
  const isUserProjectAdmin = useIsUserProjectAdmin()
  const defaultProject = useDefaultProject()
  const isDefault = project.id === defaultProject?.id

  if (showSelection) {
    return (
      <SelectionActions
        selection={selection}
        onDeleteTests={onDeleteTests}
        onClearSelection={onClearSelection}
      />
    )
  }

  return (
    <HorizontalGroup justify="flex-end">
      <CreateTestButton />

      {isUserProjectAdmin && (
        <ProjectMenu project={project} isDefault={isDefault}>
          <Button
            icon="ellipsis-v"
            aria-label="Open project menu"
            variant="secondary"
            fill="outline"
          />
        </ProjectMenu>
      )}
    </HorizontalGroup>
  )
}

interface ContentProps {
  isLoading: boolean
  isProjectEmpty: boolean
  serverError?: unknown
  onDeleteTests: (tests: Test[]) => void
  onSearchValueChanged: (search: string) => void
  search: ReturnType<typeof useTestSearch>
  searchValue: string
  selection: ReturnType<typeof useTestSelection>
}

const Content = ({
  isLoading,
  isProjectEmpty,
  serverError,
  onDeleteTests,
  onSearchValueChanged,
  search,
  searchValue,
  selection,
}: ContentProps) => {
  const isSearchEmpty = !search.tests.length
  const xxlColumnSize = search.tests.length > 3 ? 3 : 4

  const handleClearFilters = () => {
    onSearchValueChanged('')
  }

  const handleDeleteTest = (test: Test) => {
    return onDeleteTests([test])
  }

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <CenteredSpinner $height="auto" />
      </SpinnerWrapper>
    )
  }

  if (serverError) {
    return (
      <ErrorState
        error={serverError}
        view={ErrorBoundaryFullPageView.ProjectPage}
      />
    )
  }

  if (isProjectEmpty) {
    return <EmptyState />
  }

  if (isSearchEmpty) {
    return (
      <EmptyFilterView onClear={handleClearFilters}>
        No test matched the query <b>{searchValue}</b>
      </EmptyFilterView>
    )
  }

  return (
    <InfiniteScroll
      isLoading={search.isFetching}
      loadNext={search.fetchNextPage}
    >
      <Grid gap={2} data-testid={ProjectPageTestIds.CardGrid}>
        {search.tests.map((test, index) => (
          <Grid.Column key={test.id} xs={12} md={6} xl={4} xxl={xxlColumnSize}>
            <TestCard
              index={index}
              test={test}
              onDeleteTest={handleDeleteTest}
              onSelectTest={selection.onSelectTest}
              selected={isTestSelected(test.id, selection.selection)}
              showSelection={selection.showSelection}
            />
          </Grid.Column>
        ))}
      </Grid>
    </InfiniteScroll>
  )
}
