import styled from 'styled-components'

import { Flex } from 'components/Flex'
import { spacing } from 'utils/styled'

export const Wrapper = styled(Flex)`
  margin-left: auto;

  label {
    white-space: nowrap;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-left: 0;
    width: 100%;

    > div {
      width: 100%;
    }
  }
`

export const CompareWithMenu = styled.div`
  min-width: ${spacing(20)};
`
