import { useSessionStorage } from 'usehooks-ts'

import { useDatasource } from 'datasourceContext'
import { toAccountQueryKey } from './queryKeys'
import { QueryOptions } from './queryClient'
import { Account, OrganizationRole } from 'types'
import { useQuery } from '@tanstack/react-query'
import { useProvisioningState } from './useProvisioningState'
import { useDebugProjectRole } from 'components/Devtools/hooks/useDevtoolsProjectRole'
import { ORGANIZATION_ROLE_TYPES, PROJECT_ROLE_TYPES } from 'constants/roles'
import { useDebugOrgRole } from 'components/Devtools/hooks/useDevtoolsOrgRole'

// This will no longer be used once `process.env.NODE_ENV === 'development'` branches
// have been removed by webpack and therefore removed during tree shaking.
const patchUserRole = (
  devToolsOrgRole: ORGANIZATION_ROLE_TYPES | null,
  devToolsUserRole: PROJECT_ROLE_TYPES | null
) => {
  return (data: Account) => {
    return {
      ...data,
      organization_roles: data.organization_roles.map((role) => {
        return {
          ...role,
          role_id: devToolsOrgRole ?? role.role_id,
        }
      }) as [OrganizationRole, ...OrganizationRole[]],
      project_roles: data.project_roles.map((role) => {
        return {
          ...role,
          role_id: devToolsUserRole ?? role.role_id,
        }
      }),
      project_roles_granted_by_teams: data.project_roles_granted_by_teams.map(
        (role) => {
          return {
            ...role,
            role_id: devToolsUserRole ?? role.role_id,
          }
        }
      ),
    }
  }
}

// This will no longer be used once `process.env.NODE_ENV === 'development'` branches
// have been removed by webpack and therefore removed during tree shaking.
function useAccountDev() {
  const { ds } = useDatasource()

  const devToolsOrgRole = useDebugOrgRole()
  const devToolsProjectRole = useDebugProjectRole()

  const queryKey = toAccountQueryKey(ds.uid)

  const [storedAccount, setStoredAccount] = useSessionStorage<
    Account | undefined
  >(queryKey.join(''), undefined)

  const account = useAccountProd({
    placeholderData: storedAccount,
    onSuccess: setStoredAccount,
    select: patchUserRole(devToolsOrgRole, devToolsProjectRole),
  })

  return account
}

function useAccountProd(options?: QueryOptions<Account>) {
  const { ds } = useDatasource()
  const { data: provisioningState } = useProvisioningState()

  return useQuery<Account>({
    queryKey: toAccountQueryKey(ds.uid),
    queryFn: ds.fetchAccount.bind(ds),
    enabled: Boolean(provisioningState?.initialized),
    ...options,
    // endpoint has a limit on how many times it can be queried
    staleTime: 3000,

    meta: {
      alertOnError: false,
    },
  })
}

export const useAccount =
  process.env.NODE_ENV === 'development' ? useAccountDev : useAccountProd
