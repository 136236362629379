import React from 'react'
import { DocLink, docs, ReadMore } from 'components/DocLink'
import { InsightListItem } from '../InsightItem'
import { BestPracticeTooManyGroups } from 'types/insights/bestPractices'
import { SkippedDescription } from '../SkippedDescription'
import { InsightBody } from '../InsightBody'

export const TooManyGroups = () => {
  return (
    <InsightBody>
      <p>
        Having many request groups may unnecessarily complicate your code and
        make your tests more difficult to interpret and analyze.
      </p>
      <h4>Recommendations:</h4>
      <p>Reconsider your usage of request groups. Instead:</p>
      <ul>
        <li>
          Use{' '}
          <DocLink article={docs.httpRequests.urlGrouping}>
            URL grouping
          </DocLink>{' '}
          for dynamic URLs.
        </li>
        <li>
          <DocLink article={docs.httpRequests.tags}>Add a name tag</DocLink> to
          your requests.
        </li>
        <li>
          Create functions to better organize your script or create{' '}
          <DocLink article={docs.modules.localFilesystem}>
            local JavaScript modules
          </DocLink>{' '}
          for a more modular approach.
        </li>
        <li>
          Use <DocLink article={docs.scenarios.main}>scenarios</DocLink> to
          model advanced user patterns.
        </li>
      </ul>
      <p>
        <ReadMore article={docs.insights.bestPractices.tooManyGroups} />
      </p>
    </InsightBody>
  )
}

interface TooManyGroupsItemProps {
  insight: BestPracticeTooManyGroups
}

const TooManyGroupsDescription = ({ insight }: TooManyGroupsItemProps) => {
  switch (insight.status) {
    case 'PASSED':
      return (
        <>
          We did not detect an excessive number of groups in your test.{' '}
          <ReadMore article={docs.insights.bestPractices.tooManyGroups} />
        </>
      )

    case 'FAILED':
      return (
        <>We detected a high number of request groups in your test script.</>
      )

    default:
      return <SkippedDescription insight={insight} />
  }
}

export const TooManyGroupsItem = ({ insight }: TooManyGroupsItemProps) => {
  return (
    <InsightListItem header="Too Many Groups" insight={insight}>
      <TooManyGroupsDescription insight={insight} />
    </InsightListItem>
  )
}
