import styled from 'styled-components'
import NoDataOverlayImageDark from 'assets/images/chart_no_data_dark.svg'
import NoDataOverlayImageLight from 'assets/images/chart_no_data_light.svg'

export const NoDataSectionOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) =>
    theme.isDark
      ? theme.colors.background.canvas
      : theme.colors.background.primary};
  background-image: url(${({ theme }) =>
    theme.isDark ? NoDataOverlayImageDark : NoDataOverlayImageLight});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% auto;

  border-radius: 2px;
  span {
    font-size: ${({ theme }) => theme.typography.h5.fontSize};
  }
`
