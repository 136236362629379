import styled from 'styled-components'
import { color, spacing } from 'utils/styled'

export const SmallPromoCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  background-color: ${color('background', 'primary')};
  padding: ${spacing(3)};
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing(2)};
  gap: ${spacing(2)};
  h4 {
    margin: 0;
  }

  & > [class$='-PromoIcon'] {
    flex: 0 0 44px;
    transition: ${({ theme }) => theme.transitions.create('flex-basis')};
  }
`
