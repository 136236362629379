import React, { ReactElement, useState } from 'react'
import { Dropdown, Menu } from '@grafana/ui'

import type { Project } from 'types'
import {
  useIsUserOrgAdmin,
  useIsUserProjectAdminByProjectId,
} from 'data/usePermissions'
import { useConfirmation } from 'hooks/useConfirmation'
import { useDeleteProject } from 'data/useProject'
import { DeleteProjectModal } from './DeleteProjectModal'
import { RenameProjectModal } from './RenameProjectModal'
import { ProjectLimitsModal } from 'components/ProjectLimitsModal'
import { useProjectSettings } from 'data/useProjectSettings'
import { ProjectPermissionsDrawer } from 'components/ProjectPermissionsDrawer'
import { useQueryParameter } from 'hooks/useQueryParameter'

interface ProjectMenuProps {
  project: Project
  children: ReactElement
  isDefault: boolean
}

export function ProjectMenu({
  project,
  children,
  isDefault,
}: ProjectMenuProps) {
  const isOrgAdmin = useIsUserOrgAdmin()
  const isProjectAdmin = useIsUserProjectAdminByProjectId(project.id)
  const [shouldFetchLimits, setShouldFetchLimits] = useState(false)
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false)
  const [isLimitsModalOpen, setIsLimitsModalOpen] = useState(false)
  const [queryParam, setQueryParam] = useQueryParameter<string | null>({
    name: 'editPermissions',
    encoder: (value) => value,
    decoder: (value) => value,
  })

  const isPermissionsDrawerOpen = queryParam === project.id.toString()

  const { mutateAsync: deleteProject } = useDeleteProject()
  const { data: projectLimits, isFetching: isFetchingLimits } =
    useProjectSettings(project.id, { enabled: shouldFetchLimits })

  const [deleteConfirmationProps, handleConfirmDelete] = useConfirmation(() =>
    deleteProject(project.id, {
      onSettled: deleteConfirmationProps.onDismiss,
    })
  )

  if (!isProjectAdmin) {
    return null
  }

  // Lazy load project limits
  const handleVisibilityChange = (isVisible: boolean) => {
    if (isVisible && !shouldFetchLimits) {
      setShouldFetchLimits(true)
    }
  }

  const menu = (
    <Menu>
      <Menu.Item
        label="Rename"
        icon="edit"
        onClick={() => {
          setIsRenameModalOpen(true)
        }}
      />
      <Menu.Item
        label="Manage permissions"
        icon="users-alt"
        onClick={() => {
          setQueryParam('replace', project.id.toString())
        }}
      />
      {isOrgAdmin && (
        <Menu.Item
          label="Manage limits and quotas"
          icon={isFetchingLimits ? 'fa fa-spinner' : 'flip'}
          onClick={() => {
            setIsLimitsModalOpen(true)
          }}
        />
      )}
      <Menu.Divider />
      <Menu.Item
        label="Delete project"
        icon="trash-alt"
        onClick={handleConfirmDelete}
        disabled={isDefault}
        destructive
      />
    </Menu>
  )

  return (
    <>
      <Dropdown
        overlay={menu}
        placement="bottom-end"
        onVisibleChange={handleVisibilityChange}
      >
        {children}
      </Dropdown>
      <DeleteProjectModal {...deleteConfirmationProps} project={project} />
      <RenameProjectModal
        project={project}
        isOpen={isRenameModalOpen}
        onDismiss={() => {
          setIsRenameModalOpen(false)
        }}
      />
      {!!projectLimits && (
        <ProjectLimitsModal
          isOpen={isLimitsModalOpen}
          projectLimits={projectLimits}
          onDismiss={() => {
            setIsLimitsModalOpen(false)
          }}
        />
      )}
      {isPermissionsDrawerOpen && (
        <ProjectPermissionsDrawer
          project={project}
          onClose={() => {
            setQueryParam('replace', null)
          }}
        />
      )}
    </>
  )
}
