import React from 'react'
import { ShortURL } from 'components/ShortURL'
import { ClipboardContent } from '../BreakdownTable/ClipboardContent'
import { TooltipCell } from '../BreakdownTable/Table/TooltipCell'
import { isUrl } from 'utils/url'

interface WSUrlCellProps {
  name: string
}

export function WSUrlCell({ name }: WSUrlCellProps) {
  return (
    <TooltipCell
      show={isUrl(name)}
      tooltip={
        <ClipboardContent actionText="Copy url to clipboard" data={name}>
          {name}
        </ClipboardContent>
      }
    >
      <ShortURL url={name} />
    </TooltipCell>
  )
}
