import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { TableColumn, TableDefinition, TableSettings } from './types'
import { useLocalStorage } from 'usehooks-ts'

const Context = createContext<
  [TableSettings, Dispatch<SetStateAction<TableSettings>>] | null
>(null)

interface TableSettingsContextProps<T, P> {
  table: TableDefinition<T, P>
  children: ReactNode
}

function tableToSettings<T, P>(table: TableDefinition<T, P>): TableSettings {
  return {
    id: table.id,
    type: table.type,
    treeView: {
      showEmpty: true,
    },
    columns: table.columns
      .filter(({ toggle = 'on' }) => toggle === 'on')
      .map((column) => column.id),
  }
}

const getTableName = (id: string) => `table-settings-v1/${id}`

export function TableSettingsContext<T, P>({
  table,
  children,
}: TableSettingsContextProps<T, P>) {
  const state = useLocalStorage(getTableName(table.id), tableToSettings(table))
  const [settings, setSettings] = state

  useEffect(() => {
    // Can be removed when https://github.com/juliencrn/usehooks-ts/issues/296 is fixed
    if (settings && table && table.id !== settings.id) {
      try {
        const storedSettings = localStorage.getItem(getTableName(table.id))

        setSettings(
          JSON.parse(storedSettings ?? 'null') ?? tableToSettings(table)
        )
      } catch (error) {
        setSettings(tableToSettings(table))
      }
    }
  }, [table, setSettings, settings])

  return <Context.Provider value={state}>{children}</Context.Provider>
}

export function useTableSettings() {
  const context = useContext(Context)

  if (context === null) {
    throw new Error(
      'The useTableSettings-hook must be used inside a TableSettingsContext'
    )
  }

  return context
}

export function useSelectedColumns<Column extends TableColumn<any>>(
  columns: Column[]
) {
  const [settings] = useTableSettings()

  return useMemo(() => {
    return columns.filter(
      (column) =>
        column.toggle === 'none' || settings.columns.includes(column.id)
    )
  }, [settings, columns])
}

export function useTreeViewSettings() {
  const [settings] = useTableSettings()

  return settings.treeView
}
