import React from 'react'
import { BertMessage } from 'components/BertMessage'
import { LinkButton } from 'components/LinkButton'
import { routeMap } from 'routeMap'
import { PluginPage } from '@grafana/runtime'
import styled from 'styled-components'
import { spacing } from 'utils/styled'

const BertNotFound = styled(BertMessage)`
  padding-top: ${spacing(6)};
  justify-content: flex-start;
`

export function NotFound() {
  return (
    <PluginPage>
      <BertNotFound
        type="error"
        title="Oops! 404 page - page not found"
        description={
          <div style={{ textAlign: 'center' }}>
            <BertNotFound.Text>
              The page you requested could not be found or you don&apos;t have
              permissions to access it.
            </BertNotFound.Text>
            <LinkButton to={routeMap.home}>Home</LinkButton>
          </div>
        }
      />
    </PluginPage>
  )
}
