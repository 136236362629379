import type { Test } from 'types'
import type { TestDraft } from './drafts'
import type { EditorState, SaveState, WriteMode } from './types'
import { isReadOnlyTest, getTestScript, isBuilderTest } from 'utils/test'

export function hasNameError(state: EditorState) {
  return state.validationState.errors.some((error) => {
    return error.type === 'empty-name' || error.type === 'name-unavailable'
  })
}

export function getWriteMode(
  test: Test | null,
  hasWriteAccess: boolean
): WriteMode {
  if (!hasWriteAccess) {
    return 'forbidden'
  }

  if (test !== null && !isBuilderTest(test) && isReadOnlyTest(test)) {
    return 'read-only'
  }

  return 'write'
}

export function getSaveState(test: Test | null, draft: TestDraft): SaveState {
  if (test === null) {
    return 'new'
  }

  if (test.name !== draft.name) {
    return 'unsaved'
  }

  if (draft.type === 'builder') {
    return draft.request_builder_config !== test.request_builder_config
      ? 'unsaved'
      : 'saved'
  }

  if (draft.script !== getTestScript(test)) {
    return 'unsaved'
  }

  return 'saved'
}
