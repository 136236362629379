import React from 'react'
import { TestRun } from 'types'
import { getTestRunVUhDisplay } from 'components/MetaBar/utils'
import { MetaItem } from './MetaItem'

interface VUsMetaItemProps {
  testRun: TestRun
  icon?: boolean
}

export const VUhMetaItem = ({
  testRun,
  icon = true,
  ...props
}: VUsMetaItemProps) => {
  return (
    <MetaItem icon={icon && 'users-alt'} {...props}>
      {getTestRunVUhDisplay(testRun)}
    </MetaItem>
  )
}
