import { useState } from 'react'
import {
  type ConfigOverrideRule,
  ByNamesMatcherMode,
  FieldMatcherID,
} from '@grafana/data'
import { SeriesVisibilityChangeMode } from '@grafana/ui'

export function useFieldOverrides() {
  const [toggledFields, setToggledFields] = useState<string[]>([])

  const onToggleSeriesVisibility = (
    label: string,
    mode: SeriesVisibilityChangeMode
  ) => {
    if (mode === SeriesVisibilityChangeMode.AppendToSelection) {
      const fields = toggledFields.includes(label)
        ? toggledFields.filter((f) => f !== label)
        : [...toggledFields, label]

      setToggledFields(fields)
    }

    if (mode === SeriesVisibilityChangeMode.ToggleSelection) {
      if (toggledFields.length === 1 && toggledFields[0] === label) {
        setToggledFields([])
      } else {
        setToggledFields([label])
      }
    }
  }

  const overrides: ConfigOverrideRule[] =
    toggledFields.length > 0
      ? [
          {
            matcher: {
              id: FieldMatcherID.byNames,
              options: {
                mode: ByNamesMatcherMode.exclude,
                names: toggledFields,
              },
            },
            properties: [
              {
                id: 'custom.hideFrom',
                value: {
                  viz: true,
                  legend: false,
                  tooltip: false,
                },
              },
            ],
          },
        ]
      : []

  return {
    onToggleSeriesVisibility,
    overrides,
  }
}
