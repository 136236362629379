import React, { useRef, useMemo } from 'react'
import styled from 'styled-components'

import { useContentRect } from 'hooks/useContentRect'
import { getDisplayName } from './ShortURL.utils'

interface ShortURLProps {
  url: string
}

const ShortURLStyles = styled.div`
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ShortURL = ({ url }: ShortURLProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const contentRect = useContentRect(ref)

  const displayUrl = useMemo(() => {
    if (ref.current === null || contentRect === undefined) {
      return url
    }

    return getDisplayName(url, contentRect.width, ref.current)
  }, [contentRect, url])

  return (
    <ShortURLStyles ref={ref}>
      <span>{displayUrl}</span>
    </ShortURLStyles>
  )
}
