import React from 'react'

import { useIsDarkTheme } from 'hooks/useIsDarkTheme'
import { ApplicationWindow } from 'components/ApplicationWindow'
import { Flex } from 'components/Flex'

import { ContainedImage } from '../InitPage.styled'
import { Section } from '../components/Section'
import { StartTesting } from '../components/StartTesting'

import testResultDark2xSrc from '../assets/test-result-dark@2x.png'
import testResultLight2xSrc from '../assets/test-result-light@2x.png'
import testResultDarkSrc from '../assets/test-result-dark.png'
import testResultLightSrc from '../assets/test-result-light.png'

import { useResponsiveRow } from '../components/hooks/useResponsiveRow'
import { Container } from './TopSection.styled'

export function TopSection({ onInitialize }: { onInitialize: () => void }) {
  const style = useResponsiveRow('lg')
  const isDark = useIsDarkTheme()
  const testResultSrcSet = `${
    isDark ? testResultDark2xSrc : testResultLight2xSrc
  } 2x`
  const testResultSrc = isDark ? testResultDarkSrc : testResultLightSrc

  return (
    <Container>
      <Flex width="100%" gap={3} justify="space-between" className={style}>
        <Flex direction="column" gap={3} basis="600px" grow={0} shrink={1}>
          <Section.Heading $size="m">
            Prevent failures. Improve reliability. Release with confidence.
          </Section.Heading>
          <Section.Preamble>
            Test early and continuously—break the QA silo in performance testing
          </Section.Preamble>
          <Flex direction="column" align="flex-start">
            <StartTesting onInitialize={onInitialize}>
              Start testing
            </StartTesting>
          </Flex>
        </Flex>
        <Flex basis="1032px" grow={0} shrink={1}>
          <ApplicationWindow>
            <ContainedImage
              src={testResultSrc}
              srcSet={testResultSrcSet}
              alt="Example test result"
            />
          </ApplicationWindow>
        </Flex>
      </Flex>
    </Container>
  )
}
