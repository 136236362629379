import {
  useInfiniteQuery,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query'
import { groupBy } from 'lodash-es'
import { isFetchError } from '@grafana/runtime'

import { Test, TestRunWithMetrics, TestRunsResponse, TestRun } from 'types'
import { BG_TASKS_POLL_INTERVAL } from '../constants'
import { useDatasource } from 'datasourceContext'
import { isTestDone } from 'utils/testRun'
import {
  toLastExpiredTestRun,
  toLastNonExpiredTestRunWithMetricsQueryKey,
  toTestRunsWithMetricsQueryKey,
} from 'data/queryKeys'
import { useActiveTestRuns } from 'data/useActiveTestRuns'

interface TestRunsData {
  runs: TestRunWithMetrics[]
}

interface TestRunPage extends TestRunsData {
  totalPages: number
}

const toTestRunWithMetrics = ({
  'k6-runs': runs,
  'k6-runs-trending-metrics': metrics,
  meta,
}: TestRunsResponse): TestRunPage => {
  const metricsByTestRunId = groupBy(metrics, (metric) => metric.test_run_id)

  return {
    runs: runs.map((run) => {
      return {
        ...run,
        metrics: metricsByTestRunId[run.id] ?? [],
      }
    }),
    totalPages: meta?.count ?? 0,
  }
}

const getRefetchInterval = (
  data: TestRunsData | undefined,
  testId: Test['id'],
  activeRuns: Array<UseQueryResult<TestRun, unknown>>
) => {
  const runs = data?.runs ?? []

  if (
    activeRuns.some(({ data }) => data && data.test_id === testId) ||
    runs.some((run) => !isTestDone(run))
  ) {
    return BG_TASKS_POLL_INTERVAL
  }

  const metrics = data?.runs.flatMap((run) => run.metrics) ?? []

  if (metrics.some((metric) => !metric.calculated)) {
    return BG_TASKS_POLL_INTERVAL
  }

  return false
}

export const isInvalidPageError = (error: unknown) => {
  return (
    isFetchError(error) &&
    error.status === 404 &&
    error.data?.error.message === 'Invalid page.'
  )
}

export const PAGE_SIZE = 30

export const useTestRuns = (
  testId: Test['id'],
  page = 1,
  pageSize = PAGE_SIZE,
  enabled = true,
  includeDeleted = true
) => {
  const { ds } = useDatasource()
  const activeRuns = useActiveTestRuns()

  return useQuery({
    enabled,
    queryKey: toTestRunsWithMetricsQueryKey(
      testId,
      page,
      pageSize,
      includeDeleted
    ),
    queryFn: () =>
      ds.fetchTestRunTrends(testId, page, pageSize, includeDeleted),
    select: toTestRunWithMetrics,
    keepPreviousData: true,

    retry(failureCount, error) {
      if (isInvalidPageError(error)) {
        return false
      }

      return failureCount < 3
    },

    refetchInterval: (data) => getRefetchInterval(data, testId, activeRuns),
  })
}

export const useLastActiveRun = (testId: Test['id'], enabled = true) => {
  const { ds } = useDatasource()
  const activeRuns = useActiveTestRuns()

  const query = useQuery({
    enabled,
    queryKey: toLastNonExpiredTestRunWithMetricsQueryKey(testId),
    queryFn: () => ds.fetchTestRunTrends(testId, 1, 1, false),
    select: toTestRunWithMetrics,
    keepPreviousData: true,
    refetchInterval: (data) => getRefetchInterval(data, testId, activeRuns),
    retry(failureCount, error) {
      if (isInvalidPageError(error)) {
        return false
      }

      return failureCount < 3
    },
  })

  return {
    ...query,
    data: query.data?.runs[0],
  }
}

export const useHasExpiredRuns = (test: Test, enabled = true) => {
  const { ds } = useDatasource()

  const { data } = useQuery({
    enabled,
    queryKey: toLastExpiredTestRun(test.id),
    queryFn: () => ds.fetchLastExpiredTestRun(test.id),
  })

  return data ? data['k6-runs'].length > 0 : false
}

export const useAllTestRuns = (testId: Test['id'], enabled?: boolean) => {
  const { ds } = useDatasource()
  const activeRuns = useActiveTestRuns()

  return useInfiniteQuery({
    enabled,
    queryKey: toTestRunsWithMetricsQueryKey(testId),
    queryFn: async ({ pageParam: page = 1 }) => {
      return ds.fetchTestRunTrends(testId, page, PAGE_SIZE)
    },

    select(response) {
      return {
        ...response,
        pages: response.pages.map((page) => {
          const { runs } = toTestRunWithMetrics(page)

          return {
            runs,
            count: page.meta?.count ?? runs.length,
          }
        }),
      }
    },

    refetchInterval: (data) => {
      return getRefetchInterval(
        { runs: data?.pages.flatMap((page) => page.runs) ?? [] },
        testId,
        activeRuns
      )
    },

    getNextPageParam: (lastPage, pages) => {
      if (pages.length === lastPage?.meta?.count) {
        return undefined
      }

      return pages.length + 1
    },
  })
}
