import { useCachedLoadZones } from '../data/useLoadZones'
import { intersection } from 'lodash'
import { TestRun } from '../types'

export const useHasPrivateLoadZones = (testRun?: TestRun) => {
  const { data: loadZones = [] } = useCachedLoadZones()

  const privateLoadZones = loadZones
    .filter((lz) => !lz.public)
    .map((lz) => lz.k6_load_zone_id)

  const runLoadZones =
    testRun?.distribution.map(([k6LoadZoneId]) => k6LoadZoneId) ?? []

  return intersection(privateLoadZones, runLoadZones).length > 0
}
