import React, { useState } from 'react'
import { SelectableValue } from '@grafana/data'
import { Select } from '@grafana/ui'
import { useDebounce } from 'usehooks-ts'
import {
  useGrafanaUserOptions,
  GrafanaUser,
} from 'data/useGrafanaUsersAndTeams'

export interface GrafanaUserPickerProps {
  width?: number | 'auto'
  onSelected: (user: SelectableValue<GrafanaUser['userId']>) => void
}

export function GrafanaUserPicker({
  width = 'auto',
  onSelected,
}: GrafanaUserPickerProps) {
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounce(searchQuery, 300)
  const { data: users, isFetching } =
    useGrafanaUserOptions(debouncedSearchQuery)

  return (
    <Select
      isClearable
      isLoading={isFetching}
      options={users}
      onInputChange={(value) => setSearchQuery(value)}
      onChange={onSelected}
      placeholder="Select a user"
      noOptionsMessage="No users found"
      aria-label="User picker"
      width={width}
    />
  )
}
