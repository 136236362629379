import React, { ReactNode, MouseEvent, useRef } from 'react'
import { uniqueId } from 'lodash-es'
import {
  Button,
  ChevronIcon,
  Content,
  Header,
  Label,
} from './CollapsableSection.styles'
import { CollapsableSectionToggleProps } from './CollapsableSection.hooks'

export interface CollapsableSectionProps extends CollapsableSectionToggleProps {
  label: ReactNode
  children: ReactNode
  disabled?: boolean
}

/**
 * This component and its styles are a copy of the CollapsableSection from @grafana/ui, with the exception
 * that it is uncontrolled. We need to have an uncontrolled version for the performance insights, where selecting
 * a new filter should open the collapsable.
 */
export const CollapsableSection = ({
  isOpen,
  label,
  children,
  onToggle,
  disabled = false,
}: CollapsableSectionProps) => {
  const { current: id } = useRef(uniqueId())

  const handleToggleClick = (ev: MouseEvent<HTMLElement>) => {
    if (
      disabled ||
      (ev.target instanceof HTMLElement && ev.target.tagName === 'A')
    ) {
      return
    }

    ev.preventDefault()
    ev.stopPropagation()

    onToggle(!isOpen)
  }

  return (
    <>
      <Header disabled={disabled} onClick={handleToggleClick}>
        {!disabled && (
          <Button
            id={`collapse-button-${id}`}
            onClick={handleToggleClick}
            aria-expanded={isOpen}
            aria-controls={`collapse-content-${id}`}
            aria-labelledby={`collapse-label-${id}`}
          >
            <ChevronIcon name={isOpen ? 'angle-up' : 'angle-down'} />
          </Button>
        )}

        <Label id={`collapse-label-${id}`}>{label}</Label>
      </Header>
      {isOpen && <Content id={`collapse-content-${id}`}>{children}</Content>}{' '}
    </>
  )
}
