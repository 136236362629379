import React from 'react'

import { WebVitalTTFB } from 'types/insights/webVitals'
import { WEB_VITAL_CONFIG, WEB_VITALS_THRESHOLDS } from 'constants/webVitals'

import { InsightListItem } from '../InsightItem'
import { SkippedDescription } from '../SkippedDescription'
import { InsightBody } from '../InsightBody'
import { docs, ReadMore } from 'components/DocLink'

const minThreshold = WEB_VITALS_THRESHOLDS.web_vital_ttfb[0].toFixed(0)

const maxThreshold = WEB_VITALS_THRESHOLDS.web_vital_ttfb[1].toFixed(0)

interface WebVitalTTFBItemProps {
  insight: WebVitalTTFB
}

const WebVitalTTFBDescription = ({ insight }: WebVitalTTFBItemProps) => {
  switch (insight.status) {
    case 'PASSED':
      return (
        <>
          Response received within {minThreshold}{' '}
          {WEB_VITAL_CONFIG.web_vital_ttfb.unit}.{' '}
          <ReadMore article={docs.insights.webVital.ttfb} />
        </>
      )

    case 'WARNING':
      return (
        <>
          Exceeded the {minThreshold} {WEB_VITAL_CONFIG.web_vital_ttfb.unit}{' '}
          threshold.
        </>
      )

    case 'FAILED':
      return (
        <>
          Exceeded the {maxThreshold} {WEB_VITAL_CONFIG.web_vital_ttfb.unit}{' '}
          threshold.
        </>
      )

    default:
      return <SkippedDescription insight={insight} />
  }
}

export const WebVitalTTFBItem = ({ insight }: WebVitalTTFBItemProps) => {
  return (
    <InsightListItem header="Time to First Byte" insight={insight}>
      <WebVitalTTFBDescription insight={insight} />
    </InsightListItem>
  )
}

export const WebVitalTTFBContent = () => {
  return (
    <InsightBody>
      <p>There are several steps you can take to enhance TTFB.</p>
      <h4>Below are specific actions you can consider:</h4>

      <ul>
        <li>
          <strong>Upgrade Hosting Plan:</strong> Consider upgrading your hosting
          plan to one that offers better resources, especially if your website
          is currently on shared hosting. Dedicated or VPS hosting may provide
          better performance and a lower TTFB.
        </li>
        <li>
          <strong>Optimize Server Configuration:</strong> Ensure your server is
          configured for optimal performance. This includes adjusting server
          settings, such as connection timeouts and keep-alive settings, to
          minimize delays.
        </li>
        <li>
          <strong>Content Delivery Network (CDN):</strong> Utilize a CDN to
          distribute static assets geographically closer to users. This helps in
          reducing latency and improving TTFB by serving content from servers
          located nearer to the user.
        </li>
        <li>
          <strong>Server-Side Caching:</strong> Implement server-side caching
          mechanisms to store and serve frequently requested content without
          re-generating it for each user. This can significantly reduce the TTFB
          by delivering cached content when possible.
        </li>
        <li>
          <strong>Compression:</strong> Enable compression for your web server
          to reduce the size of data sent to the browser. Gzip or Brotli
          compression can be particularly effective in minimizing the time it
          takes to transmit resources.
        </li>
        <li>
          <strong>Optimize Database Queries:</strong> Review and optimize
          database queries to ensure they are efficient. Use indexes where
          necessary, and minimize the number of database queries required to
          generate a page.
        </li>
        <li>
          <strong>Minimize Redirects:</strong> Minimize the use of redirects, as
          each redirect introduces an additional round-trip delay. Where
          possible, ensure that URLs are structured to minimize unnecessary
          redirects.
        </li>
        <li>
          <strong>Browser Caching:</strong> Leverage browser caching by setting
          appropriate cache headers for static resources. This can reduce the
          need for the browser to request the same resources on subsequent
          visits.
        </li>
        <li>
          <strong>Use Preconnect and Prefetch:</strong> Implement preconnect and
          prefetch directives in your HTML to establish early connections to
          essential third-party domains and prefetch resources needed for
          subsequent navigation, respectively.
        </li>
      </ul>

      <p>
        By systematically addressing these factors, you can work towards
        improving your Time to First Byte and overall web vitals score. Remember
        that optimizing TTFB is an ongoing process, and continuous monitoring
        and refinement are key to maintaining optimal performance.
      </p>
    </InsightBody>
  )
}
