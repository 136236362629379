import {
  discriminatedUnion,
  literal,
  object,
  string,
  unknown,
  type infer as Infer,
  array,
} from 'zod'

const ValidationErrorSchema = object({
  code: literal(2),
  message: string(),
})

const InvalidTestConfiguration = object({
  code: literal(4000),
  message: string(),
})

export const ApiErrorSchema = object({
  error: discriminatedUnion('code', [
    ValidationErrorSchema,
    InvalidTestConfiguration,
  ]),
})

export const UnknownServerErrorSchema = object({
  type: literal('unknown-server-error'),
  data: unknown(),
})

export type UnknownServerError = Infer<typeof UnknownServerErrorSchema>

export const ServerValidationErrorSchema = object({
  type: literal('server-validation-error'),
  parsed: array(string()),
  error: discriminatedUnion('code', [
    ValidationErrorSchema,
    InvalidTestConfiguration,
  ]),
})

export type ServerValidationError = Infer<typeof ServerValidationErrorSchema>

export const NameUnavailableSchema = object({
  type: literal('name-unavailable'),
})

export type NameUnavailable = Infer<typeof NameUnavailableSchema>

const ERROR_MESSAGE_PREFIX =
  'No active Subscription supports the specified test parameters (make sure you use the right organization or projectID):\n'

const MAX_CONCURRENCY_ERROR_MESSAGE =
  'Max concurrency reached (max allowed number of tests already running).'

function isConfigurationError(message: string) {
  return !message.endsWith(MAX_CONCURRENCY_ERROR_MESSAGE)
}

export function parseErrorMessage(message: string) {
  const trimmed = message.startsWith(ERROR_MESSAGE_PREFIX)
    ? message.slice(ERROR_MESSAGE_PREFIX.length)
    : message

  return trimmed.split('\n').filter(isConfigurationError)
}
