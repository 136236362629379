import styled from 'styled-components'
import { color, spacing } from 'utils/styled'

export const CLIWrapper = styled.div`
  padding: ${spacing(2)};
  width: 100%;
`

export const TextSecondary = styled.p`
  color: ${color('text', 'secondary')};
`
