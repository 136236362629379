import { asTiming } from '../ComparedValue'
import { BrowserUrlsTableColumn } from './constants'
import { TooltipCell } from '../BreakdownTable/Table/TooltipCell'
import { ClipboardContent } from '../BreakdownTable/ClipboardContent'
import { ShortURL } from 'components/ShortURL'
import React from 'react'
import { createWebVitalColumn } from './BrowserTab.utils'

export const columns: BrowserUrlsTableColumn[] = [
  {
    id: 'url',
    name: 'URL',
    sortBy: 'name',
    toggle: 'none',
    customStyles: { maxWidth: 560 },
    renderCell({ name }) {
      return (
        <TooltipCell
          tooltip={
            <ClipboardContent actionText="Copy url to clipboard" data={name}>
              {name}
            </ClipboardContent>
          }
        >
          <ShortURL url={name} />
        </TooltipCell>
      )
    },
    renderGroup: ({ name }) => <>{name}</>,
  },
  {
    id: 'scenario',
    name: 'Scenario',
    sortBy: 'scenario',
    renderCell(row) {
      return row.scenario
    },
  },
  {
    id: 'count',
    name: 'Count',
    sortBy: 'browser_http_metric_summary/http_duration/count',
    renderCell({ browser_http_metric_summary }) {
      return browser_http_metric_summary.http_duration.count
    },
  },
  {
    id: 'min',
    name: 'Min',
    sortBy: 'browser_http_metric_summary/http_duration/min',
    numeric: true,

    renderCell: asTiming(
      ({ browser_http_metric_summary }) =>
        browser_http_metric_summary.http_duration.min
    ),
    renderGroup: asTiming(
      ({ browser_http_metric_summary }) =>
        browser_http_metric_summary.http_duration.min
    ),
  },
  {
    id: 'avg',
    name: 'Avg',
    sortBy: 'browser_http_metric_summary/http_duration/mean',
    numeric: true,

    renderCell: asTiming(
      ({ browser_http_metric_summary }) =>
        browser_http_metric_summary.http_duration.mean
    ),
    renderGroup: asTiming(
      ({ browser_http_metric_summary }) =>
        browser_http_metric_summary.http_duration.mean
    ),
  },
  {
    id: 'stddev',
    name: 'Stddev',
    sortBy: 'browser_http_metric_summary/http_duration/stdev',
    numeric: true,

    renderCell: asTiming(
      ({ browser_http_metric_summary }) =>
        browser_http_metric_summary.http_duration.stdev
    ),
    renderGroup: asTiming(
      ({ browser_http_metric_summary }) =>
        browser_http_metric_summary.http_duration.stdev
    ),
  },
  {
    id: 'p95',
    name: 'P95',
    sortBy: 'browser_http_metric_summary/http_duration/p95',
    numeric: true,

    renderCell: asTiming(
      ({ browser_http_metric_summary }) =>
        browser_http_metric_summary.http_duration.p95
    ),
    renderGroup: asTiming(
      ({ browser_http_metric_summary }) =>
        browser_http_metric_summary.http_duration.p95
    ),
  },
  {
    id: 'p99',
    name: 'P99',
    sortBy: 'browser_http_metric_summary/http_duration/p99',
    numeric: true,

    renderCell: asTiming(
      ({ browser_http_metric_summary }) =>
        browser_http_metric_summary.http_duration.p99
    ),
    renderGroup: asTiming(
      ({ browser_http_metric_summary }) =>
        browser_http_metric_summary.http_duration.p99
    ),
  },
  {
    id: 'max',
    name: 'Max',
    sortBy: 'browser_http_metric_summary/http_duration/max',
    numeric: true,

    renderCell: asTiming(
      ({ browser_http_metric_summary }) =>
        browser_http_metric_summary.http_duration.max
    ),
    renderGroup: asTiming(
      ({ browser_http_metric_summary }) =>
        browser_http_metric_summary.http_duration.max
    ),
  },
  createWebVitalColumn('lcp'),
  createWebVitalColumn('cls'),
  createWebVitalColumn('fid'),
  createWebVitalColumn('fcp'),
  createWebVitalColumn('inp'),
  createWebVitalColumn('ttfb'),
]
