import React from 'react'
import { TableColumn } from 'react-data-table-component'

import { OrganizationToken } from 'types'
import { DataTable, DATA_TABLE_INITIAL_HEIGHT } from 'components/DataTable'
import { RevealablePassword } from 'components/RevealablePassword'
import { LoadingContainer } from 'components/LoadingContainer'
import { BertMessage } from 'components/BertMessage'

import { TokenNameInput } from './TokenNameInput'
import { RowActions } from './RowActions'

interface StackApiTokensTableProps {
  data?: OrganizationToken[]
  isLoading: boolean
}

const columns: Array<TableColumn<OrganizationToken>> = [
  {
    name: 'Name',
    maxWidth: '200px',
    cell(row) {
      return <TokenNameInput name={row.name} token={row.token} />
    },
    sortable: true,
    sortField: 'name',
  },
  {
    name: 'Token',
    cell(row) {
      // key is used to not randomly reveal the "wrong" token when using sort
      return <RevealablePassword key={row.token} readOnly value={row.token} />
    },
  },
  {
    name: '',
    right: true,
    width: '80px',
    cell(row) {
      return <RowActions name={row.name} token={row.token} />
    },
  },
]

export function StackApiTokensTable({
  data = [],
  isLoading,
}: StackApiTokensTableProps) {
  return (
    <LoadingContainer
      loading={isLoading}
      style={{ minHeight: DATA_TABLE_INITIAL_HEIGHT, width: '100%' }}
    >
      <DataTable
        columns={columns}
        data={data}
        fixedHeader
        highlightOnHover
        noDataComponent={
          <BertMessage description="Looks empty here! Start by creating your first stack-level token." />
        }
      />
    </LoadingContainer>
  )
}
