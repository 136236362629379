import React from 'react'

import { WebVitalFCP } from 'types/insights/webVitals'
import { WEB_VITAL_CONFIG, WEB_VITALS_THRESHOLDS } from 'constants/webVitals'
import { millisecondsToSeconds } from 'utils/math'

import { InsightListItem } from '../InsightItem'
import { SkippedDescription } from '../SkippedDescription'
import { InsightBody } from '../InsightBody'
import { docs, ReadMore } from 'components/DocLink'

const minThreshold = millisecondsToSeconds(
  WEB_VITALS_THRESHOLDS.web_vital_fcp[0]
)
const maxThreshold = millisecondsToSeconds(
  WEB_VITALS_THRESHOLDS.web_vital_fcp[1]
)

interface WebVitalFCPItemProps {
  insight: WebVitalFCP
}

const WebVitalFCPDescription = ({ insight }: WebVitalFCPItemProps) => {
  switch (insight.status) {
    case 'PASSED':
      return (
        <>
          The initial render was within {minThreshold}{' '}
          {WEB_VITAL_CONFIG.web_vital_fcp.unit}.{' '}
          <ReadMore article={docs.insights.webVital.fcp} />
        </>
      )

    case 'WARNING':
      return (
        <>
          The initial render took over {minThreshold}{' '}
          {WEB_VITAL_CONFIG.web_vital_fcp.unit} to complete.
        </>
      )

    case 'FAILED':
      return (
        <>
          The initial render took over {maxThreshold}{' '}
          {WEB_VITAL_CONFIG.web_vital_fcp.unit} to complete.
        </>
      )

    default:
      return <SkippedDescription insight={insight} />
  }
}

export const WebVitalFCPItem = ({ insight }: WebVitalFCPItemProps) => {
  return (
    <InsightListItem header="First Contentful Paint" insight={insight}>
      <WebVitalFCPDescription insight={insight} />
    </InsightListItem>
  )
}

export const WebVitalFCPContent = () => {
  return (
    <InsightBody>
      <p>
        First Contentful Paint (FCP) is a crucial metric in browser web vitals
        as it measures the time it takes for the first content element to be
        painted on the screen. A faster FCP contributes to a better user
        experience.
      </p>
      <h4>Here are some strategies to improve First Contentful Paint:</h4>
      <ul>
        <li>
          <strong>Minimize Server Response Time (TTFB):</strong> Efficient
          server responses contribute to faster content rendering. Employ
          caching mechanisms and consider upgrading your hosting plan for better
          server performance.
        </li>
        <li>
          <strong>Minimize Render-Blocking Resources:</strong> Identify and
          address render-blocking resources, especially JavaScript and CSS
          files. Utilize techniques such as asynchronous loading or defer
          loading for non-critical resources.
        </li>
        <li>
          <strong>Prioritize Above-the-Fold Content:</strong> Ensure that
          above-the-fold content, the content visible without scrolling, is
          prioritized for loading. This involves optimizing the delivery of
          critical resources and minimizing the number of requests needed to
          render this content.
        </li>
        <li>
          <strong>Optimize Images and Videos:</strong> Compress and optimize
          images to reduce their file size without sacrificing quality. Consider
          using modern image formats like WebP. Also, lazy load images and
          videos to defer loading until they are needed, improving initial page
          load times.
        </li>
        <li>
          <strong>Optimize Web Fonts:</strong> Minimize the use of custom web
          fonts or use the font-display: swap; property to ensure text is
          visible even before the custom font has fully loaded.
        </li>
        <li>
          <strong>Browser Caching:</strong> Implement proper caching strategies
          for static assets. Utilize cache headers to instruct the browser to
          store and reuse resources locally, reducing the need to download them
          on subsequent visits.
        </li>
        <li>
          <strong>Use a Content Delivery Network (CDN):</strong> Deploy a
          Content Delivery Network to distribute your content across servers
          globally. This reduces latency and speeds up content delivery,
          improving FCP.
        </li>
        <li>
          <strong>Reduce Third-Party Scripts:</strong> Limit the use of
          third-party scripts as they can introduce additional delays. Only
          include essential third-party scripts and load them asynchronously to
          prevent them from blocking rendering.
        </li>
      </ul>

      <p>
        By implementing these strategies, you can work towards improving your
        website{"'"}s First Contentful Paint and providing a faster and more
        engaging user experience. Keep in mind that optimizations may need to be
        tailored based on your specific website and its content.
      </p>
    </InsightBody>
  )
}
