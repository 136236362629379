import { GroupWithScenarioName } from 'data/clients/entities/groups'
import { keyBy } from 'lodash-es'
import React, {
  Key,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { Group, GroupId, Scenario } from 'types'

interface ExpansionState {
  [id: string]: boolean
}

interface TableState {
  initialized: boolean
  expansion: ExpansionState
}

/**
 * Expands groups which have values in them.
 */
function expandGroupsById(
  state: ExpansionState,
  groups: GroupWithScenarioName[],
  ids: Iterable<GroupId | null | undefined>,
  groupId?: (group: GroupWithScenarioName) => string
): ExpansionState {
  const newState = { ...state }

  const groupsById = keyBy(groups, (group) =>
    groupId ? groupId(group) : group.id
  )

  for (const id of ids) {
    let current = id !== null && id !== undefined ? groupsById[id] : undefined

    while (current !== undefined) {
      newState['group-' + current.id] = true
      newState['scenario-' + current.scenario_id] = true

      if (current.parent_id === null) {
        break
      }

      current = groupsById[current.parent_id]
    }
  }

  return newState
}

function newTableState(): TableState {
  return {
    initialized: false,
    expansion: {},
  }
}

interface Context {
  state: TableState
  setInitialized: () => void
  expandGroups(
    groups: Group[],
    ids: Iterable<GroupId | null | undefined>,
    groupId?: (group: GroupWithScenarioName) => string
  ): void
  collapseAll(): void
  toggleItem(id: string): void
}

const context = createContext<Context | null>(null)

interface TableStateProviderProps {
  children: ReactNode
}

export function TableStateProvider({ children }: TableStateProviderProps) {
  const [state, setState] = useState(newTableState)

  const setInitialized = useCallback(() => {
    setState((state) => ({
      ...state,
      initialized: true,
    }))
  }, [])

  const expandGroups = useCallback(
    (
      groups: GroupWithScenarioName[],
      ids: Iterable<GroupId | null | undefined>,
      groupId?: (group: GroupWithScenarioName) => string
    ) => {
      setState((state) => {
        return {
          ...state,
          expansion: expandGroupsById(state.expansion, groups, ids, groupId),
        }
      })
    },
    []
  )

  const collapseAll = useCallback(() => {
    setState((state) => ({
      ...state,
      expansion: {},
    }))
  }, [])

  const toggleItem = useCallback((id: string) => {
    setState((state) => ({
      ...state,
      expansion: {
        ...state.expansion,
        [id]: !state.expansion[id],
      },
    }))
  }, [])

  const value = useMemo(() => {
    return {
      state,
      setInitialized,
      expandGroups,
      collapseAll,
      toggleItem,
    }
  }, [state, setInitialized, expandGroups, collapseAll, toggleItem])

  return <context.Provider value={value}>{children}</context.Provider>
}

export function useTableStateContext() {
  const stateContext = useContext(context)

  if (stateContext === null) {
    throw new Error(
      'Cannot use the "useTableStateContext" hook outside of a "TableStateProvider" context.'
    )
  }

  return stateContext
}

function useItemToggle(id: string): [expanded: boolean, toggle: () => void] {
  const { state, toggleItem } = useTableStateContext()
  const expanded = state.expansion[id] === true

  return [expanded, () => toggleItem(id)]
}

export function useScenarioToggle(scenario: Scenario) {
  return useItemToggle('scenario-' + scenario.id)
}

export function useGroupToggle(group: Group) {
  return useItemToggle('group-' + group.id)
}

export function useRowToggle(key: Key) {
  return useItemToggle('row-' + key)
}
