import React from 'react'
import { TestRunFilter, TestRunTracesFilterBy } from 'types'
import { toTracesSummaryQueryKey } from 'data/queryKeys'
import { TableDefinition } from '../BreakdownTable'
import { TracesChart } from './TracesChart'
import { K6DataSource } from 'datasource/datasource'
import {
  TracesSummary,
  TracesSpanStatus,
  TracesSpanStatusLabel,
  TracesSpanKindLabel,
} from 'types/traces'
import { testRunFiltersToTagQuery } from 'utils/traces'
import { GroupWithScenarioName } from 'data/clients/entities/groups'
import { asCount, asTiming } from '../ComparedValue'

interface TracesTableProps {
  filters?: TestRunFilter[]
  ds: K6DataSource
  enabled?: boolean
}

export const tracesTable: TableDefinition<TracesSummary, TracesTableProps> = {
  id: 'traces-breakdown',
  pageSize: 20,
  paginator: 'always',

  isSuccess(row) {
    return row.span.status_code !== TracesSpanStatus.ERROR
  },

  keyBy(summary) {
    // Skip summary counts from key to enable comparison
    const { summary: _, ...rest } = summary
    return JSON.stringify(rest)
  },

  // Traces API does not provide group of scenario ID, use combination of scenario and group names
  groupBy(row) {
    return `${row.test_run.scenario}_${row.test_run.group}`
  },

  groupId(group: GroupWithScenarioName) {
    return `${group.scenario_name}_${group.name}`
  },

  getRowCount({ http_metric_summary }) {
    // TODO: use count returned by traces API when available
    return http_metric_summary.requests_count ?? 0
  },

  fetchPage({ testRun, ds, enabled, filters, ...rest }) {
    const tags = testRunFiltersToTagQuery(filters || []).buildArray()

    const params = {
      ...rest,
      tags,
    }

    return {
      queryKey: toTracesSummaryQueryKey(testRun.id, params),
      queryFn: async () => {
        const response = await ds.fetchTracesSummary({
          id: testRun.id,
          ...params,
        })
        return {
          items: response.summaries,
          count: response.total_size,
        }
      },
      enabled,
    }
  },

  fetchByRows({ testRun, ds, rows, filters }) {
    const tags = testRunFiltersToTagQuery(filters || []).buildArray()

    const params = {
      pageSize: rows.length,
      tags,
    }

    return {
      queryKey: toTracesSummaryQueryKey(testRun.id, params),
      queryFn: async () => {
        const response = await ds.fetchTracesSummary({
          id: testRun.id,
          ...params,
        })
        return {
          items: response.summaries,
          count: response.total_size,
        }
      },
    }
  },

  fetchByGroup({ ds, testRun, group, filters, enabled, ...rest }) {
    const tags = testRunFiltersToTagQuery(filters || [])

    if (group !== 'any') {
      tags.equal(TestRunTracesFilterBy.Group, group.name)
      tags.equal(TestRunTracesFilterBy.Scenario, group.scenario_name)
    }

    const params = {
      ...rest,
      // Traces API does not allow 1000 as pageSize
      pageSize: rest.pageSize === 1000 ? undefined : rest.pageSize,
      tags: tags.buildArray(),
    }

    return {
      queryKey: toTracesSummaryQueryKey(testRun.id, params),
      queryFn: async (page) => {
        const response = await ds.fetchTracesSummary({
          id: testRun.id,
          ...params,
          page,
        })
        return {
          items: response.summaries,
          count: response.total_size,
        }
      },
      enabled,
    }
  },

  columns: [
    {
      id: 'name',
      name: 'NAME',
      sortBy: 'span/name',
      toggle: 'none',

      renderCell: ({ span }) => <>{span.name}</>,
      renderGroup: ({ name }) => <>{name}</>,
    },
    {
      id: 'service_name',
      name: 'SERVICE NAME',
      sortBy: 'span/service_name',
      renderCell: ({ span }) => <>{span.service_name}</>,
      renderGroup: () => <></>,
    },
    {
      id: 'kind',
      name: 'KIND',
      sortBy: 'span/kind',

      renderCell: ({ span }) => <>{TracesSpanKindLabel[span.kind]}</>,
      renderGroup: () => <></>,
    },
    {
      id: 'status',
      name: 'STATUS',
      sortBy: 'span/status_code',

      renderCell: ({ span }) => <>{TracesSpanStatusLabel[span.status_code]}</>,
      renderGroup: () => <></>,
    },
    {
      id: 'count',
      name: 'COUNT',
      sortBy: 'summary/count',
      numeric: true,

      renderCell: asCount(({ summary }) => summary.count),
      renderGroup: () => <></>,
    },
    {
      id: 'min',
      name: 'MIN',
      sortBy: 'summary/duration/min',
      numeric: true,

      renderCell: asTiming(({ summary }) => summary.duration.min),
      renderGroup: () => <></>,
    },
    {
      id: 'avg',
      name: 'AVG',
      sortBy: 'summary/duration/avg',
      numeric: true,

      renderCell: asTiming(({ summary }) => summary.duration.avg),
      renderGroup: () => <></>,
    },
    {
      id: 'stddev',
      name: 'STDDEV',
      sortBy: 'summary/duration/stddev',
      numeric: true,

      renderCell: asTiming(({ summary }) => summary.duration.stddev),
      renderGroup: () => <></>,
    },
    {
      id: 'p95',
      name: 'P95',
      sortBy: 'summary/duration/p95',
      numeric: true,

      renderCell: asTiming(({ summary }) => summary.duration.p95),
      renderGroup: () => <></>,
    },
    {
      id: 'p99',
      name: 'P99',
      sortBy: 'summary/duration/p99',
      numeric: true,

      renderCell: asTiming(({ summary }) => summary.duration.p99),
      renderGroup: () => <></>,
    },
    {
      id: 'max',
      name: 'MAX',
      sortBy: 'summary/duration/max',
      numeric: true,

      renderCell: asTiming(({ summary }) => summary.duration.max),
      renderGroup: () => <></>,
    },
  ],

  RowBodyComponent: ({ analysis, value }) => (
    <TracesChart analysis={analysis} tracesSummary={value} />
  ),

  EmptyGroupComponent: () => (
    <>No traces were captured during test execution.</>
  ),
}
