import { useQuery } from '@tanstack/react-query'
import { useDatasource } from 'datasourceContext'
import { useAppContext } from 'appContext'

import { toOrgRolesQueryKey } from './queryKeys'

export const useOrgRoles = (
  options: { useErrorBoundary?: boolean; alertOnError?: boolean } = {}
) => {
  const { useErrorBoundary, alertOnError } = options

  const { ds } = useDatasource()
  const { orgId } = useAppContext()

  return useQuery({
    queryKey: toOrgRolesQueryKey(orgId),
    queryFn: () => ds.fetchOrgRoles(orgId as number),
    placeholderData: [],
    enabled: !!orgId,
    useErrorBoundary,
    meta: {
      alertOnError,
    },
  })
}
