import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { type AbsoluteTimeRange } from '@grafana/data'

import { toScenariosQueryKey } from 'data/queryKeys'
import { K6DataSource } from 'datasource/datasource'
import { FilterExpression, includes } from 'datasource/serialization'
import { useDatasource } from 'datasourceContext'
import { keyBy } from 'lodash-es'
import { Check, PagedItems, SortOptions, TestRunId } from 'types'
import { EntityClient } from './entityClient'

export interface CheckWithScenario extends Check {
  scenario: string
}

interface FetchPageParams {
  filter: FilterExpression<Check>
  page: number
  pageSize: number
  sortBy: SortOptions<Check> | undefined
  timeRange?: AbsoluteTimeRange
}

export class CheckClient extends EntityClient<Check> {
  queryClient: QueryClient | undefined

  constructor(datasource: K6DataSource, queryClient?: QueryClient) {
    super(datasource)
    this.queryClient = queryClient
  }

  toUrl(testRunId: TestRunId | string): string {
    return `/loadtests/v4/test_runs(${testRunId})/checks`
  }

  fetchByIds(testRunId: TestRunId, ids: string[]) {
    return this.fetchPage(testRunId, {
      filter: includes('id', ids),
      page: 1,
      pageSize: ids.length,
      sortBy: undefined,
    })
  }

  async fetchPage(
    testRunId: TestRunId,
    { filter, page, pageSize, sortBy, timeRange }: FetchPageParams
  ): Promise<PagedItems<CheckWithScenario>> {
    const [checks, scenarios] = await Promise.all([
      this.fetch({
        testRunId,
        query: {
          select: ['id', 'group_id', 'scenario_id', 'name', 'metric_summary'],
          filter,
          orderBy: sortBy && [[sortBy.sortBy, sortBy.direction]],
          skip: (page - 1) * pageSize,
          top: pageSize,
          count: true,
        },
        timeRange,
      }),
      this.queryClient?.fetchQuery({
        queryKey: toScenariosQueryKey(testRunId),
        queryFn: () => this.datasource.fetchScenarios(testRunId),
      }),
    ])

    const scenariosById = keyBy(
      scenarios?.value ?? [],
      (scenario) => scenario.id
    )

    return {
      ...checks,
      items: checks.items.map((check) => ({
        ...check,
        scenario: scenariosById[check.scenario_id]?.name ?? '-',
      })),
    }
  }
}

export function useCheckClient() {
  const { ds } = useDatasource()
  const queryClient = useQueryClient()

  return new CheckClient(ds, queryClient)
}
