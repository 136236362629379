import type { TrendDataPoint } from 'types'

export interface PlaceholderDataPoint {
  run: null
  calculated: boolean
  timestamp: string
  value: 0
}

/**
 * This enum is practically the same as the TestRunStatus enum, with the addition
 * of statuses Failed and Stopping. This allows us to determine the status of a test without
 * worrying about two enums (TestRunStatus and TestRunResultStatus).
 */
export enum TrendValueStatus {
  Created = 'created',
  Initializing = 'initializing',
  Validated = 'validated',
  Queued = 'queued',
  Running = 'running',
  AbortedByUser = 'aborted-by-user',
  AbortedByThreshold = 'aborted-by-thresholds',
  AbortedBySystem = 'aborted-by-system',
  AbortedByLimit = 'aborted-by-limit',
  AbortedByScriptError = 'aborted-by-script-error',
  TimedOut = 'timed-out',
  Stopping = 'stopping',
  Finalizing = 'finalizing',
  Finished = 'finished',
  Failed = 'failed',
  Uploaded = 'uploaded',
}

export type BarChartTrendDataPoint = TrendDataPoint | PlaceholderDataPoint
export type BarChartTrendValueStatus = TrendValueStatus | 'placeholder'
