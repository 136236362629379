import { getRelativeXOffsetPercent } from './BrowserTimeline.utils'
import { Icon, IconName, Tooltip } from '@grafana/ui'
import React from 'react'
import { Span } from './types'
import type { TooltipProps } from '../Tooltip'
import styled from 'styled-components'
import { color } from '../../utils/styled'

interface InteractionIconProps {
  span: Span
  parentSpan: Span
  tooltip: TooltipProps['content']
  icon: IconName
}

const Container = styled.div`
  z-index: 2;
  position: absolute;
  left: 0;
  top: -3px;
  opacity: 0.9;
  color: ${color('background', 'primary')};
`

export function InteractionIcon({
  parentSpan,
  span,
  tooltip,
  icon,
}: InteractionIconProps) {
  const offsetX = getRelativeXOffsetPercent(span, parentSpan)

  return (
    <>
      <Container style={{ left: `calc(${offsetX}%)` }}>
        <Tooltip content={tooltip} placement="top">
          <Icon name={icon} size="xs" />
        </Tooltip>
      </Container>
    </>
  )
}
