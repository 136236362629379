import React from 'react'
import { CenteredColumn, FooterSectionStyled } from './FooterSection.styled'

export function FooterSection() {
  return (
    <FooterSectionStyled direction="row">
      <CenteredColumn>
        <span className="footer-title">Proven at Scale</span>
      </CenteredColumn>
      <CenteredColumn>
        <span>19.7K</span>
        <span>GitHub stars</span>
      </CenteredColumn>
      <CenteredColumn>
        <span>+1.200B</span>
        <span>Hours spent running tests</span>
      </CenteredColumn>
      <CenteredColumn>
        <span>+1.5M</span>
        <span>Tests Run</span>
      </CenteredColumn>
      <CenteredColumn>
        <span>+5M</span>
        <span>RPS (largest test)</span>
      </CenteredColumn>
    </FooterSectionStyled>
  )
}
