import React, { ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import { Button as GrafanaButton } from '@grafana/ui'

import { spacing } from 'utils/styled'

interface EmptyFilterViewProps {
  children: ReactNode
  onClear?: () => void
  onReady?: () => void
}

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: ${spacing(3)};
`

const Button = styled(GrafanaButton)`
  display: block;
  margin: ${spacing(2)} auto;
`

export const EmptyFilterView = ({
  children,
  onClear,
  onReady,
}: EmptyFilterViewProps) => {
  useEffect(() => {
    onReady?.()
  }, [onReady])

  return (
    <Wrapper className="empty-filter-view">
      {children}
      {onClear && (
        <Button size="md" type="button" variant="secondary" onClick={onClear}>
          Clear all filters
        </Button>
      )}
    </Wrapper>
  )
}
