import { dateTime } from '@grafana/data'
import { DateTimePicker } from '@grafana/ui'
import React from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import styled from 'styled-components'
import { ScheduledTestPayload } from 'types/scheduledTests'

export const ScheduledUtcTime = styled.div`
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-top: ${({ theme }) => theme.spacing(0.5)};
`

export function DatePickerControl({
  value,
  onChange,
  ...props
}: ControllerRenderProps<ScheduledTestPayload, 'starts'>) {
  const date = dateTime(value)

  return (
    <>
      <DateTimePicker
        {...props}
        onChange={(val) => {
          onChange({
            target: {
              value: val.toISOString(),
            },
          })
        }}
        date={date}
      />
      <ScheduledUtcTime>
        The test will run at {date.utc().format('HH:mm')} UTC.
      </ScheduledUtcTime>
    </>
  )
}
