import { DevToolsErrorType } from '@tanstack/react-query-devtools/build/lib/devtools'
import { ErrorCode } from 'types'

export const GENERIC_API_ERROR_MESSAGE =
  'Something went wrong, please try again later'

export const REACT_QUERY_ERROR_TYPES: DevToolsErrorType[] = [
  {
    name: 'API Lockdown',
    initializer: () => ({
      status: 503,
      data: { error: { code: ErrorCode.LOCKDOWN } },
      toString() {
        return 'API Lockdown'
      },
    }),
  },
  {
    name: 'Authentication error',
    initializer: () => ({
      status: 400,
      data: { error: { code: ErrorCode.NOT_AUTHENTICATED } },
      toString() {
        return 'Authentication error'
      },
    }),
  },
]
