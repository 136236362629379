import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Icon, Modal } from '@grafana/ui'

import { routeMap } from 'routeMap'
import { spacing } from 'utils/styled'
import { useIsDefaultProjectAdmin } from 'data/usePermissions'
import { useProjects } from 'data/useProjects'
import { useTestCount } from 'data/useTestCount'
import { useDefaultProjectOrFirstProject } from 'hooks/useDefaultOrFirstProject'
import { OnboardingStage } from 'hooks/useOnboarding'
import { DocLink, docs } from 'components/DocLink'
import { Flex } from 'components/Flex'

interface WelcomeNewUserProps {
  isOpen: boolean
  onChange: (stage: OnboardingStage) => void
}

export function WelcomeNewUser({ isOpen, onChange }: WelcomeNewUserProps) {
  const { data: projects = [] } = useProjects()
  const isDefaultProjectAdmin = useIsDefaultProjectAdmin()
  const defaultProject = useDefaultProjectOrFirstProject()
  const history = useHistory()

  const hasOneProject = projects.length === 1

  const { data: testCount = 0 } = useTestCount(
    hasOneProject ? defaultProject?.id : undefined
  )

  const isConfigOptionVisible =
    isDefaultProjectAdmin && hasOneProject && testCount === 0

  function handleDismiss() {
    onChange('welcomed')
  }

  function handleConfigureDefaultProject() {
    if (!defaultProject) {
      return
    }

    onChange('welcomed')
    history.push({
      pathname: routeMap.project(defaultProject.id),
      search: '?editPermissions',
    })
  }

  function handleGoToDefaultProject() {
    if (!defaultProject) {
      return
    }

    onChange('welcomed')
    history.push(routeMap.project(defaultProject.id))
  }

  return (
    <Modal
      trapFocus
      title="Welcome to Grafana Cloud k6!"
      isOpen={isOpen}
      onDismiss={handleDismiss}
    >
      <p>
        If {"you're"} ready to kick off a load test and assess your {"system's"}{' '}
        performance under different conditions, click on{' '}
        {'"Go to default project"'} to dive right in.
      </p>

      {isConfigOptionVisible && (
        <p>
          If {"you're"} more inclined to fine-tune your project configurations
          before initiating a load test, click on{' '}
          {'"Configure default project"'}. Here, you can change the project
          name, set performance thresholds, and invite other users from your
          organization.
        </p>
      )}

      <p>
        Our goal is to empower you with a robust and efficient testing platform.
        Feel free to explore the features and functionalities, and if you want
        to learn more you can find all of our documentation{' '}
        <DocLink article={docs.root.k6}>here</DocLink>.
      </p>

      <p>Happy testing!</p>

      <Flex justify="center" gap={3} paddingVertical={3}>
        <WelcomeButton fill="outline" onClick={handleGoToDefaultProject}>
          <Flex align="center" gap={1}>
            <Icon name="rocket" size="xl" />
            <span>Go to default project</span>
          </Flex>
        </WelcomeButton>

        {isConfigOptionVisible && (
          <WelcomeButton fill="outline" onClick={handleConfigureDefaultProject}>
            <Flex align="center" gap={1}>
              <Icon name="cog" size="xl" />
              <span>Configure default project</span>
            </Flex>
          </WelcomeButton>
        )}
      </Flex>
      <Modal.ButtonRow>
        <Button variant="secondary" onClick={handleDismiss}>
          Close
        </Button>
      </Modal.ButtonRow>
    </Modal>
  )
}

const WelcomeButton = styled(Button)`
  height: auto;
  padding: ${spacing(1, 2)};
  border-radius: ${({ theme }) => theme.shape.borderRadius(4)};
`
