import { useQuery } from '@tanstack/react-query'
import { useDatasource } from 'datasourceContext'
import { GrpcUrl, TestRun } from 'types'
import { toGrpcQueryKey } from './queryKeys'

type GrpcName = Pick<GrpcUrl, 'name'>

export function useGRPCNames(testRun: TestRun) {
  const { ds } = useDatasource()

  return useQuery<GrpcName[]>({
    queryKey: toGrpcQueryKey(testRun.id, {
      select: 'name',
      ended: testRun.ended,
    }),
    queryFn: () =>
      ds
        .fetchGrpcUrls({ id: testRun.id, select: 'name', count: false })
        .then((res) => res.value ?? []),
  })
}
