import React, { useMemo, useState } from 'react'
import { Select } from '@grafana/ui'
import { SelectableValue } from '@grafana/data'
import { nanoid } from 'nanoid'
import { FolderOption, NewFolder } from '../types'
import { useDashboardFolderOptions } from './FolderSelect.hooks'
import { useCanCreateFolders } from 'hooks/useGrafanaRBAC'

const ROOT_FOLDER_ID = nanoid()

function getSelectedValue(value: FolderOption | undefined) {
  if (value?.type === 'new') {
    return value.placeholderId
  }

  if (value?.type === 'root') {
    return ROOT_FOLDER_ID
  }

  return value?.uid
}

interface FolderSelectProps {
  value: FolderOption | undefined
  onChange: (folder: FolderOption) => void
}

export function FolderSelect({ value, onChange }: FolderSelectProps) {
  const [newFolder, setNewFolder] = useState<NewFolder>()
  const { data: existingFolders = [], isLoading } = useDashboardFolderOptions()

  const canCreateFolders = useCanCreateFolders()

  const selected = getSelectedValue(value)

  const options = useMemo<Array<SelectableValue<string>>>(() => {
    const rootOption: SelectableValue<string> = {
      label: 'Dashboards',
      icon: 'folder',
      value: ROOT_FOLDER_ID,
    }

    const existingOptions = [
      rootOption,
      ...existingFolders.map((folder) => ({
        label: `Dashboards / ${folder.title}`,
        icon: 'folder',
        value: folder.uid,
      })),
    ]

    if (newFolder === undefined) {
      return existingOptions
    }

    return [
      {
        label: newFolder.title + ' *',
        icon: 'folder-plus',
        value: newFolder.placeholderId,
      },
      ...existingOptions,
    ]
  }, [existingFolders, newFolder])

  const handleFolderChange = (ev: SelectableValue<string>) => {
    if (ev.value === undefined) {
      return
    }

    if (ev.value === ROOT_FOLDER_ID) {
      onChange({ type: 'root' })

      return
    }

    if (ev.value === newFolder?.placeholderId) {
      onChange(newFolder)

      return
    }

    const folder = existingFolders.find((folder) => folder.uid === ev.value)

    if (folder === undefined) {
      return
    }

    onChange(folder)
  }

  const handleCreateFolder = (name: string) => {
    const folder: NewFolder = {
      type: 'new',
      placeholderId: nanoid(),
      title: name,
    }

    setNewFolder(folder)
    onChange(folder)
  }

  return (
    <Select
      value={selected ?? null}
      placeholder="Select the folder where to save the summary..."
      options={options}
      allowCustomValue={canCreateFolders}
      isLoading={isLoading}
      loadingMessage="Loading folders..."
      formatCreateLabel={(name) => `Create in a new folder called '${name}'`}
      onCreateOption={handleCreateFolder}
      onChange={handleFolderChange}
    />
  )
}
