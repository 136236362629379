import React, { useCallback } from 'react'
import { Metric, TestRun, TestWithTrends } from 'types'
import { DocLink, docs } from 'components/DocLink'
import { Button } from 'components/Button'
import { useHasOnlyTrialTypeSubscriptions } from 'data/useSubscriptions'
import { useHasExpiredRuns } from 'data/useTestRuns'
import { TrendingMetricEditor } from './TrendingMetricEditor'
import {
  TrendingMetricDraftConfig,
  TrendingMetricDraftConfigError,
} from './TrendingMetricsEditor.types'
import {
  Description,
  ExpiredRunsInfoStyled,
  MessageContainer,
  TrendingMetricsConfigEditorContainer,
} from './TrendingMetricsEditor.styles'
import { CAN_CONFIGURE_MULTIPLE_TRENDS } from '../TrendingMetricsModal'
import { getPositionOfFirstDraftDuplicate } from './TrendingMetricsEditor.utils'
import { useMetricOptions } from 'datasource/QueryEditor/MetricConfigEditor/MetricConfigEditor.hooks'
import { toMetricOption } from 'utils/options/metricOptions'
import { isTestAborted, isTestActive, isTestRunExpired } from 'utils/testRun'
import { TextExpiredRunDataInfoMessage } from 'pages/TestRunPage/components/RunOverview/ExpiredRunOverview'

interface MetricConfigEditorProps {
  test: TestWithTrends
  lastRun?: TestRun
  metrics?: Metric[]
  draftConfigs: TrendingMetricDraftConfig[]
  draftConfigsErrors: TrendingMetricDraftConfigError[]
  onChange: (draft: TrendingMetricDraftConfig) => void
  onDeleteDraft: (draft: TrendingMetricDraftConfig) => void
  onInsertDraft: () => void
  isOpen?: boolean
  hasError?: boolean
}

export const TrendingMetricsEditor = ({
  test,
  lastRun,
  metrics,
  draftConfigs,
  draftConfigsErrors,
  onChange,
  onDeleteDraft,
  onInsertDraft,
  isOpen,
  hasError,
}: MetricConfigEditorProps) => {
  const hasExpiredRuns = useHasExpiredRuns(test, isOpen)
  const hasFreeSubscription = useHasOnlyTrialTypeSubscriptions()

  const hasTestRuns = test.test_runs.length > 0

  const isActive = lastRun && isTestActive(lastRun)

  const metricOptions = (metrics ?? []).map((option) => toMetricOption(option))

  const trendingMetricOptions = useMetricOptions(metricOptions)

  const isAborted = lastRun && isTestAborted(lastRun)

  const canConfigureMetric =
    metrics &&
    metricOptions.length > 0 &&
    hasTestRuns &&
    lastRun &&
    !isTestRunExpired(lastRun) &&
    !isAborted &&
    !hasError

  const showExpiredRunsInfo =
    canConfigureMetric && hasFreeSubscription && hasExpiredRuns

  const getBlockingMessage = useCallback(() => {
    if (canConfigureMetric) {
      return
    }

    if (!hasTestRuns || !lastRun) {
      return 'To configure the trending metric you need to have at least one test run with metric data.'
    }

    if (metrics?.length === 0 && isActive) {
      return 'Trending metrics are not yet available due to the test being active.'
    }

    if (isAborted) {
      return 'Trending metric is not configurable due to the latest test run being aborted.'
    }

    if (hasError) {
      return 'Unable to retrieve trending metrics, please try again later.'
    }

    return 'Trending metric is not configurable at this moment due to no metrics being generated by the latest test run.'
  }, [
    canConfigureMetric,
    hasTestRuns,
    lastRun,
    metrics?.length,
    isActive,
    isAborted,
    hasError,
  ])

  if (!canConfigureMetric) {
    return <MessageContainer>{getBlockingMessage()}</MessageContainer>
  }

  return (
    <TrendingMetricsConfigEditorContainer>
      <Description>
        Select which trending metric to display in performance-trending chart.
        Both system metrics and in-script user-defined metrics are supported.{' '}
        <DocLink article={docs.insights.trendingMetric}>
          Read more in the docs.
        </DocLink>
      </Description>
      {draftConfigs.map((trendingMetricDraft, index) => (
        <TrendingMetricEditor
          key={trendingMetricDraft.id}
          lastRun={lastRun!}
          draft={trendingMetricDraft}
          draftConfigErrors={draftConfigsErrors.find(
            (error) => error.id === trendingMetricDraft.id
          )}
          trendingMetricOptions={trendingMetricOptions}
          onChange={onChange}
          onDeleteDraft={onDeleteDraft}
          duplicateOf={getPositionOfFirstDraftDuplicate(
            trendingMetricDraft,
            draftConfigs
          )}
          canDelete={draftConfigs.length > 1}
          isDefault={index === 0}
        />
      ))}
      {CAN_CONFIGURE_MULTIPLE_TRENDS && (
        <Button
          onClick={onInsertDraft}
          variant="primary"
          size="sm"
          fill="text"
          icon="plus-square"
        >
          Add Trending Metric
        </Button>
      )}
      {showExpiredRunsInfo && (
        <ExpiredRunsInfoStyled>
          <b>Note</b>: Metrics data has been deleted for some of the test runs
          based on your plan&apos;s data retention policy, preventing the
          calculation of new trending metric values for those runs.{' '}
          <TextExpiredRunDataInfoMessage />
        </ExpiredRunsInfoStyled>
      )}
    </TrendingMetricsConfigEditorContainer>
  )
}
