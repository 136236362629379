import React from 'react'
import { useTheme2 } from '@grafana/ui'

export const BertErrorIllustration = () => {
  const { isDark } = useTheme2()

  const circleFillColor = isDark ? '#3c3c64' : '#fff'
  const sparkFillColor = isDark ? '#8764FF' : '#3c3c64'

  return (
    <svg x="0px" y="0px" viewBox="0 0 223 194">
      <path
        fill={sparkFillColor}
        d="M28.3,48c0.8-1.9,1.6-3.7,2.4-5.6c0-0.1,0-0.3-0.1-0.3s-0.2,0-0.3,0.1c-1.3,1.6-2.4,3.4-3.3,5.2
      c-0.9,1.7-1.6,3.6-2.1,5.4l1.7-0.5C27.1,50.9,27.7,49.4,28.3,48z"
      />
      <circle fill={circleFillColor} cx="115.6" cy="99.2" r="86.4" />
      <path
        fill="#3C3C64"
        d="M115.6,186.6c-48.2,0-87.3-39.2-87.3-87.4s39.2-87.3,87.3-87.3S203,51.1,203,99.2S163.7,186.6,115.6,186.6z
       M115.6,13.8c-47.1,0-85.4,38.3-85.4,85.4s38.3,85.4,85.4,85.4S201,146.4,201,99.2S162.7,13.8,115.6,13.8z"
      />
      <path
        fill="#FFFFFF"
        d="M153.8,32.2L153.8,32.2L153.8,32.2L153.8,32.2z M213.9,149.7c-0.8-25.6-8.4-44.2-8.4-44.2
      c5-5.1,5.4-12.8,5.4-12.8s1.1-0.1-0.2-7.3s-5.8-6.9-5.8-6.9c1-4.4-1.8-8.2-1.8-8.2c-0.1-1.8-0.4-3.5-0.8-5.3
      c-0.7-3.4-3.8-3.3-3.8-3.3l-0.5-1.1l-2.2-5.3l-2.2,0.6c-1.5-1.7-3.5-2.8-5.7-3.4l1.9,5.2l-2.2,0.4l0.2,2.7c-3-0.9-5.7,1.4-5.7,1.4
      l3.6,2.9l-0.2,1.1c0,0-6.8,10.6-8.4,15.8c-1.6,5.1,0.9,11,0.9,11s5.2,11.3,5.9,13c0.8,1.7,0.7,2.4,0.7,2.5l-0.4,14.3
      c-1.8-2.7-3.8-5.3-5.9-7.8c-3.3-3.8-8.6-5.2-8.6-5.2l-8.3-1c-0.7-2.5-2.4-4.7-4.8-5.8c-4.1-2.1-9.2-1.4-9.2-1.4l-2.8,2.7
      c-2.3-1.7-1.4-3.4-2.8-5.8c-0.9-1.5-2.4-2.4-4.1-2.7c0.9-6.7,4-14.1,4-14.1c16-5.4,25.4-16.1,28.6-20.7s5.2-18.2,2.1-21.2
      c-3.2-3-12-2-14.6-2.6s-3.9-5-4-5.3l0,0c-3.5-5.9-2.7-17.9-9.6-21.3s-17.8-0.8-20.7,3.7c-2.9,4.5-8.5,7.2-8.5,7.2
      c-7.2,2.3-18.3,1.3-18.3,1.3l-6.5-2.4c0,0-12-4.8-17.1,0.2s-4,13-3.2,16.7c0.6,2.5,1.5,4.8,2.6,7.1c-2.1,1.8-4.4,3.5-6.8,5.1
      c-4.2,2.8-14.6,3.1-16,2.3c-1.4-0.7-2.2-4.7-3.1-7.4c-0.8-2.7-3.7-2.1-5.7-1.2c-1.9,1-1,4.1-1,4.1l-2.7-0.9c-6,0-9,8.4-9,8.4
      l-1.6-2.2l-3,0.8c0,0-0.5-2.2-4.1-5.1s-6-2.4-6-2.4c7.8,5.5,6.9,12.4,6.9,12.4C19,58.6,17.6,61,17.6,61l-1.9,0.2c-4,1.9-4,7.8-4,7.8
      c-2.9,0.7-4.6,3.8-4.6,3.8c-3.7,7-0.8,13-0.8,13c4.3,6.9,7.4,14.5,9.4,22.3l1.7,25.4c0,0,0.3,7.6,4.9,22s13.9,20.4,13.9,20.4
      c5.7,1.6,12.6-1.4,12.6-1.4c12.6-5.5,19.5-13.7,19.5-13.7l2,10.4c11.2,6.9,23.9,11.2,37,12.5c2.7,0.3,5.2,0.4,7.7,0.4
      c1,0,1.9,0,2.9,0c0.7,0,1.4,0,2-0.1c13.2-0.6,21.7-4.1,21.7-4.1c2.2-1.9,8.7-15.8,10-21.3c1.4-5.5,4.1-7.6,4.1-7.6l9.6,8.9
      c0,0,22.1,25.3,27.5,27.7s15.3,1.2,17.7-1.2C213.1,184.6,214.7,175.3,213.9,149.7L213.9,149.7z M94.7,100.4
      c-5.5-0.7-11.2-0.2-16.5,1.3l-1.4,5.3c-4,0.3-6.9,4-9.2,6.5c-2.3,2.4-18.7,22.3-18.7,22.3s-5.7-13.2-7.5-16.7s-5.3-13.6-5.3-13.6
      V94.4c2.2-2.9,4.5-10,4.5-10C41.1,83.6,52,84,52,84c12.1,0.1,23.8,6.2,30.5,8.8c6.7,2.7,16.2,2.9,16.2,2.9l0.9,1.5
      C96.6,97.4,94.7,100.4,94.7,100.4z"
      />
      <path
        fill="#8764FF"
        d="M75.2,132.8c-7-4.9-15.2-11-15.2-11l4.5-5.1l10.6,6.8C75.1,126.1,75.1,129.5,75.2,132.8z"
      />
      <path
        fill="#8764FF"
        d="M85.8,130.4c12.5,1.3,28.2,2.5,40.3,3.3l-4,7.5l-0.3,0.6l-37.3-3.1c-1.3-0.7-2.6-1.4-3.9-2.3v-0.1l1-8.7
      L85.8,130.4z"
      />
      <path
        fill="#8764FF"
        d="M184,123.8c-14.2,2-37.6,20.1-37.6,20.1l-16.8-1.4l5.3-8.1c6,0.4,9.9,0.6,9.9,0.6c17.3-15,35.1-17.9,35.1-17.9
      L184,123.8z"
      />
      <path
        fill="#8764FF"
        d="M118.8,145.8c-1.1-0.3-22-2-35.7-3l0.1-1.5c35.6,2.8,36,3.1,36.2,3.2l-0.8,1.2
      C118.7,145.8,118.8,145.8,118.8,145.8z"
      />
      <path
        fill="#8764FF"
        d="M146.9,148.2L146.9,148.2l-17.8-1.5l0.1-1.5l17.4,1.5c16-14.5,36.8-21.1,37.1-21.2l0.4,1.4
      c-0.2,0.1-21,6.6-36.8,21.1C147.3,148.1,147.1,148.2,146.9,148.2z"
      />
      <path
        fill="#8764FF"
        d="M75.4,137.9c-0.7,0-2.5-1.2-9.3-6.2c-4.2-3.1-8.4-6.3-8.5-6.4l0.9-1.2c5.9,4.5,15.8,11.8,16.9,12.2
      c-0.2-0.1-0.5,0-0.6,0.2l1.1,1C75.8,137.7,75.6,137.9,75.4,137.9z"
      />
      <path
        fill="#8764FF"
        d="M29.7,169.8c0-0.4,2-4.7,2-4.7l14.7,10.2c0,0,5-1.9,7.5-3.2c0,0-12.5-11.1-25.4-14l-3.2,5L29.7,169.8z"
      />
      <path
        fill="#8764FF"
        d="M55.6,171.3c-14.3-12.3-26.1-14.8-28.3-15.2l-3.2,4.4l-1.2-0.9l3.4-4.7c0.2-0.2,0.4-0.3,0.7-0.3
      c0.5,0.1,13.2,1.6,29.5,15.6L55.6,171.3z"
      />
      <g id="_Group_">
        <path
          fill="#FFFFFF"
          d="M213.9,151.2c0,0,0.9,17.7-1.3,30.5c0,0-17.2,5.4-23.8,3.5l-15.2-15.8L213.9,151.2z"
        />
      </g>
      <path
        fill="#8764FF"
        d="M187.8,185.1c0,0,11.7-6.6,19.6-12.5l5.7,4.4l-1.4,7l-4-4.2l-12.8,8.3C195,188.1,190.4,186.8,187.8,185.1z"
      />
      <path
        fill="#8764FF"
        d="M186.2,183l-0.7-1.3c0.1-0.1,14.8-7.5,21-13.8c0.3-0.3,0.7-0.3,1,0l6.1,5.4l-1,1.1l-5.6-4.9
      C200.4,175.7,186.7,182.7,186.2,183z"
      />
      <path
        fill="#FFFFFF"
        d="M187.7,70c0,0,0.2-4.3-5.5-7.6c0,0,1.8-2,6.5-1.2l-0.9-2.9h2.6c0,0-0.2-2.9-2.9-5.7c0,0,7,0.7,8.5,9.8l0,0
      C195.9,62.5,190.6,65.1,187.7,70"
      />
      <path
        fill="#3C3C64"
        d="M187.7,70.8c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.5-0.4-0.5-0.7c0-0.2,0.1-3.9-5.2-6.9c-0.2-0.1-0.3-0.3-0.4-0.5
      c0-0.2,0-0.4,0.2-0.6c0.1-0.1,1.8-2,6-1.6l-0.6-1.8c-0.1-0.2,0-0.5,0.1-0.6c0.1-0.2,0.4-0.3,0.6-0.3h1.7c-0.5-1.7-1.3-3.2-2.5-4.5
      c-0.3-0.3-0.3-0.8,0-1c0.2-0.1,0.4-0.2,0.6-0.2c0.1,0,7.6,0.9,9.2,10.4c0.1,0.3-0.1,0.6-0.4,0.8c-0.1,0-5.2,2.6-8,7.3
      C188.2,70.6,187.9,70.8,187.7,70.8z M183.4,62.4c3,1.9,4.2,4.2,4.6,5.7c1.9-2.5,4.3-4.5,7-6c-1-5-3.8-7.2-5.7-8.1
      c0.9,1.3,1.4,2.8,1.6,4.3c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2h-1.6l0.6,2c0.1,0.2,0,0.5-0.2,0.7s-0.4,0.3-0.7,0.2
      C185.9,61.5,184.3,62,183.4,62.4z"
      />
      <path
        fill="#3C3C64"
        d="M215.5,152.8l-0.1-2.6v-1.3l-0.1-1.3l-0.2-2.6c-0.6-6.8-1.7-13.6-3.2-20.2c-0.8-3.3-1.6-6.6-2.5-9.9
      s-1.9-6.5-3.1-9.7v-0.1c-0.2-0.4-0.7-0.6-1.2-0.4l0,0l-1.2,0.6l-0.6,0.3l-0.6,0.2l-1.2,0.5l-1.2,0.4c-0.2,0.1-0.4,0.1-0.6,0.2
      l-0.6,0.2c-0.4,0.1-0.8,0.2-1.3,0.3c-0.9,0.2-1.7,0.3-2.6,0.4c-1.7,0.2-3.5,0.2-5.2,0.2c-1.6-0.1-3.3-0.3-4.8-0.6
      c-0.8-1.8-3.6-8.3-6.1-13.5c-2.3-4.7-2.3-10.1,0.1-14.7c2.5-4.8,5.3-9.4,8.3-13.8c0.2-0.3,0.2-0.8-0.1-1s-0.8-0.2-1,0.1
      c0,0,0,0,0,0.1c-3.1,4.5-5.9,9.1-8.4,14c-2.5,5-2.5,11-0.1,16c2.6,5.3,5.5,12,6.2,13.6c-0.2,3.9-0.4,7.8-0.6,11.6v0.5
      c-1.1-1.5-2.3-3-3.6-4.4c-0.9-1-1.9-1.9-2.9-2.8l-0.8-0.6l-0.8-0.6c-0.6-0.4-1.2-0.7-1.8-1l-0.5-0.2c-0.2-0.1-0.3-0.1-0.5-0.2
      c-0.3-0.1-0.6-0.2-1-0.3c-0.7-0.2-1.3-0.4-1.9-0.5c-1.3-0.3-2.6-0.5-3.9-0.6c-1.1-0.1-2.3-0.2-3.4-0.2c-0.2-0.6-0.5-1.2-0.8-1.7
      c-0.4-0.7-0.8-1.3-1.3-1.9c-0.8-0.9-1.7-1.7-2.8-2.2c-0.4-0.2-0.7-0.3-1.1-0.5c-1.4-0.6-2.9-1-4.4-1.2c-0.8-0.1-1.6-0.1-2.3-0.1
      c-0.4,0-0.8,0.1-1.2,0.1c-0.4,0.1-0.8,0.2-1.2,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.6,0.6-1.2,1.3-1.8,1.9c-0.1,0-0.1-0.1-0.1-0.1
      c-0.1-0.1-0.3-0.3-0.4-0.4c-0.3-0.3-0.5-0.7-0.6-1.1c-0.2-0.4-0.3-0.8-0.5-1.2l-0.2-0.7c-0.1-0.2-0.2-0.5-0.3-0.7
      c-0.4-1-1.1-1.8-1.9-2.5c-0.6-0.5-1.3-0.8-2-1.1c0.2-1.4,0.4-2.7,0.7-4.1c0.3-1.5,0.7-2.9,1.2-4.3s1-2.8,1.6-4.1l0,0
      c0-0.1,0.1-0.2,0.1-0.3c3.1-1.4,6.2-2.8,9.3-4.3l0.6-0.3l0.4,0.7c0.7,1.1,1.2,2.3,1.8,3.5s1.1,2.4,1.6,3.6L158,90
      c0,0.1,0.2,0.2,0.3,0.1c0.1,0,0.2-0.2,0.2-0.3l-1.1-3.8c-0.5-1.3-0.9-2.5-1.3-3.8c-0.5-1.2-1-2.5-1.6-3.7l-0.3-0.7
      c2.7-1.4,5.3-2.9,7.8-4.6c1.6-1.1,3.1-2.3,4.5-3.6l1-1l0.5-0.5l0.5-0.6c0.3-0.4,0.6-0.7,0.9-1.1l0.8-1.2c2.2-3.2,3.8-6.7,4.6-10.5
      c0.4-1.9,0.7-3.8,0.8-5.7c0-0.5,0-1,0-1.5l-0.1-1.5c-0.1-0.5-0.1-1-0.2-1.5s-0.2-1-0.3-1.5s-0.3-1-0.6-1.5c-0.1-0.2-0.2-0.5-0.4-0.7
      l-0.5-0.7c-0.7-0.8-1.5-1.5-2.5-2c-0.9-0.4-1.9-0.7-2.9-0.9c-1-0.1-1.9-0.2-2.9-0.2c-0.9,0-1.9,0-2.8,0.1c-0.9,0-1.8,0-2.7,0
      c-1,0-1.9-0.2-2.9-0.3c-0.5-0.5-1-1.1-1.3-1.7c-0.4-0.8-0.8-1.7-1.1-2.5c-0.6-1.7-1.1-3.5-1.6-5.3s-1-3.6-1.5-5.4
      c-0.3-0.9-0.6-1.8-0.9-2.7l-1.1-2.6c-0.8-1.7-1.6-3.5-3-5c-0.7-0.7-1.5-1.4-2.4-1.8C143,9.4,142,9.1,141,8.9
      c-1.9-0.3-3.9-0.3-5.8-0.1c-1.9,0.2-3.8,0.5-5.7,1.1c-0.9,0.3-1.9,0.7-2.7,1.2c-0.9,0.5-1.7,1.1-2.4,1.8c-1.4,1.4-2.4,3-3.7,4.3
      c-0.6,0.7-1.3,1.4-1.9,1.9c-0.7,0.6-1.4,1.1-2.2,1.6c-1.6,0.9-3.3,1.5-5.1,1.7c-1.8,0.3-3.7,0.5-5.5,0.6c-1.9,0.1-3.7,0.1-5.6,0.1
      c-1.3,0-2.7-0.1-4-0.1l-0.1-0.1c-1.8-0.9-3.7-1.7-5.7-2.4c-1-0.3-1.9-0.6-2.9-0.9s-2-0.5-3-0.7s-2.1-0.3-3.1-0.3
      c-1-0.1-2.1,0-3.1,0.1c-2.1,0.2-4.1,1.1-5.7,2.5c-1.6,1.5-2.7,3.3-3.2,5.4c-0.5,2-0.7,4.1-0.6,6.2s0.4,4.2,1,6.2
      c0.5,2,1.3,4,2.3,5.8c-0.5,0.5-1,1-1.6,1.5c-2.5,2.2-5.6,3.6-8.7,4.7c-1.5,0.5-3.1,0.9-4.8,1.1c-1.6,0.2-3.2,0.1-4.8-0.2
      c-0.9-0.2-1.9-0.5-2.7-0.8c-0.2-0.4-0.4-0.8-0.5-1.3c-0.3-0.7-0.5-1.5-0.7-2.2s-0.4-1.5-0.6-2.3c-0.1-0.4-0.2-0.8-0.4-1.1
      c-0.2-0.5-0.4-0.9-0.8-1.2c-0.4-0.3-0.8-0.6-1.3-0.7c-0.4-0.1-0.9-0.1-1.4-0.1c-0.9,0.1-1.7,0.2-2.5,0.6c-0.2,0.1-0.4,0.2-0.6,0.3
      l-0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4,0.4-0.6,0.8-0.8,1.3c-0.1,0.4-0.2,0.7-0.2,1.1c-0.3-0.1-0.6-0.1-0.9-0.1
      c-2-0.1-3.9,0.4-5.6,1.6c-1.5,1.1-2.8,2.5-3.7,4.1c-0.4,0.7-0.8,1.4-1.1,2.1l-0.7-1c-0.1-0.1-0.2-0.2-0.3-0.2
      c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.3,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.2,0.1-0.4,0.1
      c-0.1,0-0.3,0.1-0.4,0.1S24.3,53,24.2,53l-0.6-1c-0.4-0.5-0.7-0.9-1.1-1.4c-0.4-0.4-0.8-0.8-1.2-1.2c-0.2-0.2-0.4-0.4-0.7-0.6
      l-0.7-0.5c-0.9-0.7-2-1.2-3.1-1.6c-1.1-0.4-2.2-0.6-3.4-0.6c1.1,0.1,2.2,0.4,3.2,0.9s1.9,1.1,2.7,1.9l0.6,0.6
      c0.2,0.2,0.4,0.4,0.6,0.6c0.4,0.4,0.7,0.9,1,1.3c0.3,0.4,0.6,0.9,0.9,1.4l0.8,1.5l0,0c0.2,0.3,0.5,0.4,0.8,0.3l2.2-0.7l1,1.5
      c-0.1,0.4-0.2,0.9-0.2,1.3c-0.1,0.5-0.1,1-0.1,1.5c0,0.3,0,0.5,0.1,0.8c-1.6-0.5-3.2-0.9-4.8-1c-0.3,0-0.6,0-0.9,0
      c0-0.4-0.1-0.8-0.1-1.3c-0.1-0.7-0.3-1.3-0.5-1.9c-0.4-1.2-1-2.4-1.7-3.5c-0.4-0.5-0.8-1-1.2-1.5c-0.2-0.2-0.4-0.5-0.7-0.7l-0.7-0.6
      c-0.2-0.2-0.5-0.4-0.7-0.6l-0.8-0.6c-0.5-0.4-1.1-0.7-1.6-1c0.5,0.4,1,0.8,1.5,1.2l0.7,0.7c0.2,0.2,0.4,0.4,0.7,0.7l0.6,0.7
      c0.2,0.2,0.4,0.5,0.6,0.7c0.4,0.5,0.7,1,1,1.5c0.6,1.1,1,2.2,1.3,3.4c0.1,0.6,0.2,1.2,0.3,1.8c0,0.5,0,1,0,1.5
      c-1.1,0.5-1.9,1.3-2.5,2.3c-0.5,0-1.1,0-1.6,0.2l-0.2,0.1l-0.2,0.1l-0.4,0.2c-0.3,0.2-0.5,0.3-0.8,0.5c-0.2,0.2-0.5,0.4-0.7,0.6
      c-0.2,0.2-0.4,0.5-0.6,0.7c-0.7,0.9-1.2,2-1.6,3.1c-0.3,0.8-0.5,1.7-0.7,2.6c-0.8,0.4-1.6,0.9-2.3,1.5c-0.6,0.6-1.2,1.3-1.6,2.1
      C6,74,5.3,75.5,5,77.2l-0.3,1.2c-0.1,0.4-0.1,0.9-0.2,1.3S4.4,80.6,4.4,81c0,0.2,0,0.4,0,0.6L4.3,82c0.2,1.7,0.6,3.3,1.2,4.8
      s1.4,3,2.2,4.4s1.7,2.7,2.5,4.1c0.4,0.7,0.8,1.3,1.2,2s0.7,1.3,1,2c1.2,2.8,2.1,5.7,2.5,8.8c0.6,6.1,0.9,12.2,1.1,18.4l0.1,2.3
      c0,0.8,0,1.6,0.1,2.3c0.1,1.6,0.3,3.1,0.5,4.6c0.4,3.1,1,6.1,1.7,9.2c1.4,6.1,3.4,12,6,17.6c1.3,2.8,2.9,5.5,4.8,8
      c0.9,1.3,2,2.5,3.1,3.6s2.4,2.1,3.7,3c0,0,0.1,0,0.1,0.1c0.6,0.2,1.3,0.4,1.9,0.4c0.6,0.1,1.3,0.1,1.9,0.2c1.3,0.1,2.5,0,3.8-0.2
      c2.5-0.4,4.9-1.1,7.1-2.2c1.1-0.5,2.2-1.1,3.2-1.7c1.1-0.6,2.1-1.2,3.1-1.9c2.1-1.3,4-2.7,5.8-4.4c0.1-0.1,0.1-0.3,0-0.3
      c-0.1-0.1-0.2-0.1-0.3,0l0,0c-1,0.6-2,1.2-3.1,1.8c-1,0.6-2.1,1.1-3.2,1.7l-3.2,1.6c-1.1,0.5-2.1,1.1-3.2,1.6
      c-1.1,0.5-2.1,0.9-3.2,1.3c-0.5,0.2-1.1,0.4-1.7,0.5s-1.1,0.3-1.7,0.4c-1.1,0.2-2.3,0.4-3.4,0.4c-0.6,0-1.1,0-1.7-0.1
      c-0.5-0.1-1-0.2-1.5-0.3c-1.2-0.8-2.3-1.8-3.4-2.8c-1-1.1-2-2.2-2.9-3.4c-1.8-2.5-3.4-5.1-4.7-7.8c-2.6-5.5-4.5-11.3-5.8-17.3
      c-0.7-3-1.2-6-1.6-9c-0.2-1.5-0.3-3-0.4-4.5c0-0.8-0.1-1.5-0.1-2.3v-2.3c-0.1-5.8-0.4-11.6-0.8-17.4c0.5,0,1,0.1,1.5,0.1h1.3
      c0.4,0,0.9,0,1.3,0h1.3c0.5,0,0.9,0,1.3-0.1c0.9,0,1.8-0.2,2.7-0.2c1.8-0.2,3.5-0.6,5.3-1.1c0.4-0.1,0.9-0.2,1.3-0.4
      c0.4-0.2,0.9-0.3,1.3-0.5c0.4-0.2,0.8-0.3,1.2-0.5c0.4,1.5,0.9,3,1.4,4.5c0.7,1.9,1.4,3.8,2.3,5.7c0.4,0.9,0.9,1.9,1.3,2.8
      s1,1.8,1.4,2.7c0.9,1.8,1.8,3.6,2.6,5.4c1.3,3,2.4,6,3.5,9.1c0,0.1,0,0.2,0.1,0.3c0.2,0.6,0.4,1.2,0.6,1.9c1.2,3.8,2.5,7.6,3.2,11.6
      c0,0.1,0.1,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.3c-0.1-2-0.4-4-0.9-6c-0.4-2-0.9-4-1.5-5.9l-0.2-0.6c0,0,0,0,0.1,0s0.2,0,0.3,0
      c0.2,0,0.4,0,0.6,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.4,0.3,0.6,0.4c0.2,0.2,0.4,0.3,0.6,0.5l0.6,0.6
      l0.5,0.6c0.2,0.2,0.3,0.4,0.5,0.7c0.3,0.5,0.6,0.9,0.9,1.4l0.4,0.7c0.1,0.2,0.3,0.5,0.4,0.8c0.5,1,1,2,1.5,3.1
      c0.1,0.1,0.2,0.2,0.3,0.1c0.1,0,0.2-0.2,0.1-0.3c-0.4-1.1-0.8-2.2-1.2-3.2c-0.1-0.3-0.2-0.5-0.3-0.8l-0.4-0.8
      c-0.2-0.5-0.5-1-0.8-1.6c-0.1-0.3-0.3-0.5-0.5-0.8l-0.5-0.7l-0.6-0.7c-0.2-0.2-0.4-0.4-0.6-0.7c-0.2-0.2-0.5-0.4-0.7-0.6
      c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.2c-0.3-0.1-0.6-0.2-1-0.3c2.6-3.2,11.6-14,14.5-17.8c2.6-3.3,6.8-8.8,11-9.9
      c-0.1,0.7-0.2,1.4-0.3,2.1c-0.4,3.1-0.7,6.1-0.8,9.2l-0.1,2.3c0,0.8,0,1.5,0,2.3c0,1.5,0,3.1,0,4.6c0,1.5,0.1,3.1,0.2,4.6l0.3,4.2
      c-0.5,0.4-0.8,1-1.1,1.5c-0.3,0.6-0.6,1.3-0.7,2c0,0.1,0,0.2,0.2,0.3c0.1,0,0.3,0,0.3-0.1c0.4-1.3,1.2-2.4,2.2-3.2
      c0.5-0.4,1.1-0.6,1.7-0.7c0.6-0.1,1.2,0,1.8,0.3c1.1,0.6,2,1.6,2.5,2.7c0.6,1.2,0.9,2.5,1,3.8c0,0.7,0,1.3-0.1,2
      c-0.1,0.6-0.3,1.3-0.5,1.9c-0.4,1.2-1.2,2.2-2.3,2.9c-1.1,0.6-2.4,0.5-3.5-0.2s-2-1.8-2.5-3c-0.1-0.1-0.2-0.2-0.3-0.1
      c-0.1,0-0.2,0.2-0.2,0.3c0.4,1.4,1.3,2.7,2.5,3.6c0.6,0.5,1.4,0.8,2.2,0.8c0.8,0.1,1.6-0.1,2.3-0.4c0.7-0.4,1.3-0.9,1.8-1.5
      s0.8-1.3,1.1-2c0.3-0.7,0.5-1.4,0.6-2.2c0.1-0.7,0.1-1.5,0.1-2.2c-0.1-1.5-0.5-2.9-1.1-4.3c-0.5-1-1.3-1.9-2.2-2.6
      c0-0.5,0.1-1,0.1-1.5c0-0.7,0.1-1.5,0.2-2.2l0.1-1.1l0.1-1.1c0.1-0.7,0.2-1.5,0.3-2.2c0.4-2.9,0.9-5.9,1.6-8.8s1.5-5.7,2.4-8.6
      c0.5-1.4,1-2.8,1.5-4.2c0.2-0.5,0.4-1.1,0.6-1.6c1.4-0.3,2.8-0.5,4.2-0.6c0.1,0.1,0.3,0.2,0.4,0.2c0.5,0.1,1-0.2,1.1-0.8
      c0.2-0.8,0.4-1.7,0.8-2.5c0.3-0.8,0.8-1.6,1.3-2.3c0.5-0.7,1.2-1.3,1.9-1.7c0.5-0.3,1.1-0.5,1.7-0.7c0.1,0.4,0.2,0.8,0.3,1.1
      c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4,0.4-0.6,0.8-0.8,1.3c-0.2,0.5-0.2,1-0.2,1.5c0,0.2,0,0.5,0,0.7s0,0.5,0.1,0.7c0.1,0.5,0.3,1,0.6,1.4
      c0.5,0.7,1.2,1.4,2,1.8c0.6,0.4,1.2,0.7,1.9,0.9l1.2,10.3c0,0,0,0.1,0.1,0.1s0.2,0,0.2,0c1-1.5,1.9-3,2.9-4.5
      c0.5-0.8,0.9-1.5,1.4-2.3c0.3-0.6,0.7-1.2,1-1.8c2.5,0.1,5,0.1,7.5-0.1c1.4-0.1,2.7-0.2,4.1-0.4c1.3-0.2,2.7-0.4,4-0.7
      c2.7-0.5,5.3-1.3,7.8-2.4c1.3-0.6,2.5-1.3,3.6-2.1c0.6-0.5,1.1-1,1.6-1.6c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.2,0.4-0.5,0.4-0.8l0,0
      c0-0.1,0-0.2,0-0.4c0-0.2,0.1-0.5,0.1-0.7v-0.1c0.1-0.9,0.1-1.7,0.2-2.6l0.3,0.2c0.4,0.2,0.7,0.5,1,0.7c0.6,0.6,1.1,1.3,1.4,2.1
      c0.1,0.2,0.1,0.4,0.2,0.6l0.2,0.7c0.1,0.5,0.3,1,0.5,1.5s0.5,1,0.8,1.4c0.2,0.2,0.4,0.5,0.6,0.6l0.1,0.1c-1.7,2-3.3,4.1-4.8,6.2
      c-2.3,3.3-4.5,6.7-6.5,10.1c-2.1,3.4-4,6.9-5.9,10.4s-3.7,7.1-5.5,10.7c0,0,0,0,0,0.1l-0.3,0.3c-0.1,0.1-0.1,0.2,0,0.3
      s0.2,0.1,0.3,0c0.6-0.5,1.2-0.8,1.9-1.1c0.7-0.3,1.4-0.4,2.1-0.4c1.4,0,2.6,0.9,3,2.2s0.5,2.8,0.2,4.2s-0.9,2.8-1.7,4
      c-0.4,0.6-0.8,1.2-1.3,1.7s-1.1,1-1.7,1.3c-1.2,0.7-2.6,1.1-3.8,0.6c-1.2-0.5-1.9-1.8-2.1-3.2c-0.2-1.5-0.1-3,0.4-4.4
      c0-0.1,0-0.3-0.2-0.3c-0.1,0-0.2,0-0.3,0.1c-0.6,1.5-0.9,3.1-0.8,4.7c0.1,0.8,0.3,1.6,0.7,2.4c0.4,0.8,1.1,1.4,1.9,1.8
      c0.8,0.4,1.7,0.5,2.6,0.3c0.8-0.1,1.6-0.4,2.4-0.9c0.7-0.4,1.4-0.9,1.9-1.5c0.6-0.6,1.1-1.2,1.5-1.9c0.9-1.4,1.5-2.9,1.9-4.5
      c0.2-0.8,0.2-1.6,0.2-2.4c0-0.7-0.2-1.4-0.4-2.1c1.9-3.3,3.9-6.6,6.1-9.7s4.5-6.2,6.9-9.2s5-5.8,7.7-8.5c2.3-2.3,4.8-4.5,7.5-6.4
      c0.1,0,0.1,0,0.2,0c2.6,0,5.2,0.1,7.8,0.4c1.3,0.2,2.5,0.4,3.8,0.7c0.6,0.1,1.2,0.3,1.8,0.5c0.3,0.1,0.6,0.2,0.9,0.3
      c0.1,0.1,0.3,0.1,0.4,0.2l0.4,0.2c0.5,0.3,1.1,0.6,1.6,0.9l0.7,0.6c0.2,0.2,0.5,0.4,0.7,0.6c0.9,0.9,1.8,1.8,2.7,2.7
      c1.7,1.9,3.2,4,4.6,6.1l-0.1,2.6l-0.2,5.8c-0.1,3.9-0.2,7.8-0.3,11.6c0,0.8,0,1.7,0,2.5c-0.4,0.1-0.8,0.3-1.1,0.6
      c-0.4,0.4-0.7,0.7-1,1.2c-0.5,0.8-1,1.7-1.3,2.6c-0.7,1.8-1.3,3.6-1.7,5.5c0,0.1,0,0.1,0.1,0.2c0.1,0,0.1,0,0.2-0.1
      c0.5-1.8,1.2-3.6,2-5.4c0.4-0.9,0.9-1.7,1.4-2.4c0.3-0.4,0.6-0.7,0.9-1c0.2-0.1,0.3-0.2,0.5-0.3c0,2.7-0.1,5.4-0.1,8.2
      c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.3-0.2c0.3-3.9,0.6-7.7,0.8-11.6c0.2-3.9,0.5-7.7,0.7-11.6l0.3-5.8l0.3-5.8
      c0.2-3.5,0.3-7.1,0.4-10.7c1.5,0.3,3,0.5,4.5,0.5c1.8,0.1,3.7,0,5.5-0.1c0.9-0.1,1.8-0.2,2.7-0.4c0.5-0.1,0.9-0.2,1.3-0.3l0.7-0.2
      c0.2-0.1,0.4-0.1,0.7-0.2l1.3-0.4l1.3-0.5l0.6-0.3l0.6-0.3l0.3-0.1c1.8,6.1,3.3,12.4,4.5,18.6c1.3,6.6,2.2,13.2,2.7,19.9l0.1,2.5
      l0.1,1.3v1.2v2.5c0,0.8,0,1.7,0,2.5c0,3.4-0.1,6.8-0.2,10.1c-0.1,3.4-0.2,6.7-0.5,10.1c-0.1,1.7-0.3,3.3-0.5,4.9
      c-0.2,1.5-0.6,3-1.1,4.4c-0.1,0.1-0.1,0.3-0.2,0.4l-0.1,0.2c-0.1,0.1-0.2,0.3-0.3,0.4l-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1
      c-0.6,0.4-1.2,0.7-1.9,0.9c-1.5,0.5-3,0.9-4.6,1.1c-1.6,0.2-3.2,0.3-4.8,0.2s-3.1-0.5-4.5-1.1c-0.3-0.2-0.7-0.3-1-0.5
      c-0.2-0.1-0.3-0.2-0.5-0.3s-0.3-0.2-0.5-0.4l-0.9-0.8c-0.3-0.3-0.6-0.6-0.9-0.9c-0.6-0.6-1.2-1.2-1.8-1.8s-1.2-1.2-1.8-1.8
      c-2.4-2.4-4.7-4.9-7-7.3c-4.6-4.9-9.3-9.9-14-14.8c-2.4-2.4-4.6-5-7.2-7.2c-1.3-1.1-2.6-2.2-3.9-3.3c-0.7-0.5-1.4-1-2.1-1.5
      c-0.4-0.2-0.7-0.5-1.1-0.7c-0.4-0.2-0.8-0.4-1.1-0.6c-0.1-0.1-0.3,0-0.3,0.1s0,0.2,0,0.3c1.9,1.7,3.7,3.5,5.4,5.4
      c-0.5,0.5-1,1.1-1.4,1.7c-0.4,0.6-0.8,1.3-1.1,1.9c-0.6,1.3-1.1,2.6-1.5,4c-0.1,0.3-0.2,0.7-0.3,1l-0.1,0.3l-0.1,0.2l-0.2,0.5
      l-0.6,1.9l-0.7,1.9c-0.2,0.6-0.5,1.2-0.7,1.9c-0.9,2.5-2,4.9-3.2,7.3c-0.6,1.2-1.2,2.4-1.9,3.5s-1.4,2.2-2.2,3.3l0,0
      c-0.3,0.4-0.3,1.1,0.2,1.4c0.4,0.3,1.1,0.3,1.4-0.2c0,0,0-0.1,0.1-0.1c0.8-1.2,1.5-2.3,2.2-3.5s1.3-2.4,2-3.6
      c1.2-2.4,2.4-4.9,3.4-7.4c0.3-0.6,0.5-1.3,0.8-1.9l0.7-1.9l0.7-1.9l0.2-0.5c0-0.1,0-0.1,0.1-0.3l0.1-0.2c0.1-0.3,0.2-0.6,0.3-0.9
      c0.4-1.2,1-2.4,1.6-3.5c0.5-1,1.3-1.9,2.3-2.5c0,0,0.1,0,0.1-0.1c1,1.1,2,2.1,3.1,3.2c1.2,1.2,2.4,2.4,3.6,3.6
      c4.7,4.8,9.3,9.8,14,14.7l7,7.4c0.6,0.6,1.2,1.2,1.8,1.8s1.2,1.2,1.8,1.8c0.3,0.3,0.6,0.6,0.9,0.9l1,0.9c0.3,0.3,0.7,0.6,1.1,0.9
      c0.4,0.3,0.8,0.5,1.2,0.7c1.6,0.8,3.4,1.4,5.2,1.7s3.6,0.4,5.4,0.3c1.8-0.1,3.6-0.4,5.4-0.9c1-0.3,1.9-0.7,2.7-1.2
      c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.2,0.4-0.4,0.6-0.7c0.1-0.1,0.2-0.2,0.3-0.4l0.2-0.4
      c0.1-0.2,0.3-0.5,0.4-0.7c0.7-1.7,1.1-3.5,1.3-5.4c0.2-1.7,0.4-3.5,0.5-5.2c0.2-3.4,0.3-6.8,0.4-10.2s0.1-6.8,0.1-10.2
      C215.5,154.5,215.5,153.7,215.5,152.8z M40.6,45.3c0.1-0.3,0.2-0.5,0.4-0.7l0.1-0.1l0.2-0.1c0.1-0.1,0.3-0.2,0.5-0.2
      c0.6-0.3,1.3-0.5,2-0.6c0.6-0.1,1.2,0,1.7,0.4c0.4,0.3,0.6,1.1,0.9,1.8c0.2,0.7,0.5,1.5,0.7,2.2s0.5,1.5,0.8,2.2l0.1,0.3l-0.4-0.2
      c-1.5-0.7-2.9-1.6-4.4-2.5c-0.8-0.4-1.5-0.9-2.3-1.2l-0.3-0.1C40.5,46.1,40.5,45.7,40.6,45.3z M29.4,57c0.2-1.5,0.8-3,1.6-4.3
      c0.8-1.3,1.8-2.5,3-3.4c1.1-0.9,2.5-1.3,4-1.3c1.4,0.1,2.8,0.8,4.3,1.6s3,1.6,4.6,2.4c0.8,0.4,1.6,0.7,2.5,1
      c0.8,0.3,1.7,0.5,2.6,0.6c1.7,0.3,3.5,0.3,5.3,0.1c1.7-0.3,3.4-0.7,5-1.4c0.8-0.3,1.6-0.7,2.4-1.1c0.8-0.4,1.5-0.8,2.3-1.3
      c1.5-0.9,2.8-1.9,4.1-3.1c1.3-1.1,2.5-2.4,3.6-3.6c0.5-0.6,1-1.1,1.4-1.7c0.4,0.3,0.8,0.6,1.3,0.7c0.6,0.2,1.2,0.3,1.8,0.3
      c1.2,0,2.4-0.2,3.6-0.7c0.6-0.2,1.1-0.6,1.6-1c0.2-0.2,0.4-0.5,0.6-0.7c0.2-0.3,0.3-0.6,0.3-1s-0.1-0.7-0.3-1
      c-0.2-0.3-0.5-0.5-0.7-0.6c-0.5-0.3-1.1-0.4-1.7-0.5c-0.1,0-0.2,0.1-0.2,0.1c0,0.1,0,0.2,0.1,0.2l0,0c0.5,0.1,1.1,0.3,1.5,0.6
      c0.4,0.2,0.7,0.6,0.7,1.1c-0.1,0.5-0.3,0.9-0.8,1.2c-0.4,0.3-0.9,0.6-1.4,0.8c-1,0.4-2.1,0.5-3.2,0.5c-0.5,0-1.1-0.1-1.6-0.3
      c-0.4-0.1-0.7-0.3-1-0.5c0.4-0.5,0.8-1,1.2-1.5c1-1.3,2-2.6,3.2-3.8s2.5-2.2,3.9-3l0,0c0.1-0.1,0.2-0.2,0.1-0.3
      C84.7,32,84.6,32,84.4,32c-1,0.4-1.9,0.8-2.7,1.4l0.3-3.8c0.1-1.4,0.2-2.9-0.2-4.4c-0.1-0.4-0.2-0.8-0.4-1.1
      c-0.2-0.4-0.4-0.8-0.7-1.1c-0.7-0.7-1.7-1-2.6-0.8c-0.8,0.1-1.6,0.5-2.3,1c-0.6,0.5-1.2,1-1.7,1.6c-1.8,2.5-2.7,5.4-2.6,8.5
      c0,2.9,0.7,5.8,2.1,8.4c0.1,0.1,0.2,0.2,0.3,0.1c0.1-0.1,0.2-0.2,0.1-0.3l0,0c-0.8-2.7-1.2-5.4-1.1-8.2c0.1-1.3,0.3-2.7,0.7-4
      c0.1-0.3,0.2-0.6,0.3-0.9l0.4-0.9c0.1-0.3,0.3-0.6,0.5-0.8c0.2-0.3,0.3-0.6,0.5-0.8c0.8-1,1.8-1.8,2.9-2c0.5-0.1,1,0,1.3,0.3
      c0.4,0.4,0.7,0.9,0.8,1.4c0.3,1.3,0.5,2.7,0.5,4c0.1,1.4,0.1,2.8,0.3,4.2c-0.4,0.3-0.8,0.6-1.2,0.9c-1.3,1.2-2.4,2.5-3.4,3.9
      c-0.5,0.7-1,1.4-1.5,2.1c-0.5,0.7-1,1.4-1.5,2c-0.3,0.5-0.7,0.9-1,1.3c-0.8-1.7-1.5-3.5-1.9-5.3c-0.5-1.9-0.8-3.9-0.9-5.9
      s0.1-4,0.5-5.9c0.2-1,0.5-1.9,0.9-2.8s0.9-1.7,1.6-2.4c1.5-1.3,3.3-2.1,5.3-2.3c2-0.1,4,0,6,0.4c3.5,0.8,7,1.8,10.4,3.1
      c-1.9-0.1-3.7-0.4-5.6-0.7l0,0c-0.1,0-0.3,0.1-0.3,0.2s0.1,0.3,0.2,0.3l0,0c3.7,0.7,7.4,1.1,11.2,1.2c1.9,0.1,3.8,0.1,5.6,0
      c1.9,0,3.8-0.2,5.7-0.5c1.9-0.3,3.8-0.9,5.5-1.7c0.9-0.5,1.7-1,2.4-1.7c0.7-0.6,1.4-1.3,2.1-2c1.4-1.3,2.5-2.9,3.8-4.1
      s2.9-2,4.6-2.4c1.7-0.5,3.5-0.8,5.3-1s3.6-0.2,5.4,0c1.7,0.2,3.3,1,4.6,2.2c1.2,1.2,2,2.8,2.8,4.5l1.2,2.5c0.4,0.8,0.7,1.7,1,2.6
      c0.6,1.8,1.1,3.5,1.7,5.3s1.2,3.6,1.9,5.3c0.4,0.9,0.8,1.7,1.3,2.6c0.3,0.4,0.6,0.8,0.9,1.2c-0.5-0.1-1-0.2-1.5-0.4l0,0
      c-0.1,0-0.2,0-0.3,0.1s0,0.3,0.1,0.3c1.7,0.7,3.6,1.2,5.4,1.5c0.9,0.1,1.9,0.2,2.8,0.3c0.9,0,1.8,0.1,2.7,0.2
      c0.9,0.1,1.8,0.2,2.6,0.4s1.6,0.5,2.3,0.8c0.7,0.4,1.3,0.9,1.7,1.5l0.3,0.5c0.1,0.2,0.2,0.4,0.2,0.5c0.2,0.4,0.3,0.7,0.4,1.1
      c0.1,0.4,0.1,0.8,0.2,1.2c0.1,0.4,0.1,0.9,0.1,1.3v1.3c0,0.4,0,0.9-0.1,1.3c-0.3,3.5-1.2,7-2.7,10.2c-0.7,1.6-1.6,3.1-2.6,4.6
      l-0.8,1.1c-0.3,0.3-0.6,0.7-0.8,1l-0.4,0.5l-0.5,0.5l-0.9,1c-1.3,1.2-2.6,2.4-4.1,3.4c-2.4,1.7-4.8,3.2-7.4,4.7l-0.5-0.9
      c-0.3-0.6-0.7-1.2-1.1-1.8c1-0.4,1.9-0.9,2.8-1.3c1.5-0.8,3-1.7,4.4-2.8c1.4-1.1,3-2,4.1-3.4c1.2-1.4,2.2-2.9,2.9-4.6
      c0.7-1.7,1.2-3.5,1.4-5.3s0.1-3.7-0.3-5.5c-0.2-0.9-0.4-1.8-0.7-2.7c-0.1-0.4-0.3-0.9-0.5-1.3c-0.1-0.2-0.2-0.4-0.3-0.6
      c-0.1-0.2-0.2-0.4-0.3-0.7l0,0c0.9,0.1,1.9,0,2.7-0.4c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2-0.3-0.2l0,0l0,0c-0.5,0-0.9,0-1.4,0
      s-0.9-0.1-1.4-0.1c-0.9-0.1-1.8-0.2-2.8-0.3l0,0c-0.1,0-0.3,0.1-0.3,0.2c0,0.1,0,0.2,0.1,0.2c0.2,0.1,0.4,0.2,0.6,0.3
      c-0.7,0.6-1.5,1.2-2.3,1.8c-0.8,0.5-1.7,1-2.6,1.5c-1.3,0.7-2.7,1.3-4.1,1.8c-0.1-0.3-0.2-0.5-0.3-0.8c-0.3-0.6-0.6-1.2-1.1-1.6
      h-0.1c-0.1,0-0.1,0.1-0.1,0.1l0,0c0.1,0.6,0.2,1.2,0.2,1.8c0,0.3,0,0.6,0,0.9v0.1l0,0c-1.9,0.7-3.8,1.2-5.7,1.7l-1.5,0.3l-1.5,0.2
      c-1,0.2-2,0.3-3,0.5c-2,0.3-4,0.8-6,1.3c-0.4,0.1-0.8,0.2-1.1,0.4c0-0.2,0-0.3,0.1-0.5c0.1-0.6,0-1.2-0.1-1.8s-0.2-1.2-0.5-1.7
      c-0.2-0.5-0.5-1-0.9-1.5c0,0-0.1-0.1-0.1,0c-0.1,0-0.1,0.1-0.1,0.1l0,0l0,0c0.2,0.5,0.3,1.1,0.4,1.6c0.1,0.5,0.1,1,0.1,1.6
      c0,0.5-0.1,1-0.2,1.5c-0.1,0.5-0.2,0.9-0.3,1.3c-1,0.4-2,1-2.9,1.6s-1.7,1.3-2.4,2.1c-0.7,0.7-1.4,1.4-2.1,2s-1.5,1.2-2.3,1.7
      s-1.7,0.9-2.6,1.3c-1.8,0.7-3.7,1.2-5.6,1.4c-0.5,0-1,0.1-1.5,0.1h-0.7h0.3c-0.9-0.1-1.9-0.2-2.8-0.5c-1.9-0.6-3.7-1.3-5.5-2.2
      s-3.8-1.7-5.9-2.1c-0.8-0.1-1.6-0.2-2.4-0.1c0,0,0-0.1,0-0.2c0.1-0.5,0.2-1.1,0.2-1.6s0-1.1-0.2-1.6c-0.1-0.5-0.3-1-0.5-1.5
      l-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1l0,0c0,0.5,0,1,0,1.5s-0.1,0.9-0.2,1.4c-0.1,0.4-0.3,0.9-0.4,1.3c-0.1,0.3-0.2,0.5-0.3,0.8
      c-0.8,0.2-1.6,0.4-2.3,0.8c-1.9,0.8-3.7,1.8-5.5,2.7c-1.7,1-3.5,1.8-5.4,2.4c-1.8,0.4-3.7,0.5-5.5,0.3l-2.2-2l0,0
      c-0.1-0.1-0.2-0.1-0.3-0.1l0,0l-5.3,2.9L65.7,67c-1.4-1-2.9-1.8-4.4-2.6c-1.6-0.7-3.2-1.2-4.9-1.6c-3.3-0.8-6.7-1.3-10.1-1.5
      c-1.7-0.1-3.4-0.1-5.1,0c-1.7,0.1-3.4,0.3-5.1,0.8c-0.4,0.1-0.7,0.2-1.1,0.4c0-0.1-0.1-0.2-0.2-0.2l0,0c-0.8-0.2-1.6-0.5-2.3-0.8
      s-1.3-0.6-1.9-1.1c-0.5-0.4-0.8-0.9-1-1.5C29.3,58.3,29.2,57.6,29.4,57z M145.8,62.6c0.1,0.8,0.3,1.5,0.4,2.3c0,0.1,0.1,0.1,0.1,0.1
      c0.1,0,0.1-0.1,0.1-0.1c0-0.8,0-1.5-0.1-2.3l0.8-0.1l1.8,0.6l0,0c0.2,0.1,0.3,0,0.4-0.1c0.7-1.2,1.5-2.4,2.2-3.5
      c0.4-0.6,0.8-1.2,1.2-1.7c0.2-0.3,0.5-0.7,0.8-1l0.4,0.3c0.3,0.3,0.6,0.6,0.9,0.9c0.6,0.6,1.2,1.2,1.7,1.9l0,0V60
      c0.1,0.1,0.2,0.2,0.4,0.1c0.4-0.1,0.8-0.3,1.2-0.4c0.4-0.2,0.8-0.3,1.2-0.5c0.1-0.1,0.2-0.2,0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.1l0,0
      c-0.7,0.3-1.3,0.5-2,0.6c-0.4-0.7-0.8-1.4-1.3-2c-0.3-0.4-0.6-0.7-0.9-1c-0.3-0.3-0.6-0.7-0.9-1l0,0c0,0,0,0-0.1-0.1
      c-0.3-0.2-0.7-0.2-1,0.1l0,0l0,0c-0.5,0.6-0.9,1.2-1.2,1.9c-0.3,0.6-0.7,1.2-1,1.9c-0.6,1.2-1.1,2.3-1.7,3.5l-1.5-0.8
      c-0.1,0-0.1,0-0.2,0l0,0l-0.8,0.1c-0.1-1.1-0.2-2.1-0.3-3.2v-0.1c-0.1-0.3-0.3-0.5-0.6-0.4s-0.5,0.3-0.4,0.6c0,0,0,0,0,0.1l0,0
      c0.3,1,0.5,2.1,0.7,3.1l-5.6,0.7c-0.2,0-0.3,0.1-0.4,0.2l0,0l0,0c-0.4,0.6-0.9,1.1-1.3,1.6c-0.4,0.4-0.8,0.9-1.2,1.3l-0.4-0.2
      c-0.3-0.1-0.6-0.3-0.8-0.4c-0.6-0.3-1.1-0.6-1.7-0.9l0,0c-0.1-0.1-0.3-0.1-0.5,0c-1.3,0.7-2.8,1.4-4.2,2s-2.9,1.2-4.3,1.8
      c-0.1,0-0.2,0.2-0.1,0.3c0,0.1,0.2,0.2,0.3,0.2l0,0c1.5-0.5,3-1,4.4-1.6c0.7-0.3,1.5-0.5,2.2-0.8l1.1-0.5c0,0.2,0.1,0.4,0.3,0.5l0,0
      c0.5,0.5,0.9,1.1,1.3,1.6c0.4,0.6,0.8,1.2,1.1,1.8c0.4,0.6,0.7,1.3,0.9,1.9c0.3,0.7,0.5,1.3,0.7,2l0,0c0,0.1,0.1,0.2,0.3,0.2
      c0.1,0,0.2-0.1,0.2-0.3l0,0c-0.1-0.8-0.2-1.5-0.3-2.2c-0.2-0.7-0.4-1.5-0.6-2.2c-0.2-0.7-0.5-1.4-0.8-2.1c-0.1-0.1-0.1-0.3-0.2-0.4
      l0.2,0.1c0.3,0.2,0.6,0.3,1,0.4l0,0c0.2,0.1,0.5,0,0.7-0.2l0,0c0.5-0.6,1-1.1,1.4-1.7l0.4-0.5c0.1,0.2,0.3,0.4,0.6,0.5l0,0
      c0.3,0.1,0.5,0.3,0.7,0.4c0.3,0.1,0.5,0.3,0.8,0.5c0.5,0.4,1,0.7,1.5,1.1c1,0.8,1.9,1.7,2.8,2.6c0.9,0.9,1.7,1.8,2.5,2.8
      c-1.5,0.6-2.9,1.1-4.4,1.6c-6.4,2-13.1,2.9-19.8,3.2c-6.7,0.3-13.5-0.1-20-1.6c-3.3-0.8-6.5-1.7-9.6-3c-1.6-0.6-3.1-1.3-4.8-1.8
      c-1.6-0.5-3.3-0.8-5-1c-3.4-0.3-6.8-0.4-10.2-0.2c-0.8,0-1.7,0.1-2.5,0.1s-1.6-0.1-2.3-0.3c-1-0.3-1.9-0.8-2.7-1.3l4.3-3.1l1.4,3
      c0.1,0.2,0.2,0.3,0.4,0.3l0.3,0.1l0,0l0.4-0.1l7-0.2l7.2-0.2c1.2,0,2.4,0,3.6,0c0.3,0,0.6,0,0.8,0.1c0.2,0,0.5,0.1,0.7,0.2
      c0.5,0.2,1.1,0.5,1.6,0.7c2.1,1.1,4.1,2.4,6.2,3.6l0,0c0.2,0.1,0.4,0.1,0.6,0l0,0l3-2.6c1.1,0,2.2,0,3.2,0.1c0.6,0,1.2,0.1,1.8,0.2
      c0.6,0.1,1.2,0.2,1.7,0.3l0,0c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0-0.3-0.1-0.3l0,0c-0.6-0.2-1.1-0.4-1.7-0.6s-1.2-0.3-1.8-0.4
      c-1.2-0.2-2.4-0.4-3.6-0.6h-0.1c-0.2,0-0.3,0.1-0.4,0.2l-2.7,2.7c-0.8-0.7-1.7-1.3-2.6-1.9c-1-0.7-2.1-1.3-3.1-1.9
      c-0.6-0.3-1.1-0.6-1.7-0.8c-0.3-0.1-0.7-0.2-1-0.3c-0.3,0-0.7-0.1-1-0.1c-1.3,0-2.5,0-3.7,0.1s-2.4,0.1-3.6,0.2l-2.8,0.2
      c1.3-0.5,2.5-1.1,3.7-1.7c1.8-0.9,3.7-1.6,5.5-2.4c1.7-0.7,3.6-1,5.5-0.7c3.7,0.6,7.1,3.2,11.2,4.3c1,0.3,2.1,0.5,3.2,0.5h0.8h0.8
      c0.5,0,1-0.1,1.6-0.1c2.1-0.2,4.1-0.7,6.1-1.5c1-0.4,1.9-0.9,2.8-1.4c0.9-0.6,1.7-1.2,2.5-1.9s1.5-1.4,2.2-2.2
      c0.6-0.7,1.4-1.4,2.1-1.9c1.6-1.1,3.4-1.9,5.2-2.3c1.9-0.5,3.9-1,5.8-1.3c1-0.2,2-0.3,3-0.4l1.5-0.2l1.5-0.3c2-0.4,4-1,5.9-1.6
      s3.8-1.5,5.7-2.2l2.9-1.1c0.4-0.2,0.8-0.3,1.2-0.4c0.1,0.2,0.2,0.5,0.3,0.8c0.2,0.7,0.5,1.5,0.6,2.2c0.3,1.5,0.5,3,0.4,4.6
      c-0.1,0.8-0.2,1.5-0.3,2.3c-0.2,0.7-0.4,1.5-0.6,2.2c-0.6,1.4-1.3,2.8-2.3,4c-1,1.2-1.9,2.6-3.2,3.6s-2.7,1.9-4.1,2.7
      c-0.9,0.5-1.9,1-2.9,1.5c-0.8-1.2-1.7-2.4-2.6-3.5C147,67,146,66,145,65c-0.5-0.5-1-0.9-1.6-1.4c-0.3-0.2-0.5-0.4-0.8-0.6
      L145.8,62.6z M22.1,59.4c1.9,0.2,3.8,0.7,5.7,1.4c0.4,0.6,0.9,1.1,1.6,1.5c0.8,0.5,1.8,0.8,2.8,0.8c0.6,0.1,1.2,0,1.8-0.1
      c-0.9,0.4-1.7,0.9-2.4,1.6c-1,0.8-1.9,1.8-2.6,2.9c-0.2,0.2-0.3,0.4-0.5,0.6c0-0.1,0-0.1,0-0.2c0-0.3,0-0.5-0.1-0.8
      s-0.1-0.6-0.3-0.9c-0.2-0.6-0.6-1.1-1.1-1.5c-0.2-0.2-0.5-0.4-0.8-0.6l-0.7-0.4c-1.9-1.1-3.9-2.1-6-2.7c-0.1,0-0.3-0.1-0.4-0.1
      C19.7,59.8,20.9,59.3,22.1,59.4z M14.3,64.1c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.3-0.3,0.5-0.5s0.3-0.3,0.5-0.4l0.3-0.1l0.1-0.1h0.1
      c0.9-0.2,1.8-0.1,2.7,0.1c2,0.5,3.9,1.3,5.7,2.3l0.7,0.4c0.2,0.1,0.4,0.2,0.6,0.4c0.3,0.3,0.6,0.6,0.8,1c0.1,0.1,0.1,0.2,0.2,0.3
      c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.2-0.3,0.5-0.4,0.7c-0.3,0.5-0.7,1-1,1.5l0,0c0,0.1,0,0.1,0,0.2s0.1,0.1,0.2,0.1l0,0
      c0.3-0.1,0.6-0.3,0.9-0.5s0.5-0.4,0.8-0.6c0.1-0.1,0.2-0.2,0.3-0.4c0.1,0.3,0.2,0.5,0.3,0.8c-0.1,0.2-0.1,0.3-0.2,0.5
      c-0.2,1-0.3,2-0.2,2.9c0.1,1,0.4,1.9,0.8,2.8s0.9,1.8,1.6,2.5c0.3,0.3,0.6,0.6,0.9,1c0.2,1.2,0.2,2.4-0.1,3.6
      c-0.2,0.7-0.4,1.3-0.8,1.9c-0.2,0.4-0.5,0.8-0.8,1.1c-0.5-1.7-1-3.4-1.5-5.1l-1.3-4.5l-0.7-2.3c-0.1-0.4-0.2-0.8-0.4-1.2
      c-0.1-0.2-0.1-0.4-0.2-0.6s-0.2-0.4-0.3-0.6c-0.4-0.8-1-1.5-1.7-2.1s-1.6-1-2.5-1.2c-1.6-0.3-3.3-0.4-4.9-0.3
      c-0.8,0-1.7,0.1-2.5,0.2L12.6,68C12.9,66.6,13.5,65.3,14.3,64.1z M33.5,104.9c-0.4,0.2-0.8,0.3-1.2,0.4c-0.4,0.1-0.8,0.3-1.2,0.4
      c-1.6,0.5-3.3,0.9-4.9,1.1c-0.8,0.1-1.7,0.2-2.5,0.3c-0.4,0-0.8,0.1-1.3,0.1h-1.3c-0.4,0-0.8,0-1.3,0h-1.3c-0.6,0-1.2-0.1-1.8-0.1
      c-0.2-1.3-0.5-2.7-0.8-4c-0.4-1.6-0.9-3.1-1.5-4.6c-0.3-0.7-0.7-1.5-1.1-2.2c-0.4-0.7-0.8-1.4-1.2-2.1c-0.8-1.4-1.6-2.7-2.4-4
      c-0.8-1.3-1.4-2.7-2-4.1s-0.9-2.8-1.1-4.2l-0.1-0.5c0-0.2,0-0.4,0-0.5c0-0.4,0-0.7,0.1-1.1s0.1-0.7,0.2-1.1L7,77.5
      c0.3-1.4,0.9-2.8,1.6-4.1c0.6-1.2,1.7-2.2,2.9-2.8c0.6-0.3,1.3-0.5,2-0.6s1.5-0.2,2.2-0.3c1.5-0.2,3-0.2,4.4,0
      c0.6,0.1,1.3,0.4,1.8,0.8s1,0.9,1.3,1.5c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0.2,0.2,0.3,0.2,0.5l0.1,0.2c-0.5,0.5-0.9,1.1-1.5,1.6
      l-1.8,2.1l0,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.2,0.3,0.1c0.9-0.4,1.7-0.8,2.5-1.4c0.4-0.3,0.8-0.6,1.1-0.9l0.5,1.3l1.6,4.5
      c1,2.9,2,5.8,3.1,8.6c-0.6,0.2-1.3,0.3-2,0.3c-0.8-0.1-1.5-0.3-2.2-0.6c-0.7-0.3-1.4-0.8-2-1.3c-0.3-0.3-0.6-0.5-0.9-0.8l-0.7-0.8
      c-0.2-0.8-0.4-1.7-0.8-2.5c-0.4-0.9-0.9-1.7-1.5-2.4c-1.3-1.6-3.3-2.3-5.3-1.8l0,0c-0.2,0-0.3,0.1-0.4,0.3c-0.2,0.4-0.4,0.7-0.6,1
      c-0.2,0.3-0.5,0.7-0.7,1c-0.5,0.6-1.2,1.2-1.8,1.7l0,0c-0.1,0.1-0.1,0.2-0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.1c0.8-0.4,1.5-0.9,2.1-1.5
      c0.3-0.3,0.6-0.6,0.9-0.9c0.2-0.3,0.4-0.5,0.6-0.8c0.7-0.1,1.4-0.1,2.1,0.1c0.8,0.3,1.4,0.7,1.9,1.3c0.5,0.6,1,1.3,1.3,2.1
      c0.3,0.8,0.6,1.6,0.8,2.4l0,0v0.1c0,0.1,0.1,0.2,0.1,0.2l0.9,0.9c0.3,0.3,0.7,0.5,1,0.8c0.7,0.5,1.4,0.9,2.2,1.3
      c0.8,0.3,1.7,0.5,2.5,0.6c0.9,0,1.8-0.2,2.6-0.6l0,0c0.1-0.1,0.2-0.2,0.1-0.3l0,0c-0.3-1-0.6-2-0.9-3c0.5-0.5,1-1,1.3-1.6
      c0.4-0.6,0.8-1.4,1-2.1c0.3-0.7,0.4-1.5,0.5-2.3v-0.3c0.7,0.5,1.4,1,2.1,1.5c1.6,1,3.4,1.8,5.2,2.4c0.2,0.1,0.4,0.1,0.5,0.2
      c-0.2,1-0.5,1.9-0.8,2.8c-0.2,0.6-0.4,1.1-0.6,1.6L38,89.6c-0.1,0.3-0.3,0.5-0.4,0.8c-0.5,1.1-1.1,2.1-1.8,3c-0.3,0.5-0.7,1-1.1,1.5
      c-0.4,0.5-0.8,0.9-1.1,1.4l0,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0.1l0,0c0.5-0.3,1-0.7,1.4-1.1c-0.1,3,0,6.7,0,8.3
      c-0.3,0.2-0.6,0.3-0.9,0.5C34.3,104.5,33.8,104.7,33.5,104.9z M84.1,111.4c-1,2.9-1.8,5.8-2.4,8.7c-0.6,3-1,6-1.3,9
      c-0.1,0.8-0.1,1.5-0.1,2.3s-0.1,1.5-0.1,2.3c0,1.1,0,2.3,0.1,3.5c-0.1-0.1-0.3-0.1-0.4-0.2c-0.7-0.3-1.6-0.4-2.3-0.3
      c-0.7,0.1-1.3,0.4-1.9,0.8l-0.2-3.9c0-1.5-0.1-3.1,0-4.6c0-1.5,0-3,0.1-4.6c0-0.8,0.1-1.5,0.1-2.3l0.2-2.3c0.2-3,0.6-6.1,1.1-9.1
      c0.3-1.5,0.5-3,0.9-4.5c0.3-1.3,0.7-2.7,1.1-3.9c1.1-0.4,2.3-0.7,3.4-1c1.3-0.3,2.6-0.4,4-0.5c1.3-0.1,2.7-0.1,4-0.1
      c1,0,1.9,0.1,2.9,0.2c-0.2,0.3-0.3,0.5-0.4,0.8c-0.2,0.4-0.4,0.9-0.6,1.3c-0.1,0.3-0.2,0.6-0.3,0.9c-1.1,0-2.3,0.2-3.4,0.3
      c0.1-0.3,0.2-0.6,0.4-0.9v-0.1c0.1-0.3,0-0.6-0.3-0.8c-0.3-0.2-0.7,0-0.8,0.3c-0.3,0.7-0.7,1.3-1,2c-0.1,0.1-0.2,0.3-0.2,0.4
      c-0.3,0.6-0.5,1.1-0.8,1.7C85.1,108.5,84.5,109.9,84.1,111.4L84.1,111.4z M96.8,97.5c-0.9,0.5-1.7,1.1-2.4,1.9
      c-0.2,0.2-0.3,0.3-0.4,0.5c-1.2-0.2-2.4-0.3-3.6-0.4c-1.4-0.1-2.8-0.1-4.2-0.1c-1.4,0.1-2.8,0.2-4.2,0.4c-1.4,0.2-2.8,0.6-4.1,1.1
      l0,0c-0.2,0.1-0.4,0.3-0.5,0.5l0,0c-0.5,1.5-0.8,3-1.1,4.6c0,0.1,0,0.3-0.1,0.4c-4.8,0.8-9,5.8-12.9,10.8
      c-2.9,3.7-11.6,14.4-14.3,17.6c-0.2-0.8-0.5-1.5-0.8-2.3c-0.6-1.9-1.4-3.8-2.1-5.7c-0.7-1.9-1.6-3.8-2.5-5.6
      c-0.5-0.9-0.9-1.8-1.3-2.7c-0.4-0.9-0.8-1.8-1.2-2.7c-0.8-1.8-1.5-3.7-2.1-5.6s-1.2-3.8-1.7-5.7l0,0c0-0.1-0.1-0.2-0.1-0.3
      c-0.1-0.1-0.2-0.2-0.3-0.3c0-1.9-0.1-6.4,0-9.5c0-0.1,0-0.2,0-0.3c0.9-0.9,1.6-1.9,2.3-3s1.2-2.3,1.6-3.5c0.3-1,0.5-2,0.6-3
      c1.2,0.3,2.4,0.5,3.6,0.6c1.9,0.2,3.8,0.1,5.7-0.2c0.8-0.1,1.6-0.3,2.3-0.5c1.3,0.1,2.6,0.4,3.9,0.6c1.3,0.2,2.6,0.6,3.9,0.9
      c2.6,0.7,5.1,1.5,7.7,2.4c1.2,0.5,2.5,1,3.7,1.5c0.6,0.2,1.2,0.5,1.8,0.8c0.6,0.3,1.2,0.6,1.9,0.8l1.9,0.8l2,0.7
      c0.6,0.3,1.3,0.5,2,0.7s1.3,0.4,2,0.6l2,0.5c0.7,0.2,1.4,0.3,2.1,0.4c0.3,0.1,0.7,0.1,1,0.2l1,0.1c0.7,0.1,1.4,0.2,2.1,0.3l2.1,0.2
      c0.7,0,1.4,0.1,2.1,0.1s1.4,0,2.1,0h0.2c0.1,0.2,0.1,0.4,0.2,0.5C98,97,97.4,97.2,96.8,97.5z M132.2,104.7c-2.4,1.2-4.9,2-7.5,2.5
      s-5.3,0.8-7.9,0.9c-2.7,0.1-5.3-0.1-7.9-0.7l0,0c-0.4-0.1-0.9,0.2-1,0.6c-0.3,0.8-0.6,1.6-0.9,2.4s-0.7,1.6-1,2.4
      c-0.6,1.4-1.2,2.8-1.9,4.2l-0.2-10.1c0-0.3-0.1-0.5-0.4-0.6h-0.1l0,0c-0.7-0.3-1.4-0.6-2-1s-1.1-0.9-1.4-1.4
      c-0.1-0.2-0.2-0.5-0.3-0.8c0-0.1,0-0.3,0-0.5s0-0.3,0-0.5c0-0.3,0.1-0.5,0.2-0.8c0.1,0.5,0.2,0.9,0.3,1.4c0,0.1,0.1,0.2,0.2,0.2
      s0.2-0.1,0.3-0.2l0,0c0-1.2,0-2.4,0-3.6c0-0.6,0-1.2-0.1-1.8c0-0.3-0.1-0.7-0.1-1c2.7-0.2,5.4-0.6,8.1-1.2s5.3-1.6,7.8-2.9
      c0.1-0.1,0.2-0.2,0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.1c-2.5,1-5.1,1.7-7.8,2.1c-2.7,0.4-5.3,0.6-8,0.6c-5.4,0-10.7-0.8-15.8-2.4
      c-0.6-0.2-1.3-0.4-1.9-0.6s-1.3-0.4-1.9-0.7l-2-0.8L77,89.2c-2.5-1.2-5.1-2.3-7.7-3.2c-2.6-0.9-5.3-1.6-8.1-2.2
      c-1.4-0.2-2.8-0.5-4.2-0.6h0c1.4-0.7,2.7-1.4,4-2.4c0.1-0.1,0.1-0.2,0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-2.9,1.4-5.9,2.4-9,2.8
      c0.3-0.6,0.5-1.2,0.6-1.8c0.3-1.1,0.5-2.3,0.5-3.4s-0.1-2.3-0.2-3.4c-0.2-1.1-0.5-2.2-0.9-3.3c0-0.1-0.2-0.2-0.3-0.1
      c-0.1,0-0.2,0.2-0.2,0.3l0,0c0.6,2.1,0.8,4.3,0.6,6.4C51.8,79,51.5,80,51.2,81c-0.3,0.8-0.7,1.6-1.1,2.3c-2.4,0.2-4.7,0-7.1-0.5
      c-0.3-0.7-0.5-1.4-0.7-2.1c-0.3-0.9-0.5-1.9-0.6-2.9c-0.2-1-0.3-1.9-0.3-2.9c-0.1-1-0.1-2,0-3l0,0c0-0.1-0.1-0.2-0.2-0.2
      c-0.1,0-0.3,0.1-0.3,0.2l0,0c-0.2,1-0.3,2-0.3,3s0,2,0,3.1c0,1,0.2,2,0.3,3.1c0.1,0.4,0.2,0.9,0.3,1.3c-0.3-0.1-0.6-0.2-0.9-0.3
      c-2.5-0.8-4.8-2-6.9-3.5c-0.2-0.5-0.3-1-0.4-1.5c-0.1-0.7-0.2-1.4-0.3-2c0-0.7,0-1.4,0-2.1s0.1-1.4,0.2-2.1l0,0c0-0.1,0-0.3-0.2-0.3
      c-0.1,0-0.3,0-0.3,0.2c-0.2,0.7-0.4,1.4-0.5,2.1s-0.2,1.4-0.3,2.2c0,0.7,0,1.4,0,2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.6-1.1-1.3-1.5-2
      c-0.4-0.8-0.7-1.6-0.9-2.4c-0.2-0.8-0.3-1.7-0.2-2.5c0.1-0.6,0.2-1.2,0.5-1.7c0.8-1.2,1.9-2.2,3.1-3.1c1.3-1,2.8-1.7,4.4-2.1
      c0.8-0.2,1.6-0.4,2.4-0.5c0.4,0,0.8-0.1,1.2-0.1l1.2-0.1c1.7-0.1,3.3-0.1,5,0c3.3,0.2,6.6,0.7,9.9,1.5c1.6,0.4,3.2,0.9,4.7,1.6
      s2.9,1.5,4.3,2.5l0.9,0.6h0.1c1.1,0.8,2.3,1.4,3.6,1.9c0.9,0.3,1.7,0.4,2.6,0.4h0.1c0.1,0.3,0.3,0.6,0.4,0.9
      c0.2,0.3,0.3,0.7,0.4,1.1s0.2,0.8,0.3,1.2c0,0.2,0,0.4,0.1,0.6c0,0.2,0,0.4,0.1,0.7l0,0c0,0,0,0.1,0.1,0.1s0.1,0,0.2-0.1
      c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.1-0.4,0.2-0.7c0.1-0.5,0.1-1,0.1-1.5s-0.2-1-0.4-1.5c-0.1-0.1-0.1-0.2-0.2-0.3h1
      c3.3-0.2,6.7-0.1,10,0.2c1.7,0.2,3.3,0.5,4.9,1s3.1,1.1,4.7,1.7c3.2,1.2,6.4,2.2,9.7,3c3.1,0.7,6.2,1.2,9.3,1.4l0,0v0.1
      c0.2,0.4,0.4,0.8,0.6,1.3c0.2,0.4,0.4,0.9,0.6,1.3c0.2,0.5,0.3,0.9,0.5,1.4c0.1,0.5,0.2,1,0.3,1.5l0,0c0,0,0,0.1,0.1,0.1
      s0.1,0,0.1-0.1c0.2-0.5,0.2-1.1,0.3-1.6c0-0.6,0-1.1-0.1-1.6c-0.1-0.6-0.3-1.1-0.5-1.6c-0.1-0.2-0.2-0.4-0.4-0.6
      c3.1,0.2,6.3,0.3,9.4,0.2c6.8-0.2,13.5-1.2,20-2.9c0,0.1,0.1,0.1,0.1,0.2c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.4,0.3,0.9,0.4,1.4v0.1h0.1
      l0,0c0.2-0.8,0.3-1.6,0.1-2.3v-0.1c1.4-0.4,2.8-0.9,4.2-1.4c0.5,0.6,1,1.3,1.4,2l0.5,0.8l-0.5,0.3c-3.2,1.8-6.5,3.5-9.7,5.1
      l-4.9,2.5c-1.7,0.8-3.3,1.6-5,2.5c-0.2,0.1-0.2,0.4-0.1,0.6c0.1,0.2,0.3,0.2,0.5,0.2l0,0c2.7-1.2,5.5-2.3,8.3-3.5
      c-0.4,0.9-0.7,1.7-1.1,2.6c-0.5,1.5-1,3-1.4,4.5s-0.7,3.1-1,4.6c-0.2,1.6-0.4,3.1-0.5,4.7l0,0c0,0.3,0.1,0.6,0.2,0.8
      c-0.3,0.4-0.6,0.8-1,1.2C134.4,103.4,133.4,104.1,132.2,104.7z M158.8,108.5c0.3-0.3,0.3-0.7,0.1-1s-0.6-0.3-0.9-0.1
      c-3.1,2.4-6,4.9-8.7,7.7c-2.7,2.8-5.2,5.7-7.6,8.7c-2.4,3-4.6,6.2-6.7,9.4c-2,3.1-4,6.3-5.7,9.6c-0.3-0.7-0.9-1.3-1.5-1.8
      c-0.4-0.2-0.8-0.4-1.2-0.5c-0.4-0.1-0.9-0.1-1.3-0.1c-0.8,0.1-1.6,0.3-2.4,0.7c-0.2,0.1-0.5,0.3-0.7,0.4c1.7-3.3,3.4-6.5,5.2-9.7
      c1.9-3.5,4-6.9,6.1-10.3c2.1-3.4,4.3-6.7,6.7-9.9c1.7-2.2,3.4-4.5,5.3-6.5c0.1-0.1,0.3-0.2,0.3-0.4c0.6-0.7,1.3-1.4,1.9-2
      c0.3-0.1,0.5-0.1,0.8-0.2c0.3,0,0.7-0.1,1-0.1c0.7,0,1.4,0,2.1,0.1c1.4,0.2,2.8,0.6,4.1,1.1c0.6,0.2,1.3,0.5,1.9,0.9
      c0.6,0.4,1.1,0.8,1.6,1.3s0.9,1,1.3,1.6c0.2,0.4,0.5,0.7,0.7,1.1C160.3,108.5,159.6,108.5,158.8,108.5L158.8,108.5z"
      />
      <path
        fill="#3C3C64"
        d="M71.8,172.1c-0.7-2.5-1.3-5.1-1.8-7.7c-0.5-2.6-1-5.2-1.4-7.8c-0.8-5.2-1.5-10.5-1.8-15.8l0,0
      c0-0.1-0.1-0.2-0.2-0.2s-0.2,0.1-0.2,0.2l0,0c0,5.3,0.3,10.7,0.8,16c0.2,2.7,0.5,5.3,0.9,8s0.8,5.3,1.3,7.9v0.1
      c0.1,0.7,0.8,1.1,1.5,1s1.1-0.8,1-1.5C71.8,172.2,71.8,172.1,71.8,172.1L71.8,172.1z"
      />
      <path
        fill="#3C3C64"
        d="M120.9,66.1L120.9,66.1c-0.2-0.1-0.5-0.1-0.6,0.1l0,0l-3.1,3.8c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0
      l3.1-3.2l2.6,1.7c0.1,0.1,0.3,0.1,0.3,0c0.1-0.1,0.1-0.3,0-0.3L120.9,66.1z"
      />
      <path
        fill="#3C3C64"
        d="M150.3,41.2c-0.1,0.3-0.2,0.5-0.4,0.7c-0.1,0.2-0.2,0.5-0.4,0.7c-0.3,0.5-0.6,0.9-1,1.3
      c-0.7,0.8-1.6,1.4-2.6,1.7s-2.1,0.3-3.2,0l0,0c-0.1,0-0.1,0-0.1,0.1s0,0.1,0,0.2c0.5,0.3,1.1,0.5,1.7,0.5c0.3,0,0.6,0,0.9,0
      c0.3,0,0.6,0,0.9-0.1c1.2-0.3,2.2-0.9,3-1.9c0.4-0.4,0.7-0.9,1-1.5c0.3-0.5,0.5-1,0.7-1.6c0.2-0.5,0.4-1.1,0.5-1.6
      c0.1-0.6,0.2-1.1,0.3-1.7c0-0.1,0-0.1-0.1-0.1s-0.1,0-0.2,0.1l0,0l0,0c-0.2,0.5-0.4,1.1-0.5,1.6C150.7,40.2,150.5,40.7,150.3,41.2z"
      />
      <path
        fill="#3C3C64"
        d="M101.5,27.4c-2.3,0.1-4.6,0.4-6.9,0.8s-4.5,0.9-6.7,1.6c-0.1,0-0.2,0.2-0.2,0.3c0,0.1,0.1,0.2,0.3,0.2l0,0l0,0
      c4.5-0.7,9-1.1,13.5-1.2c2.3,0,4.5,0,6.7,0.2s4.5,0.4,6.7,0.8s4.4,1,6.5,1.7s4.2,1.6,6.1,2.7c2,1.1,3.8,2.3,5.5,3.8s3.3,3.1,4.8,4.8
      l0,0c0.1,0.1,0.2,0.1,0.3,0s0.1-0.2,0-0.3l0,0l-1-1.4c-0.2-0.2-0.3-0.5-0.5-0.7l-0.5-0.7c-0.4-0.4-0.7-0.9-1.1-1.3l-0.2-0.2
      c-0.6-0.8-1.1-1.7-1.6-2.6c-0.5-1.1-1-2.3-1.3-3.5c-0.8-2.4-1.3-4.8-1.5-7.3c-0.1-0.6-0.1-1.2-0.1-1.8c0-0.3,0-0.6,0-0.9
      s0-0.6,0.1-0.9l0.1-0.9l0.2-0.9c0.1-0.3,0.2-0.6,0.2-0.9l0.1-0.4l0.2-0.4c0.4-1.1,1-2.1,1.7-3c0.8-0.9,1.8-1.6,2.9-1.9l0,0
      c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-1.4,0-2.7,0.6-3.8,1.5c-1,0.9-1.8,2.1-2.3,3.4l-0.2,0.5l-0.2,0.5
      c-0.1,0.3-0.2,0.6-0.3,1l-0.2,1l-0.1,1c-0.1,1.3-0.1,2.6,0.1,4c0.1,1.3,0.4,2.6,0.7,3.9c0.3,1.2,0.7,2.5,1.2,3.7
      c0.2,0.6,0.5,1.2,0.8,1.8c0.1,0.3,0.3,0.6,0.4,0.9c0.1,0.3,0.3,0.5,0.4,0.8c-1.3-1.1-2.7-2.1-4.1-3c-2-1.2-4.1-2.2-6.3-3
      c-2.2-0.8-4.4-1.4-6.7-1.8C110.8,27.3,106.2,27.1,101.5,27.4z"
      />
      <path
        fill="#3C3C64"
        d="M85.3,28.9L85.3,28.9c3.4-0.9,6.8-1.6,10.2-2.1c1.7-0.2,3.4-0.4,5.1-0.6s3.4-0.2,5.2-0.2l0,0
      c0.1,0,0.2-0.1,0.2-0.2s-0.1-0.1-0.2-0.2c-1.7-0.2-3.5-0.2-5.2-0.1s-3.5,0.3-5.2,0.5c-1.7,0.3-3.4,0.6-5.1,1s-3.4,0.9-5,1.5
      c-0.1,0-0.1,0.2-0.1,0.2C85.1,28.9,85.2,29,85.3,28.9L85.3,28.9z"
      />
      <path
        fill="#3C3C64"
        d="M142.1,36.7c0,0.1,0,0.2,0,0.2c0.1,0.9,1.8,1.9,1.8,1.9s1.6-1.3,1.5-2.2c-0.1-0.8-0.2-1.7-0.3-2.5
      s-0.3-1.6-0.5-2.5c-0.4-1.6-0.9-3.2-1.5-4.7c-0.2-0.5-0.4-1-0.6-1.4c0.4,0.4,0.8,0.6,1.4,0.7c0.3,0,0.6-0.1,0.8-0.3
      c0.2-0.2,0.3-0.5,0.4-0.7c0.1-0.5,0.1-1,0-1.5c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0.1l0,0c0,0.5-0.1,0.9-0.2,1.3
      c-0.1,0.2-0.2,0.4-0.3,0.5s-0.3,0.1-0.4,0.1c-0.4-0.1-0.7-0.3-1-0.6c-0.3-0.3-0.5-0.7-0.7-1.1c-0.4-0.8-0.7-1.7-0.7-2.5
      c0-0.4,0-0.8,0.2-1.2c0.1-0.2,0.2-0.3,0.4-0.3s0.4,0,0.6,0h0.1v-0.1c-0.2-0.2-0.5-0.3-0.7-0.2c-0.3,0-0.6,0.2-0.8,0.4
      c-0.3,0.4-0.5,1-0.5,1.5s0.1,1,0.2,1.5c0-0.1-0.1-0.2-0.1-0.2c-0.9-1.4-1.9-2.6-3-3.8c-0.1-0.1-0.2-0.1-0.3,0s-0.1,0.2,0,0.3l0,0
      l0,0c0.9,1.3,1.7,2.6,2.3,4s1.2,2.9,1.5,4.4c0.4,1.5,0.6,3,0.8,4.5c0.1,0.8,0.1,1.5,0.1,2.3s-0.1,1.5-0.1,2.3L142.1,36.7z"
      />
      <path
        fill="#3C3C64"
        d="M75.1,38c0.1,0.5,1,0.9,1,0.9c0.3-0.4,0.6-0.8,0.7-1.2l0,0c-0.2-1-0.3-2-0.3-3c-0.1-2,0-4,0.5-6
      c0.2-1,0.5-2,0.9-2.9l0,0c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.3,0-0.3,0.1c-0.5,0.9-0.9,1.9-1.2,2.9c-0.7,2-1,4.1-1.1,6.2
      C75,35.7,75,36.8,75.1,38C75.1,37.9,75.1,37.9,75.1,38z"
      />
      <path
        fill="#3C3C64"
        d="M118.6,33.3L118.6,33.3L118.6,33.3c0.4,0.4,0.9,0.9,1.2,1.4c0.1,0.2,0.2,0.5,0.2,0.8c0,0.2-0.1,0.5-0.3,0.6
      c-0.5,0.3-1.1,0.3-1.7,0.2c-0.4-0.1-0.8-0.2-1.1-0.4l-0.1-0.1c-0.5-0.5-1-0.9-1.6-1.3c-1.1-0.7-2.4-1.1-3.6-1.4
      c-0.1,0-0.3,0-0.3,0.2c0,0.1,0,0.3,0.2,0.3l0,0c1.2,0.3,2.3,0.9,3.4,1.5c0.5,0.3,1,0.7,1.4,1.2c0.4,0.4,0.6,0.9,0.7,1.5
      c0.1,0.5-0.1,1-0.5,1.4s-0.9,0.7-1.5,0.9c-1.1,0.4-2.4,0.5-3.6,0.5c-2.5,0-4.9-0.8-6.9-2.1c-0.5-0.3-0.9-0.7-1.3-1.2
      c-0.3-0.4-0.5-1-0.5-1.5c0.1-0.6,0.4-1,0.8-1.4c0.5-0.4,1.1-0.6,1.6-0.8l0,0c0.1,0,0.2-0.2,0.2-0.3s-0.2-0.2-0.3-0.2
      c-0.7,0.2-1.3,0.4-1.8,0.8L103,34c-1.5,0.3-3.1,0.4-4.7,0.2c-0.4-0.1-0.8-0.1-1.2-0.3c-0.4-0.1-0.7-0.3-1-0.5
      c-0.1-0.1-0.2-0.2-0.2-0.3s0-0.2,0-0.3c0.2-0.3,0.4-0.5,0.7-0.7c0.7-0.4,1.4-0.7,2.2-0.8c0.8-0.2,1.6-0.3,2.4-0.3s1.6,0,2.4,0.2
      c0.4,0.1,0.8,0.2,1.1,0.4c0.3,0.1,0.6,0.4,0.7,0.7l0,0c0,0.1,0.1,0.2,0.2,0.1c0.1,0,0.2-0.1,0.2-0.2l0,0c0-0.2-0.1-0.5-0.3-0.7
      c-0.2-0.2-0.3-0.3-0.5-0.4c-0.4-0.2-0.8-0.4-1.2-0.5c-0.8-0.2-1.7-0.3-2.6-0.3s-1.7,0.1-2.6,0.3c-0.9,0.2-1.7,0.5-2.5,0.9
      c-0.4,0.2-0.8,0.6-1.1,1c-0.1,0.3-0.1,0.6,0,0.9c0.1,0.3,0.3,0.5,0.5,0.7c0.4,0.3,0.8,0.5,1.3,0.6c0.4,0.1,0.9,0.2,1.3,0.3
      c1.4,0.1,2.9,0,4.2-0.3c-0.2,0.3-0.4,0.7-0.4,1c-0.1,0.7,0.1,1.5,0.5,2.1s0.9,1.1,1.4,1.5c0.4,0.3,0.9,0.6,1.4,0.9
      c0.7,0.4,1.1,1,1.4,1.7c0.1,0.4,0,0.8-0.2,1.2s-0.5,0.7-0.9,0.9c-0.8,0.5-1.6,0.9-2.5,1.1c-0.9,0.2-1.9,0.3-2.8,0.2
      c-0.9,0-1.9-0.2-2.8-0.5s-1.6-0.8-2.2-1.5c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.4,0.2-0.8,0.5-1.1c0.6-0.7,1.4-1.2,2.3-1.5l0,0h0.1h0.1
      c0.3-0.1,0.5-0.3,0.7-0.5s0.4-0.5,0.5-0.8s0-0.7-0.2-0.9s-0.4-0.4-0.7-0.6c-0.1,0-0.1,0-0.2,0c0,0.1,0,0.1,0,0.2
      c0.4,0.3,0.8,0.7,0.6,1.2c-0.2,0.4-0.6,0.7-1.1,0.9c-1,0.4-2,0.5-3.1,0.3c-0.5-0.1-1-0.2-1.5-0.4c-0.4-0.2-0.8-0.6-0.8-1
      s0.4-0.8,0.9-1s1-0.4,1.5-0.5c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1l0,0c-0.6,0-1.1,0.2-1.6,0.4c-0.3,0.1-0.5,0.3-0.7,0.5
      s-0.4,0.5-0.5,0.8c0,0.3,0.1,0.7,0.3,0.9c0.2,0.2,0.4,0.4,0.7,0.5c0.5,0.3,1.1,0.4,1.6,0.5C94.6,40,95,40,95.4,40
      c-0.3,0.2-0.6,0.5-0.9,0.8c-0.8,0.9-0.9,2.2-0.2,3.1c0.7,0.8,1.6,1.5,2.6,1.8s2,0.5,3,0.6s2,0,3-0.3c0.5-0.1,1-0.3,1.5-0.5
      s0.9-0.5,1.4-0.8c0.4-0.3,0.8-0.7,1.1-1.2c0.3-0.5,0.3-1.1,0.2-1.7c-0.1-0.3-0.2-0.6-0.4-0.9l0.6,0.2c1.3,0.4,2.6,0.6,3.9,0.6
      c1.3,0.1,2.7-0.2,3.9-0.6c0.7-0.2,1.3-0.6,1.8-1.1c0.5-0.6,0.8-1.3,0.7-2.1c-0.1-0.4-0.2-0.8-0.4-1.1c0.2,0.1,0.4,0.2,0.5,0.2
      c0.3,0.1,0.7,0.2,1.1,0.2s0.8-0.1,1.1-0.3c0.4-0.2,0.6-0.6,0.6-1s-0.1-0.8-0.4-1.1C119.9,33.9,119.3,33.5,118.6,33.3z"
      />
      <path
        fill="#3C3C64"
        d="M84.7,45.6c0.6,0.3,1.3,0.4,1.9,0.5s1.3,0,1.9-0.1c0.7-0.1,1.3-0.3,1.9-0.6c0.3-0.2,0.6-0.4,0.8-0.7
      c0.2-0.3,0.3-0.8,0.1-1.1c-0.2-0.3-0.5-0.5-0.8-0.7c-0.3-0.1-0.6-0.2-1-0.3c-0.1,0-0.1,0-0.1,0.1s0,0.1,0.1,0.2l0,0
      c0.3,0.1,0.6,0.2,0.8,0.4c0.2,0.1,0.4,0.3,0.6,0.6c0.2,0.4-0.2,0.9-0.8,1.1c-1.1,0.5-2.3,0.7-3.5,0.6c-0.6,0-1.2-0.2-1.7-0.4
      c-0.2-0.1-0.5-0.3-0.6-0.5s-0.1-0.4,0-0.6c0.4-0.5,0.9-0.8,1.4-1c0.6-0.2,1.2-0.3,1.8-0.4l0,0c0.1,0,0.1-0.1,0.1-0.1
      c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.3,0.1-1.9,0.3c-0.3,0.1-0.6,0.2-0.9,0.4s-0.6,0.4-0.8,0.7c-0.1,0.2-0.2,0.4-0.2,0.6
      c0,0.2,0.1,0.4,0.2,0.6C84.1,45.2,84.4,45.4,84.7,45.6z"
      />
      <path
        fill="#3C3C64"
        d="M75,49.1c0.4,0,0.8,0,1.3-0.1c0.8-0.2,1.5-0.6,2.1-1.2v-0.1h-0.1l0,0c-0.6,0.4-1.3,0.7-2.1,0.8
      c-0.4,0.1-0.7,0.1-1.1,0c-0.3,0-0.6-0.1-0.9-0.3C74,48,74,47.7,74.1,47.5c0.2-0.3,0.4-0.5,0.7-0.7c0.6-0.4,1.3-0.7,2.1-0.9l0,0
      l0.1-0.1c0-0.1,0-0.1-0.1-0.1l0,0c-0.8,0-1.7,0.2-2.4,0.6c-0.4,0.2-0.7,0.5-1,0.9c-0.1,0.2-0.2,0.5-0.2,0.7c0,0.3,0.2,0.5,0.4,0.7
      C74.1,49,74.5,49.1,75,49.1z"
      />
      <path
        fill="#3C3C64"
        d="M187.2,69.8L187.2,69.8c0,0.1-0.1,0.1-0.1,0.2l0,0l-1.1,8.1c-0.3,0.8-0.5,1.6-0.6,2.4c-0.2,1.1-0.3,2.2-0.4,3.3
      c-0.1,1.1,0,2.2,0.1,3.3c0.1,1.1,0.3,2.2,0.6,3.3l0,0c0.1,0.4,0.5,0.6,0.9,0.5l0,0l2.1-0.6l0,0l-0.4,0.8c-0.5,1-0.8,2.1-1.1,3.2
      c-0.2,0.6-0.3,1.1-0.4,1.7c-0.1,0.3-0.1,0.6-0.1,0.9c0,0.2,0,0.3,0,0.5s0,0.3,0,0.5c0,0.5,0.4,0.9,0.9,1h0.1l0,0
      c1.2,0,2.3,0,3.4-0.1c1.1-0.1,2.3-0.2,3.4-0.4c1.2-0.2,2.3-0.4,3.4-0.8c1.1-0.4,2.2-0.9,3.1-1.7c0.2-0.1,0.4-0.4,0.4-0.6l0,0
      c0-0.5,0.1-1.1,0.3-1.6s0.4-1,0.8-1.4c0.7-0.8,1.7-1.3,2.8-1.3c0.6-0.1,1.1-0.1,1.7,0c0.5,0,1,0.2,1.5,0.4c0.4,0.2,0.7,0.6,0.8,1
      c0.1,0.2,0.1,0.5,0.1,0.7c0,0.1,0,0.2,0,0.3l0,0c0,0.1,0,0.2,0,0.4c2.5,1.3,0.7,0.4,1.3,0.6l0,0l0,0l0,0v-0.1l0.1-0.1
      c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.2,0.2-0.3,0.4-0.5c0.2-0.3,0.4-0.7,0.6-1c0.3-0.7,0.5-1.5,0.6-2.3c0-0.4,0-0.8,0-1.2
      c0-0.4-0.1-0.7-0.1-1.1c-0.1-0.7-0.2-1.4-0.3-2.1c-0.3-1.5-0.7-2.9-1.3-4.2c-0.3-0.7-0.7-1.4-1.2-2c-0.5-0.7-1.2-1.3-2-1.7
      c-0.4-0.2-0.9-0.3-1.3-0.4v-0.4c0-0.5-0.1-1-0.1-1.5c-0.1-1-0.3-2.1-0.6-3c-0.3-1.1-0.9-2-1.6-2.8c-0.1-0.1-0.2-0.2-0.2-0.2
      c0.1-0.9,0.1-1.9,0.1-2.8c-0.1-1.1-0.3-2.2-0.7-3.3c-0.2-0.6-0.6-1.1-1-1.6c-0.5-0.5-1-0.9-1.7-1.2c-0.4-0.2-0.9-0.2-1.4-0.2
      c-0.2-2.1-1-4-2.4-5.6c-0.2-0.2-0.5-0.3-0.8-0.2l-2.4,1c-0.4,0.1-0.6,0.6-0.4,0.9c0.1,0.4,0.6,0.6,0.9,0.4c0,0,0,0,0.1,0l1.9-0.8
      c1,1.3,1.6,2.8,1.7,4.4c-0.3,0.1-0.7,0.2-1,0.3c-1.1,0.4-2.1,0.9-3,1.5c-0.5,0.3-0.9,0.6-1.3,1c-0.4,0.3-0.8,0.7-1.2,1.1
      c-0.8,0.8-1.5,1.6-2.1,2.4C188.3,67.9,187.7,68.8,187.2,69.8z M208.6,82.7c0.5,1.2,1,2.4,1.3,3.7c0.2,0.6,0.3,1.3,0.4,2
      c0.1,0.3,0.1,0.7,0.2,1c0,0.3,0.1,0.6,0.1,0.9c0,0.5-0.1,1-0.2,1.4c-0.3-0.6-0.7-1.1-1.3-1.4c-0.6-0.4-1.3-0.6-2.1-0.6
      c-0.7-0.1-1.3-0.1-2,0s-1.4,0.2-2,0.5c-0.7,0.3-1.2,0.7-1.7,1.3c-0.5,0.5-0.8,1.2-1,1.8c-0.1,0.3-0.2,0.7-0.3,1
      c0,0.2-0.1,0.4-0.1,0.7c-0.8,0.5-1.6,0.9-2.5,1.1c-1,0.3-2.1,0.5-3.1,0.6c-1.1,0.1-2.2,0.2-3.2,0.2c-0.8,0-1.5,0-2.3-0.1
      c0-0.2,0-0.3,0-0.5c0-0.5,0.1-1,0.2-1.5c0.2-1,0.5-2,0.9-3s0.8-1.9,1.4-2.8c0.3-0.5,0.5-0.9,0.8-1.4l0.6-0.9
      c0.2,0.2,0.3,0.3,0.5,0.4c0.4,0.3,0.7,0.6,1.1,0.9s0.7,0.5,1.1,0.8c0.4,0.3,0.8,0.5,1.3,0.6l0,0c0.1,0,0.2,0,0.3,0
      c0.2-0.1,0.3-0.3,0.2-0.5l0,0c-0.2-0.5-0.5-0.9-0.9-1.2c-0.3-0.4-0.7-0.7-1.1-1c-0.4-0.3-0.8-0.5-1.3-0.7c-0.1-0.1-0.3-0.1-0.4-0.2
      c1.9-1.6,3.9-2.9,6.1-3.9c1.2-0.6,2.5-1.1,3.7-1.5c0.6-0.2,1.2-0.4,1.8-0.5c0.5-0.1,1,0,1.4,0.2C207.3,80.6,208.1,81.6,208.6,82.7
      L208.6,82.7z M204.1,73.2c0.1,0.2,0.1,0.4,0.2,0.7c0.1,0.2,0.1,0.4,0.1,0.7c0.1,0.5,0.2,0.9,0.2,1.4s0.1,0.9,0.1,1.4v0.4
      c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.1-0.6,0.2l-1,0.4c-1.3,0.5-2.6,1.2-3.9,1.9c-1.2,0.7-2.4,1.5-3.6,2.3
      c-1.2,0.8-2.3,1.7-3.4,2.7l-0.1,0.1l0,0l-1,1.4c-0.3,0.5-0.6,0.9-0.9,1.4c-0.1,0.1-0.2,0.3-0.2,0.4l-2.7,0.7
      c-0.2-0.8-0.3-1.6-0.4-2.3c-0.1-1-0.1-2.1-0.1-3.1s0.2-2.1,0.3-3.1c0.1-0.6,0.3-1.2,0.4-1.9l0.1,0.1c0.3,0.4,0.7,0.8,1.1,1.1
      c0.4,0.3,0.9,0.5,1.4,0.7s1,0.2,1.5,0.2c0.1,0,0.1,0,0.1-0.1c0.1-0.1,0.1-0.2,0-0.3l0,0l0,0c-0.2-0.2-0.4-0.3-0.6-0.4
      c-0.2-0.1-0.4-0.3-0.6-0.4l-1.1-0.7c-0.4-0.3-0.7-0.5-1.1-0.8l-0.5-0.4l0,0c0.3-0.5,0.7-1,1.1-1.5c0.2-0.2,0.4-0.5,0.7-0.7
      c0.2-0.2,0.5-0.5,0.7-0.7c1-0.9,2.1-1.6,3.2-2.3c0.6-0.3,1.2-0.6,1.8-0.9s1.2-0.5,1.8-0.8c0.6-0.2,1.3-0.4,1.9-0.6
      c0.6-0.2,1.3-0.3,1.9-0.5c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0.1,0.6,0.2c0.4,0.2,0.7,0.4,1,0.7
      C203.4,71.5,203.8,72.3,204.1,73.2z M192.3,66.1c0.7-0.6,1.5-1.2,2.4-1.7c0.8-0.5,1.6-0.9,2.5-1.2s1.7-0.4,2.3-0.2
      c0.3,0.1,0.6,0.3,0.8,0.5c0.3,0.3,0.5,0.6,0.7,1c0.6,1.4,1,2.9,1.2,4.4c-0.2-0.1-0.4-0.1-0.7-0.2c-0.1,0-0.3,0-0.5-0.1
      c-0.2,0-0.3,0-0.5,0l0,0c-0.7,0.2-1.4,0.3-2,0.5s-1.3,0.4-2,0.7c-0.7,0.3-1.3,0.5-1.9,0.8c-0.6,0.3-1.3,0.6-1.9,1
      c-1.2,0.7-2.4,1.5-3.5,2.4c-0.3,0.2-0.5,0.5-0.8,0.7c-0.2,0.2-0.5,0.5-0.8,0.8l-0.3,0.3l0.8-5.5C189.4,68.7,190.8,67.4,192.3,66.1
      L192.3,66.1z"
      />
      <path
        fill={sparkFillColor}
        d="M174.9,61.6c-1.1,1-2.1,2.2-2.7,3.6c-0.1,0.1,0,0.3,0.1,0.3c0.1,0.1,0.2,0,0.3-0.1l0,0c0.4-0.6,0.9-1.1,1.4-1.6
      c0.3-0.2,0.5-0.5,0.8-0.7s0.6-0.4,0.8-0.6l0.3-0.2c-0.4,2.3-0.6,4.6-0.6,6.9c0,2.9,0.2,5.7,0.6,8.5c0,0.1,0.1,0.2,0.3,0.2
      c0.1,0,0.2-0.1,0.2-0.3l0,0c-0.2-2.8-0.3-5.6-0.1-8.5c0.1-2.6,0.5-5.3,1.2-7.8l0.9-0.3c0.3-0.1,0.6-0.2,1-0.3c0.3-0.1,0.6-0.1,1-0.2
      c0.3-0.1,0.7-0.1,1-0.1h1l1,0.1h0.5l0.5,0.1l1,0.2c0.7,0.2,1.3,0.4,2,0.5h0.1c0.5,0.1,1-0.3,1.1-0.8s-0.2-0.9-0.6-1.1
      c-0.4-0.1-0.7-0.2-1.1-0.4c-0.4-0.1-0.7-0.2-1.1-0.3l-1.1-0.2l-0.6-0.1h-0.6h-1.2c-0.4,0-0.8,0.1-1.2,0.1s-0.8,0.1-1.2,0.2
      c-0.4,0.1-0.8,0.2-1.1,0.3c-0.2,0.1-0.3,0.1-0.5,0.2c0.3-0.6,0.7-1.2,1.2-1.7c0.2-0.2,0.5-0.4,0.7-0.5c0.3-0.2,0.6-0.3,0.9-0.5
      c0.6-0.3,1.2-0.5,1.8-0.6c1.2-0.2,2.5,0,3.5,0.6c0.6,0.3,1.1,0.6,1.6,1s0.9,0.9,1.7,1h0.2c0.1,0,0.1,0,0.2,0c0.4,0,0.7-0.4,0.6-0.8
      l0,0c-0.1-1-0.8-1.4-1.4-1.9c-0.6-0.5-1.3-0.9-2-1.2c-1.5-0.7-3.2-0.8-4.8-0.5c-0.8,0.2-1.5,0.5-2.2,0.8c-0.3,0.2-0.7,0.4-1,0.6
      c-0.3,0.2-0.7,0.5-0.9,0.8c-1,1.1-1.7,2.4-2.1,3.8C175.8,60.7,175.3,61.1,174.9,61.6z"
      />
      <path
        fill="#8764FF"
        d="M183.5,62.4c0,0,2.2,0,5.4,2.4c0,0,4.6-1.9,4.1-4.8c-0.5-3-1.1-4.2-1.1-4.2s2.3,1.8,3.3,6.3c0,0-4.9,2.9-7,6.1
      C188.1,68.1,187.5,65.1,183.5,62.4z"
      />
      <path
        fill="#FFFFFF"
        d="M52.9,55.4c0,0,1.7-2.4,1.5-8.6s4.8-5.4,6.6-5.2s5.9,1.3,4,6.8s-3.2,6.5-3.2,6.5"
      />
      <path
        fill="#3C3C64"
        d="M52.7,55.2c0.3-0.7,0.4-1.4,0.5-2.1c0.1-0.7,0.2-1.4,0.2-2.1c0.1-1.4,0-2.9-0.1-4.3c-0.1-0.8,0-1.7,0.2-2.5
      c0-0.2,0.1-0.4,0.2-0.6L54,43c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.3-0.4,0.5-0.5c0.7-0.6,1.6-1,2.5-1.1c0.8-0.1,1.7-0.1,2.5,0.1
      c0.4,0.1,0.8,0.1,1.1,0.2c0.4,0.1,0.8,0.1,1.2,0.3c0.8,0.2,1.6,0.6,2.2,1.2c0.7,0.6,1.1,1.3,1.3,2.2c0.1,0.4,0.1,0.8,0.1,1.2
      s-0.1,0.8-0.2,1.2l-0.1,0.6c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.4-0.3,0.7-0.4,1.1c-0.3,0.7-0.6,1.4-0.9,2.1c-0.3,0.7-0.6,1.4-1,2
      c-0.2,0.3-0.4,0.7-0.6,1c-0.2,0.3-0.5,0.6-0.8,0.9c-0.1,0.1-0.2,0.1-0.3,0l0,0c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.2-0.3,0.4-0.5,0.6-0.8
      c0.2-0.3,0.4-0.6,0.5-1c0.3-0.7,0.6-1.3,0.9-2c0.3-0.7,0.5-1.4,0.7-2.1c0.1-0.4,0.2-0.7,0.3-1c0-0.2,0.1-0.3,0.1-0.5l0.1-0.5
      c0.2-1.4-0.2-2.7-1.2-3.5c-0.5-0.4-1.1-0.6-1.8-0.8c-0.3-0.1-0.7-0.1-1-0.2c-0.4,0-0.7-0.1-1.1-0.1c-1.4,0-2.7,0.4-3.4,1.1
      c-0.7,0.7-1.1,1.9-1.1,3.3c0,0.3,0,0.8,0,1.1s0,0.8-0.1,1.1c-0.1,0.8-0.2,1.5-0.3,2.3c-0.2,0.8-0.4,1.5-0.6,2.2
      c-0.3,0.7-0.6,1.4-1.1,2.1c-0.1,0.1-0.2,0.1-0.3,0C52.6,55.5,52.6,55.4,52.7,55.2L52.7,55.2z"
      />
      <path
        fill="#3C3C64"
        d="M56.5,55.6c0.8-1.2,1.4-2.5,2-3.7c0.3-0.6,0.6-1.3,0.8-1.9c0.3-0.6,0.5-1.4,0.8-2.1c0.2-0.4,0.5-0.7,0.9-0.9
      c0.2-0.1,0.5-0.1,0.8-0.1c0.2,0.1,0.5,0.2,0.7,0.3c0.3,0.3,0.6,0.6,0.7,1c0.2,0.4,0.2,0.8,0.2,1.2c-0.1,0.8-0.2,1.5-0.5,2.2
      s-0.6,1.3-1,1.9c-0.1,0.1-0.2,0.1-0.3,0s-0.1-0.2-0.1-0.3l0,0c0.3-0.6,0.6-1.3,0.8-1.9c0.2-0.6,0.3-1.3,0.4-2c0-0.3,0-0.6-0.1-0.9
      c-0.1-0.3-0.3-0.5-0.5-0.7c-0.2-0.1-0.4-0.2-0.6-0.1c-0.3,0.1-0.5,0.3-0.7,0.5c-0.4,0.5-0.8,1.2-1,1.8c-0.3,0.7-0.6,1.3-0.9,1.9
      c-0.6,1.3-1.3,2.6-2,3.8c0,0.4-0.1,0.4-0.3,0.4C56.5,55.9,56.5,55.7,56.5,55.6L56.5,55.6L56.5,55.6z"
      />
      <path
        fill="#FFFFFF"
        d="M77.8,22c0,0-1.5-1.2-1.8-5.1c0-0.2-0.2-0.4-0.4-0.5c-0.7-0.3-1.6-0.4-2.4-0.2c-0.2,0-0.3,0.2-0.3,0.3
      c0,0.9,0.2,4,2.6,5.9L77.8,22z"
      />
      <path
        fill="#3C3C64"
        d="M78.1,22.5c-1-1-1.7-2.2-2-3.6c-0.2-0.7-0.3-1.3-0.4-2c0-0.1-0.1-0.2-0.1-0.2s0,0-0.1,0h-0.1l-0.2-0.1
      c-0.3-0.1-0.6-0.2-0.9-0.2s-0.7,0-1,0.1c-0.1,0-0.1,0.1-0.1,0.2s0,0.3,0,0.5c0,0.7,0.2,1.3,0.3,1.9c0.3,1.3,1.1,2.5,2.2,3.3
      c0.1,0,0.1,0.1,0,0.2c0,0.1-0.1,0.1-0.2,0c-1.1-0.9-1.9-2-2.3-3.4c-0.2-0.7-0.3-1.3-0.4-2c0-0.2,0-0.3,0-0.5c0-0.3,0.1-0.5,0.4-0.6
      s0.7-0.1,1.1-0.2c0.4,0,0.7,0,1.1,0.1c0.1,0,0.2,0,0.3,0.1h0.1c0.1,0,0.1,0.1,0.2,0.1c0.2,0.1,0.3,0.4,0.3,0.6
      c0.1,0.7,0.2,1.3,0.3,1.9c0.3,1.3,0.9,2.5,1.7,3.5l0,0c0,0,0,0.1,0,0.2C78.3,22.6,78.2,22.6,78.1,22.5z"
      />
      <path
        fill="#FFFFFF"
        d="M74.6,24c-1.4-0.7-2.7-1.5-3.9-2.6C69.9,22,69.4,23,69.3,24c1.1,0.9,2.4,1.6,3.6,2.3L74.6,24z"
      />
      <path
        fill="#3C3C64"
        d="M74.9,24.3c-0.8-0.3-1.5-0.7-2.3-1.1c-0.7-0.4-1.4-0.9-2.1-1.5l0,0H71c-0.2,0.1-0.3,0.3-0.5,0.4
      c-0.2,0.2-0.3,0.4-0.4,0.6c-0.2,0.4-0.4,0.9-0.5,1.3l0,0l-0.1-0.2c1.2,0.9,2.5,1.7,3.8,2.4c0.1,0,0.1,0.1,0.1,0.2l0,0
      c0,0.1-0.1,0.1-0.2,0.1c-1.4-0.7-2.7-1.4-4-2.3c-0.1,0-0.1-0.1-0.1-0.2l0,0l0,0c0-0.5,0.2-1.1,0.4-1.6c0.1-0.2,0.3-0.5,0.4-0.7
      c0.2-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.3-0.1,0.4,0l0,0l0,0c0.6,0.6,1.2,1.1,1.9,1.6c0.7,0.5,1.4,0.9,2.1,1.3l0,0c0.1,0,0.1,0.1,0,0.2
      l0,0C75,24.3,74.9,24.3,74.9,24.3z"
      />
      <path
        fill="#FFFFFF"
        d="M140.8,15.6c0,0,3.8-1.4,6.3-6c0-0.1,0.1-0.1,0.2-0.1c0.7,0.4,1.3,0.9,1.6,1.6c0.3,0.7-1.7,3.4-3.6,5"
      />
      <path
        fill="#3C3C64"
        d="M140.7,15.4c0.7-0.3,1.3-0.7,1.9-1.1c0.6-0.4,1.1-0.9,1.7-1.4c0.5-0.5,1-1.1,1.5-1.6c0.2-0.3,0.4-0.6,0.6-0.9
      c0.1-0.2,0.2-0.3,0.3-0.5l0.1-0.2l0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.1,0,0.2,0h0.1l0.3,0.2
      c0.2,0.1,0.3,0.2,0.5,0.4c0.3,0.3,0.6,0.6,0.8,0.9c0.1,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
      c-0.2,0.4-0.4,0.7-0.6,1c-0.9,1.2-1.9,2.3-3.1,3.3c-0.1,0.1-0.1,0.1-0.2,0c0-0.1,0-0.1,0-0.2c0.5-0.5,1-1,1.5-1.6s0.9-1.1,1.3-1.8
      c0.2-0.3,0.3-0.6,0.5-0.9c0.1-0.1,0.1-0.3,0.1-0.4v-0.1c0,0,0,0,0-0.1s-0.1-0.1-0.1-0.2c-0.2-0.3-0.4-0.5-0.7-0.7
      c-0.1-0.1-0.3-0.2-0.4-0.3l-0.2-0.1l0,0c0,0,0,0,0.1,0h0.1c0-0.1,0,0,0,0.1l-0.1,0.1l-0.1,0.2c-0.1,0.2-0.2,0.3-0.3,0.5
      c-0.2,0.3-0.4,0.6-0.6,0.9c-0.4,0.6-0.9,1.2-1.5,1.7c-1.1,1.1-2.4,1.9-3.8,2.5c-0.1,0-0.3,0-0.3-0.2
      C140.5,15.5,140.6,15.4,140.7,15.4L140.7,15.4z"
      />
      <path
        fill="#FFFFFF"
        d="M136,16.2c0,0,3.5-3.4,2.8-10.5c0.6-0.8,1.3-1.3,2.2-1.7c1-0.4,2.2,5.6,0.3,9"
      />
      <path
        fill="#3C3C64"
        d="M135.5,15.8c0.3-0.3,0.6-0.6,0.8-1s0.5-0.7,0.7-1.1c0.4-0.8,0.7-1.6,0.9-2.5s0.4-1.8,0.4-2.7c0-0.5,0-0.9,0-1.4
      s0-0.9-0.1-1.4l0,0c0-0.1,0-0.2,0.1-0.2l0,0c0.3-0.4,0.7-0.8,1.2-1.2c0.2-0.2,0.5-0.3,0.7-0.4c0.1-0.1,0.3-0.1,0.4-0.2h0.1
      c0.1,0,0.2,0,0.2,0c0.1,0,0.3,0.1,0.4,0.2c0.2,0.2,0.4,0.5,0.5,0.8s0.2,0.5,0.3,0.8c0.1,0.5,0.2,1.1,0.3,1.6
      c0.1,1.1,0.1,2.1-0.1,3.2c-0.1,1.1-0.5,2.1-1,3c-0.1,0.1-0.2,0.2-0.3,0.1l0,0c-0.1-0.1-0.2-0.2-0.1-0.3c0.5-0.9,0.8-1.9,0.9-2.9
      c0.1-1,0.1-2,0-3c-0.1-0.5-0.2-1-0.3-1.5c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.2-0.3-0.3-0.5l0,0l0,0h-0.1c-0.1,0-0.2,0.1-0.3,0.2
      c-0.2,0.1-0.4,0.2-0.6,0.4c-0.4,0.3-0.7,0.6-1,1l0,0l0.1-0.3c0,0.5,0.1,1,0.1,1.4c0,0.5,0,1,0,1.4c0,1-0.2,1.9-0.4,2.9
      c-0.2,0.9-0.5,1.9-0.9,2.7c-0.4,0.9-1,1.8-1.6,2.5c-0.2,0.2-0.6,0.2-0.8,0C135.3,16.4,135.3,16,135.5,15.8L135.5,15.8L135.5,15.8z"
      />
      <path
        fill="#3C3C64"
        d="M134.8,16.9c-0.9,1.3-1.5,2.7-1.8,4.2c-0.3,1.5-0.5,2.9-0.4,4.4c0,1.5,0.2,2.9,0.5,4.4c0.6,2.8,1.7,5.6,3.2,8.1
      c0.8,1.2,1.7,2.4,2.7,3.4s2.3,1.9,3.6,2.4c0.1,0,0.2,0,0.2-0.1s0-0.2-0.1-0.3c-1.2-0.6-2.3-1.5-3.2-2.6s-1.6-2.2-2.3-3.4
      c-1.3-2.4-2.1-5.1-2.6-7.8s-0.5-5.4,0.1-8c0.3-1.3,0.8-2.5,1.5-3.5c0.4-0.5,0.8-0.9,1.3-1.2c0.5-0.3,1-0.5,1.6-0.5
      c1.2,0,2.3,0.4,3.2,1.2c1,0.8,1.9,1.8,2.7,2.9s1.4,2.3,2,3.5s1.1,2.5,1.5,3.8c0.8,2.6,1.2,5.4,1.1,8.1c0,1.4-0.3,2.7-0.7,4
      s-1.2,2.4-2.3,3.2l0,0c-0.1,0.1-0.1,0.2-0.1,0.2c0,0.1,0.1,0.1,0.2,0.1l0,0c0.7-0.3,1.3-0.8,1.7-1.4c0.5-0.6,0.8-1.2,1.1-1.9
      c0.6-1.3,0.9-2.8,1.1-4.2c0.3-2.9,0.1-5.8-0.7-8.7c-0.7-2.9-1.9-5.6-3.5-8c-0.8-1.3-1.9-2.4-3-3.3c-0.6-0.5-1.3-0.9-2.1-1.2
      s-1.6-0.5-2.5-0.4c-0.9,0-1.7,0.3-2.5,0.8C135.9,15.6,135.3,16.2,134.8,16.9z"
      />
      <path
        fill="#FFFFFF"
        d="M81.1,178.6c0,0-10.5-5-16.3-9.7c-0.6-0.5-1.2-1-1.7-1.6l-0.3-0.3c-1.1-1-4.6-4.3-8.7-8.1l-2-1.8
      c-3-2.8-6.2-5.7-8.8-8.1l-1.7-1.5l-0.2-0.2l-0.3-0.3c-3.2-2.9-6.4-5.7-9.4-8.7c-0.5-0.5-1.1-1-1.6-1.6c-3.1-3.1-6-6.5-8.7-10
      c-0.4-0.6-0.9-1.2-1.3-1.9c-1.9-2.9-3.6-5.9-5-9c-0.3-0.7-0.6-1.5-0.9-2.3c-1.2-3.2-2.2-6.6-2.8-10c-0.2-0.8-0.3-1.7-0.4-2.5l0,0
      c-0.4-2.9-0.6-5.9-0.7-8.8c0-0.9,0-1.8,0-2.8c0-1.6,0.1-3.2,0.2-4.8c0-0.2,0-0.4,0-0.5c0.2-2.7,2.5-4.7,5.2-4.5s4.7,2.5,4.5,5.2
      c0,0.6-0.1,1.2-0.1,1.8c0,0.6-0.1,1.3-0.1,1.9c-0.1,2.7,0,5.4,0.2,8c0.1,0.7,0.1,1.3,0.2,1.9c0.4,3.1,1,6.1,2,9.1
      c0.2,0.6,0.4,1.2,0.6,1.9c1.1,3,2.6,5.9,4.3,8.6c0.4,0.6,0.7,1.1,1.1,1.7c2.3,3.3,4.8,6.3,7.6,9.2c0.5,0.5,0.9,1,1.4,1.5
      c2.6,2.6,5.4,5.1,8.3,7.8l1.5,1.3l0.4,0.3c2.6,2.4,5.5,4.9,8.5,7.7c0.5,0.4,1,0.9,1.5,1.3c3,2.8,6,5.7,9,8.9c0.4,0.5,0.9,1,1.3,1.4
      c4.8,5.3,9.3,11.2,12.8,17.8C80.9,177.3,81.1,177.9,81.1,178.6z"
      />
      <path
        fill="#8764FF"
        d="M81.1,178.6c0,0-10.5-5-16.3-9.7c-0.6-0.5-1.2-1-1.7-1.6l-0.3-0.3c-1.1-1-4.6-4.3-8.7-8.1l-2-1.8
      c-3-2.8-6.2-5.7-8.8-8.1l-1.7-1.5c0.9-4.4-11.6-13.8-20.4-29.5c-11.1-19.7-4.8-31.6-6.3-34.1c-2.3-3.8-4.5,0.6-4.5,0.6
      c0-0.2,0-0.4,0-0.5c0.2-2.7,2.5-4.7,5.2-4.5s4.7,2.5,4.5,5.2l0,0c0,0.6-0.1,1.2-0.1,1.8c0,0.6-0.1,1.3-0.1,1.9c-0.1,2.7,0,5.4,0.2,8
      c0.1,0.7,0.1,1.3,0.2,1.9c0.4,3.1,1,6.1,2,9.1c0.2,0.6,0.4,1.2,0.6,1.9c1.1,3,2.6,5.9,4.3,8.6c0.4,0.6,0.7,1.1,1.1,1.7
      c2.3,3.3,4.8,6.3,7.6,9.2c0.5,0.5,0.9,1,1.4,1.5c2.6,2.6,5.4,5.1,8.3,7.8l1.5,1.3l0.4,0.3c2.6,2.4,5.5,4.9,8.5,7.7
      c0.5,0.4,1,0.9,1.5,1.3c3,2.8,6,5.7,9,8.9c0.4,0.5,0.9,1,1.3,1.4c4.8,5.3,6.8,11.5,10.3,18.1C78.3,177.6,81.1,178,81.1,178.6z"
      />
      <path
        fill="#3C3C64"
        d="M40.6,147.3c-6.3-5.7-12.9-11.3-18.2-18.1c-2.7-3.4-5-7-7-10.9c-1-1.9-1.8-3.9-2.5-6c-0.7-2-1.3-4.1-1.8-6.2
      c-1-4.2-1.5-8.5-1.6-12.8c0-1.1,0-2.2,0-3.2l0.1-3.2l0.1-1.6v-0.8c0-0.3,0-0.5,0.1-0.8c0.2-1.2,0.7-2.4,1.6-3.2
      c2.2-2.1,5.7-2.1,7.9,0.1c0.5,0.6,0.9,1.2,1.2,1.9c0.2,0.6,0.3,1.2,0.4,1.8c0,0.7-0.1,1.1-0.1,1.7c-0.2,2.1-0.2,4.2-0.2,6.3
      c0.1,2.1,0.2,4.2,0.5,6.3c0.3,2.1,0.7,4.2,1.2,6.2s1.2,4,2,6c1.6,3.9,3.8,7.5,6.3,10.9c5.1,6.7,11.6,12.4,17.9,18.1
      s12.8,11.3,18.7,17.6c2.9,3.1,5.7,6.4,8.2,9.9c1.3,1.7,2.5,3.5,3.6,5.4c0.6,0.9,1.1,1.8,1.6,2.8L81,176c0.1,0.2,0.3,0.5,0.4,0.8
      c0.3,0.6,0.4,1.2,0.5,1.8l0,0c0,0.4-0.2,0.7-0.6,0.8l0,0c-0.1,0-0.3,0-0.4-0.1c-3.9-1.9-7.7-4-11.3-6.2c-1.9-1.1-3.6-2.4-5.3-3.8
      c-0.4-0.3-0.8-0.7-1.2-1.1l-0.6-0.6l-0.6-0.6l-2.3-2.2C53.1,159,47,153,40.6,147.3z M41.3,146.4l9.5,8.6c3.2,2.9,6.3,5.8,9.5,8.7
      l2.4,2.2l0.6,0.5l0.6,0.6c0.4,0.4,0.8,0.7,1.2,1c1.6,1.3,3.4,2.5,5.2,3.7c3.6,2.2,7.4,4.3,11.2,6.2l-1,0.7c0-0.5-0.2-0.9-0.3-1.3
      c-0.1-0.2-0.2-0.4-0.3-0.7l-0.4-0.7c-0.5-0.9-1-1.8-1.6-2.7c-1.1-1.8-2.3-3.6-3.5-5.3c-2.5-3.4-5.2-6.7-8.1-9.8
      c-5.8-6.2-12.2-11.8-18.6-17.6c-3.2-2.9-6.4-5.7-9.4-8.7c-3.1-3-6-6.2-8.7-9.6c-2.6-3.4-4.8-7.2-6.5-11.2c-0.8-2-1.5-4.1-2.1-6.2
      c-0.5-2.1-0.9-4.2-1.2-6.4c-0.3-2.1-0.4-4.3-0.5-6.5c0-2.2,0-4.3,0.2-6.5c0-0.5,0.1-1.1,0.1-1.5c0-0.5-0.1-0.9-0.3-1.3
      c-0.8-2.1-3.2-3.2-5.3-2.4c-0.5,0.2-1,0.5-1.4,0.9c-0.7,0.6-1.1,1.5-1.2,2.4c0,0.2,0,0.5-0.1,0.8L11,85.2l-0.1,1.6L10.8,90
      c0,1.1,0,2.1,0,3.2c0.1,4.2,0.7,8.5,1.6,12.6c0.5,2.1,1.1,4.1,1.8,6.1s1.5,3.9,2.5,5.8c1.9,3.8,4.2,7.4,6.8,10.7
      C28.8,134.9,35,140.7,41.3,146.4z"
      />
      <path
        fill="#FFFFFF"
        d="M216.8,120.2L216.8,120.2c0,0.7-0.1,1.3-0.2,1.9c-0.2,2.1-0.7,4.2-1.4,6.2c-2.6,7.7-8.3,13.4-15.4,18.2
      c-0.5,0.4-1.1,0.7-1.6,1.1c-3.7,2.3-7.6,4.5-11.6,6.4c-0.6,0.3-1.1,0.6-1.7,0.9c-1.7,0.8-3.3,1.6-5,2.5c-2.8,1.4-5.6,2.8-8.5,4.2
      l-1.7,0.9c-4.2,2.2-8.3,4.5-12.2,7.1l-0.7,0.5c-3,2-5.9,4.3-8.7,6.7c-0.5,0.4-1,0.9-1.5,1.3c-1,1-2,1.9-2.9,3
      c-4.3,1.5-14.9,3.6-14.9,3.6l0.1-0.1l0.2-0.4l0.1-0.2c0.1-0.1,0.2-0.3,0.3-0.5c1.8-2.9,3.9-5.6,6.2-8.2c0.7-0.8,1.4-1.5,2.1-2.2
      c2.5-2.6,5.2-5,8-7.2c0.8-0.6,1.7-1.3,2.6-1.9c3.7-2.6,7.6-5.1,11.6-7.3l0,0c0.9-0.5,1.8-1,2.7-1.5c4.1-2.2,8.2-4.2,12.3-6.2
      l0.5-0.3l2.3-1.1c3.8-1.8,7.3-3.6,10.6-5.5c0.8-0.5,1.6-0.9,2.4-1.4c3.3-1.9,6.5-4.2,9.3-6.8c0.7-0.7,1.4-1.5,2.1-2.2
      c1.6-1.8,2.8-3.9,3.6-6.1c0-0.1,0.1-0.2,0.1-0.3c0.3-0.9,0.6-1.9,0.7-2.9c0.5-3,0.4-6-0.1-8.9c-0.2-0.9-0.3-1.8-0.6-2.7
      c-0.7-2.8-1.6-5.5-2.8-8.2c-0.3-0.7-0.6-1.4-0.9-2.1c-0.4-1-0.9-2-1.4-3c-1-2.5,0.1-5.3,2.6-6.3c2.3-0.9,4.8-0.1,6.1,2.1
      c0.3,0.6,0.6,1.3,0.9,1.9c0.3,0.6,0.6,1.2,0.8,1.8c1.3,2.8,2.4,5.7,3.3,8.7c0.2,0.6,0.4,1.3,0.5,1.9
      C216.5,111.5,217,115.9,216.8,120.2z"
      />
      <path
        fill="#8764FF"
        d="M216.8,120.2L216.8,120.2c0,0.7-0.1,1.3-0.2,1.9c-0.2,2.1-0.7,4.2-1.4,6.2c-2.6,7.7-8.3,13.4-15.4,18.2
      c-0.5,0.4-1.1,0.7-1.6,1.1c-3.7,2.3-7.6,4.5-11.6,6.4c-0.6,0.3-1.1,0.6-1.7,0.9c-1.7,0.8-3.3,1.6-5,2.5c-2.8,1.4-5.6,2.8-8.5,4.2
      l-1.7,0.9c-4.2,2.2-8.3,4.5-12.2,7.1l-0.7,0.5c-3,2-5.9,4.3-8.7,6.7c-0.5,0.4-1,0.9-1.5,1.3c-1,1-2,1.9-2.9,3
      c-4.3,1.5-14.9,3.6-14.9,3.6l0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.4c0-0.1,3.7-0.6,3.8-0.7c2.4-3.8,11.2-21,33.8-28.2
      c25.4-8,41.9-18.9,44.7-31.7c3.5-16.3-8.2-33.3-10-29l-0.5-1.1c1-2.5,3.8-3.7,6.3-2.7c1.1,0.4,2,1.3,2.5,2.3
      c0.3,0.6,0.6,1.3,0.9,1.9c0.3,0.6,0.6,1.2,0.8,1.8c1.3,2.8,2.4,5.7,3.3,8.7c0.2,0.6,0.4,1.3,0.5,1.9
      C216.5,111.5,217,115.9,216.8,120.2z"
      />
      <path
        fill="#3C3C64"
        d="M128.9,185.4c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.2-0.5,0-0.8c11.4-18.8,30.4-28.1,47.1-36.3
      c14.3-7,26.6-13,30.1-23c2.3-6.8,0.7-15.7-5-27.2c-1.5-2.7-0.6-6.1,2-7.6c2.7-1.5,6.1-0.6,7.6,2c0.1,0.2,0.2,0.4,0.3,0.7
      c7.1,14.3,8.9,26,5.6,35.7c-4.9,14.4-19.9,21.7-35.7,29.5c-12.2,6-26,12.7-36,23.5c-0.1,0.1-0.2,0.2-0.3,0.2
      c-4.3,1.5-14.5,3.6-14.9,3.7L128.9,185.4z M205.4,91.1c-1.8,0-3.3,1.1-3.9,2.8c-0.4,1-0.3,2.2,0.2,3.1c5.9,11.9,7.5,21.2,5.1,28.3
      c-3.6,10.6-16.2,16.7-30.8,23.9c-16.1,7.9-34.3,16.8-45.6,34.3c3.1-0.7,9.7-2.1,12.9-3.2c10.1-10.9,24-17.7,36.3-23.7
      c15.6-7.6,30.2-14.8,35-28.6c3.2-9.3,1.4-20.7-5.5-34.6C208.4,92,207,91.1,205.4,91.1z"
      />
      <path
        fill={sparkFillColor}
        d="M204.5,48.6L204.5,48.6c-0.9-0.7-1.9-1.5-2.7-2.3c-0.8-0.7-1.5-1.6-2-2.6c-0.2-0.4-0.1-0.8,0.1-1.1
      c0.3-0.4,0.6-0.8,1-1.1c0.9-0.7,1.9-1.4,3-1.9c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2-0.2-0.1l0,0c-1.2,0.2-2.4,0.7-3.5,1.3
      c-0.6,0.4-1.1,0.8-1.5,1.4c-0.2,0.3-0.4,0.7-0.4,1.1c0,0.4,0,0.8,0.2,1.2c0.6,1.2,1.5,2.2,2.6,2.9c1,0.7,2.1,1.3,3.3,1.7
      c0.1,0,0.2,0,0.2-0.1C204.6,48.7,204.6,48.6,204.5,48.6L204.5,48.6z"
      />
      <path
        fill={sparkFillColor}
        d="M193.5,38.6c0.5,0.4,1.1,0.8,1.6,1.1c0.3,0.2,0.6,0.3,1,0.4h0.3c0.1,0,0.2,0,0.3,0c0.2,0,0.3-0.1,0.5-0.1
      c0.3-0.1,0.7-0.2,0.9-0.4c0.3-0.2,0.6-0.4,0.8-0.6c0.1-0.1,0.1-0.3,0-0.3c-0.1-0.1-0.2-0.1-0.3,0l0,0c-0.5,0.3-1,0.5-1.6,0.7
      c-0.1,0-0.3,0.1-0.4,0.1h-0.2h-0.2c-0.3-0.1-0.5-0.2-0.8-0.3c-0.6-0.3-1.1-0.6-1.6-0.9c-0.5-0.3-1-0.7-1.5-1.1l0,0
      c-0.1-0.1-0.2-0.1-0.3,0s-0.1,0.2,0,0.3l0,0C192.5,37.8,193,38.2,193.5,38.6z"
      />
      <path
        fill={sparkFillColor}
        d="M194,43.9c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.3,0-0.4c0-0.3-0.1-0.5-0.2-0.8c-0.2-0.4-0.4-0.8-0.7-1.1
      c-0.6-0.6-1.2-1.2-2-1.6c-0.1-0.1-0.2-0.1-0.3,0s-0.1,0.2,0,0.3l0,0c0.5,0.6,1,1.2,1.4,1.9s0.7,1.3,0.5,1.6
      c-0.1,0.3-0.3,0.5-0.6,0.7c-0.3,0.3-0.6,0.5-0.9,0.7c-0.6,0.5-1.3,0.9-1.9,1.3l0,0c-0.1,0.1-0.1,0.2-0.1,0.3s0.1,0.2,0.3,0.2l0,0
      c0.8-0.2,1.6-0.4,2.4-0.8c0.4-0.2,0.7-0.4,1.1-0.7C193.4,44.7,193.8,44.3,194,43.9z"
      />
      <path
        fill={sparkFillColor}
        d="M198.4,48c-0.3-0.3-0.6-0.5-0.9-0.8c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.5-0.2-0.7-0.3H196
      c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.1l-0.2,0.1l-0.3,0.3l-0.2,0.3c-0.2,0.3-0.5,0.7-0.6,1.1c-0.4,0.7-0.6,1.5-0.8,2.2
      c0,0.1,0,0.2,0.1,0.3s0.3,0,0.3-0.1l0,0l0,0c0.4-0.7,0.8-1.3,1.2-1.9c0.2-0.3,0.4-0.6,0.6-0.9l0.2-0.2l0.2-0.1h0.1l0,0l0,0l0,0
      c0.1,0,0.3,0.1,0.4,0.1c0.2,0.1,0.3,0.2,0.5,0.3c0.3,0.2,0.6,0.4,0.9,0.6l1.8,1.4c0.1,0.1,0.2,0.1,0.3,0s0.1-0.2,0-0.3
      C199.5,49.2,198.9,48.6,198.4,48z"
      />
      <path
        fill={sparkFillColor}
        d="M190.1,22.7c0.1-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.3-0.1-0.3,0l0,0c-0.9,1.2-1.7,2.6-2.3,3.9
      c-0.6,1.4-1.1,2.8-1.6,4.3c-0.4,1.5-0.8,3-1,4.5c-0.1,0.6-0.2,1.2-0.3,1.9l-0.1-0.2c-0.3-0.4-0.7-0.8-1-1.2
      c-0.2-0.2-0.5-0.4-0.8-0.5c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2,0-0.5,0-0.7,0.1c-0.3,0.2-0.6,0.5-0.8,0.8c-0.1,0.3-0.2,0.5-0.3,0.8
      l-0.1,0.4l-0.1,0.4c0,0.3-0.1,0.5-0.1,0.7c-0.1,1-0.1,2-0.1,2.9c0.1,1.9,0.3,3.8,0.5,5.7c0.3,1.9,0.6,3.8,0.9,5.7
      c0,0.1,0.1,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.3l0,0c-0.3-1.9-0.5-3.8-0.6-5.7c-0.2-1.9-0.2-3.8-0.2-5.7c0-0.9,0.1-1.9,0.2-2.8
      c0-0.2,0.1-0.4,0.1-0.7l0.1-0.3l0.1-0.3c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.1,0.2-0.2c0,0-0.1,0,0.1,0.1c0.1,0.1,0.3,0.2,0.4,0.3
      c0.3,0.3,0.5,0.6,0.8,1c0.5,0.8,0.9,1.6,1.3,2.4l0,0V40c0.2,0.4,0.8,0.6,1.2,0.4c0.3-0.2,0.5-0.5,0.5-0.8c0-1.5,0.1-2.9,0.2-4.4
      c0.2-1.5,0.4-2.9,0.7-4.4c0.3-1.4,0.7-2.9,1.2-4.3C188.7,25.3,189.4,24,190.1,22.7L190.1,22.7z"
      />
      <path
        fill={sparkFillColor}
        d="M24.6,35.7c-0.1-0.1-0.2-0.3-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.3c-0.2,0.1-0.3,0.3-0.5,0.4
      c-0.4,0.5-0.8,1.1-1.1,1.8c-0.3,0.6-0.5,1.2-0.7,1.8c-0.1,0.2-0.1,0.3-0.2,0.5V40c-0.1-0.2-0.2-0.3-0.3-0.4
      c-0.2-0.3-0.3-0.6-0.5-0.8c-0.4-0.5-0.7-1.1-1.1-1.6C17,35,15.5,33,13.8,31c-0.1-0.1-0.2-0.1-0.3,0s-0.1,0.2-0.1,0.3
      c1.3,2.2,2.7,4.4,4.1,6.5c0.7,1.1,1.4,2.1,2,3.2c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.1,0.4,0.1
      s0.3-0.1,0.4-0.1s0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.3,0.4-0.6,0.5-1c0.3-0.6,0.5-1.2,0.7-1.8c0.2-0.5,0.5-1,0.8-1.5
      c0.1-0.1,0.2-0.2,0.2-0.2s0,0,0.1,0c0,0.1,0,0.1,0.1,0.2c0.1,0.6,0.2,1.2,0.2,1.8s0,1.3,0,1.9c-0.1,2.5-0.3,5.1-0.6,7.6l0,0
      c0,0.1,0.1,0.2,0.2,0.3c0.1,0,0.2-0.1,0.3-0.2l0,0c0.5-2.5,0.9-5.1,1.1-7.7c0-0.6,0.1-1.3,0.1-1.9c0-0.7,0-1.4-0.2-2.1
      C24.8,36.1,24.7,35.9,24.6,35.7z"
      />
      <path
        fill={sparkFillColor}
        d="M161.1,12c0.2-0.5,0.2-1.1,0.2-1.7c0-0.6-0.4-1.1-1-1.4c-0.1,0-0.1,0-0.2,0.1c0,0.1,0,0.1,0,0.1
      c0.4,0.3,0.6,0.7,0.6,1.2s-0.1,1-0.3,1.5s-0.4,0.9-0.7,1.3c-0.3,0.4-0.6,0.8-1,1.2c-0.3,0.4-0.7,0.6-1.2,0.8
      c-0.2,0.1-0.4,0.1-0.4,0.1s-0.1-0.2-0.2-0.4c0-0.5,0-1,0.1-1.5s0.2-1,0.4-1.5c0-0.1,0-0.1-0.1-0.1s-0.1,0-0.2,0
      c-0.3,0.5-0.6,0.9-0.8,1.5c-0.2,0.5-0.3,1.1-0.3,1.7c0,0.2,0,0.3,0.1,0.5c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2
      c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.3-0.1,0.5-0.1c0.5-0.3,1-0.6,1.4-1C160.1,14,160.7,13,161.1,12z"
      />
      <path
        fill={sparkFillColor}
        d="M16.5,41.8c-0.3-0.5-0.6-1-1.1-1.4c-0.8-0.8-1.8-1.3-2.9-1.7c-0.5-0.2-1.1-0.2-1.7-0.1c-0.6,0.1-1.1,0.5-1.4,1
      c0,0.1,0,0.1,0.1,0.2c0.1,0,0.1,0,0.2,0c0.2-0.4,0.7-0.7,1.2-0.7s1,0.1,1.5,0.2c0.5,0.2,0.9,0.4,1.3,0.7c0.4,0.3,0.8,0.6,1.2,1
      c0.4,0.3,0.7,0.7,0.9,1.1c0.1,0.2,0.1,0.4,0.1,0.4s-0.2,0.1-0.4,0.2c-0.5,0-1,0-1.5-0.1s-1-0.2-1.5-0.3c-0.1,0-0.1,0-0.2,0.1
      c0,0.1,0,0.1,0.1,0.1c0.5,0.3,1,0.5,1.5,0.7s1.1,0.3,1.7,0.2c0.2,0,0.3-0.1,0.5-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.2,0.2-0.3
      c0.1-0.2,0.2-0.4,0.1-0.6C16.6,42.1,16.6,42,16.5,41.8z"
      />
      <path
        fill={sparkFillColor}
        d="M163.5,26.3c-0.1-0.1-0.3-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3l0,0c0.2,0.3,0.3,0.7,0.2,1
      c-0.1,0.4-0.3,0.8-0.5,1.1c-0.4,0.7-1,1.3-1.7,1.6c-0.3,0.2-0.6,0.3-1,0.2c-0.2-0.1-0.3-0.2-0.4-0.5c0-0.4,0-0.7,0.2-1.1
      s0.3-0.8,0.6-1.1l0,0c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0l0,0c-0.4,0.3-0.7,0.7-0.9,1.1c-0.3,0.5-0.4,1-0.4,1.5
      c0,0.3,0.1,0.6,0.3,0.9s0.5,0.4,0.9,0.5c0.6,0.1,1.1,0,1.6-0.3c0.8-0.5,1.5-1.2,2-2.1c0.2-0.4,0.4-0.9,0.4-1.4c0-0.3,0-0.5-0.1-0.8
      C163.8,26.7,163.7,26.5,163.5,26.3z"
      />
      <path
        fill={sparkFillColor}
        d="M65.6,36.4c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.2,0.2-0.1,0.3l0,0c0.2,0.4,0.4,0.7,0.5,1.1
      c0.1,0.4,0.2,0.7,0.2,1.1c0,0.3-0.2,0.5-0.4,0.5c-0.3,0-0.7,0-1-0.2c-0.7-0.4-1.3-1-1.7-1.6c-0.2-0.3-0.4-0.7-0.5-1.1
      c-0.1-0.3,0-0.7,0.2-1l0,0c0.1-0.1,0.1-0.3,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.2,0.2-0.4,0.4-0.5,0.7s-0.1,0.5,0,0.8
      c0.1,0.5,0.2,1,0.4,1.4c0.4,0.9,1.1,1.6,2,2.1c0.5,0.3,1,0.4,1.6,0.3c0.3-0.1,0.6-0.3,0.8-0.5c0.2-0.3,0.3-0.6,0.3-0.9
      c0-0.5-0.1-1-0.4-1.5C66.2,37.1,65.9,36.8,65.6,36.4z"
      />
      <path
        fill={sparkFillColor}
        d="M64.1,28c0.2-0.1,0.4-0.3,0.5-0.5s0.1-0.4,0.1-0.6c-0.2-0.7-0.7-1.4-1.3-1.8c-0.1,0-0.1,0-0.2,0
      c0,0,0,0.1,0,0.2l0,0c0.5,0.4,0.9,1,1,1.6c0,0.3-0.1,0.5-0.3,0.6c-0.3,0.1-0.6,0-0.8-0.1c-0.3-0.1-0.6-0.3-0.8-0.5
      c-0.3-0.2-0.5-0.4-0.7-0.6s-0.4-0.5-0.6-0.7c-0.2-0.2-0.2-0.5-0.1-0.8l0,0v-0.1c0,0-0.1,0-0.2,0c-0.2,0.1-0.3,0.3-0.3,0.6
      c0,0.2,0,0.4,0,0.6c0.1,0.4,0.3,0.7,0.5,1.1c0.5,0.6,1.2,1.1,1.9,1.3C63.3,28.2,63.7,28.2,64.1,28z"
      />
      <path
        fill={sparkFillColor}
        d="M170.7,11.9c-0.1-0.1-0.2,0-0.3,0c-0.1,0.1,0,0.2,0,0.2l0,0c0.2,0.2,0.2,0.5,0.1,0.7c-0.1,0.3-0.2,0.6-0.3,0.8
      c-0.3,0.5-0.8,0.9-1.3,1.3c-0.2,0.1-0.5,0.2-0.7,0.2s-0.3-0.2-0.3-0.4c0-0.3,0-0.6,0.1-0.8c0.1-0.3,0.3-0.6,0.4-0.8l0,0
      c0.1-0.1,0-0.2,0-0.2c-0.1-0.1-0.2-0.1-0.3,0l0,0c-0.5,0.5-0.9,1.2-0.9,1.9c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.4,0.3,0.6,0.4
      c0.4,0.1,0.8,0,1.2-0.2c0.6-0.4,1.1-0.9,1.5-1.5c0.2-0.3,0.3-0.7,0.3-1c0-0.2,0-0.4,0-0.6C171,12.3,170.9,12.1,170.7,11.9z"
      />
      <path
        fill={sparkFillColor}
        d="M36.1,34.2c-0.3,0-0.6,0.2-0.8,0.4S35,35.1,35,35.4s0.1,0.6,0.2,0.9c0.2,0.3,0.5,0.5,0.9,0.5s0.7-0.2,0.9-0.5
      s0.2-0.6,0.2-0.9c0-0.3-0.1-0.6-0.3-0.8C36.7,34.4,36.4,34.2,36.1,34.2z M36.1,36.4c-0.4,0-0.7-0.4-0.8-0.9c0-0.3,0-0.5,0.2-0.7
      c0.1-0.2,0.3-0.4,0.6-0.4s0.5,0.1,0.6,0.4c0.1,0.2,0.2,0.5,0.2,0.7C36.8,36,36.5,36.4,36.1,36.4L36.1,36.4z"
      />
      <path
        fill="#8764FF"
        d="M20,53.1c0,0,1.9,3.1,3.7,3.5c1.1,0.3,2.2,0.4,3.3,0.4c-0.1,0.7,0,1.3,0.1,2c0,0-3.1-1-5.7-1
      C21.2,56.3,20.7,54.6,20,53.1z"
      />
    </svg>
  )
}
