import React from 'react'

import { Project, TestRunStats } from 'types'
import { useAppContext } from 'appContext'
import { CodeSnippet } from 'components/CodeSnippet'
import { getLoginCommand } from 'components/CLIRunGuide'
import { getUserOS } from 'utils/platform'
import { getCreateScriptSnippet } from './snippets'
import { docs } from '../DocLink'

interface TabbedCLIGuideProps {
  project: Project
  stats?: TestRunStats
}

export const getCodeSnippetTabs = ({
  token,
  project,
}: {
  token: string
  project: Project
}) => [
  {
    value: 'installation',
    label: '1. Install k6',
    selected: getUserOS(),
    groups: [
      {
        value: 'debian',
        label: 'Linux (Debian/Ubuntu)',
        dedent: true,
        code: `sudo apt-key adv --keyserver hkp://keyserver.ubuntu.com:80 --recv-keys C5AD17C747E3415A3642D57D77C6C491D6AC1D69
echo "deb https://dl.k6.io/deb stable main" | sudo tee /etc/apt/sources.list.d/k6.list
sudo apt-get update
sudo apt-get install k6`,
      },
      {
        value: 'fedora-centos',
        label: 'Linux (Fedora/CentOS)',
        dedent: true,
        code: `sudo dnf install https://dl.k6.io/rpm/repo.rpm
sudo dnf install k6`,
      },
      {
        value: 'osx',
        label: 'macOS',
        dedent: true,
        code: `brew install k6`,
      },
      {
        value: 'windows',
        label: 'Windows',
        dedent: true,
        code: `// Using Chocolatey to install the unofficial k6 package
choco install k6
// Or download the official k6 installer: https://dl.k6.io/msi/k6-latest-amd64.msi
// For additional ways of installing, visit ${docs.gettingStarted.installation}`,
      },
      {
        value: 'docker',
        label: 'Docker',
        dedent: true,
        code: `docker pull grafana/k6`,
      },
    ],
  },
  {
    label: '2. Authenticate',
    value: 'auth',
    code: getLoginCommand(token),
  },
  {
    label: '3. Create a script',
    value: 'createScript',
    code: getCreateScriptSnippet(project),
    dedent: false,
    download: 'script.js',
  },
  {
    label: '4. Run a test',
    value: 'runATest',
    groups: [
      {
        value: 'cloud',
        label: 'Run your test in the Cloud',
        code: 'k6 cloud script.js',
      },
      {
        value: 'run',
        label: 'Run locally, stream results to the Cloud',
        code: 'k6 run --out=cloud script.js',
      },
    ],
  },
]

export const TabbedCLIGuide = ({ project, stats }: TabbedCLIGuideProps) => {
  const { account } = useAppContext()
  const tabs = getCodeSnippetTabs({ token: account.token.key, project })
  const initialTab =
    stats && stats.cli_started_test_runs > 0 ? 'createScript' : undefined

  return (
    <CodeSnippet height="256px" lang="js" tabs={tabs} initialTab={initialTab} />
  )
}
