import { getValueFormat, type FormattedValue } from '@grafana/data'

import type { Trend } from 'types'
import { toUnit } from 'utils/units'

const HEIGHT_DISTRIBUTION = 100
const MINIMUM_PORTION = 2
const BASE_STEP = 1

const createFormattedValue = (value: FormattedValue) => {
  return [value.prefix, value.text, value.suffix].join(' ')
}

export const getSteps = (height: number, max: number) => {
  const count = Math.floor(height / HEIGHT_DISTRIBUTION)
  const portion = max / count || MINIMUM_PORTION

  return Array.from({ length: count + BASE_STEP })
    .map((_, index) => portion * index)
    .reverse()
}

export const getValueWithUnit = (value: number | null, trend?: Trend) => {
  if (typeof value !== 'number' || !trend) {
    return '-'
  }

  const unit = toUnit({
    metric: trend.metric_name,
    method: trend.aggregation_function,
  })

  const formatter = getValueFormat(unit)
  const formattedValue = formatter(value)

  return formattedValue ? createFormattedValue(formattedValue) : '-'
}
