import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useDatasource } from '../datasourceContext'
import { toProvisioningStateQueryKey } from './queryKeys'
import { showAlert } from '../utils/showAlert'

export function useCreateAccountProvisioning() {
  const queryClient = useQueryClient()
  const { ds } = useDatasource()

  return useMutation({
    mutationFn: () => ds.createAccountProvisioning(),
    onSuccess() {
      queryClient.invalidateQueries(toProvisioningStateQueryKey())
    },
    onError() {
      showAlert('Unable to initialize Grafana k6 cloud.', 'error')
    },
  })
}
