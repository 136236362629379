import { Test } from 'types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  TrendingMetricDraftConfig,
  TrendingMetricDraftConfigError,
} from './TrendingMetricsEditor.types'
import {
  areOriginalConfigsChanged,
  toTrendingMetricDraftConfig,
  toNewTrendingMetricDraftConfig,
  getTrendingMetricDraftConfigsErrors,
  getUpdatedValidationErrors,
} from './TrendingMetricsEditor.utils'
import { usePrevious } from 'hooks/usePrevious'

export const useTrendingMetricDraftConfig = (test: Test, isOpen?: boolean) => {
  const prevIsOpen = usePrevious(isOpen)

  const initialDraftConfigs = test.trending_metrics.map(
    (trendingMetricConfig) => toTrendingMetricDraftConfig(trendingMetricConfig)
  )

  const [draftConfigs, setDraftConfigs] = useState(initialDraftConfigs)

  const [errors, setErrors] = useState<TrendingMetricDraftConfigError[]>([])

  // The modal does not unmount but is just hidden, making a simple cleanup function ineffective
  useEffect(() => {
    if (prevIsOpen !== isOpen) {
      setDraftConfigs(initialDraftConfigs)
    }
  }, [prevIsOpen, isOpen, initialDraftConfigs])

  // Remove validation errors on field update
  const updateValidationErrors = (
    updatedDraftConfig: TrendingMetricDraftConfig
  ) => {
    setErrors((prevErrors) => {
      const updatedErrors: TrendingMetricDraftConfigError[] = []
      prevErrors.forEach((prevError) => {
        const updatedError = getUpdatedValidationErrors(
          updatedDraftConfig,
          prevError
        )
        if (updatedError) {
          updatedErrors.push({ ...prevError, ...updatedError })
        }
      })
      return updatedErrors
    })
  }

  const updateConfig = (updatedDraftConfig: TrendingMetricDraftConfig) => {
    updateValidationErrors(updatedDraftConfig)
    setDraftConfigs((previousDraft) => {
      return previousDraft.map((previousDraftConfig) =>
        previousDraftConfig.id === updatedDraftConfig.id
          ? { ...previousDraftConfig, ...updatedDraftConfig }
          : previousDraftConfig
      )
    })
  }

  const addConfig = () => {
    setDraftConfigs((previousDraft) => {
      return [...previousDraft, toNewTrendingMetricDraftConfig(test.id)]
    })
  }

  const deleteConfig = (deletedDraftConfig: TrendingMetricDraftConfig) => {
    setDraftConfigs((previousDraft) =>
      previousDraft.filter(
        (initialDraftConfigs) =>
          initialDraftConfigs.id !== deletedDraftConfig.id
      )
    )
  }

  const hasChanged = useMemo(
    () => areOriginalConfigsChanged(test.trending_metrics, draftConfigs),
    [draftConfigs, test.trending_metrics]
  )

  const checkDraftIsValid = useCallback(() => {
    const validationErrors = getTrendingMetricDraftConfigsErrors(draftConfigs)
    setErrors(validationErrors)
    return validationErrors.length === 0
  }, [draftConfigs])

  return {
    draftConfigs,
    updateConfig,
    addConfig,
    deleteConfig,
    checkDraftIsValid,
    errors,
    hasChanged,
  }
}
