import type * as acorn from 'acorn'
import type * as walk from 'acorn-walk'

const optionExportMatcher: walk.SimpleVisitors<ExtractOptionsState> = {
  ExportNamedDeclaration(node, state) {
    if (node.declaration?.type !== 'VariableDeclaration') {
      return
    }

    const options = node.declaration.declarations.find(
      (declaration) =>
        declaration.id.type === 'Identifier' &&
        declaration.id.name === 'options'
    )

    if (!options) {
      return
    }

    if (options.init?.type !== 'ObjectExpression') {
      return
    }

    state.options = options.init
  },
}

interface ExtractOptionsState {
  options: acorn.ObjectExpression | null
}

export async function extractOptionsExport(program: acorn.Node) {
  const walk = await import('acorn-walk')

  const state: ExtractOptionsState = {
    options: null,
  }

  walk.simple(program, optionExportMatcher, undefined, state)

  return state.options
}
