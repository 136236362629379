import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { cx } from '@emotion/css'
import { Card, CardProps } from '@grafana/ui'
import { color, fontSize, spacing } from 'utils/styled'
import { Button } from 'components/Button'
import { NotificationClient } from '../NotificationsTab.types'
import { NOTIFICATION_CHANNELS_META } from '../NotificationsTab.constants'
import { NotificationIcon } from './NotificationIcon'
import { getRoute } from 'routeMap'

interface NotificationClientCardProps extends CardProps {
  notificationClient: NotificationClient
  isRadio?: boolean
  ctaDisabled?: boolean
  ctaTooltip?: string
}

export const NotificationClientCard = (props: NotificationClientCardProps) => {
  const { notificationClient, onClick, isSelected, ctaDisabled, ctaTooltip } =
    props
  const { label, description, client } =
    NOTIFICATION_CHANNELS_META[notificationClient]
  const isRadio = isSelected !== undefined

  const handleOnCardClick = isRadio ? onClick : undefined

  return (
    <Wrapper onClick={handleOnCardClick}>
      <Card
        {...props}
        onClick={handleOnCardClick}
        className={cx(
          'notification-card',
          isRadio ? 'notification-radio' : 'notification-action'
        )}
      >
        <div className="notification-card-container">
          <NotificationIcon clientType={client} />
          <div className="notification-card-texts">
            <Card.Heading className="notification-card-title">
              {label}
            </Card.Heading>
            <Card.Meta>{description}</Card.Meta>
          </div>
        </div>
        {!isRadio && (
          <Link
            to={{
              pathname: getRoute('newNotification'),
              state: { notificationClient },
            }}
          >
            <Button
              size="md"
              type="button"
              variant="primary"
              fill="outline"
              disabled={ctaDisabled}
              tooltip={ctaTooltip}
              className="notification-card-button"
            >
              Setup
            </Button>
          </Link>
        )}
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  cursor: pointer !important;
  height: 100%;

  .notification-card {
    position: relative;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex-direction: column;
    text-align: left;
    cursor: default;
    background-color: ${color('background', 'primary')};

    // Hide redundant element
    > h2 {
      display: none;
    }

    &.notification-radio {
      height: ${spacing(12)};
      cursor: pointer;

      //Hide an extra radio appearing randomly
      > h2 {
        display: none;
      }
    }

    .notification-card-container {
      display: flex;
    }

    .notification-card-texts {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: ${spacing(2)};
    }

    .notification-card-title {
      font-size: ${fontSize('h4')};
    }

    .notification-card-button {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: ${spacing(3)};
    }
  }
`
