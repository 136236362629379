export enum ORGANIZATION_ROLE_TYPES {
  READ_ONLY = 5,
  READ_WRITE = 3,
  ADMIN = 1,
}

export enum PROJECT_ROLE_TYPES {
  READ_ONLY = 6,
  READ_WRITE = 4,
  ADMIN = 2,
}

export const ADMIN_ROLE_TYPES = [
  ORGANIZATION_ROLE_TYPES.ADMIN,
  PROJECT_ROLE_TYPES.ADMIN,
]

export const ALL_ROLE_TYPES = [
  ORGANIZATION_ROLE_TYPES.ADMIN,
  ORGANIZATION_ROLE_TYPES.READ_ONLY,
  ORGANIZATION_ROLE_TYPES.READ_WRITE,
  PROJECT_ROLE_TYPES.ADMIN,
  PROJECT_ROLE_TYPES.READ_ONLY,
  PROJECT_ROLE_TYPES.READ_WRITE,
]

export const ROLE_DESCRIPTIONS = {
  [PROJECT_ROLE_TYPES.READ_ONLY]: 'Project member (read-only)',
  [PROJECT_ROLE_TYPES.READ_WRITE]: 'Project member',
  [PROJECT_ROLE_TYPES.ADMIN]: 'Admin',
  [ORGANIZATION_ROLE_TYPES.READ_WRITE]: 'Organization member',
  [ORGANIZATION_ROLE_TYPES.READ_ONLY]: 'Organization member',
  [ORGANIZATION_ROLE_TYPES.ADMIN]: 'Admin',
}

export const ORG_ROLE_TYPE_CHOICES = [
  {
    label: 'Project member',
    value: ORGANIZATION_ROLE_TYPES.READ_ONLY,
    description: 'Can create and run tests.',
  },
  {
    label: 'Admin',
    value: ORGANIZATION_ROLE_TYPES.ADMIN,
    description:
      'Can invite team members and change permissions. Access to billing menu.',
  },
]

export const PROJECT_TYPE_CHOICES = [
  {
    label: 'Editor',
    description: 'Can create and run tests',
    value: PROJECT_ROLE_TYPES.READ_WRITE,
  },
  {
    label: 'Viewer',
    description: 'Can only view tests',
    value: PROJECT_ROLE_TYPES.READ_ONLY,
  },
]
