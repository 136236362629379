import React from 'react'
import { Link } from 'react-router-dom'

import { Project, Test, TestRun } from 'types'
import { routeMap } from 'routeMap'
import { getTestRunLabel } from 'utils/testRun'
import { useCurrentProject } from 'projectContext'
import { createComparePageNavModel } from 'navModels'
import { useComparedRun } from 'data/useRun'
import { useTest } from 'data/useTest'
import { useMediaQuery } from 'hooks/useMediaQuery'
import { NotFound } from 'pages/NotFound'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { QueryErrorBoundary } from 'components/ErrorBoundary'
import { LinkButton } from 'components/LinkButton'
import { PluginPage } from 'components/PluginPage'
import { Sticky } from 'components/Sticky'

import { useTimeRange, TimeRangeProvider } from './TimeRangeContext'
import { Breakdown } from './components/Breakdown'
import { CompareHeader } from './components/CompareHeader'
import { CompareMetaBar } from './components/TestRunMetaBar/CompareMetaBar'
import { RunOverview } from './components/RunOverview'
import { TimeRangeResetButton } from './components/TimeRangeResetButton'
import { useLeftId, useRightId } from './ComparePage.hooks'
import { TimeRangeResetButtonWrapper } from './TestRunPage.styles'
import { useCurrentTab } from './components/Breakdown/Breakdown.hooks'
import { ErrorBoundaryLocalView } from 'services/tracking.types'

export const ComparePage = () => {
  return (
    <TimeRangeProvider>
      <ComparePageController />
    </TimeRangeProvider>
  )
}

const ComparePageController = () => {
  const project = useCurrentProject()

  const pageNav = createComparePageNavModel(project)

  const [leftId] = useLeftId()
  const [rightId] = useRightId()

  const { data: left } = useComparedRun(leftId, {
    staleTime: 1000, // Mitigate duplicate requests on app bootstrap
  })

  const { data: right } = useComparedRun(rightId)

  const { data: testLeft } = useTest(left?.test_id)
  const { data: testRight } = useTest(right?.test_id)

  if (leftId === undefined || rightId === undefined) {
    return <NotFound />
  }

  const loaded = left && right && testLeft && testRight && project

  if (!loaded) {
    return (
      <PluginPage pageNav={pageNav}>
        <CenteredSpinner $height="300px" />
      </PluginPage>
    )
  }

  return (
    <ComparePageContent
      left={{ run: left, test: testLeft }}
      right={{ run: right, test: testRight }}
      project={project}
    />
  )
}

interface ComparePageContentProps {
  left: {
    test: Test
    run: TestRun
  }
  right: { test: Test; run: TestRun }
  project: Project
}

const ComparePageContent = ({
  left,
  right,
  project,
}: ComparePageContentProps) => {
  const { timeRange } = useTimeRange()
  const isSmallScreen = useMediaQuery('lg')
  const tab = useCurrentTab()

  const pageNav = createComparePageNavModel(project, left.test, {
    left: left.run,
    right: right.run,
    tab,
  })

  return (
    <PluginPage
      pageNav={pageNav}
      actions={
        <LinkButton
          to={routeMap.testRun(left.run.id, tab)}
          button={{ variant: 'secondary' }}
        >
          Stop comparing
        </LinkButton>
      }
      subTitle={
        <>
          Comparing the results of{' '}
          <TestRunLink
            test={left.test}
            testRun={left.run}
            tab={tab}
            includeTestName={left.test.id !== right.test.id}
          />{' '}
          and{' '}
          <TestRunLink
            test={right.test}
            testRun={right.run}
            tab={tab}
            includeTestName={left.test.id !== right.test.id}
          />
          .
        </>
      }
    >
      <Sticky disabled={isSmallScreen} direction="column">
        <CompareHeader
          showTimeRangeReset={isSmallScreen}
          left={left.run}
          right={right.run}
        />
        <CompareMetaBar left={left.run} right={right.run} />
      </Sticky>

      <QueryErrorBoundary
        title="Performance Overview"
        content="An error has occurred while fetching the results overview, this can be caused by either poor connectivity or an error with our servers. If you have an ad blocking extension installed in your browser, try disabling it and reload the page."
        view={ErrorBoundaryLocalView.PerformanceOverviewComparison}
      >
        <RunOverview
          analysis={{ main: left.run, compareWith: right.run }}
          test={left.test}
        />
      </QueryErrorBoundary>
      <QueryErrorBoundary
        title="Performance Details"
        content="An error has occurred while fetching the test data, click retry request to try again. If the problem persists, please contact support."
        view={ErrorBoundaryLocalView.PerformanceDetailsComparison}
      >
        <Breakdown
          test={left.test}
          analysis={{ main: left.run, compareWith: right.run }}
        />
      </QueryErrorBoundary>

      {timeRange && isSmallScreen && (
        <Sticky justify="flex-end" placement="bottom">
          <TimeRangeResetButtonWrapper>
            <TimeRangeResetButton />
          </TimeRangeResetButtonWrapper>
        </Sticky>
      )}
    </PluginPage>
  )
}

function TestRunLink({
  test,
  testRun,
  includeTestName = false,
  tab,
}: {
  test: Test
  testRun: TestRun
  includeTestName?: boolean
  tab?: string
}) {
  return (
    <Link to={routeMap.testRun(testRun.id, tab)}>
      {includeTestName && <>{test.name} - </>}
      {getTestRunLabel(testRun)}
    </Link>
  )
}
