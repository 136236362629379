import React from 'react'

import { DocLink, docs } from 'components/DocLink'
import { CodeSnippet } from 'components/CodeSnippet'
import {
  BreakdownTabEmptyView,
  BreakdownTabMessage,
  Content,
} from '../Breakdown.styles'
import { EmptyProps } from '../BreakdownTable'
import { isTestActive } from 'utils/testRun'

const WAITING_TEXT = 'Waiting for the result of your checks to come in...'

const CHECKS_SNIPPET = `
  import { check, sleep } from 'k6'
  import http from 'k6/http'

  export default function() {
    let response = http.get('https://test.k6.io')
 
    check(response, {
      'is status 200': r => r.status === 200,
    })
    
    sleep(1)
  }
`

type ChecksEmptyViewProps = EmptyProps<{}>

export const ChecksEmptyView = ({ testRun }: ChecksEmptyViewProps) => {
  if (isTestActive(testRun)) {
    return <BreakdownTabMessage>{WAITING_TEXT}</BreakdownTabMessage>
  }

  return (
    <BreakdownTabEmptyView>
      <Content>
        <h2>What is a check?</h2>
        <p>
          Checks are like asserts but differ in that they {"don't"} halt
          execution, instead they just store the result of the check, pass or
          fail, and let the script execution continue.
        </p>
        <p>
          Checks are great for codifying assertions relating to HTTP
          requests/responses, making sure the response code is <b>2xx</b> for
          example:
        </p>
        <p>
          Read more about Checks in the{' '}
          <DocLink article={docs.checks.main}>docs</DocLink>.
        </p>
      </Content>
      <Content>
        <CodeSnippet code={CHECKS_SNIPPET} />
      </Content>
    </BreakdownTabEmptyView>
  )
}
