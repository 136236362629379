import styled from 'styled-components'
import { Button as GrafanaButton, Icon as GrafanaIcon } from '@grafana/ui'
import { spacing } from 'utils/styled'

export const Container = styled.div`
  display: flex;
`

export const ColumnContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 0;
  gap: ${spacing(1.5)};
`

export const Button = styled(GrafanaButton)<{ $focused: boolean }>`
  && {
    text-align: left;
    background: ${({ $focused, theme }) =>
      !$focused ? 'transparent' : theme.colors.background.secondary};
    text-decoration: ${({ $focused }) => (!$focused ? 'initial' : 'none')};
  }

  && > span {
    flex: 1 1 0;
  }
`

export const Icon = styled(GrafanaIcon)<{ $color: string }>`
  color: ${({ $color }) => $color};
`

export const DisplayName = styled.div`
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ResponseTime = styled.span`
  min-width: 40px;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const VirtualUsers = styled.span`
  min-width: 45px;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Suffix = styled.span`
  flex: 1 1 0;
  text-align: right;
`
