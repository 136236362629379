import styled from 'styled-components'
import React from 'react'

const Container = styled.div`
  font-weight: 600;
  font-size: 16px;
`
export function Step({ index }: { index?: number }) {
  if (index === undefined) {
    return null
  }
  const text = String((index || 0) + 1).padStart(2, '0')

  return <Container className="gradient-step">{text}</Container>
}
