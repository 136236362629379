import { useQuery } from '@tanstack/react-query'
import { useAppContext } from '../appContext'
import { toOrgTokensQueryKey } from './queryKeys'
import { useDatasource } from '../datasourceContext'

export function useOrganizationTokens() {
  const { orgId } = useAppContext()
  const { ds } = useDatasource()

  return useQuery({
    queryKey: toOrgTokensQueryKey(orgId),
    queryFn: () => ds.fetchOrganizationTokens(orgId),
  })
}
