import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAppContext } from 'appContext'
import { toNotificationsQueryKey } from 'data/queryKeys'
import { useDatasource } from 'datasourceContext'
import { showAlert } from 'utils/showAlert'
import {
  NotificationChannel,
  NotificationResponseError,
  NotificationChannelDraft,
  NotificationScriptValidationPayload,
} from '../NotificationsTab.types'

interface MutationCallbacks {
  onError(error: NotificationResponseError): void
  onSuccess(): void
}

export const useNotifications = (enabled: boolean) => {
  const { orgId } = useAppContext()
  const { ds } = useDatasource()

  return useQuery({
    enabled,
    queryKey: toNotificationsQueryKey(orgId),
    queryFn: () => ds.fetchNotificationChannelsByOrgId(orgId),
  })
}

export const useCreateNotification = ({
  onError,
  onSuccess,
}: MutationCallbacks) => {
  const { ds } = useDatasource()
  const { orgId } = useAppContext()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (notificationDraft: NotificationChannelDraft) => {
      return await ds
        .validateNotificationChannelTemplate(notificationDraft)
        .then(() => ds.createNotificationChannel(notificationDraft, orgId))
    },
    onError,
    onSuccess: async () => {
      await queryClient.invalidateQueries(toNotificationsQueryKey(orgId))
      showAlert('Notification created!', 'success')
      onSuccess()
    },
  })
}

export const useUpdateNotification = ({
  onError,
  onSuccess,
}: MutationCallbacks) => {
  const { ds } = useDatasource()
  const { orgId } = useAppContext()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (notificationDraft: NotificationChannelDraft) => {
      return await ds
        .validateNotificationChannelTemplate(notificationDraft)
        .then(() => ds.updateNotificationChannel(notificationDraft, orgId))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(toNotificationsQueryKey(orgId))
      showAlert('Notification updated!', 'success')
      onSuccess()
    },
    onError,
  })
}

export const useDeleteNotificationChannel = () => {
  const { ds } = useDatasource()
  const { orgId } = useAppContext()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (notification: NotificationChannel) =>
      ds.deleteNotificationChannel(notification.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries(toNotificationsQueryKey(orgId))
      showAlert('Notification deleted!', 'success')
    },
    onError: () => showAlert('Failed to delete Notification!', 'error'),
  })
}

export const useValidateNotification = ({
  onError,
  onSuccess,
}: MutationCallbacks) => {
  const { ds } = useDatasource()
  return useMutation({
    mutationFn: async (
      scriptValidationPayload: NotificationScriptValidationPayload
    ) => await ds.validateNotificationChannelTemplate(scriptValidationPayload),
    onSuccess,
    onError: (errors: NotificationResponseError) => {
      onError(errors)
    },
  })
}

export const useTestNotification = () => {
  const { ds } = useDatasource()

  return useMutation({
    mutationFn: (notificationChannelId: number) =>
      ds.sendTestNotification(notificationChannelId),
    onSuccess: () => {
      showAlert('Notification in on its way!', 'success')
    },
    onError: () => {
      showAlert(
        'An issue occurred while sending the test notification!',
        'success'
      )
    },
  })
}
