import React, { ReactNode, useEffect } from 'react'
import { Button } from '@grafana/ui'
import { BertMessage } from 'components/BertMessage'
import { Description } from 'components/Section/Section.styles'
import { NavModelItem } from '@grafana/data'
import { useTrackActionWithoutOrg } from 'services/tracking'

import {
  ErrorBoundaryFullPageView,
  ErrorBoundaryType,
  TrackingEventName,
} from 'services/tracking.types'

import { Page } from './Page'
import { ErrorPageStyled } from './ErrorPage.styled'

export function ErrorPage({
  navText = '',
  title = 'Unknown Error',
  message = 'This can be caused by either poor connectivity or an error with our servers. If you have an ad blocking extension installed in your browser, try disabling it and reload the page.',
}: {
  navText?: NavModelItem['text']
  title?: string
  message?: ReactNode
}) {
  const trackAction = useTrackActionWithoutOrg()

  const handleReload = () => {
    window.location.reload()
  }

  useEffect(() => {
    trackAction({
      eventName: TrackingEventName.ErrorBoundary,
      type: ErrorBoundaryType.FullPage,
      view: ErrorBoundaryFullPageView.InitializePage,
      isApiError: true, // Page is displayed only when there is an API error
    })
  }, [trackAction])

  return (
    <Page navText={navText}>
      <ErrorPageStyled>
        <BertMessage
          type="error"
          title={title}
          description={
            <>
              <Description>
                <BertMessage.Text>{message}</BertMessage.Text>
              </Description>
              <Button onClick={handleReload}>Reload page</Button>
            </>
          }
        />
      </ErrorPageStyled>
    </Page>
  )
}
