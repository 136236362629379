import React from 'react'
import styled from 'styled-components'
import { Button, Field, Icon, Input, Modal } from '@grafana/ui'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { useAppContext } from 'appContext'
import { showAlert } from 'utils/showAlert'
import { convertDurationToSeconds } from 'utils/convertDurationToSeconds'
import { routeMap } from 'routeMap'
import { useProjectNameValidation } from 'components/ProjectMenu/ProjectMenu.hooks'

import { useCreateProject } from './CreateNewProject.hooks'
import { getFieldError, getNonFieldError } from 'utils/form'
import { Project } from 'types'
import {
  DurationMaxPerTestField,
  VUMaxPerTestField,
  VUhMaxPerMonthField,
} from 'components/ProjectLimitsForm/ProjectLimitsFormFields'
import { useIsUserOrgAdmin } from 'data/usePermissions'
import { Flex } from 'components/Flex'
import {
  ProjectLimitsFormValues,
  useLimitsFormValidation,
  useProjectLimitsFormDefaultValues,
} from 'components/ProjectLimitsForm'
import { color, spacing } from 'utils/styled'

interface CreateProjectFormFields
  extends Pick<Project, 'name'>,
    ProjectLimitsFormValues {}

export const CreateNewProjectModal = ({
  isOpen,
  onDismiss,
}: {
  isOpen: boolean
  onDismiss: () => void
}) => {
  const appContext = useAppContext()
  const isOrgAdmin = useIsUserOrgAdmin()
  const projectNameValidation = useProjectNameValidation()
  const projectLimitsValidation = useLimitsFormValidation()
  const history = useHistory()
  const { mutateAsync: createProject } = useCreateProject()
  const projectLimitsFormDefaultValues = useProjectLimitsFormDefaultValues()

  const defaultValues: CreateProjectFormFields = {
    name: '',
    ...projectLimitsFormDefaultValues,
  }

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    defaultValues,
  })

  const handleDismiss = () => {
    reset()
    onDismiss()
  }

  const handleUpdate = ({
    name,
    vuh_max_per_month,
    vu_max_per_test,
    duration_max_per_test,
  }: CreateProjectFormFields) =>
    createProject(
      {
        organization_id: appContext.orgId,
        name,
        ...(isOrgAdmin && { vuh_max_per_month }),
        ...(isOrgAdmin && { vu_max_per_test }),
        ...(isOrgAdmin && {
          duration_max_per_test: duration_max_per_test
            ? convertDurationToSeconds(duration_max_per_test)
            : null,
        }),
      },
      {
        onSuccess: (project) => {
          handleDismiss()
          history.push(routeMap.project(project.id))
          showAlert(`Project "${project.name}" successfully created`, 'success')
        },
        onError: (error) => {
          reset({}, { keepValues: true, keepDirty: true, keepErrors: true })

          const nonFieldError = getNonFieldError(error)

          if (nonFieldError) {
            showAlert(nonFieldError, 'error')
            return
          }

          const fieldError = getFieldError<CreateProjectFormFields>(error)

          if (fieldError) {
            const { field, message } = fieldError
            setError(field, { message })
            return
          }

          // Show a generic error message if the API does not respond with a nice message
          showAlert('Could not create a new project. Try again later', 'error')
        },
      }
    )

  return (
    <StyledModal
      title="Create new project"
      isOpen={isOpen}
      onDismiss={handleDismiss}
      closeOnEscape
      trapFocus={false}
    >
      <p>
        Projects allow you to organize tests. For example, you might have
        separate projects for different microservices and APIs.
      </p>

      <Form onSubmit={handleSubmit(handleUpdate)}>
        <Field
          error={errors.name?.message}
          invalid={!!errors.name}
          label="Project name"
          htmlFor="project_name"
        >
          <Input
            placeholder="Enter project name"
            autoComplete="off"
            disabled={isSubmitting}
            id="project_name"
            {...register('name', projectNameValidation)}
          />
        </Field>
        <Flex gap={2}>
          <VUhMaxPerMonthField
            {...register('vuh_max_per_month', {
              setValueAs: (val) => (val ? Number(val) : null),
              validate: projectLimitsValidation.vuh_max_per_month,
            })}
            error={errors.vuh_max_per_month?.message}
            invalid={!!errors.vuh_max_per_month}
            disabled={!isOrgAdmin || isSubmitting}
          />
          <VUMaxPerTestField
            {...register('vu_max_per_test', {
              setValueAs: (val) => (val ? Number(val) : null),
              validate: projectLimitsValidation.vu_max_per_test,
            })}
            error={errors.vu_max_per_test?.message}
            invalid={!!errors.vu_max_per_test}
            disabled={!isOrgAdmin || isSubmitting}
          />
          <DurationMaxPerTestField
            {...register('duration_max_per_test', {
              setValueAs: (val) => (val ? val : null),
              validate: projectLimitsValidation.duration_max_per_test,
            })}
            error={errors.duration_max_per_test?.message}
            invalid={!!errors.duration_max_per_test}
            disabled={!isOrgAdmin || isSubmitting}
          />
        </Flex>
        {!isOrgAdmin && (
          <Info>
            <Icon name="info-circle" size="xs" aria-hidden />
            Contact your stack admin to change project limits
          </Info>
        )}
        <Modal.ButtonRow>
          <Button
            variant="secondary"
            onClick={handleDismiss}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isSubmitting}
            icon={isSubmitting ? 'fa fa-spinner' : undefined}
          >
            Create new project
          </Button>
        </Modal.ButtonRow>
      </Form>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  width: 560px;
`

const Form = styled.form`
  margin-top: ${spacing(4)};
`
const Info = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(0.5)};
  color: ${color('text', 'secondary')};
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
`
