import React from 'react'
import styled from 'styled-components'

interface PaginationSummaryProps {
  page: number
  totalPages: number
}

export const PaginationSummary = ({
  page,
  totalPages,
}: PaginationSummaryProps) => {
  return (
    <StyledPaginationSummary>
      <p>
        Showing {page} of {totalPages} pages
      </p>
    </StyledPaginationSummary>
  )
}

const StyledPaginationSummary = styled.div`
  text-align: center;
  position: relative;
`
