import React, { useState } from 'react'
import { SelectableValue } from '@grafana/data'
import { Select } from '@grafana/ui'
import { useDebounce } from 'usehooks-ts'
import {
  useGrafanaTeamOptions,
  GrafanaTeam,
} from 'data/useGrafanaUsersAndTeams'

interface GrafanaTeamPickerProps {
  width?: number | 'auto'
  onSelected: (team: SelectableValue<GrafanaTeam>) => void
}

export function GrafanaTeamPicker({
  width = 'auto',
  onSelected,
}: GrafanaTeamPickerProps) {
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounce(searchQuery, 300)
  const { data: teams, isFetching } =
    useGrafanaTeamOptions(debouncedSearchQuery)

  return (
    <Select
      isClearable
      isLoading={isFetching}
      options={teams}
      onChange={onSelected}
      onInputChange={(value) => setSearchQuery(value)}
      placeholder="Select a team"
      noOptionsMessage="No teams found"
      aria-label="Team picker"
      width={width}
    />
  )
}
