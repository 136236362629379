export const toInsightsQueryKey = (testRunId?: number) =>
  ['insights', testRunId] as const

export const toInsightsExecutionsQueryKey = (testRunId?: number) =>
  [...toInsightsQueryKey(testRunId), 'executions'] as const

export const toInsightsExecutionQueryKey = (
  testRunId?: number,
  executionId?: string
) => [...toInsightsQueryKey(testRunId), 'execution', executionId] as const

export const toInsightsExecutionResultsQueryKey = (
  testRunId?: number,
  executionId?: string
) =>
  [
    ...toInsightsExecutionQueryKey(testRunId, executionId),
    'execution-results',
  ] as const

export const toInsightsCategoriesQueryKey = (
  testRunId?: number,
  executionId?: string
) =>
  [
    ...toInsightsExecutionQueryKey(testRunId, executionId),
    'categories',
  ] as const

export const toInsightsCategoriesResultsQueryKey = (
  testRunId?: number,
  executionId?: string
) =>
  [
    ...toInsightsExecutionQueryKey(testRunId, executionId),
    'categories-results',
  ] as const

export const toInsightsGroupsQueryKey = (
  testRunId?: number,
  executionId?: string
) => [...toInsightsExecutionQueryKey(testRunId, executionId), 'groups'] as const

export const toInsightsAuditsQueryKey = (
  testRunId?: number,
  executionId?: string
) => [...toInsightsExecutionQueryKey(testRunId, executionId), 'audits'] as const

export const toInsightsAuditsResultsQueryKey = (
  testRunId?: number,
  executionId?: string
) =>
  [
    ...toInsightsExecutionQueryKey(testRunId, executionId),
    'audits-results',
  ] as const
