import React, { forwardRef, type ReactNode, type Ref } from 'react'

import {
  GridElement,
  GridColumnElement,
  type GridElementProps,
  type GridColumnElementProps,
} from './Grid.styles'

interface GridProps extends GridElementProps {
  children: ReactNode
  className?: string
  as?: keyof JSX.IntrinsicElements
}

function GridBase(
  { children, as = 'div', height, width, ...props }: GridProps,
  ref: Ref<HTMLDivElement>
) {
  return (
    <GridElement as={as} ref={ref} $height={height} $width={width} {...props}>
      {children}
    </GridElement>
  )
}

interface GridColumnProps extends GridColumnElementProps {
  children: ReactNode
  className?: string
  as?: keyof JSX.IntrinsicElements
}

function Column(
  { children, as = 'div', ...props }: GridColumnProps,
  ref: Ref<HTMLDivElement>
) {
  return (
    <GridColumnElement as={as} ref={ref} {...props}>
      {children}
    </GridColumnElement>
  )
}

// Assign the Column component to the Grid component so that it can be used as a sub-component.
export const Grid = Object.assign(forwardRef(GridBase), {
  Column: forwardRef(Column),
})
