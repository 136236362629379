import React, { useEffect } from 'react'
import { convertDurationToSeconds } from 'utils/convertDurationToSeconds'
import { Button, Modal } from '@grafana/ui'

import { ProjectSettings } from 'types'
import { useUpdateProjectLimits } from 'data/useProjectSettings'
import { useLimitsForm } from './ProjectLimitsModal.hooks'
import {
  DurationMaxPerTestField,
  VUMaxPerTestField,
  VUhMaxPerMonthField,
} from '../ProjectLimitsForm/ProjectLimitsFormFields'
import { Flex } from 'components/Flex'
import {
  ProjectLimitsFormValues,
  useLimitsFormValidation,
} from 'components/ProjectLimitsForm'

interface LimitsModalProps {
  isOpen: boolean
  projectLimits: ProjectSettings
  onDismiss: () => void
}

export function ProjectLimitsModal({
  isOpen = false,
  projectLimits,
  onDismiss,
}: LimitsModalProps) {
  const { mutateAsync: updateProjectSettings, isLoading } =
    useUpdateProjectLimits()
  const validate = useLimitsFormValidation()

  const handleUpdate = ({
    duration_max_per_test,
    ...values
  }: ProjectLimitsFormValues) =>
    updateProjectSettings(
      {
        project_id: projectLimits.project_id,
        duration_max_per_test: duration_max_per_test
          ? convertDurationToSeconds(duration_max_per_test)
          : null,
        ...values,
      },
      {
        onSuccess: () => {
          onDismiss()
        },
      }
    )

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useLimitsForm({ projectLimits })

  useEffect(() => {
    if (!isOpen) {
      reset()
    }
  }, [isOpen, reset])

  return (
    <Modal isOpen={isOpen} title="Edit project limits" onDismiss={onDismiss}>
      <form onSubmit={handleSubmit(handleUpdate)}>
        <Flex gap={2}>
          <VUhMaxPerMonthField
            {...register('vuh_max_per_month', {
              setValueAs: (val) => (val ? Number(val) : null),
              validate: validate.vuh_max_per_month,
            })}
            error={errors.vuh_max_per_month?.message}
            invalid={!!errors.vuh_max_per_month}
            disabled={isLoading}
          />
          <VUMaxPerTestField
            {...register('vu_max_per_test', {
              setValueAs: (val) => (val ? Number(val) : null),
              validate: validate.vu_max_per_test,
            })}
            error={errors.vu_max_per_test?.message}
            invalid={!!errors.vu_max_per_test}
            disabled={isLoading}
          />
          <DurationMaxPerTestField
            {...register('duration_max_per_test', {
              setValueAs: (val) => (val ? val : null),
              validate: validate.duration_max_per_test,
            })}
            error={errors.duration_max_per_test?.message}
            invalid={!!errors.duration_max_per_test}
            disabled={isLoading}
          />
        </Flex>

        <Modal.ButtonRow>
          <Button variant="secondary" onClick={onDismiss} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isLoading}
            icon={isLoading ? 'fa fa-spinner' : undefined}
          >
            Save
          </Button>
        </Modal.ButtonRow>
      </form>
    </Modal>
  )
}
