import { StaticIPStatus } from 'types'

export const STATIC_IP_ERROR_STATUSES = [
  StaticIPStatus.PROVISIONING_ERROR,
  StaticIPStatus.PROVISIONING_ERROR_QUOTA_REACHED,
  StaticIPStatus.RELEASING_ERROR,
]

export const STATIC_IP_DELETABLE_STATUSES = [
  StaticIPStatus.PROVISIONED,
  StaticIPStatus.PROVISIONING_ERROR,
  StaticIPStatus.PROVISIONING_ERROR_QUOTA_REACHED,
  StaticIPStatus.RELEASING_ERROR,
]
