import React from 'react'
import { Badge } from '@grafana/ui'
import { NewFeatureLabelStyled } from './NewFeatureLabel.styled'

interface NewFeatureLabelProps {
  label: string
}
export function NewFeatureLabel({ label }: NewFeatureLabelProps) {
  return (
    <NewFeatureLabelStyled>
      {label}
      <Badge color="orange" text="new" />
    </NewFeatureLabelStyled>
  )
}

export function createNewFeatureLabel(label: NewFeatureLabelProps['label']) {
  return <NewFeatureLabel label={label} />
}
