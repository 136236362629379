import React, { useMemo } from 'react'
import styled from 'styled-components'

import { TestWithTrends } from 'types'
import { TestPageTestIds } from 'types/dataTestIds'
import { DocLink, docs } from 'components/DocLink'
import { Flex } from 'components/Flex'
import { InfoToggletip } from 'components/InfoToggletip'
import { MetaBar } from 'components/MetaBar'

import { getTestMetaData, hasUpdatedSinceLastRun } from './TestMetaBar.utils'
import { ScheduledItem } from './ScheduledMetaItem'
import { TrendSettingsButton } from './TrendSettingsButton'

export function TestMetaBar({ test }: { test: TestWithTrends }) {
  const data = useMemo(() => {
    return getTestMetaData(test)
  }, [test])

  const hasUpdatedTestScript = hasUpdatedSinceLastRun(test)
  const label = hasUpdatedTestScript ? 'Last' : 'Current'

  return (
    <MetaBar data-testid={TestPageTestIds.MetaBar}>
      <MetaBar.Section>
        <Flex align="center" gap={0.5}>
          <InfoToggletip
            content={
              <>
                <p>
                  Total VUhs used for all runs of this test:{' '}
                  <strong>{data.testVUh}</strong>
                </p>
                {hasUpdatedTestScript && (
                  <Warning>
                    The configuration file has been updated since the most
                    recent test run so may be different to what was last used.
                  </Warning>
                )}
                <p>
                  These <em>test options</em> were used for the last test
                  execution.
                </p>
                <DocLink article={docs.cloudOptions.options} showIcon>
                  Learn more about test options
                </DocLink>
              </>
            }
            tooltip="About this configuration"
            type={hasUpdatedTestScript ? 'warning' : 'info'}
          />
          <div>{label} configuration:</div>
        </Flex>
        <MetaBar.Item.Vuh value={data.vuh} tooltip="VUh usage" />
        <MetaBar.Item.Vus value={data.vus} />
        <MetaBar.Item.ConfiguredDuration value={data.duration} />
        <MetaBar.Item.LoadZones
          compact
          testRun={data.latestRun}
          showNodes={false}
        />
      </MetaBar.Section>
      <MetaBar.Section fixedWidth alignEnd gap={0}>
        <ScheduledItem nextRun={data.nextRun} test={test} />
        <MetaBar.Item>
          <TrendSettingsButton test={test} />
        </MetaBar.Item>
      </MetaBar.Section>
    </MetaBar>
  )
}

const Warning = styled.p`
  color: ${({ theme }) => theme.colors.warning.text};
`
