import React from 'react'

import { WebVitalLCP } from 'types/insights/webVitals'
import { WEB_VITAL_CONFIG, WEB_VITALS_THRESHOLDS } from 'constants/webVitals'
import { millisecondsToSeconds } from 'utils/math'

import { InsightListItem } from '../InsightItem'
import { SkippedDescription } from '../SkippedDescription'
import { InsightBody } from '../InsightBody'
import { docs, ReadMore } from 'components/DocLink'

const minThreshold = millisecondsToSeconds(
  WEB_VITALS_THRESHOLDS.web_vital_lcp[0]
)
const maxThreshold = millisecondsToSeconds(
  WEB_VITALS_THRESHOLDS.web_vital_lcp[1]
)

interface WebVitalLCPItemProps {
  insight: WebVitalLCP
}

const WebVitalLCPDescription = ({ insight }: WebVitalLCPItemProps) => {
  switch (insight.status) {
    case 'PASSED':
      return (
        <>
          Rendered within {minThreshold} {WEB_VITAL_CONFIG.web_vital_lcp.unit}.{' '}
          <ReadMore article={docs.insights.webVital.lcp} />
        </>
      )

    case 'WARNING':
      return (
        <>
          Exceeded the {minThreshold} {WEB_VITAL_CONFIG.web_vital_lcp.unit}{' '}
          threshold.
        </>
      )

    case 'FAILED':
      return (
        <>
          Exceeded the {maxThreshold} {WEB_VITAL_CONFIG.web_vital_lcp.unit}{' '}
          threshold.
        </>
      )

    default:
      return <SkippedDescription insight={insight} />
  }
}

export const WebVitalLCPItem = ({ insight }: WebVitalLCPItemProps) => {
  return (
    <InsightListItem header="Largest Contentful Paint" insight={insight}>
      <WebVitalLCPDescription insight={insight} />
    </InsightListItem>
  )
}

export const WebVitalLCPContent = () => {
  return (
    <InsightBody>
      <p>
        Largest Contentful Paint (LCP) is a crucial metric, as it measures the
        time it takes for the largest content element on a page to become
        visible. Improving LCP contributes significantly to a better user
        experience.
      </p>
      <h4>Here are some strategies to enhance LCP:</h4>
      <ul>
        <li>
          <strong>Minimize Server Response Time (TTFB):</strong> Efficient
          server responses contribute to faster content rendering. Employ
          caching mechanisms and consider upgrading your hosting plan for better
          server performance.
        </li>
        <li>
          <strong>Minimize Render-Blocking Resources:</strong> Identify and
          address render-blocking resources, especially JavaScript and CSS
          files. Utilize techniques such as asynchronous loading or defer
          loading for non-critical resources.
        </li>
        <li>
          <strong>Prioritize Above-the-Fold Content:</strong> Ensure that
          above-the-fold content, the content visible without scrolling, is
          prioritized for loading. This involves optimizing the delivery of
          critical resources and minimizing the number of requests needed to
          render this content.
        </li>
        <li>
          <strong>Optimize Images and Videos:</strong> Compress and optimize
          images to reduce their file size without sacrificing quality. Consider
          using modern image formats like WebP. Also, lazy load images and
          videos to defer loading until they are needed, improving initial page
          load times.
        </li>
        <li>
          <strong>Optimize Web Fonts:</strong> Minimize the use of custom web
          fonts or use the font-display: swap; property to ensure text is
          visible even before the custom font has fully loaded.
        </li>
        <li>
          <strong>Browser Caching:</strong> Implement proper caching strategies
          for static assets. Utilize cache headers to instruct the browser to
          store and reuse resources locally, reducing the need to download them
          on subsequent visits.
        </li>
        <li>
          <strong>Use a Content Delivery Network (CDN):</strong> Deploy a
          Content Delivery Network to distribute your content across servers
          globally. This reduces latency and speeds up content delivery,
          improving FCP.
        </li>
        <li>
          <strong>Reduce Third-Party Scripts:</strong> Limit the use of
          third-party scripts as they can introduce additional delays. Only
          include essential third-party scripts and load them asynchronously to
          prevent them from blocking rendering.
        </li>
      </ul>

      <p>
        By implementing these strategies, you can improve your website{"'"}s
        Largest Contentful Paint, contributing to a faster and more
        user-friendly experience. Remember that optimizations may need to be
        tailored based on the specific characteristics of your website and its
        content.
      </p>
    </InsightBody>
  )
}
