export function getColumnCount(hasPrivate: boolean, showNodes: boolean) {
  let columns = 1

  if (hasPrivate) {
    columns += 1
  }

  if (showNodes) {
    columns += 2
  }

  return columns
}
