import { BackendSrv, getBackendSrv } from '@grafana/runtime'
import { captureBackendSrvException } from 'services/sentryClient'

interface RequestOptions {
  url: string
  params?: Record<string, string>
}

interface RequestWithBodyOptions extends RequestOptions {
  url: string
  body: unknown
}

function trimLeadingSlash(path: string) {
  return path.replace(/^\//, '')
}

function join(...parts: string[]) {
  return '/' + parts.map(trimLeadingSlash).join('/')
}

class HttpClient {
  baseUrl: string

  constructor(baseUrl = '/') {
    this.baseUrl = baseUrl
  }

  get<T = unknown>({ url, params }: RequestOptions) {
    return this.request((srv) =>
      srv.get<T>(join(this.baseUrl, url), params, undefined, {
        showErrorAlert: false,
        showSuccessAlert: false,
      })
    )
  }

  post<T = unknown>({ url, params, body }: RequestWithBodyOptions) {
    return this.request((srv) =>
      srv.post<T>(join(this.baseUrl, url), body, {
        params: params,
        showErrorAlert: false,
        showSuccessAlert: false,
      })
    )
  }

  request<T>(action: (srv: BackendSrv) => Promise<T>) {
    return action(getBackendSrv()).catch((err: unknown) => {
      // The API sometimes responds with 204 in case of a successful request
      // @grafana/runtime -> getBackendSrv() tries to invoke .json() method -> boom
      if (err instanceof Error && err.name === 'SyntaxError') {
        return {} as T
      }

      captureBackendSrvException(err)

      throw err
    })
  }
}

export const grafanaClient = new HttpClient('/api')

export const proxyClient = new HttpClient('api/plugins/k6-app/resources')
