import { Span } from './types'
import { getAttribute } from './BrowserTimeline.utils'
import React from 'react'
import { InteractionIcon } from './InteractionIcon'

export function Screenshot({
  span,
  parentSpan,
}: {
  span: Span
  parentSpan: Span
}) {
  if (span.name !== 'page.screenshot') {
    return null
  }

  const path = getAttribute(span.attributes, 'path')
  if (!path) {
    return null
  }
  return (
    <InteractionIcon
      parentSpan={parentSpan}
      span={span}
      icon="gf-landscape"
      tooltip={
        <>
          page.screenshot(
          <br />
          &nbsp;&nbsp;&apos;{path}&apos;
          <br />)
        </>
      }
    />
  )
}
