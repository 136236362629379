import { NEVER, RefinementCtx, ZodIssueCode, string } from 'zod'
import { NoOptionsExported, NonConstantOptions, ScriptTooLarge } from './errors'
import {
  buildObject,
  extractOptionsExport,
  parseScript,
} from 'pages/Editor/utils/script'

async function parseOptions(script: string, context: RefinementCtx) {
  const program = await parseScript(script)

  if (program === null) {
    context.addIssue({
      code: 'custom',
      message: 'Script contains syntax errors.',
      params: {
        type: 'script-syntax-error',
      } as const,
      fatal: true,
    })

    return NEVER
  }

  const options = await extractOptionsExport(program)

  if (options === null) {
    const params: NoOptionsExported = {
      type: 'no-options-exported',
    }

    context.addIssue({
      code: ZodIssueCode.custom,
      message: 'Script does not export any options.',
      params,
      fatal: true,
    })

    return NEVER
  }

  const result = buildObject(['root'], options)

  if (!result.ok) {
    const params: NonConstantOptions = {
      type: 'non-constant-options',
      errors: result.errors,
    }

    context.addIssue({
      code: ZodIssueCode.custom,
      message: 'Script options require runtime evaluation.',
      params,
      fatal: true,
    })

    return NEVER
  }

  return result.value
}

const MAX_SCRIPT_SIZE = 512000

function checkScriptSize(script: string, context: RefinementCtx) {
  const blob = new Blob([script], {
    type: 'text/plain',
  })

  if (blob.size > MAX_SCRIPT_SIZE) {
    const params: ScriptTooLarge = {
      type: 'script-too-large',
      actual: blob.size,
      max: MAX_SCRIPT_SIZE,
    }

    context.addIssue({
      code: ZodIssueCode.custom,
      message: 'Script is too large.',
      params,
      fatal: true,
    })
  }
}

export const script = string()
  .superRefine(checkScriptSize)
  .transform(parseOptions)
