import React from 'react'
import { SkippedDescription } from '../SkippedDescription'
import { InsightListItem } from '../InsightItem'
import {
  HealthLoadGeneratorHighCPUUsage,
  HealthLoadGeneratorHighCPUUsageFailed,
} from 'types/insights/health'
import { InsightBody } from '../InsightBody'
import { instanceName, percentage } from 'utils/formatters'
import { DocLink, docs, ReadMore } from 'components/DocLink'
import { TestRun } from 'types'
import { useLoadGeneratorMetrics } from './hooks'
import { LOAD_GENERATOR_CPU_PERCENT } from 'constants/metrics'
import { Chart } from 'components/Chart'

interface HighLoadGeneratorCPUUsageProps {
  testRun: TestRun
  insight: HealthLoadGeneratorHighCPUUsageFailed
}

export const HighLoadGeneratorCPUUsage = ({
  testRun,
  insight,
}: HighLoadGeneratorCPUUsageProps) => {
  const metrics = useLoadGeneratorMetrics(
    LOAD_GENERATOR_CPU_PERCENT,
    insight.data.instances
  )

  const intro =
    insight.data.instances[0] !== undefined
      ? `The cloud load generator ${instanceName(
          insight.data.instances[0]
        )} showed high CPU usage during your test`
      : `There were ${insight.data.instances.length} load generator instances showing high CPU usage during your test.`

  return (
    <InsightBody>
      <p>
        {intro}. When the resources on a load generator are consistently high,
        test results may become erratic and inaccurate as the load generator
        struggles to send requests and process responses. A good rule of thumb
        is that CPU usage should not consistently exceed{' '}
        {percentage(insight.data.threshold.max_cpu_usage)} during a test. The
        graph below shows the actual CPU utilization during this test.
      </p>
      <p>
        <Chart
          title="High Load Generator CPU Usage"
          testRun={testRun}
          metrics={metrics}
        />
      </p>
      <h4>Recommendations</h4>
      <ul>
        <li>
          Add or increase think time using{' '}
          <DocLink article={docs.javascript.sleep}>sleep</DocLink>, to slow down
          the request rate. You may want to increase the number of total VUs to
          compensate.
        </li>
        <li>
          Select more load zones to spread out the number of VUs across more
          regions and load generators.
        </li>
        <li>
          Remove or consolidate logs and custom metrics in the test script.
        </li>
        <li>
          <DocLink article={docs.thresholds.main}>Set a threshold</DocLink> for
          memory utilization to be notified when this happens again.
        </li>
      </ul>
      <p>
        <ReadMore article={docs.insights.health.highLoadGeneratorCPUUsage} />
      </p>
    </InsightBody>
  )
}

interface HighLoadGeneratorCPUUsageItemProps {
  insight: HealthLoadGeneratorHighCPUUsage
}

const HighLoadGeneratorCPUUsageDescription = ({
  insight,
}: HighLoadGeneratorCPUUsageItemProps) => {
  switch (insight.status) {
    case 'PASSED':
      return (
        <>
          The CPU utilization of the load generators remained healthy throughout
          the test.{' '}
          <ReadMore article={docs.insights.health.highLoadGeneratorCPUUsage} />
        </>
      )

    case 'FAILED':
      return (
        <>
          One or more load generators in this test displayed high CPU
          utilization.
        </>
      )

    default:
      return <SkippedDescription insight={insight} />
  }
}

export const HighLoadGeneratorCPUUsageItem = ({
  insight,
}: HighLoadGeneratorCPUUsageItemProps) => {
  return (
    <InsightListItem header="High Load Generator CPU Usage" insight={insight}>
      <HighLoadGeneratorCPUUsageDescription insight={insight} />
    </InsightListItem>
  )
}
