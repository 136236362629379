import {
  DefinedUseQueryResult,
  useMutation,
  UseMutationOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { ResultAsync } from 'neverthrow'
import { useCallback } from 'react'

export const isError = <T>(result: UseQueryResult<T>) => {
  return result.isError
}

export const isLoading = <T>(result: UseQueryResult<T>) => {
  return result.isLoading
}

export const isFetched = <T>(
  result: UseQueryResult<T>
): result is DefinedUseQueryResult<T> => {
  return result.isFetched
}

export const isFetching = <T>(result: UseQueryResult<T>) => {
  return result.isFetching
}

export const useMutationWithResult = <TData, TError, TVariables, TContext, E>(
  selectErr: (error: unknown) => E,
  options: UseMutationOptions<TData, TError, TVariables, TContext>
) => {
  // False positive for this eslint rule
  // eslint-disable-next-line @tanstack/query/prefer-query-object-syntax
  const { mutateAsync, ...rest } = useMutation(options)

  return {
    ...rest,
    mutateAsync,
    mutateResult: useCallback(
      (args: TVariables) =>
        ResultAsync.fromPromise(mutateAsync(args), selectErr),
      // adding selectErr as dependency causes infinite loop when mutateResult is used in useEffect
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [mutateAsync]
    ),
  }
}
