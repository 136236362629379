import { useStreamingQuery } from 'hooks/useStreamingQuery'
import { SortOptions, TestRun } from 'types'
import { AnyTable } from './types'

interface UsePagedListOptions<T, P> {
  testRun: TestRun
  params: P
  table: AnyTable<T, P>
  page: number
  sortBy?: SortOptions<T> | undefined
}

export function usePagedList<T, P>({
  testRun,
  params,
  page,
  sortBy,
  table: { pageSize, fetchPage },
}: UsePagedListOptions<T, P>) {
  const fetcher = fetchPage({
    ...params,
    testRun,
    page,
    pageSize,
    sortBy,
  })

  return useStreamingQuery(testRun, {
    queryKey: fetcher.queryKey,
    queryFn: () => fetcher.queryFn(),
    select: fetcher.select,

    keepPreviousData: true,
    enabled: fetcher.enabled,
  })
}
