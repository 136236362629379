import { useProjectSettings } from 'data/useProjectSettings'
import { useUsageReports } from 'data/useUsageReports'
import { startOfMonth } from 'date-fns'
import { toISODate } from 'utils/date'

const startOfTheMonth = toISODate(startOfMonth(new Date()))

export function useProjectLimitsAndUsageReport(projectId: number) {
  const { data: limits, isLoading: isLoadingProjectLimits } =
    useProjectSettings(projectId)
  const { data: usage, isLoading: isLoadingUsageReport } = useUsageReports(
    {
      projects: [projectId.toString()],
      startTime: startOfTheMonth,
    },
    {
      useErrorBoundary: false,
      meta: {
        alertOnError: 'Failed to fetch project statistics',
      },
    }
  )

  return {
    limits,
    usage,
    isLoading: isLoadingProjectLimits || isLoadingUsageReport,
  }
}
