import React from 'react'

import { HttpMetricSummary } from 'types'
import { Statistic } from './Statistic'
import {
  formatCount,
  formatRequestRate,
  formatResponseTime,
} from '../../Breakdown/ComparedValue'

import { Grid } from 'components/Grid'

interface HttpSummaryProps {
  left?: HttpMetricSummary
  right?: HttpMetricSummary | null | undefined
}

export const HttpSummary = ({ left, right }: HttpSummaryProps) => {
  const requestCount = formatCount(
    left?.requests_count || 0,
    right?.requests_count
  )
  const failureCount = formatCount(
    left?.failures_count || 0,
    right?.failures_count
  )
  const requestRate = formatRequestRate(left?.rps_max || 0, right?.rps_max)
  const responseTime = formatResponseTime(
    left?.duration.p95 ?? 0,
    right?.duration.p95
  )

  return (
    <Grid gap={1}>
      <Grid.Column xs={6} md={3} xl={2}>
        <Statistic label="REQUESTS MADE" unit="reqs" {...requestCount} />
      </Grid.Column>
      <Grid.Column xs={6} md={3} xl={2}>
        <Statistic label="HTTP FAILURES" unit="reqs" {...failureCount} />
      </Grid.Column>
      <Grid.Column xs={6} md={3} xl={2}>
        <Statistic label="PEAK RPS" unit="reqs/s" {...requestRate} />
      </Grid.Column>
      <Grid.Column xs={6} md={3} xl={2}>
        <Statistic label="P95 RESPONSE TIME" unit="ms" {...responseTime} />
      </Grid.Column>
    </Grid>
  )
}
