import { useEffect, useRef } from 'react'

export const useOnce = <TFunction extends () => void>(
  fn: TFunction,
  enabled: boolean
) => {
  const triggered = useRef(false)

  useEffect(() => {
    if (enabled && !triggered.current) {
      triggered.current = true
      fn()
    }
  }, [fn, enabled])
}
