import React, { useMemo } from 'react'
import { type AbsoluteTimeRange } from '@grafana/data'

import { Http, TestRunAnalysis, hasHttpMetricSummary } from 'types'
import { RunsPageTestIds } from 'types/dataTestIds'
import { HttpChart } from './HttpChart'

import { useTimeRange } from '../../../TimeRangeContext'
import { TableDefinition, ViewType } from '../BreakdownTable'
import { toHttpQueryKey } from 'data/queryKeys'
import { HttpEmptyView } from './HttpEmptyView'
import { useRunDetailsHttpFilters } from '../../Filters/Filters.hooks'
import { HttpFilters } from './HttpFilters'
import { useEnumParameter } from 'hooks/useQueryParameter'
import { HttpUrlClient, useHttpUrlClient } from 'data/clients/entities/httpUrls'
import { TooltipCell } from '../BreakdownTable/Table/TooltipCell'
import { ClipboardContent } from '../BreakdownTable/ClipboardContent'
import { ShortURL } from 'components/ShortURL'
import { EmptyFilterView } from 'components/EmptyFilterView'
import { BreakdownView } from '../BreakdownView'
import { asCount, asTiming } from '../ComparedValue'
import { byGroup, byRootGroup } from '../utils'
import { FilterExpression, serializeRunFilter } from 'datasource/serialization'
import { QueryErrorBoundary } from 'components/ErrorBoundary'
import { ErrorBoundaryLocalView } from 'services/tracking.types'

interface HttpTableProps {
  client: HttpUrlClient
  filter: FilterExpression<Http>
  timeRange: AbsoluteTimeRange | undefined
}

export const table: TableDefinition<Http, HttpTableProps> = {
  id: 'http-breakdown',
  paginator: 'always',
  pageSize: 20,

  keyBy(row) {
    return row.id
  },

  groupBy(row) {
    return row.group_id
  },

  isSuccess(row) {
    return row.expected_response
  },

  getRowCount({ http_metric_summary }) {
    return http_metric_summary.requests_count ?? 0
  },

  fetchPage({ client, testRun, filter, ...rest }) {
    const params = {
      ...rest,
      filter: byRootGroup(filter),
    }
    return {
      queryKey: toHttpQueryKey(testRun.id, params),
      queryFn: () => client.fetchPage(testRun.id, params),
    }
  },

  fetchByRows({ client, testRun, rows }) {
    const ids = rows.map((url) => url.id).sort()

    return {
      queryKey: toHttpQueryKey(testRun.id, { ids }),
      queryFn: () => client.fetchByIds(testRun.id, ids),
    }
  },

  fetchByGroup({ client, testRun, group, filter, ...rest }) {
    const params = {
      ...rest,
      filter: byGroup(group, filter),
    }

    return {
      queryKey: toHttpQueryKey(testRun.id, params),
      queryFn: (page) => client.fetchPage(testRun.id, { ...params, page }),
    }
  },

  columns: [
    {
      id: 'url',
      name: 'URL',
      sortBy: 'name',
      toggle: 'none',
      customStyles: { maxWidth: 560 },

      renderCell: ({ name }) => (
        <TooltipCell
          tooltip={
            <ClipboardContent actionText="Copy url to clipboard" data={name}>
              {name}
            </ClipboardContent>
          }
        >
          <ShortURL url={name} />
        </TooltipCell>
      ),
      renderGroup: ({ name }) => <>{name}</>,
    },
    {
      id: 'scenario',
      name: 'Scenario',
      sortBy: 'scenario',
      toggle: 'none',

      renderCell: ({ scenario }) => <>{scenario}</>,
    },
    {
      id: 'method',
      name: 'Method',
      sortBy: 'method',
      toggle: 'none',

      renderCell: ({ method }) => <>{method}</>,
      renderGroup: () => <></>,
    },
    {
      id: 'status',
      name: 'Status',
      sortBy: 'status',
      toggle: 'none',

      renderCell: ({ status }) => <>{status}</>,
      renderGroup: () => <></>,
    },
    {
      id: 'count',
      name: 'Count',
      sortBy: 'http_metric_summary/duration/count',
      numeric: true,

      renderCell: asCount(
        ({ http_metric_summary }) => http_metric_summary.requests_count
      ),
      renderGroup: asCount(
        ({ http_metric_summary }) => http_metric_summary.requests_count
      ),
    },
    {
      id: 'min',
      name: 'Min',
      sortBy: 'http_metric_summary/duration/min',
      numeric: true,

      renderCell: asTiming(
        ({ http_metric_summary }) => http_metric_summary.duration.min
      ),
      renderGroup: asTiming(
        ({ http_metric_summary }) => http_metric_summary.duration.min
      ),
    },
    {
      id: 'avg',
      name: 'Avg',
      sortBy: 'http_metric_summary/duration/mean',
      numeric: true,

      renderCell: asTiming(
        ({ http_metric_summary }) => http_metric_summary.duration.mean
      ),
      renderGroup: asTiming(
        ({ http_metric_summary }) => http_metric_summary.duration.mean
      ),
    },
    {
      id: 'stddev',
      name: 'Stddev',
      sortBy: 'http_metric_summary/duration/stdev',
      numeric: true,

      renderCell: asTiming(
        ({ http_metric_summary }) => http_metric_summary.duration.stdev
      ),
      renderGroup: asTiming(
        ({ http_metric_summary }) => http_metric_summary.duration.stdev
      ),
    },
    {
      id: 'p95',
      name: 'P95',
      sortBy: 'http_metric_summary/duration/p95',
      numeric: true,

      renderCell: asTiming(
        ({ http_metric_summary }) => http_metric_summary.duration.p95
      ),
      renderGroup: asTiming(
        ({ http_metric_summary }) => http_metric_summary.duration.p95
      ),
    },
    {
      id: 'p99',
      name: 'P99',
      sortBy: 'http_metric_summary/duration/p99',
      numeric: true,

      renderCell: asTiming(
        ({ http_metric_summary }) => http_metric_summary.duration.p99
      ),
      renderGroup: asTiming(
        ({ http_metric_summary }) => http_metric_summary.duration.p99
      ),
    },
    {
      id: 'max',
      name: 'Max',
      sortBy: 'http_metric_summary/duration/max',
      numeric: true,

      renderCell: asTiming(
        ({ http_metric_summary }) => http_metric_summary.duration.max
      ),
      renderGroup: asTiming(
        ({ http_metric_summary }) => http_metric_summary.duration.max
      ),
    },
  ],

  RowBodyComponent: ({ analysis, value }) => (
    <HttpChart analysis={analysis} url={value} />
  ),
  EmptyGroupComponent: () => (
    <>No HTTP requests were issued during test execution.</>
  ),
}

interface HttpTabProps {
  analysis: TestRunAnalysis
}

const HttpTabComponent = ({ analysis }: HttpTabProps) => {
  const { timeRange } = useTimeRange()
  const client = useHttpUrlClient()

  const [view, setView] = useEnumParameter('view', ['list', 'tree'] as const)
  const urlFiltersProps = useRunDetailsHttpFilters()
  const { filters, sortBy, removeAllFilters, updateSortBy } = urlFiltersProps

  const handleViewToggle = (newView: ViewType) => {
    setView('replace', newView)
  }

  const handleClearFilter = () => {
    removeAllFilters()
  }

  const filter = useMemo(() => serializeRunFilter<Http>(filters), [filters])

  if (!hasHttpMetricSummary(analysis.main.http_metric_summary)) {
    return <HttpEmptyView testRun={analysis.main} />
  }

  return (
    <BreakdownView
      data-testid={RunsPageTestIds.HttpTable}
      view={view}
      analysis={analysis}
      params={{ client, filter, timeRange }}
      table={table}
      header={<HttpFilters run={analysis.main} {...urlFiltersProps} />}
      emptyTable={
        <EmptyFilterView onClear={handleClearFilter}>
          No HTTP requests match the current filter...
        </EmptyFilterView>
      }
      sortBy={sortBy}
      onSort={updateSortBy}
      onViewChange={handleViewToggle}
    />
  )
}

export const HttpTab = (props: HttpTabProps) => (
  <QueryErrorBoundary view={ErrorBoundaryLocalView.HttpTab}>
    <HttpTabComponent {...props} />
  </QueryErrorBoundary>
)
