import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useAppContext } from 'appContext'
import { useDatasource } from 'datasourceContext'
import {
  toOrgProjectSettingsQueryKey,
  toProjectSettingsQueryKey,
} from './queryKeys'
import { ProjectSettings } from 'types'
import { showAlert } from 'utils/showAlert'
import { ResponseError } from 'types/errors'

export function useOrgProjectSettings() {
  const { ds } = useDatasource()
  const { orgId } = useAppContext()

  return useQuery({
    queryKey: toOrgProjectSettingsQueryKey(orgId),
    queryFn: () => ds.fetchOrgProjectSettings(orgId as number),
    enabled: !!orgId,
  })
}

export function useProjectSettings(
  projectId: number,
  options: UseQueryOptions<ProjectSettings> = {}
) {
  const { ds } = useDatasource()

  return useQuery({
    queryKey: toProjectSettingsQueryKey(projectId),
    queryFn: () => ds.fetchProjectSettings(projectId),
    ...options,
  })
}

export function useUpdateProjectLimits() {
  const { ds } = useDatasource()
  const queryClient = useQueryClient()
  const { orgId } = useAppContext()

  return useMutation({
    mutationFn: (limits: Omit<ProjectSettings, 'organization_id'>) =>
      ds.updateProjectLimits(limits),
    onSuccess: (_, limits) => {
      queryClient.invalidateQueries(toOrgProjectSettingsQueryKey(orgId))
      queryClient.invalidateQueries(
        toProjectSettingsQueryKey(limits.project_id)
      )
      showAlert('Project limits successfully updated', 'success')
    },
    onError: ({ data }: ResponseError) => {
      showAlert(
        `Cannot update project limits. ${data?.error?.message ?? ''}`,
        'error'
      )
    },
  })
}

export function useClearProjectLimits() {
  const { ds } = useDatasource()
  const queryClient = useQueryClient()
  const { orgId } = useAppContext()

  return useMutation({
    mutationFn: (projectId: number) => ds.clearProjectLimits(projectId),
    onSuccess: (_, projectId) => {
      queryClient.invalidateQueries(toOrgProjectSettingsQueryKey(orgId))
      queryClient.invalidateQueries(toProjectSettingsQueryKey(projectId))
      showAlert('Project limits successfully cleared', 'success')
    },
    onError: ({ data }: ResponseError) => {
      showAlert(
        `Cannot clear project limits. ${data?.error?.message ?? ''}`,
        'error'
      )
    },
  })
}
