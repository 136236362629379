import React from 'react'
import { Icon, Spinner } from '@grafana/ui'

import { type InsightAuditResult } from 'types/cloudInsights'
import {
  getBinaryScoreStatus,
  isInsightExecuting,
  isInsightFailed,
} from 'utils/insights'
import { toPercentage } from 'utils/math'
import { exhaustive } from 'utils/typescript'

import { getScoreIcon } from './Score.utils'
import { Container, Text } from './Score.styles'

const ICON_SIZE = 'xl'

interface ScoreProps {
  result?: InsightAuditResult
}

export const Score = ({ result }: ScoreProps) => {
  if (!result || isInsightExecuting(result)) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  if (isInsightFailed(result)) {
    return (
      <Container>
        <Icon
          name="question-circle"
          size={ICON_SIZE}
          aria-label="Insight failed"
        />
      </Container>
    )
  }

  switch (result.score?.type) {
    case 'numeric':
      return (
        <Container score={result.score}>
          <Text>{toPercentage(result.score.value)}</Text>
        </Container>
      )

    case 'binary':
      return (
        <Container score={result.score}>
          <Icon
            name={getScoreIcon(getBinaryScoreStatus(result.score.value))}
            size={ICON_SIZE}
            aria-label="Insight binary score"
          />
        </Container>
      )

    case undefined:
      return (
        <Container>
          <Icon
            name="question-circle"
            size={ICON_SIZE}
            aria-label="Unknown state"
          />
        </Container>
      )

    default:
      return exhaustive(result.score)
  }
}
