import React, { ReactNode, UIEvent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Dropdown, Menu } from '@grafana/ui'

import { Avatar } from 'components/Avatar'
import { LinkButton } from 'components/LinkButton'

import { ProjectRoleWithUser } from 'types'
import { routeMap } from 'routeMap'
import { CenteredSpinner } from 'components/CenteredSpinner'

export const MembersDropdown = ({
  users,
  children,
  projectId,
  isFetching,
  onScrollEnd,
}: {
  users: ProjectRoleWithUser[]
  children: ReactNode
  projectId: number
  isFetching?: boolean
  onScrollEnd?: () => void
}) => {
  return (
    <Dropdown
      overlay={
        <MembersDropdownList
          users={users}
          projectId={projectId}
          isFetching={isFetching}
          onScrollEnd={onScrollEnd}
        />
      }
    >
      <MoreMembersButton>{children}</MoreMembersButton>
    </Dropdown>
  )
}

const MembersDropdownList = ({
  users,
  projectId,
  isFetching = false,
  onScrollEnd = () => {},
}: {
  users: ProjectRoleWithUser[]
  projectId: number
  isFetching?: boolean
  onScrollEnd?: () => void
}) => {
  const [currentPosition, setCurrentPosition] = useState(0)
  const listRef = useRef<HTMLUListElement>(null)

  const handleScroll = (event: UIEvent<HTMLUListElement>) => {
    const listboxNode = event.currentTarget

    const position = listboxNode.clientHeight + listboxNode.scrollTop
    const scrollHeight = listboxNode.scrollHeight

    if (scrollHeight - position <= 10 && !isFetching) {
      setCurrentPosition(scrollHeight)
      onScrollEnd()
    }
  }

  useEffect(() => {
    if (listRef && listRef.current) {
      listRef.current.scroll({ top: currentPosition })
    }
  }, [isFetching, currentPosition])

  return (
    <Menu>
      <List onScroll={handleScroll} ref={listRef}>
        {users.map((user) => (
          <ListItem key={user.id}>
            <Avatar
              gravatar_url={user.user?.gravatar_url}
              isLoading={user.user === undefined}
            />
            <Email title={user.user_email}>{user.user_email}</Email>
          </ListItem>
        ))}

        {isFetching && <CenteredSpinner $height="58px" />}
      </List>

      <InviteUsersContainer>
        <LinkButton
          to={routeMap.project(projectId, true)}
          button={{ fullWidth: true }}
        >
          Manage users
        </LinkButton>
      </InviteUsersContainer>
    </Menu>
  )
}

export const MoreMembersButton = styled.button`
  color: ${({ theme }) => theme.colors.primary.text};
  font-weight: 500;
  background: transparent;
  border: 0;
  padding-left: ${({ theme }) => theme.spacing(0.25)};
  min-width: 45px;
`

export const List = styled.ul`
  padding: ${({ theme }) => `${theme.spacing(1.25)} ${theme.spacing(1)}`};
  list-style-type: none;
  max-height: 270px;
  overflow-y: auto;
  width: 300px;
`

export const ListItem = styled.li`
  display: flex;
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(1.5)}`};
`

export const Email = styled.span`
  margin-left: ${({ theme }) => theme.spacing(1)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const InviteUsersContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacing(0.75)} ${theme.spacing(1)}`};

  button {
    width: 100%;
    margin-bottom: 0;
  }
`
