import React from 'react'
import { Button, Modal } from '@grafana/ui'

import { SavedTestRun } from 'types/testRun'
import { useSubscriptionRule } from 'data/useSubscriptions'
import { useToggleSafe } from 'data/useToggleSafe'
import { DocLink, docs } from 'components/DocLink'

interface SavedTestRunsModalProps {
  testRun: SavedTestRun | null
  onDismiss: () => void
}

export const SavedTestRunsModal = ({
  testRun,
  onDismiss,
}: SavedTestRunsModalProps) => {
  const maxSafeCount = useSubscriptionRule('data.retention_safe.max')
  const { mutateAsync: toggleSafe, isLoading } = useToggleSafe()

  async function handleRemove() {
    if (!testRun) {
      return
    }

    await toggleSafe(testRun)
    onDismiss()
  }

  return (
    <Modal title="Remove safe status" isOpen={!!testRun} onDismiss={onDismiss}>
      <div>
        By removing safe status you are making the test results deletable by{' '}
        <DocLink article={docs.subscription.dataRetention}>
          data retention policy
        </DocLink>
        .
        <br />
        <i>
          Your subscription allows for <b>{maxSafeCount}</b> saved tests.
        </i>
      </div>
      <Modal.ButtonRow>
        <Button variant="secondary" onClick={onDismiss} fill="outline">
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          icon={isLoading ? 'fa fa-spinner' : undefined}
          onClick={handleRemove}
        >
          Remove safe status
        </Button>
      </Modal.ButtonRow>
    </Modal>
  )
}
