import { useMemo } from 'react'

import type { TestRunWithMetrics } from 'types'
import type { InsightExecutionResultWithTestRun } from 'types/cloudInsights'
import {
  useInsightsLatestExecutionList,
  useInsightsExecutionResultList,
} from 'data/useCloudInsights'
import { useQueryParameter } from 'hooks/useQueryParameter'
import { isLoading } from 'utils/reactQuery'

export const usePageParameter = () => {
  return useQueryParameter({
    name: 'page',
    decoder: (value) => {
      const page = Number(value)

      if (value === null || !Number.isInteger(page)) {
        return 1
      }

      return page
    },
    encoder: (value) => value.toString(),
  })
}

export const useTestRunInsightResults = (
  testRuns: TestRunWithMetrics[],
  options?: { enabled?: boolean; useErrorBoundary?: boolean }
) => {
  const executions = useInsightsLatestExecutionList(testRuns, options)
  const queries = useInsightsExecutionResultList(executions, options)

  const data = useMemo(() => {
    return queries.map(({ data }) => data).filter(isTestRunResult)
  }, [queries])

  return {
    data,
    isLoading: queries.some(isLoading),
  }
}

const isTestRunResult = (
  data?: InsightExecutionResultWithTestRun
): data is InsightExecutionResultWithTestRun => {
  return !!data?.test_run
}
