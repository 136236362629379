import React from 'react'
import styled from 'styled-components'

import { DocLink, docs } from 'components/DocLink'
import { Flex } from 'components/Flex'
import { InfoToggletip } from 'components/InfoToggletip'

export const VUhHeaderCell = () => {
  const tooltip = 'About Virtual User hours'

  return (
    <Flex align="center" justify="center" gap={0.5}>
      VUh
      <InfoToggletip content={<VuhContent />} tooltip={tooltip} />
    </Flex>
  )
}

const VuhContent = () => {
  return (
    <>
      <p>
        Virtual-User hours (VUh) are calculated by multiplying two variables:
      </p>
      <Ul>
        <li>The maximum number of VUs that the script uses</li>
        <li>The test execution duration in minutes</li>
      </Ul>
      <DocLink article={docs.gettingStarted.vuHours} showIcon>
        Getting started with VUh
      </DocLink>
    </>
  )
}

const Ul = styled.ul`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(2)};
`
