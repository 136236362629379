import { colorManipulator } from '@grafana/data'

import { type PropsWithTheme } from 'utils/styled'

export const getBackgroundColorWeak = ({ theme }: PropsWithTheme) => {
  return theme.isLight
    ? colorManipulator.darken(theme.colors.background.secondary, 0.05)
    : colorManipulator.lighten(theme.colors.background.secondary, 0.03)
}

export const getBackgroundColorMedium = ({ theme }: PropsWithTheme) => {
  return theme.isLight
    ? colorManipulator.darken(theme.colors.background.secondary, 0.1)
    : colorManipulator.lighten(theme.colors.background.secondary, 0.1)
}

export const getBackgroundColorStrong = ({ theme }: PropsWithTheme) => {
  return theme.isLight
    ? colorManipulator.darken(theme.colors.background.secondary, 0.2)
    : colorManipulator.lighten(theme.colors.background.secondary, 0.2)
}
