import { Rules, SUBSCRIPTION_STATUS, SUBSCRIPTION_TYPE } from 'types'
import { useAppContext } from 'appContext'
import { useOrganization } from './useOrganization'
import { getHighestRules, sortBySubscriptionType } from '../utils/subscription'

export const useSubscriptions = () => {
  const { account } = useAppContext()
  const org = useOrganization()

  if (!org) {
    return
  }

  return account.subscriptions.filter((sub) =>
    org.subscription_ids.includes(sub.id)
  )
}

export const usePaidSubscriptions = () => {
  const subscriptions = useSubscriptions()

  if (!subscriptions) {
    return
  }

  return subscriptions
    .filter(
      (subscription) =>
        subscription.type !== SUBSCRIPTION_TYPE.FREE && !subscription.is_addon
    )
    .map((subscription) => {
      return {
        ...subscription,
        isTrial: subscription.version === 4 && subscription.name === 'Trial',
      }
    })
}

export const useActiveSubscriptions = () => {
  const filteredSubscriptions = usePaidSubscriptions()

  if (!filteredSubscriptions) {
    return
  }

  const activeSubs = filteredSubscriptions.filter(
    (subscription) => subscription.status === SUBSCRIPTION_STATUS.ACTIVE
  )

  return sortBySubscriptionType(activeSubs)
}

export const useHasActiveSubscription = () => {
  const sortedSubscriptions = useActiveSubscriptions()
  return sortedSubscriptions && sortedSubscriptions.length > 0
}

export const useHasOnlyTrialTypeSubscriptions = () => {
  const activeSubscriptions = useActiveSubscriptions()
  if (!activeSubscriptions) {
    return false
  }
  return activeSubscriptions.every(
    (subscription) => subscription.type === SUBSCRIPTION_TYPE.TRIAL
  )
}

export const useIsFreeOrTrialSubscription = () => {
  const subscriptions = useSubscriptions() ?? []

  return subscriptions.every(
    (subscription) =>
      subscription.type === SUBSCRIPTION_TYPE.FREE ||
      subscription.type === SUBSCRIPTION_TYPE.TRIAL
  )
}

export const useSubscriptionRule = <K extends keyof Rules>(rule: K) => {
  const subscriptions = useActiveSubscriptions()
  const rules = getHighestRules(subscriptions ?? [])

  return rules[rule]
}

export const useSubscriptionRules = () => {
  const subscriptions = useActiveSubscriptions()

  return getHighestRules(subscriptions ?? [])
}
