import type { WebVitalValueConfig, WebVitalName, WebVitalScore } from './types'
import { WEB_VITAL_CONFIG } from './constants'
import { webVitalFormatter } from './WebVitalBarGauge.utils'
import { exhaustive } from 'utils/typescript'

export function getPresentationUnit(unit: WebVitalValueConfig['unitType']) {
  switch (unit) {
    case 'seconds':
      return 's'
    case 'milliseconds':
      return 'ms'
    case 'score':
      return ''
    default:
      return exhaustive(unit)
  }
}

function getWebVitalValueString(value: WebVitalValueConfig) {
  if (value.originalValue === null) {
    return '-'
  }
  if (value.unitType === 'score') {
    return value.value.toString()
  }

  return `${value.value}${value.unit}`
}

export function getWebVitalValueConfig(
  name: WebVitalName,
  value: undefined | number | null = null
): WebVitalValueConfig {
  const config = WEB_VITAL_CONFIG[name]
  if (!config) {
    throw new TypeError(`Unknown web vital name: ${name}`)
  }
  const score = getWebVitalScore(name, value)
  const formattedValue = webVitalFormatter(value, config.unit)
  const { unit } = config
  const thresholds = config.thresholds

  return {
    name,
    value: formattedValue,
    score,
    unitType: unit,
    unit: getPresentationUnit(unit),
    originalValue: value,
    thresholds,
    toString() {
      return getWebVitalValueString(this)
    },
  }
}

export function getWebVitalScore(
  name: WebVitalName,
  value: number | null,
  forcedValue?: WebVitalScore
): WebVitalScore | undefined {
  if (forcedValue) {
    return forcedValue
  }

  const thresholds = WEB_VITAL_CONFIG[name]?.thresholds

  if (!thresholds || value === null) {
    return undefined
  }

  const [good, poor] = thresholds

  if (good >= value) {
    return 'good'
  }

  if (poor < value) {
    return 'poor'
  }

  return 'needs_improvement'
}
