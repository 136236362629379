import React from 'react'
import styled from 'styled-components'
import {
  ClipboardIconButton,
  ClipboardIconButtonProps,
} from 'components/ClipboardIconButton'
import { spacing } from 'utils/styled'

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${spacing(1)};
`

const Content = styled.div`
  word-break: break-word;
`

interface ClipboardContentProps extends ClipboardIconButtonProps {}

export function ClipboardContent({
  children,
  ...props
}: ClipboardContentProps) {
  return (
    <Container>
      <Content>{children}</Content> <ClipboardIconButton {...props} />
    </Container>
  )
}
