import { useCallback, useMemo } from 'react'
import {
  TestRunAnalysis,
  hasGrpcMetricSummary,
  hasHttpMetricSummary,
  hasWsMetricSummary,
  toGrpcMetricSummary,
  toHttpMetricSummary,
  toWsMetricSummary,
  hasBrowserMetricSummary,
  toBrowserMetricSummary,
} from 'types'
import { Protocol } from './RunOverview.types'
import {
  BROWSER_DEFAULT_METRICS,
  GRPC_REQUEST_RATE,
  GRPC_RESPONSE_TIME,
  HTTP_FAILURE_RATE,
  HTTP_REQUEST_RATE,
  HTTP_RESPONSE_TIME_95,
  VUS_METRIC,
  WS_SESSION_DURATION,
  WS_SESSION_RATE,
} from 'constants/metrics'
import { useQueryParameter } from 'hooks/useQueryParameter'
import { first } from 'lodash-es'
import { createNewFeatureLabel } from './NewFeatureLabel'
import { BROWSER_DISCOVERABILITY } from 'constants/browser'

export function useAvailableProtocols({ main, compareWith }: TestRunAnalysis) {
  return useMemo(() => {
    const result: Protocol[] = []

    if (hasHttpMetricSummary(main.http_metric_summary)) {
      result.push({
        type: 'http',
        label: 'HTTP',
        left: main.http_metric_summary,
        right:
          compareWith && toHttpMetricSummary(compareWith.http_metric_summary),
        metrics: [
          VUS_METRIC,
          HTTP_REQUEST_RATE,
          HTTP_RESPONSE_TIME_95,
          HTTP_FAILURE_RATE,
        ],
      })
    }

    if (hasWsMetricSummary(main.ws_metric_summary)) {
      result.push({
        type: 'ws',
        label: 'WebSockets',
        left: main.ws_metric_summary,
        right: compareWith && toWsMetricSummary(compareWith.ws_metric_summary),
        metrics: [VUS_METRIC, WS_SESSION_RATE, WS_SESSION_DURATION],
      })
    }

    if (hasGrpcMetricSummary(main.grpc_metric_summary)) {
      result.push({
        type: 'grpc',
        label: 'gRPC',
        left: main.grpc_metric_summary,
        right:
          compareWith && toGrpcMetricSummary(compareWith.grpc_metric_summary),
        metrics: [VUS_METRIC, GRPC_REQUEST_RATE, GRPC_RESPONSE_TIME],
      })
    }

    if (hasBrowserMetricSummary(main.browser_metric_summary)) {
      result.push({
        type: 'browser',
        label: BROWSER_DISCOVERABILITY
          ? createNewFeatureLabel('Browser')
          : 'Browser',
        left: main.browser_metric_summary,
        right:
          compareWith &&
          toBrowserMetricSummary(compareWith.browser_metric_summary),
        metrics: [VUS_METRIC, ...BROWSER_DEFAULT_METRICS],
      })
    }

    return result
  }, [main, compareWith])
}

interface OverviewState {
  open: boolean
  active: Protocol | undefined
}

export function useOverviewState(protocols: Protocol[]) {
  const parser = useCallback(
    (value: string | null): OverviewState => {
      const open = value === null || !value.startsWith('-')
      const type = value?.replace(/^-/, '')

      return {
        open,
        active:
          protocols.find((protocol) => protocol.type === type) ??
          first(protocols),
      }
    },
    [protocols]
  )

  return useQueryParameter({
    name: 'overview',
    decoder: parser,
    encoder: ({ open, active: protocol }) => {
      if (protocol === undefined) {
        return null
      }

      return open ? protocol.type : `-${protocol.type}`
    },
  })
}
