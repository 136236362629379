import {
  sortBy,
  union,
  isNumber,
  isArray,
  isBoolean,
  mergeWith,
} from 'lodash-es'
import { Rules, Subscription, SUBSCRIPTION_TYPE } from '../types'

export const sortBySubscriptionType = <T extends { type: number }>(
  subscriptions: T[]
) => {
  return sortBy(subscriptions, (sub) => {
    switch (sub.type) {
      case SUBSCRIPTION_TYPE.CUSTOM:
        return 0
      case SUBSCRIPTION_TYPE.PREMIUM:
        return 1
      case SUBSCRIPTION_TYPE.DATA_RETENTION:
        return 2
      case SUBSCRIPTION_TYPE.APP_DIRECT:
        return 3
      case SUBSCRIPTION_TYPE.TRIAL:
        return 4
      default:
        return 5
    }
  })
}
/**
 * Ported from app.k6.io
 * Returns list of rules object from sub.rules, as well as sub.addons[].rules
 * @param subscription
 * @returns {*[]}
 */
function getRulesFromSubscriptionAndAddons(subscription: Subscription) {
  const rules = [subscription.rules]
  if (subscription.addons && subscription.addons.length) {
    subscription.addons.forEach((addon) => {
      rules.push(addon.rules)
    })
  }

  return rules
}

/**
 * Ported from app.k6.io
 * Returns list of rules object from sub[].rules, as well as sub[].addons[].rules
 * @param subscriptions
 * @returns {*[]}
 */
function getAllRulesFromSubscriptionAndAddons(subscriptions: Subscription[]) {
  const rules: Rules[] = []
  subscriptions.forEach((subscription) => {
    rules.push(...getRulesFromSubscriptionAndAddons(subscription))
  })

  return rules
}

export const NULL_SUBSCRIPTION_RULES: Rules = {
  'apm.is_enabled': false,
  'apm.metrics.max': 0,
  'apm.providers.allowed': [],
  'apm.providers.max': 0,
  'beta.load_zones.allow': false,
  'beta.payment_provider.allow': false,
  'beta.test_worker.allow': false,
  'data.retention_days.post_sub_expiry': 0,
  'data.retention_days': 0,
  'data.retention_safe.max': 0,
  'load_test.allow_start': false,
  'load_test.delete_sensitive_data.allow': false,
  'load_test.duration.max': 0,
  'load_test.extra_ip.max': 0,
  'load_test.instances.max': 0,
  'load_test.k6_cloud_execution.allow': false,
  'load_test.k6_to_ingest_with_thresholds.allow': false,
  'load_test.load_zones.max': 0,
  'load_test.new_relic.max': 0,
  'load_test.new_relic.metrics.max': 0,
  'load_test.ramp_steps.max': 0,
  'load_test.ramp_steps.min': 0,
  'load_test.ramp_time.min': 0,
  'load_test.sma.max': 0,
  'load_test.source_ips_multiple.max': 0,
  'load_test.tracks.max': 0,
  'load_test.tracks.min': 0,
  'load_test.url_groups.max': 0,
  'load_test.users.max': 0,
  'load_test.users.min': 0,
  'load_zone.loadimpact.stockholm1.allow': false,
  'load_zone.loadimpact.stockholm2.allow': false,
  'load_zone.private.load_zone_ids': [],
  'load_zone.rax.chicago.allow': false,
  'load_zone.rax.dallas.allow': false,
  'load_zone.rax.london.allow': false,
  'load_zone.rax.sydney.allow': false,
  'organization.audit_trail.view': false,
  'organization.executive_summary': false,
  'organization.members.max': 0,
  'organization.projects.default_max_duration_per_test': 3600,
  'organization.projects.default_max_vuh_per_month': 1000,
  'organization.projects.default_max_vus_per_test': 1000,
  'organization.static_ips.max': 0,
  'organization.tokens.max': 0,
  'organization.usage_reports': false,
  'subscription.cost.affects_parent_proration': false,
  'subscription.credits': 0,
  'tests.browser_users.max': 0,
  'tests.concurrent.max': 0,
  'tests.duration.max': 0,
  'tests.max': 0,
  'tests.threshold_configs.max': 0,
  'tests.users.max': 0,
  'tests.vuh.fractional_resolution': false,
  'tests.vuh.max': 0,
  'user_scenarios.validations.max': 0,
}

/**
 * Returns the highest rule from subscriptions including addons
 * @param subscriptions
 * @returns {*}
 */
export function getHighestRules(subscriptions: Subscription[]) {
  const [first, ...rest] = getAllRulesFromSubscriptionAndAddons(subscriptions)

  // In these rules we want to take the minimum value
  const minNumberRules = [
    'organization.projects.default_max_vuh_per_month',
    'organization.projects.default_max_vus_per_test',
    'organization.projects.default_max_duration_per_test',
  ]

  if (first === undefined) {
    return NULL_SUBSCRIPTION_RULES
  }

  return rest.reduce<Rules>((result, rules) => {
    return mergeWith(result, rules, (objValue, srcValue, key) => {
      if (isNumber(objValue) && isNumber(srcValue)) {
        if (minNumberRules.includes(key)) {
          return Math.min(objValue, srcValue)
        }

        return Math.max(objValue, srcValue)
      }

      if (isBoolean(objValue) && isBoolean(srcValue)) {
        return objValue || srcValue
      }

      if (isArray(objValue) && isArray(srcValue)) {
        return union(objValue, srcValue)
      }

      return objValue
    })
  }, first)
}
