import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Icon, Tooltip } from '@grafana/ui'

import { routeMap } from 'routeMap'
import { ProjectRoleWithUser } from 'types'
import { LinkButton } from 'components/LinkButton'
import { useProjectRolesWithUser } from 'data/useProjectRoles'
import { useIsUserProjectAdminByProjectId } from 'data/usePermissions'
import { RefetchIcon } from 'components/RefetchIcon'
import { spacing } from 'utils/styled'

import { MembersDropdown } from './MembersDropdown'
import { Avatar } from '../Avatar'

const PAGE_SIZE = 20
const DEFAULT_ORDER = '-id'

export const Members = ({
  projectId,
  maxMembers = 5,
  showAddButton = true,
}: {
  projectId: number
  maxMembers?: number
  showAddButton?: boolean
}) => {
  const [cachedUsers, setCachedUsers] = useState<{
    number?: ProjectRoleWithUser[]
  }>({})
  const [showErrorState, setShowErrorState] = useState(false) // Separate error state to keep error message while refetching

  const isUserProjectAdmin = useIsUserProjectAdminByProjectId(projectId)

  const {
    data: users,
    count: usersCount,
    isLoading,
    isFetching,
    isProjectRolesFetching,
    currentPage,
    fetchPage,
    isProjectRolesError,
    refetch,
  } = useProjectRolesWithUser(projectId, {
    pageSize: PAGE_SIZE,
    orderBy: DEFAULT_ORDER,
    keepPreviousData: true,
    enabled: isUserProjectAdmin,
    useErrorBoundary: false,
    alertOnError: false,
  })

  useEffect(() => {
    if (users && !isProjectRolesFetching) {
      setCachedUsers((prevUsers) => {
        return { ...prevUsers, [currentPage]: users }
      })
    }
  }, [users, currentPage, isProjectRolesFetching])

  useEffect(() => {
    if (!isProjectRolesFetching) {
      setShowErrorState(isProjectRolesError)
    }
  }, [isProjectRolesError, isProjectRolesFetching])

  if (showErrorState) {
    return (
      <Container>
        <UsersLabel>
          Members:{' '}
          <RefetchIconContainer>
            {isFetching ? (
              <Icon name="fa fa-spinner" />
            ) : (
              <RefetchIcon entity="members" onClick={() => refetch()} />
            )}
          </RefetchIconContainer>
        </UsersLabel>
      </Container>
    )
  }

  if (users.length === 0 || !usersCount || !isUserProjectAdmin) {
    return null
  }

  const cachedUsersArray = Object.values(cachedUsers).flat()

  const handleOnMembersDropdownScrollEnd = () => {
    if (usersCount > cachedUsersArray.length && !isFetching) {
      fetchPage(currentPage + 1)
    }
  }

  return (
    <Container>
      <UsersLabel>Members ({usersCount}):</UsersLabel>

      <List>
        {showAddButton && (
          <ListItem>
            <Tooltip content="Manage users">
              <AddMoreMembersLinkContainer>
                <LinkButton
                  button={{ variant: 'secondary', icon: 'plus' }}
                  to={`${routeMap.project(
                    projectId
                  )}?editPermissions=${projectId}`}
                />
              </AddMoreMembersLinkContainer>
            </Tooltip>
          </ListItem>
        )}

        {cachedUsersArray.slice(0, maxMembers).map((user) => (
          <ListItem key={user.id}>
            <Avatar
              isLoading={user.user === undefined}
              gravatar_url={user.user?.gravatar_url}
              tooltip={user.user_email}
            />
          </ListItem>
        ))}

        {cachedUsersArray.length > maxMembers && (
          <ListItem>
            <MembersDropdown
              users={cachedUsersArray}
              projectId={projectId}
              isFetching={isProjectRolesFetching && !isLoading}
              onScrollEnd={handleOnMembersDropdownScrollEnd}
            >
              <ViewAll>All</ViewAll> <Icon name="angle-down" />
            </MembersDropdown>
          </ListItem>
        )}
      </List>
    </Container>
  )
}

export const UsersLabel = styled.span`
  white-space: nowrap;
  display: flex;
  align-items: center;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`

export const List = styled.ul`
  display: flex;
  list-style-type: none;
  margin-left: ${spacing(0.75)};
`

export const ListItem = styled.li`
  margin-right: ${spacing(0.5)};
`

const AddMoreMembersLinkContainer = styled.div`
  button {
    height: 25px;
    padding: 11.5px;
    border-radius: 100%;
  }
`

export const RefetchIconContainer = styled.div`
  display: inline-block;
  margin-left: ${spacing(0.5)};
  width: 16px;
  height: 16px;
`

export const ViewAll = styled.span`
  margin-right: -5px;
`
