import React from 'react'
import { useHistory } from 'react-router-dom'
import { SelectableValue } from '@grafana/data'

import { Test, TestRun } from 'types'
import { isTestRunExpired, isTestRunComparable } from 'utils/testRun'

import { TestAndRunSelect } from '../TestAndRunSelect'
import { Wrapper } from './CompareWithDropdown.styles'
import { routeMap } from 'routeMap'
import { tabSupportsComparison } from '../Breakdown/Breakdown.utils'
import { useCurrentTab } from '../Breakdown/Breakdown.hooks'

interface CompareWithDropdownProps {
  test: Test
  testRun: TestRun
}

export const CompareWithDropdown = ({
  test,
  testRun,
}: CompareWithDropdownProps) => {
  const history = useHistory()
  const tab = useCurrentTab()
  const isRunExpired = isTestRunExpired(testRun)

  function handleChange({
    disabled,
    testRun: current,
  }: SelectableValue<Test['id']>) {
    if (current === undefined || isRunExpired || disabled) {
      return
    }

    return history.push(
      routeMap.testRunCompare({
        left: testRun.id,
        right: current.id,
        tab: tab && tabSupportsComparison(tab) ? tab : undefined,
      })
    )
  }

  if (!isTestRunComparable(testRun)) {
    return null
  }

  const selectId = 'compare-with'

  return (
    <Wrapper align="center" gap={1} wrap="nowrap">
      <label htmlFor={selectId}>Compare with</label>

      <TestAndRunSelect
        projectId={test.project_id}
        currentTestRun={testRun}
        testRunSelectId={selectId}
        onTestRunChange={handleChange}
        width={55}
        hideValue
      />
    </Wrapper>
  )
}
