import React, { FunctionComponent, ReactNode } from 'react'
import {
  Divider,
  Heading,
  ItemsContainer,
  Preamble,
  PromoItemContainer,
  PromoItemContent,
  PromoItemContentWrapper,
  PromoItemHeading,
  SectionStyled,
} from './Section.styled'
import { PromoCardsProps } from './PromoCards'
import { LearnMoreLink } from './LearnMoreLink'
import { css } from '@emotion/css'
import { useTheme2 } from '@grafana/ui'

export interface SectionProps {
  children: ReactNode
  direction?: 'row' | 'column'
  className?: string
}

export function Section({ children, direction, className }: SectionProps) {
  return (
    <SectionStyled className={className} $direction={direction}>
      {children}
    </SectionStyled>
  )
}

function PromoItems({
  items,
  divider = false,
}: PromoCardsProps & { divider?: boolean }) {
  const theme = useTheme2()
  const styles = css`
    @container (max-width: ${theme.breakpoints.values.lg}px) {
      flex-direction: column;
      & > div {
        flex-basis: auto;
      }

      & > ${Divider.toString()} {
        border-top: 1px #fff solid;
        border-left: none;
        margin: ${theme.spacing(0, 2)};
      }
    }

    @container (max-width: ${theme.breakpoints.values.sm}px) {
      & [class$='-PromoIcon'] {
        flex: 0 0 22px !important;
        height: 22px;
      }
    }
  `

  return (
    <ItemsContainer className={styles}>
      {items.map(({ icon, title, text, moreURL }, index) => (
        <React.Fragment key={index}>
          <PromoItem index={index} icon={icon} title={title} moreURL={moreURL}>
            {text}
          </PromoItem>
          {divider && index !== items.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </ItemsContainer>
  )
}

interface PromoItemProps {
  icon: FunctionComponent<any>
  title: ReactNode
  children: ReactNode
  index?: number
  moreURL?: string
}

export function PromoItem({
  icon: Icon,
  title,
  children,
  index,
  moreURL,
}: PromoItemProps) {
  return (
    <PromoItemContainer>
      <Icon index={index} />
      <PromoItemContentWrapper>
        <PromoItemHeading>{title}</PromoItemHeading>
        <PromoItemContent>{children}</PromoItemContent>
        {moreURL && (
          <div>
            <LearnMoreLink href={moreURL} />
          </div>
        )}
      </PromoItemContentWrapper>
    </PromoItemContainer>
  )
}

Section.Heading = Heading
Section.Preamble = Preamble
Section.PromoItems = PromoItems
