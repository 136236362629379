import { TestRun, WSValue } from 'types'
import { toWebSocketsQueryKey } from './queryKeys'
import { useQuery } from '@tanstack/react-query'
import { useDatasource } from 'datasourceContext'

type WebSocketsName = Pick<WSValue, 'name'>

export const useWebSocketsNames = (testRun: TestRun) => {
  const { ds } = useDatasource()

  return useQuery<WebSocketsName[]>({
    queryKey: toWebSocketsQueryKey(testRun.id, {
      select: 'name',
      ended: testRun.ended,
    }),
    queryFn: () =>
      ds
        .fetchWS({ id: testRun.id, select: 'name', count: false })
        .then((res) => res.value ?? []),
  })
}

type WebSocketsStatus = Pick<WSValue, 'status'>

export const useWebSocketsStatus = (testRun: TestRun) => {
  const { ds } = useDatasource()

  return useQuery<WebSocketsStatus[]>({
    queryKey: toWebSocketsQueryKey(testRun.id, {
      select: 'status',
      ended: testRun.ended,
    }),
    queryFn: () =>
      ds
        .fetchWS({ id: testRun.id, select: 'status', count: false })
        .then((res) => res.value ?? []),
  })
}
