import { createGlobalStyle } from 'styled-components'
import { spacing } from '../utils/styled'

export const GlobalStyles = createGlobalStyle`
  .page-scrollbar-content > div:first-child {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
  }

  // Full-width layout doesn't really work for k6.
  [class$="-page-inner"] {
    display: grid;
    grid-template-rows: max-content max-content [page-content] auto;
    grid-template-columns: minmax(0, 1820px);
    justify-content: center;

    [class$="-page-content"] {
      grid-row: page-content;
    }
  }

  // Fixed in future Grafana UI release
  .rc-drawer [class$='-drawer-content'] {
    min-height: 0;
  }

  button {
    background: unset;
    border: unset;
    margin: unset;
    padding: unset;

    &:focus-visible {
      outline: transparent dotted 2px;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.text.link};

    &:hover:not([class*="button"]) {
      text-decoration: underline;
    }
  }

  // Font awesome icons in Menu.Item should have the same width as @grafana/ui icons
  [role=menuitem] .fa {
    width: 16px;
  }

  // Tags are too close to borders when the break in two rows. Reported to the design system team
  .multiple-select {
    > div {
      padding: ${spacing(0.5, 0, 0.5, 1)};
    }
  }

  // Needed for the "waiting for the initial data" panel overlay
  [class$='-panel-content'] {
    contain: none;
  }

`
