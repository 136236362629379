import React, { useEffect } from 'react'
import styled from 'styled-components'

import { SelectableValue } from '@grafana/data'
import { IconButton, InlineFormLabel, Select } from '@grafana/ui'

import { TestRun, TestRunFilterWithOptions } from 'types'
import { useCustomFilterOptions } from './CustomFilter.hooks'

export function CustomFilter<T extends TestRunFilterWithOptions>({
  run,
  filter,
  onRemoveButtonClick,
  onAddFilterValue,
  precedingFilters,
}: {
  run: TestRun
  filter: T
  onRemoveButtonClick: (by: T['by']) => void
  onAddFilterValue: (value: string[]) => void
  allowCustomValue?: boolean
  precedingFilters: TestRunFilterWithOptions[]
}) {
  const { options, isLoading } = useCustomFilterOptions(
    filter,
    run,
    precedingFilters
  )

  // Preselect first option for required filters
  useEffect(() => {
    if (!filter.required || !options[0]) {
      return
    }

    if (
      filter.values.length !== 0 &&
      options.some((opt) => filter.values.includes(opt.value))
    ) {
      return
    }

    onAddFilterValue([options[0].value])
  }, [options, filter.required, filter.values, onAddFilterValue])

  const handleOnCreateOption = (value: string) => {
    const sanitizedValue = value.replace(/["']/g, '')
    onAddFilterValue([...filter.values, sanitizedValue])
  }

  const handleOnChange = (
    value: Array<SelectableValue<string>> | SelectableValue<string> | null
  ) => {
    if (value === null) {
      // Value is null when clearing select
      return onAddFilterValue([])
    }

    // Need to support both multiple and single selection
    const valueArray = Array.isArray(value) ? value : [value]
    const values = valueArray.map((value) => value.value ?? '')
    onAddFilterValue(values)
  }

  const removeFilterTooltip = `Remove ${filter.label} filter`

  return (
    <CustomFilterContainer data-testid={filter.by}>
      <InlineFormLabel width="auto" className="query-keyword">
        {filter.label}
      </InlineFormLabel>

      <Select
        autoFocus={filter.values.length === 0 && !filter.required}
        openMenuOnFocus
        options={options}
        value={filter.multiSelect ? filter.values : filter.values[0]}
        isLoading={isLoading}
        allowCustomValue={filter.allowCustomValue}
        loadingMessage=""
        onCreateOption={handleOnCreateOption}
        onChange={handleOnChange}
        isMulti={filter.multiSelect}
        width="auto"
        menuPlacement="auto"
        isClearable={!filter.required}
        tabSelectsValue={false}
        placeholder={isLoading ? 'Loading...' : 'Choose'}
      />

      {!filter.required && (
        <IconButton
          aria-label={removeFilterTooltip}
          name="times"
          onClick={() => onRemoveButtonClick(filter.by)}
          tooltip={removeFilterTooltip}
        />
      )}
    </CustomFilterContainer>
  )
}

const CustomFilterContainer = styled.div`
  display: flex;
  align-items: center;

  > div {
    min-width: 200px;
  }

  > button {
    margin-left: ${({ theme }) => theme.spacing(1)};

    svg {
      color: ${({ theme }) => theme.colors.text.secondary};
    }
  }
`
