// https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/letterSpacing#browser_compatibility
interface CanvasRenderingContext2DExperimental
  extends CanvasRenderingContext2D {
  letterSpacing: string
}

export function measureText(text: string, node: HTMLElement) {
  const { fontFamily, fontSize, letterSpacing } = window.getComputedStyle(node)
  const canvas = document.createElement('canvas')
  const context = canvas.getContext(
    `2d`
  ) as CanvasRenderingContext2DExperimental
  context.font = `${fontSize} ${fontFamily}`
  context.letterSpacing = letterSpacing

  return context.measureText(text)
}
