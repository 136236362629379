import React, { useCallback, useState } from 'react'
import { Field, Input } from '@grafana/ui'
import { Controller, useFormContext } from 'react-hook-form'
import { HorizontalGroup } from 'components/TrendingMetricsModal/TrendingMetricsEditor/TrendingMetricsEditor.styles'
import { Button } from 'components/Button'

import {
  toNotificationClientType,
  validation,
  validUrlField,
} from './NotificationForm.utils'

import {
  NotificationClient,
  NotificationChannelDraft,
  NotificationScriptError,
} from '../../NotificationsTab.types'

import { NotificationScriptEditor } from './NotificationScriptEditor'
import { NotificationFormHeader } from './NotificationFormHeader'
import { NotificationRecipientsSelect } from './NotificationRecipientsSelect/NotificationRecipientsSelect'
import { NotificationEventSelect } from './NotificationEventSelect'
import { FormButtonContainer } from './NotificationForm.styles'

interface NotificationFormProps {
  isLoading?: boolean
  isNewNotification?: boolean
  showScript?: boolean
  scriptError?: NotificationScriptError
  onTestNotification?(): void
  onSubmitForm(): void
  onDismissForm(): void
}

export const NotificationForm = ({
  isLoading,
  showScript,
  scriptError: scriptErrorOnMutation,
  isNewNotification,
  onTestNotification,
  onDismissForm,
  onSubmitForm,
}: NotificationFormProps) => {
  const form = useFormContext<NotificationChannelDraft>()

  const notificationDraft = form.getValues()

  const selectedClient = toNotificationClientType(notificationDraft)

  const [isAdvancedModeToggle, setIsAdvancedModeToggle] = useState(
    showScript || false
  )
  const isAdvancedMode =
    isAdvancedModeToggle || selectedClient === NotificationClient.WEBHOOK

  const [isValidatingScript, setIsValidatingScript] = useState(false)

  const canSubmit = !isLoading && !isValidatingScript && form.formState.isDirty

  const { errors } = form.formState

  const isEmailNotificationDraft = selectedClient === NotificationClient.EMAIL

  const handleSubmitForm = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (canSubmit) {
        onSubmitForm()
      }
    },
    [canSubmit, onSubmitForm]
  )

  return (
    <form onSubmit={handleSubmitForm}>
      <NotificationFormHeader
        title={notificationDraft.meta_data.name}
        clientType={selectedClient}
        isFormDirty={form.formState.isDirty}
        isAdvancedToggled={isAdvancedMode}
        isNewNotification={isNewNotification}
        onToggleAdvanced={setIsAdvancedModeToggle}
        onTestNotification={onTestNotification}
      />
      <HorizontalGroup>
        <Field
          htmlFor="meta_data.name"
          error={errors?.meta_data?.name?.message}
          invalid={!!errors?.meta_data?.name}
          label="Notification name"
        >
          <Input
            {...form.register('meta_data.name', validation.name)}
            placeholder="Enter notification name"
            autoComplete="off"
          />
        </Field>
        {isEmailNotificationDraft && (
          <Field
            htmlFor="meta_data.email_subject"
            error={errors?.meta_data?.email_subject?.message}
            invalid={!!errors?.meta_data?.email_subject}
            label="Subject"
          >
            <Input
              {...form.register('meta_data.email_subject', validation.subject)}
              placeholder="Enter a subject"
              autoComplete="off"
            />
          </Field>
        )}
      </HorizontalGroup>
      <HorizontalGroup>
        <Field
          htmlFor="url"
          error={errors?.url?.message}
          invalid={!!errors?.url}
          label={isEmailNotificationDraft ? 'Recipients' : 'URL'}
        >
          {isEmailNotificationDraft ? (
            <Controller
              name="url"
              control={form.control}
              rules={validation.emails}
              render={({ field }) => (
                <NotificationRecipientsSelect
                  invalid={!!errors?.url}
                  value={field.value}
                  onChange={(values) => field.onChange(values.join(','))}
                />
              )}
            />
          ) : (
            <Input
              {...form.register('url', validUrlField)}
              placeholder="Enter URL"
              autoComplete="off"
            />
          )}
        </Field>
        <Field
          htmlFor="events"
          error={errors?.events?.message}
          invalid={!!errors?.events}
          label="Notification events"
        >
          <Controller
            name="events"
            control={form.control}
            rules={validation.events}
            render={({ field }) => (
              <NotificationEventSelect
                value={field.value}
                onChange={field.onChange}
                invalid={!!errors?.events}
              />
            )}
          />
        </Field>
      </HorizontalGroup>
      {isAdvancedMode && (
        <Field
          htmlFor="template"
          error={errors?.template?.message}
          invalid={!!errors?.template?.message}
        >
          <Controller
            name="template"
            control={form.control}
            render={() => (
              <NotificationScriptEditor
                scriptErrorOnMutation={scriptErrorOnMutation}
                handleIsValidatingScript={setIsValidatingScript}
              />
            )}
          />
        </Field>
      )}
      <FormButtonContainer>
        <Button variant="secondary" onClick={onDismissForm}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={!canSubmit}
          icon={isLoading || isValidatingScript ? 'fa fa-spinner' : undefined}
        >
          Save
        </Button>
      </FormButtonContainer>
    </form>
  )
}
