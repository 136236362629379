import React from 'react'
import { useTheme2 } from '@grafana/ui'

export const BertIllustration = () => {
  const { isDark } = useTheme2()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="371.258"
      height="367.103"
      viewBox="0 0 371.258 367.103"
    >
      <defs>
        <style>
          {`.cls-6{fill:#3c3c64}.cls-11,.cls-2,.cls-5,.cls-8{fill:none}.cls-2,.cls-5{stroke:#8764ff;stroke-width:2px}.cls-11,.cls-2,.cls-8{stroke-linejoin:round}.cls-3{fill:#fff}.cls-4{fill:#8764ff}.cls-5{stroke-miterlimit:10}.cls-11,.cls-8{stroke:#3c3c64;stroke-linecap:round}.cls-11{stroke-width:1.5px}`}
        </style>
      </defs>
      <g id="docs-dark" transform="translate(-1819.385 -193.304)">
        <g id="Path_2170" fill={isDark ? '#3c3c64' : '#fff'} stroke="none">
          <path
            d="M161.703 320.906c-10.803 0-21.6-1.088-32.088-3.234-10.22-2.092-20.274-5.213-29.88-9.276-9.433-3.99-18.531-8.928-27.042-14.678-8.43-5.696-16.359-12.237-23.563-19.442-7.205-7.204-13.746-15.132-19.442-23.563-5.75-8.51-10.688-17.609-14.678-27.042-4.063-9.607-7.184-19.66-9.276-29.88C3.588 183.302 2.5 172.506 2.5 161.703s1.088-21.6 3.234-32.088c2.092-10.22 5.213-20.274 9.276-29.88 3.99-9.433 8.928-18.531 14.678-27.042 5.696-8.43 12.237-16.359 19.442-23.563 7.204-7.205 15.132-13.746 23.563-19.442C81.203 23.938 90.302 19 99.735 15.01c9.606-4.063 19.66-7.184 29.88-9.276C140.104 3.588 150.9 2.5 161.703 2.5s21.6 1.088 32.088 3.234c10.22 2.092 20.273 5.213 29.88 9.276 9.433 3.99 18.531 8.928 27.042 14.678 8.43 5.696 16.359 12.237 23.563 19.442 7.205 7.204 13.746 15.132 19.442 23.563 5.75 8.51 10.688 17.609 14.678 27.042 4.063 9.606 7.184 19.66 9.276 29.88 2.146 10.489 3.234 21.285 3.234 32.088s-1.088 21.6-3.234 32.088c-2.092 10.22-5.213 20.273-9.276 29.88-3.99 9.433-8.928 18.531-14.678 27.042-5.696 8.43-12.237 16.359-19.442 23.563-7.204 7.205-15.132 13.746-23.563 19.442-8.51 5.75-17.609 10.688-27.042 14.678-9.607 4.063-19.66 7.184-29.88 9.276-10.489 2.146-21.285 3.234-32.088 3.234z"
            transform="translate(1836.475 213.234)"
          />
          <path
            fill="#3b3b64"
            d="M161.703 5c-10.635 0-21.263 1.071-31.586 3.184-10.06 2.058-19.954 5.13-29.408 9.128-9.285 3.927-18.24 8.788-26.617 14.447-8.298 5.607-16.102 12.046-23.195 19.138-7.092 7.093-13.531 14.897-19.138 23.195C26.1 82.47 21.24 91.424 17.312 100.71c-3.999 9.454-7.07 19.349-9.128 29.408C6.07 140.44 5 151.067 5 161.703c0 10.635 1.071 21.263 3.184 31.586 2.058 10.06 5.13 19.954 9.128 29.408 3.927 9.285 8.788 18.24 14.447 26.617 5.607 8.298 12.046 16.102 19.138 23.195 7.093 7.092 14.897 13.531 23.195 19.138 8.377 5.659 17.332 10.52 26.617 14.447 9.454 3.999 19.349 7.07 29.408 9.128 10.323 2.113 20.95 3.184 31.586 3.184 10.635 0 21.263-1.071 31.586-3.184 10.06-2.058 19.954-5.13 29.408-9.128 9.285-3.927 18.24-8.788 26.617-14.447 8.298-5.607 16.102-12.046 23.195-19.138 7.092-7.093 13.531-14.897 19.138-23.195 5.659-8.377 10.52-17.332 14.447-26.617 3.999-9.454 7.07-19.349 9.128-29.408 2.113-10.323 3.184-20.95 3.184-31.586 0-10.635-1.071-21.263-3.184-31.586-2.058-10.06-5.13-19.954-9.128-29.408-3.927-9.285-8.788-18.24-14.447-26.617-5.607-8.298-12.046-16.102-19.138-23.195-7.093-7.092-14.897-13.531-23.195-19.138-8.377-5.659-17.332-10.52-26.617-14.447-9.454-3.999-19.349-7.07-29.408-9.128C182.966 6.07 172.34 5 161.703 5m0-5c89.306 0 161.703 72.397 161.703 161.703s-72.397 161.703-161.703 161.703S0 251.009 0 161.703 72.397 0 161.703 0z"
            transform="translate(1836.475 213.234)"
          />
        </g>
        <path
          id="Path_575"
          d="M1.45 341.34s26.424 1.343 35.171 3l15.3 34.349"
          className="cls-2"
          transform="translate(1819.302 174.862)"
        />
        <path
          id="Path_576"
          d="M98.226 181.37l.832 14.186-9.032-.6-1.437-1.844-20.513-.747 1.362 2.951s-12.909-.946-18.13 3.32c0 0-16.285 16.276-26.386 57.4 0 0-21.213 33.431-23.52 71.459s27.8 42.208 36.155 43.04c0 0 40.855-2.591 58.011-6.308l21.307-4.615s20.73 4.312 38.623 4.577h16.892l40.089 9.344s7.566 5.438 13.836.946l17.106-5.393 4.8-1.514 3.447-1.087 10.366-3.268 13.023-4.106s7.935 3.934 15.926 2.2 12.938-10.4 12.938-10.4 9.164-4.048 12.351-16.815c0 0 7.32-5.1 7.31-12.521s-9.136-18.706-9.136-18.706l26.537-57.689-12.455 3.707 2.913-6.355s-30.812-3.026-53.036 8.086c0 0-11.822 7.925-14.11 17.326l-2.44-.69s-12.219-18.035-47.125-25.062l-16.33-20.648a288.639 288.639 0 0 1-38.368-6.157c-19.066-4.568-21.752-13.713-21.752-13.713z"
          className="cls-3"
          transform="translate(1819.311 183.545)"
        />
        <path
          id="Path_577"
          d="M125.891 189.733s-19.35-10.195-25.44-18.139c0 0-15.538-14.186-24.248-33.876 0 0-7.452-16.787-8.436-24.277 0 0-14.006-20.74-11.178-37.5 0 0 2.478-25.535 26.707-26.376l10.053 1.75s15.377-10.7 22.527-16.427 22.243-16.966 36.883-16.91 22.565 3.783 30.263 13.3 14.12 26.083 22.253 32.5c0 0 10.989-1.1 16.891 6.989 0 0 9.523-3.925 18.082-2.062 0 0 13.089 1.163 14.441 18.584 0 0-.113 9.911-2.837 16.077 0 0 18.593 17.2 27.331 33.394 0 0 23.066 4.729 40.912 15.917 0 0 18.281 10.933 24.211 12.171 0 0 3.575 1.258 8.786-1.021 0 0 11.453-5.845 16.616-4.568 0 0 3.49.681 3.944 3.991 0 0-.322 3.4-7.821 6.724 0 0-5.674.842-4.511 9.523 0 0 5.079 5.164 2.61 11.349 0 0-5.674 26.2-72.452 21.354 0 0-40.817 5.012-70.116 2.326 0 0-43.172-4.029-61.065-8.975z"
          className="cls-3"
          transform="translate(1816.329 192.413)"
        />
        <path
          id="Path_578"
          d="M37.492 225.661s23.5-5.6 51.674 3.424c0 0 8.01 16.947 13.751 32.552 0 0-12.654-16.947-15.548-18.848s-33.28-5.088-55.249-2.714z"
          className="cls-4"
          transform="translate(1817.637 181.234)"
        />
        <path
          id="Path_579"
          d="M114.84 247l11.954 14.11h47.286l4.729-7.055v9.892l-5.088 13.316-45.82 1.305s.18-6.828-8.426-8.994z"
          className="cls-4"
          transform="translate(1813.147 179.982)"
        />
        <path
          id="Path_580"
          d="M30.43 243.95c4.729-.4 9.334-.624 14.006-.757s9.344-.17 14.016 0 9.344.388 14 .946a95.525 95.525 0 0 1 13.883 2.535 1.049 1.049 0 0 1 .6.4l18.678 25.052-.747.586-19.726-24.27.6.4a92.817 92.817 0 0 0-13.543-2.251c-4.577-.463-9.192-.624-13.8-.709s-9.24.047-13.855.236-9.24.5-13.8.946z"
          className="cls-4"
          transform="translate(1817.729 180.195)"
        />
        <path
          id="Line_12"
          d="M0 1.532L45.622 0"
          className="cls-2"
          transform="translate(1939.44 461.653)"
        />
        <path
          id="Path_581"
          d="M194.139 241.192a12.644 12.644 0 0 1 6.563-3.546c3.84-.586 18.584-.407 18.584-.407l6.3 8.133s-12.531-2.232-20.248-1.74l-1.551 3.168H196.7l-2.932-1.163z"
          className="cls-4"
          transform="translate(1808.863 180.514)"
        />
        <path
          id="Path_582"
          d="M88.329 283.66s12.294 16.077 24.863 42.643l-18.536-5.05-10.176-19.161z"
          className="cls-4"
          transform="translate(1814.795 177.993)"
        />
        <path
          id="Path_583"
          d="M83.2 307.11s5.41 8.71 8.512 14.791"
          className="cls-5"
          transform="translate(1814.864 176.72)"
        />
        <path
          id="Path_584"
          d="M27 256.58s30.339 18.205 51.183 52.176l-3.849 7.935-7.4-.946s-23.725-31.134-45.524-47.192l4.634-7.311z"
          className="cls-4"
          transform="translate(1818.219 179.463)"
        />
        <path
          id="Path_585"
          d="M20 271.89s27.1 21.752 42.246 44.31"
          className="cls-5"
          transform="translate(1818.295 178.632)"
        />
        <path
          id="Path_586"
          d="M5.882 359.769s6.961-1.589 21.5 1.012c0 0 5.911 11.982 9.107 18.584h12.783S39.2 355.419 35.18 349.139a196.621 196.621 0 0 0-33.45-3.849s1.428 10.034 4.152 14.479z"
          className="cls-4"
          transform="translate(1819.286 174.648)"
        />
        <path
          id="Path_587"
          d="M283.923 310.682l14.252-18.461a.946.946 0 0 1 1.437-.076l3.244 3.4a.946.946 0 0 0 1.409-.047l13.883-16.522a.946.946 0 0 1 1.646.435l3.972 21.175a.946.946 0 0 1-.681 1.088l-38.169 10.4a.946.946 0 0 1-.993-1.4z"
          className="cls-4"
          transform="translate(1803.977 178.265)"
        />
        <path
          id="Path_588"
          d="M124.066 194.031a72.162 72.162 0 0 1-20.806-12.6 102.218 102.218 0 0 1-8.852-8.445 104.991 104.991 0 0 1-7.849-9.41 103.288 103.288 0 0 1-6.759-10.221 109.652 109.652 0 0 1-5.608-10.895c-.794-1.892-1.636-3.783-2.336-5.674-.359-.946-.728-1.891-1.059-2.837l-.946-2.913c-.671-1.891-1.163-3.906-1.74-5.854s-.946-3.963-1.409-5.939a1.7 1.7 0 0 1 3.282-.88v.095c.558 1.891 1.088 3.783 1.655 5.674s1.163 3.783 1.891 5.674l1.021 2.743 1.116 2.743c.7 1.844 1.542 3.632 2.336 5.438 1.693 3.556 3.442 7.093 5.476 10.479.946 1.712 2.043 3.367 3.083 5.041s2.175 3.282 3.367 4.861a119.421 119.421 0 0 0 15.69 17.666A137.75 137.75 0 0 0 124.3 193.53a.265.265 0 0 1 .085.378.274.274 0 0 1-.319.123z"
          className="cls-6"
          transform="translate(1815.762 187.027)"
        />
        <path
          id="Path_589"
          d="M83.517 129.663a50.048 50.048 0 0 1-14.441-10.781 54.492 54.492 0 0 1-10.1-15.132 56.62 56.62 0 0 1-4.634-17.713 39.011 39.011 0 0 1 2.09-18.508 29.535 29.535 0 0 1 12.153-14.47A27.766 27.766 0 0 1 87.1 49.56a29.573 29.573 0 0 1 8.937 3 31.067 31.067 0 0 1 7.463 5.54 38.944 38.944 0 0 1 3.064 3.433 40.246 40.246 0 0 1 2.563 3.783 45.194 45.194 0 0 1 3.859 8.152.558.558 0 0 1-.322.719.577.577 0 0 1-.69-.255 54.14 54.14 0 0 0-4.606-7.4c-.823-1.192-1.816-2.232-2.733-3.329a39.37 39.37 0 0 0-3.1-2.932 27.767 27.767 0 0 0-15-6.771 24.145 24.145 0 0 0-15.586 3.461A25.251 25.251 0 0 0 60.81 69.259a29.316 29.316 0 0 0-1.948 7.963 45.4 45.4 0 0 0 0 8.417 57.216 57.216 0 0 0 3.65 16.6 57.292 57.292 0 0 0 21.469 26.431.558.558 0 0 1-.539.946z"
          className="cls-6"
          transform="translate(1816.448 190.718)"
        />
        <path
          id="Path_590"
          d="M231.631 71.64a20.61 20.61 0 0 1 3.6-.6c1.211-.151 2.412-.35 3.622-.6a33.276 33.276 0 0 1 7.9-.709 21.854 21.854 0 0 1 7.868 1.721 18.912 18.912 0 0 1 3.556 2.081 17.328 17.328 0 0 1 2.913 2.913 19.322 19.322 0 0 1 3.4 7.3 30.04 30.04 0 0 1 .757 7.821 48.6 48.6 0 0 1-.889 7.7 76.573 76.573 0 0 1-1.892 7.433.785.785 0 0 1-.946.52.8.8 0 0 1-.539-.946c.634-2.421 1.192-4.852 1.589-7.31a46.338 46.338 0 0 0 .568-7.386 28.059 28.059 0 0 0-.955-7.225 16.777 16.777 0 0 0-3.14-6.261 14.772 14.772 0 0 0-2.5-2.364 15.186 15.186 0 0 0-3.017-1.683 18.914 18.914 0 0 0-6.875-1.333 30.149 30.149 0 0 0-7.15.8c-1.267.293-2.553.53-3.849.719a11.047 11.047 0 0 1-3.944 0 1.362 1.362 0 0 1-.114-2.648z"
          className="cls-6"
          transform="translate(1806.864 189.604)"
        />
        <path
          id="Path_591"
          d="M87.064 47.7l11.188-.463-.946.2.322-.161.416-.227.88-.52c.586-.359 1.182-.738 1.769-1.125 1.173-.785 2.336-1.617 3.49-2.459 2.308-1.693 4.587-3.461 6.838-5.277s4.445-3.669 6.705-5.561a88.077 88.077 0 0 1 7.112-5.372 62.333 62.333 0 0 1 16.077-7.954 49.235 49.235 0 0 1 17.77-2.279 53.15 53.15 0 0 1 8.88 1.267 26.064 26.064 0 0 1 8.37 3.679A49.157 49.157 0 0 1 188.37 34.19c3.414 4.785 6.232 9.921 9.117 14.933a65.579 65.579 0 0 0 4.558 7.263 63.025 63.025 0 0 0 5.627 6.459 1.127 1.127 0 0 1-1.551 1.636 66.45 66.45 0 0 1-5.769-6.752 79.272 79.272 0 0 1-4.729-7.566A154.268 154.268 0 0 0 186.5 35.42c-3.376-4.625-7.225-8.975-11.973-11.944a23.2 23.2 0 0 0-7.566-3.168 51.163 51.163 0 0 0-8.417-1.021 46.152 46.152 0 0 0-16.626 2.487 58.636 58.636 0 0 0-14.99 7.85 83.228 83.228 0 0 0-6.715 5.287c-2.147 1.891-4.36 3.877-6.62 5.769s-4.492 3.783-6.819 5.608c-1.173.946-2.355 1.8-3.584 2.667q-.946.653-1.891 1.277l-.946.624-.53.312c-.2.1-.331.189-.653.34h-.076a2.128 2.128 0 0 1-.823.161l-11.16-.3a1.891 1.891 0 0 1 0-3.783z"
          className="cls-6"
          transform="translate(1814.755 192.498)"
        />
        <path
          id="Path_592"
          d="M217.6 77.609A104.74 104.74 0 0 1 238.752 87.9a151.374 151.374 0 0 1 19.009 13.978c1.485 1.286 2.922 2.639 4.379 3.963l2.166 2.014 2.071 2.118 4.123 4.227 3.906 4.445 1.939 2.232c.624.757 1.211 1.551 1.825 2.326l3.632 4.672c.293.388.605.766.9 1.173l.832 1.22 1.664 2.44 3.31 4.889a1.809 1.809 0 1 1-2.96 2.081l-.076-.1c-2.26-3.13-4.379-6.374-6.7-9.457l-3.528-4.6c-.6-.757-1.163-1.542-1.778-2.289l-1.892-2.194-3.783-4.426-3.953-4.227-1.977-2.109-2-2.033c-1.39-1.333-2.743-2.724-4.161-4.029a165.725 165.725 0 0 0-18.14-14.443l-2.4-1.636-2.46-1.541c-1.627-1.05-3.32-2-4.993-2.941a87.746 87.746 0 0 0-10.4-5.135.473.473 0 0 1-.265-.615.482.482 0 0 1 .558-.294z"
          className="cls-6"
          transform="translate(1807.603 189.177)"
        />
        <path
          id="Path_593"
          d="M272.357 142.389a47.584 47.584 0 0 1 9.249-.7 73.636 73.636 0 0 1 9.23.681 98 98 0 0 1 17.969 4.284 123.093 123.093 0 0 1 16.957 7.386c2.714 1.466 5.41 2.96 8.02 4.606s5.1 3.461 7.642 4.946 5.23 2.951 7.925 4.208a33.374 33.374 0 0 0 8.323 2.771 9.041 9.041 0 0 0 4.01-.1 40.27 40.27 0 0 0 4.19-1.551 90.226 90.226 0 0 1 8.634-3.329 16.131 16.131 0 0 1 4.729-.747 7.888 7.888 0 0 1 4.729 1.513 5.818 5.818 0 0 1 1.749 1.967 3.377 3.377 0 0 1 0 2.837 9.361 9.361 0 0 1-3.376 3.6 34.036 34.036 0 0 1-4.057 2.336c-.671.34-1.437.681-2.052.946a4.418 4.418 0 0 0-1.494 1.239 4.509 4.509 0 0 0-.861 1.731 6.476 6.476 0 0 0-.095 2.043l.18 4.568a1 1 0 0 1-2 .085v-.057l.066-4.577a8.726 8.726 0 0 1 .255-2.5 6.288 6.288 0 0 1 1.211-2.308 6.024 6.024 0 0 1 2.043-1.608c.766-.35 1.4-.615 2.1-.946a28.262 28.262 0 0 0 3.868-2.213 7.973 7.973 0 0 0 2.837-2.951 1.826 1.826 0 0 0 0-1.6 4.123 4.123 0 0 0-1.315-1.381 6.308 6.308 0 0 0-3.858-1.107 14.8 14.8 0 0 0-4.227.785 71.674 71.674 0 0 0-8.313 3.546c-1.4.643-2.837 1.277-4.322 1.806a11.4 11.4 0 0 1-5.041.388 35.655 35.655 0 0 1-9.107-2.657 72.832 72.832 0 0 1-8.341-4.152c-.671-.388-1.333-.775-2.005-1.2l-1.891-1.277c-1.258-.861-2.534-1.664-3.783-2.459-2.553-1.617-5.154-3.14-7.793-4.606a120.808 120.808 0 0 0-16.446-7.471 98.361 98.361 0 0 0-17.411-4.814 67.547 67.547 0 0 0-17.969-1.059.478.478 0 1 1-.142-.946z"
          className="cls-6"
          transform="translate(1804.619 185.699)"
        />
        <path
          id="Path_594"
          d="M148.848 204.981a49.759 49.759 0 0 0 7.972 4.625 71.6 71.6 0 0 0 8.511 3.4 80.265 80.265 0 0 0 8.947 2.27c1.513.246 3.017.558 4.54.728l4.615.53c6.317.672 12.521 1.646 18.725 2.459s12.4 1.579 18.621 2.071a340.33 340.33 0 0 0 37.4.946c12.483-.331 24.948-1.239 37.4-2.393h.246c13.24 0 26.594-.558 39.815-1.551a42.011 42.011 0 0 0 17.969-5.674 40.92 40.92 0 0 0 7.566-5.731 28.275 28.275 0 0 0 5.618-7.329 10.637 10.637 0 0 0 .757-1.939 3.66 3.66 0 0 0 .151-1.617 5.673 5.673 0 0 0-1.701-3.165c-1.891-1.891-4.842-2.9-7.878-3.329a39.35 39.35 0 0 0-9.457 0 82.991 82.991 0 0 0-9.637 1.712 156.263 156.263 0 0 0-18.914 5.949c-6.242 2.3-12.351 4.956-18.47 7.632a.4.4 0 0 1-.53-.208.407.407 0 0 1 .17-.52l4.4-2.421c1.475-.794 2.979-1.542 4.464-2.308 2.989-1.513 6.005-2.988 9.07-4.369s6.185-2.648 9.334-3.859a99.059 99.059 0 0 1 9.647-3.149 75.383 75.383 0 0 1 10.015-2.137 41.61 41.61 0 0 1 10.479-.255 23.507 23.507 0 0 1 5.334 1.267 14.185 14.185 0 0 1 4.937 3.055 9.787 9.787 0 0 1 2.979 5.485 7.1 7.1 0 0 1 .076 1.74 9.826 9.826 0 0 1-.284 1.6 14.287 14.287 0 0 1-1.04 2.724 32.343 32.343 0 0 1-6.421 8.578 44.922 44.922 0 0 1-8.322 6.421 46.549 46.549 0 0 1-19.86 6.459c-6.705.577-13.391.946-20.1 1.239s-13.392.416-20.144.4h.246c-12.55 1.2-25.147 1.977-37.772 2.175a341.712 341.712 0 0 1-37.829-1.362c-6.3-.567-12.559-1.381-18.791-2.279s-12.427-1.891-18.593-2.639c-1.523-.161-3.149-.407-4.729-.624s-3.159-.586-4.729-.879a69.515 69.515 0 0 1-9.211-2.837 49.858 49.858 0 0 1-16.446-10 .612.612 0 0 1 .776-.946z"
          className="cls-6"
          transform="translate(1811.36 183.341)"
        />
        <path
          id="Path_595"
          d="M94.288 84.7a41.718 41.718 0 0 0 1.891 7.641 68.39 68.39 0 0 0 2.979 7.405 98.353 98.353 0 0 0 8 13.864 139.712 139.712 0 0 0 21.477 23.851c4 3.575 8.171 6.979 12.493 10.157 1.05.832 2.175 1.57 3.282 2.317l3.329 2.26 3.433 2.081 1.721 1.031c.577.34 1.182.634 1.769.946a70.115 70.115 0 0 0 30.386 8.984c2.667.1 5.334.095 8.039 0 1.343 0 2.676-.066 4.086 0s2.762.123 4.123.284a61.993 61.993 0 0 1 15.917 3.991 65.644 65.644 0 0 1 7.443 3.471c2.383 1.315 4.729 2.667 7.046 4.086 4.644 2.837 9.117 5.845 13.789 8.389a32.59 32.59 0 0 0 7.263 2.988 21 21 0 0 0 7.7.416c5.258-.567 10.583-1.891 16.077-2.222a45.459 45.459 0 0 1 8.256.18c1.381.123 2.733.407 4.085.643l2.014.473c.671.151 1.333.34 2 .558l2 .615 1.891.728c1.248.463 2.516.946 3.783 1.324a67.172 67.172 0 0 0 7.689 2.109.785.785 0 0 1 .615.946.794.794 0 0 1-.879.624 54.324 54.324 0 0 1-8-1.891c-1.305-.416-2.591-.851-3.877-1.315l-1.891-.709-1.891-.577a18.919 18.919 0 0 0-1.891-.53l-1.892-.463c-1.3-.218-2.6-.492-3.915-.605a43.371 43.371 0 0 0-7.916-.18c-5.3.312-10.526 1.617-16 2.213a29.19 29.19 0 0 1-4.19.161c-.709 0-1.409-.142-2.109-.208a19.758 19.758 0 0 1-2.081-.416 34.784 34.784 0 0 1-7.689-3.159c-4.814-2.629-9.278-5.674-13.883-8.445q-3.452-2.1-6.97-4.038a64.309 64.309 0 0 0-7.225-3.376 60.355 60.355 0 0 0-15.415-3.877c-1.315-.142-2.639-.236-3.953-.265s-2.657 0-4.01 0c-2.7.066-5.438.076-8.162 0a65.992 65.992 0 0 1-16.153-2.837l-1.958-.6c-.653-.208-1.286-.473-1.891-.709l-1.891-.728-.946-.359-.946-.426-3.736-1.683-3.622-1.891c-.6-.322-1.211-.624-1.8-.946l-1.75-1.059-3.5-2.109-3.376-2.289c-1.125-.766-2.26-1.513-3.329-2.355q-6.563-4.833-12.635-10.28a141.9 141.9 0 0 1-21.761-24.189 101.043 101.043 0 0 1-8.19-14.139 70.627 70.627 0 0 1-3.055-7.613 43.329 43.329 0 0 1-1.967-8.067.954.954 0 1 1 1.891-.246z"
          className="cls-6"
          transform="translate(1814.359 188.84)"
        />
        <path
          id="Path_596"
          d="M311.176 196.9s8.6 2.2 15.264.378a78.92 78.92 0 0 1-9.5 4.265l-7.566-3.263z"
          className="cls-6"
          transform="translate(1802.589 182.702)"
        />
        <path
          id="Path_597"
          d="M94.264 88.926a9.59 9.59 0 0 1 3.972-4.826c3.282-2.251 3.868-5.589 2.837-7.121s-2.743-1.683-4.1.057c-1.192 1.523-4.331 5.457-4.568 7.15z"
          className="cls-6"
          transform="translate(1814.365 189.275)"
        />
        <path
          id="Path_598"
          d="M337.39 172.147s4.256-15.132 14.583-18.366 17.733 1.816 15.292 6.147-11.907 13.24-24.589 18.044"
          className="cls-3"
          transform="translate(1801.068 185.098)"
        />
        <path
          id="Path_599"
          d="M336.908 171.946a33.372 33.372 0 0 1 1.466-4.568 36.316 36.316 0 0 1 2.014-4.36 25.175 25.175 0 0 1 6.091-7.566 14.968 14.968 0 0 1 2.071-1.437c.369-.208.728-.435 1.106-.624l1.182-.482a21.013 21.013 0 0 1 4.823-1.229 20.192 20.192 0 0 1 5.012-.047 13.488 13.488 0 0 1 4.918 1.636 7.948 7.948 0 0 1 2.118 1.769 5 5 0 0 1 1.163 2.837v.407a1.146 1.146 0 0 1-.047.4c-.047.132-.076.539-.142.794a7.634 7.634 0 0 1-.634 1.334 16.515 16.515 0 0 1-1.485 2.033 42.946 42.946 0 0 1-7.036 6.62 57.489 57.489 0 0 1-16.758 8.975.463.463 0 0 1-.6-.3.482.482 0 0 1 .265-.586 66.679 66.679 0 0 0 15.822-9.675c1.182-.993 2.346-2.014 3.424-3.1a35.29 35.29 0 0 0 3.064-3.367 15.462 15.462 0 0 0 1.258-1.8l.133-.227a.959.959 0 0 0 .076-.189c.047-.132.1-.246.161-.369l.047-.369a1.313 1.313 0 0 0 0-.189v-.18a3.783 3.783 0 0 0-2.128-2.657 10.942 10.942 0 0 0-3.982-1.277 18.84 18.84 0 0 0-4.379 0 21.153 21.153 0 0 0-4.3 1.031l-1.012.378c-.322.151-.634.35-.946.511a12.654 12.654 0 0 0-1.825 1.2A24.885 24.885 0 0 0 341.9 164a44.446 44.446 0 0 0-4.114 8.275.473.473 0 0 1-.615.274.463.463 0 0 1-.265-.605z"
          className="cls-6"
          transform="translate(1801.097 185.166)"
        />
        <path
          id="Path_600"
          fill="none"
          stroke="#3c3c64"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.8px"
          d="M344 172.829s5.2-4.492 9.807-7.689 5.2-4.842 3.783-5.731-9.39 1.031-10.63 10.791"
          transform="translate(1800.71 184.747)"
        />
        <path
          id="Path_601"
          d="M290.48 192.67s7.736-9.523 11.462-13.24c0 0 1.05 3.093.946 15.027"
          className="cls-8"
          transform="translate(1803.614 183.65)"
        />
        <path
          id="Path_602"
          d="M219.9 173.92s-1.7 12.058-1.7 19c0 0 14.3-9.533 17.751-11.349"
          className="cls-8"
          transform="translate(1807.537 183.949)"
        />
        <path
          id="Path_603"
          d="M277.62 193.852c.085-1.712.218-3.4.388-5.1a50.576 50.576 0 0 1 .747-5.041.151.151 0 0 1 .293.047 49.38 49.38 0 0 0 .047 5.022c.095 1.674.236 3.357.416 5a.947.947 0 1 1-1.882.208.814.814 0 0 1-.009-.136z"
          className="cls-6"
          transform="translate(1804.313 183.423)"
        />
        <path
          id="Path_604"
          d="M248.258 188.173c.823-1.5 1.683-2.97 2.572-4.417s1.844-2.837 2.837-4.2a.137.137 0 0 1 .208.18 47.587 47.587 0 0 0-2.156 4.549c-.643 1.542-1.258 3.111-1.816 4.672a.95.95 0 1 1-1.787-.643.8.8 0 0 1 .142-.141z"
          className="cls-6"
          transform="translate(1805.917 183.646)"
        />
        <path
          id="Path_605"
          d="M175 167.566a19.444 19.444 0 0 1 .946-2.336c.369-.766.7-1.485 1.088-2.2a24.172 24.172 0 0 1 2.7-4.029.132.132 0 0 1 .2 0 .161.161 0 0 1 0 .189 22.819 22.819 0 0 0-1.948 4.331c-.255.747-.482 1.5-.681 2.26a22.711 22.711 0 0 0-.473 2.279.957.957 0 1 1-1.891-.293.889.889 0 0 1 .059-.201z"
          className="cls-6"
          transform="translate(1809.887 184.761)"
        />
        <path
          id="Path_606"
          d="M138.414 147.589c.407-.832.832-1.6 1.286-2.364s.946-1.513 1.419-2.251a25.48 25.48 0 0 1 3.4-4.067.132.132 0 0 1 .2 0 .142.142 0 0 1 0 .2 25.234 25.234 0 0 0-2.658 4.417c-.369.775-.709 1.56-1.031 2.355s-.615 1.617-.861 2.4a.951.951 0 0 1-1.816-.567z"
          className="cls-6"
          transform="translate(1811.874 185.852)"
        />
        <path
          id="Path_607"
          d="M114.559 125.168c.388-.69.794-1.315 1.211-1.948s.851-1.248 1.3-1.891a38.873 38.873 0 0 1 2.837-3.452.145.145 0 1 1 .227.18 38.521 38.521 0 0 0-2.166 3.849c-.331.653-.634 1.315-.946 1.986s-.558 1.362-.794 2.014a.953.953 0 1 1-1.8-.634z"
          className="cls-6"
          transform="translate(1813.173 186.994)"
        />
        <path
          id="Path_608"
          d="M267.659 196.451c-.17 1.712-.388 3.386-.643 5.069a49.686 49.686 0 0 1-1.016 4.993.145.145 0 0 1-.284-.057 49.3 49.3 0 0 0 .208-5.022c0-1.674-.066-3.357-.161-5.012a.947.947 0 1 1 1.891-.113z"
          className="cls-6"
          transform="translate(1804.958 182.782)"
        />
        <path
          id="Path_609"
          d="M202.56 171.647q-1.319 2.1-2.544 4.322a48.663 48.663 0 0 0-2.222 4.511.145.145 0 0 1-.274-.095 48.9 48.9 0 0 1 1.5-4.861c.577-1.608 1.192-3.178 1.891-4.729a.949.949 0 1 1 1.75.738.512.512 0 0 1-.066.123z"
          className="cls-6"
          transform="translate(1808.66 184.15)"
        />
        <path
          id="Path_610"
          d="M158.631 162.606c-1.1 1.248-2.185 2.544-3.225 3.849a46.49 46.49 0 0 0-2.932 4.085.151.151 0 0 1-.2 0 .17.17 0 0 1-.066-.189c.671-1.56 1.466-3.064 2.289-4.549s1.7-2.932 2.629-4.388a.948.948 0 1 1 1.6 1.021l-.085.113z"
          className="cls-6"
          transform="translate(1811.119 184.651)"
        />
        <path
          id="Path_611"
          d="M129.078 141.189c-.463.359-.946.8-1.419 1.229a16.681 16.681 0 0 0-1.352 1.362 25.849 25.849 0 0 0-2.4 3.036.152.152 0 0 1-.2 0 .161.161 0 0 1-.057-.18 25.01 25.01 0 0 1 1.787-3.556c.34-.567.709-1.135 1.1-1.683s.775-1.078 1.239-1.627a.953.953 0 0 1 1.453 1.23.942.942 0 0 1-.142.132z"
          className="cls-6"
          transform="translate(1812.67 185.821)"
        />
        <path
          id="Path_612"
          d="M231.92 81.861l5.466-13.477c.946-2.241 1.806-4.5 2.743-6.734l.359-.832.208-.492c.085-.18.151-.359.255-.549a7.906 7.906 0 0 1 1.438-2 7.32 7.32 0 0 1 4.549-2.024 7.121 7.121 0 0 1 2.5.284 7.494 7.494 0 0 1 1.144.426l.842.407 3.291 1.551c2.185 1.05 4.4 2.043 6.62 3.045 4.407 2.033 8.871 3.944 13.363 5.788l6.734 2.771 3.357 1.409.842.359.416.18h.1l.218.114a4.514 4.514 0 0 1 .426.236 5.5 5.5 0 0 1 2.185 6.421l-.6 1.825-1.248 3.414-2.563 6.819-5.031 13.656c-.861 2.27-1.693 4.539-2.6 6.79l-1.362 3.367c-.473 1.125-.766 2.308-2.147 3.083a2.393 2.393 0 0 1-3.263-.946 2.847 2.847 0 0 1-.18-.388c-.52-1.485 0-2.582.407-3.783l1.192-3.433c.794-2.3 1.655-4.558 2.5-6.838l5.192-13.543 2.535-6.819 1.305-3.4.662-1.674h-.019l-.407-.208-.823-.407-3.253-1.655-6.488-3.3c-4.331-2.2-8.682-4.331-13.1-6.355-2.2-1.021-4.4-2.043-6.62-3.017l-3.329-1.485-.813-.35a3.327 3.327 0 0 0-.567-.189 3.112 3.112 0 0 0-1.1-.095 3.216 3.216 0 0 0-1.892.946 3.784 3.784 0 0 0-.653.946c-.047.085-.076.2-.123.284l-.057.151-.076.208-.322.851c-.8 2.241-1.75 4.521-2.6 6.79l-5.249 13.59a2.346 2.346 0 1 1-4.379-1.683z"
          className="cls-6"
          transform="translate(1806.802 190.363)"
        />
        <path
          id="Path_613"
          fill="none"
          stroke="#3c3c64"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.6px"
          d="M194.48 78.816s14.186-8.256 26.007-5.1a14.1 14.1 0 0 1 9.221 8.152"
          transform="translate(1808.825 186.939)"
        />
        <path
          id="Path_614"
          fill="none"
          stroke="#3c3c64"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.5px"
          d="M196.62 73.012s11.509-9.58 25.79-5.315c6.752 2 9.306 8.029 10.129 13.827"
          transform="translate(1808.709 189.774)"
        />
        <path
          id="Path_615"
          d="M257.411 82.666a11.839 11.839 0 0 1 1.513 4.426 19.624 19.624 0 0 1 .066 4.729 26.994 26.994 0 0 1-.946 4.653c-.227.747-.492 1.494-.738 2.251l-.889 2.194a1.305 1.305 0 0 1-2.417-.946 1.03 1.03 0 0 1 .066-.142v-.076l1.088-1.891c.312-.662.643-1.324.946-2a24.271 24.271 0 0 0 1.39-4.2 18.168 18.168 0 0 0 .5-4.407 11.176 11.176 0 0 0-.946-4.341.227.227 0 0 1 .369-.255z"
          className="cls-6"
          transform="translate(1805.598 188.906)"
        />
        <path
          id="Path_616"
          d="M248.809 95.583a11.615 11.615 0 0 0 1.352-1.891 14.893 14.893 0 0 0 1.078-2.166 13.932 13.932 0 0 0 .719-2.336 14.319 14.319 0 0 0 .3-2.468.18.18 0 0 1 .35 0 14.742 14.742 0 0 1 .2 2.591 13.68 13.68 0 0 1-.265 2.62 15.435 15.435 0 0 1-.691 2.572 13.873 13.873 0 0 1-1.163 2.516 1.192 1.192 0 1 1-2.043-1.22.94.94 0 0 1 .142-.189z"
          className="cls-6"
          transform="translate(1805.896 188.689)"
        />
        <path
          id="Path_617"
          d="M256.116 72.514a9.465 9.465 0 0 1 .884-1.683 10.121 10.121 0 0 1 1.135-1.485 9.454 9.454 0 0 1 1.39-1.286 5.475 5.475 0 0 1 1.73-.851.794.794 0 0 1 .946.539.8.8 0 0 1-.18.757h-.047l-1.04 1.154c-.34.388-.69.757-1 1.173s-.6.842-.88 1.286-.558.946-.775 1.315v.067a1.2 1.2 0 0 1-1.627.482 1.22 1.22 0 0 1-.539-1.466z"
          className="cls-6"
          transform="translate(1805.483 189.742)"
        />
        <path
          id="Path_618"
          d="M259.342 74.319a9.8 9.8 0 0 1 1.258-1.466 9.917 9.917 0 0 1 1.466-1.2 9.162 9.162 0 0 1 1.683-.946 5.1 5.1 0 0 1 1.891-.407.953.953 0 0 1 .605 1.683l-.095.076c-.832.681-1.636 1.182-2.421 1.891-.378.34-.757.681-1.125 1.04l-1.031 1.078-.066.085a1.419 1.419 0 0 1-2.213-1.769z"
          className="cls-6"
          transform="translate(1805.321 189.573)"
        />
        <path
          id="Path_619"
          d="M151.635 71.382l9.921-12.531-.87 1.343L169 43.5a.473.473 0 0 1 .9.293l-3.272 18.328a1.976 1.976 0 0 1-.87 1.3l-13.486 8.625a.465.465 0 0 1-.634-.662z"
          className="cls-6"
          transform="translate(1811.155 191.044)"
        />
        <path
          id="Path_620"
          d="M138.661 72a26.48 26.48 0 0 1-5.911-16.664 35.689 35.689 0 0 1 1.021-8.947c.435-1.438.842-2.894 1.343-4.313a43.185 43.185 0 0 1 1.759-4.142l2.081-3.963a23.9 23.9 0 0 1 2.837-3.783 24.145 24.145 0 0 1 7.566-5.523 22.462 22.462 0 0 1 9.287-1.986l2.393.085 2.336.369c.388.076.794.1 1.173.208l1.125.322 2.26.653a22.063 22.063 0 0 1 8.341 5.012 20.72 20.72 0 0 1 5.258 8.36 27.616 27.616 0 0 1 1.371 9.457 35.264 35.264 0 0 1-.4 4.681c-.113.766-.255 1.532-.416 2.308l-.265 1.154c-.1.4-.18.738-.35 1.248a30.524 30.524 0 0 1-1.891 4.341c-.359.69-.757 1.362-1.135 2.033l-1.244 1.949a34.7 34.7 0 0 1-9.864 9.627 29.55 29.55 0 0 1-4.067 2.1 30.009 30.009 0 0 1-8.691 2.3.473.473 0 0 1-.53-.4.463.463 0 0 1 .312-.511 34.34 34.34 0 0 0 11.24-5.815 34.489 34.489 0 0 0 3.007-2.837A33.991 33.991 0 0 0 173.5 62.7a35.09 35.09 0 0 0 3.168-7.471c.047-.189.123-.567.18-.87l.161-.946c.095-.671.18-1.352.236-2.024a38.942 38.942 0 0 0 .132-4.057 30.039 30.039 0 0 0-1.135-7.774 19.375 19.375 0 0 0-1.438-3.509 14.6 14.6 0 0 0-2.1-3.215 17.739 17.739 0 0 0-1.371-1.343 14.68 14.68 0 0 0-1.533-1.141 20.305 20.305 0 0 0-3.442-1.844l-1.892-.643-.946-.331c-.322-.1-.653-.142-.946-.218l-1.967-.426-2.09-.123a18.64 18.64 0 0 0-7.764 1.267 21.023 21.023 0 0 0-9.259 7.462l-2.175 3.688c-.671 1.258-1.22 2.553-1.844 3.783s-.946 2.657-1.466 3.991a33.3 33.3 0 0 0-1.371 8.341 27.2 27.2 0 0 0 .946 8.408 23.182 23.182 0 0 0 3.726 7.566.474.474 0 0 1-.738.6z"
          className="cls-6"
          transform="translate(1812.176 192.158)"
        />
        <path
          id="Path_621"
          d="M168.459 79.746A32.95 32.95 0 0 0 177.3 67.99a30.036 30.036 0 0 0 2.118-6.961 30.28 30.28 0 0 0 .492-3.6 16.712 16.712 0 0 0 .066-1.825V54.23c0-.076-.066-.151.114-.227a4.33 4.33 0 0 1 1.163-.265 3.785 3.785 0 0 1 .946 0 3.6 3.6 0 0 1 1.154.218c.17.076.076.161.095.246v1.513a15.961 15.961 0 0 1-.1 2.014 31.552 31.552 0 0 1-.615 3.991 28.667 28.667 0 0 1-2.771 7.566 27.927 27.927 0 0 1-4.729 6.478 24.047 24.047 0 0 1-6.27 4.795.463.463 0 0 1-.634-.208.473.473 0 0 1 .113-.577z"
          className="cls-6"
          transform="translate(1810.247 190.474)"
        />
        <path
          id="Path_622"
          d="M191.065 57.143a37.6 37.6 0 0 1-.378 5.674 38.965 38.965 0 0 1-1.2 5.57 30.266 30.266 0 0 1-2.118 5.362 25.9 25.9 0 0 1-3.272 4.88 1.333 1.333 0 1 1-2.052-1.7.437.437 0 0 0 .066-.066l.057-.057a23.645 23.645 0 0 0 3.253-4.142 28.687 28.687 0 0 0 2.364-4.842 39.737 39.737 0 0 0 1.551-5.239 36.63 36.63 0 0 0 .785-5.428.473.473 0 0 1 .946 0z"
          className="cls-6"
          transform="translate(1809.517 190.313)"
        />
        <path
          id="Path_623"
          d="M131.511 46.21a60.074 60.074 0 0 0-1.192 9.826 32.05 32.05 0 0 0 1.192 9.741.388.388 0 0 1-.274.473.416.416 0 0 1-.445-.2 17.8 17.8 0 0 1-1.891-4.88 22.374 22.374 0 0 1-.511-5.2 25.614 25.614 0 0 1 .61-5.17 19.92 19.92 0 0 1 1.74-4.9.378.378 0 0 1 .52-.161.388.388 0 0 1 .208.416z"
          className="cls-6"
          transform="translate(1812.412 190.909)"
        />
        <path
          id="Path_624"
          d="M152.546 33.62a5.125 5.125 0 0 1 2.544 2.3 5.353 5.353 0 0 1 .492 3.537 5.476 5.476 0 0 1-1.891 3.13 5.561 5.561 0 0 1-6.961.085 5.448 5.448 0 0 1-1.958-3.083 5.315 5.315 0 0 1 .407-3.546 5.154 5.154 0 0 1 2.487-2.364.161.161 0 0 1 .208.085.151.151 0 0 1-.047.189 4.8 4.8 0 0 0-1.891 5.324 4.4 4.4 0 0 0 8.445-.1 4.728 4.728 0 0 0-2.014-5.268.17.17 0 0 1 0-.227.179.179 0 0 1 .18-.057z"
          className="cls-6"
          transform="translate(1811.53 191.564)"
        />
        <path
          id="Path_625"
          d="M156.085 44.876a3.972 3.972 0 0 1 1.456 2.393 4.378 4.378 0 0 1-.463 2.913 4.53 4.53 0 0 1-2.26 2.014 4.729 4.729 0 0 1-3.074.132 4.586 4.586 0 0 1-2.459-1.825 4.483 4.483 0 0 1-.757-2.941 4.36 4.36 0 0 1 1.239-2.629 4.076 4.076 0 0 1 2.554-1.173.18.18 0 0 1 .18.161.161.161 0 0 1-.113.161 3.783 3.783 0 0 0-2.6 3.584 3.4 3.4 0 0 0 6.223 1.891 3.83 3.83 0 0 0-.161-4.435.17.17 0 0 1 0-.227.161.161 0 0 1 .236-.019z"
          className="cls-6"
          transform="translate(1811.32 191.014)"
        />
        <path
          id="Path_626"
          d="M161.073 22.457a3.234 3.234 0 0 0 3.424-1.74C165.584 18.532 166.208.8 161.423.8c-5.164 0-2.62 13.164-1.144 19.3a14.986 14.986 0 0 0 .794 2.355"
          className="cls-3"
          transform="translate(1810.787 193.345)"
        />
        <path
          id="Path_627"
          d="M161.129 21.945a3.272 3.272 0 0 0 2.043-1.088 2.488 2.488 0 0 0 .454-.946 10.682 10.682 0 0 0 .217-1.371c.2-1.891.284-3.953.3-5.958a42.855 42.855 0 0 0-.35-5.949 16.842 16.842 0 0 0-.605-2.837 4.246 4.246 0 0 0-1.229-2.118 1.277 1.277 0 0 0-.946-.2 1.513 1.513 0 0 0-.946.473 4.729 4.729 0 0 0-1.031 2.44 21.646 21.646 0 0 0-.057 5.958 52.291 52.291 0 0 0 .946 6.015c.2.993.416 1.986.653 2.97.113.5.227.946.369 1.456l.236.7.123.35.066.17v.095c-.17.076.407-.17-.388.18s-.227.085-.407.161v-.132l-.076-.18-.151-.369c-.1-.246-.18-.492-.265-.747-.161-.5-.284-1.021-.4-1.513q-.35-1.485-.653-3.007c-.407-2.014-.766-4.038-1-6.081a28.2 28.2 0 0 1-.113-6.261 6.251 6.251 0 0 1 1.196-3.168 2.96 2.96 0 0 1 3.7-.719 3.9 3.9 0 0 1 1.343 1.352 7.561 7.561 0 0 1 .757 1.523 19.057 19.057 0 0 1 .832 3.121 40.993 40.993 0 0 1 .586 6.242 35.858 35.858 0 0 1-.34 6.27 12.975 12.975 0 0 1-.34 1.636 3.783 3.783 0 0 1-1.078 1.7 3.386 3.386 0 0 1-1.768.861 3.442 3.442 0 0 1-1.825-.2.473.473 0 0 1-.265-.615.454.454 0 0 1 .35-.293z"
          className="cls-6"
          transform="translate(1810.815 193.394)"
        />
        <path
          id="Path_628"
          d="M170.766 26.391a3.66 3.66 0 0 0 4.142-.87c1.712-1.958 6.961-20.03 1.8-21.317-5.57-1.39-6.2 12.843-6.185 19.548a15.385 15.385 0 0 0 .246 2.639"
          className="cls-3"
          transform="translate(1810.125 193.166)"
        />
        <path
          id="Path_629"
          d="M170.934 25.885a3.783 3.783 0 0 0 2.468-.53 3.124 3.124 0 0 0 .416-.369l.189-.227.113-.189a12.461 12.461 0 0 0 .548-1.333 58.782 58.782 0 0 0 1.665-6.119 57.116 57.116 0 0 0 1.078-6.223 30.181 30.181 0 0 0 .18-3.026 4.512 4.512 0 0 0-.577-2.487 1.4 1.4 0 0 0-.9-.539 1.778 1.778 0 0 0-1.161.157 3.991 3.991 0 0 0-1.712 2.232 18.34 18.34 0 0 0-.69 3.055c-.17 1.04-.388 2.081-.549 3.14-.3 2.118-.558 4.256-.719 6.421-.085 1.078-.161 2.156-.208 3.244v1.6a4.433 4.433 0 0 0 .047.785v.775c-.208 0 .463-.076-.463.095s-.255 0-.463.076v-.2l-.047-.208-.066-.416a8.4 8.4 0 0 0-.123-.832c-.066-.567-.085-1.135-.1-1.674 0-1.106-.066-2.2-.076-3.3a53.508 53.508 0 0 1 .331-6.62 20.884 20.884 0 0 1 .615-3.3 17.337 17.337 0 0 1 1.305-3.168 11.445 11.445 0 0 1 .9-1.523 4.6 4.6 0 0 1 1.343-1.324 3.386 3.386 0 0 1 1.967-.53 3.149 3.149 0 0 1 2.024.766 4.285 4.285 0 0 1 1.182 1.759 9.462 9.462 0 0 1 .539 1.8 15.251 15.251 0 0 1 .208 3.509 35.633 35.633 0 0 1-1.078 6.677c-.265 1.088-.605 2.156-.946 3.215a31.546 31.546 0 0 1-1.254 3.146 11.928 11.928 0 0 1-.87 1.617 3.675 3.675 0 0 1-.359.473l-.4.359a4.076 4.076 0 0 1-.946.567 3.783 3.783 0 0 1-2.052.322 4.189 4.189 0 0 1-1.816-.671.52.52 0 0 1-.142-.719.511.511 0 0 1 .473-.227z"
          className="cls-6"
          transform="translate(1810.166 193.208)"
        />
        <path
          id="Path_630"
          d="M173.742 94.229c-21.856-8.171-43.806-16.077-65.737-24.021l-2.09-.709-1.031-.369-.255-.095-.322-.123a7.011 7.011 0 0 1-.813-.378 7.57 7.57 0 0 1-3.461-9.287l.747-2.052 1.494-4.114 5.977-16.446 6.024-16.427 1.513-4.1.095-.265.151-.359.312-.7a11.537 11.537 0 0 1 .776-1.324 10.8 10.8 0 0 1 2.052-2.3 11.141 11.141 0 0 1 8.616-2.591 11.814 11.814 0 0 1 1.513.274l.728.227.369.123.265.095 65.491 24.683 4.067 1.485.274.1.473.2a6.267 6.267 0 0 1 .879.511 6.524 6.524 0 0 1 1.466 1.419 6.4 6.4 0 0 1 1.267 3.783 6.3 6.3 0 0 1-.331 2.015l-.406 1.115-.757 2.052-1.513 4.1-6.005 16.44c-1.967 5.523-3.944 11.008-6 16.493l-1.532 4.1-.2.53-.3.7a12.056 12.056 0 0 1-.719 1.333 11.5 11.5 0 0 1-1.967 2.308 10.668 10.668 0 0 1-5.381 2.62 8.085 8.085 0 0 1-2.979-.18 23.182 23.182 0 0 1-2.75-.866zM175.5 89.5a4.133 4.133 0 0 0 2.837-.322 5.41 5.41 0 0 0 2.364-1.2 5.54 5.54 0 0 0 .861-1.021c.095-.2.227-.378.312-.586l.132-.312.18-.492 1.513-4.1c2.024-5.466 4.114-10.914 6.176-16.37l6.194-16.361 1.532-4.1.766-2.043.35-.946a.369.369 0 0 0 0-.142.416.416 0 0 0-.085-.274.293.293 0 0 0-.1-.085h-.066l-.246-.085-4.1-1.5-65.772-24.149-.246-.085-.161-.047-.312-.095c-.208 0-.416-.1-.634-.113a4.814 4.814 0 0 0-1.277 0 6.392 6.392 0 0 0-1.239.359 5.675 5.675 0 0 0-1.088.69 4.529 4.529 0 0 0-.861.946c-.142.17-.218.378-.34.558l-.123.293-.076.151-.085.246-1.542 4.095-6.164 16.371-6.223 16.352-1.56 4.095-.766 2.081a1.57 1.57 0 0 0-.123.775 1.6 1.6 0 0 0 .775 1.229 1.546 1.546 0 0 0 .18.085.729.729 0 0 0 .18.076l.255.095 1.021.4 2.052.775c21.818 8.294 43.6 16.673 65.482 24.769z"
          className="cls-6"
          transform="translate(1813.977 192.929)"
        />
        <path
          id="Path_631"
          d="M215.929 89.857a7.206 7.206 0 0 1 3.149 4.36 7.433 7.433 0 0 1-.946 5.495 11.945 11.945 0 0 1-9.334 5.306 12.739 12.739 0 0 1-5.447-.7 9.835 9.835 0 0 1-4.558-3.206 7.111 7.111 0 0 1-1.466-5.391 7.774 7.774 0 0 1 2.658-4.653.369.369 0 0 1 .5 0 .359.359 0 0 1 0 .454 7.216 7.216 0 0 0-1.74 4.3 5.5 5.5 0 0 0 .369 2.185 5.569 5.569 0 0 0 1.135 1.891 8.37 8.37 0 0 0 3.783 2.374 11.491 11.491 0 0 0 4.577.5 11.632 11.632 0 0 0 4.445-1.229 9.051 9.051 0 0 0 3.461-2.894 6.062 6.062 0 0 0 1.135-4.2 6.8 6.8 0 0 0-2.147-4.067.369.369 0 0 1 0-.511.359.359 0 0 1 .426-.014z"
          className="cls-6"
          transform="translate(1808.674 188.515)"
        />
        <path
          id="Path_632"
          d="M236.977 104.437a3.575 3.575 0 0 1 1.7 2.166 3.849 3.849 0 0 1-.425 2.9 6.147 6.147 0 0 1-4.785 2.837 5.949 5.949 0 0 1-5.267-2.04 3.934 3.934 0 0 1-.813-2.979 4.038 4.038 0 0 1 1.466-2.506.605.605 0 0 1 .87.823 3.083 3.083 0 0 0-.671 1.825 2.4 2.4 0 0 0 .633 1.617 4.521 4.521 0 0 0 3.632 1.239 4.908 4.908 0 0 0 3.575-1.721 2.648 2.648 0 0 0 .586-1.806 3.111 3.111 0 0 0-.861-1.891.312.312 0 0 1 0-.426.284.284 0 0 1 .36-.038z"
          className="cls-6"
          transform="translate(1807.041 187.723)"
        />
        <path
          id="Path_633"
          d="M225.719 98a5.164 5.164 0 0 1 3.1 2.26 5.344 5.344 0 0 1 .558 3.991 8.615 8.615 0 0 1-5.258 5.674 9.211 9.211 0 0 1-3.915.719 7.14 7.14 0 0 1-3.887-1.239 5.315 5.315 0 0 1-2.241-3.49 5.675 5.675 0 0 1 .851-3.877.5.5 0 0 1 .7-.161.53.53 0 0 1 .208.624 4.8 4.8 0 0 0-.255 3.1 3.887 3.887 0 0 0 1.75 2.3 6.97 6.97 0 0 0 6.062.208 7.943 7.943 0 0 0 2.714-1.7 6.234 6.234 0 0 0 1.74-2.6 4.274 4.274 0 0 0-.057-3.026 4.8 4.8 0 0 0-2.251-2.336.248.248 0 0 1 .18-.463z"
          className="cls-6"
          transform="translate(1807.765 188.072)"
        />
        <path
          id="Path_634"
          d="M257.066 112.8a6.5 6.5 0 0 1 3.783 2.5 5.675 5.675 0 0 1 .946 4.729 6.621 6.621 0 0 1-2.837 3.783 9.874 9.874 0 0 1-4.341 1.485 10.848 10.848 0 0 1-4.5-.426 8.256 8.256 0 0 1-3.887-2.487 5.8 5.8 0 0 1-1.381-4.539 5.117 5.117 0 0 1 2.591-3.783.246.246 0 0 1 .331.095.227.227 0 0 1 0 .255 7.037 7.037 0 0 0-1.381 3.565 5.069 5.069 0 0 0 1.078 3.4 7.1 7.1 0 0 0 3.093 2.222 8.322 8.322 0 0 0 3.915.388 6.7 6.7 0 0 0 5.561-4.483 5.123 5.123 0 0 0 .123-1.74 5.25 5.25 0 0 0-.548-1.712 8.426 8.426 0 0 0-2.658-2.837.237.237 0 0 1-.066-.331.246.246 0 0 1 .178-.084z"
          className="cls-6"
          transform="translate(1806.094 187.267)"
        />
        <path
          id="Path_635"
          d="M240.7 118.926a6.043 6.043 0 0 1 4.019.823 5.466 5.466 0 0 1 2.544 3.49 5.35 5.35 0 0 1 0 2.26 5.73 5.73 0 0 1-.946 2.052 6.62 6.62 0 0 1-3.594 2.4 7.566 7.566 0 0 1-4.256-.095 6.327 6.327 0 0 1-3.433-2.544 5.022 5.022 0 0 1 2-7.339.236.236 0 0 1 .312.113.227.227 0 0 1-.057.284 5.324 5.324 0 0 0-1.816 2.988 4.189 4.189 0 0 0 .719 3.178 5.542 5.542 0 0 0 6.1 1.891 5.1 5.1 0 0 0 2.686-1.891 4.029 4.029 0 0 0 .7-3.026 4.8 4.8 0 0 0-1.721-2.762 6.922 6.922 0 0 0-3.282-1.409.236.236 0 0 1-.189-.274.217.217 0 0 1 .208-.142z"
          className="cls-6"
          transform="translate(1806.67 186.938)"
        />
        <path
          id="Path_636"
          d="M252.41 125a4.9 4.9 0 0 1 2.941.946 4.039 4.039 0 0 1 1.579 2.837 4.871 4.871 0 0 1-.7 3.026 6.289 6.289 0 0 1-2.052 2.09.151.151 0 0 1-.2 0 .142.142 0 0 1 0-.161 9.107 9.107 0 0 0 1.381-2.308 4.54 4.54 0 0 0 .3-2.421 3.178 3.178 0 0 0-1.135-1.948 5.059 5.059 0 0 0-2.232-.946.511.511 0 0 1-.4-.605.5.5 0 0 1 .511-.511z"
          className="cls-6"
          transform="translate(1805.709 186.604)"
        />
        <path
          id="Path_637"
          d="M246.024 128.748a6.09 6.09 0 0 0-1.532 2.62 3.036 3.036 0 0 0 .492 2.714 4.01 4.01 0 0 0 2.629 1.409 7.933 7.933 0 0 0 3.32-.208.17.17 0 0 1 .2.114.152.152 0 0 1-.085.18 7.3 7.3 0 0 1-3.518.813 4.908 4.908 0 0 1-3.509-1.494 4.313 4.313 0 0 1-.946-3.849 6.687 6.687 0 0 1 1.891-3.338.747.747 0 0 1 1.05 0 .738.738 0 0 1 .008 1.039z"
          className="cls-6"
          transform="translate(1806.194 186.469)"
        />
        <path
          id="Path_638"
          d="M245.4 127.381a5.248 5.248 0 0 1 .946-1.57 4.171 4.171 0 0 1 1.664-1.248 4.246 4.246 0 0 1 3.859.388.492.492 0 0 1 .123.69.5.5 0 0 1-.388.208 6.762 6.762 0 0 0-2.837.586 5.967 5.967 0 0 0-1.059.766 15.451 15.451 0 0 1-1.267.946h-.041a.69.69 0 0 1-1.031-.832z"
          className="cls-6"
          transform="translate(1806.065 186.644)"
        />
        <path
          id="Path_639"
          d="M292.173 140.46l2 2.922-3.348-.653z"
          className="cls-6"
          transform="translate(1803.596 185.765)"
        />
        <path
          id="Path_640"
          d="M93.216 168.435a64.532 64.532 0 0 0 5.58 6.989q1.513 1.655 3.111 3.234c1.059 1.05 2.175 2.1 3.253 3.036a2.09 2.09 0 0 1 .671 1.485v.057l.142 8.512.066 4.227v1.948a6.762 6.762 0 0 0 .388 1.617 24.2 24.2 0 0 0 3.925 6.771 45.045 45.045 0 0 0 5.674 5.939 63.678 63.678 0 0 0 13.817 9.164 86.071 86.071 0 0 0 15.69 5.911.889.889 0 0 1 .624.577q1.731 4.823 3.329 9.665c1.069 3.234 2.1 6.488 3 9.8l-1.891.094c.492-3.149.861-6.365 1.144-9.561s.473-6.412.577-9.618a.946.946 0 0 1 .52-.823c1.229-.662 2.459-1.409 3.641-2.194a39.322 39.322 0 0 0 3.4-2.506 21.17 21.17 0 0 0 2.932-2.837 7.623 7.623 0 0 0 1.712-3.225l.823 1.551c-1.891-.87-3.7-1.787-5.5-2.762s-3.547-2-5.277-3.083a62.219 62.219 0 0 1-9.731-7.414.586.586 0 1 1 .776-.88 60.846 60.846 0 0 0 9.93 6.62c1.75.946 3.537 1.844 5.343 2.676s3.66 1.627 5.476 2.317a1.277 1.277 0 0 1 .766 1.532 10.223 10.223 0 0 1-2.459 4.294 24.532 24.532 0 0 1-3.414 3.045 39.494 39.494 0 0 1-3.707 2.478c-1.277.757-2.553 1.456-3.915 2.109l.53-.823c-.1 3.253-.293 6.507-.586 9.741s-.653 6.459-1.163 9.7a.946.946 0 0 1-1.844.1c-1.8-6.5-4.019-12.928-6.28-19.312l.662.6a84.226 84.226 0 0 1-16.219-5.4 66.089 66.089 0 0 1-14.848-9.041 48.538 48.538 0 0 1-6.317-6.157 27.87 27.87 0 0 1-4.852-7.736 11.1 11.1 0 0 1-.681-2.487c0-.227-.066-.511-.076-.69v-.539l-.057-1.069-.189-4.284c-.085-2.837-.123-5.674-.085-8.559l.671 1.542c-1.135-1.173-2.156-2.317-3.178-3.509s-2-2.4-2.951-3.641a67.059 67.059 0 0 1-5.239-7.736 1.345 1.345 0 1 1 2.279-1.428z"
          className="cls-6"
          transform="translate(1814.457 184.28)"
        />
        <ellipse
          id="Ellipse_675"
          cx="10.431"
          cy="9.098"
          className="cls-8"
          rx="10.431"
          ry="9.098"
          transform="rotate(-5.89 5338.862 -18428.781)"
        />
        <path
          id="Path_641"
          d="M115.992 266.108a165.238 165.238 0 0 0-3.783-18.631c-1.617-6.157-3.442-12.294-5.589-18.243s-4.445-11.926-7.093-17.7-5.5-11.472-8.512-17.023l.9.643c-1.608-.227-3.338-.378-5.022-.492s-3.4-.161-5.088-.189c-3.4 0-6.809.085-10.195.359l1.447-3.026c3.622 5.353 6.8 10.81 9.864 16.38s5.92 11.226 8.6 17.023 5.211 11.557 7.5 17.458c1.2 2.932 2.251 5.92 3.357 8.88s2.052 5.986 3.083 8.975a.473.473 0 0 1-.889.322c-1.135-2.941-2.213-5.9-3.4-8.814s-2.374-5.835-3.669-8.7c-2.5-5.788-5.258-11.443-8.1-17.023s-5.92-11.093-9.136-16.484-6.62-10.715-10.271-15.68A1.891 1.891 0 0 1 71.354 191c3.509-.066 7.017 0 10.507.236 1.75.132 3.5.293 5.239.5s3.452.463 5.249.832a1.277 1.277 0 0 1 .861.653c3.026 5.674 5.958 11.405 8.512 17.307s4.918 11.878 7.046 17.969 3.915 12.247 5.5 18.47a167.019 167.019 0 0 1 3.783 18.914 1.026 1.026 0 1 1-2.033.274z"
          className="cls-6"
          transform="translate(1815.607 183.023)"
        />
        <path
          id="Line_13"
          d="M0 0L8.199 0.596"
          className="cls-8"
          transform="translate(1909.338 378.552)"
        />
        <path
          id="Path_642"
          d="M69.795 197.3a46.332 46.332 0 0 0-10.242.388 23.943 23.943 0 0 0-4.842 1.258 11.746 11.746 0 0 0-4.01 2.572 37.31 37.31 0 0 0-6.081 8.067 100.668 100.668 0 0 0-4.729 9.23 182.308 182.308 0 0 0-7.329 19.51c-2.118 6.62-3.982 13.363-5.722 20.125v.047a2.119 2.119 0 0 1-.3.643 123.033 123.033 0 0 0-6.677 11.585c-2.09 3.991-3.944 8.1-5.674 12.238A161.72 161.72 0 0 0 5.9 308.7c-.246 1.1-.52 2.194-.747 3.3l-.605 3.32c-.2 1.116-.426 2.222-.605 3.338l-.435 3.348a61.785 61.785 0 0 0-.671 13.382 43.022 43.022 0 0 0 2.837 12.928 33.411 33.411 0 0 0 7.14 11.018 35.71 35.71 0 0 0 10.848 7.566 37.015 37.015 0 0 0 6.289 2.2 26.573 26.573 0 0 0 3.225.634 30.277 30.277 0 0 0 3.272.189c4.464.057 8.994-.284 13.5-.634s9.022-.775 13.533-1.333c9-1.087 17.969-2.535 26.944-4.086s17.874-3.367 26.783-5.211a1.581 1.581 0 1 1 .662 3.092 510.031 510.031 0 0 1-54.02 9.457c-9.107 1.012-18.253 1.891-27.53 1.248a33.525 33.525 0 0 1-3.537-.369 30 30 0 0 1-3.442-.851 48.861 48.861 0 0 1-6.648-2.459 39.994 39.994 0 0 1-11.552-7.877 35.73 35.73 0 0 1-7.8-11.661 45.482 45.482 0 0 1-3.152-13.651 65.036 65.036 0 0 1 .444-13.911l.378-3.433c.151-1.144.35-2.27.53-3.414l.539-3.414c.2-1.135.454-2.251.681-3.386a170.136 170.136 0 0 1 7.8-26.48c1.683-4.284 3.433-8.559 5.447-12.72.993-2.081 2.033-4.152 3.111-6.194s2.175-4.057 3.452-6.119l-.312.69c2-6.781 4.1-13.524 6.488-20.182a187.535 187.535 0 0 1 8.133-19.643 102.864 102.864 0 0 1 5.192-9.353 39.086 39.086 0 0 1 6.866-8.455 15.386 15.386 0 0 1 2.317-1.731c.426-.227.851-.473 1.277-.671l1.324-.539a27 27 0 0 1 5.372-1.211 47.624 47.624 0 0 1 10.762 0 .944.944 0 0 1-.151 1.882z"
          className="cls-6"
          transform="translate(1819.38 182.799)"
        />
        <path
          id="Path_643"
          d="M26.2 318.779a119.005 119.005 0 0 1 23.785-2.506h6c2 .047 3.991.085 5.986.236s3.991.236 5.977.416l5.968.653c1.986.217 3.953.567 5.93.851s3.953.615 5.911 1.04 3.925.775 5.873 1.21 3.868.993 5.807 1.485c3.887.946 7.66 2.279 11.453 3.528l1.419.473 1.4.548 2.78 1.1c1.891.757 3.7 1.513 5.523 2.3l-1.192.218a96.369 96.369 0 0 1 8.029-6.176 83.689 83.689 0 0 1 8.625-5.306 54.36 54.36 0 0 1 9.372-4.057 31.4 31.4 0 0 1 10.28-1.6 1.315 1.315 0 1 1-.085 2.629h-.047a28.906 28.906 0 0 0-9.382 1.409 53.257 53.257 0 0 0-8.975 3.783 83.069 83.069 0 0 0-8.417 5.107 93.418 93.418 0 0 0-7.849 5.949 1.05 1.05 0 0 1-1 .265l-.189-.047q-2.837-.766-5.674-1.589c-1.891-.549-3.783-1.154-5.674-1.617-3.783-.946-7.566-2.09-11.405-2.837-7.566-1.891-15.33-3.253-23.066-4.521a210.643 210.643 0 0 0-23.407-2.289 176.608 176.608 0 0 0-23.567.284.463.463 0 0 1-.511-.426.473.473 0 0 1 .322-.513z"
          className="cls-6"
          transform="translate(1817.977 176.39)"
        />
        <path
          id="Path_644"
          d="M89.393 262.627a127.931 127.931 0 0 1 .123 13.864 136.654 136.654 0 0 1-1.286 13.893 109.814 109.814 0 0 1-2.9 13.732 85.349 85.349 0 0 1-5.03 13.316 2.885 2.885 0 0 1-5.22-2.459l.114-.217.057-.095A80.752 80.752 0 0 0 81 302.679a105.23 105.23 0 0 0 3.934-12.928 135.915 135.915 0 0 0 2.44-13.41 126.738 126.738 0 0 0 1.069-13.647.464.464 0 0 1 .482-.464.473.473 0 0 1 .468.397z"
          className="cls-6"
          transform="translate(1815.32 179.156)"
        />
        <path
          id="Path_645"
          d="M24.99 261.288c2.1-.076 4.114 0 6.147.066s4.067.3 6.081.558 4.01.605 6 1.04a57.667 57.667 0 0 1 5.854 1.617.35.35 0 0 1 .218.435.341.341 0 0 1-.407.227 58.8 58.8 0 0 0-5.863-1.125c-1.977-.265-3.953-.454-5.939-.549s-3.972-.123-5.949-.066-3.981.18-5.892.407a1.324 1.324 0 0 1-1.456-1.154 1.305 1.305 0 0 1 1.154-1.447z"
          className="cls-6"
          transform="translate(1818.09 179.209)"
        />
        <path
          id="Path_646"
          d="M27.095 255.34h2.052c.681 0 1.343.066 2.014.113a31.546 31.546 0 0 1 3.981.567.511.511 0 0 1 .388.6.529.529 0 0 1-.511.4 30.872 30.872 0 0 0-3.849.132c-.634.057-1.277.132-1.891.227s-1.277.2-1.891.321a1.184 1.184 0 1 1-.492-2.317 1.435 1.435 0 0 1 .199-.043z"
          className="cls-6"
          transform="translate(1817.972 179.53)"
        />
        <path
          id="Path_647"
          d="M123.609 331.625a88.279 88.279 0 0 0-1.456 8.975 76.741 76.741 0 0 0-.53 9.069 46.545 46.545 0 0 0 .776 8.918 24.419 24.419 0 0 0 2.932 8.077 1.659 1.659 0 1 1-2.837 1.721 27.708 27.708 0 0 1-3.159-9.268 50.127 50.127 0 0 1-.6-9.457 77.131 77.131 0 0 1 .766-9.363 91.553 91.553 0 0 1 1.731-9.23 1.2 1.2 0 0 1 2.345.548z"
          className="cls-6"
          transform="translate(1812.938 175.47)"
        />
        <path
          id="Path_648"
          d="M123.982 366.829a89.134 89.134 0 0 0 9.155 2.128c3.121.586 6.261 1.078 9.457 1.457s6.336.652 9.514.946l4.776.274c1.589.057 3.178.132 4.785.161s3.2 0 4.842.066 3.159.076 4.729.066a75.884 75.884 0 0 0 9.457-.605 35.511 35.511 0 0 0 4.577-.946c1.551-.388 3.093-.8 4.644-1.229s3.14-.823 4.729-1.154a20.652 20.652 0 0 1 5.022-.473l-.842 1.163a11.277 11.277 0 0 0-1.333-1.891 16.4 16.4 0 0 0-1.7-1.74 26.648 26.648 0 0 0-3.972-2.941 37.928 37.928 0 0 0-9.278-4.01 70.4 70.4 0 0 0-10.053-2.166c-3.424-.482-6.866-.813-10.337-1.021a.473.473 0 0 1-.445-.5.463.463 0 0 1 .454-.444 86.783 86.783 0 0 1 10.516.293 61.463 61.463 0 0 1 10.45 1.75 40.66 40.66 0 0 1 9.968 3.972 21.505 21.505 0 0 1 4.35 3.348 15.073 15.073 0 0 1 1.778 2.156 11.121 11.121 0 0 1 .719 1.22 6.837 6.837 0 0 1 .53 1.475v.094a.832.832 0 0 1-.577 1.04.773.773 0 0 1-.293 0c-3.036.151-6.024 1.229-9.069 2.232-1.532.5-3.074 1.012-4.625 1.485-.766.246-1.561.473-2.393.681s-1.636.388-2.459.53a76.627 76.627 0 0 1-9.807.946c-1.636.066-3.263.1-4.908.095h-4.823a161.851 161.851 0 0 1-19.472-1.163c-3.234-.4-6.459-.88-9.665-1.513a82.093 82.093 0 0 1-9.6-2.44 1.768 1.768 0 0 1 1.05-3.376z"
          className="cls-6"
          transform="translate(1812.781 174.18)"
        />
        <path
          id="Path_649"
          d="M150.982 323.811a57.448 57.448 0 0 1 8.606-2.7c1.466-.36 2.941-.653 4.426-.946s2.988-.454 4.5-.605a51.789 51.789 0 0 1 9.145-.113l1.154.085 1.144.17a16.889 16.889 0 0 1 2.308.407c.785.217 1.589.369 2.336.634l2.222.879a35.878 35.878 0 0 1 7.793 5.135 57.061 57.061 0 0 1 6.365 6.563 71.418 71.418 0 0 1 5.306 7.329 72.893 72.893 0 0 1 4.388 7.888v.075a.8.8 0 0 1-.388 1.05.708.708 0 0 1-.369.066 32.974 32.974 0 0 1-4.218-.1 22.567 22.567 0 0 1-4.246-.776 1.27 1.27 0 1 1 .709-2.44l.132.047a19.273 19.273 0 0 0 3.7 1.1 30.474 30.474 0 0 0 3.915.549l-.719 1.2a98.146 98.146 0 0 0-10.507-14.034 61.04 61.04 0 0 0-6.223-6.005 35.294 35.294 0 0 0-7.083-4.54l-1.891-.757c-.643-.227-1.305-.35-1.958-.53a14.172 14.172 0 0 0-2.062-.369c-.7-.123-1.409-.189-2.118-.255a62.644 62.644 0 0 0-8.644-.2 130.719 130.719 0 0 0-17.486 2.071.473.473 0 0 1-.549-.388.482.482 0 0 1 .312-.49z"
          className="cls-6"
          transform="translate(1811.203 176.059)"
        />
        <path
          id="Path_650"
          d="M165.143 335.306c2.431-.426 4.861-.747 7.311-.946a69.046 69.046 0 0 1 7.415-.34 34.392 34.392 0 0 1 3.783.2 18.133 18.133 0 0 1 3.849.728 27.583 27.583 0 0 1 6.828 3.565 51.754 51.754 0 0 1 10.734 10.479 64.02 64.02 0 0 1 7.717 12.682.87.87 0 0 1-.4 1.154.749.749 0 0 1-.246.076 26.087 26.087 0 0 1-5.343.218 42.312 42.312 0 0 1-5.239-.605 45.4 45.4 0 0 1-5.126-1.22c-.842-.227-1.664-.558-2.487-.832s-1.627-.653-2.43-.993a1.088 1.088 0 0 1 .738-2.033c1.617.482 3.215 1.012 4.842 1.456s3.244.861 4.88 1.229 3.3.615 4.965.823a36.707 36.707 0 0 0 4.946.265l-.634 1.239a126.15 126.15 0 0 0-8.511-11.689 67.406 67.406 0 0 0-4.842-5.277 52.028 52.028 0 0 0-5.343-4.662 25.1 25.1 0 0 0-5.986-3.423 27.555 27.555 0 0 0-6.847-1.173 132.867 132.867 0 0 0-14.526 0 .475.475 0 0 1-.095-.946z"
          className="cls-6"
          transform="translate(1810.443 175.26)"
        />
        <path
          id="Path_651"
          d="M181.235 262.323c.047-3.6 0-7.244-.151-10.857s-.35-7.235-.653-10.838-.738-7.2-1.258-10.762A88.35 88.35 0 0 0 177 219.321a1.428 1.428 0 0 1 2.762-.719c.861 3.613 1.485 7.244 1.986 10.885s.794 7.3 1.04 10.952.359 7.31.416 10.961 0 7.3-.085 10.97a.946.946 0 1 1-1.891 0z"
          className="cls-6"
          transform="translate(1809.775 181.579)"
        />
        <path
          id="Path_652"
          d="M193.2 220.94c.416 1.891.719 3.783.946 5.674s.454 3.783.586 5.674a83.062 83.062 0 0 1 .057 11.481.947.947 0 1 1-1.891-.085 81.924 81.924 0 0 0-.435-11.188c-.2-1.891-.454-3.707-.766-5.542s-.681-3.688-1.125-5.438a1.343 1.343 0 0 1 2.6-.662z"
          className="cls-6"
          transform="translate(1809.039 181.454)"
        />
        <path
          id="Path_653"
          d="M210.437 222.555q4.086 5.164 8.114 10.4l4.01 5.249c1.314 1.759 2.629 3.518 3.887 5.353a.636.636 0 0 1-1.021.757c-1.333-1.712-2.752-3.386-4.171-5.05l-4.322-4.975q-4.331-4.946-8.72-9.845a1.456 1.456 0 1 1 2.156-1.948z"
          className="cls-6"
          transform="translate(1808.104 181.343)"
        />
        <path
          id="Path_654"
          d="M157.822 317.747l30.036-73.237a1.419 1.419 0 0 1 1.759-.8l77.71 25.932a1.419 1.419 0 0 1 .9 1.788.717.717 0 0 0 0 .1l-44.384 106.005-1.75.9a.794.794 0 0 1-.757.274l-.662-.151-1.324-.293-2.648-.586-5.287-1.182c-3.528-.8-7.065-1.56-10.583-2.383-7.046-1.617-14.091-3.253-21.118-4.975a1.76 1.76 0 1 1 .775-3.433c7.083 1.447 14.186 2.988 21.213 4.549 3.527.757 7.046 1.579 10.573 2.374l5.3 1.2 2.639.6 1.324.3.662.142a.794.794 0 0 1 .567.577l-1.75.9 44.551-105.888.861 1.892-77.71-25.913 1.759-.813-30.036 73.237a1.419 1.419 0 1 1-2.62-1.078z"
          className="cls-6"
          transform="translate(1810.822 180.166)"
        />
        <path
          id="Line_14"
          d="M0 68.452L31.871 0"
          className="cls-11"
          transform="translate(2128.736 428.798)"
        />
        <path
          id="Path_655"
          d="M237.253 377.509L281.7 270.33a1.41 1.41 0 0 1 .87-.8l70.93-22.465a1.836 1.836 0 0 1 1.116 3.5L283.4 272.231l.889-.813-44.445 107.188a1.419 1.419 0 0 1-2.62-1.087z"
          className="cls-6"
          transform="translate(1806.51 179.984)"
        />
        <path
          id="Path_656"
          fill="none"
          stroke="#3c3c64"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3px"
          d="M273.14 272.572s6.62 2.6 11.822-.312"
          transform="translate(1804.556 178.612)"
        />
        <path
          id="Path_657"
          d="M226.14 383.782c1.816 2.837 8.4 3.509 11.822.946l49.3-15.018"
          className="cls-11"
          transform="translate(1807.106 173.322)"
        />
        <path
          id="Path_658"
          d="M330.272 323.944a32.71 32.71 0 0 0-5.448-.766l-1.4-.085h-1.4c-.946 0-1.891 0-2.837.047a67.346 67.346 0 0 0-11.14 1.485c-1.825.444-3.651.861-5.447 1.447s-3.565 1.163-5.334 1.806c-.9.3-1.759.681-2.62 1.05l-2.6 1.107c-1.712.794-3.386 1.664-5.079 2.487l.189-1.541a83.193 83.193 0 0 0 9.977 5.93 33.915 33.915 0 0 0 5.343 2.033 27.412 27.412 0 0 0 2.743.643c.946.151 1.891.3 2.884.426a76.243 76.243 0 0 0 11.661.6 89.091 89.091 0 0 0 11.642-1.088l-.634.246a12.252 12.252 0 0 0 1.551-1.22 15.7 15.7 0 0 0 1.409-1.447 17.137 17.137 0 0 0 2.279-3.348 15.508 15.508 0 0 0 1.409-3.783 10.28 10.28 0 0 0 .293-1.891 8.488 8.488 0 0 0 0-.946 4.379 4.379 0 0 0-.094-.946 24.131 24.131 0 0 0-2.932-7.812 50.588 50.588 0 0 0-4.814-7.15.946.946 0 0 1 1.447-1.211 52.031 52.031 0 0 1 5.334 7.273 26.33 26.33 0 0 1 3.546 8.51 7.079 7.079 0 0 1 .17 1.2c0 .407.095.813.085 1.229a13.387 13.387 0 0 1-.274 2.45 18.328 18.328 0 0 1-1.475 4.521 20.747 20.747 0 0 1-2.516 4.038 20.146 20.146 0 0 1-1.6 1.768 14.98 14.98 0 0 1-1.891 1.627 1.506 1.506 0 0 1-.586.237h-.047a77.643 77.643 0 0 1-12.115 1.182 72.926 72.926 0 0 1-12.181-.757c-1-.142-2-.331-3.007-.511a30.066 30.066 0 0 1-3.055-.785 39 39 0 0 1-15.7-9.5.946.946 0 0 1-.085-1.305.785.785 0 0 1 .284-.218c1.731-.842 3.442-1.721 5.182-2.535l2.658-1.125c.879-.378 1.759-.766 2.676-1.078 1.806-.662 3.6-1.334 5.466-1.892s3.707-1.021 5.58-1.475a69.809 69.809 0 0 1 11.472-1.532c.946 0 1.891-.076 2.9-.047h1.459l1.456.085a34.179 34.179 0 0 1 5.835.841.944.944 0 1 1-.445 1.835z"
          className="cls-6"
          transform="translate(1803.873 176.578)"
        />
        <path
          id="Path_659"
          d="M332.911 343.146a38.652 38.652 0 0 1-3.14 7.746 22.111 22.111 0 0 1-5.3 6.677c-.293.227-.577.463-.879.672l-.946.586a20.1 20.1 0 0 1-1.891.946 32.024 32.024 0 0 1-12.295 2.591 24.588 24.588 0 0 1-8.351-1.419 21.954 21.954 0 0 1-7.273-4.388 43.234 43.234 0 0 1-5.608-6.072 24.289 24.289 0 0 1-3.944-7.443.946.946 0 0 1 .671-1.116h.114c2.686-.35 5.362-.662 8.067-.9 1.343-.123 2.7-.236 4.057-.3s2.724-.123 4.123-.066a.947.947 0 0 1-.076 1.892c-1.286-.057-2.62 0-3.944.066s-2.658.17-3.982.293c-2.667.236-5.334.539-7.982.889l.785-1.182a17.543 17.543 0 0 0 1.75 3.32 33.392 33.392 0 0 0 2.27 3.13 44.584 44.584 0 0 0 5.495 5.523 19.6 19.6 0 0 0 6.422 3.783 22.925 22.925 0 0 0 7.424 1.267 27.713 27.713 0 0 0 7.566-1.04 15.983 15.983 0 0 0 11.349-8.984 36.42 36.42 0 0 0 2.837-7.131v-.057a1.374 1.374 0 1 1 2.648.738z"
          className="cls-6"
          transform="translate(1804.006 174.9)"
        />
        <path
          id="Path_660"
          d="M295.946 359.3a60.108 60.108 0 0 0-7.8 1.012 34.854 34.854 0 0 0-3.783.946 13.647 13.647 0 0 0-3.363 1.537l.189-1.664a30.627 30.627 0 0 0 4.445 3.367 34.493 34.493 0 0 0 4.908 2.639 31.376 31.376 0 0 0 5.258 1.806 29.9 29.9 0 0 0 5.41.9 15.681 15.681 0 0 0 10.091-2.676 25.482 25.482 0 0 0 7.083-8.218 1.371 1.371 0 0 1 1.891-.492 1.39 1.39 0 0 1 .5 1.892 26.631 26.631 0 0 1-7.859 9.183 18.46 18.46 0 0 1-11.85 3.13 26.712 26.712 0 0 1-3.026-.359c-.946-.227-1.967-.4-2.932-.7a34.813 34.813 0 0 1-5.617-2.081 33.337 33.337 0 0 1-9.873-6.819 1.049 1.049 0 0 1 0-1.485.784.784 0 0 1 .2-.152 15.937 15.937 0 0 1 3.925-1.73 35.883 35.883 0 0 1 4.019-.946c1.343-.246 2.686-.435 4.038-.586s2.686-.255 4.066-.321a.947.947 0 1 1 .1 1.891z"
          className="cls-6"
          transform="translate(1804.221 173.986)"
        />
        <path
          id="Path_661"
          d="M279.316 271.869a40.821 40.821 0 0 1 6.535-6.024l1.768-1.315 1.844-1.2c1.211-.842 2.506-1.523 3.783-2.251a77.227 77.227 0 0 1 16.257-6.535l4.256-1.069c1.419-.35 2.837-.539 4.3-.813 2.837-.6 5.788-.794 8.691-1.125 1.447-.2 2.913-.18 4.369-.255l4.369-.161c2.913 0 5.826.17 8.748.255a1.476 1.476 0 0 1-.085 2.951h-.123c-2.837-.114-5.674-.274-8.511-.359l-4.265.095c-1.428.047-2.837 0-4.265.18-2.837.284-5.674.435-8.512.946-1.39.246-2.837.407-4.2.728l-4.142.946a75.2 75.2 0 0 0-15.833 6.107c-1.239.69-2.506 1.333-3.679 2.118l-1.787 1.144-1.721 1.229a38.767 38.767 0 0 0-6.242 5.57l-.057.066a.986.986 0 0 1-1.494-1.286z"
          className="cls-6"
          transform="translate(1804.233 179.759)"
        />
        <path
          id="Path_662"
          d="M278 271.862a33.1 33.1 0 0 1 4.729-8.843 36.78 36.78 0 0 1 6.923-7.159c5.173-4.208 11.613-6.733 17.969-8.161a115.374 115.374 0 0 1 19.359-2.714 144.607 144.607 0 0 1 19.624.113 1.39 1.39 0 0 1 1.267 1.513 1.273 1.273 0 0 1-.142.5l-3.036 5.986a1.449 1.449 0 1 1-2.582-1.315l3.1-5.949 1.116 2.043a144.487 144.487 0 0 0-19.208-.331 112.742 112.742 0 0 0-19.028 2.431c-1.551.359-3.112.757-4.634 1.192a40.734 40.734 0 0 0-4.435 1.617 35.721 35.721 0 0 0-14.735 11.415 31.256 31.256 0 0 0-4.492 8.209v.076a.946.946 0 0 1-1.787-.615z"
          className="cls-6"
          transform="translate(1804.293 180.107)"
        />
        <path
          id="Path_663"
          d="M196.4 246a127.56 127.56 0 0 1 20.806 1.891 150.2 150.2 0 0 1 20.333 4.823l2.506.757 2.459.861c1.646.6 3.3 1.135 4.927 1.769 3.225 1.333 6.488 2.572 9.618 4.123a146.5 146.5 0 0 1 18.357 10.223 1.3 1.3 0 0 1 .407 1.787 1.315 1.315 0 0 1-1.8.407h-.047a143.555 143.555 0 0 0-17.866-10.166c-3.055-1.56-6.261-2.837-9.457-4.142-1.6-.643-3.225-1.182-4.833-1.778l-2.431-.88-2.459-.766a148.175 148.175 0 0 0-20.011-4.918 125.4 125.4 0 0 0-20.466-2.1h-.057a.946.946 0 1 1 0-1.891z"
          className="cls-6"
          transform="translate(1808.772 180.037)"
        />
        <path
          id="Path_664"
          d="M203.494 246.708l1.523-3.178a.946.946 0 0 1 .757-.511 139.634 139.634 0 0 1 18.811 1.551 103.371 103.371 0 0 1 18.262 4.729 87.755 87.755 0 0 1 17.023 8.237 80.56 80.56 0 0 1 14.82 11.878 1.478 1.478 0 1 1-2.109 2.071 75.993 75.993 0 0 0-13.981-11.877 85.554 85.554 0 0 0-16.427-8.37 100.878 100.878 0 0 0-17.827-4.965c-3.045-.558-6.119-.946-9.2-1.267a51.07 51.07 0 0 0-9.287-.113l.766-.511-1.579 3.149a.87.87 0 0 1-1.173.4.889.889 0 0 1-.378-1.22z"
          className="cls-6"
          transform="translate(1808.342 180.199)"
        />
        <path
          id="Line_15"
          d="M0 0L39.371 13.949"
          className="cls-8"
          transform="translate(2010.039 463.677)"
        />
        <path
          id="Line_16"
          d="M0 0L33.337 10.999"
          className="cls-8"
          transform="translate(2006.142 479.158)"
        />
      </g>
    </svg>
  )
}
