import { TestRun } from 'types'
import {
  hasScriptError,
  hasSystemError,
  isTestActive,
  isTestRunExpired,
  isTestRunUploaded,
} from 'utils/testRun'

export function canExportSummary(testRun: TestRun) {
  return (
    !isTestActive(testRun) &&
    !isTestRunExpired(testRun) &&
    !isTestRunUploaded(testRun) &&
    !hasSystemError(testRun) &&
    !hasScriptError(testRun)
  )
}
