import React, { ReactNode, KeyboardEvent, forwardRef } from 'react'
import { TableRowStyles } from './TableRow.styles'
import { FullWidthTableData } from './TableData'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { TableCell } from './TableCell'

interface TableRowProps {
  highlight?: boolean
  expanded: boolean
  children: ReactNode
  onToggle: () => void
}

export function TableRow({
  highlight = false,
  expanded,
  children,
  onToggle,
}: TableRowProps) {
  const handleRowClick = () => {
    onToggle()
  }

  const handleKeyDown = (ev: KeyboardEvent<HTMLTableRowElement>) => {
    if (ev.key === 'Enter') {
      onToggle()
    }
  }

  return (
    <TableRowStyles
      tabIndex={0}
      highlight={highlight}
      aria-expanded={expanded}
      onClick={handleRowClick}
      onKeyDown={handleKeyDown}
    >
      {children}
    </TableRowStyles>
  )
}

interface FullWidthRowProps {
  className?: string
  children: ReactNode
}

export const FullWidthRow = forwardRef<HTMLTableRowElement, FullWidthRowProps>(
  function FullWidthRow(props, ref) {
    const { className, children } = props

    return (
      <TableRowStyles ref={ref} className={className} noHover>
        <FullWidthTableData>{children}</FullWidthTableData>
      </TableRowStyles>
    )
  }
)

export function LoadingRow() {
  return (
    <FullWidthRow>
      <TableCell>
        <CenteredSpinner />
      </TableCell>
    </FullWidthRow>
  )
}
