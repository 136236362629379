import { TestId } from 'types'

export const toTrendingMetricsQueryKey = (testId: number) =>
  ['trending-metrics', testId] as const

export const toTagsWithValuesQueryKey = (testId: number) =>
  [...toTrendingMetricsQueryKey(testId), 'tags-with-values'] as const

export const toTestTrendingMetricsOptionsKey = (testId: TestId) =>
  [
    ...toTrendingMetricsQueryKey(testId),
    'test-trending-metrics-options',
  ] as const
