import styled from 'styled-components'
import { color, spacing } from 'utils/styled'
import { Section } from '../components/Section'

export const FooterSectionStyled = styled(Section)`
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${spacing(3)};
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  & > span:first-of-type {
    font-size: 2rem;
    color: ${color('info', 'text')};
  }

  & > span.footer-title {
    color: inherit;
    font-size: 1.5rem;
  }
`

export const CenteredColumn = styled(Column)`
  justify-content: center;
`
