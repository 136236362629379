import React from 'react'
import { Metric, TestRun } from 'types'
import {
  TagOptionWithValues,
  useTagsOptionsWithValues,
} from 'data/useTagsAndValues'
import { TagFilterDraft, TagFilterDraftError } from 'components/TagsInput/types'
import { TagInput, TagInputProps, TagsInputList } from 'components/TagsInput'
import { LoadingContainer } from 'components/LoadingContainer'

export interface PreloadedTagInputProps
  extends Omit<TagInputProps, 'tagOptions' | 'tagValues'> {
  preloadedTagOptionsWithTagValues?: TagOptionWithValues[]
  excludedTags?: Array<string | undefined>
}

//TrendingMetrics loads all tags and respective values preemptively on modal appearance
export const PreloadedTagInput = (props: PreloadedTagInputProps) => {
  const { preloadedTagOptionsWithTagValues, draft } = props

  if (!preloadedTagOptionsWithTagValues) {
    return <TagInput {...props} tagOptions={[]} tagValues={[]} />
  }

  const remainingTagOptions = preloadedTagOptionsWithTagValues
    ?.map(({ tagOption }) => tagOption)
    .filter(({ value }) => !props.excludedTags?.includes(value))

  const selectedTagOptionWithValues = preloadedTagOptionsWithTagValues.find(
    ({ tagOption }) => tagOption.value === draft.name
  )

  return (
    <TagInput
      {...props}
      tagOptions={remainingTagOptions}
      tagValues={selectedTagOptionWithValues?.tagValues || []}
    />
  )
}

interface TagsSectionProps {
  errors: TagFilterDraftError[] | undefined
  metric?: Metric
  tags: TagFilterDraft[]
  onChange: (tags: TagFilterDraft[]) => void
  testRun: TestRun
}

export const TagsSection = ({
  metric,
  tags,
  onChange,
  errors,
  testRun,
}: TagsSectionProps) => {
  const { data: tagOptionsWithTagValues, isInitialLoading } =
    useTagsOptionsWithValues(testRun, metric?.id)
  return (
    <LoadingContainer loading={isInitialLoading} estimatedHeight={50}>
      <TagsInputList
        errors={errors}
        metric={metric}
        drafts={tags}
        preloadedTagOptionsWithTagValues={tagOptionsWithTagValues}
        TagInput={PreloadedTagInput}
        fullWidth
        onChange={onChange}
        testRunId={testRun.id}
      />
    </LoadingContainer>
  )
}
