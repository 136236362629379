import { CenteredSpinner } from 'components/CenteredSpinner'
import React from 'react'
import styled, { css } from 'styled-components'

const Overlay = css`
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Row = styled.tr`
  ${Overlay}
`

const Cell = styled.td`
  ${Overlay}

  &::before {
    ${Overlay}

    content: ' ';
    display: block;
    background-color: #000;
    opacity: 0.2;
  }
`

export function LoadingOverlay({ loading }: { loading: boolean }) {
  if (!loading) {
    return null
  }

  return (
    <Row role="presentation">
      <Cell colSpan={1000} role="presentation">
        <CenteredSpinner />
      </Cell>
    </Row>
  )
}
