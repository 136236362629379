import { useQuery, useMutation } from '@tanstack/react-query'
import { useDatasource } from 'datasourceContext'
import { Test, TrendingMetricConfig } from 'types'
import { showAlert } from 'utils/showAlert'
import { queryClient } from './queryClient'
import { toTestTrendingMetricsOptionsKey } from './queryKeys/trendingMetrics'
import { toTestWithTrendsQueryKey } from './queryKeys'
import { TrendingMetricDraftConfig } from 'components/TrendingMetricsModal/TrendingMetricsEditor/TrendingMetricsEditor.types'
import {
  canResetTrendingMetrics,
  DEFAULT_TRENDING_METRIC_CONFIG,
  toActionCategories,
  toTrendingMetricConfigPayload,
  toValidTrendingMetricsDraftConfigs,
} from 'components/TrendingMetricsModal/TrendingMetricsEditor/TrendingMetricsEditor.utils'

export const useTrendingMetrics = (test: Test, fetchMetricsOptions = false) => {
  const { ds } = useDatasource()

  return useQuery({
    queryKey: toTestTrendingMetricsOptionsKey(test.id),
    queryFn: () => ds.fetchTestTrendingMetricsOptions(test.id),
    enabled: fetchMetricsOptions,
  })
}

export const useUpdateTrendingMetrics = (test: Test) => {
  const { ds } = useDatasource()

  return useMutation({
    mutationFn: async (
      trendingMetricsDraftConfigs: TrendingMetricDraftConfig[]
    ) => {
      const { toUpdate } = toActionCategories(
        test.trending_metrics,
        trendingMetricsDraftConfigs
      )

      const updateMutations = toValidTrendingMetricsDraftConfigs(toUpdate).map(
        (draftConfig) =>
          ds.updateTrendingMetric(
            toTrendingMetricConfigPayload(draftConfig, test.id)
          )
      )

      await Promise.all([...updateMutations])
    },
    onSuccess: () => {
      queryClient.invalidateQueries(toTestWithTrendsQueryKey(test.id))
    },
    onError: () => {
      showAlert('Failed to updating the trending metrics.', 'error')
    },
  })
}

// @TODO: Once we allow multiple trending metrics being set, make sure to delete all the configs except the default (first)
export const useResetTrendingMetrics = (test: Test) => {
  const { ds } = useDatasource()

  return useMutation({
    mutationFn: async (
      trendingMetricsDraftConfigs: TrendingMetricDraftConfig[]
    ) => {
      const canReset = canResetTrendingMetrics(test.trending_metrics)
      if (!canReset) {
        return
      }
      const defaultTrendingMetric = trendingMetricsDraftConfigs[0]!

      const trendingMetricPayload = {
        ...DEFAULT_TRENDING_METRIC_CONFIG,
        id: defaultTrendingMetric.id,
        load_test_id: test.id,
      } as TrendingMetricConfig

      await ds.updateTrendingMetric(trendingMetricPayload)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(toTestWithTrendsQueryKey(test.id))
    },
    onError: () => {
      showAlert('Failed to reset the trending metrics.', 'error')
    },
  })
}
