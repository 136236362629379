import React from 'react'

import { Value } from './Value'
import { ProjectSettings } from 'types'

interface VUhMaxPerMonthValueProps {
  limit?: ProjectSettings['vuh_max_per_month']
}

export function VUhMaxPerMonthValue({ limit }: VUhMaxPerMonthValueProps) {
  return <Value>{limit ?? '-'}</Value>
}
