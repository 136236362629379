import styled, { css } from 'styled-components'
import { spacing } from 'utils/styled'
import { MetaBarItem } from './MetaBarItem'

interface MetaSectionProps {
  alignEnd?: boolean
  fixedWidth?: boolean
  gap?: number
}

const MetaBarStyles = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${spacing(2)};
  flex-wrap: wrap;
  min-height: ${spacing(4)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: flex-start;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    align-items: center;
    flex-direction: row;
  }
`

const MetaBarSection = styled.div<MetaSectionProps>`
  display: flex;
  gap: ${({ theme, gap }) => theme.spacing(gap ?? 2)};
  align-items: center;
  flex: 1 1 0;
  flex-wrap: wrap;

  ${({ fixedWidth = false }) =>
    fixedWidth
      ? css`
          flex: unset;
        `
      : css`
          @media (min-width: ${({ theme }) =>
              theme.breakpoints.values.md + 1}px) {
            flex-basis: 0;
          }
        `}

  ${({ alignEnd = false }) =>
    alignEnd &&
    css`
      justify-content: flex-end;
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    flex-basis: auto;
    width: 100%;
    justify-content: flex-start;
  }
`

export const MetaBar = Object.assign(MetaBarStyles, {
  Item: MetaBarItem,
  Section: MetaBarSection,
})
