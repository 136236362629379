import type * as acorn from 'acorn'

export function getPropertyName(node: acorn.Property) {
  if (node.key.type === 'Identifier') {
    return node.key.name
  }

  if (node.key.type !== 'Literal' || typeof node.key.value !== 'string') {
    return null
  }

  return node.key.value
}
