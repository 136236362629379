import { type infer as Infer, discriminatedUnion, type ZodIssue } from 'zod'
import {
  NoOptionsExportedSchema,
  NonConstantOptionsSchema,
  ScriptSyntaxErrorSchema,
  ScriptTooLargeSchema,
} from './schema/script/errors'
import {
  InvalidDistributionTotalSchema,
  InvalidShorthandCombinationSchema,
  InvalidUseOfVUsSchema,
} from './schema/options/errors'
import { InvalidDurationSchema, InvalidJsonSchema } from './schema/primitives'
import { EmptyNameSchema } from './schema/testName'
import { TestBuilderErrorSchema } from './schema/builder'
import {
  DurationTooLongSchema,
  LegacyTooManyLoadZonesSchema,
  MaxDurationTooLongSchema,
  StartingTooLateSchema,
  TooManyBrowserVUsSchema,
  TooManyLoadZonesSchema,
  TooManyVUsSchema,
  TotalDurationTooLongSchema,
} from './rules/errors'
import {
  NameUnavailableSchema,
  ServerValidationErrorSchema,
  UnknownServerErrorSchema,
} from './api/errors'
import { NodePath } from '../utils/script/nodePath'

const CustomErrorSchema = discriminatedUnion('type', [
  // Name
  EmptyNameSchema,
  NameUnavailableSchema,

  // Primitives
  InvalidDurationSchema,
  InvalidJsonSchema,

  // Options
  InvalidShorthandCombinationSchema,
  InvalidUseOfVUsSchema,
  InvalidDistributionTotalSchema,
  UnknownServerErrorSchema,
  ServerValidationErrorSchema,

  // Builder
  TestBuilderErrorSchema,

  // Script
  ScriptTooLargeSchema,
  ScriptSyntaxErrorSchema,
  NoOptionsExportedSchema,
  NonConstantOptionsSchema,

  // Rules
  DurationTooLongSchema,
  MaxDurationTooLongSchema,
  TotalDurationTooLongSchema,
  StartingTooLateSchema,
  LegacyTooManyLoadZonesSchema,
  TooManyLoadZonesSchema,
  TooManyVUsSchema,
  TooManyBrowserVUsSchema,
])

type CustomError = Infer<typeof CustomErrorSchema>

export interface ZodError {
  type: 'zod-error'
  issue: ZodIssue
  path: NodePath
}

export type ValidationError = CustomError | ZodError

function fromZodError(error: ZodIssue): ValidationError {
  switch (error.code) {
    case 'custom':
      return CustomErrorSchema.parse(error.params)

    default:
      return {
        type: 'zod-error',
        issue: error,
        path: error.path,
      }
  }
}

export function formatErrors(errors: ZodIssue[]) {
  return errors.map(fromZodError) as [ValidationError, ...ValidationError[]]
}

export function hasFatalError(errors: ValidationError[]) {
  return errors.some((error) => {
    return (
      error.type === 'empty-name' ||
      error.type === 'name-unavailable' ||
      error.type === 'script-too-large' ||
      error.type === 'test-builder-error'
    )
  })
}
