import React from 'react'

import { exhaustive } from 'utils/typescript'
import { NotApplicableInsight, SkippedInsight } from 'types/insights/common'
import { duration, quantity } from 'utils/formatters'

interface SkippedDescriptionProps {
  insight: SkippedInsight | NotApplicableInsight
}

export const SkippedDescription = ({ insight }: SkippedDescriptionProps) => {
  switch (insight.status) {
    case 'CREATED':
      return (
        <>
          The insight could not be processed. This usually happens when a metric
          that the insight depends on is missing{` `}
          {/* cspell:disable-next-line */}
          or doesn&apos;t have enough data.
        </>
      )

    case 'SKIPPED':
      return <>The test was configured to skip processing of this insight.</>

    case 'N/A':
      switch (insight.data.reason) {
        case 'not_enough_training_data':
          return (
            <>
              To collect enough training data the test must complete{' '}
              {quantity(insight.data.threshold.min_iterations)} iterations, but
              it only completed {quantity(insight.data.total_iterations)}.
            </>
          )

        case 'duration_too_short':
          return (
            <>
              In order to perform any meaningful analysis we needed the test to
              run for at least{' '}
              {duration(insight.data.threshold.min_duration_ms / 1000, {
                verbose: true,
              })}{' '}
              after completing its first{' '}
              {quantity(insight.data.threshold.min_iterations)} iterations.
            </>
          )

        default:
          return (
            <>
              Analysis could not be performed because there was not enough data.
            </>
          )
      }

    default:
      return exhaustive(insight, <></>)
  }
}
