import React, { Fragment, useEffect } from 'react'
import { PagedItems, SortOptions, TestRunAnalysis } from 'types'
import { ShowMorePaginator } from './ShowMorePaginator'
import { Diff, TreeTable } from '../types'
import { ValueRow } from '../ValueRow'
import { useGroupValues } from './hooks'
import { useGroupToggle } from '../TableStateProvider'
import { GroupTreeNode } from 'data/clients/entities/groups'
import { TableRow, TableRowIcon, LoadingRow, TableData } from '../Table'
import { EmptyGroupRow } from './EmptyGroupRow'
import { useSelectedColumns } from '../TableSettingsContext'

interface GroupProps<T, P> {
  analysis: TestRunAnalysis
  node: GroupTreeNode
  params: P
  table: TreeTable<T, P>
  level: number
  sortBy: SortOptions<T> | undefined
}

function GroupNode<T, P>({
  analysis,
  node,
  params,
  table,
  level,
  sortBy,
}: GroupProps<T, P>) {
  const [expanded, toggleGroup] = useGroupToggle(node.left)
  const columns = useSelectedColumns(table.columns)

  return (
    <>
      <TableRow expanded={expanded} onToggle={toggleGroup}>
        {columns.map((column, index) => {
          return (
            <TableData
              key={index}
              type="group"
              column={column}
              index={index}
              level={level}
              rowIcon={
                <TableRowIcon name={expanded ? 'folder-open' : 'folder'} />
              }
            >
              {column.renderGroup(node.left, node.right)}
            </TableData>
          )
        })}
      </TableRow>
      {expanded && (
        <GroupChildren
          analysis={analysis}
          node={node}
          params={params}
          table={table}
          level={level + 1}
          sortBy={sortBy}
        />
      )}
    </>
  )
}

interface ValuePageProps<T, P> {
  analysis: TestRunAnalysis
  page: PagedItems<Diff<T>>
  params: P
  table: TreeTable<T, P>
  level: number
}

function ValuePage<T, P>({
  analysis,
  page,
  params,
  table,
  level,
}: ValuePageProps<T, P>) {
  return (
    <>
      {page.items.map((row) => (
        <ValueRow
          key={table.keyBy(row.left)}
          view="tree"
          analysis={analysis}
          value={row}
          level={level}
          params={params}
          table={table}
        />
      ))}
    </>
  )
}

interface GroupChildrenProps<T, P> {
  analysis: TestRunAnalysis
  node: GroupTreeNode
  params: P
  table: TreeTable<T, P>
  level: number
  sortBy: SortOptions<T> | undefined
  onLoading?: () => void
  onLoaded?: () => void
}

export function GroupChildren<T, P>({
  analysis,
  node,
  params,
  level,
  sortBy,
  table,
  onLoading,
  onLoaded,
}: GroupChildrenProps<T, P>) {
  const { isLoading, isFetching, hasNextPage, values, pages, fetchNextPage } =
    useGroupValues({
      analysis,
      group: node.left,
      params,
      sortBy,
      table,
    })

  useEffect(() => {
    if (!isLoading) {
      onLoaded?.()
    }
  }, [isLoading, onLoaded])

  useEffect(() => {
    if (isLoading) {
      onLoading?.()
    }
  }, [isLoading, onLoading])

  const handleShowMore = () => {
    fetchNextPage()
  }

  if (
    node.children.length === 0 &&
    values &&
    values.every((page) => page.count === 0)
  ) {
    return (
      <EmptyGroupRow testRun={analysis.main} params={params} table={table} />
    )
  }

  if (isLoading) {
    return <LoadingRow />
  }

  return (
    <>
      {pages.map((page, index) => (
        <Fragment key={index}>
          {page.isLoading && <LoadingRow />}
          {page.data && (
            <ValuePage
              analysis={analysis}
              params={params}
              page={page.data}
              table={table}
              level={level}
            />
          )}
        </Fragment>
      ))}
      <ShowMorePaginator
        hasMore={hasNextPage ?? false}
        isFetching={isFetching}
        onShowMore={handleShowMore}
      />
      {node.children.map((child) => (
        <GroupNode
          key={child.left.id}
          analysis={analysis}
          node={child}
          params={params}
          level={level}
          sortBy={sortBy}
          table={table}
        />
      ))}
    </>
  )
}
