import styled from 'styled-components'
import { spacing } from 'utils/styled'

export const TableContainer = styled.div`
  position: relative;
`

export const PaginationWrapper = styled.div`
  margin: ${spacing(2)};

  &:empty {
    margin: 0;
  }

  &::after {
    content: ' ';
    display: block;
    clear: both;
  }
`
