import React from 'react'
import styled from 'styled-components'

import { CreateTestButton } from 'components/CreateTestButton'
import { BertMessage } from 'components/BertMessage'
import {
  useHasUserProjectWriteAccess,
  useIsUserProjectAdmin,
} from 'data/usePermissions'
import { Body } from './EmptyState.styles'
import { useCurrentProject } from 'projectContext'
import { spacing } from 'utils/styled'
import { Link } from 'react-router-dom'
import { routeMap } from 'routeMap'

export function EmptyState() {
  return (
    <Body>
      <BertMessage
        title="Looks like you don't have any tests created"
        description={<Description />}
      />
    </Body>
  )
}

function Description() {
  const project = useCurrentProject()
  const hasUserProjectWriteAccess = useHasUserProjectWriteAccess()
  const hasUserProjectAdminAccess = useIsUserProjectAdmin()

  if (!hasUserProjectWriteAccess) {
    return (
      <BertMessage.Text>
        Unfortunately, you don{"'"}t have permissions to create new tests
      </BertMessage.Text>
    )
  }

  return (
    <>
      <BertMessage.Text>
        Fortunately, it{"'"}s very easy to create one
      </BertMessage.Text>
      <CreateTestButton />
      {hasUserProjectAdminAccess && (
        <>
          <Divider />
          <BertMessage.Text>
            Grant access to your project using{' '}
            <Link to={routeMap.project(project.id, true)}>
              Manage permissions
            </Link>
          </BertMessage.Text>
        </>
      )}
    </>
  )
}

const Divider = styled.hr`
  width: 40%;
  margin: ${spacing(3, 0)};
`
