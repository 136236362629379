import styled, { css } from 'styled-components'
import { color, spacing } from 'utils/styled'
import { CAN_CONFIGURE_MULTIPLE_TRENDS } from '../TrendingMetricsModal'

export const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${spacing(2)} 0;
`

export const TrendingMetricsConfigEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing(2)};
  margin-bottom: ${spacing(2)};
  
  ${() =>
    CAN_CONFIGURE_MULTIPLE_TRENDS &&
    css`
      margin-bottom: ${spacing(7)};
      & > button {
        display: flex;
        float: right;
      }
    `}}
`

export const Description = styled.p`
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
  margin: 0;
`

export const ExpiredRunsInfoStyled = styled(Description)`
  b {
    text-decoration: underline;
  }
`

export const TrendingMetricEditorPanel = styled.div`
  position: relative;
  
  &> button {
    position: absolute;
    bottom: ${spacing(1)};
    right: ${spacing(1)};
  }
  
  ${() =>
    CAN_CONFIGURE_MULTIPLE_TRENDS &&
    css`
      padding: ${spacing(1)};
      background-color: ${color('background', 'secondary')};
    `}}
`

export const HorizontalGroup = styled.div`
  width: 100%;
  display: flex;
  gap: ${spacing(1)};

  & > div {
    flex: 1;
    & > div {
      width: 100%;
    }
  }
`

export const TrendingMetricEditorPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.div`
  font-size: ${({ theme }) => theme.typography.h6.fontSize};
  font-weight: ${({ theme }) => theme.typography.h6.fontWeight};
  margin-bottom: ${spacing(2)};
`
