import { useLocalStorage } from 'usehooks-ts'

import { useIsUserOrgAdmin } from 'data/usePermissions'
import { useUserToggle } from 'hooks/useUserToggle'
import { useSnoozeUntil } from 'hooks/useSnoozeUntil'

export function useNoLimitsWarningState(projectId: number) {
  const isUserOrgAdmin = useIsUserOrgAdmin()
  const storageKey = `unset-project-limits-warning-dismissed-${projectId}`
  const {
    dismissed: isDismissedForAllProjects,
    dismiss: dismissForAllProjects,
  } = useUserToggle('unset-project-limits-warning')
  const [isDismissed, setIsDismissed] = useLocalStorage(storageKey, false)
  const { isSnoozed, onSnooze } = useSnoozeUntil({
    storageKey,
    minutes: 24 * 60,
  })

  const onDismiss = (shouldDismissForAll: boolean) => {
    setIsDismissed(true)
    dismissForAllProjects(shouldDismissForAll)
  }

  return {
    isVisible:
      isUserOrgAdmin &&
      !isSnoozed &&
      !isDismissed &&
      !isDismissedForAllProjects,
    onDismiss,
    onSnooze,
  }
}
