import { Dashboard, DashboardCursorSync, Panel } from '@grafana/schema'
import { DraftPanelModel, GeneratorContext, SummaryRow } from './types'
import { createSummaryPanel } from './texts/summary'
import { createStatsRow } from './visualizations/stats'
import {
  createBandwidthChart,
  createPerformanceOverviewChart,
  createResponseTimeChart,
  createThroughputChart,
} from './visualizations/charts'
import { createTextPanel } from './panels'
import { LoadZoneClient } from 'data/clients/loadZones'
import { TestRun } from 'types'
import { LoadZoneDistribution } from 'types/loadZone'
import { createResponseTimeText } from './texts/responseTime'
import { createPerformanceOverviewText } from './texts/performanceOverview'
import { createBandwidthText } from './texts/bandwidth'
import { createThroughputText } from './texts/throughput'

function fetchLoadZones(testRun: TestRun) {
  // There's no point in showing load zones if there's only one, because
  // the data would be identical to that of the global results.
  if (testRun.nodes.length <= 1) {
    return Promise.resolve([])
  }

  return new LoadZoneClient().forTestRun(testRun)
}

function layoutPanels(rows: SummaryRow[]) {
  const { panels } = rows.reduce(
    (result, { height, panels }) => {
      const width = 24 / panels.length

      const columns = panels.map((panel, x): DraftPanelModel => {
        return {
          ...panel,
          gridPos: {
            x: x * width,
            y: result.y,
            w: width,
            h: height,
          },
        }
      })

      return {
        y: result.y + height,
        panels: [...result.panels, ...columns],
      }
    },
    { y: 0, panels: [] as DraftPanelModel[] }
  )

  return panels
}

function createTestOverviewHeading(): SummaryRow {
  return {
    height: 2,
    panels: [createTextPanel('# Test overview')],
  }
}

function createLoadZoneHeading(loadZone: LoadZoneDistribution): SummaryRow {
  return {
    height: 2,
    panels: [createTextPanel(`# ${loadZone.name}`)],
  }
}

function generateSection(context: GeneratorContext) {
  return (loadZone: LoadZoneDistribution | undefined) => {
    const { responseTime, throughput, bandwidth } = context.options.sections

    if (!responseTime && !throughput && !bandwidth) {
      return []
    }

    const rows = [
      loadZone === undefined
        ? [createTestOverviewHeading()]
        : [createLoadZoneHeading(loadZone)],
      responseTime && [
        createResponseTimeText(context, loadZone),
        createResponseTimeChart(context, loadZone),
      ],
      throughput && [
        createThroughputText(context, loadZone),
        createThroughputChart(context, loadZone),
      ],
      bandwidth && [
        createBandwidthText(context, loadZone),
        createBandwidthChart(context, loadZone),
      ],
    ]

    return rows.flatMap((row) => row || [])
  }
}

type DashboardPayload = Omit<Dashboard, 'id' | 'uid' | 'style'> & {
  id: null
  uid: null
}

export async function generateSummaryDashboard(
  context: GeneratorContext
): Promise<DashboardPayload> {
  const { options } = context
  const { testRun } = context.options

  const loadZones = options.include.loadZones
    ? await fetchLoadZones(testRun)
    : []

  const sections = [undefined, ...loadZones].flatMap(generateSection(context))

  const rows: SummaryRow[] = await Promise.all([
    createStatsRow(context),
    createSummaryPanel(context),
    createPerformanceOverviewText(context),
    createPerformanceOverviewChart(context),
    ...sections,
  ])

  const panels = layoutPanels(rows)

  const from = testRun.started ?? testRun.created
  const to = testRun.ended ?? from

  return {
    id: null,
    uid: null,
    title: options.name,
    tags: ['k6'],
    timezone: 'browser',
    schemaVersion: 16,
    refresh: '25s',
    time: { from, to },
    timepicker: {
      hidden: true,
      collapse: true,
      enable: false,
      refresh_intervals: [],
      time_options: [],
    },
    editable: false,
    graphTooltip: DashboardCursorSync.Crosshair,
    panels: panels as Panel[],
  }
}
