import React, { MouseEvent, ReactNode } from 'react'
import { Tab as TabComponent } from '@grafana/ui'
import { TabStyles } from './Tab.styles'
import { PropsOf } from 'utils/typescript'
import { useHistory } from 'react-router-dom'

type GrafanaTabProps = PropsOf<typeof TabComponent>

interface TabProps
  extends Omit<
    GrafanaTabProps,
    'onChangeTab' | 'ref' | 'as' | 'suffix' | 'label'
  > {
  suffix?: GrafanaTabProps['suffix']
  onChangeTab?: () => void
  label: ReactNode
}

export const Tab = ({
  suffix,
  href,
  onChangeTab,
  label,
  ...props
}: TabProps) => {
  const history = useHistory()

  // We need to capture the click using a wrapper element instead the built-in onChangeTab-handler,
  // otherwise clicking the counter will trigger clicks in e.g. CollapsableSection.
  const handleTabClick = (ev: MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation()
    ev.preventDefault()

    if (href !== undefined) {
      history.push(href)
    }

    onChangeTab?.()
  }

  return (
    <TabStyles onClick={handleTabClick}>
      <TabComponent
        {...props}
        href={href}
        suffix={suffix}
        // @ts-expect-error Tab component has label set to type string while in fact ReactNode can be used
        label={label}
      />
    </TabStyles>
  )
}
