import React from 'react'
import { PageContainer } from './InitPage.styled'
import { Page } from './components/Page'
import { TopSection } from './compositions/TopSection'
import { ScriptExampleSection } from './compositions/ScriptExampleSection'
import { HowGrafanaWorksSection } from './compositions/HowGrafanaWorksSection'
import { DesignedToHelpTeamsSection } from './compositions/DesignedToHelpTeamsSection'
import { BillingSection } from './compositions/BillingSection'
import { FooterSection } from './compositions/FooterSection'
import { InitializeCTASection } from './compositions/InitializeCTASection'
import { SectionGrid } from './components/SectionGrid.styled'

export function InitPage({ onInitialize }: { onInitialize: () => void }) {
  return (
    <Page>
      <PageContainer>
        <TopSection onInitialize={onInitialize} />
        <HowGrafanaWorksSection />
        <ScriptExampleSection />
        <DesignedToHelpTeamsSection />
        <SectionGrid columns={2}>
          <InitializeCTASection onInitialize={onInitialize} />
          <BillingSection />
        </SectionGrid>
        <FooterSection />
      </PageContainer>
    </Page>
  )
}
