import React, { useState } from 'react'
import styled from 'styled-components'
import { Icon, IconName } from '@grafana/ui'

import { TestWithTrends } from 'types'
import { color, spacing } from 'utils/styled'
import {
  isCalculatingTrendingValues,
  useTrendPolling,
} from 'data/useTestWithTrends'
import { CollapsableSection } from 'components/CollapsableSection'
import { Flex } from 'components/Flex'
import { Tab } from 'components/Tabs/Tab'
import { TabsBar } from 'components/Tabs/TabsBar'

import { TrendsHttp } from './TrendsHttp'
import { TrendsInsights } from './TrendsInsights'
import { useFeatureFlag } from 'store/FeatureFlags'

type TrendsTabValue = 'http' | 'insights'

interface TrendsTab {
  label: string
  value: TrendsTabValue
  icon: IconName
}

const useTrendTabs = () => {
  const isFeatureCloudInsightsEnabled = useFeatureFlag('cloudInsights')

  const tabs: TrendsTab[] = [
    {
      label: 'HTTP',
      value: 'http',
      icon: 'graph-bar',
    },
  ]

  // TODO - add to tabs array when this feature is officially released
  if (isFeatureCloudInsightsEnabled) {
    tabs.push({
      label: 'Insights',
      value: 'insights',
      icon: 'heart-rate',
    })
  }

  return tabs
}

interface TrendsProps {
  test: TestWithTrends
}

export const Trends = ({ test }: TrendsProps) => {
  const [open, setOpen] = useState(true)
  const [filter, setFilter] = useState<TrendsTabValue>('http')
  const { data: latestTest } = useTrendPolling(test)
  const tabs = useTrendTabs()
  const isCalculating = isCalculatingTrendingValues(latestTest)
  const [trend] = latestTest.trends

  const handleTabChange = (value: TrendsTabValue) => {
    if (!open) {
      setOpen(true)
    }

    setFilter(value)
  }

  return (
    <MainContainer>
      <CollapsableSection
        label={
          <TabContainer>
            <TabsBar>
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={<TabLabel {...tab} />}
                  active={open && filter === tab.value}
                  onChangeTab={() => handleTabChange(tab.value)}
                />
              ))}
            </TabsBar>
          </TabContainer>
        }
        isOpen={open}
        onToggle={setOpen}
      >
        {open && <Divider />}

        <TrendContainer>
          {filter === 'http' && (
            <TrendsHttp trend={trend} calculating={isCalculating} />
          )}

          {filter === 'insights' && (
            <TrendsInsights trend={trend} calculating={isCalculating} />
          )}
        </TrendContainer>
      </CollapsableSection>
    </MainContainer>
  )
}

const TabLabel = ({ label, icon }: TrendsTab) => {
  return (
    <Flex align="center" gap={1}>
      <Icon name={icon} size="xl" />
      <span>{label}</span>
    </Flex>
  )
}

const MainContainer = styled.div`
  background-color: ${color('background', 'secondary')};
`

const TabContainer = styled.div`
  padding: ${spacing(0, 2, 0.5)};
`

const TrendContainer = styled.div`
  padding: ${spacing(2)};
`

const Divider = styled.hr`
  margin: 0;
`
