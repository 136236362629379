import { useQuery } from '@tanstack/react-query'

import { hasBrowserMetricSummary, TestRun } from 'types'
import { Insight, InsightsByStatus } from 'types/insights'
import { getBrowserInsights } from 'utils/webVitals'
import { useDatasource } from 'datasourceContext'
import { toPerformanceInsightsQueryKey } from './queryKeys'

export const usePerformanceInsights = (testRun: TestRun, enabled: boolean) => {
  const { ds } = useDatasource()

  return useQuery({
    queryKey: toPerformanceInsightsQueryKey(testRun.id),
    queryFn: () => ds.fetchPerformanceInsights(testRun.id),
    enabled,
    select: (data) => {
      if (!hasBrowserMetricSummary(testRun.browser_metric_summary)) {
        return groupInsightsByStatus(data.value)
      }

      return groupInsightsByStatus([
        ...data.value,
        ...getBrowserInsights(testRun),
      ])
    },
    useErrorBoundary: true,
  })
}

const groupInsightsByStatus = (value: Insight[]) => {
  return value.reduce<InsightsByStatus>(
    (result, insight) => {
      const status =
        insight.status === 'N/A' ? 'NOT_APPLICABLE' : insight.status

      // The outdated release insight should only be displayed if the insight failed.
      if (
        insight.name === 'best_practice_outdated_k6_release_used' &&
        insight.status !== 'FAILED'
      ) {
        return result
      }

      return {
        ...result,
        [status]: [...result[status], insight],
      }
    },
    {
      CREATED: [],
      PASSED: [],
      FAILED: [],
      SKIPPED: [],
      WARNING: [],
      NOT_APPLICABLE: [],
    }
  )
}
