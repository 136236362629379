import { BrowserChart } from './BrowserChart'
import React from 'react'
import { BrowserUrls } from 'types/k6Browser'
import { TestRunAnalysis } from 'types'

interface RowBodyComponentProps {
  value: BrowserUrls
  analysis: TestRunAnalysis
}

export function RowBodyComponent({ analysis, value }: RowBodyComponentProps) {
  return (
    <BrowserChart
      analysis={analysis}
      scenario={value.scenario}
      url={value.name}
    />
  )
}

export function renderRowBodyComponent(
  analysis: RowBodyComponentProps['analysis'],
  value: RowBodyComponentProps['value']
) {
  return <RowBodyComponent analysis={analysis} value={value} />
}
